export class StreamList {
    /**
     * Список id устройств
     */
    deviceIdList: string[];

    /**
     * Индексированный по deviceId список
     */
    byDeviceId: { [id: string]: MediaStream };

    constructor() {
        this.byDeviceId = {};
        this.deviceIdList = [];
    }

    /**
     *
     * @param deviceId
     * @param stream
     */
    registerStream(deviceId: string, stream: MediaStream): void {
        if (this.byDeviceId[deviceId] !== undefined) {
            throw new Error('Device stream already available');
        }

        this.byDeviceId[deviceId] = stream;
        this.deviceIdList.push(deviceId);
    }

    /**
     *
     * @param deviceId
     */
    getStream(deviceId: string): MediaStream | null {
        return this.byDeviceId[deviceId]??null;
    }

    /**
     *
     * @param deviceId
     */
    unregisterStream(deviceId: string): void {
        if (this.byDeviceId[deviceId] !== undefined) {
            delete this.byDeviceId[deviceId];
        }

        this.deviceIdList = this.deviceIdList.filter(item => item !== deviceId);
    }

    /**
     *
     */
    getAllDeviceIds(): string[] {
        return this.deviceIdList;
    }
}
