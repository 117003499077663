import React from 'react';

import styled from "styled-components";

const WrapperStyle = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

interface Props {
    children: React.ReactNode;
}

export function Wrapper({children}: Props) {
    return (
        <WrapperStyle>
            {children}
        </WrapperStyle>
    );
}