import {put, takeEvery} from 'redux-saga/effects';
import {LessonRoomsActionTypes, OpenedLessonRoom} from "../../store/lessonRooms/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";
import {DtoSELessonOpenedList} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSELessonOpenedList";
import {addOpenedLessonRooms} from "../../store/lessonRooms/actions";

/**
 * Обработка ответа за запрос доступных для ученика комнат урока
 */
export function* watchHandleGetOpenedRoomsResponse() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<DtoSELessonOpenedList>, string>>>(
        LessonRoomsActionTypes.HANDLE_GET_OPENED_ROOMS_RESPONSE,
        handleGetOpenedRoomsResponse
    );
}

function* handleGetOpenedRoomsResponse(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<DtoSELessonOpenedList>, string>>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.LESSON_ROOM, "Get opened rooms response completed");

    if (data.payload.response.status !== WsResponseStatusEnum.OK) {
        logger.error(
            LoggerSectionsEnum.LESSON_ROOM,
            `Error on load opened lesson rooms: Response code is ${data.payload.response.status}.`
        );

        return;
    }

    const localRoomModels: OpenedLessonRoom[] = data.payload.response.result.list.map((item) => {
        return {
            roomId: item.lessonRoomId,
            teacherId: item.teacherId,
            teacherAvatarFileId: item.teacherAvatarFileId,
            teacherName: item.teacherName,
        }
    });

    yield put(addOpenedLessonRooms(localRoomModels));
}
