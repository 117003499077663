import React, {useContext, useMemo} from "react";
import {EpisodeContext, IEpisodeContext} from "../EpisodeContext";
import {ISubscriptionContext, SubscriptionContext} from "../../SubscriptionContext";
import styled from "styled-components";
import Energy32 from "../../../../../../../../components/Ui/Png/Energy32@2x.png";

const Wrapper = styled.div`
    border-radius: 8px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    border: 2px solid ${({theme}) => theme.colors.backgroundPrimary};
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const EnergyIcon = styled.img`
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
`;

const LossFiller = styled.div`
    position: absolute;
    left: -180%;
    width: 200%;
    aspect-ratio: 1;
    top: 0;
    background: linear-gradient(52deg, rgba(252, 200, 17, 1) 0%, rgba(252, 200, 17, 1) 50%, rgba(0, 212, 255, 0) 83%);
    z-index: 0;
`;

interface LossPowerIndicatorProps {
    className?: string;
}

export const LossPowerIndicator: React.FC<LossPowerIndicatorProps> = ({className}) => {
    const {overviewItem} = useContext<ISubscriptionContext>(SubscriptionContext);
    const {secondsBeforeOpen} = useContext<IEpisodeContext>(EpisodeContext);

    const remainInPercent = useMemo<number>(() => {
        if (overviewItem.chargingValue === 0) {
            return 100;
        }

        return 100 - Math.floor((100 / 86400) * secondsBeforeOpen());
    }, [overviewItem.chargingValue, secondsBeforeOpen]);

    const fillValueForGradient = useMemo<number>(() => {
        // Пустой градиент, если -180. Полный если 0.

        return (180 / 100) * remainInPercent * -1;
    }, [remainInPercent]);


    return <Wrapper className={className} title={remainInPercent.toString(10)}>
        <LossFiller style={{left: `${fillValueForGradient}%`}}/>
        <EnergyIcon src={Energy32} alt={'Energy icon'}/>
    </Wrapper>
}