import {IActionHandler} from "./IActionHandler";
import {SlideItemInteractivityActionTypeEnum} from "../../../../../enums/SlideItemInteractivityActionNameEnum";
import {ShowOrHideItemByAlias} from "./ShowOrHideItemByAlias";
import {ILogger} from "../../../../../components/Logger/ILogger";

export class ActionHandlerFactory {
    static getActionHandler(logger: ILogger, actionType: SlideItemInteractivityActionTypeEnum): IActionHandler | null
    {
        switch (actionType){
            case SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS:{
                return new ShowOrHideItemByAlias(logger);
            }
        }

        return null;
    }
}