import styled from "styled-components";

export const RegularText = styled.p`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: normal;
  margin-bottom: 0;

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

export const RegularOl = styled.ol`
  margin-bottom: 0;
`;

export const RegularUl = styled.ul`
  margin-bottom: 0;
`;

export const RegularRadioOl = styled.ol`
  list-style: none;
`;

export const RegularLi = styled.li`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: normal;
  margin-bottom: 0;

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

export const RegularTitle = styled.h1`
  font-size: 17px;
  line-height: 1.5em;
  font-weight: bold;
  margin-bottom: 0;

  @media (${({theme}) => theme.media.small}) {
    font-size: 20px;
  }
`;