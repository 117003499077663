import {IDeviceMediaStreamFetcher} from "./IDeviceMediaStreamFetcher";
import {StreamList} from "./StreamList";
import {ILogger} from "../Logger/ILogger";
import {PresetIdsEnum} from "./PresetIdsEnum";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class DeviceMediaStreamFetcher implements IDeviceMediaStreamFetcher {
    logger: ILogger;

    cameraStreams: StreamList;
    microphoneStreams: StreamList;
    cameraWithMicrophoneStreams: StreamList;
    screenStream: MediaStream | null;

    constructor(logger: ILogger) {
        this.logger = logger;

        this.cameraStreams = new StreamList();
        this.microphoneStreams = new StreamList();
        this.cameraWithMicrophoneStreams = new StreamList();
        this.screenStream = null;
    }

    /**
     * @inheritDoc
     */
    async getCameraStream(cameraPresetId: PresetIdsEnum, deviceId: string): Promise<MediaStream> {
        const existStream = this.cameraStreams.getStream(deviceId);

        if (existStream) {
            return existStream;
        }

        const newStream = await navigator.mediaDevices.getUserMedia(
            {
                video: {
                    ...this.getCameraConstraintsByPresetId(cameraPresetId),
                    deviceId: deviceId
                }
            }
        );

        this.cameraStreams.registerStream(deviceId, newStream);

        this.addStreamStopHandler(
            newStream,
            () => {
                this.logger.info(
                    LoggerSectionsEnum.DEVICE_MEDIA_STREAM_FETCHER,
                    `Camera stream with device id ${deviceId} ended and unregistered.`
                );

                this.cameraStreams.unregisterStream(deviceId);
            }
        );

        return newStream;
    }

    /**
     * @inheritDoc
     */
    stopCameraStream(deviceId: string) {
        const stream = this.cameraStreams.getStream(deviceId);

        if (stream !== null) {
            this.stopStream(stream);
        }

        this.cameraStreams.unregisterStream(deviceId);
    }

    /**
     * @inheritDoc
     */
    stopAllCameraStreams(): void {
        this.cameraStreams.getAllDeviceIds().forEach(deviceId => this.stopCameraStream(deviceId));
    }

    /**
     * @inheritDoc
     */
    async getMicrophoneStream(deviceId: string): Promise<MediaStream> {
        const existStream = this.microphoneStreams.getStream(deviceId);

        if (existStream) {
            return existStream;
        }

        const newStream = await navigator.mediaDevices.getUserMedia(
            {
                audio: {
                    ...this.getMicrophoneConstraints(),
                    deviceId: deviceId
                }
            }
        );

        this.microphoneStreams.registerStream(deviceId, newStream);

        this.addStreamStopHandler(
            newStream,
            () => {
                this.logger.info(
                    LoggerSectionsEnum.DEVICE_MEDIA_STREAM_FETCHER,
                    `Microphone stream with device id ${deviceId} ended and unregistered.`
                );

                this.microphoneStreams.unregisterStream(deviceId);
            }
        );

        return newStream;
    }

    /**
     * @inheritDoc
     */
    stopAllMicrophoneStreams(): void {
        this.microphoneStreams.getAllDeviceIds().forEach(deviceId => this.stopMicrophoneStream(deviceId));
    }

    /**
     * @inheritDoc
     */
    stopMicrophoneStream(deviceId: string): void {
        const stream = this.microphoneStreams.getStream(deviceId);

        if (stream !== null) {
            this.stopStream(stream);
        }

        this.microphoneStreams.unregisterStream(deviceId);
    }

    /**
     * @inheritDoc
     */
    async getCameraWithMicrophoneStream(cameraPresetId: PresetIdsEnum, cameraDeviceId: string, microphoneDeviceId: string): Promise<MediaStream> {
        const streamDeviceId = cameraDeviceId + microphoneDeviceId;

        const existStream = this.cameraWithMicrophoneStreams.getStream(streamDeviceId);

        if (existStream) {
            return existStream;
        }

        const newStream = await navigator.mediaDevices.getUserMedia(
            {
                video: {
                    ...this.getCameraConstraintsByPresetId(cameraPresetId),
                    deviceId: cameraDeviceId
                },
                audio: {
                    ...this.getMicrophoneConstraints(),
                    deviceId: microphoneDeviceId
                }
            }
        );

        this.cameraWithMicrophoneStreams.registerStream(streamDeviceId, newStream);

        this.addStreamStopHandler(
            newStream,
            () => {
                this.logger.info(
                    LoggerSectionsEnum.DEVICE_MEDIA_STREAM_FETCHER,
                    `Microphone+camera stream with device id ${streamDeviceId} ended and unregistered.`
                );

                this.cameraWithMicrophoneStreams.unregisterStream(streamDeviceId);
            }
        );

        return newStream;
    }

    /**
     *
     * @param cameraDeviceId
     * @param microphoneDeviceId
     */
    stopCameraWithMicrophoneStream(cameraDeviceId: string, microphoneDeviceId: string): void {
        const streamDeviceId = cameraDeviceId + microphoneDeviceId;

        const stream = this.cameraWithMicrophoneStreams.getStream(streamDeviceId);

        if (stream !== null) {
            this.stopStream(stream);
        }

        this.cameraWithMicrophoneStreams.unregisterStream(streamDeviceId);
    }

    /**
     * @inheritDoc
     */
    async getScreenStream(): Promise<MediaStream> {
        if (this.screenStream !== null) {
            return this.screenStream;
        }

        this.screenStream = await navigator.mediaDevices.getDisplayMedia({
            video: {
                sampleRate: {
                    max: 1536000
                },
                frameRate: 20,
                width: {
                    max: 1280,
                },
                height: {
                    max: 720
                },
            },
            audio: {
                autoGainControl: false,
                echoCancellation: false,
                noiseSuppression: false,
                suppressLocalAudioPlayback: false
            }
        });

        return this.screenStream;
    }

    /**
     * @inheritDoc
     */
    stopScreenStream(): void {
        if (this.screenStream) {
            this.stopStream(this.screenStream);
        }

        this.screenStream = null;
    }

    /**
     *
     * @param presetId
     * @protected
     */
    protected getCameraConstraintsByPresetId(presetId: PresetIdsEnum): MediaTrackConstraints {
        const baseConfig: MediaTrackConstraints = {
            width: {
                min: 320,
                ideal: 640,
                max: 640
            },
            aspectRatio: 1.777777778,
            frameRate: 24,
        };

        switch (presetId) {
            case PresetIdsEnum.CAMERA_LOW: {
                baseConfig.width = {min: 320, ideal: 320, max: 640};
            }
        }

        return baseConfig;
    }

    protected getMicrophoneConstraints(): MediaTrackConstraints {
        return {
            autoGainControl: true,
            channelCount: 2,
            echoCancellation: true,
            noiseSuppression: true,
            sampleSize: 16,
            sampleRate: 16000
        }
    }

    protected stopStream(stream: MediaStream): void {
        try {
            const tracks = stream.getTracks();

            for (let i in tracks) {
                const mediaStreamTrack = tracks[i];

                if (mediaStreamTrack) {
                    mediaStreamTrack.stop();
                }
            }
        } catch (e) {

        }
    }

    protected addStreamStopHandler(stream: MediaStream, callback: Function): void {
        stream.addEventListener('ended', () => callback);
    }
}
