import {AllMessages} from "@lingui/core/cjs/i18n";

export enum I18nActionTypes {

    // Запускает процесс установки локали. В т.к. запускает процесс загрузки локали.
    SELECT_LOCALE = '@@i18n/SELECT_LOCALE',

    // Сохранить название текущей локали в i18n.
    SET_CURRENT_LOCALE_NAME = '@@i18n/SET_CURRENT_LOCALE_NAME',

    // Установить новое состояние
    SET_LOCALE_STATE = '@@i18n/SET_LOCALE_STATE'
}

export enum LocaleNameEnum {
    RU = "Русский",
    EN = "English"
}

export const LocaleNameById = {
    "ru-RU": LocaleNameEnum.RU,
    "en-US": LocaleNameEnum.EN
} as {[id: string]: LocaleNameEnum}

export enum LocaleEnum {
    RU = "ru-RU",
    EN = "en-US"
}

export const AvailableLocales = [
    LocaleEnum.EN,
    LocaleEnum.RU
]

export enum LocaleStateEnum {
    INIT_STATE,
    DOWNLOADING_DICTIONARY,
    READY,
    FAILED
}

export const DefaultLocale = LocaleEnum;

export type I18nCatalogType = AllMessages;

export interface I18nState {
    currentState: LocaleStateEnum;
    currentLocale: LocaleEnum;
    catalogs: I18nCatalogType;
}