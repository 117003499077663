import {instanceCachingFactory} from 'tsyringe';
import {IScreenSaverLock} from "../components/ScreenSaverLock/IScreenSaverLock";
import {ScreenSaverLock} from "../components/ScreenSaverLock/ScreenSaverLock";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";

export default instanceCachingFactory<IScreenSaverLock>((container) => {
    return new ScreenSaverLock(
        container.resolve<ILogger>(DiTokens.LOGGER)
    );
});
