import {Editor, Element, Element as SlateElement, Node, NodeEntry, Point, Range, Transforms} from "slate";
import {ALLOWED_FOR_EXERCISE_RADIO} from "../Enums";
import {IRadioListItem} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioListItem";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {IRadioList} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioList";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withRadioList = (editor: Editor) => {
    const {normalizeNode, deleteBackward} = editor;

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        // Проверим - есть ли такой элемент в списке разрешённых
        if (SlateElement.isElement(node)) {
            if (ALLOWED_FOR_EXERCISE_RADIO.indexOf(node.type) < 0) {
                Transforms.setNodes(editor, {type: ElementTypes.PARAGRAPH}, {at: path});
            }
        }

        // Если нормализуется корень, проверим все элементы LIST, чтобы их ID были не пустыми и уникальными
        if (path.length === 0) {
            const idList = new Set();

            const allExercises = Array.from(Editor.nodes(editor, {
                at: [],
                match: (node1, path1) => {
                    if (Editor.isEditor(node1)) {
                        return false;
                    }

                    if (!SlateElement.isElement(node1)) {
                        return false;
                    }

                    return node1.type === ElementTypes.EXERCISE_RADIO_LIST;
                },
                mode: "all"
            }));

            for (const [listElement, listElementPath] of allExercises) {
                const listElementId: string | undefined = (listElement as IRadioList).id;

                if (listElementId === undefined) {
                    const newId = ExerciseIdGenerator.generateId();
                    Transforms.setNodes<IRadioList>(editor, {id: newId}, {at: listElementPath});
                    idList.add(newId);
                } else {
                    // Если id есть - проверим, чтобы он был уникален
                    if (idList.has(listElementId)) {
                        const newId = ExerciseIdGenerator.generateId();
                        Transforms.setNodes<IRadioList>(editor, {id: newId}, {at: listElementPath});
                        idList.add(newId);
                    } else {
                        idList.add(listElementId);
                    }
                }
            }
        }

        // Проверим, чтобы у EXERCISE_RADIO_LIST_ITEM не существовало родителя кроме EXERCISE_RADIO_LIST
        // if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_RADIO_LIST_ITEM) {
        //     const [parent, parentPath] = Editor.parent(editor, path);
        //
        //     if (parent === editor) {
        //         const element: IRadioList = {
        //             id: ExerciseIdGenerator.generateId(),
        //             type: ElementTypes.EXERCISE_RADIO_LIST,
        //             children: []
        //         }
        //
        //         Transforms.wrapNodes(editor, element, {at: path});
        //     }
        //
        //     if ((parent as unknown as IBaseElement).type !== ElementTypes.EXERCISE_RADIO_LIST) {
        //         Transforms.liftNodes(editor, {at: path});
        //     }
        // }

        // Удалим id у всех элементов, не EXERCISE_RADIO_LIST и EXERCISE_RADIO_LIST_ITEM
        if (
            Element.isElement(node)
            && node.type !== ElementTypes.EXERCISE_RADIO_LIST
            && node.type !== ElementTypes.EXERCISE_RADIO_LIST_ITEM
        ) {
            if ((node as { id?: string }).id !== undefined) {
                Transforms.setNodes<IRadioListItem>(editor, {id: undefined}, {at: path})
            }

            if ((node as { checked?: boolean }).checked !== undefined) {
                Transforms.setNodes<IRadioListItem>(editor, {checked: undefined}, {at: path})
            }
        }

        // Проверим, чтобы в EXERCISE_RADIO_LIST не существовало дочерних элементов кроме EXERCISE_RADIO_LIST_ITEM
        if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_RADIO_LIST) {
            for (const [child, childPath] of Array.from(Node.children(editor, path))) {
                if (
                    Element.isElement(child)
                    && !editor.isInline(child)
                    && child.type !== ElementTypes.EXERCISE_RADIO_LIST_ITEM
                ) {
                    Transforms.liftNodes(
                        editor,
                        // {
                        //     type: ElementTypes.EXERCISE_RADIO_LIST_ITEM
                        // },
                        {
                            at: childPath
                        }
                    );
                }
            }
        }


        // Проверим, чтобы в EXERCISE_RADIO_LIST_ITEM не существовало дочерних элементов
        // if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_RADIO_LIST_ITEM) {
        //     for (const [child, childPath] of Array.from(Node.children(editor, path))) {
        //         debugger;
        //         if (
        //             Element.isElement(child)
        //             && !editor.isInline(child)
        //         ) {
        //             Transforms.unwrapNodes(editor, {at: childPath});
        //         }
        //     }
        // }


        // Проследим, чтобы EXERCISE_RADIO_LIST всегда имел хотя бы один
        // выбранный верным вариант ответа + все EXERCISE_RADIO_LIST_ITEM имели уникальный ID
        if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_RADIO_LIST) {
            const idList = new Set();
            let firstItem: NodeEntry<IRadioListItem> | null = null;
            const checkedItems: NodeEntry<IRadioListItem>[] = [];
            let checkCandidates: NodeEntry<IRadioListItem>[] = [];

            for (const [child, childPath] of Array.from(Node.children(editor, path))) {
                const childElement = (child as IRadioListItem);

                if (
                    Element.isElement(childElement)
                    && !editor.isInline(childElement)
                    && (childElement.type === ElementTypes.EXERCISE_RADIO_LIST_ITEM)
                ) {

                    if (childElement.id === undefined) {
                        const newId = ExerciseIdGenerator.generateId();
                        Transforms.setNodes<IRadioListItem>(editor, {id: newId}, {at: childPath});
                        idList.add(newId);
                    } else {
                        // Если id есть - проверим, чтобы он был уникален
                        if (idList.has(childElement.id)) {
                            const newId = ExerciseIdGenerator.generateId();
                            Transforms.setNodes<IRadioListItem>(editor, {id: newId}, {at: childPath});
                            idList.add(newId);
                        } else {
                            idList.add(childElement.id);
                        }
                    }

                    if (firstItem === null) {
                        firstItem = [childElement, childPath];
                    }

                    if ((childElement as IRadioListItem).checked) {
                        checkedItems.push([childElement, childPath]);
                    }

                    if (childElement.needCheck !== undefined) {
                        checkCandidates.push([childElement, childPath]);
                    }
                }
            }

            if (checkCandidates.length > 1) {
                checkCandidates = [checkCandidates[0]];
            }

            if (checkCandidates.length > 0) {
                // Если есть кандидат быть выбранным, включаем его, остальных отключаем
                for (let index = 0; index < checkedItems.length; index++) {
                    const [, itemPath] = checkedItems[index];
                    Transforms.setNodes<IRadioListItem>(editor, {checked: false}, {at: itemPath})
                }

                const [, candidatePath] = checkCandidates[0];
                Transforms.setNodes<IRadioListItem>(editor, {checked: true, needCheck: undefined}, {at: candidatePath})
            } else if (checkedItems.length === 1) {
                // Выбран один элемент, кандидатов на выбор нет. Ничего не делаем
            } else if (checkedItems.length === 0) {
                // Нет выбранных элемнетов. Кандидатов на выбор нет. Выбираем первый элемент.
                // Если первый элемент существует.
                if (firstItem !== null) {
                    const [, firstItemPath] = firstItem;
                    Transforms.setNodes<IRadioListItem>(editor, {checked: true}, {at: firstItemPath})
                }
            } else if (checkedItems.length > 0) {
                // Если выбранных элементов стало больше одного, оставим выбранным первый

                for (let index = 1; index < checkedItems.length; index++) {
                    const [, itemPath] = checkedItems[index];
                    Transforms.setNodes<IRadioListItem>(editor, {checked: false}, {at: itemPath})
                }
            }
        }

        normalizeNode(entry);
    }

    editor.deleteBackward = (...args) => {
        const {selection} = editor

        if (selection && Range.isCollapsed(selection)) {
            const [match] = Array.from(
                Editor.nodes(editor, {
                    match: (node) =>
                        !Editor.isEditor(node) &&
                        Element.isElement(node) &&
                        node.type === ElementTypes.EXERCISE_RADIO_LIST_ITEM,
                })
            );

            if (match) {
                const [, path] = match
                const start = Editor.start(editor, path)

                if (Point.equals(selection.anchor, start)) {
                    const newProperties: Partial<Element> = {
                        type: ElementTypes.PARAGRAPH,
                    }

                    Transforms.setNodes(
                        editor,
                        newProperties,
                        {
                            at: path
                        }
                    );

                    // Transforms.liftNodes(
                    //     editor,
                    //     {
                    //         at: path
                    //     }
                    // );

                    return
                }
            }
        }

        deleteBackward(...args)
    }

    return editor
}