import {action} from "typesafe-actions";

import {AppActionTypes, AppInitializationStateTypes} from "./type";
import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";
import {PingResultDto} from "../../components/WsApiClient/ApiDto/Response/Ping/PingResultDto";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";

export const startAppInitialization = () => {
    return action(AppActionTypes.START_APP_INITIALIZATION);
};

export const processStartupGetParameters = () => {
    return action(AppActionTypes.PROCESS_STARTUP_GET_PARAMETERS);
};

export const sendDeviceInfo = () => {
    return action(AppActionTypes.SEND_DEVICE_INFO);
};

export const setAppInitializationState = (newState: AppInitializationStateTypes) => {
    return action(AppActionTypes.SET_APP_INITIALIZATION_STATE, newState);
};

export const setWsConnectionStatus = (newStatus: WsConnectionStatusEnum) => {
    return action(AppActionTypes.SET_WS_CONNECTION_STATUS, newStatus);
};

export const setInFatalErrorState = (value: boolean) => {
    return action(AppActionTypes.SET_IN_FATAL_ERROR_STATE, value);
};

export const processPingResult = (value: ResponseActionCreatorPayload<ResponseBaseDto<PingResultDto>, null>) => {
    return action(AppActionTypes.PROCESS_PING_RESULT, value);
};

export const setLastPingDate = (value: string) => {
    return action(AppActionTypes.SET_LAST_PING_DATE, value);
};

export const setPingTimeSuspendedValue = (value: boolean) => {
    return action(AppActionTypes.SET_PING_TIME_SUSPENDED_VALUE, value);
};

export const startSimulateServerErrors = (errorCode: number) => {
    return action(AppActionTypes.START_SIMULATE_SERVER_ERRORS, errorCode);
};

export const stopSimulateServerErrors = () => {
    return action(AppActionTypes.STOP_SIMULATE_SERVER_ERRORS);
};

export const setWsConnectionRequired = (value: boolean) => {
    return action(AppActionTypes.SET_WS_CONNECTION_REQUIRED, value);
};

export const incWsReconnectNum = () => {
    return action(AppActionTypes.SET_INCREMENT_WS_RECONNECT_COUNT);
};

export const setWsDisconnectAlertImmediately = () => {
    return action(AppActionTypes.SET_WS_DISCONNECT_ALERT_IMMEDIATELY, true);
};

export const zeroWsReconnectCount = () => {
    return action(AppActionTypes.SET_WS_RECONNECT_COUNT_TO_ZERO);
};

export const reportLocationPosition = (location: string) => {
    return action(AppActionTypes.REPORT_LOCATION_POSITION, location);
};

export const setWebRtcSupported = (value: boolean) => {
    return action(AppActionTypes.SET_WEB_RTC_SUPPORTED, value);
};

export const setCameraRequestAccessNow = (value: boolean) => {
    return action(AppActionTypes.SET_CAMERA_REQUEST_ACCESS_NOW, value);
}

export const setCameraAllowedInSession = (value: boolean | null) => {
    return action(AppActionTypes.SET_CAMERA_ALLOWED_IN_SESSION, value);
}

export const setRouteLeaveConfirmMessage = (value: string | null) => {
    return action(AppActionTypes.SET_ROUTE_LEAVE_CONFIRM_MESSAGE, value);
}

export const setInitialCustomizerValue = (value: boolean) => {
    return action(AppActionTypes.SET_NEED_INITIAL_CUSTOMIZER, value);
}

export const newWindowOpened = (newWindowUserId: string) => {
    return action(AppActionTypes.NEW_WINDOW_OPENED, newWindowUserId);
}

export const logoutInAnotherWindow = (newWindowUserId: string) => {
    return action(AppActionTypes.LOGOUT_IN_ANOTHER_WINDOW, newWindowUserId);
}

export const collectUtm = () => {
    return action(AppActionTypes.COLLECT_UTM);
}

export const rebootApp = () => {
    return action(AppActionTypes.REBOOT_APP);
};
