import React, {useMemo} from 'react';
import styled from "styled-components";
import {CustomSortableItemComponent} from "./index";
import classNames from "classnames";
import {SlideIndexIconForTeacher, TypesEnum} from "../../../../../../components/Ui/Elements/SlideIndexForTeacher";
import {useSelector} from "react-redux";
import {selectedSchoolActualParams, selectedSchoolActualParamsCount} from "../../../../../../../store/user/selector";

const ItemStyled = styled.div`
  font-size: 16px;
  padding: 0 0 0 5px;
`;

const SlideItemWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
`;

const SlideNumber = styled.span`
  vertical-align: middle;
  display: inline-block;
  min-width: 25px;
  max-width: 25px;
  text-align: right;
  margin-right: 5px;
  padding-right: 4px;
  color: ${({theme}) => theme.colors.textPrimary};

  &.with-icon {
    padding-right: 0;
  }
`;

const SlideName = styled.span`
  flex-grow: 1;
  color: ${({theme}) => theme.colors.textPrimary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  &.align-center {
    text-align: center;
  }
`;

const SlideActualParamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SlideActualParamName = styled.div`
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  color: ${({theme}) => theme.colors.textPrimary};
  width: 18px;
  border-radius: 15px;
  opacity: 0.4;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
  font-family: Arial, monospace; 
`;

interface SectionItemProps {
    item: CustomSortableItemComponent;
    index: number;
    onSelect: () => void;
    selected: boolean;
}

export const SlideItem: React.FC<SectionItemProps> = (props) => {
    const actualParams = useSelector(selectedSchoolActualParams);
    const actualParamsCount = useSelector(selectedSchoolActualParamsCount);

    const actualParamName = useMemo<string>(() => {
        let actualParamNum = props.item.item.actualParamNum;
        let unknownPlaceholder = "n";

        if (props.item.item.actualParamNum > actualParamsCount) {
            actualParamNum = 1;
        }

        if (actualParams === null) {
            return unknownPlaceholder;
        }

        const getCharFromString = (str: string): string => {
            return str.trim().substring(0, 1).toUpperCase();
        }

        switch (actualParamNum) {
            case 1: {
                return getCharFromString(actualParams.first);
            }
            case 2: {
                return (actualParams.second) ? getCharFromString(actualParams.second) : unknownPlaceholder;
            }
            case 3: {
                return (actualParams.third) ? getCharFromString(actualParams.third) : unknownPlaceholder;
            }
            case 4: {
                return (actualParams.fourth) ? getCharFromString(actualParams.fourth) : unknownPlaceholder;
            }
            case 5: {
                return (actualParams.fifth) ? getCharFromString(actualParams.fifth) : unknownPlaceholder;
            }
        }
        return unknownPlaceholder;
    }, [actualParams, actualParamsCount, props.item.item.actualParamNum]);

    const slideNumber = () => {
        if ((!props.item.item.isAdditional) && (!props.item.item.forHomework)) {
            return <SlideNumber>{props.item.requiredIndex.toString(10)}&nbsp;</SlideNumber>;
        }

        if (props.item.item.forHomework) {
            return <SlideNumber className={"with-icon"}><SlideIndexIconForTeacher
                type={TypesEnum.HOMEWORK_ICON}/></SlideNumber>;
        }

        return <SlideNumber className={"with-icon"}><SlideIndexIconForTeacher
            type={TypesEnum.ADDITIONAL_ICON}/></SlideNumber>;
    }

    return <ItemStyled onClick={() => props.onSelect()}
                       className={classNames("item-label", props.selected && "selected")}>
        <SlideItemWrapper>
            {slideNumber()}
            <SlideName title={props.item.item.name}>{props.item.item.name}</SlideName>
            <SlideActualParamNameWrapper>
                <SlideActualParamName>{actualParamName}</SlideActualParamName>
            </SlideActualParamNameWrapper>
        </SlideItemWrapper>
    </ItemStyled>
}