import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonMaterialsActionTypes} from "../../store/lessonMaterials/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ApplicationState} from "../../store";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {DtoSENeedSyncSlideList} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSyncSlideList";
import {loadLessonMaterialsList} from "../../store/lessonMaterials/actions";

/**
 * Обработка сообщения о необходимости "тихо" обновить список слайдов
 */
export function* watchHandleNeedSyncSlideList() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSENeedSyncSlideList>, null>>>(
        LessonMaterialsActionTypes.HANDLE_SE_NEED_SYNC_SLIDE_LIST,
        handleNeedSyncSlideList
    );
}

const currentLessonMaterialsLessonId = ({lessonMaterials}: ApplicationState) => lessonMaterials.lessonId;
const currentLessonMaterialsIsHomework = ({lessonMaterials}: ApplicationState) => lessonMaterials.homeworkMaterials;

function* handleNeedSyncSlideList(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSENeedSyncSlideList>, null>>) {
    const messageRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentLessonMaterialsLessonId)) as string | null;
    const homeworkIsActive = (yield select(currentLessonMaterialsIsHomework)) as boolean;

    if (activeRoomId !== messageRoomId) {
        return;
    }

    yield put(loadLessonMaterialsList(messageRoomId, homeworkIsActive, true));
}
