import axios, {AxiosInstance} from "axios";
import {BaseResponseDto} from "./ApiDto/Response/BaseResponseDto";
import {ClassType} from "../../types/ClassType";
import {ModelValidator} from "../ModelValidator/ModelValidator";
import {ResponseStatusEnum} from "./Enums/ResponseStatusEnum";
import {ServerErrorException} from "./Exception/ServerErrorException";
import {Exception} from "./Exception/Exception";
import {IncorrectResponse} from "./Exception/IncorrectResponse";
import {FieldValidationException} from "../ModelValidator/FieldValidationException";
import {ValidationErrorException} from "./Exception/ValidationErrorException";
import {UnknownMethodException} from "./Exception/UnknownMethodException";
import {InvalidTokenException} from "./Exception/InvalidTokenException";
import {PermissionDeniedException} from "./Exception/PermissionDeniedException";
import {IpBlockedException} from "./Exception/IpBlockedException";
import {DatabaseBlockedException} from "./Exception/DatabaseBlockedException";
import {RecordIsReadOnlyException} from "./Exception/RecordIsReadOnlyException";
import {NotEnoughMoneyException} from "./Exception/NotEnoughMoneyException";
import {MethodNotSupportedAppsException} from "./Exception/MethodNotSupportedAppsException";
import {IStonlineApiClient} from "./IStonlineApiClient";
import {StudentGroupsListModeEnum} from "./Enums/StudentGroupsListModeEnum";
import {DtoResponseWithPagination} from "./ApiDto/Response/Common/DtoResponseWithPagination";
import {DtoGroupListItem} from "./ApiDto/Response/StudentGroups/DtoGroupListItem";
import {UnknownModuleException} from "./Exception/UnknownModuleException";
import {DtoGroupGetByIdResponse} from "./ApiDto/Response/StudentGroups/DtoGroupGetByIdResponse";
import {DtoGroupNextLessonInfo} from "./ApiDto/Response/StudentGroups/DtoGroupNextLessonInfo";
import {DtoGroupCompiledSchedule} from "./ApiDto/Response/StudentGroups/DtoGroupCompiledSchedule";
import {DtoStudentBase} from "./ApiDto/Response/Student/DtoStudentBase";
import {DtoLessonListItem} from "./ApiDto/Response/Lesson/DtoLessonListItem";
import {DtoLessonCreateResponse} from "./ApiDto/Response/Lesson/DtoLessonCreateResponse";
import {DtoStudentInLessonListItem} from "./ApiDto/Response/StudentInLesson/DtoStudentInLessonListItem";
import {DtoLessonDataResponse} from "./ApiDto/Response/Lesson/DtoLessonDataResponse";
import {DtoTeacherSearchResponseItem} from "./ApiDto/Response/Teacher/DtoTeacherSearchResponseItem";
import {DtoPlanLessonsForDateResponse} from "./ApiDto/Response/StudentGroups/DtoPlanLessonsForDateResponse";
import {RequestedDataNotFoundException} from "./Exception/RequestedDataNotFoundException";
import {DtoStudentListItem} from "./ApiDto/Response/Student/DtoStudentListItem";
import {DtoStudentGeneralInfo} from "./ApiDto/Response/Student/DtoStudentGeneralInfo";
import {DtoTimezoneSearchResponseItem} from "./ApiDto/Response/StaticData/DtoTimezoneSearchResponseItem";
import {DtoGetPaymentsByStudentId} from "./ApiDto/Response/Payments/DtoGetPaymentsByStudentId";
import {NoConnection} from "./Exception/NoConnection";
import {DtoAgreementItem} from "./ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import {DtoCourseListItem} from "./ApiDto/Response/Courses/DtoCourseListItem";
import {DtoTrainingProgramListItem} from "./ApiDto/Response/TrainingProgram/DtoTrainingProgramListItem";
import {DtoGroupSchedule} from "./ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {
    CreateAgreementRequestData
} from "../../views/pages/teacher/student/create-agreement-page/CreateAgreementRequestData";
import {DtoCreateAgreementResponse} from "./ApiDto/Response/StudentAgreements/DtoCreateAgreementResponse";
import {DtoSchoolsSummaryInfo} from "./ApiDto/Response/SchoolsManagement/DtoSchoolsSummaryInfo";

interface RequestItem {
    method: string;
    data: any;
    token?: string;
}

export class StonlineApiClient implements IStonlineApiClient {
    /**
     * Точка входа в API Stonline
     * @private
     */
    protected apiEntryPoint: string;

    protected axios: AxiosInstance | null;

    constructor(apiEntryPoint: string) {
        this.apiEntryPoint = apiEntryPoint;

        this.axios = axios.create();
    }

    /**
     * @inheritDoc
     */
    async createStudentProfileDraft(token: string, abortController: AbortController): Promise<BaseResponseDto<DtoStudentGeneralInfo>> {
        const result = await this.execRequest<[BaseResponseDto<DtoStudentGeneralInfo>]>(
            token,
            [
                {
                    method: 'Students/CreateStudentProfileDraft',
                    data: {}
                }
            ],
            [
                DtoStudentGeneralInfo
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async deleteStudentProfile(token: string, studentId: number): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Students/DeleteProfile',
                    data: {
                        id: studentId
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getStudentsList(token: string, searchString: string, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoStudentListItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoStudentListItem>>]>(
            token,
            [
                {
                    method: 'Students/GetStudentsList',
                    data: {
                        searchString,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoStudentListItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getStudentProfileGeneralInfo(token: string, studentId: number, abortController: AbortController): Promise<BaseResponseDto<DtoStudentGeneralInfo>> {
        const result = await this.execRequest<[BaseResponseDto<DtoStudentGeneralInfo>]>(
            token,
            [
                {
                    method: 'Students/GetById',
                    data: {
                        studentId
                    }
                }
            ],
            [
                DtoStudentGeneralInfo
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async saveStudentProfile(token: string, dto: DtoStudentGeneralInfo, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Students/SaveProfile',
                    data: {
                        id: dto.id,
                        groupmentId: dto.studentGroupmentId,
                        longName: dto.longName,
                        gender: dto.gender,
                        reportResultsOnMail: dto.reportResultsOnMail,
                        allowExcelReportLink: dto.allowExcelReportLink,
                        enablePersonalCabinet: dto.enablePersonalCabinet,
                        enablePayOnline: dto.enablePayOnline,
                        timezoneName: dto.timezoneName,
                        parentName: dto.parentInContract,
                        dateBorn: dto.dateBorn,
                        email: dto.email,
                        smsPhone: dto.smsPhone,
                        phone: dto.phone,
                        comment: dto.comment
                    }
                }
            ],
            [],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getAdditionalAgreementsListByStudent(token: string, studentId: number, addActive: boolean, addInactive: boolean, abortController?: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoAgreementItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoAgreementItem>>]>(
            token,
            [
                {
                    method: 'StudentAgreements/GetAdditionalList',
                    data: {
                        studentId,
                        addActive,
                        addInactive
                    }
                }
            ],
            [
                [DtoAgreementItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getStudentGroupsList(token: string, searchMode: StudentGroupsListModeEnum, searchString: string, date: string, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoGroupListItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoGroupListItem>>]>(
            token,
            [
                {
                    method: 'StudentGroups/GetStudentGroupsListV2',
                    data: {
                        searchMode,
                        searchString,
                        date,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoGroupListItem]
            ],
            abortController
        );

        return result[0];
    }

    async getGroupBaseInfo(token: string, groupId: number, dateTime: string, date: string, lessonsPageLimit: number, abortController: AbortController): Promise<[DtoGroupGetByIdResponse, DtoGroupNextLessonInfo | null, DtoStudentBase[], DtoGroupCompiledSchedule, DtoResponseWithPagination<DtoLessonListItem>]> {
        const result = await this.execRequest<[BaseResponseDto<DtoGroupGetByIdResponse>, BaseResponseDto<DtoResponseWithPagination<DtoGroupNextLessonInfo>>, BaseResponseDto<DtoResponseWithPagination<DtoStudentBase>>, BaseResponseDto<DtoGroupCompiledSchedule>, BaseResponseDto<DtoResponseWithPagination<DtoLessonListItem>>]>(
            token,
            [
                {
                    method: 'StudentGroups/GetById',
                    data: {
                        groupId: groupId
                    }
                },
                {
                    method: 'StudentGroups/GetNextLessonInfo',
                    data: {
                        groupId: groupId,
                        dateTime: dateTime
                    }
                },
                {
                    method: 'StudentGroups/StudentsInGroup',
                    data: {
                        groupId: groupId
                    }
                },
                {
                    method: 'StudentGroups/OneWeekSchedule',
                    data: {
                        sGroupId: groupId,
                        date: date
                    }
                },
                {
                    method: 'Lessons/GetListByGroupV2',
                    data: {
                        groupId: groupId,
                        page: 1,
                        limit: lessonsPageLimit
                    }
                }
            ],
            [
                DtoGroupGetByIdResponse,
                [DtoGroupNextLessonInfo], //DtoGroupNextLessonInfo
                [DtoStudentBase], //DtoStudentBase
                DtoGroupCompiledSchedule,
                [DtoLessonListItem]
            ],
            abortController
        );

        return [
            result[0].result,
            ((result[1].result) && (result[1].result.items) && (result[1].result.items.length > 0)) ? result[1].result.items[0] : null,
            ((result[2].result) && (result[2].result.items) && (result[2].result.items.length > 0)) ? result[2].result.items : [],
            result[3].result,
            result[4].result
        ];
    }

    /**
     * @inheritDoc
     */
    async getLessonsList(token: string, groupId: number, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoLessonListItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoLessonListItem>>]>(
            token,
            [
                {
                    method: 'Lessons/GetListByGroupV2',
                    data: {
                        groupId,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoLessonListItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getPlanLessonsForDate(token: string, groupId: number, date: string, abortController?: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoPlanLessonsForDateResponse>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoPlanLessonsForDateResponse>>]>(
            token,
            [
                {
                    method: 'StudentGroups/PlanLessonsForDate',
                    data: {
                        groupId,
                        lessonDate: date
                    }
                }
            ],
            [
                [DtoPlanLessonsForDateResponse]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async createLessonDraft(token: string, groupId: number, teacherId: number, lessonDate: string, timeStart: string, timeEnd: string, abortController?: AbortController): Promise<BaseResponseDto<DtoLessonCreateResponse>> {
        const result = await this.execRequest<[BaseResponseDto<DtoLessonCreateResponse>]>(
            token,
            [
                {
                    method: 'Lessons/CreateNewLessonWithStudents',
                    data: {
                        sGroupId: groupId,
                        teacherId: teacherId,
                        lessonDate: lessonDate,
                        timeStart: timeStart,
                        timeEnd: timeEnd,
                        isDraft: 1,
                        ignoreAgreementStartDate: 1
                    }
                }
            ],
            [DtoLessonCreateResponse],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getLessonData(token: string, lessonId: number, abortController?: AbortController): Promise<[DtoLessonDataResponse, DtoStudentInLessonListItem[]]> {
        const result = await this.execRequest<[BaseResponseDto<DtoLessonDataResponse>, BaseResponseDto<DtoResponseWithPagination<DtoStudentInLessonListItem>>]>(
            token,
            [
                {
                    method: 'Lessons/GetLessonData',
                    data: {
                        lessonId: lessonId
                    }
                },
                {
                    method: 'StudentInLesson/GetListByLessonV2',
                    data: {
                        lessonId: lessonId,
                        addFullCommentText: 1
                    }
                }
            ],
            [
                DtoLessonDataResponse,
                [DtoStudentInLessonListItem]
            ],
            abortController
        );

        return [
            result[0].result,
            ((result[1].result) && (result[1].result.items) && (result[1].result.items.length > 0)) ? result[1].result.items : [],
        ];
    }

    /**
     * @inheritDoc
     */
    async updateLessonData(token: string, lessonId: number, teacherId: number, lessonDate: string, timeStart: string, timeEnd: string, homeTask: string, comment: string, isFake: boolean, isDraft: boolean, abortController?: AbortController): Promise<[BaseResponseDto<null>]> {
        return this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Lessons/UpdateLessonData',
                    data: {
                        lessonId,
                        teacherId,
                        lessonDate,
                        timeStart,
                        timeEnd,
                        homeTask,
                        comment,
                        isFake,
                        isDraft
                    }
                }
            ],
            [],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    deleteLesson(token: string, lessonId: number, abortController?: AbortController): Promise<[BaseResponseDto<null>]> {
        return this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Lessons/DeleteLessonData',
                    data: {
                        lessonId
                    }
                }
            ],
            [],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    async searchTeacher(token: string, searchString: string, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoTeacherSearchResponseItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoTeacherSearchResponseItem>>]>(
            token,
            [
                {
                    method: 'Teachers/GetTeachersSimpleListV2',
                    data: {
                        searchString,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoTeacherSearchResponseItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async updateStudentInLesson(token: string, studentInLessonId: number, param1Value: number, param2Value: number | null, param3Value: number | null, param4Value: number | null, param5Value: number | null, teacherComment: string, absentValue: number, abortController?: AbortController): Promise<void> {
        const requestObject: { [id: string]: any } = {
            sILId: studentInLessonId,
            param1: param1Value,
            comment: teacherComment,
            absent: absentValue
        }

        if (param2Value !== null) {
            requestObject['param2'] = param2Value;
        }

        if (param3Value !== null) {
            requestObject['param3'] = param3Value;
        }

        if (param4Value !== null) {
            requestObject['param4'] = param4Value;
        }

        if (param5Value !== null) {
            requestObject['param5'] = param5Value;
        }

        await this.execRequest<[BaseResponseDto<void>]>(
            token,
            [
                {
                    method: 'StudentInLesson/UpdateStudentInLesson',
                    data: requestObject
                }
            ],
            [],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    async getStudentsInGroup(token: string, groupId: number): Promise<BaseResponseDto<DtoResponseWithPagination<DtoStudentBase>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoStudentBase>>]>(
            token,
            [
                {
                    method: 'StudentGroups/StudentsInGroup',
                    data: {
                        groupId: groupId
                    }
                },
            ],
            [
                [DtoStudentBase]
            ]
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async searchTimezone(token: string, searchString: string, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoTimezoneSearchResponseItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoTimezoneSearchResponseItem>>]>(
            token,
            [
                {
                    method: 'StaticData/Timezones',
                    data: {
                        searchString,
                        pageNum: page,
                        limit
                    }
                },
            ],
            [
                [DtoTimezoneSearchResponseItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getPaymentsByStudentId(token: string, studentId: number, moneyDestinations: number[], dateStart: string | null, dateEnd: string | null, abortController?: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoGetPaymentsByStudentId>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoGetPaymentsByStudentId>>]>(
            token,
            [
                {
                    method: 'Payments/GetPaymentsByStudentIdV2',
                    data: {
                        studentId,
                        moneyDestinations: moneyDestinations.join(','),
                        dateStart,
                        dateEnd,
                        page: 1,
                        limit: 5000,
                        sortByDateDesc: true
                    }
                },
            ],
            [
                [DtoGetPaymentsByStudentId]
            ]
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async addStudentPaymentOperation(token: string, studentId: number, sum: number, paymentDate: string, comment: string | null, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Payments/AddStudentOperation',
                    data: {
                        studentId,
                        sum,
                        paymentDate,
                        comment
                    }
                },
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async editStudentPaymentOperation(token: string, operationId: number, sum: number, paymentDate: string, comment: string | null, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Payments/EditStudentOperation',
                    data: {
                        operationId,
                        sum,
                        paymentDate,
                        comment
                    }
                },
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async deleteStudentPaymentOperation(token: string, operationId: number, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'Payments/DeleteStudentOperation',
                    data: {
                        operationId
                    }
                },
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async coursesGetList(token: string, searchString: string, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoCourseListItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoCourseListItem>>]>(
            token,
            [
                {
                    method: 'Courses/GetList',
                    data: {
                        searchString,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoCourseListItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async trainingProgramsInCourse(token: string, courseId: number, searchString: string, page: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoResponseWithPagination<DtoTrainingProgramListItem>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoTrainingProgramListItem>>]>(
            token,
            [
                {
                    method: 'TrainingPrograms/GetByCourse',
                    data: {
                        courseId,
                        searchString,
                        page,
                        limit
                    }
                }
            ],
            [
                [DtoTrainingProgramListItem]
            ],
            abortController
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async setTrainingProgramToGroup(token: string, groupId: number, courseName: string, trainingProgramName: string, courseId: number | null, trainingProgramId: number | null): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentGroups/SetTrainingProgramToGroup',
                    data: {
                        groupId,
                        courseName,
                        trainingProgramName,
                        courseId,
                        trainingProgramId
                    }
                }
            ],
            []
        );

        return result[0];
    }

    async crateGroupSchedule(token: string, groupId: number, scheduleDto: DtoGroupSchedule, startFromDate: boolean, startDate: string | null): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentGroupsSchedules/CreateNewSchedule',
                    data: {
                        groupId,
                        createMode: (startFromDate) ? 1 : 2,
                        startDate: startDate,
                        mondayTimeStart: scheduleDto.mondayTimeStart,
                        mondayTimeEnd: scheduleDto.mondayTimeEnd,
                        mondayBreakTime: 0,
                        tuesdayTimeStart: scheduleDto.tuesdayTimeStart,
                        tuesdayTimeEnd: scheduleDto.tuesdayTimeEnd,
                        tuesdayBreakTime: 0,
                        wednesdayTimeStart: scheduleDto.wednesdayTimeStart,
                        wednesdayTimeEnd: scheduleDto.wednesdayTimeEnd,
                        wednesdayBreakTime: 0,
                        thursdayTimeStart: scheduleDto.thursdayTimeStart,
                        thursdayTimeEnd: scheduleDto.thursdayTimeEnd,
                        thursdayBreakTime: 0,
                        fridayTimeStart: scheduleDto.fridayTimeStart,
                        fridayTimeEnd: scheduleDto.fridayTimeEnd,
                        fridayBreakTime: 0,
                        saturdayTimeStart: scheduleDto.saturdayTimeStart,
                        saturdayTimeEnd: scheduleDto.saturdayTimeEnd,
                        saturdayBreakTime: 0,
                        sundayTimeStart: scheduleDto.sundayTimeStart,
                        sundayTimeEnd: scheduleDto.sundayTimeEnd,
                        sundayBreakTime: 0,
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async createNewCostRegisterRecord(token: string, agreementId: number, newValue: number, startFromDate: boolean, startDate: string | null): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentAgreements/CreateCostRegisterRecord',
                    data: {
                        agreementId,
                        createMode: (startFromDate) ? 1 : 2,
                        costMode: 3,
                        startDate: (startDate) ? startDate + ' 00:00:00' : null,
                        costWithoutDiscount: newValue,
                        discountPercent: 0,
                        cost: newValue,
                        comment: ''
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async closeAgreement(token: string, agreementId: number): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentAgreements/CloseAgreement',
                    data: {
                        agreementId,
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async restoreAgreement(token: string, agreementId: number): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentAgreements/RestoreAgreement',
                    data: {
                        agreementId,
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async deleteAgreement(token: string, agreementId: number): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'StudentAgreements/DeleteAgreement',
                    data: {
                        agreementId,
                        allowAutoCloseGroup: true
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async createAgreement(token: string, studentId: number, data: CreateAgreementRequestData): Promise<BaseResponseDto<DtoCreateAgreementResponse>> {
        const result = await this.execRequest<[BaseResponseDto<DtoCreateAgreementResponse>]>(
            token,
            [
                {
                    method: 'StudentAgreements/AddCourse',
                    data: {
                        studentId: studentId,
                        teamMode: data.teamMode,
                        teamId: data.teamId,
                        teamName: data.teamName,
                        courseId: data.courseId,
                        courseName: data.courseName,
                        tpId: data.tpId,
                        tpName: data.tpName,
                        lessonCost: data.lessonCost,
                        startDate: data.startDate,
                        mondayTimeStart: data.schedule.mondayTimeStart,
                        mondayTimeEnd: data.schedule.mondayTimeEnd,
                        mondayBreakTime: 0,
                        tuesdayTimeStart: data.schedule.tuesdayTimeStart,
                        tuesdayTimeEnd: data.schedule.tuesdayTimeEnd,
                        tuesdayBreakTime: 0,
                        wednesdayTimeStart: data.schedule.wednesdayTimeStart,
                        wednesdayTimeEnd: data.schedule.wednesdayTimeEnd,
                        wednesdayBreakTime: 0,
                        thursdayTimeStart: data.schedule.thursdayTimeStart,
                        thursdayTimeEnd: data.schedule.thursdayTimeEnd,
                        thursdayBreakTime: 0,
                        fridayTimeStart: data.schedule.fridayTimeStart,
                        fridayTimeEnd: data.schedule.fridayTimeEnd,
                        fridayBreakTime: 0,
                        saturdayTimeStart: data.schedule.saturdayTimeStart,
                        saturdayTimeEnd: data.schedule.saturdayTimeEnd,
                        saturdayBreakTime: 0,
                        sundayTimeStart: data.schedule.sundayTimeStart,
                        sundayTimeEnd: data.schedule.sundayTimeEnd,
                        sundayBreakTime: 0,
                    }
                }
            ],
            [
                DtoCreateAgreementResponse
            ]
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async setupWizardSetData(token: string, userName: string, isTutor: boolean, schoolName: string, timezoneName: string): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'SetupWizard/SetData',
                    data: {
                        timezoneName: timezoneName,
                        userLongName: userName,
                        isTutor: isTutor,
                        name: schoolName
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async initActualParamsList(token: string, fistParamName: string, secondParamName: string | null, thirdParamName: string | null, fourthParamName: string | null, fifthParamName: string | null): Promise<BaseResponseDto<null>> {
        const result = await this.execRequest<[BaseResponseDto<null>]>(
            token,
            [
                {
                    method: 'LessonsMarksList/InitActualParamsList',
                    data: {
                        fistParamName: fistParamName,
                        fistParamShortName: fistParamName.substring(0, 5),
                        secondParamName: secondParamName,
                        secondParamShortName: (secondParamName) ? secondParamName.substring(0, 5) : null,
                        thirdParamName: thirdParamName,
                        thirdParamShortName: (thirdParamName) ? thirdParamName.substring(0, 5) : null,
                        fourthParamName: fourthParamName,
                        fourthParamShortName: (fourthParamName) ? fourthParamName.substring(0, 5) : null,
                        fifthParamName: fifthParamName,
                        fifthParamShortName: (fifthParamName) ? fifthParamName.substring(0, 5) : null,
                    }
                }
            ],
            []
        );

        return result[0];
    }

    /**
     * @inheritDoc
     */
    async getSchoolsSummary(token: string): Promise<BaseResponseDto<DtoResponseWithPagination<DtoSchoolsSummaryInfo>>> {
        const result = await this.execRequest<[BaseResponseDto<DtoResponseWithPagination<DtoSchoolsSummaryInfo>>]>(
            token,
            [
                {
                    method: 'SchoolsManagement/GetSchoolsSummary',
                    data: {}
                }
            ],
            [
                [DtoSchoolsSummaryInfo]
            ]
        );

        return result[0];
    }

    async execRequest<T extends Array<BaseResponseDto<any>>>(token: string, requestItems: RequestItem[], types: Array<ClassType<any> | Array<ClassType<any>>>, abortController?: AbortController): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            if (token) {
                requestItems[0].token = token;
            }

            axios.post<string>(this.apiEntryPoint, JSON.stringify(requestItems), {signal: (abortController) ? abortController.signal : undefined})
                .then(async response => {
                    if (!response) {
                        reject(new Exception(
                            `Received empty response model.`
                        ));

                        return;
                    }

                    if (!Array.isArray(response.data)) {
                        reject(new Exception(
                            `Stonline api response is not array`
                        ));

                        return;
                    }

                    const result: BaseResponseDto<any>[] = [];

                    for (let responseIndex = 0; responseIndex < response.data.length; responseIndex++) {
                        const currentResponseItem = response.data[responseIndex];

                        // Валидируем основную часть сообщения
                        let dataAsBaseClassObject: BaseResponseDto<any>;

                        try {
                            dataAsBaseClassObject = await this.transformAndValidateRequestJsonObject(
                                BaseResponseDto,
                                this.convertRawStringToObject(
                                    ((typeof currentResponseItem) === "object")
                                        ? JSON.stringify(currentResponseItem)
                                        : currentResponseItem
                                )
                            );
                        } catch (e) {
                            reject(new IncorrectResponse(
                                (e instanceof Error) ? e.message : "",
                                JSON.stringify(currentResponseItem),
                                JSON.stringify(response)
                            ));

                            return;
                        }

                        // На этом этапе у нас точно есть корректная базовая часть. Проверяем дальше.

                        // Проверяем статус
                        if (dataAsBaseClassObject.resultStatus !== ResponseStatusEnum.SUCCESS) {
                            try {
                                this.throwExceptionByStatus(dataAsBaseClassObject.resultStatus, dataAsBaseClassObject);
                            } catch (e) {
                                reject(e);

                                return;
                            }
                        }

                        // Пытаемся преобразовать поле data в нужный тип.
                        if (types[responseIndex] !== undefined) {
                            try {
                                const type = types[responseIndex];

                                if (Array.isArray(type)) {
                                    // Речь про то, что мы получим DTO пагинации элементов.
                                    // Сначала валидируем DTO пагинации...
                                    dataAsBaseClassObject.result = await this.transformAndValidateRequestJsonObject(
                                        DtoResponseWithPagination,
                                        dataAsBaseClassObject.result
                                    ) as DtoResponseWithPagination<any>;

                                    // ... а затем каждый элемент внутри
                                    const itemsCount = dataAsBaseClassObject.result.items.length;

                                    for (let index = 0; index < itemsCount; index++) {
                                        dataAsBaseClassObject.result.items[index]
                                            = await this.transformAndValidateRequestJsonObject(
                                            type[0],
                                            dataAsBaseClassObject.result.items[index]
                                        );
                                    }
                                } else {
                                    dataAsBaseClassObject.result = await this.transformAndValidateRequestJsonObject(
                                        type,
                                        dataAsBaseClassObject.result
                                    );
                                }
                            } catch (e) {
                                reject(new IncorrectResponse(
                                    (e instanceof Error) ? e.message : "",
                                    JSON.stringify(currentResponseItem),
                                    JSON.stringify(response)
                                ));

                                return;
                            }
                        }

                        result.push(dataAsBaseClassObject);
                    }

                    resolve(result as unknown as T);
                })
                .catch(e => {
                    if (e.message === 'Network Error') {
                        reject(new NoConnection(e.message));

                        return
                    }

                    reject(new Exception(e));

                    return;
                })
        });
    }

    /**
     * Метод генерирует исключение в соответствии со статусом ответа сервера.
     *
     * @param status
     * @param responseModel
     *
     * @protected
     */
    protected throwExceptionByStatus(status: number, responseModel: BaseResponseDto<any>): never {
        switch (status) {
            case ResponseStatusEnum.UNKNOWN_MODULE: {
                throw new UnknownModuleException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.VALIDATION_ERROR: {
                throw new ValidationErrorException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.UNKNOWN_METHOD: {
                throw new UnknownMethodException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.TOKEN_CHECK: {
                throw new InvalidTokenException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.PERMISSION_DENIED: {
                throw new PermissionDeniedException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.IP_BLOCKED: {
                throw new IpBlockedException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.UNKNOWN_ERROR: {
                throw new ServerErrorException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.DATABASE_BLOCKED: {
                throw new DatabaseBlockedException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.ACTION_ERROR_DATA_NOT_FOUND: {
                throw new RequestedDataNotFoundException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.RECORD_IS_READ_ONLY: {
                throw new RecordIsReadOnlyException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.NOT_ENOUGH_MONEY: {
                throw new NotEnoughMoneyException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.METHOD_NOT_SUPPORTED_APPS: {
                throw new MethodNotSupportedAppsException(responseModel.resultStatusText ?? JSON.stringify(responseModel));
            }
            default: {
                throw new ServerErrorException((responseModel.resultStatusText) ?? JSON.stringify(responseModel));
            }
        }
    }

    /**
     * Преобразование JSON строки в некоторый Object
     *
     * @param rawString
     *
     * @protected
     */
    protected convertRawStringToObject(rawString: string): object {
        let object: object | undefined;

        try {
            object = JSON.parse(rawString);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(
                    'Error on handling string, received from server: ' + JSON.stringify(rawString)
                    + '. Error: ' + e.message
                );
            } else {
                throw e;
            }
        }

        return (object === undefined) ? {} : object;
    }

    /**
     * Валидация параметров ответа сервера в соответствии с моделью
     * и конвертация сырого объекта в DTO.
     *
     * @param classType
     * @param jsonRequestData
     */
    protected async transformAndValidateRequestJsonObject<T extends object>(
        classType: ClassType<T>,
        jsonRequestData: object,
    ): Promise<T> {
        try {
            return await ModelValidator
                .validateAndTransformRawRequestData(classType, jsonRequestData);
        } catch (e) {

            if (e instanceof FieldValidationException) {
                throw new Error(
                    'Response validation error: ' + JSON.stringify(e.fieldValidationErrorsList)
                    + ', object: ' + JSON.stringify(jsonRequestData)
                );
            }

            if (e instanceof Error) {
                throw new Error(
                    'Response validation error: ' + e.message
                    + ', object: ' + JSON.stringify(jsonRequestData)
                );
            }

            throw new Error(
                'Response validation error: ' + JSON.stringify(e)
                + ', object: ' + JSON.stringify(jsonRequestData)
            );
        }
    }
}
