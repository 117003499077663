import * as React from "react";
import styled from "styled-components";
import {OrganizationItemStyled} from "./OrganizationItem";
import {ReactComponent as PlusSvg} from "../../../../../components/Ui/Svg/Plus.svg";
import {Trans} from "@lingui/macro";
import {ModalResultType, TmOrganizationModal} from "./modal";
import {
    DtoTmOrganization
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";

const AddOrganizationItem = styled(OrganizationItemStyled)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 15px 5px 10px;
`;

const PlusIcon = styled(PlusSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 40px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CreateNewOrganizationItemProps {
    onItemCreated: (data: DtoTmOrganization) => void
}

export const CreateNewOrganizationItem: React.FC<CreateNewOrganizationItemProps> = (props) => {
    const onResult = (action: ModalResultType, data: DtoTmOrganization | null) => {
        if (data !== null) {
            props.onItemCreated(data);
        }
    }

    return (
        <TmOrganizationModal
            organizationItem={null}
            result={onResult}
            trigger={
                <AddOrganizationItem active={false}>
                    <PlusIcon/>
                    <PlusText><Trans>Добавить организацию</Trans></PlusText>
                </AddOrganizationItem>
            }/>
    );
}