import React, {useContext} from "react";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";
import {ButtonArea} from "../../student/create-agreement-page/ButtonArea";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {IInitialCustomizerPageContext, InitialCustomizerPageContext} from "../InitialCustomizerPageContext";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  row-gap: 40px;
  margin-top: 30px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  
  @media (${({theme}) => theme.media.small}) {
    text-align: left;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @media (${({theme}) => theme.media.small}) {
    justify-content: space-between;
  }
`;

const Image = styled.img`
  box-shadow: 0 4px 50px 0 rgba(25, 121, 255, 0.50);
  width: 50%;
  height: auto;

  @media (${({theme}) => theme.media.small}) {
    height: auto;
    width: 247px;
  }
`;

export const AboutNavigationSection: React.FC = () => {
    const pageContext = useContext<IInitialCustomizerPageContext>(InitialCustomizerPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const onFinish = () => {
        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.ABOUT_NAVIGATION,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    return <div>
        <FormWrapper>
            <PageSubtitleSmallMargin>
                <Trans>На основной панели навигации есть несколько разделов:</Trans>
            </PageSubtitleSmallMargin>
            <ContentWrapper>
                <ImageWrapper>
                    <Image src={'/img/navigation-screen.png'}/>
                </ImageWrapper>
                <TextWrapper>
                    <RegularText>
                        <b><Trans>«Ученики»</Trans></b><br/>
                        <Trans>Это ваша база учеников. Там история взаиморасчётов с учениками и планирование
                            расписания.</Trans>
                    </RegularText>

                    <RegularText>
                        <b><Trans>«Группы и уроки»</Trans></b><br/>
                        <Trans>Здесь всё про конкретные уроки - там мы запускаем онлайн урок и задаём домашнюю
                            работу.</Trans>
                    </RegularText>

                    <RegularText>
                        <b><Trans>«Учебные материалы»</Trans></b><br/>
                        <Trans>Это ваша методическая библиотека - здесь вы формируете ваш электронный учебник, странички
                            которого (слайды) затем применяете на занятиях.</Trans>
                    </RegularText>
                </TextWrapper>
            </ContentWrapper>
            <br/>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}
            nextBtnOnClick={async () => {
                await pageContext.saveData(pageContext.formData);

                onFinish();

                return Promise.resolve();
            }}
        />
    </div>;
}