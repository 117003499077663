import React, {useMemo} from "react";
import styled from "styled-components";
import {MonthAsString} from "../../Ui/Elements/TextPresenter/MonthAsString";
import {PaymentTableItem} from "./PaymentTableItemType";
import {Item} from "./Item";
import {RegularTextCss} from "../../../styles/global-elements";

const TableWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 20px;
  padding: 16px 32px;
`;

const MonthGroupWrapper = styled.div`
  margin-bottom: 24px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 40px;
  }
`;

const MonthGroupTitle = styled.div`
  ${RegularTextCss};

  color: ${({theme}) => theme.colors.textTertiary};
  line-height: 24px;
  text-align: center;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight}px;
`;

const PaymentsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

interface MonthGroup {
    date: string; // key
    monthName: JSX.Element;
    items: Array<PaymentTableItem & { key: string }>;
}

interface PaymentsTableProps {
    items: PaymentTableItem[];
    onItemClick?: (paymentId: number) => void;
}

export const PaymentsTable: React.FC<PaymentsTableProps> = (
    {items, onItemClick}
) => {
    const groupedByMonth = useMemo<Array<MonthGroup>>(() => {
        const monthGroups: MonthGroup[] = [];

        let currentMonthGroupIndex = -1;
        let tempMonthNum = -1;
        const itemsCount = items.length;
        const currentYear = (new Date()).getFullYear();

        for (let i = 0; i < itemsCount; i++) {
            const itemMonthNum = items[i].datePay.getMonth() + 1;

            if (itemMonthNum !== tempMonthNum) {
                const itemYear = items[i].datePay.getFullYear();

                let monthName = <MonthAsString monthNum={itemMonthNum} inGenitiveCase={false}/>;

                if (itemYear !== currentYear) {
                    const itemYearAsString = itemYear.toString(10);

                    monthName = <>
                        <MonthAsString monthNum={itemMonthNum} inGenitiveCase={false}/>&nbsp;
                        <span>'{itemYearAsString.substring(itemYearAsString.length - 2)}</span>
                    </>
                }

                monthGroups.push({
                    date: items[i].datePay.getDate() + '.' + items[i].datePay.getFullYear(),
                    monthName: monthName,
                    items: []
                });

                currentMonthGroupIndex = monthGroups.length - 1;
                tempMonthNum = itemMonthNum;
            }

            monthGroups[currentMonthGroupIndex].items.push({
                ...items[i],
                key: items[i].datePay.toISOString().concat(
                    (items[i].paymentId ?? 0).toString(10)
                )
            });
        }

        return monthGroups;
    }, [items]);


    return <TableWrapper>
        {
            groupedByMonth.map(monthItem => {
                return <MonthGroupWrapper key={monthItem.date}>
                    <MonthGroupTitle>{monthItem.monthName}</MonthGroupTitle>
                    <PaymentsList>
                        {
                            monthItem.items.map(paymentItem => {
                                return <Item item={paymentItem}
                                             key={paymentItem.key}
                                             onItemClick={
                                                 (onItemClick)
                                                     ? () => onItemClick(paymentItem.paymentId)
                                                     : undefined
                                }/>
                            })
                        }
                    </PaymentsList>
                </MonthGroupWrapper>
            })
        }
    </TableWrapper>
}
