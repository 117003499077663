import {createSelector} from 'reselect';
import {ApplicationState} from "../index";
import {StreamEventItemState, StreamEventStoreItem} from "./type";

export const getAllStreamEventItems = ({streamEvent}: ApplicationState) => streamEvent.eventList;

/**
 * Получить ближайший event, который ещё не обоработан
 */
export const getOneUnhandledEvent = createSelector(
    [getAllStreamEventItems],
    (allItems) => {
        let itemForHandle: StreamEventStoreItem<object | null> | null = null;

        for (let index = 0; index < allItems.length; index++) {
            if (allItems[index].state === StreamEventItemState.WAIT_FOR_HANDLE) {
                itemForHandle = allItems[index];

                break;
            }
        }

        return itemForHandle;
    }
);
