import React, {useCallback} from "react";
import {ReactComponent as SearchIcon} from "../Svg/Search.svg";
import styled from "styled-components";
import {Input} from "./TextInput";
import {LinkAsButton} from "./LinkAsButton";
import {BtnStyleEnum} from "./Button";
import {ReactComponent as AddSvg} from "../../../components/Ui/Svg/Add.svg";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    @media (${({theme}) => theme.media.small}) {
        margin-bottom: 30px;
    }
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const SearchByStringFilter = styled.div`
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    border-width: 1px;
    border-color: ${({theme}) => theme.colors.accentDivider};
    border-style: none none solid none;

    padding: 3px 10px;
    flex-grow: 1;

    display: flex;
    flex-direction: row;
`;

const SearchByStringIcon = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
`;

const SearchByStringInput = styled(Input)`
    border-bottom: none !important;
`;

const CreateNewButton = styled(LinkAsButton)`
    .label {
        display: none;
    }
    
    .plus {
        display: flex;
    }

    @media (${({theme}) => theme.media.small}) {
        .plus {
            display: none;
        }

        .label {
            display: inline;
        }
    }
`;

type CreateNewBtnConfig = {
    label: string,
    link: string
}

export interface FilterAreaProps {
    placeholder: string;
    searchString: string;
    onChange: (newSearchString: string) => void;
    chipsRadioItems?: React.ReactNode;
    createNewBtn?: CreateNewBtnConfig;
}

export const ChipsFilterArea: React.FC<FilterAreaProps> = (
    {
        placeholder,
        searchString,
        onChange,
        chipsRadioItems,
        createNewBtn
    }
) => {
    const searchStringOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    }, [onChange]);

    return <Wrapper>
        <div>
            {chipsRadioItems}
        </div>
        <SearchWrapper>
            <SearchByStringFilter>
                <SearchByStringIcon><SearchIcon/></SearchByStringIcon>
                <SearchByStringInput
                    placeholder={placeholder}
                    value={searchString}
                    onChange={searchStringOnChange}
                />
            </SearchByStringFilter>

            {
                (createNewBtn) &&
                <CreateNewButton btnStyle={BtnStyleEnum.Primary} to={createNewBtn.link}>
                    <span className={'plus'}><AddSvg/></span>
                    <span className={'label'}>{createNewBtn.label}</span>
                </CreateNewButton>
            }
        </SearchWrapper>
    </Wrapper>
}