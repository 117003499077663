import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t, Trans} from "@lingui/macro";
import {
    BottomControlItem,
    BottomControlItemIconWrapper,
    BottomControlItemTextWrapper,
    BottomControls,
    ControlCell,
    ControlCellButton,
    ControlCellButtonsWrapper,
    ElementWrapper,
    HeaderCell,
    HeaderControlCell,
    ItemCell,
    Table
} from "./Styles";
import {ReactComponent as RemoveIcon} from "../../../Ui/Svg/Remove.svg";
import {ReactComponent as PlusIcon} from "../../../Ui/Svg/Plus.svg";
import {ExerciseIdGenerator} from "../../components/ExerciseIdGenerator";
import {ContentEditable} from "../../../ContentEditable";
import {ElementData} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/ElementDataType";
import {Sides} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/SlidesEnum";

interface ExerciseMatchProps extends ItemPropsInterface<any> {
}

interface ExerciseMatchRefMethods extends ItemRefMethodsInterface {
}

export const ExerciseMatch = forwardRef<ExerciseMatchRefMethods, ExerciseMatchProps>(
    (props, ref) => {

        const {id, setToolbarConfigById, initialData, onChange} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
            },
            getExercisesCount: () => {
                return items.length;
            }
        }));

        useEffect(() => {
            setToolbarConfigById(id, [
                {
                    buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                    active: false,
                    disabled: true
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                    active: false,
                    disabled: true
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_JUSTIFY,
                    active: false,
                    disabled: true
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                    active: false,
                    disabled: true
                }
            ]);
        }, [id, setToolbarConfigById]);

        const items = useMemo<ElementData[]>(() => {
            if (!Array.isArray(initialData)) {
                return [];
            }

            return initialData.map((item) => item as ElementData);
        }, [initialData]);

        const addItem = useCallback(() => {
            const newItems = [
                ...items
            ];

            const newItem: ElementData = {
                id: ExerciseIdGenerator.generateId(),
                leftSideText: "",
                rightSideText: ""
            }

            newItems.push(newItem);

            onChange(newItems);
        }, [items, onChange]);

        const updateItem = useCallback((itemId: string, value: string, side: Sides) => {
            const newItems = [
                ...items
            ];

            const targetItemIndex = newItems.findIndex((item) => item.id === itemId);

            if (targetItemIndex < 0) {
                return;
            }

            if (side === Sides.LEFT) {
                newItems[targetItemIndex].leftSideText = value;
            } else {
                newItems[targetItemIndex].rightSideText = value;
            }

            onChange(newItems);
        }, [items, onChange]);

        const removeItem = useCallback((itemId: string) => {
            const newItems = [
                ...items.filter((item) => item.id !== itemId)
            ];

            onChange(newItems);
        }, [items, onChange]);

        return <ElementWrapper>
            <Table>
                <thead>
                <tr>
                    <HeaderCell className={'left'}>
                        <Trans>Левая сторона</Trans>
                    </HeaderCell>
                    <HeaderCell className={'right'}>
                        <Trans>Правая сторона</Trans>
                    </HeaderCell>
                    <HeaderControlCell></HeaderControlCell>
                </tr>
                </thead>
                <tbody>
                {
                    items.map((item) => {
                        return <tr key={item.id}>
                            <ItemCell className={'left'}>
                                <ContentEditable
                                    id={item.id + '_left'}
                                    maxLength={255}
                                    value={item.leftSideText}
                                    placeholder={t`Текст левой стороны`}
                                    onBlur={(value) => updateItem(item.id, value, Sides.LEFT)}
                                />
                            </ItemCell>
                            <ItemCell className={'right'}>
                                <ContentEditable
                                    id={item.id + '_right'}
                                    maxLength={255}
                                    value={item.rightSideText}
                                    placeholder={t`Текст правой стороны`}
                                    onBlur={(value) => updateItem(item.id, value, Sides.RIGHT)}
                                />
                            </ItemCell>
                            <ControlCell>
                                <ControlCellButtonsWrapper>
                                    <ControlCellButton onClick={() => removeItem(item.id)}>
                                        <RemoveIcon/>
                                    </ControlCellButton>
                                </ControlCellButtonsWrapper>
                            </ControlCell>
                        </tr>;
                    })
                }
                </tbody>
            </Table>
            <BottomControls>
                <BottomControlItem onClick={addItem}>
                    <BottomControlItemIconWrapper>
                        <PlusIcon/>
                    </BottomControlItemIconWrapper>
                    <BottomControlItemTextWrapper>
                        <Trans>Добавить пару</Trans>
                    </BottomControlItemTextWrapper>
                </BottomControlItem>
            </BottomControls>
        </ElementWrapper>;
    }
);