import React, {useCallback, useContext, useMemo, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin} from "../../../../../styles/global-elements";
import {Form, FormItem, useForm} from "../../../../../components/Ui/Elements/Form";
import {
    CreateAgreementTeamModeEnum
} from "../../../../../../components/StonlineApiClient/Enums/CreateAgreementTeamModeEnum";
import {RadioGroup, RadioInput} from "../../../../../components/Ui/Elements/RadioInput";
import styled from "styled-components";
import {AsyncSelect, ItemType} from "../../../../../components/Ui/Elements/Selectors/AsyncSelect";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import {NoConnection} from "../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {container} from "tsyringe";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {
    StudentGroupsListModeEnum
} from "../../../../../../components/StonlineApiClient/Enums/StudentGroupsListModeEnum";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {ButtonArea} from "../ButtonArea";
import {useNavigate} from "react-router-dom";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {PageSectionsEnum as StudentProfilePageSectionsEnum} from "../../profile-page/PageSectionsEnum";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {IStepWizardContext, StepWizardContext} from "../../../../../components/StepWizard/StepWizardContext";
import {StepWizardStatusEnum} from "../../../../../components/StepWizard/StepWizardStatusEnum";

const FormWrapper = styled.div`
  margin-bottom: 100px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const StartFromGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  margin-bottom: 30px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StartFromInputWrapper = styled.div`
  padding-left: 25px;
`;

enum FormFieldNamesEnum {
    TEAM_MODE = 'team_mode',
    TEAM = 'team'
}

export const NewAgreementTeamSection: React.FC = () => {
    const [form] = useForm();
    const navigate = useNavigate();

    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const [selectTeamFieldVisible, setSelectTeamFieldVisible] = useState<boolean>(
        pageContext.formData.teamMode === CreateAgreementTeamModeEnum.IN_TEAM
    );
    const [teamSearchAbortController, setTeamSearchAbortController] = useState<AbortController | null>(null);

    const stToken = useSelector(stTokenSelector);

    const stApiClient = useMemo(() => container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT), []);

    const onFormValuesChange = useCallback(() => {
        const selectTeamFieldSelector =
            form.getFieldValue(FormFieldNamesEnum.TEAM_MODE) === CreateAgreementTeamModeEnum.IN_TEAM;

        if (selectTeamFieldSelector !== selectTeamFieldVisible) {
            setSelectTeamFieldVisible(selectTeamFieldSelector);
        }
    }, [form, selectTeamFieldVisible]);

    const onFinish = async (values: any) => {
        const teamMode = values[FormFieldNamesEnum.TEAM_MODE];
        const teamModeInTeam = teamMode === CreateAgreementTeamModeEnum.IN_TEAM;
        const teamId = (values[FormFieldNamesEnum.TEAM]) ? values[FormFieldNamesEnum.TEAM].id : null;
        const teamName = (values[FormFieldNamesEnum.TEAM]) ? values[FormFieldNamesEnum.TEAM].name : '';

        pageContext.setFormData((oldValue) => {
            return {
                ...oldValue,
                teamMode: teamMode,
                teamId: (teamModeInTeam)
                    ? teamId
                    : null,
                teamName: (teamModeInTeam)
                    ? teamName
                    : ''
            }
        });

        const needNewGroup = teamId === null;

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.TEAM,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ],
            [
                {
                    id: PageSectionsEnum.TRAINING_PROGRAM,
                    visible: needNewGroup
                },
                {
                    id: PageSectionsEnum.SCHEDULE,
                    visible: needNewGroup
                }
            ]
        );
    }

    const teamSearch = useCallback((searchingString: string): Promise<ItemType[]> => {
        return new Promise<ItemType[]>((resolve) => {
            if (stToken === null) {
                resolve([]);

                return;
            }

            const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

            if (teamSearchAbortController !== null) {
                teamSearchAbortController.abort();
            }

            const newAbortController = new AbortController();

            setTeamSearchAbortController(newAbortController);

            stApiClient.getStudentGroupsList(
                stToken,
                StudentGroupsListModeEnum.ALL_ACTIVE_GROUPS,
                searchingString,
                dateHelperService.formatAsSQLDate(new Date()),
                1,
                5,
                newAbortController
            )
                .then((items) => {
                    resolve(items.result.items.map(dtoItem => ({
                        id: dtoItem.id.toString(10),
                        name: dtoItem.name
                    })));
                })
                .catch((e) => {
                    if (e instanceof NoConnection) {
                        resolve([]);
                    }

                    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                    logger.error(
                        LoggerSectionsEnum.STONLINE_GROUPS_LIST_API,
                        `Error on get groups list for new agreement: `,
                        e
                    );
                })
        });
    }, [teamSearchAbortController, stApiClient, stToken]);


    return <div>
        <PageSubtitleSmallMargin>
            <Trans>С кем {pageContext.shortStudentName} будет учиться?</Trans>
        </PageSubtitleSmallMargin>

        <FormWrapper>
            <Form form={form} layout={"inline"} onFinish={onFinish} onValuesChange={onFormValuesChange}>
                <FormItemsWrapper>
                    <StartFromGroup>
                        <FormItem
                            name={FormFieldNamesEnum.TEAM_MODE}
                            initialValue={pageContext.formData.teamMode}
                        >
                            <RadioGroup>
                                <RadioWrapper>
                                    <RadioInput value={CreateAgreementTeamModeEnum.INDIVIDUAL}>
                                        <Trans>Индивидуально</Trans></RadioInput>
                                    <RadioInput value={CreateAgreementTeamModeEnum.IN_TEAM}>
                                        <Trans>В команде</Trans></RadioInput>
                                </RadioWrapper>
                            </RadioGroup>
                        </FormItem>

                        {
                            (selectTeamFieldVisible) &&
                            <StartFromInputWrapper>
                                <FormItem
                                    name={FormFieldNamesEnum.TEAM}
                                    initialValue={{
                                        id: pageContext.formData.teamId,
                                        name: pageContext.formData.teamName
                                    }}
                                    label={<Trans>Введите название команды</Trans>}
                                    rules={[
                                        ({getFieldValue}) => ({
                                            validator(_, value) {
                                                if (!value || !value.name) {
                                                    return Promise.reject(t`Необходимо указать название команды`);
                                                }

                                                return Promise.resolve()
                                            }
                                        })
                                    ]}
                                >
                                    <AsyncSelect searchMethod={teamSearch}/>
                                </FormItem>
                            </StartFromInputWrapper>
                        }
                    </StartFromGroup>
                </FormItemsWrapper>
                <ButtonArea
                    prevSectionAvailable={false}
                    nextSectionAvailable={true}
                    prevBtnOnClick={() => {
                        navigate(RoutesHelper.replaceParams(
                            RoutesList.TEACHER_STUDENT_PROFILE,
                            [
                                {
                                    key: 'studentId',
                                    value: pageContext.studentGeneralInfoDto.id.toString(10)
                                },
                                {
                                    key: 'sectionId',
                                    value: StudentProfilePageSectionsEnum.EDUCATION
                                }
                            ]
                        ));

                        return Promise.resolve();
                    }}

                    nextBtnOnClick={async () => {
                        await form.validateFields();
                        form.submit();
                    }}
                />
            </Form>
        </FormWrapper>
    </div>
}