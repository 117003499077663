import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';

/**
 * DTO параметров ответа на запрос создания демо пользователя.
 */
export class CreateDemoStudentDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, 100)
    authLink: string;
}
