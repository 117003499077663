import React from "react";
import styled from "styled-components";

const SubtitleItemStyled = styled.div`
    color: ${({theme}) => theme.colors.textSecondary};
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    padding: 10px 7px 0 7px;
`;

interface SubtitleItemProps {
    children: React.ReactNode;
}

export const SubtitleItem: React.FC<SubtitleItemProps> = ({children}) => {
    return <SubtitleItemStyled>
        {children}
    </SubtitleItemStyled>
}