import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../../store/lessonRoom/type";
import {WorkerPayloadType} from "../../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../../components/WsApiClient/ResponseActionCreatorPayload";
import {ApplicationState} from "../../../store";
import {StreamEventStoreItem} from "../../../store/streamEvent/type";
import {setMemberPinedUser} from "../../../store/lessonRoom/actions";
import {
    DtoSEMemberPublicVideoPinState
} from "../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicVideoPinState";

export function* watchHandleSeMemberPublicVideoPinState() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicVideoPinState>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_VIDEO_PIN,
        handleVideoPinState
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleVideoPinState(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicVideoPinState>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    yield put(setMemberPinedUser(
        data.payload.response.dto.payload.userId,
        data.payload.response.dto.payload.pinnedUserId
    ));
}
