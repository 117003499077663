import React, {useCallback, useMemo, useState} from "react";
import {PaymentTableItem} from "./PaymentTableItemType";
import styled from "styled-components";
import {ReactComponent as WalletMinusSvg} from "../../../components/Ui/Svg/WalletMinus24.svg";
import {ReactComponent as WalletPlusSvg} from "../../../components/Ui/Svg/WalletPlus24.svg";
import {ReactComponent as WalletSvg} from "../../../components/Ui/Svg/Wallet24.svg";
import {ReactComponent as ArrowDownSvg} from "../../../components/Ui/Svg/ArrowDown24.svg";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ITheme} from "../../../../services/theme/ITheme";
import useMediaQuery from "../../../../services/hooks/useMediaQuery";
import {MoneyHelper} from "../../../../helpers/MoneyHelper";
import {RegularTextCss} from "../../../styles/global-elements";
import {MonthAsString} from "../../Ui/Elements/TextPresenter/MonthAsString";
import classNames from "classnames";

const ItemWrapper = styled.li`
  padding: 16px 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};

  &.dark-bottom {
    border-bottom-color: ${({theme}) => theme.colors.textSecondary};
  }

  &.allow-click {
    cursor: pointer;
  }
`;

const FirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SecondLine = styled.div`
  padding: 16px 0 0 0;
  color: ${({theme}) => theme.colors.textSecondary};
`;

const IconWrapper = styled.div`
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;

  &.plus {
    color: ${({theme}) => theme.colors.walletPlusGreen};
  }

  &.zero {
    color: ${({theme}) => theme.colors.textTertiary};
  }

  &.minus {
    color: ${({theme}) => theme.colors.textTertiary};
  }
`;

const DateWrapper = styled.div`
  ${RegularTextCss};

  text-transform: lowercase;

  @media (${({theme}) => theme.media.small}) {
    margin-right: 35px;
    min-width: 120px;
  }
`;

const DescriptionWrapper = styled.div`
  ${RegularTextCss};

  flex-grow: 1;
`;

const SumWrapper = styled.div`
  ${RegularTextCss};
  min-width: 100px;
  text-align: right;

  &.plus {
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.zero {
    color: ${({theme}) => theme.colors.textSecondary};
  }

  &.minus {
    color: ${({theme}) => theme.colors.walletMinusSum};
  }
`;

const ArrowDownWrapper = styled.div`
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
  color: ${({theme}) => theme.colors.textTertiary};
  margin-left: 10px;
`;

const ArrowDownSvgStyled = styled(ArrowDownSvg)`
  transition: all 0.3s ease;

  &.opened {
    transform: rotate(180deg);
  }
`;

interface ItemProps {
    item: PaymentTableItem;
    onItemClick?: () => void;
}

export const Item: React.FC<ItemProps> = ({item, onItemClick}) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const [secondLineIsOpened, setSecondLineIsOpened] = useState<boolean>(false);

    const itemIcon = useMemo<JSX.Element>(() => {
        let colorClass = 'plus';
        let icon = <WalletPlusSvg/>;

        if (item.summ === 0) {
            colorClass = 'zero';
            icon = <WalletSvg/>;
        }

        if (item.summ < 0) {
            colorClass = 'minus';
            icon = <WalletMinusSvg/>;
        }

        return <IconWrapper className={colorClass}>
            {icon}
        </IconWrapper>
    }, [item.summ]);

    const dateItem = useMemo<JSX.Element>(() => {
        if (!isSmall) {
            let month = (item.datePay.getMonth() + 1).toString(10);

            if (month.length === 1) {
                month = '0' + month;
            }

            return <DateWrapper>
                {item.datePay.getDate()}.{month}
            </DateWrapper>
        }

        return <DateWrapper>
            {item.datePay.getDate()} <MonthAsString monthNum={item.datePay.getMonth() + 1} inGenitiveCase={true}/>
        </DateWrapper>
    }, [isSmall, item.datePay]);

    const descriptionItem = useMemo<JSX.Element>(() => {
        return <DescriptionWrapper>
            {item.comment}
        </DescriptionWrapper>
    }, [item.comment]);

    const sumItem = useMemo<JSX.Element>(() => {
        let className = '';

        let sumValue = MoneyHelper.formatMoney(item.summ);

        if (item.summ > 0) {
            className = 'plus';
            sumValue = '+' + sumValue;
        }

        if (item.summ < 0) {
            className = 'minus';
        }

        if (item.summ === 0) {
            className = 'zero';
        }

        return <SumWrapper className={className}>{sumValue}</SumWrapper>
    }, [item.summ]);

    const toggleSecondLineOpened = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        setSecondLineIsOpened((value) => {
            return !value;
        });

        event.stopPropagation();
    }, []);

    const onItemClickHandler = useCallback(() => {
        if (!onItemClick) {
            return;
        }

        onItemClick();
    }, [onItemClick]);

    return <ItemWrapper
        className={classNames(
            secondLineIsOpened && !isSmall && 'dark-bottom',
            onItemClick && 'allow-click'
        )}
        onClick={onItemClickHandler}
    >
        <FirstLine>
            {itemIcon}
            {dateItem}
            {
                (isSmall) ? descriptionItem : <DescriptionWrapper/>
            }
            {sumItem}
            {
                (!isSmall) && <ArrowDownWrapper onClick={toggleSecondLineOpened}>
                <ArrowDownSvgStyled className={classNames(secondLineIsOpened && 'opened')}/>
              </ArrowDownWrapper>
            }
        </FirstLine>
        {
            (!isSmall && secondLineIsOpened) && <SecondLine>
                {descriptionItem}
          </SecondLine>
        }
    </ItemWrapper>
}
