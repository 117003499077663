import React from 'react';
import {UnknownElement} from "./elements/UnknownElement";
import {IElementProps} from "./IElementProps";
import {IElementRefMethods} from "./IElementRefMethods";
import {EditorDataItem} from "../SlidePlayerEditorCommonParts/EditorData";
import {ElementTypeEnum} from "../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {Title} from "./elements/Title";
import {Text} from "./elements/Text";
import {ExerciseFillGapsCombobox} from "./elements/ExerciseFillGapsCombobox";
import {SlideItemWorkContextProvider} from "./SlideItemWorkContext";
import {PlayerContext} from "./PlayerContext";
import {TeacherNotes} from "./elements/TeacherNotes";
import {TeacherCanSay} from "./elements/TeacherCanSay";
import {TeacherAlsoDiscuss} from "./elements/TeacherAlsoDiscuss";
import {UnfoldBlock} from "./elements/UnfoldBlock";
import {ExerciseFillGapsText} from "./elements/ExerciseFillGapsText";
import {ExerciseCorrectExistInInput} from "./elements/ExerciseCorrectExistInInput";
import {ExerciseFillGapsDragDrop} from "./elements/ExerciseFillGapsDragDrop";
import {ExerciseRadio} from "./elements/ExerciseRadio";
import {ExerciseCheckbox} from "./elements/ExerciseCheckbox";
import {ExerciseMatch} from "./elements/ExerciseMatch";
import {Image} from "./elements/Image";
import {VideoYoutube} from "./elements/VideoYoutube";
import {Video} from "./elements/Video";
import {Audio} from "./elements/Audio";
import styled from "styled-components";
import {
    ActionParamExerciseUserAdmitDefeat,
    ActionParamExerciseUserAnswer,
    ActionParamExerciseUserValue,
    SlideItemWorkData
} from "../../../store/slidesWorkData/type";
import {ApplicationState} from "../../../store";
import {Dispatch} from "redux";
import {connect, ConnectedProps} from "react-redux";
import {initialSlideItemWorkData} from "../../../store/slidesWorkData/initialState";
import {isEqual} from "lodash";
import {
    handleUserEventAddAnswer, handleUserEventAdmitDefeat,
    handleUserEventChangeAdditionalData,
    handleUserEventValueChange
} from "../../../store/slidesWorkData/actions";
import classNames from "classnames";
import {DialogBubble} from "./elements/DialogBubble";

interface ElementClassProps extends PropsFromRedux {
    playerId: string;
    slideId: string;
    element: EditorDataItem<any>;
    showCorrectAnswers?: boolean;
    readOnly?: boolean;
}

interface ElementClassState {
    currentSlideItemData: SlideItemWorkData;
    hidden: boolean;
}

const ItemWrapper = styled.div`
  margin-bottom: 20px;
    transition: all 0.3s ease;
    opacity: 1;
  
  &.no-margin {
      margin-bottom: 0;
  }
    
    &.hidden {
        opacity: 0;
        transform: translateY(10px);
    }
`;

class ElementClass extends React.Component<ElementClassProps, ElementClassState> {
    constructor(props: Readonly<ElementClassProps> | ElementClassProps) {
        super(props);

        this.state = {
            currentSlideItemData: initialSlideItemWorkData,
            hidden: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                hidden: false
            })
        }, 200);
    }

    shouldComponentUpdate(nextProps: Readonly<ElementClassProps>, nextState: Readonly<ElementClassState>, nextContext: any): boolean {
        if (nextState.hidden !== this.state.hidden) {
            return true;
        }

        // Проверим, что изменились данные именно нашего элемента
        const nextItemWorkData = this.getSlideItemWorkData(nextProps);
        const currentItemWorkData = this.getSlideItemWorkData(this.props);

        return !isEqual(nextItemWorkData, currentItemWorkData);
    }

    protected getSlideItemWorkData = (props: Readonly<ElementClassProps>): SlideItemWorkData => {
        const playerIndex = props.slideWorkDataState.indexByPlayerId[props.playerId];

        if (playerIndex === undefined) {
            return initialSlideItemWorkData;
        }

        const slideIndex = playerIndex.indexBySlideId[props.slideId];

        if (slideIndex === undefined || props.slideWorkDataState.slides[slideIndex] === undefined) {
            return initialSlideItemWorkData;
        }

        const slideData = props.slideWorkDataState.slides[slideIndex];

        const itemIndex = slideData.itemsIndexById[props.element.id];

        if ((itemIndex === undefined) || (slideData.items[itemIndex] === undefined)) {
            return initialSlideItemWorkData;
        }

        return slideData.items[itemIndex];
    }

    protected saveExerciseValue = (exerciseId: string, value: string) => {
        this.props.handleUserEventValueChange({
            playerId: this.props.playerId,
            slideId: this.props.slideId,
            slideItemId: this.props.element.id,
            exerciseId: exerciseId,
            value: value,
        });
    }

    protected saveExerciseAnswer = (exerciseId: string, value: string, award: number, missedAward: number, slideExercisesCount: number, answerIsCorrect: boolean) => {
        this.props.handleUserEventAddAnswer({
            playerId: this.props.playerId,
            slideId: this.props.slideId,
            slideItemId: this.props.element.id,
            exerciseId: exerciseId,
            value: value,
            award: award,
            missedAward: missedAward,
            slideExercisesCount: slideExercisesCount,
            answerIsCorrect: answerIsCorrect
        });
    }

    protected saveExerciseAdmitDefeat = (exerciseId: string, value: string, slideExercisesCount: number) => {
        this.props.handleUserEventAdmitDefeat({
            playerId: this.props.playerId,
            slideId: this.props.slideId,
            slideItemId: this.props.element.id,
            exerciseId: exerciseId,
            value: value,
            slideExercisesCount: slideExercisesCount
        });
    }

    protected saveSlideItemAdditionalData = (exerciseId: string, value: string | null) => {
        this.props.handleUserEventChangeAdditionalData({
            playerId: this.props.playerId,
            slideId: this.props.slideId,
            slideItemId: this.props.element.id,
            exerciseId: exerciseId,
            value: value
        });
    }

    protected elem = (): React.ForwardRefExoticComponent<IElementProps<any> & React.RefAttributes<IElementRefMethods>> => {
        switch (this.props.element.type) {
            case ElementTypeEnum.TEACHER_NOTES: {
                return TeacherNotes;
            }
            case ElementTypeEnum.TEACHER_CAN_SAY: {
                return TeacherCanSay;
            }
            case ElementTypeEnum.TEACHER_ALSO_DISCUSS: {
                return TeacherAlsoDiscuss;
            }
            case ElementTypeEnum.TITLE: {
                return Title;
            }
            case ElementTypeEnum.TEXT: {
                return Text;
            }
            case ElementTypeEnum.UNFOLD_BLOCK: {
                return UnfoldBlock;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX: {
                return ExerciseFillGapsCombobox;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT: {
                return ExerciseFillGapsText;
            }
            case ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT: {
                return ExerciseCorrectExistInInput;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP: {
                return ExerciseFillGapsDragDrop;
            }
            case ElementTypeEnum.EXERCISES_RADIO: {
                return ExerciseRadio;
            }
            case ElementTypeEnum.EXERCISES_CHECKBOX: {
                return ExerciseCheckbox;
            }
            case ElementTypeEnum.EXERCISES_MATCH: {
                return ExerciseMatch;
            }
            case ElementTypeEnum.MEDIA_PICTURE: {
                return Image;
            }
            case ElementTypeEnum.MEDIA_YOUTUBE: {
                return VideoYoutube;
            }
            case ElementTypeEnum.MEDIA_VIDEO: {
                return Video;
            }
            case ElementTypeEnum.MEDIA_AUDIO: {
                return Audio;
            }
            case ElementTypeEnum.DIALOG_BUBBLE: {
                return DialogBubble;
            }
            default: {
                return UnknownElement;
            }
        }
    }

    protected elementsWithNoMargin = [
        ElementTypeEnum.TEACHER_NOTES,
        ElementTypeEnum.TEACHER_CAN_SAY,
        ElementTypeEnum.TEACHER_ALSO_DISCUSS
    ];

    render() {
        const Elem = this.elem();

        let additionalClassName: string | null = null;

        if (this.elementsWithNoMargin.indexOf(this.props.element.type)>-1) {
            additionalClassName = 'no-margin';
        }

        return <PlayerContext.Consumer>
            {
                (playerContext) => {
                    return (playerContext.selectedSlideId !== null)
                        && <SlideItemWorkContextProvider value={{
                            playerId: this.props.playerId,
                            lessonId: playerContext.lessonId,
                            slideId: playerContext.selectedSlideId,
                            itemId: this.props.element.id,
                            readOnly: this.props.readOnly,
                            showCorrectAnswers: this.props.showCorrectAnswers,
                            slideItemWorkData: this.getSlideItemWorkData(this.props),
                            saveExerciseValue: this.saveExerciseValue,
                            saveExerciseAnswer: this.saveExerciseAnswer,
                            saveAdditionalData: this.saveSlideItemAdditionalData,
                            saveAdmitDefeat: this.saveExerciseAdmitDefeat
                        }}>
                            <ItemWrapper
                                data-slide-item-id={this.props.element.id}
                                className={classNames(additionalClassName, this.state.hidden && 'hidden')}
                            >
                                <Elem elementData={this.props.element}/>
                            </ItemWrapper>
                        </SlideItemWorkContextProvider>
                }
            }
        </PlayerContext.Consumer>;
    }
}

const mapStateToProps = ({slidesWorkData}: ApplicationState) => ({
    slideWorkDataState: slidesWorkData
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleUserEventValueChange: (params: ActionParamExerciseUserValue<string>) => {
        dispatch(handleUserEventValueChange(params))
    },
    handleUserEventAddAnswer: (params: ActionParamExerciseUserAnswer) => {
        dispatch(handleUserEventAddAnswer(params))
    },
    handleUserEventAdmitDefeat: (params: ActionParamExerciseUserAdmitDefeat) => {
        dispatch(handleUserEventAdmitDefeat(params))
    },
    handleUserEventChangeAdditionalData: (params: ActionParamExerciseUserValue<string | null>) => {
        dispatch(handleUserEventChangeAdditionalData(params))
    }
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ElementClass);
