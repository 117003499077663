import {ActionParamExerciseUserValue, SlidesWorkDataState} from "../../../store/slidesWorkData/type";
import {putResolve, select} from "redux-saga/effects";
import {setSlideExerciseWorkDataEmptyObject, setSlideWorkDataEmptyObject} from "../../../store/slidesWorkData/actions";
import {ApplicationState} from "../../../store";

const currentSlidesWorkDataState = ({slidesWorkData}: ApplicationState) => slidesWorkData;

/**
 * Включает в себя создание slideItem object
 *
 * @param data
 */
export function* prepareOrFindExerciseObject(data: ActionParamExerciseUserValue<null>) {
    let currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;

    const playerId = data.playerId;
    const slideId = data.slideId;
    const slideItemId = data.slideItemId;
    const exerciseId = data.exerciseId;

    // Если slideItem нет, то создадим объект и перезагрузим значение
    if (
        (currentSlidesWorkData.indexByPlayerId[playerId] === undefined)
        || (currentSlidesWorkData.indexByPlayerId[playerId].indexBySlideId[slideId] === undefined)
    ) {
        yield putResolve(setSlideWorkDataEmptyObject({
            playerId: playerId,
            slideId: slideId,
            value: null
        }));

        currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;
    }

    // Если нет структуры для этого упражнения, то создадим объект и перезагрузим значение
    const slideIndex = currentSlidesWorkData.indexByPlayerId[playerId].indexBySlideId[slideId];

    if (slideIndex === undefined) {
        throw new Error('slide work data undefined after create');
    }

    let slideItemData = currentSlidesWorkData.slides[slideIndex];

    // Если нет структуры для элемента слайда
    if (slideItemData.itemsIndexById[slideItemId] === undefined) {
        yield putResolve(setSlideExerciseWorkDataEmptyObject({
            playerId: playerId,
            slideId: slideId,
            slideItemId: slideItemId,
            exerciseId: exerciseId,
            value: null
        }));

        currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;
        slideItemData = currentSlidesWorkData.slides[slideIndex];
    }

    let slideItemIndex = slideItemData.itemsIndexById[slideItemId];

    if ((slideItemIndex === undefined) || (slideItemData.items[slideItemIndex] === undefined)) {
        throw new Error('slide item work data undefined after create');
    }

    // Если нет данных упражнения
    if (slideItemData.items[slideItemIndex].exercisesIndexById[exerciseId] === undefined) {
        yield putResolve(setSlideExerciseWorkDataEmptyObject({
            playerId: playerId,
            slideId: slideId,
            slideItemId: slideItemId,
            exerciseId: exerciseId,
            value: null
        }));

        currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;
        slideItemData = currentSlidesWorkData.slides[slideIndex];
        slideItemIndex = slideItemData.itemsIndexById[slideItemId];
    }

    const exerciseIndex = slideItemData.items[slideItemIndex].exercisesIndexById[exerciseId];

    if ((exerciseIndex === undefined) || (slideItemData.items[slideItemIndex].exercises[exerciseIndex] === undefined)) {
        throw new Error('slide exercise item work data undefined after create');
    }

    return [slideIndex, slideItemIndex, exerciseIndex];
}