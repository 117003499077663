import {IsNotEmpty, IsString} from "class-validator";

export class DtoLastCall {
    @IsNotEmpty()
    @IsString()
    dateLogin: string;

    @IsNotEmpty()
    @IsString()
    lastCall: string;
}