import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin, RegularText} from "../../../../../styles/global-elements";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import {PageLoadStatus} from "../../../knowledge-base/common/Enums";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../../components/HttpApiClient/Exception/NoConnection";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {LinkAsButton} from "../../../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {PageSectionsEnum as StudentProfilePagePageSectionEnum} from "../../profile-page/PageSectionsEnum";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import styled from "styled-components";
import {IClipboardHelper} from "../../../../../../components/ClipboardHelper/IClipboardHelper";
import {IStepWizardContext, StepWizardContext} from "../../../../../components/StepWizard/StepWizardContext";
import {StepWizardStatusEnum} from "../../../../../components/StepWizard/StepWizardStatusEnum";
import {PageSectionsEnum} from "../PageSectionsEnum";

const ContentWrapper = styled.div`
  margin-bottom: 30px;
`;

const OneButtonWrapper = styled.div`
`;

const TwoButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

const LinkArea = styled.span`
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;

  color: ${({theme}) => theme.colors.textSecondary};
`;

export const NewAgreementInvitationSection: React.FC = () => {
    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [studentLoginLink, setStudentLoginLink] = useState<string | null>(() => {
        if (!pageContext.studentEntranceInfoResponse) {
            return null;
        }

        return pageContext.studentEntranceInfoResponse.authLink;
    });

    const apiToken = useSelector(sessionTokenSelector);

    const onFinish = () => {
        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.INVITATION,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    const fetchEntranceLink = useCallback(async () => {
        if (pageContext.studentGeneralInfoDto.email === '') {
            return;
        }

        if (apiToken === null) {
            return;
        }

        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        setPageLoadingState(PageLoadStatus.LOADING);

        try {
            const entranceInfo = await apiClient
                .getStudentEntranceInfo(apiToken, pageContext.studentGeneralInfoDto.email, true);

            if (!entranceInfo.data.authLink) {
                throw new Error('authLink is empty in response');
            }

            setStudentLoginLink(entranceInfo.data.authLink);
            setPageLoadingState(PageLoadStatus.SUCCESS);
        } catch (e) {
            if (e instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить шаг`,
                    t`Проверьте подключение к интернету`
                );

                setPageLoadingState(PageLoadStatus.ERROR);
            }

            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.error(
                LoggerSectionsEnum.STONLINE_STUDENTS_API,
                'Error on create student for generate invitation link: ',
                e
            );

            setPageLoadingState(PageLoadStatus.ERROR);
        }

    }, [apiToken, pageContext.studentGeneralInfoDto.email]);

    const copyLinkOnClick = useCallback(() => {
        const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

        if (studentLoginLink === null) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось скопировать ссылку`,
                ''
            );

            return;
        }

        clipboardHelper.copyText(studentLoginLink)
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Ссылка успешно скопирована`,
                    ''
                );

                return;
            })
            .catch((e) => {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось скопировать ссылку`,
                    ''
                );

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.CLIPBOARD_HELPER,
                    'Error on copy student login link to clipboard. Link:',
                    studentLoginLink,
                    'Error',
                    e
                );
            })
    }, [studentLoginLink]);

    useEffect(() => {
        if (pageLoadingState !== PageLoadStatus.NOT_INIT) {
            return;
        }

        if (!pageContext.studentGeneralInfoDto.email) {
            setPageLoadingState(PageLoadStatus.SUCCESS);

            return;
        }

        if (!pageContext.studentEntranceInfoResponse || pageContext.studentEntranceInfoResponse.authLink === null) {
            fetchEntranceLink();
        }

        setPageLoadingState(PageLoadStatus.SUCCESS);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isFemale = useMemo<boolean>(
        () => pageContext.studentGeneralInfoDto.gender !== 1,
        [pageContext.studentGeneralInfoDto.gender]
    );

    switch (pageLoadingState) {
        case PageLoadStatus.NOT_INIT:
        case PageLoadStatus.LOADING: {
            return <DefaultLoader/>
        }
        case PageLoadStatus.ERROR: {
            return <ErrorLoadingContent retryBtnClick={fetchEntranceLink}/>
        }

        default: {
            // Если email не указан
            if (!pageContext.studentGeneralInfoDto.email) {
                return <div>
                    <ContentWrapper>
                        <PageSubtitleSmallMargin>
                            <Trans>{pageContext.shortStudentName} пока не может войти на платформу</Trans>
                        </PageSubtitleSmallMargin>

                        <RegularText>
                            {
                                (isFemale)
                                    ? <>
                                        <Trans>У неё в профиле не задан email.</Trans>
                                        <br/><br/>
                                        <Trans>Как только вы укажете email в её
                                            профиле, то сразу сможете отправить приглашение и скопировать ссылку для
                                            быстрого входа.</Trans>
                                    </>
                                    : <>
                                        <Trans>У него в профиле не задан email.</Trans>
                                        <br/><br/>
                                        <Trans>Как только вы укажете email в его
                                            профиле, то сразу сможете отправить приглашение и скопировать ссылку для
                                            быстрого входа.</Trans>
                                    </>
                            }
                        </RegularText>
                    </ContentWrapper>

                    <OneButtonWrapper>
                        <LinkAsButton
                            btnStyle={BtnStyleEnum.Primary}
                            to={
                                RoutesHelper.replaceParams(
                                    RoutesList.TEACHER_STUDENT_PROFILE,
                                    [
                                        {
                                            key: 'studentId',
                                            value: pageContext.studentGeneralInfoDto.id.toString(10)
                                        },
                                        {
                                            key: 'sectionId',
                                            value: StudentProfilePagePageSectionEnum.GENERAL
                                        }
                                    ]
                                )
                            }>
                            <Trans>В профиль ученика</Trans>
                        </LinkAsButton>
                    </OneButtonWrapper>
                </div>
            }


            // Если email есть
            return <div>
                <ContentWrapper>
                    <PageSubtitleSmallMargin>
                        <Trans>{pageContext.shortStudentName} ждёт приглашения на платформу</Trans>
                    </PageSubtitleSmallMargin>

                    <RegularText>
                        {
                            (isFemale)
                                ? <Trans>Отправьте ей ссылку для входа любым удобным способом. Она сможет по ней войти
                                    без пароля.</Trans>
                                : <Trans>Отправьте ему ссылку для входа любым удобным способом. Он сможет по ней войти в
                                    систему без пароля.</Trans>
                        }
                    </RegularText>
                    {
                        (studentLoginLink) && <>
                            <br/>
                            <RegularText>
                                <LinkArea>{studentLoginLink}</LinkArea>
                            </RegularText>
                        </>
                    }
                </ContentWrapper>

                <TwoButtonWrapper>
                    <Button
                        btnStyle={BtnStyleEnum.Secondary}
                        onClick={copyLinkOnClick}
                    >
                        <Trans>Скопировать ссылку</Trans>
                    </Button>

                    <Button
                        btnStyle={BtnStyleEnum.Primary}
                        onClick={onFinish}
                    >
                        <Trans>Дальше</Trans>
                    </Button>
                </TwoButtonWrapper>
            </div>
        }
    }
}