import {action} from "typesafe-actions";
import {ActiveSubscriptionsItemsList, OnlineStateEnum, UserOnlineStateSubscriptionsActionTypes} from "./type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {StreamEventStoreItem} from "../streamEvent/type";
import {UserOnlineStatusDto} from "../../components/WsApiClient/ApiDto/Response/user-online-state/UserOnlineStatusDto";

export const subscribeToNewUsers = (userIds: Array<number>) => {
    return action(UserOnlineStateSubscriptionsActionTypes.SUBSCRIBE_TO_NEW_USERS, userIds);
};

export const pushUsersToWaitList = (userIds: Array<number>) => {
    return action(UserOnlineStateSubscriptionsActionTypes.PUSH_USERS_TO_WAIT_LIST, userIds);
};

export const deleteUsersFromWaitList = (userIds: Array<number>) => {
    return action(UserOnlineStateSubscriptionsActionTypes.DELETE_USERS_FROM_WAIT_LIST, userIds);
};

export const processBatchFromWaitList = () => {
    return action(UserOnlineStateSubscriptionsActionTypes.PROCESS_BATCH_FROM_WAIT_LIST);
};

export const pushToActiveSubscriptionsList = (items: ActiveSubscriptionsItemsList) => {
    return action(UserOnlineStateSubscriptionsActionTypes.PUSH_TO_ACTIVE_SUBSCRIPTIONS_LIST, items);
};

export const updateStateInActiveSubscriptionsList = (items: { [id: number]: OnlineStateEnum }) => {
    return action(UserOnlineStateSubscriptionsActionTypes.UPDATE_STATE_IN_ACTIVE_SUBSCRIPTIONS_LIST, items);
};

export const deleteFromActiveSubscriptionsList = (userIds: Array<number>) => {
    return action(UserOnlineStateSubscriptionsActionTypes.DELETE_FROM_ACTIVE_SUBSCRIPTIONS_LIST, userIds);
};

export const clearActiveSubscriptionsList = () => {
    return action(UserOnlineStateSubscriptionsActionTypes.CLEAR_ACTIVE_SUBSCRIPTIONS_LIST);
};

export const resubscribeActiveSubscriptions = () => {
    return action(UserOnlineStateSubscriptionsActionTypes.RESUBSCRIBE_ACTIVE_SUBSCRIPTIONS);
};

export const streamEventUserStateUpdate = (newState: ResponseActionCreatorPayload<StreamEventStoreItem<UserOnlineStatusDto>, null>) => {
    return action(UserOnlineStateSubscriptionsActionTypes.STREAM_EVENT_USER_STATE_UPDATE, newState);
};