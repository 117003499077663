import React, {CSSProperties, useCallback, useContext, useMemo} from "react";
import {
    DtoSelfStudyTrackSubscriptionEpisodeOverviewItem
} from "../../../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionEpisodeOverviewItem";
import styled, {css} from "styled-components";
import {ISubscriptionContext, SubscriptionContext} from "../SubscriptionContext";
import {EpisodeContextProvider, IEpisodeContext} from "./EpisodeContext";
import {Text} from "./Text";
import {Cover} from "./Cover";
import {ButtonArea} from "./ButtonArea";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../../../di-factory/DiTokens";
import {RewardText} from "./RewardText";
import {Link as RouterLink} from "react-router-dom";
import {RoutesHelper} from "../../../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../../../RoutesList";

const WrapperCss = css`
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    //flex-basis: 0;
    //flex-grow: 1;
    background-color: ${({theme}) => theme.colors.backgroundSecondary};

    padding: 24px;
    //min-height: 140px;

    @media (${({theme}) => theme.media.small}) {
        padding: 28px 32px 28px 136px;
        min-height: 126px;
    }
`;

const WrapperDiv = styled.div`
    ${WrapperCss};
`;

const WrapperLink = styled(RouterLink)`
    color: inherit;
    ${WrapperCss};

    &:hover {
        color: inherit;
    }
`;

const TextAndButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        align-items: center;
        min-height: 69px;
        height: 100%;
    }
`;

const TextAndRewardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (${({theme}) => theme.media.small}) {
        flex-grow: 1;
    }
`;

const ButtonWrapper = styled.div`
    @media (${({theme}) => theme.media.small}) {
    }
`;

const RewardTextStyled = styled(RewardText)`
    @media (${({theme}) => theme.media.large}) {
        display: none;
    }
`;

interface EpisodeProps {
    item: DtoSelfStudyTrackSubscriptionEpisodeOverviewItem;
    prevItem: DtoSelfStudyTrackSubscriptionEpisodeOverviewItem | null;
}

export const Episode: React.FC<EpisodeProps> = ({item, prevItem}) => {
    const {overviewItem, backgroundColor} = useContext<ISubscriptionContext>(SubscriptionContext);

    const thisIsNextAfterLastActive = useMemo<boolean>(() => {
        return overviewItem.lastEpisodeNumber + 1 === item.numberOfEpisode;
    }, [item.numberOfEpisode, overviewItem.lastEpisodeNumber]);

    const secondsBeforeOpen = useCallback((): number => {
        const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        const currentTime = new Date();

        let nextDateTime = new Date();
        nextDateTime.setDate(currentTime.getDate() + 1);
        nextDateTime = dateHelper.setZeroTimeForDateObject(nextDateTime);

        return dateHelper.secondsBetween(currentTime, nextDateTime) + 5 * 60;
    }, []);

    const episodeContextValue = useMemo<IEpisodeContext>(() => ({
        episodeItem: item,
        prevEpisodeItem: prevItem,
        thisIsNextAfterLastActive,
        secondsBeforeOpen
    }), [item, prevItem, secondsBeforeOpen, thisIsNextAfterLastActive]);

    const wrapperAdditionalStyle = useMemo<CSSProperties>(() => {
        if (item.completedAt === null && item.id === overviewItem.lastEpisodeId) {
            return {
                backgroundColor: backgroundColor
            }
        }

        if (thisIsNextAfterLastActive && prevItem && prevItem.completedAt !== null) {
            return {
                backgroundColor: backgroundColor
            }
        }

        return {}
    }, [backgroundColor, item.completedAt, item.id, overviewItem.lastEpisodeId, prevItem, thisIsNextAfterLastActive]);

    const linkToEpisode = useMemo<string | null>(() => {
        if (item.id === null) {
            return null;
        }

        return RoutesHelper.replaceParams(
            RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE,
            [
                {key: 'episodeId', value: item.id}
            ]
        )
    }, [item.id]);

    const content = useMemo(() => {
        return <>
            <Cover/>

            <TextAndButtonWrapper>
                <TextAndRewardWrapper>
                    <Text/>
                    {
                        (item.completedAt !== null)
                        && <RewardTextStyled/>
                    }
                </TextAndRewardWrapper>
                <ButtonWrapper>
                    <ButtonArea/>
                </ButtonWrapper>
            </TextAndButtonWrapper>
        </>
    }, [item]);

    return <EpisodeContextProvider value={episodeContextValue}>
        {
            (linkToEpisode === null)
                ? <WrapperDiv style={wrapperAdditionalStyle}>
                    {content}
                </WrapperDiv>
                : <WrapperLink style={wrapperAdditionalStyle} to={linkToEpisode}>
                    {content}
                </WrapperLink>
        }
    </EpisodeContextProvider>
}