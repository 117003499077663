import {Editor} from "slate";
import {MarkFormats} from "../Enums";
import {isMarkActive} from "../CommonMethods";

export const toggleMark = (editor: Editor, format: MarkFormats) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
