import React, {useContext, useRef} from "react";
import {CreateStudentPageContext, ICreateStudentPageContext} from "./CreateStudentPageContextProvider";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../store/app/selector";
import {StudentGeneralForm, StudentGeneralFormMethods} from "../common/StudentGeneralForm";
import {ButtonArea} from "../create-agreement-page/ButtonArea";
import {RoutesList} from "../../../../RoutesList";
import {useNavigate} from "react-router-dom";
import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {NoConnection} from "../../../../../components/StonlineApiClient/Exception/NoConnection";

export const GeneralForm: React.FC = () => {
    const navigate = useNavigate();
    const formRef = useRef<StudentGeneralFormMethods>(null);

    const pageContext = useContext<ICreateStudentPageContext>(CreateStudentPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const stToken = useSelector(stTokenSelector);

    const onFinish = (values: DtoStudentGeneralInfo) => {
        return new Promise<void>((resolve, reject) => {
            if (!stToken) {
                reject();

                return;
            }

            const logger = container.resolve<ILogger>(DiTokens.LOGGER);
            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

            stApiClient.saveStudentProfile(stToken, values)
                .then((result) => {
                    formRef.current?.reset();

                    pageContext.setStudentGeneralInfoDto(values);

                    stepWizardContext.goNext(
                        [
                            {
                                id: PageSectionsEnum.GENERAL,
                                status: StepWizardStatusEnum.COMPLETED
                            }
                        ]
                    );

                    resolve();
                })
                .catch((err) => {
                    reject();

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось сохранить`,
                        t`Не удалось сохранить данные профиля ученика`
                    );

                    if (err instanceof NoConnection) {
                        return;
                    }

                    logger.error(
                        LoggerSectionsEnum.STONLINE_STUDENTS_API,
                        `Error on save student with id ${values.id}.`,
                        values,
                        err
                    );
                });
        });
    }

    return <div>
        <StudentGeneralForm
            ref={formRef}
            studentGeneralInfoDto={pageContext.studentGeneralInfoDto}
            onFinish={onFinish}
        />

        <ButtonArea
            prevSectionAvailable={false}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                try {
                    navigate(RoutesList.TEACHER_ALL_STUDENTS);
                } catch (e) {
                }

                return Promise.resolve();
            }}

            nextBtnOnClick={async () => {
                await formRef.current?.submit();
            }}
        />
    </div>
}