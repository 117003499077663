import * as React from "react";
import {forwardRef, useCallback, useMemo} from "react";
import {InputRef} from "antd/lib/input/Input";
import {MaskedInput} from "./MaskedInput";
import {MaskedInputProps} from "antd-mask-input/build/main/lib/MaskedInput";
import styled, {css} from "styled-components";
import classNames from "classnames";
import {ReactComponent as CallSvg} from "../Svg/Call.svg";
import {ReactComponent as CopySvg} from "../Svg/Copy.svg";
import {NotificationTypesEnum, openNotification} from "./Notification";
import {t} from "@lingui/macro";
import {container} from "tsyringe";
import {IClipboardHelper} from "../../../../components/ClipboardHelper/IClipboardHelper";
import {DiTokens} from "../../../../di-factory/DiTokens";

const Wrapper = styled.div`
  position: relative;
`;

const MaskedInputStyled = styled(MaskedInput)`
  &.oneBtn {
    padding-right: 40px;
  }

  &.twoBtns {
    padding-right: 90px;
  }
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
`;

const ButtonCss = css`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const CallBtnWrapper = styled.a`
  ${ButtonCss};

  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const CopyBtnWrapper = styled.div`
  ${ButtonCss};
  cursor: pointer;
`;

interface PhoneInputProps extends MaskedInputProps {
    showCopyBtn?: boolean;
    showCallBtn?: boolean;
}

export const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps & React.RefAttributes<InputRef>> = forwardRef<InputRef, PhoneInputProps>(
    ({showCopyBtn, showCallBtn, ...props}, ref) => {
        const buttonsAllowed = useMemo<boolean>(() => {
            return props.value !== '';
        }, [props.value]);

        const additionalClassName = useMemo<string>(() => {
            if ((!buttonsAllowed) || (!showCopyBtn && !showCallBtn)) {
                return '';
            }

            if (showCopyBtn && showCallBtn) {
                return 'twoBtns';
            }

            return 'oneBtn';
        }, [buttonsAllowed, showCallBtn, showCopyBtn]);

        const showBtnWrapper = useMemo<boolean>(() => {
            return !!(buttonsAllowed && (showCopyBtn || showCallBtn));
        }, [buttonsAllowed, showCallBtn, showCopyBtn]);

        const copyBtnOnClick = useCallback(() => {
            const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

            clipboardHelper.copyText(props.value ?? '')
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Номер скопирован`,
                        ''
                    );

                    return;
                })
                .catch(() => {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не получается скопировать. Попробуйте скопировать вручную.`,
                        ''
                    );
                });
        }, [props.value]);

        const buttons = useMemo<JSX.Element | null>(() => {
            if (!showBtnWrapper) {
                return null;
            }

            return <ButtonsWrapper>
                {
                    (showCallBtn) && <CallBtnWrapper href={'tel:+' + props.value}>
                    <CallSvg/>
                  </CallBtnWrapper>
                }
                {
                    (showCopyBtn) && <CopyBtnWrapper onClick={copyBtnOnClick}>
                    <CopySvg/>
                  </CopyBtnWrapper>
                }
            </ButtonsWrapper>
        }, [copyBtnOnClick, props.value, showBtnWrapper, showCallBtn, showCopyBtn]);


        return <Wrapper>
            <MaskedInputStyled ref={ref} className={classNames(additionalClassName, props.className)}
                               autoComplete="false" {...props}/>
            {buttons}
        </Wrapper>
    })
