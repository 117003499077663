import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import {ReactComponent as ArrowLeftRounded16Icon} from "../Ui/Svg/ArrowLeftRounded16.svg";
import {ReactComponent as ArrowRightRounded16Icon} from "../Ui/Svg/ArrowRightRounded16.svg";
import {ReactComponent as ThreeDots16Icon} from "../Ui/Svg/ThreeDots16.svg";
import {Trans} from "@lingui/macro";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 3.5em;
  cursor: pointer;
  font-size: 14px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

const NavigationBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  color: ${({theme}) => theme.colors.textSecondary};

  &.enabled {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const CurrentSlideNameWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const CurrentSlideNameText = styled.span`
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ThreeDotsIconStyled = styled(ThreeDots16Icon)`
  min-height: 16px;
  max-height: 16px;
`;

interface SelectedSlideNameWrapperProps {
    nextSlideId: string | null;
    prevSlideId: string | null;
    currentSlideId: string | null;
    currentSlideName: string;
    onSlideSelect: (slideId: string) => void;
    onClickByName: () => void;
}

export const SelectedSlideNameWrapper: React.FC<SelectedSlideNameWrapperProps> = (props) => {
    const {nextSlideId, prevSlideId, currentSlideName, currentSlideId, onSlideSelect, onClickByName} = props;

    const onPrevClick = () => {
        if (prevSlideId) {
            onSlideSelect(prevSlideId);
        }
    }

    const onNextClick = () => {
        if (nextSlideId) {
            onSlideSelect(nextSlideId);
        }
    }

    return <Wrapper>
        <NavigationBtnWrapper className={classNames(prevSlideId && "enabled")} onClick={onPrevClick}>
            <ArrowLeftRounded16Icon/>
        </NavigationBtnWrapper>
        <CurrentSlideNameWrapper onClick={onClickByName}>
            <CurrentSlideNameText>
                {
                    currentSlideId ? currentSlideName :
                        <Trans>Пока нет материалов</Trans>
                }
            </CurrentSlideNameText>
            <ThreeDotsIconStyled/>
        </CurrentSlideNameWrapper>
        <NavigationBtnWrapper className={classNames(nextSlideId && "enabled")} onClick={onNextClick}>
            <ArrowRightRounded16Icon/>
        </NavigationBtnWrapper>
    </Wrapper>
}