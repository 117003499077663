import React, {CSSProperties} from "react";
import {t} from "@lingui/macro";
import styled from "styled-components";
import classNames from "classnames";

export enum WeekDayType {
    NOMINATIVE,
    ACCUSATIVE,
    SHORT
}

export interface WeekDayNameByNumberProps {
    weekDayNumber: number;
    nameType: WeekDayType;
    style?: CSSProperties;
    className?: string;
    lowerCase?: boolean;
}

const StyledWrapper = styled.span`
  &.lower {
    text-transform: lowercase;
  }
`;

export const WeekDayNameByNumber: React.FC<WeekDayNameByNumberProps> = (props) => {

    const weekDayNameShort = () => {
        switch (props.weekDayNumber) {
            case 1: {
                return t`Пн`;
            }
            case 2: {
                return t`Вт`;
            }
            case 3: {
                return t`Ср`;
            }
            case 4: {
                return t`Чт`;
            }
            case 5: {
                return t`Пт`;
            }
            case 6: {
                return t`Сб`;
            }
            case 0: {
                return t`Вс`;
            }
        }
    }

    const weekDayNameNominative = () => {
        switch (props.weekDayNumber) {
            case 1: {
                return t`Понедельник`;
            }
            case 2: {
                return t`Вторник`;
            }
            case 3: {
                return t`Среда`;
            }
            case 4: {
                return t`Четверг`;
            }
            case 5: {
                return t`Пятница`;
            }
            case 6: {
                return t`Суббота`;
            }
            case 0: {
                return t`Воскресенье`;
            }
        }
    }

    const weekDayNameAccusative = () => {
        switch (props.weekDayNumber) {
            case 1: {
                return t`Понедельник`;
            }
            case 2: {
                return t`Вторник`;
            }
            case 3: {
                return t`Среду`;
            }
            case 4: {
                return t`Четверг`;
            }
            case 5: {
                return t`Пятницу`;
            }
            case 6: {
                return t`Субботу`;
            }
            case 0: {
                return t`Воскресенье`;
            }
        }
    }

    const weekDayName = () => {
        switch (props.nameType) {
            case WeekDayType.ACCUSATIVE: {
                return weekDayNameAccusative();
            }
            case WeekDayType.SHORT: {
                return weekDayNameShort();
            }
            case WeekDayType.NOMINATIVE:
            default: {
                return weekDayNameNominative();
            }
        }
    }

    return <StyledWrapper className={classNames(props.lowerCase && "lower", props.className)}
                          style={props.style}>{weekDayName()}</StyledWrapper>;
}
