import {IsNotEmpty, IsNumber, IsString} from "class-validator";

export class DtoTeacherInfo {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    longName: string;

    @IsString()
    smsPhone: string;

    @IsString()
    lastCall: string;

    @IsString()
    authToken: string;
}