import React from "react";
import {
    DtoSelfStudyTrackSubscriptionEpisodeOverviewItem
} from "../../../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionEpisodeOverviewItem";

export interface IEpisodeContext {
    episodeItem: DtoSelfStudyTrackSubscriptionEpisodeOverviewItem;
    prevEpisodeItem: DtoSelfStudyTrackSubscriptionEpisodeOverviewItem | null;
    thisIsNextAfterLastActive: boolean;
    secondsBeforeOpen: () => number;
}

export const EpisodeContext = React.createContext<IEpisodeContext>({
    episodeItem: {} as DtoSelfStudyTrackSubscriptionEpisodeOverviewItem,
    prevEpisodeItem: null,
    thisIsNextAfterLastActive: false,
    secondsBeforeOpen: () => 0
});

export const EpisodeContextProvider = EpisodeContext.Provider;