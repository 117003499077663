import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    DtoSubjectAreaLevel
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SubjetArea/DtoSubjectAreaLevel";
import {BtnStyleEnum, Button} from "../../Button";
import {t, Trans} from "@lingui/macro";
import {Modal, ModalControlParams} from "../../Modal";
import {PageTitle} from "../../../../../styles/global-elements";
import {PageLoadingStateEnum} from "../../../../../../enums/PageLoadingStateEnum";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {debounce, DebouncedFunc} from "lodash";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {ChipsFilterArea} from "../../ChipsFilterArea";
import {ResultList} from "../Common/ResultList";
import {DefaultLoader} from "../../../../DefaultLoader";
import {ErrorLoadingContent} from "../../ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText} from "../../NoticeBlock";
import {ResultListItem} from "../Common/ResultListItem";

const ITEM_PER_PAGE = 6;

interface SubjectAreaSelectModalWindowProps {
    trigger: React.ReactElement;
    onChange: (value: DtoSubjectAreaLevel) => void;
}

export const SubjectAreaSelectModalWindow: React.FC<SubjectAreaSelectModalWindowProps> = (
    {
        trigger,
        onChange
    }
) => {
    const apiToken = useSelector(sessionTokenSelector);

    const isFirstRender = useRef<boolean>(true);
    const logger = useRef<ILogger>(container.resolve<ILogger>(DiTokens.LOGGER));
    const abortController = useRef<AbortController | null>(null);
    const httpApiClient = useRef<IHttpApiClient>(
        container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT)
    );

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [searchString, setSearchString] = useState<string>('');
    const [itemsList, setItemsList] = useState<DtoSubjectAreaLevel[] | null>(null);
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const [totalItemsCount, setTotalItemsCount] = useState<number | null>(null);

    const fetchItems = useCallback(async () => {
        if (!apiToken) {
            return;
        }

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        if (abortController.current !== null) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        try {
            const result = await httpApiClient.current.getSubjectAreaList(
                apiToken,
                searchString,
                currentPageNum,
                ITEM_PER_PAGE,
                abortController.current
            );

            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            setItemsList(result.data.list);
            setTotalItemsCount(result.data.totalCount);
        } catch (e) {
            logger.current.error(LoggerSectionsEnum.SUBJECT_AREA_API, e);

            setPageLoadingState(PageLoadingStateEnum.ERROR);
        }
    }, [apiToken, currentPageNum, searchString]);

    const lastDebounced = useRef<DebouncedFunc<any> | null>(null);

    const fetchItemsDebounced = useMemo<DebouncedFunc<any>>(() => {
        lastDebounced.current?.cancel();

        lastDebounced.current = debounce(() => {
            if (currentPageNum !== 1) {
                setCurrentPageNum(1);
            } else {
                fetchItems().then();
            }
        }, 500);

        return lastDebounced.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchItems]);

    const onOpenWindow = useCallback(() => {
        setPageLoadingState(PageLoadingStateEnum.LOADING);
        setCurrentPageNum(1);
        setSearchString('');
        fetchItems().then();
    }, [fetchItems]);

    const onCloseWindow = useCallback(() => {
        lastDebounced.current?.cancel();

        if (abortController.current !== null) {
            abortController.current.abort();
            abortController.current = null;
        }
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        fetchItemsDebounced.cancel();

        fetchItems().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageNum]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        abortController.current?.abort();
        abortController.current = null;

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        fetchItemsDebounced();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(
        () => {
            isFirstRender.current = false;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const resultContent = (controls: ModalControlParams) => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.SUCCESS: {
                if ((itemsList === null) || (totalItemsCount === null)) {
                    return <DefaultLoader/>
                }

                if (itemsList.length === 0) {
                    return <NoticeBlock>
                        <NoticeBlockText>
                            <Trans>По вашему запросу не нашлось элементов</Trans>
                        </NoticeBlockText>
                    </NoticeBlock>
                }

                return <>
                    <ResultList
                        currentPageNum={currentPageNum}
                        itemsPerPage={ITEM_PER_PAGE}
                        onPageChange={setCurrentPageNum}
                        totalItemsCount={totalItemsCount}
                    >
                        {
                            itemsList.map((item) => {
                                return <ResultListItem key={item.id} onClick={() => {
                                    onChange(item);
                                    controls.closeModal();
                                }}>
                                    <span>{item.subjectArea.internationalName} {item.internationalName}</span>
                                </ResultListItem>;
                            })
                        }
                    </ResultList>
                </>;
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchItems}/>
            }
        }
    };

    return <Modal trigger={trigger}
                  onOpen={onOpenWindow}
                  onClose={onCloseWindow}
                  closeAllowed={true}
                  footer={(controls) => {
                      return <div>
                          <Button btnStyle={BtnStyleEnum.Secondary}
                                  onClick={() => controls.closeModal()}><Trans>Закрыть</Trans></Button>
                      </div>
                  }}
                  children={(controls) => {
                      return <div>
                          <PageTitle><Trans>Выбор дисциплины и уровня</Trans></PageTitle>
                          <div>
                              <ChipsFilterArea
                                  placeholder={t`Поиск по названию, например chinese hsk 1...`}
                                  searchString={searchString}
                                  onChange={setSearchString}
                              />
                              <div>
                                  {resultContent(controls)}
                              </div>
                          </div>
                      </div>
                  }}
    />
}