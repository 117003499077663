import {RenderElementProps} from "slate-react";
import React, {useMemo} from "react";
import {RegularLi, RegularOl, RegularText, RegularTitle, RegularUl} from "../element-views/General";
import {FillGapsCombobox} from "../element-views/FillGapsCombobox";
import {FillGapsText} from "../element-views/FillGapsText";
import {CorrectExistInInput} from "../element-views/CorrectExistInInput";
import {FillGapsDragDrop} from "../element-views/FillGapsDragDrop";
import {RadioList} from "../element-views/RadioList";
import {RadioListItem} from "../element-views/RadioListItem";
import {CheckboxList} from "../element-views/CheckboxList";
import {CheckboxListItem} from "../element-views/CheckboxListItem";
import {CustomComponentProps, CustomEditor} from "../RichTextEditor";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {Translated} from "../element-views/Translated";

export interface ElementProps extends RenderElementProps {
    editor: CustomEditor;
    paragraphCustomComponent?: React.FC<CustomComponentProps>;
    liCustomComponent?: React.FC<CustomComponentProps>;
}

export const Element: React.FC<ElementProps> = (props) => {
    const {
        attributes,
        children,
        element,
        paragraphCustomComponent,
        liCustomComponent
    } = props;

    const style = {textAlign: element.align}

    const ParagraphComponentMemo = useMemo<React.FC<CustomComponentProps>>(() => {
        if (paragraphCustomComponent) {
            return paragraphCustomComponent;
        }

        return RegularText;
    }, [paragraphCustomComponent]);

    const LiComponentMemo = useMemo<React.FC<CustomComponentProps>>(() => {
        if (liCustomComponent) {
            return liCustomComponent;
        }

        return RegularLi;
    }, [liCustomComponent]);

    switch (element.type) {
        case ElementTypes.BLOCK_QUOTE:
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )

        case ElementTypes.HEADING_ONE:
            return (
                <RegularTitle style={style} {...attributes}>
                    {children}
                </RegularTitle>
            )
        case ElementTypes.HEADING_TWO:
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case ElementTypes.NUMBERED_LIST:
            return (
                <RegularOl style={style} {...attributes}>
                    {children}
                </RegularOl>
            )
        case ElementTypes.BULLETED_LIST:
            return (
                <RegularUl style={style} {...attributes}>
                    {children}
                </RegularUl>
            )
        case ElementTypes.LIST_ITEM:
            return (
                <LiComponentMemo style={style} {...attributes}>
                    {children}
                </LiComponentMemo>
            )
        case ElementTypes.TRANSLATED:
            return (
                <Translated style={style} {...props}>
                    {children}
                </Translated>
            )
        case ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX:
            return (
                <FillGapsCombobox style={style} {...props}>
                    {children}
                </FillGapsCombobox>
            )
        case ElementTypes.EXERCISE_FILL_GAPS_TEXT:
            return (
                <FillGapsText style={style} {...props}>
                    {children}
                </FillGapsText>
            )
        case ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT:
            return (
                <CorrectExistInInput style={style} {...props}>
                    {children}
                </CorrectExistInInput>
            )
        case ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP:
            return (
                <FillGapsDragDrop style={style} {...props}>
                    {children}
                </FillGapsDragDrop>
            )
        case ElementTypes.EXERCISE_RADIO_LIST:
            return (
                <RadioList style={style} {...props}>
                    {children}
                </RadioList>
            )
        case ElementTypes.EXERCISE_RADIO_LIST_ITEM:
            return (
                <RadioListItem style={style} {...props}>
                    {children}
                </RadioListItem>
            )
        case ElementTypes.EXERCISE_CHECKBOX_LIST:
            return (
                <CheckboxList style={style} {...props}>
                    {children}
                </CheckboxList>
            )
        case ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM:
            return (
                <CheckboxListItem style={style} {...props}>
                    {children}
                </CheckboxListItem>
            )
        default:
            return (
                <ParagraphComponentMemo style={style} {...attributes}>
                    {children}
                </ParagraphComponentMemo>
            )
    }
}