import {IsNotEmpty, IsOptional, ValidateNested} from 'class-validator';
import {Type} from "class-transformer";
import {DtoSelfStudyTrackSubscriptionUserOverviewItem} from "./DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {DtoSelfStudyTrackEpisodeSurvey} from "./DtoSelfStudyTrackEpisodeSurvey";

/**
 * DTO Данные self-study трека для формы редактирования
 */
export class DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview {
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoSelfStudyTrackSubscriptionUserOverviewItem)
    subscriptionOverview: DtoSelfStudyTrackSubscriptionUserOverviewItem;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoSelfStudyTrackEpisodeSurvey)
    episodeSurvey: DtoSelfStudyTrackEpisodeSurvey | null;
}
