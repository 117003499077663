import {Descendant} from "slate/dist/interfaces/node";

export class NodesToPlainText {
    static convertNodesToPlainText(nodesList: Descendant[] | null): string {
        let result = '';

        if (nodesList === null) {
            return result;
        }

        nodesList.forEach((item: any) => {
            let childText = '';

            if (item.hasOwnProperty('children')) {
                childText = NodesToPlainText.convertNodesToPlainText(item.children)
            } else if (item.hasOwnProperty('text')) {
                childText = item.text;
            }

            result = result + ((result !== '') ? ' ' : '') + childText;
        });

        return result;
    }
}