import {call, takeEvery} from "redux-saga/effects";
import {AppActionTypes} from "../../store/app/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {DtoUpdateClientLocation} from "../../components/WsApiClient/ApiDto/Request/User/DtoUpdateClientLocation";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";

/**
 * Сага отвечает за отправку текущего местоположения
 */
export function* watchReportLocationPosition() {
    yield takeEvery(
        AppActionTypes.REPORT_LOCATION_POSITION,
        reportLocationPosition
    );
}

function* reportLocationPosition(data: WorkerPayloadType<string>) {
    let wsClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    const requestDto = new DtoUpdateClientLocation();

    requestDto.location = data.payload;

    yield call(() => wsClient.query(
        ApiMethodEnum.USER_UPDATE_CLIENT_LOCATION,
        requestDto,
        undefined,
        true
    ));
}
