import React, {useMemo, useState} from "react";
import {t} from "@lingui/macro";

interface GreetingTextProps {
    userName: string | null;
}

export const GreetingText: React.FC<GreetingTextProps> = ({userName}) => {
    const [currentTs,] = useState<Date>(new Date());

    const dayPartString = useMemo(() => {
        const hour = currentTs.getHours();

        let string = t`Здравствуйте`;

        if ((hour >= 5) && (hour < 10)) {
            string = t`Доброе утро`;
        } else if ((hour >= 10) && (hour < 18)) {
            string = t`Добрый день`;
        } else if ((hour >= 18) && (hour <= 23)) {
            string = t`Добрый вечер`;
        }

        return string;
    }, [currentTs]);

    return (userName) ? <>{dayPartString}, {userName}</> : <>{dayPartString}</>
}