import React from "react";
import {DtoTmDiscipline} from "../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {DtoTmLevel} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {cloneDeep} from "lodash";
import styled from "styled-components";
import {RegularTextCss} from "../../../../styles/global-elements";
import {ReactComponent as PlusIcon} from "../../../Ui/Svg/Plus.svg";
import {t, Trans} from "@lingui/macro";
import {ModalResultType as DisciplineModalResultType} from "../../modals/discipline-modal";
import {ModalResultType as LevelModalResultType, TmLevelModal} from "../../modals/level-modal";
import {NoticeBlock, NoticeBlockText} from "../../../Ui/Elements/NoticeBlock";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../DefaultLoader";
import {ErrorLoadingContent} from "../../../Ui/Elements/ErrorLoadingContent";
import {DisciplineIconTypeEnum, DisciplineTitle} from "./DisciplineTitle";
import {LevelsList} from "./LevelsList";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";

const LEVEL_ITEMS_PER_PAGE = 10;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

const Wrapper = styled.div`
  width: 100%;
`;

const LevelsListWrapper = styled.div``;

const EmptyNoticeBlockWrapper = styled.div`
  padding: 0 30px;
`;

const LevelsActionsWrapper = styled.div``;
const LevelsActionsList = styled.ul`
  padding: 0;
  list-style: none;
`;
const LevelsActionItem = styled.li`
  ${RegularTextCss};
  color: ${({theme}) => theme.colors.accentPrimary};
  padding-left: 5px;
  padding-bottom: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &:hover {
    text-decoration: underline;
  }

  &.no-icon {
    padding-left: 45px;
  }
`;

const LevelsActionItemText = styled.div`
  padding-top: 5px;
`;

interface DisciplineItemProps {
    apiToken: string;
    organizationId: string;
    selectedDiscipline: DtoTmDiscipline;
    initTotalLevelsCount: number;
    initLevelItems: DtoTmLevel[];
    modalOnResult: (action: DisciplineModalResultType, item: (DtoTmDiscipline | null)) => void;
    navigateToLevelMethod?: (toLevelId: string) => void;
    selectMode?: boolean;
    isUsed?:boolean;
    usedDisciplineIds?: string[]; // отмечаются кружком дисциплины из списка, последняя из списка выделяется флажком
    usedLevelIds?: string[]; // отмечаются кружком уровни из списка, последняя из списка выделяется флажком
}

interface DisciplineItemState {
    loadingState: LoadingState;
    savingLevelOrderNow: boolean;
    levelItems: DtoTmLevel[];
    totalLevelsCount: number | null;
}

export class DisciplineItem extends React.Component<DisciplineItemProps, DisciplineItemState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    constructor(props: Readonly<DisciplineItemProps> | DisciplineItemProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.SUCCESS,
            levelItems: cloneDeep(this.props.initLevelItems),
            totalLevelsCount: this.props.initTotalLevelsCount,
            savingLevelOrderNow: false
        }
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }
    }

    protected resetList = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING,
                levelItems: [],
                totalLevelsCount: null
            }
        }, () => {
            this.fetchLevels().then()
        });
    }

    protected fetchLevels = async () => {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING
            }
        });

        try {
            const items = await this.apiClient.tmGetLevels(
                this.props.apiToken,
                this.props.selectedDiscipline.id,
                (this.state.levelItems.length === 0)
                    ? 1
                    : (Math.round(this.state.levelItems.length / LEVEL_ITEMS_PER_PAGE) + 1),
                LEVEL_ITEMS_PER_PAGE
            );

            this.setState((state) => {
                return {
                    levelItems: [...this.state.levelItems, ...items.data.list],
                    totalLevelsCount: items.data.totalCount,
                    loadingState: LoadingState.SUCCESS
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            this.logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error loading tm levels: ", e);
        }
    }

    protected levelModalOnResult = (action: LevelModalResultType, editedItem: (DtoTmLevel | null)) => {
        switch (action) {
            case LevelModalResultType.CREATED:
            case LevelModalResultType.DELETED: {
                this.resetList();

                return;
            }
            case LevelModalResultType.UPDATED: {
                if (editedItem === null) {
                    return;
                }

                this.setState((state) => {
                    return {
                        levelItems: state.levelItems.map((item) => {
                            if (item.id === editedItem.id) {
                                return editedItem;
                            }

                            return item;
                        })
                    }
                })
            }
        }
    }

    protected reorderLevelItems = (from: number, to: number) => {
        const firstItemId = this.state.levelItems[from].id;
        const secondItemId = this.state.levelItems[to].id;

        const newLevelsArray = arrayMove(this.state.levelItems, from, to);

        this.setState(() => {
            return {
                levelItems: newLevelsArray,
                savingLevelOrderNow: true
            }
        });

        this.apiClient.tmSwapLevelOrderPosition(this.props.apiToken, firstItemId, secondItemId)
            .catch((err) => {
                this.logger.error(
                    LoggerSectionsEnum.TM_DISCIPLINE_LEVELS_API,
                    'Error on reorder discipline level list: ',
                    err
                );

                this.setState(() => {
                    return {
                        levelItems: arrayMove(newLevelsArray, to, from)
                    }
                });

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок уровней дисциплины. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                this.setState(() => {
                    return {
                        savingLevelOrderNow: false
                    }
                });
            });
    }

    protected disciplineIcon = ():DisciplineIconTypeEnum => {
        if ((this.props.usedDisciplineIds === undefined) || (this.props.usedDisciplineIds.length === 0)) {
            return DisciplineIconTypeEnum.NO_USED;
        }

        const index = this.props.usedDisciplineIds.indexOf(this.props.selectedDiscipline.id);

        if (index === -1) {
            return DisciplineIconTypeEnum.NO_USED;
        }

        if (index === this.props.usedDisciplineIds.length-1) {
            return DisciplineIconTypeEnum.LAST_USED;
        }

        return DisciplineIconTypeEnum.USED;
    }

    render() {
        return <Wrapper>

            <DisciplineTitle selectedDiscipline={this.props.selectedDiscipline}
                             totalLevelsCount={this.state.totalLevelsCount}
                             organizationId={this.props.organizationId}
                             selectMode={this.props.selectMode}
                             iconType={this.disciplineIcon()}
                             modalOnResult={this.props.modalOnResult}/>

            <LevelsListWrapper>
                {
                    this.state.totalLevelsCount === 0
                    && <EmptyNoticeBlockWrapper><NoticeBlock><NoticeBlockText>
                        <Trans
                            id={"Теперь для материалов дисциплины «{Имя}» можно добавить уровень. Например «1 класс», «Начинающий», «Beginner» и т.п."}
                            values={{'Имя': this.props.selectedDiscipline.name}}/>
                    </NoticeBlockText></NoticeBlock></EmptyNoticeBlockWrapper>
                }
                {
                    this.state.levelItems.length > 0
                    && <LevelsList levelItems={this.state.levelItems}
                                   reorderItems={(this.props.selectMode === true) ? undefined : this.reorderLevelItems}
                                   itemsDisabled={this.state.savingLevelOrderNow}
                                   disciplineId={this.props.selectedDiscipline.id}
                                   navigateToLevelMethod={this.props.navigateToLevelMethod}
                                   modalOnResult={this.levelModalOnResult}
                                   selectMode={this.props.selectMode}
                                   usedLevelIds={this.props.usedLevelIds}
                    />
                }
            </LevelsListWrapper>

            {(this.state.loadingState === LoadingState.LOADING) && <DefaultLoader/>}
            {(this.state.loadingState === LoadingState.ERROR) &&
                <ErrorLoadingContent retryBtnClick={this.fetchLevels}/>}

            <LevelsActionsWrapper>
                <LevelsActionsList>
                    {
                        (
                            (this.state.loadingState === LoadingState.SUCCESS)
                            && (this.state.levelItems.length > 0)
                            && (this.state.totalLevelsCount !== null)
                            && (this.state.totalLevelsCount > this.state.levelItems.length)
                        )
                        && <LevelsActionItem className={"no-icon"}>
                            <LevelsActionItemText onClick={this.fetchLevels}>
                                <Trans>Показать ещё</Trans>
                            </LevelsActionItemText>
                        </LevelsActionItem>
                    }
                    {
                        (this.props.selectMode !== true)
                        && <TmLevelModal levelItem={null} disciplineId={this.props.selectedDiscipline.id}
                                         result={this.levelModalOnResult}
                                         trigger={
                                             <LevelsActionItem>
                                                 <PlusIcon/>
                                                 <LevelsActionItemText><Trans>Добавить
                                                     уровень</Trans></LevelsActionItemText>
                                             </LevelsActionItem>
                                         }/>
                    }
                </LevelsActionsList>
            </LevelsActionsWrapper>
        </Wrapper>
    }
}