import React, {useContext, useEffect, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin} from "../../../../../styles/global-elements";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import {Form, FormItem, FormUseWatch, useForm} from "../../../../../components/Ui/Elements/Form";
import {ScheduleForm} from "../../common/ScheduleForm";
import styled from "styled-components";
import {container} from "tsyringe";
import {IGroupScheduleReader} from "../../../../../components/GroupScheduleReader/IGroupScheduleReader";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {DatePicker} from "../../../../../components/Ui/Elements/DatePicker";
import {ButtonArea} from "../ButtonArea";
import {IStepWizardContext, StepWizardContext} from "../../../../../components/StepWizard/StepWizardContext";
import {StepWizardStatusEnum} from "../../../../../components/StepWizard/StepWizardStatusEnum";
import {
    ScheduleFormFieldsEnum as ScheduleFormFields
} from "../../../../../components/GroupScheduleReader/ScheduleFormFieldsEnum";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import moment from "moment";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const StartFromInputWrapper = styled.div`
  max-width: 300px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ScheduleFormStyled = styled(ScheduleForm)`
  margin-bottom: 30px;
`;

const EmptyScheduleText = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.accentSecondary};
  margin-bottom: 20px;
`;

enum FormFieldNamesEnum {
    START_FROM = 'start_from'
}

export const NewAgreementScheduleSection: React.FC = () => {
    const [form] = useForm();

    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const [emptyScheduleError, setEmptyScheduleError] = useState<boolean>(false);

    const mondayTimeStartValue = FormUseWatch(ScheduleFormFields.MONDAY_TIME_START, form);
    const mondayTimeEndValue = FormUseWatch(ScheduleFormFields.MONDAY_TIME_END, form);
    const tuesdayTimeStartValue = FormUseWatch(ScheduleFormFields.TUESDAY_TIME_START, form);
    const tuesdayTimeEndValue = FormUseWatch(ScheduleFormFields.TUESDAY_TIME_END, form);
    const wednesdayTimeStartValue = FormUseWatch(ScheduleFormFields.WEDNESDAY_TIME_START, form);
    const wednesdayTimeEndValue = FormUseWatch(ScheduleFormFields.WEDNESDAY_TIME_END, form);
    const thursdayTimeStartValue = FormUseWatch(ScheduleFormFields.THURSDAY_TIME_START, form);
    const thursdayTimeEndValue = FormUseWatch(ScheduleFormFields.THURSDAY_TIME_END, form);
    const fridayTimeStartValue = FormUseWatch(ScheduleFormFields.FRIDAY_TIME_START, form);
    const fridayTimeEndValue = FormUseWatch(ScheduleFormFields.FRIDAY_TIME_END, form);
    const saturdayTimeStartValue = FormUseWatch(ScheduleFormFields.SATURDAY_TIME_START, form);
    const saturdayTimeEndValue = FormUseWatch(ScheduleFormFields.SATURDAY_TIME_END, form);
    const sundayTimeStartValue = FormUseWatch(ScheduleFormFields.SUNDAY_TIME_START, form);
    const sundayTimeEndValue = FormUseWatch(ScheduleFormFields.SUNDAY_TIME_END, form);

    useEffect(() => {
            const scheduleReader = container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER);
            const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

            const scheduleDto = scheduleReader.createScheduleDtoByForm(form.getFieldsValue());

            const scheduleAsArray = scheduleReader.readSchedule(scheduleDto);

            let nearestDate: Date | null = null;

            scheduleAsArray.forEach(item => {
                const date = dateHelper.getNextDayOfTheWeek(item.weekDayNum, false);

                if (nearestDate === null) {
                    nearestDate = date;
                } else {
                    if (date < nearestDate) {
                        nearestDate = date;
                    }
                }
            });

            form.setFieldValue(FormFieldNamesEnum.START_FROM, (nearestDate) ? moment(nearestDate) : '');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            mondayTimeStartValue,
            mondayTimeEndValue,
            tuesdayTimeStartValue,
            tuesdayTimeEndValue,
            wednesdayTimeStartValue,
            wednesdayTimeEndValue,
            thursdayTimeStartValue,
            thursdayTimeEndValue,
            fridayTimeStartValue,
            fridayTimeEndValue,
            saturdayTimeStartValue,
            saturdayTimeEndValue,
            sundayTimeStartValue,
            sundayTimeEndValue
        ]
    );


    const onFinish = (values: any) => {
        const scheduleReader = container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER);

        const scheduleDto = scheduleReader.createScheduleDtoByForm(values);

        if (scheduleDto.weekDays.trim() === '') {
            setEmptyScheduleError(true);

            return;
        }

        let startDate = values[FormFieldNamesEnum.START_FROM];

        startDate = (startDate)
            ? startDate.format('YYYY-MM-DD')
            : null;

        pageContext.setFormData((oldValues) => {
            return {
                ...oldValues,
                schedule: scheduleDto,
                startDate: startDate
            }
        });

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.SCHEDULE,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    return <div>
        <FormWrapper>
            <Form form={form} layout={"inline"} onFinish={onFinish}>
                <FormItemsWrapper>
                    <PageSubtitleSmallMargin>
                        <Trans>В какое время {pageContext.shortStudentName} будет заниматься?</Trans>
                    </PageSubtitleSmallMargin>

                    <ScheduleFormStyled form={form} scheduleData={null}/>

                    {
                        (emptyScheduleError)
                        && <EmptyScheduleText>
                        <Trans>Необходимо указать расписание.</Trans><br/>
                        <Trans>Если не знаете точно, можно задать любой день и время - в дальнейшем это можно
                          легко изменить.</Trans>
                      </EmptyScheduleText>
                    }

                    <PageSubtitleSmallMargin>
                        <Trans>Когда планируется первое занятие?</Trans>
                    </PageSubtitleSmallMargin>

                    <StartFromInputWrapper>
                        <FormItem
                            name={FormFieldNamesEnum.START_FROM}
                            label={<Trans>Дата первого занятия</Trans>}
                            initialValue={(pageContext.formData.startDate) ? moment(pageContext.formData.startDate) : null}
                            rules={[
                                {
                                    required: true,
                                    message: t`Необходимо указать дату первого занятия`,
                                }
                            ]}
                        >
                            <DatePicker/>
                        </FormItem>
                    </StartFromInputWrapper>
                </FormItemsWrapper>
            </Form>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}

            nextBtnOnClick={async () => {
                await form.validateFields();
                form.submit();
            }}
        />
    </div>
}
