import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import styled from "styled-components";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {ParagraphDefaultStyle} from "../../components/RichTextReader/elements/Paragraph";
import {VoiceoverPlayer} from "./VoiceoverPlayer";
import {VoiceoverPlayerMethods} from "./VoiceoverPlayerMethods";
import {PlayerTypeEnum} from "./VoiceoverPlayer/PlayerTypeEnum";

const NameCustomParagraphComponent = styled(ParagraphDefaultStyle)`
    margin-bottom: 0;
    font-size: 13px;
    color: ${({theme}) => theme.colors.textSecondary};
`;

const CustomParagraphComponent = styled(ParagraphDefaultStyle)`
    margin-bottom: 0;
    display: inline;
    vertical-align: middle;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const NameWrapper = styled.div`
    margin-bottom: 2px;
`;

const TextAreaWrapper = styled.div`
    flex-grow: 1;
`;

const SmallPlayerWrapper = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 6px;
`;

const TextWrapper = styled.div`
    flex-grow: 1;
`;

export interface BubbleContentMethods {
    playVoiceover: () => void;
}

interface BubbleContentProps {
    name: CustomElement[];
    text: CustomElement[];
    audioFileId: string | null;
    onReady: () => void;
    onError: () => void;
    onEnd: () => void;
    alreadyCompleted: boolean;
    className?: string;
}

export const BubbleContent = forwardRef<BubbleContentMethods, BubbleContentProps>((
    {
        name, 
        text, 
        audioFileId, 
        onReady, 
        onError,
        onEnd,
        className,
        alreadyCompleted
    },
    ref
) => {
    const voiceoverPlayerRef = useRef<VoiceoverPlayerMethods>(null);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        playVoiceover: () => {
            voiceoverPlayerRef.current?.startPlay();
        }
    }));

    const content = useMemo(() => {
        if (!text && !audioFileId) {
            return <div>No content</div>;
        }

        if (!text && audioFileId) {
            return <VoiceoverPlayer
                ref={voiceoverPlayerRef}
                audioFileId={audioFileId}
                playerType={PlayerTypeEnum.FULL}
                onReady={onReady}
                onError={onError}
                onEnd={onEnd}
                alreadyCompleted={alreadyCompleted}
            />;
        }

        return <TextAreaWrapper>
            <TextWrapper>
                {
                    (audioFileId)
                    && <SmallPlayerWrapper>
                        <VoiceoverPlayer
                            ref={voiceoverPlayerRef}
                            audioFileId={audioFileId}
                            playerType={PlayerTypeEnum.SMALL}
                            onReady={onReady}
                            onError={onError}
                            onEnd={onEnd}
                            alreadyCompleted={alreadyCompleted}
                        />
                    </SmallPlayerWrapper>
                }

                <RichTextReader
                    elements={text}
                    paragraphComponent={CustomParagraphComponent}
                />
            </TextWrapper>
        </TextAreaWrapper>
    }, [alreadyCompleted, audioFileId, onEnd, onError, onReady, text]);

    return <Wrapper className={className}>
        {
            (name)
            && <NameWrapper>
                <RichTextReader
                    elements={name}
                    paragraphComponent={NameCustomParagraphComponent}
                />
            </NameWrapper>
        }
        {content}
    </Wrapper>
});