import {call, put, select, takeEvery} from 'redux-saga/effects';
import {AuthUserRequestContext, UserActionTypes} from "../../store/user/type";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {Login} from "../../components/WsApiClient/ApiDto/Request/Auth/Login";
import {sessionTokenSelector} from "../../store/app/selector";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import * as AppActionCreators from "../../store/app/actions";
import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";

/**
 * Сага запускает процесс авторизации пользователя по токену авторизации.
 */

export function* watchStartUserAuthOnWsConnection() {
    yield takeEvery(
        UserActionTypes.START_USER_AUTH_ON_WS_CONNECTION,
        startUserAuthOnWsConnection
    );
}

function* startUserAuthOnWsConnection() {
    let wsApiClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.UNAUTHORIZED));

    let userAuthToken = (yield select(sessionTokenSelector)) as string | null;

    if (userAuthToken === null) {
        throw new Error('Ws connection activated, but session token is empty');
    }

    yield call(async () => {
            if (userAuthToken === null) {
                return;
            }

            await wsApiClient.query(
                ApiMethodEnum.AUTH_BY_AUTH_KEY,
                new Login(
                    userAuthToken
                ),
                new AuthUserRequestContext(false)
            )
        }
    );
}
