import {Reducer} from "redux";
import produce from "immer";
import {vscStateInitialState} from "./initialState";
import {VcsStateActionTypes, VcsStateType} from "./types";
import {cloneDeep} from "lodash";

const reducer: Reducer<VcsStateType> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case VcsStateActionTypes.SET_STATUS: {
            draft.status = action.payload;

            break;
        }

        case VcsStateActionTypes.SET_PARTICIPANTS: {
            draft.participants = cloneDeep(action.payload);

            break;
        }

        case VcsStateActionTypes.SET_AUDIO_ROOM_STATE: {
            draft.audioRoomState = cloneDeep(action.payload);

            break;
        }

        case VcsStateActionTypes.SET_VIDEO_ROOM_STATE: {
            draft.videoRoomState = cloneDeep(action.payload);

            break;
        }

        case VcsStateActionTypes.RESET_STATE: {
            draft = cloneDeep(vscStateInitialState);

            break;
        }
    }
}, vscStateInitialState);

export {reducer as vcsStateReducer};
