import {IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator';

/**
 * DTO элемента ответа на запрос состояния платежа
 */
export class DtoOnlinePaymentOrderStateResponse {
    @IsNotEmpty()
    @IsString()
    id: string;

    @IsNotEmpty()
    @IsNumber()
    sum: number;

    @IsNotEmpty()
    @IsString()
    description: string;

    @IsNotEmpty()
    @IsNumber()
    status: number;

    @IsNotEmpty()
    @IsString()
    studentAgreementId: string;

    @IsNotEmpty()
    @IsString()
    studentInSchoolId: string;

    @IsNotEmpty()
    @IsString()
    createdAt: string;

    @IsOptional()
    @IsString()
    lastStatusCheckAt: string | null;
}