import {Editor, Element as SlateElement, Node as SlateNode, NodeEntry, Transforms} from "slate";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withOnlyTitles = (editor: Editor) => {
    const {normalizeNode} = editor

    editor.normalizeNode = (entry: NodeEntry<SlateNode>) => {
        const [node, path] = entry;

        // If the element is a paragraph, ensure its children are valid.
        if (SlateElement.isElement(node)) {
            if (node.type === ElementTypes.HEADING_ONE) {
                for (const [child, childPath] of Array.from(SlateNode.children(editor, path))) {
                    if (SlateElement.isElement(child) && !editor.isInline(child)) {
                        Transforms.unwrapNodes(editor, {at: childPath})
                        return;
                    }
                }
            } else if (node.type === ElementTypes.TRANSLATED) {
            } else {
                Transforms.setNodes(editor, {type: ElementTypes.HEADING_ONE}, {at: path});
                return;
            }
        }

        normalizeNode(entry)
    }

    return editor
}