import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../store/lessonRoom/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ApplicationState} from "../../store";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {setMemberOpenedSlideId} from "../../store/lessonRoom/actions";
import {
    DtoSEParticipantCurrentSlideInfo
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEParticipantCurrentSlideInfo";

/**
 * Обработка сообщения об изменении активного слайда у ученика
 */
export function* watchHandleRoomMemberOpenedSlideChanged() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEParticipantCurrentSlideInfo>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_OPENED_SLIDE_CHANGED,
        handleRoomMemberOpenedSlideChanged
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleRoomMemberOpenedSlideChanged(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEParticipantCurrentSlideInfo>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    yield put(setMemberOpenedSlideId(
        data.payload.response.dto.payload.userId,
        data.payload.response.dto.payload.slideId
    ));
}
