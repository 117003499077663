import React from 'react';
import styled from "styled-components";
import {Trans} from "@lingui/macro";
import {ModesList} from "./index";
import {ReactComponent as SlidesSvg} from "./icons/Slides.svg";
import {ReactComponent as ElementsSvg} from "./icons/Elements.svg";
import classNames from "classnames";

const ModeSelectorStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 32px;
`;

const ModeItemWrapper = styled.div`
  text-align: center;
  max-width: 50%;
  overflow: hidden;
`;

const ModeItem = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  :hover, &.active {
    opacity: 1;
  }
`;

const ModeItemIconWrapper = styled.div`
  min-width: 40px;
`;

const ModeItemTextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  font-weight: bold;
  font-size: 16px;
`;

interface ModeSelectorProps {
    activeMode: ModesList;
    setActiveItem: (activeItem: ModesList) => void;
}

export const ModeSelector: React.FC<ModeSelectorProps> = (props) => {
    return <ModeSelectorStyled>
        <ModeItemWrapper>
            <ModeItem className={classNames((props.activeMode === ModesList.SLIDES_LIST) && "active")}
                      onClick={event => {
                          props.setActiveItem(ModesList.SLIDES_LIST)
                      }}>
                <ModeItemIconWrapper>
                    <SlidesSvg/>
                </ModeItemIconWrapper>
                <ModeItemTextWrapper>
                    <Trans>Слайды</Trans>
                </ModeItemTextWrapper>
            </ModeItem>
        </ModeItemWrapper>
        <ModeItemWrapper>
            <ModeItem className={classNames((props.activeMode === ModesList.ELEMENTS_PALETTE) && "active")}
                      onClick={event => {
                          props.setActiveItem(ModesList.ELEMENTS_PALETTE)
                      }}
            >
                <ModeItemIconWrapper>
                    <ElementsSvg/>
                </ModeItemIconWrapper>
                <ModeItemTextWrapper>
                    <Trans>Элементы</Trans>
                </ModeItemTextWrapper>
            </ModeItem>
        </ModeItemWrapper>
    </ModeSelectorStyled>
}