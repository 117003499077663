import {call, put, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../store/lessonRoom/type";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {clearRoomState} from "../../store/lessonRoom/actions";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {resetLessonMaterialsState} from "../../store/lessonMaterials/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";

/**
 * Сага запускается, когда пользователь выходит из комнаты урока через переход роутером на другую страницу
 * Нужно послать сообщение о переходе и вызвать ws метод leave from room
 */
export function* watchHandleLeaveFromRoomViaRouter() {
    yield takeEvery<WorkerPayloadType<string>>(
        LessonRoomActionTypes.HANDLE_LEAVE_FROM_ROOM_VIA_ROUTER,
        handleLeaveFromRoomViaRouter
    );
}

function* handleLeaveFromRoomViaRouter(data: WorkerPayloadType<string>) {
    const wsClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.LESSON_ROOM, 'Started LeaveFromRoomViaRouter saga');

    yield put(clearRoomState());
    yield put(resetLessonMaterialsState());

    yield call(() => wsClient.query(
            ApiMethodEnum.LESSON_ROOM_LEAVE_FROM_ROOM,
            {},
            null,
            true
        )
    );
}
