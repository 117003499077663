export enum DeviceKindEnum {
    AUDIO_INPUT = 'audioinput',
    AUDIO_OUTPUT = 'audiooutput',
    VIDEO_INPUT = 'videoinput',
    UNKNOWN = 'unknown'
}

export interface DeviceInfo {
    kind: DeviceKindEnum;
    id: string;
    name: string;
    group: string;
}

export interface DevicesForCall {
    audioInput: DeviceInfo | null;
    audioOutput: DeviceInfo | null;
    videoInput: DeviceInfo | null;
}

export type SubscriberCallbackFunc = (deviceId: DeviceInfo) => void;

export interface SubscriberItem {
    id: string;
    kind: string;
    func: SubscriberCallbackFunc;
}

