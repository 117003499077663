import {DtoTmSlideContent} from "../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContent";
import {EditorData, EditorDataItem, EditorItemDataParams, EditorItemInteractivityConfig} from "./EditorData";
import {ElementTypeEnum} from "./ElementTypeEnum";
import {DtoTmSlideContentItem} from "../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContentItem";
import {
    DtoTmSlideContentItemParams
} from "../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContentItemParams";
import {transformAndValidateSync} from "class-transformer-validator";

export class EditorDataDtoMapper {
    /**
     * @param dtoModel
     */
    static dtoToEditorData(dtoModel: DtoTmSlideContent | null): EditorData {
        const editorData = new EditorData();

        if (dtoModel === null) {
            return editorData;
        }

        editorData.items = dtoModel.items.map((itemFromDto): EditorDataItem<any> => {
            const dataItem = new EditorDataItem();

            let paramsDto = itemFromDto.params;

            if (paramsDto === undefined) {
                paramsDto = new DtoTmSlideContentItemParams();
                paramsDto.visible = true;
            }

            const paramsConverted = transformAndValidateSync(EditorItemDataParams, paramsDto);

            let interactivityConfig = null;

            if (itemFromDto.interactivityConfig) {
                interactivityConfig = transformAndValidateSync(
                    EditorItemInteractivityConfig,
                    itemFromDto.interactivityConfig
                );
            }

            dataItem.id = itemFromDto.id;
            dataItem.type = (itemFromDto.type as ElementTypeEnum);
            dataItem.alias = (itemFromDto.alias === undefined) ? null : itemFromDto.alias;
            dataItem.params = paramsConverted;
            dataItem.interactivityConfig = interactivityConfig;
            dataItem.exercisesCount = itemFromDto.exercisesCount;
            dataItem.data = (itemFromDto.data === null) ? null : JSON.parse(itemFromDto.data);

            return dataItem;
        })

        return editorData;
    }

    /**
     * @param editorDataModel
     */
    static editorDataToDto(editorDataModel: EditorData): DtoTmSlideContent {
        const dtoData = new DtoTmSlideContent();

        dtoData.items = editorDataModel.items.map((itemFromEditorModel): DtoTmSlideContentItem => {
            const dataItem = new DtoTmSlideContentItem();

            dataItem.id = itemFromEditorModel.id;
            dataItem.type = (itemFromEditorModel.type as ElementTypeEnum);
            dataItem.alias = itemFromEditorModel.alias;
            dataItem.params = itemFromEditorModel.params;
            dataItem.interactivityConfig = itemFromEditorModel.interactivityConfig;
            dataItem.exercisesCount = itemFromEditorModel.exercisesCount;
            dataItem.data = (itemFromEditorModel.data === null) ? null : JSON.stringify(itemFromEditorModel.data);


            return dataItem;
        })

        return dtoData;
    }
}