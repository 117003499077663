import React, {useEffect, useMemo, useState} from 'react';
import {ElementData, VIDEO_ELEMENT_HEIGHT, VIDEO_ELEMENT_HEIGHT_MOBILE} from "./Common";
import styled from "styled-components";
import {PresenterPlaceholder, PresenterPlaceholderShowModeEnum} from "./PresenterPlaceholder";
import {t} from "@lingui/macro";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../../store/fileFormatsRepo/type";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../../store/fileFormatsRepo/selector";
import * as FileFormatsRepoActionCreators from "../../../../../store/fileFormatsRepo/actions";
import {QualityItem, VideoPlayer} from "../../../Ui/Elements/VideoPlayer";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";

interface PresenterComponentProps {
    elementData: ElementData
}

enum PresenterInternalState {
    WAIT_FOR_INIT,
    NO_IMAGE,
    FETCHING_LINK,
    READY,
    ERROR
}

const PresenterWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const PresenterComponent: React.FC<PresenterComponentProps> = (props) => {
    const {elementData} = props;
    const fileRepoState = useSelector(repoStateSelector);

    const dispatch = useDispatch();

    const [state, setState] = useState<PresenterInternalState>(PresenterInternalState.WAIT_FOR_INIT);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;
    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
        if (elementData.videoId === null) {
            return null
        }

        const itemIndex = fileRepoState.indexByFileId[elementData.videoId];

        if (itemIndex === undefined) {
            return null;
        }

        return fileRepoState.files[itemIndex] ?? null;
    }, [fileRepoState, elementData]);


    const fileLinks = useMemo<QualityItem[] | null>(() => {
        if (currentFileInFileRepo === null) {
            return null;
        }

        if (currentFileInFileRepo.availableFormats.length === 0) {
            return null;
        }

        return currentFileInFileRepo.availableFormats.map((item): QualityItem => {
            return {
                mimeType: item.mimeType,
                url: item.url,
                format: item.formatType
            }
        });
    }, [currentFileInFileRepo]);

    useEffect(() => {
        const fetchNewFileInfo = (fileId: string) =>
            dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

        if (elementData.videoId === null) {
            setState(PresenterInternalState.NO_IMAGE);

            return;
        }

        if (currentFileInFileRepo === null) {
            // Запросим ссылку
            fetchNewFileInfo(elementData.videoId);
            setState(PresenterInternalState.FETCHING_LINK);

            return;
        }

        switch (currentFileInFileRepo.fetchInfoStatus) {
            case FileFetchInfoProcessStatus.WAIT_FOR_START:
            case FileFetchInfoProcessStatus.IN_PROCESS: {
                setState(PresenterInternalState.FETCHING_LINK);

                break;
            }
            case FileFetchInfoProcessStatus.SUCCESS: {
                setState(PresenterInternalState.READY);

                break;
            }
            default: {
                setState(PresenterInternalState.ERROR);

                break;
            }
        }
    }, [currentFileInFileRepo, dispatch, elementData]);

    const elementHeight = useMemo<string>(() => {
        return isSmall
            ? VIDEO_ELEMENT_HEIGHT.toString(10) + 'px'
            : VIDEO_ELEMENT_HEIGHT_MOBILE.toString(10) + 'px';
    }, [isSmall]);

    const content = () => {
        switch (state) {
            case PresenterInternalState.WAIT_FOR_INIT:
            case PresenterInternalState.FETCHING_LINK: {
                return <div style={{height: elementHeight}}><PresenterPlaceholder
                    showMode={PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING}
                    text={t`Картинка загружается...`}
                /></div>;
            }
            case PresenterInternalState.READY: {
                if (fileLinks) {
                    return <VideoPlayer qualityItems={fileLinks} height={elementHeight}/>;
                }
            }
        }

        return <PresenterPlaceholder
            showMode={PresenterPlaceholderShowModeEnum.LOADING_ERROR}
            text={t`Ошибка загрузки видео`}
        />;
    }

    return <PresenterWrapper>
        {content()}
    </PresenterWrapper>
}