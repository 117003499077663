import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {ITheme} from "../../../services/theme/ITheme";
import useMediaQuery from "../../../services/hooks/useMediaQuery";
import {PageTitle} from "../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {BottomNavigationBar} from "../PageNavigation/BottomNavigationBar";
import {RightNavigationArea} from "../PageNavigation/RightNavigationArea";
import {PageNavigationItem} from "../PageNavigation/CommonTypes";
import {PageBackLink} from "../Ui/Elements/PageBackLink";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  max-width: 680px;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
  }
`;

export interface PageWithNavigationItem {
    key: string;
    icon: JSX.Element;
    name: JSX.Element;
    body: JSX.Element;
    link?: string;
}

interface BackLink {
    name: string;
    link: string;
}

interface PageWithNavigationProps {
    title: string;
    activeSection: string;
    items: PageWithNavigationItem[];
    onSelectSection?: (key: string) => void;
    backLink?: BackLink;
}

export const PageWithNavigation: React.FC<PageWithNavigationProps> = ({
                                                                          title,
                                                                          activeSection,
                                                                          items,
                                                                          onSelectSection,
                                                                          backLink
                                                                      }) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const navigationPanelItems = useMemo<PageNavigationItem[]>(() => {
        return items.map(currentItem => {
            return {
                key: currentItem.key,
                text: currentItem.name,
                icon: currentItem.icon,
                link: currentItem.link
            }
        });
    }, [items]);

    const activeSectionIndex = useMemo<number | null>(() => {
        const index = items.findIndex(item => item.key === activeSection);

        return (index < 0) ? null : index;
    }, [activeSection, items]);

    const onSelectSectionCb = useCallback((itemKey: string) => {
        if (onSelectSection) {
            onSelectSection(itemKey);
        }
    }, [onSelectSection]);

    const content = useMemo(() => {
        if (activeSectionIndex === null) {
            return;
        }

        return <Wrapper>
            <ContentWrapper>{items[activeSectionIndex].body}</ContentWrapper>
            {
                (isLarge)
                && <RightNavigationArea items={navigationPanelItems} selectedItemKey={activeSection}
                                        onSelectItem={onSelectSectionCb}/>
            }
            {(!isLarge) && <BottomNavigationBar items={navigationPanelItems} selectedItemKey={activeSection}
                                                onSelectItem={onSelectSectionCb}/>}
        </Wrapper>
    }, [activeSection, activeSectionIndex, isLarge, items, navigationPanelItems, onSelectSectionCb]);

    return <>
        {
            (backLink) && <PageBackLink text={backLink.name} link={backLink.link}/>
        }
        <PageTitle><Trans>{title}</Trans></PageTitle>
        {content}
    </>
}
