import {Editor, Node as SlateNode, NodeEntry} from "slate";

export const withTranslated = (editor: Editor) => {
    const {normalizeNode} = editor

    editor.normalizeNode = (entry: NodeEntry<SlateNode>) => {
        // const [node, path] = entry;
        // if (SlateElement.isElement(node)) {
        //     if (ALLOWED_FOR_TRANSLATE_ELEMENT.indexOf(node.type) < 0) {
        //         // Если такой элемент не найден в списке разрешённых
        //         //Transforms.unwrapNodes(editor, {at: path});
        //         Transforms.setNodes(editor, {type: ElementTypes.PARAGRAPH}, {at: path});
        //     }
        // }
        normalizeNode(entry)
    }

    return editor
}