import {css} from "styled-components";

export const ButtonStyleCss = css`
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1em;
    text-align: center;

    @media (${({theme}) => theme.media.small}) {
        padding: 15px 24px;
        font-size: 16px;
        line-height: 20px;
        min-width: 149px;
    }
`;