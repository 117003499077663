import {IsNotEmpty, IsNumber, IsString} from "class-validator";
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO базовых атрибутов сообщения о произошедшем событии.
 */
export class StreamEventBaseDto<PayloadType> implements IBaseApiDto {
    @IsString()
    @IsNotEmpty()
    id: string;

    @IsNumber()
    @IsNotEmpty()
    streamId: number;

    @IsNotEmpty()
    createdAt: string;

    payload: PayloadType;
}
