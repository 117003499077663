import {StreamEventBaseDto} from "../../components/WsApiClient/ApiDto/Response/StreamEvent/StreamEventBaseDto";

export enum StreamEventActionTypes {
    // Action обработка входящего из WebSocket события (saga)
    INCOMING_EVENT = '@@streamEvent/INCOMING_EVENT',

    // Выполнить запрос на загрузку данных потока событий,
    // которые возможно были упущены.
    LOAD_EVENTS_AFTER_RECONNECT = '@@streamEvent/LOAD_EVENTS_AFTER_RECONNECT',

    PROCESS_EVENTS_RESPONSE_BATCH = '@@streamEvent/PROCESS_EVENTS_RESPONSE_BATCH',

    // Action добавления события в список событий (reducer)
    PUSH_EVENTS_TO_LIST = '@@streamEvent/PUSH_EVENTS_TO_LIST',

    // Запуск обработки событий (saga)
    START_HANDLING_EVENTS = '@@streamEvent/START_HANDLING_EVENTS',

    // Остановить обработку входящих событий (saga)
    STOP_HANDLING_EVENTS = '@@streamEvent/STOP_HANDLING_EVENTS',

    // Запуск саги циклической обработки событий
    // (будет выполниться до наступления STOP_HANDLING_EVENT или когда закончатся события)
    START_HANDLE_EVENT_CYCLE = '@@streamEvent/START_HANDLE_EVENT_CYCLE',

    // Установка режима - разрешено обрабатывать полученные streamEvents или нет (reducer)
    SET_HANDLE_EVENTS_ENABLED = '@@streamEvent/SET_HANDLE_EVENTS_ENABLED',

    // Установка состояния конкретного event (reducer)
    SET_EVENT_STATE = '@@streamEvent/SET_EVENT_STATE',

    // Обработка события (saga)
    HANDLE_EVENT = '@@streamEvent/HANDLE_EVENT',

    // Удаление обработанных событий (reducer)
    DELETE_HANDLED_EVENTS = '@@streamEvent/DELETE_HANDLED',

    // Установить дату последнего события
    SET_LAST_EVENT_DATE = '@@streamEvent/SET_LAST_EVENT_DATE'
}

export enum StreamEventItemState {
    WAIT_FOR_HANDLE = 0,
    IN_PROCESS = 1,
    HANDLED = 2,
}

export class StreamEventStoreItem<T> {
    state: StreamEventItemState;
    dto: StreamEventBaseDto<T>
}

export interface StreamEventState {
    readyToHandle: boolean,
    newestEventIndex: number | null,
    newestEventId: string | null,
    eventList: Array<StreamEventStoreItem<object | null>>,
    dateOfLastEvent: string | null
}
