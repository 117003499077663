import {
    DtoLessonDataResponse
} from "../../../../components/StonlineApiClient/ApiDto/Response/Lesson/DtoLessonDataResponse";
import {
    DtoStudentInLessonListItem
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentInLesson/DtoStudentInLessonListItem";
import React from "react";
import {StudentItem} from "../../common/lesson/RoomControlBlock/StudentItemType";
import {
    DtoLessonMaterialItem
} from "../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialItem";
import {
    DtoLessonMaterialHomeworkItem
} from "../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialHomeworkItem";
import {LessonMaterialsModePage} from "./OnLessonMode/LessonMaterialsMode/LessonMaterialsModePage";
import {
    LessonHomeworkMaterialsModePage
} from "./HomeworkMode/LessonHomeworkMaterialsMode/LessonHomeworkMaterialsModePage";

export interface ILessonPageContext {
    lessonRoomId: string | null;
    lessonDto: DtoLessonDataResponse | null;
    studentInLessonItems: DtoStudentInLessonListItem[];
    students: StudentItem[];

    groupIntId: number | null;
    lessonIntId: number | null;
    lessonMaterialsList: DtoLessonMaterialItem[];
    lessonHomeworkMaterialsList: DtoLessonMaterialHomeworkItem[];

    setLessonMaterialsList: (items: DtoLessonMaterialItem[]) => void;
    setLessonHomeworkMaterialsList: (items: DtoLessonMaterialHomeworkItem[]) => void;

    lessonMaterialsPageRef: React.RefObject<LessonMaterialsModePage>;
    lessonHomeworkMaterialsPageRef: React.RefObject<LessonHomeworkMaterialsModePage>;

    homeworkIsAvailableForStudents: boolean;
    setHomeworkIsAvailableForStudents: () => void;

    updateStudentInLessonInCache: (newDto: DtoStudentInLessonListItem) => void;

    onlineRoomIsOpened: boolean;
    onlineRoomIsConnected: boolean;
    onlineRoomHasVideo: boolean;

    startOnlineRoom: (noVideo: boolean) => Promise<void>;
    closeOnlineRoom: () => Promise<void>;
    joinToRoom: () => Promise<void>;

    selectedRoomMemberId: string | null;
    setSelectedRoomMemberId: (memberId: string | null) => void;

    updateLessonDto: (lessonDto: DtoLessonDataResponse) => void;
}

export const LessonPageContext = React.createContext<ILessonPageContext>({
    lessonRoomId: null,
    lessonDto: null,
    studentInLessonItems: [],
    students: [],

    groupIntId: null,
    lessonIntId: null,
    lessonMaterialsList: [],
    lessonHomeworkMaterialsList: [],
    setLessonMaterialsList: () => {
    },
    setLessonHomeworkMaterialsList: () => {
    },
    lessonMaterialsPageRef: React.createRef(),
    lessonHomeworkMaterialsPageRef: React.createRef(),

    homeworkIsAvailableForStudents: false,
    setHomeworkIsAvailableForStudents: () => {
    },

    onlineRoomIsOpened: false,
    onlineRoomIsConnected: false,
    onlineRoomHasVideo: false,

    updateStudentInLessonInCache: () => {
    },

    startOnlineRoom: (noVideo: boolean) => Promise.resolve(),
    closeOnlineRoom: () => Promise.resolve(),
    joinToRoom: () => Promise.resolve(),

    selectedRoomMemberId: null,
    setSelectedRoomMemberId: (generalMemberId: string | null) => {
    },

    updateLessonDto: () => {
    }
});

export const LessonPageContextProvider = LessonPageContext.Provider;
