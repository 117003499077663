import {IsArray, IsNotEmpty, IsNumber, IsString, ValidateNested} from 'class-validator';
import {DtoRequestWithPagination} from "../Common/DtoRequestWithPagination";
import {Type} from "class-transformer";
import {DtoSelfStudyTrackAdminSummaryResponse} from "./DtoSelfStudyTrackAdminSummaryResponse";
import {DtoSelfStudyTrackAdminSubscriberListItem} from "./DtoSelfStudyTrackAdminSubscriberListItem";

class SubscribersListTypeForValidate extends DtoRequestWithPagination<DtoSelfStudyTrackAdminSubscriberListItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoSelfStudyTrackAdminSubscriberListItem)
    list: Array<DtoSelfStudyTrackAdminSubscriberListItem>;
}

/**
 * DTO элемента таблицы подписчиков трека (админка)
 */
export class DtoSelfStudyTrackAdminOverviewResponse {
    @IsNotEmpty()
    @IsString()
    trackId: string;

    @IsNotEmpty()
    @IsString()
    trackName: string;

    @IsNotEmpty()
    @IsString()
    trackSlug: string;

    @IsNumber()
    totalEpisodesCount: number;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoSelfStudyTrackAdminSummaryResponse)
    summaryResponse: DtoSelfStudyTrackAdminSummaryResponse;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => SubscribersListTypeForValidate)
    subscribers: SubscribersListTypeForValidate;
}
