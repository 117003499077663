import React, {useCallback, useEffect, useRef, useState} from "react";
import {Trans} from "@lingui/macro";
import {
    PageSubtitle,
    PageSubtitleSmallMargin,
    PageTitle,
    RegularText,
    RegularTextCss
} from "../../../styles/global-elements";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import * as AppActions from "../../../../store/app/actions";
import {container} from "tsyringe";
import {IMediaDeviceService} from "../../../../services/media-device/IMediaDeviceService";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {DeviceInfo, DeviceKindEnum} from "../../../../services/media-device/Types";
import {IDeviceMediaStreamFetcher} from "../../../../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {Space} from "antd";
import {RadioGroup, RadioInput} from "../../../components/Ui/Elements/RadioInput";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import styled from "styled-components";
import {PresetIdsEnum} from "../../../../components/DeviceMediaStreamFetcher/PresetIdsEnum";
import {DefaultLoader} from "../../../components/DefaultLoader";
import classNames from "classnames";
import {SoundVolumeMeter} from "../../../../components/SoundVolumeMeter/SoundVolumeMeter";
import {ReactComponent as MicSettingsIcon} from "../../../components/Ui/Svg/MicSettings.svg";
import {SoundIndicator} from "./SoundIndicator";
import {IDeviceOrientation} from "../../../../components/DeviceOrientation/IDeviceOrientation";
import {DeviceOrientatonTypeEnum} from "../../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";

const GroupOfDevices = styled.div`
  margin-bottom: 40px;
`;

const VideoPlayerArea = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundAlwaysDark};
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 640px;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  text-align: center;
`;

const VideoLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const VideoElement = styled.video`
  visibility: hidden;
  object-fit: cover;
  width: 100%;

  &.portrait {
    object-fit: contain;
    height: 100%;
    width: auto;
  }

  &.visible {
    visibility: visible;
  }
`;

const VideoElementErrorText = styled.div`
  ${RegularTextCss};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  text-align: center;
`;

const MicrophoneIndicatorArea = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 640px;
  padding-right: 40px;
`;

const MicIconWrapper = styled.div`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
`;

const MicIndicatorWrapper = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AudioElement = styled.audio`
  width: 100%;
  max-width: 640px;
  margin-top: 20px;
`;

export const VideoCallTest: React.FC = () => {
    const videoElement = useRef<HTMLVideoElement>(null);

    const cameraAllowedInSession = useSelector<ApplicationState>(
        ({app}: ApplicationState) => app.cameraAccess.allowedInSession
    ) as boolean;

    const cameraRequestAccessNow = useSelector<ApplicationState>(
        ({app}: ApplicationState) => app.cameraAccess.requestAccessNow
    ) as boolean;

    const dispatch = useDispatch();
    const setCameraRequestAccessNow = (value: boolean) =>
        dispatch(AppActions.setCameraRequestAccessNow(value));

    const deviceService = useRef(container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE));
    const deviceStreamFetcher = useRef(container.resolve<IDeviceMediaStreamFetcher>(DiTokens.DEVICE_MEDIA_STREAM_FETCHER));
    const logger = useRef(container.resolve<ILogger>(DiTokens.LOGGER));
    const deviceOrientation = useRef(container.resolve<IDeviceOrientation>(DiTokens.DEVICE_ORIENTATION));

    const [soundVolumeMeter, setSoundVolumeMeter] = useState<SoundVolumeMeter | null>(null);

    const [videoInputDevices, setVideoInputDevices] = useState<DeviceInfo[]>([]);
    const [audioInputDevices, setAudioInputDevices] = useState<DeviceInfo[]>([]);

    const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<DeviceInfo | null>(null);
    const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<DeviceInfo | null>(null);

    const [videoStreamReady, setVideoStreamReady] = useState<boolean>(false);
    const [audioStreamReady, setAudioStreamReady] = useState<boolean>(false);

    const [videoStreamError, setVideoStreamError] = useState<boolean>(false);
    const [audioStreamError, setAudioStreamError] = useState<boolean>(false);

    const [fetchDevicesError, setFetchDevicesError] = useState<boolean>(false);

    const [portraitOrientation, setPortraitOrientation] = useState<boolean>(false);

    const switchCamera = useCallback((newDeviceId: string) => {
        deviceStreamFetcher.current.stopAllCameraStreams();
        setVideoStreamReady(false);

        deviceStreamFetcher.current
            .getCameraStream(PresetIdsEnum.CAMERA_MEDIUM, newDeviceId)
            .then((stream) => {
                setVideoStreamReady(true);
                setVideoStreamError(false);

                if (videoElement.current) {
                    videoElement.current.srcObject = stream;

                    videoElement.current.oncanplay = () => {
                        videoElement.current?.play();
                    }
                }
            })
            .catch(() => {
                setVideoStreamReady(false);
                setVideoStreamError(true);
            })
    }, []);

    const switchMicrophone = useCallback((newDeviceId: string) => {
        if (soundVolumeMeter !== null) {
            soundVolumeMeter.disconnect();
            setSoundVolumeMeter(null);
        }

        deviceStreamFetcher.current.stopAllMicrophoneStreams();
        setAudioStreamReady(false);

        deviceStreamFetcher.current
            .getMicrophoneStream(newDeviceId)
            .then((stream) => {
                setAudioStreamReady(true);
                setAudioStreamError(false);

                const meter = new SoundVolumeMeter(logger.current);

                if (meter.isSupported()) {
                    meter.connectToStream(stream);

                    setSoundVolumeMeter(meter);
                }
            })
            .catch(() => {
                setAudioStreamReady(false);
                setAudioStreamError(true);
            })
    }, [soundVolumeMeter]);

    const videoDeviceOnChange = useCallback((newDevice: DeviceInfo) => {
        setSelectedVideoInputDevice(newDevice);
        switchCamera(newDevice.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const audioDeviceOnChange = useCallback((newDevice: DeviceInfo) => {
        setSelectedAudioInputDevice(newDevice);
        switchMicrophone(newDevice.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const cameraSubscriberId = 'video-test-page--camera';
        const microphoneSubscriberId = 'video-test-page--microphone';
        const deviceServiceCurrent = deviceService.current;

        deviceServiceCurrent.registerSubscriber(
            DeviceKindEnum.VIDEO_INPUT,
            cameraSubscriberId,
            videoDeviceOnChange
        );

        deviceServiceCurrent.registerSubscriber(
            DeviceKindEnum.AUDIO_INPUT,
            microphoneSubscriberId,
            audioDeviceOnChange
        );

        return () => {
            deviceServiceCurrent.unregisterSubscriber(
                cameraSubscriberId
            );

            deviceServiceCurrent.unregisterSubscriber(
                microphoneSubscriberId
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!cameraAllowedInSession) {
            setCameraRequestAccessNow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const deviceStreamFetcherCurrent = deviceStreamFetcher.current;

        return () => {
            deviceStreamFetcherCurrent.stopAllMicrophoneStreams();
            deviceStreamFetcherCurrent.stopAllCameraStreams();
        }
    }, []);

    useEffect(() => {
        const subscriberId = 'video-call-test';
        const deviceOrientationCurrent = deviceOrientation.current;

        const currentOrientation = deviceOrientationCurrent.getCurrentState();

        setPortraitOrientation(
            (currentOrientation === DeviceOrientatonTypeEnum.PORTRAIT_SECONDARY)
            || (currentOrientation === DeviceOrientatonTypeEnum.PORTRAIT_PRIMARY)
        );

        deviceOrientationCurrent.registerSubscriber(
            subscriberId,
            (newType) => {
                setPortraitOrientation(
                    (newType === DeviceOrientatonTypeEnum.PORTRAIT_SECONDARY)
                    || (newType === DeviceOrientatonTypeEnum.PORTRAIT_PRIMARY)
                );
            });

        return () => {
            deviceOrientationCurrent.unregisterSubscriber(
                subscriberId
            );
        }
    }, []);

    useEffect(() => {
        if (!cameraAllowedInSession) {
            return;
        }

        Promise.all([
            deviceService.current.getAudioInputDevices(),
            deviceService.current.getVideoInputDevices(),
            deviceService.current.getDevicesForCall()
        ])
            .then((value) => {
                setVideoInputDevices(value[1]);
                setAudioInputDevices(value[0]);

                setSelectedVideoInputDevice(value[2].videoInput);

                if (value[2].videoInput) {
                    switchCamera(value[2].videoInput.id);
                }

                setSelectedAudioInputDevice(value[2].audioInput);

                if (value[2].audioInput) {
                    switchMicrophone(value[2].audioInput.id);
                }
            })
            .catch((e) => {
                setFetchDevicesError(true);

                logger.current.error(LoggerSectionsEnum.VIDEO_CALL_TEST, 'Error on fetch device list: ', e);
            })

        deviceService.current.getAudioInputDevices()
            .then((items) => {
                setAudioInputDevices(items);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraAllowedInSession]);

    if (!cameraAllowedInSession || cameraRequestAccessNow) {
        return <div>
            <PageTitle>
                <Trans>Проверка устройств</Trans>
            </PageTitle>
            <PageSubtitle>
                <Trans>Нет разрешения на доступ к устройствам</Trans>
            </PageSubtitle>
        </div>
    }

    if (fetchDevicesError) {
        return <div>
            <PageTitle>
                <Trans>Проверка устройств</Trans>
            </PageTitle>
            <PageSubtitle>
                <Trans>При получении списка устройств произошла ошибка. Попробуйте обновить страницу.</Trans>
            </PageSubtitle>
        </div>
    }

    return <div>
        <PageTitle>
            <Trans>Проверка устройств</Trans>
        </PageTitle>

        <GroupOfDevices>
            <PageSubtitleSmallMargin>
                <Trans>Выбор камеры</Trans>
            </PageSubtitleSmallMargin>

            <RadioGroup value={selectedVideoInputDevice?.id}
                        onChange={(e) => {
                            deviceService.current.setVideoInputDevice(e.target.value);
                        }}>
                <Space direction={"vertical"}>
                    {
                        videoInputDevices.map(item => {
                            return <RadioInput value={item.id} key={item.id}>
                                {item.name}
                            </RadioInput>
                        })
                    }
                </Space>
            </RadioGroup>

            <VideoPlayerArea>
                {
                    (!videoStreamReady && !videoStreamError)
                    && <VideoLoaderWrapper><DefaultLoader/></VideoLoaderWrapper>
                }
                {
                    (!videoStreamReady && videoStreamError)
                    && <VideoElementErrorText><Trans>Не удалось активировать эту камеру</Trans></VideoElementErrorText>
                }
                <VideoElement
                    className={classNames(
                        videoStreamReady && 'visible',
                        portraitOrientation && 'portrait'
                    )}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    ref={videoElement}
                />
            </VideoPlayerArea>
        </GroupOfDevices>

        <GroupOfDevices>
            <PageSubtitleSmallMargin>
                <Trans>Выбор микрофона</Trans>
            </PageSubtitleSmallMargin>

            <RegularText><Trans>Скажите что-нибудь и шкала должна измениться</Trans></RegularText>

            <MicrophoneIndicatorArea>
                <MicIconWrapper>
                    <MicSettingsIcon/>
                </MicIconWrapper>
                <MicIndicatorWrapper>
                    {
                        (!audioStreamReady && !audioStreamError)
                        && <DefaultLoader/>
                    }
                    {
                        (!audioStreamReady && audioStreamError)
                        && <RegularText>Не удалось активировать этот микрофон</RegularText>
                    }
                    {
                        (audioStreamReady && !audioStreamError)
                        && <SoundIndicator soundVolumeMeter={soundVolumeMeter}/>
                    }
                </MicIndicatorWrapper>
            </MicrophoneIndicatorArea>

            <RadioGroup value={selectedAudioInputDevice?.id}
                        onChange={(e) => {
                            deviceService.current.setAudioInputDevice(e.target.value);
                        }}>
                <Space direction={"vertical"}>
                    {
                        audioInputDevices.map(item => {
                            return <RadioInput value={item.id} key={item.id}>
                                {item.name}
                            </RadioInput>
                        })
                    }
                </Space>
            </RadioGroup>
        </GroupOfDevices>

        <GroupOfDevices>
            <PageSubtitleSmallMargin>
                <Trans>Проверка динамика</Trans>
            </PageSubtitleSmallMargin>

            <RegularText><Trans>Запустите аудио, чтобы проверить динамики</Trans></RegularText>

            <AudioElement controls src={"/sounds/audio-test.mp3"}/>
        </GroupOfDevices>
    </div>
}
