import React, {CSSProperties, forwardRef, useContext, useEffect, useMemo, useState} from "react";
import styled, {keyframes} from "styled-components";
import {RegularText} from "../../components/RichTextReader/elements/common-styles";
import {ElementRenderState, IItemIdWithSide} from "./Types";
import classNames from "classnames";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {ExerciseProgressBar} from "../../../ExerciseProgressBar";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {Sides} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/SlidesEnum";

interface MatchItemProps {
    id: string;
    text: string;
    side: Sides;
    width: number | null;
    leftPos: number | null;
    topPosition: number;
    renderState: ElementRenderState;
    onClick: (itemWithSide: IItemIdWithSide) => void;
    onHoverStart: (itemWithSide: IItemIdWithSide) => void;
    onHoverEnd: (itemWithSide: IItemIdWithSide) => void;
    selected: boolean;
    asCorrectAnswer: boolean;
    onTransitionEnd: (itemWithSide: IItemIdWithSide) => void;
    showErrorAnimationInExercise: IItemIdWithSide | null;
    setShowErrorAnimationInExercise: (newValue: IItemIdWithSide | null) => void;
    allowedTransitions: boolean;
    blueLineTo: boolean;
    noPrograssBar?: boolean;
}

const ExerciseWrapper = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;

  word-break: keep-all;

  &.transitions-allowed {
    transition: transform 1s ease;
  }
`;

const ErrorTremor = keyframes`
  0% {
    transform: translateX(-3px);
  }

  15% {
    transform: translateX(+3px);
  }

  30% {
    transform: translateX(-2px);
  }

  45% {
    transform: translateX(+2px);
  }

  60% {
    transform: translateX(-1px);
  }

  75% {
    transform: translateX(+1);
  }

  90% {
    transform: translateX(0);
  }
`;

const ItemWrapper = styled.div`
  ${RegularText};

  line-height: 1.5em;
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.accentDivider + "99"};
  padding: 3px 12px 6px;
  border-radius: 4px;
  cursor: pointer;
  word-break: inherit;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};

  &.correct {
    background-color: ${({theme}) => theme.colors.constructorCorrectAnswer};
  }

  &.incorrect {
    animation: ${ErrorTremor} 0.9s ease-in-out;
  }

  &:hover {
    border-color: ${({theme}) => theme.colors.accentDivider + "ff"};
  }

  &.selected {
    border-color: ${({theme}) => theme.colors.accentPrimary};
  }
  
  &.read-only {
    cursor: not-allowed;
  }
`;

const ItemText = styled.div`
  color: ${({theme}) => theme.colors.textPrimaryDark};
`;

const ItemDot = styled.div`
  position: absolute;
  width: 3px;
  height: 6px;
  background-color: ${({theme}) => theme.colors.accentDivider + "ff"};

  &.left {
    top: calc(50% - 3px);
    right: -3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.right {
    top: calc(50% - 3px);
    left: -3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.selected, &.blue-line-to {
    background-color: ${({theme}) => theme.colors.accentPrimary};
  }

  &.correct {
    background-color: ${({theme}) => theme.colors.constructorCorrectAnswer};
  }
`;

const ProgressbarWrapper = styled.div`
  margin: 0 4px;
  width: 6px;
  height: 27px;
  position: absolute;
  right: -18px;
  top: 3px;
`;


export const MatchItem = forwardRef<HTMLDivElement, MatchItemProps>(
    (props, ref) => {
        const {
            id,
            text,
            side,
            width,
            leftPos,
            topPosition,
            renderState,
            onClick,
            selected,
            onHoverStart,
            onHoverEnd,
            asCorrectAnswer,
            onTransitionEnd,
            showErrorAnimationInExercise,
            setShowErrorAnimationInExercise,
            allowedTransitions,
            blueLineTo,
            noPrograssBar
        } = props;

        const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);

        // Методы, доступные родителю
        // useImperativeHandle(ref, () => ({}));

        const styles = useMemo<CSSProperties>((): CSSProperties => {
            if ((![ElementRenderState.READY, ElementRenderState.CALC_TOP_POSITIONS].includes(renderState)) || (width === null)) {
                return {
                    visibility: "hidden"
                };
            }

            return {
                width: width.toString(10) + 'px ',
                display: "block",
                wordBreak: "break-all",
                left: (leftPos === null) ? 'auto' : leftPos.toString(10) + 'px',
                transform: "translateY(" + topPosition.toString(10) + "px)"
            }
        }, [renderState, width, leftPos, topPosition]);

        // const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        useEffect(() => {
            if (showErrorAnimationInExercise !== null) {
                if ((showErrorAnimationInExercise.id === id) && (showErrorAnimationInExercise.side === side)) {
                    setNowShowErrorAnimation(true);
                    setShowErrorAnimationInExercise(null);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showErrorAnimationInExercise]);

        const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
            if (side === Sides.LEFT) {
                return null;
            }

            if ((!slideItemWorkContext) || (!slideItemWorkContext.itemId) || (!slideItemWorkContext.slideId)) {
                return null;
            }

            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[id];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [id, slideItemWorkContext]);

        return <ExerciseWrapper ref={ref}
                                style={styles}
                                className={classNames(
                                    allowedTransitions && "transitions-allowed"
                                )}
                                onTransitionEnd={() => onTransitionEnd({id: id, side: side})}>
            <ItemWrapper onMouseEnter={() => onHoverStart({id: id, side: side})}
                         onMouseLeave={() => onHoverEnd({id: id, side: side})}
                         className={classNames(
                             selected && "selected",
                             nowShowErrorAnimation && "incorrect",
                             asCorrectAnswer && "correct",
                             (!asCorrectAnswer && slideItemWorkContext.readOnly) && "read-only"
                         )}
                         onAnimationEnd={() => setNowShowErrorAnimation(false)}
                         onClick={() => {
                             if (!asCorrectAnswer && !slideItemWorkContext.readOnly) {
                                 onClick({id: id, side: side});
                             }
                         }}>
                <ItemDot className={classNames(
                    side === Sides.LEFT && "left",
                    side === Sides.RIGHT && "right",
                    selected && "selected",
                    blueLineTo && "blue-line-to",
                    asCorrectAnswer && "correct"
                )}/>
                <ItemText>
                    {text}
                </ItemText>
                {
                    (side === Sides.RIGHT) && (noPrograssBar !== true) && <ProgressbarWrapper>
                        <ExerciseProgressBar
                            wrongValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.missedAward : 0}
                            rightValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.award : 0}/>
                    </ProgressbarWrapper>
                }
            </ItemWrapper>
        </ExerciseWrapper>;
    }
);

MatchItem.displayName = 'MatchItem';