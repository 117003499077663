import React, {useContext, useEffect, useMemo, useRef} from "react";
import {VideoGalleryParticipantProfile} from "../../Types";
import {CommonContext, ICommonContext} from "../../../../../contexts/CommonContext";
import {VideoPlayer} from "./VideoPlayer";
import styled from "styled-components";
import {ControlsLayer} from "../Common/ControlsLayer";
import {
    IVideoSubscriptionsActionsContext,
    VideoSubscriptionsActionsContext
} from "../../../../../actions/video-subscriptions/VideoSubscriptionsActionsContext";
import {ConferenceModeEnum, StreamStatusEnum} from "../../../../../Types";
import {useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../../../../store/user/selector";
import {
    IVideoRoomActionsContext,
    VideoRoomActionsContext
} from "../../../../../actions/video-room/VideoRoomActionsContext";
import {StreamTypeEnum} from "../../../../../../../../components/JanusClient/Types";
import classNames from "classnames";
import {AvatarStatusLayer} from "../Common/AvatarStatusLayer";
import {t} from "@lingui/macro";
import {DeviceOrientatonTypeEnum} from "../../../../../../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";
import {ApplicationState} from "../../../../../../../../store";
import {ITheme} from "../../../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../../../services/hooks/useMediaQuery";


const VideoPlayerStyled = styled(VideoPlayer)`
  object-fit: cover;

  &.hidden {
    visibility: hidden;
  }

  &.portrait {
    object-fit: contain;
    width: auto;
  }
`;

interface CameraVideoItemProps {
    profile: VideoGalleryParticipantProfile;
    randomItemId: string;
}

export const CameraVideoItem: React.FC<CameraVideoItemProps> = ({profile, randomItemId}) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const currentUserId = useSelector(currentUserIdSelector);

    const subscriptionRequestWasSend = useRef<boolean>(false);
    const subscriptionConnectedToVideo = useRef<boolean>(false);

    const commonRoomContext = useContext<ICommonContext>(CommonContext);
    const videoSubscriptionsActionsContext = useContext<IVideoSubscriptionsActionsContext>(VideoSubscriptionsActionsContext);

    const videoRoomActionsContext = useContext<IVideoRoomActionsContext>(VideoRoomActionsContext);
    const videoPlayerRef = useRef<HTMLVideoElement>(null);

    const currentUserInVideoRoom = useMemo(() => {
        return commonRoomContext.conferenceParticipants.find(item => item.id === profile.id) ?? null;
    }, [commonRoomContext.conferenceParticipants, profile.id]);

    const videoStatus = useMemo<StreamStatusEnum>(() => {
        if (!currentUserInVideoRoom) {
            return StreamStatusEnum.NOT_AVAILABLE;
        }

        return currentUserInVideoRoom.videoStatus;
    }, [currentUserInVideoRoom]);

    const statusText = useMemo<string | null>(() => {
        if (currentUserInVideoRoom && currentUserInVideoRoom.videoStatus === StreamStatusEnum.CONNECTING) {
            return t`Подключение видео`;
        }

        return null;
    }, [currentUserInVideoRoom]);

    const portrait = useMemo<boolean>(() => {
        if (
            (profile.screenOrientation === DeviceOrientatonTypeEnum.PORTRAIT_PRIMARY)
            || (profile.screenOrientation === DeviceOrientatonTypeEnum.PORTRAIT_SECONDARY)
        ) {
            return !((commonRoomContext.currentMode === ConferenceModeEnum.FLOAT_ON_PAGE) && (!isLarge));
        }

        return false;
    }, [commonRoomContext.currentMode, isLarge, profile.screenOrientation]);

    useEffect(() => {
        if (profile.id !== currentUserId) {
            if ((videoStatus === StreamStatusEnum.AVAILABLE) || (videoStatus === StreamStatusEnum.CONNECTED)) {
                videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.VIDEO, randomItemId);

                subscriptionRequestWasSend.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Если мы были подключены к видео, но оно перестало работать
        if (videoStatus !== StreamStatusEnum.CONNECTED && subscriptionConnectedToVideo.current) {
            subscriptionConnectedToVideo.current = false;
        }

        // Если мы подписывались на видео, но оно стало недоступно
        if ((videoStatus === StreamStatusEnum.NOT_AVAILABLE) && (subscriptionRequestWasSend.current)) {
            subscriptionRequestWasSend.current = false;

            return;
        }

        // Если далее видео стало доступно и мы ранее на него не подписывались
        if (videoStatus === StreamStatusEnum.AVAILABLE && !subscriptionRequestWasSend.current) {
            videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.VIDEO, randomItemId);

            subscriptionRequestWasSend.current = true;
        }

        // Если стало видео подключено и мы на него ещё не подписались
        if ((videoStatus === StreamStatusEnum.CONNECTED) && (!subscriptionConnectedToVideo.current)) {
            subscriptionConnectedToVideo.current = true;

            if (videoPlayerRef.current) {
                videoRoomActionsContext.attachVideoToElement(profile.id, videoPlayerRef.current);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoStatus]);

    useEffect(() => {
        return () => {
            if (profile.id !== currentUserId) {
                if (subscriptionRequestWasSend.current) {
                    videoSubscriptionsActionsContext.unsubscribeFromVideoStream(profile.id, StreamTypeEnum.VIDEO, randomItemId);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <VideoPlayerStyled
            ref={videoPlayerRef}
            className={classNames(
                videoStatus !== StreamStatusEnum.CONNECTED && 'hidden',
                portrait && 'portrait'
            )}
            mirrored={true}
        />
        {
            (videoStatus !== StreamStatusEnum.CONNECTED)
            && <AvatarStatusLayer avatarFileId={profile.avatarFileId} userName={profile.name} statusText={statusText}/>
        }
        <ControlsLayer
            profile={profile}
            userConferenceParticipantItem={currentUserInVideoRoom}
        />
    </>
}
