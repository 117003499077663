import React from "react";
import {SubscriptionRequestItem} from "../../Types";

export interface IVideoRoomActionsContext {
    disableMyVideo: () => void;
    enableMyVideo: () => void;
    startScreenSharing: () => void;
    stopScreenSharing: () => void;
    setMyVideoIsAvailable: (value: boolean) => void;
    setMyScreenSharingIsAvailable: (value: boolean) => void;
    attachVideoToElement: (userId: string, videoElement: HTMLVideoElement) => void;
    attachScreenVideoToElement: (userId: string, videoElement: HTMLVideoElement) => void;
    attachScreenAudioToElement: (userId: string, videoElement: HTMLAudioElement) => void;
    subscribeToStreams: (list: SubscriptionRequestItem[]) => void;
    unsubscribeFromStreams: (list: SubscriptionRequestItem[]) => void;
}

export const VideoRoomActionsContext = React.createContext<IVideoRoomActionsContext>({
    disableMyVideo: () => {
    },
    enableMyVideo: () => {
    },
    startScreenSharing: () => {
    },
    stopScreenSharing: () => {
    },
    setMyVideoIsAvailable: () => {
    },
    setMyScreenSharingIsAvailable: () => {
    },
    attachVideoToElement: () => {
    },
    attachScreenVideoToElement: () => {
    },
    attachScreenAudioToElement: () => {
    },
    subscribeToStreams: () => {
    },
    unsubscribeFromStreams: () => {
    }
});

export const VideoRoomActionsContextProvider = VideoRoomActionsContext.Provider;
