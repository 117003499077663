import React, {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useState} from 'react';
import styled from "styled-components";
import {IRichTextElementRefMethods} from "../../components/RichTextReader/IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../components/RichTextReader/IRichTextElementProps";
import {ICheckboxList} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxList";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import classNames from "classnames";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {
    ExerciseCheckboxContextProvider,
    IExerciseCheckboxContext,
    ShowMode,
    VariantState
} from "./ExerciseCheckboxContext";
import {ICheckboxListItem} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxListItem";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";

const CheckboxList = styled.ul`
  list-style: none;
  padding-left: 15px;
`;

export const ExerciseCheckboxList = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<ICheckboxList>>(
    (props, ref) => {
        const {element, className, ...otherProps} = props;

        const [showErrorAnimationInVariantId, setShowErrorAnimationInVariantId]
            = useState<string | null>(null);

        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);
        const {showCorrectAnswers} = slideItemWorkContext;

        const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[element.id];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
        }, [element.id, slideItemWorkContext]);

        const allVariants = useMemo<ICheckboxListItem[]>(() => {
            return element.children.map((item) => {
                return item as unknown as ICheckboxListItem;
            });
        }, [element]);

        const allCorrectVariants = useMemo<ICheckboxListItem[]>(() => {
            return allVariants.filter((item) => {
                return item.checked;
            });
        }, [allVariants]);

        const countAnswersInHistory = useMemo<number>(
            () => {
                return (!currentExerciseHistoryData) ? 0 : currentExerciseHistoryData.inputHistory.length;
            },
            [currentExerciseHistoryData]
        );

        const countCorrectVariantsInHistory = useMemo<number>(() => {
            if (!currentExerciseHistoryData) {
                return 0;
            }

            const correctVariantIds = allCorrectVariants.filter(item => item.checked).map(item => item.id);

            let count = 0;

            currentExerciseHistoryData.inputHistory.forEach((item) => {
                if (correctVariantIds.includes(item)) {
                    ++count;
                }
            });

            return count;
        }, [allCorrectVariants, currentExerciseHistoryData]);
        
        const checkExerciseIsCompleted = useCallback((inputHistory: string[]) => {
            let result = true;
            
            allCorrectVariants.forEach((item) => {
                if (!inputHistory.includes(item.id)) {
                    result = false;
                }
            });
            
            return result;
        }, [allCorrectVariants]);

        const exerciseCompleted = useMemo<boolean>(() => {
            if (showCorrectAnswers === true) {
                // Если нужно просто показать все верные ответы
                return true;
            }

            if (!currentExerciseHistoryData) {
                return false;
            }

            return checkExerciseIsCompleted(currentExerciseHistoryData.inputHistory);
        }, [checkExerciseIsCompleted, currentExerciseHistoryData, showCorrectAnswers]);

        const variantState = useMemo<VariantState>(() => {
            const result: VariantState = {};

            allVariants.forEach((variant) => {
                if (showCorrectAnswers === true) {
                    // Если нужно показать все верные ответы
                    if (variant.checked) {
                        result[variant.id] = ShowMode.CORRECT;
                    } else {
                        result[variant.id] = ShowMode.MUTED;
                    }

                    return;
                }

                let mode: ShowMode = (exerciseCompleted)
                    ? ShowMode.MUTED
                    : ShowMode.INPUT;

                if (currentExerciseHistoryData) {
                    if (currentExerciseHistoryData.inputHistory.includes(variant.id)) {
                        mode = (variant.checked) ? ShowMode.CORRECT : ShowMode.ERROR;
                    }
                }

                result[variant.id] = mode;
            });

            return result;
        }, [allVariants, currentExerciseHistoryData, exerciseCompleted, showCorrectAnswers]);

        const variantOnClick = useCallback((variantId: string) => {
            if (
                (slideItemWorkContext.itemId === null)
                || (slideItemWorkContext.slideId === null)
                || (playerContext.selectedSlide === null)
                || (slideItemWorkContext.readOnly === true)
            ) {
                return;
            }

            const soundPlayer = container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);

            // Рассчитываем значения упущенной выгоды и награды

            let award = 0;
            let missedAward = 0;

            if (currentExerciseHistoryData) {
                award = currentExerciseHistoryData.award;
                missedAward = currentExerciseHistoryData.missedAward;
            }

            const availableScoreCount = MAX_AWARD_SCORE - award - missedAward;
            const answerIsCorrect = allCorrectVariants.map(item => item.id).includes(variantId);

            if (answerIsCorrect) {
                // Если сейчас был дан верный ответ
                soundPlayer.playSound(SoundsEnum.RIGHT);

                const countAvailableCorrectValues = allCorrectVariants.length - countCorrectVariantsInHistory;
                const correctCost = Math.ceil(availableScoreCount / countAvailableCorrectValues);

                award += correctCost;
            } else {
                // Если сейчас был дан ошибочный ответ
                soundPlayer.playSound(SoundsEnum.ERROR);
                setShowErrorAnimationInVariantId(variantId);

                const totalIncorrectCount = allVariants.length - allCorrectVariants.length;
                const countAvailableIncorrectValues
                    = totalIncorrectCount - (countAnswersInHistory - countCorrectVariantsInHistory);

                const incorrectCost = Math.ceil(availableScoreCount / countAvailableIncorrectValues);

                missedAward += incorrectCost;
            }

            if (missedAward > MAX_AWARD_SCORE) {
                missedAward = MAX_AWARD_SCORE;
            }

            if (award > MAX_AWARD_SCORE) {
                award = MAX_AWARD_SCORE;
            }

            slideItemWorkContext.saveExerciseAnswer(
                element.id,
                variantId,
                award,
                missedAward,
                playerContext.selectedSlide.exercisesCount,
                checkExerciseIsCompleted([
                        ...((currentExerciseHistoryData === null) ? [] : currentExerciseHistoryData.inputHistory),
                        variantId
                    ]
                )
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [slideItemWorkContext, currentExerciseHistoryData]);

        const providerData = useMemo<IExerciseCheckboxContext>(
            () => {
                return {
                    variantOnClick: variantOnClick,
                    variantState: variantState,
                    showErrorAnimationInVariantId: showErrorAnimationInVariantId,
                    setShowErrorAnimationInVariantId: setShowErrorAnimationInVariantId
                }
            },
            [showErrorAnimationInVariantId, variantOnClick, variantState]
        );

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <CheckboxList className={classNames(className)}>
            <ExerciseCheckboxContextProvider value={providerData}>
                <RichTextReader elements={element.children} {...otherProps}/>
            </ExerciseCheckboxContextProvider>
        </CheckboxList>
    }
);

ExerciseCheckboxList.displayName = 'ExerciseCheckboxList';