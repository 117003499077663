import React, {useCallback, useContext, useMemo} from "react";
import {IButtonBaseProps} from "./IButtonBaseProps";
import {
    ControlPanelItem,
    ControlPanelItemIcon,
    ControlPanelItemText
} from "../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as CastOn} from "../../../Ui/Elements/ControlPanel/icons/CastOn.svg";
import {ReactComponent as CastOff} from "../../../Ui/Elements/ControlPanel/icons/CastOff.svg";
import {Trans} from "@lingui/macro";
import classNames from "classnames";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {ConnectionStateEnum, StreamStatusEnum} from "../../Types";
import {IVideoRoomContext, VideoRoomContext} from "../../contexts/VideoRoomContext";
import {IVideoRoomActionsContext, VideoRoomActionsContext} from "../../actions/video-room/VideoRoomActionsContext";
import {container} from "tsyringe";
import {IDeviceMediaStreamFetcher} from "../../../../../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../store/user/selector";
import {IWsApiClient} from "../../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../../components/WsApiClient/ApiMethodEnum";

export const ScreenShareBtn: React.FC<IButtonBaseProps> = () => {
    const currentUserId = useSelector(currentUserIdSelector);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);
    const videoRoomActionsContext = useContext<IVideoRoomActionsContext>(VideoRoomActionsContext);
    const commonContext = useContext<ICommonContext>(CommonContext);

    const myUserConfParticipant = useMemo(() => {
        return commonContext.conferenceParticipants.find(item => item.id === currentUserId) ?? null;
    }, [commonContext.conferenceParticipants, currentUserId]);

    const toggleButton = useCallback(() => {
        if (!myUserConfParticipant) {
            return;
        }

        if (myUserConfParticipant.screenVideo !== StreamStatusEnum.CONNECTED) {
            const deviceMediaStreamFetcher = container
                .resolve<IDeviceMediaStreamFetcher>(DiTokens.DEVICE_MEDIA_STREAM_FETCHER);

            deviceMediaStreamFetcher.getScreenStream()
                .then(() => {
                    videoRoomActionsContext.startScreenSharing();

                    const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

                    wsClient.query(
                        ApiMethodEnum.LESSON_ROOM_SELECT_ROOM_MEMBER_VIDEO_FOR_ALL,
                        {
                            lessonId: commonContext.lessonId,
                            userId: currentUserId
                        },
                        undefined,
                        false
                    );

                    wsClient.query(
                        ApiMethodEnum.LESSON_ROOM_SET_MEMBERS_VIDEO_TO_FULL_SCREEN_FOR_ALL,
                        {
                            lessonId: commonContext.lessonId,
                            value: true
                        },
                        undefined,
                        false
                    );
                })
        } else {
            videoRoomActionsContext.stopScreenSharing();
        }

    }, [commonContext.lessonId, currentUserId, myUserConfParticipant, videoRoomActionsContext]);


    const btnDisabled = useMemo<boolean>(
        () =>
            videoRoomContext.videoRoomConnectionState.publisherConnection.status !== ConnectionStateEnum.CONNECTED
            || myUserConfParticipant === null
            || myUserConfParticipant.screenVideo === StreamStatusEnum.CONNECTING
            || commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED,
        [commonContext.lessonConferenceState, myUserConfParticipant, videoRoomContext.videoRoomConnectionState.publisherConnection.status]
    );

    const screenSharingEnabled = useMemo(() => {
        if ((btnDisabled) || (myUserConfParticipant === null)) {
            return false;
        }

        return myUserConfParticipant.screenVideo === StreamStatusEnum.CONNECTED;
    }, [btnDisabled, myUserConfParticipant]);

    return <ControlPanelItem
        onClick={() => toggleButton()}
        className={
            classNames(
                'panel-item',
                btnDisabled && "disabled",
                screenSharingEnabled && "warning"
            )
        }>
        <ControlPanelItemIcon>{
            (screenSharingEnabled)
                ? <CastOn/>
                : <CastOff/>
        }</ControlPanelItemIcon>
        <ControlPanelItemText><Trans>Показать экран</Trans></ControlPanelItemText>
    </ControlPanelItem>
}
