import React, {useCallback, useContext, useRef, useState} from "react";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {RoutesList} from "../../../../../RoutesList";
import {useNavigate} from "react-router-dom";
import {ResponseStatusEnum} from "../../../../../../components/StonlineApiClient/Enums/ResponseStatusEnum";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {StudentGeneralForm, StudentGeneralFormMethods} from "../../common/StudentGeneralForm";
import {
    DtoStudentGeneralInfo
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";


const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

export const StudentProfileSection: React.FC = () => {
    const formRef = useRef<StudentGeneralFormMethods>(null);

    const [saveInProcess, setSaveInProcess] = useState<boolean>(false);
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);

    const navigate = useNavigate();
    const stToken = useSelector(stTokenSelector);

    const onFinish = async (values: DtoStudentGeneralInfo) => {
        if (!stToken) {
            return;
        }

        setSaveInProcess(true);

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        stApiClient.saveStudentProfile(stToken, values)
            .then((result) => {
                if (result.resultStatus === ResponseStatusEnum.SUCCESS) {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Успешно`,
                        t`Данные профиля ученика сохранены`
                    );

                    formRef.current?.reset();
                    studentPageContext.reloadBaseInfo();

                    return;
                }

                throw result;
            })
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.STONLINE_STUDENTS_API,
                    `Error on save student with id ${values.id}.`,
                    values,
                    err
                );

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось сохранить`,
                    t`Не удалось сохранить данные профиля ученика`
                )
            })
            .finally(() => {
                setSaveInProcess(false);
            })
    }

    const deleteBtnClick = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            if (stToken === null) {
                reject();

                return;
            }

            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            formRef.current?.reset();

            stApiClient.deleteStudentProfile(stToken, studentPageContext.studentGeneralInfoDto.id)
                .then((result) => {
                    if (result.resultStatus !== ResponseStatusEnum.SUCCESS) {
                        logger.error(LoggerSectionsEnum.STONLINE_STUDENTS_API, result);

                        reject(t`Не удалось удалить профиль ученика`);

                        return;
                    }

                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Профиль ученика успешно удалён`,
                        ''
                    );

                    navigate(RoutesList.TEACHER_ALL_STUDENTS);

                    resolve();
                })
                .catch((err) => {
                    logger.error(LoggerSectionsEnum.STONLINE_STUDENTS_API, err);

                    reject(t`Не удалось удалить профиль ученика`);
                })
        });
    }, [navigate, stToken, studentPageContext.studentGeneralInfoDto.id]);


    return <div>
        <StudentGeneralForm
            ref={formRef}
            studentGeneralInfoDto={studentPageContext.studentGeneralInfoDto}
            onFinish={onFinish}
        />
        <ButtonsWrapper>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => formRef.current?.submit()}>
                <Trans>Сохранить</Trans>
            </Button>

            <ConfirmDialog
                trigger={
                    <Button btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                        <Trans>Удалить ученика</Trans>
                    </Button>
                }
                okText={t`Удалить`}
                cancelText={t`Отмена`}
                title={t`Удалить ученика?`}
                text={t`Вы уверены, что нужно удалить информацию об ученике?`}
                errorText={t`Не удалось удалить профиль ученика`}
                okMethod={deleteBtnClick}/>
        </ButtonsWrapper>
    </div>
}
