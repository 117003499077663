import {IsArray, ValidateNested} from 'class-validator';
import {DtoRequestWithPagination} from "../Common/DtoRequestWithPagination";
import {Type} from "class-transformer";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";

class SelfStudyTrackSubscriptionsTypeForValidate extends DtoRequestWithPagination<DtoSelfStudyTrackSubscriptionUserOverviewItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoSelfStudyTrackSubscriptionUserOverviewItem)
    list: Array<DtoSelfStudyTrackSubscriptionUserOverviewItem>;
}

/**
 * DTO ответа на запрос контента для главной страницы ученика
 */
export class DtoFreeStudentMainPageDataResponse {
    @ValidateNested()
    @Type(() => SelfStudyTrackSubscriptionsTypeForValidate)
    selfStudyTrackSubscriptions: SelfStudyTrackSubscriptionsTypeForValidate;
}