import React from 'react';
import {UniqueIdentifier} from "@dnd-kit/core";
import {IFillGapsDrapDrop} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsDrapDrop";

export type InternalExerciseData = {
    element: IFillGapsDrapDrop,
    parent: null|UniqueIdentifier
}

export type WordParentList = { [wordId: string]: UniqueIdentifier };

export interface IExerciseDragDropContext {
    dragging: boolean;
    draggingItem: InternalExerciseData | null;
    wordParentList: WordParentList;
    allWords: InternalExerciseData[];
    wordsWithoutParent: string[];
    showErrorAnimationInExerciseId: string | null;
    setShowErrorAnimationInExerciseId: (newValue: string | null) => void;
}

export const ExerciseDragDropContext = React.createContext<IExerciseDragDropContext>({
    dragging: false,
    draggingItem: null,
    wordParentList: {},
    allWords: [],
    wordsWithoutParent: [],
    showErrorAnimationInExerciseId: null,
    setShowErrorAnimationInExerciseId: () => {}
});

export const ExerciseDragDropContextProvider = ExerciseDragDropContext.Provider;