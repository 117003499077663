import styled from "styled-components";
import {NoticeBlock, NoticeBlockTitleAsDiv} from "../../Ui/Elements/NoticeBlock";

export const ElementWrapper = styled.div`
  padding: 20px 0;
`;

export const NoticeBlockColored = styled(NoticeBlock)`
  background-color: ${({theme}) => theme.slideContent.teacherAlsoDiscussBg};
`;

export const NoticeBlockTitleStyled = styled(NoticeBlockTitleAsDiv)`
  display: flex;
  flex-direction: row;
`;

export const NoticeBlockTitleText = styled.div`
  padding-top: 4px;
  flex-grow: 1;
`;

export const NoticeBlockTitleIcon = styled.div``;