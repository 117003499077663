import * as React from "react";
import {forwardRef} from "react";
import styled, {css, useTheme} from 'styled-components';
import {Button as DefaultAntButton} from 'antd';
import {ButtonProps as AntButtonProps} from 'antd/lib/button';
import {DotLoader} from "./DotLoader";
import {RegularFontSize} from "../../../styles/global-elements";

export enum BtnStyleEnum {
    Primary,
    Secondary
}

export type DefaultButtonProps = {
    loading?: boolean;
    disabled?: boolean;
    btnStyle: BtnStyleEnum;
    ref?: React.Ref<HTMLElement>;
    icon?: React.ReactNode;
};

type ButtonProps = AntButtonProps & DefaultButtonProps & {
    ref?: React.Ref<HTMLButtonElement>;
};

export const ButtonLoaderStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center
`;

interface ButtonLoaderProps {
    accentColor: string;
}

export const ButtonLoader: React.FC<ButtonLoaderProps> = (props) => {
    return <ButtonLoaderStyle>
        <DotLoader accentColor={props.accentColor}/>
    </ButtonLoaderStyle>
}

export const ButtonStyleCss = css<DefaultButtonProps>`
  ${RegularFontSize};
  
  color: ${
          (props) =>
                  (props.btnStyle === BtnStyleEnum.Primary)
                          ? props.theme.colors.textInverse
                          : props.theme.colors.textPrimary
  };
  background-color: ${
          (props) =>
                  (props.btnStyle === BtnStyleEnum.Primary)
                          ? props.theme.colors.accentPrimary
                          : props.theme.colors.backgroundSecondary
  };
  border-radius: 8px;
  height: auto;
  border: none;
  font-weight: bold;
  text-shadow: none;
  position: relative;
  overflow: hidden;
  
  padding: 12px;
  
  &:focus, &:hover, &:active {
    color: ${
            (props) =>
                    (props.btnStyle === BtnStyleEnum.Primary)
                            ? props.theme.colors.textInverse
                            : props.theme.colors.textPrimary
    };
    background-color: ${
            (props) =>
                    (props.btnStyle === BtnStyleEnum.Primary)
                            ? props.theme.colors.accentPrimary
                            : props.theme.colors.backgroundSecondary
    };
  }

  &[disabled] {
    cursor: wait;
    background-color: ${
            (props) =>
                    (props.btnStyle === BtnStyleEnum.Primary)
                            ? props.theme.colors.disabledButtonBackground
                            : props.theme.colors.backgroundSecondary
    } !important;
  }

  @media (${({theme}) => theme.media.small}) {
    padding: 16px
  }
`;

const AntButton: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>(
    ({btnStyle, ...props}, _ref) => {
        return <DefaultAntButton {...props}>{props.children}</DefaultAntButton>;
    }
)

const ButtonStyle: React.FunctionComponent<ButtonProps> = styled(AntButton)<ButtonProps>`
  ${ButtonStyleCss}
`;

export const Button: React.FC<ButtonProps> = forwardRef<HTMLButtonElement, ButtonProps>((
    {btnStyle, loading, disabled, ...props}, ref
) => {
    const disabledForAnt = (disabled) ? disabled : !!loading;

    const theme = useTheme();

    return <ButtonStyle btnStyle={btnStyle} type="primary" disabled={disabledForAnt} {...props} ref={ref}>
        <span style={{visibility: (loading) ? 'hidden' : 'visible'}}>{props.children}</span>
        {
            loading && <ButtonLoader accentColor={
                (btnStyle === BtnStyleEnum.Primary)
                    ? theme.colors.accentPrimary
                    : theme.colors.textSecondary
            }/>
        }
    </ButtonStyle>;
});
