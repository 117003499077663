import React, {CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import styled, {css, keyframes} from "styled-components";
import {ReactComponent as KeyboardEnterSvgIcon} from "../../../../components/Ui/Svg/KeyboardEnter16.svg";
import {ReactComponent as KeySvgIcon} from "../../../../components/Ui/Svg/Key16.svg";
import classNames from "classnames";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {ExerciseProgressBar} from "../../../ExerciseProgressBar";
import {ContentEditable, ContentEditableRefMethods} from "../../../ContentEditable";
import {ISlideControlContext, SlideControlContext} from "../../SlideControlContext";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {ConfirmDialog} from "../../../Ui/Elements/ConfirmDialog";
import {t} from "@lingui/macro";
import {IAnswerChecker} from "../../../../../components/AnswerChecker/IAnswerChecker";
import {InputHistoryTip} from "../Common/InputHistoryTip";

interface ExerciseProps {
    // Список элементов для выбора. Первый из нах - верный.
    values: string[];
    exerciseId: string;
}

const ErrorTremor = keyframes`
    0% {
        transform: translateX(-3px);
    }

    15% {
        transform: translateX(+3px);
    }

    30% {
        transform: translateX(-2px);
    }

    45% {
        transform: translateX(+2px);
    }

    60% {
        transform: translateX(-1px);
    }

    75% {
        transform: translateX(+1);
    }

    90% {
        transform: translateX(0);
    }
`;

const BaseBlock = css`
    background: ${({theme}) => theme.colors.backgroundPrimary};
    border: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-radius: 5px;
`;

const InputFieldWrapper = styled.div`
    flex-grow: 1;
`;

const EnterIconWrapper = styled.div`
    min-width: 25px;
    opacity: 0.3;
    align-items: center;
    cursor: pointer;

    transition: opacity 0.3s ease;
    padding-left: 5px;

    &.read-only {
        cursor: not-allowed;
    }
`;

const EnterIcon = styled(KeyboardEnterSvgIcon)`
    vertical-align: middle;
    margin-bottom: 3px;
`;

const KeyIconWrapper = styled.div`
    min-width: 25px;
    opacity: 0.3;
    align-items: center;
    cursor: pointer;

    transition: opacity 0.3s ease;
    padding-left: 5px;
`;

const KeyIcon = styled(KeySvgIcon)`
    vertical-align: middle;
    margin-bottom: 3px;
`;

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    transform: translateY(-2px);
    line-height: 1.7em;
    font-size: 1em;
    vertical-align: middle;

    &.correct {
        font-size: 1em;
    }

    &.active {
        z-index: ${({theme}) => theme.zIndices.pageContent};
    }

    &:hover ${EnterIconWrapper} {
        opacity: 0.7;
    }

    &:hover ${KeyIconWrapper} {
        opacity: 0.7;
    }
`;

const InputArea = styled.div`
    ${BaseBlock};

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: text;
    position: relative;
    transform: translateY(0px);
    box-shadow: rgb(0 0 0 / 6%) 0 1px 1px;
    border-radius: 5px;

    transition: transform 0.1s ease, background-position .4s ease-out;

    background: linear-gradient(to left, ${({theme}) => theme.slideContent.textInputDefaultBg} 50%, ${({theme}) => theme.slideContent.textInputCorrectBg} 50%) right;
    background-size: 200% 100%;
    padding: 1px 6px;

    &.correct {
        border: none;
        box-shadow: none;
        cursor: text;
        background-position: left center;
        padding: 1px 6px;
    }

    &.error {
        animation: ${ErrorTremor} 0.9s ease-in-out;
    }

`;

const ControlWrapper = styled.div`
    position: relative;
`;

const ProgressbarWrapper = styled.div`
    margin: 0 4px;
    width: 6px;
    height: 27px;
`;

enum ShowMode {
    INPUT,
    ERROR,
    CORRECT
}

const MAX_ERRORS_COUNT = 5;

export const Exercise: React.FC<ExerciseProps> = (props) => {
    const {exerciseId} = props;

    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);
    const slideControlContext = useContext<ISlideControlContext>(SlideControlContext);

    const [incorrectVariant,] = useState<string>(() => props.values[0]);
    const [correctVariants,] = useState<string[]>(() => {
        let array = [
            ...props.values
        ];

        array.shift();

        return array;
    });

    const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);
    const [defaultInputWidth, setDefaultInputWidth] = useState<number | null>(null);

    const soundPlayer = useMemo<ISoundPlayer>(() => {
        return container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);
    }, []);

    const inputRef = useRef<ContentEditableRefMethods>(null);

    const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
        const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

        const exerciseIndex = slideItemWorkData.exercisesIndexById[exerciseId];

        if (exerciseIndex === undefined) {
            return null;
        }

        return slideItemWorkData.exercises[exerciseIndex];
    }, [exerciseId, slideItemWorkContext]);

    const showMode = useMemo<ShowMode>(() => {
        if (
            (currentExerciseHistoryData)
            && (currentExerciseHistoryData.inputHistory.length > 0)
            && (
                correctVariants.indexOf(
                    currentExerciseHistoryData.inputHistory[currentExerciseHistoryData.inputHistory.length - 1]
                ) > -1
            )
        ) {
            return ShowMode.CORRECT;
        }

        if (nowShowErrorAnimation) {
            return ShowMode.ERROR;
        }

        return ShowMode.INPUT;
    }, [correctVariants, currentExerciseHistoryData, nowShowErrorAnimation]);

    const currentValue = useMemo<string>(() => {
        if (!currentExerciseHistoryData) {
            return incorrectVariant;
        }

        return (currentExerciseHistoryData.value) ?? "";
    }, [currentExerciseHistoryData, incorrectVariant]);

    const keyIconVisible = useMemo<boolean>(() => {
        if (
            (showMode !== ShowMode.INPUT)
            && (showMode !== ShowMode.ERROR)
        ) {
            return false;
        }

        if (slideItemWorkContext.readOnly === true) {
            return false;
        }

        if (currentExerciseHistoryData === null) {
            return false;
        }

        return currentExerciseHistoryData.inputHistory.length > 0;
    }, [showMode, slideItemWorkContext.readOnly, currentExerciseHistoryData]);

    const admitDefeat = useCallback((): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (correctVariants.length < 1) {
                reject();
            }

            if (playerContext.selectedSlide === null) {
                return true;
            }

            slideItemWorkContext.saveAdmitDefeat(
                exerciseId,
                correctVariants[0],
                playerContext.selectedSlide.exercisesCount
            );

            resolve();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correctVariants, exerciseId, playerContext.selectedSlide, slideItemWorkContext.saveAdmitDefeat]);

    const keyIconClickDialog = useMemo<JSX.Element>(() => {
        return <ConfirmDialog okText={t`Показать`}
                              cancelText={t`Отмена`}
                              title={t`Показать ответ?`}
                              okMethod={admitDefeat}
                              text={t`Если посмотреть ответ, баллы не будут начислены`}
                              trigger={<KeyIcon/>}
        />;
    }, [admitDefeat]);

    // Запоминается ширина на старте
    useEffect(
        () => {
            if ((currentValue === incorrectVariant) && (inputRef.current)) {
                const width = inputRef.current.currentWidth();

                setDefaultInputWidth(
                    (width) ? width + 10 : null
                )
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputRef]
    );

    /**
     * Пользователь просто вводит значение и мы запишем это значение, без применения в качестве ответа
     */
    const onVariantUserChange = (newValue: string) => {
        if (
            (slideItemWorkContext.itemId === null)
            || (slideItemWorkContext.slideId === null)
            || (playerContext.selectedSlide === null)
        ) {
            return;
        }

        slideItemWorkContext.saveExerciseValue(
            exerciseId,
            newValue,
        );
    };

    const onVariantEnter = useCallback((variant: string) => {
        if (variant === "") {
            return;
        }

        if (
            (slideItemWorkContext.itemId === null)
            || (slideItemWorkContext.slideId === null)
            || (playerContext.selectedSlide === null)
            || (slideItemWorkContext.readOnly === true)
        ) {
            return;
        }

        const answerChecker = container.resolve<IAnswerChecker>(DiTokens.ANSWER_CHECKER);

        const correctVariantIndex = answerChecker.checkAnswer(correctVariants, variant);

        if (correctVariantIndex === null) {
            setNowShowErrorAnimation(true);

            soundPlayer.playSound(SoundsEnum.ERROR);
        } else {
            soundPlayer.playSound(SoundsEnum.RIGHT);

            // Чтобы скорректировать регистр
            variant = correctVariants[correctVariantIndex];

            // Можем попытаться перейти к следующему упражнению
            slideControlContext.goFocusToNextExercise(
                slideItemWorkContext.slideId,
                slideItemWorkContext.itemId,
                exerciseId
            );
        }
        let award = 0;
        let missedAward = 0;
        let historyItemsCount = 0;

        // Рассчитываем значения упущенной выгоды и награды
        if (currentExerciseHistoryData) {
            historyItemsCount = currentExerciseHistoryData.inputHistory.length;
            award = currentExerciseHistoryData.award;
            missedAward = currentExerciseHistoryData.missedAward;
        }

        if (correctVariantIndex !== null) {
            // Если сейчас был дан верный ответ - присуждаем все доступные баллы
            award = Math.ceil(
                MAX_AWARD_SCORE - missedAward
            );
        } else {
            // Если сейчас был дан ошибочный ответ
            // В этом упражнении дадим ошибиться 5 раз. Пятая ошибка снимает последний балл,
            // который можно было присудить ученику.

            if (historyItemsCount >= MAX_ERRORS_COUNT) {
                // Ученик ошибся максимальное кол-во раз. Нечего рассчитывать.
                missedAward = MAX_AWARD_SCORE;
            } else {
                // Отнимаем каждый раз ровный кусочек
                missedAward += Math.ceil(MAX_AWARD_SCORE / MAX_ERRORS_COUNT);
            }
        }

        if (missedAward > MAX_AWARD_SCORE) {
            missedAward = MAX_AWARD_SCORE;
        }

        if (award > MAX_AWARD_SCORE) {
            award = MAX_AWARD_SCORE;
        }

        slideItemWorkContext.saveExerciseAnswer(
            exerciseId,
            variant,
            award,
            missedAward,
            playerContext.selectedSlide.exercisesCount,
            correctVariantIndex !== null
        );
    }, [correctVariants, currentExerciseHistoryData, exerciseId, playerContext.selectedSlide, slideControlContext, slideItemWorkContext, soundPlayer]);

    const answerInputWidth = useMemo<CSSProperties>(() => {
        return (showMode !== ShowMode.CORRECT)
            ? {
                minWidth: (defaultInputWidth) ? defaultInputWidth.toString(10) + 'px' : 'auto'
            } : {};
    }, [showMode, defaultInputWidth]);

    const inputDisabled = useMemo<boolean>(() => {
        return (showMode === ShowMode.CORRECT) || (slideItemWorkContext.readOnly === true);
    }, [showMode, slideItemWorkContext.readOnly])

    const enterIconClick = useCallback(() => {
        onVariantEnter(currentValue);
    }, [currentValue, onVariantEnter])

    // Рендер результата
    if (slideItemWorkContext.showCorrectAnswers === true) {
        if (correctVariants.length === 0) {
            return <div/>;
        }

        // Если нужно показать сразу верный вариант
        return <Wrapper
            data-exercise-id={exerciseId}
            className={"correct"}>
            <ControlWrapper>
                <InputArea className={"correct"}>
                    <InputFieldWrapper>
                        <ContentEditable customAttributes={{"data-exercise-input": ""}}
                                         ref={inputRef}
                                         id={exerciseId}
                                         value={correctVariants[0]}
                            // onChange={onVariantUserChange}
                            // onChange={onVariantUserChange}
                                         disabled={true}
                                         maxLength={255}/>
                    </InputFieldWrapper>
                </InputArea>
            </ControlWrapper>
        </Wrapper>;
    }

    return <Wrapper
        data-exercise-id={exerciseId}
        className={classNames(
            (showMode === ShowMode.CORRECT) && "correct",
            (showMode === ShowMode.ERROR) && "error"
        )}>
        <ControlWrapper>
            <InputHistoryTip
                inputHistory={currentExerciseHistoryData?.inputHistory}
                lastIsCorrect={showMode === ShowMode.CORRECT}
            >
                <InputArea className={classNames(
                    (showMode === ShowMode.CORRECT) && "correct",
                    (showMode === ShowMode.ERROR) && "error",
                )}
                           onAnimationEnd={() => setNowShowErrorAnimation(false)}>
                    <InputFieldWrapper>
                        <ContentEditable customAttributes={{"data-exercise-input": ""}}
                                         style={answerInputWidth}
                                         ref={inputRef}
                                         id={exerciseId}
                                         value={currentValue}
                                         onBlur={onVariantUserChange}
                            // onChange={onVariantUserChange}
                            // onChange={onVariantUserChange}
                                         onPressEnter={onVariantEnter}
                                         placeholder={incorrectVariant}
                                         disabled={inputDisabled}
                                         maxLength={255}/>
                    </InputFieldWrapper>
                    {
                        (showMode !== ShowMode.CORRECT)
                        && <EnterIconWrapper className={classNames(slideItemWorkContext.readOnly && "read-only")}
                                             onClick={enterIconClick}>
                            <EnterIcon/>
                        </EnterIconWrapper>
                    }
                    {
                        (keyIconVisible)
                        && <KeyIconWrapper>
                            {keyIconClickDialog}
                        </KeyIconWrapper>
                    }
                </InputArea>
            </InputHistoryTip>
        </ControlWrapper>
        <ProgressbarWrapper>
            <ExerciseProgressBar wrongValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.missedAward : 0}
                                 rightValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.award : 0}/>
        </ProgressbarWrapper>
    </Wrapper>;
}

Exercise.displayName = 'ExerciseCorrectExistInInputExercise';