import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomsActionTypes} from "../../store/lessonRooms/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {NotificationTypesEnum, openNotification} from "../../views/components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {DtoLessonClosed} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoLessonClosed";
import {ApplicationState} from "../../store";
import {removeOpenedLessonRoom} from "../../store/lessonRooms/actions";
import {RoutesList} from "../../views/RoutesList";
import {container} from "tsyringe";
import {IRouterService} from "../../services/router/IRouterService";
import {DiTokens} from "../../di-factory/DiTokens";
import {clearRoomState} from "../../store/lessonRoom/actions";

/**
 * Обработка сообщения о завершении комнаты урока. Нужно вызвать метод leave.
 */
export function* watchHandleSeLessonRoomClosed() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoLessonClosed>, null>>>(
        LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_CLOSED,
        handleSeLessonRoomClosed
    );
}

const currentRoomIdSelector = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleSeLessonRoomClosed(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoLessonClosed>, null>>) {
    yield put(removeOpenedLessonRoom(data.payload.response.dto.payload.lessonRoomId));

    // Нужно проверить, что мы сейчас в указанной группе
    const currentRoomId = (yield select(currentRoomIdSelector)) as string | null;

    if (currentRoomId !== data.payload.response.dto.payload.lessonRoomId) {
        // Получается, мы не находимся в комнате из которой нас выгнали. Игнорируем событие.
        return;
    }

    const routerService = container.resolve<IRouterService>(DiTokens.ROUTER_SERVICE);

    if (routerService.navigate) {
        routerService.navigate(RoutesList.MAIN_PAGE);
    }

    openNotification(
        NotificationTypesEnum.SUCCESS,
        t`Урок завершён`,
        ``
    );

    yield put(clearRoomState());

    // TODO: выполнить операции по очистке данных текущей комнаты в store и вызов leave from room
}
