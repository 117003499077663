import {StreamEventRoutesList} from "../services/stream-event-service/StreamEventRoutesList";
import {StreamEventTypeEnum} from "../services/stream-event-service/StreamEventTypeEnum";
import {streamEventUserStateUpdate} from "../store/userOnlineStateSubscriptions/actions";
import {
    handleSeLessonRoomClosed,
    handleSeLessonRoomKicked,
    handleSeLessonRoomOpened
} from "../store/lessonRooms/actions";
import {
    handleSeMemberEntered,
    handleSeMemberLeft,
    handleSeMemberOpenedSlideChanged,
    handleSeMemberPublicFullScreenValue,
    handleSeMemberPublicScreenOrientation,
    handleSeMemberPublicVideoPinState,
    handleSeNeedSelectRoomMemberVideo,
    handleSeNeedSetMembersFullScreenModeValue,
    handleSeStudentNeedOpenSlide
} from "../store/lessonRoom/actions";
import {handleSeNeedSyncSlideList} from "../store/lessonMaterials/actions";
import {
    handleSEStudentLessonSlideWorkDataUpdated,
    handleSEStudentLessonWorkDataSummaryItem
} from "../store/slidesWorkData/actions";

/**
 * Перечисление маршрутов для направления сообщений для обработчиков событий истории событий.
 */
export const streamEventRoutes: StreamEventRoutesList = [
    {
        streamType: StreamEventTypeEnum.USER_ONLINE_STATE_UPDATE,
        actionCreator: streamEventUserStateUpdate
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_OPENED,
        actionCreator: handleSeLessonRoomOpened
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_KICKED,
        actionCreator: handleSeLessonRoomKicked
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_CLOSED,
        actionCreator: handleSeLessonRoomClosed
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_PARTICIPANT_ENTERED,
        actionCreator: handleSeMemberEntered
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_PARTICIPANT_LEFT,
        actionCreator: handleSeMemberLeft
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_STUDENT_NEED_OPEN_SLIDE,
        actionCreator: handleSeStudentNeedOpenSlide
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_NEED_SELECT_ROOM_MEMBER_VIDEO,
        actionCreator: handleSeNeedSelectRoomMemberVideo
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE,
        actionCreator: handleSeNeedSetMembersFullScreenModeValue
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_STUDENT_CURRENT_SLIDE_CHANGED,
        actionCreator: handleSeMemberOpenedSlideChanged
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_STUDENT_SLIDE_SUMMARY_UPDATE,
        actionCreator: handleSEStudentLessonWorkDataSummaryItem
    },
    {
        streamType: StreamEventTypeEnum.LESSON_AND_HOMEWORK_NEED_SYNC_SLIDE_LIST,
        actionCreator: handleSeNeedSyncSlideList
    },
    {
        streamType: StreamEventTypeEnum.LESSON_AND_HOMEWORK_STUDENT_SLIDE_WORK_DATA_UPDATE,
        actionCreator: handleSEStudentLessonSlideWorkDataUpdated
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN_STATE,
        actionCreator: handleSeMemberPublicVideoPinState
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN,
        actionCreator: handleSeMemberPublicFullScreenValue
    },
    {
        streamType: StreamEventTypeEnum.LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION,
        actionCreator: handleSeMemberPublicScreenOrientation
    },
];
