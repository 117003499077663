import {Modal, ModalChildProps} from "../Ui/Elements/Modal";
import {Plural, t, Trans} from "@lingui/macro";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {
    SchoolAccountDto
} from "../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/SchoolAccountDto";
import {
    selectedAgreement as selectedAgreementSelector,
    selectedSchool as selectedSchoolSelector, selectedUserInSchool as selectedUserInSchoolSelector
} from "../../../store/user/selector";
import {
    UserAgreementDto
} from "../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserAgreementDto";
import {RadioIcon} from "../Ui/Elements/RadioIcon";
import {useLingui} from "@lingui/react";
import {BtnStyleEnum} from "../Ui/Elements/Button";
import * as UserActionCreators from "../../../store/user/actions";
import {
    UserInSchoolAccountDto
} from "../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserInSchoolAccountDto";
import {
    SelectSchoolTitle,
    SchoolsList,
    SchoolItem,
    SchoolDefaultAvatar,
    SchoolIcon,
    SchoolName,
    SchoolSubName,
    SelectSchoolSubtitle,
    AgreementsList,
    AgreementItem,
    AgreementIconWrapper,
    AgreementNameWrapper,
    AgreementName,
    AgreementDetails,
    SelectButton
} from "./styles";

interface SchoolAgreementItem {
    userInSchoolAccount: UserInSchoolAccountDto,
    agreementItem: UserAgreementDto,
    agreementName: string,
    courseName: string
}

interface SchoolSelectorProps extends ModalChildProps {

}

export const SchoolSelectorModal: React.FC<SchoolSelectorProps> = (props) => {
    const schoolsArray = useSelector<ApplicationState>(
        ({user}: ApplicationState) => {
            if (
                (!user.profileData)
                || (!Array.isArray(user.profileData.schools))
            ) {
                return [];
            }

            return user.profileData.schools;
        }
    ) as SchoolAccountDto[];

    const dispatch = useDispatch();

    const selectedSchoolInApp = useSelector(selectedSchoolSelector) as SchoolAccountDto | null;
    const selectedUserInSchoolInApp = useSelector(selectedUserInSchoolSelector) as UserInSchoolAccountDto | null;
    const selectedAgreementInApp = useSelector(selectedAgreementSelector) as UserAgreementDto | null;

    const [selectedSchoolItem, setSelectedSchoolItem] = useState<SchoolAccountDto | null>(null);
    const [selectedUserInSchoolAccount, setSelectedUserInSchoolAccount] = useState<UserInSchoolAccountDto | null>(null);
    const [selectedAgreementItem, setSelectedAgreementItem] = useState<UserAgreementDto | null>(null);

    const {i18n} = useLingui();

    useEffect(() => {
        if (selectedSchoolInApp) {
            setSelectedSchoolItem(selectedSchoolInApp);
        }

        if (selectedUserInSchoolInApp) {
            setSelectedUserInSchoolAccount(selectedUserInSchoolInApp);
        }

        if (selectedAgreementInApp) {
            setSelectedAgreementItem(selectedAgreementInApp);
        }
    }, [selectedAgreementInApp, selectedUserInSchoolInApp, selectedSchoolInApp]);

    const accountSelectedInSelectedSchool = useMemo((): boolean => {
        if (
            (selectedSchoolItem === null)
            || (selectedUserInSchoolAccount === null)
        ) {
            return false;
        }

        return selectedSchoolItem.accounts.some(
            (userInSchool) => userInSchool.id === selectedUserInSchoolAccount.id
        );

    }, [selectedSchoolItem, selectedUserInSchoolAccount]);

    const agreementsList = useMemo<SchoolAgreementItem[]>(() => {
        const result: SchoolAgreementItem[] = [];

        if (selectedSchoolItem === null) {
            return result;
        }

        selectedSchoolItem.accounts.forEach((account) => {
            account.agreements.forEach((agreement) => {
                const docName = t({
                    id: "Договор {docNumber} от {docDate}",
                    values: {
                        docNumber: agreement.docPrefix + agreement.docNumber,
                        docDate: i18n.date(agreement.startWork)
                    }
                });

                result.push({
                    agreementItem: agreement,
                    agreementName: docName,
                    userInSchoolAccount: account,
                    courseName: `${agreement.courseName} (${account.name})`
                });
            });
        });

        return result;
    }, [i18n, selectedSchoolItem]);

    const schoolItem = (item: SchoolAccountDto) => {
        const itemIsActive: boolean = (
            (selectedSchoolItem !== null)
            && (selectedSchoolItem.id === item.id)
        );

        let accountsInSchool = 0;

        item.accounts.forEach((userInSchool) => {
            accountsInSchool += userInSchool.agreements.length;
        });

        return <SchoolItem key={item.id} active={itemIsActive}
                           onClick={() => setSelectedSchoolItem(item)}>
            <SchoolIcon>
                <SchoolDefaultAvatar/>
            </SchoolIcon>
            <SchoolName>
                {item.name}
            </SchoolName>
            <SchoolSubName>
                <Plural
                    value={accountsInSchool}
                    zero="Нет договоров"
                    one="# договор"
                    few="# договора"
                    many="# договоров"
                    other="# договоров"
                />
            </SchoolSubName>
        </SchoolItem>;
    }

    const agreementItem = (item: SchoolAgreementItem) => {
        const itemIsActive = (
            (selectedAgreementItem !== null) && (selectedAgreementItem.id === item.agreementItem.id)
        );

        return <AgreementItem
            key={item.agreementItem.id}
            onClick={() => {
                setSelectedUserInSchoolAccount(item.userInSchoolAccount);
                setSelectedAgreementItem(item.agreementItem);
            }}>
            <AgreementIconWrapper>
                <RadioIcon checked={itemIsActive}/>
            </AgreementIconWrapper>

            <AgreementNameWrapper>
                <AgreementName>{item.agreementItem.trainingProgramName}</AgreementName>
                <AgreementDetails>{item.agreementName}</AgreementDetails>
                <AgreementDetails>{item.courseName}</AgreementDetails>
            </AgreementNameWrapper>
        </AgreementItem>;
    }

    const selectButtonOnClick = useCallback((close: () => void) => {
        const activateAgreementInApp = (schoolId: string, userInSchoolId: string, agreementId: string) =>
            dispatch(UserActionCreators.activateAgreementId({
                    schoolId, userInSchoolId, agreementId
                })
            );

        if (
            (selectedSchoolItem === null)
            || (selectedUserInSchoolAccount === null)
            || (selectedAgreementItem === null)
        ) {
            return;
        }

        activateAgreementInApp(
            selectedSchoolItem.id,
            selectedUserInSchoolAccount.id,
            selectedAgreementItem.id
        );

        close();
    }, [dispatch, selectedSchoolItem, selectedUserInSchoolAccount, selectedAgreementItem]);

    return <Modal onOpen={props.onOpen}
                  closeAllowed={true}
                  trigger={props.trigger}
                  footer={
                      (controls) => {
                          return <div>
                              <SelectButton btnStyle={BtnStyleEnum.Primary}
                                            disabled={!accountSelectedInSelectedSchool}
                                            onClick={() => selectButtonOnClick(controls.closeModal)}
                              >
                                  <Trans>Выбрать</Trans>
                              </SelectButton>
                          </div>;
                      }
                  }
                  children={
                      (controls) => {
                          return <><SelectSchoolTitle>
                              <Trans>Выбор школы</Trans>
                          </SelectSchoolTitle>
                              <SchoolsList>
                                  {schoolsArray.map((school) => schoolItem(school))}
                              </SchoolsList>
                              <SelectSchoolSubtitle>
                                  <Trans>Программы занятий</Trans>
                              </SelectSchoolSubtitle>
                              <AgreementsList>
                                  {agreementsList.map((agreement) => agreementItem(agreement))}
                              </AgreementsList></>;
                      }
                  }
    />;
}