import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

interface ButtonAreaProps {
    prevSectionAvailable: boolean;
    nextSectionAvailable: boolean;
    prevBtnOnClick: () => Promise<void>;
    nextBtnOnClick: () => Promise<void>;
}

export const ButtonArea: React.FC<ButtonAreaProps> = (
    {
        prevSectionAvailable,
        nextSectionAvailable,
        prevBtnOnClick,
        nextBtnOnClick
    }
) =>
{
    const [prevNowLoading, setPrevNowLoading] = useState<boolean>(false);
    const [nextNowLoading, setNextNowLoading] = useState<boolean>(false);

    const prevOnClick = useCallback(() => {
        setPrevNowLoading(true);

        prevBtnOnClick().finally(() => setPrevNowLoading(false));
    }, [prevBtnOnClick]);

    const nextOnClick = useCallback(() => {
        setNextNowLoading(true);

        nextBtnOnClick().finally(() => setNextNowLoading(false));
    }, [nextBtnOnClick]);

    return <Wrapper>
        <div>
            <Button
                btnStyle={BtnStyleEnum.Secondary}
                disabled={nextNowLoading}
                loading={prevNowLoading}
                onClick={prevOnClick}
            >
                {
                    (prevSectionAvailable)
                        ? <Trans>Назад</Trans>
                        : <Trans>Отмена</Trans>
                }
            </Button>
        </div>
        <div>
            <Button
                btnStyle={BtnStyleEnum.Primary}
                disabled={prevNowLoading}
                loading={nextNowLoading}
                onClick={nextOnClick}
            >
                {
                    (nextSectionAvailable)
                        ? <Trans>Далее</Trans>
                        : <Trans>Завершить</Trans>
                }
            </Button>
        </div>
    </Wrapper>
}