import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ApplicationState} from "../../../../store";
import React from "react";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {withRouter, WithRouterProps} from "../../../components/WithRouterHoc";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {
    DtoGroupGetByIdResponse
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupGetByIdResponse";
import {
    DtoGroupNextLessonInfo
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupNextLessonInfo";
import {DtoStudentBase} from "../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentBase";
import {
    DtoGroupCompiledSchedule
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupCompiledSchedule";
import {PageSubtitleSmallMargin} from "../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {NextLessonDetails} from "./NextLessonDetails";
import {
    DtoGroupSchedule
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {RoutesList} from "../../../RoutesList";
import {DtoLessonListItem} from "../../../../components/StonlineApiClient/ApiDto/Response/Lesson/DtoLessonListItem";
import {LessonsListGrid} from "./LessonsListGrid";
import {Pagination} from "../../../components/Ui/Elements/Pagination";
import {connect} from "react-redux";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {NotificationTypesEnum, openNotification} from "../../../components/Ui/Elements/Notification";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {ResponseStatusEnum} from "../../../../components/StonlineApiClient/Enums/ResponseStatusEnum";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {PageWrapper} from "../../../components/PageWrapper";
import {SmallVerticalScheduleTable} from "../../../components/ScheduleTables/SmallVerticalScheduleTable";

const ITEMS_PER_PAGE = 30;

const CreateLessonBtn = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

interface RouterParams {
    groupId: string;
}

interface GroupProps {
    stToken: string | null;
    currentStUserId: number | null;
}

type GroupPropsWRouter = WithRouterProps<RouterParams> & GroupProps;

interface GroupState {
    loadingState: LoadingState;
    groupIdInt: number | null;

    groupDto: DtoGroupGetByIdResponse | null;
    nextLessonInfoDto: DtoGroupNextLessonInfo | null;
    groupStudents: DtoStudentBase[] | null;
    groupSchedule: DtoGroupCompiledSchedule | null;

    lessonsList: DtoLessonListItem[] | null;
    lessonsListLoadingState: LoadingState;
    totalLessonsCount: number;
    currentLessonsListPage: number;

    newLessonCreateProcess: boolean;
}

class GroupInfo extends React.Component<GroupPropsWRouter, GroupState> {
    protected listAbortController: AbortController | null;
    protected createNewLessonAbortController: AbortController | null;

    protected stonlineApiClient: IStonlineApiClient;
    protected dateHelperService: IDateHelperService;

    protected logger: ILogger;

    protected fetchBaseGroupDataBind;
    protected createNewLessonBind;

    constructor(props: Readonly<GroupPropsWRouter>) {
        super(props);

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);


        this.state = {
            loadingState: LoadingState.NOT_INIT,
            groupIdInt: null,

            groupDto: null,
            nextLessonInfoDto: null,
            groupStudents: null,
            groupSchedule: null,

            lessonsList: null,
            lessonsListLoadingState: LoadingState.NOT_INIT,
            totalLessonsCount: 0,
            currentLessonsListPage: 1,

            newLessonCreateProcess: false
        }

        this.listAbortController = null;
        this.createNewLessonAbortController = null;
        this.fetchBaseGroupDataBind = this.fetchBaseGroupData.bind(this);
        this.createNewLessonBind = this.createNewLesson.bind(this);
    }

    componentDidMount() {
        const groupId = parseInt(this.props.match.params.groupId);

        if ((isNaN(groupId)) || (groupId < 1)) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            return;
        }

        this.setState(
            () => {
                return {
                    groupIdInt: groupId
                }
            },
            () => {
                this.fetchBaseGroupDataBind();
            }
        );
    }

    componentWillUnmount() {
        if (this.listAbortController !== null) {
            this.listAbortController.abort();
            this.listAbortController = null;
        }

        if (this.createNewLessonAbortController !== null) {
            this.createNewLessonAbortController.abort();
            this.createNewLessonAbortController = null;
        }
    }

    protected linkToLesson = (groupId: number, lessonId: number) => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_PAGE,
            [
                {
                    key: 'groupId',
                    value: groupId.toString(10)
                },
                {
                    key: 'lessonId',
                    value: lessonId.toString(10)
                }
            ]
        );
    }

    protected fetchBaseGroupData = () => {
        if (
            (this.props.stToken === null)
            || (this.state.groupIdInt === null)
        ) {
            return null;
        }

        this.listAbortController = new AbortController();

        this.stonlineApiClient.getGroupBaseInfo(
            this.props.stToken,
            this.state.groupIdInt,
            this.dateHelperService.formatAsSQLDateTime(new Date()),
            this.dateHelperService.formatAsSQLDate(new Date()),
            ITEMS_PER_PAGE,
            this.listAbortController
        )
            .then(result => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                        groupDto: result[0],
                        nextLessonInfoDto: result[1],
                        groupStudents: result[2],
                        groupSchedule: result[3],


                        lessonsList: result[4].items,
                        lessonsListLoadingState: LoadingState.SUCCESS,
                        totalLessonsCount: result[4].totalCount,
                        currentLessonsListPage: 1
                    }
                })
            })
            .catch(err => {
                this.logger.error(LoggerSectionsEnum.STONLINE_GROUPS_LIST_API, err);

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                })
            });
    }

    protected fetchLessons = async () => {
        if ((!this.props.stToken) || (!this.state.groupIdInt)) {
            return;
        }

        this.setState(() => {
            return {
                lessonsListLoadingState: LoadingState.LOADING
            }
        });

        if (this.listAbortController !== null) {
            this.listAbortController.abort();
        }

        this.listAbortController = new AbortController();

        try {
            const result = await this.stonlineApiClient.getLessonsList(
                this.props.stToken,
                this.state.groupIdInt,
                this.state.currentLessonsListPage,
                ITEMS_PER_PAGE,
                this.listAbortController
            );

            this.setState(() => {
                return {
                    lessonsListLoadingState: LoadingState.SUCCESS,
                    lessonsList: result.result.items,
                    totalLessonsCount: result.result.totalCount
                }
            });
        } catch (e) {
            this.logger.error(LoggerSectionsEnum.STONLINE_GROUPS_LIST_API, e);

            this.setState(() => {
                return {
                    lessonsListLoadingState: LoadingState.ERROR
                }
            });
        }
    }

    protected lessonsList = () => {
        switch (this.state.lessonsListLoadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.SUCCESS: {
                if (
                    (this.state.groupDto !== null)
                    && (this.state.lessonsList !== null)
                    && (this.state.groupIdInt !== null)
                    && (this.state.totalLessonsCount !== null)
                ) {
                    if (this.state.lessonsList.length > 0) {
                        return <>
                            <LessonsListGrid items={this.state.lessonsList}
                                             groupId={this.state.groupIdInt}
                                             groupTeacherId={this.state.groupDto.teacherId}/>
                            <Pagination defaultCurrent={this.state.currentLessonsListPage}
                                        total={this.state.totalLessonsCount}
                                        hideOnSinglePage={true}
                                        onChange={(page, _pageSize) => {
                                            this.setState({
                                                currentLessonsListPage: page
                                            }, () => this.fetchLessons());
                                        }}
                                        defaultPageSize={ITEMS_PER_PAGE}/>
                        </>
                    } else {
                        return <NoticeBlock>
                            <>
                                <NoticeBlockTitle>
                                    <Trans>В группе занятия ещё не проводились</Trans>
                                </NoticeBlockTitle>

                                <NoticeBlockText>
                                    <Trans>Можно нажать кнопку «Новый урок», чтобы начать занятие или создать его
                                        черновик.</Trans>
                                </NoticeBlockText>
                            </>
                        </NoticeBlock>;
                    }
                }

                return <ErrorLoadingContent retryBtnClick={this.fetchLessons}/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.fetchLessons}/>;
            }
        }
    }

    protected createNewLesson = () => {
        if (
            (this.state.groupIdInt === null)
            || (this.props.stToken === null)
            || (this.props.currentStUserId === null)
        ) {
            return;
        }

        this.setState(() => {
            return {
                newLessonCreateProcess: true
            }
        });

        const currentDate = new Date();
        this.createNewLessonAbortController = new AbortController();

        this.stonlineApiClient.getPlanLessonsForDate(
            this.props.stToken,
            this.state.groupIdInt,
            this.dateHelperService.formatAsSQLDate(currentDate),
            this.createNewLessonAbortController,
        )
            .then((data) => {
                let timeStart = '00:00:00';
                let timeEnd = '00:00:00';

                if (data.resultStatus === ResponseStatusEnum.SUCCESS) {
                    if (data.result.items.length > 0) {
                        timeStart = data.result.items[0].timeStart;
                        timeEnd = data.result.items[0].timeEnd;
                    }
                }

                if (
                    (this.state.groupIdInt === null)
                    || (this.props.stToken === null)
                    || (this.props.currentStUserId === null)
                ) {
                    throw new Error('groupIdInt or stToken or currentStUserId is null after getPlanLessonsForDate');
                }

                this.createNewLessonAbortController = new AbortController();

                return this.stonlineApiClient.createLessonDraft(
                    this.props.stToken,
                    this.state.groupIdInt,
                    this.props.currentStUserId,
                    this.dateHelperService.formatAsSQLDate(currentDate),
                    timeStart,
                    timeEnd,
                    this.createNewLessonAbortController
                );
            })
            .then((data) => {
                if (this.state.groupIdInt === null) {
                    return;
                }

                this.props.navigate(
                    this.linkToLesson(this.state.groupIdInt, parseInt(data.result.lessonId, 10))
                );
            })
            .catch((error) => {
                this.setState(() => {
                    return {
                        newLessonCreateProcess: false
                    }
                });

                this.logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, "Error on create new lesson", error);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось создать занятие`
                );
            });
    }

    render() {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.fetchBaseGroupDataBind}/>;
            }
            case LoadingState.SUCCESS: {
                if (this.state.groupDto === null) {
                    return <ErrorLoadingContent retryBtnClick={this.fetchBaseGroupDataBind}/>;
                }

                return <PageWrapper
                    backLink={{
                        title: t`Группы`,
                        href: RoutesList.TEACHER_ALL_GROUPS
                    }}
                    pageTitle={this.state.groupDto.name}
                    rightBlockContent={
                        <>
                            <CreateLessonBtn btnStyle={BtnStyleEnum.Primary}
                                    onClick={this.createNewLessonBind}
                                    loading={this.state.newLessonCreateProcess}>
                                <Trans>Новый урок</Trans>
                            </CreateLessonBtn>

                            <PageSubtitleSmallMargin><Trans>Расписание</Trans></PageSubtitleSmallMargin>
                            <SmallVerticalScheduleTable schedule={this.state.groupSchedule as DtoGroupSchedule}/>

                            {
                                (this.state.nextLessonInfoDto)
                                && <>
                                    <PageSubtitleSmallMargin><Trans>Следующее занятие</Trans></PageSubtitleSmallMargin>
                                    <NextLessonDetails nextLesson={this.state.nextLessonInfoDto}/>
                                </>
                            }
                        </>
                    }
                    pageContent={
                        <>
                            <PageSubtitleSmallMargin><Trans>Прошедшие уроки</Trans></PageSubtitleSmallMargin>
                            {this.lessonsList()}
                        </>
                    }
                />
            }
        }
    }
}

const mapStateToProps = ({user}: ApplicationState) => ({
    stToken: user.stToken,
    currentStUserId: (user.profileData) ? user.profileData.schools[0].accounts[0].stId : null
});

export default withRouter(connect(mapStateToProps)(GroupInfo));
