import {IsNotEmpty, IsNumber, IsString, Length, MaxLength, ValidateNested} from 'class-validator';
import {DtoSubjectArea} from "./DtoSubjectArea";
import {Type} from "class-transformer";

/**
 * DTO уровня предметной области
 */
export class DtoSubjectAreaLevel {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    @Length(1, 100)
    alias: string;

    @IsNotEmpty()
    @IsString()
    @MaxLength(100)
    internationalName: string;

    @IsNotEmpty()
    @IsNumber()
    order: number;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoSubjectArea)
    subjectArea: DtoSubjectArea;
}
