import {select, takeEvery, put} from 'redux-saga/effects';
import {LessonRoomsActionTypes} from "../../store/lessonRooms/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {DtoBaseWithLessonRoomId} from "../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoBaseWithLessonRoomId";
import {NotificationTypesEnum, openNotification} from "../../views/components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {ApplicationState} from "../../store";
import {container} from "tsyringe";
import {IRouterService} from "../../services/router/IRouterService";
import {DiTokens} from "../../di-factory/DiTokens";
import {RoutesList} from "../../views/RoutesList";
import {clearRoomState} from "../../store/lessonRoom/actions";


/**
 * Обработка сообщения об удалении ученика с урока (например, если он подключился параллельным сеансом).
 * В этом случае нужно просто зафиксировать выход из комнаты, leave выполнять не нужно.
 */
export function* watchHandleSeLessonRoomKicked() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoBaseWithLessonRoomId>, null>>>(
        LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_KICKED,
        handleSeLessonRoomKicked
    );
}

const currentRoomIdSelector = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleSeLessonRoomKicked(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoBaseWithLessonRoomId>, null>>) {

    // Нужно проверить, что мы сейчас в указанной группе
    const currentRoomId = (yield select(currentRoomIdSelector)) as string | null;

    if (currentRoomId !== data.payload.response.dto.payload.lessonId) {
        // Получается, мы не находимся в комнате из которой нас выгнали. Игнорируем событие.
        return;
    }

    const routerService = container.resolve<IRouterService>(DiTokens.ROUTER_SERVICE);

    if (routerService.navigate) {
        routerService.navigate(RoutesList.MAIN_PAGE);
    }

    openNotification(
        NotificationTypesEnum.ERROR,
        t`Выполнено отключение от урока`,
        t`Вероятно вы вошли на урок в другой вкладке`,
    );
}
