import {
    CreateAgreementTeamModeEnum
} from "../../../../../components/StonlineApiClient/Enums/CreateAgreementTeamModeEnum";
import {
    DtoGroupSchedule
} from "../../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";

export class CreateAgreementRequestData {

    teamMode: CreateAgreementTeamModeEnum;
    teamId: number | null;
    teamName: string;
    courseId: number | null;
    courseName: string;
    tpId: number | null;
    tpName: string;
    lessonCost: number;
    startDate: string | null;
    schedule: DtoGroupSchedule;

    constructor() {
        this.teamMode = CreateAgreementTeamModeEnum.INDIVIDUAL;
        this.teamId = null;
        this.teamName = '';
        this.courseId = null;
        this.courseName = '';
        this.tpId = null;
        this.tpName = '';
        this.lessonCost = 0;
        this.startDate = null;
        this.schedule = new DtoGroupSchedule();
    }
}