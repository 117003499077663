import React from "react";

export interface IHomeworkPageContext {
    lessonId: string | null;
    finishSlideIsAvailable: boolean;
}

export const HomeworkPageContext = React.createContext<IHomeworkPageContext>({
    lessonId: null,
    finishSlideIsAvailable: false
});

export const HomeworkPageContextProvider = HomeworkPageContext.Provider;
