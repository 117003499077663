import * as React from "react";
import styled from 'styled-components';
import {Spin as AntSpin} from 'antd';
import {SpinProps as AntSpinProps} from 'antd/lib/spin';

type SpinProps = AntSpinProps & {};

const Tip = styled.p`
  margin: 15px 0 0 0;
`;

export const Spin = ({tip, ...props}: SpinProps) => {
    return <div>
        <AntSpin {...props}/>
        <Tip>{tip}</Tip>
    </div>
};
