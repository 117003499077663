import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t} from "@lingui/macro";

const ExerciseRadioStyle = styled.div`
`

interface ExerciseRadioProps extends ItemPropsInterface<any> {
}

interface ExerciseRadioRefMethods extends ItemRefMethodsInterface {
}

export const ExerciseRadio = forwardRef<ExerciseRadioRefMethods, ExerciseRadioProps>(
    (props, ref) => {

        const richTextEditorRef = useRef<RichTextEditor>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                richTextEditorRef.current?.toolbarItemOnToggle(buttonType, newValue);
            },
            getExercisesCount: () => {
                if (!richTextEditorRef.current) {
                    return 0;
                }

                return richTextEditorRef.current?.getExercisesCount();
            }
        }));

        return <ExerciseRadioStyle>
            <RichTextEditor editorMode={RichTextEditorMode.RADIO_LIST}
                            placeHolderText={t`Упражнение "Выбор одного из вариантов"`}
                            setToolbarConfigById={props.setToolbarConfigById}
                            ref={richTextEditorRef}
                            onChange={props.onChange}
                            initialData={props.initialData}
                            id={props.id}
            />
        </ExerciseRadioStyle>;
    }
);