import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {PaymentTypesEnum} from "../../../../../components/PaymentsTable/PaymentTypesEnum";
import {isEqual} from "lodash";
import {Filters} from "../../../../../components/PaymentsTable/Filters";
import useDebounce from "../../../../../../components/DebounceHook";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {PaymentsTable} from "../../../../../components/PaymentsTable/Table";
import {PaymentTableItem} from "../../../../../components/PaymentsTable/Table/PaymentTableItemType";
import {PageLoadingStateEnum} from "../../../../../../enums/PageLoadingStateEnum";
import {NoConnection} from "../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import styled from "styled-components";
import {PayEditModal, PayEditModalProps} from "../../../../../components/PayEditModal";
import {PayTypeEnum} from "../../../../../../components/StonlineApiClient/Enums/PayTypeEnum";
import {PopupActions} from "reactjs-popup/dist/types";
import {
    PermissionDeniedException
} from "../../../../../../components/StonlineApiClient/Exception/PermissionDeniedException";

const ButtonWrapper = styled.div`
  margin-top: 35px;
  width: 100%;
`;

const AddButton = styled(Button)`
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    width: auto;
  }
`;

export const StudentsPaymentsSection: React.FC = () => {
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
    const stToken = useSelector(stTokenSelector);

    const dateHelperService = useMemo<IDateHelperService>(
        () => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE),
        []
    );

    const [fromDate, setFromDate] = useState<Date | null>(dateHelperService.getFirstDayThreeMonthBefore());
    const [toDate, setToDate] = useState<Date | null>(null);
    const [selectedTypes, setSelectedTypes] = useState<PaymentTypesEnum[]>([
        PaymentTypesEnum.CONSUMPTION,
        PaymentTypesEnum.ADMISSION,
        PaymentTypesEnum.LESSON_PAYMENTS
    ]);

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [items, setItems] = useState<PaymentTableItem[]>([]);
    const [filterChangeIterationNum, setFilterChangeIterationNum] = useState<number>(0);

    const [noPermission, setNoPermission] = useState<boolean>(false);

    const [payEditModalProps, setPayEditModalProps] = useState<PayEditModalProps>({
        // Данные - заглушка
        itemId: null,
        payType: PayTypeEnum.LESSON_PAYMENT,
        subjectId: 0,
        subjectName: '',
        paymentDate: new Date(),
        sum: 0,
        comment: '',
        onUpdate: () => {
        }
    });
    const payEditModalRef = useRef<PopupActions>(null);

    const filterChangeIterationNumDebounced = useDebounce(filterChangeIterationNum);

    const onFiltersChange = useCallback((newFromDate: Date, newToDate: Date, newItems: PaymentTypesEnum[]) => {
        let somethingChanged = false;

        if (newFromDate !== fromDate) {
            setFromDate(newFromDate);

            somethingChanged = true;
        }

        if (newToDate !== toDate) {
            setToDate(newToDate);

            somethingChanged = true;
        }

        if (!isEqual(newItems, selectedTypes)) {
            setSelectedTypes(newItems);

            somethingChanged = true;
        }

        if (somethingChanged) {
            setFilterChangeIterationNum((value) => {
                return value + 1;
            });
        }
    }, [fromDate, toDate, selectedTypes]);

    const fetchPayments = useCallback(() => {
        if (!stToken) {
            return;
        }

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        stApiClient.getPaymentsByStudentId(
            stToken,
            studentPageContext.studentGeneralInfoDto.id,
            selectedTypes,
            (fromDate) ? dateHelperService.formatAsSQLDate(fromDate) : null,
            (toDate) ? dateHelperService.formatAsSQLDate(toDate) : null,
            newAbortController
        )
            .then((data) => {
                setItems(data.result.items.map(item => {
                    return {
                        paymentId: item.id,
                        summ: parseFloat(item.summ),
                        datePay: dateHelperService.dateFromString(item.datePay),
                        payType: item.payType,
                        comment: item.comment ?? '',
                        commentAsString: item.comment ?? ''
                    }
                }));

                setNoPermission(false);
                setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                if (err instanceof PermissionDeniedException) {
                    setNoPermission(true);
                    setPageLoadingState(PageLoadingStateEnum.SUCCESS);

                    return;
                }

                if (!(err instanceof NoConnection)) {
                    logger.error(
                        LoggerSectionsEnum.STONLINE_STUDENTS_API,
                        `Error on load payments list for student ${studentPageContext.studentGeneralInfoDto.id}`,
                        err
                    );
                }

                setPageLoadingState(PageLoadingStateEnum.ERROR);
            });
    }, [stToken, abortController, studentPageContext.studentGeneralInfoDto.id, selectedTypes, fromDate, dateHelperService, toDate]);

    const onPaymentUpdate = useCallback(() => {
        fetchPayments();
        studentPageContext.reloadBaseInfo();
    }, [fetchPayments, studentPageContext]);

    const onClickItem = useCallback((paymentId: number) => {
        const paymentItem = items.find((item) => {
            return item.paymentId === paymentId;
        });

        if (paymentItem === undefined) {
            return;
        }

        setPayEditModalProps({
            itemId: paymentItem.paymentId,
            payType: paymentItem.payType as unknown as PayTypeEnum,
            subjectId: studentPageContext.studentGeneralInfoDto.id,
            subjectName: studentPageContext.studentGeneralInfoDto.longName,
            paymentDate: paymentItem.datePay,
            sum: paymentItem.summ,
            comment: paymentItem.commentAsString,
            onUpdate: onPaymentUpdate
        });

        payEditModalRef.current?.open();
    }, [items, onPaymentUpdate, studentPageContext.studentGeneralInfoDto.id, studentPageContext.studentGeneralInfoDto.longName]);

    useEffect(() => {
        fetchPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChangeIterationNumDebounced]);

    useEffect(() => {
        return () => {
            if (abortController !== null) {
                abortController.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const table = useMemo<JSX.Element>(() => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchPayments}/>;
            }
        }

        if (items.length === 0) {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Не найдено операций</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Попробуйте скорректировать фильтры</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        }

        return <>
            <PaymentsTable items={items} onItemClick={onClickItem}/>
            <PayEditModal
                ref={payEditModalRef}
                {...payEditModalProps}
            />
        </>
    }, [fetchPayments, items, onClickItem, pageLoadingState, payEditModalProps]);


    if (noPermission) {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle>
                    <Trans>Нет доступа к списку платежей</Trans>
                </NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>Попросите руководителя предоставить доступ к списку истории платежей учеников</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>
    }

    return <div>
        <Filters
            fromDate={fromDate}
            toDate={toDate}
            selectedTypes={selectedTypes}
            onChange={onFiltersChange}
        />
        {table}
        <ButtonWrapper>
            <PayEditModal
                itemId={null}
                payType={PayTypeEnum.STUDENT_MANUAL_REFILL}
                subjectId={studentPageContext.studentGeneralInfoDto.id}
                subjectName={studentPageContext.studentGeneralInfoDto.longName}
                paymentDate={new Date()}
                sum={0}
                comment={''}
                onUpdate={onPaymentUpdate}
                trigger={
                    <AddButton btnStyle={BtnStyleEnum.Primary}><Trans>Добавить операцию</Trans></AddButton>
                }
            />

        </ButtonWrapper>
    </div>
}
