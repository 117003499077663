import * as React from "react";
import {useEffect, useState} from "react";
import styled from 'styled-components';
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {Link} from "../../../components/Ui/Elements/Link";
import {i18n} from '@lingui/core'
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {OutletContext} from "../index";
import {RoutesList} from "../../../RoutesList";
import {AnimatedContainer} from "./AnimatedContainer";
import {container} from 'tsyringe';
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {AccessDeniedException} from "../../../../components/HttpApiClient/Exception/AccessDeniedException";
import {RequestValidationError} from "../../../../components/HttpApiClient/Exception/RequestValidationError";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {NotFoundException} from "../../../../components/HttpApiClient/Exception/NotFoundException";

const Wrapper = styled.div`

`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ForgotPasswordLink = styled.div`
  margin-bottom: 73px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 0;
  }
`;

export enum SituationCodeEnum {
    PREPARING = 0,
    WAIT_FOR_RESPONSE = 1,
    SUCCESS = 2,
    INVALID_AUTH_TOKEN = 3,
    UNKNOWN_ERROR = 4
}

export interface RestoreViaTokenFormProps {
}

export const RestoreViaTokenForm: React.FC<RestoreViaTokenFormProps> = (props: RestoreViaTokenFormProps) => {
    const [situationCode, setSituationCode] = useState<SituationCodeEnum>(SituationCodeEnum.PREPARING);
    const [authViaTokenToken, setAuthViaTokenToken] = useState<string | null>(null);

    const outletContext = (useOutletContext() as OutletContext);
    const navigate = useNavigate();
    const params = useParams<{ token: string }>();

    useEffect(() => {
        if ((situationCode === SituationCodeEnum.PREPARING) || (situationCode === SituationCodeEnum.WAIT_FOR_RESPONSE)) {
            outletContext.setLoader(true, i18n._(t`Пожалуйста, подождите...`));
        } else {
            outletContext.setLoader(false);
        }
    }, [situationCode, outletContext]);

    useEffect(() => {
        const method = async (): Promise<void> => {
            // Выполняем запрос на восстановление пароля по токену восстановления пароля
            const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            try {
                if (!params.token) {
                    throw new Error("Empty auth token");
                }

                setSituationCode(SituationCodeEnum.WAIT_FOR_RESPONSE);
                const result = await httpApiClient.restorePasswordViaToken(params.token);

                setAuthViaTokenToken(result.data.token);
                setSituationCode(SituationCodeEnum.SUCCESS);
            } catch (e) {
                if ((e instanceof AccessDeniedException) || (e instanceof NotFoundException)) {
                    setSituationCode(SituationCodeEnum.UNKNOWN_ERROR);

                    return;
                }

                if (e instanceof RequestValidationError) {
                    setSituationCode(SituationCodeEnum.INVALID_AUTH_TOKEN);

                    return;
                }

                setSituationCode(SituationCodeEnum.UNKNOWN_ERROR);
                logger.error(LoggerSectionsEnum.UNKNOWN_API_RESPONSE_ERROR, e);

                return;
            }
        }

        if (situationCode === SituationCodeEnum.PREPARING) {
            method();
        }

    }, [situationCode, params.token]);

    const instantLogin = () => {
        if (authViaTokenToken !== null) {
            navigate(RoutesList.LOGIN_VIA_TOKEN.replace(':token', authViaTokenToken));
        }
    }

    const content = () => {
        if (situationCode !== SituationCodeEnum.SUCCESS) {
            return (
                <Wrapper>
                    <InputsWrapper>
                        <h2 style={{marginBottom: "1.5em"}}><Trans>Ошибка восстановления пароля</Trans></h2>
                        <p>
                            <Trans>Попробуйте повторить попытку восстановления пароля с самого начала.</Trans>
                        </p>
                        <p style={{fontSize: "11px", color: "gray"}}><Trans>Код ситуации: {situationCode}</Trans></p>
                    </InputsWrapper>
                    <ButtonsWrapper>
                        <ButtonItem>
                            <Button
                                btnStyle={BtnStyleEnum.Primary}
                                onClick={() => navigate(RoutesList.RESTORE_FORM)}>
                                <Trans>Повторить попытку</Trans>
                            </Button>
                        </ButtonItem>
                    </ButtonsWrapper>
                    <ForgotPasswordLink>
                        <Link onClick={() => navigate(RoutesList.LOGIN_FORM)}><Trans>Войти с паролем</Trans></Link>
                    </ForgotPasswordLink>
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <InputsWrapper>
                    <h2 style={{marginBottom: "1.5em"}}><Trans>Создан новый пароль</Trans></h2>
                    <p>
                        <Trans>Вам на почту отправлено сообщение с новыми данными для входа.</Trans>
                    </p>
                </InputsWrapper>
                <ButtonsWrapper>
                    <ButtonItem>
                        <Button
                            btnStyle={BtnStyleEnum.Primary}
                            onClick={instantLogin}>
                            <Trans>Быстрый вход</Trans>
                        </Button>
                    </ButtonItem>
                </ButtonsWrapper>
                <ForgotPasswordLink>
                    <Link onClick={() => navigate(RoutesList.LOGIN_FORM)}><Trans>Обычный вход</Trans></Link>
                </ForgotPasswordLink>
            </Wrapper>
        );
    }

    return (
        <AnimatedContainer>
            {content()}
        </AnimatedContainer>
    );
}
