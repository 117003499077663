import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested} from "class-validator";
import {Type} from "class-transformer";
import {DtoLastCall} from "./DtoLastCall";
import {DtoTeacherInfo} from "./DtoTeacherInfo";

/**
 * DTO результата создания договора на обучение ученика
 */
export class DtoSchoolsSummaryInfo {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    name: number;

    @IsBoolean()
    isTutor: boolean;

    @IsNotEmpty()
    @IsString()
    timezoneName: string;

    @IsNotEmpty()
    @IsString()
    dateCreate: string;

    @IsBoolean()
    initialCustomizer: boolean;

    @IsNumber()
    studentsCount: number;

    @IsNumber()
    studentsDraft: number;

    @IsNumber()
    agreementsCount: number;

    @IsNumber()
    agreementsDraft: number;

    @IsNumber()
    lessonsCount: number;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoLastCall)
    lastTeacherActivity: null | DtoLastCall;

    @IsOptional()
    @ValidateNested({each: true})
    @Type(() => DtoTeacherInfo)
    teachers: DtoTeacherInfo[];
}
