import styled from "styled-components";
import React from "react";
import {useSelector} from "react-redux";
import {selectedSchool} from "../../../../../../store/user/selector";
import {Index} from "./FourSkills/TgBanner";

const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;
 
  &.has-content {
    margin-top: 24px;
  }
`;

export const BannersBlock: React.FC = () => {
    const schoolAccountDto = useSelector(selectedSchool);

    if (!schoolAccountDto) {
        return null;
    }

    switch (schoolAccountDto.id) {
        // FS
        case '8a6fbfa5-0d1e-402d-b53b-de62b6db0a0b': {
            return <WrapperGrid className='has-content'><Index/></WrapperGrid>;
        }
    }

    return null;
}
