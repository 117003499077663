import {Progress as AntProgress} from 'antd';
import styled from "styled-components";
import React from "react";
import classNames from "classnames";

export const ProgressStyled = styled(AntProgress)`

  &.small-stroke {
    circle, .ant-progress-circle-path, .ant-progress-circle-trail {
      stroke-width: 2px;
    }
  }

  .ant-progress-circle {
    transform: scale(-1, 1);
  }

  .ant-progress-circle-path {
    //transform: initial;
  }

  .ant-progress-text {
    color: ${({theme}) => theme.colors.textPrimary};
    font-size: 18px;

    font-feature-settings: 'pnum' on, 'lnum' on;
  }
`;


interface CircleProgressProps {
    showPercent: boolean;
    color: string;
    width: number;
    value: number;
}

export const CircleProgress: React.FC<CircleProgressProps> = (props) => {
    return <ProgressStyled type={"circle"}
                           showInfo={props.showPercent}
                           strokeColor={props.color}
                           width={props.width}
                           className={classNames(props.width > 40 && "small-stroke")}
                           status={"normal"}
                           success={{percent: -1}}
                           format={percent => `${percent} %`}
                           percent={props.value}
    />
}