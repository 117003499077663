import * as React from "react";
import styled from 'styled-components';
import {ReactComponent as MirWhite} from "../Ui/Svg/MirWhite.svg";
import {ReactComponent as VisaWhite} from "../Ui/Svg/VisaWhite.svg";
import {ReactComponent as MastercardWhite} from "../Ui/Svg/MastercardWhite.svg";
import {ReactComponent as MirGray} from "../Ui/Svg/MirGray.svg";
import {ReactComponent as VisaGray} from "../Ui/Svg/VisaGray.svg";
import {ReactComponent as MastercardGray} from "../Ui/Svg/MastercardGray.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 162px;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`

`;

export enum IconColor {
    WHITE,
    GRAY
}

interface CreditCardLogosProps {
    iconColor: IconColor
}

export const CreditCardLogos: React.FC<CreditCardLogosProps> = (props) => {
    const mirIcon = () => {
        switch (props.iconColor) {
            case IconColor.WHITE: {
                return <MirWhite/>;
            }

            case IconColor.GRAY: {
                return <MirGray/>;
            }
        }
    }

    const visaIcon = () => {
        switch (props.iconColor) {
            case IconColor.WHITE: {
                return <VisaWhite/>;
            }

            case IconColor.GRAY: {
                return <VisaGray/>;
            }
        }
    }

    const mastercardIcon = () => {
        switch (props.iconColor) {
            case IconColor.WHITE: {
                return <MastercardWhite/>;
            }

            case IconColor.GRAY: {
                return <MastercardGray/>;
            }
        }
    }

    return (
        <Wrapper>
            <LogoWrapper>
                {mirIcon()}
            </LogoWrapper>
            <LogoWrapper>
                {visaIcon()}
            </LogoWrapper>
            <LogoWrapper>
                {mastercardIcon()}
            </LogoWrapper>
        </Wrapper>
    );
}