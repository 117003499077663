import React, {useMemo} from "react";
import {LargeCoverProps} from "./Types";
import styled from "styled-components";
import {ImageCover} from "../Common/ImageCover";

const Wrapper = styled.div`
    border-radius: 20px;
    padding: 22px;
    aspect-ratio: 3/4;
    max-width: 344px;
`;

const ImageWrapper = styled.div`
    aspect-ratio: 1/1;
    width: 100%;
    margin-bottom: 22px;
`;

const Title = styled.h5`
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: ${({theme}) => theme.colors.textPrimary};
`;

const Description = styled.p`
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.textSecondary};
`;

export const LargeItem: React.FC<LargeCoverProps> = (
    {
        baseData
    }
) => {
    const backgroundColor = useMemo(() => {
        return `${baseData.accentColor}1a`;
    }, [baseData.accentColor]);

    return <Wrapper
        style={{backgroundColor: backgroundColor}}
    >
        <ImageWrapper>
            <ImageCover
                maskType={baseData.coverMaskId}
                imageFileId={baseData.coverFileId}
            />
        </ImageWrapper>
        <Title>{baseData.name}</Title>
        <Description>{baseData.shortDescription}</Description>
    </Wrapper>
}

LargeItem.displayName = 'SelfStudyTrackCoverLarge';