import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../../Ui/Elements/Modal";
import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Form, FormItem, useForm} from '../../../Ui/Elements/Form';
import {BtnStyleEnum, Button} from "../../../Ui/Elements/Button";
import {Input} from "../../../Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {InputLabel, ModalSubTitle, ModalTitle} from "../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../Ui/Elements/ConfirmDialog";
import {TextArea} from "../../../Ui/Elements/TextArea";
import {DtoTmLesson} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {RequestValidationError} from "../../../../../components/HttpApiClient/Exception/RequestValidationError";
import {ImageCropperUploader, ImageCropperUploaderRefMethods} from "../../../Ui/Elements/ImageCropperUploader";
import {UserFileFormatsEnum, UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import {UserImage} from "../../../Ui/Elements/UserImage";
import styled from "styled-components";

const CoverPreviewWrapper = styled.div`
    width: 150px;
    height: 150px;

    display: block;
    margin: 10px auto;

    @media (${({theme}) => theme.media.medium}) {
        margin: 10px 0;
    }

    border: 1px ${({theme}) => theme.colors.accentDivider} solid;
`;

const CoverButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (${({theme}) => theme.media.medium}) {
        flex-direction: row;
        justify-content: flex-start;
        max-width: 500px;
    }
`;

export enum ModalResultType {
    CREATED,
    UPDATED,
    DELETED
}

interface TmLessonModalProps extends ModalChildProps {
    sectionId: string;
    lessonItem: DtoTmLesson | null;
    result: (action: ModalResultType, item: DtoTmLesson | null) => void;
}

export const TmLessonModal = forwardRef<ReactjsPopupActions, TmLessonModalProps>((props, ref) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const cropperRef = useRef<ImageCropperUploaderRefMethods>(null);

    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);
    const [backgroundFileId, setBackgroundFileId] = useState<string | null>(props.lessonItem?.coverFileId ?? null);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        close: () => {
            modalRef.current?.close();
        },
        open: () => {
            modalRef.current?.open();
        },
        toggle: () => {
            modalRef.current?.toggle();
        }
    }));

    useEffect(() => {
        setBackgroundFileId((props.lessonItem) ? props.lessonItem.coverFileId : null);
    }, [props.lessonItem]);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        const filteredDescriptionForTeacher = (
            (typeof values.descriptionForTeacher === "string") && (values.descriptionForTeacher.trim() !== '')
        ) ? values.descriptionForTeacher : null;

        const filteredDescriptionForStudent = (
            (typeof values.descriptionForStudent === "string") && (values.descriptionForStudent.trim() !== '')
        ) ? values.descriptionForStudent : null;

        setCloseAllowed(false);
        setSaveInProcess(true);

        const promise = (props.lessonItem) ?
            httpApiClient.tmUpdateLesson(
                sessionToken,
                props.lessonItem.id,
                values.name,
                filteredDescriptionForStudent,
                filteredDescriptionForTeacher,
                backgroundFileId
            )
            : httpApiClient.tmCreateLesson(
                sessionToken,
                props.sectionId,
                values.name,
                filteredDescriptionForStudent,
                filteredDescriptionForTeacher,
                backgroundFileId
            );

        promise
            .then((data) => {
                props.result(
                    (props.lessonItem) ? ModalResultType.UPDATED : ModalResultType.CREATED,
                    data.data
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (props.lessonItem) {
                    logger.error(LoggerSectionsEnum.TM_LESSONS_API, "Error on update lesson: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_LESSONS_API, "Error on create lesson: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef) || (!props.lessonItem)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteLesson(
                sessionToken,
                props.lessonItem.id
            )
        } catch (error) {
            if (error instanceof RequestValidationError) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Урок содержит слайды`,
                    t`Попробуйте сначала удалить их`
                );

                return;
            }

            logger.error(LoggerSectionsEnum.TM_LESSONS_API, "Error on delete lesson: ", error);

            throw error;
        }

        props.result(
            ModalResultType.DELETED,
            null
        );

        modalRef?.current?.close();
    }

    const changeCoverImageBtnOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        cropperRef.current?.selectFile();
    };

    const footerContent = (controls: ModalControlParams) => {
        if (props.lessonItem === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Создать урок</Trans>
                </Button>
            </div>;
        }

        // Если сейчас мы редактируем урок
        return <>
            <ConfirmDialog trigger={
                <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                    <Trans>Удалить</Trans>
                </Button>
            }
                           okText={t`Удалить`}
                           cancelText={t`Отмена`}
                           title={t`Удалить урок?`}
                           text={t`Вы уверены, что нужно удалить урок ${props.lessonItem.name}?`}
                           errorText={t`Не удалось удалить информацию`}
                           okMethod={onDelete}/>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>;
    }

    return <Modal innerRef={modalRef}
                  trigger={props.trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      setCloseAllowed(true);
                      setSaveInProcess(false);

                      form.resetFields();
                  }}
                  footer={footerContent}
                  footerMode={(props.lessonItem) ? FooterMode.SPACE_BETWEEN : FooterMode.DEFAULT}
                  children={
                      (controls) => {
                          return <div>
                              <ModalTitle>
                                  {
                                      (props.lessonItem === null)
                                          ? <Trans>Создать урок</Trans>
                                          : <Trans>Редактировать урок</Trans>
                                  }
                              </ModalTitle>
                              <ModalSubTitle> </ModalSubTitle>
                              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={`name`}
                                      initialValue={props.lessonItem?.name}
                                      label={t`Название`}
                                      rules={[
                                          {
                                              required: true,
                                              message: t`Необходимо указать название урока`,
                                          },
                                          {
                                              type: "string",
                                              min: 3,
                                              message: t`Название должно быть не короче трёх символов`
                                          },
                                          {
                                              type: "string",
                                              max: 255,
                                              message: t`Название должно быть не длиннее 255 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={'Название урока'}/>
                                  </FormItem>
                                  <FormItem
                                      name={`descriptionForTeacher`}
                                      initialValue={props.lessonItem?.descriptionForTeacher}
                                      label={t`Внутреннее описание (не будет видно ученикам)`}
                                      rules={[
                                          {
                                              type: "string",
                                              max: 1000,
                                              message: t`Описание должно быть не длиннее 1000 символов`
                                          }
                                      ]}
                                  >
                                      <TextArea
                                          status={""}
                                          size="large"/>
                                  </FormItem>
                                  <FormItem
                                      name={`descriptionForStudent`}
                                      initialValue={props.lessonItem?.descriptionForStudent}
                                      label={t`Описание для учеников`}
                                      rules={[
                                          {
                                              type: "string",
                                              max: 1000,
                                              message: t`Описание должно быть не длиннее 1000 символов`
                                          }
                                      ]}
                                  >
                                      <TextArea
                                          status={""}
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                      />
                                  </FormItem>

                                  <InputLabel>
                                      <Trans>Обложка урока (для self-study треков)</Trans>
                                  </InputLabel>

                                  <FormItem>
                                      <ImageCropperUploader ref={cropperRef}
                                                            fileType={UserFileTypeEnum.TM_LESSON_COVER}
                                                            handleFile={(fileId) => {
                                                                setBackgroundFileId(fileId);
                                                                return Promise.resolve();
                                                            }}
                                                            config={{
                                                                width: 300,
                                                                height: 300,
                                                                shape: "round"
                                                            }}
                                      />

                                      {
                                          (backgroundFileId !== null)
                                          && <CoverPreviewWrapper>
                                              <UserImage
                                                  fileId={backgroundFileId}
                                                  fileFormat={UserFileFormatsEnum.TM_LESSON_COVER_ORIGINAL}
                                              />
                                          </CoverPreviewWrapper>
                                      }

                                      {
                                          (backgroundFileId === null)
                                              ? <CoverButtonsWrapper>
                                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                                          onClick={changeCoverImageBtnOnClick}>
                                                      <Trans>Выбрать изображение</Trans>
                                                  </Button>
                                              </CoverButtonsWrapper>
                                              : <CoverButtonsWrapper>
                                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                                          onClick={changeCoverImageBtnOnClick}>
                                                      <Trans>Изменить картинку</Trans>
                                                  </Button>
                                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                                          onClick={() => setBackgroundFileId(null)}>
                                                      <Trans>Удалить картинку</Trans>
                                                  </Button>
                                              </CoverButtonsWrapper>
                                      }
                                  </FormItem>
                              </Form>
                          </div>
                      }
                  }
    />
});

TmLessonModal.displayName = 'TmLessonModal';