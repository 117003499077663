import {IsNotEmpty, IsOptional, IsString, MaxLength} from 'class-validator';
import {IBaseApiDto} from "../../../IBaseApiDto";

/**
 * DTO
 */
export class DtoScreenOrientationChange implements IBaseApiDto {
    @IsNotEmpty()
    @IsString()
    lessonId: string;

    @IsOptional()
    @IsString()
    @MaxLength(100)
    value: string | null;
}
