import * as React from "react";
import {useCallback, useEffect, useRef, useState} from "react";
import {Popup as ReactjsPopup} from "reactjs-popup";
import {PopupActions as ReactjsPopupActions, PopupProps as ReactjsPopupProps} from "reactjs-popup/dist/types";
import styled from "styled-components";
import {ReactComponent as CloseCrossSvg} from "../Svg/CloseCross.svg";
import {useDispatch, useSelector} from "react-redux";
import * as LayoutActions from "../../../../store/layout/actions";
import {bodyScrollAvailableSelector} from "../../../../store/layout/selector";

const StyledModal = styled(ReactjsPopup)`
  &-overlay {
    z-index: ${({theme}) => theme.zIndices.modal} !important;
    background-color: ${({theme}) => theme.colors.modalBackdrop};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;

    @media (${({theme}) => theme.media.small}) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @media (${({theme}) => theme.media.medium}) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &-content {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};

    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media (${({theme}) => theme.media.small}) {
      position: relative !important;
      width: 92%;
      border-radius: 20px;

      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }

    @media (${({theme}) => theme.media.large}) {
      width: 90%;
    }

    @media (${({theme}) => theme.media.extraLarge}) {
      width: 1260px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 16px 20px;

  @media (${({theme}) => theme.media.small}) {
    padding: 24px 80px 34px 80px;
  }

  @media (${({theme}) => theme.media.large}) {
    padding: 40px 80px 34px 80px;
  }
`;

export enum FooterMode {
    DEFAULT,
    SPACE_BETWEEN
}

export interface FooterWrapperProps {
    footerMode?: FooterMode
}

const FooterWrapper = styled.div<FooterWrapperProps>`
  padding: 16px 20px;

  ${(props) => {
    switch (props.footerMode) {
      case FooterMode.SPACE_BETWEEN: {
        return "display: flex; width: 100%; justify-content: space-between;";
      }

      default: {
        return "";
      }
    }
  }};

  @media (${({theme}) => theme.media.small}) {
    padding: 14px 80px 34px 80px;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalHeader = styled.div`
  min-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CloseCrossButton = styled.button`
  border: none;
  outline: none !important;
  opacity: 0.3;
  cursor: pointer;
  color: ${({theme}) => theme.colors.textPrimary};
  background: none;

  &:hover {
    opacity: 1;
  }
`;

const CloseCrossIcon = styled(CloseCrossSvg)`
  color: inherit;
  padding: 2px;
`;

const ModalContent = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

export interface ModalChildProps {
    trigger?: React.ReactElement;
    onOpen?: (() => void);
}

interface ProviderWrapperProps {
    children: React.ReactNode,
    realProvider?: { component: React.FC<any>, props?: Object }
}

export const ProviderWrapper: React.FC<ProviderWrapperProps> = (props) => {
    if (props.realProvider) {
        return <props.realProvider.component {...props}>
            {props.children}
        </props.realProvider.component>;
    }

    return <>{props.children}</>;
}

export interface ModalControlParams {
    closeModal: () => void
}

type ModalProps =
    Omit<ReactjsPopupProps, "closeOnDocumentClick" | "closeOnEscape">
    & React.RefAttributes<ReactjsPopupActions>
    & {
    onClose?: () => void,
    footer?: (modalControls: ModalControlParams) => React.ReactNode,
    wrapperProvider?: { component: React.FC<any>, props?: Object },
    children: (modalControls: ModalControlParams) => React.ReactNode,
    closeAllowed?: boolean,
    innerRef?: React.Ref<any>,
    wideHeaderBlock?: JSX.Element;
    isSecondaryModal?: boolean;
    clearFocusAfterOpen?: boolean; // При открытии окна фокус будет переведён на кнопку закрытия
} & FooterWrapperProps

export const Modal = ({
                          onClose,
                          onOpen,
                          isSecondaryModal,
                          wrapperProvider,
                          innerRef,
                          clearFocusAfterOpen,
                          ...props
                      }: ModalProps) => {
    const [footerEnabled, setFooterEnabled] = useState<boolean>(false);
    const dispatch = useDispatch();
    const scrollIsAvailable = useSelector(bodyScrollAvailableSelector);

    const modalIsOpened = useRef(false);
    const bodyScrollAvailableBeforeModal = useRef(scrollIsAvailable);
    const closeBtn = useRef<HTMLButtonElement>(null);

    // useEffect(() => {
    //
    //
    //     return () => {
    //         if (scrollIsAvailableBeforeModal) {
    //             dispatch(LayoutActions.setBodyScrollAvailable(true));
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const modalOnClose = useCallback(() => {
        modalIsOpened.current = false;
        setFooterEnabled(false);

        if (bodyScrollAvailableBeforeModal.current) {
            dispatch(LayoutActions.setBodyScrollAvailable(true));
        }

        if (onClose !== undefined) {
            onClose();
        }
    }, [bodyScrollAvailableBeforeModal, dispatch, onClose]);

    const modalOnOpen = useCallback(() => {
        modalIsOpened.current = true;
        setFooterEnabled(true);
        dispatch(LayoutActions.setBodyScrollAvailable(false));
        bodyScrollAvailableBeforeModal.current = scrollIsAvailable;

        if (clearFocusAfterOpen) {
            if (closeBtn.current) {
                closeBtn.current.focus();
            }
        }

        if (onOpen !== undefined) {
            onOpen();
        }
    }, [clearFocusAfterOpen, dispatch, onOpen, scrollIsAvailable]);

    useEffect(() => {
        return () => {
            if (modalIsOpened.current) {
                if (bodyScrollAvailableBeforeModal.current) {
                    dispatch(LayoutActions.setBodyScrollAvailable(true));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledModal
            {...props}
            lockScroll={false}
            onClose={modalOnClose}
            onOpen={modalOnOpen}
            closeOnDocumentClick={(props.closeAllowed === undefined) ? true : props.closeAllowed}
            closeOnEscape={(props.closeAllowed === undefined) ? true : props.closeAllowed}
            ref={innerRef} modal nested>
            {
                (closeModal: () => void) => (
                    <ModalContentWrapper>
                        <ProviderWrapper realProvider={wrapperProvider}>
                            <ModalHeader>
                                <CloseCrossButton ref={closeBtn} className={'modal-close-cross'}>
                                    <CloseCrossIcon
                                        onClick={() => {
                                            if (props.closeAllowed) {
                                                closeModal();
                                            }
                                        }}
                                    />
                                </CloseCrossButton>
                            </ModalHeader>
                            {
                                props.wideHeaderBlock
                            }
                            <ModalContent>
                                <Wrapper>
                                    {props.children({closeModal})}
                                </Wrapper>
                            </ModalContent>
                            {props.footer && footerEnabled &&
                              <ModalFooter>
                                <FooterWrapper footerMode={props.footerMode}>
                                    {props.footer({closeModal})}
                                </FooterWrapper>
                              </ModalFooter>
                            }
                        </ProviderWrapper>
                    </ModalContentWrapper>
                )
            }
        </StyledModal>
    )
}
