import * as React from "react";
import styled from "styled-components";
import {OrganizationItemStyled} from "./OrganizationItem";
import {Trans} from "@lingui/macro";

const LoadMoreOrganizationsItemStyled = styled(OrganizationItemStyled)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 15px 5px 10px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface LoadMoreOrganizationsItemProps {
    onClick: () => void;
}

export const LoadMoreOrganizationsItem: React.FC<LoadMoreOrganizationsItemProps> = (props) => {
    return (
        <LoadMoreOrganizationsItemStyled active={false} onClick={props.onClick}>
            {/*<PlusIcon/>*/}
            <PlusText><Trans>Показать ещё</Trans></PlusText>
        </LoadMoreOrganizationsItemStyled>
    );
}