import {putResolve, takeEvery} from 'redux-saga/effects';
import {StreamEventActionTypes} from "../../store/streamEvent/type";
import {setAppIsReadyToHandleItemsValue} from "../../store/streamEvent/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";

/**
 * Сага отвечает за остановку обработки сообщений потока событий
 */
export function* watchStopHandleEvent() {
    yield takeEvery(
        StreamEventActionTypes.STOP_HANDLING_EVENTS,
        stopHandleEvent
    );
}

function* stopHandleEvent() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, 'Streaming events processing stopped.');

    yield putResolve(setAppIsReadyToHandleItemsValue(false));
}
