import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {IButtonBaseProps} from "./IButtonBaseProps";
import {ControlPanelItem, ControlPanelItemIcon} from "../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as Expand} from "../../../Ui/Elements/ControlPanel/icons/Expand.svg";
import {ReactComponent as Collapse} from "../../../Ui/Elements/ControlPanel/icons/Collapse.svg";
import classNames from "classnames";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {ConferenceModeEnum} from "../../Types";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../../actions/common/RoomCommonActionsContext";

export const FullScreenBtn: React.FC<IButtonBaseProps> = () => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const commonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);

    const btnDisabled = useMemo<boolean>(() => {
        return commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED
    }, [commonContext.lessonConferenceState]);

    const onClick = useCallback(() => {
        if (commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED) {
            return;
        }

        if (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN) {
            commonActionsContext.setCurrentConferenceMode(ConferenceModeEnum.NORMAL);
        } else {
            commonActionsContext.setCurrentConferenceMode(ConferenceModeEnum.FULL_SCREEN);
        }
    }, [commonActionsContext, commonContext.currentMode, commonContext.lessonConferenceState]);

    useEffect(() => {
        if (
            (commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED)
            && (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN)
        ) {
            commonActionsContext.setCurrentConferenceMode(ConferenceModeEnum.NORMAL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.currentMode, commonContext.lessonConferenceState]);

    return <ControlPanelItem
        className={classNames('panel-item', btnDisabled && 'disabled')}
        onClick={onClick}
    >
        <ControlPanelItemIcon className={classNames('no-margin')}>
            {
                (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN)
                    ? <Collapse/>
                    : <Expand/>
            }
        </ControlPanelItemIcon>
    </ControlPanelItem>
}
