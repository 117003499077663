import React from "react";
import {GallerySizeMode} from "./Types";

export interface IVideoGalleryContext {
    setSelectedMemberId: (memberId: string | null) => void;
    setMemberOffset: React.Dispatch<React.SetStateAction<number>>;
    selectedMemberId: string | null;
    memberOffset: number;
    totalMembersCount: number;
    membersCountForScroll: number;
    gallerySize: number;
    gallerySizeMode: GallerySizeMode;
}

export const VideoGalleryContext = React.createContext<IVideoGalleryContext>({
    setSelectedMemberId: () => {},
    setMemberOffset: () => {},
    selectedMemberId: null,
    memberOffset: 0,
    totalMembersCount: 0,
    membersCountForScroll: 0,
    gallerySize: 2,
    gallerySizeMode: GallerySizeMode.SMALL
});

export const VideoGalleryContextProvider = VideoGalleryContext.Provider;
