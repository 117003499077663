import React, {useContext} from "react";
import {ITmCatalogContext, TmCatalogContext} from "../TmCatalogContext";
import styled from "styled-components";
import {
    DtoTmOrganization
} from "../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {TmOrganizationSelector} from "../../Ui/Elements/Selectors/TmOrganizationSelector";
import {ReactComponent as PencilIcon} from "../../Ui/Svg/Pencil.svg";
import {ReactComponent as PlusIcon} from "../../Ui/Svg/Plus.svg";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {ModalResultType, TmOrganizationModal} from "../modals/organization-modal";
import {InputLabel} from "../../../styles/global-elements";

const SimpleWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 40px;
  }
`;

const InputWrapper = styled.div`
  flex-grow: 1;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  margin: 0 5px;

  &:hover {
    opacity: 1;
  }
`;

interface OrganizationSectionProps {
    onChange: (organizationDto: DtoTmOrganization | null) => void;
    selectMode?: boolean; // Если true, редактирование запрещено
}

export const TmOrganizationSection: React.FC<OrganizationSectionProps> = (props) => {
    const catalogContext = useContext<ITmCatalogContext>(TmCatalogContext);

    const modalOnResult = (action: ModalResultType, data: DtoTmOrganization | null) => {
        props.onChange(data);
    }

    const content = () => {
        if (catalogContext.selectedOrganization === null) {
            return <SimpleWrapper>
                <TmOrganizationModal
                    organizationItem={null}
                    result={modalOnResult}
                    trigger={
                        <Button btnStyle={BtnStyleEnum.Primary}><Trans>Создать владельца материалов</Trans></Button>
                    }/>
            </SimpleWrapper>;
        }

        return <Wrapper>
            <InputWrapper>
                <InputLabel><Trans>Владелец материалов</Trans></InputLabel>
                <TmOrganizationSelector onChange={props.onChange} value={catalogContext.selectedOrganization}/>
            </InputWrapper>

            {
                (props.selectMode !== true) &&
                <TmOrganizationModal
                    organizationItem={catalogContext.selectedOrganization}
                    result={modalOnResult}
                    trigger={<ButtonWrapper>
                        <PencilIcon/>
                    </ButtonWrapper>}/>
            }

            {
                (props.selectMode !== true) &&
                <TmOrganizationModal
                    organizationItem={null}
                    result={modalOnResult}
                    trigger={<ButtonWrapper>
                        <PlusIcon/>
                    </ButtonWrapper>}/>
            }
        </Wrapper>;
    }

    return content();
}