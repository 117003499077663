import * as React from "react";
import {TimePicker as AntTimePicker} from "antd";
import {TimePickerProps} from "antd/lib/time-picker";
import styled from "styled-components";
import {RegularFontSize} from "../../../styles/global-elements";

export const TimePickerStyled = styled(AntTimePicker)`
  ${RegularFontSize};
  border: none;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 200px;
  cursor: pointer;

  &:focus, &:hover {
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-right-width: inherit;
    box-shadow: none;
  }

  &.ant-picker-focused {
    box-shadow: none;
  }

  .ant-picker-suffix {
    color: inherit;
  }

  .ant-picker-clear {
    color: inherit;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
  }

  input {
    color: inherit;
    cursor: pointer;
    font-size: inherit;
  }

  @media (${({theme}) => theme.media.small}) {
    input {
      font-size: inherit;
    }
  }
`;

export const TimePicker: React.FunctionComponent<TimePickerProps> = ({...props}) => {
    return <TimePickerStyled
        format={"HH:mm"}
        placement={"bottomLeft"}
        inputReadOnly
        allowClear={false} {...props} />;
}