import React, {ReactNode, useMemo} from "react";
import MaskSquare from './Masks/mask-square.svg';
import MaskCircle from './Masks/mask-circle.svg';
import MaskPolygon from './Masks/mask-polygon.svg';
import MaskStar from './Masks/mask-star.svg';
import MaskStar2 from './Masks/mask-star2.svg';
import {SelfStudyTrackCoverMaskTypeEnum} from "../../../../../../enums/SelfStudyTrackCoverMaskTypeEnum";
import styled from "styled-components";
import {UserImage} from "../../UserImage";
import {UserFileFormatsEnum} from "../../../../../../enums/UserFileEnums";

const SvgMask = styled.div`
    width: 100%;
    height: 100%;
    mask-repeat: no-repeat;
    mask-size: contain;

    &.square {
        mask-image: url(${MaskSquare});
    }

    &.circle {
        mask-image: url(${MaskCircle});
    }

    &.polygon {
        mask-image: url(${MaskPolygon});
    }

    &.star {
        mask-image: url(${MaskStar});
    }

    &.star2 {
        mask-image: url(${MaskStar2});
    }
`;

const ImagePlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
`;

interface ImageCoverProps {
    maskType: SelfStudyTrackCoverMaskTypeEnum;
    imageFileId?: string | null;
    fileFormat?: UserFileFormatsEnum;
    children?: ReactNode;
}

export const ImageCover: React.FC<ImageCoverProps> = (
    {
        maskType,
        imageFileId,
        fileFormat,
        children
    }
) => {

    const maskTypeClass = useMemo((): string => {
        switch (maskType) {
            case SelfStudyTrackCoverMaskTypeEnum.SQUARE: {
                return 'square';
            }
            case SelfStudyTrackCoverMaskTypeEnum.CIRCLE: {
                return 'circle';
            }
            case SelfStudyTrackCoverMaskTypeEnum.POLYGON: {
                return 'polygon';
            }
            case SelfStudyTrackCoverMaskTypeEnum.STAR: {
                return 'star';
            }
            case SelfStudyTrackCoverMaskTypeEnum.STAR2: {
                return 'star2';
            }
            default: {
                return '';
            }
        }
    }, [maskType]);

    const imageElement = useMemo(() => {
        if (children) {
            return children;
        }

        if (imageFileId) {
            return <UserImage fileId={imageFileId}
                              fileFormat={fileFormat ?? UserFileFormatsEnum.SELF_STUDY_TRACK_COVER_ORIGINAL}/>;
        }

        return <ImagePlaceholder/>;
    }, [children, fileFormat, imageFileId]);

    return <SvgMask className={maskTypeClass}>
        {imageElement}
    </SvgMask>
}