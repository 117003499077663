import {IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator';

/**
 * DTO ответа на запрос истории взаиморасчётов ученика со стороны ученика
 */
export class DtoPaymentsHistoryItem {
    @IsOptional()
    summ: string;

    @IsNotEmpty()
    @IsString()
    datePay: string;

    @IsOptional()
    @IsString()
    comment: string | null;

    @IsNotEmpty()
    @IsNumber()
    payType: number;

    @IsOptional()
    @IsString()
    docPrefix: string;

    @IsOptional()
    @IsNumber()
    docNumber: number;

    @IsOptional()
    @IsString()
    docDate: string;
}
