import {instanceCachingFactory} from 'tsyringe';
import {IGroupScheduleReader} from "../views/components/GroupScheduleReader/IGroupScheduleReader";
import {GroupScheduleReader} from "../views/components/GroupScheduleReader/GroupScheduleReader";
import {IDateHelperService} from "../services/date-helper/IDateHelperService";
import {DiTokens} from "./DiTokens";

export default instanceCachingFactory<IGroupScheduleReader>((dependencyContainer) => {
    return new GroupScheduleReader(
        dependencyContainer.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE)
    );
});
