import {action} from "typesafe-actions";
import {LessonMaterialsActionTypes, LoadingStateEnum, SlideItemData} from "./type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {DtoSENeedSyncSlideList} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSyncSlideList";

export const loadLessonMaterialsList = (lessonId: string, homeworkMaterials: boolean, silentLoad: boolean, tryNum?: number) => {
    return action(LessonMaterialsActionTypes.LOAD_LESSON_MATERIALS_LIST, {
        lessonId, homeworkMaterials, silentLoad, tryNum: (tryNum) ?? 1
    });
}

export const setLoadingState = (loadingState: LoadingStateEnum) => {
    return action(LessonMaterialsActionTypes.SET_LOADING_STATE, loadingState);
}

export const setLessonId = (lessonId: string) => {
    return action(LessonMaterialsActionTypes.SET_LESSON_ID, lessonId);
}

export const setMaterialsType = (isHomework: boolean) => {
    return action(LessonMaterialsActionTypes.SET_MATERIALS_TYPE, isHomework);
}

export const setLessonMaterialsList = (materialsList: SlideItemData[]) => {
    return action(LessonMaterialsActionTypes.SET_LESSON_MATERIALS_LIST, materialsList);
}

export const resetLessonMaterialsState = () => {
    return action(LessonMaterialsActionTypes.RESET_LESSON_MATERIALS_STATE);
}

export const subscribeToSlideList = (id: string) => {
    return action(LessonMaterialsActionTypes.SUBSCRIBE_TO_SLIDE_LIST, id);
}

export const unsubscribeFromSlideList = (id: string) => {
    return action(LessonMaterialsActionTypes.UNSUBSCRIBE_FROM_SLIDE_LIST, id);
}

export const addSubscriptionId = (id: string) => {
    return action(LessonMaterialsActionTypes.ADD_SUBSCRIPTION_ID, id);
}

export const removeSubscriptionId = (id: string) => {
    return action(LessonMaterialsActionTypes.REMOVE_SUBSCRIPTION_ID, id);
}

export const clearSubscriptionsList = () => {
    return action(LessonMaterialsActionTypes.CLEAR_SUBSCRIPTIONS_LIST);
}

export const handleSeNeedSyncSlideList = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSENeedSyncSlideList>, string>) => {
    return action(LessonMaterialsActionTypes.HANDLE_SE_NEED_SYNC_SLIDE_LIST, data);
}
