import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PageLoadingStateEnum} from "../../../../../enums/PageLoadingStateEnum";
import {PageWrapper} from "../../../../components/PageWrapper";
import {useDispatch, useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import styled from "styled-components";
import {useNavigate, useParams} from "react-router-dom";
import {CommonContextProvider, ICommonContext} from "./CommonContext";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../components/HttpApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoSelfStudyTrackSlideItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSlideItem";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../../../../enums/SlidePlayerIdEnum";
import {ContentWrapper} from "./ContentWrapper";
import {loadSlideWorkData, resetAllWorkDataState} from "../../../../../store/slidesWorkData/actions";
import {slidesWorkDataStateSelector} from "../../../../../store/slidesWorkData/selector";
import {SlideWorkData, SlideWorkDataLoadingStateEnum} from "../../../../../store/slidesWorkData/type";
import {RightBlock} from "./RightBlock";
import {NextButton} from "./NextButton";
import {MobileTopLine} from "./MobileTopLine";
import {RoutesList} from "../../../../RoutesList";
import {setContentLayoutSettings} from "../../../../../store/layout/actions";
import {ThemeEnum} from "../../../../../services/theme/ThemeEnum";
import classNames from "classnames";
import {fetchNewFileInfo} from "../../../../../store/fileFormatsRepo/actions";
import {FileHelper} from "../../../../../helpers/FileHelper";
import {t} from "@lingui/macro";
import {SlideItemInteractivityActionsRunModeEnum} from "../../../../../enums/SlideItemInteractivityActionsRunModeEnum";
import {
    EditorItemDataParams,
    EditorItemInteractivityConfigWithParentItem
} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {
    InteractivityActionsRunner
} from "../../../../components/SlidePlayer/interactivity-actions/InteractivityActionsRunner";
import {EditorDataDtoMapper} from "../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {SlideItemsParamsBySlide} from "../../../../components/SlidePlayer/SlideItemParamsStore";
import {NAVIGATION_CUSTOM_CONTENT_AREA_ID} from "../../../../../Constants";
import {ReactComponent as PlaySettingsIcon} from "../../../../components/Ui/Svg/PlaySettings.svg";
import {createPortal} from "react-dom";
import {Popup, PopupItem} from "../../../../components/Ui/Elements/Popup";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";

const PlaySettingsWrapper = styled.div`
    padding: 0 17px;
    height: 40px;
`;

const PlaySettingsBtn = styled(PlaySettingsIcon)`
    opacity: 0.6;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }
`;

const CenterBlock = styled.div`
    @media (${({theme}) => theme.media.small}) {
        &.with-bg {
            padding: 20px;
            border-radius: 20px;
            backdrop-filter: blur(10px);
        }

        &.theme-dark {
            background-color: rgba(0, 0, 0, 0.5);
        }

        &.theme-light {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
`;

const RightBlockText = styled.div`
    margin-bottom: 32px;
`;

RightBlockText.displayName = 'RightBlockText'

export const Episode: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {episodeId} = useParams<{ episodeId: string }>();

    const leftNavigationCustomContentArea = useRef<HTMLElement | null>(null);

    const [slidePlayerId,] = useState<string>(() => RoutesHelper.replaceParams(
        SlidePlayerIdEnum.SELF_STUDY_TRACK_EPISODE,
        [
            {
                key: 'episodeId',
                value: episodeId ?? ""
            }
        ]
    ));

    const slideWorkDataState = useSelector(slidesWorkDataStateSelector);

    const logger = useRef(container.resolve<ILogger>(DiTokens.LOGGER));
    const httpApiClient = useRef(container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT));

    const [slideContentLoadingState, setSlideContentLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [slideList, setSlideList] = useState<DtoSelfStudyTrackSlideItem[]>([]);
    const [selectedTmSlideId, setSelectedTmSlideId] = useState<string | null>(null);
    const [noRightBlock, setNoRightBlock] = useState<boolean>(false);
    const [hasBackground, setHasBackground] = useState<boolean>(false);
    const [delayedInteractivityConfig, setDelayedInteractivityConfig] = useState<EditorItemInteractivityConfigWithParentItem | null>(null);

    const sessionToken = useSelector(sessionTokenSelector);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const currentThemeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as string;

    const large = useMediaQuery(`(${currentTheme.media.large})`);

    const fetchData = useCallback(async (afterReset?: boolean) => {
        if ((!sessionToken) || (!episodeId)) {
            return;
        }

        setSlideContentLoadingState(PageLoadingStateEnum.LOADING);

        // 1. Загружаем материалы
        try {
            const materialsResponse = await httpApiClient.current.selfStudyTrackEpisodeMaterials(
                sessionToken,
                episodeId
            );

            setSlideList(materialsResponse.data.list);

            if (!afterReset) {
                const firstItem = materialsResponse.data.list.find(item => item.orderPosition === 1);

                if (firstItem) {
                    setSelectedTmSlideId(firstItem.slideTmId);
                } else {
                    logger.current.error(LoggerSectionsEnum.SELF_STUDY_TRACK_API, `Not found first slide for self study episode ${episodeId}`);

                    setSlideContentLoadingState(PageLoadingStateEnum.ERROR);

                    return;
                }
            }

            // 2. Ставим на получение прямых путей всех файлов фонов и изображений первого слайда
            if (!afterReset) {
                if (materialsResponse.data.list.length > 0) {
                    FileHelper
                        .getFileIdsForPrefetchFromSlideContent(
                            [materialsResponse.data.list[0].content]
                        )
                        .forEach(fileInfo => dispatch(
                            fetchNewFileInfo(fileInfo.fileId, fileInfo.typeForPrefetch)
                        ));
                }
            }

            // 3. Загружаем результаты работы
            dispatch(loadSlideWorkData({
                playerId: slidePlayerId,
                lessonId: episodeId,
                userId: '',
                slideIds: materialsResponse.data.list.map(item => item.slideTmId) // Но загружается всё равно всегда весь эпизод пачкой.
            }));
        } catch (e) {
            setSlideContentLoadingState(PageLoadingStateEnum.ERROR);

            if (e instanceof NoConnection) {
                return;
            }

            logger.current.error(LoggerSectionsEnum.SELF_STUDY_TRACK_API, `Error on load self-study track materials:`, e);

            return
        }

        setSlideContentLoadingState(PageLoadingStateEnum.SUCCESS);

    }, [dispatch, episodeId, sessionToken, slidePlayerId]);

    const selectedSlideIndex = useMemo<number>(() => {
        const value = slideList.findIndex(item => item.slideTmId === selectedTmSlideId);

        return (value < 0) ? 0 : value;
    }, [selectedTmSlideId, slideList]);

    useEffect(() => {
        if (slideList[selectedSlideIndex] === undefined) {
            return;
        }

        if (slideList.length <= selectedSlideIndex + 1) {
            return;
        }

        // Ставим на получение прямых путей всех файлов фонов и изображений материалов следующего слайда
        FileHelper
            .getFileIdsForPrefetchFromSlideContent(
                [slideList[selectedSlideIndex + 1].content]
            )
            .forEach(fileInfo => dispatch(
                fetchNewFileInfo(fileInfo.fileId, fileInfo.typeForPrefetch)
            ));
    }, [dispatch, selectedSlideIndex, slideList]);

    useEffect(() => {
        leftNavigationCustomContentArea.current = document.getElementById(NAVIGATION_CUSTOM_CONTENT_AREA_ID);
    }, []);

    const currentSlideWorkData = useMemo<SlideWorkData | null>(() => {
        if (selectedTmSlideId === null) {
            return null;
        }

        const playerIndex = slideWorkDataState.indexByPlayerId[slidePlayerId];

        if (playerIndex === undefined) {
            return null;
        }

        const slideIndex = playerIndex.indexBySlideId[selectedTmSlideId];

        if ((slideIndex === undefined) || (slideWorkDataState.slides[slideIndex] === undefined)) {
            return null;
        }

        return slideWorkDataState.slides[slideIndex];
    }, [slidePlayerId, selectedTmSlideId, slideWorkDataState.indexByPlayerId, slideWorkDataState.slides]);

    const slideItemsParams = useMemo<SlideItemsParamsBySlide>(() => {
        const result: SlideItemsParamsBySlide = {};

        if (selectedSlideIndex < 0 || !slideList[selectedSlideIndex]) {
            return result;
        }

        const selectedSlideContent = slideList[selectedSlideIndex];

        if (selectedSlideContent) {
            selectedSlideContent.content.items.forEach((slideContentItem) => {
                if (slideContentItem.params === undefined) {
                    // Если это устаревший слайд - не устанавливаем ему параметры
                    return;
                }

                let overriddenParams: Partial<EditorItemDataParams> = {};

                if (currentSlideWorkData) {
                    const currentSlideItemIndex = currentSlideWorkData.itemsIndexById[slideContentItem.id];

                    if (currentSlideItemIndex) {
                        const currentSlideItem = currentSlideWorkData.items[currentSlideItemIndex];

                        if (currentSlideItem && currentSlideItem.overriddenParams) {
                            overriddenParams = currentSlideItem.overriddenParams;
                        }
                    }
                }

                result[slideContentItem.id] = {
                    ...slideContentItem.params,
                    ...overriddenParams
                };
            });
        }

        return result;
    }, [currentSlideWorkData, selectedSlideIndex, slideList]);

    const selectedSlideAvailableExercisesCount = useMemo<number>(() => {
        if (selectedSlideIndex < 0 || slideList[selectedSlideIndex] === undefined) {
            return 0;
        }

        const countInVisibleItems = slideList[selectedSlideIndex].content.items.reduce(
            (count, item) => {
                if (slideItemsParams[item.id] && slideItemsParams[item.id].visible && item.exercisesCount) {
                    return count + item.exercisesCount;
                }

                return count;
            },
            0
        );

        return countInVisibleItems > 0 ? countInVisibleItems : slideList[selectedSlideIndex].slideExerciseCount;
    }, [selectedSlideIndex, slideItemsParams, slideList]);

    const currentSlideExercisesCompletedCount = useMemo<number>(() => {
        if (!currentSlideWorkData || slideList[selectedSlideIndex].slideExerciseCount === 0) {
            return 0;
        }

        let countOfCompletedExercisesValue = 0

        currentSlideWorkData.items.forEach((slideItem) => {
            slideItem.exercises.forEach(exerciseItem => {
                if (exerciseItem.completed) {
                    countOfCompletedExercisesValue++;
                }
            });
        });

        return countOfCompletedExercisesValue;
    }, [currentSlideWorkData, selectedSlideIndex, slideList]);

    const currentProgressValuePercent = useMemo<number>(() => {
        // Берём и страницу и прогресс выполнения на текущей странице + 10% авансом в начале ученику.
        // Предполагаем, что перейти на следующую страниц не сделав предыдущую нельзя.


        // Отнимаем с начала 10% с конца
        const barMaxPercent = 100 - 10;

        let currentFillValue = 10;

        const pagesCount = slideList.length;

        // Добавляем заполнение страницы
        const pageMaxPercent = barMaxPercent / pagesCount;

        currentFillValue = currentFillValue + (pageMaxPercent * selectedSlideIndex);

        // Добавляем заполнение упражнений текущей страницы
        if (currentSlideWorkData && slideList[selectedSlideIndex].slideExerciseCount > 0) {
            const slideExercisesCount = slideList[selectedSlideIndex].slideExerciseCount;
            const exerciseMaxPercent = pageMaxPercent / slideExercisesCount;

            currentFillValue = currentFillValue + (exerciseMaxPercent * currentSlideExercisesCompletedCount);

            // const exercisesAwardSum = currentSlideWorkData.totalAward + currentSlideWorkData.missedAward;
            //
            // currentFillValue = currentFillValue + (exercisesAwardSum * pageMaxPercent / MAX_AWARD_SCORE);
        }

        return Math.ceil(currentFillValue);
    }, [slideList, selectedSlideIndex, currentSlideWorkData, currentSlideExercisesCompletedCount]);

    const runInteractivityConfig = useCallback((config: EditorItemInteractivityConfigWithParentItem) => {
        if (!currentSlideWorkData || !selectedTmSlideId) {
            return;
        }

        const runner = new InteractivityActionsRunner(container.resolve<ILogger>(DiTokens.LOGGER));

        runner.run({
            playerId: slidePlayerId,
            slideId: selectedTmSlideId,
            actions: config.actions,
            slideItemId: config.parentItemId,
            slideContent: EditorDataDtoMapper.dtoToEditorData(slideList[selectedSlideIndex].content),
            slideWorkData: currentSlideWorkData,
            dispatch: dispatch
        });
    }, [currentSlideWorkData, dispatch, selectedSlideIndex, selectedTmSlideId, slideList, slidePlayerId]);

    const setInteractivityConfig = useCallback((config: EditorItemInteractivityConfigWithParentItem | null) => {
        if (!config || (config.actionRunMode === SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN)) {
            setDelayedInteractivityConfig(config);

            return;
        }

        // В противном случае запустится по useEffect
        setDelayedInteractivityConfig(config);
    }, []);

    useEffect(() => {
        if (
            (delayedInteractivityConfig) &&
            (delayedInteractivityConfig.actionRunMode === SlideItemInteractivityActionsRunModeEnum.IMMEDIATELY)
        ) {
            runInteractivityConfig(delayedInteractivityConfig);
            setDelayedInteractivityConfig(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedInteractivityConfig]);

    const goNext = useCallback(() => {
        if (delayedInteractivityConfig !== null) {
            runInteractivityConfig(delayedInteractivityConfig);

            setDelayedInteractivityConfig(null);

            return;
        }

        if (selectedSlideIndex < slideList.length - 1) {
            setTimeout(() => window.scrollTo(0, 0), 100);
            setSelectedTmSlideId(slideList[selectedSlideIndex + 1].slideTmId);

            return;
        }

        navigate(RoutesHelper.replaceParams(
            RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE_END,
            [{key: 'episodeId', value: episodeId ?? ''}]
        ));
    }, [delayedInteractivityConfig, episodeId, navigate, runInteractivityConfig, selectedSlideIndex, slideList]);

    const nextButtonLabel = useMemo<string>(() => {
        if (delayedInteractivityConfig !== null && delayedInteractivityConfig.nextBtnLabel !== null) {
            return delayedInteractivityConfig.nextBtnLabel;
        }

        if (slideList.length === 0 || selectedSlideIndex < 0) {
            return t`Продолжить`;
        }

        const slideContent = slideList[selectedSlideIndex].content;

        if (slideContent.layoutSettings !== null && slideContent.layoutSettings.nextButtonLabel !== null) {
            return slideContent.layoutSettings.nextButtonLabel;
        }

        return t`Продолжить`;
    }, [delayedInteractivityConfig, selectedSlideIndex, slideList]);

    const nextButtonDisabled = useMemo<boolean>(() => {
        if (delayedInteractivityConfig !== null) {
            return false;
        }

        if ((slideList[selectedSlideIndex]) && (slideList[selectedSlideIndex].slideExerciseCount === 0)) {
            return false;
        }

        if (!currentSlideWorkData) {
            return true;
        }

        return selectedSlideAvailableExercisesCount > currentSlideExercisesCompletedCount;
    }, [currentSlideExercisesCompletedCount, currentSlideWorkData, delayedInteractivityConfig, selectedSlideAvailableExercisesCount, selectedSlideIndex, slideList]);

    const commonContextValue = useMemo<ICommonContext>(() => ({
        selectedTmSlideId: selectedTmSlideId,
        setSelectedTmSlideId: setSelectedTmSlideId,
        playerId: slidePlayerId,
        slideList: slideList,
        currentSlideWorkData,
        selectedSlideIndex,
        goNext: goNext,
        nextButtonDisabled: nextButtonDisabled,
        nextButtonLabel: nextButtonLabel,
        currentProgressValuePercent: currentProgressValuePercent,
        slideItemsParamsBySlide: slideItemsParams
    }), [currentProgressValuePercent, currentSlideWorkData, goNext, nextButtonDisabled, nextButtonLabel, selectedSlideIndex, selectedTmSlideId, slideItemsParams, slideList, slidePlayerId]);

    const workDataLoadingState = useMemo<PageLoadingStateEnum>(() => {
        if (currentSlideWorkData === null) {
            return PageLoadingStateEnum.LOADING;
        }

        switch (currentSlideWorkData.loadState) {
            case SlideWorkDataLoadingStateEnum.NOT_INIT:
            case SlideWorkDataLoadingStateEnum.LOADING: {
                return PageLoadingStateEnum.LOADING;
            }
            case SlideWorkDataLoadingStateEnum.ERROR: {
                return PageLoadingStateEnum.ERROR
            }
            default: {
                return PageLoadingStateEnum.SUCCESS;
            }

        }
    }, [currentSlideWorkData]);

    const pageLoadingState = useMemo<PageLoadingStateEnum>(() => {
        if ((slideContentLoadingState === PageLoadingStateEnum.ERROR) || (workDataLoadingState === PageLoadingStateEnum.ERROR)) {
            return PageLoadingStateEnum.ERROR;
        }

        if ((slideContentLoadingState === PageLoadingStateEnum.SUCCESS) && (workDataLoadingState === PageLoadingStateEnum.SUCCESS)) {
            return PageLoadingStateEnum.SUCCESS;
        }

        return PageLoadingStateEnum.LOADING;
    }, [slideContentLoadingState, workDataLoadingState]);

    useEffect(() => {
        fetchData();

        return () => {
            dispatch(setContentLayoutSettings(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (pageLoadingState !== PageLoadingStateEnum.SUCCESS) {
            return;
        }

        // При первом монтировании получаем последний отображаемый элемент
        // и проверяем - если на нём есть отложенный interactivityConfig, то восстанавливаем его
        const selectedSlide = slideList[selectedSlideIndex];

        if (!selectedSlide) {
            return;
        }

        let lastVisibleItemIndex: null | number = null;

        for (let i = 0; i < selectedSlide.content.items.length; i++) {
            const params = slideItemsParams[selectedSlide.content.items[i].id];

            if (!params || params.visible === undefined || params.visible) {
                lastVisibleItemIndex = i;
            }
        }

        if (lastVisibleItemIndex === null) {
            return;
        }

        const interactivityConfig = selectedSlide.content.items[lastVisibleItemIndex].interactivityConfig;

        if (interactivityConfig && interactivityConfig.actionRunMode === SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN) {
            const slideItem = selectedSlide.content.items[lastVisibleItemIndex];

            if (slideItem.exercisesCount === undefined) {
                return;
            }

            if (!currentSlideWorkData) {
                return;
            }

            const workDataIndex = currentSlideWorkData.itemsIndexById[slideItem.id];

            if (workDataIndex === undefined) {
                return;
            }

            const workData = currentSlideWorkData.items[workDataIndex];

            const completedExercises = workData.exercises.reduce(
                (completedCount, item) => {
                    if (item.completed === true) {
                        return completedCount + 1;
                    }

                    return completedCount;
                },
                0
            );

            if (completedExercises !== slideItem.exercisesCount) {
                return;
            }

            setInteractivityConfig({
                ...interactivityConfig,
                parentItemId: selectedSlide.content.items[lastVisibleItemIndex].id
            });

            const logger = container.resolve<ILogger>(DiTokens.LOGGER);
            logger.info(LoggerSectionsEnum.SLIDE_INTERACTIVITY, `Deferred interactivity config restored`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLoadingState, selectedSlideIndex]);

    useEffect(() => {
        if (selectedTmSlideId === null) {
            dispatch(setContentLayoutSettings(null));
            setHasBackground(false);
            setNoRightBlock(false);

            return;
        }

        const slideListItem = slideList.find((item) => item.slideTmId === selectedTmSlideId);

        if (slideListItem && slideListItem.content.layoutSettings) {
            const settings = slideListItem.content.layoutSettings;

            dispatch(setContentLayoutSettings({
                themeName: settings.themeName as ThemeEnum,
                backgroundFileId: settings.backgroundFileId ?? undefined,
                backgroundMode: settings.backgroundMode ?? undefined,
                hideLeftNavigation: settings.hideLeftNavigation,
                transparentHeader: settings.transparentHeader
            }));

            setHasBackground(!!settings.backgroundFileId);
            setNoRightBlock(true);
        } else {
            dispatch(setContentLayoutSettings(null));
            setHasBackground(false);
            setNoRightBlock(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTmSlideId]);

    const contentWrapperClassName = useMemo<string | undefined>(() => {
        const withBg = hasBackground;
        let themeName = undefined;

        if (withBg) {
            themeName = currentThemeName === ThemeEnum.light ? 'theme-light' : 'theme-dark';
        }

        return classNames(withBg && 'with-bg', themeName);
    }, [currentThemeName, hasBackground]);

    const content = (): React.ReactNode => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>
            }

            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchData}/>
            }

            default: {
                return <PageWrapper
                    pageTitle={null}
                    pageContent={<CenterBlock className={contentWrapperClassName}>
                        {
                            (!large && !noRightBlock) && <MobileTopLine/>
                        }
                        <ContentWrapper setInteractivityConfig={setInteractivityConfig}/>
                        <NextButton label={nextButtonLabel}/>
                    </CenterBlock>
                    }
                    rightBlockContent={
                        (!large || noRightBlock) ? null :
                            <RightBlockText>
                                <RightBlock nextBtnLabel={nextButtonLabel}/>
                            </RightBlockText>
                    }
                    contentOnCenter={noRightBlock}
                    rightBlockWrapperStyle={hasBackground ? {
                        backgroundColor: currentTheme.colors.backgroundPrimary + '80',
                        border: 'none'
                    } : undefined}
                    backLink={null}/>
            }
        }
    };

    const playSettingsPopupItems = useMemo<PopupItem[]>(() => {
        const items: PopupItem[] = [];

        items.push({
            text: t`Выполнить задание заново`,
            onClick: () => {
                if (!sessionToken || !episodeId || !selectedTmSlideId) {
                    return;
                }

                httpApiClient.current
                    .selfStudyClearSlideWorkData(
                        sessionToken,
                        episodeId,
                        selectedTmSlideId
                    )
                    .then(() => {
                        fetchData(true);

                        dispatch(resetAllWorkDataState());

                        openNotification(
                            NotificationTypesEnum.SUCCESS,
                            t`Успешно`,
                            t`Прогресс задания сброшен`
                        );
                    })
                    .catch(() => {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Ошибка`,
                            t`Не удалось выполнить операцию`
                        );
                    });
            }
        });

        return items;
    }, [dispatch, episodeId, fetchData, selectedTmSlideId, sessionToken]);

    return <CommonContextProvider value={commonContextValue}>
        {content()}
        {
            (
                (leftNavigationCustomContentArea.current !== null)
                && (workDataLoadingState === PageLoadingStateEnum.SUCCESS)
            )
            && createPortal(
                <PlaySettingsWrapper>
                    <Popup
                        items={playSettingsPopupItems}
                        trigger={<PlaySettingsBtn/>}
                        position={'top left'}
                        arrow={false}
                    />
                </PlaySettingsWrapper>,
                leftNavigationCustomContentArea.current
            )
        }
    </CommonContextProvider>
}

Episode.displayName = 'Episode'