import {instanceCachingFactory} from 'tsyringe';
import {ISoundPlayer} from "../components/SoundPlayer/ISoundPlayer";
import {SoundPlayer} from "../components/SoundPlayer/SoundPlayer";
import {ILogger} from "../components/Logger/ILogger";
import {DiTokens} from "./DiTokens";
import soundPlayerLinks from "../config/SoundLinks";

export default instanceCachingFactory<ISoundPlayer>((container) => {
    return new SoundPlayer(
        container.resolve<ILogger>(DiTokens.LOGGER),
        soundPlayerLinks
    );
});