import React, {useCallback, useEffect} from "react";
import {Form, FormItem, useForm} from "../../../../../components/Ui/Elements/Form";
import {useDispatch, useSelector} from "react-redux";
import {LocaleEnum, LocaleNameById, LocaleNameEnum, LocaleStateEnum} from "../../../../../../store/i18n/type";
import * as i18nActionCreators from "../../../../../../store/i18n/actions";
import {ApplicationState} from "../../../../../../store";
import {Trans} from "@lingui/macro";
import {PopupSelector} from "../../../../../components/Ui/Elements/Selectors/PopupSelector";
import classNames from "classnames";
import styled from "styled-components";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {sessionTokenSelector} from "../../../../../../store/app/selector";

const FORM_FIELD_NAME = 'currentLocale';

const PopupSelectorStyled = styled(PopupSelector)`
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
`;

export const SetLocaleField: React.FC<{}> = () => {
    const [form] = useForm();
    const dispatch = useDispatch();
    
    const currentToken = useSelector(sessionTokenSelector);

    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;

    const currentState = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentState
    ) as LocaleStateEnum;

    const selectLocale = useCallback((localeId: LocaleEnum) => {
        dispatch(i18nActionCreators.selectLocale(localeId));
        
        if (currentToken === null) {
            return;
        }
        
        const httpClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        
        httpClient.setUiLocale(
            currentToken,
            localeId
        );
    }, [currentToken, dispatch]);
    
    useEffect(() => {
        form.setFieldValue(
            FORM_FIELD_NAME,
            {
                key: currentLocale,
                name: LocaleNameById[currentLocale]
            }
        );
    }, [currentLocale, form]);

    const formOnChange = () => {
        const selectedObject = form.getFieldValue(FORM_FIELD_NAME);

        selectLocale(selectedObject.key);
    }

    return <Form form={form} layout={"vertical"} onValuesChange={formOnChange}>
        <FormItem
            key={currentLocale}
            name={FORM_FIELD_NAME}
            initialValue={{
                key: currentLocale,
                name: LocaleNameById[currentLocale]
            }}
            label={<Trans>Язык интерфейса</Trans>}
        >
            <PopupSelectorStyled
                className={classNames(
                    currentState !== LocaleStateEnum.READY && 'disabled'
                )}
                popupPosition={"bottom left"}
                variants={[
                    {
                        key: LocaleEnum.EN,
                        name: LocaleNameEnum.EN
                    },
                    {
                        key: LocaleEnum.RU,
                        name: LocaleNameEnum.RU
                    }
                ]}
            />
        </FormItem>
    </Form>
}