import React from "react";
import {PageTitle} from "../../../../../styles/global-elements";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {debounce} from "lodash";
import {NoticeBlock, NoticeBlockText} from "../../NoticeBlock";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../../../DefaultLoader";
import {ErrorLoadingContent} from "../../ErrorLoadingContent";
import {Modal, ModalControlParams} from "../../Modal";
import {BtnStyleEnum, Button} from "../../Button";
import {SingleNameFilter} from "../Common/SingleNameFilter";
import {ResultList} from "../Common/ResultList";
import {
    DtoTimezoneSearchResponseItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StaticData/DtoTimezoneSearchResponseItem";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {ResultListItem} from "../Common/ResultListItem";

const ITEMS_PER_PAGE = 6;

const PageWrapper = styled.div``;
const ResultWrapper = styled.div`
  padding: 0 10px;
  min-height: 310px;
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export interface StTeacherSelectModalProps {
    stToken?: string | null;
    trigger: React.ReactElement;
    onChange: (value: DtoTimezoneSearchResponseItem) => void;
}

interface StTeacherSelectModalState {
    loadingState: LoadingState;
    searchString: string;
    timezonesList: DtoTimezoneSearchResponseItem[] | null;
    currentPageNum: number;
    totalTimezonesCount: number | null;
}

export class TimezoneSelectModalWindow extends React.Component<StTeacherSelectModalProps, StTeacherSelectModalState> {
    protected searchAbortController: AbortController | null;

    protected stonlineApiClient: IStonlineApiClient;
    protected logger: ILogger;
    protected dateHelperService: IDateHelperService;

    protected setSearchStringBind;
    protected onOpenWindowBind;
    protected onCloseWindowBind;
    protected searchRequestDebounced;

    constructor(props: Readonly<StTeacherSelectModalProps>) {
        super(props);

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            searchString: '',
            timezonesList: null,
            totalTimezonesCount: null,
            currentPageNum: 1
        };

        this.searchAbortController = null;

        this.setSearchStringBind = this.setSearchString.bind(this);
        this.onOpenWindowBind = this.onOpenWindow.bind(this);
        this.onCloseWindowBind = this.onCloseWindow.bind(this);
        this.searchRequestDebounced = debounce(() => {
            this.setState(() => {
                return {
                    currentPageNum: 1
                }
            }, () => {
                this.searchRequest().then();
            })
        }, 500);
    }

    componentWillUnmount() {
        this.onCloseWindowBind();
    }

    protected onOpenWindow() {
        this.setState(() => {
            return {
                currentPageNum: 1,
                searchString: ''
            }
        }, () => {
            this.searchRequest().then();
        })
    }

    protected onCloseWindow() {
        this.searchRequestDebounced.cancel();

        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
            this.searchAbortController = null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOT_INIT
            }
        })
    }

    protected setSearchString = (newSearchString: string) => {
        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
            this.searchAbortController = null;
        }

        this.setState(() => {
            return {
                searchString: newSearchString
            }
        }, () => this.searchRequestDebounced());
    }

    protected searchRequest = async () => {
        if ((this.props.stToken === null) || (this.props.stToken === undefined)) {
            return null;
        }

        this.setState(
            () => {
                return {
                    loadingState: LoadingState.LOADING
                }
            }
        );

        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
        }

        this.searchAbortController = new AbortController();

        try {
            const result = await this.stonlineApiClient.searchTimezone(
                this.props.stToken,
                this.state.searchString,
                this.state.currentPageNum,
                ITEMS_PER_PAGE,
                this.searchAbortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    timezonesList: result.result.items,
                    totalTimezonesCount: result.result.totalCount
                }
            });
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.STONLINE_TIMEZONES_API,
                "Error on search timezone: ",
                e
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });
        }
    }

    protected emptyListNotificationBox = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockText>
                    <Trans>По вашему запросу не нашлось временных зон</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    protected resultContent = (controls: ModalControlParams) => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.SUCCESS: {
                if ((this.state.timezonesList !== null) && (this.state.totalTimezonesCount !== null)) {
                    if (this.state.timezonesList.length > 0) {
                        return <>
                            <ResultList
                                currentPageNum={this.state.currentPageNum}
                                itemsPerPage={ITEMS_PER_PAGE}
                                onPageChange={(page) => {
                                    this.setState({
                                        currentPageNum: page
                                    }, () => this.searchRequest());
                                }}
                                totalItemsCount={this.state.totalTimezonesCount}
                            >
                                {
                                    this.state.timezonesList.map((item) => {
                                        return <ResultListItem key={item.zoneName} onClick={() => {
                                            this.props.onChange(item);
                                            controls.closeModal();
                                        }}>
                                            <span>{item.offset}, {item.zoneName} ({this.dateHelperService.dateFromStringToTimeString(item.currentTime)})</span>
                                        </ResultListItem>;
                                    })
                                }
                            </ResultList>
                        </>;
                    } else {
                        return <>{this.emptyListNotificationBox()}</>;
                    }
                }

                return <ErrorLoadingContent retryBtnClick={this.searchRequest}/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.searchRequest}/>
            }
        }
    }

    protected modalContent = (controls: ModalControlParams) => {
        return <div>
            <PageTitle><Trans>Выбор временной зоны</Trans></PageTitle>
            <PageWrapper>
                <SingleNameFilter
                    placeholder={t`Поиск по имени зоны...`}
                    searchString={this.state.searchString}
                    setSearchString={(newValue: string) => this.setSearchString(newValue)}/>
                <ResultWrapper>
                    {this.resultContent(controls)}
                </ResultWrapper>
            </PageWrapper>
        </div>
    }

    render() {
        return <Modal trigger={this.props.trigger}
                      onOpen={this.onOpenWindowBind}
                      onClose={this.onCloseWindowBind}
                      closeAllowed={true}
                      footer={(controls) => {
                          return <div>
                              <Button btnStyle={BtnStyleEnum.Secondary}
                                      onClick={() => controls.closeModal()}><Trans>Закрыть</Trans></Button>
                          </div>
                      }}
                      children={(controls) => this.modalContent(controls)}
        />
    }
}
