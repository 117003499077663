export enum FormFieldNamesEnum {
    USER_NAME = 'user_name',
    SCHOOL_TYPE = 'school_type',
    SCHOOL_NAME = 'school_name',
    TIMEZONE_NAME = 'timezone_name',
    ACT_PARAMS_COUNT = 'act_params_count',
    ACT_PARAM1_NAME = 'act_param1_name',
    ACT_PARAM2_NAME = 'act_param2_name',
    ACT_PARAM3_NAME = 'act_param3_name',
    ACT_PARAM4_NAME = 'act_param4_name',
    ACT_PARAM5_NAME = 'act_param5_name',
}