import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t} from "@lingui/macro";

const TextStyle = styled.div`
`

interface TextProps extends ItemPropsInterface<any> {
}

interface TextRefMethods extends ItemRefMethodsInterface {
}

export const Text = forwardRef<TextRefMethods, TextProps>(
    (props, ref) => {

        const richTextEditorRef = useRef<RichTextEditor>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                richTextEditorRef.current?.toolbarItemOnToggle(buttonType, newValue);
            },
            getExercisesCount: () => {
                return 0;
            }
        }));

        return <TextStyle>
            <RichTextEditor editorMode={RichTextEditorMode.TEXT_WITH_ADDONS}
                            placeHolderText={t`Текст слайда...`}
                            setToolbarConfigById={props.setToolbarConfigById}
                            ref={richTextEditorRef}
                            onChange={props.onChange}
                            initialData={props.initialData}
                            id={props.id}
            />
        </TextStyle>;
    }
);