import React, {FormEvent, useCallback, useContext, useEffect, useState} from "react";
import {ILessonPageContext, LessonPageContext} from "../LessonPageContext";
import styled from "styled-components";
import {Form, useForm} from "../../../../components/Ui/Elements/Form";
import {t, Trans} from "@lingui/macro";
import FormItem from "antd/es/form/FormItem";
import {DatePicker} from "../../../../components/Ui/Elements/DatePicker";
import {TimePicker} from "../../../../components/Ui/Elements/TimePicker";
import moment from "moment";
import {Checkbox} from "../../../../components/Ui/Elements/Checkbox";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {TextArea} from "../../../../components/Ui/Elements/TextArea";
import {StTeacherSelector} from "../../../../components/Ui/Elements/Selectors/StTeacherSelector";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../store/app/selector";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import classNames from "classnames";
import {ConfirmDialog} from "../../../../components/Ui/Elements/ConfirmDialog";
import {useNavigate} from "react-router-dom";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {RegularTextCss} from "../../../../styles/global-elements";
import {MessageDialog} from "../../../../components/Ui/Elements/MessageDialog";

enum FormFieldNames {
    teacherId = 'teacherId',
    lessonDate = 'lessonDate',
    timeStart = 'timeStart',
    timeEnd = 'timeEnd',
    homeTask = 'homeTask',
    comment = 'comment',
    isDraft = 'isDraft',
    isFake = 'isFake'
}

const LessonFormWrapper = styled.div`
  .ant-form-inline .ant-form-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-form-item-label > label {
    color: ${({theme}) => theme.colors.textTertiary};
  }

  .ant-form-item-label > label:after {
    content: "";
  }

  .ant-form-item-label > label {
    height: auto;
  }

  .ant-form-item-label, .ant-form-item-control {
    width: 100%;
    text-align: left;
  }

  @media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control, .ant-form .ant-form-item .ant-form-item-label {
      flex: unset;
      padding: 0;
    }
  }
`;

const FormInputWrapper = styled.div`
  padding: 0;
  margin-bottom: 15px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .picker {
    width: 100%;
  }
`;

const LessonDateWrapper = styled(FormInputWrapper)`
  width: 100%;

  @media (${({theme}) => theme.media.large}) {
    width: auto;
  }
`;

const LessonTimeWrapper = styled(FormInputWrapper)`
  width: 100%;
  transition: opacity 0.3s ease;

  @media (${({theme}) => theme.media.small}) {
    width: 50%;
  }

  @media (${({theme}) => theme.media.large}) {
    width: auto;
  }

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }
`;

const WideFieldWrapper = styled(FormInputWrapper)`
  width: 100%;
`;

// const DatePickerStyled = styled(DatePicker)`
//   width: 100%;
// `;
//
// const TimePickerStyled = styled(TimePicker)`
//   width: 100%;
// `;

const CheckboxMainText = styled.div`
  ${RegularTextCss};

  display: inline-block;
  margin-bottom: 5px;
`;

const CheckboxNotice = styled.div`
  display: inline-block;
  color: ${({theme}) => theme.colors.textSecondary};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  margin-top: 20px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;


export const GeneralModePage: React.FC = () => {
    const [form] = useForm();

    const [saveInProcess, setSaveInProcess] = useState<boolean>(false);
    const [loadingScheduleForTimes, setLoadingScheduleForTimes] = useState<boolean>(false);

    const [isDraftDisabled, setIsDraftDisabled] = useState<boolean>(false);
    const [isFakeDisabled, setIsFakeDisabled] = useState<boolean>(false);

    const lessonPageContext = useContext<ILessonPageContext>(LessonPageContext);
    const stToken = useSelector(stTokenSelector);

    const navigate = useNavigate();

    const checkIsDraftAndIsFakeFormValues = useCallback((initiatorName: string | null) => {
        const isDraftValue = form.getFieldValue('isDraft');
        const isFakeValue = form.getFieldValue('isFake');

        if (initiatorName === null) {
            if (isDraftValue === true) {
                setIsDraftDisabled(false);
                setIsFakeDisabled(true);
            } else if (isFakeValue === true) {
                setIsDraftDisabled(true);
                setIsFakeDisabled(false);
            }
        }

        if (initiatorName === FormFieldNames.isDraft) {
            setIsFakeDisabled(isDraftValue === true);
        }

        if (initiatorName === FormFieldNames.isFake) {
            setIsDraftDisabled(isFakeValue === true);
        }
    }, [form]);

    useEffect(() => {
        checkIsDraftAndIsFakeFormValues(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDraftDisabled) {
            form.setFieldsValue({
                isDraft: false,
            });
        }

        if (isFakeDisabled) {
            form.setFieldsValue({
                isFake: false,
            });
        }
    }, [form, isDraftDisabled, isFakeDisabled]);
    
    useEffect(() => {
        if (lessonPageContext.lessonDto && lessonPageContext.lessonDto.isDraft === '0') {
            if (form.getFieldValue(FormFieldNames.isDraft) === true) {
                form.setFieldValue(FormFieldNames.isDraft, false);
                checkIsDraftAndIsFakeFormValues(FormFieldNames.isDraft);
            }
        }
    }, [checkIsDraftAndIsFakeFormValues, form, lessonPageContext.lessonDto]);

    const formOnChange = (_event: FormEvent) => {
    }

    const onFinish = async (values: any) => {
        if (stToken === null) {
            return;
        }

        if (lessonPageContext.lessonDto === null) {
            return;
        }

        if (values[FormFieldNames.teacherId] === undefined) {
            return;
        }

        const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        const stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        const lessonId = parseInt(lessonPageContext.lessonDto.id);
        const teacherId = values[FormFieldNames.teacherId].id;
        const lessonDate = dateHelper.formatAsSQLDate(values[FormFieldNames.lessonDate].toDate());
        const timeStart = values[FormFieldNames.timeStart].format('HH:mm');
        const timeEnd = values[FormFieldNames.timeEnd].format('HH:mm');
        const homeTask = values[FormFieldNames.homeTask];
        const comment = values[FormFieldNames.comment];
        const isFake = values[FormFieldNames.isFake];
        const isDraft = values[FormFieldNames.isDraft];

        setSaveInProcess(true);

        try {
            await stonlineApiClient.updateLessonData(
                stToken,
                lessonId,
                teacherId,
                lessonDate,
                timeStart,
                timeEnd,
                homeTask,
                comment,
                isFake,
                isDraft
            );

            setSaveInProcess(false);

            openNotification(
                NotificationTypesEnum.SUCCESS,
                t`Информация сохранена`,
                t`Основные данные урока обновлены`
            );

            const isDraftAsString = (isDraft) ? "1" : "0";

            if (lessonPageContext.lessonDto.isDraft !== isDraftAsString) {
                lessonPageContext.updateLessonDto({
                    ...lessonPageContext.lessonDto,
                    isDraft: isDraftAsString
                });
            }
        } catch (e) {
            logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, "Error on save lesson: ", e);

            setSaveInProcess(false);

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Ошибка сохранения`,
                t`Не удалось сохранить информацию`
            );
        }
    }

    const lessonDateOnChange = () => {
        if (stToken === null) {
            return;
        }

        if (lessonPageContext.lessonDto === null) {
            return;
        }

        const lessonDate = form.getFieldValue(FormFieldNames.lessonDate);

        if (!lessonDate) {
            return;
        }

        const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        const stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setLoadingScheduleForTimes(true);

        stonlineApiClient.getPlanLessonsForDate(
            stToken,
            parseInt(lessonPageContext.lessonDto.groupId),
            dateHelper.formatAsSQLDate(lessonDate.toDate())
        )
            .then((items) => {
                if (items.result.items.length > 0) {
                    form.setFieldsValue({
                        timeStart: moment(items.result.items[0].timeStart, "HH:mm"),
                        timeEnd: moment(items.result.items[0].timeEnd, "HH:mm")
                    })
                } else {
                    form.setFieldsValue({
                        timeStart: moment("00:00", "HH:mm"),
                        timeEnd: moment("00:00", "HH:mm")
                    })
                }

                setLoadingScheduleForTimes(false);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, "Error on load planLessonForDate for lesson times. ", err);

                setLoadingScheduleForTimes(false);
            })
    }

    const deleteBtnClick = async () => {
        if (stToken === null) {
            return;
        }

        if (lessonPageContext.lessonDto === null) {
            return;
        }

        const stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        try {
            await stonlineApiClient.deleteLesson(stToken, parseInt(lessonPageContext.lessonDto.id));

            navigate(
                RoutesHelper.replaceParams(
                    RoutesList.TEACHER_GROUP_INFO,
                    [
                        {
                            key: 'groupId',
                            value: lessonPageContext.lessonDto.groupId
                        }
                    ]
                )
            );
        } catch (e) {
            logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, "Error on delete lesson: ", e);

            throw e;
        }
    }

    if (!lessonPageContext.lessonDto) {
        return <LessonFormWrapper/>;
    }

    return <LessonFormWrapper>
        <Form form={form} layout={"inline"} onFinish={onFinish} onValuesChange={formOnChange}>
            <WideFieldWrapper>
                <FormItem
                    name={FormFieldNames.teacherId}
                    initialValue={{
                        id: parseInt(lessonPageContext.lessonDto.teacherId),
                        longName: lessonPageContext.lessonDto.teacherName
                    }}
                    label={<Trans>Преподаватель</Trans>}
                >
                    <StTeacherSelector/>
                </FormItem>
            </WideFieldWrapper>
            <LessonDateWrapper>
                <FormItem
                    name={FormFieldNames.lessonDate}
                    initialValue={moment(lessonPageContext.lessonDto.lessonDate, "YYYY-MM-DD")}
                    label={<Trans>Дата занятия</Trans>}
                    rules={[
                        {
                            type: "date",
                            message: t`Нужно указать дату занятия`
                        }
                    ]}
                >
                    <DatePicker className={"picker"} placeholder={t`Дата занятия`} onChange={lessonDateOnChange}/>
                </FormItem>
            </LessonDateWrapper>
            <LessonTimeWrapper className={classNames(loadingScheduleForTimes && "loading")}>
                <FormItem
                    name={FormFieldNames.timeStart}
                    initialValue={moment(lessonPageContext.lessonDto.timeStart, "HH:mm")}
                    label={<Trans>Начало</Trans>}
                >
                    <TimePicker className={"picker"} placeholder={t`Время начала`}/>
                </FormItem>
            </LessonTimeWrapper>
            <LessonTimeWrapper className={classNames(loadingScheduleForTimes && "loading")}>
                <FormItem
                    name={FormFieldNames.timeEnd}
                    dependencies={[FormFieldNames.timeStart]}
                    initialValue={moment(lessonPageContext.lessonDto.timeEnd, "HH:mm")}
                    label={<Trans>Завершение</Trans>}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value) {
                                    const timeStartValue = getFieldValue(FormFieldNames.timeStart);

                                    if (value.isBefore(timeStartValue)) {
                                        return Promise.reject(new Error(t`Раньше начала`));
                                    }

                                    if (getFieldValue(FormFieldNames.timeStart).format('H:mm') === value.format('H:mm')) {
                                        return Promise.reject(new Error(t`Нулевая длительность`));
                                    }
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <TimePicker className={"picker"} placeholder={t`Время завершения`}/>
                </FormItem>
            </LessonTimeWrapper>
            <WideFieldWrapper>
                <FormItem
                    name={FormFieldNames.homeTask}
                    initialValue={lessonPageContext.lessonDto.homeTask}
                    label={<Trans>Комментарии к домашнему заданию (будет отправлено ученикам)</Trans>}
                >
                    <TextArea/>
                </FormItem>
            </WideFieldWrapper>
            <WideFieldWrapper>
                <FormItem
                    name={FormFieldNames.comment}
                    initialValue={lessonPageContext.lessonDto.comment}
                    label={<Trans>Внутренняя заметка (не видно ученикам)</Trans>}
                >
                    <Input/>
                </FormItem>
            </WideFieldWrapper>
            <WideFieldWrapper>
                <FormItem
                    name={FormFieldNames.isDraft}
                    initialValue={lessonPageContext.lessonDto.isDraft === "1"}
                    valuePropName="checked">
                    <Checkbox disabled={isDraftDisabled}
                              onChange={() => checkIsDraftAndIsFakeFormValues(FormFieldNames.isDraft)}>
                        <CheckboxMainText><Trans>Это черновик занятия</Trans></CheckboxMainText><br/>
                        <CheckboxNotice><Trans>Черновик занятия используется для подготовки материалов к
                            уроку. Галочка будет снята автоматически, если вы начнёте занятие.</Trans></CheckboxNotice>
                    </Checkbox>
                </FormItem>
            </WideFieldWrapper>
            <WideFieldWrapper>
                <FormItem
                    name={FormFieldNames.isFake}
                    initialValue={lessonPageContext.lessonDto.isFake === "1"}
                    valuePropName="checked">
                    <Checkbox disabled={isFakeDisabled}
                              onChange={() => checkIsDraftAndIsFakeFormValues(FormFieldNames.isFake)}>
                        <CheckboxMainText><Trans>Занятие не проводилось</Trans></CheckboxMainText><br/>
                        <CheckboxNotice><Trans>Используется в случае, если урок не проводился, но нужно сформировать по
                            нему финансовые операции</Trans></CheckboxNotice>
                    </Checkbox>
                </FormItem>
            </WideFieldWrapper>
            <ButtonsWrapper>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Сохранить</Trans>
                </Button>
                {
                    (lessonPageContext.onlineRoomIsOpened)
                        ? <MessageDialog title={t`Комната урока ещё открыта`}
                                         text={
                                             (lessonPageContext.onlineRoomIsConnected)
                                                 ? t`Пожалуйста закройте комнату урока перед удалением занятия`
                                                 : t`Пожалуйста подключитесь к комнате урока и закройте её перед удалением занятия`
                                         }
                                         trigger={
                                             <Button btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                                                 <Trans>Удалить занятие</Trans>
                                             </Button>
                                         }
                        />
                        : <ConfirmDialog trigger={
                            <Button btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                                <Trans>Удалить занятие</Trans>
                            </Button>
                        }
                                         okText={t`Удалить`}
                                         cancelText={t`Отмена`}
                                         title={t`Удалить урок?`}
                                         text={t`Вы уверены, что нужно удалить информацию об этом уроке?`}
                                         errorText={t`Не удалось удалить информацию о занятии`}
                                         okMethod={deleteBtnClick}/>
                }
            </ButtonsWrapper>
        </Form>
    </LessonFormWrapper>;
};
