import {IWsApiClient} from "./IWsApiClient";
import {Dispatch} from "redux";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";
import {ResponseBaseDto} from "./ApiDto/Response/ResponseBaseDto";
import {ClassType} from "../../types/ClassType";
import {ModelValidator} from "../ModelValidator/ModelValidator";
import {RouteItem} from "./Router/RouteItem";
import {IRouter} from "./Router/IRouter";
import {ConnectionEventsRoutesEnum} from "./ConnectionEventsRoutesEnum";
import {classToPlain} from "class-transformer";
import {IBaseApiDto} from "./ApiDto/IBaseApiDto";
import {RequestContextInfoList} from "./RequestContextInfo/RequestContextInfoList";
import {RequestContextInfoItem} from "./RequestContextInfo/RequestContextInfoItem";
import {ApiMethodEnum} from "./ApiMethodEnum";
import {WsResponseStatusEnum} from "./WsResponseStatusEnum";
import {ErrorsResponseData} from "./ApiDto/Response/ErrorsResponseData";
import {RequestBaseDto} from "./ApiDto/Request/RequestBaseDto";
import {ResponseActionCreatorPayload} from "./ResponseActionCreatorPayload";

enum SocketReadyStateEnum {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3
}

export enum WsServerConstants {
    USER_LIST_MAX_BATCH_SIZE = 20,
    STREAM_EVENT_HISTORY_MAX_BATCH_SIZE = 100,
    ONLINE_STATE_SUBSCRIPTIONS_MAX_COUNT = 500,
    QUERY_AS_PROMISE_TIMEOUT_SEC = 10,
    SLIDE_WORK_DATA_MAX_BATCH_SIZE = 50
}

/**
 * Класс для реализации запросов с ожиданием ответа await.
 */
class DeferredForAwaitCalls<T> {
    protected promise: Promise<ResponseActionCreatorPayload<ResponseBaseDto<T>, null>>;
    public resolve: (data: ResponseActionCreatorPayload<ResponseBaseDto<T>, null>) => void;

    constructor() {
        this.promise = new Promise((resolve) => {
            this.resolve = resolve;
        });
    }

    public getPromise() {
        return this.promise;
    }
}

type AwaitCallData<ResponseDtoType> = {
    methodName: string,
    deferredObjectForResolve: DeferredForAwaitCalls<ResponseDtoType>,
    resultDto: ClassType<IBaseApiDto> | null,
    timerValue: number;
    alreadyHandled: boolean;
}

type AwaitCallDataList = { [id: string]: AwaitCallData<any> };

export class WsApiClient implements IWsApiClient {
    protected serverUri: string;
    protected connection: WebSocket | null;
    protected responseRouter: IRouter;
    protected dispatch: Dispatch;
    protected logger: ILogger;
    protected simulatedErrorCode: WsResponseStatusEnum | null;

    /**
     * Счётчик запросов. Инкрементируется с каждым запросом в сторону WebSocket сервера.
     */
    protected requestCounter: number;

    protected requestContextInfo: RequestContextInfoList;

    protected awaitCallsList: AwaitCallDataList;
    protected awaitCallsListTimeoutTimer: number | null;

    constructor(serverUri: string, dispatch: Dispatch, responseRouter: IRouter, logger: ILogger) {
        this.serverUri = serverUri;
        this.dispatch = dispatch;
        this.connection = null;
        this.logger = logger;
        this.responseRouter = responseRouter;
        this.requestCounter = 0;
        this.requestContextInfo = {};
        this.simulatedErrorCode = null;
        this.awaitCallsList = {};
        this.awaitCallsListTimeoutTimer = null;
    }

    /** @inheritDoc */
    public createConnection(): void {
        if (this.connection !== null) {
            try {
                this.connection.close();
            } catch (e) {
            }
        }

        this.connection = new WebSocket(this.serverUri);

        this.connection.onopen = this.onConnectionOpen.bind(this);
        this.connection.onerror = this.onConnectionError.bind(this);
        this.connection.onclose = this.onConnectionClose.bind(this);
        this.connection.onmessage = this.onConnectionMessage.bind(this);
    }

    public closeConnection(): void {
        if (this.connection === null) {
            this.logger.info(
                LoggerSectionsEnum.WS_COMPONENT,
                'closeConnection method called but connection is null'
            );

            return;
        }

        try {
            this.logger.info(LoggerSectionsEnum.WS_COMPONENT, 'Close connection manually');

            // Здесь отписываемся от событий сокета, посылаем сигнал закрытия и забываем про него.
            // Всё дело в том, что событие браузера onclose может сработать через слишком долгий
            // промежуток времени, поэтому не будем его дожидаться.
            this.connection.onclose = null;
            this.connection.onerror = null;
            this.connection.onmessage = null;
            this.connection.onopen = null;
            this.connection.close();

            this.afterConnectionClosed();
        } catch (e) {
        }

        this.connection = null;
    }

    /** @inheritDoc */
    public async query(methodName: ApiMethodEnum, paramsDto: IBaseApiDto, contextInfo: any = null, ignoreResponse: boolean = false): Promise<void> {
        return new Promise<void>(resolve => {

            // Если мы в режиме имитации ошибок
            if (this.simulatedErrorCode !== null) {
                if (ignoreResponse) {
                    return;
                }

                // Собираем объект ошибки и роутинг.
                let response: ResponseBaseDto<null> = new ResponseBaseDto<null>();

                response.id = 0;
                response.params = null;
                response.method = methodName;
                response.status = this.simulatedErrorCode;
                response.errors = new ErrorsResponseData('Simulated error');

                this.handleEvent(JSON.stringify(classToPlain(response)), contextInfo).then();

                resolve();

                return;
            }

            // Если активного соединения нет - сразу вызываем обработчик результата ошибки.
            if (this.connection === null || this.connection.readyState !== SocketReadyStateEnum.OPEN) {
                if (ignoreResponse) {
                    return;
                }

                // Собираем объект ошибки и роутинг.
                let response: ResponseBaseDto<null> = new ResponseBaseDto<null>();

                response.id = 0;
                response.params = null;
                response.method = methodName;
                response.status = WsResponseStatusEnum.CONNECTION_ERROR;
                response.errors = new ErrorsResponseData('Web socket connection unavailable.');

                this.handleEvent(JSON.stringify(classToPlain(response)), contextInfo).then();

                resolve();

                return;
            }

            // Записываем информацию о контексте запроса, которую мы потом будет передавать
            // в actionCreator, обрабатывающий результат.
            let requestId = ++this.requestCounter;

            if (!ignoreResponse) {
                this.requestContextInfo[requestId] = new RequestContextInfoItem(contextInfo);
            }

            // Готовим запрос
            let requestObject = new RequestBaseDto(
                requestId.toString(10),
                methodName,
                paramsDto
            );

            this.connection.send(JSON.stringify(classToPlain(requestObject)));

            resolve();
        });
    }

    public async queryAsPromise<ResponseDtoType>(methodName: ApiMethodEnum, paramsDto: IBaseApiDto, resultType: ClassType<ResponseDtoType> | null): Promise<ResponseActionCreatorPayload<ResponseBaseDto<ResponseDtoType>, null>> {
        let deferredObject = new DeferredForAwaitCalls<ResponseDtoType>();

        // Внесли промис в список ожидания ответа
        let requestId = ++this.requestCounter;

        this.awaitCallsList[requestId] = {
            methodName: methodName,
            deferredObjectForResolve: deferredObject,
            resultDto: resultType,
            timerValue: 0,
            alreadyHandled: false
        };

        // Если мы в режиме имитации ошибок
        if (this.simulatedErrorCode !== null) {
            // Собираем объект ошибки и роутинг.
            let response: ResponseBaseDto<null> = new ResponseBaseDto<null>();

            response.id = requestId;
            response.params = null;
            response.method = methodName;
            response.status = this.simulatedErrorCode;
            response.errors = new ErrorsResponseData('Simulated error');

            this.handleEvent(JSON.stringify(classToPlain(response)), null).then();
        } else if (this.connection === null || this.connection.readyState !== SocketReadyStateEnum.OPEN) {
            // Если активного соединения нет - сразу вызываем обработчик результата ошибки.

            // Собираем объект ошибки и роутинг.
            let response: ResponseBaseDto<null> = new ResponseBaseDto<null>();

            response.id = requestId;
            response.params = null;
            response.method = methodName;
            response.status = WsResponseStatusEnum.CONNECTION_ERROR;
            response.errors = new ErrorsResponseData('Web socket connection unavailable.');

            this.handleEvent(JSON.stringify(classToPlain(response)), null).then();
        } else {
            // Готовим запрос
            let requestObject = new RequestBaseDto(
                requestId.toString(10),
                methodName,
                paramsDto
            );

            this.connection.send(JSON.stringify(classToPlain(requestObject)));
        }

        let restarted = false;

        if (this.awaitCallsListTimeoutTimer !== null) {
            clearInterval(this.awaitCallsListTimeoutTimer);

            restarted = true;
        }

        this.awaitCallsListTimeoutTimer = window.setInterval(
            this.queryAsPromiseTimeoutTimerOnTick.bind(this),
            1000
        );

        this.logger.info(
            LoggerSectionsEnum.WS_COMPONENT,
            'Promise query timeout timer ' + ((restarted) ? 'restarted' : 'started')
        );

        return deferredObject.getPromise();
    }

    /** @inheritDoc */
    public softReconnect() {
        if (
            this.connection
            && this.connection.readyState in [SocketReadyStateEnum.OPEN, SocketReadyStateEnum.CONNECTING]
        ) {
            return;
        }

        this.createConnection();
    }

    /**
     * {@inheritDoc}
     */
    public connectionIsOpen(): boolean {
        return ((this.connection !== null) && (this.connection.readyState === SocketReadyStateEnum.OPEN));
    }

    /**
     * {@inheritDoc}
     */
    startSimulateServerError(errorCode: WsResponseStatusEnum): void {
        this.logger.warning(
            LoggerSectionsEnum.WS_COMPONENT,
            `Server error with code ${errorCode} simulation started`
        );

        this.simulatedErrorCode = errorCode;
    }

    /**
     * {@inheritDoc}
     */
    stopSimulateServerError(): void {
        this.logger.warning(
            LoggerSectionsEnum.WS_COMPONENT,
            `Server error with code ${this.simulatedErrorCode} simulation stopped`
        );

        this.simulatedErrorCode = null;
    }

    protected onConnectionOpen() {
        // Имитируем получения ответа для запуска обработки в соответствии с роутами.
        let response: ResponseBaseDto<null> = new ResponseBaseDto();

        response.id = 0;
        response.params = null;
        response.status = WsResponseStatusEnum.OK;
        response.method = ConnectionEventsRoutesEnum.WS_CONNECTED;
        response.result = null;

        this.handleEvent(JSON.stringify(classToPlain(response))).then();
    }

    protected onConnectionError(error: Event) {
        let errorMessage = 'connectionError: ' + JSON.stringify(error);

        if ((window.navigator.onLine !== undefined) && (!window.navigator.onLine))
        {
            errorMessage = errorMessage.concat(' (no internet)');
        }

        this.logger.info(
            LoggerSectionsEnum.WS_COMPONENT,
            errorMessage
        );
    }

    protected onConnectionClose() {
        this.logger.info(LoggerSectionsEnum.WS_COMPONENT, 'Connection onClose event received');

        this.afterConnectionClosed();
    }

    protected onConnectionMessage(data: MessageEvent) {
        this.handleEvent(data.data).then();
    }

    /**
     * Действия после закрытия соединения
     *
     * @protected
     */
    protected afterConnectionClosed() {
        this.logger.info(LoggerSectionsEnum.WS_COMPONENT, 'Connection closed');

        // Имитируем получения ответа для запуска обработки в соответствии с роутами.
        let response: ResponseBaseDto<null> = new ResponseBaseDto();

        response.id = 0;
        response.params = null;
        response.status = WsResponseStatusEnum.OK;
        response.method = ConnectionEventsRoutesEnum.WS_DISCONNECTED;
        response.result = null;

        this.handleEvent(JSON.stringify(classToPlain(response))).then();
    }

    /**
     * Обработка события
     *
     * @param data
     * @param responseContext можно использовать в случае, если context не размещался в this.requestContextInfo
     *                        (например, если запрос не выполнялся, т.е. система работает в режиме имитации ошибки
     *                        или соединение разорвано)
     */
    protected async handleEvent(data: any, responseContext?: any) {
        try {
            // Валидируем основную часть сообщения.
            let dataObject: object = this.convertRawStringToObject(data);

            let responseClassObject: ResponseBaseDto<any> = await
                this.transformAndValidateRequestJsonObject(ResponseBaseDto, dataObject);

            let responseMethod = responseClassObject.method;

            // Ищем маршрут обработки

            // Проверяем - не соответствует ли данному запросу promise обработчик
            if (this.awaitCallsList[responseClassObject.id]) {
                await this.handleResponseByAwaitCallsList(
                    responseMethod,
                    responseClassObject
                );
            } else {
                await this.handleResponseByRoutesList(
                    responseMethod,
                    responseClassObject,
                    responseContext
                )
            }
        } catch (e: any) {
            this.logger.error(
                LoggerSectionsEnum.WS_COMPONENT,
                (e.message) ? e.message : e
            );
        }
    }

    /**
     * Обработать результат и разрезолвить промис, связанный с ним.
     *
     * @param responseMethod
     * @param responseClassObject
     * @protected
     */
    protected async handleResponseByAwaitCallsList(responseMethod: string, responseClassObject: ResponseBaseDto<any>): Promise<void> {
        const awaitCallData = this.awaitCallsList[responseClassObject.id];

        let responseHandlerResultParamsDto: any = null;

        // Читаем с DTO результата только если состояние ответа говорит об успехе выполнения запроса.
        if (responseClassObject.status === WsResponseStatusEnum.OK) {
            // Если метод подразумевает наличие параметров результата.
            if (awaitCallData.resultDto) {
                responseHandlerResultParamsDto = responseClassObject.result;

                try {
                    responseHandlerResultParamsDto = await
                        this.transformAndValidateRequestJsonObject(awaitCallData.resultDto, responseHandlerResultParamsDto);

                    responseClassObject.result = responseHandlerResultParamsDto;
                } catch (e) {
                    responseClassObject.status = WsResponseStatusEnum.VALIDATION_ERROR;
                    responseClassObject.errors = new ErrorsResponseData(
                        (e instanceof Error)
                            ? e.message
                            : JSON.stringify(e)
                    );

                    responseClassObject.result = null;

                    this.logger.error(LoggerSectionsEnum.WS_COMPONENT, e);
                }
            } else {
                responseClassObject.result = null;
            }
        } else {
            responseClassObject.result = null;
        }

        awaitCallData.deferredObjectForResolve.resolve(
            new ResponseActionCreatorPayload(
                responseClassObject,
                null
            )
        );

        this.awaitCallsList[responseClassObject.id].alreadyHandled = true;
    }

    /**
     * Обработать результат по списку роутов.
     *
     * @param responseMethod
     * @param responseClassObject
     * @param responseContext
     * @protected
     */
    protected async handleResponseByRoutesList(responseMethod: string, responseClassObject: ResponseBaseDto<any>, responseContext?: any): Promise<void> {
        // Ищем список роутов
        let route: RouteItem | null = this.responseRouter.getRouteByName(responseMethod);

        if (route === null) {
            this.logger.error(
                LoggerSectionsEnum.WS_COMPONENT,
                'Not found response route for route: "' + responseMethod + '"'
            );

            return;
        }

        let responseHandlerResultParamsDto: any = null;

        // Читаем с DTO результата только если состояние ответа говорит об успехе выполнения запроса.
        if (responseClassObject.status === WsResponseStatusEnum.OK) {
            // Если метод подразумевает наличие параметров результата.
            if (route.paramsDto) {
                responseHandlerResultParamsDto = responseClassObject.result;

                responseHandlerResultParamsDto = await
                    this.transformAndValidateRequestJsonObject(route.paramsDto, responseHandlerResultParamsDto);
            }
        }

        if (responseContext === undefined) {
            responseContext = null;

            if (responseClassObject.id) {
                let responseId =
                    (typeof responseClassObject.id === "string")
                        ? parseInt(responseClassObject.id)
                        : responseClassObject.id;

                if (this.requestContextInfo[responseId] !== undefined) {
                    responseContext = this.requestContextInfo[responseId].contextData;
                }
            }
        }

        responseClassObject.result = responseHandlerResultParamsDto;

        let responseActionCreatorPayload = new ResponseActionCreatorPayload(
            responseClassObject,
            responseContext
        );

        // Стартуем action, связанный с данным сообщением.
        this.dispatch(route.actionCreator(responseActionCreatorPayload));
    }

    protected queryAsPromiseTimeoutTimerOnTick() {
        this.logger.info(
            LoggerSectionsEnum.WS_COMPONENT,
            'Promise query timeout timer tick started'
        );

        let foundWaitItems = false;

        for (const itemKey in this.awaitCallsList) {
            if (this.awaitCallsList[itemKey].alreadyHandled) {
                continue;
            }

            this.awaitCallsList[itemKey].timerValue = this.awaitCallsList[itemKey].timerValue + 1;

            if (this.awaitCallsList[itemKey].timerValue <= WsServerConstants.QUERY_AS_PROMISE_TIMEOUT_SEC) {
                if (!foundWaitItems) {
                    foundWaitItems = true;
                }

                if (this.awaitCallsList[itemKey].timerValue === WsServerConstants.QUERY_AS_PROMISE_TIMEOUT_SEC) {
                    // Нужно вызвать для этого элемента ошибку TIMEOUT
                    let response: ResponseBaseDto<null> = new ResponseBaseDto<null>();

                    response.id = itemKey;
                    response.params = null;
                    response.method = this.awaitCallsList[itemKey].methodName;
                    response.status = WsResponseStatusEnum.CONNECTION_ERROR;
                    response.errors = new ErrorsResponseData('Request timeout error');

                    this.handleEvent(JSON.stringify(classToPlain(response)), null).then();

                    this.logger.info(
                        LoggerSectionsEnum.WS_COMPONENT,
                        'Promise query timeout timer rejected request ' + itemKey
                    );
                }
            }
        }

        if ((!foundWaitItems) && (this.awaitCallsListTimeoutTimer !== null)) {
            clearInterval(this.awaitCallsListTimeoutTimer);

            this.logger.info(
                LoggerSectionsEnum.WS_COMPONENT,
                'Promise query timeout timer stopped'
            );
        }
    }

    protected convertRawStringToObject(rawString: string): object {
        let object: object | undefined;

        try {
            object = JSON.parse(rawString);
        } catch (e) {
            throw new Error(
                'Error on handling string, received by web socket: ' + rawString
            );
        }

        return (object === undefined) ? {} : object;
    }

    /**
     * Валидация параметров ответа сервера в соответствии с моделью
     * и конвертация сырого объекта в DTO.
     *
     * @param classType
     * @param jsonRequestData
     */
    protected async transformAndValidateRequestJsonObject<T extends object>(
        classType: ClassType<T>,
        jsonRequestData: object,
    ): Promise<T> {
        try {
            return await ModelValidator
                .validateAndTransformRawRequestData(classType, jsonRequestData);
        } catch (e: any) {
            throw new Error(
                'Response validation error: ' + JSON.stringify(e.fieldValidationErrorsList)
                + ', object: ' + JSON.stringify(jsonRequestData)
            );
        }
    }
}
