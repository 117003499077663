import {Exception} from "../../HttpClient/exception/Exception";
import {ValidationErrorDetails} from "../ApiDto/Response/BaseResponseDto";

export class RequestValidationError extends Exception {
    public errorDetails: ValidationErrorDetails | null;
    public errorSummary: string;

    constructor(errorSummary: string, errorDetails: ValidationErrorDetails | null) {
        super(errorSummary);

        this.type = 'Validation error';

        this.errorSummary = errorSummary;
        this.errorDetails = errorDetails;
    }
}