import * as React from "react";
import styled from 'styled-components';

const NoticeWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.noticeBackground};
  border: 2px ${({theme}) => theme.colors.noticeBorderAccent};
  border-style: none none none solid;
  padding: 19px;
`;

interface NoticeProps {
    children: React.ReactNode;
    className?: string;
}

export const Notice: React.FC<NoticeProps> = (props) => {
    return <NoticeWrapper className={props.className}>{props.children}</NoticeWrapper>
}