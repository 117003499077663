import React, {useCallback, useMemo} from "react";
import {
    DtoGroupListItem
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupListItem";
import styled from "styled-components";
import {BlockAdditionalBullSymbol} from "../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {GroupScheduleAsText} from "../../../components/Ui/Elements/TextPresenter/GroupScheduleAsText";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {
    DtoGroupSchedule
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {ContentList, ContentListProps} from "../../../components/Ui/Elements/ContentList";
import {SubtitleItem} from "../../../components/Ui/Elements/ContentList/SubtitleItem";
import {ListItem} from "../../../components/Ui/Elements/ContentList/ListItem";

const DebtorInfoText = styled.span`
    display: inline-block;
    color: ${({theme}) => theme.colors.accentSecondary};
`;

interface GroupItemProps extends Omit<ContentListProps, 'children'> {
    items: DtoGroupListItem[];
}

export const GroupItemsList: React.FC<GroupItemProps> = (
    {
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange,
        items
    }
) => {
    const debtorsInfo = (debtorsCount: number) => {
        if (debtorsCount === 0) {
            return;
        }

        return <><BlockAdditionalBullSymbol/><DebtorInfoText>
            {
                (debtorsCount === 1)
                    ? <Trans>Есть должник</Trans>
                    : <Trans>Есть должники</Trans>
            }
        </DebtorInfoText></>
    }

    const linkToGroup = useCallback((groupId: number): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_GROUP_INFO,
            [
                {
                    key: 'groupId',
                    value: groupId.toString(10)
                }
            ]
        );
    }, []);

    const listItems = useMemo((): React.ReactNode[] => {
        let currentCourseId: number | null = null;
        let result: React.ReactNode[] = [];

        items.forEach(item => {
            if (currentCourseId !== item.courseId) {
                result.push(
                    <SubtitleItem key={`course${item.courseId}`}>{item.courseNominativeName}</SubtitleItem>
                );

                currentCourseId = item.courseId;
            }

            result.push(
                <ListItem
                    key={`group${item.id}`}
                    link={linkToGroup(item.id)}
                    firstLine={item.name}
                    secondLine={<>
                        {
                            (
                                ((item.weekSchedule) && (item.weekSchedule.weekDays))
                                && (item.weekSchedule.weekDays.trim() !== "")
                            )
                                ? <Trans><GroupScheduleAsText
                                    schedule={item.weekSchedule as DtoGroupSchedule}/></Trans>
                                : <Trans>Расписание не задано</Trans>
                        }
                        {
                            debtorsInfo(item.debtorsCount)
                        }
                    </>}
                />
            )
        })

        return result;
    }, [items, linkToGroup]);

    return <ContentList
        currentPageNum={currentPageNum}
        itemsPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={onPageChange}
    >{listItems}</ContentList>
}