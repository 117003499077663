import {notification} from 'antd';

export enum NotificationTypesEnum {
    SUCCESS,
    ERROR,
    INFO
}

export const openNotification = (type: NotificationTypesEnum, message: string, description: string) => {
    switch (type) {
        case NotificationTypesEnum.SUCCESS: {
            notification.success({
                message,
                description,
                placement: "topRight"
            });

            break;
        }
        case NotificationTypesEnum.INFO: {
            notification.info({
                message,
                description,
                placement: "topRight"
            });

            break;
        }
        case NotificationTypesEnum.ERROR: {
            notification.error({
                message,
                description,
                placement: "topRight"
            });

            break;
        }
        default: {
            notification.info({
                message,
                description
            });
        }
    }
};