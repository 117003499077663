import {Editor, Element as SlateElement, Node as SlateNode, NodeEntry, Transforms} from "slate";
import {ALLOWED_FOR_TEXT_ELEMENT} from "../Enums";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withText = (editor: Editor) => {
    const {normalizeNode} = editor

    editor.normalizeNode = (entry: NodeEntry<SlateNode>) => {
        const [node, path] = entry;

        if (SlateElement.isElement(node)) {
            if (ALLOWED_FOR_TEXT_ELEMENT.indexOf(node.type) < 0) {
                // Если такой элемент не найден в списке разрешённых
                //Transforms.unwrapNodes(editor, {at: path});
                Transforms.setNodes(editor, {type: ElementTypes.PARAGRAPH}, {at: path});
            }
        }

        normalizeNode(entry)
    }

    return editor
}