import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {EpisodeContext, IEpisodeContext} from "../EpisodeContext";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {ButtonStyleCss} from "./ButtonStyle";

const Wrapper = styled.div`
    ${ButtonStyleCss};
    
    border-radius: 6px;
    align-items: center;
    border: 2px solid ${({theme}) => theme.colors.accentDivider};
    color: ${({theme}) => theme.colors.textSecondary};
    font-weight: bold;
`;

const CountdownTimeWrapper = styled.span`
    display: inline-block;
    min-width: 59px;

    @media (${({theme}) => theme.media.small}) {
        min-width: 69px;
    }
`;

const CountdownDescriptionWrapper = styled.span`
    @media (${({theme}) => theme.media.small}) {
        display: none;
    }
`;

export const ButtonPlaceholder: React.FC = () => {
    const {
        episodeItem,
        thisIsNextAfterLastActive,
        prevEpisodeItem,
        secondsBeforeOpen
    } = useContext<IEpisodeContext>(EpisodeContext);

    const [openAfter, setOpenAfter] = useState<string>('');
    
    const countdownInterval = useRef<number|null>(null);

    useEffect(() => {
        if (thisIsNextAfterLastActive && prevEpisodeItem?.completedAt) {
            countdownInterval.current = setInterval(() => {
                const secondsCount = secondsBeforeOpen();
                setOpenAfter(new Date(secondsCount * 1000).toISOString().substring(11, 19));
            }, 1000) as unknown as number;
        }
        
        return () => {
            if (countdownInterval.current) {
                clearInterval(countdownInterval.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const label = useMemo<JSX.Element>(() => {
        if (episodeItem.completedAt !== null) {
            return <Trans>Сделано</Trans>;
        }

        if (thisIsNextAfterLastActive && prevEpisodeItem?.completedAt) {
            return <Trans><CountdownDescriptionWrapper>Через </CountdownDescriptionWrapper><CountdownTimeWrapper>{openAfter}</CountdownTimeWrapper></Trans>;
        }

        return <Trans>Скоро</Trans>;
    }, [episodeItem.completedAt, openAfter, prevEpisodeItem?.completedAt, thisIsNextAfterLastActive]);

    return <Wrapper>{label}</Wrapper>
}