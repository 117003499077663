import {StreamTypeEnum} from "../Types";

export const STREAM_IS_SCREEN_SUFFIX = 'screen';
export const USER_ID_SUFFIX_DELIMITER = '_';

export class PublisherMidDescriptionUtils {
    static createMid(userId: string, date: Date, streamType: StreamTypeEnum): string {
        return userId + USER_ID_SUFFIX_DELIMITER + date.getTime() + USER_ID_SUFFIX_DELIMITER + streamType;
    }

    static parseMidDescription(midDescription: string): { userId: string, ts: number, streamType: StreamTypeEnum } {
        const midSescriptionParts = midDescription.split(USER_ID_SUFFIX_DELIMITER);

        return {
            userId: midSescriptionParts[0],
            ts: parseInt(midSescriptionParts[1], 10),
            streamType: midSescriptionParts[2] as StreamTypeEnum
        }
    }
}
