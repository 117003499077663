/**
 * Класс значения параметра payload, передаваемого в actionCreator.
 */
export class ResponseActionCreatorPayload<ResponseObjectType, ContextType> {
    response: ResponseObjectType;
    responseContext: ContextType;

    constructor(response: ResponseObjectType, responseContext: ContextType) {
        this.response = response;
        this.responseContext = responseContext;
    }
}