export enum ScheduleFormFieldsEnum {
    MONDAY_ENABLED = 'monday_enabled',
    MONDAY_TIME_START = 'monday_time_start',
    MONDAY_TIME_END = 'monday_time_end',

    TUESDAY_ENABLED = 'tuesday_enabled',
    TUESDAY_TIME_START = 'tuesday_time_start',
    TUESDAY_TIME_END = 'tuesday_time_end',

    WEDNESDAY_ENABLED = 'wednesday_enabled',
    WEDNESDAY_TIME_START = 'wednesday_time_start',
    WEDNESDAY_TIME_END = 'wednesday_time_end',

    THURSDAY_ENABLED = 'thursday_enabled',
    THURSDAY_TIME_START = 'thursday_time_start',
    THURSDAY_TIME_END = 'thursday_time_end',

    FRIDAY_ENABLED = 'friday_enabled',
    FRIDAY_TIME_START = 'friday_time_start',
    FRIDAY_TIME_END = 'friday_time_end',

    SATURDAY_ENABLED = 'saturday_enabled',
    SATURDAY_TIME_START = 'saturday_time_start',
    SATURDAY_TIME_END = 'saturday_time_end',

    SUNDAY_ENABLED = 'sunday_enabled',
    SUNDAY_TIME_START = 'sunday_time_start',
    SUNDAY_TIME_END = 'sunday_time_end',
}