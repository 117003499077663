import React, {useCallback, useEffect, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {PageTitle} from "../../../../styles/global-elements";
import {PageLoadStatus} from "../common/Enums";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {ApplicationState} from "../../../../../store";
import {LocaleEnum} from "../../../../../store/i18n/type";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {NoticeBlock, NoticeBlockText} from "../../../../components/Ui/Elements/NoticeBlock";
import {useParams} from "react-router-dom";
import {DtoKbPage} from "../../../../../components/HttpApiClient/ApiDto/Response/KnowledgeBase/DtoKbPage";
import {DtoKbSection} from "../../../../../components/HttpApiClient/ApiDto/Response/KnowledgeBase/DtoKbSection";
import {ReactComponent as PageIcon} from "../../../../components/Ui/Svg/Page.svg";
import {Item, ItemLink, ItemLinkText, List,} from "../common/styles";
import {PageBackLink} from "../../../../components/Ui/Elements/PageBackLink";
import styled from "styled-components";


const PageIconStyled = styled(PageIcon)`
    min-width: 40px;
`;

export const PagesList: React.FC = () => {
    const {sectionId} = useParams();

    const [contentLoadState, setContentLoadState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [sectionDetails, setSectionDetails] = useState<DtoKbSection | null>(null);
    const [pages, setPages] = useState<DtoKbPage[]>([]);

    const sessionToken = useSelector(sessionTokenSelector);

    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;

    const loadContent = useCallback(() => {
        if ((!sessionToken) || (!sectionId)) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setContentLoadState(PageLoadStatus.LOADING);

        const sectionDetailsPromise = httpApiClient.kbGetSectionDetails(sessionToken, currentLocale, sectionId)
            .then((data) => {
                setSectionDetails(data.data);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.KNOWLEDGE_BASE_API, "Error loading data: ", err);
            });

        const pagesPromise = httpApiClient.kbGetPages(sessionToken, currentLocale, sectionId, 1, 20)
            .then((data) => {
                setPages(data.data.list);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.KNOWLEDGE_BASE_API, "Error loading data: ", err);
            });

        Promise.all([sectionDetailsPromise, pagesPromise])
            .then(() => {
                setContentLoadState(PageLoadStatus.SUCCESS);
            })
            .catch(() => {
                setContentLoadState(PageLoadStatus.ERROR);
            });
    }, [currentLocale, sectionId, sessionToken]);

    useEffect(() => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT: {
                loadContent();

                break;
            }
        }
    }, [contentLoadState, loadContent]);

    const linkToPage = useCallback((pageId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_KNOWLEDGE_BASE_PAGE,
            [
                {
                    key: 'pageId',
                    value: pageId
                }
            ]
        );
    }, []);

    const subtitle = () => {
        return <PageBackLink text={t`Все разделы справки`} link={RoutesList.TEACHER_KNOWLEDGE_BASE_MAIN}/>;
    }

    const content = () => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT:
            case PageLoadStatus.LOADING: {
                return <div>
                    <DefaultLoader/>
                </div>;
            }
            case PageLoadStatus.SUCCESS: {
                if (pages.length === 0) {
                    return <div>
                        {subtitle()}
                        {(sectionDetails) && <PageTitle>
                            <Trans>{sectionDetails.name}</Trans>
                        </PageTitle>}
                        <NoticeBlock>
                            <NoticeBlockText>
                                <Trans>Похоже, что страницы в этот раздел ещё не добавлены</Trans>
                            </NoticeBlockText>
                        </NoticeBlock>
                    </div>;
                }

                return <div>
                    {subtitle()}
                    {(sectionDetails) && <PageTitle>
                        <Trans>{sectionDetails.name}</Trans>
                    </PageTitle>}
                    <List>
                        {
                            pages.map((item) => {
                                return <Item key={item.id}>
                                    <ItemLink to={linkToPage(item.id)}>
                                        <PageIconStyled/><ItemLinkText>{item.title}</ItemLinkText>
                                    </ItemLink>
                                </Item>;
                            })
                        }
                    </List>
                </div>;
            }
            case PageLoadStatus.ERROR: {
                return <div><ErrorLoadingContent
                    title={t`Не удалось загрузить список страниц раздела`}
                    retryBtnClick={() => loadContent()}
                /></div>;
            }
        }
    }

    return content();
}
