import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PageTitle} from "../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {
    DtoGroupListItem
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupListItem";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {
    AllStudentGroupsListMode,
    StudentGroupsListModeEnum
} from "../../../../components/StonlineApiClient/Enums/StudentGroupsListModeEnum";
import {useSelector} from "react-redux";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {GroupItemsList} from "./GroupItemsList";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {debounce, DebouncedFunc} from "lodash";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {ChipsFilterArea} from "../../../components/Ui/Elements/ChipsFilterArea";
import {ChipsRadio, ChipsRadioItemType} from "../../../components/Ui/Elements/ChipsRadio";
import {ListModeLocaleName} from "./ListModeLocaleName";
import {PageLoadingStateEnum} from "../../../../enums/PageLoadingStateEnum";
import {stTokenSelector} from "../../../../store/app/selector";
import {EmptyListNotificationBox} from "./EmptyListNotificationBox";

const ITEMS_PER_PAGE = 30;

export const GroupsList: React.FC = () => {
    const stToken = useSelector(stTokenSelector);

    const isFirstRender = useRef<boolean>(true);
    const logger = useRef<ILogger>(container.resolve<ILogger>(DiTokens.LOGGER));
    const abortController = useRef<AbortController | null>(null);
    const stonlineApiClient = useRef<IStonlineApiClient>(
        container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT)
    );
    const dateHelperService = useRef<IDateHelperService>(
        container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE)
    );

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [listActiveMode, setListActiveMode] = useState<StudentGroupsListModeEnum>(StudentGroupsListModeEnum.ALL_ACTIVE_GROUPS);
    const [searchString, setSearchString] = useState<string>('');
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const [totalGroupsCount, setTotalGroupsCount] = useState<number | null>(null);
    const [groupsList, setGroupsList] = useState<DtoGroupListItem[] | null>(null);

    const fetchGroups = useCallback(async () => {
        if (!stToken) {
            return;
        }

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        if (abortController.current !== null) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        try {
            const result = await stonlineApiClient.current.getStudentGroupsList(
                stToken,
                listActiveMode,
                searchString,
                dateHelperService.current.formatAsSQLDate(new Date()),
                currentPageNum,
                ITEMS_PER_PAGE,
                abortController.current
            );

            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            setGroupsList(result.result.items);
            setTotalGroupsCount(result.result.totalCount);
        } catch (e) {
            logger.current.error(LoggerSectionsEnum.STONLINE_GROUPS_LIST_API, e);

            setPageLoadingState(PageLoadingStateEnum.ERROR);
        }
    }, [currentPageNum, listActiveMode, searchString, stToken]);

    const lastDebounced = useRef<DebouncedFunc<any> | null>(null);

    const fetchGroupsDebounced = useMemo<DebouncedFunc<any>>(() => {
        lastDebounced.current?.cancel();

        lastDebounced.current = debounce(fetchGroups, 500);

        return lastDebounced.current;
    }, [fetchGroups]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        fetchGroupsDebounced.cancel();

        fetchGroups().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listActiveMode, currentPageNum]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        abortController.current?.abort();
        abortController.current = null;

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        fetchGroupsDebounced();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(
        () => {
            isFirstRender.current = false;

            fetchGroups().then();

            return () => {
                lastDebounced.current?.cancel();

                if (abortController.current !== null) {
                    abortController.current.abort();
                    abortController.current = null;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    const radioItems = useMemo(() => {
        const variants = AllStudentGroupsListMode.map((item): ChipsRadioItemType => {
            return {
                key: item,
                label: <ListModeLocaleName listMode={item}/>
            }
        });

        return <ChipsRadio
            variants={variants}
            value={listActiveMode}
            onChange={(newValue) => {
                setListActiveMode(newValue as StudentGroupsListModeEnum)
            }}
        />
    }, [listActiveMode]);


    const content = useMemo(() => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.SUCCESS: {
                if ((groupsList === null) || (totalGroupsCount === null)) {
                    return <DefaultLoader/>;
                }

                if (groupsList.length === 0) {
                    return <EmptyListNotificationBox searchStringIsEmpty={searchString === ''}
                                                     listActiveMode={listActiveMode}/>;
                }

                return <GroupItemsList
                    totalItemsCount={totalGroupsCount}
                    onPageChange={(page: number) => setCurrentPageNum(page)}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPageNum={currentPageNum}
                    items={groupsList}
                />
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchGroups}/>
            }
        }
    }, [currentPageNum, fetchGroups, groupsList, listActiveMode, pageLoadingState, searchString, totalGroupsCount]);

    return <div>
        <PageTitle><Trans>Группы</Trans></PageTitle>
        <ChipsFilterArea
            placeholder={t`Поиск по названию группы...`}
            searchString={searchString}
            onChange={setSearchString}
            chipsRadioItems={radioItems}
        />
        {content}
    </div>;
}