import {IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";

/**
 * DTO базовой информации об ученике
 */
export class DtoStudentBase {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    longName: string;

    @IsString()
    email: string | null;

    @IsString()
    parentName: string;

    @IsString()
    smsPhone: string | null;

    @IsOptional()
    dateBorn: string;

    @IsNotEmpty()
    @IsString()
    isDebtor: string;
}
