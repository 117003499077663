import {DefaultTheme} from "styled-components";
import {ThemeEnum} from "../../services/theme/ThemeEnum";
import {ContentLayoutSettingsBackgroundModeEnum} from "../../enums/ContentLayoutSettingsBackgroundModeEnum";

export enum LayoutActionTypes {
    SET_SPLASH_SCREEN_VISIBLE = '@@layout/SET_SPLASH_SCREEN_VISIBLE',
    SET_RIGHT_PANEL_VISIBLE = '@@layout/SET_RIGHT_PANEL_VISIBLE',

    CHANGE_THEME = '@@layout/changeTheme',
    SET_ACTIVE_THEME_NAME = '@@layout/SET_ACTIVE_THEME_NAME',
    SET_ACTIVE_THEME = '@@layout/SET_ACTIVE_THEME',

    SET_BODY_SCROLL_AVAILABLE = '@@layout/SET_BODY_SCROLL_AVAILABLE',

    SET_CONTENT_LAYOUT_SETTINGS = '@@layout/SET_CONTENT_LAYOUT_SETTINGS'
}

export interface ContentLayoutSettings {
    themeName?: ThemeEnum,
    backgroundFileId?: string,
    backgroundMode?: ContentLayoutSettingsBackgroundModeEnum,
    hideLeftNavigation?: boolean,
    transparentHeader?: boolean
}

export interface LayoutState {
    splashScreenVisible: boolean;
    rightPanelVisible: boolean;
    activeTheme: DefaultTheme;
    activeThemeName: string;
    bodyScrollAvailable: boolean;
    contentLayoutSettings: ContentLayoutSettings | null
}
