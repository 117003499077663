import {IAppVersionHelper} from "./IAppVersionHelper";

export class AppVersionHelper implements IAppVersionHelper {
    protected appVersion: string;

    constructor(appVersion: string) {
        this.appVersion = appVersion;
    }

    /**
     * @inheritDoc
     */
    getAppVersion(): string {
        return this.appVersion;
    }
}
