export enum ToolbarButtonEnum {
    BOLD,
    ITALIC,
    UNDERLINED,
    LIST_NUMBERED,
    LIST_BULLETED,
    ALIGN_CENTER,
    ALIGN_JUSTIFY,
    ALIGN_LEFT,
    ALIGN_RIGHT,
    LIST_RADIO,
    LIST_CHECK,
    TRANSLATED
}