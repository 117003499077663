import React from "react";
import {Dispatch} from "redux";
import {rebootApp} from "../../../../../store/app/actions";
import {ApplicationState} from "../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {withRouter, WithRouterProps} from "../../../../components/WithRouterHoc";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {WsConnectionStatusEnum} from "../../../../../components/WsApiClient/WsConnectionStatusEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import styled from "styled-components";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {t, Trans} from "@lingui/macro";
import {IWsApiClient} from "../../../../../components/WsApiClient/IWsApiClient";
import {HomeworkPageContextProvider} from "./HomeworkPageContext";
import {isEqual} from "lodash";
import {PlayerSlideModel} from "../../../../components/SlidePlayer/PlayerSlideModel";
import {PlayerContextProvider, SlidesCachedContent} from "../../../../components/SlidePlayer/PlayerContext";
import {EditorData} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {MaterialsPlayer} from "./MetarialsPlayer";
import {
    loadLessonMaterialsList,
    resetLessonMaterialsState,
    setLoadingState,
    subscribeToSlideList,
    unsubscribeFromSlideList
} from "../../../../../store/lessonMaterials/actions";
import {LoadingStateEnum} from "../../../../../store/lessonMaterials/type";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../components/Ui/Elements/NoticeBlock";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../../../../enums/SlidePlayerIdEnum";
import {loadSlideWorkData} from "../../../../../store/slidesWorkData/actions";
import {LoadSlideWorkDataActionParams} from "../../../../../store/slidesWorkData/type";
import HomeworkSlidesList from "./SlidesList";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";

enum PageStatusEnum {
    NOT_INIT,
    READY,
    FATAL_ERROR
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageMainContentWrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
    max-width: 680px;
  }
`;

const PageLoaderWrapper = styled.div`
  width: 100%;
`;

interface RouterParams {
    lessonId: string;
}

interface LessonPageProps extends PropsFromRedux {
}

type HomeworkPagePropsWRouter = WithRouterProps<RouterParams> & LessonPageProps;

interface HomeworkPageState {
    pageStatus: PageStatusEnum;

    windowIsLarge: boolean;

    selectedSlideId: string | null;
    selectedSlideIndex: number | null;

    slidesContent: SlidesCachedContent;

    finishSlideIsAvailable: boolean;
}

class HomeworkPage extends React.Component<HomeworkPagePropsWRouter, HomeworkPageState> {
    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    protected mediaQueryLarge;

    constructor(props: Readonly<HomeworkPagePropsWRouter>) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);

        this.state = {
            pageStatus: PageStatusEnum.NOT_INIT,
            windowIsLarge: this.mediaQueryLarge.matches,
            selectedSlideId: null,
            selectedSlideIndex: null,
            slidesContent: {},
            finishSlideIsAvailable: false
        }
    }

    componentDidMount() {
        if (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED) {
            this.props.loadLessonMaterialsList(this.props.match.params.lessonId, false);
            this.props.subscribeToSlideList(this.props.match.params.lessonId);

            this.setState(() => {
                return {
                    pageStatus: PageStatusEnum.READY
                }
            })
            // Если подктлючение не готово, то просто ждём готовноси
        }

        if (this.checkFinishSlideAvailable()) {
            this.enableFinishSlide();
        } else {
            this.disableFinishSlide();
        }
    }

    componentDidUpdate(prevProps: Readonly<HomeworkPagePropsWRouter>, prevState: Readonly<HomeworkPageState>, snapshot?: any) {
        if (this.state.pageStatus === PageStatusEnum.NOT_INIT) {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если страница не инициализирована
                // и ws соединение только сейчас подготовилось (не было готово на этапе монтирования)
                this.props.loadLessonMaterialsList(this.props.match.params.lessonId, false);
                this.props.subscribeToSlideList(this.props.match.params.lessonId);

                this.setState(() => {
                    return {
                        pageStatus: PageStatusEnum.READY
                    }
                })
            }

            return;
        } else {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если соединение установлено после какой-то проблемы, когда страница уже прошла инициализацию
                this.props.loadLessonMaterialsList(this.props.match.params.lessonId, true);
                this.props.subscribeToSlideList(this.props.match.params.lessonId);
            }
        }

        let needUpdateFinishButton = false;

        // Если появились слайды
        if (this.props.lessonMaterialsSlideList.length > 0 && prevProps.lessonMaterialsSlideList.length === 0) {
            if (this.state.selectedSlideId === null) {
                this.setSelectedSlideId(this.props.lessonMaterialsSlideList[0].tmSlideId);
                this.startFetchSlidesWorkData();
            }
        } else if (this.props.lessonMaterialsSlideList.length === 0 && prevProps.lessonMaterialsSlideList.length > 0) {
            // Если исчезли слайды
            if (this.state.selectedSlideId !== null) {
                this.setSelectedSlideId(null);
            }
        } else if (!isEqual(this.props.lessonMaterialsSlideList, prevProps.lessonMaterialsSlideList)) {
            // Если как-то ещё обновился список слайдов
            this.startFetchSlidesWorkData();

            openNotification(
                NotificationTypesEnum.INFO,
                t`Учитель скорректировал задания`,
                t`Список заданий изменился`
            );

            // Проверим: есть ли ещё активный слайд в списке
            const slideFound = this.props.lessonMaterialsSlideList
                .some(item => item.tmSlideId === this.state.selectedSlideId);

            if (!slideFound) {
                this.setSelectedSlideId(this.props.lessonMaterialsSlideList[0].tmSlideId);
            }

            needUpdateFinishButton = true;
        } else if ((this.props.lessonMaterialsSlideList.length > 0) && (this.state.selectedSlideId === null)) {
            // Если слайды есть, но почему-то ни один не выбран
            this.setSelectedSlideId(this.props.lessonMaterialsSlideList[0].tmSlideId);
        }

        // Если изменился набор результатов работы ученика
        if (!isEqual(this.props.slidesWorkData, prevProps.slidesWorkData) || needUpdateFinishButton) {
            if (this.checkFinishSlideAvailable()) {
                this.enableFinishSlide();
            } else {
                this.disableFinishSlide();
            }
        }
    }

    componentWillUnmount() {
        this.props.unsubscribeFromSlideList(this.props.match.params.lessonId);
    }

    protected disableFinishSlide = (): void => {
        if (this.state.finishSlideIsAvailable) {
            this.setState(() => {
                return {
                    finishSlideIsAvailable: false
                }
            })
        }
    }

    protected enableFinishSlide = (): void => {
        if (!this.state.finishSlideIsAvailable) {
            this.setState(() => {
                return {
                    finishSlideIsAvailable: true
                }
            })
        }
    }

    protected checkFinishSlideAvailable = ():boolean => {
        if (this.props.lessonMaterialsSlideList.length === 0) {
            return false;
        }

        const workDataIndexedByPlayerId = this.props.slidesWorkData.indexByPlayerId[this.getPlayerId()];

        if (workDataIndexedByPlayerId === undefined) {
            return false;
        }

        const workDataIndexedBySlideId = workDataIndexedByPlayerId.indexBySlideId;

        // Получаем список материалов занятия
        for (let index = 0; index < this.props.lessonMaterialsSlideList.length; index++) {
            if (this.props.lessonMaterialsSlideList[index].exercisesCount === 0) {
                // Если на слайде нет упражнений
                continue;
            }

            const currentSlideId = this.props.lessonMaterialsSlideList[index].tmSlideId;

            if (workDataIndexedBySlideId[currentSlideId] === undefined) {
                return false;
            }

            const slideWorkDataItem = this.props.slidesWorkData.slides[workDataIndexedBySlideId[currentSlideId]];

            if (!slideWorkDataItem) {
                return false;
            }

            if (!slideWorkDataItem.slideCompleted) {
                return false;
            }
        }

        return true;
    }

    protected startFetchSlidesWorkData = () => {
        if ((this.props.match.params.lessonId === null) || (this.props.userId === null)) {
            return;
        }

        if (this.props.lessonMaterialsSlideList.length === 0) {
            return;
        }

        this.props.loadSlideWorkData({
            lessonId: this.props.match.params.lessonId,
            playerId: this.getPlayerId(),
            userId: this.props.userId,
            slideIds: this.props.lessonMaterialsSlideList.map(item => item.tmSlideId)
        });
    }

    protected getPlayerId = () => {
        return RoutesHelper.replaceParams(
            SlidePlayerIdEnum.STUDENT_SLIDE_ON_HOMEWORK,
            [
                {
                    key: 'lessonId',
                    value: this.props.match.params.lessonId ?? ""
                }
            ]
        );
    }

    protected setSlideContent = (slideId: string, data: EditorData) => {
        const value = this.state.slidesContent;

        value[slideId] = data;

        this.setState(() => {
            return {
                slidesContent: value
            }
        })
    }

    protected setSelectedSlideId = (slideId: string | null) => {
        if ((slideId === null) || (this.props.lessonMaterialsSlideList === null)) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        const selectedSlideIndex = this.props.lessonMaterialsSlideList.findIndex((item) => item.tmSlideId === slideId);

        if (selectedSlideIndex < 0) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        this.setState(() => {
            return {
                selectedSlideId: slideId,
                selectedSlideIndex: selectedSlideIndex
            }
        });
    }

    protected content = () => {
        if (this.state.pageStatus === PageStatusEnum.FATAL_ERROR) {
            return <ErrorLoadingContent
                retryBtnClick={() => {
                    this.props.rebootApp()
                }}
                title={t`Возникла проблема`}
                message={t`Возникла проблема с подключением. Попробуйте повторить попытку, перезагрузив страницу.`}
            />
        }

        if (this.state.pageStatus !== PageStatusEnum.READY) {
            return <PageLoaderWrapper><DefaultLoader/></PageLoaderWrapper>;
        }

        return <>
            <PageMainContentWrapper>
                {
                    (this.props.lessonMaterialsSlideListReadyForShow && this.props.lessonMaterialsSlideList.length > 0)
                    && <>
                        <MaterialsPlayer/>
                    </>
                }
                {
                    (this.props.lessonMaterialsSlideListReadyForShow && this.props.lessonMaterialsSlideList.length === 0)
                    && <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Материала для домашней работы пока нет</Trans></NoticeBlockTitle>
                            <NoticeBlockText><Trans>Учитель пока не добавил материалы домашней
                                работы</Trans></NoticeBlockText>
                        </>
                    </NoticeBlock>
                }
                {
                    (this.props.lessonMaterialsLoadingState === LoadingStateEnum.ERROR)
                    && <ErrorLoadingContent retryBtnClick={() => {
                        if (this.props.match.params.lessonId) {
                            this.props.loadLessonMaterialsList(this.props.match.params.lessonId, false);
                        } else {
                            this.props.rebootApp();
                        }
                    }}/>
                }
                {
                    (this.props.lessonMaterialsLoadingState === LoadingStateEnum.INIT_LOADING)
                    && <PageLoaderWrapper><DefaultLoader/></PageLoaderWrapper>
                }
            </PageMainContentWrapper>
            <HomeworkSlidesList/>
        </>;
    }

    render() {
        return <div>
            <HomeworkPageContextProvider value={{
                lessonId: this.props.match.params.lessonId,
                finishSlideIsAvailable: this.state.finishSlideIsAvailable
            }}>
                <PlayerContextProvider value={{
                    lessonId: this.props.match.params.lessonId ?? "",
                    playerId: this.getPlayerId(),
                    slides: (this.props.lessonMaterialsSlideListReadyForShow)
                        ? this.props.lessonMaterialsSlideList
                        : null,
                    slidesContent: this.state.slidesContent,
                    setSlidesContent: this.setSlideContent,
                    selectedSlideId: this.state.selectedSlideId,
                    setSelectedSlideId: this.setSelectedSlideId,
                    selectedSlide: (this.state.selectedSlideIndex !== null && this.props.lessonMaterialsSlideListReadyForShow)
                        ? (this.props.lessonMaterialsSlideList[this.state.selectedSlideIndex] ?? null)
                        : null,
                    selectedSlideIndex: this.state.selectedSlideIndex,
                    setInteractivityConfig: null
                }}>
                    <PageWrapper>
                        {this.content()}
                    </PageWrapper>
                </PlayerContextProvider>
            </HomeworkPageContextProvider>
        </div>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    rebootApp: () =>
        dispatch(rebootApp()),
    loadLessonMaterialsList: (lessonId: string, silent: boolean) => {
        if (!silent) {
            dispatch(setLoadingState(LoadingStateEnum.INIT_LOADING));
        }

        dispatch(loadLessonMaterialsList(lessonId, true, silent));
    },
    resetLessonMaterialsState: () => {
        dispatch(resetLessonMaterialsState())
    },
    loadSlideWorkData: (params: LoadSlideWorkDataActionParams) => {
        dispatch(loadSlideWorkData(params))
    },
    subscribeToSlideList: (lessonId: string) => {
        dispatch(subscribeToSlideList(lessonId));
    },
    unsubscribeFromSlideList: (lessonId: string) => {
        dispatch(unsubscribeFromSlideList(lessonId))
    }
});

const mapStateToProps = ({user, app, layout, lessonMaterials, slidesWorkData}: ApplicationState) => ({
    userId: (user.profileData?.id) ?? null,
    stToken: user.stToken,
    apiToken: user.sessionToken,
    wsStatus: app.wsConnectionStatus,
    currentTheme: layout.activeTheme,
    lessonMaterialsLoadingState: lessonMaterials.loadingState,
    lessonMaterialsSlideList: lessonMaterials.slidesList.map((item): PlayerSlideModel => {
        return new PlayerSlideModel(
            item.tmId,
            item.tmName,
            item.contentVersion,
            item.exerciseCount,
            !item.hiddenForStudent,
            item.orderPosition
        );
    }),
    slidesWorkData: slidesWorkData,
    lessonMaterialsSlideListReadyForShow:
        (lessonMaterials.loadingState === LoadingStateEnum.SUCCESS)
        || (lessonMaterials.loadingState === LoadingStateEnum.SILENT_LOADING)
        || (lessonMaterials.loadingState === LoadingStateEnum.SILENT_ERROR)
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(HomeworkPage));
