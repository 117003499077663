import {EditorDataItem} from "../../SlidePlayerEditorCommonParts/EditorData";
import {EditorDataDtoMapper} from "../../SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {instanceToPlain, plainToInstance} from "class-transformer";
import {DtoTmSlideContent} from "../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContent";

const VERIFY_TOKEN_SECRET = 'hBK&IhAVIU&^d7978A#';

export class ExerciseCopyToBuffer {
    public static async prepareToPaste(elementAsText: string): Promise<EditorDataItem<any>> {
        const plainWrapperObject = JSON.parse(elementAsText);

        const integrityCheckTokenSource = plainWrapperObject.integrityCheck;
        const plainObject = plainWrapperObject.data;

        if (!integrityCheckTokenSource || !plainObject) {
            throw new Error('Read data error');
        }

        const plainObjectAsText = JSON.stringify(plainObject);

        const integrityCheckTokenTarget = await ExerciseCopyToBuffer.hashFunction(plainObjectAsText + VERIFY_TOKEN_SECRET);

        if (integrityCheckTokenSource !== integrityCheckTokenTarget) {
            throw new Error('Integrity check error');
        }

        const dtoData = EditorDataDtoMapper.dtoToEditorData(
            plainToInstance(
                DtoTmSlideContent,
                {
                    items: [plainObject],
                    layoutSettings: null
                }
            )
        );

        return dtoData.items[0];
    }

    public static async prepareToCopy(element: EditorDataItem<any>): Promise<string> {
        const dtoData = EditorDataDtoMapper.editorDataToDto({items: [element]});

        const plain = instanceToPlain(dtoData.items[0]);
        const plainText = JSON.stringify(plain);

        const integrityCheckToken = await ExerciseCopyToBuffer.hashFunction(plainText + VERIFY_TOKEN_SECRET);

        return JSON.stringify({
            data: plain,
            integrityCheck: integrityCheckToken
        });
    }

    protected static async hashFunction(data: any) {
        const encoder = new TextEncoder();
        const dataBuffer = encoder.encode(data);
        const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    }
}