import React from "react";
import {ReactComponent as RadioCheckedSvg} from "../../../components/Ui/Svg/RadioChecked.svg";
import {ReactComponent as RadioUncheckedSvg} from "../../../components/Ui/Svg/RadioUnchecked.svg";
import styled from "styled-components";

const RadioCheckedStyled = styled(RadioCheckedSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
`;

const RadioUncheckedStyled = styled(RadioUncheckedSvg)`
  color: ${({theme}) => theme.colors.textTertiary};
`;

export interface RadioIconProps {
    checked: boolean;
    className?: string;
    onClick?: () => void;
}

export const RadioIcon: React.FC<RadioIconProps> = (props) => {
    if (props.checked) {
        return <RadioCheckedStyled className={props.className} onClick={props.onClick}/>
    }

    return <RadioUncheckedStyled className={props.className} onClick={props.onClick}/>;
}