import {call, put, select, takeEvery} from 'redux-saga/effects';
import {AuthUserRequestContext, UserActionTypes} from "../../store/user/type";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApplicationState} from "../../store";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {Reconnection} from "../../components/WsApiClient/ApiDto/Request/Auth/Reconnection";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import * as AppActionCreators from "../../store/app/actions";
import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";

/**
 * Сага запускает процесс повторной авторизации пользователя по токену переподключения.
 */

export function* watchStartUserReconnectionAuthOnWsConnection() {
    yield takeEvery(
        UserActionTypes.START_USER_RECONNECTION_AUTH_ON_WS_CONNECTION,
        startUserReconnectionAuthOnWsConnection
    );
}

const getUserReconnectionToken = (state: ApplicationState) => state.user.wsReconnectionToken;

function* startUserReconnectionAuthOnWsConnection() {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    let wsApiClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    let userReconnectionToken = (yield select(getUserReconnectionToken)) as string | null;

    yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.UNAUTHORIZED));

    if (userReconnectionToken === null) {
        // Почему-то мы попали на переавторизацию без токена переподключения
        logger.error(
            LoggerSectionsEnum.WS_COMPONENT,
            'Ws reconnection activated, but reconnection token token is empty'
        );

        yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.DISCONNECTED));

        // Разрываем соединение, чтобы оно снова запустилось
        wsApiClient.closeConnection();

        return;
    }

    yield call(async () => {
            if (userReconnectionToken === null) {
                return;
            }

            await wsApiClient.query(
                ApiMethodEnum.AUTH_BY_RECONNECTION_TOKEN,
                new Reconnection(userReconnectionToken),
                new AuthUserRequestContext(true)
            )
        }
    );
}
