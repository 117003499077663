import {IsNotEmpty, IsString} from 'class-validator';

/**
 * DTO результата создания/обновления self-study трека.
 */
export class DtoSelfStudyTrackCreateResponse {
    @IsNotEmpty()
    @IsString()
    id: string;
}
