import styled from "styled-components";
import {PageSubtitle2} from "../../../styles/global-elements";
import React from "react";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {ApplicationState} from "../../../../store";
import {withRouter} from "../../WithRouterHoc";
import {connect} from "react-redux";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {DtoTmDiscipline} from "../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {DtoTmLevel} from "../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {DtoTmSection} from "../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {ModalResultType as SectionModalResultType, TmSectionModal} from "../modals/section-modal";
import {SectionsList} from "./SectionsList";
import {ModalResultType as LevelModalResultType} from "../modals/level-modal";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../Ui/Elements/Notification";
import {t, Trans} from "@lingui/macro";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../Ui/Elements/NoticeBlock";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";

const LEVEL_COUNT_PER_PAGE = 30;

const Wrapper = styled.div``;

const BtnWrapper = styled.div`
  margin-bottom: 20px;
  display: block;
  //text-align: right;
`;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

enum LoadingState {
    NOT_INIT,
    LOADING_OVERVIEW,
    OVERVIEW_LOADING_ERROR,
    LOADING_SECTIONS,
    SUCCESS,
    SECTIONS_ERROR
}

interface LevelDetailsSectionProps {
    levelId: string;
    apiToken?: string | null;
    onSelectSection?: (dtoTmSection: DtoTmSection) => void;
    selectMode?: boolean;
    usedSectionIds?: string[];
}

interface LevelDetailsSectionState {
    loadingState: LoadingState;
    disciplineItem: DtoTmDiscipline | null;
    levelItem: DtoTmLevel | null;
    levelSections: DtoTmSection[];
    totalSectionsCount: number | null;
    savingSectionsOrderNow: boolean;
}

export class TmLevelDetailsSection extends React.Component<LevelDetailsSectionProps, LevelDetailsSectionState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    constructor(props: Readonly<LevelDetailsSectionProps> | LevelDetailsSectionProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            disciplineItem: null,
            levelItem: null,
            levelSections: [],
            totalSectionsCount: null,
            savingSectionsOrderNow: false
        }
    }

    componentDidMount() {
        this.fetchOverview().then();
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }
    }

    resetSectionsList = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_SECTIONS,
                levelSections: [],
                totalSectionsCount: null
            }
        }, () => {
            this.fetchSections().then()
        });
    }

    protected fetchOverview = async () => {
        if ((this.props.apiToken === undefined) || (this.props.apiToken === null)) {
            return;
        }

        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_OVERVIEW,
                levelItem: null,
                disciplineItem: null,
                levelSections: [],
                totalSectionsCount: null
            }
        });

        try {
            const result = await this.apiClient.tmGetLevelOverview(
                this.props.apiToken,
                this.props.levelId,
                LEVEL_COUNT_PER_PAGE,
                this.abortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    levelItem: result.data.levelItem,
                    disciplineItem: result.data.disciplineItem,
                    levelSections: result.data.sectionsList,
                    totalSectionsCount: result.data.totalSectionsCount
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.OVERVIEW_LOADING_ERROR
                }
            });

            if (e instanceof CanceledByUser) {
                return;
            }

            const errorMessage = 'Error loading level overview: ';

            if (e instanceof NoConnection) {
                this.logger.info(LoggerSectionsEnum.TM_DISCIPLINE_LEVELS_API, errorMessage, e);
            } else {
                this.logger.error(LoggerSectionsEnum.TM_DISCIPLINE_LEVELS_API, errorMessage, e);
            }
        }
    }

    protected fetchSections = async () => {
        if ((this.props.apiToken === undefined) || (this.props.apiToken === null)) {
            return;
        }

        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_SECTIONS
            }
        });

        try {
            const result = await this.apiClient.tmGetSections(
                this.props.apiToken,
                this.props.levelId,
                (this.state.levelSections.length === 0)
                    ? 1
                    : (Math.round(this.state.levelSections.length / LEVEL_COUNT_PER_PAGE) + 1),
                LEVEL_COUNT_PER_PAGE,
                this.abortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    levelSections: [...this.state.levelSections, ...result.data.list],
                    totalSectionsCount: result.data.totalCount
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.SECTIONS_ERROR
                }
            });

            this.logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, "Error loading sections: ", e);
        }
    }

    protected sectionModalOnResult = (action: SectionModalResultType, editedItem: (DtoTmSection | null)) => {
        switch (action) {
            case LevelModalResultType.CREATED:
            case LevelModalResultType.DELETED: {
                this.resetSectionsList();

                return;
            }
            case LevelModalResultType.UPDATED: {
                if (editedItem === null) {
                    return;
                }

                this.setState((state) => {
                    return {
                        levelSections: state.levelSections.map((item) => {
                            if (item.id === editedItem.id) {
                                return editedItem;
                            }

                            return item;
                        })
                    }
                })
            }
        }
    }

    protected reorderSectionItems = (from: number, to: number) => {
        if (!this.props.apiToken) {
            return;
        }

        const firstItemId = this.state.levelSections[from].id;
        const secondItemId = this.state.levelSections[to].id;

        const newLevelsArray = arrayMove(this.state.levelSections, from, to);

        this.setState(() => {
            return {
                levelSections: newLevelsArray,
                savingSectionsOrderNow: true
            }
        });

        this.apiClient.tmSwapSectionOrderPosition(this.props.apiToken, firstItemId, secondItemId)
            .catch((err) => {
                this.logger.error(
                    LoggerSectionsEnum.TM_LEVEL_SECTIONS_API,
                    'Error on reorder sections list: ',
                    err
                );

                this.setState(() => {
                    return {
                        levelSections: arrayMove(newLevelsArray, to, from),
                        savingSectionsOrderNow: false
                    }
                });

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок разделов. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                this.setState(() => {
                    return {
                        savingSectionsOrderNow: false
                    }
                });
            });
    }

    protected loadMoreButton = () => {
        return <BtnWrapper>
            <Button btnStyle={BtnStyleEnum.Secondary} onClick={this.fetchSections}>Показать ещё</Button>
        </BtnWrapper>;
    }

    protected addSectionButton = () => {
        if (this.props.selectMode === true) {
            return;
        }

        return <BtnWrapper>
            <TmSectionModal
                levelId={this.props.levelId}
                sectionItem={null}
                result={this.resetSectionsList}
                trigger={
                    <Button btnStyle={
                        (this.state.levelSections.length > 0)
                            ? BtnStyleEnum.Secondary
                            : BtnStyleEnum.Primary
                    }><Trans>Добавить раздел</Trans></Button>
                }/>
        </BtnWrapper>;
    }

    protected emptyListMessage = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle><Trans>Список разделов пуст</Trans></NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>Раздел, это название главы уровня. Например: «Натуральные числа».</Trans><br/>
                    <Trans>А в целом каталог учебных материалов делится на несколько
                        уровней: <br/> Дисциплина &gt; Уровень &gt; Раздел уровня &gt; Урок</Trans><br/><br/>

                    {
                        (this.props.selectMode === true)
                        && <Trans>Перейдите в «Учебные материалы», чтобы создать раздел</Trans>
                    }
                    {
                        (this.props.selectMode !== true)
                        && <Trans>Создать раздел можно, нажав по кнопке «Добавить раздел»</Trans>
                    }
                </NoticeBlockText>
            </>
        </NoticeBlock>
    }

    render() {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING_OVERVIEW: {
                return <Wrapper><DefaultLoader/></Wrapper>;
            }
            case LoadingState.OVERVIEW_LOADING_ERROR: {
                return <Wrapper><ErrorLoadingContent retryBtnClick={this.fetchOverview}/></Wrapper>;
            }
            case LoadingState.LOADING_SECTIONS:
            case LoadingState.SECTIONS_ERROR:
            case LoadingState.SUCCESS: {
                if (this.state.disciplineItem === null) {
                    throw new Error("Try render contend with disciplineItem === null");
                }

                if (this.state.levelItem === null) {
                    throw new Error("Try render contend with levelItem === null");
                }

                if (this.state.loadingState === LoadingState.SUCCESS && this.state.levelSections.length === 0) {
                    return <Wrapper>
                        <Title>{this.state.disciplineItem.name}, {this.state.levelItem.name}</Title>
                        {this.emptyListMessage()}<br/>
                        {this.addSectionButton()}
                    </Wrapper>
                }

                return <Wrapper>
                    <Title>{this.state.disciplineItem.name}, {this.state.levelItem.name}</Title>
                    {
                        this.state.levelSections.length > 0
                        && <SectionsList sectionItems={this.state.levelSections}
                                         reorderItems={(this.props.selectMode === true) ? undefined : this.reorderSectionItems}
                                         selectMode={this.props.selectMode}
                                         usedSectionIds={this.props.usedSectionIds}
                                         levelId={this.state.levelItem.id}
                                         navigateToSectionMethod={this.props.onSelectSection}
                                         itemsDisabled={this.state.savingSectionsOrderNow}
                                         modalOnResult={this.sectionModalOnResult}/>
                    }
                    {
                        (this.state.loadingState === LoadingState.LOADING_SECTIONS)
                        && <DefaultLoader/>
                    }
                    {
                        (this.state.loadingState === LoadingState.SECTIONS_ERROR)
                        && <ErrorLoadingContent retryBtnClick={this.fetchSections}/>
                    }
                    {(
                        (this.state.totalSectionsCount !== null)
                        && (this.state.loadingState === LoadingState.SUCCESS)
                        && (this.state.levelSections.length > 0)
                        && (this.state.levelSections.length < this.state.totalSectionsCount)
                    ) && this.loadMoreButton()}
                    {(this.state.loadingState === LoadingState.SUCCESS) && this.addSectionButton()}
                </Wrapper>;
            }
            default: {
                throw new Error("Unknown loadingState for TmLevelSectionsList");
            }
        }
    }
}


const mapStateToProps = ({user}: ApplicationState) => ({
    apiToken: user.sessionToken,
});

export default withRouter(connect(mapStateToProps)(TmLevelDetailsSection));
