import styled from "styled-components";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {Button} from "../../../../components/Ui/Elements/Button";

export const Subtitle = styled.h3`
    margin-bottom: 0;
`;

export const NextButtonWrapper = styled.div`
    margin-top: 20px;
    min-width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const MainPageLinkAsButtonStyled = styled(LinkAsButton)`
    min-width: 50%;

    @media (${({theme}) => theme.media.small}) {
        min-width: unset;
    }
`;

export const ButtonStyled = styled(Button)`
    min-width: 50%;

    @media (${({theme}) => theme.media.small}) {
        min-width: unset;
    }
`;