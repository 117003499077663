export enum RoutesList {
    // Auth
    AUTH_LAYOUT = '/auth',
    LOGIN_FORM = '/auth',
    LOGIN_VIA_TOKEN = '/auth/via-token/:token',
    SIGN_UP_FORM = '/auth/sign-up',
    RESTORE_FORM = '/auth/restore',
    RESTORE_VIA_TOKEN = '/auth/restore-via-token/:token',
    LOAD_PROFILE_ERR = '/auth/load-profile-error',
    TEACHER_LOGIN_FORM = '/auth/teacher',
    TEACHER_LOGIN_VIA_TOKEN = '/auth/teacher/via-token/:token',
    STUDENT_DEMO = '/auth/student-demo',

    // Страница подписки на self-study трек
    SELF_STUDY_SUBSCRIBE = '/sst-start/:trackId',

    // Common
    PROFILE_SETTINGS = '/profile-settings',
    VIDEO_CALL_TEST = '/video-call-test',

    // Student
    MAIN_PAGE = '/',
    STUDENT_AREA = '/student',
    SCHEDULE_PAGE = '/student/schedule',
    PERFORMANCE_PAGE = '/student/performance',
    STUDENT_PAYMENTS_PAGE = '/student/payments',
    ABOUT_SCHOOL_PAGE = '/student/about-school',
    ONLINE_PAYMENT_SUCCESS_URL = '/?payment-id={publicOrderId}', // Используется для передачи роута на сервер
    STUDENT_LESSON_PAGE = '/student/lesson/:lessonId',
    STUDENT_HOMEWORK_PAGE = '/student/homework/:lessonId',
    STUDENT_HOMEWORK_FINISH_PAGE = '/student/homework/summary/:lessonId',
    STUDENT_SELF_STUDY_TRACK_EPISODE = '/student/sst/ep/:episodeId',
    STUDENT_SELF_STUDY_TRACK_EPISODE_END = '/student/sst/ep/:episodeId/end',

    // Teacher
    TEACHER_MAIN_PAGE = '/',
    TEACHER_AREA = '/teacher',
    TEACHER_SETUP = '/teacher/setup/:stepId',

    TEACHER_MODERATOR = '/teacher/moderator',

    TEACHER_ALL_STUDENTS = '/teacher/students',
    TEACHER_CREATE_STUDENT = '/teacher/student/new/:stepId',
    TEACHER_STUDENT_NEW_AGREEMENT = '/teacher/student/:studentId/new-agreement/:stepId',
    TEACHER_STUDENT_PROFILE = '/teacher/student/:studentId/profile/:sectionId',

    TEACHER_ALL_GROUPS = '/teacher/groups',
    TEACHER_GROUP_INFO = '/teacher/group/:groupId',
    TEACHER_LESSON_PAGE = '/teacher/group/:groupId/lesson/:lessonId',

    TEACHER_TEACHING_MATERIALS = '/teacher/teaching-materials',
    TEACHER_TEACHING_MATERIALS_OLD = '/teacher/teaching-materials-old',
    TEACHER_TEACHING_MATERIALS_LEVEL_DETAILS = '/teacher/teaching-materials/level/:tmLevelId',
    TEACHER_TEACHING_MATERIALS_SECTION_DETAILS = '/teacher/teaching-materials/level/:tmLevelId/section/:tmSectionId',

    TEACHER_LESSON_EDITOR = '/teacher/lesson-editor/:lessonId',
    TEACHER_LESSON_PLAYER = '/teacher/lesson-play/:lessonId',
    TEACHER_SLIDE_PREVIEW = '/teacher/slide-preview/:slideId',
    TEACHER_SLIDE_PREVIEW_W_VERSION = '/teacher/slide-preview/:slideId/:contentVersionId',

    TEACHER_KNOWLEDGE_BASE_MAIN = '/teacher/kb',
    TEACHER_KNOWLEDGE_BASE_SECTION = '/teacher/kb/section/:sectionId',
    TEACHER_KNOWLEDGE_BASE_PAGE = '/teacher/kb/page/:pageId',

    TEACHER_SELF_STUDY_TRACKS = '/teacher/self-study-tracks',
    TEACHER_SELF_STUDY_TRACK_VIEW = '/teacher/self-study-track/:trackId',
    TEACHER_SELF_STUDY_TRACK_EDIT = '/teacher/self-study-track/:trackId/edit',
    TEACHER_SELF_STUDY_TRACK_CREATE = '/teacher/self-study-track/new',

    TEACHER_ABOUT_WIN_APP = '/teacher/win-app'
}
