import React, {useCallback, useContext} from "react";
import {IAudioRoomStudentListActionsContextProvider} from "./AudioRoomStudentListActionsContext";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../common/RoomCommonActionsContext";
import {StreamStatusEnum} from "../../Types";
import {AudioRoomParticipantItem} from "../../../../../components/JanusClient/AudioBridge/Types";

export const AudioRoomStudentListActions: React.FC = ({children}) => {
    const commonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);

    const setJoinedAudioStream = useCallback(
        (studentId: AudioRoomParticipantItem[]) => {
            commonActionsContext.setParticipantsListItemUpdate(
                studentId.map(item => {
                    return {
                        id: item.userId,
                        audioStatus: StreamStatusEnum.CONNECTED,
                        talkingNow: item.talking,
                        audioMuted: item.muted
                    }
                })
            );
        },
        [commonActionsContext]
    );

    const setLeaveAudioStream = useCallback(
        (studentId: string[]) => {
            commonActionsContext.setParticipantsListItemUpdate(
                studentId.map(item => {
                    return {
                        id: item,
                        audioStatus: StreamStatusEnum.NOT_AVAILABLE
                    }
                })
            );
        },
        [commonActionsContext]
    );

    return <IAudioRoomStudentListActionsContextProvider value={{
        setJoinedAudioStreams: setJoinedAudioStream,
        setLeaveAudioStreams: setLeaveAudioStream,
    }}>
        {children}
    </IAudioRoomStudentListActionsContextProvider>
}
