import React from 'react';


export enum ShowMode {
    INPUT,
    ERROR,
    CORRECT,
    MUTED
}

export type VariantState = {[variantId: string]: ShowMode};

export interface IExerciseRadioContext {
    variantOnClick: (variantId: string) => void;
    variantState: VariantState;
    showErrorAnimationInVariantId: string | null;
    setShowErrorAnimationInVariantId: (newValue: string | null) => void;
}

export const ExerciseRadioContext = React.createContext<IExerciseRadioContext>({
    variantOnClick: () => {},
    variantState: {},
    showErrorAnimationInVariantId: null,
    setShowErrorAnimationInVariantId: () => {}
});

export const ExerciseRadioContextProvider = ExerciseRadioContext.Provider;