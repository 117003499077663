import React, {useMemo} from 'react';
import {ReactComponent as AlignCenterSvg} from "./icons/AlignCenter.svg";
import {ReactComponent as AlignJustifySvg} from "./icons/AlignJustify.svg";
import {ReactComponent as AlignLeftSvg} from "./icons/AlignLeft.svg";
import {ReactComponent as AlignRightSvg} from "./icons/AlignRight.svg";
import {ReactComponent as BoldSvg} from "./icons/Bold.svg";
import {ReactComponent as ItalicSvg} from "./icons/Italic.svg";
import {ReactComponent as ListBulletedSvg} from "./icons/ListBulleted.svg";
import {ReactComponent as ListNumberedSvg} from "./icons/ListNumbered.svg";
import {ReactComponent as UnderlinedSvg} from "./icons/Underlined.svg";
import {ReactComponent as ListRadioSvg} from "./icons/ListRadio.svg";
import {ReactComponent as ListCheckSvg} from "./icons/ListCheck.svg";
import {ReactComponent as TranslatedSvg} from "./icons/Translated.svg";
import {t} from "@lingui/macro";

import {ToolbarButtonEnum} from "./ToolbarButtonEnum";

interface ToolbarIconProps {
    iconType: ToolbarButtonEnum;
}

export const ToolbarIcon: React.FC<ToolbarIconProps> = (props) => {
    const {iconType} = props;

    const elementIcon = useMemo(() => {
        switch (iconType) {
            case ToolbarButtonEnum.ALIGN_CENTER: {
                return <AlignCenterSvg title={t`Выровнять по центру`}/>;
            }
            case ToolbarButtonEnum.ALIGN_JUSTIFY: {
                return <AlignJustifySvg title={t`Выровнять по ширине`}/>;
            }
            case ToolbarButtonEnum.ALIGN_LEFT: {
                return <AlignLeftSvg title={t`Выровнять по левому краю`}/>;
            }
            case ToolbarButtonEnum.ALIGN_RIGHT: {
                return <AlignRightSvg title={t`Выровнять по правому краю`}/>;
            }
            case ToolbarButtonEnum.BOLD: {
                return <BoldSvg title={t`Полужирный шрифт (Ctrl+B)`}/>;
            }
            case ToolbarButtonEnum.ITALIC: {
                return <ItalicSvg title={t`Курсив (Ctrl+I)`}/>;
            }
            case ToolbarButtonEnum.LIST_BULLETED: {
                return <ListBulletedSvg title={t`Маркерный список`}/>;
            }
            case ToolbarButtonEnum.LIST_NUMBERED: {
                return <ListNumberedSvg title={t`Нумерованный список`}/>;
            }
            case ToolbarButtonEnum.UNDERLINED: {
                return <UnderlinedSvg title={t`Подчёркнутый (Ctrl+U)`}/>;
            }
            case ToolbarButtonEnum.TRANSLATED: {
                return <TranslatedSvg title={t`Добавить/удалить перевод слова/фразы (Ctrl+Q)`}/>;
            }
            case ToolbarButtonEnum.LIST_RADIO: {
                return <ListRadioSvg title={t`Упражнение «Выбор одного из вариантов»`}/>;
            }
            case ToolbarButtonEnum.LIST_CHECK: {
                return <ListCheckSvg title={t`Упражнение «Выбор нескольких из списка»`}/>;
            }
            default: {
                return <div>?</div>;
            }
        }
    }, [iconType]);

    return <>{elementIcon}</>;
}