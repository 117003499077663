import React, {useCallback, useEffect, useMemo, useState} from "react";
import {PageLoadingStateEnum} from "../../../../../enums/PageLoadingStateEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {useParams} from "react-router-dom";
import {NoConnection} from "../../../../../components/HttpApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ApplicationState} from "../../../../../store";
import {LocaleEnum} from "../../../../../store/i18n/type";
import {
    DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview";
import {EnergyInfo} from "./EnergyInfo";
import {EpisodeSurvey} from "./EpisodeSurvey";

enum ModeEnum {
    DEFAULT_NOTHING,
    SURVEY,
    ENERGY_RESULT
}

export const EpisodeEnd: React.FC = () => {
    const sessionToken = useSelector(sessionTokenSelector);
    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;

    const {episodeId} = useParams<{ episodeId: string }>();

    const [loadingState, setLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [overviewData, setOverviewData] = useState<DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview | null>(null);

    const [currentMode, setCurrentMode] = useState<ModeEnum>(ModeEnum.DEFAULT_NOTHING);

    const fetchOverviewData = useCallback(async () => {
        if (!sessionToken || !episodeId) {
            setLoadingState(PageLoadingStateEnum.ERROR);

            return;
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        try {
            const receivedOverviewData = await httpApiClient.selfStudySubscriptionOnEpisodeCompleteOverview(
                sessionToken,
                episodeId,
                currentLocale
            );

            setOverviewData(receivedOverviewData.data);

            setCurrentMode(
                (receivedOverviewData.data.episodeSurvey)
                    ? ModeEnum.SURVEY : ModeEnum.ENERGY_RESULT
            );

            setLoadingState(PageLoadingStateEnum.SUCCESS);
        } catch (e) {
            setLoadingState(PageLoadingStateEnum.ERROR);

            if (e instanceof NoConnection) {
                return;
            }

            logger.error(
                LoggerSectionsEnum.SELF_STUDY_TRACK_API,
                `Error on fetch subscription overview by episode id ${episodeId}: `,
                e
            );
        }
    }, [currentLocale, episodeId, sessionToken]);

    useEffect(() => {
        fetchOverviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const bodyElement = useMemo(() => {
        switch (currentMode) {
            case ModeEnum.SURVEY: {
                if (overviewData && overviewData.episodeSurvey && episodeId) {
                    return <EpisodeSurvey
                        episodeId={episodeId}
                        surveyData={overviewData.episodeSurvey}
                        subscriptionOverview={overviewData.subscriptionOverview}
                        goNext={() => setCurrentMode(ModeEnum.ENERGY_RESULT)}
                    />;
                }

                break;
            }
            case ModeEnum.ENERGY_RESULT: {
                if (overviewData) {
                    return <EnergyInfo subscriptionOverview={overviewData?.subscriptionOverview}/>;
                }

                break;
            }
        }

        return <div/>;
    }, [currentMode, episodeId, overviewData]);

    const content = () => {
        switch (loadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchOverviewData}/>
            }
            default: {
                if (!overviewData) {
                    return <DefaultLoader/>;
                }

                return bodyElement;
            }
        }
    }

    return content()
}