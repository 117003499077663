import {StreamTypeEnum} from "../Types";
import {ILogger} from "../../Logger/ILogger";
import {LoggerSectionsEnum} from "../../Logger/LoggerSectionsEnum";
import {cloneDeep} from "lodash";

export enum ItemState {
    AVAILABLE,
    PREPARING,
    READY
}

export const OWN_FEED_ID = 'ownFeedId';

export type Item = {
    userId: string;
    feedId: string | null;
    joinTs: number;
    type: StreamTypeEnum;
    trackId: string | null;
    mid: string | null;
    state: ItemState;
    track: MediaStreamTrack | null;
    stream: MediaStream | null;
}

type SubscriberItemHandler = (oldValue: Item | null, newValue: Item | null, diff?: Partial<Item>) => void;

type SubscriberItem = {
    subscriberId: string;
    handler: SubscriberItemHandler;
}

export class StreamsStore {
    protected logger: ILogger;

    protected items: Item[];

    protected subscribers: SubscriberItem[];

    constructor(logger: ILogger) {
        this.logger = logger;

        this.items = [];
        this.subscribers = [];

        // @ts-ignore
        window['streamStore'] = this.getAll.bind(this);
    }

    public destroy() {
        this.items = [];
        this.subscribers = [];
    }

    public subscribe(subscriberId: string, handler: SubscriberItemHandler) {
        const currentItem = this.subscribers.find(item => item.subscriberId === subscriberId);

        if (currentItem) {
            this.logger.warning(
                LoggerSectionsEnum.VIDEO_ROOM_STREAMS_STORE,
                `Subscriber with id ${subscriberId} already exist`
            );

            return;
        }

        this.subscribers.push({
            subscriberId: subscriberId,
            handler: handler
        });

        this.logger.info(
            LoggerSectionsEnum.VIDEO_ROOM_STREAMS_STORE,
            `Registered subscriber with id ${subscriberId}`
        );
    }

    public unsubscribe(subscriberId: string) {
        const currentItemIndex = this.subscribers.findIndex(item => item.subscriberId === subscriberId);

        if (currentItemIndex === -1) {
            this.logger.warning(
                LoggerSectionsEnum.VIDEO_ROOM_STREAMS_STORE,
                `Subscriber with id ${subscriberId} not found`
            );

            return;
        }

        this.subscribers.splice(currentItemIndex);

        this.logger.info(
            LoggerSectionsEnum.VIDEO_ROOM_STREAMS_STORE,
            `Unregistered subscriber with id ${subscriberId}`
        );
    }

    public addItem(item: Item) {
        this.items.push(item);

        this.fireSubscribers(null, item);
    }

    public findByUserIdTrackId(userId: string, trackId: string): Item | null {
        return this.items.find(item => {
            return (item.userId === userId)
                && (item.trackId === trackId);
        }) ?? null;
    }

    public findByUserIdAndType(userId: string, type: StreamTypeEnum): Item | null {
        return this.items.find(item => {
            return (item.userId === userId)
                && (item.type === type);
        }) ?? null;
    }

    public findAllByUserId(userId: string): Item[] {
        const result: Item[] = [];

        this.items.forEach(item => {
            if (item.userId === userId) {
                result.push(item);
            }
        });

        return result;
    }

    public findAllByFeedId(feedId: string): Item[] {
        const result: Item[] = [];

        this.items.forEach(item => {
            if (item.feedId === feedId) {
                result.push(item);
            }
        });

        return result;
    }

    public updateItem(userId: string, feedId: string, type: StreamTypeEnum, mid: string | null, update: Partial<Item>): void {
        const itemIndex = this.findIndex(userId, feedId, type, mid ?? undefined);

        if (itemIndex === null) {
            return;
        }

        const oldItem = cloneDeep(this.items[itemIndex]);

        this.items[itemIndex] = {
            ...this.items[itemIndex],
            ...update
        }

       this.fireSubscribers(oldItem, this.items[itemIndex], update);
    }

    public deleteItem(userId: string, feedId: string | null, type: StreamTypeEnum, mid?: string): void {
        const itemIndex = this.findIndex(userId, feedId, type, mid);

        if (itemIndex === null) {
            return;
        }

        const oldItem = cloneDeep(this.items[itemIndex]);

        this.items.splice(itemIndex, 1);

        this.fireSubscribers(oldItem, null);
    }

    /**
     * Получить поток из хранилища потоков по Mid.
     * Mid уникален в контексте подключения. А т.к. у publisher и subscriber разные подключения - mid между ними
     * могут совпадать. Поэтому при поиске мы всегда игнорируем feedId OWN_FEED_ID.
     *
     * @param mid
     */
    public findByMid(mid: string): Item[] {
        const result: Item[] = [];

        this.items.forEach(item => {
            if (item.feedId === OWN_FEED_ID) {
                return;
            }

            if (item.mid === mid) {
                result.push({...item});
            }
        });

        return result;
    }

    public getAll(): Item[] {
        return this.items
    }

    protected fireSubscribers(oldValue: Item | null, newValue: Item | null, diff?: Partial<Item>) {
        this.logger.info(
            LoggerSectionsEnum.STREAM_STORE,
            'Old value: ', oldValue,
            'New value: ', newValue,
            'Diff: ', diff
        );

        this.subscribers.forEach(item => {
            try {
                item.handler(oldValue, newValue, diff);
            } catch (e) {
                this.logger.error(
                    LoggerSectionsEnum.VIDEO_ROOM_STREAMS_STORE,
                    `Subscriber ${item.subscriberId} call error: `,
                    e
                );
            }
        });
    }

    protected findIndex(userId: string, feedId: string | null, type: StreamTypeEnum, mid?: string): number | null {
        const itemIndex = this.items.findIndex(item => {
            if (
                (item.userId === userId)
                && (item.type === type)
            ) {
                if (feedId !== null && item.feedId !== feedId) {
                    return false;
                }

                if ((mid !== undefined) && (item.mid !== mid)) {
                    return false;
                }

                return true;
            }

            return false;
        });

        return itemIndex > -1 ? itemIndex : null;
    }
}
