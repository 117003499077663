export enum SelfStudyTrackCoverMaskTypeEnum {
    SQUARE = 'square',
    CIRCLE = 'circle',
    STAR = 'star',
    STAR2 = 'star2',
    POLYGON = 'polygon'
}

export const allMasks = [
    SelfStudyTrackCoverMaskTypeEnum.SQUARE,
    SelfStudyTrackCoverMaskTypeEnum.CIRCLE,
    SelfStudyTrackCoverMaskTypeEnum.STAR,
    SelfStudyTrackCoverMaskTypeEnum.STAR2,
    SelfStudyTrackCoverMaskTypeEnum.POLYGON,
]