import React from "react";
import {
    DEFAULT_WIDTH_PX,
    ElementData,
    ElementStateEnum,
    ImageAlignVariants
} from "../../../SlidePlayerEditorCommonParts/components/Image/Common";
import {UploadProcessDetails} from "../../../../../store/uploadQueue/type";

export interface IElementContext {
    elementData: ElementData;
    elementState: ElementStateEnum;
    currentFileInUploadQueue: UploadProcessDetails | null,
    onChangeElementData: (newValue: ElementData) => void;
    setElementState: React.Dispatch<React.SetStateAction<ElementStateEnum>>;
}

export const ElementContext = React.createContext<IElementContext>({
    elementData: {
        imageId: null,
        widthInPercent: DEFAULT_WIDTH_PX,
        align: ImageAlignVariants.CENTER,
        heightInPx: null,
        widthInPx: null
    },
    elementState: ElementStateEnum.WAIT_INIT,
    currentFileInUploadQueue: null,
    onChangeElementData: () => {
    },
    setElementState: () => {
    }
});

export const ElementContextProvider = ElementContext.Provider;