import {forwardRef, useImperativeHandle} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {RichTextReader} from "../../RichTextReader";
import {
    IBaseElement
} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";

const BulletedListStyled = styled.ul`
  margin-bottom: 0;
`;

export const BulletedList = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IBaseElement>>(
    (props, ref) => {

        const {element, ...otherProps} = props;

        useImperativeHandle(ref, () => ({}));

        return <BulletedListStyled>
            <RichTextReader elements={element.children}  {...otherProps}/>
        </BulletedListStyled>;
    }
);

BulletedList.displayName = 'BulletedList';