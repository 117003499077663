import {cloneDeep} from "lodash";
import {StreamTypeEnum} from "../../../components/JanusClient/Types";

export interface ConferenceConnectionParams {
    turnServerEntrypoint: string;
    turnServerTlsEntrypoint: string;
    turnServerLogin: string;
    turnServerPassword: string;
    janusWsApiEntrypoint: string;
    janusApiKey: string;
    janusRoomId: string;
    janusJoinKey: string;
    expectedNumberOfStudents: number;
}

export enum ConferenceModeEnum {
    NORMAL,
    FULL_SCREEN,
    DOCUMENT_IN_PICTURE,
    FLOAT_ON_PAGE
}

export enum ConnectionStateEnum {
    NOT_INIT,
    CONNECTING,
    CONNECTED,
    CONNECTION_ERROR,
    CLOSED
}

export enum StreamStatusEnum {
    NOT_AVAILABLE,
    AVAILABLE,
    CONNECTING,
    CONNECTED,
    ERROR,
}

export type ConnectionQualityParams = {
    status: ConnectionStateEnum;
    badConnectionSignal: Date | null;
    totalLossesPacked: number;
}

export const connectionQualityParamsInitialState: ConnectionQualityParams = {
    status: ConnectionStateEnum.NOT_INIT,
    badConnectionSignal: null,
    totalLossesPacked: 0
}

export type VideoRoomConnectionState = {
    publisherConnection: ConnectionQualityParams;
    subscriberConnection: ConnectionQualityParams;
    cameraMuted: boolean;
    myVideoIsAvailable: boolean;
}

export const videoRoomConnectionInitState: VideoRoomConnectionState = {
    publisherConnection: cloneDeep(connectionQualityParamsInitialState),
    subscriberConnection: cloneDeep(connectionQualityParamsInitialState),
    cameraMuted: false,
    myVideoIsAvailable: false
}

export type AudioRoomConnectionState = {
    connection: ConnectionQualityParams;
    streamIsReady: boolean;
    myMicMuted: boolean;
}

export const audioRoomConnectionInitState: AudioRoomConnectionState = {
    connection: cloneDeep(connectionQualityParamsInitialState),
    streamIsReady: false,
    myMicMuted: false,
}

export type ParticipantItem = {
    id: string;
    talkingNow: boolean;
    audioMuted: boolean;
    audioStatus: StreamStatusEnum;
    videoStatus: StreamStatusEnum;
    screenVideo: StreamStatusEnum;
    screenAudio: StreamStatusEnum;
}

export const studentItemInitialValue: ParticipantItem = {
    id: '',
    talkingNow: false,
    audioMuted: false,
    audioStatus: StreamStatusEnum.NOT_AVAILABLE,
    videoStatus: StreamStatusEnum.NOT_AVAILABLE,
    screenVideo: StreamStatusEnum.NOT_AVAILABLE,
    screenAudio: StreamStatusEnum.NOT_AVAILABLE,
}

export type SubscriptionRequestItem = {
    userId: string;
    streamType: StreamTypeEnum;
}
