import React, {useContext} from "react";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {t} from "@lingui/macro";
import {CommonContext, ICommonContext} from "./CommonContext";

const ButtonStyled = styled(Button)`
    width: 100%;

    &[disabled] {
        cursor: default;
    }
`;

interface NextButtonProps {
    label: string;
}

export const NextButton: React.FC<NextButtonProps> = ({label}) => {
    const {
        goNext,
        nextButtonDisabled
    } = useContext<ICommonContext>(CommonContext);

    return <ButtonStyled
        btnStyle={BtnStyleEnum.Primary}
        disabled={nextButtonDisabled}
        onClick={goNext}>
        {label}
    </ButtonStyled>
}