import {IsNotEmpty, ValidateNested} from 'class-validator';
import {DtoSlideItemBase} from "../Common/DtoSlideItemBase";
import {Type} from "class-transformer";
import {DtoTmSlideContent} from "../TmSlide/DtoTmSlideContent";

/**
 * DTO элемента списка используемых материалов self-study трека
 */
export class DtoSelfStudyTrackSlideItem extends DtoSlideItemBase {
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoTmSlideContent)
    content: DtoTmSlideContent;
}
