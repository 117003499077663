import React, {useCallback, useContext, useEffect, useRef} from "react";
import {CommonContext, ICommonContext} from "../../contexts/CommonContext";
import {ConferenceModeEnum} from "../../Types";
import {
    DtoFullScreenState
} from "../../../../../components/WsApiClient/ApiDto/Request/LessonRoom/PublicState/DtoFullScreenState";
import {container} from "tsyringe";
import {IWsApiClient} from "../../../../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ApiMethodEnum} from "../../../../../components/WsApiClient/ApiMethodEnum";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";

export const FullScreenPublisher: React.FC = ({children}) => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const lastPublishState = useRef<boolean | null>(null);

    const publicInfoToWs = useCallback((value: boolean) => {
        const dto = new DtoFullScreenState();
        dto.lessonId = commonContext.lessonId;
        dto.inFullScreen = value;

        const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        wsClient.query(
            ApiMethodEnum.LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN,
            dto,
            null,
            true
        )
            .catch((error) => {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.warning(
                    LoggerSectionsEnum.LESSON_ROOM,
                    `Error on publish current full screen mode`,
                    error
                );
            });
    }, [commonContext.lessonId]);

    useEffect(() => {
        const newValue = (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN);

        if (lastPublishState.current !== newValue) {
            publicInfoToWs(newValue);
            lastPublishState.current = newValue;
        }
    }, [commonContext.currentMode, publicInfoToWs]);

    return <>{children}</>;
}
