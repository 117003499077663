import {action} from "typesafe-actions";

import {CommonPersistedActionTypes} from "./type";
import {LocaleEnum} from "../i18n/type";
import {ThemeEnum} from "../../services/theme/ThemeEnum";
import {UserProfileType} from "../user/type";

export const setProfileType = (profileType: UserProfileType) => {
    return action(CommonPersistedActionTypes.SET_PROFILE_TYPE, profileType);
};

export const setAuthToken = (token: string) => {
    return action(CommonPersistedActionTypes.SET_AUTH_TOKEN, token);
};

export const setLocale = (localeName: LocaleEnum) => {
    return action(CommonPersistedActionTypes.SET_LOCALE, localeName);
};

export const setThemeName = (name: ThemeEnum) => {
    return action(CommonPersistedActionTypes.SET_THEME_NAME, name);
};

export const setAllowedToUseCameraEarly = (value: boolean) => {
    return action(CommonPersistedActionTypes.SET_ALLOWED_TO_USER_CAMERA_EARLY, value);
};

export const setLastAudioInputDeviceId = (value: string) => {
    return action(CommonPersistedActionTypes.SET_LAST_AUDIO_INPUT_DEVICE_ID, value);
};

export const setLastAudioOutputDeviceId = (value: string) => {
    return action(CommonPersistedActionTypes.SET_LAST_AUDIO_OUTPUT_DEVICE_ID, value);
};

export const setLastVideoInputDeviceId = (value: string) => {
    return action(CommonPersistedActionTypes.SET_LAST_VIDEO_INPUT_DEVICE_ID, value);
};

export const setOtpEmail = (value: string | null) => {
    return action(CommonPersistedActionTypes.SET_OTP_EMAIL, value);
};

export const setUtmForSave = (value: { [id: string]: string } | null) => {
    return action(CommonPersistedActionTypes.SET_UTM_FOR_SAVE, value);
};

export const clearAllData = () => {
    return action(CommonPersistedActionTypes.CLEAR_ALL_DATA);
}
