import {call, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {
    ExerciseWorkData,
    LoadSlideWorkDataActionParams,
    SlideItemWorkData,
    SlidesWorkDataState,
    SlideWorkDataActionTypes,
    SlideWorkDataLoadingStateEnum
} from "../../store/slidesWorkData/type";
import {
    setSlideTotalValues,
    setSlideWorkDataEmptyObject,
    setSlideWorkDataItemsList,
    setSlideWorkDataLoadingState
} from "../../store/slidesWorkData/actions";
import {ApplicationState} from "../../store";
import {container} from "tsyringe";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../di-factory/DiTokens";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {
    DtoGetStudentInLessonSlidesWorkDataRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetStudentInLessonSlidesWorkDataRequest";
import {
    DtoGetStudentInLessonSlidesWorkDataResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetStudentInLessonSlidesWorkDataResponse";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";
import {initialSlideItemWorkData} from "../../store/slidesWorkData/initialState";
import {cloneDeep} from "lodash";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {SlidePlayerIdEnum} from "../../enums/SlidePlayerIdEnum";
import {
    DtoGetStudentHomeworkSlidesWorkDataRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetStudentHomeworkSlidesWorkDataRequest";
import {
    DtoGetStudentHomeworkSlidesWorkDataResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetStudentHomeworkSlidesWorkDataResponse";
import {
    DtoTeacherGetStudentHomeworkSlidesWorkDataRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoTeacherGetStudentHomeworkSlidesWorkDataRequest";
import {
    DtoTeacherGetStudentHomeworkSlidesWorkDataResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoTeacherGetStudentHomeworkSlidesWorkDataResponse";
import {
    DtoTeacherGetStudentLessonWorkSlidesWorkDataResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoTeacherGetStudentLessonWorkSlidesWorkDataResponse";
import {
    DtoTeacherGetStudentLessonWorkSlidesWorkDataRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoTeacherGetStudentLessonWorkSlidesWorkDataRequest";
import {ISlidePlayerPatternHelper} from "../../services/slide-player-id-pattern-helper/ISlidePlayerPatternHelper";
import {
    DtoGetSelfStudyTrackSlidesWorkDataResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetSelfStudyTrackSlidesWorkDataResponse";
import {
    DtoGetSelfStudyTrackSlidesWorkDataRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoGetSelfStudyTrackSlidesWorkDataRequest";

export function* watchLoadSlidesWorkData() {
    yield takeEvery<WorkerPayloadType<LoadSlideWorkDataActionParams>>(
        SlideWorkDataActionTypes.LOAD_SLIDES_WORK_DATA,
        loadSlidesWorkData
    );
}

const currentSlidesWorkDataState = ({slidesWorkData}: ApplicationState) => slidesWorkData;

function* loadSlidesWorkData(data: WorkerPayloadType<LoadSlideWorkDataActionParams>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    if (data.payload.slideIds.length === 0) {
        return;
    }

    const slidePlayerPatternHelper = container.resolve<ISlidePlayerPatternHelper>(DiTokens.SLIDE_PLAYER_PATTERN_HELPER);
    const playerIdMatchPatternResultData = slidePlayerPatternHelper.findPlayerIdPattern(data.payload.playerId);

    if (playerIdMatchPatternResultData === null) {
        logger.error(
            LoggerSectionsEnum.SLIDES_WORK_DATA_LOAD_QUEUE,
            'Unknown player id pattern: ', data.payload.playerId
        );

        return;
    }

    // Создадим пустые объекты со статусом "загружается" (но элементам со статусом "загружено" статус менять не будем)
    let currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;

    let playerIndex = currentSlidesWorkData.indexByPlayerId[data.payload.playerId];

    if (playerIndex === undefined) {
        yield putResolve(setSlideWorkDataEmptyObject({
            playerId: data.payload.playerId,
            slideId: data.payload.slideIds[0],
            value: null
        }));

        currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;
        playerIndex = currentSlidesWorkData.indexByPlayerId[data.payload.playerId];
    }

    const slidesCount = data.payload.slideIds.length;

    for (let itemIndex = 0; itemIndex < slidesCount; itemIndex++) {
        const slideIndex = playerIndex.indexBySlideId[data.payload.slideIds[itemIndex]];

        if ((slideIndex === undefined) || (currentSlidesWorkData.slides[slideIndex] === undefined)) {
            yield putResolve(setSlideWorkDataEmptyObject({
                playerId: data.payload.playerId,
                slideId: data.payload.slideIds[itemIndex],
                value: null
            }));

            yield putResolve(setSlideWorkDataLoadingState({
                playerId: data.payload.playerId,
                slideId: data.payload.slideIds[itemIndex],
                value: SlideWorkDataLoadingStateEnum.LOADING
            }));
        } else if (currentSlidesWorkData.slides[slideIndex].loadState !== SlideWorkDataLoadingStateEnum.SUCCESS) {
            yield put(setSlideWorkDataLoadingState({
                playerId: data.payload.playerId,
                slideId: data.payload.slideIds[itemIndex],
                value: SlideWorkDataLoadingStateEnum.LOADING
            }));
        }
    }

    const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    // Выполняем запрос на получение данных по этим слайдам
    const pattern = playerIdMatchPatternResultData.pattern;

    let workDataResult: ResponseActionCreatorPayload<ResponseBaseDto<DtoGetStudentInLessonSlidesWorkDataResponse>, null>
        | ResponseActionCreatorPayload<ResponseBaseDto<DtoGetStudentHomeworkSlidesWorkDataResponse>, null>;

    switch (pattern) {
        case SlidePlayerIdEnum.STUDENT_SLIDE_ON_LESSON: {
            const requestDto = new DtoGetStudentInLessonSlidesWorkDataRequest();

            requestDto.studentId = data.payload.userId;
            requestDto.lessonId = data.payload.lessonId;
            requestDto.slideIds = data.payload.slideIds;

            workDataResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_GET_STUDENT_IN_LESSON_SLIDES_DATA,
                    requestDto,
                    DtoGetStudentInLessonSlidesWorkDataResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoGetStudentInLessonSlidesWorkDataResponse>, null>;

            break;
        }

        case SlidePlayerIdEnum.STUDENT_SLIDE_ON_HOMEWORK: {
            const requestDto = new DtoGetStudentHomeworkSlidesWorkDataRequest();

            requestDto.studentId = data.payload.userId;
            requestDto.lessonId = data.payload.lessonId;
            requestDto.slideIds = data.payload.slideIds;

            workDataResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_GET_STUDENT_HOMEWORK_SLIDES_DATA,
                    requestDto,
                    DtoGetStudentHomeworkSlidesWorkDataResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoGetStudentHomeworkSlidesWorkDataResponse>, null>;

            break;
        }
        case SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_LESSON: {
            const requestDto = new DtoTeacherGetStudentLessonWorkSlidesWorkDataRequest();

            requestDto.studentId = data.payload.userId;
            requestDto.lessonId = data.payload.lessonId;
            requestDto.slideIds = data.payload.slideIds;

            workDataResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_TEACHER_GET_STUDENT_LESSON_WORK_SLIDES_DATA,
                    requestDto,
                    DtoTeacherGetStudentLessonWorkSlidesWorkDataResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoTeacherGetStudentLessonWorkSlidesWorkDataResponse>, null>;

            break;
        }
        case SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_HOMEWORK: {
            const requestDto = new DtoTeacherGetStudentHomeworkSlidesWorkDataRequest();

            requestDto.studentId = data.payload.userId;
            requestDto.lessonId = data.payload.lessonId;
            requestDto.slideIds = data.payload.slideIds;

            workDataResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_TEACHER_GET_STUDENT_HOMEWORK_SLIDES_DATA,
                    requestDto,
                    DtoTeacherGetStudentHomeworkSlidesWorkDataResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoTeacherGetStudentHomeworkSlidesWorkDataResponse>, null>;

            break;
        }
        case SlidePlayerIdEnum.SELF_STUDY_TRACK_EPISODE: {
            const requestDto = new DtoGetSelfStudyTrackSlidesWorkDataRequest();

            requestDto.episodeId = data.payload.lessonId;

            workDataResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_GET_SELF_STUDY_TRACK_SLIDES_DATA,
                    requestDto,
                    DtoGetSelfStudyTrackSlidesWorkDataResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoGetSelfStudyTrackSlidesWorkDataResponse>, null>;

            break;
        }
        default: {
            logger.error(LoggerSectionsEnum.SLIDES_WORK_DATA_LOAD_QUEUE, 'Unknown player id pattern: ', pattern);

            return;
        }
    }

    currentSlidesWorkData = (yield select(currentSlidesWorkDataState)) as SlidesWorkDataState;
    playerIndex = currentSlidesWorkData.indexByPlayerId[data.payload.playerId];

    if (playerIndex === undefined) {
        // Почему-то всё очистилось. Видимо уже результат не актуален.
        return;
    }

    if (workDataResult.response.status !== WsResponseStatusEnum.OK) {
        // Установим всем, кто не находится в состоянии "загружено" состояние "ошибка"

        for (let itemIndex = 0; itemIndex < slidesCount; itemIndex++) {
            const slideIndex = playerIndex.indexBySlideId[data.payload.slideIds[itemIndex]];

            if (
                (slideIndex !== undefined)
                && (currentSlidesWorkData.slides[slideIndex].loadState !== SlideWorkDataLoadingStateEnum.SUCCESS)
            ) {
                yield put(setSlideWorkDataLoadingState({
                    playerId: data.payload.playerId,
                    slideId: data.payload.slideIds[itemIndex],
                    value: SlideWorkDataLoadingStateEnum.ERROR
                }));
            }
        }

        return;
    }

    // Всё успешно загрузилось. Добавляем информацию.

    const resultItemsCount = workDataResult.response.result.list.length;

    for (let resultSlideIndex = 0; resultSlideIndex < resultItemsCount; resultSlideIndex++) {
        const receivedDataItem = workDataResult.response.result.list[resultSlideIndex];
        const slideItemWorkDataList: SlideItemWorkData[] = [];

        const itemsCountInSlide = receivedDataItem.slideItems.length;

        for (let resultSlideItemIndex = 0; resultSlideItemIndex < itemsCountInSlide; resultSlideItemIndex++) {
            const itemWorkData = cloneDeep(initialSlideItemWorkData);

            const itemDataExercisesIndexById: { [exerciseId: string]: number } = {};

            const itemDataExercises = receivedDataItem.slideItems[resultSlideItemIndex].exercises.map(
                (item, index): ExerciseWorkData => {
                    itemDataExercisesIndexById[item.exerciseId] = index;

                    return {
                        exerciseId: item.exerciseId,
                        value: item.value,
                        inputHistory: item.inputHistory,
                        award: item.award,
                        missedAward: item.missedAward,
                        completed: item.completed,
                    }
                }
            );

            itemWorkData.slideItemId = receivedDataItem.slideItems[resultSlideItemIndex].slideItemId;
            itemWorkData.additionalData = receivedDataItem.slideItems[resultSlideItemIndex].additionalData;
            itemWorkData.overriddenParams = receivedDataItem.slideItems[resultSlideItemIndex].overriddenParams;
            itemWorkData.exercisesIndexById = itemDataExercisesIndexById;
            itemWorkData.exercises = itemDataExercises;

            slideItemWorkDataList.push(itemWorkData);
        }

        yield put(setSlideWorkDataItemsList({
            playerId: data.payload.playerId,
            slideId: workDataResult.response.result.list[resultSlideIndex].slideId,
            value: slideItemWorkDataList
        }));

        yield put(setSlideTotalValues({
            playerId: data.payload.playerId,
            slideId: workDataResult.response.result.list[resultSlideIndex].slideId,
            value: {
                totalAward: workDataResult.response.result.list[resultSlideIndex].totalAward,
                missedAward: workDataResult.response.result.list[resultSlideIndex].totalMissedAward
            }
        }));

        yield put(setSlideWorkDataLoadingState({
            playerId: data.payload.playerId,
            slideId: workDataResult.response.result.list[resultSlideIndex].slideId,
            value: SlideWorkDataLoadingStateEnum.SUCCESS
        }));
    }
}
