import {IJanusAudioRoomPlugin} from "./IJanusAudioRoomPlugin";
import {ILogger} from "../../Logger/ILogger";
import {IMediaDeviceService} from "../../../services/media-device/IMediaDeviceService";
import {IJanusConnection} from "../IJanusConnection";
import {AudioRoomsCallback, JoinAudioRoomParams} from "./Types";
import {JanusJS} from "../OfficialClient/janus";
import {LoggerSectionsEnum} from "../../Logger/LoggerSectionsEnum";
import {PublisherNameUtils} from "../VideoRoom/PublisherNameUtils";
import {PluginNameEnum} from "../PluginNameEnum";
import {IDeviceMediaStreamFetcher} from "../../DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {DeviceInfo, DeviceKindEnum} from "../../../services/media-device/Types";
import {StreamTypeEnum, TrackContentHintEnum} from "../Types";
import {ParticipantStore} from "./ParticipantStore";
import {cloneDeep} from "lodash";

const MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID = 'audioRoomPlugin';

export class JanusAudioRoomPlugin implements IJanusAudioRoomPlugin {
    protected logger: ILogger;
    protected mediaDeviceService: IMediaDeviceService;
    protected deviceMediaStreamFetcher: IDeviceMediaStreamFetcher;
    protected janusConnection: IJanusConnection;

    protected audioRoomOpaqueId: string;
    protected myUserId: string | null;
    protected publisherUserName: string | null;

    protected roomId: string | null;
    protected roomJoinKey: string | null;

    protected callbacks: AudioRoomsCallback | null;

    protected descriptor: JanusJS.PluginHandle | null;

    protected myFeedId: string;

    protected currentInputDevice: DeviceInfo | null;
    protected currentMicrophoneTrack: MediaStreamTrack | null;
    protected participantStore: ParticipantStore;

    protected remoteAudioStream: MediaStream | null;

    protected startMuted: boolean;

    constructor(
        logger: ILogger,
        mediaDeviceService: IMediaDeviceService,
        deviceMediaStreamFetcher: IDeviceMediaStreamFetcher,
        janusConnection: IJanusConnection
    ) {
        this.logger = logger;

        this.mediaDeviceService = mediaDeviceService;
        this.mediaDeviceService
            .registerSubscriber(
                DeviceKindEnum.AUDIO_INPUT,
                MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID,
                this.onInputDeviceChangedByUser.bind(this)
            );

        this.deviceMediaStreamFetcher = deviceMediaStreamFetcher;

        this.janusConnection = janusConnection;

        this.audioRoomOpaqueId = 'audioroom-' + JanusJS.Janus.randomString(12);

        this.myFeedId = '';
        this.myUserId = null;
        this.publisherUserName = null;
        this.roomId = null;
        this.roomJoinKey = null;
        this.descriptor = null;
        this.currentInputDevice = null;
        this.currentMicrophoneTrack = null;
        this.participantStore = new ParticipantStore();
        this.remoteAudioStream = null;
        this.startMuted = false;

        this.callbacks = null;

        // @ts-ignore
        window['participantStore'] = () => {
            console.log(this.participantStore);
        }

        // @ts-ignore
        window['fireIceDisconnected'] = () => {
            this.callbacks?.iceDisconnected();
        }
    }

    /**
     * @inheritDoc
     */
    destroy(): void {
        this.logger.info(
            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
            'Called destroy proc'
        );

        this.mediaDeviceService
            .unregisterSubscriber(MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID);

        if (this.currentMicrophoneTrack !== null) {
            this.currentMicrophoneTrack.removeEventListener('ended', this.onStreamEnded);
        }

        if (this.currentInputDevice !== null) {
            this.deviceMediaStreamFetcher.stopMicrophoneStream(this.currentInputDevice.id);
        }

        // TODO что-то написать
    }

    joinToAudioRoom(params: JoinAudioRoomParams): void {
        const joinDate = new Date();

        this.roomId = params.roomId;
        this.roomJoinKey = params.roomJoinKey;
        this.myUserId = params.userId;
        this.publisherUserName = PublisherNameUtils.createComplexUserId(this.myUserId, joinDate, false);
        this.callbacks = params.callbacks;
        this.startMuted = params.startMuted;

        if (!this.publisherUserName) {
            throw new Error('Publisher user name is null');
        }

        if (!this.roomId || !this.roomJoinKey) {
            throw new Error(`roomId or roomJoinKey is null`);
        }

        this.janusConnection.getInstance().attach(
            {
                plugin: PluginNameEnum.AUDIO_ROOM,
                opaqueId: this.audioRoomOpaqueId,
                success: (pluginHandle) => {
                    this.descriptor = pluginHandle;

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Plugin attached! (" + this.descriptor.getPlugin() + ", id=" + this.descriptor.getId() + ")"
                    );

                    this.descriptor.send({
                        message: {
                            request: "join",
                            room: params.roomId,
                            pin: params.roomJoinKey,
                            display: this.publisherUserName,
                            codec: 'opus'
                        }
                    });

                    this.callbacks?.joinedSuccessfully();
                },
                error: (error: string) => {
                    this.logger.error(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Error attaching plugin: ", error);

                    this.callbacks?.error("Error attaching plugin: " + JSON.stringify(error));
                },
                consentDialog: (on: boolean) => {
                    this.logger.error(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Consent dialog should be " + (on ? "on" : "off") + " now");
                },
                webrtcState: (isConnected: boolean) => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Janus says our WebRTC PeerConnection is " + (isConnected ? "up" : "down") + " now");
                },
                iceState: (state) => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "ICE state changed to " + state);

                    if (state === "failed") {
                        this.callbacks?.iceDisconnected();
                    }
                },
                mediaState: (medium, receiving, mid) => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Janus " + (receiving ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                },
                slowLink: (uplink, lost, mid) => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Janus reports problems " + (uplink ? "sending" : "receiving") +
                        " packets on mid " + mid + " (" + lost + " lost packets)");

                    this.callbacks?.slowLinkMessage(lost);
                },
                onmessage: (message, jsep) => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Got a message", message);
                    const event = message["audiobridge"];
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Event", event);

                    if (event !== undefined && event !== null) {
                        this.handleAudioRoomPluginEvent(message);
                    }

                    if (jsep) {
                        // this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Handling SDP as well...", jsep);

                        if (this.descriptor !== null) {
                            this.descriptor.handleRemoteJsep({jsep: jsep});
                        }
                    }

                },
                onlocaltrack: (track, on) => {
                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Local track " + (on ? "added" : "removed") + ":",
                        track
                    );
                },
                onremotetrack: (track, mid, on, metadata) => {
                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Remote track (mid=" + mid + ") " +
                        (on ? "added" : "removed") +
                        (metadata ? " (" + metadata.reason + ") " : "") + ":", track
                    );

                    if (track.kind !== StreamTypeEnum.AUDIO) {
                        this.logger.warning(
                            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                            `Received remote track with unexpected kind ${track.kind}`
                        );

                        return;
                    }

                    if (!on) {
                        if (this.remoteAudioStream !== null) {
                            this.remoteAudioStream.getTracks().forEach(item => item.stop());
                        }

                        this.remoteAudioStream = null;

                        this.callbacks?.trackStateChange(false);

                        this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Remote audio track ended");
                    } else {
                        this.remoteAudioStream = new MediaStream([track]);

                        this.callbacks?.trackStateChange(true);

                        this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Fetched remote audio track");
                    }
                },
                oncleanup: () => {
                    this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "Cleanup");
                    this.callbacks?.onCleanup();
                }
            }
        )
    }

    attachAudioToElement(element: HTMLAudioElement): boolean {
        if (this.remoteAudioStream === null) {
            this.logger.warning(
                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                `Attach audio to element called for not ready stream`
            );

            return false;
        }

        JanusJS.Janus.attachMediaStream(element, this.remoteAudioStream);

        return true;
    }

    setMuteOwnAudio(value: boolean): void {
        if (!this.descriptor) {
            this.logger.warning(
                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                `setMuteAudio for empty descriptor`
            );

            return;
        }

        this.descriptor.send({message: {request: "configure", muted: value}});

        if (this.myUserId) {
            if (value) {
                this.participantStore.updateParticipant(
                    this.myUserId,
                    {talking: false, muted: value}
                );

                this.callbacks?.setParticipantTalkingValue(this.myUserId, false);
            } else {
                this.participantStore.updateParticipant(
                    this.myUserId,
                    {muted: value}
                );
            }

            this.callbacks?.setParticipantMutedValue(this.myUserId, value);
        }
    }

    protected handleAudioRoomPluginEvent = (message: JanusJS.Message) => {
        const event = message["audiobridge"] as string;

        switch (event) {
            case "joined": {
                if (message["id"]) {
                    this.myFeedId = message["id"];

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Successfully joined room " + message["room"] + " with ID " + this.myFeedId
                    );

                    this.publishOwnFeed(this.startMuted);

                    if (this.myUserId) {
                        this.processParticipantsList([
                            {
                                id: this.myFeedId,
                                muted: this.startMuted,
                                display: PublisherNameUtils.createComplexUserId(
                                    this.myUserId,
                                    new Date(),
                                    false
                                )
                            }
                        ] as JanusJS.AudioRoomParticipant[]);
                    }
                }

                // Any room participants?
                if (message["participants"]) {
                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Got a list of participants: ",
                        message["participants"]
                    );

                    this.processParticipantsList(message["participants"] as JanusJS.AudioRoomParticipant[]);
                }

                break;
            }
            case "event": {
                if (message["participants"]) {
                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Got a list of participants: ",
                        message["participants"]
                    );

                    this.processParticipantsList(message["participants"] as JanusJS.AudioRoomParticipant[]);
                }

                if (message["error"]) {
                    this.logger.error(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        `Received error message `,
                        message
                    );

                    this.setAppParticipantsDisconnected();

                    this.callbacks?.error(message);
                }

                if (message['leaving']) {
                    let leaving = message["leaving"];

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        "Participant left: ", leaving
                    );

                    const participant = this.participantStore.getItemByFeedId(leaving);

                    if (participant) {
                        this.participantStore.removeParticipant(participant.userId);

                        this.callbacks?.handleDisconnectedParticipants([participant.userId]);
                    }
                }

                break;
            }
            case "talking": {
                const participant = this.participantStore.getItemByFeedId(message['id']);

                if (participant && !participant.muted) {
                    this.participantStore.updateParticipant(
                        participant.userId,
                        {
                            talking: true
                        }
                    );

                    this.callbacks?.setParticipantTalkingValue(
                        participant.userId,
                        true
                    );
                }

                break;
            }
            case "stopped-talking": {
                const participant = this.participantStore.getItemByFeedId(message['id']);

                if (participant) {
                    this.participantStore.updateParticipant(
                        participant.userId,
                        {
                            talking: false
                        }
                    );

                    this.callbacks?.setParticipantTalkingValue(
                        participant.userId,
                        false
                    );
                }

                break;
            }
            case "destroyed": {
                // The room has been destroyed
                this.logger.info(LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN, "The room has been destroyed!");

                this.setAppParticipantsDisconnected();

                break;
            }

        }
    }

    protected setAppParticipantsDisconnected = () => {
        const allParticipants = this.participantStore.getAllParticipants();

        this.callbacks?.handleDisconnectedParticipants(allParticipants.map(item => item.userId));

        this.participantStore.clearParticipantsList();
    }

    protected processParticipantsList = (participants: JanusJS.AudioRoomParticipant[]) => {
        participants.forEach((participant) => {
            const userData = PublisherNameUtils.parseComplexUserId(participant['display']);

            const existData = this.participantStore.getItemByFeedId(participant['id']);

            if (!existData) {
                this.participantStore.addNewParticipant(
                    participant['id'],
                    userData.userId,
                    {
                        talking: false,
                        muted: participant['muted']
                    }
                );

                this.callbacks?.handleJoinedParticipants(this.participantStore.getAllParticipants());
            } else {
                if (participant['muted'] !== existData.muted) {
                    if (participant['muted']) {
                        this.participantStore.updateParticipant(
                            userData.userId,
                            {
                                muted: participant['muted'],
                                talking: false
                            }
                        );

                        this.callbacks?.setParticipantTalkingValue(userData.userId, false);
                    } else {
                        this.participantStore.updateParticipant(
                            userData.userId,
                            {
                                muted: participant['muted'],
                            }
                        );
                    }

                    this.callbacks?.setParticipantMutedValue(userData.userId, participant['muted']);
                }
            }
        });
    }

    protected publishOwnFeed = async (startAsMuted: boolean) => {
        const tracks = await this.getActiveMicrophoneTracks();

        this.logger.info(
            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
            `Creating offer`
        );

        this.descriptor?.createOffer({
            tracks: [tracks],
            customizeSdp: function (jsep) {
                jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1");
            },
            success: (jsep: JanusJS.JSEP) => {
                let publish = {request: "configure", muted: startAsMuted};
                this.descriptor?.send({message: publish, jsep: jsep});
            },
            error: (error) => {
                this.logger.error(
                    LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                    "Error on createOffer:",
                    error
                );

                this.callbacks?.error("Error on createOffer:" + JSON.stringify(error));
            }
        });
    }

    protected onInputDeviceChangedByUser = (newDevice: DeviceInfo) => {
        if (!this.descriptor) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                `Input device changed by user but descriptor is null`
            );

            return;
        }

        this.logger.info(
            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
            `Input device changed by user to ${newDevice.name}, start track replacing`
        );

        const currentInputDevice = cloneDeep(this.currentInputDevice);
        const currentMicrophoneTrack = this.currentMicrophoneTrack;

        this.getActiveMicrophoneTracks()
            .then((track) => {
                this.descriptor?.replaceTracks({
                    tracks: [
                        {
                            ...track,
                            replace: true
                        }
                    ],
                    success: (_data) => {
                        this.logger.info(
                            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                            `Replace track to ${newDevice.name} finished.`
                        );

                        // Останавливаем старый поток
                        if (currentInputDevice && currentInputDevice.id === newDevice.id) {
                            this.logger.warning(
                                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                                `New device and current device is equal`
                            );

                            return;
                        }

                        if (currentMicrophoneTrack !== null) {
                            currentMicrophoneTrack.removeEventListener('ended', this.onStreamEnded);
                        }

                        if (currentInputDevice !== null) {
                            this.deviceMediaStreamFetcher.stopMicrophoneStream(currentInputDevice.id);

                            this.logger.info(
                                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                                `Prev track from ${currentInputDevice.name} stopped.`
                            );
                        }

                        this.callbacks?.onMicChanged(newDevice);
                    },
                    error: (error) => {
                        this.logger.error(
                            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                            `Error on replace current track from device ${currentInputDevice?.name} to track from device ${newDevice.name}`,
                            error
                        );

                        // Останавливаем новый поток

                        // Отключаем событие onended
                        if (track.capture) {
                            (track.capture as MediaStreamTrack).removeEventListener('ended', this.onStreamEnded);
                        }

                        this.deviceMediaStreamFetcher.stopMicrophoneStream(newDevice.id);

                        this.logger.error(
                            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                            `Track from ${newDevice.name} stopped.`
                        );
                    }
                })
            });
    }

    protected async getActiveMicrophoneTracks(): Promise<JanusJS.Track> {
        const availableDevices = await this.mediaDeviceService.getDevicesForCall();

        if (!availableDevices.audioInput) {
            throw new Error(`Not found audioInput device`);
        }

        this.currentInputDevice = availableDevices.audioInput;

        const microphoneStream = await this.deviceMediaStreamFetcher.getMicrophoneStream(
            availableDevices.audioInput.id
        );

        const audioTrack = microphoneStream.getAudioTracks()[0];

        audioTrack.contentHint = TrackContentHintEnum.MIC_AUDIO;

        this.currentMicrophoneTrack = audioTrack;

        audioTrack.addEventListener('ended', this.onStreamEnded);

        return {
            type: StreamTypeEnum.AUDIO,
            capture: audioTrack,
            recv: true
        };
    }

    protected onStreamEnded = () => {
        this.logger.warning(
            LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
            'Microphone stream is ended'
        );

        // Попробуем включить другой микрофон
        this.mediaDeviceService.getDevicesForCall()
            .then((devices) => {
                if (devices.audioInput === null) {
                    this.logger.warning(
                        LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                        'Microphone stream in ended and not found another mic in system'
                    );

                    this.callbacks?.onMicGoneAway(this.currentInputDevice, true);

                    return;
                }

                this.logger.warning(
                    LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                    `Try to use mic ${devices.audioInput.name}`
                );

                this.callbacks?.onMicGoneAway(this.currentInputDevice, false);

                if (this.currentInputDevice) {
                    this.deviceMediaStreamFetcher.stopMicrophoneStream(this.currentInputDevice.id);
                    this.currentInputDevice = null;
                }

                this.onInputDeviceChangedByUser(devices.audioInput);
            });
    }
}
