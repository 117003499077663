import {Reducer} from "redux";
import {SetSelectedUserIdReducerInput, UserActionTypes, UserState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";
import {
    ActualParamsDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {cloneDeep} from "lodash";

const reducer: Reducer<UserState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case UserActionTypes.SET_USER_PROFILE_TYPE: {
            draft.profileType = action.payload;

            break;
        }
        case UserActionTypes.SET_USER_AVATAR_FILE_ID: {
            if (draft.profileData) {
                draft.profileData = {
                    ...draft.profileData,
                    avatarFileId: action.payload
                };
            }

            break;
        }
        case UserActionTypes.SET_USER_SESSION_TOKEN: {
            draft.sessionToken = action.payload;

            break;
        }
        case UserActionTypes.SET_USER_ST_TOKEN: {
            draft.stToken = action.payload;

            break;
        }
        case UserActionTypes.SET_USER_PROFILE_LOAD_STATE: {
            draft.authState = action.payload;

            break;
        }
        case UserActionTypes.SET_USER_PROFILE_DATA: {
            draft.profileData = action.payload;

            break;
        }
        case UserActionTypes.SET_USER_TEACHER_ACTUAL_PARAMS: {
            if (draft.profileData && draft.profileData.schools.length === 1) {
                const newProfileData = cloneDeep(draft.profileData);

                newProfileData.schools[0].actualParams = action.payload;

                draft.profileData = newProfileData;
            }

            break;
        }
        case UserActionTypes.SET_USER_IS_MODERATOR: {
            draft.userIsModerator = action.payload;

            break;
        }
        case UserActionTypes.SET_WS_RECONNECTION_TOKEN: {
            draft.wsReconnectionToken = action.payload;

            break;
        }
        case UserActionTypes.SET_SELECTED_AGREEMENT_ID: {
            const input = action.payload as SetSelectedUserIdReducerInput;

            draft.selectedSchoolId = input.schoolId;
            draft.selectedUserInSchoolId = input.userInSchoolId;
            draft.selectedAgreementId = input.agreementId;

            break;
        }
        case UserActionTypes.SET_UPDATES_AFTER_INITIAL_CUSTOMIZER_FINISHED: {
            const payload: { userName: string, timezoneName: string, actualParams: ActualParamsDto, schoolName: string } = action.payload;

            const profileData = cloneDeep(draft.profileData);

            if (!profileData || profileData.schools.length !== 1) {
                return;
            }

            profileData.schools[0].name = payload.schoolName;
            profileData.schools[0].actualParams = payload.actualParams;
            profileData.schools[0].timezoneName = payload.timezoneName;
            profileData.schools[0].accounts[0].name = payload.userName;

            draft.profileData = profileData;

            break;
        }
    }
}, initialState);

export {reducer as userReducer}