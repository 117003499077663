import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import styled, {keyframes} from "styled-components";
import {IRichTextElementRefMethods} from "../../components/RichTextReader/IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../components/RichTextReader/IRichTextElementProps";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {RegularText} from "../../components/RichTextReader/elements/common-styles";
import classNames from "classnames";
import {ExerciseCheckboxContext, IExerciseCheckboxContext, ShowMode} from "./ExerciseCheckboxContext";
import {ICheckboxListItem} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxListItem";
import {ReactComponent as CheckboxChecked20Svg} from "../../../Ui/Svg/CheckboxChecked20.svg";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {AlignTypes} from "../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

const ErrorTremor = keyframes`
  0% {
    transform: translateX(-3px);
  }

  15% {
    transform: translateX(+3px);
  }

  30% {
    transform: translateX(-2px);
  }

  45% {
    transform: translateX(+2px);
  }

  60% {
    transform: translateX(-1px);
  }

  75% {
    transform: translateX(+1px);
  }

  90% {
    transform: translateX(0);
  }
`;

const CheckboxListItem = styled.li`
  ${RegularText};

  position: relative;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &.correct, &.incorrect, &.muted {
    pointer-events: none;
    cursor: default;
  }

  &.incorrect, &.muted {
    opacity: 0.5;
  }

  &.error-animation {
    animation: ${ErrorTremor} 0.9s ease-in-out;
  }

  &.align-right {
    text-align: right;
  }

  &.align-justify {
    text-align: justify;
  }

  &.align-center {
    text-align: center;
  }

  &.align-left {
    text-align: left;
  }
  
  &.read-only {
    cursor: not-allowed;
  }
`;

const CheckboxStateIconCol = styled.div`
  min-width: 30px;
  padding: 0.4em 10px;
`;

const BodyWrapper = styled.div`
  flex-grow: 1;
`;

const CheckboxIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  box-shadow: rgb(0 0 0 / 6%) 0 1px 1px;

  &.correct {
    color: ${({theme}) => theme.colors.progressBarRight};
  }

  &.incorrect {
    color: ${({theme}) => theme.colors.progressBarWrong};
  }
`;

const UncheckedIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.accentDivider};
  border-radius: 3px;
`;

export const ExerciseCheckboxListItem = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<ICheckboxListItem>>(
    (props, ref) => {

        const {element, className, ...otherProps} = props;

        const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);

        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const alignClass = useMemo(() => {
            switch (element.align) {
                case AlignTypes.RIGHT: {
                    return "align-right";
                }
                case AlignTypes.JUSTIFY: {
                    return "align-justify";
                }
                case AlignTypes.CENTER: {
                    return "align-center";
                }
                default: {
                    return "align-left";
                }
            }
        }, [element]);

        const exerciseContext = useContext<IExerciseCheckboxContext>(ExerciseCheckboxContext);

        const {showErrorAnimationInVariantId, setShowErrorAnimationInVariantId} = exerciseContext;

        useEffect(() => {
            if (showErrorAnimationInVariantId === element.id) {
                setNowShowErrorAnimation(true);
                setShowErrorAnimationInVariantId(null);
            }
        }, [element.id, setShowErrorAnimationInVariantId, showErrorAnimationInVariantId])

        const currentShowMode = useMemo<ShowMode>(() => {
            return exerciseContext.variantState[element.id] ?? ShowMode.INPUT;
        }, [element.id, exerciseContext.variantState]);

        const icon = () => {
            switch (currentShowMode) {
                case ShowMode.CORRECT:
                case ShowMode.ERROR: {
                    return <CheckboxChecked20Svg style={{verticalAlign: "top"}}/>;
                }
                case ShowMode.INPUT:
                case ShowMode.MUTED:
                default: {
                    return <UncheckedIcon/>;
                }
            }
        }

        return <CheckboxListItem
            className={
                classNames(
                    alignClass,
                    className,
                    (currentShowMode === ShowMode.ERROR) && "incorrect",
                    (currentShowMode === ShowMode.CORRECT) && "correct",
                    (currentShowMode === ShowMode.MUTED) && "muted",
                    nowShowErrorAnimation && "error-animation",
                    (slideItemWorkContext.readOnly && currentShowMode === ShowMode.INPUT) && "read-only"
                )
            }
            onClick={() => {
                exerciseContext.variantOnClick(element.id)
            }}
            onAnimationEnd={() => setNowShowErrorAnimation(false)}
        >
            <CheckboxStateIconCol>
                <CheckboxIconWrapper className={classNames(
                    (currentShowMode === ShowMode.ERROR) && "incorrect",
                    (currentShowMode === ShowMode.CORRECT) && "correct"
                )}>
                    {icon()}
                </CheckboxIconWrapper>
            </CheckboxStateIconCol>
            <BodyWrapper>
                <RichTextReader elements={element.children} {...otherProps}/>
            </BodyWrapper>
        </CheckboxListItem>
    }
);

ExerciseCheckboxListItem.displayName = 'ExerciseCheckboxListItem';