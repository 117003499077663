import {IsNumber, IsOptional, IsString} from "class-validator";

export class DtoCostRegisterData {
    @IsNumber()
    costMode: number;

    @IsString()
    startDateTime: string;

    @IsString()
    cost: string;

    @IsOptional()
    @IsString()
    comment: string | null;
}