import styled, {css} from "styled-components";
import {BullSymbol} from "../components/Ui/Elements/BullSymbol";

export const PageTitle = styled.h1`
  color: ${({theme}) => theme.colors.textPrimary};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 32px;
    margin-bottom: 40px;
  }

  @media (${({theme}) => theme.media.medium}) {
    font-size: 40px;
    margin-bottom: 40px;
  }
`;

export const PageSubtitleCss = css`
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 700;

  @media (${({theme}) => theme.media.small}) {
    font-size: 24px;
    margin-bottom: 40px;
  }

  @media (${({theme}) => theme.media.medium}) {
    font-size: 24px;
    margin-bottom: 40px;
  }
`;

export const PageSubtitleSmallMarginCss = css`
  ${PageSubtitleCss};
  margin-bottom: 15px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 20px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 20px;
  }
`;

export const PageSubtitle = styled.h2`
  ${PageSubtitleCss};
`;

export const PageSubtitleSmallMargin = styled.h2`
  ${PageSubtitleSmallMarginCss};
`;

export const PageSubtitle2Css = css`
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 700;

  @media (${({theme}) => theme.media.small}) {
    font-size: 20px;
    margin-bottom: 30px;
  }

  @media (${({theme}) => theme.media.medium}) {
    font-size: 24px;
    margin-bottom: 36px;
  }
`;

export const PageSubtitle2 = styled.h3`
  ${PageSubtitle2Css};
`;

export const RegularFontSize = css`
  font-size: 15px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

export const RegularTextCss = css`
  ${RegularFontSize};
  line-height: 1.5em;
  font-weight: normal;
  margin-bottom: 0;
`;

export const RegularText = styled.p`
  ${RegularTextCss};
`;

export const BlockWithBorderCss = css`
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 20px;
`;

export const BlockWithBorderAndMarginCss = css`
  ${BlockWithBorderCss};

  padding: 24px;
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 32px;
  }
`;

export const BlockWithBorderAndPaddingCss = css`
  ${BlockWithBorderCss};

  padding: 24px;
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    padding: 32px;
  }
`;

export const BlockWithBorder = styled.div`
  ${BlockWithBorderCss};
`;

const BlockWithBorderFlexCss = css`
  ${BlockWithBorderCss};
  display: flex;
  flex-direction: column;
`;

export const BlockWithBorderFlex = styled.div`
  ${BlockWithBorderFlexCss}
`;

export const BlockWithBorderFlexLink = styled.a`
  ${BlockWithBorderFlexCss}
`;

export const BlockWithBorderAndPadding = styled.div`
  ${BlockWithBorderAndPaddingCss};
`;

export const BlockWithBorderAndMargin = styled.div`
  ${BlockWithBorderAndMarginCss};
`;

export const BlockHeaderMargin = css`
  padding-bottom: 12px;
  margin-bottom: 12px;

  @media (${({theme}) => theme.media.large}) {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

export const BlockAccentTextCss = css`
  color: ${({theme}) => theme.colors.textPrimary};
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const BlockAdditionalTextCss = css`
  color: ${({theme}) => theme.colors.textTertiary};
  font-size: 12px;
  line-height: 16px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const InputLabelCss = css`
  font-size: 14px;
  color: ${({theme}) => theme.colors.textTertiary};
`;

/**
 * Отступы для контента под шапкой блока
 */
export const BlockContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 24px;
  flex-grow: 1;

  @media (${({theme}) => theme.media.small}) {
    padding: 24px 32px 32px 32px;
  }

  @media (${({theme}) => theme.media.medium}) {
    padding: 32px;
  }
`;

/**
 * Отступы для шапки блока с аватаркой
 */
export const BlockAvatarWrapper = styled.div`
  padding: 24px 24px 0 24px;

  @media (${({theme}) => theme.media.small}) {
    padding: 24px 32px 0 32px;
  }

  @media (${({theme}) => theme.media.medium}) {
    padding: 32px 32px 0 32px;
  }
`;

export const BlockAdditionalBullSymbol = styled(BullSymbol)`
  margin: 0 8px;
`;

export const ModalTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 48px;
  }
`;

export const ModalSubTitle = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 24px;
  padding-bottom: 0;
  color: ${({theme}) => theme.colors.textPrimary};
  
  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 40px;
  }
`;

export const ModalP = styled.p`
  ${RegularTextCss};
  
  margin-bottom: 34px;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 50px;
  }

  @media (${({theme}) => theme.media.medium}) {
    max-width: 60%;
  }
`;

export const InputLabel = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
  font-size: 14px;
  margin-bottom: 10px;
`;

export const TopIndex = styled.sup`
  padding-left: 2px;
  opacity: 0.7;
`;

export const RedDot = css`
  content: " ";
  position: absolute;
  top: 10px;
  right: 10px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.warningBulletColor};
`;

export const RoubleSymbol = styled.div`
  position: absolute;
  right: 8px;
  top: 1.5em;
  padding: 13px 0;
  font-size: 16px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

export const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
`;