import React, {useEffect} from "react";
import {IScreenSaverLock} from "../../../../components/ScreenSaverLock/IScreenSaverLock";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {container} from "tsyringe";

export const ScreenSaverLocker: React.FC = () => {
    useEffect(() => {
        const screenSaverLocker = container.resolve<IScreenSaverLock>(DiTokens.SCREEN_SAVER_LOCK);

        screenSaverLocker.setLock();

        return () => {
            screenSaverLocker.unsetLock();
        }
    }, []);

    return null;
}
