import {IRouter} from './IRouter';
import {RouteItem} from './RouteItem';

export class Router implements IRouter {
    protected routes: Array<RouteItem>;

    protected indexedRoutes: { [key: string]: number };

    constructor(routes: Array<RouteItem>) {
        this.routes = routes;

        this.indexedRoutes = {};

        this.createRoutesIndex();
    }

    getRouteByName(route: string): RouteItem | null {
        if (route in this.indexedRoutes) {
            return this.routes[this.indexedRoutes[route]];
        }

        return null;
    }

    /**
     * Сформировать индекс роутов.
     */
    protected createRoutesIndex() {
        for (let i = 0; i < this.routes.length; i++) {
            this.indexedRoutes[this.routes[i].method] = i;
        }
    }
}
