import React from "react";
import styled from "styled-components";
import {RedDot} from "../../../../../styles/global-elements";
import classNames from "classnames";

const Wrapper = styled.span`
    position: relative;
    display: inline-block;
    
    &.is-new::after {
        ${RedDot}
        
        top: 0px;
        right: -9px;
    }
`;

interface HomeTaskBtnLabelProps {
    isNew?: boolean;
    children: JSX.Element;
}

export const HomeTaskBtnLabel: React.FC<HomeTaskBtnLabelProps> = ({isNew, children}) => {
    return <Wrapper className={classNames(isNew && "is-new")}>{children}</Wrapper>
}
