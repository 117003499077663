import {Reducer} from "redux";
import {
    ActionParamExerciseUserAnswerByIndexes,
    ActionParamExerciseUserValue,
    ActionParamExerciseValueByIndexes,
    ActionParamSetSlideTotalValues,
    ActionParamWithSlideIdAndValue,
    SlideItemWorkData,
    SlidesWorkDataState,
    SlideWorkDataActionTypes,
    SlideWorkDataLoadingStateEnum
} from "./type";
import {
    initialExerciseWorkDataObject,
    initialSlideItemWorkData,
    initialSlideWorkDataObject,
    initialState
} from "./initialState";
import produce, {Draft} from "immer";
import {cloneDeep} from "lodash";
import {MAX_AWARD_SCORE} from "../../Constants";
import {
    SlideItemOverriddenParams
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/StudentSlideWorkDataAnswersData/SlideItemOverriddenParams";
import {EditorItemDataParams} from "../../views/components/SlidePlayerEditorCommonParts/EditorData";

const calcTotalValuesInDraft = (draft: Draft<SlidesWorkDataState>, slideIndex: number, slideExercisesCount: number): Draft<SlidesWorkDataState> => {
    let totalAwardScore = 0;
    let totalMissedAward = 0;

    draft.slides[slideIndex].items.forEach((slideItem) => {
        slideItem.exercises.forEach((item) => {
            totalAwardScore += (item.award !== 0) ? (item.award / slideExercisesCount) : 0;
            totalMissedAward += (item.missedAward !== 0) ? (item.missedAward / slideExercisesCount) : 0;
        });
    });

    totalAwardScore = Math.ceil(totalAwardScore);
    totalMissedAward = Math.ceil(totalMissedAward);

    if (totalAwardScore > MAX_AWARD_SCORE) {
        totalAwardScore = MAX_AWARD_SCORE;
    }

    if (totalMissedAward > MAX_AWARD_SCORE) {
        totalMissedAward = MAX_AWARD_SCORE;
    }

    if (totalAwardScore + totalMissedAward > MAX_AWARD_SCORE) {
        // Если суммарно получилось больше, чем может быть
        let newMissedAward = MAX_AWARD_SCORE - totalAwardScore;

        if ((newMissedAward < 1) && (totalMissedAward > 0)) {
            // Если ошибка была, но по итогу округлений она полностью потерялась
            totalMissedAward = 1;
            totalAwardScore = MAX_AWARD_SCORE - 1;
        } else {
            totalMissedAward = newMissedAward;
        }
    }

    draft.slides[slideIndex].totalAward = totalAwardScore;
    draft.slides[slideIndex].missedAward = totalMissedAward;

    if (totalAwardScore + totalMissedAward >= MAX_AWARD_SCORE) {
        draft.slides[slideIndex].slideCompleted = true;
    }

    return draft;
}

const reducer: Reducer<SlidesWorkDataState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_EMPTY_OBJECT: {
            const payload = action.payload as ActionParamWithSlideIdAndValue<never>;

            let playerIndex = draft.indexByPlayerId[payload.playerId];

            if (playerIndex === undefined) {
                playerIndex = {
                    indexBySlideId: {}
                }

                draft.indexByPlayerId = cloneDeep(draft.indexByPlayerId);
                draft.indexByPlayerId[payload.playerId] = playerIndex;
            }

            const slideItemIndex = playerIndex.indexBySlideId[payload.slideId]

            if ((slideItemIndex !== undefined) && (draft.slides[slideItemIndex] !== undefined)) {
                return;
            }

            const newItem = cloneDeep(initialSlideWorkDataObject);

            newItem.loadState = SlideWorkDataLoadingStateEnum.NOT_INIT;
            newItem.slideId = payload.slideId;
            newItem.playerId = payload.playerId;
            newItem.slideContentVersionNum = payload.slideContentVersionNum ?? null;

            draft.slides = cloneDeep(draft.slides);
            draft.slides.push(newItem);

            draft.indexByPlayerId[payload.playerId] = cloneDeep(draft.indexByPlayerId[payload.playerId]);
            draft.indexByPlayerId[payload.playerId].indexBySlideId[payload.slideId] = draft.slides.length - 1;

            break;
        }

        case SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_AWARD_VALUES: {
            const payload = action.payload as ActionParamExerciseUserValue<{ totalAward: number, totalMissedAward: number }>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (!playerIndex) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            draft.slides[slideIdIndex].totalAward = payload.value.totalAward;
            draft.slides[slideIdIndex].missedAward = payload.value.totalMissedAward;
            draft.slides[slideIdIndex].slideCompleted = (payload.value.totalAward + payload.value.totalMissedAward) >= 100;

            break;
        }

        case SlideWorkDataActionTypes.SET_SLIDE_EXERCISE_WORK_DATA_EMPTY_OBJECT: {
            const payload = action.payload as ActionParamExerciseUserValue<never>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (!playerIndex) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            let slideItemIndex = draft.slides[slideIdIndex].itemsIndexById[payload.slideItemId];

            // Если слайд есть, но внутри нет ничего об элементе слайда
            if (slideItemIndex === undefined) {
                const itemWorkData = cloneDeep(initialSlideItemWorkData);
                itemWorkData.slideItemId = payload.slideItemId;

                draft.slides[slideIdIndex].items = cloneDeep(draft.slides[slideIdIndex].items);
                draft.slides[slideIdIndex].items.push(itemWorkData);

                draft.slides[slideIdIndex].itemsIndexById = cloneDeep(draft.slides[slideIdIndex].itemsIndexById);
                draft.slides[slideIdIndex].itemsIndexById[payload.slideItemId] = draft.slides[slideIdIndex].items.length - 1;

                slideItemIndex = draft.slides[slideIdIndex].itemsIndexById[payload.slideItemId];
            }

            let exerciseItemIndex = draft.slides[slideIdIndex].items[slideItemIndex].exercisesIndexById[payload.exerciseId];

            // Элемент слайда есть, но внутри нет ничего про упражнение
            if (exerciseItemIndex === undefined) {
                const exerciseItem = cloneDeep(initialExerciseWorkDataObject);
                exerciseItem.exerciseId = payload.exerciseId;

                draft.slides[slideIdIndex].items[slideItemIndex].exercises
                    = cloneDeep(draft.slides[slideIdIndex].items[slideItemIndex].exercises);
                draft.slides[slideIdIndex].items[slideItemIndex].exercises.push(exerciseItem);

                draft.slides[slideIdIndex].items[slideItemIndex].exercisesIndexById
                    = cloneDeep(draft.slides[slideIdIndex].items[slideItemIndex].exercisesIndexById);
                draft.slides[slideIdIndex].items[slideItemIndex].exercisesIndexById[payload.exerciseId]
                    = draft.slides[slideIdIndex].items[slideItemIndex].exercises.length - 1;
            }

            break;
        }

        case SlideWorkDataActionTypes.SET_EXERCISE_VALUE: {
            const payload = action.payload as ActionParamExerciseValueByIndexes<string>;

            const slide = draft.slides[payload.slideIndex];

            if (slide === undefined) {
                throw new Error('Not found slide by index ' + payload.slideIndex);
            }

            const slideItem = slide.items[payload.slideItemIndex];

            if (slideItem === undefined) {
                throw new Error('Not found slide item by index ' + payload.slideItemIndex);
            }

            const exerciseItem = slideItem.exercises[payload.exerciseIndex];

            if (exerciseItem === undefined) {
                throw new Error('Not found slide exercise by index ' + payload.exerciseIndex);
            }

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .exercises[payload.exerciseIndex]
                .value = payload.value;

            break;
        }

        case SlideWorkDataActionTypes.SET_EXERCISE_ANSWER: {
            const payload = action.payload as ActionParamExerciseUserAnswerByIndexes;

            const slide = draft.slides[payload.slideIndex];

            if (slide === undefined) {
                throw new Error('Not found slide by index ' + payload.slideIndex);
            }

            const slideItem = slide.items[payload.slideItemIndex];

            if (slideItem === undefined) {
                throw new Error('Not found slide item by index ' + payload.slideItemIndex);
            }

            const exerciseItem = slideItem.exercises[payload.exerciseIndex];

            if (exerciseItem === undefined) {
                throw new Error('Not found slide exercise by index ' + payload.exerciseIndex);
            }

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .exercises[payload.exerciseIndex]
                .value = payload.value ?? '';

            // Добавляем значение в историю только если оно не было введено ранее
            if (exerciseItem.inputHistory.indexOf(payload.value) === -1) {
                draft.slides[payload.slideIndex]
                    .items[payload.slideItemIndex]
                    .exercises[payload.exerciseIndex]
                    .inputHistory.push(payload.value);
            }

            if (payload.answerIsCorrect) {
                draft.slides[payload.slideIndex]
                    .items[payload.slideItemIndex]
                    .exercises[payload.exerciseIndex]
                    .completed = true;
            }

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .exercises[payload.exerciseIndex]
                .award = payload.award;

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .exercises[payload.exerciseIndex]
                .missedAward = payload.missedAward;

            // Рассчитываем средний award score по слайду
            draft = calcTotalValuesInDraft(draft, payload.slideIndex, payload.slideExercisesCount);

            break;
        }

        case SlideWorkDataActionTypes.SET_SLIDE_ITEM_ADDITIONAL_DATA: {
            const payload = action.payload as ActionParamExerciseValueByIndexes<string>;

            const slide = draft.slides[payload.slideIndex];

            if (slide === undefined) {
                throw new Error('Not found slide by index ' + payload.slideIndex);
            }

            const slideItem = slide.items[payload.slideItemIndex];

            if (slideItem === undefined) {
                throw new Error('Not found slide item by index ' + payload.slideItemIndex);
            }

            const exerciseItem = slideItem.exercises[payload.exerciseIndex];

            if (exerciseItem === undefined) {
                throw new Error('Not found slide exercise by index ' + payload.exerciseIndex);
            }

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .additionalData = payload.value;

            break;
        }

        case SlideWorkDataActionTypes.SET_SLIDE_ITEM_OVERRIDDEN_PARAMS: {
            const payload = action.payload as ActionParamExerciseValueByIndexes<Partial<EditorItemDataParams>>;

            const slide = draft.slides[payload.slideIndex];

            if (slide === undefined) {
                throw new Error('Not found slide by index ' + payload.slideIndex);
            }

            const slideItem = slide.items[payload.slideItemIndex];

            if (slideItem === undefined) {
                throw new Error('Not found slide item by index ' + payload.slideItemIndex);
            }

            draft.slides[payload.slideIndex]
                .items[payload.slideItemIndex]
                .overriddenParams = payload.value;

            break;
        }

        case SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_LOADING_STATE: {
            const payload = action.payload as ActionParamWithSlideIdAndValue<SlideWorkDataLoadingStateEnum>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (playerIndex === undefined) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            // Если элемент есть
            draft.slides = cloneDeep(draft.slides);
            draft.slides[slideIdIndex].loadState = payload.value;

            break;
        }
        case SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_ITEMS_LIST: {
            const payload = action.payload as ActionParamWithSlideIdAndValue<SlideItemWorkData[]>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (playerIndex === undefined) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            // Если элемент есть
            draft.slides[slideIdIndex].items = payload.value;

            // Пересчитываем индекс элементов
            const itemsIndexById: { [slideItemId: string]: number } = {};

            draft.slides[slideIdIndex].items.forEach((item, index) => itemsIndexById[item.slideItemId] = index);
            draft.slides[slideIdIndex].itemsIndexById = itemsIndexById;

            break;
        }
        case SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_ITEMS_LIST_ITEM_UPDATE: {
            const payload = action.payload as ActionParamWithSlideIdAndValue<SlideItemWorkData>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (playerIndex === undefined) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            // Если элемент есть, нужно или обновить существующий элемент или добавить новый
            const slideItemIndex = draft.slides[slideIdIndex].items.findIndex(
                (item) => item.slideItemId === payload.value.slideItemId
            );

            if (slideItemIndex === -1) {
                draft.slides[slideIdIndex].items.push(payload.value);
            } else {
                draft.slides[slideIdIndex].items[slideItemIndex] = payload.value;
            }

            // Пересчитываем индекс элементов
            const itemsIndexById: { [slideItemId: string]: number } = {};

            draft.slides[slideIdIndex].items.forEach((item, index) => itemsIndexById[item.slideItemId] = index);
            draft.slides[slideIdIndex].itemsIndexById = itemsIndexById;

            break;
        }
        case SlideWorkDataActionTypes.SET_SLIDE_TOTAL_VALUES: {
            const payload = action.payload as ActionParamWithSlideIdAndValue<ActionParamSetSlideTotalValues>;

            const playerIndex = draft.indexByPlayerId[payload.playerId];

            if (playerIndex === undefined) {
                throw new Error('Not found slide work data player index object by player id ' + payload.playerId);
            }

            const slideIdIndex = playerIndex.indexBySlideId[payload.slideId];

            if (slideIdIndex === undefined || draft.slides[slideIdIndex] === undefined) {
                throw new Error('Not found slide work data object by id ' + payload.slideId + ' in player index with id ' + payload.playerId);
            }

            // Если элемент есть
            draft.slides[slideIdIndex].totalAward = payload.value.totalAward;
            draft.slides[slideIdIndex].missedAward = payload.value.missedAward;
            draft.slides[slideIdIndex].slideCompleted = (payload.value.totalAward + payload.value.missedAward) >= 100;

            break;
        }
        case SlideWorkDataActionTypes.RESET_ALL_WORK_DATA_STATE: {
            return initialState;
        }
    }
}, initialState);

export {reducer as slidesWorkDataReducer}
