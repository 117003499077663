import React, {useMemo} from "react";
import {AnswerTypeEmojiEnum, AnswerTypeEnum} from "./AnswerTypeEnum";
import styled from "styled-components";
import {BtnStyleEnum, Button as KitButton} from "../../../../../components/Ui/Elements/Button";
import classNames from "classnames";

const ButtonWrapper = styled.li`
    position: relative;
`;

const AnswerTypeEmoji = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    width: 32px;
    height: 32px;
    font-size: 20px;

    @media (${({theme}) => theme.media.small}) {
        font-size: 24px;
        top: -15px;
        right: -12px;
    }
`;

const AnswerButtonStyled = styled(KitButton)`
    width: 100%;
    height: 100%;
    background-color: unset;
    border: 1px solid ${({theme}) => theme.colors.accentDivider};
    white-space: wrap;

    &:hover {
        background-color: unset;
        border: 1px solid ${({theme}) => theme.colors.accentDivider};
    }

    &:focus {
        background-color: unset;
        border: 1px solid ${({theme}) => theme.colors.accentDivider};
    }

    &.active {
        background-color: ${({theme}) => theme.colors.backgroundSecondary};
    }
`;

interface AnswerButtonProps {
    active: boolean;
    answerType: AnswerTypeEnum;
    label: string;
    onClick: () => void;
}

export const AnswerButton: React.FC<AnswerButtonProps> = (
    {
        active,
        answerType,
        label,
        onClick
    }
) => {
    const answerTypeEmojiCharacter = useMemo<string>(() => {
        switch (answerType) {
            case AnswerTypeEnum.BAD: {
                return AnswerTypeEmojiEnum.BAD;
            }
            case AnswerTypeEnum.NORMAL: {
                return AnswerTypeEmojiEnum.NORMAL;
            }
            case AnswerTypeEnum.GOOD: {
                return AnswerTypeEmojiEnum.GOOD;
            }
        }
    }, [answerType]);

    return <ButtonWrapper>
        <AnswerButtonStyled
            btnStyle={BtnStyleEnum.Secondary}
            onClick={onClick}
            className={classNames(active && 'active')}
        >
            {label}
        </AnswerButtonStyled>
        <AnswerTypeEmoji>
            {answerTypeEmojiCharacter}
        </AnswerTypeEmoji>
    </ButtonWrapper>
}