import {ParticipantItemUpdateConfig, ParticipantStoreItemType} from "./ParticipantStoreItemTypes";

export class ParticipantStore {
    protected list: ParticipantStoreItemType[];

    constructor() {
        this.list = [];
    }

    getItemByFeedId(feedId: string): ParticipantStoreItemType | null {
        return this.list.find(item => item.feedId === feedId) ?? null;
    }

    addNewParticipant(feedId: string, userId: string, config: ParticipantItemUpdateConfig): void {
        let index = this.list.findIndex((item) => item.userId === userId);

        if (index > -1) {
            this.list[index].muted = config.muted ?? false;
            this.list[index].talking = config.talking ?? false;

            return;
        }

        this.list.push({
            feedId: feedId,
            userId: userId,
            muted: config.muted ?? false,
            talking: config.talking ?? false
        });
    }

    updateParticipant(userId: string, config: ParticipantItemUpdateConfig): void {
        let index = this.list.findIndex((item) => item.userId === userId);

        if (index > -1) {
            if (config.muted !== undefined) {
                this.list[index].muted = config.muted;
            }

            if (config.talking !== undefined) {
                this.list[index].talking = config.talking;
            }
        }
    }

    removeParticipant(userId: string): void {
        let index = this.list.findIndex((item) => item.userId === userId);

        if (index > -1) {
            this.list.splice(index, 1);
        }
    }

    clearParticipantsList(): void {
        this.list = [];
    }

    getAllParticipants(): ParticipantStoreItemType[] {
        return this.list;
    }
}
