import React from "react";
import Total100 from "./Images/100.png";
import Biceps from "./Images/biceps.png";
import GoodCat from "./Images/GoodCat.png";
import LaughCat from "./Images/LaughCat.png";
import LoveCat from "./Images/LoveCat.png";
import Party from "./Images/party.png";
import {t} from "@lingui/macro";
import styled from "styled-components";

const Image = styled.img`
`;

export enum LessonResultAnimationVariants {
    TOTAL_100,
    BICEPS,
    GOOD_CAT,
    LAUGH_CAT,
    LOVE_CAT,
    PARTY
}

interface LessonResultAnimationsProps {
    variant: LessonResultAnimationVariants;
    className?: string;
}

export const LessonResultAnimations: React.FC<LessonResultAnimationsProps>
    = ({variant, className}) => {
    switch (variant) {
        case LessonResultAnimationVariants.TOTAL_100: {
            return <Image className={className} src={Total100} alt={t`Максимальный результат`}/>;
        }
        case LessonResultAnimationVariants.BICEPS: {
            return <Image className={className} src={Biceps} alt={t`Поднажмём`}/>;
        }
        case LessonResultAnimationVariants.GOOD_CAT: {
            return <Image className={className} src={GoodCat} alt={t`Хороший результат`}/>;
        }
        case LessonResultAnimationVariants.LAUGH_CAT: {
            return <Image className={className} src={LaughCat} alt={t`Хороший результат`}/>;
        }
        case LessonResultAnimationVariants.LOVE_CAT: {
            return <Image className={className} src={LoveCat} alt={t`Блестяще`}/>;
        }
        case LessonResultAnimationVariants.PARTY: {
            return <Image className={className} src={Party} alt={t`Отлично`}/>;
        }
    }
}
