import styled from "styled-components";
import {ElementProps} from "../rich-text-editor/Element";
import React, {CSSProperties, useMemo} from "react";
import {Tooltip} from "antd";
import {ITranslated} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ITranslated";
import {ReactComponent as TranslatedSvg} from "./../../Toolbar/icons/Translated.svg";

const Wrapper = styled.span`
    display: inline-block;
    position: relative;
    
    :hover {
        background-color: ${({theme}) => theme.colors.backgroundSecondary};
        text-decoration: underline;
    }
    
    ::after {
        content: 'tr';
        text-align: right;
        display: block;
        position: absolute;
        top: -13px;
        right: 0;
        font-size: 10px;
        font-weight: bold;
    }
`;

const TooltipContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TooltipTranslateIcon = styled(TranslatedSvg)`
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
`;

const TooltipText = styled.div`
    flex-grow: 1;
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const Translated: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        children,
        element
    } = props;

    const typedElement = useMemo<ITranslated>(() => element as ITranslated, [element]);

    const tooltipText = useMemo(() => {
        return <TooltipContentWrapper>
            <TooltipTranslateIcon/>
            <TooltipText>{typedElement.translate}</TooltipText>
        </TooltipContentWrapper>;
    }, [typedElement])

    return <Wrapper style={style} {...attributes}>
        <Tooltip title={tooltipText} >
            {children}
        </Tooltip>
    </Wrapper>;
}