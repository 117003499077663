import React from 'react';
import styled from "styled-components";
import {BlockWithBorderCss} from "../../../../../styles/global-elements";
import classNames from "classnames";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ContentWidthWrapperCss} from "../../../../layout/styles";
import {withRouter} from "../../../../../components/WithRouterHoc";
import {SelectedSlideNameWrapper} from "../../../../../components/SelectedSlideNameWrapper";
import {LessonPlayerScoreCounter} from "../../../../../components/ScoreCounter";
import {PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";
import {SlidesList} from "../../../../../components/SlidesList";
import {LoadingStateEnum} from "../../../../../../store/lessonMaterials/type";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {NavigationButtons} from "./NavigationButtons";


const SlidesListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 78px;
  max-height: calc(100% - ${({theme}) => theme.size.headerHeight.toString(10) + 'px'});
  transform: translateY(100%) translateY(-78px);
  transition: transform 0.3s ease;
  user-select: none;
  z-index: ${({theme}) => theme.zIndices.bottomPageNavigation};

  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};

  &.deployed {
    transform: translateY(0);

    @media (${({theme}) => theme.media.large}) {
      transform: unset;
    }
  }

  @media (${({theme}) => theme.media.small}) {
    min-height: 92px;
    transform: translateY(100%) translateY(-92px);
  }

  @media (${({theme}) => theme.media.large}) {
    z-index: unset;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: unset;
    transition: unset;
    border-top: none;

    max-width: 344px;
    min-width: 344px;

    &.lower {
      transform: translateY(120px);
    }
  }
`;

const SlidesListContentWrapper = styled.div`
  ${ContentWidthWrapperCss};
  margin: auto;
  padding: 0 20px 16px 20px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  @media (${({theme}) => theme.media.small}) {
    padding: 0 0 16px 0;
  }

  @media (${({theme}) => theme.media.large}) {
    ${BlockWithBorderCss};
    padding: 11px 0;
    margin: 0;
    display: block;
    position: sticky;
    overflow: unset;
    max-height: unset;
    top: ${({theme}) => 40 + theme.size.headerHeight}px;
  };
`;

const ContentWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${({theme}) => theme.media.large}) {
    display: block;
    max-height: calc(100vh - 100px);
    margin-right: 6px;
  }
`;

const LessonPlayerScoreCounterWrapper = styled.div`
  margin-bottom: 15px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 0;
    padding: 21px 32px 40px 32px;
  }
`;

const NavigationButtonsStyled = styled(NavigationButtons)`
    @media (${({theme}) => theme.media.large}) {
        padding: 0 32px;
        margin-bottom: 20px;
    }
`;

interface HomeworkSlidesListProps extends PropsFromRedux {
    className?: string;
}

interface HomeworkSlidesListState {
    deployed: boolean;
    showOnBottom: boolean;
    windowBiggerThanSmall: boolean;
}

class HomeworkSlidesList extends React.Component<HomeworkSlidesListProps, HomeworkSlidesListState> {
    protected mediaQuerySmall;
    protected mediaQueryMedium;
    static contextType = PlayerContext;
    context!: React.ContextType<typeof PlayerContext>;

    constructor(props: Readonly<HomeworkSlidesListProps> | HomeworkSlidesListProps) {
        super(props);

        if (this.props.currentTheme === undefined) {
            throw new Error("currentTheme is undefined");
        }

        this.mediaQuerySmall = window.matchMedia(`(${this.props.currentTheme.media.large})`);
        this.mediaQueryMedium = window.matchMedia(`(${this.props.currentTheme.media.small})`);

        this.state = {
            deployed: false,
            showOnBottom: !this.mediaQuerySmall.matches,
            windowBiggerThanSmall: this.mediaQueryMedium.matches
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    protected onWindowResize = () => {
        if (this.mediaQuerySmall.matches) {
            if (this.state.showOnBottom) {
                this.setState(() => {
                    return {
                        showOnBottom: false,
                        deployed: false
                    }
                })
            }
        } else {
            if (!this.state.showOnBottom) {
                this.setState(() => {
                    return {
                        showOnBottom: true,
                        deployed: false
                    }
                })
            }
        }

        if (this.mediaQueryMedium.matches) {
            if (!this.state.windowBiggerThanSmall) {
                this.setState(() => {
                    return {
                        windowBiggerThanSmall: true
                    }
                })
            }
        } else {
            if (this.state.windowBiggerThanSmall) {
                this.setState(() => {
                    return {
                        windowBiggerThanSmall: false
                    }
                })
            }
        }
    }

    protected toggleDeployed = () => {
        if (!this.state.showOnBottom) {
            return;
        }

        const newState = !this.state.deployed;

        this.setState((state) => {
            return {
                deployed: newState
            }
        });
    }

    protected getNextSlideId = (): string | null => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return null;
        }

        if (this.context.selectedSlideIndex >= this.context.slides.length - 1) {
            return null;
        }

        if (this.context.slides[this.context.selectedSlideIndex + 1]) {
            return this.context.slides[this.context.selectedSlideIndex + 1].tmSlideId;
        }

        return null;
    }

    protected getPrevSlideId = (): string | null => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return null;
        }

        if (this.context.selectedSlideIndex <= 0) {
            return null;
        }

        if (this.context.slides[this.context.selectedSlideIndex - 1]) {
            return this.context.slides[this.context.selectedSlideIndex - 1].tmSlideId;
        }

        return null;
    }

    protected onSlideSelect = (slideId: string) => {
        this.context.setSelectedSlideId(slideId);
    }

    protected getCurrentSlideName = (): string => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return '';
        }

        return (this.context.slides[this.context.selectedSlideIndex])
            ? (this.context.slides[this.context.selectedSlideIndex].tmSlideName ?? '')
            : '';
    }

    render() {
        return <SlidesListWrapper className={classNames(this.props.className, this.state.deployed && "deployed")}>
            <SlidesListContentWrapper>
                {
                    (this.state.showOnBottom)
                    && <SelectedSlideNameWrapper nextSlideId={this.getNextSlideId()}
                                                 prevSlideId={this.getPrevSlideId()}
                                                 currentSlideId={this.context.selectedSlideId}
                                                 currentSlideName={this.getCurrentSlideName()}
                                                 onSlideSelect={this.onSlideSelect}
                                                 onClickByName={this.toggleDeployed}
                    />
                }
                <ContentWrapper>
                    <LessonPlayerScoreCounterWrapper onClick={this.toggleDeployed}>
                        <LessonPlayerScoreCounter onlyProgress={this.state.showOnBottom}/>
                    </LessonPlayerScoreCounterWrapper>
                    {
                        (this.props.lessonMaterialsLoadingState === LoadingStateEnum.INIT_LOADING)
                        && <DefaultLoader/>
                    }
                    {
                        (this.props.lessonMaterialsLoadingState !== LoadingStateEnum.INIT_LOADING)
                        && <div onClick={() => {
                            if (this.state.showOnBottom) {
                                this.toggleDeployed();
                            }
                        }}>
                            <SlidesList/>
                            <NavigationButtonsStyled exitBtnVisible={true}/>
                        </div>
                    }
                </ContentWrapper>
            </SlidesListContentWrapper>
        </SlidesListWrapper>
    }
}

const mapStateToProps = ({layout, lessonMaterials}: ApplicationState) => ({
    currentTheme: layout.activeTheme,
    lessonMaterialsLoadingState: lessonMaterials.loadingState,
    lessonMaterialsList: lessonMaterials.slidesList
});

const connector = connect(mapStateToProps, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(HomeworkSlidesList));
