import {IsBoolean, IsNumber, IsString, Max, MaxLength, Min} from "class-validator";

export class QueueItemAnswerPayload {
  @IsString()
  @MaxLength(3000)
  value: string;

  @IsNumber()
  @Max(100)
  @Min(0)
  award: number;

  @IsNumber()
  @Max(100)
  @Min(0)
  missedAward: number;

  @IsBoolean()
  answerIsCorrect: boolean;
}
