import * as React from "react";
import {HTMLAttributeAnchorTarget, useCallback, useMemo, useRef} from "react";
import {Popup as ReactjsPopup} from "reactjs-popup";
import {PopupActions as ReactjsPopupActions, PopupProps as ReactjsPopupProps} from "reactjs-popup/dist/types";

import styled from "styled-components";
import {RouterLink} from "./Link";

export const StyledPopup = styled(ReactjsPopup)`

`;

export const StyledPopupList = styled.ul`
  background-color: ${({theme}) => theme.colors.backgroundInverse};
  border-radius: 12px;
  list-style: none;
  padding: 12px 0;
`;

export const StyledPopupItemLink = styled(RouterLink)`
  color: inherit;
  text-decoration: inherit;
  font-size: inherit;
  padding: 12px 20px;
  display: block;

  &:hover {
    color: inherit;
  }

  &:focus {
    color: inherit;
  }
`;

export const StyledPopupItem = styled.li`
  padding: 12px 20px;
  color: ${({theme}) => theme.colors.textInverse};
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.colors.backgroundInverseHover};
  }
  
  &.with-link {
    padding: 0;
  }
`;

export interface PopupItemLinkConfig {
    link: string;
    target?: HTMLAttributeAnchorTarget
}

export interface PopupItem {
    text: string | JSX.Element;
    onClick?: () => void;
    href?: PopupItemLinkConfig;
}

type PopupProps = Omit<ReactjsPopupProps, 'children'> & React.RefAttributes<ReactjsPopupActions> & {
    items: PopupItem[],
    onClose?: () => void,
}

export const Popup = ({items, onClose, ...props}: PopupProps) => {
    const popup = useRef(null);

    const closeModal = useCallback(() => {
        const popupActions = (popup.current as unknown as ReactjsPopupActions | null);

        if (popupActions === null) {
            return;
        }

        popupActions.close();
    }, [popup]);

    const menuItems = useMemo(() => {
        const listItems = () => items.map((item, index) => {
            if (item.href !== undefined) {
                return <StyledPopupItem key={index} className={"with-link"}>
                    <StyledPopupItemLink onClick={closeModal} to={item.href.link} target={item.href.target}>{item.text}</StyledPopupItemLink>
                </StyledPopupItem>;
            }

            if (item.onClick) {
                return <StyledPopupItem key={index} onClick={item.onClick}>{item.text}</StyledPopupItem>;
            }

            return <StyledPopupItem key={index}>{item.text}</StyledPopupItem>;
        });

        return <StyledPopupList onClick={closeModal}>
            {listItems()}
        </StyledPopupList>
    }, [items, closeModal]);

    return (
        <StyledPopup {...props} ref={popup}>
            {menuItems}
        </StyledPopup>
    )
}
