import React from "react";

export interface IDocumentInPictureActionsContext {
    startDocumentInPicture: () => void;
    stopDocumentInPicture: () => void;
}

export const DocumentInPictureActionsContext = React.createContext<IDocumentInPictureActionsContext>({
    startDocumentInPicture: () => {},
    stopDocumentInPicture: () => {}
});

export const DocumentInPictureActionsContextProvider = DocumentInPictureActionsContext.Provider;
