import {instanceCachingFactory} from 'tsyringe';
import {IFeatureToggle} from "../components/FeatureToggle/IFeatureToggle";
import {FeatureToggle} from "../components/FeatureToggle/FeatureToggle";

export default instanceCachingFactory<IFeatureToggle>((dependencyContainer) => {
    const isDev = process.env.NODE_ENV === "development";
    const allFeatureUserId = process.env.REACT_APP_ALL_FEATURE_USER_ID ?? "";

    return new FeatureToggle(allFeatureUserId, isDev);
});
