import styled from "styled-components";
import React, {useCallback, useMemo, useRef} from "react";
import {ReactComponent as ArrowUpSvg} from "./icons/ArrowUp.svg";
import {ReactComponent as ArrowDownSvg} from "./icons/ArrowDown.svg";
import {ReactComponent as DeleteSvg} from "./icons/Delete.svg";
import {ReactComponent as SettingsSvg} from "./icons/Settings.svg";
import {ReactComponent as QuestionSvg} from "./icons/QuestionRounded.svg";
import {ReactComponent as CopySvg} from "../../Ui/Svg/Copy.svg";
import {ReactComponent as MagicSvg} from "../../Ui/Svg/Magic.svg";
import {t} from "@lingui/macro";
import {ConfirmDialog} from "../../Ui/Elements/ConfirmDialog";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ElementWithSettings} from "../elements/ElementWithSettings";
import {PopupActions} from "reactjs-popup/dist/types";
import classNames from "classnames";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {EditorItemDataParams} from "../../SlidePlayerEditorCommonParts/EditorData";
import {container} from "tsyringe";
import {IFeatureToggle} from "../../../../components/FeatureToggle/IFeatureToggle";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {FeaturesEnum} from "../../../../components/FeatureToggle/FeaturesEnum";
import {NotificationTypesEnum, openNotification} from "../../Ui/Elements/Notification";

const ElementControlPanelStyled = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.textTertiary};
  border-style: solid solid dotted solid;
  position: absolute;
  top: 0;
  right: -1px;
  transform: translateY(-100%);
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 10px 10px 0 0;

  &.controlsBottom:not(.always-visible) {
    top: auto;
    bottom: 0;
    transform: translateY(+100%);
    z-index: ${({theme}) => theme.zIndices.pageContent};
    border-radius: 0 0 10px 10px;
    border-style: dotted solid solid solid;
  }

  &.always-visible {
    transform: none;
    border: none;
    top: auto;
    bottom: 0;
    right: 3px;
  }
`;

const PanelItemStyled = styled.div`
  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer;
  height: 40px;

  :hover {
    opacity: 1;
  }
`;

interface ElementControlPanelProps {
    copyElement: () => void;
    moveUp: () => void;
    moveDown: () => void;
    deleteItem: () => void;
    editInteractivityConfig: () => void;
    className?: string;
    elementType: ElementTypeEnum;
    alwaysVisible?: boolean;
    elementParams: EditorItemDataParams;
}

export const ElementControlPanel: React.FC<ElementControlPanelProps> = (props) => {
    const {elementType, alwaysVisible} = props;

    const confirmDialog = useRef<PopupActions>(null);

    const featureToggle = useMemo(() => container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE), []);
    const interactivityConfigButtonAllowed = useMemo(() => featureToggle.featureIsAvailable(FeaturesEnum.INTERACTIVITY_CONFIG_IN_SLIDE_EDITOR), [featureToggle]);

    const supportSettings = useMemo<boolean>(() => {
        // @ts-ignore
        return ElementWithSettings.includes(elementType);
    }, [elementType]);

    const kbPageIdByElementType = useCallback((elementType: ElementTypeEnum):string|null => {
        switch (elementType) {
            case ElementTypeEnum.TEACHER_ALSO_DISCUSS:
            case ElementTypeEnum.TEACHER_CAN_SAY:
            case ElementTypeEnum.TEACHER_NOTES:{
                return '9a7964f5-f2e5-43f2-8e51-226b5f8be8b8';
            }
            case ElementTypeEnum.TITLE: {
                return '485039f9-9f6c-4c73-9c09-327762c98c9d';
            }
            case ElementTypeEnum.TEXT: {
                return 'b4d74dae-e2d2-4fe0-b075-5d1a356dca5b';
            }
            case ElementTypeEnum.UNFOLD_BLOCK: {
                return '39f718ba-d9c6-4a14-b812-63528d138a92';
            }
            case ElementTypeEnum.MEDIA_PICTURE: {
                return 'e97e3be6-3c61-4fff-963a-f3d5fd0010eb';
            }
            case ElementTypeEnum.MEDIA_VIDEO: {
                return 'da4bb66c-32fc-439d-9d64-bd04e885cdb9';
            }
            case ElementTypeEnum.MEDIA_AUDIO: {
                return '3ec4264b-a764-44f7-bafb-aaac6a78e574';
            }
            case ElementTypeEnum.MEDIA_YOUTUBE: {
                return '6a084501-bd24-4012-9acb-853017f25541';
            }
            case ElementTypeEnum.EXERCISES_RADIO: {
                return 'd39d5e51-3e58-4f6f-9e78-557f9d239e77';
            }
            case ElementTypeEnum.EXERCISES_CHECKBOX: {
                return '5844a4a2-7f32-4837-96a0-0600debdec5b';
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX: {
                return '4e6cc90f-606d-4bd0-95a9-ddbfb2bf6fc1';
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT: {
                return 'a5794e1e-4061-4f64-a4e4-889e95a38d5b';
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP: {
                return '386d1218-dc28-48e2-904d-f622b8a3375f';
            }
            case ElementTypeEnum.EXERCISES_MATCH: {
                return '365800e1-655b-458b-a408-b442c4b436ff';
            }
            case ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT: {
                return 'c94ecd27-31f4-4855-855f-bef43469a3e1';
            }

            default:{
                return null
            }
        }
    }, []);

    const openInstructions = useCallback(() => {
        const pageId = kbPageIdByElementType(elementType);

        if (!pageId) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Нет информации об элементе`,
                t`Это экспериментальный элемент, справка ещё не готова.`
            );

            return;
        }

        window.open(RoutesHelper.replaceParams(
            RoutesList.TEACHER_KNOWLEDGE_BASE_PAGE,
            [
                {
                    key: 'pageId',
                    value: pageId
                }
            ]
        ), '_blank');
    }, [elementType, kbPageIdByElementType]);

    return <ElementControlPanelStyled className={classNames(props.className, alwaysVisible && 'always-visible')}>
        <PanelItemStyled onMouseUp={openInstructions} title={t`Узнать подробнее об элементе`}><QuestionSvg/></PanelItemStyled>

        {
            (interactivityConfigButtonAllowed)
            && <PanelItemStyled
                onMouseUp={() => props.editInteractivityConfig()}
                title={t`Настройки интерактивности элемента`}
            ><MagicSvg/></PanelItemStyled>
        }

        <PanelItemStyled onMouseUp={() => props.copyElement()} title={t`Скопировать элемент в буфер`}><CopySvg/></PanelItemStyled>
        <PanelItemStyled onMouseUp={() => props.moveUp()} title={t`Переместить элемент выше`}><ArrowUpSvg/></PanelItemStyled>
        <PanelItemStyled onMouseUp={() => props.moveDown()}  title={t`Переместить элемент ниже`}><ArrowDownSvg/></PanelItemStyled>
        <PanelItemStyled onMouseUp={() => confirmDialog.current?.open()}  title={t`Удалить элемент`}><DeleteSvg/></PanelItemStyled>
        <ConfirmDialog ref={confirmDialog}
                       okText={t`Удалить`}
                       cancelText={t`Отмена`}
                       title={t`Удалить элемент?`}
                       errorText={t`Не удалось удалить элемент`}
                       okMethod={() => {
                           props.deleteItem();
                           return Promise.resolve();
                       }}/>
        {(supportSettings) && <PanelItemStyled><SettingsSvg/></PanelItemStyled>}
    </ElementControlPanelStyled>;
}
