import React from "react";
import {ReactComponent as CheckboxCheckedSvg} from "../../../components/Ui/Svg/CheckboxChecked.svg";
import {ReactComponent as CheckboxUncheckedSvg} from "../../../components/Ui/Svg/CheckboxUnchecked.svg";
import styled from "styled-components";

const CheckCheckedStyled = styled(CheckboxCheckedSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
`;

const CheckUncheckedStyled = styled(CheckboxUncheckedSvg)`
  color: ${({theme}) => theme.colors.textTertiary};
`;

export interface CheckIconProps {
    checked: boolean;
    className?: string;
    onClick?: () => void;
}

export const CheckIcon: React.FC<CheckIconProps> = (props) => {
    if (props.checked) {
        return <CheckCheckedStyled className={props.className} onClick={props.onClick}/>
    }

    return <CheckUncheckedStyled className={props.className} onClick={props.onClick}/>;
}