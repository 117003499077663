import React from "react";
import styled from "styled-components";
import {ReactComponent as SearchIcon} from "../../../../Ui/Svg/Search.svg";
import {BlockWithBorderCss} from "../../../../../styles/global-elements";
import {Input} from "../../TextInput";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    margin-bottom: 30px;
  }
`;

const SearchByNameFilter = styled.div`
  ${BlockWithBorderCss};

  padding: 3px 10px;
  flex-grow: 1;

  display: flex;
  flex-direction: row;
`;

const SearchByNameIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
`;

const SearchByNameInput = styled(Input)`
  border-bottom: none !important;
`;

interface FiltersProps {
    placeholder: string;
    searchString: string;
    setSearchString: (newSearchString: string) => void;
}

// TODO: Заменить на ChipsFilterArea
export const SingleNameFilter: React.FC<FiltersProps> = ({placeholder, searchString, setSearchString}) => {
    return <FilterWrapper>
        <SearchByNameFilter>
            <SearchByNameIcon><SearchIcon/></SearchByNameIcon>
            <SearchByNameInput placeholder={placeholder}
                               value={searchString}
                               onChange={(e) => setSearchString(e.target.value)}/>
        </SearchByNameFilter>
    </FilterWrapper>;
}
