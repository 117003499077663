import React from "react";
import styled, {css} from "styled-components";
import classNames from "classnames";
import {PageNavigationBarProps} from "../CommonTypes";
import {RouterLink} from "../../Ui/Elements/Link";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px 0 12px 0;
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  justify-content: space-around;
  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};
  z-index: ${({theme}) => theme.zIndices.bottomPageNavigation};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
`;

const Item = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 40px;
`;

const ItemAsDiv= styled.div`
  ${Item};
`;

const ItemAsLink = styled(RouterLink)`
  ${Item};
`;

const ItemIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ItemIconBg = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  bottom: 0;
  top: 0;
  border-radius: 12px;
  z-index: -1;
  margin: auto;
  width: 50px;
  
  &.active {
    opacity: 1;
  }
`;

const ItemTextWrapper = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  font-size: 10px;
  text-align: center;
`;

export const BottomNavigationBar: React.FC<PageNavigationBarProps> = ({items, selectedItemKey, onSelectItem}) => {
    return <Wrapper>
        {
            items.map((item) => {
                if (item.link) {
                    return <ItemAsLink key={item.key} to={item.link}>
                        <ItemIconWrapper>
                            <ItemIconBg className={classNames(item.key === selectedItemKey && 'active')}/>
                            {item.icon}
                        </ItemIconWrapper>
                        <ItemTextWrapper>
                            {item.text}
                        </ItemTextWrapper>
                    </ItemAsLink>
                }

                return <ItemAsDiv key={item.key} onClick={() => onSelectItem(item.key)}>
                    <ItemIconWrapper>
                        <ItemIconBg className={classNames(item.key === selectedItemKey && 'active')}/>
                        {item.icon}
                    </ItemIconWrapper>
                    <ItemTextWrapper>
                        {item.text}
                    </ItemTextWrapper>
                </ItemAsDiv>
            })
        }
    </Wrapper>
}
