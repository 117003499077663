export enum AnswerTypeEnum {
    BAD = 1,
    NORMAL = 2,
    GOOD = 3
}

export enum AnswerTypeEmojiEnum {
    BAD = "😕",
    NORMAL = "😐",
    GOOD = "😃",
}