import {IsNotEmpty, IsString} from "class-validator";

/**
 * DTO базовых данных урока по id урока
 */
export class DtoLessonDataResponse {
    @IsNotEmpty()
    @IsString()
    id: string;

    @IsNotEmpty()
    @IsString()
    teacherId: string;

    @IsNotEmpty()
    @IsString()
    groupId: string;

    @IsNotEmpty()
    @IsString()
    lessonDate: string;

    @IsNotEmpty()
    @IsString()
    timeStart: string;

    @IsNotEmpty()
    @IsString()
    timeEnd: string;

    @IsNotEmpty()
    @IsString()
    breakTime: string;

    @IsString()
    comment: string;

    @IsString()
    homeTask: string;

    @IsNotEmpty()
    @IsString()
    isFake: string;

    @IsNotEmpty()
    @IsString()
    isDraft: string;

    @IsNotEmpty()
    @IsString()
    groupName: string;

    @IsNotEmpty()
    @IsString()
    teacherName: string;
}
