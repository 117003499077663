import * as React from "react";
import {useContext} from "react";
import {ModalResultType, TmSlideModal} from "../navigation/slides-list/modal";
import {DtoTmSlide} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {ReactComponent as PencilSvg} from "../../../../../components/Ui/Svg/Pencil.svg"
import {EditorContext, IEditorContext} from "../EditorContext";
import {t} from "@lingui/macro";

export interface EditSlideBtnProps {
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const EditSlideBtn: React.FC<EditSlideBtnProps> = (props) => {
    const editorContext = useContext<IEditorContext>(EditorContext);

    const {slides, setSlides, selectedSlideId, selectedSlide, lessonId, setSelectedSlideId} = editorContext;

    const updateItemInSlidesPropsList = (newModel: DtoTmSlide) => {
        if (slides === null || setSlides === null) {
            return;
        }

        const newSlidesList = slides.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        setSlides(newSlidesList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromSlidesPropsList = () => {
        if (slides === null || setSlides === null || selectedSlideId === null) {
            return;
        }

        const newSlidesList = slides.filter((item) => (
            (selectedSlideId !== item.id)
        ));

        setSlides(newSlidesList);
        setSelectedSlideId(null);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmSlide | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after slide updated.');
            }

            updateItemInSlidesPropsList(data);

            return;
        }

        deleteCurrentItemFromSlidesPropsList();
    }

    return (
        <TmSlideModal
            lessonId={lessonId}
            slideItem={selectedSlide}
            result={onResult}
            trigger={<PencilSvg title={t`Редактировать информацию о слайде`}/>}/>
    );
}