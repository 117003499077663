import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import React from "react";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {
    DtoStudentEntranceInfoResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/Students/DtoStudentEntranceInfoResponse";

export interface IStudentPageContext {
    studentGeneralInfoDto: DtoStudentGeneralInfo;
    setStudentGeneralInfoDto: (studentGeneralInfoDto: DtoStudentGeneralInfo) => void;

    studentEntranceInfoResponse: DtoStudentEntranceInfoResponse | null;

    setStudentEntranceInfoDto: (studentEntranceInfoResponse: DtoStudentEntranceInfoResponse) => void;

    getLinkToSection: (sectionId: PageSectionsEnum) => string;

    reloadBaseInfo: () => void;
}

export const StudentPageContext = React.createContext<IStudentPageContext>({
    studentGeneralInfoDto: new DtoStudentGeneralInfo(),
    setStudentGeneralInfoDto: studentGeneralInfoDto => {
    },
    studentEntranceInfoResponse: new DtoStudentEntranceInfoResponse(),
    setStudentEntranceInfoDto: DtoStudentEntranceInfoResponse => {
    },
    getLinkToSection: (sectionId) => {
        return ''
    },
    reloadBaseInfo: () => {}
});

export const StudentPageContextProvider = StudentPageContext.Provider;
