import React from "react";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";

interface NoOrganizationsMessageProps {
    readonly: boolean;
}

export const NoOrganizationsMessage: React.FC<NoOrganizationsMessageProps> = (props) => {
    if (props.readonly) {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle><Trans>Обучающие материалы ещё не созданы</Trans></NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>Вы можете создать материалы самостоятельно в конструкторе или получить их
                        от своего партнёра или от владельца франшизы, по которой вы работаете
                        (если вы франчайзи).</Trans><br/><br/>
                </NoticeBlockText>
                <NoticeBlockText>
                    <Trans>Чтобы воспользоваться конструктором и создать собственные материалы, перейдите в раздел
                        «Учебные материалы»</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    return <NoticeBlock>
        <>
            <NoticeBlockTitle><Trans>Обучающие материалы ещё не созданы</Trans></NoticeBlockTitle>
            <NoticeBlockText>
                <Trans>Вы можете создать материалы самостоятельно в конструкторе или получить их
                    от своего партнёра или от владельца франшизы, по которой вы работаете
                    (если вы франчайзи).</Trans><br/><br/>
            </NoticeBlockText>
            <NoticeBlockText>
                <Trans>У всех материалов есть владелец. Поэтому чтобы создать собственные обучающие материалы,
                    нажмите «Создать владельца материалов». Нужно будет указать имя владельца: ваше имя,
                    название вашей организации или любой другой текст. Его не будет видно ученикам.
                    Это нужно лишь один раз в начале работы.</Trans>
            </NoticeBlockText>
        </>
    </NoticeBlock>
}