import {IsNotEmpty, IsString} from "class-validator";

export class DtoVideoRoomParams {

    @IsNotEmpty()
    @IsString()
    turnServerEntrypoint: string;

    @IsNotEmpty()
    @IsString()
    turnServerTlsEntrypoint: string;

    @IsNotEmpty()
    @IsString()
    turnServerLogin: string;

    @IsNotEmpty()
    @IsString()
    turnServerPassword: string;

    @IsNotEmpty()
    @IsString()
    janusWsApiEntrypoint: string;

    @IsNotEmpty()
    @IsString()
    janusApiKey: string;

    @IsNotEmpty()
    @IsString()
    janusRoomId: string;

    @IsNotEmpty()
    @IsString()
    janusJoinKey: string;
}
