import {withRouter, WithRouterProps} from "../../../../components/WithRouterHoc";
import React from "react";
import {RoutesList} from "../../../../RoutesList";
import {PageBackLink} from "../../../../components/Ui/Elements/PageBackLink";
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import TeachingMaterialsSectionDetails from "../../../../components/TeachingMaterialsCatalog/section-details";
import {ApplicationState} from "../../../../../store";
import {connect} from "react-redux";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";

interface RouterParams {
    tmLevelId: string;
    tmSectionId: string;
}

type TeachingMaterialsSectionDetailsPagePropsWRouter = WithRouterProps<RouterParams>;

interface TeachingMaterialsSectionDetailsPageState {
    linkToLevelPage: string;
}

class TeachingMaterialsSectionDetailsPage extends React.Component<TeachingMaterialsSectionDetailsPagePropsWRouter, TeachingMaterialsSectionDetailsPageState> {

    constructor(props: Readonly<TeachingMaterialsSectionDetailsPagePropsWRouter> | TeachingMaterialsSectionDetailsPagePropsWRouter) {
        super(props);

        this.state = {
            linkToLevelPage: "#"
        }
    }

    componentDidMount() {
        if (this.props.match.params.tmSectionId.trim() === "") {
            this.props.navigate(RoutesList.MAIN_PAGE);

            return;
        }

        this.setState(() => {
            return {
                linkToLevelPage: RoutesHelper.replaceParams(
                    RoutesList.TEACHER_TEACHING_MATERIALS_LEVEL_DETAILS,
                    [
                        {
                            key: 'tmLevelId',
                            value: this.props.match.params.tmLevelId
                        }
                    ]
                )
            }
        })
    }

    render() {
        return <div>
            <PageBackLink link={this.state.linkToLevelPage}/>
            <PageTitle><Trans>Учебные материалы</Trans></PageTitle>
            {
                (this.props.match.params.tmSectionId.trim() !== "")
                && <TeachingMaterialsSectionDetails sectionId={this.props.match.params.tmSectionId}/>
            }
        </div>
    }
}

const mapStateToProps = ({user}: ApplicationState) => ({
    apiToken: user.sessionToken,
});

export default withRouter(connect(mapStateToProps)(TeachingMaterialsSectionDetailsPage));