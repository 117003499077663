import React, {useContext} from "react";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {useSelector} from "react-redux";
import {sessionTokenSelector, stTokenSelector} from "../../../../../../store/app/selector";
import {cloneDeep} from "lodash";
import {container} from "tsyringe";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {NoConnection} from "../../../../../../components/HttpApiClient/Exception/NoConnection";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";

export const InviteUserBtn: React.FC = () => {
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
    const token = useSelector(sessionTokenSelector);
    const stToken = useSelector(stTokenSelector);

    const updateLkParamsInUser = async () => {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (!token || !studentPageContext || !stToken) {
            logger.error(LoggerSectionsEnum.STUDENT_PROFILE_LK_SECTION, 'token or studentPageContext or stToken is empty');

            throw new Error('token or studentPageContext or stToken is empty');
        }

        const saveDto = cloneDeep(studentPageContext.studentGeneralInfoDto);
        saveDto.enablePersonalCabinet = true;
        saveDto.enablePayOnline = true;

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        try {
            await stApiClient.saveStudentProfile(
                stToken,
                saveDto
            );

            studentPageContext.setStudentGeneralInfoDto(saveDto);
        } catch (err) {
            if (err instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Проверьте интернет соединение`,
                );

                throw new Error();
            }

            logger.error(
                LoggerSectionsEnum.STUDENT_PROFILE_LK_SECTION,
                'Error on update params for invite user to lk.',
                saveDto,
                err
            );

            throw new Error();
        }
    }

    const sendInvitationToUser = async () => {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (!token || !studentPageContext) {
            logger.error(LoggerSectionsEnum.STUDENT_PROFILE_LK_SECTION, 'token or studentPageContext is empty');

            throw new Error('token or studentPageContext is empty');
        }

        if (
            (!studentPageContext.studentGeneralInfoDto.enablePayOnline)
            || (!studentPageContext.studentGeneralInfoDto.enablePersonalCabinet)
        ) {
            await updateLkParamsInUser();
        }

        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        try {
            await apiClient.sendInvitationToStudent(token, studentPageContext.studentGeneralInfoDto.email);

            studentPageContext.reloadBaseInfo();
        } catch (err) {
            if (err instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Проверьте интернет соединение`,
                );

                throw new Error();
            }

            logger.error(
                LoggerSectionsEnum.STUDENT_PROFILE_LK_SECTION,
                'Error on call sendInvitationToStudent.',
                err
            );

            throw new Error();
        }
    }

    return <div>
        <ConfirmDialog
            okText={t`Пригласить`}
            cancelText={t`Отмена`}
            title={t`Пригласить ученика`}
            text={<>
                <Trans>Ученику будет отправлен email с логином, простым паролем (который он при желании позднее сможет
                    сменить) и ссылкой для быстрого входа без пароля.</Trans>
                <br/>
                <br/>
                <Trans>Также после отправки приглашения вы сможете получить и отправить в любой мессенджер ученику
                    ссылку для быстрого входа, чтобы не терять время на поиск email в ящике ученика.</Trans>
            </>}
            okMethod={sendInvitationToUser}
            trigger={
                <Button btnStyle={BtnStyleEnum.Primary}>
                    <Trans>Отправить приглашение</Trans>
                </Button>
            }/>
    </div>
}
