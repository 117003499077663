/**
 * Токены контейнера DI.
 */
export enum DiTokens {
    SENTRY_LOGGER = 'sentryLogger',
    LOGGER = 'logger',
    CUSTOM_STORAGE = 'customStorage',
    CLIPBOARD_HELPER = 'clipboardHelper',
    INTERNET_CONNECTION_CHECKER = 'internetConnectionChecker',
    DEVICE_DETECTOR = 'deviceDetector',
    SCREEN_SAVER_LOCK = 'screenSaverLock',
    PAGES_BROADCAST_SERVICE = 'pagesBroadcastService',
    HTTP_API_CLIENT = 'apiClient',
    STONLINE_CLIENT = 'stonlineClient',
    HTTP_CLIENT = 'httpClient',
    DEVICE_MEDIA_STREAM_FETCHER = 'deviceMediaStreamFetcher',
    DEVICE_ORIENTATION = 'deviceOrientation',
    APP_VERSION_HELPER = 'appVersionHelper',
    LOCALE_DICTIONARY_DOWNLOADER = 'localeDictionaryDownloader',
    GROUP_SCHEDULE_READER = 'groupScheduleReader',
    I18N_SERVICE = 'i18nService',
    DATE_HELPER_SERVICE = 'dateHelperService',
    STORE_SERVICE = 'storeService',
    SOUND_PLAYER = 'soundPlayer',
    WS_RESPONSE_ROUTER = 'wsResponseRouter',
    WS_STREAM_EVENT_SERVICE = 'wsStreamEventService',
    WS_CLIENT = 'wsClient',
    WS_DISCONNECTOR = 'wsDisconnector',
    JANUS_CONNECTION = 'janusConnection',
    FEATURE_TOGGLE = 'featureToggle',
    ROUTER_SERVICE = 'routerService',
    ANSWER_CHECKER = 'answerChecker',
    MEDIA_DEVICE_SERVICE = 'mediaDeviceService',
    SLIDE_PLAYER_PATTERN_HELPER = 'slidePlayerPatternHelper'
}
