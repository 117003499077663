import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {LessonPlayerScoreCounter} from "../../../../../components/ScoreCounter";
import {SlidesList as SlidesListOriginal} from "../../../../../components/SlidesList";
import {BlockWithBorderCss} from "../../../../../styles/global-elements";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";
import {ContentWidthWrapperCss} from "../../../../layout/styles";
import {SelectedSlideNameWrapper} from "../../../../../components/SelectedSlideNameWrapper";
import {IPlayerContext, PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";

const PlayerNavigationStyled = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 70px;
  max-height: calc(100% - ${({theme}) => theme.size.headerHeight.toString(10) + 'px'});
  transform: translateY(100%) translateY(-70px);
  transition: transform 0.3s ease;
  user-select: none;
  z-index: ${({theme}) => theme.zIndices.bottomPageNavigation};

  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};

  &.deployed {
    transform: translateY(0);

    @media (${({theme}) => theme.media.large}) {
      transform: unset;
    }
  }

  @media (${({theme}) => theme.media.small}) {
    min-height: 92px;
    transform: translateY(100%) translateY(-92px);
  }

  @media (${({theme}) => theme.media.large}) {
    z-index: unset;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: unset;
    transition: unset;
    border-top: none;
  }
`;

const PlayerNavigationContentWrapper = styled.div`
  ${ContentWidthWrapperCss};
  margin: auto;
  padding: 0 20px 16px 20px;


  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  @media (${({theme}) => theme.media.small}) {
    padding: 0 0 16px 0;
  }

  @media (${({theme}) => theme.media.large}) {
    ${BlockWithBorderCss};
    padding: 0;
    margin: 0;
    display: block;
    position: sticky;
    overflow: unset;
    max-height: unset;
    top: ${({theme}) => 100 + theme.size.headerHeight}px;
  };
`;

const LessonPlayerScoreCounterWrapper = styled.div`
  margin-bottom: 15px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 0;
    padding: 32px 32px 40px 32px;
  }
`;

const SlidesList = styled(SlidesListOriginal)`
  overflow: auto;
  flex-grow: 1;

  @media (${({theme}) => theme.media.large}) {
    display: block;
    max-height: calc(100vh - 400px);
    margin-right: 6px;
  }
`;

export interface NavigationProps {
    className?: string;
}

export const PlayerNavigation: React.FC<NavigationProps> = (props) => {
    const [deployed, setDeployed] = useState<boolean>(false);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const {selectedSlideId, setSelectedSlideId, slides, selectedSlideIndex, selectedSlide} = playerContext;

    const nextSlideId = useMemo<string | null>(() => {
        if ((slides === null) || (slides.length === 0) || (selectedSlideIndex === null)) {
            return null;
        }

        if (selectedSlideIndex >= slides.length - 1) {
            return null;
        }

        return slides[selectedSlideIndex + 1].tmSlideId;
    }, [slides, selectedSlideIndex]);

    const prevSlideId = useMemo<string | null>(() => {
        if ((slides === null) || (slides.length === 0) || (selectedSlideIndex === null)) {
            return null;
        }

        if (selectedSlideIndex <= 0) {
            return null;
        }

        return slides[selectedSlideIndex - 1].tmSlideId;
    }, [slides, selectedSlideIndex]);

    const onSlideSelect = (itemId: string) => {
        setSelectedSlideId(itemId);
    }

    const toggleDeployed = useCallback(() => {
        if (!isLarge) {
            setDeployed((value) => !value);
        }
    }, [isLarge]);

    useEffect(() => {
        if (deployed && isLarge) {
            setDeployed(false);
        }
    }, [deployed, isLarge]);

    useEffect(() => {
        if (deployed) {
            setDeployed(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSlideId]);

    return <PlayerNavigationStyled className={classNames(props.className, deployed && "deployed")}>
        <PlayerNavigationContentWrapper>
            <div>
                {
                    (!isLarge && selectedSlide) && <SelectedSlideNameWrapper
                        prevSlideId={prevSlideId}
                        nextSlideId={nextSlideId}
                        onSlideSelect={onSlideSelect}
                        currentSlideId={selectedSlideId}
                        onClickByName={toggleDeployed}
                        currentSlideName={selectedSlide?.tmSlideName}/>
                }
                <LessonPlayerScoreCounterWrapper onClick={toggleDeployed}>
                    <LessonPlayerScoreCounter onlyProgress={!isLarge}/>
                </LessonPlayerScoreCounterWrapper>
            </div>
            <SlidesList/>
        </PlayerNavigationContentWrapper>
    </PlayerNavigationStyled>;
};