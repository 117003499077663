import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO ответа на запрос общей информации о домашнем задании
 */
export class DtoHometaskOverviewResponse {
    @IsOptional()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    lessonId: string;

    @IsNotEmpty()
    @IsString()
    lessonDate: string;

    @IsOptional()
    @IsString()
    teacherText: string | null;

    @IsNotEmpty()
    @IsBoolean()
    hasExercises: boolean;

    @IsNotEmpty()
    @IsNumber()
    slidesCount: number;

    @IsNotEmpty()
    @IsBoolean()
    exercisesStarted: boolean;

    @IsNotEmpty()
    @IsNumber()
    exercisesCount: number;

    @IsNotEmpty()
    @IsBoolean()
    exercisesCompleted: boolean;
}
