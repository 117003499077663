import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {
    DtoGroupSchedule
} from "../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {WeekDayNameByNumber, WeekDayType} from "../Ui/Elements/TextPresenter/WeekDayNameByNumber";
import {container} from "tsyringe";
import {DiTokens} from "../../../di-factory/DiTokens";
import {Trans} from "@lingui/macro";
import {RegularText, RegularTextCss} from "../../styles/global-elements";
import {IGroupScheduleReader} from "../GroupScheduleReader/IGroupScheduleReader";
import {
    GroupScheduleReaderResultItemType
} from "../GroupScheduleReader/GroupScheduleReaderResultItemType";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5px;
`;

const DayNameWrapper = styled.div`
  ${RegularTextCss};
  flex-grow: 1;
`;

const TimeWrapper = styled.div`
  ${RegularTextCss};
  min-width: 120px;
  max-width: 120px;
  text-align: right;
`;

interface ScheduleTableProps {
    schedule: DtoGroupSchedule | null;
}

export const SmallVerticalScheduleTable: React.FC<ScheduleTableProps> = ({schedule}) => {
    const groupScheduleReader = useMemo<IGroupScheduleReader>(
        () => container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER),
        []
    );

    const scheduleItems = useMemo<GroupScheduleReaderResultItemType[]>(() => {
        if (schedule === null) {
            return [];
        }

        return groupScheduleReader.readSchedule(schedule);
    }, [groupScheduleReader, schedule]);

    const prepareRowItem = useCallback((weekDayNum: number, timeStart: string, timeEnd: string) => {
        return <Row key={weekDayNum}>
            <DayNameWrapper>
                <WeekDayNameByNumber weekDayNumber={weekDayNum} nameType={WeekDayType.NOMINATIVE}/>
            </DayNameWrapper>
            <TimeWrapper>
                {timeStart}&nbsp;-&nbsp;{timeEnd}
            </TimeWrapper>
        </Row>
    }, []);

    if (scheduleItems.length === 0) {
        return <Wrapper><RegularText><Trans>Расписание не задано</Trans></RegularText></Wrapper>;
    }

    return <Wrapper>
        {
            scheduleItems.map((item) => {
                return prepareRowItem(
                    item.weekDayNum,
                    item.timeStart,
                    item.timeEnd
                )
            })
        }
    </Wrapper>
}