import React, {useCallback, useContext, useMemo, useRef} from "react";
import {IButtonBaseProps} from "./IButtonBaseProps";
import {ControlPanelItem, ControlPanelItemIcon} from "../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as PictureInPicture} from "../../../Ui/Elements/ControlPanel/icons/PictureInPicture.svg";
import {
    ReactComponent as OutFromPictureInPicture
} from "../../../Ui/Elements/ControlPanel/icons/OutFromPictureInPicture.svg";
import classNames from "classnames";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {t} from "@lingui/macro";
import {MessageBox} from "../../../Ui/Elements/MessageBox";
import {PopupActions} from "reactjs-popup/dist/types";
import {ConferenceModeEnum} from "../../Types";
import {
    DocumentInPictureActionsContext,
    IDocumentInPictureActionsContext
} from "../../actions/document-in-picture/DocumentInPictureActionsContext";

export const DocumentInPictureBtn: React.FC<IButtonBaseProps> = () => {
    const unsupportedModalRef = useRef<PopupActions>(null);

    const commonContext = useContext<ICommonContext>(CommonContext);

    const documentInPictureActionsContext = useContext<IDocumentInPictureActionsContext>(DocumentInPictureActionsContext);

    const onClick = useCallback(() => {
        if (!('documentPictureInPicture' in window)) {
            unsupportedModalRef.current?.open();

            return;
        }

        if (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE) {
            documentInPictureActionsContext.stopDocumentInPicture();
        } else {
            documentInPictureActionsContext.startDocumentInPicture();
        }
    }, [commonContext.currentMode, documentInPictureActionsContext]);

    const btnDisabled = useMemo<boolean>(() => (
            commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED
        ),
        [commonContext.lessonConferenceState]
    );

    return <ControlPanelItem
        className={classNames('panel-item', btnDisabled && 'disabled')}
        onClick={onClick}
    >
        <ControlPanelItemIcon className={classNames('no-margin')}>
            {
                (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE)
                    ? <OutFromPictureInPicture/>
                    : <PictureInPicture/>
            }
        </ControlPanelItemIcon>
        <MessageBox
            ref={unsupportedModalRef}
            title={t`Нужен Google Chrome версии 116`}
            text={t`Новый режим "Картинка в картинке" поддерживается только в Google Chrome, выпущенном после сентября 2023 (версия 116). Воспользуйтесь Google Chrome версии 116 и старше для использования этой функции.`}/>
    </ControlPanelItem>
}
