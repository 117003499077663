import {
    IsString,
    Length,
    IsNotEmpty, IsOptional,
} from 'class-validator';
import {LengthEnum} from '../../../../Enums/LengthEnum';

/**
 * DTO параметров оценки работы ученика в школе
 */
export class ActualParamsDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.ACTUAL_PARAM_NAME_MAX_LENGTH)
    first: string;

    @IsOptional()
    @IsString()
    @Length(1, LengthEnum.ACTUAL_PARAM_NAME_MAX_LENGTH)
    second: string | null;

    @IsOptional()
    @IsString()
    @Length(1, LengthEnum.ACTUAL_PARAM_NAME_MAX_LENGTH)
    third: string | null;

    @IsOptional()
    @IsString()
    @Length(1, LengthEnum.ACTUAL_PARAM_NAME_MAX_LENGTH)
    fourth: string | null;

    @IsOptional()
    @IsString()
    @Length(1, LengthEnum.ACTUAL_PARAM_NAME_MAX_LENGTH)
    fifth: string | null;
}
