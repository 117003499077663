export enum LessonRoomsActionTypes {
    HANDLE_GET_OPENED_ROOMS_RESPONSE = '@@app/HANDLE_GET_OPENED_ROOMS_RESPONSE',
    HANDLE_SE_LESSON_ROOM_OPENED = '@@app/HANDLE_SE_LESSON_ROOM_OPENED',
    HANDLE_SE_LESSON_ROOM_KICKED = '@@app/HANDLE_SE_LESSON_ROOM_KICKED',
    HANDLE_SE_LESSON_ROOM_CLOSED = '@@app/HANDLE_SE_LESSON_ROOM_CLOSED',

    ADD_OPENED_LESSON_ROOMS = '@@app/ADD_OPENED_LESSON_ROOMS',
    REMOVE_OPENED_LESSON_ROOM = '@@app/REMOVE_OPENED_LESSON_ROOM',
}

export interface OpenedLessonRoom {
    roomId: string;
    teacherId: string;
    teacherName: string;
    teacherAvatarFileId: string | null;
}

export interface OpenedLessonRooms {
    list: OpenedLessonRoom[];
}

export interface LessonRoomsState {
    openedLessonRooms: OpenedLessonRooms,
}