import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import styled from "styled-components";
import {forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {Avatar} from "../../../Ui/Elements/Avatar";
import {NodesToPlainText} from "../../../SlideEditor/components/text-editor/NodesToPlainText";
import {Descendant} from "slate";
import {BubbleContent, BubbleContentMethods} from "./BubbleContent";
import classNames from "classnames";
import {DefaultLoader} from "../../../DefaultLoader";
import {Trans} from "@lingui/macro";
import {UserFileFormatsEnum} from "../../../../../enums/UserFileEnums";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {EditorItemInteractivityConfigWithParentItem} from "../../../SlidePlayerEditorCommonParts/EditorData";

const WrapperStyle = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
`;

const AvatarWrapper = styled.div`
    max-width: 64px;
    max-height: 64px;
    min-width: 64px;
    min-height: 64px;
`;

const BubbleWrapper = styled.div`
    flex-grow: 1;
`;

const BubbleContentStyled = styled(BubbleContent)`
    &.hidden {
        opacity: 0;
    }
`;

const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const Bubble = styled.div`
    position: relative;
    width: 100%;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    border: ${({theme}) => theme.colors.accentDivider} solid 1px;
    color: ${({theme}) => theme.colors.textPrimary};

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent ${({theme}) => theme.colors.backgroundPrimary};
        display: block;
        width: 0;
        z-index: 1;
        left: -9px;
        top: 17px;
    }

    &::before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 11px 11px 11px 0;
        border-color: transparent ${({theme}) => theme.colors.accentDivider};
        display: block;
        width: 0;
        z-index: 0;
        left: -11px;
        top: 16px;
    }
`;

const BubbleOverflowWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 10px;
    position: relative;
    border-radius: 15px;
`;

enum LoadingState {
    LOADING,
    READY,
    ERROR
}

export type DialogBubbleType = {
    name: CustomElement[];
    text: CustomElement[];
    avatarFileId: string | null;
    soundFileId: string | null;
}

interface DialogBubbleProps extends IElementProps<DialogBubbleType> {
}

interface DialogBubbleRefMethods extends IElementRefMethods {
}

export const DialogBubble = forwardRef<DialogBubbleRefMethods, DialogBubbleProps>(
    (props, ref) => {

        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        const bubbleContentRef = useRef<BubbleContentMethods>(null);

        const {elementData} = props;

        const [loadingState, setLoadingState] = useState<LoadingState>(() => {
            return elementData.data.soundFileId ? LoadingState.LOADING : LoadingState.READY;
        });

        const currentExerciseWorkData = useMemo<ExerciseWorkData | null>(() => {
            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[elementData.id];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
        }, [elementData.id, slideItemWorkContext.slideItemWorkData]);

        const nameForAvatar = useMemo<string>(() => {
            if (elementData.data.name as unknown as string !== '') {
                return NodesToPlainText.convertNodesToPlainText(elementData.data.name as Descendant[]);
            }

            return '';
        }, [elementData.data.name]);

        const markAsCompleted = useCallback(() => {
            if (playerContext.selectedSlide === null) {
                return;
            }

            if (slideItemWorkContext.readOnly === true || slideItemWorkContext.showCorrectAnswers === true) {
                return;
            }

            if (currentExerciseWorkData === null || currentExerciseWorkData.completed !== true) {
                // Сохраняем выполнение
                slideItemWorkContext.saveExerciseAnswer(
                    elementData.id,
                    '',
                    100,
                    0,
                    playerContext.selectedSlide.exercisesCount,
                    true
                );

                // Передаём информацию о действия, которые нужно выполнить
                if ((playerContext.setInteractivityConfig !== null) && (elementData.interactivityConfig !== null)) {
                    playerContext.setInteractivityConfig(
                        new EditorItemInteractivityConfigWithParentItem(elementData.id, elementData.interactivityConfig)
                    );
                }
            }

        }, [currentExerciseWorkData, elementData.id, elementData.interactivityConfig, playerContext, slideItemWorkContext]);

        const bubbleContent = useMemo(() => {
            if (loadingState === LoadingState.ERROR) {
                return <span><Trans>Ошибка загрузки фразы.</Trans><br/><Trans>Попробуйте перезагрузить страницу.</Trans></span>
            }

            return <>
                <BubbleContentStyled
                    ref={bubbleContentRef}
                    className={classNames(loadingState === LoadingState.LOADING && 'hidden')}
                    name={elementData.data.name}
                    text={elementData.data.text}
                    alreadyCompleted={!!(currentExerciseWorkData && currentExerciseWorkData.completed)}
                    audioFileId={elementData.data.soundFileId}
                    onReady={() => {
                        setLoadingState(LoadingState.READY);

                        if (slideItemWorkContext.readOnly === true || slideItemWorkContext.showCorrectAnswers === true) {
                            return;
                        }

                        if (!currentExerciseWorkData || !currentExerciseWorkData.completed) {
                            bubbleContentRef.current?.playVoiceover();
                        }
                    }}
                    onError={() => {
                        setLoadingState(LoadingState.ERROR);
                    }}
                    onEnd={() => {
                        if (!currentExerciseWorkData || !currentExerciseWorkData.completed) {
                            markAsCompleted();
                        }
                    }}
                />
                {
                    (loadingState === LoadingState.LOADING)
                    && <LoaderWrapper>
                        <DefaultLoader/>
                    </LoaderWrapper>
                }
            </>
        }, [currentExerciseWorkData, elementData.data.name, elementData.data.soundFileId, elementData.data.text, loadingState, markAsCompleted, slideItemWorkContext]);

        useEffect(() => {
            if (elementData.data.soundFileId === null) {
                // Если аудио прослушать нельзя, сразу отмечаем как завершённое
                markAsCompleted();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return <WrapperStyle>
            <AvatarWrapper>
                <Avatar
                    userName={nameForAvatar}
                    fileId={elementData.data.avatarFileId}
                    size={64}
                    fileFormat={UserFileFormatsEnum.TM_SLIDE_BUBBLE_ELEMENT_AVATAR_ORIGINAL}
                />
            </AvatarWrapper>
            <BubbleWrapper>
                <Bubble>
                    <BubbleOverflowWrapper>
                        {bubbleContent}
                    </BubbleOverflowWrapper>
                </Bubble>
            </BubbleWrapper>
        </WrapperStyle>
    }
);

DialogBubble.displayName = 'DialogBubble';