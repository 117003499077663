import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import React, {FormEvent, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react";
import {Form, FormItem, useForm} from "../../../../components/Ui/Elements/Form";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IDateHelperService} from "../../../../../services/date-helper/IDateHelperService";
import {cloneDeep} from "lodash";
import {t, Trans} from "@lingui/macro";
import {setRouteLeaveConfirmMessage} from "../../../../../store/app/actions";
import moment from "moment";
import {PopupSelector, PopupSelectorVariant} from "../../../../components/Ui/Elements/Selectors/PopupSelector";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {TimezoneSelector} from "../../../../components/Ui/Elements/Selectors/TimezoneSelector";
import {DatePicker} from "../../../../components/Ui/Elements/DatePicker";
import {EmailInput} from "../../../../components/Ui/Elements/EmailInput";
import {PhoneInput} from "../../../../components/Ui/Elements/PhoneInput";
import {TextArea} from "../../../../components/Ui/Elements/TextArea";

const TimezoneAndGenderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;

  @media (${({theme}) => theme.media.medium}) {
    flex-wrap: nowrap;
  }
`;

const FieldsWrapper = styled.div``;

const BornDatePickerFormItem = styled(FormItem)`
  width: 100%;

  @media (${({theme}) => theme.media.medium}) {
    width: auto;
  }
`;

export enum FormFieldNamesEnum {
    longName = 'longName',
    gender = 'gender',
    timezoneName = 'timezoneName',
    parentName = 'parentName',
    dateBorn = 'dateBorn',
    email = 'email',
    smsPhone = 'smsPhone',
    comment = 'comment'
}

interface StudentGeneralFormProps {
    studentGeneralInfoDto: DtoStudentGeneralInfo;
    onFinish: (values: DtoStudentGeneralInfo) => Promise<void>;
}

export interface StudentGeneralFormMethods {
    submit: () => Promise<void>;
    reset: () => void;
}

export const StudentGeneralForm = React.forwardRef<StudentGeneralFormMethods, StudentGeneralFormProps>((
    {
        studentGeneralInfoDto,
        onFinish
    }, ref
) => {
    const [form] = useForm();
    const dispatch = useDispatch();

    const [hasChanges, setHasChanges] = useState<boolean>(false);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        submit: async () => {
            await form.validateFields();
            await form.submit();
        },
        reset: () => {
            form.resetFields();
            setHasChanges(false);
        }
    }));

    const onFinishForm = async (values: any) => {
        const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        const timezoneName =
            (values[FormFieldNamesEnum.timezoneName] && values[FormFieldNamesEnum.timezoneName].zoneName)
                ? values[FormFieldNamesEnum.timezoneName].zoneName : '';

        const genderType = (values[FormFieldNamesEnum.gender] && values[FormFieldNamesEnum.gender].key)
            ? parseInt(values[FormFieldNamesEnum.gender].key)
            : 0;

        let smsPhone = (values[FormFieldNamesEnum.smsPhone])
            ? (values[FormFieldNamesEnum.smsPhone] as string).replace(/\D/g,'')
            : null;

        smsPhone = (smsPhone !== null && smsPhone.length > 3) ? `+${smsPhone}` : null;

        const email = (values[FormFieldNamesEnum.email] as string).trim();

        const saveDto = cloneDeep(studentGeneralInfoDto);

        saveDto.longName = (values[FormFieldNamesEnum.longName] as string).trim();
        saveDto.gender = genderType;
        saveDto.timezoneName = timezoneName;
        saveDto.parentInContract = (values[FormFieldNamesEnum.parentName])
            ? (values[FormFieldNamesEnum.parentName] as string).trim()
            : null;
        saveDto.dateBorn = (values[FormFieldNamesEnum.dateBorn])
            ? dateHelperService.formatAsSQLDate(values[FormFieldNamesEnum.dateBorn].toDate())
            : null;
        saveDto.email = email;
        saveDto.smsPhone = (smsPhone) ? smsPhone : null;
        saveDto.comment = (values[FormFieldNamesEnum.comment])
            ? (values[FormFieldNamesEnum.comment] as string).trim()
            : null;

        if (studentGeneralInfoDto.email !== saveDto.email) {
            if (saveDto.email !== '') {
                saveDto.enablePersonalCabinet = true;
                saveDto.enablePayOnline = true;
            }
        }

        await onFinish(saveDto);
    }

    const formOnChange = (_event: FormEvent) => {
        const fieldsTouched = form.isFieldsTouched();

        if (fieldsTouched) {
            if (!hasChanges) {
                dispatch(setRouteLeaveConfirmMessage(t`Некоторые изменения не сохранены. Выйти без сохранения?`));

                setHasChanges(true);
            }
        } else {
            if (hasChanges) {
                dispatch(setRouteLeaveConfirmMessage(null));

                setHasChanges(false);
            }
        }
    }

    const getDateInitialValue = useCallback((date: string | null) => {
        if (date === null) {
            return null;
        }

        const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        const dateObj = dateHelperService.dateFromString(date);

        return moment(dateObj);
    }, []);

    useEffect(() => {
        return () => {
            if (hasChanges) {
                dispatch(setRouteLeaveConfirmMessage(null));

                setHasChanges(false);
            }
        }
    }, [dispatch, hasChanges]);

    const studentGenderVariants = useMemo<PopupSelectorVariant[]>(() => {
        return [
            {
                key: '0',
                name: t`Жен.`
            },
            {
                key: '1',
                name: t`Муж.`
            }
        ] as PopupSelectorVariant[]
    }, []);

    const initialStudentGenderForInput = useMemo<PopupSelectorVariant>(() => {
        const genderValue = studentGeneralInfoDto.gender;

        if (
            (genderValue === 0) || (genderValue === 1)
        ) {
            return studentGenderVariants[genderValue];
        }

        return studentGenderVariants[0];
    }, [studentGenderVariants, studentGeneralInfoDto.gender]);

    return <Form form={form} layout={"vertical"} onFinish={onFinishForm} onValuesChange={formOnChange}>
        <FieldsWrapper>
            <FormItem
                name={FormFieldNamesEnum.longName}
                initialValue={studentGeneralInfoDto.longName}
                label={<Trans>ФИО</Trans>}
                rules={[
                    {required: true, message: t`Укажите ФИО ученика`},
                    {type: 'string', max: 100, message: t`Не более 100 символов`}
                ]}
            >
                <Input/>
            </FormItem>
            <TimezoneAndGenderWrapper>
                <FormItem
                    name={FormFieldNamesEnum.timezoneName}
                    style={{
                        flexGrow: 1
                    }}
                    initialValue={{
                        offset: studentGeneralInfoDto.studentLocalUtcDiff,
                        zoneName: studentGeneralInfoDto.timezoneName,
                        currentTime: studentGeneralInfoDto.studentLocalTime
                    }}
                    rules={[
                        {required: true, message: t`Укажите время ученика`}
                    ]}
                    label={<Trans>Местное время ученика</Trans>}
                >
                    <TimezoneSelector/>
                </FormItem>
                <FormItem
                    name={FormFieldNamesEnum.gender}
                    initialValue={initialStudentGenderForInput}
                    label={<Trans>Пол</Trans>}
                    rules={[
                        {required: true, message: t`Укажите пол ученика`}
                    ]}
                >
                    <PopupSelector variants={studentGenderVariants}/>
                </FormItem>
                <BornDatePickerFormItem
                    name={FormFieldNamesEnum.dateBorn}
                    initialValue={getDateInitialValue(studentGeneralInfoDto.dateBorn)}
                    label={<Trans>Дата рождения</Trans>}
                >
                    <DatePicker allowClear={true} suffixIcon={false}/>
                </BornDatePickerFormItem>
            </TimezoneAndGenderWrapper>
            <FormItem
                name={FormFieldNamesEnum.email}
                initialValue={studentGeneralInfoDto.email}
                label={<Trans>Email</Trans>}
                rules={[

                    {required: true, message: t`Укажите email`},
                    {type: 'email', message: t`Укажите email`},
                    {max: 255, message: t`Укажите email длиной не более 255 символов`},
                ]}
            >
                <EmailInput type={'email'} showCopyBtn={true}/>
            </FormItem>
            <FormItem
                name={FormFieldNamesEnum.smsPhone}
                initialValue={studentGeneralInfoDto.smsPhone}
                label={<Trans>Номер телефона</Trans>}
            >
                <PhoneInput mask={'+7 (000) 000 00 00'} type={'tel'} showCopyBtn={true} showCallBtn={true}/>
            </FormItem>
            <FormItem
                name={FormFieldNamesEnum.parentName}
                initialValue={studentGeneralInfoDto.parentInContract}
                label={<Trans>ФИО родителя</Trans>}
            >
                <Input/>
            </FormItem>
            <FormItem
                name={FormFieldNamesEnum.comment}
                initialValue={studentGeneralInfoDto.comment}
                label={<Trans>Комментарий</Trans>}
            >
                <TextArea/>
            </FormItem>
        </FieldsWrapper>
    </Form>
});
