import React from "react";
import {Pagination} from "../Pagination";

// export type ContentListItemType = typeof ListItem | typeof SubtitleItem;

export interface ContentListProps {
    children: React.ReactNode;
    currentPageNum: number;
    totalItemsCount: number;
    itemsPerPage: number;
    onPageChange: (newPageNum: number) => void;
}

export const ContentList: React.FC<ContentListProps> = (
    {
        children,
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange
    }
) => {
    return <div>
        {
            children
        }
        <Pagination defaultCurrent={currentPageNum}
                    total={totalItemsCount}
                    hideOnSinglePage={true}
                    onChange={(page, pageSize) => {
                        onPageChange(page);
                    }}
                    defaultPageSize={itemsPerPage}/>
    </div>
}