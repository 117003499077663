import * as React from "react";
import {CSSProperties, useMemo} from "react";
import styled from 'styled-components';
import {Avatar as AntAvatar} from 'antd';
import {UserFileFormatsEnum} from "../../../../enums/UserFileEnums";
import {DefaultUserAvatar} from "./DefaultUserAvatar";
import {ReactComponent as AddPhoto} from "../../Ui/Svg/AddPhoto.svg";
import classNames from "classnames";
import {UserImage} from "./UserImage";

interface AvatarProps {
    userName: string | null;
    fileId: string | null;
    size: number;
    noBorder?: boolean;
    noPlaceholder?: boolean;
    allowUpload?: boolean;
    onUploadClick?: () => void;
    className?: string;
    style?: CSSProperties;
    outline?: boolean;
    fileFormat?: UserFileFormatsEnum;
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  overflow: hidden;

  &.outline {
    border: 2px solid white;
    outline: 2px solid #1979ff;
  }
`;

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 50%;
`;

const Initials = styled.div`
  opacity: 0.5;
`;

const AvatarImageWrapper = styled(UserImage)`
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const UploadSelector = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({theme}) => theme.colors.backgroundInverse};
  color: ${({theme}) => theme.colors.textInverse};

  &:hover {
    opacity: 0.5;
  }
`;

const AddPhotoStyled = styled(AddPhoto)`
  width: 60px;
  height: 60px;
`;

export const Avatar: React.FC<AvatarProps> = (
    {
        fileId,
        size,
        userName,
        allowUpload,
        onUploadClick,
        className,
        style,
        noPlaceholder,
        noBorder,
        outline,
        fileFormat
    }
) => {
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (onUploadClick) {
            onUploadClick();
        }
    };

    const sizeString = useMemo<string>(() => {
        return size.toString(10) + 'px';
    }, [size]);

    const fontSizeString = useMemo<string>(() => {
        return Math.ceil(size / 3).toString(10) + 'px';
    }, [size]);

    const userInitials = useMemo<string | null>(() => {
        if (userName === null) {
            return null;
        }

        const allNames = userName.trim().split(' ');

        return allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`;
            }
            return acc;
        }, '');
    }, [userName]);

    return <Wrapper className={classNames(className, noBorder && "no-border", outline && "outline")}
                    style={{width: sizeString, height: sizeString, ...style}}>
        {
            (!noPlaceholder)
            && <Initials style={{fontSize: fontSizeString}}>
                {(userInitials) ?? <DefaultUserAvatar size={size}/>}
            </Initials>
        }
        {
            (fileId !== null)
            && <AvatarImageWrapper
                fileId={fileId}
                fileFormat={fileFormat ?? UserFileFormatsEnum.TM_USER_AVATAR_ORIGINAL}
            />
        }
        {
            (!noBorder)
            && <Border/>
        }
        {
            (allowUpload)
            && <UploadSelector onClick={handleClick}>
                <AddPhotoStyled/>
            </UploadSelector>
        }
    </Wrapper>;
}

export const AvatarGroup = AntAvatar.Group;
