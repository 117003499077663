import {putResolve, select, takeLeading} from 'redux-saga/effects';
import {StreamEventActionTypes, StreamEventItemState, StreamEventStoreItem} from "../../store/streamEvent/type";
import {deleteHandledEvents, setEventState} from "../../store/streamEvent/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {ApplicationState} from "../../store";
import {getOneUnhandledEvent} from "../../store/streamEvent/selector";
import {IStreamEventService} from "../../services/stream-event-service/IStreamEventService";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";

/**
 * Сага отвечает за остановку обработки сообщений потока событий
 */
export function* watchHandleEvent() {
    yield takeLeading(
        StreamEventActionTypes.START_HANDLE_EVENT_CYCLE,
        handleEvents
    );
}

const getReadyToHandleState = (state: ApplicationState) => state.streamEvent.readyToHandle;

function* handleEvents() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    let streamEventService: IStreamEventService = container.resolve<IStreamEventService>(DiTokens.WS_STREAM_EVENT_SERVICE);

    logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, 'Start stream event handle cycle');

    let canWork: boolean;

    while (true) {
        // Можем ли мы продолжать
        canWork = yield select(getReadyToHandleState);

        if (!canWork) {
            break;
        }

        // Есть ли элементы для обработки
        let eventForHandle: StreamEventStoreItem<object | null> = yield select(getOneUnhandledEvent);

        if (!eventForHandle) {
            break;
        }

        // Отметим, что взяли в работу
        yield putResolve(setEventState(eventForHandle.dto.id, StreamEventItemState.IN_PROCESS));

        // Получаем actionCreator
        let actionCreator = streamEventService.getActionByStreamEventType(eventForHandle.dto.streamId);

        if (actionCreator === null) {
            logger.error(
                LoggerSectionsEnum.WS_STREAM_EVENT,
                `Not found actionCreator for streamId ${eventForHandle.dto.streamId}`
            );

            // Отметим, что обработали
            yield putResolve(setEventState(eventForHandle.dto.id, StreamEventItemState.HANDLED));

            continue;
        }

        let objectForActionCreator: ResponseActionCreatorPayload<object | null, null>;

        objectForActionCreator = new ResponseActionCreatorPayload(eventForHandle, null);

        logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, `Start handling stream event with id ${eventForHandle.dto.id}`);

        yield putResolve(actionCreator(objectForActionCreator));

        // Получаем и вызываем "не redux" подписчиков, если они есть
        let subscribers = streamEventService.getSubscribersByStreamEventType(eventForHandle.dto.streamId);

        if (subscribers.length > 0) {
            for (let index = 0; index < subscribers.length; index++) {
                try {
                    subscribers[index].handler(objectForActionCreator);
                } catch (e) {
                    logger.error(
                        LoggerSectionsEnum.WS_STREAM_EVENT,
                        `Error on stream event subscriber handler with id ${subscribers[index].subscriberId}`,
                        e
                    );
                }
            }
        }

        // Отметим, что обработали
        yield putResolve(setEventState(eventForHandle.dto.id, StreamEventItemState.HANDLED));

        logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, `Handle stream event with id ${eventForHandle.dto.id} completed`);
    }

    yield putResolve(deleteHandledEvents());

    logger.debug(
        LoggerSectionsEnum.WS_STREAM_EVENT,
        'Stream event handle cycle completed'
        + ((!canWork) ? ' because handling suspended.' : ' because no new events.')
    );
}
