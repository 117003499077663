import {
    DtoAgreementItem
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {ConfirmDialog} from "../../../../../../components/Ui/Elements/ConfirmDialog";
import {t, Trans} from "@lingui/macro";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";

interface DeleteAgreementModalProps {
    dto: DtoAgreementItem;
}

export const DeleteAgreementModal = forwardRef<PopupActions, DeleteAgreementModalProps>(
    (
        {
            dto
        },
        ref
    ) => {
        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
        const modalRef = useRef<PopupActions>(null);
        const stToken = useSelector(stTokenSelector);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            close: () => {
                modalRef.current?.close()
            },
            open: () => {
                modalRef.current?.open()
            },
            toggle: () => {
                modalRef.current?.toggle()
            },
        }));

        const closeAgreement = useCallback(async () => {
            if (!stToken) {
                return;
            }

            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

            try {
                await stApiClient.deleteAgreement(stToken, dto.id);

                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Выполнено`,
                    t`Информация удалена`
                );

                studentPageContext.reloadBaseInfo();
            } catch (e) {
                if (e instanceof NoConnection) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось удалить`,
                        t`Проверьте соединение с интернетом`
                    );

                    return;
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось удалить`,
                    t`Попробуйте повторить попытку позднее`
                );

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.STONLINE_SCHEDULE_API,
                    `Error on delete agreement ${dto.id}: `,
                    e
                );
            }
        }, [dto.id, stToken, studentPageContext]);

        return <ConfirmDialog
            ref={modalRef}
            okText={t`Удалить`}
            cancelText={t`Отмена`}
            title={t`Удалить информацию об обучении?`}
            text={<>
                <Trans>Не рекомендуется удалять информацию об обучении, если только это не тестовая и не ошибочная
                    запись.</Trans><br/><br/>
                <Trans>После удаления ученик больше не будет видеть информацию об обучении.</Trans><br/><br/>
                <Trans>Вам будут доступны данные о его участии на прошедших занятиях, информация о платежах и списаниях
                    за занятия в рамках этого обучения будет сохранена.</Trans>
            </>
            }
            okMethod={closeAgreement}
        />
    });