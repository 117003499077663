import React, {useCallback, useContext, useMemo} from "react";
import {ReactComponent as ArrowBackIcon} from "../../../../Ui/Svg/ArrowBack.svg";
import {ReactComponent as ArrowNextIcon} from "../../../../Ui/Svg/ArrowNext.svg";
import {ReactComponent as GroupIcon} from "../../../../Ui/Svg/Group.svg";
import styled from "styled-components";
import {RegularTextCss} from "../../../../../styles/global-elements";
import {IVideoGalleryContext, VideoGalleryContext} from "./VideoGalleryContext";
import classNames from "classnames";
import {GalleryItemsCountBySizeMode} from "./Types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: inherit;
`;

const BtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.hidden {
    visibility: hidden;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  opacity: 0.8;
`;

const CounterText = styled.div`
  ${RegularTextCss};
`;

interface MemberCounterProps {
}

export const MemberCounter: React.FC<MemberCounterProps> = () => {
    const videoGalleryContext = useContext<IVideoGalleryContext>(VideoGalleryContext);

    const showPrevBtn = useMemo<boolean>(() => {
        return videoGalleryContext.memberOffset > 0;
    }, [videoGalleryContext]);

    const showNextBtn = useMemo<boolean>(() => {
        if (videoGalleryContext.gallerySize === 0) {
            return false;
        }

        return (videoGalleryContext.membersCountForScroll
            - videoGalleryContext.memberOffset
            - videoGalleryContext.gallerySize) > 0;
    }, [videoGalleryContext]);

    const goToNextMembers = useCallback(() => {
        let newValue = videoGalleryContext.memberOffset + videoGalleryContext.gallerySize;

        if (newValue >= videoGalleryContext.membersCountForScroll) {
            newValue = 0;
        }

        videoGalleryContext.setMemberOffset(newValue);
    }, [videoGalleryContext]);

    const goToPrevMembers = useCallback(() => {
        let newValue = videoGalleryContext.memberOffset
            - GalleryItemsCountBySizeMode[videoGalleryContext.gallerySizeMode];

        if (newValue < 0) {
            newValue = 0;
        }

        videoGalleryContext.setMemberOffset(newValue);
    }, [videoGalleryContext]);

    return <Wrapper>
        <BtnWrapper className={classNames(!showPrevBtn && 'hidden')} onClick={goToPrevMembers}>
            <ArrowBackIcon/>
        </BtnWrapper>
        <CounterWrapper>
            <GroupIcon/>
            <CounterText>{videoGalleryContext.totalMembersCount}</CounterText>
        </CounterWrapper>
        <BtnWrapper className={classNames(!showNextBtn && 'hidden')} onClick={goToNextMembers}>
            <ArrowNextIcon/>
        </BtnWrapper>
    </Wrapper>
}
