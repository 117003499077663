import {IsNotEmpty, IsNumber, IsString, ValidateNested} from 'class-validator';
import {DtoSelfStudyTrackSurveySummary} from "./DtoSelfStudyTrackSurveySummary";
import {Type} from "class-transformer";

/**
 * DTO Обзорных результатов self-study трека
 */
export class DtoSelfStudyTrackAdminSummaryResponse {
    @IsNotEmpty()
    @IsString()
    trackId: string;

    @IsNumber()
    totalSubscribersCount: number;

    @IsNumber()
    guestSubscribersCount: number;

    @IsNumber()
    registeredSubscribersCount: number;

    @IsNumber()
    newRegisteredSubscribersCount: number;

    @IsNumber()
    firstEpisodeStartedCount: number;

    @IsNumber()
    firstEpisodeCompletedCount: number;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoSelfStudyTrackSurveySummary)
    surveySummary: DtoSelfStudyTrackSurveySummary;
}
