import {IMediaDeviceService} from "./IMediaDeviceService";
import {IStoreService} from "../store/IStoreService";
import {
    setLastAudioInputDeviceId,
    setLastAudioOutputDeviceId,
    setLastVideoInputDeviceId
} from "../../store/commonPersisted/actions";
import {DeviceInfo, DeviceKindEnum, DevicesForCall, SubscriberCallbackFunc, SubscriberItem} from "./Types";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";


export class MediaDeviceService implements IMediaDeviceService {
    protected logger: ILogger;
    protected storeService: IStoreService;

    protected subscribersDeviceOnChange: SubscriberItem[];

    constructor(logger: ILogger, storeService: IStoreService) {
        this.logger = logger;
        this.storeService = storeService;

        this.subscribersDeviceOnChange = [];
    }

    /**
     * @inheritDoc
     */
    async userDeviceIsReadyForCalls(): Promise<boolean> {
        return this.requiredDevicesExist([
            DeviceKindEnum.VIDEO_INPUT,
            DeviceKindEnum.AUDIO_INPUT
        ]);
    }

    /**
     * @inheritDoc
     */
    async requiredDevicesExist(requiredDevices: DeviceKindEnum[]): Promise<boolean> {
        const deviceKindMap: { [deviceKind: string]: boolean } = {};

        requiredDevices.forEach(item => {
            deviceKindMap[item] = false
        });

        const devices = await this.getAllDevices();

        devices.forEach(item => {
            const kind = this.convertJsKindToProjectKind(item.kind);

            if (deviceKindMap[kind] !== undefined) {
                deviceKindMap[kind] = true;
            }
        });

        const notFoundDeviceKinds = requiredDevices.filter((item) => {
            return !deviceKindMap[item];
        });

        return notFoundDeviceKinds.length === 0;
    }

    /**
     * @inheritDoc
     */
    async getDevicesForCall(): Promise<DevicesForCall> {
        const storeState = this.storeService.store.getState();

        const lastAudioInputDeviceId = storeState.commonPersisted.lastAudioInputDeviceId;
        const lastAudioOutputDeviceId = storeState.commonPersisted.lastAudioOutputDeviceId;
        const lastVideoInputDeviceId = storeState.commonPersisted.lastVideoInputDeviceId;

        const allAvailableDevicesJs = await this.getAllDevices();
        const allAvailableDevices = allAvailableDevicesJs.map(item => this.convertJsDeviceInfoToProjectDeviceInfo(item));

        return {
            audioInput: this.getFromListByIdOrFirstByKind(
                allAvailableDevices,
                lastAudioInputDeviceId,
                DeviceKindEnum.AUDIO_INPUT
            ),
            audioOutput: this.getFromListByIdOrFirstByKind(
                allAvailableDevices,
                lastAudioOutputDeviceId,
                DeviceKindEnum.AUDIO_OUTPUT
            ),
            videoInput: this.getFromListByIdOrFirstByKind(
                allAvailableDevices,
                lastVideoInputDeviceId,
                DeviceKindEnum.VIDEO_INPUT
            ),
        }

    }

    /**
     * @inheritDoc
     */
    async getVideoInputDevices(): Promise<DeviceInfo[]> {
        const allDevices = await this.getAllDevices();

        const result: DeviceInfo[] = [];

        allDevices.forEach((item) => {
            if (this.convertJsKindToProjectKind(item.kind) === DeviceKindEnum.VIDEO_INPUT) {
                result.push(this.convertJsDeviceInfoToProjectDeviceInfo(item));
            }
        });

        return result;
    }

    /**
     * @inheritDoc
     */
    async getAudioInputDevices(): Promise<DeviceInfo[]> {
        const allDevices = await this.getAllDevices();

        const result: DeviceInfo[] = [];

        allDevices.forEach((item) => {
            if (this.convertJsKindToProjectKind(item.kind) === DeviceKindEnum.AUDIO_INPUT) {
                result.push(this.convertJsDeviceInfoToProjectDeviceInfo(item));
            }
        });

        return result;
    }

    /**
     * @inheritDoc
     */
    async getAudioOutputDevices(): Promise<DeviceInfo[]> {
        const allDevices = await this.getAllDevices();

        const result: DeviceInfo[] = [];

        allDevices.forEach((item) => {
            if (this.convertJsKindToProjectKind(item.kind) === DeviceKindEnum.AUDIO_OUTPUT) {
                result.push(this.convertJsDeviceInfoToProjectDeviceInfo(item));
            }
        });

        return result;
    }

    /**
     * @inheritDoc
     */
    async setAudioInputDevice(deviceId: string): Promise<void> {
        const audioDevices = await this.getAudioInputDevices();

        const targetDevice = audioDevices.find(item => item.id === deviceId);

        if (!targetDevice) {
            throw new Error(`Cannot change audio input device to ${deviceId} - not found device by id`);
        }

        this.storeService.store.dispatch(setLastAudioInputDeviceId(deviceId));

        this.callDeviceOnChangeSubscribers(DeviceKindEnum.AUDIO_INPUT, targetDevice);
    }

    /**
     * @inheritDoc
     *
     * @param deviceId
     */
    async setVideoInputDevice(deviceId: string): Promise<void> {
        const videoDevices = await this.getVideoInputDevices();

        const targetDevice = videoDevices.find(item => item.id === deviceId);

        if (!targetDevice) {
            throw new Error(`Cannot change video input device to ${deviceId} - not found device by id`);
        }

        this.storeService.store.dispatch(setLastVideoInputDeviceId(deviceId));

        this.callDeviceOnChangeSubscribers(DeviceKindEnum.VIDEO_INPUT, targetDevice);
    }

    /**
     * @inheritDoc
     *
     * @param deviceId
     */
    async setAudioOutputDevice(deviceId: string): Promise<void> {
        if (!this.browserSupportSelectAudioOutputDevice()) {
            return;
        }

        const audioOutputDevices = await this.getAudioOutputDevices();

        const targetDevice = audioOutputDevices.find(item => item.id === deviceId);

        if (!targetDevice) {
            throw new Error(`Cannot change audio output device to ${deviceId} - not found device by id`);
        }

        this.storeService.store.dispatch(setLastAudioOutputDeviceId(deviceId));

        this.callDeviceOnChangeSubscribers(DeviceKindEnum.AUDIO_OUTPUT, targetDevice);
    }

    /**
     * @inheritDoc
     */
    browserSupportSelectAudioOutputDevice(): boolean {
        return ('sinkId' in HTMLMediaElement.prototype);
    }

    /**
     * @inheritDoc
     */
    registerSubscriber(deviceKind: DeviceKindEnum, subscriberId: string, func: SubscriberCallbackFunc): void {
        const existSubscriber = this.subscribersDeviceOnChange.find(item => item.id === subscriberId);

        if (existSubscriber) {
            throw new Error(`deviceOnChange subscriber with id ${subscriberId} already exist`);
        }

        this.subscribersDeviceOnChange.push({
            id: subscriberId,
            kind: deviceKind,
            func: func
        });

        this.logger.info(
            LoggerSectionsEnum.MEDIA_DEVICE_SERVICE,
            `New subscriber ${subscriberId} (${deviceKind}) registered`
        );
    }

    /**
     * @inheritDoc
     */
    checkSubscriber(subscriberId: string): boolean {
        return this.subscribersDeviceOnChange.some(item => item.id === subscriberId);
    }

    /**
     * @inheritDoc
     */
    unregisterSubscriber(subscriberId: string): void {
        const existSubscriber = this.subscribersDeviceOnChange.find(item => item.id === subscriberId);

        if (!existSubscriber) {
            throw new Error(`deviceOnChange subscriber with id ${subscriberId} not found`);
        }

        this.subscribersDeviceOnChange = this.subscribersDeviceOnChange.filter(item => item.id !== subscriberId);

        this.logger.info(
            LoggerSectionsEnum.MEDIA_DEVICE_SERVICE,
            `Subscriber ${subscriberId} (${existSubscriber.kind}) unregistered`
        );
    }

    /**
     * Вызвать подписчиков на изменение устройства определённого типа
     *
     * @param deviceKind
     * @param deviceInfo
     * @protected
     */
    protected callDeviceOnChangeSubscribers(deviceKind: DeviceKindEnum, deviceInfo: DeviceInfo): void {
        this.subscribersDeviceOnChange.forEach(item => {
            if (item.kind !== deviceKind) {
                return;
            }

            try {
                item.func(deviceInfo);
            } catch (e) {
                this.logger.error(
                    LoggerSectionsEnum.MEDIA_DEVICE_SERVICE,
                    `Error on call subscriber for change device with kind ${deviceKind}: `, e
                );
            }
        })
    }

    /**
     * Получить доступное устройство по ID и если такого нет, то первое доступное по типу
     *
     * @param devices
     * @param devicePreferredId
     * @param kind
     * @protected
     */
    protected getFromListByIdOrFirstByKind(devices: DeviceInfo[], devicePreferredId: string | null, kind: DeviceKindEnum): DeviceInfo | null {
        if (devicePreferredId !== null) {
            const deviceById = devices.find(item => item.id === devicePreferredId && item.kind === kind);

            if (deviceById) {
                return deviceById;
            }
        }

        return devices.find(item => item.kind === kind) ?? null;
    }

    protected convertJsDeviceInfoToProjectDeviceInfo(jsDeviceInfo: MediaDeviceInfo): DeviceInfo {
        return {
            kind: this.convertJsKindToProjectKind(jsDeviceInfo.kind),
            id: jsDeviceInfo.deviceId,
            name: jsDeviceInfo.label,
            group: jsDeviceInfo.groupId
        };
    }

    protected convertJsKindToProjectKind(jsKind: MediaDeviceKind): DeviceKindEnum {
        switch (jsKind) {
            case "audioinput": {
                return DeviceKindEnum.AUDIO_INPUT;
            }
            case "audiooutput": {
                return DeviceKindEnum.AUDIO_OUTPUT;
            }
            case "videoinput": {
                return DeviceKindEnum.VIDEO_INPUT;
            }
            default: {
                return DeviceKindEnum.UNKNOWN;
            }
        }
    }

    protected getAllDevices(): Promise<MediaDeviceInfo[]> {
        return navigator.mediaDevices.enumerateDevices();
    }
}
