import styled from "styled-components";
import React, {useMemo} from "react";
import {Trans} from "@lingui/macro";
import {Progress} from "../../../Ui/Elements/Progress";

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
`;

const ControlsPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70px;
  padding: 10px;
  background: ${({theme}) => theme.colors.backgroundInverse}BB;
  border-radius: 5px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.textInverse};
  opacity: 0.8;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressLabel = styled.div`
  color: ${({theme}) => theme.colors.textInverse};
`;

interface ControlPanelProps {
    statusText?: string | null;

    showCancelUploadBtn?: boolean;
    cancelUploadBtnClick?: () => void;

    showProgressBar?: boolean;
    progressBarPosition?: number;

    showRetryBtn?: boolean;
    retryBtnClick?: () => void;
}

export const VoiceoverControlPanel: React.FC<ControlPanelProps> = (props) => {
    const {
        showProgressBar,
        progressBarPosition,
        statusText,
        showCancelUploadBtn,
        cancelUploadBtnClick,
        showRetryBtn,
        retryBtnClick
    } = props;

    const statusLabel = useMemo<JSX.Element | null>(() => {
        if (statusText !== null) {
            return <>{statusText}</>;
        }

        if (showProgressBar) {
            return <>
                <Trans>Загружено</Trans>&nbsp;
                {((progressBarPosition) ? progressBarPosition.toString(10) : "0") + '%'}
            </>;
        }

        return null;
    }, [progressBarPosition, showProgressBar, statusText]);

    const showBtnWrapper = useMemo<boolean>(() => {
        return !!(showCancelUploadBtn || showRetryBtn);
    }, [showCancelUploadBtn, showRetryBtn]);

    const progress = () => {
        if ((!showProgressBar) && (!statusText)) {
            return;
        }

        return <ProgressWrapper>
            {statusLabel
                && <ProgressLabel>
                    {statusLabel}
                </ProgressLabel>}
            {
                showProgressBar
                && <Progress percent={progressBarPosition} showInfo={false}/>
            }
        </ProgressWrapper>
    }

    return <ControlsWrapper>
        <ControlsPanel>
            {progress()}
            {showBtnWrapper
                && <ButtonsWrapper>

                    {showCancelUploadBtn
                        && <ButtonWrapper onClick={cancelUploadBtnClick}>
                            <Trans>Отменить</Trans>
                        </ButtonWrapper>
                    }

                    {showRetryBtn
                        && <ButtonWrapper onClick={retryBtnClick}>
                            <Trans>Повторить</Trans>
                        </ButtonWrapper>
                    }

                </ButtonsWrapper>
            }
        </ControlsPanel>
    </ControlsWrapper>
}