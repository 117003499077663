import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";

/**
 * DTO базовой информации профиля ученика
 */
export class DtoStudentGeneralInfo {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNumber()
    studentGroupmentId: number;

    @IsString()
    studentGroupmentName: string;

    @IsString()
    longName: string;

    @IsNumber()
    gender: number;

    @IsOptional()
    @IsString()
    dateBorn: string | null;

    @IsOptional()
    @IsString()
    parentInContract: string | null;

    @IsOptional()
    @IsString()
    smsPhone: string | null;

    @IsOptional()
    @IsString()
    phone: string | null;

    @IsOptional()
    @IsString()
    comment: string | null;

    @IsString()
    email: string;

    @IsBoolean()
    reportResultsOnMail: boolean;

    @IsBoolean()
    allowExcelReportLink: boolean;

    @IsBoolean()
    enablePersonalCabinet: boolean;

    @IsBoolean()
    enablePayOnline: boolean;

    @IsString()
    timezoneName: string;

    @IsString()
    studentLocalTime: string;

    @IsNumber()
    studentLocalUtcDiffInSeconds: number;

    @IsString()
    studentLocalUtcDiff: string; // "UTC+07:00"

    @IsString()
    balance: string;
}
