import React, {useMemo} from "react";
import {LessonRoomMember, LessonRoomUserTypeEnum} from "../../../../../store/lessonRoom/type";
import {Avatar} from "../../../../components/Ui/Elements/Avatar";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {StringHelper} from "../../../../../helpers/StringHelper";
import classNames from "classnames";
import {ReactComponent as CastOnSvg} from "../../../../components/Ui/Elements/ControlPanel/icons/CastOn.svg";
import {ReactComponent as EyeClosedSvg} from "../../../../components/Ui/Elements/ControlPanel/icons/EyeClosed.svg";
import {ReactComponent as MicrophoneSvg} from "../../../../components/Ui/Elements/ControlPanel/icons/Microphone.svg";
import {
    ReactComponent as MicrophoneOffSvg
} from "../../../../components/Ui/Elements/ControlPanel/icons/MicrophoneOff.svg";
import {StudentItem} from "./StudentItemType";
import {useSelector} from "react-redux";
import {roomMembersSelector} from "../../../../../store/lessonRoom/selector";
import {userIsTeacher} from "../../../../../store/user/selector";
import {ParticipantItem, StreamStatusEnum} from "../../../../components/LessonConference/Types";


const StudentItemStyled = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 0 0 0 6px;
  align-items: center;

  &.may-selected {
    cursor: pointer;
  }

  &.selected {
    border-left-color: ${({theme}) => theme.colors.backgroundInverse};
  }

  @media (${({theme}) => theme.media.large}) {
    padding: 0 32px;
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 64px;
  max-height: 64px;
  min-width: 64px;
  max-width: 64px;

  margin-right: 10px;
`;

const CenterWrapper = styled.div`
  flex-grow: 1;
`;

const RightIconWrapper = styled.div`
  height: 100%;
  min-width: 24px;
  max-width: 24px;
  color: ${({theme}) => theme.colors.textSecondary};

  &.warning {
    color: ${({theme}) => theme.colors.accentSecondary};
  }
`;

const NameAndStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const Name = styled.div`

`;

const Status = styled.div`
  opacity: 0.5;
  
  &.offline {
    color: ${({theme}) => theme.colors.accentSecondary};
  }

  &.online {
    color: ${({theme}) => theme.colors.textTertiary};
  }
  
  &.details {
    color: ${({theme}) => theme.colors.textSecondary};
    opacity: 0.7;
  }
`;

export interface StudentListItemProps {
    studentItem: StudentItem | null;
    roomMemberData: LessonRoomMember | null;
    videoRoomParticipantData: ParticipantItem | null;
    roomConnected: boolean;
    roomHasVideo: boolean;
    videoRoomGalleryGeneralMemberId: string | null;
    onClick?: () => void;
}

export const MembersListItem: React.FC<StudentListItemProps> = (
    {
        studentItem,
        roomMemberData,
        onClick,
        videoRoomParticipantData,
        roomConnected,
        roomHasVideo,
        videoRoomGalleryGeneralMemberId
    }
) => {
    const roomMembers = useSelector(roomMembersSelector);
    const currentUserIsTeacher = useSelector(userIsTeacher);

    const avatarFileId = useMemo<string | null>(() => {
        if (roomMemberData) {
            return roomMemberData.userAvatarFileId;
        }

        if (studentItem) {
            return studentItem.avatarFileId;
        }

        return null;
    }, [roomMemberData, studentItem]);

    const name = useMemo<string>(() => {
        if (studentItem) {
            return studentItem.name;
        }

        if (roomMemberData) {
            return roomMemberData.userName;
        }

        return "";
    }, [roomMemberData, studentItem]);

    const shortName = useMemo<string>(() => {
        if (studentItem) {
            return studentItem.shortName;
        }

        if (roomMemberData) {
            return StringHelper.createShortName(roomMemberData.userName);
        }

        return "";
    }, [roomMemberData, studentItem]);

    const status = useMemo<JSX.Element>(() => {
        if (!roomConnected) {
            return <Status/>;
        }

        if (roomMemberData === null) {
            return <Status className={"offline"}><Trans>Нет на уроке</Trans></Status>;
        }

        if (roomMemberData.userType === LessonRoomUserTypeEnum.TEACHER && !currentUserIsTeacher) {
            return <Status className={"online"}><Trans>Онлайн</Trans></Status>;
        }

        if (roomMemberData.pinnedUser === null) {
            if (roomMemberData.inFullScreen) {
                return <Status className={"details"}><Trans>Галерея на весь экран</Trans></Status>;
            }

            return <Status className={"online"}><Trans>Онлайн</Trans></Status>;

        }

        if (roomMemberData.pinnedUser === roomMemberData.userId) {
            if (roomMemberData.inFullScreen) {
                return <Status className={"details"}><Trans>Смотрит на себя на весь экран</Trans></Status>;
            }

            return <Status className={"details"}><Trans>Смотрит на себя</Trans></Status>;

        }

        const roomPinnedUser = roomMembers.find(item => item.userId === roomMemberData.pinnedUser);

        if (roomPinnedUser) {
            const name = StringHelper.extractFirstName(roomPinnedUser.userName, true);

            if (roomMemberData.inFullScreen) {
                return <Status className={"details"}>
                    <Trans values={{name: roomMemberData.userName}}>{name} на весь экран</Trans>
                </Status>;
            }

            return <Status className={"details"}>
                <Trans values={{name: roomMemberData.userName}}>Закреплено видео {name}</Trans>
            </Status>;
        }

        return <Status className={"online"}><Trans>Онлайн</Trans></Status>;
    }, [currentUserIsTeacher, roomConnected, roomMemberData, roomMembers]);

    const selected = useMemo<boolean>(() => {
        if ((roomConnected) && (roomMemberData !== null)) {
            if (roomMemberData.userId === videoRoomGalleryGeneralMemberId) {
                return true;
            }
        }

        return false;
    }, [roomConnected, roomMemberData, videoRoomGalleryGeneralMemberId]);

    const screenSharingAvailable = useMemo<boolean>(() => {
        if (videoRoomParticipantData) {
            return (videoRoomParticipantData.screenVideo === StreamStatusEnum.AVAILABLE)
                || (videoRoomParticipantData.screenVideo === StreamStatusEnum.CONNECTING)
                || (videoRoomParticipantData.screenVideo === StreamStatusEnum.CONNECTED);
        }

        return false;
    }, [videoRoomParticipantData]);

    const videoMuted = useMemo<boolean>(() => {
        if (videoRoomParticipantData) {
            return (videoRoomParticipantData.videoStatus === StreamStatusEnum.NOT_AVAILABLE)
                || (videoRoomParticipantData.videoStatus === StreamStatusEnum.ERROR);
        }

        return false;
    }, [videoRoomParticipantData]);

    const audioMuted = useMemo<boolean>(() => {
        if (videoRoomParticipantData) {
            return videoRoomParticipantData.audioMuted;
        }

        return false;
    }, [videoRoomParticipantData]);

    return <StudentItemStyled onClick={onClick}
                              className={classNames(
                                  onClick && "may-selected",
                                  selected && "selected"
                              )}>
        <AvatarWrapper>
            <Avatar size={64}
                    noBorder={true}
                    fileId={avatarFileId}
                    userName={name}
            />
        </AvatarWrapper>
        <CenterWrapper>
            <NameAndStatusWrapper>
                <Name>{shortName}</Name>
                {status}
            </NameAndStatusWrapper>
        </CenterWrapper>
        {
            (roomMemberData) && (roomConnected) && (roomHasVideo)
            && <>
                {
                    (screenSharingAvailable)
                    && <RightIconWrapper className={"warning"}><CastOnSvg/></RightIconWrapper>
                }
                {
                    (videoMuted)
                    && <RightIconWrapper className={"warning"}><EyeClosedSvg/></RightIconWrapper>
                }
                <RightIconWrapper className={classNames(audioMuted && "warning")}>
                    {(audioMuted) ? <MicrophoneOffSvg/> : <MicrophoneSvg/>}
                </RightIconWrapper>
            </>
        }
    </StudentItemStyled>;
}
