import React, {useMemo} from "react";
import {RegularText} from "../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {ReactComponent as WhatsAppSvg} from "../Ui/Svg/WhatsApp24.svg";
import styled from "styled-components";
import {Link} from "../Ui/Elements/Link";
import {useSelector} from "react-redux";
import {supportPhoneSelector} from "../../../store/user/selector";
import {StringHelper} from "../../../helpers/StringHelper";


const SupportItem = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const SupportWhatsAppString: React.FC = () => {
    const supportPhone = useSelector(supportPhoneSelector);

    const formattedSupportPhone = useMemo(() => {
        return StringHelper.formatPhone(supportPhone);
    }, [supportPhone]);

    return <div>
        <RegularText><Trans>Чат поддержки в WhatsApp</Trans></RegularText>
        <SupportItem href={`https://wa.me/${supportPhone}`} target={'_blank'}>
            <WhatsAppSvg/>
            <RegularText><Trans>{formattedSupportPhone}</Trans></RegularText>
        </SupportItem>
    </div>
}