import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {StepWizardStatusEnum} from "./StepWizardStatusEnum";
import {StepWizardContextProvider, StepWizardStatusConfig, StepWizardVisibleConfig} from "./StepWizardContext";
import {useNavigate} from "react-router-dom";
import {RoutesHelper} from "../../../helpers/RoutesHelper";
import {cloneDeep} from "lodash";

export type StepInitItem = {
    id: string;
    component: JSX.Element;
}

export type StepStoreItem = {
    id: string;
    component: JSX.Element;
    status: StepWizardStatusEnum;
    visible: boolean;
}

export interface StepWizardProps {
    // Страницы должны быть добавлены в массив в нужном порядке
    steps: StepInitItem[];

    // Роут к текущей странице без заполненного параметра ":stepId",
    pageLink: string;
}

export const StepWizard: React.FC<StepWizardProps> = (props) => {
    const {stepId} = useParams();
    const navigate = useNavigate();

    const [steps, setSteps] = useState(() => {
        return props.steps.map((item): StepStoreItem => {
            return {
                id: item.id,
                component: item.component,
                status: StepWizardStatusEnum.EMPTY,
                visible: true
            }
        })
    });

    const activeStepIndex = useMemo<number | null>(() => {
        if (stepId === undefined) {
            return null;
        }

        const index = steps.findIndex((item) => {
            return item.id === stepId
        });

        return (index > -1) ? index : null;
    }, [stepId, steps]);

    const currentStepIsStart = useMemo<boolean>(() => {
        if (activeStepIndex === null) {
            return false;
        }

        return activeStepIndex === 0;
    }, [activeStepIndex]);

    const currentStepIsFinish = useMemo<boolean>(() => {
        if (activeStepIndex === null) {
            return false;
        }

        return activeStepIndex + 1 === steps.length;
    }, [activeStepIndex, steps.length]);

    const activeStepComponent = useMemo<JSX.Element | null>(() => {
        if (activeStepIndex === null) {
            return null;
        }

        return steps[activeStepIndex].component;
    }, [activeStepIndex, steps]);

    const findPrevStepIndex = useCallback((steps: StepStoreItem[]): number | null => {
        if ((activeStepIndex === null) || (currentStepIsStart)) {
            return null;
        }

        for (let i = activeStepIndex - 1; i >= 0; i--) {
            if (steps[i].visible) {
                if (i === 0) {
                    return i;
                } else {
                    if (steps[i].status === StepWizardStatusEnum.COMPLETED) {
                        return i;
                    }
                }
            }
        }

        return null;
    }, [activeStepIndex, currentStepIsStart]);

    const findNextStepIndex = useCallback((steps: StepStoreItem[]): number | null => {
        if ((activeStepIndex === null) || (currentStepIsFinish)) {
            return null;
        }

        for (let i = activeStepIndex + 1; i < steps.length; i++) {
            if (steps[i].visible) {
                return i;
            }
        }

        return null;
    }, [activeStepIndex, currentStepIsFinish]);

    const goPrev = useCallback(() => {
        if (currentStepIsStart) {
            return;
        }

        const prevIndex = findPrevStepIndex(steps);

        if (prevIndex === null) {
            return;
        }

        navigate(
            RoutesHelper.replaceParams(
                props.pageLink,
                [
                    {
                        key: 'stepId',
                        value: steps[prevIndex].id
                    }
                ]
            )
        );
    }, [currentStepIsStart, findPrevStepIndex, navigate, props.pageLink, steps]);

    /**
     * Может ли пользователь находиться на этом шаге (на случай входа по ссылке).
     */
    const currentStepIsAvailable = useCallback((): boolean => {
        // Если текущий этап - первой, он всегда доступен
        if (currentStepIsStart) {
            return true;
        }

        // Проверим, что текущий этап видимый
        if (!activeStepIndex) {
            return false;
        }

        if (!steps[activeStepIndex].visible) {
            return false;
        }

        const prevStepIndex = findPrevStepIndex(steps);

        if (prevStepIndex === null) {
            // Непонятно, как так вышло, но отправим true 
            return true;
        }

        return steps[prevStepIndex].status === StepWizardStatusEnum.COMPLETED;
    }, [activeStepIndex, currentStepIsStart, findPrevStepIndex, steps]);

    const goNext = useCallback((newStatusConfig?: StepWizardStatusConfig[], newVisibleConfig?: StepWizardVisibleConfig[]) => {
        const newSteps = cloneDeep(steps);

        if (newStatusConfig !== undefined) {
            // Обработаем обновление статусов
            newStatusConfig.forEach((newStatusConfigItem) => {
                const index = newSteps.findIndex((stepItem) => stepItem.id === newStatusConfigItem.id);

                if (index > -1) {
                    newSteps[index].status = newStatusConfigItem.status;
                }
            });
        }

        if (newVisibleConfig !== undefined) {
            // Обработаем обновление видимости
            newVisibleConfig.forEach((newVisibleConfigItem) => {
                const index = newSteps.findIndex((stepItem) => stepItem.id === newVisibleConfigItem.id);

                if (index > -1) {
                    newSteps[index].visible = newVisibleConfigItem.visible;
                }
            });
        }

        // Отправляем изменения в состояние
        if (newVisibleConfig !== undefined || newStatusConfig !== undefined) {
            setSteps(newSteps);
        }

        // Выполняем поиск следующего шага
        if (currentStepIsFinish) {
            return;
        }

        const nextIndex = findNextStepIndex(newSteps);

        if (nextIndex === null) {
            return;
        }

        navigate(
            RoutesHelper.replaceParams(
                props.pageLink,
                [
                    {
                        key: 'stepId',
                        value: steps[nextIndex].id
                    }
                ]
            )
        );
    }, [currentStepIsFinish, findNextStepIndex, navigate, props.pageLink, steps]);

    useEffect(() => {
        if ((activeStepIndex === null) && (steps.length > 0)) {
            // Вернёмся к первому этапу
            navigate(
                RoutesHelper.replaceParams(
                    props.pageLink,
                    [
                        {
                            key: 'stepId',
                            value: steps[0].id
                        }
                    ]
                )
            );
        }

        window.scrollTo(0, 0);
    }, [activeStepIndex, navigate, props.pageLink, stepId, steps]);

    useEffect(() => {
        if (!currentStepIsAvailable()) {
            goPrev();
        }
    }, [activeStepIndex, currentStepIsAvailable, goPrev])

    return <StepWizardContextProvider value={{
        currentStepIsStart: currentStepIsStart,
        currentStepIfFinish: currentStepIsFinish,
        goPrev: goPrev,
        goNext: goNext
    }}>
        {activeStepComponent}
    </StepWizardContextProvider>
}