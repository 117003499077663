import React from "react";
import {PageTitle, RegularTextCss} from "../../../../../styles/global-elements";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {NoticeBlock, NoticeBlockText} from "../../NoticeBlock";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../../../DefaultLoader";
import {Pagination} from "../../Pagination";
import {ErrorLoadingContent} from "../../ErrorLoadingContent";
import {Modal, ModalControlParams} from "../../Modal";
import {BtnStyleEnum, Button} from "../../Button";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";

const ITEMS_PER_PAGE = 6;

const PageWrapper = styled.div``;
const ResultWrapper = styled.div`
  padding: 0 10px;
  min-height: 310px;
`;

const ResultList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 30px;
  }
`;

const ResultItem = styled.li`
  ${RegularTextCss};

  padding: 5px 0;
  cursor: pointer;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.8;
  transition: opacity 0.3s ease;

  :hover {
    opacity: 1;
  }
`;

const OrganizationName = styled.div``;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export interface TmOrganizationSelectorItem {
    id: string;
    name: string;
}

export interface TmOrganizationModalProps {
    apiToken?: string | null;
    trigger: React.ReactElement;
    onChange: (value: TmOrganizationSelectorItem) => void;
}

interface TmOrganizationSelectModalState {
    loadingState: LoadingState;
    organizationsList: TmOrganizationSelectorItem[] | null;
    currentPageNum: number;
    totalOrganizationsCount: number | null;
}

export class TmOrganizationSelectModalWindow extends React.Component<TmOrganizationModalProps, TmOrganizationSelectModalState> {
    protected searchAbortController: AbortController | null;

    protected apiClient: IHttpApiClient;
    protected logger: ILogger;

    protected onOpenWindowBind;
    protected onCloseWindowBind;

    constructor(props: Readonly<TmOrganizationModalProps>) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            organizationsList: null,
            totalOrganizationsCount: null,
            currentPageNum: 1
        };

        this.searchAbortController = null;

        this.onOpenWindowBind = this.onOpenWindow.bind(this);
        this.onCloseWindowBind = this.onCloseWindow.bind(this);
    }

    componentWillUnmount() {
        this.onCloseWindowBind();
    }

    protected onOpenWindow() {
        this.setState(() => {
            return {
                currentPageNum: 1
            }
        }, () => {
            this.fetchOrganizations().then();
        })
    }

    protected onCloseWindow() {
        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
            this.searchAbortController = null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOT_INIT
            }
        })
    }

    protected fetchOrganizations = async () => {
        if ((this.props.apiToken === null) || (this.props.apiToken === undefined)) {
            return null;
        }

        this.setState(
            () => {
                return {
                    loadingState: LoadingState.LOADING
                }
            }
        );

        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
        }

        this.searchAbortController = new AbortController();

        try {
            const result = await this.apiClient.tmGetOrganizations(
                this.props.apiToken,
                this.state.currentPageNum,
                ITEMS_PER_PAGE,
                this.searchAbortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    organizationsList: result.data.list.map((item) => {
                        return {
                            id: item.id,
                            name: item.name
                        }
                    }),
                    totalOrganizationsCount: result.data.totalCount
                }
            });
        } catch (e) {
            this.logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error on search tm organization: ", e);

            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });
        }
    }

    protected emptyListNotificationBox = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockText>
                    <Trans>По вашему запросу не нашлось информации</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    protected resultContent = (controls: ModalControlParams) => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.SUCCESS: {
                if ((this.state.organizationsList !== null) && (this.state.totalOrganizationsCount !== null)) {
                    if (this.state.organizationsList.length > 0) {
                        return <>
                            <ResultList>
                                {
                                    this.state.organizationsList.map((item) => {
                                        return <ResultItem key={item.id} onClick={() => {
                                            this.props.onChange(item);
                                            controls.closeModal();
                                        }}>
                                            <OrganizationName>
                                                {item.name}
                                            </OrganizationName>
                                        </ResultItem>;
                                    })
                                }
                            </ResultList>
                            <Pagination defaultCurrent={this.state.currentPageNum}
                                        total={this.state.totalOrganizationsCount}
                                        hideOnSinglePage={true}
                                        onChange={(page, pageSize) => {
                                            this.setState({
                                                currentPageNum: page
                                            }, () => this.fetchOrganizations());
                                        }}
                                        defaultPageSize={ITEMS_PER_PAGE}/>
                        </>;
                    } else {
                        return <>{this.emptyListNotificationBox()}</>;
                    }
                }

                return <ErrorLoadingContent retryBtnClick={this.fetchOrganizations}/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.fetchOrganizations}/>
            }
        }
    }

    protected modalContent = (controls: ModalControlParams) => {
        return <div>
            <PageTitle><Trans>Выбор владельца (издателя) материалов</Trans></PageTitle>
            <PageWrapper>
                <ResultWrapper>
                    {this.resultContent(controls)}
                </ResultWrapper>
            </PageWrapper>
        </div>
    }

    render() {
        return <Modal trigger={this.props.trigger}
                      onOpen={this.onOpenWindowBind}
                      onClose={this.onCloseWindowBind}
                      closeAllowed={true}
                      footer={(controls) => {
                          return <div>
                              <Button btnStyle={BtnStyleEnum.Secondary}
                                      onClick={() => controls.closeModal()}><Trans>Закрыть</Trans></Button>
                          </div>
                      }}
                      children={(controls) => this.modalContent(controls)}
        />
    }
}