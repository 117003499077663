import React from "react";
import styled from "styled-components";
import {PowerCounter} from "../../../../components/PowerCounter";
import {Trans} from "@lingui/macro";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {MainPageLinkAsButtonStyled, NextButtonWrapper, Subtitle} from "./styles";
import {RoutesList} from "../../../../RoutesList";
import {BtnStyleEnum} from "../../../../components/Ui/Elements/Button";
import {EpisodeCompleteTitle} from "./EpisodeCompleteTitle";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    //min-height: 100%;
    text-align: center;
`;

const PowerCounterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 284px;
    height: 300px;

    @media (${({theme}) => theme.media.small}) {
        height: 300px;
    }
`;

const PowerCounterStyled = styled(PowerCounter)`
    transform: scale(3);
    box-shadow: -1px 0px 5px ${({theme}) => theme.colors.accentDivider}6b;
    border: none;

    @media (${({theme}) => theme.media.small}) {
        transform: scale(4);
    }
`;

const NextButtonWrapperStyled = styled(NextButtonWrapper)`
    min-width: 50%;
`;

interface EnergyInfoProps {
    subscriptionOverview: DtoSelfStudyTrackSubscriptionUserOverviewItem;
}

export const EnergyInfo: React.FC<EnergyInfoProps> = ({subscriptionOverview}) => {
    return <Wrapper>
        <EpisodeCompleteTitle episodeName={subscriptionOverview.name}/>

        <PowerCounterWrapper>
            <PowerCounterStyled value={subscriptionOverview.chargingValue} largeEnergyIcon={true}/>
        </PowerCounterWrapper>
        <div>
            <Subtitle>
                <Trans>Выполняй задания ежедневно и удерживай заряд на 10, чтобы отлично изучить материал.</Trans>
            </Subtitle>
        </div>
        <NextButtonWrapperStyled>
            <MainPageLinkAsButtonStyled
                to={RoutesList.MAIN_PAGE}
                btnStyle={BtnStyleEnum.Primary}>
                <Trans>Продолжить</Trans>
            </MainPageLinkAsButtonStyled>
        </NextButtonWrapperStyled>
    </Wrapper>
}