import {call, put, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes, MembersListLoadingStateEnum} from "../../store/lessonRoom/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import * as LessonRoomActionCreators from "../../store/lessonRoom/actions";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {DtoBaseWithLessonRoomId} from "../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoBaseWithLessonRoomId";
import {WorkerPayloadType} from "../WorkerPayloadType";

/**
 * Сага отвечает за запуск запроса списка участников комнаты
 */
export function* watchLoadRoomMembersList() {
    yield takeEvery<WorkerPayloadType<string>>(
        LessonRoomActionTypes.LOAD_ROOM_MEMBERS_LIST,
        loadRoomLessonsList
    );
}

function* loadRoomLessonsList(data: WorkerPayloadType<string>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    const wsClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    logger.info(LoggerSectionsEnum.LESSON_ROOM, "Get members request started");

    const requestDto = new DtoBaseWithLessonRoomId();
    requestDto.lessonId = data.payload;

    yield call(() => wsClient.query(
        ApiMethodEnum.LESSON_ROOM_GET_MEMBERS_LIST,
        requestDto,
        requestDto.lessonId
    ));

    yield put(LessonRoomActionCreators.setRoomMembersListLoadingState(MembersListLoadingStateEnum.LOADING_NOW));
}
