import styled from "styled-components";

export const ExerciseWrapper = styled.div`
  //visibility: hidden;
  position: relative;
  
  &.ready {
    visibility: visible;
  }
`;

export const Col = styled.div``;

export const LeftCol = styled(Col)``;

export const RightCol = styled(Col)``;