import React from "react";
import styled from "styled-components";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import {ReactComponent as PencilIcon} from "../../../../../components/Ui/Svg/PencilNoPadding.svg";
import {ReactComponent as SaveIcon} from "../../../../../components/Ui/Svg/SaveNoPadding.svg";

const EditorAreaPlaceholderWrapper = styled.div`
  margin: 10px 0;
`;

export enum EditorAreaPlaceholderMode {
    NO_SLIDES,
    NO_SLIDE_CONTENT
}

interface EditorAreaPlaceholderProps {
    mode: EditorAreaPlaceholderMode;
}

export const EditorAreaPlaceholder: React.FC<EditorAreaPlaceholderProps> = (props) => {

    const content = () => {
        switch (props.mode) {
            case EditorAreaPlaceholderMode.NO_SLIDES: {
                return <>
                    <NoticeBlockTitle><Trans>Что дальше?</Trans></NoticeBlockTitle>
                    <NoticeBlockText><Trans>Слайд урока - это страничка учебника.</Trans></NoticeBlockText><br/>
                    <NoticeBlockText><Trans>На слайде можно разместить контент (текст, видео, картинка) и упражнения.
                        Всё содержимое слайда будет отображаться здесь, на месте этого сообщения.
                        А все элементы, которые можно добавить на слайд, перечислены
                        на вкладке «Элементы».</Trans></NoticeBlockText><br/>
                    <NoticeBlockText><Trans>Давайте создадим первый слайд этого урока. Нажмите кнопку «Добавить
                        слайд» на вкладке «Слайды».</Trans></NoticeBlockText>
                </>
            }
            case EditorAreaPlaceholderMode.NO_SLIDE_CONTENT: {
                return <>
                    <NoticeBlockTitle><Trans>Этот слайд пока пуст</Trans></NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Варианты материалов, которые можно добавить на слайд перечислены на вкладке
                            «Элементы».</Trans>
                    </NoticeBlockText><br/>
                    <NoticeBlockText>
                        <Trans>После того, как вы добавите элемент на слайд, подробная информация о его
                            возможностях будет доступна по щелчку на знак вопроса.</Trans>
                    </NoticeBlockText><br/>
                    <NoticeBlockText>
                        <span style={{verticalAlign: "middle"}}>
                            <Trans>Изменить название слайда или удалить его можно, щёлкнув по иконке</Trans>
                        </span>&nbsp;
                        <span style={{whiteSpace: "nowrap"}}>«<PencilIcon
                            style={{display: "inline", verticalAlign: "middle", margin: "0 3px"}}/>»,</span>&nbsp;
                        <Trans>расположенной сверху.</Trans>
                    </NoticeBlockText><br/>
                    <NoticeBlockText>
                        <span style={{verticalAlign: "middle"}}>
                            <Trans>В ходе работы над контентом, будет появляться иконка</Trans>
                        </span>&nbsp;«<SaveIcon style={{display: "inline", verticalAlign: "middle", margin: "0 3px"}}/>».&nbsp;
                        <span style={{verticalAlign: "middle"}}><Trans>
                            Не забывайте нажимать на неё для сохранения изменений.
                        </Trans></span>
                    </NoticeBlockText>
                </>
            }
            default: {
                return <div></div>;
            }
        }
    }

    return <EditorAreaPlaceholderWrapper>
        <NoticeBlock>
            {content()}
        </NoticeBlock>
    </EditorAreaPlaceholderWrapper>;
}