import React, {useContext} from "react";
import {CommonContext, ICommonContext} from "../CommonContext";
import {ProgressBar as ProgressBarUiComponent} from "../../../../../components/ProgressBar";

export const ProgressBar: React.FC = () => {
    const {
        currentProgressValuePercent
    } = useContext<ICommonContext>(CommonContext);

    return <ProgressBarUiComponent rightValue={currentProgressValuePercent} wrongValue={0}/>
}