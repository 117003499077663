import React from "react";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {PalettePopup} from "./PalettePopup";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";

const Wrapper = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

interface BottomPaletteButtonProps {
    onSelectItem: (itemType: ElementTypeEnum) => void;
}

export const BottomPaletteButton: React.FC<BottomPaletteButtonProps> = (
    {
        onSelectItem
    }
) => {
    return <Wrapper>
        <PalettePopup
            trigger={
                <div>
                    <Button btnStyle={BtnStyleEnum.Secondary}>
                        <Trans>+ Добавить контент</Trans>
                    </Button>
                </div>
            }
            onSelectItem={onSelectItem}
        />
    </Wrapper>
}