import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    html {
        scroll-behavior: smooth;
    }
    
  body, #root, .app {
    min-width: 100%;
    min-height: 100vh;
  }

  body {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    font-family: Raleway, Arial, serif;
    color: ${({theme}) => theme.colors.textPrimary};
    min-width: 320px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  input {
    font-family: Raleway, Arial, serif;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  h1, h2, h3, h4, h5, h6, p {
    color: ${({theme}) => theme.colors.textPrimary};
  }

  .input-checkbox, input.text, input[type=button], input[type=date], input[type=email], input[type=number], input[type=password], input[type=submit], input[type=text], textarea {
    -webkit-appearance: none;
    border-radius: 0;
  }

  input[type="text"]:focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({theme}) => theme.colors.accentDivider};
    border-radius: 3px;
    border: 3px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: hsla(0, 0%, 100%, 0);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .ant-notification-notice {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  .ant-picker-status-error.ant-picker {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    border-color: ${({theme}) => theme.colors.accentSecondary} !important;

    &:hover {
      background-color: ${({theme}) => theme.colors.backgroundPrimary} !important;
      border-color: ${({theme}) => theme.colors.accentSecondary} !important;
    }
  }
  
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.colors.accentSecondary} !important;
  }
  
  .ant-picker-time-panel-cell {
    color: ${({theme}) => theme.colors.textPrimary} !important;
    
    &:hover {
      background: ${({theme}) => theme.colors.backgroundSecondary} !important;
      color: ${({theme}) => theme.colors.textPrimary} !important;
    }
    
    &-selected {
      background: ${({theme}) => theme.colors.backgroundSecondary} !important;
      color: ${({theme}) => theme.colors.textPrimary} !important;
    }
  }

  .ant-picker-time-panel-cell-inner {
    background-color: inherit !important;
    color: inherit !important;
    
    &:hover {
      background: inherit !important;
      color: inherit !important;
    }
  }
  
  .ant-picker-ranges {
    .ant-picker-now-btn {
      color: ${({theme}) => theme.colors.textPrimary} !important;
    }
    
    .ant-picker-ok {
      .ant-btn {
        background-color: ${({theme}) => theme.colors.accentPrimary} !important;
        color: ${({theme}) => theme.colors.textInverse} !important;
        border: none !important;
      }
    }
  }

  // AntDatePicker
  .ant-picker-dropdown {
    max-width: 304px;
  }

  .ant-picker-panel-container {
    color: ${({theme}) => theme.colors.textPrimary};
    background-color: ${({theme}) => theme.colors.backgroundPrimary};

    .ant-picker-header {
      color: inherit;
    }

    .ant-picker-header button {
      color: inherit;
      min-width: 40px;
    }

    .ant-picker-content th {
      color: inherit;
    }

    .ant-picker-cell {
      color: ${({theme}) => theme.colors.textTertiary};
    }

    .ant-picker-content td, .ant-picker-content th {
      min-width: 40px;
    }

    .ant-picker-date-panel .ant-picker-content {
      width: auto;
    }

    .ant-picker-date-panel, .ant-picker-decade-panel, .ant-picker-month-panel, .ant-picker-quarter-panel, .ant-picker-time-panel, .ant-picker-week-panel, .ant-picker-year-panel {
      width: auto;
    }

    .ant-picker-cell .ant-picker-cell-inner {
      height: 40px;
      line-height: 40px;
      min-width: 40px;
    }

    .ant-picker-cell-in-view {
      color: inherit;
    }
  }

  // AntTimePicker
  .ant-picker-panel-container {
    .ant-picker-time-panel-cell-inner {
      height: 40px !important;
      line-height: 40px !important;
    }
  }


  .ant-notification-notice-message, .ant-notification-notice-description, .ant-notification-notice-close-icon {
    color: ${({theme}) => theme.colors.textPrimary};
  }

  .ant-picker-time-panel-column {
    touch-action: pan-y;
    overflow: auto !important;
    width: 100px;
  }

  .ant-picker-time-panel-column::-webkit-scrollbar {
    display: none;
  }

  [contenteditable] {
    outline: 0 solid transparent;
  }

  .ant-tooltip {
      .ant-tooltip-inner {
          padding: 10px;
          background-color: ${({theme}) => theme.colors.backgroundSecondary}db;
          font-size: 14px;
          color: ${({theme}) => theme.colors.textPrimary};
          border-radius: 5px;
          backdrop-filter: blur(5px);
          box-shadow: none;
      }

      .ant-tooltip-arrow-content {
          box-shadow: none;

          &::before {
              background: ${({theme}) => theme.colors.backgroundSecondary};
          }
      }
  }

  // {@see InputHistoryTip}
  .input-history-tooltip {
      .ant-tooltip-inner {
          font-size: 13px;
          width: 200px;
      }
  }
`;
