import React, {useContext, useMemo} from "react";
import {IButtonBaseProps} from "../IButtonBaseProps";
import {TeacherMagicModal} from "./TeacherMagicModal";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../../contexts/CommonContext";
import {ControlPanelItem, ControlPanelItemIcon} from "../../../../Ui/Elements/ControlPanel/ControlPanel";
import classNames from "classnames";
import {ReactComponent as MagicIcon} from "../../../../Ui/Elements/ControlPanel/icons/Magic.svg";

export const TeacherMagicBtn: React.FC<IButtonBaseProps> = () => {
    const commonContext = useContext<ICommonContext>(CommonContext);

    const btnDisabled = useMemo<boolean>(() => {
        return commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED;
    }, [commonContext.lessonConferenceState]);

    return <TeacherMagicModal
        trigger={
            <ControlPanelItem className={classNames('panel-item', btnDisabled && 'disabled')}>
                <ControlPanelItemIcon className={'no-margin'}><MagicIcon/></ControlPanelItemIcon>
            </ControlPanelItem>
        }/>
}
