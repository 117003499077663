import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {ContentLayoutSettingsBackgroundModeEnum} from "../../../../../enums/ContentLayoutSettingsBackgroundModeEnum";
import classNames from "classnames";

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s ease opacity;

    &.visible {
        opacity: 1;
    }

    &.cover {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &.pave {
        background-repeat: repeat;

        @media (-webkit-min-device-pixel-ratio: 1.25),
        (min-resolution: 120dpi) {
            background-size: 20%;
        }
    }
`;

interface PresenterProps {
    imageUrl: string;
    backgroundType: ContentLayoutSettingsBackgroundModeEnum;
}

export const Presenter: React.FC<PresenterProps> = ({backgroundType, imageUrl}) => {
    const [visible, setVisible] = useState<boolean>(false);

    const bgClassName = useMemo<string>(() => {
        if (backgroundType === ContentLayoutSettingsBackgroundModeEnum.PAVE) {
            return 'pave';
        } else {
            return 'cover';
        }
    }, [backgroundType]);

    const bgStyle = useMemo<CSSProperties>(() => {
        if (backgroundType === ContentLayoutSettingsBackgroundModeEnum.PAVE) {
            return {
                backgroundImage: `url(${imageUrl})`
            }
        } else {
            return {
                backgroundImage: `url(${imageUrl})`
            }
        }
    }, [backgroundType, imageUrl]);

    useEffect(() => {
        let visibleTimer: number | null = setTimeout(() => {
            setVisible(true);
            visibleTimer = null;
        }, 0) as unknown as number;

        return () => {
            if (visibleTimer !== null) {
                clearTimeout(visibleTimer);
            }
        }
    }, []);

    return <Wrapper className={classNames(visible && 'visible', bgClassName)} style={bgStyle}/>
}