import {ConditionCheckerFactory} from "./ConditionCheckers/ConditionCheckerFactory";
import {EditorItemInteractivityConfigAction} from "../../SlidePlayerEditorCommonParts/EditorData";
import {ActionHandlerFactory} from "./ActionHandlers/ActionHandlerFactory";
import {SlideItemInteractivityActionTypeEnum} from "../../../../enums/SlideItemInteractivityActionNameEnum";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {RunParams} from "./RunParams";

export class InteractivityActionsRunner {
    protected logger: ILogger;
    protected runParams: RunParams;

    constructor(logger: ILogger) {
        this.logger = logger;
    }

    async run(runParams: RunParams): Promise<void> {
        this.runParams = runParams;

        for (let i = 0; i < runParams.actions.length; i++) {
            await this.runAction(
                runParams.actions[i]
            );
        }
    }

    protected async runAction(action: EditorItemInteractivityConfigAction): Promise<void> {
        const conditionChecker = ConditionCheckerFactory.getChecker();

        const itemWorkDataIndex = this.runParams.slideWorkData.itemsIndexById[this.runParams.slideItemId];

        const itemWorkData = this.runParams.slideWorkData.items[itemWorkDataIndex];

        if (!itemWorkData) {
            this.logger.error(
                LoggerSectionsEnum.SLIDE_INTERACTIVITY,
                `Not found item work data for slide item ${this.runParams.slideItemId}`
            );

            return;
        }

        const conditionCheckResult = await conditionChecker.checkCondition(action.condition, itemWorkData);

        if (!conditionCheckResult) {
            this.logger.info(
                LoggerSectionsEnum.SLIDE_INTERACTIVITY,
                `Condition not satisfied for action ${action.actionType}`
            );

            return;
        }

        const actionHandler = ActionHandlerFactory.getActionHandler(
            this.logger,
            action.actionType as SlideItemInteractivityActionTypeEnum
        );

        if (!actionHandler) {
            this.logger.error(
                LoggerSectionsEnum.SLIDE_INTERACTIVITY,
                `Not found action handler for action ${action.actionType}`
            );

            return;
        }

        await actionHandler.run(
            this.runParams,
            action,
        );
    }
}