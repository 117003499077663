import {IsNotEmpty, IsNumber, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO элемента слайда для разных задач
 */
export abstract class DtoSlideItemBase {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    slideTmId: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    slideTmName: string;

    @IsNotEmpty()
    @IsNumber()
    slideExerciseCount: number;

    @IsNotEmpty()
    @IsNumber()
    slideContentVersion: number;

    @IsNotEmpty()
    @IsNumber()
    orderPosition: number;
}
