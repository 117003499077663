import {Dispatch} from "redux";
import {ApplicationState} from "../../../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import React, {RefObject} from "react";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {IWsApiClient} from "../../../../../../../components/WsApiClient/IWsApiClient";
import {LessonPageContext} from "../../../LessonPageContext";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {LoadSlideWorkDataActionParams} from "../../../../../../../store/slidesWorkData/type";
import {loadSlideWorkData} from "../../../../../../../store/slidesWorkData/actions";
import {FooterMode, Modal, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import {EditorData} from "../../../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {PopupActions} from "reactjs-popup/dist/types";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {EditorDataDtoMapper} from "../../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../../components/Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockTitle} from "../../../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import {PlayerContextProvider} from "../../../../../../components/SlidePlayer/PlayerContext";
import {SlidePlayer} from "../../../../../../components/SlidePlayer";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {PageSubtitle2} from "../../../../../../styles/global-elements";
import {NoConnection} from "../../../../../../../components/HttpApiClient/Exception/NoConnection";
import {SlideItemsParamsBySlide} from "../../../../../../components/SlidePlayer/SlideItemParamsStore";

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const TitleTextWrapper = styled.div`
  padding-top: 6px;
  flex-grow: 1;
`;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

const SlidePlayerBg = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  padding: 2px 0;
`;

const SlidePlayerWrapper = styled.div`
  max-width: 680px;
  padding: 0;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  margin: auto;
  
  @media (${({theme}) => theme.media.small}) {
    padding: 10px 20px 10px 20px;
  }
`;

interface StudentHomeworkSlideDataModalProps extends PropsFromRedux {
    tmSlideId: string;
    slideContentVersion: number;
    lessonId: string;
    playerId: string;
    studentId: string;
    studentName: string;
    children: JSX.Element;
    onClose?: () => void;
}

interface StudentHomeworkSlideDataModalState {
    loadingState: LoadingState;
    slideContent: EditorData | null;
}

class StudentHomeworkSlideDataModal extends React.Component<StudentHomeworkSlideDataModalProps, StudentHomeworkSlideDataModalState> {
    protected apiClient: IHttpApiClient;
    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    protected abortController: AbortController | null;
    protected modalRef: RefObject<PopupActions>;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<StudentHomeworkSlideDataModalProps> | StudentHomeworkSlideDataModalProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            slideContent: null
        }

        this.modalRef = React.createRef<PopupActions>();
    }

    protected onOpen = () => {
        this.fetchSlideContent();
    }

    protected onClose = () => {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        if (this.props.onClose !== undefined) {
            this.props.onClose();
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOT_INIT
            }
        });
    }

    protected fetchSlideContent = () => {
        if (this.props.apiToken === null) {
            return;
        }

        const loadStudentWorkDataParams: LoadSlideWorkDataActionParams = {
            playerId: this.props.playerId,
            slideIds: [this.props.tmSlideId],
            lessonId: this.props.lessonId,
            userId: this.props.studentId
        }

        this.props.loadSlideWorkData(loadStudentWorkDataParams);

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING,
                slideContent: null
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.tmGetSlideContent(
            this.props.apiToken,
            this.props.tmSlideId,
            this.props.slideContentVersion,
            this.abortController
        )
            .then((data) => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                        slideContent: EditorDataDtoMapper.dtoToEditorData(data.data.content)
                    }
                })
            })
            .catch((error) => {
                const messageText = "Error on fetch tmSlide content for preview: " + this.props.tmSlideId;

                if (error instanceof NoConnection) {
                    this.logger.info(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                }

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }

    protected slideItemsParams = () => {
        const result: SlideItemsParamsBySlide = {};

        if (!this.state.slideContent) {
            return result;
        }

        this.state.slideContent.items.forEach((slideContentItem) => {
            result[slideContentItem.id] = slideContentItem.params;
        });

        return result;
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <Wrapper>
                    <DefaultLoader/>
                </Wrapper>
            }
            case LoadingState.ERROR: {
                return <Wrapper>
                    <ErrorLoadingContent retryBtnClick={this.fetchSlideContent}/>
                </Wrapper>
            }
            case LoadingState.SUCCESS: {
                if (this.state.slideContent === null) {
                    return <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Слайд пуст</Trans></NoticeBlockTitle>
                        </>
                    </NoticeBlock>
                }

                return <Wrapper>
                    <SlidePlayerBg>
                        <SlidePlayerWrapper>
                            <PlayerContextProvider value={{
                                lessonId: "none",
                                playerId: "slide-preview",
                                slides: null,
                                slidesContent: {},
                                setSlidesContent: () => {
                                },
                                selectedSlideId: this.props.tmSlideId,
                                setSelectedSlideId: () => {
                                },
                                selectedSlide: null,
                                selectedSlideIndex: null,
                                setInteractivityConfig: null
                            }}>
                                <SlidePlayer playerId={this.props.playerId}
                                             slideId={this.props.tmSlideId}
                                             slideContent={this.state.slideContent}
                                             readOnly={true}
                                             showCorrectAnswers={false}
                                             slideItemsParams={this.slideItemsParams()}
                                />
                            </PlayerContextProvider>
                        </SlidePlayerWrapper>
                    </SlidePlayerBg>
                </Wrapper>;
            }
            default: {
                throw new Error('Unknown loading state in select tmSlide preview modal');
            }
        }
    }

    protected modalContent = (_controls: ModalControlParams) => {
        return <Wrapper>
            <TitleWrapper>
                <TitleTextWrapper>
                    <Title>{this.props.studentName}</Title>
                </TitleTextWrapper>
            </TitleWrapper>
            {this.content()}
        </Wrapper>;
    }

    render() {
        return <Modal innerRef={this.modalRef}
                      closeAllowed={true}
                      trigger={this.props.children}
                      onOpen={this.onOpen}
                      onClose={this.onClose}
                      footer={(controls: ModalControlParams) => {
                          return <Button btnStyle={BtnStyleEnum.Secondary}
                                         onClick={controls.closeModal}>
                              <Trans>Закрыть</Trans>
                          </Button>
                      }}
                      footerMode={FooterMode.DEFAULT}
                      children={this.modalContent}/>
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSlideWorkData: (params: LoadSlideWorkDataActionParams) => {
        dispatch(loadSlideWorkData(params))
    }
});

const mapStateToProps = ({user}: ApplicationState) => ({
    // userId: (user.profileData?.id) ?? null,
    // stToken: user.stToken,
    apiToken: user.sessionToken
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StudentHomeworkSlideDataModal);
