import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {Sortable, SortableItemComponent} from "../../../../../components/Sortable/Sortable";
import {MaterialItemWithAdditionalFields} from "../../Common/LessonMaterialsModeBase";
import {RegularTextCss} from "../../../../../styles/global-elements";
import {ReactComponent as EyeClosedIcon} from "../../../../../components/Ui/Svg/EyeClosed.svg";
import {ReactComponent as SettingsIcon} from "../../../../../components/Ui/Svg/Settings.svg";
import {Popup, PopupItem} from "../../../../../components/Ui/Elements/Popup";
import {t} from "@lingui/macro";
import {SlidePreviewModal} from "../../../../../components/TeachingMaterialsCatalog/slide-preview-modal";
import {DtoTmSlide} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";

const ListWrapper = styled.div`
  width: 100%;
`;

const SlideItem = styled.div`
  display: flex;
  flex-direction: row;
`;


const SlideIconWrapper = styled.div`
  ${RegularTextCss};

  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.7;
`;

const SlideNumIndexWrapper = styled.div`
  padding-top: 6px;
`;


const SlideNameWrapper = styled.div`
  ${RegularTextCss};

  flex-grow: 1;
  padding-top: 6px;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
  }
`;

const SlideButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SlideButtonWrapper = styled.div`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:not(.disabled) {
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const EyeClosedIconStyled = styled(EyeClosedIcon)``;

interface LessonHomeworkMaterialsListProps {
    apiToken: string;
    lessonMaterials: MaterialItemWithAdditionalFields[];
    reorderItems: (from: number, to: number) => void;
    allItemsDisabled: boolean;
    deleteItem: (item: MaterialItemWithAdditionalFields) => void;
    copySlideToWorkOnClassOnClick: (item: MaterialItemWithAdditionalFields) => void;
}

export const LessonHomeworkMaterialsList: React.FC<LessonHomeworkMaterialsListProps> = (props) => {
    const previewModal = useRef<SlidePreviewModal>(null);
    const [slideForPreview, setSlideForPreview] = useState<DtoTmSlide | null>(null);
    const [slideForPreviewContentVersion, setSlideForPreviewContentVersion] = useState<number | null>(null);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const openSlideForPreviewInModal = useCallback((item: MaterialItemWithAdditionalFields) => {
        setSlideForPreviewContentVersion(item.slideContentVersion);

        const slideDto = new DtoTmSlide();

        slideDto.id = item.slideTmId;
        slideDto.name = item.slideTmName;

        setSlideForPreview(slideDto);
    }, []);

    const popupMenuItems = useCallback((item: MaterialItemWithAdditionalFields) => {
        const items: PopupItem[] = [];

        const linkToPreviewItem = RoutesHelper.replaceParams(
            RoutesList.TEACHER_SLIDE_PREVIEW_W_VERSION,
            [
                {
                    key: 'slideId',
                    value: item.slideTmId
                },
                {
                    key: 'contentVersionId',
                    value: item.slideContentVersion.toString(10)
                }
            ]
        );

        items.push({
            text: t`Посмотреть в новой вкладке`,
            href: {
                link: linkToPreviewItem,
                target: "_blank"
            }
        });

        items.push({
            text: t`Скопировать в работу в классе`,
            onClick: () => {
                props.copySlideToWorkOnClassOnClick(item);
            }
        });

        items.push({
            text: t`Удалить слайд из домашней работы`,
            onClick: () => {
                props.deleteItem(item);
            }
        });

        return items;
    }, [props]);

    const sortableItems = useMemo<SortableItemComponent<MaterialItemWithAdditionalFields>[]>(() => {
        return props.lessonMaterials.map((item) => {
            return {
                id: item.slideTmId,
                selected: false,
                disabled: props.allItemsDisabled || item.itemDisabled,
                item: item
            }
        });
    }, [props.allItemsDisabled, props.lessonMaterials]);

    useEffect(() => {
        if ((slideForPreview !== null) && (previewModal.current !== null)) {
            previewModal.current.open();
        }
    }, [slideForPreview]);

    const lessonItem = (item: MaterialItemWithAdditionalFields) => {
        return <SlideItem>
            <SlideIconWrapper>
                {
                    (item.index !== null)
                        ? <SlideNumIndexWrapper>{item.index.toString(10)}</SlideNumIndexWrapper>
                        : <EyeClosedIconStyled/>
                }
            </SlideIconWrapper>
            <SlideNameWrapper onClick={() => openSlideForPreviewInModal(item)}>
                {item.slideTmName}
            </SlideNameWrapper>
            <SlideButtonsWrapper>
                <SlideButtonWrapper>
                    <Popup items={popupMenuItems(item)}
                           trigger={<SettingsIcon/>}
                           position={isSmall ? "bottom right" : "bottom center"}/>
                </SlideButtonWrapper>
            </SlideButtonsWrapper>
        </SlideItem>
    }

    return <ListWrapper>
        <Sortable<MaterialItemWithAdditionalFields>
            tinyItems={true}
            items={sortableItems}
            reorderItems={(array, from, to) => {
                props.reorderItems(from, to);
            }}
            renderItemContent={(item, index) => {
                return lessonItem(item.item);
            }}
        />
        {
            (slideForPreview) && (slideForPreviewContentVersion !== null)
            && <SlidePreviewModal ref={previewModal}
                                  tmSlide={slideForPreview}
                                  onClose={() => {
                                      setSlideForPreview(null);
                                      setSlideForPreviewContentVersion(null);
                                  }}
                                  apiToken={props.apiToken}
                                  slideContentVersion={slideForPreviewContentVersion}/>
        }
    </ListWrapper>
}
