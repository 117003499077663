import {Navigate, RouteObject} from 'react-router-dom';
import {Layout} from "./pages/layout";
import {AuthPage} from "./pages/auth";
import {RoutesList} from "./RoutesList";
import {LoginForm} from "./pages/auth/components/LoginForm";
import {SignUpForm} from "./pages/auth/components/SignUpForm";
import {RestorePasswordForm} from "./pages/auth/components/RestorePasswordForm";
import {RestoreViaTokenForm} from "./pages/auth/components/RestoreViaTokenForm";
import {LoginViaTokenForm} from "./pages/auth/components/LoginViaTokenForm";
import {UserProfileLoadState} from "../store/user/type";
import {LoadProfileError} from "./pages/auth/components/LoadProfileError";
import {MainPage as StudentMainPage} from "./pages/student/main";
import {Schedule} from "./pages/student/schedule";
import {Performance} from "./pages/student/performance";
import {Payments} from "./pages/student/payments";
import {AboutSchool} from "./pages/student/about-school";
import {Episode as SelfStudyTrackEpisode} from "./pages/student/self-study-track/episode";
import {EpisodeEnd as SelfStudyTrackEpisodeEnd} from "./pages/student/self-study-track/episode-end";
import {TeacherLoginForm} from "./pages/auth/components/TeacherLoginForm";
import {
    TeachingMaterials as TeachingMeterialsCatalogPageOld
} from "./pages/teacher/teaching-materials/catalog-old/index";
import TeachingMeterialsCatalogPage from "./pages/teacher/teaching-materials/catalog/index";
import TeachingMeterialsCatalogLevelDetailsPage from "./pages/teacher/teaching-materials/catalog/level-details";
import TeachingMaterialsSectionDetailsPage from "./pages/teacher/teaching-materials/catalog/section-details";
import {LessonEditor} from "./pages/teacher/teaching-materials/lesson-editor";
import LessonPlayer from "./pages/teacher/teaching-materials/lesson-player";
import {KnowledgeBase} from "./pages/teacher/knowledge-base/section-list";
import {PagesList} from "./pages/teacher/knowledge-base/pages-list";
import {Page} from "./pages/teacher/knowledge-base/page";
import {GroupsList} from "./pages/teacher/groups";
import GroupInfo from "./pages/teacher/group";
import TeacherLessonPage from "./pages/teacher/lesson";
import {SlidePreview} from "./pages/teacher/teaching-materials/slide-preview";
import {ProfileSettingsPage} from "./pages/common/profile-settings";
import StudentLessonPage from "./pages/student/lesson/index";
import StudentHomeworkPage from "./pages/student/homework/main";
import StudentHomeworkSummaryPage from "./pages/student/homework/finish";
import {StudentsList} from "./pages/teacher/students";
import {AboutWinApp} from "./pages/teacher/about-win-app";
import {StudentInfo} from "./pages/teacher/student/profile-page";
import {CreateAgreementPage} from "./pages/teacher/student/create-agreement-page";
import {NewStudentPage} from "./pages/teacher/student/new-student-page";
import {InitialCustomizerPage} from "./pages/teacher/initial-customizer";
import {StudentDemoForm} from "./pages/auth/components/StudentDemoForm";
import {VideoCallTest} from "./pages/common/video-call-test";
import {Moderator} from "./pages/moderator/index";
import {SelfTracksCatalog} from "./pages/teacher/self-study-tracks";
import {EditTrack} from "./pages/teacher/self-study-track/edit";
import {SelfStudyStart} from "./pages/auth/components/SelfStudyStart";
import {ViewTrack} from "./pages/teacher/self-study-track/view";

export const RoutesConfig = (
    authState: UserProfileLoadState,
    userProfileIsEmpty: boolean,
    userIsTeacher: boolean,
    needInitialCustomizer: boolean
): RouteObject[] => [
    // Роутинг для авторизации
    {
        path: RoutesList.AUTH_LAYOUT,
        element: (authState === UserProfileLoadState.AUTHORIZED)
            ? <Navigate to={RoutesList.MAIN_PAGE}/>
            : <AuthPage/>,
        children: [
            {index: true, element: <LoginForm/>},
            {path: RoutesList.SIGN_UP_FORM, element: <SignUpForm/>},
            {path: RoutesList.RESTORE_FORM, element: <RestorePasswordForm/>},
            {path: RoutesList.RESTORE_VIA_TOKEN, element: <RestoreViaTokenForm/>},
            {path: RoutesList.LOGIN_VIA_TOKEN, element: <LoginViaTokenForm/>},
            {path: RoutesList.TEACHER_LOGIN_VIA_TOKEN, element: <LoginViaTokenForm/>},
            {path: RoutesList.LOAD_PROFILE_ERR, element: <LoadProfileError/>},
            {path: RoutesList.TEACHER_LOGIN_FORM, element: <TeacherLoginForm/>},
            {path: RoutesList.STUDENT_DEMO, element: <StudentDemoForm/>},
        ]
    },

    // Роутинг для страницы подписки на self-study трек
    {
        path: RoutesList.SELF_STUDY_SUBSCRIBE,
        element: <AuthPage/>,
        children: [
            {index: true, element: <SelfStudyStart/>},
        ]
    },


    // Роутинг для главной страницы
    {
        path: '/',
        element: (authState === UserProfileLoadState.NOT_AUTHORIZED)
            ? <Navigate to={RoutesList.AUTH_LAYOUT}/>
            : <Layout/>,
        children: [
            {
                index: true,
                element: (userIsTeacher)
                    ? (
                        (needInitialCustomizer)
                            ? <Navigate to={RoutesList.TEACHER_SETUP}/>
                            : <Navigate to={RoutesList.TEACHER_ALL_GROUPS}/>
                    )
                    : <StudentMainPage/>
            }
        ],
    },

    // Роутинг для общих страниц
    {
        path: RoutesList.PROFILE_SETTINGS,
        element: (authState === UserProfileLoadState.NOT_AUTHORIZED)
            ? <Navigate to={RoutesList.AUTH_LAYOUT}/>
            : <Layout/>,
        children: [
            {index: true, element: <ProfileSettingsPage/>},
        ]
    },
    {
        path: RoutesList.VIDEO_CALL_TEST,
        element: (authState === UserProfileLoadState.NOT_AUTHORIZED)
            ? <Navigate to={RoutesList.AUTH_LAYOUT}/>
            : <Layout/>,
        children: [
            {index: true, element: <VideoCallTest/>},
        ]
    },

    // Роутинг для "зоны" ученика
    {
        path: RoutesList.STUDENT_AREA,
        element: (authState === UserProfileLoadState.NOT_AUTHORIZED)
            ? <Navigate to={RoutesList.AUTH_LAYOUT}/>
            : ((userIsTeacher) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Layout/>),
        children: [
            {
                index: true, element: <Navigate to={RoutesList.MAIN_PAGE}/>
            },
            {
                path: RoutesList.STUDENT_LESSON_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <StudentLessonPage/>
            },
            {
                path: RoutesList.STUDENT_HOMEWORK_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <StudentHomeworkPage/>
            },
            {
                path: RoutesList.STUDENT_HOMEWORK_FINISH_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <StudentHomeworkSummaryPage/>
            },
            {
                path: RoutesList.SCHEDULE_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Schedule/>
            },
            {
                path: RoutesList.PERFORMANCE_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Performance/>
            },
            {
                path: RoutesList.STUDENT_PAYMENTS_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Payments/>
            },
            {
                path: RoutesList.ABOUT_SCHOOL_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <AboutSchool/>
            },
            {
                path: RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE,
                element: <SelfStudyTrackEpisode/>
            },
            {
                path: RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE_END,
                element: <SelfStudyTrackEpisodeEnd/>
            },
        ],
    },

    // Роутинг для "зоны" учителя
    {
        path: RoutesList.TEACHER_AREA,
        element: (authState === UserProfileLoadState.NOT_AUTHORIZED)
            ? <Navigate to={RoutesList.AUTH_LAYOUT}/>
            : ((!userIsTeacher) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Layout/>),
        children: [
            {
                index: true, element: <Navigate to={RoutesList.MAIN_PAGE}/>
            },
            {
                path: RoutesList.TEACHER_SETUP,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <InitialCustomizerPage/>
            },
            {
                path: RoutesList.TEACHER_MODERATOR,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Moderator/>
            },
            {
                path: RoutesList.TEACHER_ALL_STUDENTS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <StudentsList/>
            },
            {
                path: RoutesList.TEACHER_CREATE_STUDENT,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <NewStudentPage/>
            },
            {
                path: RoutesList.TEACHER_STUDENT_NEW_AGREEMENT,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <CreateAgreementPage/>
            },
            {
                path: RoutesList.TEACHER_STUDENT_PROFILE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <StudentInfo/>
            },
            {
                path: RoutesList.TEACHER_ALL_GROUPS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <GroupsList/>
            },
            {
                path: RoutesList.TEACHER_GROUP_INFO,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <GroupInfo/>
            },
            {
                path: RoutesList.TEACHER_LESSON_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <TeacherLessonPage/>
            },
            {
                path: RoutesList.TEACHER_TEACHING_MATERIALS_OLD,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> :
                    <TeachingMeterialsCatalogPageOld/>
            },
            {
                path: RoutesList.TEACHER_TEACHING_MATERIALS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <TeachingMeterialsCatalogPage/>
            },
            {
                path: RoutesList.TEACHER_TEACHING_MATERIALS_LEVEL_DETAILS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> :
                    <TeachingMeterialsCatalogLevelDetailsPage/>
            },
            {
                path: RoutesList.TEACHER_TEACHING_MATERIALS_SECTION_DETAILS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> :
                    <TeachingMaterialsSectionDetailsPage/>
            },
            {
                path: RoutesList.TEACHER_LESSON_EDITOR,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <LessonEditor/>
            },
            {
                path: RoutesList.TEACHER_LESSON_PLAYER,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <LessonPlayer/>
            },
            {
                path: RoutesList.TEACHER_SLIDE_PREVIEW,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <SlidePreview/>
            },
            {
                path: RoutesList.TEACHER_SLIDE_PREVIEW_W_VERSION,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <SlidePreview/>
            },
            {
                path: RoutesList.TEACHER_KNOWLEDGE_BASE_MAIN,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <KnowledgeBase/>
            },
            {
                path: RoutesList.TEACHER_KNOWLEDGE_BASE_SECTION,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <PagesList/>
            },
            {
                path: RoutesList.TEACHER_KNOWLEDGE_BASE_PAGE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <Page/>
            },
            {
                path: RoutesList.TEACHER_ABOUT_WIN_APP,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <AboutWinApp/>
            },
            {
                path: RoutesList.TEACHER_SELF_STUDY_TRACKS,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <SelfTracksCatalog/>
            },
            {
                path: RoutesList.TEACHER_SELF_STUDY_TRACK_CREATE,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <EditTrack/>
            },
            {
                path: RoutesList.TEACHER_SELF_STUDY_TRACK_VIEW,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <ViewTrack/>
            },
            {
                path: RoutesList.TEACHER_SELF_STUDY_TRACK_EDIT,
                element: (userProfileIsEmpty) ? <Navigate to={RoutesList.MAIN_PAGE}/> : <EditTrack/>
            },
        ],
    },
];
