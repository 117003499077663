import React, {forwardRef, useMemo} from "react";
import {Input} from "../../TextInput";
import {t} from "@lingui/macro";
import {TimezoneSelectModalWindow} from "./TimezoneSelectModalWindow";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {InputRef} from "antd/lib/input/Input";
import {
    DtoTimezoneSearchResponseItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StaticData/DtoTimezoneSearchResponseItem";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";

interface TimezoneSelectorProps {
    value?: DtoTimezoneSearchResponseItem;
    onChange?: (value: DtoTimezoneSearchResponseItem) => void;
}

export const TimezoneSelector = forwardRef<InputRef, TimezoneSelectorProps>(
    (props, ref) => {
        const stToken = useSelector(stTokenSelector);

        const currentValue = useMemo<string>(() => {
            if (!props.value) {
                return '';
            }

            const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

            const zoneTimeAsString = dateHelperService.dateFromStringToTimeString(props.value.currentTime);

            return `${zoneTimeAsString} (${props.value.offset})`;
        }, [props.value]);

        return <TimezoneSelectModalWindow
            stToken={stToken}
            trigger={
                <Input ref={ref} style={{cursor: "pointer"}} placeholder={t`Нажмите, чтобы выбрать`}
                       value={currentValue}
                       readOnly/>}
            onChange={(newValue) => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue);
                }
            }}/>
    }
)
