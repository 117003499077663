import {put, select, takeEvery} from 'redux-saga/effects';
import * as AppActionCreators from "../../../store/app/actions";
import * as UserActionCreators from "../../../store/user/actions";
import {ILogger} from "../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {WsHandlersActionType} from "../../../store/wsHandlers/type";
import {WsConnectionStatusEnum} from "../../../components/WsApiClient/WsConnectionStatusEnum";
import {ApplicationState} from "../../../store";
import {container} from "tsyringe";
import {DiTokens} from "../../../di-factory/DiTokens";

/**
 * Сага отвечает за обработку события подключения к WebSocket серверу.
 */

export function* watchWsConnected() {
    yield takeEvery(
        WsHandlersActionType.CONNECTED,
        wsConnected
    );
}

const selectWsReconnectionToken = (state: ApplicationState) => state.user.wsReconnectionToken;

function* wsConnected() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.debug(LoggerSectionsEnum.WS_COMPONENT, 'WebSocket connection opened');

    const reconnectionToken = (yield select(selectWsReconnectionToken)) as string | null;

    yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.CONNECTED));

    if (reconnectionToken === null) {
        // Если подключаемся первый раз в этом сеансе работы
        yield put(UserActionCreators.startUserAuthOnWsConnection());
    } else {
        // Если приложение было инициализировано ранее, значит нужно выполнить попытку переподключения.
        yield put(UserActionCreators.startUserReconnectionAuthOnWsConnection());
    }
}
