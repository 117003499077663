export class PlayerSlideModel {
    // slideDto: DtoTmSlide;
    tmSlideId: string;
    tmSlideName: string;
    contentVersion: number;
    exercisesCount: number;
    visibleForStudent: boolean;
    orderPosition: number | null;

    constructor(tmSlideId: string, tmSlideName: string, contentVersion: number, exercisesCount: number, visibleForStudent: boolean, orderPosition?: number) {
        // this.slideDto = slideDto;
        this.tmSlideId = tmSlideId;
        this.tmSlideName = tmSlideName;
        this.contentVersion = contentVersion;
        this.exercisesCount = exercisesCount;
        this.visibleForStudent = visibleForStudent;
        this.orderPosition = orderPosition ?? null;
    }
}
