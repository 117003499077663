import {IsString} from "class-validator";

export class DtoTmSlideContentItemInteractivityConfigActionCondition {
    @IsString()
    fieldForCondition: string; // Field for compare ("answer" for example)

    @IsString()
    conditionMode: string; // {@see SlideItemInteractivityActionConditionModeEnum}

    @IsString()
    targetValue: string; // Value for compare
}