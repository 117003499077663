import {ILocaleDictionaryDownloader} from "./ILocaleDictionaryDownloader";
import {LocaleEnum} from "../../store/i18n/type";
import {IHttpClient} from "../HttpClient/IHttpClient";
import {Exception as HttpClientBaseException} from "../HttpClient/exception/Exception";
import {Exception} from "./Exception";
import {StringHelper} from "../../helpers/StringHelper";
import {Messages} from "@lingui/core";
import {transform} from "relaxed-json";
import {IAppVersionHelper} from "../AppVersionHelper/IAppVersionHelper";

const DICTIONARY_URN_TEMPLATE = '{prefix}/locales/{locale}/messages.js?v={appVersion}';

class MessagesClass implements Messages {
    [key: string]: string;
}

export class LocaleDictionaryDownloader implements ILocaleDictionaryDownloader {
    protected localeDictionariesUrnPrefix: string;
    protected httpClient: IHttpClient;
    protected appVersionHelper: IAppVersionHelper;

    constructor(httpClient: IHttpClient, appVersionHelper: IAppVersionHelper) {
        this.httpClient = httpClient;
        this.appVersionHelper = appVersionHelper;
        this.localeDictionariesUrnPrefix = '';
    }

    /**
     * {@inheritDoc}
     */
    setUrnPrefix(localeDictionariesUrnPrefix: string) {
        this.localeDictionariesUrnPrefix = localeDictionariesUrnPrefix;
    }

    /**
     * {@inheritDoc}
     */
    public async downloadDictionary(locale: LocaleEnum): Promise<Messages> {
        let dictionaryResponseDto: String;
        let messages: MessagesClass;

        try {
            dictionaryResponseDto = await this.httpClient.get<String>(
                StringHelper.strtr(
                    DICTIONARY_URN_TEMPLATE,
                    {
                        'prefix': this.localeDictionariesUrnPrefix,
                        'locale': locale,
                        'appVersion': this.appVersionHelper.getAppVersion()
                    }
                ),
                String
            );

            let localeMessages = dictionaryResponseDto.substring(dictionaryResponseDto.indexOf("{") + 1);
            localeMessages = localeMessages.substring(localeMessages.indexOf("{"));
            localeMessages = localeMessages.slice(0, -2);

            localeMessages = transform(localeMessages);

            messages = JSON.parse(localeMessages);
        } catch (e) {
            if (e instanceof HttpClientBaseException) {
                throw new Exception("Locale dictionary download error: " + e.type + " - " + e.message);
            }

            throw e;
        }

        return messages;
    }
}
