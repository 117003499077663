import * as React from "react";
import {Link} from "./styles";
import {RoutesList} from "../../../RoutesList";
import {Trans} from "@lingui/macro";
import {useLocation} from "react-router-dom";

interface AboutWinAppLinkProps {
    onClick: () => void;
}

export const AboutWinAppLink: React.FC<AboutWinAppLinkProps> = (props) => {
    const location = useLocation();

    return <Link
        onClick={props.onClick}
        to={RoutesList.TEACHER_ABOUT_WIN_APP}
        $active={location.pathname === RoutesList.TEACHER_ABOUT_WIN_APP}>
        <Trans>Больше для учёта</Trans>
    </Link>;
}
