export enum ElementTypeEnum {
    TITLE = 'title',
    TEXT = 'text',
    UNFOLD_BLOCK = 'unfold_block',

    TEACHER_NOTES = 'teacher_notes',
    TEACHER_CAN_SAY = 'teacher_can_say',
    TEACHER_ALSO_DISCUSS = 'teacher_also_discuss',

    MEDIA_PICTURE = 'media_picture',
    MEDIA_VIDEO = 'media_video',
    MEDIA_AUDIO = 'media_audio',
    MEDIA_YOUTUBE = 'media_video_from_online_video_platform',

    EXERCISES_RADIO = 'exercises_radio',
    EXERCISES_CHECKBOX = 'exercises_checkbox',
    EXERCISES_FILL_GAPS_COMBOBOX = 'exercises_fill_gaps_combobox',
    EXERCISES_FILL_GAPS_TEXT = 'exercises_fill_gaps_text',
    EXERCISES_FILL_GAPS_DRAG_DROP = 'exercises_fill_gaps_drag_drop',
    EXERCISES_MATCH = 'exercises_match',
    EXERCISES_CORRECT_EXIST_IN_INPUT = 'exercises_correct_exist_in_input',

    CLIPBOARD_PASTE = 'clipboard_paste',
    DIALOG_BUBBLE = 'dialog_bubble',

    TEST_DIV = 'test_div'
}