import * as React from "react";
import {SelectedItemTitle} from "../index";
import {ModalResultType, TmLevelModal} from "./modal";
import {DtoTmLevel} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";

interface LevelTitleItemProps {
    disciplineId: string;
    item: DtoTmLevel;
    levels: DtoTmLevel[] | null;
    setLevels: (value: DtoTmLevel[] | null) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const LevelTitleItem: React.FC<LevelTitleItemProps> = (props) => {

    const updateItemInLevelsPropsList = (newModel: DtoTmLevel) => {
        if (props.levels === null || props.setLevels === null) {
            return;
        }

        const newDisciplinesList = props.levels.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        props.setLevels(newDisciplinesList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromLevelsPropsList = () => {
        if (props.levels === null || props.setLevels === null) {
            return;
        }

        const newDisciplinesList = props.levels.filter((item) => (
            (props.item.id !== item.id)
        ));

        props.setLevels(newDisciplinesList);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmLevel | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after level updated.');
            }

            updateItemInLevelsPropsList(data);

            return;
        }

        deleteCurrentItemFromLevelsPropsList();
    }

    return (
        <TmLevelModal
            disciplineId={props.disciplineId}
            levelItem={props.item}
            result={onResult}
            trigger={<SelectedItemTitle>{props.item.name}</SelectedItemTitle>}/>
    );
}