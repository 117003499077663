import React, {useContext, useEffect, useMemo} from "react";
import {ILessonPageContext, LessonPageContext} from "../LessonPageContext";
import {StudentItem} from '../../../common/lesson/RoomControlBlock/StudentItemType';
import styled from "styled-components";
import {roomMembersSelector} from "../../../../../store/lessonRoom/selector";
import {useSelector} from "react-redux";
import {MembersListItem} from "../../../common/lesson/RoomControlBlock/MembersListItem";
import {PageSubtitle2} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {LessonRoomMember, LessonRoomUserTypeEnum} from "../../../../../store/lessonRoom/type";
import {vcsParticipantsList} from "../../../../../store/vcsState/selector";
import {ParticipantItem} from "../../../../components/LessonConference/Types";

const MainWrapper = styled.div``;

const SectionWrapper = styled.div``;

const ListTitle = styled(PageSubtitle2)`
  margin-bottom: 14px;
  font-size: 18px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 24px;
    margin-bottom: 12px;
  }

  @media (${({theme}) => theme.media.large}) {
    font-size: 24px;
    margin-bottom: 12px;
    padding: 0 32px;
  }
`;

const List = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 32px;
`;

// const RightControlsWrapper = styled.div``; Пока не используется

export const MembersList: React.FC = () => {
    const lessonPageContext = useContext<ILessonPageContext>(LessonPageContext);
    const roomMembers = useSelector(roomMembersSelector);
    const vcsParticipants = useSelector(vcsParticipantsList);

    const studentItems = useMemo<{ studentItem: StudentItem, roomMemberData: LessonRoomMember | null, videoRoomParticipantData: ParticipantItem | null }[]>(() => {
        const result: { studentItem: StudentItem, roomMemberData: LessonRoomMember | null, videoRoomParticipantData: ParticipantItem | null }[] = [];

        lessonPageContext.students.forEach((studentItem) => {
            const memberItemIndex = roomMembers.findIndex(
                (memberItem) => memberItem.detailsForTeacher?.stApiUserId === studentItem.stId
            );

            if (memberItemIndex > -1) {
                const index = vcsParticipants.findIndex(item => item.id === roomMembers[memberItemIndex].userId);

                result.push({
                    studentItem: studentItem,
                    roomMemberData: roomMembers[memberItemIndex],
                    videoRoomParticipantData: (vcsParticipants[index]) ?? null
                });
            } else {
                result.push({
                    studentItem: studentItem,
                    roomMemberData: null,
                    videoRoomParticipantData: null
                });
            }
        });

        result.sort((a, b) => {
            if (a.roomMemberData && !b.roomMemberData) {
                return -1;
            }

            if (!a.roomMemberData && b.roomMemberData) {
                return 1;
            }

            return 0;
        })

        return result;
    }, [lessonPageContext.students, roomMembers, vcsParticipants]);

    const teachersInRoom = useMemo<Array<{ roomMemberData: LessonRoomMember, videoRoomParticipantData: ParticipantItem | null }>>(() => {
        const result: { roomMemberData: LessonRoomMember, videoRoomParticipantData: ParticipantItem | null }[] = [];

        roomMembers.filter(item => item.userType === LessonRoomUserTypeEnum.TEACHER)
            .forEach((teacherItem) => {
                const index = vcsParticipants.findIndex(item => item.id === teacherItem.userId);

                result.push({
                    roomMemberData: teacherItem,
                    videoRoomParticipantData: vcsParticipants[index] ?? null
                });

            });

        return result;
    }, [roomMembers, vcsParticipants]);

    // При исчезновении выбранного ученика из списка участников сбрасываем его выбор
    useEffect(() => {
        if (lessonPageContext.selectedRoomMemberId !== null) {
            const indexInMembersList = roomMembers.findIndex(
                (item) => item.userId === lessonPageContext.selectedRoomMemberId
            );

            if (indexInMembersList === -1) {
                lessonPageContext.setSelectedRoomMemberId(null);
            }
        }
    }, [roomMembers, lessonPageContext]);

    return <MainWrapper>
        <SectionWrapper>
            <ListTitle><Trans>Ученики</Trans></ListTitle>
            <List>
                {/*{*/}
                {/*    (lessonPageContext.onlineRoomIsConnected)*/}
                {/*    && <AllStudentsListItem onClick={() => lessonPageContext.setVideoRoomGalleryMode(null)}*/}
                {/*                            selected={*/}
                {/*                                (lessonPageContext.onlineRoomIsConnected)*/}
                {/*                                && (lessonPageContext.videoRoomGalleryGeneralMemberId === null)*/}
                {/*                            }*/}
                {/*                            studentItems={studentItems}*/}
                {/*                            countOnline={onlineStudentsCount}/>*/}
                {/*}*/}
                {
                    studentItems.map((studentItem) => {
                        if (studentItem.roomMemberData) {
                            const roomMemberData = studentItem.roomMemberData;

                            return <MembersListItem key={studentItem.studentItem.stId}
                                                    onClick={() => {
                                                        if (lessonPageContext.selectedRoomMemberId === roomMemberData.userId) {
                                                            lessonPageContext.setSelectedRoomMemberId(null);
                                                        } else {
                                                            lessonPageContext.setSelectedRoomMemberId(roomMemberData.userId);
                                                        }
                                                    }}
                                                    videoRoomParticipantData={studentItem.videoRoomParticipantData}
                                                    studentItem={studentItem.studentItem}
                                                    roomMemberData={studentItem.roomMemberData}
                                                    roomConnected={lessonPageContext.onlineRoomIsConnected}
                                                    roomHasVideo={lessonPageContext.onlineRoomHasVideo}
                                                    videoRoomGalleryGeneralMemberId={lessonPageContext.selectedRoomMemberId}/>
                        }

                        return <MembersListItem key={studentItem.studentItem.stId}
                                                studentItem={studentItem.studentItem}
                                                videoRoomParticipantData={studentItem.videoRoomParticipantData}
                                                roomMemberData={studentItem.roomMemberData}
                                                roomConnected={lessonPageContext.onlineRoomIsConnected}
                                                roomHasVideo={lessonPageContext.onlineRoomHasVideo}
                                                videoRoomGalleryGeneralMemberId={lessonPageContext.selectedRoomMemberId}/>
                    })
                }
            </List>
        </SectionWrapper>
        {
            (lessonPageContext.onlineRoomIsConnected && teachersInRoom.length > 0)
            && <SectionWrapper>
            <ListTitle><Trans>Учитель</Trans></ListTitle>
            <List>
                {
                    teachersInRoom.map((teacher) => {
                        return <MembersListItem key={teacher.roomMemberData.userId}
                                                onClick={() => {
                                                    if (lessonPageContext.selectedRoomMemberId === teacher.roomMemberData.userId) {
                                                        lessonPageContext.setSelectedRoomMemberId(null);
                                                    } else {
                                                        lessonPageContext.setSelectedRoomMemberId(teacher.roomMemberData.userId);
                                                    }
                                                }}
                                                videoRoomParticipantData={teacher.videoRoomParticipantData}
                                                studentItem={null}
                                                roomMemberData={teacher.roomMemberData}
                                                roomConnected={lessonPageContext.onlineRoomIsConnected}
                                                roomHasVideo={lessonPageContext.onlineRoomHasVideo}
                                                videoRoomGalleryGeneralMemberId={lessonPageContext.selectedRoomMemberId}/>
                    })
                }
            </List>
          </SectionWrapper>
        }
    </MainWrapper>
}
