/**
 * Класс регистрирует фабрики сервисов в конфигурации приложения для регистрации.
 */
import { container } from 'tsyringe';
import { DiFactoryList } from './DiFactoryList';
import { DiFactoryListItem } from './DiFactoryListItem';
import { DiFactoryType } from './DiFactoryType';

export class DiFactoryRegistrator {
  static registerFactories(factoryList: DiFactoryList) {
    factoryList.forEach((item: DiFactoryListItem) => {
      if (item.factoryType === DiFactoryType.CACHING_FACTORY) {
        this.registerCachedFactory(item.token, item.factory);
      }
    });
  }

  private static registerCachedFactory(token: string, factory: any) {
    container.register(token, {
      useFactory: factory,
    });
  }
}
