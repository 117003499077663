export class RoutesHelper {
    /**
     * @param template
     * @param params
     *
     * @return string
     */
    static replaceParams(template: string, params: Array<{ key: string, value: string }>): string {
        let result = template;

        params.forEach((item) => {
            result = result.replace(`:${item.key}`, item.value);
        });

        return result;
    }
}