import {AppInitializationStateTypes, AppState} from "./type";
import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";

export const initialState: AppState = {
    appInitializationState: AppInitializationStateTypes.WAIT_FOR_INIT,
    appInitializedAt: null,
    wsConnectionStatus: WsConnectionStatusEnum.NOT_INIT,
    inFatalErrorState: false,
    lastPingDate: null,
    pingTimeUpdateSuspended: false,
    wsConnectionRequired: false,
    wsReconnectNum: 0,
    wsDisconnectAlertImmediately: false,
    webRtcSupported: true,
    cameraAccess: {
        requestAccessNow: false,
        allowedInSession: null
    },
    routeLeaveConfirmMessage: null,
    needInitialCustomizer: false
};
