import {DtoResponseWithPagination} from "../DtoResponseWithPagination";
import {DtoStudentLessonWorkDataItem} from "./DtoStudentLessonWorkDataItem";
import {IsArray, IsNotEmpty, ValidateNested} from "class-validator";
import {Type} from "class-transformer";

export class DtoGetStudentsLessonWorkDataResult extends DtoResponseWithPagination<DtoStudentLessonWorkDataItem> {
    @IsNotEmpty()
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoStudentLessonWorkDataItem)
    list: Array<DtoStudentLessonWorkDataItem>;
}
