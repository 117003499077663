import {action} from "typesafe-actions";
import {FileFetchInfoProcessStatus, FileFormatsRepoActionTypes, FileInfo} from "./type";
import {UserFileFormatsEnum} from "../../enums/UserFileEnums";

export const fetchNewFileInfo = (fileId: string, preloadFormat?: UserFileFormatsEnum) => {
    const fileInfoObject: FileInfo = {
        id: fileId,
        fetchInfoStatus: FileFetchInfoProcessStatus.WAIT_FOR_START,
        type: null,
        availableFormats: [],
        formatForPrefetch: preloadFormat ?? null
    };

    return action(FileFormatsRepoActionTypes.FETCH_FILE_INFO, fileInfoObject);
};

export const setFileInfo = (fileInfoObject: FileInfo) => {
    return action(FileFormatsRepoActionTypes.SET_FILE_INFO, fileInfoObject);
}

export const removeFileInfo = (fileId: string) => {
    return action(FileFormatsRepoActionTypes.REMOVE_FILE_INFO, fileId);
}