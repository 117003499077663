import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {PlayerSlideModel} from "../../../../components/SlidePlayer/PlayerSlideModel";

export class SlidesLoader {
    protected httpApiClient: IHttpApiClient;
    protected logger: ILogger;
    protected sessionToken: string;

    constructor(httpApiClient: IHttpApiClient, logger: ILogger, sessionToken: string) {
        this.httpApiClient = httpApiClient;
        this.logger = logger;
        this.sessionToken = sessionToken;
    }

    async loadContent(tmLessonId: string): Promise<PlayerSlideModel[]> {
        const slides = await this.httpApiClient.tmGetSlides(this.sessionToken, tmLessonId, 1, 200);

        return slides.data.list.map((slideDto) => {
            return new PlayerSlideModel(
                slideDto.id,
                slideDto.name,
                slideDto.currentVersion,
                slideDto.exerciseCount,
                !slideDto.isAdditional
            );
        });
    }
}