import {Exception} from "./Exception";

export class IncorrectResponse extends Exception {
    public rawRequestBody: string;
    public rawResponseBody: string;

    constructor(message: string, rawRequestBody: string, rawResponseBody: string) {
        super(message);

        this.type = 'Incorrect response';

        this.rawRequestBody = rawRequestBody;
        this.rawResponseBody = rawResponseBody;
    }
}