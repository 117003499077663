import React, {useCallback, useEffect, useRef, useState} from "react";
import {SoundVolumeMeter} from "../../../../components/SoundVolumeMeter/SoundVolumeMeter";
import styled from "styled-components";
import classNames from "classnames";

const VolumeItemsList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`;

const VolumeItem = styled.div`
  display: block;
  height: 5px;
  border-radius: 3px;
  background-color: ${({theme}) => theme.colors.accentPrimary};
  opacity: 0.2;
  flex-grow: 1;
  
  &.active {
    opacity: 1;
  }
`;

interface SoundIndicatorProps {
    soundVolumeMeter: SoundVolumeMeter | null;
}

export const SoundIndicator: React.FC<SoundIndicatorProps> = ({soundVolumeMeter}) => {
    const interval = useRef<number | null>(null);

    const [items, setItems] = useState(() => {
        const list: boolean[] = Array(10);
        list.fill(false, 0, 10);

        return list;
    });

    const showValue = useCallback(() => {
        const newValues = Array(10);
        newValues.fill(false, 0, 10);

        if (soundVolumeMeter === null) {
            setItems(newValues);

            return;
        }

        let volumeLevel = soundVolumeMeter.getCurrentValue();

        volumeLevel = Math.round(volumeLevel * 100);

        for (let i = 0; i < 11; i++) {
            newValues[i] = volumeLevel >= i;
        }

        setItems(newValues);
    }, [soundVolumeMeter]);

    useEffect(() => {
        if (interval.current) {
            clearInterval(interval.current);
        }

        interval.current = (setInterval(showValue, 200) as unknown as number);
    }, [showValue]);

    useEffect(() => {
        if (interval.current) {
            clearInterval(interval.current);
        }

        interval.current = (setInterval(showValue, 200) as unknown as number);

        return () => {
            if (interval.current) {
                clearInterval(interval.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <VolumeItemsList>
        {
            items.map((active, index) => {
                return <VolumeItem
                    key={index}
                    className={classNames(active && 'active')}
                />
            })
        }
    </VolumeItemsList>
}
