import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export enum MarkFormats {
    BOLD = 'bold',
    ITALIC = 'italic',
    UNDERLINE = 'underline'
}

export const LIST_TYPES = [
    ElementTypes.NUMBERED_LIST,
    ElementTypes.BULLETED_LIST,
    ElementTypes.EXERCISE_RADIO_LIST,
    ElementTypes.EXERCISE_CHECKBOX_LIST
];

export enum RichTextEditorMode {
    ONLY_TEXT,
    TEXT_WITH_ADDONS,
    TITLE,
    FILL_GAPS_COMBOBOX,
    FILL_GAPS_TEXT,
    CORRECT_EXIST_IN_INPUT,
    FILL_GAPS_DRAG_DROP,
    RADIO_LIST,
    CHECKBOX_LIST
}

// Перечисление разрешённых элементов для разных элементов слайда, использующих textEditor
export const ALLOWED_FOR_TEXT_ELEMENT = [
    ElementTypes.PARAGRAPH,
    ElementTypes.BULLETED_LIST,
    ElementTypes.NUMBERED_LIST,
    ElementTypes.LIST_ITEM,
    ElementTypes.TRANSLATED,
];

export const ALLOWED_FOR_TRANSLATE_ELEMENT = [
    ElementTypes.HEADING_ONE,
    ElementTypes.PARAGRAPH,
    ElementTypes.LIST_ITEM,
    ElementTypes.EXERCISE_RADIO_LIST_ITEM,
    ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM,
];

export const ALLOWED_FOR_EXERCISE_CORRECT_EXIST_IN_INPUT = [
    ElementTypes.PARAGRAPH,
    ElementTypes.BULLETED_LIST,
    ElementTypes.NUMBERED_LIST,
    ElementTypes.LIST_ITEM,
    ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT,
    ElementTypes.TRANSLATED
];

export const ALLOWED_FOR_EXERCISE_FILL_GAPS_COMBOBOX = [
    ElementTypes.PARAGRAPH,
    ElementTypes.BULLETED_LIST,
    ElementTypes.NUMBERED_LIST,
    ElementTypes.LIST_ITEM,
    ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX,
    ElementTypes.TRANSLATED
];

export const ALLOWED_FOR_EXERCISE_FILL_GAPS_DRAG_DROP = [
    ElementTypes.PARAGRAPH,
    ElementTypes.BULLETED_LIST,
    ElementTypes.NUMBERED_LIST,
    ElementTypes.LIST_ITEM,
    ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP,
    ElementTypes.TRANSLATED
];

export const ALLOWED_FOR_EXERCISE_FILL_GAPS_TEXT = [
    ElementTypes.PARAGRAPH,
    ElementTypes.BULLETED_LIST,
    ElementTypes.NUMBERED_LIST,
    ElementTypes.LIST_ITEM,
    ElementTypes.EXERCISE_FILL_GAPS_TEXT,
    ElementTypes.TRANSLATED
];

export const ALLOWED_FOR_EXERCISE_RADIO = [
    ElementTypes.PARAGRAPH,
    ElementTypes.EXERCISE_RADIO_LIST,
    ElementTypes.EXERCISE_RADIO_LIST_ITEM,
    ElementTypes.TRANSLATED
];

export const ALLOWED_FOR_EXERCISE_CHECKBOX = [
    ElementTypes.PARAGRAPH,
    ElementTypes.EXERCISE_CHECKBOX_LIST,
    ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM,
    ElementTypes.TRANSLATED
];