import React, {useCallback, useContext, useRef} from "react";
import {VideoSubscriptionsActionsContextProvider} from "./VideoSubscriptionsActionsContext";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {IVideoRoomActionsContext, VideoRoomActionsContext} from "../video-room/VideoRoomActionsContext";
import {StreamTypeEnum} from "../../../../../components/JanusClient/Types";
import {SubscribersList} from "./SubscribersList";

interface VideoSubscriptionsActionsProviderProps {

}

export const VideoSubscriptionsActionsProvider: React.FC<VideoSubscriptionsActionsProviderProps> = ({children}) => {
    const videoRoomActionsContext = useContext<IVideoRoomActionsContext>(VideoRoomActionsContext);

    const logger = useRef<ILogger>(container.resolve<ILogger>(DiTokens.LOGGER));
    const timeoutRef = useRef<number | null>(null);

    const subscribersList = useRef(new SubscribersList());

    const waitTimerHandler = useCallback(() => {
        const data = subscribersList.current.flushList();

        logger.current.info(
            LoggerSectionsEnum.VIDEO_ROOM_SUBSCRIPTIONS_MANAGER,
            `Flush data: `,
            data
        );

        if (data.newSubscriptions.length > 0) {
            logger.current.info(
                LoggerSectionsEnum.VIDEO_ROOM_SUBSCRIPTIONS_MANAGER,
                `New subscription list `,
                data.newSubscriptions
            );

            if (data.newSubscriptions.length > 0) {
                videoRoomActionsContext.subscribeToStreams(data.newSubscriptions);
            }
        }

        if (data.deprecatedSubscriptions.length > 0) {
            logger.current.info(
                LoggerSectionsEnum.VIDEO_ROOM_SUBSCRIPTIONS_MANAGER,
                `Unsubscription list `,
                data.deprecatedSubscriptions
            );

            if (data.deprecatedSubscriptions.length > 0) {
                videoRoomActionsContext.unsubscribeFromStreams(data.deprecatedSubscriptions);
            }
        }

        if (timeoutRef.current) {
            timeoutRef.current = null;
        }
    }, [videoRoomActionsContext]);

    const setWaitTimer = useCallback(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);

            timeoutRef.current = null;
        }

        timeoutRef.current = setTimeout(waitTimerHandler, 1000) as unknown as number;
    }, [waitTimerHandler]);

    const subscribeToVideoStream = useCallback((userId: string, streamType: StreamTypeEnum, subscriberId: string) => {
        logger.current.info(
            LoggerSectionsEnum.VIDEO_ROOM_SUBSCRIPTIONS_MANAGER,
            `Requested subscription on user with id ${userId} for item ${subscriberId} (stream type ${streamType})`
        );

        subscribersList.current.addSubscriber(userId, streamType, subscriberId);

        setWaitTimer();
    }, [setWaitTimer]);

    const unsubscribeFromVideoStream = useCallback((userId: string, streamType: StreamTypeEnum, subscriberId: string) => {
        logger.current.info(
            LoggerSectionsEnum.VIDEO_ROOM_SUBSCRIPTIONS_MANAGER,
            `Requested unsubscription on user with id ${userId} for item ${subscriberId} (stream type ${streamType})`
        );

        subscribersList.current.removeSubscriber(userId, streamType, subscriberId);

        setWaitTimer();
    }, [setWaitTimer]);

    return <VideoSubscriptionsActionsContextProvider
        value={{
            subscribeToVideoStream,
            unsubscribeFromVideoStream
        }}>
        {children}
    </VideoSubscriptionsActionsContextProvider>
}
