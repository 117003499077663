import {put, select, takeEvery} from 'redux-saga/effects';
import * as StreamEventsCreators from "../../store/streamEvent/actions";
import {startHandlingEvents} from "../../store/streamEvent/actions";
import {StreamEventActionTypes, StreamEventItemState, StreamEventStoreItem} from "../../store/streamEvent/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {StreamEventBaseDto} from "../../components/WsApiClient/ApiDto/Response/StreamEvent/StreamEventBaseDto";
import {ApplicationState} from "../../store";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";

/**
 * Сага отвечает за обработку входящего web socket сообщения потока событий.
 */
export function* watchIncomingEvent() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<StreamEventBaseDto<object | null>>, null>>>(
        StreamEventActionTypes.INCOMING_EVENT,
        incomingEvent
    );
}

const getReadyToHandleState = (state: ApplicationState) => state.streamEvent.readyToHandle;

function* incomingEvent(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<StreamEventBaseDto<object | null>>, null>>) {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    logger.debug(
        LoggerSectionsEnum.WS_STREAM_EVENT,
        `Received incoming event from web socket (id: ${data.payload.response.result.id})`
    );

    let appIsReadyToHandleItems: boolean = yield select(getReadyToHandleState);

    let eventItem: StreamEventStoreItem<object | null> = new StreamEventStoreItem();

    eventItem.dto = data.payload.response.result;
    eventItem.state = StreamEventItemState.WAIT_FOR_HANDLE;

    yield put(StreamEventsCreators.pushEventsToList([eventItem]));

    yield put(StreamEventsCreators.setLastEventDate(eventItem.dto.createdAt));

    if (appIsReadyToHandleItems) {
        // Запускаем обработку событий.
        yield put(startHandlingEvents());
    }
}
