import React, {useMemo} from "react";
import {MonthAsString} from "./MonthAsString";
import styled from "styled-components";

// Преобразовать время (объект Date в строку вида "5 января" или "5 января '18")

const MonthName = styled.span`
    text-transform: lowercase;
`;

interface DateAsStringProps {
    date: Date;
    inGenitiveCase?: boolean;
}

export const DateAsString: React.FC<DateAsStringProps> = (props) => {
    const dateAsString = useMemo<JSX.Element>(() => {
        const currentDate = new Date();

        return <>
            {props.date.getDate().toString(10)}&nbsp;
            <MonthName>
                <MonthAsString monthNum={props.date.getMonth() + 1} inGenitiveCase={true}/>
            </MonthName>
            {
                (currentDate.getFullYear() !== props.date.getFullYear())
                && (' \'' + (props.date.getFullYear() % 100).toString(10))
            }
        </>;
    }, [props]);

    return <span>{dateAsString}</span>;
}
