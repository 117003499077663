import React from "react";
import {StepWizardStatusEnum} from "./StepWizardStatusEnum";

export type StepWizardVisibleConfig = {
    id: string;
    visible: boolean;
}

export type StepWizardStatusConfig = {
    id: string;
    status: StepWizardStatusEnum;
}

export interface IStepWizardContext {
    currentStepIsStart: boolean;
    currentStepIfFinish: boolean;
    goNext: (newStatusConfig?: StepWizardStatusConfig[], newVisibleConfig?: StepWizardVisibleConfig[]) => void;
    goPrev: () => void;
}

export const StepWizardContext = React.createContext<IStepWizardContext>({
    currentStepIsStart: false,
    currentStepIfFinish: false,
    goNext: () => {},
    goPrev: () => {}
});

export const StepWizardContextProvider = StepWizardContext.Provider;
