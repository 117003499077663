import React from "react";
import styled from "styled-components";
import classNames from "classnames";

export enum ValueShowMode {
    LINE,
    COLUMN
}

const Wrapper = styled.div`
  display: flex;

  &.line {
    flex-direction: row;
    align-items: center;
  }

  &.col {
    flex-direction: column;
    align-items: center;
  }
`;

const ValueWrapper = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;

  &.line {
    margin-right: 12px;
  }
`;

const NameWrapper = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
  flex-grow: 1;

  margin-bottom: 4px;
  font-size: 14px;
  line-height: 18px;

  &.col {
    text-align: center;
  }
`;

interface ValueProps {
    value: number | null;
    name: string;
    showMode: ValueShowMode;
}

export const Value: React.FC<ValueProps> = ({showMode, name, value}) => {
    return <Wrapper className={classNames(showMode === ValueShowMode.COLUMN ? "col" : "line")}>
        <ValueWrapper className={classNames(showMode === ValueShowMode.COLUMN ? "col" : "line")}>
            {
                (value === null)
                    ? <>&mdash;</>
                    : <>{value}&nbsp;%</>
            }
        </ValueWrapper>
        <NameWrapper className={classNames(showMode === ValueShowMode.COLUMN ? "col" : "line")}>{name}</NameWrapper>
    </Wrapper>
}