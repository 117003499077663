import {IsNotEmpty, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO ответа на запрос url для отправки файла
 */
export class DtoUrlForUploadResponse {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    fileId: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 500)
    uploadUrl: string;
}
