import {RenderLeafProps} from "slate-react";
import React from "react";

export interface LeafProps extends RenderLeafProps {
}

export const Leaf: React.FC<LeafProps> = ({attributes, children, leaf}) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}