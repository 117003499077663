import {forwardRef, useImperativeHandle, useMemo} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {RichTextReader} from "../../RichTextReader";
import {IBaseElement} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {RegularText} from "../common-styles";
import classNames from "classnames";
import {AlignTypes} from "../../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

const ListItemStyled = styled.li`
  &.align-right {
    text-align: right;
  }

  &.align-justify {
    text-align: justify;
  }

  &.align-center {
    text-align: center;
  }

  &.align-left {
    text-align: left;
  }
`;

export const ListItem = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IBaseElement>>(
    (props, ref) => {

        const {element, className, ...otherProps} = props;

        useImperativeHandle(ref, () => ({}));

        const alignClass = useMemo(() => {
            switch (element.align) {
                case AlignTypes.RIGHT: {
                    return "align-right";
                }
                case AlignTypes.JUSTIFY: {
                    return "align-justify";
                }
                case AlignTypes.CENTER: {
                    return "align-center";
                }
                default: {
                    return "align-left";
                }
            }
        }, [element]);

        return <ListItemStyled className={classNames(alignClass, className)}>
            <RichTextReader elements={element.children}  {...otherProps}/>
        </ListItemStyled>;
    }
);

ListItem.displayName = 'ListItem';

export const ListItemDefaultStyle = styled(ListItem)`
  ${RegularText};
  margin-bottom: 5px;

  color: ${({theme}) => theme.colors.textPrimary};

  &::marker {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;