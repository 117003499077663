import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {
    DtoTmLessonSummaryResponse
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLessonSummaryResponse";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, DefaultButtonProps} from "../../../../../components/Ui/Elements/Button";
import {ReactComponent as MosaicSvg} from "../../../../../components/Ui/Svg/Mosaic.svg";
import {ReactComponent as PlaySvg} from "../../../../../components/Ui/Svg/Play.svg";
import {LinkAsButton} from "../../../../../components/Ui/Elements/LinkAsButton";
import {RoutesList} from "../../../../../RoutesList";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";

interface LessonDetailsCardProps {
    lessonId: string;
    descriptionForTeacher: string | null;
    descriptionForStudent: string | null;
}

const LessonDetailsWrapper = styled.div`

`;

const LessonParameterWrapper = styled.div`
  margin-bottom: 20px;
`;

const LessonParameterName = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

const LessonParameterValue = styled.div`

`;

const LessonParameterEmpty = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
`;

const ActionsWrapper = styled.div`
  display: inline-block;
`;

const BtnStyled = styled(LinkAsButton)<DefaultButtonProps>`
  padding: 5px 15px 5px 5px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
`;

export const LessonDetailsCard: React.FC<LessonDetailsCardProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [summaryModel, setSummaryModel] = useState<null | DtoTmLessonSummaryResponse>(null);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {lessonId} = props;

    const loadContent = useCallback(() => {
        if (sessionToken === null) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        httpApiClient.tmGetLessonSummaryInfo(sessionToken, lessonId)
            .then((data) => {
                setSummaryModel(data.data);
            })
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.TM_LESSONS_API,
                    'Error on fetch tmLesson summary: ',
                    err
                );

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [httpApiClient, lessonId, logger, sessionToken]);

    useEffect(() => {
        loadContent();
    }, [lessonId, loadContent, logger, sessionToken]);

    const linkToEditor = useMemo<string>((): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_EDITOR,
            [
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );
    }, [lessonId]);

    const linkToPlayer = useMemo<string>((): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_PLAYER,
            [
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );
    }, [lessonId]);

    return (
        (nowLoading) ? <DefaultLoader/> :
            (loadingError) ? <ErrorLoadingContent
                    title={t`Не удалось загрузить детали урока`}
                    retryBtnClick={() =>
                        loadContent()
                    }/>
                : (summaryModel) && <LessonDetailsWrapper>

                <LessonParameterWrapper>
                    <LessonParameterName><Trans>Количество слайдов</Trans></LessonParameterName>
                    {(!summaryModel.slidesCount)
                        ? <LessonParameterEmpty>Нет слайдов</LessonParameterEmpty>
                        : <LessonParameterValue>{summaryModel.slidesCount}</LessonParameterValue>
                    }
                </LessonParameterWrapper>

                <LessonParameterWrapper>
                    <LessonParameterName><Trans>Описание для ученика</Trans></LessonParameterName>
                    {(!props.descriptionForStudent)
                        ? <LessonParameterEmpty><Trans>Не указано</Trans></LessonParameterEmpty>
                        : <LessonParameterValue>{props.descriptionForStudent}</LessonParameterValue>
                    }
                </LessonParameterWrapper>

                <LessonParameterWrapper>
                    <LessonParameterName><Trans>Описание внутреннее</Trans></LessonParameterName>
                    {(!props.descriptionForStudent)
                        ? <LessonParameterEmpty><Trans>Не указано</Trans></LessonParameterEmpty>
                        : <LessonParameterValue>{props.descriptionForTeacher}</LessonParameterValue>
                    }
                </LessonParameterWrapper>

                <ActionsWrapper>
                    <BtnStyled to={linkToEditor} icon={<MosaicSvg/>} btnStyle={BtnStyleEnum.Primary}>
                        Редактировать слайды
                    </BtnStyled><br/>
                    <BtnStyled to={linkToPlayer} icon={<PlaySvg/>} btnStyle={BtnStyleEnum.Secondary}>
                        Протестировать
                    </BtnStyled>
                </ActionsWrapper>

            </LessonDetailsWrapper>
    );
}