import React from "react";
import styled from "styled-components";
import {BlockWithBorderCss, PageSubtitle} from "../../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {
    DtoOnlinePaymentDocumentResponseItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {PaymentForm} from "../../../common/online-payment/PaymentForm";
import {MainPageSection} from "../../style";

const Wrapper = styled.div`
  ${BlockWithBorderCss};

  padding: 24px 20px;

  @media (${({theme}) => theme.media.small}) {
    padding: 40px 32px 12px 32px;
  }
`;

interface PayOnlineProps {
    acquiringDocuments: DtoOnlinePaymentDocumentResponseItem[];
}

export const PayOnline: React.FC<PayOnlineProps> = ({acquiringDocuments}) => {
    return <MainPageSection>
        <PageSubtitle><Trans>Пополнение счёта</Trans></PageSubtitle>
        <Wrapper>
            <PaymentForm acquiringDocuments={acquiringDocuments}/>
        </Wrapper>
    </MainPageSection>
}

PayOnline.displayName = 'PayOnline';