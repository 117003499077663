import React, {forwardRef} from 'react';
import styled from "styled-components";

const ListStyled = styled.ul<Props>`
  display: grid;
  grid-auto-rows: max-content;
  box-sizing: border-box;
  min-width: 70px;
  grid-gap: ${(props) => (props.tinyItems) ? "0" : "10px"};
  padding: 0;
  margin: 0;
`;

export interface Props {
    children: React.ReactNode;
    tinyItems?: boolean;
}

export const List = forwardRef<HTMLUListElement, Props>(
    ({children, tinyItems}: Props, ref) => {
        return (
            <ListStyled tinyItems={tinyItems} ref={ref}>
                {children}
            </ListStyled>
        );
    }
);