import React, {forwardRef, useImperativeHandle, useMemo} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import styled from "styled-components";
import {ElementData} from "../../../SlidePlayerEditorCommonParts/components/Video/Common";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Video/PresenterComponent";

const VideoElementWrapper = styled.div`

`;

const SizeContainer = styled.div`
  margin: auto;
`;

interface VideoProps extends IElementProps<ElementData> {
}

interface VideoRefMethods extends IElementRefMethods {
}

export const Video = forwardRef<VideoRefMethods, VideoProps>(
    (props, ref) => {
        const {elementData} = props;

        const videoData = useMemo<ElementData>(() => {
            return elementData.data;
        }, [elementData]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <VideoElementWrapper>
            <SizeContainer>
                <PresenterComponent elementData={videoData}/>
            </SizeContainer>
        </VideoElementWrapper>;
    }
);

Video.displayName = 'Video';