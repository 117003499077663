import React, {useCallback, useContext, useEffect} from "react";
import {IInitialCustomizerPageContext, InitialCustomizerPageContext} from "../InitialCustomizerPageContext";
import styled from "styled-components";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum} from "../../../../components/Ui/Elements/Button";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {PageSectionsEnum} from "../../student/new-student-page/PageSectionsEnum";
import {KbLinks} from "../../../../components/KbLinksList";
import {KbPageIdsEnum} from "../../../../../enums/KbPageIdsEnum";
import {useDispatch} from "react-redux";
import {setInitialCustomizerValue} from "../../../../../store/app/actions";
import {
    ActualParamsDto
} from "../../../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {setUpdatesAfterInitialCustomizerFinished} from "../../../../../store/user/actions";
import {SchoolTypeEnum} from "../../../../../components/StonlineApiClient/Enums/SchoolTypeEnum";


const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

export const FinishSection: React.FC = () => {
    const pageContext = useContext<IInitialCustomizerPageContext>(InitialCustomizerPageContext);

    const dispatch = useDispatch();

    const disableInitialCustomizer = useCallback(
        () => dispatch(setInitialCustomizerValue(false)), [dispatch]
    );

    const updatesAfterInitialCustomizer = useCallback(
        (userName: string, timezoneName: string, actualParams: ActualParamsDto, schoolName: string) =>
            dispatch(setUpdatesAfterInitialCustomizerFinished(userName, timezoneName, actualParams, schoolName)), [dispatch]
    );

    useEffect(() => {
        disableInitialCustomizer();
        updatesAfterInitialCustomizer(
            pageContext.formData.userName,
            pageContext.formData.timezoneName ?? "Asia/Novosibirsk",
            {
                first: pageContext.formData.actualParam1Name,
                second: (pageContext.formData.actualParamsCount > 1 && pageContext.formData.actualParam2Name) ? pageContext.formData.actualParam2Name : null,
                third: (pageContext.formData.actualParamsCount > 2 && pageContext.formData.actualParam3Name) ? pageContext.formData.actualParam3Name : null,
                fourth: (pageContext.formData.actualParamsCount > 3 && pageContext.formData.actualParam4Name) ? pageContext.formData.actualParam4Name : null,
                fifth: (pageContext.formData.actualParamsCount > 4 && pageContext.formData.actualParam5Name) ? pageContext.formData.actualParam5Name : null
            },
            (pageContext.formData.schoolType === SchoolTypeEnum.PERSONAL_TUTOR)
                ? pageContext.formData.userName
                : pageContext.formData.schoolName,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>
        <FormWrapper>
            <PageSubtitleSmallMargin>
                <Trans>Добавить первого ученика?</Trans>
            </PageSubtitleSmallMargin>
            <RegularText>
                <Trans>Рекомендуем прямо сейчас добавить ученика и настроить расписание. Начнём?</Trans>
            </RegularText>
        </FormWrapper>
        <LinkAsButton btnStyle={BtnStyleEnum.Primary} to={
            RoutesHelper.replaceParams(
                RoutesList.TEACHER_CREATE_STUDENT,
                [
                    {
                        key: 'stepId',
                        value: PageSectionsEnum.GENERAL
                    }
                ]
            )
        }>Добавить первого ученика</LinkAsButton>
        <br/>
        <br/>
        <br/>
        <br/>
        <FormWrapper>
            <PageSubtitleSmallMargin>
                <Trans>Если возникнут вопросы и предложения</Trans>
            </PageSubtitleSmallMargin>
            <RegularText>
                <Trans>Команда «Мои занятия» всегда рядом - в разделе «Справка и поддержка» есть контакты для
                    связи.</Trans>
            </RegularText>
            <br/>
            <RegularText>
                <Trans>Есть идея, как эффективно изучать слова и термины? Придумали новый вид упражнений? Есть
                    идея обучающей игры в классе начальной школы? Напишите нам - обсудим.</Trans>
            </RegularText>
        </FormWrapper>
        <br/>
        <br/>
        <FormWrapper>
            <PageSubtitleSmallMargin><Trans>Изучить инструменты</Trans></PageSubtitleSmallMargin>
            <RegularText>
                <Trans>Познакомьтесь с доступными инструментами для онлайн урока и домашних заданий (ссылки
                    откроются в новых вкладках):</Trans>
            </RegularText>
        </FormWrapper>
        <KbLinks linksList={[
            {
                name: t`Электронный учебник - основы`,
                pageId: KbPageIdsEnum.ABOUT_TEACHING_MATERIALS_CATALOG
            },
            {
                name: t`Как начать онлайн урок`,
                pageId: KbPageIdsEnum.HOW_TO_START_ONLINE_LESSON
            },
            {
                name: t`Видеосвязь на уроке`,
                pageId: KbPageIdsEnum.VIDEOCONFERENCE_ON_LESSON
            },
            {
                name: t`Работа со слайдами на уроке`,
                pageId: KbPageIdsEnum.WORK_WITH_SLIDES_ON_LESSON
            }
        ]}/>
    </div>;
}