import {IAnswerChecker} from "./IAnswerChecker";

export class AnswerChecker implements IAnswerChecker {

    public checkAnswer(correctVariants: string[], userVariant: string): number | null {
        const filteredVariants = correctVariants.map(item => this.filterVariant(item));
        const filteredUserVariant = this.filterVariant(userVariant);

        const correctVariantIndex = filteredVariants.indexOf(filteredUserVariant);

        return correctVariantIndex > -1 ? correctVariantIndex : null;
    }

    public engShortMap(): { [short: string]: string } {
        return {
            // Исключительные ситуации, которые лучше обработать первыми
            "i ain't": "i'm not", //I ain't going to tell you again. -> I'm not going to tell you again.
            "we ain't": "we haven't", //We ain't done any harm to him. -> We haven't done any harm to him
            "i've not": "i haven't",

            // do
            "don't": "do not",
            "doesn't": "does not",
            "didn't": "did not",
            "dostn't": "dost not",
            "didstn't": "didst not",
            "dothn't": "doth not",
            "d'you": "do you",

            // be
            "i'm": "i am",
            "he's": "he is",
            "she's": "she is",
            "it's": "it is",
            "you're": "you are",
            "we're": "we are",
            "they're": "they are",

            // устаревшие формы употребляемые с местоимением "thou":
            "thou'rt": "thou art",
            "thou'st": "thou beest",

            // Не можем обработать "s, т.к. Nelly's my daughter. (Nelly is my daughter.) может быть и Nelly's daughter (дочь Нелли)

            // Сокращённая отрицательная частица "n't" [nt] от "not":
            "we're not": "we are not",
            "isn't": "is not",
            "amn't": "am not",
            "an't": "am not",
            "aren't": "are not",
            "bisn't": "beest not",
            "beestn't": "beest not",

            // Прошедшее время
            "wasn't": "was not",
            "weren't": "were not",

            "wastn't": "wast not",
            "wertn't": "wert not",

            // have
            "i've": "i have",
            "you've": "you have",
            "we've": "we have",

            // has he's; she's; it's повторяют is. Но если "под капотом" будут обрабатываться через be и ответ ученика
            // и все верные варианты - варианты совпадут и получается корректно.
            // "he's": "he has",
            // "she's": "she has",
            // "it's": "is has"

            "i'd": "i had",
            "you'd": "you had",
            "he'd": "he had",
            "she'd": "she had",
            "we'd": "we had",
            "they'd": "they had",

            "haven't": "have not",
            "hasn't": "has not",
            "hadn't": "had not",

            // "thou'st": "thou hast", повторяется с вариантом выше
            "thou'dst": "thou hadst",
            "hathn't": "hath not",

            // can
            "can't": "cannot",
            "canstn't": "canst not",
            "couldn't": "could not",

            // may
            "mayn't": "may no",
            "maystn't": "mayst not",
            "mightn't": "might not",

            // must
            "mustn't": "must not",

            // need
            "needn't": "need not",

            //shall
            // "'ll": "shall" - рискованно, нужно или более точно, или лучше не делать (например We'll)?
            "shan't": "shall not",
            "shaltn't": "shalt not",

            // will
            // "'ll": "will" - рискованно
            "won't": "will not",
            "wiltn't": "will not",

            //would
            // "'d": "would" - рискованно
            "wouldn't": "would not",
            "wouldstn't": "wouldst not",
        }
    }

    /**
     * Обработать значение
     *
     * @param text
     * @protected
     */
    protected filterVariant(text: string): string {
        // Trim
        let result = text.trim();

        // Символ переноса строки
        result = result.replace('/(\\r\\n|\\r)/g', '');

        // Нижний регистр
        result = result.toLowerCase();

        // Заменяем апострофы типа "английская одинарная кавычка" и похожие
        result = result.replace(/[’‘`]/g, '\'');

        // Удаляем знаки препинания
        result = result.replace(/[!?:;.,]/g, '');

        // Разные виды "тире" на минус
        result = result.replace(/[−–—]/g, '-'); // &minus;, &ndash;, &mdash;

        // Заменяем пробельные последовательности на один пробел
        result = result.replace(/\s+/g, ' ');

        // Разворачиваем краткие формы в полные для английского языка
        result = this.engShortForms(result);

        return result;
    }

    protected engShortForms(text: string): string {
        const variants = this.engShortMap();

        let result = text;

        for (let key in variants) {
            result = result.replaceAll(key, variants[key]);
        }

        return result;
    }
}
