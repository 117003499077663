import {IsOptional, IsString, Length,} from 'class-validator';
import {BaseRequestDto} from '../BaseRequestDto';

/**
 * DTO параметров запроса сохранения информации о UTM метках пользователя
 */
export class UserUtmSaveRequestDto extends BaseRequestDto {
    @IsOptional()
    @IsString()
    @Length(1, 100)
    utmSource: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 100)
    utmMedium: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 100)
    utmCampaign: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 100)
    utmContent: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 100)
    utmTerm: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 500)
    otherUtms: string | null;

    @IsOptional()
    @IsString()
    @Length(1, 100)
    routeName: string | null;

    constructor() {
        super();

        this.utmSource = null;
        this.utmMedium = null;
        this.utmCampaign = null;
        this.utmContent = null;
        this.utmTerm = null;
        this.otherUtms = null;
        this.routeName = null;
    }
}