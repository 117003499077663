import {
    QueueItemDataTypeEnum,
    QueueItemTargetEnum
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItem";

export enum SlidesWorkDataSaveQueueActionTypes {
    NEW_ITEM_TO_QUEUE = '@@slidesWorkData/NEW_ITEM_TO_QUEUE',
    START_SAVE_DATA_AFTER_RECONNECT = '@@slidesWorkData/START_SAVE_DATA_AFTER_RECONNECT',

    START_HANDLE_CYCLE = '@@slidesWorkData/START_HANDLE_CYCLE',
    STOP_HANDLE_CYCLE = '@@slidesWorkData/STOP_HANDLE_CYCLE',

    SEND_QUEUE = '@@slidesWorkData/SEND_QUEUE',

    SET_NEW_ITEM = '@@slidesWorkData/SET_NEW_ITEM',
    SET_ITEMS_HANDLE_STATE = '@@slidesWorkData/SET_ITEMS_HANDLE_STATE',

    SET_CYCLE_ENABLED_VALUE = '@@slidesWorkData/SET_CYCLE_DISABLED_VALUE',
    SET_CYCLE_STATE_VALUE = '@@slidesWorkData/SET_CYCLE_STATE_VALUE',

    DELETE_ITEMS = '@@slidesWorkData/DELETE_ITEMS',

    CLEAR_ALL = '@@slidesWorkData/CLEAR_ALL'
}

export enum QueueSendCycleStateEnum {
    STOPPED = 'stopped',
    WORKING = 'working'
}

export enum ItemHandleState {
    WAIT = 'wait',
    IN_PROCESS = 'in_process'
}

export interface ValuePayload {
    value: string;
}

export interface AdditionalDataPayload {
    value: string;
}

export interface OverriddenParamsPayload {
    visible?: boolean;
}

export interface AnswerPayload {
    value: string;
    award: number;
    missedAward: number;
    answerIsCorrect: boolean;
}

export interface QueueItemLessonOrHomeworkContextData {
    lessonId: string;
    tmSlideId: string;
}

export interface QueueItemSelfStudyTrackContextData {
    episodeId: string;
    tmSlideId: string;
}

export type QueueItemContext = QueueItemSelfStudyTrackContextData | QueueItemLessonOrHomeworkContextData

export interface QueueItem<T> {
    itemNumber: number;
    handleState: ItemHandleState;
    // Тип цели (в какую сущность сохраняем результат), например - работа ученика на уроке
    targetType: QueueItemTargetEnum;
    // Из пользователя, в профиль которого нужно сохранить результат
    targetUserId: string;
    slideItemId: string;
    exerciseId: string | null;
    // Тип данных
    dataType: QueueItemDataTypeEnum;
    // Содержимое
    payload: AdditionalDataPayload | ValuePayload | AnswerPayload | OverriddenParamsPayload;
    contextData: T;
}

export interface SlidesWorkDataSaveQueueState {
    sendCycleEnabled: boolean;
    sendCycleState: QueueSendCycleStateEnum;
    lastItemNumber: number;
    queue: QueueItem<QueueItemContext>[];
}