import {IHttpClient} from "./IHttpClient";
import {ClassType} from "../../types/ClassType";
import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {Exception} from "./exception/Exception";
import {NotFoundException} from "./exception/NotFoundException";
import {ServerErrorException} from "./exception/ServerErrorException";
import {UploadFileCallbackList, UploadFileMethodResult} from "./Types";

enum HttpCodes {
    OK = 200,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500
}

export class HttpClient implements IHttpClient {
    protected httpClient: AxiosInstance | null;

    constructor(config: AxiosRequestConfig) {
        this.httpClient = axios.create(config);
    }

    /**
     * @inheritDoc
     */
    public async get<T extends object>(url: string, resultClass: ClassType<T>): Promise<T> {
        return new Promise((resolve, reject) => {

            if (this.httpClient === null) {
                reject(new Exception("Axios not initialized in HttpClient object"));

                return;
            }

            this.httpClient.get(url)
                .then((data) => {
                    switch (data.status) {
                        case HttpCodes.OK: {
                            resolve(data.data);

                            return;
                        }

                        case HttpCodes.NOT_FOUND: {
                            reject(new NotFoundException(data.data));

                            return;
                        }

                        case HttpCodes.UNAUTHORIZED: {
                            reject(new NotFoundException(data.data));

                            return;
                        }
                    }

                    if (data.status >= HttpCodes.INTERNAL_SERVER_ERROR) {
                        reject(new ServerErrorException(data.data));

                        return;
                    }

                    reject(new Exception(data.data));
                })
                .catch((data) => {
                    reject(new Exception(data));
                })
        });
    }

    /**
     * @param url
     * @param file
     * @param callbackList
     */
    uploadFile<T>(url: string, file: File | Blob, callbackList: UploadFileCallbackList): UploadFileMethodResult<T> {
        const controller = new AbortController();

        const promise = new Promise<T>((resolve, reject) => {
            if (this.httpClient === null) {
                reject(new Exception("Axios not initialized in HttpClient object"));

                return;
            }

            const data = new FormData();

            data.append('file', file);

            this.httpClient.post<T>(url, data, {
                onUploadProgress: callbackList.onProgress,
                signal: controller.signal
            })
                .then((data) => {
                    resolve(data.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return {
            processPromise: promise,
            abortProcess: () => {
                controller.abort()
            }
        }
    }
}