import {FooterMode, Modal, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import React, {forwardRef, useCallback, useContext, useMemo, useState} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin, PageTitle, RegularText} from "../../../../../../styles/global-elements";
import {Form, FormItem, useForm} from "../../../../../../components/Ui/Elements/Form";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {container} from "tsyringe";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {RadioGroup, RadioInput} from "../../../../../../components/Ui/Elements/RadioInput";
import moment from "moment";
import {DatePicker} from "../../../../../../components/Ui/Elements/DatePicker";
import {MoneyInput} from "../../../../../../components/Ui/Elements/MoneyInput";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";

const FormWrapper = styled.div`
  margin-bottom: 100px;
`;

const StartFromGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  margin-bottom: 30px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StartFromInputWrapper = styled.div`
  padding-left: 25px;
  max-width: 300px;
`;

const NewCostInputWrapper = styled.div`
  max-width: 300px;
`;


const Comment = styled(RegularText)`
  margin-bottom: 20px
`;

enum FormFieldNamesEnum {
    START_MODE_SELECTOR = 'start_mode_selector',
    START_FROM = 'start_from',
    NEW_COST = 'new_cost'
}

enum StartFromEnum {
    NOW = 0,
    FROM_DATE = 1
}

interface ScheduleModalProps {
    agreementId: number;
}

export const NewCostModal = forwardRef<PopupActions, ScheduleModalProps>(
    (
        {
            agreementId
        },
        ref
    ) => {
        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);

        const [savingNow, setSavingNow] = useState<boolean>(false);

        const [startFromVisible, setStartFromVisible] = useState<boolean>(false);

        const stToken = useSelector(stTokenSelector);

        const [form] = useForm();

        const stApiClient = useMemo(() => container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT), []);

        const onFormValuesChange = useCallback(() => {
            const startModeSelectorValue =
                form.getFieldValue(FormFieldNamesEnum.START_MODE_SELECTOR) === StartFromEnum.FROM_DATE;

            if (startModeSelectorValue !== startFromVisible) {
                setStartFromVisible(startModeSelectorValue);
            }
        }, [form, startFromVisible]);

        const onFinish = async (controls: ModalControlParams, values: any) => {
            if (!stToken) {
                return;
            }

            setSavingNow(true);

            const startFromDateMode =
                (form.getFieldValue(FormFieldNamesEnum.START_MODE_SELECTOR) === StartFromEnum.FROM_DATE)
                && (!!form.getFieldValue(FormFieldNamesEnum.START_FROM));

            const newValue = form.getFieldValue(FormFieldNamesEnum.NEW_COST);

            stApiClient.createNewCostRegisterRecord(
                stToken,
                agreementId,
                newValue,
                startFromDateMode,
                (startFromDateMode) ? form.getFieldValue(FormFieldNamesEnum.START_FROM).format('YYYY-MM-DD') : null
            )
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Сохранено`,
                        t`Информация успешно сохранена`
                    );

                    studentPageContext.reloadBaseInfo();
                })
                .catch((e) => {
                    setSavingNow(false);

                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось применить изменения`,
                            t`Проверьте соединение с интернетом`
                        );

                        return;
                    }

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось применить изменения`,
                        t`Попробуйте повторить попытку позднее`
                    );

                    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                    logger.error(
                        LoggerSectionsEnum.STONLINE_SCHEDULE_API,
                        `Error on save new cost for agreement ${agreementId}: `,
                        e
                    );
                });
        }

        return <Modal
            innerRef={ref}
            footerMode={FooterMode.SPACE_BETWEEN}
            onOpen={() => form.resetFields()}
            closeAllowed={!savingNow}
            footer={
                (controls) => {
                    return <>
                        <Button btnStyle={BtnStyleEnum.Primary}
                                loading={savingNow}
                                onClick={() => form.submit()}
                        >
                            <Trans>Сохранить</Trans>
                        </Button>
                        <Button btnStyle={BtnStyleEnum.Secondary}
                                disabled={savingNow}
                                onClick={controls.closeModal}
                        >
                            <Trans>Закрыть</Trans>
                        </Button>
                    </>
                }
            }
            children={(controls) => <>
                <PageTitle tabIndex={0}><Trans>Задать цену урока</Trans></PageTitle>
                <PageSubtitleSmallMargin>
                    <Trans>Начало действия</Trans>
                </PageSubtitleSmallMargin>
                <Comment>
                    <Trans>Если уже существуют занятия, попадающие в период новой цены, для них будет выполнен
                        перерасчёт.</Trans>
                </Comment>
                <FormWrapper>
                    <Form form={form} layout={"inline"}
                          onFinish={(values: any) => onFinish(controls, values)}
                          onValuesChange={onFormValuesChange}
                    >
                        <FormItemsWrapper>
                            <StartFromGroup>
                                <FormItem
                                    name={FormFieldNamesEnum.START_MODE_SELECTOR}
                                    initialValue={StartFromEnum.NOW}
                                >
                                    <RadioGroup>
                                        <RadioWrapper>
                                            <RadioInput value={StartFromEnum.NOW}>
                                                <Trans>Изменить текущую стоимость прямо сейчас</Trans></RadioInput>
                                            <RadioInput value={StartFromEnum.FROM_DATE}>
                                                <Trans>Запланировать изменение дня новых занятий с определённой
                                                    даты</Trans></RadioInput>
                                        </RadioWrapper>
                                    </RadioGroup>
                                </FormItem>

                                {
                                    (startFromVisible) &&
                                    <StartFromInputWrapper>
                                        <FormItem
                                            name={FormFieldNamesEnum.START_FROM}
                                            initialValue={moment(new Date())}
                                            label={<Trans>Дата начала действия</Trans>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t`Необходимо указать дату начала действия расписания`,
                                                }
                                            ]}
                                        >
                                            <DatePicker/>
                                        </FormItem>
                                    </StartFromInputWrapper>
                                }
                            </StartFromGroup>

                            <div>
                                <PageSubtitleSmallMargin>
                                    <Trans>Новая стоимость</Trans>
                                </PageSubtitleSmallMargin>
                                <NewCostInputWrapper>
                                    <FormItem
                                        name={FormFieldNamesEnum.NEW_COST}
                                        initialValue={0}
                                        label={<Trans>Новая стоимость занятия</Trans>}
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    const numberValue = parseInt(value);

                                                    if (numberValue < 0) {
                                                        return Promise.reject(new Error(t`Стоимость должна быть больше нуля`));
                                                    }

                                                    if (numberValue > 100000) {
                                                        return Promise.reject(new Error(t`Максимальная сумма 100 000 рублей`));
                                                    }

                                                    return Promise.resolve()
                                                }
                                            })
                                        ]}
                                    >
                                        <MoneyInput
                                            placeholder={'0'}
                                            style={{textAlign: "right"}}/>
                                    </FormItem>
                                </NewCostInputWrapper>
                            </div>
                        </FormItemsWrapper>
                    </Form>
                </FormWrapper>
            </>}
        />
    });