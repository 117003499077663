import {
    DtoRequestWithPagination
} from "../../../../../components/HttpApiClient/ApiDto/Response/Common/DtoRequestWithPagination";
import {
    DtoSelfStudyTrackAdminSubscriberListItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackAdminSubscriberListItem";
import {
    DtoSelfStudyTrackAdminSummaryResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackAdminSummaryResponse";
import React from "react";

export interface IAdminViewContext {
    trackId: string;
    trackName: string;
    trackSlug: string;
    totalEpisodesCount: number;
    summaryResponse: DtoSelfStudyTrackAdminSummaryResponse;
    subscribersList: DtoRequestWithPagination<DtoSelfStudyTrackAdminSubscriberListItem>;
}

export const AdminViewContext  = React.createContext<IAdminViewContext>({
    trackId: '',
    trackName: '',
    trackSlug: '',
    totalEpisodesCount: 0,
    summaryResponse: new DtoSelfStudyTrackAdminSummaryResponse(),
    subscribersList: new DtoRequestWithPagination()
});

export const AdminViewContextProvider = AdminViewContext.Provider;