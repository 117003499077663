import React, {useCallback} from "react";
import {BlockAdditionalBullSymbol} from "../../../styles/global-elements";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {DtoStudentListItem} from "../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentListItem";
import {MoneyHelper} from "../../../../helpers/MoneyHelper";
import {PageSectionsEnum} from "../student/profile-page/PageSectionsEnum";
import {ContentList, ContentListProps} from "../../../components/Ui/Elements/ContentList";
import {ListItem} from "../../../components/Ui/Elements/ContentList/ListItem";

interface StudentItemsGridProps extends Omit<ContentListProps, 'children'> {
    items: DtoStudentListItem[];
}

export const StudentItemsList: React.FC<StudentItemsGridProps> = (
    {
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange,
        items
    }
) => {
    const linkToStudent = useCallback((studentId: number): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_STUDENT_PROFILE,
            [
                {
                    key: 'studentId',
                    value: studentId.toString(10)
                },
                {
                    key: 'sectionId',
                    value: PageSectionsEnum.GENERAL
                }
            ]
        );
    }, []);

    const detailsString = useCallback((balance, phone, email) => {
        const strings = [];

        strings.push(MoneyHelper.formatMoney(parseFloat(balance)));

        if (phone) {
            strings.push('+' + phone);
        }

        if (email) {
            strings.push(email);
        }

        return strings.map((string, index) => {
            if (index === 0) {
                return string;
            }

            return <span key={index}><BlockAdditionalBullSymbol/>{string}</span>
        });
    }, []);

    return <ContentList
        currentPageNum={currentPageNum}
        itemsPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={onPageChange}
    >
        {
            items.map((item) => {
                return <ListItem
                    key={`student${item.id}`}
                    link={linkToStudent(item.id)}
                    firstLine={item.longName}
                    secondLine={
                        detailsString(item.balance, item.smsPhone, item.email)
                    }
                />
            })
        }
    </ContentList>
}
