import {WorkerPayloadType} from "../WorkerPayloadType";
import {
    FileUploadProcessState,
    UploadProcessDetails,
    UploadQueueActionTypes,
    UploadQueueState
} from "../../store/uploadQueue/type";
import {call, ChannelTakeEffect, put, select, take, takeEvery} from "redux-saga/effects";
import * as UploadQueueActionCreators from "../../store/uploadQueue/actions";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {IHttpClient} from "../../components/HttpClient/IHttpClient";
import {END, EventChannel, eventChannel} from "redux-saga";
import {queueStateSelector} from "../../store/uploadQueue/selector";

function createUploadProcess(url: string, file: File | Blob, httpClient: IHttpClient) {
    return eventChannel<any>(emit => {
        const uploadProcessControls = httpClient.uploadFile<null>(url, file, {
            onProgress: (event) => {
                emit(Math.round((event.loaded * 100) / event.total));
            }
        });

        uploadProcessControls.processPromise
            .then(data => {
                emit(END);
            })
            .catch(error => {
                emit(new Error(error));
            })

        return () => {
            uploadProcessControls.abortProcess();
        }
    });
}

/**
 * Сага запускает процесс отправки файла.
 * Не обращение к API с получением fileId,
 * а просто отправку методом post на известный url.
 */
export function* watchUploadNewFile() {
    yield takeEvery<WorkerPayloadType<UploadProcessDetails>>(
        UploadQueueActionTypes.UPLOAD_NEW_FILE,
        uploadNewFile
    );
}

function* uploadNewFile(data: WorkerPayloadType<UploadProcessDetails>) {
    yield put(UploadQueueActionCreators.setNewUploadProcess(data.payload));

    const httpClient = container.resolve<IHttpClient>(DiTokens.HTTP_CLIENT);

    const uploadProcessChannel = (yield call(
        createUploadProcess,
        data.payload.url,
        data.payload.file,
        httpClient
    )) as EventChannel<any>

    let processFailed = false;
    let processCancelled = false;

    try {
        while (true) {
            const payload = (yield take(uploadProcessChannel)) as ChannelTakeEffect<any>;

            yield put(UploadQueueActionCreators.setProcessState({
                fileId: data.payload.fileId,
                completedInPercent: (payload as unknown as number),
                state: FileUploadProcessState.IN_PROCESS,
            }));

            // Проверим - не нужно ли остановить загрузку
            const queueState = (yield select(queueStateSelector)) as UploadQueueState;

            const currentQueueItemIndex = queueState.indexByFileId[data.payload.fileId];

            if (currentQueueItemIndex === undefined) {
                // Почему-то индекса нет. Остановим загрузку
                processCancelled = true;
                uploadProcessChannel.close();
            } else {
                const currentStateProcess = queueState.process[currentQueueItemIndex];

                if (!currentStateProcess) {
                    // Почему-то данных нет. Остановим загрузку
                    processCancelled = true;
                    uploadProcessChannel.close();
                } else {
                    if (currentStateProcess.needCancel) {
                        processCancelled = true;
                        uploadProcessChannel.close();
                    }
                }
            }
        }
    } catch (e) {
        console.error(e);

        yield put(UploadQueueActionCreators.setProcessState({
            fileId: data.payload.fileId,
            completedInPercent: 0,
            state: FileUploadProcessState.FAILED
        }));

        uploadProcessChannel.close();

        processFailed = true;
    } finally {
        if (!processFailed) {
            if (processCancelled) {
                yield put(UploadQueueActionCreators.setProcessState({
                    fileId: data.payload.fileId,
                    completedInPercent: 0,
                    state: FileUploadProcessState.CANCELLED
                }));
            } else {
                yield put(UploadQueueActionCreators.setProcessState({
                    fileId: data.payload.fileId,
                    completedInPercent: 100,
                    state: FileUploadProcessState.SUCCESS
                }));
            }
        }
    }
}