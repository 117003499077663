import React, {forwardRef, useImperativeHandle, useMemo} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import styled from "styled-components";
import {ElementData} from "../../../SlidePlayerEditorCommonParts/components/VideoYoutube/Common";
import {YouTubePlayer} from "../../../SlidePlayerEditorCommonParts/components/VideoYoutube/YouTubePlayer";

const VideoYoutubeElementWrapper = styled.figure`
  margin: 0;
  position: relative;
`;

interface VideoYoutubeProps extends IElementProps<ElementData> {
}

interface VideoYoutubeRefMethods extends IElementRefMethods {
}

export const VideoYoutube = forwardRef<VideoYoutubeRefMethods, VideoYoutubeProps>(
    (props, ref) => {

        const {elementData} = props;

        const videoData = useMemo<ElementData>(() => {
            return elementData.data;
        }, [elementData]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <VideoYoutubeElementWrapper>
            <YouTubePlayer videoId={videoData.videoId} height={videoData.height}/>
        </VideoYoutubeElementWrapper>;
    }
);

VideoYoutube.displayName = 'VideoYoutube';