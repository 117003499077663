import React, {useCallback, useEffect, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {PageLoadStatus} from "../common/Enums";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {ApplicationState} from "../../../../../store";
import {LocaleEnum} from "../../../../../store/i18n/type";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {useParams} from "react-router-dom";
import {SlidePlayer} from "../../../../components/SlidePlayer";
import {EditorData} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {EditorDataDtoMapper} from "../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import styled from "styled-components";
import {DtoKbSection} from "../../../../../components/HttpApiClient/ApiDto/Response/KnowledgeBase/DtoKbSection";
import {RoutesList} from "../../../../RoutesList";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {PageTitle} from "../../../../styles/global-elements";
import {PageBackLink} from "../../../../components/Ui/Elements/PageBackLink";
import {PlayerContextProvider} from "../../../../components/SlidePlayer/PlayerContext";

const SlidePlayerWrapper = styled.div`
  max-width: 680px;
`;

export const Page: React.FC = () => {
    const {pageId} = useParams();

    const [contentLoadState, setContentLoadState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [pageSection, setPageSection] = useState<DtoKbSection | null>(null);
    const [pageData, setPageData] = useState<EditorData | null>(null);
    const [pageTitle, setPageTitle] = useState<string | null>(null);

    const sessionToken = useSelector(sessionTokenSelector);

    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;


    const loadContent = useCallback(() => {
        if ((!sessionToken) || (!pageId)) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setContentLoadState(PageLoadStatus.LOADING);

        const sectionDetailsPromise = httpApiClient.kbGetPageSectionDetails(sessionToken, currentLocale, pageId)
            .then((data) => {
                setPageSection(data.data);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.KNOWLEDGE_BASE_API, "Error loading data: ", err);
            });

        const pagesPromise = httpApiClient.kbGetPageWBody(sessionToken, currentLocale, pageId)
            .then((data) => {
                setPageData(
                    EditorDataDtoMapper.dtoToEditorData(data.data.body)
                );
                setPageTitle(data.data.title);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.KNOWLEDGE_BASE_API, "Error loading data: ", err);
            });

        Promise.all([sectionDetailsPromise, pagesPromise])
            .then(() => {
                setContentLoadState(PageLoadStatus.SUCCESS);
            })
            .catch(() => {
                setContentLoadState(PageLoadStatus.ERROR);
            });
    }, [currentLocale, pageId, sessionToken]);

    useEffect(() => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT: {
                loadContent();

                break;
            }
            // case PageLoadStatus.SUCCESS: {
            //     if ((slidePlayerRef.current) && (pageData)) {
            //         slidePlayerRef.current.setSlideData(pageData, "kb-page-slide");
            //     }
            // }
        }
    }, [contentLoadState, loadContent, pageData]);

    const linkToSection = useCallback((sectionId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_KNOWLEDGE_BASE_SECTION,
            [
                {
                    key: 'sectionId',
                    value: sectionId
                }
            ]
        );
    }, []);

    const subtitle = () => {
        if (!pageSection) {
            return;
        }

        return <PageBackLink text={pageSection.name} link={linkToSection(pageSection.id)}/>;
    }

    const content = () => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT:
            case PageLoadStatus.LOADING: {
                return <div>
                    <DefaultLoader/>
                </div>;
            }
            case PageLoadStatus.SUCCESS: {
                return <div>
                    {subtitle()}
                    {(pageTitle) && <PageTitle>
                        <Trans>{pageTitle}</Trans>
                    </PageTitle>}
                    <SlidePlayerWrapper>
                        {
                            (pageData)
                            && <PlayerContextProvider value={{
                                lessonId: "none",
                                playerId: "slide-preview",
                                slides: null,
                                slidesContent: {},
                                setSlidesContent: () => {
                                },
                                selectedSlideId: 'kb-page-slide',
                                setSelectedSlideId: () => {
                                },
                                selectedSlide: null,
                                selectedSlideIndex: null,
                                setInteractivityConfig: null
                            }}>
                                <SlidePlayer playerId={"kb-page"}
                                             slideId={"kb-page-slide"}
                                             slideContent={pageData}
                                             slideItemsParams={{}}
                                />
                            </PlayerContextProvider>
                        }
                    </SlidePlayerWrapper>
                </div>;
            }
            case PageLoadStatus.ERROR: {
                return <ErrorLoadingContent
                    title={t`Не удалось загрузить информацию`}
                    retryBtnClick={() => loadContent()}
                />;
            }
        }
    }

    return content();
}
