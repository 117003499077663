import styled from "styled-components";
import React from "react";
import {Pagination} from "../../Pagination";

const ResultListStyle = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 20px;

    @media (${({theme}) => theme.media.small}) {
        margin-bottom: 30px;
    }
`;

interface ResultListProps {
    children: React.ReactNode;
    currentPageNum: number;
    totalItemsCount: number;
    itemsPerPage: number;
    onPageChange: (newPageNum: number) => void;
}

export const ResultList: React.FC<ResultListProps> = (
    {
        children,
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange
    }
) => {
    return <div>
        <ResultListStyle>{children}</ResultListStyle>
        <Pagination defaultCurrent={currentPageNum}
                    total={totalItemsCount}
                    hideOnSinglePage={true}
                    onChange={(page, pageSize) => {
                        onPageChange(page);
                    }}
                    defaultPageSize={itemsPerPage}/>
    </div>
}