import React from "react";
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {ApplicationState} from "../../../../../store";
import {withRouter} from "../../../../components/WithRouterHoc";
import {connect} from "react-redux";
import {TmOrganizationAndDisciplineSection} from "../../../../components/TeachingMaterialsCatalog/organization-and-discipline";

interface TeachingMaterialsCatalogPageProps {
    apiToken?: string | null;
}

interface TeachingMaterialsCatalogPageState {
}

class TeachingMaterialsCatalogPage extends React.Component<TeachingMaterialsCatalogPageProps, TeachingMaterialsCatalogPageState> {
    render() {
        if (!this.props.apiToken) {
            return <div></div>;
        }

        return <div>
            <PageTitle><Trans>Учебные материалы</Trans></PageTitle>
            <TmOrganizationAndDisciplineSection apiToken={this.props.apiToken}/>
        </div>
    }
}

const mapStateToProps = ({user}: ApplicationState) => ({
    apiToken: user.sessionToken,
});

export default withRouter(connect(mapStateToProps)(TeachingMaterialsCatalogPage));