import React, {useRef} from "react";
import styled from "styled-components";
import {Popup as ReactjsPopup} from "reactjs-popup";
import {PopupActions} from "reactjs-popup/dist/types";
import {BlockWithBorder} from "../../../styles/global-elements";
import {ElementsPalette} from "../elements-palette";
import {PaletteGroupEnum} from "../elements-palette/PaletteGroupEnum";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";

const StyledPopup = styled(ReactjsPopup)`
    max-width: 350px;
`;

const Wrapper = styled(BlockWithBorder)`
    padding: 10px;
    max-height: 400px;
    overflow: auto;
    width: 300px;
`;

interface PalettePopupProps {
    trigger: JSX.Element;
    onSelectItem: (itemType: ElementTypeEnum) => void;
}

export const PalettePopup: React.FC<PalettePopupProps> = (
    {
        trigger,
        onSelectItem
    }
) => {
    const ref = useRef<PopupActions>(null);

    const onSelect = (itemType: ElementTypeEnum) => {
        ref.current?.close();

        onSelectItem(itemType);
    }

    return <StyledPopup
        ref={ref}
        trigger={trigger}
        position={"top right"}
        arrow={false}
    >
        <Wrapper className={'palette-popup'}>
            <ElementsPalette
                onElementSelect={onSelect}
                disabledPalettes={[
                    PaletteGroupEnum.EXERCISES,
                    PaletteGroupEnum.FOR_TEACHER,
                    PaletteGroupEnum.OTHER
                ]}
            />
        </Wrapper>
    </StyledPopup>
};