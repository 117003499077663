import {
    AudioRoomConnectionState,
    ParticipantItem,
    VideoRoomConnectionState
} from "../../views/components/LessonConference/Types";
import {LessonConferenceStatusEnum} from "../../views/components/LessonConference/contexts/CommonContext";

export enum VcsStateActionTypes {
    SET_STATUS = '@@vcsState/SET_STATUS',
    SET_PARTICIPANTS = '@@vcsState/SET_PARTICIPANTS',
    SET_VIDEO_ROOM_STATE = '@@vcsState/SET_VIDEO_ROOM_STATE',
    SET_AUDIO_ROOM_STATE = '@@vcsState/SET_AUDIO_ROOM_STATE',
    RESET_STATE = '@@vcsState/RESET_STATE'
}

export type VcsStateType = {
    status: LessonConferenceStatusEnum;
    participants: ParticipantItem[];
    audioRoomState: AudioRoomConnectionState;
    videoRoomState: VideoRoomConnectionState;
}
