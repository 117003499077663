import {IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested,} from 'class-validator';
import {DtoStudentLessonWorkSlideData} from './DtoStudentLessonWorkSlideData';
import {DtoResponseWithPagination} from "../DtoResponseWithPagination";
import {Type} from "class-transformer";

class DtoStudentLessonWorkDataItemSlidesValidate extends DtoResponseWithPagination<DtoStudentLessonWorkSlideData> {
  @IsArray()
  @ValidateNested({each: true})
  @Type(() => DtoStudentLessonWorkSlideData)
  list: Array<DtoStudentLessonWorkSlideData>;
}

export class DtoStudentLessonWorkDataItem {
  @IsNotEmpty()
  @IsString()
  userId: string;

  @IsNotEmpty()
  @IsString()
  userName: string;

  @IsOptional()
  @IsString()
  userAvatarFileId: string | null;

  @IsNotEmpty()
  @IsNumber()
  studentStApiId: number;

  @IsNumber()
  totalAward: number;

  @IsNumber()
  totalMissedAward: number;

  @IsOptional()
  @IsString()
  valuesByActualParams: string | null;

  @ValidateNested()
  @Type(() => DtoStudentLessonWorkDataItemSlidesValidate)
  slides: DtoStudentLessonWorkDataItemSlidesValidate;
}
