import {IBaseApiDto} from "../../IBaseApiDto";
import {IsNotEmpty, IsString} from "class-validator";

/**
 * DTO параметров запроса на завершение онлайн урока учителем.
 */
export class DtoStopOnlineLessonRequest implements IBaseApiDto {
    @IsNotEmpty()
    @IsString()
    lessonId: string;
}
