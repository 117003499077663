import styled from "styled-components";
import {CustomComponentProps} from "../RichTextEditor";
import {ElementProps} from "../rich-text-editor/Element";
import React, {CSSProperties, useMemo} from "react";
import {RegularLi} from "./General";
import {ReactEditor} from "slate-react";
import {Transforms} from "slate";
import {ICheckboxListItem} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxListItem";
import {CheckIcon} from "../../../../Ui/Elements/CheckIcon";

const CheckedIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: -40px;
  height: 40px;
  width: 40px;
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const CheckboxListItem: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        element,
        liCustomComponent,
        children,
        editor
    } = props;

    const elementData: ICheckboxListItem = element as ICheckboxListItem;

    const LiComponentMemo = useMemo<React.FC<CustomComponentProps>>(() => {
        if (liCustomComponent) {
            return liCustomComponent;
        }

        return RegularLi;
    }, [liCustomComponent]);

    const toggleItemChecked = () => {
        const path = ReactEditor.findPath(editor, element);

        const newProperties: Partial<ICheckboxListItem> = {
            checked: !elementData.checked
        }

        Transforms.setNodes(editor, newProperties, {at: path});
    };

    return <LiComponentMemo style={{...style, position: "relative"}} {...attributes}>
        <CheckedIcon contentEditable={false} onClick={toggleItemChecked}>
            <CheckIcon checked={elementData.checked}/>
        </CheckedIcon>
        {children}
    </LiComponentMemo>;
}