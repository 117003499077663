import {
    IsNotEmpty, IsString, Length, MaxLength, IsOptional, IsNumber, Max
} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO урока секции
 */
export class DtoTmLesson {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    name: string;

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    descriptionForStudent: string | null;

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    descriptionForTeacher: string | null;

    @IsNotEmpty()
    @IsNumber()
    @Max(32767)
    orderPosition: number;

    @IsNumber()
    slidesCount: number;

    @IsOptional()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    coverFileId: string | null;
}
