import {put, select, takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {currentUserIdSelector} from "../../store/user/selector";
import {ICustomStorage} from "../../components/CustomStorage/ICustomStorage";
import {TargetsEnum} from "../../components/CustomStorage/TargetsEnum";
import {sessionTokenSelector} from "../../store/app/selector";
import * as AppActions from "../../store/app/actions";

export function* watchLogoutInAnotherWindow() {
    yield takeEvery(
        AppActionTypes.LOGOUT_IN_ANOTHER_WINDOW,
        logoutInAnotherWindow
    );
}

function* logoutInAnotherWindow(data: WorkerPayloadType<string>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);

    const anotherWindowUserId = data.payload;

    const currentUserId = (yield select(currentUserIdSelector)) as string | null;
    const currentSessionToken = (yield select(sessionTokenSelector)) as string | null;

    if ((currentUserId === null) || (currentSessionToken === null)) {
        return;
    }

    if (
        (currentUserId !== anotherWindowUserId)
        && (customStorage.getCurrentTarget() !== TargetsEnum.LOCAL_STORAGE)
    ) {
        // Не выводим из системы только если текущее окно не использует localStorage и если там другой пользователь.
        logger.info(LoggerSectionsEnum.APPLICATION, 'Logout message from window with another user. Ignore.');

        return;
    }

    logger.info(LoggerSectionsEnum.APPLICATION, 'Logout with same user id in another window. Reload.');

    if (customStorage.getCurrentTarget() !== TargetsEnum.LOCAL_STORAGE) {
        // Текущее окно уже не работает с localStorage. Очищаем хранилище и перезагружаемся.
        customStorage.clear();

        // Перезапускаем приложение
        yield put(AppActions.rebootApp());

        return;
    }

    // Текущее окно работает с localStorage.
    // Надеемся, что окно в котором пользователь выполнял logout уже очистило хранилище
    // и никто ничего туда ещё не успел записать. Просто перезапускаем приложение.

    // Перезапускаем приложение
    yield put(AppActions.rebootApp());
}
