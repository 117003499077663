import * as React from "react";
import {useCallback, useMemo, useRef} from "react";
import {PopupActions as ReactjsPopupActions, PopupProps as ReactjsPopupProps} from "reactjs-popup/dist/types";
import {ReactComponent as Check16Svg} from "../../Ui/Svg/Check16.svg";

import styled from "styled-components";
import {StyledPopup, StyledPopupItem, StyledPopupList} from "./Popup";
import {cloneDeep} from "lodash";

const StyledMultiselectPopupItem = styled(StyledPopupItem)`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ItemIconWrapper = styled.div`
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemTextWrapper = styled.div`
`;

export interface PopupMultiselectItem {
    key: string;
    text: string | JSX.Element;
}

type PopupProps = Omit<ReactjsPopupProps, 'children'> & React.RefAttributes<ReactjsPopupActions> & {
    selectedKeys: string[];
    items: PopupMultiselectItem[];
    onChange: (selectedKeys: string[]) => void;
}

export const PopupMultiselect = ({selectedKeys, items, onChange, ...props}: PopupProps) => {
    const popup = useRef(null);

    const onItemClick = useCallback((itemKey: string) => {
        const selectedKeysArr = cloneDeep(selectedKeys);
        const indexInSelectedKeys = selectedKeysArr.indexOf(itemKey);

        if (indexInSelectedKeys === -1) {
            selectedKeysArr.push(itemKey);
            onChange(selectedKeysArr);

            return;
        }

        selectedKeysArr.splice(indexInSelectedKeys, 1);
        onChange(selectedKeysArr);
    }, [onChange, selectedKeys]);

    const menuItems = useMemo(() => {
        const listItems = () => items.map((item) => {
            return <StyledMultiselectPopupItem key={item.key} onClick={() => onItemClick(item.key)}>
                <ItemIconWrapper>
                    {
                        (selectedKeys.indexOf(item.key) > -1) ? <Check16Svg/> : null
                    }
                </ItemIconWrapper>
                <ItemTextWrapper>
                    {item.text}
                </ItemTextWrapper>
            </StyledMultiselectPopupItem>;
        });

        return <StyledPopupList>
            {listItems()}
        </StyledPopupList>
    }, [items, selectedKeys, onItemClick]);

    return (
        <StyledPopup {...props} ref={popup}>
            {menuItems}
        </StyledPopup>
    )
}
