import styled from 'styled-components';
import {Link as ParentRouterLink} from "react-router-dom";

export const Link = styled.a`
  color: ${({theme}) => theme.colors.textSecondary};
  //text-decoration: underline;
  //font-size: 14px;
  
  &:focus, &:hover { 
    color: ${({theme}) => theme.colors.textSecondary};
    //text-decoration: underline;
  }
`;

export const RouterLink = styled(ParentRouterLink)`
  color: ${({theme}) => theme.colors.textSecondary};
  //text-decoration: underline;
  //font-size: 14px;
  
  &:focus, &:hover { 
    color: ${({theme}) => theme.colors.textSecondary};
    //text-decoration: underline;
  }
`;

// type LinkProps = {
//     href: string;
//     title: string;
// }
//
// export const Link: React.FunctionComponent<LinkProps> = (props: LinkProps) => {
//     return <StyledLink href={props.href}>{props.title}</StyledLink>
// }
