import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes, LessonRoomMember, MembersListLoadingStateEnum} from "../../store/lessonRoom/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {DtoResponseWithPagination} from "../../components/WsApiClient/ApiDto/Response/DtoResponseWithPagination";
import {DtoRoomParticipant} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoRoomParticipant";
import {currentRoomId} from "../../store/lessonRoom/selector";
import {setItemsToMembersList, setRoomMembersListLoadingState} from "../../store/lessonRoom/actions";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";

/**
 * Сага отвечает за запуск запроса списка участников комнаты
 */
export function* watchHandleRoomMembersListResult() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<DtoResponseWithPagination<DtoRoomParticipant>>, string>>>(
        LessonRoomActionTypes.HANDLE_ROOM_MEMBERS_LIST_RESULT,
        handleRoomMembersListResult
    );
}

function* handleRoomMembersListResult(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<DtoResponseWithPagination<DtoRoomParticipant>>, string>>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.LESSON_ROOM, "Get members request completed");

    if (data.payload.response.status !== WsResponseStatusEnum.OK) {
        yield put(setRoomMembersListLoadingState(MembersListLoadingStateEnum.ERROR));

        logger.error(
            LoggerSectionsEnum.LESSON_ROOM,
            `Error on load members list for room ${data.payload.responseContext}: Response code is ${data.payload.response.status}.`
        );

        return;
    }

    const roomId = data.payload.responseContext as string;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== roomId) {
        logger.info(
            LoggerSectionsEnum.LESSON_ROOM,
            `Received members list for room ${roomId} but current room is ` + (activeRoomId ?? 'null')
        );

        return;
    }

    const items: LessonRoomMember[] = [];

    data.payload.response.result.list.forEach((dtoItem) => {
        items.push({
            userId: dtoItem.userId,
            userName: dtoItem.userName,
            userAvatarFileId: dtoItem.userAvatarFileId,
            userType: dtoItem.userType,
            screenOrientation: dtoItem.screenOrientation,
            detailsForTeacher:
                (dtoItem.detailsForTeacher)
                    ? {
                        stApiUserId: dtoItem.detailsForTeacher.stApiUserId,
                        openedSlideId: dtoItem.detailsForTeacher.openedSlideId
                    }
                    : null,
            pinnedUser: (dtoItem.conferenceParams) ? dtoItem.conferenceParams.pinnedUser : null,
            inFullScreen: (dtoItem.conferenceParams) ? dtoItem.conferenceParams.inFullScreen : false
        });
    });

    yield put(setItemsToMembersList(items));
    yield put(setRoomMembersListLoadingState(MembersListLoadingStateEnum.SUCCESS));
}
