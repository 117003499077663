import React, {
    CSSProperties,
    forwardRef,
    useContext,
    useImperativeHandle,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../contexts/CommonContext";
import styled from "styled-components";
import {AudioRoomContext, IAudioRoomContext} from "../contexts/AudioRoomContext";
import {ConferenceModeEnum, ConnectionStateEnum} from "../Types";
import {StartingAllConnections} from "./layouts/StartAllConnections";
import {ConnectionError} from "./layouts/ConnectionError";
import {AudioRoomFatalError} from "./layouts/AudioRoomFatalError";
import {InitDevicesError} from "./layouts/InitDevicesError";
import {IVideoRoomContext, VideoRoomContext} from "../contexts/VideoRoomContext";
import {VideoGallery} from "./layouts/VideoGallery";
import {VideoGalleryParticipantProfile} from "./layouts/VideoGallery/Types";
import {DeviceOrientatonTypeEnum} from "../../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";
import {useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../store/user/selector";
import {container} from "tsyringe";
import {IDeviceOrientation} from "../../../../components/DeviceOrientation/IDeviceOrientation";
import {DiTokens} from "../../../../di-factory/DiTokens";
import useResizeObserver from "@react-hook/resize-observer";
import {FloatVideoPlaceholder} from "./layouts/FloatVideoPlaceholder";

const MainZoneWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

interface MainZoneProps {
    className?: string;
}

export const MainZone = forwardRef<HTMLDivElement, MainZoneProps>((props, forwardedRef) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => wrapperRef.current as HTMLDivElement);

    const commonContext = useContext<ICommonContext>(CommonContext);
    const audioRoomContext = useContext<IAudioRoomContext>(AudioRoomContext);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);
    const currentUserId = useSelector(currentUserIdSelector);
    const deviceOrientationService = useRef(container.resolve<IDeviceOrientation>(DiTokens.DEVICE_ORIENTATION));
    const [fixedHeight, setFixedHeight] = useState<number | null>(null);
    const currentHeight = useRef<number>(0);

    useResizeObserver(wrapperRef, (entry) => {
        if (commonContext.currentMode === ConferenceModeEnum.NORMAL) {
            currentHeight.current = entry.contentRect.height;
        }
    });

    const videoGalleryParticipantProfiles = useMemo<VideoGalleryParticipantProfile[]>(() => {
        return commonContext.roomWsMembers.map(item => {
            const memberInVcsList = commonContext.conferenceParticipants.find(vcsItem => vcsItem.id === item.userId);

            let orientation = DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY;

            if (item.userId !== currentUserId) {
                orientation = item.screenOrientation ?? DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY;
            } else {
                orientation = deviceOrientationService.current.getCurrentState();
            }

            return {
                id: item.userId,
                avatarFileId: item.userAvatarFileId,
                micMuted: (memberInVcsList) ? memberInVcsList.audioMuted : false,
                name: item.userName,
                screenOrientation: orientation
            }
        })
    }, [commonContext.conferenceParticipants, commonContext.roomWsMembers, currentUserId]);

    const content = useMemo(() => {
        switch (commonContext.lessonConferenceState) {
            case LessonConferenceStatusEnum.INIT_DEVICES: {
                return <StartingAllConnections/>;
            }
            case LessonConferenceStatusEnum.INIT_DEVICES_ERROR: {
                return <InitDevicesError/>;
            }
            case LessonConferenceStatusEnum.CHECK_CONNECTION:
            case LessonConferenceStatusEnum.STARTED: {
                if (!(
                    (audioRoomContext.audioRoomConnectionState.connection.status === ConnectionStateEnum.CONNECTED)
                    && (videoRoomContext.videoRoomConnectionState.publisherConnection.status === ConnectionStateEnum.CONNECTED)
                    && (videoRoomContext.videoRoomConnectionState.subscriberConnection.status === ConnectionStateEnum.CONNECTED)
                )) {
                    return <StartingAllConnections/>;
                }

                break;
            }
            case LessonConferenceStatusEnum.CONNECTION_ERROR:
            case LessonConferenceStatusEnum.CONNECTION_ERROR_WAIT_NETWORK: {
                return <ConnectionError/>
            }
        }

        if (audioRoomContext.audioRoomConnectionState.connection.status === ConnectionStateEnum.CONNECTION_ERROR) {
            return <AudioRoomFatalError/>;
        }

        const galleryComponent = <VideoGallery participantProfiles={videoGalleryParticipantProfiles}/>;

        if (commonContext.currentMode === ConferenceModeEnum.FLOAT_ON_PAGE) {
            return <>
                <FloatVideoPlaceholder/>
                {galleryComponent}
            </>;
        }

        return galleryComponent;
    }, [audioRoomContext.audioRoomConnectionState.connection.status, commonContext.currentMode, commonContext.lessonConferenceState, videoGalleryParticipantProfiles, videoRoomContext.videoRoomConnectionState.publisherConnection.status, videoRoomContext.videoRoomConnectionState.subscriberConnection.status]);

    useLayoutEffect(() => {
        if (commonContext.currentMode === ConferenceModeEnum.FLOAT_ON_PAGE) {
            if (fixedHeight === null) {
                if (wrapperRef.current !== null) {
                    setFixedHeight(currentHeight.current);
                }
            }
        } else {
            if (fixedHeight !== null) {
                setFixedHeight(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.currentMode]);

    const mainZoneStyle = useMemo<CSSProperties>((): CSSProperties => {
        if (fixedHeight === null) {
            return {};
        }

        return {
            height: `${fixedHeight}px`
        }
    }, [fixedHeight]);

    return <MainZoneWrapper ref={wrapperRef} className={props.className} style={mainZoneStyle}>
        {content}
    </MainZoneWrapper>
});
