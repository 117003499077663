import {FieldValidationErrorsList} from "./FieldValidationErrorsList";

/**
 * Класс ошибки валидации полей модели.
 */
export class FieldValidationException {
  private readonly _fieldValidationErrorsList: FieldValidationErrorsList;

  constructor(fieldValidationErrorsList: FieldValidationErrorsList) {
    this._fieldValidationErrorsList = fieldValidationErrorsList;
  }

  get fieldValidationErrorsList(): FieldValidationErrorsList {
    return this._fieldValidationErrorsList;
  }
}
