import {SchoolTypeEnum} from "../../../../components/StonlineApiClient/Enums/SchoolTypeEnum";

export class InitialCustomizerRequestData {

    userName: string;
    schoolType: SchoolTypeEnum;
    schoolName: string;
    timezoneName: string | null;
    timezoneUtcDiff: string | null;
    timezoneLocalTime: string | null;
    actualParamsCount: number;
    actualParam1Name: string;
    actualParam2Name: string | null;
    actualParam3Name: string | null;
    actualParam4Name: string | null;
    actualParam5Name: string | null;

    constructor() {
        this.userName = '';
        this.schoolType = SchoolTypeEnum.PERSONAL_TUTOR;
        this.schoolName = '';
        this.timezoneName = null;
        this.timezoneUtcDiff = null;
        this.timezoneLocalTime = null;
        this.actualParamsCount = 1;
        this.actualParam1Name = '';
        this.actualParam2Name = null;
        this.actualParam3Name = null;
        this.actualParam4Name = null;
        this.actualParam5Name = null;
    }
}