import React, {useCallback, useContext, useEffect, useRef} from "react";
import {RoomCommonActionsContextProvider} from "./RoomCommonActionsContext";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {container} from "tsyringe";
import {IDeviceMediaStreamFetcher} from "../../../../../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {PresetIdsEnum} from "../../../../../components/DeviceMediaStreamFetcher/PresetIdsEnum";
import {IMediaDeviceService} from "../../../../../services/media-device/IMediaDeviceService";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ConferenceModeEnum, ParticipantItem, StreamStatusEnum, studentItemInitialValue} from "../../Types";
import {cloneDeep} from "lodash";
import {IVideoRoomContext, VideoRoomContext} from "../../contexts/VideoRoomContext";

interface RoomCommonActionsProviderProps {
    setLessonConferenceState: React.Dispatch<React.SetStateAction<LessonConferenceStatusEnum>>;
    setConferenceParticipants: React.Dispatch<React.SetStateAction<ParticipantItem[]>>;
    setCurrentConferenceMode: React.Dispatch<React.SetStateAction<ConferenceModeEnum>>;
}

export const RoomCommonActionsProvider: React.FC<RoomCommonActionsProviderProps> = (
    {
        children,
        setLessonConferenceState,
        setConferenceParticipants,
        setCurrentConferenceMode
    }
) => {
    const logger = useRef(container.resolve<ILogger>(DiTokens.LOGGER));
    const commonContext = useContext<ICommonContext>(CommonContext);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);

    const initDevices = useCallback(async () => {
        const mediaDeviceService = container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE);
        const deviceFetcher = container.resolve<IDeviceMediaStreamFetcher>(DiTokens.DEVICE_MEDIA_STREAM_FETCHER);

        const devicesForCall = await mediaDeviceService.getDevicesForCall();

        if ((devicesForCall.audioInput === null) || (devicesForCall.videoInput === null)) {
            setLessonConferenceState(LessonConferenceStatusEnum.INIT_DEVICES_ERROR);

            return;
        }

        try {
            await deviceFetcher.getMicrophoneStream(devicesForCall.audioInput.id);
        } catch (e) {
            logger.current.error(
                LoggerSectionsEnum.LESSON_ROOM,
                'Error on fetch audio stream: '
            )

            setLessonConferenceState(LessonConferenceStatusEnum.INIT_DEVICES_ERROR);

            return;
        }

        try {
            await deviceFetcher.getCameraStream(PresetIdsEnum.CAMERA_MEDIUM, devicesForCall.videoInput.id);
        } catch (e) {
            logger.current.error(
                LoggerSectionsEnum.LESSON_ROOM,
                'Error on fetch video stream: '
            )

            setLessonConferenceState(LessonConferenceStatusEnum.INIT_DEVICES_ERROR);

            return;
        }

        if (videoRoomContext.videoRoomConnectionState.cameraMuted) {
            deviceFetcher.stopCameraStream(devicesForCall.videoInput.id);
        }

        setLessonConferenceState(LessonConferenceStatusEnum.CHECK_CONNECTION);
    }, [setLessonConferenceState, videoRoomContext.videoRoomConnectionState.cameraMuted]);

    const setParticipantsListItemUpdate = useCallback((studentItems: Partial<ParticipantItem>[]) => {
        setConferenceParticipants((state) => {
            const studentsList = cloneDeep(state);

            studentItems.forEach(studentItem => {
                if (studentItem.id === undefined) {
                    throw new Error('Student is is undefined');
                }

                let index = studentsList.findIndex((item) => item.id === studentItem.id);

                if (index > -1) {
                    studentsList[index] = {
                        ...studentsList[index],
                        ...studentItem
                    }

                    logger.current.info(
                        LoggerSectionsEnum.LESSON_ROOM,
                        `Participant update: `, studentItem, studentsList[index], studentsList
                    );
                } else {
                    studentsList.push({
                        ...studentItemInitialValue,
                        ...studentItem
                    });

                    logger.current.info(
                        LoggerSectionsEnum.LESSON_ROOM,
                        `Participant update (add): `, studentItem, studentsList
                    );
                }
            });

            return studentsList;
        });
    }, [setConferenceParticipants]);

    const setUnavailableAudioForAllParticipants = useCallback(() => {
        logger.current.info(
            LoggerSectionsEnum.LESSON_ROOM,
            `setUnavailableAudioForAllParticipants`
        );

        setConferenceParticipants((state) => {
            return state.map((item) => {
                const newItem = cloneDeep(item);

                // newItem.audioMuted = false;
                newItem.audioStatus = StreamStatusEnum.NOT_AVAILABLE;

                newItem.talkingNow = false;

                return newItem;
            });
        });
    }, [setConferenceParticipants]);

    const setUnavailableVideoForAllParticipants = useCallback(() => {
        logger.current.info(
            LoggerSectionsEnum.LESSON_ROOM,
            `setUnavailableVideoForAllParticipants`
        );

        setConferenceParticipants((state) => {
            return state.map((item) => {
                const newItem = cloneDeep(item);

                newItem.screenVideo = StreamStatusEnum.NOT_AVAILABLE;
                newItem.screenAudio = StreamStatusEnum.NOT_AVAILABLE;
                newItem.videoStatus = StreamStatusEnum.NOT_AVAILABLE;

                return newItem;
            });
        });
    }, [setConferenceParticipants]);

    useEffect(() => {
        if (commonContext.lessonConferenceState === LessonConferenceStatusEnum.INIT_DEVICES) {
            initDevices();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.lessonConferenceState]);

    return <RoomCommonActionsContextProvider value={{
        setLessonConferenceState: setLessonConferenceState,
        setParticipantsListItemUpdate: setParticipantsListItemUpdate,
        setUnavailableAudioForAllParticipants: setUnavailableAudioForAllParticipants,
        setUnavailableVideoForAllParticipants: setUnavailableVideoForAllParticipants,
        setCurrentConferenceMode: setCurrentConferenceMode
    }}>
        {children}
    </RoomCommonActionsContextProvider>
}
