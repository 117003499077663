import {VideoGalleryParticipantProfile} from "../Types";
import React, {useContext, useMemo, useState} from "react";
import {CommonContext, ICommonContext} from "../../../../contexts/CommonContext";
import {StreamStatusEnum} from "../../../../Types";
import {CameraVideoItem} from "./CameraVideo";
import {AvatarStatusLayer} from "./Common/AvatarStatusLayer";
import styled from "styled-components";
import {ScreenVideoItem} from "./ScreenVideo";
import {ControlsLayer} from "./Common/ControlsLayer";
import {v4 as uuidv4} from "uuid";

enum VideoItemModeEnum {
    NOTHING,
    CAMERA_VIDEO,
    SCREEN_VIDEO
}

const Wrapper = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Placeholder = styled.video`
    width: 100%;
    height: 100%;
`;

interface VideoItemProps {
    profile: VideoGalleryParticipantProfile;
    ignoreScreenVideo: boolean; // Если нужно не обращать внимание на наличие потока демонстрации экрана
}

export const VideoItem: React.FC<VideoItemProps> = ({profile, ignoreScreenVideo}) => {
    const [randomItemId,] = useState<string>(() => uuidv4());
    const commonRoomContext = useContext<ICommonContext>(CommonContext);

    const currentUserInVideoRoom = useMemo(() => {
        return commonRoomContext.conferenceParticipants.find(item => item.id === profile.id) ?? null;
    }, [commonRoomContext.conferenceParticipants, profile.id]);

    const videoStatus = useMemo<StreamStatusEnum>(() => {
        if (!currentUserInVideoRoom) {
            return StreamStatusEnum.NOT_AVAILABLE;
        }

        return currentUserInVideoRoom.videoStatus;
    }, [currentUserInVideoRoom]);

    const screenVideoStatus = useMemo<StreamStatusEnum>(() => {
        if (!currentUserInVideoRoom) {
            return StreamStatusEnum.NOT_AVAILABLE;
        }

        return currentUserInVideoRoom.screenVideo;
    }, [currentUserInVideoRoom]);

    const videoItemMode = useMemo<VideoItemModeEnum>(() => {
        if (!ignoreScreenVideo) {
            if (screenVideoStatus !== StreamStatusEnum.NOT_AVAILABLE) {
                return VideoItemModeEnum.SCREEN_VIDEO;
            }
        }

        if (videoStatus !== StreamStatusEnum.NOT_AVAILABLE) {
            return VideoItemModeEnum.CAMERA_VIDEO;
        }

        return VideoItemModeEnum.NOTHING;
    }, [ignoreScreenVideo, screenVideoStatus, videoStatus]);

    const randomItemIdWithType = useMemo<string>(() => {
        switch (videoItemMode) {
            case VideoItemModeEnum.SCREEN_VIDEO: {
                return `${randomItemId}-screen`;
            }
            case VideoItemModeEnum.CAMERA_VIDEO: {
                return `${randomItemId}-camera`;
            }
            default: {
                return `${randomItemId}-unknown`;
            }
        }
    }, [randomItemId, videoItemMode]);

    switch (videoItemMode) {
        case VideoItemModeEnum.SCREEN_VIDEO: {
            return <Wrapper className={'video-item'} data-item-id={randomItemIdWithType}>
                <ScreenVideoItem profile={profile} randomItemId={randomItemIdWithType}/>
            </Wrapper>;
        }

        case VideoItemModeEnum.CAMERA_VIDEO: {
            return <Wrapper className={'video-item'} data-item-id={randomItemIdWithType}>
                <CameraVideoItem profile={profile} randomItemId={randomItemIdWithType}/>
            </Wrapper>
        }

        default: {
            return <Wrapper className={'video-item'} data-item-id={randomItemIdWithType}>
                <Placeholder/>
                <AvatarStatusLayer avatarFileId={profile.avatarFileId} userName={profile.name} statusText={null}/>
                <ControlsLayer
                    profile={profile}
                    userConferenceParticipantItem={currentUserInVideoRoom}
                />
            </Wrapper>
        }
    }
}
