import {IsNotEmpty, Max, Min} from "class-validator";
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO обновления location клиента
 */
export class DtoUpdateClientLocation implements IBaseApiDto {
    @IsNotEmpty()
    @Min(1)
    @Max(300)
    location: string;
}
