import {IDateHelperService} from "./IDateHelperService";
import {Ii18nService} from "../i18n/Ii18nService";
import * as moment from 'moment';

export class DateHelperService implements IDateHelperService {
    i18nService: Ii18nService;

    constructor(i18nService: Ii18nService) {
        this.i18nService = i18nService;
    }

    /**
     * {@inheritDoc}
     */
    public nowUTC(): string {
        return moment.default().format();
    }

    /**
     * {@inheritDoc}
     */
    public secondsBetween(date1: Date, date2: Date): number {
        return Math.ceil((date2.getTime() - date1.getTime()) / 1000);
    }

    /**
     * {@inheritDoc}
     */
    public getFirstDayThreeMonthBefore(): Date {
        const currentDate = new Date();

        currentDate.setMonth(currentDate.getMonth() - 2);
        currentDate.setDate(1);

        this.setZeroTimeForDateObject(currentDate);

        return currentDate;
    }

    /**
     * {@inheritDoc}
     */
    public getFirstDayCurrentMonthDate(): Date {
        const currentDate = new Date();

        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    }

    /**
     * {@inheritDoc}
     */
    public formatAsTime(date: Date): string {
        let formatter = new Intl.DateTimeFormat(this.i18nService.locale(),
            {hour: 'numeric', minute: 'numeric'}
        );

        return formatter.format(date);
    }

    /**
     * {@inheritDoc}
     */
    public formatAsDate(date: Date): string {
        let formatter = new Intl.DateTimeFormat(this.i18nService.locale(),
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            }
        );

        return formatter.format(date);
    }

    formatAsDateWithWeek(date: Date): string {
        let formatter = new Intl.DateTimeFormat(this.i18nService.locale(),
            {
                weekday: "long",
                month: 'long',
                day: 'numeric'
            }
        );

        return formatter.format(date);
    }

    /**
     * @inheritDoc
     */
    public formatAsSQLDate(date: Date): string {
        return date.getFullYear()
            + '-' + this.setZeroLeading(date.getMonth() + 1)
            + '-' + this.setZeroLeading(date.getDate());
    }

    /**
     * @inheritDoc
     */
    public formatAsSQLDateTime(date: Date): string {
        return date.getFullYear()
            + '-' + this.setZeroLeading(date.getMonth() + 1)
            + '-' + this.setZeroLeading(date.getDate())
            + ' ' + this.setZeroLeading(date.getHours())
            + ':' + this.setZeroLeading(date.getMinutes())
            + ':' + this.setZeroLeading(date.getSeconds());
    }

    /**
     * @inheritDoc
     */
    public formatAsNominativeMonthName(date: Date): string {
        return "";
    }

    /**
     * {@inheritDoc}
     */
    public formatAsShortWeekDayName(date: Date): string {
        let formatter = new Intl.DateTimeFormat(this.i18nService.locale(),
            {weekday: 'short'}
        );

        return formatter.format(date);
    }

    /**
     * {@inheritDoc}
     */
    public formatAsLongWeekDayName(date: Date): string {
        let formatter = new Intl.DateTimeFormat(this.i18nService.locale(),
            {weekday: 'long'}
        );

        return formatter.format(date);
    }

    /**
     * {@inheritDoc}
     */
    public getDateStringRelativeToTheCurrent(date: Date, longWeekDayName?: boolean, withoutTime?: boolean) {
        let oneDayMillisecond = 1000 * 60 * 60 * 24;
        let now = new Date();

        let dateDayStart = new Date(date);
        dateDayStart.setHours(0, 0, 0, 0);
        let dateDayStartAsMs = dateDayStart.getTime();

        let nowDayStart = new Date(now);
        nowDayStart.setHours(0, 0, 0, 0);
        let nowDayStartAsMs = nowDayStart.getTime();

        let difference = nowDayStartAsMs - dateDayStartAsMs;

        if (difference === 0) {
            if (withoutTime === true) {
                return this.i18nService.t('Сегодня');
            }

            return this.formatAsTime(date);
        }

        if (withoutTime) {
            if (difference === oneDayMillisecond) {
                return this.i18nService.t('Вчера');
            }
        }

        if (difference < oneDayMillisecond * 3) {
            if (longWeekDayName === true) {
                return this.formatAsLongWeekDayName(date);
            } else {
                return this.formatAsShortWeekDayName(date);
            }
        }

        return this.formatAsDate(date);
    }

    /**
     * {@inheritDoc}
     */
    public formatDateObjectToIsoString(date: Date): string {
        return `${date.getUTCFullYear()}-`
            + `${this.setZeroLeading(date.getUTCMonth() + 1)}-`
            + `${this.setZeroLeading(date.getUTCDate())}T`
            + `${this.setZeroLeading(date.getUTCHours())}:`
            + `${this.setZeroLeading(date.getUTCMinutes())}:`
            + `${this.setZeroLeading(date.getUTCSeconds())}Z`;
    }

    /**
     * @inheritDoc
     */
    public removeSecondsFromTimeString(timeString: string): string {
        return timeString.slice(0, timeString.length - 3);
    }

    /**
     * @inheritDoc
     */
    public setZeroTimeForDateObject(date: Date): Date {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    /**
     * @inheritDoc
     */
    public getCurrentDateWithoutTime(): Date {
        const date = new Date();

        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        return date;
    }

    /**
     * @inheritDoc
     */
    public dateFromString(dateAsString: string, dateInUTC?: boolean): Date {
        // Проверка соответствия строки 2023-04-10 15:31:27
        const matchResult = dateAsString.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}\s{1}[0-9]{2}:[0-9]{2}:[0-9]{2}$/g);

        if (matchResult === null) {
            // Получили что-то другое
            return new Date(dateAsString);
        }

        // У нас строка вида "2023-04-10 15:31:27". Вставим T между частями, а если дата в UTC, то и Z в конце.
        return new Date(dateAsString.replace(' ', 'T')+(dateInUTC?'Z':''));
    }

    public utcDateFromString(dateAsString: string): Date {
        // У нас строка вида "2023-04-10 15:31:27". Вставим T между частями и в конец - Z.
        return this.dateFromString(dateAsString.replace(' ', 'T') + 'Z');
    }

    /**
     * @inheritDoc
     */
    public dateFromStringToTimeString(dateAsString: string): string {
        const dateObject = this.dateFromString(dateAsString);

        return this.formatAsTime(dateObject);
    }

    /**
     * @inheritdoc
     */
    public timeRangeFromStartAndDuration(startDateTime: Date, durationMinutes: number): string {
        const formatter = this.getFormatter(false, true);

        const timeFromString = formatter.format(startDateTime);

        const endDateTime = new Date(startDateTime.getTime());

        endDateTime.setMinutes(endDateTime.getMinutes() + durationMinutes);

        const timeToString = formatter.format(endDateTime);

        return `${timeFromString}–${timeToString}`
    }

    /**
     * @inheritDoc
     */
    public getNextDayOfTheWeek(dayNumber: number, excludeToday?: boolean): Date {
        if (dayNumber < 0) {
            throw new Error(`Incorrect dayNumber: ${dayNumber}`);
        }

        const refDate = new Date();

        refDate.setHours(0, 0, 0, 0);
        refDate.setDate(refDate.getDate() + +!!excludeToday +
            (dayNumber + 7 - refDate.getDay() - +!!excludeToday) % 7);
        return refDate;
    }

    /**
     * @inheritdoc
     */
    getCurrentDeviceTimezoneName(): string {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * @inheritdoc
     */
    getCityFromTimezoneName(timezoneName: string): string {
        try {
            const parts = timezoneName.split('/');

            return parts[parts.length - 1];
        } catch (e) {
            return timezoneName;
        }
    }

    /**
     * @inheritdoc
     */
    getFormatter(date: boolean, time: boolean, timeZoneName?: string, additionalOptions?: Partial<Intl.DateTimeFormatOptions>): Intl.DateTimeFormat {
        let options: Intl.DateTimeFormatOptions = {
            timeZone: timeZoneName
        };

        if (date) {
            options.year = 'numeric';
            options.month = 'numeric';
            options.day = 'numeric';
        }

        if (time) {
            options.hour = 'numeric';
            options.minute = 'numeric';
        }

        if (additionalOptions) {
            options = {
                ...options,
                ...additionalOptions
            }
        }

        return new Intl.DateTimeFormat(this.i18nService.locale(), options);
    }

    /**
     * Добавить 0 слева, если число меньше 10.
     *
     * @param num
     */
    protected setZeroLeading(num: number): string {
        if (num < 10) {
            return '0' + num.toString(10);
        }

        return num.toString(10);
    }
}
