import {ApplicationState} from "../index";
import {createSelector} from "reselect";
import {WsServerConstants} from "../../components/WsApiClient/WsApiClient";
import {QueueItem, QueueItemContext} from "./type";

export const handleCycleStatusSelector = ({slidesWorkDataSaveQueue}: ApplicationState) => slidesWorkDataSaveQueue.sendCycleState;
export const handleCycleEnabledSelector = ({slidesWorkDataSaveQueue}: ApplicationState) => slidesWorkDataSaveQueue.sendCycleEnabled;
export const queueStateSelector = ({slidesWorkDataSaveQueue}: ApplicationState) => slidesWorkDataSaveQueue;

export const lastQueueItemSelector = createSelector(
    [
        queueStateSelector
    ],
    (queueState) => {
        if (queueState.queue.length === 0) {
            return null;
        }

        return queueState.queue[queueState.queue.length - 1];
    }
)

export const itemsForSendSelector = createSelector(
    [
        queueStateSelector
    ],
    (queueState) => {
        if (queueState.queue.length === 0) {
            return [];
        }

        const result: QueueItem<QueueItemContext>[] = [];

        for (let index = 0; index < WsServerConstants.SLIDE_WORK_DATA_MAX_BATCH_SIZE; index++) {
            if (queueState.queue.length > index) {
                result.push(queueState.queue[index]);
            } else {
                break;
            }
        }

        return result;
    }
)