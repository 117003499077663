import {action} from "typesafe-actions";

import {I18nActionTypes, LocaleEnum, LocaleStateEnum} from "./type";

export const selectLocale = (localeName: LocaleEnum) => {
    return action(I18nActionTypes.SELECT_LOCALE, localeName)
};

export const setCurrentLocaleName = (localeName: LocaleEnum) => {
    return action(I18nActionTypes.SET_CURRENT_LOCALE_NAME, localeName)
};

export const setLocaleState = (newState: LocaleStateEnum) => {
    return action(I18nActionTypes.SET_LOCALE_STATE, newState)
};