import styled, {css} from "styled-components";
import {BlockWithBorderCss} from "../../../styles/global-elements";
import React from "react";
import {PageNavigationBarProps} from "../CommonTypes";
import classNames from "classnames";
import {RouterLink} from "../../Ui/Elements/Link";

const Wrapper = styled.div`
  ${BlockWithBorderCss};
  overflow: hidden;
  max-width: 344px;
  min-width: 344px;

  padding: 18px 0;
  margin: 0;
  display: block;
  position: sticky;
  overflow: unset;
  top: ${({theme}) => 100 + theme.size.headerHeight}px;
  height: fit-content;
`;

const ItemsListWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: block;
  max-height: 100%;
  margin-right: 6px;
`;

const ItemsList = styled.div`
  padding: 0;
  margin: 0;
`;

const ListItem = css`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 100%;

  font-size: 16px;
  background: ${({theme}) => theme.colors.backgroundPrimary};
  color: ${({theme}) => theme.colors.textPrimary};

  cursor: pointer;
  border-left: 4px solid rgba(0, 0, 0, 0);
  overflow: hidden;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &.selected {
    opacity: 1;
  }

  padding: 6px 32px;

  &.selected {
    border-left-color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const ListItemDiv = styled.div`
  ${ListItem};
`;

const ListItemLink = styled(RouterLink)`
  ${ListItem};
`;

export const RightNavigationArea: React.FC<PageNavigationBarProps> = ({selectedItemKey, items, onSelectItem}) => {
    return <Wrapper>
        <ItemsListWrapper>
            <ItemsList>
                {
                    items.map((item) => {
                        if (item.link) {
                            return <ListItemLink className={classNames(item.key === selectedItemKey && 'selected')}
                                                 key={item.key}
                                                 to={item.link}
                            >
                                {item.text}
                            </ListItemLink>;
                        }

                        return <ListItemDiv
                            key={item.key}
                            className={classNames(item.key === selectedItemKey && 'selected')}
                            onClick={() => onSelectItem(item.key)}
                        >
                            {item.text}
                        </ListItemDiv>
                    })
                }
            </ItemsList>
        </ItemsListWrapper>
    </Wrapper>
}
