import {IScreenSaverLock} from "./IScreenSaverLock";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class ScreenSaverLock implements IScreenSaverLock {
    protected logger: ILogger;

    /**
     * null или объект типа WakeLockSentinel (не поддерживается в IDE)
     * @protected
     */
    protected screenLockObject: Object | null;

    protected needScreenLock: boolean;

    protected documentOnVisibilityChangeBind;

    constructor(logger: ILogger) {
        this.logger = logger;
        this.needScreenLock = false;
        this.screenLockObject = null;
        this.documentOnVisibilityChangeBind = this.documentOnVisibilityChange.bind(this);
    }

    /**
     * @inheritDoc
     */
    lockIsActivated(): boolean {
        return this.needScreenLock;
    }

    /**
     * @inheritDoc
     */
    setLock(): void {
        if (!this.isScreenLockSupported()) {
            this.logger.info(
                LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                'ScreenSaver lock not supported by browser'
            );

            return;
        }

        if (this.screenLockObject !== null || this.needScreenLock) {
            this.logger.info(
                LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                'ScreenSaver lock already started'
            );

            return;
        }

        this.requestScreenLock()
            .then(() => {
                this.needScreenLock = true;

                document.addEventListener('visibilitychange', this.documentOnVisibilityChangeBind);

                this.logger.info(
                    LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                    'Screen lock activated'
                );
            })
            .catch((e) => {
                this.logger.info(
                    LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                    'ScreenSaver lock activate error',
                    e
                );
            });
    }

    /**
     * @inheritDoc
     */
    unsetLock(): void {
        if (!this.needScreenLock) {
            this.logger.info(
                LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                'Screen lock is not activated'
            );
        }

        this.needScreenLock = false;

        if (this.screenLockObject === null) {
            return;
        }

        try {
            // @ts-ignore
            this.screenLockObject.release();
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                'Error on release screen lock: ',
                e
            );
        }

        this.logger.info(
            LoggerSectionsEnum.SCREEN_SAVER_LOCK,
            'Screen lock deactivated'
        );
    }

    /**
     * Событие при снятии блокировки.
     *
     * @protected
     */
    protected lockOnRelease() {
        this.logger.info(
            LoggerSectionsEnum.SCREEN_SAVER_LOCK,
            'Lock released'
        );

        this.screenLockObject = null;
    }

    /**
     * Вызывается при изменении видимости окна для повторного запроса блокировки
     *
     * @protected
     */
    protected documentOnVisibilityChange() {
        if (this.needScreenLock && document.visibilityState === 'visible') {
            this.requestScreenLock()
                .then(() => {
                    this.logger.info(
                        LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                        'ScreenSaver lock reactivated'
                    );
                })
                .catch((e) => {
                    this.logger.info(
                        LoggerSectionsEnum.SCREEN_SAVER_LOCK,
                        'Error on reactivate ScreenSaver lock: ',
                        e
                    )
                })
        }
    }

    /**
     *
     * @protected
     */
    protected isScreenLockSupported(): boolean {
        return ('wakeLock' in navigator);
    }

    /**
     * Запросить блокировку заставки
     *
     * @protected
     */
    protected async requestScreenLock(): Promise<void> {
        // @ts-ignore
        this.screenLockObject = await (navigator.wakeLock.request('screen') as Promise<Object>);

        // @ts-ignore
        this.screenLockObject.addEventListener(
            'release',
            this.lockOnRelease.bind(this)
        );
    }
}
