import styled from "styled-components";
import {ReactComponent as SchoolDefaultAvatarSvg} from "../Ui/Svg/SchoolDefaultAvatar.svg";
import {Button} from "../Ui/Elements/Button";

export const SelectSchoolTitle = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 24px;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 40px;
    font-size: 40px;
    line-height: 48px;
  }
`;

export const SchoolsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  @media (${({theme}) => theme.media.medium}) {
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;

    margin-bottom: 40px;
    padding-bottom: 20px;
  }
`;

interface SchoolItemProps {
    active: boolean
}

export const SchoolItem = styled.li<SchoolItemProps>`
  padding: ${p => (p.active) ? "18px" : "20px"};
  border-style: solid;
  border-width: ${p => (p.active) ? "3px" : "1px"};
  border-color: ${p => (p.active) ? p.theme.colors.backgroundInverse : p.theme.colors.accentDivider};
  border-radius: 16px;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.backgroundInverse};
  }

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 24px;
    padding: ${p => (p.active) ? "20px" : "22px"};
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 0;
    margin-right: 24px;
    max-width: 372px;
    min-width: 372px;
    overflow: hidden;
    padding: ${p => (p.active) ? "30px" : "32px"};

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (${({theme}) => theme.media.large}) {
    max-width: 528px;
    min-width: 528px;
  }
`;

export const SchoolDefaultAvatar = styled(SchoolDefaultAvatarSvg)`
  width: 100%;
  height: 100%;
  opacity: 0.7;
`;

export const SchoolIcon = styled.div`
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  display: block;
  border-radius: 20px;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  overflow: hidden;

  @media (${({theme}) => theme.media.small}) {
    width: 60px;
    height: 60px;
    margin-bottom: 16px;
    border-radius: 30px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 24px;
  }
`;

export const SchoolName = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  @media (${({theme}) => theme.media.medium}) {
    line-height: 28px;
    margin-bottom: 16px;
  }
`;

export const SchoolSubName = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const SelectSchoolSubtitle = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 14px;
  padding-bottom: 0;
  color: ${({theme}) => theme.colors.textPrimary}
`;

export const AgreementsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const AgreementItem = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  cursor: pointer;
`;

export const AgreementIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AgreementNameWrapper = styled.div`
  padding-top: 11px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
`;

export const AgreementName = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textPrimary};
  margin-bottom: 6px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 12px;
    font-size: 18px;
    line-height: 18px;
  }
`;

export const AgreementDetails = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textSecondary};
  margin-bottom: 2px;
`;

export const SelectButton = styled(Button)`
  width: 100%;
  padding: 12px 40px;
`;