import React, {useContext, useMemo} from "react";
import {ReactComponent as Cloud} from "../../../../Ui/Svg/Cloud.svg";
import {ReactComponent as HeadsetIcon} from "../../../../Ui/Svg/Headset.svg";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../../contexts/CommonContext";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../../../DefaultLoader";

const Wrapper = styled.div`
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  color: ${({theme}) => theme.colors.textSecondary};
  
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
`;

const TextWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 15px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const StartingAllConnections: React.FC = () => {
    const commonContext = useContext<ICommonContext>(CommonContext);

    const icon = useMemo<JSX.Element>(() => {
        switch (commonContext.lessonConferenceState) {
            case LessonConferenceStatusEnum.CHECK_CONNECTION:
            {
                return <Cloud/>;
            }
            case LessonConferenceStatusEnum.INIT_DEVICES:
            case LessonConferenceStatusEnum.STARTED: {
                return <HeadsetIcon/>
            }
        }

        return <div/>
    }, [commonContext.lessonConferenceState]);

    const text = useMemo<JSX.Element>(() => {
        switch (commonContext.lessonConferenceState) {
            case LessonConferenceStatusEnum.CHECK_CONNECTION:
            {
                return <Trans>Выполняется проверка подключения...</Trans>;
            }
            case LessonConferenceStatusEnum.INIT_DEVICES: {
                return <Trans>Включение камеры и микрофона...</Trans>;
            }
            case LessonConferenceStatusEnum.STARTED: {
                return <Trans>Выполняется настройка звука и видео...</Trans>;
            }
        }

        return <div/>
    }, [commonContext.lessonConferenceState]);

    return <Wrapper>
        <IconWrapper>{icon}</IconWrapper>
        <TextWithLoader>
            <LoaderWrapper>
                <DefaultLoader simple={true}/>
            </LoaderWrapper>
            <TextWrapper>
                {text}
            </TextWrapper>
        </TextWithLoader>
    </Wrapper>
}
