/**
 * Перечисление ссылок на звуковые файлы
 */
import {SoundLinkListType} from "../components/SoundPlayer/SoundLinkListType";
import {SoundsEnum} from "../components/SoundPlayer/SoundsEnum";

const soundLinks: SoundLinkListType = {};

soundLinks[SoundsEnum.RIGHT] = '/sounds/right.wav';
soundLinks[SoundsEnum.ERROR] = '/sounds/error.wav';

export default soundLinks;