import * as React from "react";
import styled from 'styled-components';
import {Spin} from "../../../components/Ui/Elements/Spin";


const Wrapper = styled.div`

`;

const SpinnerWrapper = styled.div`
  margin-bottom: 32px;
  padding-top: 30px;
`;

export interface SpinnerAreaProps {
    tip?: string
}

export const SpinnerArea: React.FC<SpinnerAreaProps> = (props: SpinnerAreaProps) => {
    return (
        <Wrapper>
            <SpinnerWrapper>
                <Spin tip={props.tip}/>
            </SpinnerWrapper>
        </Wrapper>
    );
}