import {ReactComponent as SchoolbagIcon} from "../Svg/Schoolbag24.svg";
import {ReactComponent as StarIcon} from "../Svg/Star24.svg";
import React from "react";

export enum TypesEnum {
    ADDITIONAL_ICON,
    HOMEWORK_ICON
}

export interface SlideIndexForTeacherType {
    type: TypesEnum;
}

export const SlideIndexIconForTeacher: React.FC<SlideIndexForTeacherType> = (props) => {
    switch (props.type) {
        case TypesEnum.ADDITIONAL_ICON: {
            return <StarIcon/>;
        }
        case TypesEnum.HOMEWORK_ICON: {
            return <SchoolbagIcon/>;
        }
    }
}