import React from "react";
import {useDispatch} from "react-redux";
import {Trans} from "@lingui/macro";
import {rebootApp} from "../../../../store/app/actions";
import {VideoRoomErrorModalTypeEnum} from "../common/Lesson/VideoRoomErrorModal";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {LinkAsButton} from "../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {RoutesList} from "../../../RoutesList";

export interface VideoRoomErrorModalProps {
    errorType: VideoRoomErrorModalTypeEnum
}

export const ErrorMessage: React.FC<VideoRoomErrorModalProps> = (props) => {
    const dispatch = useDispatch();

    switch (props.errorType) {
        case VideoRoomErrorModalTypeEnum.NOT_FOUND_CAPTURE_DEVICES: {
            return <div>
                <NoticeBlock>
                    <>
                        <NoticeBlockTitle><Trans>Устройство не поддерживает звонки</Trans></NoticeBlockTitle>
                        <NoticeBlockText>
                            <Trans>На вашем устройстве нет камеры или микрофона, на нём не получится принять
                                участие в этом занятии</Trans>
                        </NoticeBlockText>
                        <br/>
                        <NoticeBlockText>
                            <LinkAsButton btnStyle={BtnStyleEnum.Primary} to={RoutesList.MAIN_PAGE}>
                                <Trans>Перейти на главную страницу</Trans>
                            </LinkAsButton>
                        </NoticeBlockText>
                    </>
                </NoticeBlock>
            </div>
        }

        case VideoRoomErrorModalTypeEnum.USER_DEVICE_ERROR: {
            return <div>
                <NoticeBlock>
                    <>
                        <NoticeBlockTitle><Trans>Не получается войти в комнату</Trans></NoticeBlockTitle>
                        <NoticeBlockText>
                            <Trans>Проверьте, не запущены ли на устройстве другие приложения, показывающие изображение с
                                камеры и нажмите «Повторить».</Trans>
                        </NoticeBlockText>
                        <br/>
                        <NoticeBlockText>
                            <Button btnStyle={BtnStyleEnum.Primary} onClick={() => dispatch(rebootApp())}>
                                <Trans>Повторить</Trans>
                            </Button>
                        </NoticeBlockText>
                    </>
                </NoticeBlock>
            </div>
        }

        default: {
            return <div>
                <NoticeBlock>
                    <>
                        <NoticeBlockTitle><Trans>Не получается войти в комнату</Trans></NoticeBlockTitle>
                        <NoticeBlockText>
                            <Trans>Проверьте соединение с интернетом и нажмите «Повторить».</Trans>
                        </NoticeBlockText>
                        <br/>
                        <NoticeBlockText>
                            <Button btnStyle={BtnStyleEnum.Primary} onClick={() => dispatch(rebootApp())}>
                                <Trans>Повторить</Trans>
                            </Button>
                        </NoticeBlockText>
                    </>
                </NoticeBlock>
            </div>
        }
    }
}
