import {IsNotEmpty, IsOptional, IsString, ValidateNested} from "class-validator";
import {DtoTmSlideContentItemInteractivityConfigAction} from "./DtoTmSlideContentItemInteractivityConfigAction";
import {Type} from "class-transformer";

export class DtoTmSlideContentItemInteractivityConfig {
    @IsNotEmpty()
    @IsString()
    actionRunMode: string; // {@see SlideItemInteractivityActionRunModeEnum}

    @IsOptional()
    @IsString()
    nextBtnLabel: string | null;

    @IsNotEmpty()
    @ValidateNested({each: true})
    @Type(() => DtoTmSlideContentItemInteractivityConfigAction)
    actions: DtoTmSlideContentItemInteractivityConfigAction[];
}