import {IsOptional, IsString, Length} from "class-validator";

/**
 * DTO собранного на текущую неделю расписания занятий группы
 */
export class DtoGroupCompiledSchedule {
    @IsOptional()
    @IsString()
    @Length(0, 8)
    weekDays?: string | null;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    mondayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    mondayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    mondayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    tuesdayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    tuesdayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    tuesdayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    wednesdayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    wednesdayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    wednesdayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    thursdayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    thursdayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    thursdayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    fridayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    fridayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    fridayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    saturdayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    saturdayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    saturdayBreakTime?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    sundayTimeStart?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    sundayTimeEnd?: string;

    @IsOptional()
    @IsString()
    @Length(0, 8)
    sundayBreakTime?: string;
}
