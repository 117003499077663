import {Radio as AntRadio} from 'antd';
import {RadioProps} from 'antd/lib/radio';
import styled from "styled-components";
import {RegularTextCss} from "../../../styles/global-elements";
import {forwardRef} from "react";


const RadioStyled = styled(AntRadio)`
  ${RegularTextCss};

  color: ${({theme}) => theme.colors.textPrimary};

  .ant-radio-inner {
    background-color: inherit;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
`;

export const RadioGroup = styled(AntRadio.Group)`
  label {
    ${RegularTextCss};
  }
`;

export const RadioInput = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
    return <RadioStyled ref={ref} {...props}/>
});

export const RadioGroupButton = styled(AntRadio.Group)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3px;
  border-radius: 10px;
  overflow: hidden;

  label {
    ${RegularTextCss};

    flex-grow: 1;
    background-color: ${({theme}) => theme.colors.backgroundSecondary} !important;
    border: none !important;
    color: ${({theme}) => theme.colors.textPrimary} !important;
    display: inline !important;
    height: auto;
    width: auto;
    text-align: center;

    &:before {
      content: none !important;
    }

    &:focus-within {
      box-shadow: none !important;
    }

    &.ant-radio-button-wrapper-checked {
      span:last-child {
        border-bottom-width: 3px;
        border-bottom-color: ${({theme}) => theme.colors.textPrimary};
        border-bottom-style: solid;
      }
    }
  }
`;

export const RadioButtonStyled = styled(AntRadio.Button)`
  border: none;
  background-color: #f7f4f2;
  color: black;
  padding: 10px;
`;

export const RadioButton = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
    return <RadioButtonStyled ref={ref} {...props}/>
});