import React from "react";
import {Trans} from "@lingui/macro";
import {RegularText} from "../../../../../styles/global-elements";

export const NewAgreementTeamSectionNotice: React.FC = () => {
    return <div>
        <RegularText>
            <Trans>Платформа поддерживает групповые звонки и вы можете проводить групповые онлайн занятия.</Trans>
        </RegularText>
    </div>
}