import {Reducer} from "redux";
import {LessonRoomsActionTypes, LessonRoomsState, OpenedLessonRoom} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<LessonRoomsState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case LessonRoomsActionTypes.ADD_OPENED_LESSON_ROOMS: {
            const dataList: OpenedLessonRoom[] = action.payload;

            dataList.forEach((data) => {
                // Проверим, что в объекте нет указанного элемента
                const findIndex = draft.openedLessonRooms.list.findIndex((item) => {
                    return (item.roomId === data.roomId);
                });

                if (findIndex < 0) {
                    draft.openedLessonRooms.list.push(data);
                } else {
                    draft.openedLessonRooms.list[findIndex] = data;
                }
            });

            draft.openedLessonRooms.list = [...draft.openedLessonRooms.list];

            break;
        }
        case LessonRoomsActionTypes.REMOVE_OPENED_LESSON_ROOM: {
            draft.openedLessonRooms.list = draft.openedLessonRooms.list.filter((item) => {
                return item.roomId !== action.payload;
            });

            break;
        }
    }
}, initialState);

export {reducer as lessonRoomsReducer}