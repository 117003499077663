import {put, select, takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {PingResultDto} from "../../components/WsApiClient/ApiDto/Response/Ping/PingResultDto";
import {setLastPingDate} from "../../store/app/actions";
import {ApplicationState} from "../../store";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";

/**
 * Сага отвечает за обработку результата ping запроса.
 */
export function* watchPingResult() {
    yield takeEvery(
        AppActionTypes.PROCESS_PING_RESULT,
        pingResult
    );
}

const getPingTimeUpdateSuspendedValue = (state: ApplicationState) => state.app.pingTimeUpdateSuspended;

function* pingResult(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<PingResultDto>, null>>) {
    if (data.payload.response.status !== WsResponseStatusEnum.OK) {
        return;
    }

    let updateDisabled = (yield select(getPingTimeUpdateSuspendedValue)) as boolean;

    if (!updateDisabled) {
        yield put(setLastPingDate(data.payload.response.result.currentDate));
    }
}
