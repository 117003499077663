import {IsNotEmpty, IsNumber, IsNumberString, IsString} from "class-validator";

/**
 * DTO элемента списка учеников
 */
export class DtoStudentListItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsString()
    longName: string;

    @IsString()
    smsPhone: string;

    @IsString()
    email: string;

    @IsNumberString()
    balance: boolean;
}
