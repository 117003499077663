import {IsNotEmpty, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {DtoTmSlideContent} from "../TmSlide/DtoTmSlideContent";

/**
 * DTO страницы секции базы знаний с контентом
 */
export class DtoKbPageWBody {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    title: string;

    body: DtoTmSlideContent | null;
}
