import styled from "styled-components";
import {SchoolSelectorModal} from "../../../../components/SchoolSelectorModal/SchoolSelectorModal";
import * as React from "react";
import {useSelector} from "react-redux";
import {selectedAgreement as selectedAgreementSelector} from "../../../../../store/user/selector";
import {ReactComponent as ArrowSvg} from "../../../../components/Ui/Svg/RightArrow.svg";

export const ProgramSelectorStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: 18px 24px;
  width: 100%;
  cursor: pointer;
  
  :hover {
    background-color: ${({theme}) => theme.colors.backgroundHover};
  }
`;

export const ProgramNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 5px;

  overflow: hidden;
`

export const ProgramChangeIconWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const ProgramTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${({theme}) => theme.colors.textPrimary};
  margin-bottom: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CourseTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ProgramSelectorProps {
    onClick: () => void
}

export const ProgramSelector: React.FC<ProgramSelectorProps> = (props) => {
    const selectedAgreement = useSelector(selectedAgreementSelector);

    return <SchoolSelectorModal
        onOpen={props.onClick}
        trigger={
            <ProgramSelectorStyled>
                <ProgramNameWrapper>
                    <ProgramTitle>{selectedAgreement?.trainingProgramName}</ProgramTitle>
                    <CourseTitle>{selectedAgreement?.courseName}</CourseTitle>
                </ProgramNameWrapper>
                <ProgramChangeIconWrapper>
                    <ArrowSvg/>
                </ProgramChangeIconWrapper>
            </ProgramSelectorStyled>
        }/>
}