import React, {forwardRef, useCallback, useContext} from "react";
import {Modal, ModalControlParams} from "../../../../../Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../../../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {PageTitle, RegularText} from "../../../../../../styles/global-elements";
import {ReactComponent as FullScreenVideoOffSvg} from "./images/TeacherMagicFullScreenVideoOff.svg";
import {ReactComponent as FullScreenVideoOnSvg} from "./images/TeacherMagicFullScreenVideoOn.svg";
import {ReactComponent as PinMyVideoSvg} from "./images/TeacherMagicPinMyVideo.svg";
import {ReactComponent as PinGallerySvg} from "./images/TeacherMagicPinGallery.svg";
import styled, {css} from "styled-components";
import {PopupActions} from "reactjs-popup/dist/types";
import {currentUserIdSelector} from "../../../../../../../store/user/selector";
import {useSelector} from "react-redux";
import {container} from "tsyringe";
import {IWsApiClient} from "../../../../../../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {ApiMethodEnum} from "../../../../../../../components/WsApiClient/ApiMethodEnum";
import {CommonContext, ICommonContext} from "../../../../contexts/CommonContext";

const ItemsList = styled.ul`
    margin: 0;
    width: 100%;
    list-style: none;
    justify-content: space-around;
    padding: 0;
    display: flex;  
    flex-direction: row;
    flex-wrap: wrap;
`;

const Item = styled.li`
    opacity: 0.7;
    display: flex;
    flex-direction: column;
    color: ${({theme}) => theme.colors.textPrimary};
    cursor: pointer;
    align-items: center;
    width: 50%;
    padding: 5px 10px;
    
    &:hover {
        opacity: 1;
    }
    
    @media (${({theme}) => theme.media.small}) {
      width: 33%;
    }
    
    @media (${({theme}) => theme.media.medium}) {
      width: 25%;
    }
`;

const ItemSvg = css`
    width: 100px;
    height: 100px;
    
    margin-bottom: 10px;
`;

const PinMyVideoSvgStyled = styled(PinMyVideoSvg)`${ItemSvg};`;
const PinGallerySvgStyled = styled(PinGallerySvg)`${ItemSvg};`;
const FullScreenVideoOnSvgStyled = styled(FullScreenVideoOnSvg)`${ItemSvg};`;
const FullScreenVideoOffSvgStyled = styled(FullScreenVideoOffSvg)`${ItemSvg};`;

const ItemText = styled(RegularText)`
    text-align: center;
`;

interface TeacherMagicModalProps {
    trigger: JSX.Element;
}

export const TeacherMagicModal = forwardRef<PopupActions, TeacherMagicModalProps>(({trigger}, ref) => {
    const commonContext = useContext<ICommonContext>(CommonContext);

    const currentUserId = useSelector(currentUserIdSelector);

    const selectMyVideoForAll = useCallback((controls: ModalControlParams) => {
        const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        wsApiClient.query(
            ApiMethodEnum.LESSON_ROOM_SELECT_ROOM_MEMBER_VIDEO_FOR_ALL,
            {
                lessonId: commonContext.lessonId,
                userId: currentUserId
            },
            undefined,
            false
        );

        controls.closeModal();
    }, [commonContext.lessonId, currentUserId]);

    const selectVideoGalleryModelForAll = useCallback((controls: ModalControlParams) => {
        const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        wsApiClient.query(
            ApiMethodEnum.LESSON_ROOM_SELECT_ROOM_MEMBER_VIDEO_FOR_ALL,
            {
                lessonId: commonContext.lessonId,
                userId: null
            },
            undefined,
            false
        );

        controls.closeModal();
    }, [commonContext.lessonId]);

    const setMembersVideoToFullScreenForAll = useCallback((controls: ModalControlParams, value: boolean) => {
        const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        wsApiClient.query(
            ApiMethodEnum.LESSON_ROOM_SET_MEMBERS_VIDEO_TO_FULL_SCREEN_FOR_ALL,
            {
                lessonId: commonContext.lessonId,
                value: value
            },
            undefined,
            false
        );

        controls.closeModal();
    }, [commonContext.lessonId]);

    return <Modal closeAllowed={true}
                  innerRef={ref}
                  trigger={trigger}
                  footer={
                      (controls) => {
                          return <div>
                              <Button btnStyle={BtnStyleEnum.Primary} onClick={controls.closeModal}>
                                  <Trans>Закрыть</Trans>
                              </Button>
                          </div>;
                      }
                  }
                  children={
                      (modalControls) => {
                          return <>
                              <PageTitle>
                                  <Trans>Управление видеокомнатой</Trans>
                              </PageTitle>
                              <ItemsList>
                                  <Item onClick={() => selectMyVideoForAll(modalControls)}>
                                      <PinMyVideoSvgStyled/>
                                      <ItemText>
                                          <Trans>Закрепить моё видео для всех</Trans>
                                      </ItemText>
                                  </Item>
                                  <Item onClick={() => selectVideoGalleryModelForAll(modalControls)}>
                                      <PinGallerySvgStyled/>
                                      <ItemText>
                                          <Trans>Перевести всех в режим галереи</Trans>
                                      </ItemText>
                                  </Item>
                                  <Item onClick={() => setMembersVideoToFullScreenForAll(modalControls, true)}>
                                      <FullScreenVideoOnSvgStyled/>
                                      <ItemText>
                                          <Trans>Развернуть всем видео на весь экран</Trans>
                                      </ItemText>
                                  </Item>
                                  <Item onClick={() => setMembersVideoToFullScreenForAll(modalControls, false)}>
                                      <FullScreenVideoOffSvgStyled/>
                                      <ItemText>
                                          <Trans>Выйти из режима полного экрана</Trans>
                                      </ItemText>
                                  </Item>
                              </ItemsList>
                          </>
                      }
                  }
    />
});
