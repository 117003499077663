import {IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested} from "class-validator";
import {Type} from "class-transformer";
import {DtoGroupData} from "./DtoGroupData";
import {DtoCostRegisterData} from "./DtoCostRegisterData";
import {DtoAnotherParticipantItem} from "./DtoAnotherParticipantItem";

export class DtoAgreementItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsNumber()
    groupId: number;

    @IsNotEmpty()
    @IsString()
    startDate: string;

    @IsNumber()
    annulled: number;

    @IsNumber()
    payMethod: number;

    @IsNumber()
    lessonsCountInCostPeriod: number;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoGroupData)
    groupData: DtoGroupData | null;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoCostRegisterData)
    costRegisterData: DtoCostRegisterData | null;

    @ValidateNested({each: true})
    @Type(() => DtoAnotherParticipantItem)
    anotherGroupParticipants: DtoAnotherParticipantItem[];
}