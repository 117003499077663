import React, {useMemo} from "react";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {ISubscriptionContext, SubscriptionContextProvider} from "./SubscriptionContext";
import {Header} from "./Header";
import styled from "styled-components";
import {Episode} from "./Episode";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../../store";
import {ITheme} from "../../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../../services/hooks/useMediaQuery";
import {RoutesHelper} from "../../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../../RoutesList";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (${({theme}) => theme.media.small}) {
        gap: 24px;
    }

    @media (${({theme}) => theme.media.large}) {
        flex-direction: row;
    }
`;

const HeaderWrapper = styled.div`
`;

const EpisodesWrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column-reverse;
    width: 100%;
    
    @media (${({theme}) => theme.media.large}) {
        justify-content: space-between;
    }
`;

interface SelfStudySubscriptionOverviewProps {
    item: DtoSelfStudyTrackSubscriptionUserOverviewItem;
}

export const SelfStudySubscriptionOverview: React.FC<SelfStudySubscriptionOverviewProps> = ({item}) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const backgroundColor = useMemo(() => {
        return `${item.accentColor}1a`;
    }, [item.accentColor]);
    
    const linkToLastEpisode = useMemo<string>(() => {
        return RoutesHelper.replaceParams(
            RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE,
            [
                {key: 'episodeId', value: item.lastEpisodeId}
            ]
        )
    }, [item.lastEpisodeId]);

    const contextValue = useMemo<ISubscriptionContext>(() => ({
        overviewItem: item,
        backgroundColor: backgroundColor,
        linkToLastEpisode: linkToLastEpisode
    }), [backgroundColor, item, linkToLastEpisode]);
    
    const filteredEpisodes = useMemo(() => {
        // Показываем только один скрытый элемент, а не все
        let foundSecretItems = 0;

        return item.episodes.filter((item) => {
            if (item.id === null) {
                foundSecretItems++;
            }
            
            return foundSecretItems < 2 || isLarge;
        });
    }, [isLarge, item.episodes]);

    return <SubscriptionContextProvider value={contextValue}>
        <Wrapper>
            <HeaderWrapper>
                <Header/>
            </HeaderWrapper>

            <EpisodesWrapper>
                {
                    filteredEpisodes.map((episodeItem, index) => {
                        return <Episode
                            key={index}
                            item={episodeItem}
                            prevItem={index > 0 ? item.episodes[index - 1] : null}
                        />
                    })
                }
            </EpisodesWrapper>
        </Wrapper>
    </SubscriptionContextProvider>
}