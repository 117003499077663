import React from "react";

export type StudentItem = {
    name: string;
    shortName: string;
    stId: number;
    avatarFileId: string | null;
    myLessonsUserId: string | null;
}

export interface ILessonPageContext {
    lessonId: string | null;
    onlineRoomIsConnected: boolean;
    onlineRoomHasVideo: boolean;

    selectedRoomMemberId: string | null;
    setSelectedRoomMemberId: (memberId: string | null) => void;
}

export const LessonPageContext = React.createContext<ILessonPageContext>({
    lessonId: null,
    onlineRoomIsConnected: false,
    onlineRoomHasVideo: false,

    setSelectedRoomMemberId: (memberId: string | null) => {
    },
    selectedRoomMemberId: null,
});

export const LessonPageContextProvider = LessonPageContext.Provider;
