import React, {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useState} from 'react';
import styled from "styled-components";
import {IRichTextElementRefMethods} from "../../components/RichTextReader/IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../components/RichTextReader/IRichTextElementProps";
import {IRadioList} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioList";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import classNames from "classnames";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {ExerciseRadioContextProvider, IExerciseRadioContext, ShowMode, VariantState} from "./ExerciseRadioContext";
import {IRadioListItem} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioListItem";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";

const RadioList = styled.ul`
  list-style: none;
  padding-left: 15px;
`;

export const ExerciseRadioList = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IRadioList>>(
    (props, ref) => {
        const {element, className, ...otherProps} = props;

        const [showErrorAnimationInVariantId, setShowErrorAnimationInVariantId]
            = useState<string | null>(null);

        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);
        const {showCorrectAnswers} = slideItemWorkContext;

        const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[element.id];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
        }, [element.id, slideItemWorkContext]);

        const allVariants = useMemo<IRadioListItem[]>(() => {
            return element.children.map((item) => {
                return item as unknown as IRadioListItem;
            });
        }, [element])

        const correctVariantId = useMemo<string | null>(() => {
            for (let index = 0; index < allVariants.length; index++) {
                if (allVariants[index].checked) {
                    return allVariants[index].id;
                }
            }

            return null;
        }, [allVariants]);

        const variantState = useMemo<VariantState>(() => {
            const result: VariantState = {};

            allVariants.forEach((variant) => {
                if (showCorrectAnswers === true) {
                    // Если нужно показать все верные ответы
                    if (variant.checked) {
                        result[variant.id] = ShowMode.CORRECT;
                    } else {
                        result[variant.id] = ShowMode.MUTED;
                    }

                    return;
                }

                let mode: ShowMode = (
                    (currentExerciseHistoryData)
                    && (currentExerciseHistoryData.value !== "")
                    && (currentExerciseHistoryData.value === correctVariantId)
                )
                    ? ShowMode.MUTED
                    : ShowMode.INPUT;

                if (currentExerciseHistoryData) {
                    if (currentExerciseHistoryData.inputHistory.includes(variant.id)) {
                        mode = (variant.id === correctVariantId) ? ShowMode.CORRECT : ShowMode.ERROR;
                    }
                }

                result[variant.id] = mode;
            });

            return result;
        }, [allVariants, correctVariantId, currentExerciseHistoryData, showCorrectAnswers]);

        const variantOnClick = useCallback((variantId: string) => {
            if (
                (slideItemWorkContext.itemId === null)
                || (slideItemWorkContext.slideId === null)
                || (playerContext.selectedSlide === null)
                || (slideItemWorkContext.readOnly === true)
            ) {
                return;
            }

            const soundPlayer = container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);

            let award = 0;
            let missedAward = 0;
            let historyItemsCount = 0;

            // Рассчитываем значения упущенной выгоды и награды
            if (currentExerciseHistoryData) {
                historyItemsCount = currentExerciseHistoryData.inputHistory.length;
                award = currentExerciseHistoryData.award;
                missedAward = currentExerciseHistoryData.missedAward;
            }

            const answerIsCorrect = variantId === correctVariantId;

            if (answerIsCorrect) {
                // Если сейчас был дан верный ответ
                soundPlayer.playSound(SoundsEnum.RIGHT);

                // Присуждаем все доступные баллы
                award = Math.ceil(
                    MAX_AWARD_SCORE - missedAward
                );
            } else {
                // Если сейчас был дан ошибочный ответ
                soundPlayer.playSound(SoundsEnum.ERROR);
                setShowErrorAnimationInVariantId(variantId);

                // Сколько ещё доступно баллов
                const availableScore = MAX_AWARD_SCORE - missedAward;

                // Посчитаем, сколько ещё ошибочных ответов есть, вычитая из общего количество
                // количество уже имеющихся в истории и один ответ, который точно верный
                const availableWrongAnswerCount = allVariants.length - historyItemsCount - 1;

                if (availableWrongAnswerCount > 0) {
                    missedAward += Math.ceil(availableScore / availableWrongAnswerCount);
                }
            }

            if (missedAward > MAX_AWARD_SCORE) {
                missedAward = MAX_AWARD_SCORE;
            }

            if (award > MAX_AWARD_SCORE) {
                award = MAX_AWARD_SCORE;
            }

            slideItemWorkContext.saveExerciseAnswer(
                element.id,
                variantId,
                award,
                missedAward,
                playerContext.selectedSlide.exercisesCount,
                answerIsCorrect
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [slideItemWorkContext, currentExerciseHistoryData]);

        const providerData = useMemo<IExerciseRadioContext>(
            () => {
                return {
                    variantOnClick: variantOnClick,
                    variantState: variantState,
                    showErrorAnimationInVariantId: showErrorAnimationInVariantId,
                    setShowErrorAnimationInVariantId: setShowErrorAnimationInVariantId
                }
            },
            [showErrorAnimationInVariantId, variantOnClick, variantState]
        );

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <RadioList className={classNames(className)}>
            <ExerciseRadioContextProvider value={providerData}>
                <RichTextReader elements={element.children} {...otherProps}/>
            </ExerciseRadioContextProvider>
        </RadioList>
    }
);

ExerciseRadioList.displayName = 'ExerciseRadioList';