import {call, put, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ActionParamExerciseUserAnswer, SlideWorkDataActionTypes} from "../../store/slidesWorkData/type";
import {prepareOrFindExerciseObject} from "./common/prepareOrFindExerciseObject";
import {setExerciseAnswer} from "../../store/slidesWorkData/actions";
import {DataTypeEnum, pushDataToSaveQueue} from "./common/pushDataToSaveQueue";

export function* watchHandleUserEventAddAnswer() {
    yield takeEvery<WorkerPayloadType<ActionParamExerciseUserAnswer>>(
        SlideWorkDataActionTypes.HANDLE_USER_EVENT_ADD_ANSWER,
        handleUserEventAddAnswer
    );
}

function* handleUserEventAddAnswer(data: WorkerPayloadType<ActionParamExerciseUserAnswer>) {
    const [slideIndex, slideItemIndex, exerciseIndex]
        = (
        yield call(
            prepareOrFindExerciseObject,
            {
                playerId: data.payload.playerId,
                slideId: data.payload.slideId,
                slideItemId: data.payload.slideItemId,
                exerciseId: data.payload.exerciseId,
                value: null
            }
        )
    ) as [number, number, number];

    yield put(setExerciseAnswer({
        slideIndex: slideIndex,
        slideItemIndex: slideItemIndex,
        exerciseIndex: exerciseIndex,
        value: data.payload.value,
        slideExercisesCount: data.payload.slideExercisesCount,
        award: data.payload.award,
        missedAward: data.payload.missedAward,
        answerIsCorrect: data.payload.answerIsCorrect
    }));

    yield call(pushDataToSaveQueue, DataTypeEnum.ANSWER, data.payload);

    return;
}
