import React, {useCallback, useImperativeHandle, useRef} from "react";
import {FooterMode, Modal, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import {PopupActions as ReactjsPopupActions, PopupActions} from "reactjs-popup/dist/types";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {StudentSlideViewDetailsType} from "../Common/StudentSlideViewDetailsType";
import StudentLessonResultsBySlides from "../StudentResultsBySlides";

interface StudentLessonWorkSlideDataModalProps {
    openStudentSlideViewProc: (data: StudentSlideViewDetailsType) => void;
}

export const StudentLessonWorkSlideDataModal = React.forwardRef<PopupActions, StudentLessonWorkSlideDataModalProps>((props, ref) => {
    const modalRef = useRef<ReactjsPopupActions>(null);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        close: () => {
            modalRef.current?.close();
        },
        open: () => {
            modalRef.current?.open();
        },
        toggle: () => {
            modalRef.current?.toggle();
        }
    }));

    const openStudentSlideViewProc = useCallback((data: StudentSlideViewDetailsType) => {
        modalRef.current?.close();

        props.openStudentSlideViewProc(data);
    }, [props]);

    return <Modal innerRef={modalRef}
                  closeAllowed={true}
                  footer={(controls: ModalControlParams) => {
                      return <Button btnStyle={BtnStyleEnum.Secondary}
                                     onClick={controls.closeModal}>
                          <Trans>Закрыть</Trans>
                      </Button>
                  }}
                  footerMode={FooterMode.DEFAULT}
                  children={() =>
                      <StudentLessonResultsBySlides
                          openStudentSlideViewProc={openStudentSlideViewProc}/>
                  }/>
});
