export type FieldValidationErrorsList = { [p: string]: Array<string> };

/**
 * Класс - описание ответа WebSocket сервера - описания проблемы,
 * возникшей при обработке запроса.
 */
export class ErrorsResponseData {
    public technicalText: string;

    private _fieldValidationErrorsList: FieldValidationErrorsList | null;

    constructor(
        technicalText: string,
        validationSummary: string | null = null,
        validationErrorDetails: FieldValidationErrorsList | null = null,
    ) {
        this.technicalText = technicalText;
        this._validationSummary = validationSummary;
        this._fieldValidationErrorsList = validationErrorDetails;
    }

    private _validationSummary: string | null;

    get validationSummary(): string {
        if (this._validationSummary !== null) {
            return this._validationSummary;
        }

        return '';
    }

    get fieldValidationErrorsList(): FieldValidationErrorsList | null {
        return this._fieldValidationErrorsList;
    }

    public addValidationErrorDetails(fieldName: string, validationError: Array<string>) {
        if (this._fieldValidationErrorsList === null) {
            this._fieldValidationErrorsList = {};
        }

        this._fieldValidationErrorsList[fieldName] = validationError;
    }

    toString() {
        return {
            technicalText: this.technicalText,
            validationSummary: this.validationSummary,
            fieldValidationErrorsList: this.fieldValidationErrorsList,
        }
    }
}
