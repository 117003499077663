import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../../Ui/Elements/Modal";
import React, {useRef, useState} from "react";
import {Form, FormItem, useForm} from "../../../Ui/Elements/Form";
import {BtnStyleEnum, Button} from "../../../Ui/Elements/Button";
import {Input} from "../../../Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {ModalSubTitle, ModalTitle} from "../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../Ui/Elements/ConfirmDialog";
import {TextArea} from "../../../Ui/Elements/TextArea";
import {DtoTmSection} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {RequestValidationError} from "../../../../../components/HttpApiClient/Exception/RequestValidationError";

export enum ModalResultType {
    CREATED,
    UPDATED,
    DELETED
}

interface TmSectionModalProps extends ModalChildProps {
    levelId: string;
    sectionItem: DtoTmSection | null;
    result: (action: ModalResultType, item: DtoTmSection | null) => void;
}

export const TmSectionModal: React.FC<TmSectionModalProps> = (props) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        const filteredDescription = (
            (typeof values.description === "string") && (values.description.trim() !== '')
        ) ? values.description : null;

        setCloseAllowed(false);
        setSaveInProcess(true);

        const promise = (props.sectionItem) ?
            httpApiClient.tmUpdateSection(
                sessionToken,
                props.sectionItem.id,
                values.name,
                filteredDescription
            )
            : httpApiClient.tmCreateSection(
                sessionToken,
                props.levelId,
                values.name,
                filteredDescription
            );

        promise
            .then((data) => {
                props.result(
                    (props.sectionItem) ? ModalResultType.UPDATED : ModalResultType.CREATED,
                    data.data
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (props.sectionItem) {
                    logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, "Error on update level section: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, "Error on create level section: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef) || (!props.sectionItem)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteSection(
                sessionToken,
                props.sectionItem.id
            )
        } catch (error) {
            if (error instanceof RequestValidationError) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Раздел содержит уроки`,
                    t`Попробуйте сначала удалить их`
                );

                return;
            }

            logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, "Error on delete section: ", error);

            throw error;
        }

        props.result(
            ModalResultType.DELETED,
            null
        );

        modalRef?.current?.close();
    }

    const footerContent = (controls: ModalControlParams) => {
        if (props.sectionItem === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Создать раздел</Trans>
                </Button>
            </div>;
        }

        // Если сейчас мы редактируем раздел
        return <>
            <ConfirmDialog trigger={
                <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                    <Trans>Удалить</Trans>
                </Button>
            }
                           okText={t`Удалить`}
                           cancelText={t`Отмена`}
                           title={t`Удалить раздел?`}
                           text={t`Вы уверены, что нужно удалить раздел ${props.sectionItem.name}?`}
                           errorText={t`Не удалось удалить информацию`}
                           okMethod={onDelete}/>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>;
    }

    return <Modal innerRef={modalRef}
                  trigger={props.trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      setCloseAllowed(true);
                      setSaveInProcess(false);

                      form.resetFields();
                  }}
                  footer={footerContent}
                  footerMode={(props.sectionItem) ? FooterMode.SPACE_BETWEEN : FooterMode.DEFAULT}
                  children={
                      (controls) => {
                          return <div>
                              <ModalTitle>
                                  {
                                      (props.sectionItem === null)
                                          ? <Trans>Создать раздел</Trans>
                                          : <Trans>Редактировать раздел</Trans>
                                  }
                              </ModalTitle>
                              <ModalSubTitle> </ModalSubTitle>
                              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={`name`}
                                      initialValue={props.sectionItem?.name}
                                      rules={[
                                          {
                                              required: true,
                                              message: t`Необходимо указать название раздела`,
                                          },
                                          {
                                              type: "string",
                                              min: 3,
                                              message: t`Название должно быть не короче трёх символов`
                                          },
                                          {
                                              type: "string",
                                              max: 255,
                                              message: t`Название должно быть не длиннее 255 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={'Название раздела'}/>
                                  </FormItem>
                                  <FormItem
                                      name={`description`}
                                      initialValue={props.sectionItem?.description}
                                      rules={[
                                          {
                                              type: "string",
                                              max: 1000,
                                              message: t`Описание должно быть не длиннее 1000 символов`
                                          }
                                      ]}
                                  >
                                      <TextArea
                                          status={""}
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={'Описание раздела'}/>
                                  </FormItem>
                              </Form>
                          </div>
                      }
                  }
    />
}