import React, {useContext} from "react";
import {ILessonPageContext, LessonPageContext} from "../../../LessonPageContext";
import styled from "styled-components";
import {StudentItem} from "./StudentItem";
import {RegularText} from "../../../../../../styles/global-elements";

const ListWrapper = styled.ul`
    list-style: none;
    padding: 0;
`;

const ItemWrapper = styled.li`
    
`;

const SlideName = styled(RegularText)`
    margin-bottom: 15px;
    font-weight: bold;
`;

const StudentItemsWrapper = styled.div`
    margin-bottom: 20px;
    padding-left: 20px;
`;

const StudentItemStyled = styled(StudentItem)`
    margin-bottom: 15px;
`;

interface SlidesListProps {
    lessonId: string
}

export const SlidesList: React.FC<SlidesListProps> = (props) => {
    const {lessonHomeworkMaterialsList, students, lessonRoomId} = useContext<ILessonPageContext>(LessonPageContext);

    if (lessonRoomId === null) {
        return <div/>;
    }

    return <ListWrapper>
        {
            lessonHomeworkMaterialsList.map((item, index) => {
                return <ItemWrapper key={item.slideTmId}>
                    <SlideName>{index + 1}. {item.slideTmName}</SlideName>
                    <StudentItemsWrapper>
                        {
                            students.map(studentItem => {
                                return <StudentItemStyled key={studentItem.stId}
                                                          lessonRoomId={lessonRoomId}
                                                          tmSlideId={item.slideTmId}
                                                          studentUserId={studentItem.myLessonsUserId}
                                                          studentName={studentItem.name}
                                                          studentAvatarFileId={studentItem.avatarFileId}
                                />
                            })
                        }
                    </StudentItemsWrapper>
                </ItemWrapper>
            })
        }
    </ListWrapper>;
}
