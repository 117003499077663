import {IsBoolean, IsNotEmpty, IsNumber, IsString} from "class-validator";

/**
 * DTO элемента списка учеников на занятии
 */
export class DtoStudentInLessonListItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsNumber()
    studentId: number;

    @IsNotEmpty()
    @IsString()
    studentName: string;

    @IsNotEmpty()
    @IsNumber()
    sex: number;

    @IsNotEmpty()
    @IsNumber()
    param1: number;

    @IsNotEmpty()
    @IsNumber()
    param2: number;

    @IsNotEmpty()
    @IsNumber()
    param3: number;

    @IsNotEmpty()
    @IsNumber()
    param4: number;

    @IsNotEmpty()
    @IsNumber()
    param5: number;

    @IsNotEmpty()
    @IsNumber()
    totalAverage: number;

    @IsNotEmpty()
    @IsNumber()
    absent: number;

    @IsNotEmpty()
    @IsBoolean()
    isDebtor: boolean;

    @IsBoolean()
    commentExists: boolean;

    @IsString()
    comment: string;

    @IsNumber()
    mailReportStatus: number;
}
