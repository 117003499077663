import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PageTitle} from "../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../store/app/selector";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {PaymentTypesEnum} from "../../../components/PaymentsTable/PaymentTypesEnum";
import {PageLoadingStateEnum} from "../../../../enums/PageLoadingStateEnum";
import {PaymentTableItem} from "../../../components/PaymentsTable/Table/PaymentTableItemType";
import useDebounce from "../../../../components/DebounceHook";
import {isEqual} from "lodash";
import {selectedAgreement as selectedAgreementSelector} from "../../../../store/user/selector";
import {ILogger} from "../../../../components/Logger/ILogger";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {PaymentsTable} from "../../../components/PaymentsTable/Table";
import {Filters} from "../../../components/PaymentsTable/Filters";

export const Payments: React.FC = () => {
    const selectedAgreement = useSelector(selectedAgreementSelector);
    const apiToken = useSelector(sessionTokenSelector);

    const dateHelperService = useMemo<IDateHelperService>(
        () => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE),
        []
    );

    const [fromDate, setFromDate] = useState<Date | null>(dateHelperService.getFirstDayThreeMonthBefore());
    const [toDate, setToDate] = useState<Date | null>(null);
    const [selectedTypes, setSelectedTypes] = useState<PaymentTypesEnum[]>([
        PaymentTypesEnum.CONSUMPTION,
        PaymentTypesEnum.ADMISSION,
        PaymentTypesEnum.LESSON_PAYMENTS
    ]);

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const [items, setItems] = useState<PaymentTableItem[]>([]);
    const [filterChangeIterationNum, setFilterChangeIterationNum] = useState<number>(0);

    const filterChangeIterationNumDebounced = useDebounce(filterChangeIterationNum);

    const onFiltersChange = useCallback((newFromDate: Date, newToDate: Date, newItems: PaymentTypesEnum[]) => {
        let somethingChanged = false;

        if (newFromDate !== fromDate) {
            setFromDate(newFromDate);

            somethingChanged = true;
        }

        if (newToDate !== toDate) {
            setToDate(newToDate);

            somethingChanged = true;
        }

        if (!isEqual(newItems, selectedTypes)) {
            setSelectedTypes(newItems);

            somethingChanged = true;
        }

        if (somethingChanged) {
            setFilterChangeIterationNum((value) => {
                return value + 1;
            });
        }
    }, [fromDate, toDate, selectedTypes]);

    const fetchPayments = useCallback(() => {
        if ((!apiToken) || (!selectedAgreement)) {
            return;
        }

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        apiClient.getPaymentsHistory(
            apiToken,
            selectedAgreement.id,
            selectedTypes.indexOf(PaymentTypesEnum.ADMISSION) > -1,
            selectedTypes.indexOf(PaymentTypesEnum.CONSUMPTION) > -1,
            selectedTypes.indexOf(PaymentTypesEnum.LESSON_PAYMENTS) > -1,
            (fromDate) ? dateHelperService.formatAsSQLDate(fromDate) : null,
            (toDate) ? dateHelperService.formatAsSQLDate(toDate) : null,
            1,
            5000,
            newAbortController
        )
            .then((data) => {
                setItems(data.data.list.map(item => {
                    return {
                        paymentId: 0,
                        summ: parseFloat(item.summ),
                        datePay: dateHelperService.dateFromString(item.datePay),
                        payType: item.payType,
                        comment: item.comment ?? '',
                        commentAsString: item.comment ?? ''
                    }
                }));

                setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                if (!(err instanceof NoConnection)) {
                    logger.error(
                        LoggerSectionsEnum.STUDENT_PAYMENTS_LIST,
                        `Error on load payments list for student agreement ${selectedAgreement.id}`,
                        err
                    );
                }

                setPageLoadingState(PageLoadingStateEnum.ERROR);
            });
    }, [abortController, apiToken, dateHelperService, fromDate, selectedAgreement, selectedTypes, toDate]);

    useEffect(() => {
        fetchPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterChangeIterationNumDebounced]);

    useEffect(() => {
        return () => {
            if (abortController !== null) {
                abortController.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const table = useMemo<JSX.Element>(() => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchPayments}/>;
            }
        }

        if (items.length === 0) {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Не найдено операций</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Попробуйте скорректировать фильтры</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        }

        return <>
            <PaymentsTable items={items}/>
        </>
    }, [fetchPayments, items, pageLoadingState]);

    return <div>
        <PageTitle><Trans>Платежи</Trans></PageTitle>
        <Filters
            fromDate={fromDate}
            toDate={toDate}
            selectedTypes={selectedTypes}
            onChange={onFiltersChange}
        />
        {table}
    </div>

}
