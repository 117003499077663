import React from 'react';
import styled from "styled-components";

interface DotLoaderProps {
    accentColor: string;
}

const DotLoaderStyle = styled.div<DotLoaderProps>`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${(props) => props.accentColor};
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;

  @keyframes dotFlashing {
    0% {
      background-color: ${(props) => props.accentColor};
    }
    50%,
    100% {
      background-color: ${(props) => props.accentColor + '66'};
    }
  }

  ::before, ::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  ::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.accentColor};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  ::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${(props) => props.accentColor};
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
`;

export const DotLoader: React.FC<DotLoaderProps> = (props) => {
    return <DotLoaderStyle accentColor={props.accentColor}/>;
}