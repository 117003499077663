import React from "react";
import {LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {ConferenceModeEnum, ParticipantItem} from "../../Types";

export interface IRoomCommonActionsContext {
    setLessonConferenceState: React.Dispatch<React.SetStateAction<LessonConferenceStatusEnum>>;
    setParticipantsListItemUpdate: (studentItem: Partial<ParticipantItem>[]) => void;
    setUnavailableAudioForAllParticipants: () => void;
    setUnavailableVideoForAllParticipants: () => void;
    setCurrentConferenceMode: React.Dispatch<React.SetStateAction<ConferenceModeEnum>>;
}

export const RoomCommonActionsContext = React.createContext<IRoomCommonActionsContext>({
    setLessonConferenceState: () => {},
    setParticipantsListItemUpdate: (studentItem: Partial<ParticipantItem>[]) => {},
    setUnavailableAudioForAllParticipants: () => {},
    setUnavailableVideoForAllParticipants: () => {},
    setCurrentConferenceMode: () => {}
});

export const RoomCommonActionsContextProvider = RoomCommonActionsContext.Provider;
