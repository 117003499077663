import {Reducer} from "redux";
import {AppActionTypes, AppInitializationStateTypes, AppState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<AppState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case AppActionTypes.SET_APP_INITIALIZATION_STATE: {
            draft.appInitializationState = action.payload;

            if (action.payload === AppInitializationStateTypes.COMPLETED) {
                draft.appInitializedAt = new Date();
            }

            break;
        }
        case AppActionTypes.SET_WS_CONNECTION_STATUS: {
            draft.wsConnectionStatus = action.payload;

            break;
        }
        case AppActionTypes.SET_IN_FATAL_ERROR_STATE: {
            draft.inFatalErrorState = action.payload;

            break;
        }
        case AppActionTypes.SET_LAST_PING_DATE: {
            draft.lastPingDate = action.payload;

            break;
        }
        case AppActionTypes.SET_PING_TIME_SUSPENDED_VALUE: {
            draft.pingTimeUpdateSuspended = action.payload;

            break;
        }
        case AppActionTypes.SET_WS_CONNECTION_REQUIRED: {
            draft.wsConnectionRequired = action.payload;

            break;
        }
        case AppActionTypes.SET_INCREMENT_WS_RECONNECT_COUNT: {
            draft.wsReconnectNum = draft.wsReconnectNum + 1;

            break;
        }
        case AppActionTypes.SET_WS_DISCONNECT_ALERT_IMMEDIATELY: {
            draft.wsDisconnectAlertImmediately = action.payload;

            break;
        }
        case AppActionTypes.SET_WS_RECONNECT_COUNT_TO_ZERO: {
            draft.wsReconnectNum = 0;

            break;
        }
        case AppActionTypes.SET_WEB_RTC_SUPPORTED: {
            draft.webRtcSupported = action.payload;

            break;
        }
        case AppActionTypes.SET_CAMERA_REQUEST_ACCESS_NOW: {
            draft.cameraAccess.requestAccessNow = action.payload;

            break;
        }
        case AppActionTypes.SET_CAMERA_ALLOWED_IN_SESSION: {
            draft.cameraAccess.allowedInSession = action.payload;

            break;
        }
        case AppActionTypes.SET_ROUTE_LEAVE_CONFIRM_MESSAGE: {
            draft.routeLeaveConfirmMessage = action.payload;

            break;
        }
        case AppActionTypes.SET_NEED_INITIAL_CUSTOMIZER: {
            draft.needInitialCustomizer = action.payload;

            break;
        }
    }
}, initialState);

export {reducer as appReducer}
