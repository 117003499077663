import {IsNotEmpty, IsOptional, IsString, ValidateNested} from "class-validator";
import {Type} from "class-transformer";
import {DtoVideoRoomParams} from "./DtoVideoRoomParams";

export class DtoJoinToRoomResponse {
    @IsNotEmpty()
    @IsString()
    lessonId: string;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoVideoRoomParams)
    videoRoomParams: DtoVideoRoomParams | null;

    @IsOptional()
    @IsString()
    currentSlideId: string | null;
}
