import React from "react";

export interface IAudioRoomActionsContext {
    muteMyAudio: () => Promise<void>;
    unmuteMyAudio: () => Promise<void>;
}

export const AudioRoomActionsContext = React.createContext<IAudioRoomActionsContext>({
    muteMyAudio: async () => {
    },
    unmuteMyAudio: async () => {
    }
});

export const AudioRoomActionsContextProvider = AudioRoomActionsContext.Provider;
