import * as React from "react";
import {ReactComponent as BackButtonIcon} from "../../../components/Ui/Svg/BackArrow.svg";
import styled from "styled-components";
import {RoutesList} from "../../../RoutesList";
import {useNavigate} from "react-router-dom";

const TitleWrapper = styled.div`
  width: 100%;
  display: block;
  position: relative;
`;

const BackBtnIconWrapper = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  padding: 8px;
  line-height: 1;
`;

const TitleText = styled.div`
  display: block;
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

export interface FormTitleProps {
    titleText: string;
}

export const FormTitle: React.FC<FormTitleProps> = (props) => {
    const navigate = useNavigate();

    return <TitleWrapper>
        <BackBtnIconWrapper onClick={() => navigate(RoutesList.LOGIN_FORM)}>
            <BackButtonIcon/>
        </BackBtnIconWrapper>
        <TitleText>
            {props.titleText}
        </TitleText>
    </TitleWrapper>
}