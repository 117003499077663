import {IsNotEmpty, IsNumber, IsString} from "class-validator";

/**
 * DTO элемента списка доступных уровней
 */
export class DtoTrainingProgramListItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    name: string;
}
