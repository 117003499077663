import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';
import {LengthEnum} from '../../../Enums/LengthEnum';

/**
 * DTO параметров запроса авторизации пользователя.
 */
export class AuthLoginDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.API_AUTH_TOKEN_LENGTH)
    token: string;
}