import {IsNotEmpty, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO Опроса по содержимому эпизода self-study трека
 */
export class DtoSelfStudyTrackEpisodeSurvey {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    questionId: string;

    @IsNotEmpty()
    @IsString()
    question: string;

    @IsNotEmpty()
    @IsString()
    badAnswer: string;

    @IsNotEmpty()
    @IsString()
    normalAnswer: string;

    @IsNotEmpty()
    @IsString()
    goodAnswer: string;
}
