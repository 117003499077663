import {IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";

/**
 * DTO курирующего преподавателя
 */
export class DtoTutorProfile {
    @IsNotEmpty()
    @IsString()
    id: string;

    @IsOptional()
    @IsNumber()
    stApiId: number | null;

    @IsNotEmpty()
    @IsString()
    fio: string;

    @IsOptional()
    @IsString()
    position: string;

    @IsOptional()
    @IsString()
    avatarFileId: string | null;
}