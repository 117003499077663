import {IsNotEmpty, IsNumber, IsOptional, IsString, Length, ValidateNested} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {Type} from "class-transformer";
import {DtoTmSlideContentItemParams} from "./DtoTmSlideContentItemParams";
import {DtoTmSlideContentItemInteractivityConfig} from "./DtoTmSlideContentItemInteractivityConfig";

/**
 * DTO элемента слайда урока
 */
export class DtoTmSlideContentItem {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 200)
    type: string;

    @IsOptional()
    @IsNotEmpty()
    @IsString()
    alias?: string | null;

    @IsOptional()
    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoTmSlideContentItemParams)
    params: DtoTmSlideContentItemParams;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoTmSlideContentItemInteractivityConfig)
    interactivityConfig: DtoTmSlideContentItemInteractivityConfig | null;

    @IsOptional()
    @IsString()
    @Length(1, 1000000)
    data: string | null;

    @IsOptional()
    @IsNumber()
    exercisesCount?: number;
}
