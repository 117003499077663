import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Modal} from "../../../../../components/Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {PageTitle, RegularTextCss} from "../../../../../styles/global-elements";
import styled from "styled-components";
import bgImage from "./acuiringDocumentBg.jpg";
import {
    DtoOnlinePaymentDocumentResponseItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {selectedAgreement} from "../../../../../../store/user/selector";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {CanceledByUser} from "../../../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../../../components/HttpApiClient/Exception/NoConnection";

const InfoLine = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.textSecondary};

  @media (${({theme}) => theme.media.large}) {
    font-size: 18px;
  }
`;

const WideImage = styled.div`
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  background-size: cover;
  background-position: center;
  min-height: 160px;
  max-height: 160px;
  background-image: url(${bgImage});

  @media (${({theme}) => theme.media.small}) {
    min-height: 200px;
    max-height: 200px;
  }

  @media (${({theme}) => theme.media.large}) {
    min-height: 240px;
    max-height: 240px;
  }
`;

const PageTitleStyled = styled(PageTitle)`
  margin-bottom: 0;
`;

const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

const DocBody = styled.div`
    ${RegularTextCss};
`;

enum LoadingStateEnum {
    NOT_INIT,
    LOADING_NOW,
    SUCCESS,
    ERROR
}

interface AcquiringDocumentModalProps {
    documentId: string;
    documentDefaultName: string;
    trigger: JSX.Element;
}

export const AcquiringDocumentModal: React.FC<AcquiringDocumentModalProps> = (props) => {
    const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_INIT);
    const [documentData, setDocumentData] = useState<DtoOnlinePaymentDocumentResponseItem | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const sessionToken = useSelector(sessionTokenSelector);
    const agreement = useSelector(selectedAgreement);

    useEffect(() => {
        return () => {
            if (abortController !== null) {
                abortController.abort();
            }
        }
    }, [abortController]);

    const fetchData = useCallback(() => {
        if ((agreement === null) || (sessionToken === null)) {
            setLoadingState(LoadingStateEnum.ERROR);

            return;
        }

        if (abortController !== null) {
            abortController.abort();
        }

        setLoadingState(LoadingStateEnum.LOADING_NOW);
        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const httpClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        httpClient.getAcquiringDocumentBody(
            sessionToken,
            agreement.id,
            props.documentId,
            newAbortController
        )
            .then((data) => {
                setDocumentData(data.data);
                setLoadingState(LoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                if (err instanceof CanceledByUser) {
                    return;
                }

                const message = 'Error on load acquiring document body';

                if (err instanceof NoConnection) {
                    logger.info(LoggerSectionsEnum.ACQUIRING_DOCUMENT_MODAL, message, err);
                } else {
                    logger.error(LoggerSectionsEnum.ACQUIRING_DOCUMENT_MODAL, message, err);
                }


                setLoadingState(LoadingStateEnum.ERROR);
            });

    }, [abortController, agreement, props.documentId, sessionToken]);

    const title = useMemo<JSX.Element | string>(() => {
        switch (props.documentId) {
            case "payment-rules-and-security": {
                return <Trans>Правила оплаты</Trans>;
            }
            case "return-policy": {
                return <Trans>Правила возврата</Trans>;
            }
            case "contacts-and-requisites": {
                return <Trans>Реквизиты</Trans>;
            }
            default: {
                return props.documentDefaultName;
            }
        }
    }, [props.documentId, props.documentDefaultName]);

    const subtitle = useMemo<JSX.Element | null>(() => {
        switch (props.documentId) {
            case "payment-rules-and-security": {
                return <Trans>Правила оплаты и безопасность платежей, конфиденциальность информации</Trans>;
            }
            case "return-policy": {
                return <Trans>Правила возврата денежных средств</Trans>;
            }
            case "contacts-and-requisites": {
                return <Trans>Контактная информация и реквизиты</Trans>;
            }
            default: {
                return null;
            }
        }
    }, [props.documentId]);

    const content = useMemo<JSX.Element>(() => {
        switch (loadingState) {
            case LoadingStateEnum.NOT_INIT:
            case LoadingStateEnum.LOADING_NOW: {
                return <DefaultLoader/>;
            }

            case LoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchData}/>;
            }

            case LoadingStateEnum.SUCCESS: {
                return <DocBody dangerouslySetInnerHTML={{__html: documentData?.body ?? ""}}/>;
            }
        }

    }, [documentData, fetchData, loadingState]);

    return <Modal trigger={props.trigger}
                  closeAllowed={true}
                  onOpen={fetchData}
                  footer={
                      (controls) => {
                          return <div>
                              <Button btnStyle={BtnStyleEnum.Primary}
                                      onClick={controls.closeModal}
                              >
                                  <Trans>Закрыть</Trans>
                              </Button>
                          </div>
                      }
                  }
                  wideHeaderBlock={
                      <WideImage/>
                  }
                  children={
                      (controls) => {
                          return <>
                              <TitleWrapper>
                                  <PageTitleStyled>{title}</PageTitleStyled>
                                  {
                                      (subtitle)
                                      && <InfoLine><Trans>{subtitle}</Trans></InfoLine>
                                  }
                              </TitleWrapper>
                              {content}
                          </>
                      }
                  }
    />;
}
