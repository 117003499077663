import {IsNotEmpty, IsOptional, IsString} from 'class-validator';

/**
 * DTO элемента ответа на запрос документов к онлайн эквайрингу
 */
export class DtoOnlinePaymentDocumentResponseItem {
    @IsNotEmpty()
    @IsString()
    id: string;

    @IsOptional()
    @IsString()
    title: string;

    @IsOptional()
    @IsString()
    body: string | null;
}