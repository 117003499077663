import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import React from "react";

export interface ICreateStudentPageContext {
    studentGeneralInfoDto: DtoStudentGeneralInfo;
    setStudentGeneralInfoDto: (studentGeneralInfoDto: DtoStudentGeneralInfo) => void;
    shortStudentName: string;
}

export const CreateStudentPageContext = React.createContext<ICreateStudentPageContext>({
    studentGeneralInfoDto: new DtoStudentGeneralInfo(),
    setStudentGeneralInfoDto: studentGeneralInfoDto => {
    },
    shortStudentName: '',
});

export const CreateStudentPageContextProvider = CreateStudentPageContext.Provider;
