import styled from "styled-components";
import {RegularTextCss} from "../../styles/global-elements";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  ${RegularTextCss};
  
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-bottom: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;

  @media (${({theme}) => theme.media.small}) {
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
  }
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
`;