import React, {useCallback, useContext, useEffect, useRef} from "react";
import {CommonContext, ICommonContext} from "../../contexts/CommonContext";
import {container} from "tsyringe";
import {IWsApiClient} from "../../../../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ApiMethodEnum} from "../../../../../components/WsApiClient/ApiMethodEnum";
import {DtoVideoPin} from "../../../../../components/WsApiClient/ApiDto/Request/LessonRoom/PublicState/DtoVideoPin";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";

export const SelectedMemberPublisher: React.FC = ({children}) => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const lastPublishState = useRef<string | null>(null);

    const publicInfoToWs = useCallback((value: string | null) => {
        const dto = new DtoVideoPin();
        dto.lessonId = commonContext.lessonId;
        dto.pinnedUserId = value;

        const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        wsClient.query(
            ApiMethodEnum.LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN,
            dto,
            null,
            true
        )
            .catch((error) => {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.warning(
                    LoggerSectionsEnum.LESSON_ROOM,
                    `Error on publish current selected member id`,
                    error
                );
            });
    }, [commonContext.lessonId]);

    useEffect(() => {
        if (lastPublishState.current !== commonContext.selectedMemberId) {
            publicInfoToWs(commonContext.selectedMemberId);
            lastPublishState.current = commonContext.selectedMemberId;
        }
    }, [commonContext.selectedMemberId, publicInfoToWs]);

    return <>{children}</>;
}
