import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {PageSubtitle, PageTitle} from "../../../../styles/global-elements";
import {OrganizationsList} from "./organizations-list";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {
    DtoTmOrganization
} from "../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {Notice} from "../../../../components/Ui/Elements/Notice";
import {OrganizationTitleItem} from "./organizations-list/OrganizationTitleItem";
import {DisciplinesList} from "./disciplines-list";
import {DtoTmDiscipline} from "../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {DisciplineTitleItem} from "./disciplines-list/DisciplineTitleItem";
import {LevelsList} from "./levels-list";
import {DtoTmLevel} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {LevelTitleItem} from "./levels-list/LevelTitleItem";
import {SectionsList} from "./sections-list";
import {DtoTmSection} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {SectionTitleItem} from "./sections-list/SectionTitleItem";
import {LessonsList} from "./lessons-list";
import {DtoTmLesson} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {LessonTitleItem} from "./lessons-list/LessonTitleItem";
import {LessonDetailsCard} from "./lessons-list/LessonDetailsCard";

const ContentGroup = styled.div`
  margin-bottom: 40px;
`;

const SubTitle = styled(PageSubtitle)`
  margin-bottom: 24px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 24px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 24px;
  }
`;

export const SelectedItemTitle = styled.span`
  display: inline;
  border-bottom: 2px dashed ${({theme}) => theme.colors.textSecondary};
  cursor: pointer;
  transition: border-color 1s ease;

  &:hover {
    border-bottom-style: solid;
    border-bottom-color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const ListWrapper = styled.div`
  @media (${({theme}) => theme.media.large}) {
    max-width: 60%;
  }
`;

export const TeachingMaterials: React.FC = () => {
    const [organizations, setOrganizations] = useState<DtoTmOrganization[] | null>(null);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState<string | null>(null);

    const [disciplines, setDisciplines] = useState<DtoTmDiscipline[] | null>(null);
    const [selectedDisciplineId, setSelectedDisciplineId] = useState<string | null>(null);

    const [levels, setLevels] = useState<DtoTmLevel[] | null>(null);
    const [selectedLevelId, setSelectedLevelId] = useState<string | null>(null);

    const [sections, setSections] = useState<DtoTmLevel[] | null>(null);
    const [selectedSectionId, setSelectedSectionId] = useState<string | null>(null);

    const [lessons, setLessons] = useState<DtoTmLesson[] | null>(null);
    const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);

    // Сброс активного уровня дисциплины при изменении активной дисциплины
    useEffect(() => {
        if (selectedLevelId === null) {
            return;
        }

        if ((selectedOrganizationId === null) || (selectedDisciplineId === null)) {
            setSelectedLevelId(() => null);
        }
    }, [selectedLevelId, selectedOrganizationId, selectedDisciplineId]);

    const selectedOrganization = useMemo<DtoTmOrganization | null>(() => {
        if ((organizations === null) || (selectedOrganizationId === null)) {
            return null;
        }

        const item = organizations.find((item) => (item.id === selectedOrganizationId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [organizations, selectedOrganizationId]);

    const selectedDiscipline = useMemo<DtoTmDiscipline | null>(() => {
        if ((disciplines === null) || (selectedDisciplineId === null)) {
            return null;
        }

        const item = disciplines.find((item) => (item.id === selectedDisciplineId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [disciplines, selectedDisciplineId]);

    const selectedLevel = useMemo<DtoTmLevel | null>(() => {
        if ((levels === null) || (selectedLevelId === null)) {
            return null;
        }

        const item = levels.find((item) => (item.id === selectedLevelId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [levels, selectedLevelId]);

    const selectedSection = useMemo<DtoTmSection | null>(() => {
        if ((sections === null) || (selectedSectionId === null)) {
            return null;
        }

        const item = sections.find((item) => (item.id === selectedSectionId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [sections, selectedSectionId]);

    const selectedLesson = useMemo<DtoTmLesson | null>(() => {
        if ((lessons === null) || (selectedLessonId === null)) {
            return null;
        }

        const item = lessons.find((item) => (item.id === selectedLessonId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [lessons, selectedLessonId]);

    const items = () => {
        const result = [];

        result.push(<ContentGroup key={'organizations'}>
            <SubTitle>
                <Trans>Организации</Trans>
            </SubTitle>
            <ListWrapper>
                <OrganizationsList organizations={organizations}
                                   setOrganizations={setOrganizations}
                                   selectedOrganizationId={selectedOrganizationId}
                                   setSelectedOrganizationId={setSelectedOrganizationId}
                />
            </ListWrapper>
        </ContentGroup>);

        if (selectedOrganization !== null) {
            result.push(<ContentGroup key={'disciplines'}>
                <SubTitle>
                    <span><Trans>Дисциплины</Trans></span>&nbsp;
                    <OrganizationTitleItem item={selectedOrganization}
                                           organizations={organizations}
                                           setOrganizations={setOrganizations}
                                           onDelete={() => {
                                               setSelectedOrganizationId(null);
                                               // Полная перезагрузка списка организаций
                                               setOrganizations(null);
                                           }}
                    >{selectedOrganization.name}</OrganizationTitleItem>
                </SubTitle>

                <ListWrapper>
                    <DisciplinesList organizationId={selectedOrganization.id}
                                     disciplines={disciplines}
                                     setDisciplines={setDisciplines}
                                     selectedDisciplineId={selectedDisciplineId}
                                     setSelectedDisciplineId={setSelectedDisciplineId}
                    />
                </ListWrapper>
            </ContentGroup>);

            if (selectedDiscipline !== null) {
                result.push(
                    <ContentGroup key={'levels'}>
                        <SubTitle>
                            <span><Trans>Уровни дисциплины</Trans></span>&nbsp;
                            <DisciplineTitleItem organizationId={selectedOrganization.id}
                                                 item={selectedDiscipline}
                                                 disciplines={disciplines}
                                                 setDiscipline={setDisciplines}
                                                 onDelete={() => {
                                                     setSelectedDisciplineId(null);
                                                     // Полная перезагрузка списка дисциплин
                                                     setDisciplines(null);
                                                 }}
                            >{selectedOrganization.name}</DisciplineTitleItem>
                        </SubTitle>

                        <ListWrapper>
                            <LevelsList disciplineId={selectedDiscipline.id}
                                        levels={levels}
                                        setLevels={setLevels}
                                        selectedLevelId={selectedLevelId}
                                        setSelectedLevelId={setSelectedLevelId}/>
                        </ListWrapper>
                    </ContentGroup>
                );

                if (selectedLevel !== null) {
                    result.push(
                        <ContentGroup key={'sections'}>
                            <SubTitle>
                                <Trans>Секции уровня </Trans>&nbsp;
                                <LevelTitleItem disciplineId={selectedDiscipline.id}
                                                item={selectedLevel}
                                                levels={levels}
                                                setLevels={setLevels}
                                                onDelete={() => {
                                                    setSelectedLevelId(null);
                                                    // Полная перезагрузка списка уровней
                                                    setLevels(null);
                                                }}
                                >{selectedLevel.name}</LevelTitleItem>
                            </SubTitle>

                            <ListWrapper>
                                <SectionsList levelId={selectedLevel.id}
                                              sections={sections}
                                              setSections={setSections}
                                              selectedSectionId={selectedSectionId}
                                              setSelectedSectionId={setSelectedSectionId}/>
                            </ListWrapper>
                        </ContentGroup>
                    );

                    if (selectedSection !== null) {
                        result.push(
                            <ContentGroup key={'lessons'}>
                                <SubTitle>
                                    <Trans>Уроки секции </Trans>&nbsp;
                                    <SectionTitleItem levelId={selectedLevel.id}
                                                      item={selectedSection}
                                                      sections={sections}
                                                      setSections={setSections}
                                                      onDelete={() => {
                                                          setSelectedSectionId(null);
                                                          // Полная перезагрузка списка секций
                                                          setSections(null);
                                                      }}
                                    >{selectedLevel.name}</SectionTitleItem>
                                </SubTitle>

                                <ListWrapper>
                                    <LessonsList sectionId={selectedSection.id}
                                                 lessons={lessons}
                                                 setLessons={setLessons}
                                                 selectedLessonId={selectedLessonId}
                                                 setSelectedLessonId={setSelectedLessonId}/>
                                </ListWrapper>
                            </ContentGroup>
                        );

                        if (selectedLesson !== null) {
                            result.push(
                                <ContentGroup key={'lesson_item'}>
                                    <SubTitle>
                                        <Trans>Урок </Trans>&nbsp;
                                        <LessonTitleItem sectionId={selectedSection.id}
                                                         item={selectedLesson}
                                                         lessons={lessons}
                                                         setLessons={setLessons}
                                                         onDelete={() => {
                                                             // setSelectedSectionId(null);

                                                             // Полная перезагрузка списка уроков
                                                             setLessons(null);
                                                         }}
                                        >{selectedLevel.name}</LessonTitleItem>
                                    </SubTitle>
                                    <ListWrapper>
                                        <LessonDetailsCard lessonId={selectedLesson.id}
                                                           descriptionForTeacher={selectedLesson.descriptionForTeacher}
                                                           descriptionForStudent={selectedLesson.descriptionForStudent}/>
                                    </ListWrapper>
                                </ContentGroup>
                            );
                        }
                    }
                } else {
                    result.push(
                        <ListWrapper key={'level_notice'}>
                            <Notice>
                                <Trans>Выберите уровень, чтобы просмотреть доступные секции уровня</Trans>
                            </Notice>
                        </ListWrapper>
                    )
                }
            } else {
                result.push(
                    <ListWrapper key={'disciplines_notice'}>
                        <Notice>
                            <Trans>Выберите дисциплину, чтобы просмотреть доступные уровни</Trans>
                        </Notice>
                    </ListWrapper>
                )
            }
        } else {
            result.push(
                <ListWrapper key={'organizations_notice'}>
                    <Notice>
                        <Trans>Выберите организацию, чтобы просмотреть доступные дисциплины</Trans>
                    </Notice>
                </ListWrapper>
            )
        }

        return result;
    }

    return (
        <>
            <PageTitle>
                <Trans>Учебные материалы</Trans>
            </PageTitle>
            {items()}
        </>
    );
}