import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Form, FormItem, useForm} from "../../../../components/Ui/Elements/Form";
import {InputLabel, RoubleSymbol} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {AcquiringDocumentModal} from "./AcquiringDocument/AcquiringDocumentModal";
import {ReactComponent as MirColored} from "../../../../components/Ui/Svg/MirColored.svg";
import {ReactComponent as VisaColored} from "../../../../components/Ui/Svg/VisaColored.svg";
import {ReactComponent as MastercardColored} from "../../../../components/Ui/Svg/MastercardColored.svg";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import styled from "styled-components";
import {
    DtoOnlinePaymentDocumentResponseItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {selectedAgreement} from "../../../../../store/user/selector";
import {RoutesList} from "../../../../RoutesList";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";

enum FormFieldNames {
    paymentSum = 'paymentSum',
    studentEmail = 'studentEmail'
}

const PaymentFormStyled = styled.div`
  .ant-form-inline .ant-form-item {
    margin-right: 0;
  }
`;

const FormComponentsWrapper = styled.div`
  display: grid;
  grid-template: "a" "b" "c";
  grid-gap: 24px;
  width: 100%;
  margin-bottom: 24px;

  @media (${({theme}) => theme.media.medium}) {
    grid-template: "a b" "c .";
  }

  @media (${({theme}) => theme.media.large}) {
    grid-template: "a b c";
  }
`;

const FormFieldWrapper = styled.div`
  position: relative;
`;

const FormButtonWrapper = styled.div`
  width: 100%;

  @media (${({theme}) => theme.media.large}) {
    padding-top: 1.5em;
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const DocumentsAndIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${({theme}) => theme.media.medium}) {
    flex-direction: row;
  }
`;

const AcquiringDocumentsList = styled.ul`
  padding: 0;
  list-style: none;
  flex-grow: 1;

  @media (${({theme}) => theme.media.medium}) {
    margin-right: 20px;
  }
`;

const AcquiringDocumentItem = styled.li`
  margin-bottom: 8px;
  text-decoration: underline;
  color: ${({theme}) => theme.colors.textTertiary};
  font-size: 10px;

  @media (${({theme}) => theme.media.large}) {
    font-size: 14px;
  }
`;

const AcquiringDocumentLink = styled.span`
  cursor: pointer;
`;

const AcquiringTypesIcons = styled.div`
  display: flex;
  flex-direction: row;

  @media (${({theme}) => theme.media.medium}) {
    justify-content: right;
  }
`;

const AcquiringTypeIconWrapper = styled.div`
  margin-right: 16px;
`;

interface PaymentFormProps {
    acquiringDocuments: DtoOnlinePaymentDocumentResponseItem[];
}

export const PaymentForm: React.FC<PaymentFormProps> = ({acquiringDocuments}) => {
    const [form] = useForm();

    const [loadingPaymentLink, setLoadingPaymentLink] = useState<boolean>(false);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const userEmail = useSelector(({user}: ApplicationState) => {
        return (user.profileData) ? user.profileData.email : null;
    });

    const sessionToken = useSelector(sessionTokenSelector);
    const agreement = useSelector(selectedAgreement);

    const minPaymentSum = useMemo(() => {
        if (!agreement) {
            return 1;
        }

        if (agreement.onlinePaymentMinSum === 0) {
            return 1;
        }

        return agreement.onlinePaymentMinSum;
    }, [agreement]);

    const onFinish = async (values: any) => {
        if ((sessionToken === null) || (agreement === null)) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        setLoadingPaymentLink(true);

        const link = window.location.origin + RoutesList.ONLINE_PAYMENT_SUCCESS_URL;

        httpApiClient.payOnlineViaCard(
            sessionToken,
            values[FormFieldNames.paymentSum],
            values[FormFieldNames.studentEmail],
            link,
            link,
            agreement.id,
            newAbortController
        )
            .then((data) => {
                window.location.href = data.data.paymentPageUrl;
            })
            .catch((err) => {
                setLoadingPaymentLink(false);
                logger.error(LoggerSectionsEnum.PAYMENT_MODAL, 'Error on start pay via card', err);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось перейти к оплате. Попробуйте повторить попытку позднее.`
                );
            })
    }

    useEffect(() => {
        return () => {
            if (abortController !== null) {
                return abortController.abort();
            }
        }
    }, [abortController]);

    const getAcquiringDocumentName = useCallback((documentId: string, defaultName: string) => {
        switch (documentId) {
            case "payment-rules-and-security": {
                return <Trans>Правила оплаты и безопасность платежей, конфиденциальность информации</Trans>;
            }
            case "return-policy": {
                return <Trans>Правила возврата денежных средств</Trans>;
            }
            case "contacts-and-requisites": {
                return <Trans>Контактная информация и реквизиты</Trans>;
            }
            default: {
                return <>defaultName</>;
            }
        }
    }, []);

    return <PaymentFormStyled>
        <Form form={form} layout={"inline"} onFinish={onFinish}>
            <FormComponentsWrapper>
                <FormFieldWrapper style={{gridArea: "a"}}>
                    <InputLabel><Trans>Сумма</Trans></InputLabel>
                    <FormItem
                        name={FormFieldNames.paymentSum}
                        rules={[
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value) {
                                        return Promise.reject(t`Необходимо указать сумму платежа`);
                                    }

                                    if (!(/^[-\d]+$/.test(value))) {
                                        return Promise.reject(new Error(t`Используйте, пожалуйста, целое значение`));
                                    }

                                    const numberValue = parseInt(value);

                                    if (numberValue < minPaymentSum) {
                                        return Promise.reject(new Error(t`Минимальная сумма - ${minPaymentSum} р.`));
                                    }

                                    if (numberValue > 100000) {
                                        return Promise.reject(new Error(t`Минимальная сумма - 100 000 рублей`));
                                    }

                                    return Promise.resolve()
                                }
                            })
                        ]}
                    >
                        <Input type={"number"} placeholder={"0"}/>
                    </FormItem>
                    <RoubleSymbol>&#8381;</RoubleSymbol>
                </FormFieldWrapper>
                <FormFieldWrapper style={{gridArea: "b"}}>
                    <InputLabel><Trans>E-mail для отправки чека</Trans></InputLabel>
                    <FormItem
                        name={FormFieldNames.studentEmail}
                        initialValue={(userEmail) ?? ""}
                        rules={[
                            {
                                required: true,
                                message: t`Необходимо указать email`,
                            },
                            {
                                type: "email",
                                message: t`Необходимо указать email`,
                            },
                        ]}
                    >
                        <Input type={"email"} placeholder={"example@mail.com"}/>
                    </FormItem>
                </FormFieldWrapper>
                <FormButtonWrapper style={{gridArea: "c"}}>
                    <ButtonStyled btnStyle={BtnStyleEnum.Primary}
                                  loading={loadingPaymentLink}
                                  onClick={() => form.submit()}
                    >
                        <Trans>Пополнить</Trans>
                    </ButtonStyled>
                </FormButtonWrapper>
            </FormComponentsWrapper>
            <DocumentsAndIconsWrapper>
                <AcquiringDocumentsList>
                    {
                        acquiringDocuments.map((item) => {
                            return <AcquiringDocumentItem key={item.id}>
                                <AcquiringDocumentModal trigger={
                                    <AcquiringDocumentLink>
                                        {getAcquiringDocumentName(item.id, item.title)}
                                    </AcquiringDocumentLink>
                                }
                                                        documentId={item.id}
                                                        documentDefaultName={item.title}
                                />
                            </AcquiringDocumentItem>
                        })
                    }
                </AcquiringDocumentsList>
                <AcquiringTypesIcons>
                    <AcquiringTypeIconWrapper>
                        <MirColored/>
                    </AcquiringTypeIconWrapper>
                    <AcquiringTypeIconWrapper>
                        <VisaColored/>
                    </AcquiringTypeIconWrapper>
                    <AcquiringTypeIconWrapper>
                        <MastercardColored/>
                    </AcquiringTypeIconWrapper>
                </AcquiringTypesIcons>
            </DocumentsAndIconsWrapper>
        </Form>
    </PaymentFormStyled>;
}
