export class StringHelper {
    /**
     * Замена по шаблону, например:
     * '{prefix}/{locale}/messages.json', {prefix: "locales", locale: "ru-RU"}
     *
     * @param template
     * @param data
     */
    static strtr(template: string, data: any) {
        const pattern = /{\s*(\w+?)\s*}/g;
        return template.replace(pattern, (_, token) => data[token] || '');
    }

    static createShortName(fio: string) {
        const t = fio.split(' ');

        let shortFio = fio;

        const c = StringHelper.capitalizeFirstLetter;

        if (t.length === 3) {
            shortFio = c(t[0]) + ' ' + c(t[1].charAt(0)) + '. ' + c(t[2].charAt(0)) + '.';
        } else if (t.length === 2) {
            shortFio = c(t[0]) + ' ' + c(t[1].charAt(0)) + '.';
        }

        return shortFio;
    }

    static extractFirstAndMiddleName(fio: string, noMiddlewareCharacter?: boolean) {
        const t = fio.split(' ');

        let shortFio = fio;

        const c = StringHelper.capitalizeFirstLetter;

        if (t.length === 3) {
            shortFio = c(t[1]) + ' ' + c(t[2]) + ((noMiddlewareCharacter !== true) ? ' ' + c(t[0].charAt(0)) + '.' : '');
        } else if (t.length === 2) {
            shortFio = c(t[1]) + ' ' + c(t[0]);
        }

        return shortFio;
    }

    static extractFirstName(fio: string, addFamilyNameChar?: boolean) {
        const t = fio.split(' ');

        let shortFio = fio;

        const c = StringHelper.capitalizeFirstLetter;

        if (t.length === 3) {
            shortFio = c(t[1]);
        } else if (t.length === 2) {
            shortFio = c(t[1]);
        } else {
            shortFio = fio;
        }

        if (!shortFio) {
            // Если в ходе обработки что-то пошло не так и fio уничтожилось
            return fio;
        }

        if (shortFio !== fio && addFamilyNameChar === true) {
            shortFio = c(shortFio) + ' ' + c(shortFio[0].charAt(0)) + '.';
        }

        return shortFio;
    }

    /**
     * Заменить символы переноса строки на <br/>
     *
     * @param text
     */
    static replaceLineBreakTextChar(text: string) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    /**
     * Первый символ строки в верхний регистр
     *
     * @param text
     */
    static capitalizeFirstLetter(text: string) {
        if (text.length === 0) {
            return text;
        }

        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    static formatPhone(phoneString: string): string {
        const ruPhone = phoneString.match(/^7([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})$/);

        if (ruPhone) {
            return `+7 (${ruPhone[1]}) ${ruPhone[2]} ${ruPhone[3]} ${ruPhone[4]}`;
        }

        return phoneString;
    }
}
