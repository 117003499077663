import {ElementTypeEnum} from "./ElementTypeEnum";
import {useMemo} from "react";
import {Trans} from "@lingui/macro";

interface ElementNameProps {
    elementType: ElementTypeEnum;
}

export const ElementName: React.FC<ElementNameProps> = (props) => {
    const {elementType} = props;

    const elementName = useMemo(() => {
        switch (elementType) {
            case ElementTypeEnum.TITLE: {
                return <Trans>Заголовок</Trans>;
            }
            case ElementTypeEnum.TEXT: {
                return <Trans>Текст</Trans>;
            }
            case ElementTypeEnum.UNFOLD_BLOCK: {
                return <Trans>Разворачиваемый блок</Trans>;
            }
            case ElementTypeEnum.TEACHER_NOTES: {
                return <Trans>Заметка для учителя</Trans>;
            }
            case ElementTypeEnum.TEACHER_CAN_SAY: {
                return <Trans>Ещё учитель может сказать</Trans>;
            }
            case ElementTypeEnum.TEACHER_ALSO_DISCUSS: {
                return <Trans>Ещё можно обсудить</Trans>;
            }
            case ElementTypeEnum.MEDIA_PICTURE: {
                return <Trans>Картинка</Trans>;
            }
            case ElementTypeEnum.MEDIA_VIDEO: {
                return <Trans>Видео</Trans>;
            }
            case ElementTypeEnum.MEDIA_AUDIO: {
                return <Trans>Аудио</Trans>;
            }
            case ElementTypeEnum.MEDIA_YOUTUBE: {
                return <Trans>Видео из Youtube</Trans>;
            }
            case ElementTypeEnum.EXERCISES_RADIO: {
                return <Trans>Выбор одного из вариантов</Trans>;
            }
            case ElementTypeEnum.EXERCISES_CHECKBOX: {
                return <Trans>Выбор нескольких из вариантов</Trans>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX: {
                return <Trans>Заполнить пропуски из вариантов</Trans>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT: {
                return <Trans>Заполнить пропуски своим текстом</Trans>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP: {
                return <Trans>Заполнить пропуски перетаскиванием</Trans>;
            }
            case ElementTypeEnum.EXERCISES_MATCH: {
                return <Trans>Соединить пары</Trans>;
            }
            case ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT: {
                return <Trans>Исправить ошибку</Trans>;
            }
            case ElementTypeEnum.CLIPBOARD_PASTE: {
                return <Trans>Вставить из буфера</Trans>;
            }
            case ElementTypeEnum.DIALOG_BUBBLE: {
                return <Trans>Фраза участника истории</Trans>;
            }
            default: {
                return <Trans>Неизвестный элемент</Trans>;
            }
        }
    }, [elementType]);

    return <>{elementName}</>;
}
