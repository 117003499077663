import React, {forwardRef} from 'react';

import {Action, ActionProps} from './Action';
import {ReactComponent as SortSvg} from "../../../../Ui/Svg/Sort.svg";
import styled from "styled-components";
import classNames from "classnames";

const ActionStyled = styled(Action)<ActionProps>`
  cursor: grab;
`;

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
    (props, ref) => {
        const {className, ...propsWoClassName} = props;

        return (
            <ActionStyled
                ref={ref}
                data-cypress="draggable-handle"
                className={classNames("handle-btn", className)}
                {...propsWoClassName}
            >
                <SortSvg/>
            </ActionStyled>
        );
    }
);