import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Sortable, SortableItemComponent} from "../../../../../components/Sortable/Sortable";
import {LessonItem} from "./LessonItem";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {t, Trans} from "@lingui/macro";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {CreateNewLessonItem} from "./CreateNewLessonItem";
import {Notice} from "../../../../../components/Ui/Elements/Notice";
import {LoadMoreLessonItem} from "./LoadMoreLessonItem";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {DtoTmLesson} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";

const LessonsListComponentWrapper = styled.div`
`;

const NoticeStyled = styled(Notice)`
  margin-top: 18px;
`;

const SortableWrapper = styled.div`
  margin-bottom: 10px;
`;

interface LessonsListProps {
    sectionId: string;
    lessons: DtoTmLesson[] | null;
    setLessons: React.Dispatch<React.SetStateAction<DtoTmLesson[] | null>>;
    selectedLessonId: string | null;
    setSelectedLessonId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const LessonsList: React.FC<LessonsListProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [paginationPagesLoaded, setPaginationPagesLoaded] = useState<number>(0);
    const [totalLessonsItems, setTotalLessonsItems] = useState<number>(0);

    const [savingOrderResultNow, setSavingOrderResultNow] = useState<boolean>(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {sectionId, lessons, setLessons, selectedLessonId, setSelectedLessonId} = {...props};

    const loadContent = useCallback((page?: number) => {
        if (!sessionToken) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        if (page === undefined) {
            page = 1;
        }

        httpApiClient.tmGetLessons(sessionToken, sectionId, page, 200)
            .then((data) => {
                setLessons((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setPaginationPagesLoaded(data.data.page);
                setTotalLessonsItems(data.data.totalCount);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_LESSONS_API, 'Error on loading lessons list: ', err);

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [sessionToken, httpApiClient, sectionId, setLessons, logger]);

    const reorderItems = (from: number, to: number) => {
        if ((lessons === null) || (sessionToken === null)) {
            return;
        }

        const firstItemId = lessons[from].id;
        const secondItemId = lessons[to].id;

        const newLevelsArray = arrayMove(lessons, from, to);

        setLessons(newLevelsArray);

        setSavingOrderResultNow(true);

        httpApiClient.tmSwapLessonOrderPosition(sessionToken, firstItemId, secondItemId)
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.TM_LESSONS_API,
                    'Error on reorder lessons list: ',
                    err
                );

                setLessons(arrayMove(newLevelsArray, to, from));

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок уроков. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                setSavingOrderResultNow(false);
            });
    }

    // Сброс уроков при изменении выбранной секции
    useEffect(() => {
        setLessons(() => null);

    }, [sectionId, setLessons]);

    // Загрузка уроков при сбросе списка уроков
    useEffect(() => {
        if (lessons === null) {
            loadContent(1);
        }
    }, [lessons, loadContent]);

    const lessonOnClick = (itemId: string) => {
        setSelectedLessonId(itemId);
    }

    const items = useMemo(() => {
        if (lessons === null) {
            return [];
        }

        return lessons?.map((item): SortableItemComponent<DtoTmLesson> => {
            return {
                id: item.id,
                selected: (selectedLessonId === item.id),
                disabled: savingOrderResultNow,
                item: item
            }
        });

    }, [lessons, selectedLessonId, savingOrderResultNow]);

    const levelsList = () => {
        if (nowLoading) {
            return <DefaultLoader/>;
        }

        return <LessonsListComponentWrapper>
            <SortableWrapper>
                <Sortable<DtoTmLesson>
                    items={items}
                    reorderItems={(array, from, to) => {
                        reorderItems(from, to);
                    }}
                    renderItemContent={(item, index) => {
                        return <LessonItem item={item}
                                           index={index}
                                           onSelect={() => lessonOnClick(item.item.id)}/>
                    }}
                />
            </SortableWrapper>

            {((props.lessons !== null) && (totalLessonsItems > props.lessons.length)) ?
                <LoadMoreLessonItem onClick={() => loadContent(paginationPagesLoaded + 1)}/> : null}

            <CreateNewLessonItem sectionId={props.sectionId}
                                 onItemCreated={() => props.setLessons(null)}/>

            {(((props.lessons?.length ?? 0) > 1)) ? <NoticeStyled>
                <Trans>Порядок уроков имеет значение. Сверху нужно разместить первые уроки секции, а внизу -
                    завершающие.</Trans>
            </NoticeStyled> : null}

        </LessonsListComponentWrapper>
    }

    return (
        <div>
            {
                (loadingError)
                    ? <ErrorLoadingContent
                        title={t`Не удалось загрузить список уроков`}
                        retryBtnClick={() => loadContent()}
                    />
                    : levelsList()
            }

        </div>
    );
}