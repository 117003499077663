import {instanceCachingFactory} from 'tsyringe';
import {ICustomStorage} from "../components/CustomStorage/ICustomStorage";
import {CustomStorage} from "../components/CustomStorage/CustomStorage";
import {ILogger} from "../components/Logger/ILogger";
import {DiTokens} from "./DiTokens";

export default instanceCachingFactory<ICustomStorage>((container) => {
    const customStorage = new CustomStorage(container.resolve<ILogger>(DiTokens.LOGGER));

    customStorage.selectLastUserStorage();

    return customStorage;
});
