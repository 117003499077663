import React from "react";
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import TeachingMaterialsLevelDetailsSection from "../../../../components/TeachingMaterialsCatalog/level-details/index";
import {ApplicationState} from "../../../../../store";
import {withRouter, WithRouterProps} from "../../../../components/WithRouterHoc";
import {connect} from "react-redux";
import {RoutesList} from "../../../../RoutesList";
import {PageBackLink} from "../../../../components/Ui/Elements/PageBackLink";

interface RouterParams {
    tmLevelId: string;
}

type TeachingMaterialsLevelDetailsPagePropsWRouter = WithRouterProps<RouterParams>; //& TeachingMaterialsLevelDetailsPageProps;

interface TeachingMaterialsLevelDetailsPageState {
}

class TeachingMaterialsLevelDetailsPage extends React.Component<TeachingMaterialsLevelDetailsPagePropsWRouter, TeachingMaterialsLevelDetailsPageState> {
    componentDidMount() {
        if (this.props.match.params.tmLevelId.trim() === "") {
            this.props.navigate(RoutesList.MAIN_PAGE);

            return;
        }
    }

    render() {
        return <div>
            <PageBackLink link={RoutesList.TEACHER_TEACHING_MATERIALS}/>
            <PageTitle><Trans>Учебные материалы</Trans></PageTitle>
            {
                (this.props.match.params.tmLevelId.trim() !== "")
                && <TeachingMaterialsLevelDetailsSection levelId={this.props.match.params.tmLevelId}/>
            }
        </div>
    }
}

const mapStateToProps = ({user}: ApplicationState) => ({
    apiToken: user.sessionToken,
});

export default withRouter(connect(mapStateToProps)(TeachingMaterialsLevelDetailsPage));