import * as React from "react";
import {BlockWithBorder} from "../../../../../styles/global-elements";
import styled from "styled-components";
import {
    DtoTmOrganization
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";

interface OrganizationItemStyledProps {
    active: boolean
}

export const OrganizationItemStyled = styled(BlockWithBorder)<OrganizationItemStyledProps>`
  padding: ${p => (p.active) ? "9px 14px" : "10px 15px"};
  border-style: solid;
  border-width: ${p => (p.active) ? "2px" : "1px"};
  border-color: ${p => (p.active) ? p.theme.colors.backgroundInverse : p.theme.colors.accentDivider};
  min-width: 100px;
  min-height: 50px;
  max-width: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  user-select: none;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.backgroundInverse};
  }
`;

const OrganizationItemName = styled.span`
  font-size: 14px;
  white-space: nowrap;
`;

interface OrganizationItemProps {
    active: boolean;
    item: DtoTmOrganization;
    onClick: () => void;
}

export const OrganizationItem: React.FC<OrganizationItemProps> = (props) => {
    return (
        <OrganizationItemStyled active={props.active} onClick={props.onClick}>
            <OrganizationItemName>{props.item.name}</OrganizationItemName>
        </OrganizationItemStyled>
    );
}