import classNames from "classnames";
import {SlideEditor, SlideEditorRefMethods} from "../../../../../components/SlideEditor";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import React, {CSSProperties, forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import styled from "styled-components";
import {SaveState} from "./index";
import {EditorData} from "../../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {cloneDeep, isEqual} from "lodash";
import {EditorContext, IEditorContext} from "../EditorContext";

const EditorAreaStyled = styled.div`
  //flex-grow: 1;

  &.disabled {
    pointer-events: none;
  }
`;

const SlateEditorWrapper = styled.div`
  transition: opacity 0.3s ease;

  &.disabled {
    opacity: 0.5;
  }
`;

export interface EditorAreaProps {
    contentSaveState: SaveState;
    style?: CSSProperties;
}

export interface EditorAreaRefMethods extends SlideEditorRefMethods {
}

export const EditorArea = forwardRef<EditorAreaRefMethods, EditorAreaProps>(
    (props, ref) => {
        const {contentSaveState} = props;

        const slideEditorRef = useRef<SlideEditorRefMethods>(null);

        const editorContext = useContext<IEditorContext>(EditorContext);

        const {slidesDraftContent, setSlidesDraftContent, selectedSlideId} = editorContext;

        useImperativeHandle(ref, () => ({
            putElement: element => {
                slideEditorRef.current?.putElement(element);
            },
            reloadInitialData: (newData) => {
                slideEditorRef.current?.reloadInitialData(newData);
            },
            getExercisesCount: () => {
                if (!slideEditorRef.current) {
                    return [];
                }

                return slideEditorRef.current.getExercisesCount();
            }
        }));

        const contentOnChange = useCallback((content: EditorData) => {
            if (selectedSlideId === null) {
                return;
            }

            if (slidesDraftContent[selectedSlideId] !== undefined) {
                // Если данные уже были, сравним - есть ли изменения
                // с предыдущим состоянием
                if (isEqual(slidesDraftContent[selectedSlideId], content)) {
                    return;
                }
            }

            setSlidesDraftContent((slidesDraftContent) => {
                const newSlidesEditorContent = cloneDeep(slidesDraftContent);

                newSlidesEditorContent[selectedSlideId] = cloneDeep(content);
                return (newSlidesEditorContent);
            });
        }, [selectedSlideId, setSlidesDraftContent, slidesDraftContent]);

        return <EditorAreaStyled className={classNames((contentSaveState === SaveState.IN_PROCESS) && "disabled")}
                                 style={props.style}>
            <SlateEditorWrapper className={classNames((contentSaveState === SaveState.IN_PROCESS) && "disabled")}>
                <SlideEditor ref={slideEditorRef}
                             contentOnChange={contentOnChange}/>
            </SlateEditorWrapper>
            {(contentSaveState === SaveState.IN_PROCESS) && <DefaultLoader/>}
        </EditorAreaStyled>
    })