import React, {useMemo} from "react";
import styled from "styled-components";
import {ReactComponent as ImagePlaceholderSvg} from "../../../Ui/Svg/AudioPlaceholder.svg";
import {Trans} from "@lingui/macro";
import {SlideAudioMimeSupported} from "../../../../../enums/UserFileEnums";
import classNames from "classnames";

const AudioPlaceholderStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 10px;
  text-align: center;
`;

const FirstWrapper = styled.div`
  border-radius: 5px;
  border: 1px dashed ${({theme}) => theme.colors.accentDivider};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.with-input {
    cursor: pointer;
  }
`;

const SecondWrapper = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 5px;
`;

const IconWrapper = styled.div``;

const ImagePlaceholder = styled(ImagePlaceholderSvg)`
    height: 50px;
`;

const TextWrapper = styled.div`

`;

interface AudioPlaceholderProps {
    onNewFileSelect?: (file: File) => void;
}

export const AudioPlaceholder: React.FC<AudioPlaceholderProps> = (props) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onNewFileSelect === undefined) {
            return;
        }

        if ((event.target.files) && (event.target.files[0])) {
            props.onNewFileSelect(event.target.files[0]);
        }
    };

    const supportedMimeTypes = useMemo<string>(() => {
        const arr = Object.values(SlideAudioMimeSupported);

        let stringList = "";

        arr.forEach((item) => {
            stringList += (stringList === "" ? '' : ',') + item;
        });

        return stringList;
    }, []);

    const withInput = useMemo<boolean>(() => {
        return props.onNewFileSelect !== undefined;
    }, [props.onNewFileSelect]);

    return <AudioPlaceholderStyled>
        <FirstWrapper onClick={handleClick} className={classNames(withInput && "with-input")}>
            <SecondWrapper>
                <IconWrapper>
                    <ImagePlaceholder/>
                </IconWrapper>
                {
                    (withInput) && <TextWrapper>
                        <Trans>Нажмите, чтобы выбрать аудиофайл</Trans>
                    </TextWrapper>
                }
            </SecondWrapper>
        </FirstWrapper>
        {
            (withInput)
            && <input type="file"
                      accept={supportedMimeTypes}
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{display: 'none'}}
            />
        }
    </AudioPlaceholderStyled>
}