import {IsNotEmpty, IsString,} from 'class-validator';
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO параметров сообщения - результата запроса на запуск онлайн комнаты.
 */
export class DtoTeacherStartOnlineLessonResult implements IBaseApiDto {
    @IsNotEmpty()
    @IsString()
    lessonId: string;
}
