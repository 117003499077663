import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {CityName} from "../../../../../components/Ui/Elements/TextPresenter/CityName";

const Wrapper = styled.div`
    display: flex;
    color: ${({theme}) => theme.colors.textTertiary};
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        font-size: 15px;
    }
`;

const DateLine = styled.div`
    display: none;

    @media (${({theme}) => theme.media.small}) {
        display: block;
    }

    &::first-letter {
        text-transform: capitalize;
    }
`;

const TimeLine = styled.div`

`;


export const CurrentTimeLine: React.FC = () => {

    const [currentTs, setCurrentTs] = useState<Date>(new Date());

    const dateHelperService = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);

    useEffect(() => {
        const timer = setInterval(() => setCurrentTs(new Date()), 10000);

        return () => {
            clearInterval(timer)
        }
    }, []);

    const dateLine = useMemo(() => {
        return dateHelperService.formatAsDateWithWeek(currentTs)
    }, [currentTs, dateHelperService]);

    const timeString = useMemo(() => {
        return dateHelperService.formatAsTime(currentTs);
    }, [currentTs, dateHelperService]);

    const timezoneCityName = useMemo(() => {
        return dateHelperService.getCityFromTimezoneName(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }, [dateHelperService]);

    return <Wrapper>
        <DateLine>{dateLine},&nbsp;</DateLine>
        <TimeLine>{timeString}, <CityName engName={timezoneCityName}></CityName></TimeLine>
    </Wrapper>
}