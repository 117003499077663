import React from "react";
import {ElementData, ElementStateEnum} from "../../../SlidePlayerEditorCommonParts/components/Audio/Common";
import {UploadProcessDetails} from "../../../../../store/uploadQueue/type";

export interface IElementContext {
    elementData: ElementData;
    elementState: ElementStateEnum;
    currentFileInUploadQueue: UploadProcessDetails | null,
    onChangeElementData: (newValue: ElementData) => void;
    setElementState: React.Dispatch<React.SetStateAction<ElementStateEnum>>;
}

export const ElementContext = React.createContext<IElementContext>({
    elementData: {
        audioId: null,
    },
    elementState: ElementStateEnum.WAIT_INIT,
    currentFileInUploadQueue: null,
    onChangeElementData: () => {
    },
    setElementState: () => {
    }
});

export const ElementContextProvider = ElementContext.Provider;