import * as React from "react";
import {forwardRef} from "react";
import {InputProps} from "antd/lib/input";
import {InputRef} from "antd/lib/input/Input";
import {Input} from "./TextInput";
import styled from "styled-components";
import {RegularFontSize} from "../../../styles/global-elements";

const Wrapper = styled.div`
  position: relative;
`;

const RoubleSymbol = styled.div`
  ${RegularFontSize};
  
  position: absolute;
  right: 8px;
  top: 0;
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};
`;

const InputStyled = styled(Input)`
    padding-right: 25px;
`;

export const MoneyInput: React.FunctionComponent<InputProps & React.RefAttributes<InputRef>> = forwardRef<InputRef, InputProps>(
    ({...props}, ref) => {
        return <Wrapper>
            <InputStyled ref={ref} autoComplete="false" type={"number"} {...props}/>
            <RoubleSymbol>&#8381;</RoubleSymbol>
        </Wrapper>
    });
