export enum LoggerSectionsEnum {
    APPLICATION = 'application',
    START_APPLICATION = 'start_application',
    INTERNET_CONNECTION_CHECKER = 'internet_connection_checker',
    LOCALE_SERVICE = 'locale_service',
    THEME_SERVICE = 'theme_service',
    DEVICE_ORIENTATION = 'device_orientation',
    UNKNOWN_API_RESPONSE_ERROR = 'unknown_api_response_error',
    LOAD_USER_DATA_PROCESS = 'load_user_data_process',
    UPDATE_ACTIVE_AGREEMENT_ID = 'update_active_agreement_id',
    TM_ORGANIZATIONS_API = 'tm_organizations_api',
    TM_DISCIPLINES_API = 'tm_disciplines_api',
    TM_DISCIPLINE_LEVELS_API = 'tm_discipline_levels_api',
    TM_LEVEL_SECTIONS_API = 'tm_level_sections_api',
    TM_LESSONS_API = 'tm_lessons_api',
    TM_SLIDES_API = 'tm_slides_api',
    USER_FILES_API = 'user_files_api',
    SELF_STUDY_TRACK_API = 'self_study_track_api',
    SELF_STUDY_TRACK_EPISODE_SURVEY = 'self_study_track_episode_survey',
    SELF_STUDY_TRACK_SUBSCRIBE_PAGE = 'self_study_track_subscribe_page',
    USER_MERGE_PROFILE = 'user_merge_profile',
    SAVE_DEVICE_INFO = 'save_device_info',
    SUBJECT_AREA_API = 'subject_area_api',
    TM_AUDIO_PLAYER = 'tm_audio_player',
    TM_VIDEO_PLAYER = 'tm_video_player',
    TM_IMAGE_LOADER = 'tm_image_loader',
    KNOWLEDGE_BASE_API = 'knowledge_base_api',
    STONLINE_STUDENTS_LIST_API = 'stonline_students_list_api',
    STONLINE_GROUPS_LIST_API = 'stonline_groups_list_api',
    STONLINE_LESSONS_API = 'stonline_lessons_api',
    STONLINE_TEACHERS_API = 'stonline_teachers_api',
    STONLINE_STUDENTS_API = 'stonline_students_api',
    STONLINE_PAYMENTS_API = 'stonline_payments_api',
    STONLINE_TIMEZONES_API = 'stonline_timezones_api',
    STONLINE_TRAINING_PRROGRAM_API = 'stonline_training_program_api',
    STONLINE_SCHEDULE_API = 'stonline_schedule_api',
    STONLINE_CREATE_AGREEMENT_API = 'stonline_create_agreement_api',
    MODERATOR_PANEL = 'moderator_panel',
    CLIPBOARD_HELPER = 'clipboard_helper',
    STUDENT_PROFILE_LK_SECTION = 'student_profile_lk_section',
    STUDENT_PROFILE_AGREEMENTS_SECTION = 'student_profile_agreements_section',
    STUDENT_PROFILE = 'student_profile',
    LESSON_MATERIALS = 'lesson_materials',
    LESSON_ROOMS = 'lesson_rooms',
    LESSON_ROOM = 'lesson_room',
    LESSON_ROOM_SCREEN_ORIENTATION_PUBLISHER = 'lesson_room_screen_orientation_publisher',
    LESSON_AND_HOMEWORK = 'lesson_and_homework',
    HOMEWORK_ROOM = 'homework_room',
    VIDEO_ROOM_VIDEO_ITEM = 'video_room_video_item',
    SETTINGS_PAGE = 'settings_page',
    PAYMENT_MODAL = 'payment_modal',
    ACCESS_TO_CAMERA = 'access_to_camera',
    SLIDES_WORK_DATA_SAVE_QUEUE = 'slides_work_data_save_queue',
    SLIDES_WORK_DATA_LOAD_QUEUE = 'slides_work_data_load_queue',
    STUDENT_PAYMENTS_LIST = 'student_payments_list',
    BROWSER_TIMEZONE = 'browser_timezone',
    INITIAL_CUSTOMIZER = 'initial_customizer',
    PRELOAD_HELPER = 'preload_helper',
    SLIDE_INTERACTIVITY_CONFIG_MODAL = 'slideInteractivityModalConfig',
    OTP_REQUEST_MODAL = 'otp_request_modal',
    UTM_COLLECTOR = 'utm_collector',
    UTM_SAVER = 'utm_saver',
    SELF_STUDY_ADMIN_OVERVIEW = 'self_study_admin_overview',

    CREATE_DEMO_STUDENT_LK_TOKEN = 'create_demo_student_lk_token',

    WS_COMPONENT = 'ws_component',
    WS_STREAM_EVENT = 'ws_stream_event',
    WS_DISCONNECTOR = 'ws_disconnector',
    JANUS_CONNECTION = 'janus_connection',
    JANUS_AUDIOROOM_PLUGIN = 'audioroom_plugin',
    JANUS_VIDEOROOM_PLUGIN = 'videoroom_plugin',
    JANUS_VIDEO_ROOM_SUBSCRIBER = 'video_room_subscriber',
    JANUS_VIDEO_ROOM_PUBLISHER = 'video_room_publisher',
    VIDEO_ROOM_STREAMS_STORE = 'video_room_streams_store',
    VIDEO_ROOM_SUBSCRIPTIONS_MANAGER = 'video_room_subscriptions_manager',

    STREAM_STORE = 'stream_store',

    VCS_VIDEO_ITEM = 'vcs_video_item',

    AVATAR_UPLOADER = 'avatar_uploader',

    SOUND_PLAYER = 'sound_player',

    SCHOOL_STUDENT_MAIN_PAGE = 'school_student_main_page',
    FREE_STUDENT_MAIN_PAGE = 'free_student_main_page',

    ACQUIRING_DOCUMENT_MODAL = 'acquiring_document_modal',

    ABOUT_SCHOOL_PAGE = 'about_school_page',

    MEDIA_DEVICE_SERVICE = 'media_device_service',
    DEVICE_MEDIA_STREAM_FETCHER = 'device_media_stream_fetcher',

    VIDEO_ROOM_AUDIO_ELEMENT = 'video_room_audio_element',

    CUSTOM_STORAGE = 'custom_storage',
    PAGES_BROADCAST_SERVICE = 'pages_broadcast_service',
    SCREEN_SAVER_LOCK = 'screen_saver_lock',

    BROWSER_AUDIO_CONTEXT = 'browser_audio_context',

    VIDEO_CALL_TEST = 'video_call_test',

    SLIDE_INTERACTIVITY = 'slide_interactivity'
}
