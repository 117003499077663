import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {i18n} from '@lingui/core'
import {useNavigate, useOutletContext} from "react-router-dom";
import {OutletContext} from "../index";
import {RoutesList} from "../../../RoutesList";
import {AnimatedContainer} from "./AnimatedContainer";
import {container} from 'tsyringe';
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {BaseResponseDto} from "../../../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {ICustomStorage} from "../../../../components/CustomStorage/ICustomStorage";
import {StorageKeysEnum} from "../../../../enums/StorageKeysEnum";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {CreateDemoStudentDto} from "../../../../components/HttpApiClient/ApiDto/Response/Demo/CreateDemoStudentDto";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {matchPath} from "react-router";


const Wrapper = styled.div`
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export enum SituationCodeEnum {
    PREPARING = 0,
    WAIT_FOR_RESPONSE = 1,
    SUCCESS = 2,
    ERROR = 3
}

export const StudentDemoForm: React.FC = () => {
    const [situationCode, setSituationCode] = useState<SituationCodeEnum>(SituationCodeEnum.PREPARING);
    const [authLocation, setAuthLocation] = useState<string | null>(null);  

    const outletContext = (useOutletContext() as OutletContext);
    const navigate = useNavigate();

    useEffect(() => {
        if ((situationCode === SituationCodeEnum.PREPARING) || (situationCode === SituationCodeEnum.WAIT_FOR_RESPONSE)) {
            outletContext.setLoader(true, i18n._(t`Пример личного кабинета ученика подготавливается...`));
        } else {
            outletContext.setLoader(false);
        }
    }, [situationCode, outletContext]);

    const prepareDemoLkAndGo = useCallback(async (): Promise<void> => {
        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const storage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setSituationCode(SituationCodeEnum.PREPARING);

        // Проверяем - нет ли токена в storage
        let demoAuthToken = await storage.getItem(StorageKeysEnum.DEMO_STUDENT_LK_TOKEN);
        let apiResult: BaseResponseDto<CreateDemoStudentDto> | null = null;

        if (!demoAuthToken) {
            setSituationCode(SituationCodeEnum.WAIT_FOR_RESPONSE);

            // Запросим authToken в API
            try {
                apiResult = await apiClient.requestDemoStudentLk();
            } catch (e) {
                setSituationCode(SituationCodeEnum.ERROR);

                if (e instanceof NoConnection) {
                    return;
                }

                logger.error(
                    LoggerSectionsEnum.CREATE_DEMO_STUDENT_LK_TOKEN,
                    'Error on fetch new token: ',
                    e
                );

                return;
            }

            if (apiResult === null || !apiResult.data.authLink) {
                logger.error(
                    LoggerSectionsEnum.CREATE_DEMO_STUDENT_LK_TOKEN,
                    'apiResult is null after requestDemoStudentLk from api: ',
                    apiResult
                );

                setSituationCode(SituationCodeEnum.ERROR);

                return;
            }

            const authUrl = new URL(apiResult.data.authLink);

            const routeParams = matchPath(
                {
                    path: RoutesList.LOGIN_VIA_TOKEN
                },
                authUrl.pathname
            );

            if (!routeParams || !routeParams.params.token) {
                logger.error(
                    LoggerSectionsEnum.CREATE_DEMO_STUDENT_LK_TOKEN,
                    'Error get token from auth link. Received auth link: ',
                    apiResult.data.authLink
                );

                setSituationCode(SituationCodeEnum.ERROR);

                return;
            }

            demoAuthToken = routeParams.params.token;

            await storage.setItem(
                StorageKeysEnum.DEMO_STUDENT_LK_TOKEN,
                demoAuthToken
            );
        }

        setAuthLocation(RoutesHelper.replaceParams(
            RoutesList.LOGIN_VIA_TOKEN,
            [
                {
                    key: 'token',
                    value: demoAuthToken
                }
            ]
        ));

        setSituationCode(SituationCodeEnum.SUCCESS);
    }, []);

    useEffect(() => {
        if (authLocation !== null) {
            navigate(authLocation);
        }
    }, [authLocation, navigate]);
    
    useEffect(() => {
        prepareDemoLkAndGo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const errorText = useMemo(() => {
        return <Trans>Возможно что-то с интернетом. Проверьте связь и попробуйте ещё раз.</Trans>
    }, []);

    const enterWithPassBtn = useMemo(() => {
        return <Button
            btnStyle={BtnStyleEnum.Primary}
            onClick={prepareDemoLkAndGo}>
            <Trans>Повторить попытку</Trans>
        </Button>
    }, [prepareDemoLkAndGo]);

    const content = () => {
        if (situationCode !== SituationCodeEnum.SUCCESS) {
            return (
                <Wrapper>
                    <InputsWrapper>
                        <h2 style={{marginBottom: "1.5em"}}><Trans>Ошибка автоматического входа</Trans></h2>
                        <p>{errorText}</p>
                        <p style={{fontSize: "11px", color: "gray"}}>
                            <Trans id="Код ситуации: {situationCode}" values={{situationCode: situationCode}}/>
                        </p>
                    </InputsWrapper>
                    <ButtonsWrapper>
                        <ButtonItem>
                            {enterWithPassBtn}
                        </ButtonItem>
                    </ButtonsWrapper>
                </Wrapper>
            );
        }

        return <div/>;
    }

    return (
        <AnimatedContainer>
            {content()}
        </AnimatedContainer>
    );
}