import * as React from "react";
import {useCallback, useEffect, useLayoutEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {useNavigate, useOutletContext} from "react-router-dom";
import {OutletContext} from "../index";
import {AnimatedContainer} from "./AnimatedContainer";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {UserProfileLoadState, UserProfileType} from "../../../../store/user/type";
import {appInitializationStateSelector, sessionTokenSelector} from "../../../../store/app/selector";
import {userIsTeacher} from "../../../../store/user/selector";
import {useParams} from "react-router";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {RoutesList} from "../../../RoutesList";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {NotFoundException} from "../../../../components/HttpClient/exception/NotFoundException";
import {LinkAsButton} from "../../../components/Ui/Elements/LinkAsButton";
import {RequestValidationError} from "../../../../components/HttpApiClient/Exception/RequestValidationError";
import * as UserActionCreators from "../../../../store/user/actions";
import {ICustomStorage} from "../../../../components/CustomStorage/ICustomStorage";
import {StorageKeysEnum} from "../../../../enums/StorageKeysEnum";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {AppInitializationStateTypes} from "../../../../store/app/type";
import {Ii18nService} from "../../../../services/i18n/Ii18nService";


const Wrapper = styled.div`
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export enum PageLoadingStateEnum {
    PREPARING = 0,
    CREATING_ACCOUNT = 1,
    CREATING_SUBSCRIPTION = 2,
    ERROR_TEACHER_FOUND = 3,
    ERROR_UNKNOWN = 4,
    NOT_FOUND_TRACK_BY_ID = 5
}

export const SelfStudyStart: React.FC = () => {
    const {trackId} = useParams();
    const dispatch = useDispatch();

    const outletContext = (useOutletContext() as OutletContext);
    const navigate = useNavigate();

    const appAuthState = useSelector<ApplicationState>(({user}: ApplicationState) => user.authState) as UserProfileLoadState;
    const appInitState = useSelector(appInitializationStateSelector);
    const storeSessionToken = useSelector(sessionTokenSelector);
    const isTeacher = useSelector(userIsTeacher);
    const httpApiClient = useMemo(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);
    const logger = useMemo(() => container.resolve<ILogger>(DiTokens.LOGGER), []);
    const keyValueStorage = useMemo(() => container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE), []);
    const i18nService = useMemo(() => container.resolve<Ii18nService>(DiTokens.I18N_SERVICE), []);

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(() => {
        if (!trackId) {
            return PageLoadingStateEnum.NOT_FOUND_TRACK_BY_ID;
        }

        if (appAuthState === UserProfileLoadState.AUTH_ERROR) {
            return PageLoadingStateEnum.ERROR_UNKNOWN;
        } else if (appAuthState === UserProfileLoadState.AUTHORIZED && isTeacher) {
            return PageLoadingStateEnum.ERROR_TEACHER_FOUND;
        }

        return PageLoadingStateEnum.PREPARING;
    });

    const prepareSubscription = useCallback(async () => {
        if (!trackId) {
            setPageLoadingState(PageLoadingStateEnum.ERROR_UNKNOWN);

            return;
        }

        const startLoadingUserProfile = () => dispatch(UserActionCreators.startLoadingUserProfile());
        const setUserProfileType = (profileType: UserProfileType) => dispatch(UserActionCreators.setUserProfileType(profileType));
        const setSessionToken = (token: string) => dispatch(UserActionCreators.setUserSessionToken(token));

        try {
            let sessionToken = '';

            if (storeSessionToken) {
                sessionToken = storeSessionToken;
            } else {
                // Требуется и временная авторизация
                const result = await httpApiClient.registerTemp(i18nService.locale());
                sessionToken = result.data.sessionToken;
                setUserProfileType(UserProfileType.STUDENT);
                setSessionToken(result.data.sessionToken);
                await keyValueStorage.setItem(StorageKeysEnum.MERGE_TOKEN, result.data.mergeToken);
            }

            const subscriptionOverview = await httpApiClient.subscribeToTrack(sessionToken, trackId);

            startLoadingUserProfile();

            navigate(
                RoutesHelper.replaceParams(
                    RoutesList.STUDENT_SELF_STUDY_TRACK_EPISODE,
                    [
                        {
                            key: 'episodeId',
                            value: subscriptionOverview.data.lastEpisodeId
                        }
                    ]
                )
            );
        } catch (err) {
            outletContext.setLoader(false);

            if (
                (err instanceof NotFoundException)
                || (err instanceof RequestValidationError)
            ) {
                setPageLoadingState(PageLoadingStateEnum.NOT_FOUND_TRACK_BY_ID);

                return;
            }

            setPageLoadingState(PageLoadingStateEnum.ERROR_UNKNOWN);

            if (err instanceof NoConnection) {
                return;
            }

            logger.error(
                LoggerSectionsEnum.SELF_STUDY_TRACK_SUBSCRIBE_PAGE,
                `Error on subscribe to self-study track with id ${trackId}: `,
                err
            );
        }
    }, [dispatch, httpApiClient, i18nService, keyValueStorage, logger, navigate, outletContext, storeSessionToken, trackId]);

    useLayoutEffect(() => {
        if (
            (pageLoadingState === PageLoadingStateEnum.NOT_FOUND_TRACK_BY_ID)
            || (pageLoadingState === PageLoadingStateEnum.ERROR_UNKNOWN)
            || (pageLoadingState === PageLoadingStateEnum.ERROR_TEACHER_FOUND)
        ) {
            outletContext.setLoader(false);
        } else {
            outletContext.setLoader(true, t`Готовим для вас материалы....`);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLoadingState]);

    useEffect(() => {
        if (appInitState === AppInitializationStateTypes.COMPLETED) {
            prepareSubscription();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appInitState]);

    const errorText = useMemo(() => {
        if (pageLoadingState === PageLoadingStateEnum.ERROR_TEACHER_FOUND) {
            return <Trans>Вы зашли как учитель. Откройте ссылку в режиме «Инкогнито» или зайдите как ученик.</Trans>
        }

        if (pageLoadingState === PageLoadingStateEnum.NOT_FOUND_TRACK_BY_ID) {
            return <Trans>Не удалось найти курс по этой ссылке.</Trans>
        }

        return <Trans>Возможно что-то с интернетом. Проверьте связь и попробуйте ещё раз.</Trans>
    }, [pageLoadingState]);

    const enterWithPassBtn = useMemo(() => {
        if (pageLoadingState === PageLoadingStateEnum.ERROR_TEACHER_FOUND) {
            return <LinkAsButton
                btnStyle={BtnStyleEnum.Primary}
                to={RoutesList.MAIN_PAGE}>
                <Trans>Перейти в свой профиль</Trans>
            </LinkAsButton>
        }

        return <Button
            btnStyle={BtnStyleEnum.Primary}
            onClick={() => window.location.reload()}>
            <Trans>Повторить попытку</Trans>
        </Button>
    }, [pageLoadingState]);

    const content = () => {
        if (pageLoadingState !== PageLoadingStateEnum.PREPARING) {
            return (
                <Wrapper>
                    <InputsWrapper>
                        <h2 style={{marginBottom: "1.5em"}}><Trans>Не удалось выполнить</Trans></h2>
                        <p>{errorText}</p>
                        <p style={{fontSize: "11px", color: "gray"}}>
                            <Trans id="Код ситуации: {situationCode}" values={{situationCode: pageLoadingState}}/>
                        </p>
                    </InputsWrapper>
                    <ButtonsWrapper>
                        <ButtonItem>
                            {enterWithPassBtn}
                        </ButtonItem>
                    </ButtonsWrapper>
                </Wrapper>
            );
        }

        return <div/>;
    }

    return (
        <AnimatedContainer>
            {content()}
        </AnimatedContainer>
    );
}