import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t, Trans} from "@lingui/macro";
import {Descendant} from "slate";

const UnfoldBlockStyle = styled.div`
  padding: 10px 0;
`

const TitleChapterWrapper = styled.div`
  margin-bottom: 20px;
`;

const BodyChapterWrapper = styled.div`
`;

const ChapterTitle = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
`;

type UnfoldBlockType = {
    title: string | Descendant[];
    body: string | Descendant[];
}

enum Elements {
    TITLE,
    BODY
}

interface UnfoldBlockProps extends ItemPropsInterface<UnfoldBlockType> {
}

interface UnfoldBlockRefMethods extends ItemRefMethodsInterface {
}

export const UnfoldBlock = forwardRef<UnfoldBlockRefMethods, UnfoldBlockProps>(
    (props, ref) => {

        const {initialData} = props;

        const richTextEditorTitleRef = useRef<RichTextEditor>(null);
        const richTextEditorBodyRef = useRef<RichTextEditor>(null);

        const [lastFocusedElement, setLastFocusedElement] = useState<Elements>(Elements.TITLE);

        const [state, setState] = useState<UnfoldBlockType>(() => {
            if (initialData === null) {
                return {
                    title: "",
                    body: ""
                }
            }

            return {
                title: initialData.title,
                body: initialData.body,
            }
        });

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                switch (lastFocusedElement) {
                    case Elements.BODY: {
                        richTextEditorBodyRef.current?.toolbarItemOnToggle(buttonType, newValue);

                        break;
                    }
                    case Elements.TITLE:
                    default: {
                        richTextEditorTitleRef.current?.toolbarItemOnToggle(buttonType, newValue);

                        break;
                    }
                }
            },
            getExercisesCount: () => {
                return 0;
            }
        }));

        useEffect(() => {
            props.onChange(state);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state]);

        const onChange = useCallback((title: string | Descendant[] | null, body: string | Descendant[] | null) => {
            setState((currentState) => {
                return {
                    title: title ?? currentState.title,
                    body: body ?? currentState.body
                };
            });
        }, [setState]);

        return <UnfoldBlockStyle>
            <TitleChapterWrapper>
                <ChapterTitle><Trans>Заголовок блока</Trans></ChapterTitle>
                <RichTextEditor editorMode={RichTextEditorMode.ONLY_TEXT}
                                placeHolderText={t`Текст заголовка блока`}
                                setToolbarConfigById={props.setToolbarConfigById}
                                ref={richTextEditorTitleRef}
                                onChange={(value) => onChange(value, null)}
                                initialData={state.title}
                                id={props.id}
                                onFocus={() => setLastFocusedElement(Elements.TITLE)}
                />
            </TitleChapterWrapper>
            <BodyChapterWrapper>
                <ChapterTitle><Trans>Разворачиваемая часть блока</Trans></ChapterTitle>
                <RichTextEditor editorMode={RichTextEditorMode.TEXT_WITH_ADDONS}
                                placeHolderText={t`Разворачиваемый контент`}
                                setToolbarConfigById={props.setToolbarConfigById}
                                ref={richTextEditorBodyRef}
                                onChange={(value) => onChange(null, value)}
                                initialData={state.body}
                                id={props.id}
                                onFocus={() => setLastFocusedElement(Elements.BODY)}
                />
            </BodyChapterWrapper>
        </UnfoldBlockStyle>;
    }
);