import React, {useContext} from "react";
import styled from "styled-components";
import {CreateStudentPageContext, ICreateStudentPageContext} from "./CreateStudentPageContextProvider";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum} from "../../../../components/Ui/Elements/Button";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {PageSectionsEnum} from "../create-agreement-page/PageSectionsEnum";

const BlocksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const TextBlock = styled.div`
  margin-bottom: 30px;
`;

const TwoButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

export const FinishPage: React.FC = () => {
    const pageContext = useContext<ICreateStudentPageContext>(CreateStudentPageContext);

    return <BlocksList>
        <div>
            <TextBlock>
                <PageSubtitleSmallMargin><Trans>Запланируйте занятия</Trans></PageSubtitleSmallMargin>
                <RegularText>
                    <Trans>Теперь можете указать дисциплину для изучения, задать расписание занятий и отправить ученику
                        ссылку для быстрого входа на платформу.</Trans>
                </RegularText>
            </TextBlock>

            <TwoButtonWrapper>
                <div>
                    <LinkAsButton
                        btnStyle={BtnStyleEnum.Secondary}
                        to={RoutesList.TEACHER_ALL_STUDENTS}
                    >
                        <Trans>Пропустить</Trans>
                    </LinkAsButton>
                </div>
                <div>
                    <LinkAsButton btnStyle={BtnStyleEnum.Primary} to={
                        RoutesHelper.replaceParams(
                            RoutesList.TEACHER_STUDENT_NEW_AGREEMENT,
                            [
                                {
                                    key: 'studentId',
                                    value: pageContext.studentGeneralInfoDto.id.toString(10)
                                },
                                {
                                    key: 'stepId',
                                    value: PageSectionsEnum.TEAM
                                }
                            ]
                        )
                    }>
                        <Trans>Запланировать занятия</Trans>
                    </LinkAsButton>
                </div>
            </TwoButtonWrapper>
        </div>
    </BlocksList>
}