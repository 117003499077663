import {IsNotEmpty, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO секции базы знаний
 */
export class DtoKbSection {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    name: string;
}
