import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PageSubtitle2, PageTitle, RegularTextCss} from "../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../store/app/selector";
import {selectedAgreement, selectedSchool} from "../../../../store/user/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoSchoolBaseInfoResponse
} from "../../../../components/HttpApiClient/ApiDto/Response/School/DtoSchoolBaseInfoResponse";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";
import {StringHelper} from "../../../../helpers/StringHelper";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";

enum LoadingStateEnum {
    NOT_INIT,
    LOADING_NOW,
    SUCCESS,
    ERROR
}

const ContentBlock = styled.div``;

const ContentBlockTitle = styled(PageSubtitle2)`
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: 700;

  @media (${({theme}) => theme.media.small}) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media (${({theme}) => theme.media.medium}) {
    font-size: 24px;
    margin-bottom: 26px;
  }
`;

const ContentBlockText = styled.div`
  ${RegularTextCss};

  margin-bottom: 30px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 40px;
  }
`;

export const AboutSchool: React.FC = () => {
    const [schoolInfo, setSchoolInfo] = useState<DtoSchoolBaseInfoResponse | null>(null);
    const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_INIT);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const sessionToken = useSelector(sessionTokenSelector);
    const school = useSelector(selectedSchool);
    const agreement = useSelector(selectedAgreement);

    const fetchData = useCallback(() => {
        if ((agreement === null) || (sessionToken === null)) {
            setLoadingState(LoadingStateEnum.ERROR);

            return;
        }

        if (abortController !== null) {
            abortController.abort();
        }

        setLoadingState(LoadingStateEnum.LOADING_NOW);
        setSchoolInfo(null);
        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        httpApiClient.getSchoolBaseInfo(
            sessionToken,
            agreement.id,
            newAbortController
        )
            .then((data) => {
                setSchoolInfo(data.data);
                setLoadingState(LoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                if (err instanceof CanceledByUser) {
                    return;
                }

                const errorText = 'Error on load school base info';

                if (err instanceof NoConnection) {
                    logger.info(LoggerSectionsEnum.ABOUT_SCHOOL_PAGE, errorText, err);
                } else {
                    logger.error(LoggerSectionsEnum.ABOUT_SCHOOL_PAGE, errorText, err);
                }


                setLoadingState(LoadingStateEnum.ERROR);
            });

    }, [abortController, agreement, sessionToken]);

    useEffect(() => {
        if (loadingState === LoadingStateEnum.NOT_INIT) {
            fetchData();
        }
    }, [fetchData, loadingState]);

    useEffect(() => {
        if (loadingState === LoadingStateEnum.SUCCESS) {
            fetchData();
        }
    }, [school])

    useEffect(() => {
        return () => {
            if (abortController !== null) {
                abortController.abort();
            }
        }
    }, [abortController]);

    const contactsBlock = useMemo<JSX.Element | null>(() => {
        if (!schoolInfo || !schoolInfo.contacts) {
            return null;
        }

        return <ContentBlock>
            <ContentBlockTitle><Trans>Контакты</Trans></ContentBlockTitle>
            <ContentBlockText
                dangerouslySetInnerHTML={{__html: StringHelper.replaceLineBreakTextChar(schoolInfo.contacts)}}/>
        </ContentBlock>
    }, [schoolInfo]);

    const addressesBlock = useMemo<JSX.Element | null>(() => {
        if (!schoolInfo || !schoolInfo.addresses) {
            return null;
        }

        return <ContentBlock>
            <ContentBlockTitle><Trans>Адреса</Trans></ContentBlockTitle>
            <ContentBlockText
                dangerouslySetInnerHTML={{__html: StringHelper.replaceLineBreakTextChar(schoolInfo.addresses)}}/>
        </ContentBlock>
    }, [schoolInfo]);

    const requisitesBlock = useMemo<JSX.Element | null>(() => {
        if (!schoolInfo || !schoolInfo.requisites) {
            return null;
        }

        return <ContentBlock>
            <ContentBlockTitle><Trans>Реквизиты</Trans></ContentBlockTitle>
            <ContentBlockText
                dangerouslySetInnerHTML={{__html: StringHelper.replaceLineBreakTextChar(schoolInfo.requisites)}}/>
        </ContentBlock>
    }, [schoolInfo]);

    const detailedInformationBlock = useMemo<JSX.Element | null>(() => {
        if (!schoolInfo || !schoolInfo.detailedInformation) {
            return null;
        }

        return <ContentBlock>
            <ContentBlockTitle><Trans>Подробная информация</Trans></ContentBlockTitle>
            <ContentBlockText
                dangerouslySetInnerHTML={{__html: StringHelper.replaceLineBreakTextChar(schoolInfo.detailedInformation)}}/>
        </ContentBlock>
    }, [schoolInfo]);

    const content = useMemo<JSX.Element>(() => {
        switch (loadingState) {
            case LoadingStateEnum.NOT_INIT:
            case LoadingStateEnum.LOADING_NOW: {
                return <DefaultLoader/>;
            }
            case LoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchData}/>
            }
            case LoadingStateEnum.SUCCESS: {
                if (!schoolInfo) {
                    return <ErrorLoadingContent retryBtnClick={fetchData}/>;
                }

                return <div>
                    {contactsBlock}
                    {addressesBlock}
                    {requisitesBlock}
                    {detailedInformationBlock}
                </div>;
            }
            default: {
                throw new Error('Unknown loading state ' + loadingState);
            }
        }
    }, [addressesBlock, contactsBlock, detailedInformationBlock, fetchData, loadingState, requisitesBlock, schoolInfo]);

    return <div>
        <PageTitle>
            {
                (school)
                    ? ((schoolInfo) ? schoolInfo.name : school.name)
                    : <Trans>О школе</Trans>
            }
        </PageTitle>
        {
            content
        }
    </div>
}
