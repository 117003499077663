import * as React from "react";
import styled from "styled-components";
import {DisciplineItemStyled} from "./DisciplineItem";
import {Trans} from "@lingui/macro";

const LoadMoreDisciplineItemStyled = styled(DisciplineItemStyled)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 15px 5px 10px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface LoadMoreDisciplineItemProps {
    onClick: () => void;
}

export const LoadMoreDisciplineItem: React.FC<LoadMoreDisciplineItemProps> = (props) => {
    return (
        <LoadMoreDisciplineItemStyled active={false} onClick={props.onClick}>
            {/*<PlusIcon/>*/}
            <PlusText><Trans>Показать ещё</Trans></PlusText>
        </LoadMoreDisciplineItemStyled>
    );
}