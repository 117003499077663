import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t} from "@lingui/macro";
import {
    ElementWrapper,
    NoticeBlockColored,
    NoticeBlockTitleIcon,
    NoticeBlockTitleStyled,
    NoticeBlockTitleText
} from "../../../SlidePlayerEditorCommonParts/components/TeacherAlsoDiscuss";
import {ElementName} from "../../../SlidePlayerEditorCommonParts/ElementName";
import {ElementTypeEnum} from "../../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ReactComponent as EyeClosedIcon} from "../../../Ui/Svg/EyeClosed24.svg";
import {NoticeBlockTextCss} from "../../../Ui/Elements/NoticeBlock";

const paragraphComponent = styled.p`
  ${NoticeBlockTextCss};
`;

const liComponent = styled.li`
  ${NoticeBlockTextCss};
`;

interface TeacherAlsoDiscussProps extends ItemPropsInterface<any> {
}

interface TeacherAlsoDiscussRefMethods extends ItemRefMethodsInterface {
}

export const TeacherAlsoDiscuss = forwardRef<TeacherAlsoDiscussRefMethods, TeacherAlsoDiscussProps>(
    (props, ref) => {

        const richTextEditorRef = useRef<RichTextEditor>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                richTextEditorRef.current?.toolbarItemOnToggle(buttonType, newValue);
            },
            getExercisesCount: () => {
                return 0;
            }
        }));

        return <ElementWrapper>
            <NoticeBlockColored>
                <>
                    <NoticeBlockTitleStyled>
                        <NoticeBlockTitleText>
                            <ElementName elementType={ElementTypeEnum.TEACHER_ALSO_DISCUSS}/>
                        </NoticeBlockTitleText>
                        <NoticeBlockTitleIcon>
                            <EyeClosedIcon/>
                        </NoticeBlockTitleIcon>
                    </NoticeBlockTitleStyled>
                    <RichTextEditor editorMode={RichTextEditorMode.ONLY_TEXT}
                                    placeHolderText={t`Ещё можно обсудить`}
                                    setToolbarConfigById={props.setToolbarConfigById}
                                    ref={richTextEditorRef}
                                    onChange={props.onChange}
                                    initialData={props.initialData}
                                    id={props.id}
                                    paragraphCustomComponent={paragraphComponent}
                                    liCustomComponent={liComponent}
                    />
                </>
            </NoticeBlockColored>
        </ElementWrapper>;
    }
);

TeacherAlsoDiscuss.displayName = 'TeacherAlsoDiscuss';