import React from "react";
import styled from "styled-components";
import {ReactComponent as ImagePlaceholderErrorSvg} from "../../../Ui/Svg/ImagePlaceholderError.svg";
import {DefaultLoader} from "../../../DefaultLoader";

export enum PresenterPlaceholderShowModeEnum {
    WAIT_FOR_LOADING,
    LOADING_ERROR
}

const PresenterPlaceholderStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 10px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const FirstWrapper = styled.div`
  border-radius: 5px;
  border: 1px dashed ${({theme}) => theme.colors.accentDivider};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px 5px;
`;

const SecondWrapper = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

const TextWrapper = styled.div`
`;

interface VideoPlaceholderProps {
    showMode: PresenterPlaceholderShowModeEnum;
    text: string | null;
}

export const PresenterPlaceholder: React.FC<VideoPlaceholderProps> = (props) => {
    return <PresenterPlaceholderStyled>
        <FirstWrapper>
            <SecondWrapper>
                <IconWrapper>
                    {
                        ((props.showMode === PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING))
                            ? <DefaultLoader height={"auto"}/> : <ImagePlaceholderErrorSvg/>
                    }
                </IconWrapper>
                {
                    (props.text) && <TextWrapper>
                        {props.text}
                    </TextWrapper>
                }
            </SecondWrapper>
        </FirstWrapper>
    </PresenterPlaceholderStyled>
}