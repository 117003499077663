import React, {useCallback, useContext, useState} from "react";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../components/Ui/Elements/NoticeBlock";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import styled from "styled-components";
import {container} from "tsyringe";
import {IWsApiClient} from "../../../../../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILessonPageContext, LessonPageContext} from "../../LessonPageContext";
import {ApiMethodEnum} from "../../../../../../components/WsApiClient/ApiMethodEnum";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {WsResponseStatusEnum} from "../../../../../../components/WsApiClient/WsResponseStatusEnum";

const Wrapper = styled.div`
    margin-bottom: 30px;
`;

interface NoticeHomeworkIsNotAvailableProps {
}

export const NoticeHomeworkIsNotAvailable: React.FC<NoticeHomeworkIsNotAvailableProps> = (props) => {

    const lessonPageContext = useContext<ILessonPageContext>(LessonPageContext);
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const sendHomeworkToStudents = useCallback(() => {
        if (
            (lessonPageContext.groupIntId === null)
            || (lessonPageContext.lessonIntId === null)
        ) {
            return;
        }


        const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        setLoadingState(true);

        wsClient.queryAsPromise<null>(
            ApiMethodEnum.LESSON_PREPARE_STUDENT_IN_LESSON_ITEMS,
            {
                stGroupId: lessonPageContext.groupIntId,
                stLessonId: lessonPageContext.lessonIntId
            },
            null
        )
            .then((data) => {
                if (data.response.status !== WsResponseStatusEnum.OK) {
                    throw new Error('Error with status: ' + data.response.status);
                }

                lessonPageContext.setHomeworkIsAvailableForStudents();
            })
            .catch((err) => {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.LESSON_AND_HOMEWORK,
                    'Error on create student in lesson for send homework without start online lesson: ',
                    err
                );

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось отправить задания`,
                    t`Попробуйте начать урок без видео и сразу завершить его`
                )

                setLoadingState(false);
            });
    }, [lessonPageContext]);

    if (!lessonPageContext.homeworkIsAvailableForStudents) {
        return <Wrapper>
            <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Ученики пока не видят домашнее задание к этому уроку</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Упражнения отправляются ученикам после проведения онлайн урока.</Trans>
                    </NoticeBlockText>
                    <NoticeBlockText>
                        <Trans>Если вы хотите отправить материалы без проведения урока, нажмите здесь:</Trans><br/><br/>
                        <Button btnStyle={BtnStyleEnum.Primary}
                                loading={loadingState}
                                onClick={sendHomeworkToStudents}>
                            <Trans>Отправить материалы</Trans>
                        </Button>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        </Wrapper>
    }

    return <Wrapper>
        <NoticeBlock>
            <>
                <NoticeBlockTitle>
                    <Trans>Ученики пока не видят домашнее задание к этому уроку</Trans>
                </NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>Это занятие отмечено как черновик и ученики не видят его.</Trans>
                </NoticeBlockText>
                <NoticeBlockText>
                    <Trans>Вы можете перейти в раздел «Работа на уроке» &rarr; «Основное», убрать там галочку «Это черновик занятия» и нажать кнопку «Сохранить».</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>
    </Wrapper>
}
