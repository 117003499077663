import {cloneDeep} from "lodash";

export class ArrayHelpers {
    /**
     * @param array
     *
     * @return string
     */
    static shuffleArray<T>(array: Array<T>): Array<T> {
        let resultArray = cloneDeep(array);

        let m = resultArray.length;
        let t: T;
        let i: number;

        // While there remain elements to shuffle…
        while (m) {
            // Pick a remaining element…
            i = Math.floor(Math.random() * m--);

            // And swap it with the current element.
            t = resultArray[m];
            resultArray[m] = resultArray[i];
            resultArray[i] = t;
        }

        return resultArray;
    }

    static unique(arr: any[]): any[] {
        return arr.filter((value, index, array) => {
            return array.indexOf(value) === index;
        });
    }
}
