import React, {useContext, useMemo, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {t, Trans} from "@lingui/macro";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import styled from "styled-components";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";

const ButtonStyled = styled(Button)`
    width: 100%;
`;

interface ResetPasswordBtnProps {
    onSuccess: () => void;
}

export const ResetPasswordBtn: React.FC<ResetPasswordBtnProps> = ({onSuccess}) => {
    const apiToken = useSelector(sessionTokenSelector);

    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
    const apiClient = useMemo(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);

    const modal = useRef<PopupActions>(null);

    const resetPassword = () => {
        return new Promise<void>((resolve, reject) => {
            if (!studentPageContext.studentEntranceInfoResponse || apiToken === null) {
                reject();

                return;
            }

            apiClient
                .resetStudentPassword(apiToken, studentPageContext.studentGeneralInfoDto.email)
                .then((result) => {
                    studentPageContext.setStudentEntranceInfoDto(result.data);

                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Операция выполнена`,
                        t`Пароль успешно сброшен, ссылка для входа тоже обновилась.`
                    );

                    resolve();
                })
                .catch(() => {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не получается выполнить операцию.`,
                        ''
                    );

                    reject();
                });
        });
    }

    return <div>
        {
            (studentPageContext.studentEntranceInfoResponse)
            && <ButtonStyled btnStyle={BtnStyleEnum.Secondary} onClick={() => modal.current?.open()}>
                <Trans>Сбросить пароль</Trans>
            </ButtonStyled>
        }

        {
            (studentPageContext.studentEntranceInfoResponse)
            && <ConfirmDialog
                ref={modal}
                okText={t`Сбросить пароль`}
                cancelText={t`Закрыть`}
                title={t`Сброс пароля ученика`}
                okMethod={resetPassword}
                text={<>
                    <Trans>Ученику:</Trans>
                    <ul>
                        <li>
                            <Trans>будет установлен пароль для входа 0000 (четыре нуля)</Trans>;
                        </li>
                        <li>
                            <Trans>будет отправлен e-mail об этом</Trans>.
                        </li>
                    </ul>
                </>}
            />
        }
    </div>
}
