import {LayoutState} from "./type";
import {LightTheme} from "../../services/theme/LightTheme";
import {ThemeEnum} from "../../services/theme/ThemeEnum";

export const initialState: LayoutState = {
    splashScreenVisible: true,
    rightPanelVisible: false,
    activeTheme: LightTheme,
    activeThemeName: ThemeEnum.light,
    bodyScrollAvailable: true,
    contentLayoutSettings: null
};
