import React from "react";
import {userProfileIsEmpty as userProfileIsEmptySelector} from "../../../../store/user/selector";
import {useSelector} from "react-redux";
import {SchoolContractMainPage} from "./SchoolContractMainPage";
import {NoSchoolContractMainPage} from "./NoSchoolContractMainPage";

export const MainPage: React.FC = () => {
    const noSchoolContracts = useSelector(userProfileIsEmptySelector);

    if (noSchoolContracts) {
        return <NoSchoolContractMainPage/>
    } else {
        return <SchoolContractMainPage/>
    }
}