import {IsArray, IsNotEmpty, IsNumber, Max, Min} from "class-validator";

/**
 * Базовый DTO ответа на запрос с пагинацией
 */
export class DtoResponseWithPagination<T> {
  @IsNotEmpty()
  @IsNumber()
  @Max(10000)
  @Min(1)
  page: number;

  @IsNotEmpty()
  @IsNumber()
  @Max(200)
  @Min(1)
  limit: number;

  @IsNotEmpty()
  @IsNumber()
  @Max(10000000)
  @Min(0)
  totalCount: number;

  @IsArray() // @ValidateNested({each: true})
  list: Array<T>;
}
