export const AUDIO_ELEMENT_HEIGHT = 100;

export enum ElementStateEnum {
    WAIT_INIT,
    NO_AUDIO,
    WAIT_FOR_UPLOADING,
    UPLOADING_NOW,
    UPLOAD_SUCCESSFULLY,
    UPLOAD_ERROR
}

export const UploadStateList = [
    ElementStateEnum.WAIT_INIT,
    ElementStateEnum.NO_AUDIO,
    ElementStateEnum.WAIT_FOR_UPLOADING,
    ElementStateEnum.UPLOADING_NOW,
    ElementStateEnum.UPLOAD_ERROR
]

export type ElementData = {
    audioId: string | null;
}