import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {
    DtoAgreementItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import {container} from "tsyringe";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {NoConnection} from "../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {PageSubtitleSmallMargin} from "../../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {AgreementItem} from "./AgreementItem";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../components/Ui/Elements/NoticeBlock";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {LinkAsButton} from "../../../../../components/Ui/Elements/LinkAsButton";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {PageSectionsEnum} from "../../create-agreement-page/PageSectionsEnum";

const ListTitle = styled(PageSubtitleSmallMargin)`
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NoticeBlockStyled = styled(NoticeBlock)`
  margin-bottom: 35px;
`;

enum PageLoadStatus {
    NOT_INIT,
    LOADING,
    LOADING_ARCHIVE,
    SUCCESS,
    ERROR
}

export const StudentAgreementsSection: React.FC = () => {
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
    const stToken = useSelector(stTokenSelector);

    const [pageLoadStatus, setPageLoadStatus] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [activeAgreements, setActiveAgreements] = useState<DtoAgreementItem[] | null>(null);
    const [inactiveAgreements, setInActiveAgreements] = useState<DtoAgreementItem[] | null>(null);
    const [totalAgreementsCount, setTotalAgreementsCount] = useState<number | null>(null);

    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const dateHelper = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);

    const itemIsActive = useCallback((item: DtoAgreementItem) => {
        let result = true;

        if (item.annulled !== 0) {
            result = false;
        } else {
            // Проверим дату завершения обучения группы
            if (item.groupData && item.groupData.groupDateEndWork !== '') {
                const dateEndWork = dateHelper.dateFromString(item.groupData.groupDateEndWork);
                const currentDate = dateHelper.getCurrentDateWithoutTime();

                if (dateEndWork < currentDate) {
                    result = false;
                }
            }
        }

        return result;
    }, [dateHelper]);

    const fetchAgreements = useCallback(async (needLoadInactiveItems?: boolean) => {
        if (needLoadInactiveItems === undefined) {
            needLoadInactiveItems = false;
        }

        if (!needLoadInactiveItems) {
            if (inactiveAgreements !== null && inactiveAgreements.length > 0) {
                needLoadInactiveItems = true;
            }
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (!stToken || !studentPageContext) {
            logger.error(
                LoggerSectionsEnum.STUDENT_PROFILE_AGREEMENTS_SECTION,
                'studentPageContext or stToken is empty'
            );

            throw new Error('stToken or studentPageContext is empty');
        }

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        setPageLoadStatus(
            (needLoadInactiveItems) ? PageLoadStatus.LOADING_ARCHIVE : PageLoadStatus.LOADING
        );

        try {
            const items = await stApiClient.getAdditionalAgreementsListByStudent(
                stToken,
                studentPageContext.studentGeneralInfoDto.id,
                true,
                needLoadInactiveItems,
                newAbortController
            );

            const newActiveAgreements: DtoAgreementItem[] = [];
            const newInactiveAgreements: DtoAgreementItem[] = [];

            items.result.items.forEach(item => {
                if (item.groupData === null) {
                    return;
                }

                if (itemIsActive(item)) {
                    newActiveAgreements.push(item);
                } else {
                    newInactiveAgreements.push(item);
                }
            });

            setActiveAgreements(newActiveAgreements);
            setInActiveAgreements((needLoadInactiveItems) ? newInactiveAgreements : []);
            setTotalAgreementsCount(items.result.totalCount);

            setPageLoadStatus(PageLoadStatus.SUCCESS);
        } catch (e) {
            setPageLoadStatus(PageLoadStatus.ERROR);

            if (e instanceof NoConnection) {
                return;
            }

            logger.error(
                LoggerSectionsEnum.STUDENT_PROFILE_AGREEMENTS_SECTION,
                `Error on load agreements for student with id ${studentPageContext.studentGeneralInfoDto.id}: `,
                e
            );
        }
    }, [abortController, inactiveAgreements, itemIsActive, stToken, studentPageContext]);

    const loadArchive = useCallback(() => {
        fetchAgreements(true);
    }, [fetchAgreements]);

    useEffect(() => {
        fetchAgreements();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const itemsInArchive = useMemo<number>(() => {
        if (activeAgreements === null || totalAgreementsCount === null) {
            return 0;
        }

        return totalAgreementsCount - activeAgreements.length - ((inactiveAgreements) ? inactiveAgreements.length : 0);
    }, [activeAgreements, inactiveAgreements, totalAgreementsCount]);

    const linkToNewAgreement = useMemo<string>(() => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_STUDENT_NEW_AGREEMENT,
            [
                {
                    key: 'studentId',
                    value: studentPageContext.studentGeneralInfoDto.id.toString(10)
                },
                {
                    key: 'stepId',
                    value: PageSectionsEnum.TEAM
                }
            ]
        );
    }, [studentPageContext.studentGeneralInfoDto.id]);

    const content = useMemo<JSX.Element>(() => {
        const activeItems: JSX.Element[] = [];
        const inactiveItems: JSX.Element[] = [];
        let activeItemsPlaceholder: JSX.Element | null = null;

        if (activeAgreements !== null) {
            activeAgreements.forEach(item => {
                if (item.groupData !== null) {
                    activeItems.push(
                        <AgreementItem key={item.id} dto={item} dtoGroupData={item.groupData}/>
                    )
                }
            });
        }

        if (inactiveAgreements !== null) {
            inactiveAgreements.forEach(item => {
                if (item.groupData !== null) {
                    inactiveItems.push(
                        <AgreementItem key={item.id} dto={item} dtoGroupData={item.groupData}/>
                    )
                }
            });
        }

        if (activeItems.length === 0) {
            if (totalAgreementsCount !== null && totalAgreementsCount > 0) {
                activeItemsPlaceholder = <NoticeBlockStyled>
                    <NoticeBlockTitle><Trans>Нет активных программ обучения</Trans></NoticeBlockTitle>
                    <NoticeBlockText><Trans>Ученик завершил обучение. Вы можете назначить новую
                        программу.</Trans></NoticeBlockText>
                </NoticeBlockStyled>
            } else {
                activeItemsPlaceholder = <NoticeBlockStyled>
                    <NoticeBlockTitle><Trans>Обучение не назначено</Trans></NoticeBlockTitle>
                    <NoticeBlockText><Trans>Ученику ещё не назначались программы обучения. Вы можете назначить новую
                        программу.</Trans></NoticeBlockText>
                </NoticeBlockStyled>
            }
        }

        return <div>
            <ListTitle><Trans>Текущие программы обучения</Trans></ListTitle>
            {(activeItems) && <ItemsWrapper>{activeItems}</ItemsWrapper>}
            {activeItemsPlaceholder}

            {
                (inactiveItems.length > 0)
                && <>
                    <ListTitle><Trans>Архивные программы обучения</Trans></ListTitle>
                    <ItemsWrapper>{inactiveItems}</ItemsWrapper>
                </>
            }

            <ButtonsArea>
                <LinkAsButton
                    btnStyle={BtnStyleEnum.Primary}
                    to={linkToNewAgreement}
                >
                    <Trans>Новый курс</Trans>
                </LinkAsButton>
                {
                    (itemsInArchive > 0 && pageLoadStatus) &&
                    <Button btnStyle={BtnStyleEnum.Secondary}
                            loading={pageLoadStatus === PageLoadStatus.LOADING_ARCHIVE}
                            onClick={loadArchive}
                    >
                        <Trans>Архив</Trans>&nbsp;({itemsInArchive})
                    </Button>
                }
            </ButtonsArea>
        </div>
    }, [activeAgreements, inactiveAgreements, itemsInArchive, linkToNewAgreement, loadArchive, pageLoadStatus, totalAgreementsCount]);

    switch (pageLoadStatus) {
        case PageLoadStatus.NOT_INIT:
        case PageLoadStatus.LOADING: {
            return <DefaultLoader/>;
        }
        case PageLoadStatus.SUCCESS:
        case PageLoadStatus.LOADING_ARCHIVE: {
            return content;
        }
        default: {
            return <ErrorLoadingContent retryBtnClick={() => fetchAgreements()}/>
        }
    }
}