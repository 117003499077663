import {IsNotEmpty, IsOptional, IsString} from "class-validator";

/**
 * DTO базовой информации о пользователе
 */
export class DtoUserProfileInfo {
    @IsOptional()
    @IsString()
    id: string | null;

    @IsNotEmpty()
    @IsString()
    name: string;

    @IsOptional()
    @IsString()
    avatarFileId: string | null;
}