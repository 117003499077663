import React, {useContext, useMemo} from "react";
import {ControlPanelWrapper} from "../../Ui/Elements/ControlPanel/ControlPanel";
import {MyVideoBtn} from "./buttons/MyVideoBtn";
import {MyMicBtn} from "./buttons/MyMicBtn";
import {FullScreenBtn} from "./buttons/FullScreenBtn";
import {ScreenShareBtn} from "./buttons/ScreenShareBtn";
import {SettingsBtn} from "./buttons/SettingsBtn";
import {container} from "tsyringe";
import {IDeviceDetector} from "../../../../components/DeviceDetector/IDeviceDetector";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {userIsTeacher} from "../../../../store/user/selector";
import {TeacherMagicBtn} from "./buttons/TeacherMagicBtn";
import {DocumentInPictureBtn} from "./buttons/DocumentInPictureBtn";
import {CommonContext, ICommonContext} from "../contexts/CommonContext";
import {ConferenceModeEnum} from "../Types";

interface VideoControlPanelProps {
    className?: string;
}

export const VideoControlPanel: React.FC<VideoControlPanelProps> = ({className}) => {
    const isTeacher = useSelector(userIsTeacher);

    const commonContext = useContext<ICommonContext>(CommonContext);

    const isDesktop = useMemo<boolean>(() => {
        const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);

        return deviceDetector.isDesktop();
    }, []);

    return <ControlPanelWrapper className={className}>
        <MyVideoBtn/>
        <MyMicBtn/>
        {
            (isDesktop && commonContext.currentMode !== ConferenceModeEnum.DOCUMENT_IN_PICTURE)
            && <ScreenShareBtn/>
        }
        {
            (commonContext.currentMode !== ConferenceModeEnum.DOCUMENT_IN_PICTURE)
            && <FullScreenBtn/>
        }
        {
            (isDesktop)
            && <DocumentInPictureBtn/>
        }
        {
            (isTeacher && commonContext.currentMode !== ConferenceModeEnum.DOCUMENT_IN_PICTURE)
            && <TeacherMagicBtn/>
        }
        {
            (commonContext.currentMode !== ConferenceModeEnum.DOCUMENT_IN_PICTURE)
            && <SettingsBtn/>
        }
    </ControlPanelWrapper>
}
