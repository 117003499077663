import {Editor, Element as SlateElement, Range, Transforms} from "slate";
import {elementTypeIsActive} from "../CommonMethods";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {ITranslated} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ITranslated";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {NotificationTypesEnum, openNotification} from "../../../../Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {NodesToPlainText} from "../NodesToPlainText";

export const toggleTranslated = (editor: Editor) => {
    const isActive = elementTypeIsActive(editor, ElementTypes.TRANSLATED);

    if (isActive) {
        Transforms.unwrapNodes(editor, {
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                if (![ElementTypes.TRANSLATED].includes(node.type)) {
                    return false;
                }

                return node.type === ElementTypes.TRANSLATED;
            },
            split: true,
        })
    } else {
        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)

        if (isCollapsed) {
            openNotification(
                NotificationTypesEnum.INFO,
                t`Выделите фрагмент`,
                t`Укажите текст, перевод которого вы хотите указать`
            )

            return;
        }

        const fragment = editor.getFragment();

        const text = NodesToPlainText.convertNodesToPlainText(fragment);

        const translateText = prompt(t`Укажите перевод фрагмента "${text}"`);

        if (!translateText) {
            return;
        }

        const block: ITranslated = {
            id: ExerciseIdGenerator.generateId(),
            type: ElementTypes.TRANSLATED,
            translate: translateText,
            children: isCollapsed ? [{text: ""}] : []
        };

        if (isCollapsed) {
            Transforms.insertNodes(editor, block)
        } else {
            Transforms.wrapNodes(editor, block, { split: true })
            Transforms.collapse(editor, { edge: 'end' })
        }
    }
}