import React, {useRef} from "react";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {OptAuthPopup} from "../../../components/OptAuthPopup";
import {PopupActions} from "reactjs-popup/dist/types";

const NoticeBlockStyled = styled(NoticeBlock)`
    background-color: ${({theme}) => theme.colors.fatalErrorHeader};
`;

const NoticeBlockTextStyled = styled(NoticeBlockText)`
    color: ${({theme}) => theme.colors.textPrimary};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media (${({theme}) => theme.media.large}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const TextWrapper = styled.div`
    text-align: center;

    @media (${({theme}) => theme.media.large}) {
        text-align: left;
    }
`;

const ButtonWrapper = styled.div`
    text-align: center;
`;

export const EnterEmail: React.FC = () => {
    const otpModalRef = useRef<PopupActions>(null);

    const onClick = () => {
        otpModalRef.current?.open();
    }

    return <NoticeBlockStyled>
        <Wrapper>
            <TextWrapper>
                <NoticeBlockTitle><Trans>Сохраните достижения!</Trans></NoticeBlockTitle>
                <NoticeBlockTextStyled><Trans>Укажите email, чтобы сохранить прогресс в
                    аккаунте.</Trans></NoticeBlockTextStyled>
            </TextWrapper>
            <ButtonWrapper>
                <Button btnStyle={BtnStyleEnum.Primary} onClick={onClick}><Trans>Сохранить достижения</Trans></Button>
            </ButtonWrapper>
        </Wrapper>
        <OptAuthPopup ref={otpModalRef}/>
    </NoticeBlockStyled>
}