import {withRouter, WithRouterProps} from "../../../../components/WithRouterHoc";
import React from "react";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoHomeworkSummaryResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/Homework/DtoHomeworkSummaryResponse";
import {PageTitle} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {NoticeBlock, NoticeBlockText} from "../../../../components/Ui/Elements/NoticeBlock";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum} from "../../../../components/Ui/Elements/Button";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {AverageValuesComponent, MetricItem} from "../../common/AverageValuesComponent";
import {StudentRateParamPositionEnum} from "../../../../../enums/StudentRateParamPositionEnum";
import {ApplicationState} from "../../../../../store";
import {selectedSchoolActualParams, selectedSchoolActualParamsCount} from "../../../../../store/user/selector";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import {
    LessonResultAnimations,
    LessonResultAnimationVariants
} from "../../../../components/Ui/Elements/LessonResultAnimations/LessonResultAnimations";

const ValuesWrapper = styled.div`
    margin-bottom: 40px;
`;

const ResultAnimationImage = styled(LessonResultAnimations)`
    width: 100px;
    height: 100px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    
    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
`;

const PageTitleStyled = styled(PageTitle)`
    margin-bottom: 0;
`;

const PageTitleWrapper = styled.div``;

const AnimationWrapper = styled.div`
   margin-bottom: 20px;
   
   @media (${({theme}) => theme.media.small}) {
    margin-bottom: auto;
   }
`;

const LinkAsButtonStyled = styled(LinkAsButton)`
    display: block;
    text-align: center;
    
    @media (${({theme}) => theme.media.small}) {
        display: inline-block;
        text-align: left;
    }
`;

enum PageStatus {
    LOADING,
    ERROR,
    READY
}

interface RouterParams {
    lessonId: string;
}

interface HomeworkFinishPageProps extends PropsFromRedux {
}

type HomeworkFinishPagePropsWRouter = WithRouterProps<RouterParams> & HomeworkFinishPageProps;

interface HomeworkFinishPageState {
    pageStatus: PageStatus;

    homeworkSummary: DtoHomeworkSummaryResponse | null;

    totalValueItem: MetricItem | null;
    detailValueItems: MetricItem[];
}

class HomeworkFinishPage extends React.Component<HomeworkFinishPagePropsWRouter, HomeworkFinishPageState> {
    protected logger: ILogger;
    protected httpApiClient: IHttpApiClient;

    protected abortController: AbortController | null;

    constructor(props: Readonly<HomeworkFinishPagePropsWRouter>) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        this.state = {
            pageStatus: PageStatus.LOADING,
            homeworkSummary: null,

            totalValueItem: null,
            detailValueItems: [],
        }

        this.abortController = null;
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();
        }
    }

    protected getTitle = (totalValue: number) => {
        const veryGoodVariants = [
            t`Отличная работа`,
            t`Замечательный результат`,
            t`Отлично`
        ];

        const goodVariants = [
            t`Хорошая работа`,
            t`Хороший результат`
        ];

        const badVariants = [
            t`Есть, куда стремиться`,
            t`В следующий раз получится лучше`,
            t`Покажем класс в следующий раз :)`
        ];

        if (totalValue > 90) {
            return veryGoodVariants[Math.floor(Math.random() * veryGoodVariants.length)];
        }

        if (totalValue > 60) {
            return goodVariants[Math.floor(Math.random() * goodVariants.length)];
        }

        return badVariants[Math.floor(Math.random() * badVariants.length)];
    }

    protected getAnimation = (totalValue: number): LessonResultAnimationVariants => {
        const veryGoodVariants: LessonResultAnimationVariants[] = [
            LessonResultAnimationVariants.LOVE_CAT,
            LessonResultAnimationVariants.PARTY
        ];

        const goodVariants: LessonResultAnimationVariants[] = [
            LessonResultAnimationVariants.LAUGH_CAT,
            LessonResultAnimationVariants.GOOD_CAT,
        ];

        const badVariants: LessonResultAnimationVariants[] = [
            LessonResultAnimationVariants.BICEPS
        ];

        if (totalValue === 100) {
            return LessonResultAnimationVariants.PARTY;
        }

        if (totalValue > 90) {
            return veryGoodVariants[Math.floor(Math.random() * veryGoodVariants.length)];
        }

        if (totalValue > 60) {
            return goodVariants[Math.floor(Math.random() * goodVariants.length)];
        }

        return badVariants[Math.floor(Math.random() * badVariants.length)];
    }

    protected fetchData = () => {
        if (
            (this.props.apiToken === null)
            || (!this.props.match.params.lessonId)
        ) {
            this.setState(() => {
                return {
                    pageStatus: PageStatus.ERROR
                }
            });

            return;
        }

        this.setState(() => {
            return {
                pageStatus: PageStatus.LOADING
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();

        this.httpApiClient.getHomeworkSummary(
            this.props.apiToken,
            this.props.match.params.lessonId,
            this.abortController
        )
            .then((data) => {
                if (data.data.averageResults === null) {
                    // Вероятно домашняя работа не завершена
                    if (data.data.workCompleted) {
                        // Если указано, что завершена, значит у нас ошибка и нужно отправить отчёт
                        this.logger.error(
                            LoggerSectionsEnum.LESSON_AND_HOMEWORK,
                            `Error in homework summary results: averageResults is null. `
                        );
                    }

                    // Отправляем пользователя на главную страницу
                    this.props.navigate(RoutesList.MAIN_PAGE);

                    return;
                }

                const valuesObject = data.data.averageResults;

                let detailInfo: MetricItem[] = [];
                let totalInfo: MetricItem;

                if (valuesObject.param1 && this.props.schoolActualParams?.first) {
                    detailInfo.push({
                        name: this.props.schoolActualParams.first,
                        value: valuesObject.param1,
                        paramPosition: StudentRateParamPositionEnum.FIRST,
                    });
                }

                if (valuesObject.param2 && this.props.schoolActualParams?.second) {
                    detailInfo.push({
                        name: this.props.schoolActualParams.second,
                        value: valuesObject.param2,
                        paramPosition: StudentRateParamPositionEnum.SECOND,
                    });
                }

                if (valuesObject.param3 && this.props.schoolActualParams?.third) {
                    detailInfo.push({
                        name: this.props.schoolActualParams.third,
                        value: valuesObject.param3,
                        paramPosition: StudentRateParamPositionEnum.THIRD,
                    });
                }

                if (valuesObject.param4 && this.props.schoolActualParams?.fourth) {
                    detailInfo.push({
                        name: this.props.schoolActualParams.fourth,
                        value: valuesObject.param4,
                        paramPosition: StudentRateParamPositionEnum.FOURTH,
                    });
                }

                if (valuesObject.param5 && this.props.schoolActualParams?.fifth) {
                    detailInfo.push({
                        name: this.props.schoolActualParams.fifth,
                        value: valuesObject.param5,
                        paramPosition: StudentRateParamPositionEnum.FIFTH,
                    });
                }

                if (detailInfo.length === 0) {
                    // В результате почему-то оценок не оказалось ни под одному параметру
                    throw new Error('Not found homework average values');
                }

                if (detailInfo.length === 1) {
                    // В результате есть только одна оценка
                    totalInfo = {
                        name: t`Оценка`,
                        value: detailInfo[0].value,
                        paramPosition: null
                    };

                    detailInfo = [];
                } else {
                    totalInfo = {
                        name: t`Средняя оценка`,
                        value: valuesObject.totalAverage,
                        paramPosition: null
                    };
                }

                this.setState(() => {
                    return {
                        homeworkSummary: data.data,
                        pageStatus: PageStatus.READY,
                        detailValueItems: detailInfo,
                        totalValueItem: totalInfo
                    }
                })
            })
            .catch((err) => {
                this.logger.error(
                    LoggerSectionsEnum.HOMEWORK_ROOM,
                    "Error on load HomeworkSummary: ",
                    err
                );

                this.setState(() => {
                    return {
                        pageStatus: PageStatus.ERROR
                    }
                })
            });

    }

    protected noExercises = (): JSX.Element | null => {
        return <>
            <PageTitle>
                <Trans>Результаты домашней работы</Trans>
            </PageTitle>
            <NoticeBlock>
                <>
                    <NoticeBlockText>
                        <Trans>Домашняя работа к выбранному уроку не содержит интерактивных упражнений</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        </>;
    }

    protected workNotCompleted = (): JSX.Element | null => {
        return <>
            <PageTitle>
                <Trans>Результаты домашней работы</Trans>
            </PageTitle>
            <NoticeBlock>
                <>
                    <NoticeBlockText>
                        <Trans>Домашняя работа к выбранному уроку ещё завершена. Когда вы выполните все упражнения,
                            здесь появятся итоговые результаты.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
            <LinkAsButton btnStyle={BtnStyleEnum.Primary} to={RoutesHelper.replaceParams(
                RoutesList.STUDENT_LESSON_PAGE,
                [{key: 'lessonId', value: this.props.match.params.lessonId}]
            )}>
                <Trans>Сделать домашнюю работу</Trans>
            </LinkAsButton>
        </>;
    }

    render() {
        if (this.state.pageStatus === PageStatus.LOADING) {
            return <DefaultLoader/>;
        }

        if (this.state.pageStatus === PageStatus.ERROR) {
            return <ErrorLoadingContent retryBtnClick={this.fetchData}/>;
        }

        if (
            (this.state.pageStatus !== PageStatus.READY)
            || (!this.state.homeworkSummary)
        ) {
            return null;
        }

        if (!this.state.homeworkSummary.hasExercises) {
            return this.noExercises();
        }

        if (!this.state.homeworkSummary.workCompleted) {
            return this.workNotCompleted();
        }

        if (this.state.totalValueItem === null) {
            throw new Error('totalValueItem is null');
        }

        return <>
            <TitleWrapper>
                <PageTitleWrapper>
                    <PageTitleStyled>{this.getTitle(this.state.totalValueItem.value)}</PageTitleStyled>
                </PageTitleWrapper>
                <AnimationWrapper>
                    <ResultAnimationImage variant={this.getAnimation(this.state.totalValueItem.value)}/>
                </AnimationWrapper>
            </TitleWrapper>
            <ValuesWrapper>
                <AverageValuesComponent
                    totalAverageItem={this.state.totalValueItem}
                    detailValues={this.state.detailValueItems}/>
            </ValuesWrapper>
            <LinkAsButtonStyled btnStyle={BtnStyleEnum.Primary} to={RoutesList.MAIN_PAGE}>
                <Trans>Закрыть домашнюю работу</Trans>
            </LinkAsButtonStyled>
        </>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    apiToken: state.user.sessionToken,
    schoolActualParams: selectedSchoolActualParams(state),
    schoolActualParamsCount: selectedSchoolActualParamsCount(state)
});

const connector = connect(mapStateToProps, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(HomeworkFinishPage));
