import React from "react";

export interface IElementWrapperContext {
    elementInFocus: boolean
}

export const ElementWrapperContext = React.createContext<IElementWrapperContext>({
    elementInFocus: false
});

export const ElementWrapperContextProvider = ElementWrapperContext.Provider;