import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import styled from "styled-components";
import {Header} from "./Header";
import {ExercisesCountInSlideItem, SlideEditorRefMethods} from "../../../../../components/SlideEditor";
import {ElementTypeEnum} from "../../../../../components/SlidePlayerEditorCommonParts/ElementTypeEnum";
import {v4 as uuidv4} from 'uuid';
import {EditorData, EditorItemDataParams} from "../../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {cloneDeep, isEqual} from "lodash";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {EditorContext, IEditorContext} from "../EditorContext";
import {EditorArea} from "./EditorArea";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {EditorDataDtoMapper} from "../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {t} from "@lingui/macro";
import {EditorAreaPlaceholder, EditorAreaPlaceholderMode} from "./EditorAreaPlaceholder";
import {ExerciseCopyToBuffer} from "../../../../../components/SlideEditor/components/ExerciseCopyToBuffer";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {IClipboardHelper} from "../../../../../../components/ClipboardHelper/IClipboardHelper";


const EditorContentSideStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  //width: calc(100% - 80px);

  @media (${({theme}) => theme.media.small}) {
    width: 460px;
  }

  @media (${({theme}) => theme.media.medium}) {
    width: 688px;
  }

  @media (${({theme}) => theme.media.large}) {
    width: 700px;
  }
`;


export interface EditorContentSideProps {
    className?: string;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export interface EditorContentSideRefMethods {
    putElement: (elementType: ElementTypeEnum) => void;
}

export enum SaveState {
    NOT_SAVED,
    IN_PROCESS,
    SUCCESS,
    ERROR
}

enum LoadingStateEnum {
    NOT_LOADED,
    IN_PROCESS,
    SUCCESS,
    ERROR
}

export const EditorContentSide = forwardRef<EditorContentSideRefMethods, EditorContentSideProps>((props, ref) => {
    const slideEditorRef = useRef<SlideEditorRefMethods>(null);
    const [contentSaveState, setContentSaveState] = useState<SaveState>(SaveState.NOT_SAVED);
    const sessionToken = useSelector(sessionTokenSelector);

    const editorContext = useContext<IEditorContext>(EditorContext);

    const {
        selectedSlideId,
        slidesDraftContent,
        slidesSavedContent,
        setSlidesSavedContent,
        setSlidesLayoutSettings,
        slides
    } = editorContext;

    const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_LOADED);

    useEffect(() => {
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (
            (loadingState !== LoadingStateEnum.NOT_LOADED)
            || (!selectedSlideId)
            || (!sessionToken)
        ) {
            return;
        }

        if (
            (slidesDraftContent[selectedSlideId] !== undefined)
            || (slidesSavedContent[selectedSlideId] !== undefined)
        ) {
            setLoadingState(LoadingStateEnum.SUCCESS);

            return;
        }

        setLoadingState(LoadingStateEnum.IN_PROCESS);

        // Похоже, контента слайда нет. Загрузим с сервера.
        httpApiClient.tmGetSlideContent(sessionToken, selectedSlideId, null)
            .then((data) => {
                setSlidesSavedContent((existContent) => {
                    const editorData = EditorDataDtoMapper.dtoToEditorData(data.data.content)

                    const newExistContentList = cloneDeep(existContent);

                    newExistContentList[selectedSlideId] = editorData;

                    return newExistContentList;
                });

                setSlidesLayoutSettings((existContent) => {
                    const newExistContentList = cloneDeep(existContent);

                    newExistContentList[selectedSlideId] = 
                        (data.data.content) 
                            ? data.data.content.layoutSettings 
                            : null;

                    return newExistContentList;
                });

                setLoadingState(LoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                setLoadingState(LoadingStateEnum.ERROR);

                logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on load slide content: ", err);
            });

    }, [loadingState, selectedSlideId, sessionToken, setSlidesLayoutSettings, setSlidesSavedContent, slidesDraftContent, slidesSavedContent]);


    const dataForSlide = useMemo((): EditorData => {
        if ((loadingState !== LoadingStateEnum.SUCCESS) || (!selectedSlideId)) {
            return {
                items: []
            };
        }

        if (slidesDraftContent[selectedSlideId] !== undefined) {
            return slidesDraftContent[selectedSlideId];
        } else {
            return slidesSavedContent[selectedSlideId];
        }
    }, [loadingState, selectedSlideId, slidesDraftContent, slidesSavedContent]);

    useEffect(() => {
        if (loadingState === LoadingStateEnum.SUCCESS) {
            slideEditorRef.current?.reloadInitialData(dataForSlide);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingState]);

    useEffect(() => {
        if (contentSaveState === SaveState.SUCCESS) {
            slideEditorRef.current?.reloadInitialData(dataForSlide);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentSaveState]);

    useEffect(() => {
        setLoadingState(LoadingStateEnum.NOT_LOADED);
    }, [selectedSlideId]);

    const needSave = useMemo(() => {
        if (
            (loadingState !== LoadingStateEnum.SUCCESS)
            || (!selectedSlideId)
            || (!slidesDraftContent[selectedSlideId])
        ) {
            return false;
        }

        return !isEqual(
            slidesSavedContent[selectedSlideId],
            slidesDraftContent[selectedSlideId]
        );
    }, [loadingState, selectedSlideId, slidesDraftContent, slidesSavedContent]);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        putElement: (elementType: ElementTypeEnum) => {
            const itemParams = new EditorItemDataParams();
            itemParams.visible = true;

            if (elementType === ElementTypeEnum.CLIPBOARD_PASTE) {
                const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

                // Нужно вставить элемент из буфера
                clipboardHelper
                    .getTextFromBuffer()
                    .then((text) => {
                        return ExerciseCopyToBuffer.prepareToPaste(text);
                    })
                    .then((element) => {
                        element.id= uuidv4();

                        slideEditorRef.current?.putElement(element);

                        openNotification(
                            NotificationTypesEnum.SUCCESS,
                            t`Элемент вставлен`,
                            t`Элемент успешно вставлен из буфера`
                        );
                    }).catch(() => {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Ошибка`,
                        t`Не удалось прочитать информацию`
                    );
                });

            } else {
                slideEditorRef.current?.putElement({
                    id: uuidv4(),
                    alias: null,
                    params: itemParams,
                    data: null,
                    type: elementType,
                    interactivityConfig: null
                });
            }
        }
    }));

    const getExercisesCount = useCallback((): ExercisesCountInSlideItem[] => {
        if (!slideEditorRef.current) {
            return [];
        }

        return slideEditorRef.current.getExercisesCount();
    }, []);

    const content = () => {
        if (!selectedSlideId) {
            if ((slides !== null) && (slides.length === 0)) {
                return <EditorAreaPlaceholder mode={EditorAreaPlaceholderMode.NO_SLIDES}/>;
            }

            return;
        }

        switch (loadingState) {
            case LoadingStateEnum.NOT_LOADED:
            case LoadingStateEnum.IN_PROCESS: {
                return <DefaultLoader/>;
            }
            case LoadingStateEnum.ERROR: {
                return <ErrorLoadingContent title={t`Не удалось загрузить содержимое слайда`}
                                            retryBtnClick={() => {
                                                setLoadingState(LoadingStateEnum.NOT_LOADED);
                                            }}/>
            }
            case LoadingStateEnum.SUCCESS: {
                const needShowNoContentNotice = (
                    (selectedSlideId)
                    && (slidesSavedContent[selectedSlideId] !== undefined)
                    && (slidesSavedContent[selectedSlideId].items.length === 0)
                    && (
                        (slidesDraftContent[selectedSlideId] === undefined)
                        || (slidesDraftContent[selectedSlideId].items.length === 0)
                    )
                );

                return <>
                    <EditorArea contentSaveState={contentSaveState} ref={slideEditorRef}/>
                    {
                        (needShowNoContentNotice)
                        && <EditorAreaPlaceholder mode={EditorAreaPlaceholderMode.NO_SLIDE_CONTENT}/>
                    }
                </>;
            }
            default: {
                return;
            }
        }
    }

    return <EditorContentSideStyled className={props.className}>
        <Header needSave={needSave}
                getExercisesCount={getExercisesCount}
                setContentSaveState={setContentSaveState}/>
        {content()}
    </EditorContentSideStyled>
});