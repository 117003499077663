export enum PayTypeEnum {
    // Операция пополнения счёта студента
    STUDENT_MANUAL_REFILL = 1,

    // Операция списания со счёта студента за занятие.
    // Пока устарела, но может будет актуально.
    LESSON_PAYMENT = 2,

    // Начисление преподавателю за занятие
    TEACHER_PAY_FOR_LESSON = 3,

    // Ручная операция преподавателю
    TEACHER_HAND_OPERATION = 4,

    // Корректировка баланса для РКО преподавателя
    ADJUSTMENT_TEACHER_BALANCE_FOR_RKO = 5,

    // операция с балансом заказчика
    CUSTOMER_BALANCE_OPERATION = 6,

    // корректировка счёта студента банковской выпиской
    STUDENT_BANK_STATEMENT_DOC_OPERATION = 7,

    // корректировка счёта заказчика банковской выпиской
    BANK_STATEMENT_DOC_OPERATION_FOR_CUSTOMER = 8,

    // списание за занятие с AGREEMENTS
    STUDENT_AGREEMENT_PAY_FOR_LESSON = 9,

    // оплата через эквайринг
    STUDENT_PAYMENT_VIA_ACQUIRING = 10,
}
