import {IsNotEmpty, IsNumber, IsOptional, IsString, Length, Max, Min, ValidateNested} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {DtoTmSlideContent} from "./DtoTmSlideContent";
import {Type} from "class-transformer";

/**
 * DTO ответа на запрос контента слайда урока
 */
export class DtoTmSlideContentResponse {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    slideId: string;

    @IsNotEmpty()
    @IsNumber()
    @Min(1)
    @Max(255)
    version: string;

    @IsNotEmpty()
    @IsNumber()
    @Min(0)
    @Max(50)
    exerciseCount: number;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoTmSlideContent)
    content: DtoTmSlideContent | null;
}
