import * as React from "react";
import styled from "styled-components";
import {Trans} from "@lingui/macro";
import {AddLevelItem} from "./CreateNewLevelItem";

const LoadMoreLevelItemStyled = styled(AddLevelItem)`
  margin-bottom: 10px;
  padding-left: 55px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 40px;
`;

interface LoadMoreLevelItemProps {
    onClick: () => void;
}

export const LoadMoreLevelItem: React.FC<LoadMoreLevelItemProps> = (props) => {
    return (
        <LoadMoreLevelItemStyled onClick={props.onClick}>
            <PlusText><Trans>Показать ещё</Trans></PlusText>
        </LoadMoreLevelItemStyled>
    );
}