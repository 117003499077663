import React from "react";
import {
    DtoTmOrganization
} from "../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoTmDisciplineWithLevels
} from "../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmDisciplineWithLevels";
import styled from "styled-components";
import {TmDisciplineModal} from "../../../pages/teacher/teaching-materials/catalog-old/disciplines-list/modal";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../Ui/Elements/NoticeBlock";
import {DisciplineItem} from "./discipline-item";
import {PageSubtitle2} from "../../../styles/global-elements";
import {ModalResultType} from "../modals/discipline-modal";
import {DtoTmDiscipline} from "../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";

const OVERVIEW_COUNT_PER_PAGE = 500;

const Wrapper = styled.div``;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

const DisciplineListGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.medium}) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }

  @media (${({theme}) => theme.media.large}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const DisciplineGridItem = styled.div``;

const BtnWrapper = styled.div`
  margin-bottom: 20px;
  display: block;
  //text-align: right;
`;

enum LoadingState {
    NOT_INIT,
    LOADING_OVERVIEW,
    SUCCESS,
    ERROR
}

interface DisciplineSectionProps {
    apiToken: string;
    selectMode?: boolean;
    selectedOrganization: DtoTmOrganization;
    navigateToLevelMethod?: (toLevelId: string) => void;
    usedDisciplineIds?: string[]; // отмечаются кружком дисциплины из списка, последняя из списка выделяется флажком
    usedLevelIds?: string[]; // отмечаются кружком уровни из списка, последняя из списка выделяется флажком
}

interface DisciplineSectionState {
    loadingState: LoadingState;
    overviewItems: DtoTmDisciplineWithLevels[];
    totalOverviewItemsCount: number | null;
}

export class TmDisciplineSection extends React.Component<DisciplineSectionProps, DisciplineSectionState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    constructor(props: Readonly<DisciplineSectionProps> | DisciplineSectionProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            overviewItems: [],
            totalOverviewItemsCount: null
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }
    }

    resetList = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_OVERVIEW,
                overviewItems: [],
                totalOverviewItemsCount: null
            }
        }, () => {
            this.fetchOverview().then()
        });
    }

    protected fetchOverview = async () => {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_OVERVIEW
            }
        });

        try {
            const items = await this.apiClient.tmOverviewByOrganization(
                this.props.apiToken,
                this.props.selectedOrganization.id,
                (this.state.overviewItems.length === 0)
                    ? 1
                    : (Math.round(this.state.overviewItems.length / OVERVIEW_COUNT_PER_PAGE) + 1),
                OVERVIEW_COUNT_PER_PAGE,
                this.abortController
            );

            this.setState(() => {
                return {
                    overviewItems: [...this.state.overviewItems, ...items.data.disciplines],
                    totalOverviewItemsCount: items.data.totalCount,
                    loadingState: LoadingState.SUCCESS
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            this.logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error loading organization overview: ", e);
        }
    }

    protected loadMoreButton = () => {
        return <BtnWrapper>
            <Button btnStyle={BtnStyleEnum.Secondary} onClick={this.fetchOverview}>Показать ещё</Button>
        </BtnWrapper>;
    }

    protected addDisciplineButton = () => {
        if (this.props.selectMode === true) {
            return;
        }

        return <BtnWrapper>
            <TmDisciplineModal
                organizationId={this.props.selectedOrganization.id}
                disciplineItem={null}
                result={this.resetList}
                trigger={
                    <Button btnStyle={
                        (this.state.overviewItems.length > 0)
                            ? BtnStyleEnum.Secondary
                            : BtnStyleEnum.Primary
                    }><Trans>Добавить дисциплину</Trans></Button>
                }/>
        </BtnWrapper>;
    }

    protected emptyListMessage = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle><Trans>Список дисциплин пуст</Trans></NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>Каталог учебных материалов делится на несколько уровней.</Trans><br/>
                    <Trans>Самый верхний уровень - дисциплина. Дисциплина, это название предмета: Английский язык,
                        Математика и т.п.</Trans><br/><br/>

                    {
                        (this.props.selectMode === true)
                        &&
                        <Trans>Перейдите в раздел «Учебные материалы», чтобы создать начать создание собственных учебных
                            материалов.</Trans>
                    }
                    {
                        (this.props.selectMode !== true)
                        && <Trans>Начните создание своих учебных материалов, нажав по кнопке «Добавить
                            дисциплину»</Trans>
                    }
                </NoticeBlockText>
            </>
        </NoticeBlock>
    }

    protected disciplineItemModelOnResult = (action: ModalResultType, editeditem: (DtoTmDiscipline | null)) => {
        if (action === ModalResultType.DELETED) {
            this.resetList();

            return;
        }

        if (editeditem === null) {
            return;
        }

        if (action === ModalResultType.UPDATED) {
            this.setState((state) => {
                return {
                    overviewItems: state.overviewItems.map((item) => {
                        if (item.id === editeditem.id) {
                            item.name = editeditem.name;
                        }

                        return item;
                    })
                }
            })
        }
    }


    render() {
        if (this.state.loadingState === LoadingState.SUCCESS && this.state.overviewItems.length === 0) {
            return <Wrapper>
                {this.emptyListMessage()}<br/>
                {this.addDisciplineButton()}
            </Wrapper>;
        }

        return <Wrapper>
            {
                (this.state.overviewItems.length > 0) && <Title><Trans>Дисциплины</Trans></Title>
            }
            <DisciplineListGrid>
                {
                    this.state.overviewItems.map((item) => {
                        return <DisciplineGridItem key={item.id}>
                            <DisciplineItem apiToken={this.props.apiToken} selectedDiscipline={item}
                                            organizationId={this.props.selectedOrganization.id}
                                            modalOnResult={this.disciplineItemModelOnResult}
                                            selectMode={this.props.selectMode}
                                            usedDisciplineIds={this.props.usedDisciplineIds}
                                            usedLevelIds={this.props.usedLevelIds}
                                            navigateToLevelMethod={this.props.navigateToLevelMethod}
                                            initLevelItems={item.levels} initTotalLevelsCount={item.totalCount}/>
                        </DisciplineGridItem>
                    })
                }
            </DisciplineListGrid>
            {(this.state.loadingState === LoadingState.LOADING_OVERVIEW) && <DefaultLoader/>}
            {(this.state.loadingState === LoadingState.ERROR) &&
                <ErrorLoadingContent retryBtnClick={this.fetchOverview}
                                     title={t`Не удалось загрузить список дисциплин`}/>}
            {(
                (this.state.totalOverviewItemsCount !== null)
                && (this.state.loadingState === LoadingState.SUCCESS)
                && (this.state.overviewItems.length > 0)
                && (this.state.overviewItems.length < this.state.totalOverviewItemsCount)
            ) && this.loadMoreButton()}
            {this.state.loadingState === LoadingState.SUCCESS && this.addDisciplineButton()}
        </Wrapper>;
    }
}