import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import {Descendant} from "slate";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {Avatar} from "../../../Ui/Elements/Avatar";
import {NodesToPlainText} from "../../components/text-editor/NodesToPlainText";
import {UserFileFormatsEnum, UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import {ImageCropperUploader, ImageCropperUploaderRefMethods} from "../../../Ui/Elements/ImageCropperUploader";
import {Voiceover} from "./VoiceoverChapter";
import {ReactComponent as DeleteVoiceoverSvg} from "../../../Ui/Svg/DeleteVoiceover.svg";
import {ConfirmDialog} from "../../../Ui/Elements/ConfirmDialog";

const Wrapper = styled.div`
    padding: 10px 0;
`

const ChapterWrapper = styled.div`
    padding: 10px;
`;

const ChapterTitle = styled.div`
    color: ${({theme}) => theme.colors.textSecondary};
`;

const AvatarAndNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const AvatarWrapper = styled.div`
    min-width: 64px;
    min-height: 64px;
    max-width: 64px;
    max-height: 64px;
`;

const NameWrapper = styled.div`
    flex-grow: 1;
    padding-top: 5px;
`;

const VoiceOverControls = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
`;

const VoiceOverPlayerWrapper = styled.div`
    flex-grow: 1;
`;

const VoiceOverDeleteBtnWrapper = styled.div`
    min-width: 40px;
    max-width: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const DeleteVoiceoverIconStyled = styled(DeleteVoiceoverSvg)`
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
`;

type DialogBlockType = {
    avatarFileId: string | null;
    soundFileId: string | null;
    name: null | string | Descendant[];
    text: null | string | Descendant[];
}

enum Elements {
    NAME,
    TEXT
}

interface DialogBlockProps extends ItemPropsInterface<DialogBlockType> {

}

interface DialogBlockRefMethods extends ItemRefMethodsInterface {
}

export const DialogBlock = forwardRef<DialogBlockRefMethods, DialogBlockProps>(
    (props, ref) => {
        const {initialData} = props;

        const richTextEditorNameRef = useRef<RichTextEditor>(null);
        const richTextEditorBodyRef = useRef<RichTextEditor>(null);
        const cropperRef = useRef<ImageCropperUploaderRefMethods>(null);

        const [lastFocusedElement, setLastFocusedElement] = useState<Elements>(Elements.NAME);

        const [state, setState] = useState<DialogBlockType>(() => {
            if (initialData === null) {
                return {
                    name: "",
                    text: "",
                    avatarFileId: null,
                    soundFileId: null
                }
            }

            return {
                name: initialData.name,
                text: initialData.text,
                avatarFileId: initialData.avatarFileId,
                soundFileId: initialData.soundFileId,
            }
        });

        const nameForAvatar = useMemo<string>(() => {
            if (state.name !== '') {
                return NodesToPlainText.convertNodesToPlainText(state.name as Descendant[]);
            }

            return '';
        }, [state.name]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                switch (lastFocusedElement) {
                    case Elements.NAME: {
                        richTextEditorNameRef.current?.toolbarItemOnToggle(buttonType, newValue);

                        break;
                    }
                    case Elements.TEXT:
                    default: {
                        richTextEditorBodyRef.current?.toolbarItemOnToggle(buttonType, newValue);

                        break;
                    }
                }
            },
            getExercisesCount: () => {
                return 1; // Это всегда считается одним упражнением
            }
        }));

        useEffect(() => {
            props.onChange(state);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [state]);

        const filterEditorText = useCallback((text: Descendant[]): Descendant[] | null => {
            if (NodesToPlainText.convertNodesToPlainText(text).trim() !== '') {
                return text;
            }

            return null;
        }, []);

        const nameOnChange = useCallback((name: string | Descendant[] | null) => {
            setState((currentState) => {
                return {
                    ...currentState,
                    name: (name === null) ? null : filterEditorText(name as Descendant[])
                };
            });
        }, [filterEditorText]);

        const textOnChange = useCallback((text: string | Descendant[] | null) => {
            setState((currentState) => {
                return {
                    ...currentState,
                    text: (text === null) ? null : filterEditorText(text as Descendant[])
                };
            });
        }, [filterEditorText]);

        const fileOnChange = useCallback((avatarFileId: string | null, soundFileId: string | null) => {
            setState((currentState) => {
                return {
                    name: currentState.name,
                    text: currentState.text,
                    avatarFileId: avatarFileId ?? currentState.avatarFileId,
                    soundFileId: soundFileId ?? currentState.soundFileId
                };
            });
        }, [setState]);

        const deleteAudio = useCallback((): Promise<void> => {
            setState((currentState) => {
                return {
                    ...currentState,
                    soundFileId: null
                };
            });

            return Promise.resolve();
        }, [setState]);

        return <Wrapper>
            <ChapterWrapper>
                <AvatarAndNameWrapper>
                    <AvatarWrapper>
                        <Avatar size={64}
                                userName={nameForAvatar}
                                fileId={state.avatarFileId}
                                allowUpload={true}
                                onUploadClick={() => {
                                    cropperRef.current?.selectFile()
                                }}
                                fileFormat={UserFileFormatsEnum.TM_SLIDE_BUBBLE_ELEMENT_AVATAR_ORIGINAL}
                        />
                        <ImageCropperUploader
                            ref={cropperRef}
                            fileType={UserFileTypeEnum.TM_SLIDE_DIALOG_BUBBLE_ELEMENT_AVATAR}
                            handleFile={(fileId) => {
                                fileOnChange(fileId, null);

                                return Promise.resolve();
                            }}
                            config={{
                                width: 128,
                                height: 128,
                                shape: "round",
                                aspectRatio: 1
                            }}
                        />
                    </AvatarWrapper>

                    <NameWrapper>
                        <ChapterTitle><Trans>Имя героя</Trans></ChapterTitle>
                        <RichTextEditor editorMode={RichTextEditorMode.ONLY_TEXT}
                                        placeHolderText={t`Имя героя`}
                                        setToolbarConfigById={props.setToolbarConfigById}
                                        ref={richTextEditorNameRef}
                                        onChange={nameOnChange}
                                        initialData={state.name}
                                        id={props.id}
                                        onFocus={() => setLastFocusedElement(Elements.NAME)}
                        />
                    </NameWrapper>
                </AvatarAndNameWrapper>
            </ChapterWrapper>

            <ChapterWrapper>
                <ChapterTitle><Trans>Фраза героя текстом</Trans></ChapterTitle>
                <RichTextEditor editorMode={RichTextEditorMode.ONLY_TEXT}
                                placeHolderText={t`Фраза героя`}
                                setToolbarConfigById={props.setToolbarConfigById}
                                ref={richTextEditorNameRef}
                                onChange={textOnChange}
                                initialData={state.text}
                                id={props.id}
                                onFocus={() => setLastFocusedElement(Elements.TEXT)}
                />
            </ChapterWrapper>

            <ChapterWrapper>
                <ChapterTitle><Trans>Озвучка фразы</Trans></ChapterTitle>

                <VoiceOverControls>
                    <VoiceOverPlayerWrapper>
                        <Voiceover fileId={state.soundFileId} onChange={(value) => fileOnChange(null, value)}/>
                    </VoiceOverPlayerWrapper>
                    {
                        (state.soundFileId !== null)
                        && <VoiceOverDeleteBtnWrapper>
                            <ConfirmDialog
                                okText={t`Удалить`}
                                cancelText={t`Отмена`}
                                title={t`Удалить озвучку фразы?`}
                                okMethod={deleteAudio}
                                trigger={<DeleteVoiceoverIconStyled/>}
                            />
                        </VoiceOverDeleteBtnWrapper>
                    }
                </VoiceOverControls>
            </ChapterWrapper>
        </Wrapper>
    }
)