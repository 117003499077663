import {IsNotEmpty,} from "class-validator";
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO результата ping запроса.
 */
export class PingResultDto implements IBaseApiDto {
    @IsNotEmpty()
    currentDate: string;
}
