import {forwardRef, useImperativeHandle} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {
    CustomElement
} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";

interface ExerciseCheckboxProps extends IElementProps<CustomElement[]> {
}

interface ExerciseCheckboxRefMethods extends IElementRefMethods {
}

export const ExerciseCheckbox = forwardRef<ExerciseCheckboxRefMethods, ExerciseCheckboxProps>(
    (props, ref) => {

        const {elementData} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <RichTextReader elements={elementData.data}/>;
    }
);

ExerciseCheckbox.displayName = 'ExerciseCheckbox';