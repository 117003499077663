import * as React from "react";
import {Dispatch} from "redux";
import {LessonRoomStateTypes, RoomConnectionParams} from "../../../../../store/lessonRoom/type";
import {
    clearRoomState,
    handleLeaveFromRoomViaRouter,
    loadRoomMembersList,
    setLessonRoomId,
    setLessonRoomState,
    setRoomConnectionParams
} from "../../../../../store/lessonRoom/actions";
import {setCameraRequestAccessNow} from "../../../../../store/app/actions";
import {resetLessonMaterialsState} from "../../../../../store/lessonMaterials/actions";
import {ApplicationState} from "../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {HorizontalNavigation} from "../../../../components/Ui/Elements/HorizontalNavigation";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {t} from "@lingui/macro";
import {LessonPageContext} from "../LessonPageContext";
import {LessonHomeworkMaterialsModePage} from "./LessonHomeworkMaterialsMode/LessonHomeworkMaterialsModePage";
import LessonHomeworkResultsMode from "./LessonHomeworkResultsMode";
import {ViewModeEnum} from "../../../../components/Ui/Elements/HorizontalNavigation/ViewModeEnum";

enum SubpageModeEnum {
    HOMEWORK_MATERIALS = 'homework_materials',
    HOMEWORK_RESULTS = 'homework_results',
}

interface HomeworkModePageProps extends PropsFromRedux {

}

interface HomeworkModePageState {
    activeSubpageMode: SubpageModeEnum;
}

class HomeworkModePage extends React.Component<HomeworkModePageProps, HomeworkModePageState> {
    protected logger: ILogger;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<HomeworkModePageProps> | HomeworkModePageProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.state = {
            activeSubpageMode: SubpageModeEnum.HOMEWORK_MATERIALS
        }
    }

    protected activeModeOnChange = (newValue: string) => {
        this.setState(() => {
            return {
                activeSubpageMode: newValue as SubpageModeEnum
            }
        });
    }

    protected content = () => {
        return <>
            {/*HOMEWORK_MATERIALS*/}
            <div style={{display: (this.state.activeSubpageMode === SubpageModeEnum.HOMEWORK_MATERIALS) ? "block" : "none"}}
                 key={"homework_materials"}>
                {
                    ((this.context.lessonIntId !== null) && (this.context.groupIntId !== null))
                    &&  <LessonHomeworkMaterialsModePage
                    ref={this.context.lessonHomeworkMaterialsPageRef}
                    refToLessonHomeworkMaterialsPage={null}
                    refToLessonMaterialsPage={this.context.lessonMaterialsPageRef}
                    materialsDtoList={this.context.lessonHomeworkMaterialsList}
                    apiToken={this.props.apiToken}/>
                }
            </div>
            {/*HOMEWORK_RESULTS*/}
            <div style={{display: (this.state.activeSubpageMode === SubpageModeEnum.HOMEWORK_RESULTS) ? "block" : "none"}}
                 key={"homework_results"}>
                <LessonHomeworkResultsMode homeworkIsAvailableForStudents={this.context.homeworkIsAvailableForStudents}/>
            </div>
        </>
    }

    render() {
        return <div>
            <HorizontalNavigation onItemChange={this.activeModeOnChange}
                                  activeItemId={this.state.activeSubpageMode}
                                  viewMode={ViewModeEnum.BOOKMARKS}
                                  items={
                                      [
                                          {
                                              id: SubpageModeEnum.HOMEWORK_MATERIALS,
                                              name: t`Упражнения`
                                          },
                                          {
                                              id: SubpageModeEnum.HOMEWORK_RESULTS,
                                              name: t`Результаты выполнения`
                                          }
                                      ]
                                  }/>
            {this.content()}
        </div>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setRoomConnectionParams: (connectionParams: RoomConnectionParams | null) =>
        dispatch(setRoomConnectionParams(connectionParams)),
    setRoomConnectionState: (state: LessonRoomStateTypes) =>
        dispatch(setLessonRoomState(state)),
    setCurrentRoomId: (roomId: string | null) =>
        dispatch(setLessonRoomId(roomId)),
    setCameraRequestAccessNow: (value: boolean) =>
        dispatch(setCameraRequestAccessNow(value)),
    loadLessonRoomMembers: (roomId: string) =>
        dispatch(loadRoomMembersList(roomId)),
    clearActiveRoomState: () =>
        dispatch(clearRoomState()),
    leaveFromRoomViaRouter: () =>
        dispatch(handleLeaveFromRoomViaRouter()),
    resetLessonMaterialsState: () =>
        dispatch(resetLessonMaterialsState())
});

const mapStateToProps = ({user, app, layout}: ApplicationState) => ({
    userId: (user.profileData?.id) ?? null,
    stToken: user.stToken,
    apiToken: user.sessionToken,
    currentStUserId: (user.profileData) ? user.profileData.schools[0].accounts[0].stId : null,
    accessToCameraAllowedInSession: app.cameraAccess.allowedInSession,
    accessToCameraRequestingNow: app.cameraAccess.requestAccessNow,
    currentTheme: layout.activeTheme,
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomeworkModePage);
