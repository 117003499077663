import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t} from "@lingui/macro";

const ExerciseFillGapsTextStyle = styled.div`
`

interface ExerciseFillGapsTextProps extends ItemPropsInterface<any> {
}

interface ExerciseFillGapsTextRefMethods extends ItemRefMethodsInterface {
}

export const ExerciseFillGapsText = forwardRef<ExerciseFillGapsTextRefMethods, ExerciseFillGapsTextProps>(
    (props, ref) => {

        const richTextEditorRef = useRef<RichTextEditor>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                richTextEditorRef.current?.toolbarItemOnToggle(buttonType, newValue);
            },
            getExercisesCount: () => {
                if (!richTextEditorRef.current) {
                    return 0;
                }

                return richTextEditorRef.current?.getExercisesCount();
            }
        }));

        return <ExerciseFillGapsTextStyle>
            <RichTextEditor editorMode={RichTextEditorMode.FILL_GAPS_TEXT}
                            placeHolderText={t`Упражнение "Заполнить пропуски"`}
                            setToolbarConfigById={props.setToolbarConfigById}
                            ref={richTextEditorRef}
                            onChange={props.onChange}
                            initialData={props.initialData}
                            id={props.id}
            />
        </ExerciseFillGapsTextStyle>;
    }
);