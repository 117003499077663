import {IsNotEmpty, IsString} from "class-validator";

/**
 * DTO элемента ответа на запрос поиска временной зоны
 */
export class DtoTimezoneSearchResponseItem {
    @IsNotEmpty()
    @IsString()
    offset: string;

    @IsNotEmpty()
    @IsString()
    zoneName: string;

    @IsNotEmpty()
    @IsString()
    currentTime: string;
}
