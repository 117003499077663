import React from "react";
import {
    DtoSelfStudyTrackSlideItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSlideItem";
import {SlideWorkData} from "../../../../../store/slidesWorkData/type";
import {SlideItemsParamsBySlide} from "../../../../components/SlidePlayer/SlideItemParamsStore";

export interface ICommonContext {
    playerId: string,
    selectedTmSlideId: string | null,
    setSelectedTmSlideId: (value: string) => void,
    slideList: DtoSelfStudyTrackSlideItem[],
    selectedSlideIndex: number,
    currentSlideWorkData: SlideWorkData | null,
    goNext: () => void,
    nextButtonDisabled: boolean,
    nextButtonLabel: string | undefined,
    currentProgressValuePercent: number,
    slideItemsParamsBySlide: SlideItemsParamsBySlide
}

export const CommonContext = React.createContext<ICommonContext>({
    playerId: '',
    selectedTmSlideId: null,
    setSelectedTmSlideId: () => {},
    slideList: [],
    selectedSlideIndex: 0,
    currentSlideWorkData: null,
    goNext: () => {},
    nextButtonDisabled: true,
    nextButtonLabel: undefined,
    currentProgressValuePercent: 0,
    slideItemsParamsBySlide: {}
});

export const CommonContextProvider = CommonContext.Provider;