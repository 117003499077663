import React, {forwardRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";

interface TestDivProps extends ItemPropsInterface<any> {
}

interface TestDivRefMethods extends ItemRefMethodsInterface {

}

export const TestDiv = forwardRef<TestDivRefMethods, TestDivProps>((params, ref) => {
    return <div>
        Test div with input <input/>
    </div>;
})