import {takeEvery, call, select, put} from 'redux-saga/effects';
import {StreamEventActionTypes} from "../../store/streamEvent/type";
import {ApplicationState} from "../../store";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {StreamHistoryRequestDto} from "../../components/WsApiClient/ApiDto/Request/StreamHistory/StreamHistoryRequestDto";
import {WsServerConstants} from "../../components/WsApiClient/WsApiClient";
import {rebootApp} from "../../store/app/actions";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";

/**
 * Сага отвечает за отправку запроса на получение stream events, за некоторый период
 */
export function* watchLoadEventsAfterReconnect() {
    yield takeEvery(
        StreamEventActionTypes.LOAD_EVENTS_AFTER_RECONNECT,
        loadEventsAfterReconnect
    );
}

const getDateOfLastEvent = (state: ApplicationState) => state.streamEvent.dateOfLastEvent;
const getLastPingDate = (state: ApplicationState) => state.app.lastPingDate;

function* loadEventsAfterReconnect() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    let wsClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
    let dateHelperService: IDateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

    let targetDateString: string | null = null;

    // В первую очередь нас интересует время последнего события, если есть
    let dateOfLastEventString: string | null = yield select(getDateOfLastEvent);

    if (dateOfLastEventString !== null) {
        targetDateString = dateOfLastEventString;
    }

    // Если нет даты последнего события, скорее всего никаких событий приложение не получало,
    // пока сеть работала, пытаемся взять pingDate.
    if (targetDateString === null) {
        let lastPingDate: string = yield select(getLastPingDate);

        if (lastPingDate !== null) {
            targetDateString = lastPingDate;
        }
    }

    // Если и даты пинга нет, это странно. Лучше перестраховаться и перезагрузить страницу.
    if (targetDateString === null) {
        yield put(rebootApp());

        return;
    }

    // Если удалось выяснить время последнего присутствия сети,
    // проверим - не слишком ли давно сеть была в наличии
    let targetDateObject = dateHelperService.dateFromString(targetDateString);

    if (process.env.REACT_APP_MAX_PERIOD_OFFLINE_HOURS === undefined) {
        throw new Error("Not found value MAX_PERIOD_OFFLINE_HOURS");
    }

    const maxValueOfflineHours = parseInt(process.env.REACT_APP_MAX_PERIOD_OFFLINE_HOURS);

    let maxOfflinePeriodSeconds: number = maxValueOfflineHours * 60 * 60;

    if (dateHelperService.secondsBetween(targetDateObject, new Date()) >= maxOfflinePeriodSeconds) {
        // Период времени нахождения оффлайн превысил допустимые лимиты. Перезагрузим страницу.
        yield put(rebootApp());

        return;
    }

    let eventHistoryRequestDto = new StreamHistoryRequestDto();
    eventHistoryRequestDto.afterTs = dateHelperService.formatDateObjectToIsoString(targetDateObject);
    eventHistoryRequestDto.offset = 0;
    eventHistoryRequestDto.limit = WsServerConstants.STREAM_EVENT_HISTORY_MAX_BATCH_SIZE;

    logger.debug(
        LoggerSectionsEnum.WS_STREAM_EVENT,
        `Load stream events history after ${eventHistoryRequestDto.afterTs}`
    );

    yield call(() => wsClient.query(
        ApiMethodEnum.STREAM_EVENT_HISTORY,
        eventHistoryRequestDto
    ));
}
