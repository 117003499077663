import {ErrorsResponseData} from "./ErrorsResponseData";
import {IsNotEmpty, IsNumber, IsString} from "class-validator";
import {WsResponseStatusEnum} from "../../WsResponseStatusEnum";

export class ResponseBaseDto<ResultType> {
    @IsNotEmpty()
    id: number | string;

    @IsNotEmpty()
    @IsString()
    method: string;

    @IsNotEmpty()
    @IsNumber()
    @IsNotEmpty()
    status: WsResponseStatusEnum;
    params: any;
    result: ResultType;
    errors: ErrorsResponseData | null;
}