import {ISoundPlayer} from "./ISoundPlayer";
import {SoundLinkListType} from "./SoundLinkListType";
import {SoundsEnum} from "./SoundsEnum";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

enum AudioObjectReadyState {
    HAVE_FUTURE_DATA = 3,
    HAVE_ENOUGH_DATA = 4
}

export class SoundPlayer implements ISoundPlayer {
    protected logger: ILogger;
    protected audioObjects: { [n: number]: HTMLAudioElement };
    protected initialized: boolean;
    protected listOfRelativeLinksToSounds: SoundLinkListType;
    protected appHomeUrl: string;

    constructor(loggerObject: ILogger, listOfRelativeLinks: SoundLinkListType) {
        this.logger = loggerObject;
        this.audioObjects = {};
        this.listOfRelativeLinksToSounds = listOfRelativeLinks;
        this.initialized = false;
        this.appHomeUrl = '';
    }

    /**
     * {@inheritDoc}
     */
    loadSounds(): void {
        for (let soundType in this.listOfRelativeLinksToSounds) {
            if (!this.listOfRelativeLinksToSounds.hasOwnProperty(soundType)) {
                continue;
            }

            let soundTypeInt = (soundType as unknown as number);

            this.audioObjects[soundTypeInt] = new Audio(
                this.listOfRelativeLinksToSounds[soundTypeInt]
            );

            this.audioObjects[soundTypeInt].addEventListener('error', (e: Event) => {
                this.onError(e);
            });

            this.audioObjects[soundTypeInt].load();
        }

        this.initialized = true;

        this.logger.debug(LoggerSectionsEnum.SOUND_PLAYER, 'Objects is created');
    }

    /**
     * {@inheritDoc}
     */
    playSound(soundType: SoundsEnum): void {
        if (!this.initialized) {
            this.logger.warning(LoggerSectionsEnum.SOUND_PLAYER, 'playSound method called before loadSounds.');

            return;
        }

        if (this.playerAvailable()) {
            this.audioObjects[soundType].pause();
            this.audioObjects[soundType].currentTime = 0;

            this.audioObjects[soundType].play().then(() => {
                this.logger.debug(LoggerSectionsEnum.SOUND_PLAYER, `Play sound ${soundType} request handled.`);
            }).catch(() => {
                this.logger.debug(LoggerSectionsEnum.SOUND_PLAYER, `Play sound ${soundType} request could not be completed because play Promise failed.`);
            });
        } else {
            this.logger.debug(LoggerSectionsEnum.SOUND_PLAYER, `Play sound ${soundType} request could not be completed because player is busy.`);
        }
    }

    /**
     * {@inheritDoc}
     */
    playerAvailable(): boolean {
        if (!this.initialized) {
            return false;
        }

        for (let soundType in this.audioObjects) {
            if (!this.audioObjects.hasOwnProperty(soundType)) {
                continue;
            }

            let soundTypeInt = (soundType as unknown as number);

            if (this.audioObjects[soundTypeInt].onplaying) {
                return false;
            }

            if (![AudioObjectReadyState.HAVE_ENOUGH_DATA, AudioObjectReadyState.HAVE_FUTURE_DATA].includes(this.audioObjects[soundTypeInt].readyState)) {
                return false;
            }
        }

        return true;
    }

    onError(e: Event) {
        if (e.currentTarget && e.currentTarget instanceof Audio && e.currentTarget.error instanceof MediaError) {
            this.logger.error(
                LoggerSectionsEnum.SOUND_PLAYER,
                `Error with file ${e.currentTarget.currentSrc}: `,
                e.currentTarget.error
            );
        } else {
            this.logger.error(
                LoggerSectionsEnum.SOUND_PLAYER,
                `Unknown error in audio component: ${JSON.stringify(e)} `
            );
        }
    }

}
