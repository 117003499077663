import * as React from "react";
import {forwardRef} from "react";
import styled from 'styled-components';
import {Input as AntInput} from 'antd';
import {InputProps} from 'antd/lib/input';
import {InputRef} from "antd/lib/input/Input";
import classNames from "classnames";
import {RegularFontSize} from "../../../styles/global-elements";

const InputStyled: React.FunctionComponent<InputProps & React.RefAttributes<InputRef>> = styled(AntInput)`
  ${RegularFontSize};
  border: none;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};

  &:focus, &:hover {
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-right-width: inherit;
    box-shadow: none;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    border-color: #ff4d4f;
    box-shadow: none;
    border-right-width: inherit;
    outline: 0;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.read-only {
    cursor: default;
  }
`;

export const Input: React.FunctionComponent<InputProps & React.RefAttributes<InputRef>> = forwardRef<InputRef, InputProps>(
    ({className, ...props}, ref) => {
        return <InputStyled
            ref={ref}
            className={classNames(props.readOnly && 'read-only', className)}
            autoComplete="false"
            {...props}/>
    })
