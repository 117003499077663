import * as React from "react";
import {useCallback, useMemo, useRef} from "react";
import styled from "styled-components";
import {ReactComponent as Wallet} from "../../../../components/Ui/Svg/Wallet.svg";
import {useSelector} from "react-redux";
import {selectedUserInSchool} from "../../../../../store/user/selector";
import {i18n} from "@lingui/core";
import classNames from "classnames";
import {BalancePopup} from "./BalancePopup";
import {PopupActions} from "reactjs-popup/dist/types";
import {PayOnlinePopup} from "./PayOnlinePopup";
import {RedDot} from "../../../../styles/global-elements";

export const BalanceWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
`;

export const BalanceSum = styled.div`
  margin-right: 0;
  justify-content: center;
  align-items: center;
  display: none;
  color: ${({theme}) => theme.colors.textPrimary};
  position: relative;

  @media (${({theme}) => theme.media.small}) {
    display: flex;
  }
`;

export const BalanceIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.warning::after {
    ${RedDot}
  }
`;

export const WalletStyled = styled(Wallet)`
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const Balance: React.FC = () => {
    const userInSchool = useSelector(selectedUserInSchool);
    const payOnlinePopupRef = useRef<PopupActions>(null);
    const balancePopupRef = useRef<PopupActions>(null);

    const sum = useMemo<number>(() => {
        if (userInSchool === null) {
            return 0;
        }

        return userInSchool.balance;
    }, [userInSchool]);

    const formattedSum = useMemo<string>(() => {
        return i18n.number(sum, {style: "currency", currency: "RUB"});
    }, [sum]);

    const payOnlineOnClick = useCallback(() => {
        payOnlinePopupRef.current?.open()
        balancePopupRef.current?.close();
    }, []);

    return <div>
        <BalancePopup payOnloneOnClick={payOnlineOnClick}
                      ref={balancePopupRef}
                      trigger={
                          <BalanceWrapper>
                              <BalanceSum>{formattedSum}</BalanceSum>
                              <BalanceIconWrapper className={classNames(sum < 0 && "warning")}>
                                  <WalletStyled/>
                              </BalanceIconWrapper>
                          </BalanceWrapper>
                      }
        />
        <PayOnlinePopup ref={payOnlinePopupRef}/>
    </div>
};
