export const getGridClassName = (membersCount: number) => {
    switch (membersCount) {
        case 0:
        case 1: {
            return "one-item";
        }
        case 2: {
            return "two-item";
        }
        case 3: {
            return "three-item";
        }
        case 4: {
            return "four-item";
        }
        case 5: {
            return "five-item";
        }
        case 6: {
            return "six-item";
        }
        case 7: {
            return "seven-item";
        }
        case 8: {
            return "eight-item";
        }
        case 9: {
            return "nine-item";
        }
    }

    return "many-items";
};
