import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../../store/lessonRoom/type";
import {setMemberScreenOrientation} from "../../../store/lessonRoom/actions";
import {container} from "tsyringe";
import {WorkerPayloadType} from "../../WorkerPayloadType";
import {ILogger} from "../../../components/Logger/ILogger";
import {DiTokens} from "../../../di-factory/DiTokens";
import {IWsApiClient} from "../../../components/WsApiClient/IWsApiClient";
import {
    DtoScreenOrientationChange
} from "../../../components/WsApiClient/ApiDto/Request/LessonRoom/PublicState/DtoScreenOrientationChange";
import {ApiMethodEnum} from "../../../components/WsApiClient/ApiMethodEnum";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {currentUserIdSelector} from "../../../store/user/selector";

/**
 * Обработка изменения ориентации СОБСТВЕННОГО экрана (не WS сообщения)
 */
export function* watchProcessScreenOrientationOnChange() {
    yield takeEvery<WorkerPayloadType<{ lessonId: string, newValue: string | null }>>(
        LessonRoomActionTypes.PROCESS_SCREEN_ORIENTATION_ON_CHANGE,
        processScreenOrientationOnChange
    );
}

function* processScreenOrientationOnChange(data: WorkerPayloadType<{ lessonId: string, newValue: string | null }>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    const wsClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    const currentUserId = (yield select(currentUserIdSelector)) as string | null;

    if (currentUserId === null) {
        logger.warning(
            LoggerSectionsEnum.LESSON_ROOM_SCREEN_ORIENTATION_PUBLISHER,
            `Called processScreenOrientationOnChange saga, but currentUserId is null`
        );

        return;
    }

    const dto = new DtoScreenOrientationChange();
    dto.lessonId = data.payload.lessonId;
    dto.value = data.payload.newValue;

    wsClient.query(
        ApiMethodEnum.LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION,
        dto,
        null,
        true
    ).catch((e) => {
        logger.warning(
            LoggerSectionsEnum.LESSON_ROOM_SCREEN_ORIENTATION_PUBLISHER,
            `Public screen orientation error`, e
        );
    });

    yield put(setMemberScreenOrientation(
        currentUserId,
        data.payload.newValue
    ));
}
