import React from "react";
import styled from "styled-components";


interface ExerciseProgressBarProps {
    wrongValue: number;
    rightValue: number;
}

const ExerciseProgressBarWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.progressBarBackground};
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
`;

const ExerciseProgressBarElementBase = styled.div`
  transition: flex-basis .3s linear 0s;
`;

const ProgressBarRight = styled(ExerciseProgressBarElementBase)`
  background-color: ${({theme}) => theme.colors.progressBarRight};
`;

const ProgressBarWrong = styled(ExerciseProgressBarElementBase)`
  background-color: ${({theme}) => theme.colors.progressBarWrong};
  //width: X
`;

export const ExerciseProgressBar: React.FC<ExerciseProgressBarProps> = (props) => {
    const {wrongValue, rightValue} = props;

    return <ExerciseProgressBarWrapper>
        <ProgressBarWrong style={{flexBasis: wrongValue.toString(10) + "%"}}/>
        <ProgressBarRight style={{flexBasis: rightValue.toString(10) + "%"}}/>
    </ExerciseProgressBarWrapper>;
}