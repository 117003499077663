import {
    DtoAgreementItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import {
    DtoGroupData
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoGroupData";
import React, {useCallback, useMemo, useRef} from "react";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {Popup, PopupItem} from "../../../../../components/Ui/Elements/Popup";
import {DisciplineAndLevelModal} from "./DisciplineAndLevelModal";
import {PopupActions} from "reactjs-popup/dist/types";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../../../../../RoutesList";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {ScheduleModal} from "./ScheduleModal";
import styled from "styled-components";
import {NewCostModal} from "./NewCostModal";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";
import {CloseAgreementModal} from "./CloseAgreementModal";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {RestoreAgreementModal} from "./RestoreAgreementModal";
import {DeleteAgreementModal} from "./DeleteAgreementModal";

const ButtonStyled = styled(Button)`
  width: 100%;
`;

interface ChangeBtnProps {
    dto: DtoAgreementItem;
    dtoGroupData: DtoGroupData;
}

export const ChangeBtn: React.FC<ChangeBtnProps> = ({dto, dtoGroupData}) => {
    const navigate = useNavigate();

    const dateHelper = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);

    const disciplineAndLevelModal = useRef<PopupActions>(null);
    const scheduleModal = useRef<PopupActions>(null);
    const newCostModal = useRef<PopupActions>(null);
    const closeAgreementModal = useRef<PopupActions>(null);
    const restoreAgreementModal = useRef<PopupActions>(null);
    const deleteAgreementModal = useRef<PopupActions>(null);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isMedium = useMediaQuery(`(${currentTheme.media.medium})`);

    const goToLessonsBtnClick = useCallback(() => {
        navigate(RoutesHelper.replaceParams(
            RoutesList.TEACHER_GROUP_INFO,
            [
                {
                    key: 'groupId',
                    value: dto.groupId.toString(10)
                }
            ]
        ))
    }, [dto.groupId, navigate]);

    const changeTrainingProgramBtnClick = useCallback(() => {
        disciplineAndLevelModal.current?.open();
    }, []);

    const changeScheduleBtnClick = useCallback(() => {
        scheduleModal.current?.open();
    }, []);

    const setNewCostBtnClick = useCallback(() => {
        newCostModal.current?.open();
    }, []);

    const closeAgreementBtnClick = useCallback(() => {
        closeAgreementModal.current?.open();
    }, []);

    const restoreAgreementBtnClick = useCallback(() => {
        restoreAgreementModal.current?.open();
    }, []);

    const deleteAgreementClick = useCallback(() => {
        deleteAgreementModal.current?.open();
    }, []);

    const popupItems = useMemo<PopupItem[]>(() => {
        const items: PopupItem[] = [];

        items.push({
            text: t`Перейти к урокам`,
            onClick: goToLessonsBtnClick
        });

        items.push({
            text: t`Изменить уровень/дисциплину`,
            onClick: changeTrainingProgramBtnClick
        });

        items.push({
            text: t`Изменить расписание`,
            onClick: changeScheduleBtnClick
        });

        items.push({
            text: t`Задать новую стоимость`,
            onClick: setNewCostBtnClick
        });

        const dateEndWork = dateHelper.setZeroTimeForDateObject(
            dateHelper.dateFromString(dtoGroupData.groupDateEndWork)
        ).getTime();

        const nowDate = dateHelper.getCurrentDateWithoutTime().getTime();

        if (dto.annulled === 0) {
            if (nowDate < dateEndWork) {
                items.push({
                    text: t`Завершить обучение`,
                    onClick: closeAgreementBtnClick
                });
            } else if (dto.anotherGroupParticipants.length === 0) {
                items.push({
                    text: t`Продолжить обучение`,
                    onClick: restoreAgreementBtnClick
                });
            }
        } else {
            if (nowDate < dateEndWork) {
                items.push({
                    text: t`Возобновить обучение`,
                    onClick: restoreAgreementBtnClick
                });
            }
        }

        items.push({
            text: t`Удалить`,
            onClick: deleteAgreementClick,
        });

        return items;
    }, [changeScheduleBtnClick, changeTrainingProgramBtnClick, closeAgreementBtnClick, dateHelper, deleteAgreementClick, dto.annulled, dto.anotherGroupParticipants.length, dtoGroupData.groupDateEndWork, goToLessonsBtnClick, restoreAgreementBtnClick, setNewCostBtnClick]);

    return <>
        <Popup
            items={popupItems}
            position={(isMedium) ? 'top right' : 'top center'}
            trigger={<div><ButtonStyled btnStyle={BtnStyleEnum.Secondary}><Trans>Изменить</Trans></ButtonStyled></div>}
        />
        <DisciplineAndLevelModal
            ref={disciplineAndLevelModal}
            groupId={dto.groupId}
            courseId={dtoGroupData.courseId}
            courseName={dtoGroupData.courseName}
            trainingProgramId={dtoGroupData.trainingProgramId}
            trainingProgramName={dtoGroupData.trainingProgramName}
            anotherParticipants={dto.anotherGroupParticipants}
        />
        <ScheduleModal
            ref={scheduleModal}
            groupId={dto.groupId}
            currentSchedule={dtoGroupData.schedule}
            anotherParticipants={dto.anotherGroupParticipants}
        />
        <NewCostModal
            ref={newCostModal}
            agreementId={dto.id}
        />
        <CloseAgreementModal
            ref={closeAgreementModal}
            dto={dto}
        />
        <RestoreAgreementModal
            ref={restoreAgreementModal}
            dto={dto}
        />
        <DeleteAgreementModal
            ref={deleteAgreementModal}
            dto={dto}
        />
    </>
}