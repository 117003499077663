import {IsNotEmpty, IsOptional, IsString, ValidateNested} from "class-validator";
import {DtoTmSlideContentItemInteractivityConfigActionCondition} from "./DtoTmSlideContentItemInteractivityConfigActionCondition";
import {Type} from "class-transformer";

export class DtoTmSlideContentItemInteractivityConfigAction {
    @IsNotEmpty()
    @IsString()
    actionType: string; // {@see SlideItemInteractivityActionNameEnum}

    @IsOptional()
    @IsString()
    actionAdditionalInfo: any; // Target Slide Item alias.

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoTmSlideContentItemInteractivityConfigActionCondition)
    condition: DtoTmSlideContentItemInteractivityConfigActionCondition;
}