import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import React, {useRef, useState} from "react";
import {Form, FormItem, useForm} from '../../../../../../components/Ui/Elements/Form';
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {Input} from "../../../../../../components/Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {InputLabel, ModalP, ModalTitle} from "../../../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../../store/app/selector";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../../../../components/Ui/Elements/ConfirmDialog";
import {RadioGroup, RadioInput} from "../../../../../../components/Ui/Elements/RadioInput";
import {Space} from "antd";
import {
    DtoTmSlideContentLayoutSettings
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContentLayoutSettings";
import {Checkbox} from "../../../../../../components/Ui/Elements/Checkbox";
import {ThemeEnum} from "../../../../../../../services/theme/ThemeEnum";
import styled from "styled-components";
import {
    ContentLayoutSettingsBackgroundModeEnum
} from "../../../../../../../enums/ContentLayoutSettingsBackgroundModeEnum";
import {UserFileFormatsEnum, UserFileTypeEnum} from "../../../../../../../enums/UserFileEnums";
import {UserImage} from "../../../../../../components/Ui/Elements/UserImage";
import {
    ImageCropperUploader,
    ImageCropperUploaderRefMethods
} from "../../../../../../components/Ui/Elements/ImageCropperUploader";

enum FormFieldNames {
    BACKGROUND_MODE = 'backgroundMode',
    HIDE_LEFT_NAVIGATION = 'hideLeftNavigation',
    NEXT_BUTTON_LABEL = 'nextButtonLabel',
    THEME_NAME = 'themeName',
    TRANSPARENT_HEADER = 'transparentHeader',
}

const ImageButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (${({theme}) => theme.media.medium}) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 500px;
    }
`;

const BackgroundImagePreviewWrapper = styled.div`
    max-width: 500px;
    max-height: 500px;

    display: block;
    width: 100%;
    margin: 10px auto;

    @media (${({theme}) => theme.media.medium}) {
        margin: 10px 0;
    }

    border: 1px ${({theme}) => theme.colors.accentDivider} solid;
`;

interface TmSlideLayoutSettingsModalProps extends ModalChildProps {
    slideId: string;
    currentLayoutSettings: DtoTmSlideContentLayoutSettings | null;
    onUpdate: (newData: DtoTmSlideContentLayoutSettings | null) => void;
}

const FormStyled = styled(Form)`
    .ant-form-item-label {
        margin-bottom: 10px;
    }
`;

export const TmSlideLayoutSettingsModal: React.FC<TmSlideLayoutSettingsModalProps> = (
    {
        trigger,
        slideId,
        currentLayoutSettings,
        onUpdate
    }
) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const cropperRef = useRef<ImageCropperUploaderRefMethods>(null);

    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const [backgroundFileId, setBackgroundFileId] = useState<string | null>(currentLayoutSettings?.backgroundFileId ?? null);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        setCloseAllowed(false);
        setSaveInProcess(true);

        const layoutSettings = new DtoTmSlideContentLayoutSettings();

        layoutSettings.backgroundFileId = backgroundFileId;
        layoutSettings.backgroundMode = (backgroundFileId === null) ? null : values[FormFieldNames.BACKGROUND_MODE];
        layoutSettings.hideLeftNavigation = values[FormFieldNames.HIDE_LEFT_NAVIGATION] ?? false;
        layoutSettings.themeName = values[FormFieldNames.THEME_NAME];
        layoutSettings.transparentHeader = values[FormFieldNames.TRANSPARENT_HEADER] ?? false;

        const nextBtnLabel = (values[FormFieldNames.NEXT_BUTTON_LABEL] as string).trim();

        layoutSettings.nextButtonLabel = (nextBtnLabel.length > 0) ? nextBtnLabel : null;

        console.log(layoutSettings);

        httpApiClient.tmSetSlideContentLayoutSettings(
            sessionToken,
            slideId,
            layoutSettings
        )
            .then((data) => {
                onUpdate(layoutSettings);

                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Сохранено`,
                    t`Конфигурация макета успешно сохранена`
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (slideId) {
                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on update slide layout config: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on update slide layout config: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteSlideContentLayoutSettings(
                sessionToken,
                slideId
            )
        } catch (error) {
            logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on delete slide layout settings: ", error);

            throw error;
        }

        openNotification(
            NotificationTypesEnum.SUCCESS,
            t`Удалено`,
            t`Конфигурация макета успешно удалена`
        );

        onUpdate(null);

        modalRef?.current?.close();
    }

    const changeImageBtnOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        cropperRef.current?.selectFile();
    };

    const footerContent = (controls: ModalControlParams) => {
        if (currentLayoutSettings === null) {
            return <>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Сохранить</Trans>
                </Button>

                <Button btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}
                        onClick={controls.closeModal}>
                    <Trans>Отмена</Trans>
                </Button>
            </>;
        }

        return <>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
            <ConfirmDialog
                trigger={
                    <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                        <Trans>Удалить</Trans>
                    </Button>
                }
                okText={t`Удалить`}
                cancelText={t`Отмена`}
                title={t`Удалить конфигурацию макета?`}
                text={t`Вы уверены, что нужно удалить конфигурацию макета для слайда?`}
                errorText={t`Не удалось удалить информацию`}
                okMethod={onDelete}/>
        </>;
    }

    return <Modal innerRef={modalRef}
                  trigger={trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      setCloseAllowed(true);
                      setSaveInProcess(false);

                      form.resetFields();
                  }}
                  onOpen={() => {
                      setBackgroundFileId(currentLayoutSettings?.backgroundFileId ?? null);
                  }}
                  footer={footerContent}
                  footerMode={FooterMode.SPACE_BETWEEN}
                  children={
                      (controls) => {
                          return <div>
                              <ModalTitle><Trans>Конфигурация макета</Trans></ModalTitle>
                              <ModalP>
                                  <Trans>Дополнительные настройки внешнего вида слайда для большего погружения в
                                      сюжет.</Trans>&nbsp;
                                  <Trans>Работает только в режиме прохождения self-study трека.</Trans>
                              </ModalP>

                              <FormStyled form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={FormFieldNames.TRANSPARENT_HEADER}
                                      initialValue={(currentLayoutSettings) ? currentLayoutSettings.transparentHeader : null}
                                      valuePropName={'checked'}
                                      label={t`Поведение header'а страницы`}
                                  >
                                      <Checkbox><Trans>Скрыть header страницы</Trans></Checkbox>
                                  </FormItem>

                                  <FormItem
                                      name={FormFieldNames.HIDE_LEFT_NAVIGATION}
                                      initialValue={(currentLayoutSettings) ? currentLayoutSettings.hideLeftNavigation : null}
                                      valuePropName={'checked'}
                                      label={t`Поведение левой панели навигации`}
                                  >
                                      <Checkbox><Trans>Скрыть левую панель навигации</Trans></Checkbox>
                                  </FormItem>

                                  <FormItem
                                      name={FormFieldNames.THEME_NAME}
                                      initialValue={(currentLayoutSettings) ? currentLayoutSettings.themeName : ThemeEnum.light}
                                      label={t`Тема на время отображения слайда`}
                                  >
                                      <RadioGroup>
                                          <Space direction={"vertical"}>
                                              <RadioInput value={ThemeEnum.light}>
                                                  <Trans>Включить светлую тему</Trans>
                                              </RadioInput>
                                              <RadioInput value={ThemeEnum.dark}>
                                                  <Trans>Включить тёмную тему</Trans>
                                              </RadioInput>
                                          </Space>
                                      </RadioGroup>
                                  </FormItem>

                                  <InputLabel>
                                      <Trans>Фоновое изображение</Trans>
                                  </InputLabel>

                                  <FormItem>
                                      <ImageCropperUploader ref={cropperRef}
                                                            fileType={UserFileTypeEnum.TM_SLIDE_BACKGROUND_PICTURE}
                                                            handleFile={(fileId) => {
                                                                setBackgroundFileId(fileId);
                                                                return Promise.resolve();
                                                            }}
                                                            config={{
                                                                width: 2000,
                                                                height: 2000,
                                                                shape: "rect",
                                                                quality: 0.9
                                                            }}
                                      />

                                      {
                                          (backgroundFileId !== null)
                                          && <BackgroundImagePreviewWrapper>
                                              <UserImage
                                                  fileId={backgroundFileId}
                                                  fileFormat={UserFileFormatsEnum.TM_SLIDE_BACKGROUND_PICTURE_ORIGINAL}
                                              />
                                          </BackgroundImagePreviewWrapper>
                                      }

                                      {
                                          (backgroundFileId === null)
                                              ? <ImageButtonsWrapper>
                                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                                          onClick={changeImageBtnOnClick}>
                                                      <Trans>Выбрать изображение</Trans>
                                                  </Button>
                                              </ImageButtonsWrapper>
                                              : <ImageButtonsWrapper>
                                                  <Button btnStyle={BtnStyleEnum.Secondary} onClick={changeImageBtnOnClick}>
                                                      <Trans>Изменить картинку</Trans>
                                                  </Button>
                                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                                          onClick={() => setBackgroundFileId(null)}>
                                                      <Trans>Удалить картинку</Trans>
                                                  </Button>
                                              </ImageButtonsWrapper>
                                      }
                                  </FormItem>

                                  {
                                      (backgroundFileId)
                                      && <FormItem
                                          name={FormFieldNames.BACKGROUND_MODE}
                                          initialValue={
                                              (currentLayoutSettings)
                                                  ? currentLayoutSettings.backgroundMode ?? ContentLayoutSettingsBackgroundModeEnum.COVER
                                                  : ContentLayoutSettingsBackgroundModeEnum.COVER
                                          }
                                          label={t`Режим отображения фона`}
                                      >
                                          <RadioGroup>
                                              <Space direction={"vertical"}>
                                                  <RadioInput value={ContentLayoutSettingsBackgroundModeEnum.COVER}>
                                                      <Trans>Растянуть</Trans>
                                                  </RadioInput>
                                                  <RadioInput value={ContentLayoutSettingsBackgroundModeEnum.PAVE}>
                                                      <Trans>Замостить</Trans>
                                                  </RadioInput>
                                              </Space>
                                          </RadioGroup>
                                      </FormItem>
                                  }

                                  <FormItem
                                      name={FormFieldNames.NEXT_BUTTON_LABEL}
                                      initialValue={(currentLayoutSettings) ? currentLayoutSettings.nextButtonLabel ?? '' : ''}
                                      label={t`Надпись на кнопке «Продолжить»`}
                                      rules={[
                                          {
                                              type: "string",
                                              max: 50,
                                              message: t`Должно быть не длиннее 50 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                      />
                                  </FormItem>
                              </FormStyled>
                          </div>
                      }
                  }
    />
}
