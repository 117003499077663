import {call, put, select, takeEvery} from 'redux-saga/effects';
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {UserActionTypes} from "../../store/user/type";
import {collectedUserUtms} from "../../store/commonPersisted/selector";
import {UserUtmSaveRequestDto} from "../../components/HttpApiClient/ApiDto/Request/UserUtm/UserUtmSaveRequestDto";
import {KnownUtmEnum} from "../../enums/KnownUtmEnum";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {sessionTokenSelector} from "../../store/app/selector";
import {setUtmForSave} from "../../store/commonPersisted/actions";

/**
 * Сага отвечает за отправку utm собранных ранее меток
 */
export function* watchSaveUserUtm() {
    yield takeEvery(
        UserActionTypes.SAVE_USER_UTM,
        saveUserUtm
    );
}

function* saveUserUtm() {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const sessionToken = (yield select(sessionTokenSelector)) as string | null;

    if (sessionToken === null) {
        logger.warning(LoggerSectionsEnum.UTM_SAVER, 'Not found session token');

        return;
    }

    const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

    const collectedUtms = (yield (select(collectedUserUtms))) as { [id: string]: string } | null;

    if (!collectedUtms) {
        logger.info(LoggerSectionsEnum.UTM_SAVER, 'No utm');

        return;
    }

    if (collectedUtms instanceof String) {
        logger.info(LoggerSectionsEnum.UTM_SAVER, 'Utm collected as string');

        return;
    }

    const dto = new UserUtmSaveRequestDto();

    for (const [key, value] of Object.entries(collectedUtms)) {
        switch (key) {
            case KnownUtmEnum.UTM_SOURCE: {
                dto.utmSource = value;

                break;
            }
            case KnownUtmEnum.UTM_MEDIUM: {
                dto.utmMedium = value;

                break;
            }
            case KnownUtmEnum.UTM_CAMPAIGN: {
                dto.utmCampaign = value;

                break;
            }
            case KnownUtmEnum.UTM_CONTENT: {
                dto.utmContent = value;

                break;
            }
            case KnownUtmEnum.UTM_TERM: {
                dto.utmTerm = value;

                break;
            }
            case 'routeName': {
                dto.routeName = collectedUtms['routeName'];

                break;
            }
            default: {
                const tempObj: { [id: string]: string } = (dto.otherUtms === null) ? {} : JSON.parse(dto.otherUtms);

                tempObj[key] = value;

                dto.otherUtms = JSON.stringify(tempObj);

                break;
            }
        }
    }

    try {
        yield call(() => apiClient.saveUserUtm(sessionToken, dto));

        logger.info(LoggerSectionsEnum.UTM_SAVER, 'Utm successfully saved');
    } catch (e) {
        logger.error(LoggerSectionsEnum.UTM_SAVER, e);
    }

    yield put(setUtmForSave(null));
}
