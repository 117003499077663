import React, {MouseEvent, useCallback, useMemo} from "react";
import {DtoTmLevel} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {MinusIconStyled} from "../../common/styled-components";
import {ReactComponent as PencilIcon} from "../../../Ui/Svg/Pencil.svg";
import {ReactComponent as SmallCircleOutlineIcon} from "../../../Ui/Svg/SmallCircleOutline.svg";
import {ReactComponent as CheckIcon} from "../../../Ui/Svg/Check.svg";
import styled from "styled-components";
import {RegularTextCss} from "../../../../styles/global-elements";
import {Sortable, SortableItemComponent} from "../../../Sortable/Sortable";
import {ModalResultType, TmLevelModal} from "../../modals/level-modal";
import {RouterLink} from "../../../Ui/Elements/Link";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";

const LevelsListStyled = styled.div``;

const LevelItem = styled.div`
  ${RegularTextCss};
  color: ${({theme}) => theme.colors.textSecondary};
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  &.active {
    color: ${({theme}) => theme.colors.textPrimary};
    font-weight: bold;
  }
`;

const LevelItemIcon = styled.div`
  max-height: 40px;
`;

const LevelItemText = styled(RouterLink)`
  ${RegularTextCss};

  padding-top: 5px;
  flex-grow: 1;
  white-space: normal;
  word-break: break-all;

  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const LevelItemEditBtn = styled.div`
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;

  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.3;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;

interface LevelsListProps {
    levelItems: DtoTmLevel[];
    disciplineId: string;
    modalOnResult: (action: ModalResultType, item: (DtoTmLevel | null)) => void;
    reorderItems?: (from: number, to: number) => void;
    itemsDisabled?: boolean;
    navigateToLevelMethod?: (toLevelId: string) => void;
    selectMode?: boolean;
    usedLevelIds?: string[]; // отмечаются кружком уровни из списка, последняя из списка выделяется флажком
}

export const LevelsList: React.FC<LevelsListProps> = (props) => {
    const defaultLinkToLevel = (levelId: string) => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_TEACHING_MATERIALS_LEVEL_DETAILS,
            [
                {
                    key: 'tmLevelId',
                    value: levelId
                }
            ]
        )
    }

    const levelItemOnClick = useCallback((e: MouseEvent<HTMLAnchorElement>, itemId: string) => {
        if (props.navigateToLevelMethod === undefined) {
            return;
        }

        props.navigateToLevelMethod(itemId);
        e.preventDefault();
    }, [props]);

    const lastUsedLevelId = useMemo<string | null>(() => {
        if (props.usedLevelIds === undefined) {
            return null;
        }

        if (props.usedLevelIds.length === 0) {
            return null;
        }

        return props.usedLevelIds[props.usedLevelIds.length - 1];
    }, [props.usedLevelIds]);

    const itemIcon = (item: DtoTmLevel) => {
        if ((lastUsedLevelId === null) || (props.usedLevelIds === undefined)) {
            return <MinusIconStyled/>;
        }

        if (lastUsedLevelId === item.id) {
            return <SmallCircleOutlineIcon/>;
        }

        if (props.usedLevelIds.indexOf(item.id) > -1) {
            return <CheckIcon/>;
        }

        return <MinusIconStyled/>;
    }

    const levelItem = (item: DtoTmLevel) => {
        return <>
            <LevelItemIcon>{itemIcon(item)}</LevelItemIcon>
            <LevelItemText onClick={(e) => levelItemOnClick(e, item.id)}
                           to={defaultLinkToLevel(item.id)}>{item.name}</LevelItemText>
            {
                (props.selectMode !== true)
                && <LevelItemEditBtn>
                    <TmLevelModal disciplineId={props.disciplineId} levelItem={item} result={props.modalOnResult}
                                  trigger={<PencilIcon/>}/>
                </LevelItemEditBtn>
            }
        </>
    }

    if (props.reorderItems === undefined) {
        return <LevelsListStyled>
            {
                props.levelItems.map((item) => {
                    return <LevelItem key={item.id}>{levelItem(item)}</LevelItem>;
                })
            }
        </LevelsListStyled>;
    } else {
        return <Sortable<DtoTmLevel>
            tinyItems={true}
            items={props.levelItems.map((item): SortableItemComponent<DtoTmLevel> => {
                return {
                    id: item.id,
                    selected: false,
                    disabled: (props.itemsDisabled === true),
                    item: item
                }
            })}
            reorderItems={(array, from, to) => {
                if (props.reorderItems !== undefined) {
                    props.reorderItems(from, to);
                }
            }}
            renderItemContent={(item, index) => {
                return <LevelItem>{levelItem(item.item)}</LevelItem>;
            }}
        />
    }
}