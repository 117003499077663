import * as React from "react";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import styled from "styled-components";
import {Popup as ReactjsPopup} from "reactjs-popup";
import {PopupActions, PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ReactComponent as CloseCrossSvg} from "../Svg/CloseCross.svg";
import {NotificationTypesEnum, openNotification} from "./Notification";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "./Button";
import {ModalP, ModalSubTitle} from "../../../styles/global-elements";

export const StyledConfirmDialog = styled(ReactjsPopup)`
  &-overlay {
    z-index: ${({theme}) => theme.zIndices.modal} !important;
    background-color: ${({theme}) => theme.colors.modalBackdrop};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-content {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    position: relative !important;
    width: 92%;
    max-height: 80%;
    max-width: 338px;
    border-radius: 20px;

    top: auto;
    left: auto;
    right: auto;
    bottom: auto;

    @media (${({theme}) => theme.media.large}) {
      max-width: 452px;
    }

    @media (${({theme}) => theme.media.extraLarge}) {
      width: 608px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 16px 20px;
`;

const FooterWrapper = styled.div`
  padding: 16px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalHeader = styled.div`
  min-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CloseCross = styled(CloseCrossSvg)`
  opacity: 0.3;
  cursor: pointer;
  padding: 2px;
  color: ${({theme}) => theme.colors.textPrimary};

  &:hover {
    opacity: 1;
  }
`;

const ModalContent = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

const ModalPStyled = styled(ModalP)`
  max-width: none !important;
  margin-bottom: 24px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 30px;
  }
`;

interface ConfirmModalDialogProps {
    trigger?: JSX.Element,
    okText: string,
    cancelText: string,
    title: string,
    text?: string | JSX.Element,
    okMethod: () => Promise<void>,
    errorText?: string,
    onCancel?: () => void,
}

export const ConfirmDialog = forwardRef<PopupActions, ConfirmModalDialogProps>(
    ({...props}: ConfirmModalDialogProps, ref) => {
        const modalRef = useRef<ReactjsPopupActions>(null);

        const [closeAllowed, setCloseAllowed] = useState(true);
        const [okActionInProcess, setOkActionInProcess] = useState(false);
        const [okCalledBefore, setOkCalledBefore] = useState(false);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            close: () => {
                modalRef.current?.close()
            },
            open: () => {
                modalRef.current?.open()
            },
            toggle: () => {
                modalRef.current?.toggle()
            },
        }));

        const onOk = () => {
            setOkCalledBefore(true);

            if (!props.okMethod) {
                return;
            }

            const okMethodProp = props.okMethod;

            setCloseAllowed(false);
            setOkActionInProcess(true);

            new Promise<void>((resolve, reject) => {
                okMethodProp()
                    .then(() => {
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            })
                .then(() => {
                    modalRef?.current?.close();
                })
                .catch(() => {
                    setCloseAllowed(true);
                    setOkActionInProcess(false);

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Ошибка`,
                        (props.errorText) ? props.errorText : t`Не удалось выполнить операцию`
                    );
                });
        }

        const beforeClose = (closeFunc: () => void) => {
            if (!okCalledBefore) {
                if (props.onCancel) {
                    props.onCancel();
                }
            }

            closeFunc();
        }

        return (
            <StyledConfirmDialog
                trigger={props.trigger}
                onOpen={() => {
                    setCloseAllowed(true);
                    setOkActionInProcess(false);
                    setOkCalledBefore(false);
                }}
                closeOnDocumentClick={(closeAllowed === undefined) ? true : closeAllowed}
                closeOnEscape={(closeAllowed === undefined) ? true : closeAllowed}
                ref={modalRef} modal nested>
                {
                    (closeModal: () => void) => (
                        <ModalContentWrapper>
                            <ModalHeader>
                                <CloseCross onClick={() => {
                                    if (closeAllowed) {
                                        beforeClose(closeModal)
                                    }
                                }}/>
                            </ModalHeader>
                            <ModalContent>
                                <Wrapper>
                                    <ModalSubTitle>{props.title}</ModalSubTitle>
                                    {
                                        (props.text) && <ModalPStyled>{props.text}</ModalPStyled>
                                    }
                                </Wrapper>
                            </ModalContent>
                            <ModalFooter>
                                <FooterWrapper>
                                    <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Primary}
                                            loading={okActionInProcess}
                                            onClick={onOk}>
                                        {(props.okText) ? props.okText : <Trans>Ок</Trans>}
                                    </Button>
                                    <Button btnStyle={BtnStyleEnum.Secondary} disabled={okActionInProcess}
                                            onClick={() => beforeClose(closeModal)}>
                                        {(props.cancelText) ? props.cancelText : <Trans>Отмена</Trans>}
                                    </Button>
                                </FooterWrapper>
                            </ModalFooter>
                        </ModalContentWrapper>
                    )
                }
            </StyledConfirmDialog>
        )
    });


ConfirmDialog.displayName = 'ConfirmDialog';