import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {UploadComponent} from "./UploadComponent";
import {ElementContextProvider, IElementContext} from "./ElementContext";
import {
    AUDIO_ELEMENT_HEIGHT,
    ElementData,
    ElementStateEnum,
    UploadStateList
} from "../../../SlidePlayerEditorCommonParts/components/Audio/Common";
import {FileUploadProcessState, UploadProcessDetails} from "../../../../../store/uploadQueue/type";
import {useSelector} from "react-redux";
import {queueStateSelector} from "../../../../../store/uploadQueue/selector";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Audio/PresenterComponent";

const AudioElementWrapper = styled.div`
  width: 100%;
`;

interface AudioProps extends ItemPropsInterface<ElementData> {
}

interface AudioRefMethods extends ItemRefMethodsInterface {
}

export const Audio = forwardRef<AudioRefMethods, AudioProps>(
        (props, ref) => {

            const {id, setToolbarConfigById, initialData, onChange} = props;
            const queueState = useSelector(queueStateSelector);

            const [elementState, setElementState] = useState<ElementStateEnum>(ElementStateEnum.WAIT_INIT);
            const elementWrapperRef = useRef<HTMLDivElement>(null);

            const elementData = useMemo((): ElementData => {
                if (initialData === null) {
                    return {
                        audioId: null
                    }
                }

                return initialData;
            }, [initialData]);

            const currentFileInUploadQueue = useMemo<UploadProcessDetails | null>(() => {
                if (elementData.audioId === null) {
                    return null
                }

                const itemIndex = queueState.indexByFileId[elementData.audioId];

                if (itemIndex === undefined) {
                    return null;
                }

                return queueState.process[itemIndex] ?? null;
            }, [queueState, elementData]);

            const elementProviderData = useMemo<IElementContext>(() => {
                return {
                    elementData: elementData,
                    elementState: elementState,
                    setElementState: setElementState,
                    onChangeElementData: onChange,
                    currentFileInUploadQueue: currentFileInUploadQueue
                }
            }, [currentFileInUploadQueue, elementData, elementState, onChange]);

            useEffect(() => {
                setToolbarConfigById(id, [
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                        active: false,
                        disabled: true
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                        active: false,
                        disabled: true
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                        active: false,
                        disabled: true
                    }
                ]);
            }, [elementData, id, setToolbarConfigById]);

            // Методы, доступные родителю
            useImperativeHandle(ref, () => ({
                toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                },
                getExercisesCount: () => 0
            }));

            useEffect(() => {
                // Обновление статуса компонента
                if (!currentFileInUploadQueue) {
                    if (elementData.audioId !== null) {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    } else {
                        setElementState(ElementStateEnum.NO_AUDIO);
                    }

                    return;
                }

                if (elementState === ElementStateEnum.NO_AUDIO) {
                    return;
                }

                switch (currentFileInUploadQueue.state) {
                    case FileUploadProcessState.WAIT_FOR_START:
                    case FileUploadProcessState.IN_PROCESS: {
                        if (elementState !== ElementStateEnum.UPLOADING_NOW) {
                            setElementState(ElementStateEnum.UPLOADING_NOW);
                        }

                        break;
                    }
                    case FileUploadProcessState.CANCELLED: {
                        setElementState(ElementStateEnum.NO_AUDIO);
                        // Данные файла и preview обнули UploadComponent

                        break;
                    }
                    case FileUploadProcessState.FAILED: {
                        if (elementState !== ElementStateEnum.UPLOAD_ERROR) {
                            setElementState(ElementStateEnum.UPLOAD_ERROR);
                        }

                        break;
                    }
                    case FileUploadProcessState.SUCCESS: {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [currentFileInUploadQueue]);


            return <AudioElementWrapper style={{height: AUDIO_ELEMENT_HEIGHT.toString(10) + 'px'}}
                                        ref={elementWrapperRef}>
                <ElementContextProvider value={elementProviderData}>
                    {
                        (UploadStateList.includes(elementState))
                            ? <UploadComponent/>
                            : <PresenterComponent elementData={elementData} inConstructor={true}/>
                    }
                </ElementContextProvider>
            </AudioElementWrapper>;
        }
    )
;