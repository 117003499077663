import React from "react";
import styled from "styled-components";
import {EnergyBar} from "./EnergyBar";
import {ReactComponent as CloseCross} from "../../../../components/Ui/Svg/CloseCross.svg";
import {Link as RouterLink} from "react-router-dom";
import {RoutesList} from "../../../../RoutesList";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
  margin-top: -20px;
  margin-left: -5px;
    margin-bottom: 20px;
    gap: 3px;
    align-items: center;
`;

const CloseButtonWrapper = styled(RouterLink)`
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
    opacity: 0.5;
    color: ${({theme}) => theme.colors.textPrimary};
`;

const EnergyBarWrapper = styled.div`
    flex-grow: 1;
`;

export const MobileTopLine: React.FC = () => {
    return <Wrapper>
        <CloseButtonWrapper to={RoutesList.MAIN_PAGE}>
            <CloseCross/>
        </CloseButtonWrapper>
        <EnergyBarWrapper>
            <EnergyBar/>
        </EnergyBarWrapper>
    </Wrapper>
}