import {WorkerPayloadType} from "../WorkerPayloadType";
import {FileUploadProcessState, UploadQueueActionTypes, UploadQueueState} from "../../store/uploadQueue/type";
import {put, putResolve, select, takeEvery} from "redux-saga/effects";
import * as UploadQueueActionCreators from "../../store/uploadQueue/actions";
import {queueStateSelector} from "../../store/uploadQueue/selector";

/**
 * Сага перезапускает процесс отправки файла.
 */
export function* watchRestartFileUploading() {
    yield takeEvery<WorkerPayloadType<string>>(
        UploadQueueActionTypes.RESTART_FILE_UPLOADING,
        restartFileUploading
    );
}

function* restartFileUploading(data: WorkerPayloadType<string>) {
    // Получаем объект с данными файла
    const queueState = (yield select(queueStateSelector)) as UploadQueueState;

    const index = queueState.indexByFileId[data.payload];

    if (index === undefined) {
        return;
    }

    const processDetails = queueState.process[index];

    if (processDetails === undefined) {
        return;
    }

    if (processDetails.state !== FileUploadProcessState.FAILED) {
        return;
    }

    // Удаляем процесс из очереди
    yield putResolve(UploadQueueActionCreators.removeFromQueue(processDetails.fileId));

    // Запускаем загрузку заново
    yield put(UploadQueueActionCreators.uploadNewFile(
        processDetails.fileId,
        processDetails.fileType,
        processDetails.url,
        processDetails.file
    ));
}