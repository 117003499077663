import * as React from "react";
import styled from 'styled-components';
import {Checkbox as AntCheckbox} from 'antd';
import {CheckboxProps} from 'antd/lib/checkbox';
import {forwardRef} from "react";
import {RegularTextCss} from "../../../styles/global-elements";

const CheckboxStyled = styled(AntCheckbox)`
  ${RegularTextCss};
  
  &.ant-checkbox-wrapper {
    ${RegularTextCss};
  }
  
  color: ${({theme}) => theme.colors.textPrimary};

  .ant-checkbox-inner {
    background: ${({theme}) => theme.colors.backgroundSecondary};
    border-color: ${({theme}) => theme.colors.accentPrimary};
  }

  .ant-checkbox-inner::after {
    border-color: ${({theme}) => theme.colors.accentPrimary};
  }

  .ant-checkbox-checked:after {
    border-color: ${({theme}) => theme.colors.accentPrimary};
  }
  
  .ant-checkbox-disabled {
    opacity: 0.5;
    
    .ant-checkbox-inner {
      border-color: ${({theme}) => theme.colors.textSecondary};
    }
  }
`;

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    return <CheckboxStyled ref={ref} {...props}/>
})