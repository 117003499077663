import {ApplicationState} from "../index";
import {createSelector} from "reselect";
import {UserProfileType} from "./type";

export const userProfileIsEmpty = ({user}: ApplicationState) => {
    if (user.profileData === null) {
        return true;
    }

    if (!Array.isArray(user.profileData.schools)) {
        return true;
    }

    return (user.profileData.schools.length < 1);
};

export const userIsTeacher = ({user}: ApplicationState) => {
    return user.profileType === UserProfileType.TEACHER;
};

export const currentUserIdSelector = ({user}: ApplicationState) => user.profileData?.id ?? null;
export const currentAvatarFileIdSelector = ({user}: ApplicationState) => user.profileData?.avatarFileId ?? null;
export const isTempProfileSelector = ({user}: ApplicationState) => (user.profileData) ? user.profileData.isTempProfile : false;
const userProfileDataSelector = ({user}: ApplicationState) => user.profileData;
const selectedSchoolIdSelector = ({user}: ApplicationState) => user.selectedSchoolId;
const selectedUserInSchoolIdSelector = ({user}: ApplicationState) => user.selectedUserInSchoolId;
const selectedAgreementIdSelector = ({user}: ApplicationState) => user.selectedAgreementId;
export const supportPhoneSelector = ({user}: ApplicationState) => user.support.phone;

/**
 * Селектор выбранной школы
 */
export const selectedSchool = createSelector(
    [
        userProfileDataSelector,
        selectedSchoolIdSelector
    ],
    (userProfileData, selectedSchoolId) => {
        if (selectedSchoolId === null) {
            return null;
        }

        if (
            (userProfileData === null)
            || (!Array.isArray(userProfileData.schools))
            || (userProfileData.schools.length === 0)
        ) {
            return;
        }

        const schoolsCount = userProfileData.schools.length;

        for (let schoolIndex = 0; schoolIndex < schoolsCount; schoolIndex++) {
            if (userProfileData.schools[schoolIndex].id === selectedSchoolId) {
                return userProfileData.schools[schoolIndex];
            }
        }

        return null;
    }
)

/**
 * Селектор выбранного аккаунта (userInSchool) в выбранной школе
 */
export const selectedUserInSchool = createSelector(
    [
        selectedSchool,
        selectedUserInSchoolIdSelector
    ],
    (selectedSchool, selectedUserInSchoolId) => {
        if (
            (selectedSchool === undefined)
            || (selectedSchool === null)
            || (selectedUserInSchoolIdSelector === null)
        ) {
            return null;
        }

        const agreementsCount = selectedSchool.accounts.length;

        for (let agreementIndex = 0; agreementIndex < agreementsCount; agreementIndex++) {
            if (selectedSchool.accounts[agreementIndex].id === selectedUserInSchoolId) {
                return selectedSchool.accounts[agreementIndex];
            }
        }

        return null;
    }
)

/**
 * Селектор выбранного договора выбранного профиля выбранной школы
 */
export const selectedAgreement = createSelector(
    [
        selectedUserInSchool,
        selectedAgreementIdSelector
    ],
    (selectedUserInSchool, selectedAgreementId) => {
        if (
            (selectedUserInSchool === undefined)
            || (selectedUserInSchool === null)
            || (selectedAgreementId === null)
        ) {
            return null;
        }

        const agreementsCount = selectedUserInSchool.agreements.length;

        for (let agreementIndex = 0; agreementIndex < agreementsCount; agreementIndex++) {
            if (selectedUserInSchool.agreements[agreementIndex].id === selectedAgreementId) {
                return selectedUserInSchool.agreements[agreementIndex];
            }
        }

        return null;
    }
)

export const selectedSchoolActualParams = createSelector(
    [
        selectedSchool
    ],
    (selectedSchool) => {
        if (!selectedSchool) {
            return null;
        }

        return selectedSchool.actualParams;
    }
)

export const selectedSchoolActualParamsCount = createSelector(
    [
        selectedSchoolActualParams
    ],
    (actualParams) => {
        if (actualParams === null) {
            return 1;
        }

        if (actualParams.fifth !== null) {
            return 5;
        }

        if (actualParams.fourth !== null) {
            return 4;
        }

        if (actualParams.third !== null) {
            return 3;
        }

        if (actualParams.second !== null) {
            return 2;
        }

        return 1;
    }
)