import React, {useCallback, useContext, useMemo} from "react";
import {IButtonBaseProps} from "./IButtonBaseProps";
import {IVideoRoomContext, VideoRoomContext} from "../../contexts/VideoRoomContext";
import {IVideoRoomActionsContext, VideoRoomActionsContext} from "../../actions/video-room/VideoRoomActionsContext";
import {
    ControlPanelItem,
    ControlPanelItemIcon,
    ControlPanelItemText
} from "../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as EyeOpenedSvg} from "../../../Ui/Elements/ControlPanel/icons/Eye.svg";
import {ReactComponent as EyeClosedSvg} from "../../../Ui/Elements/ControlPanel/icons/EyeClosed.svg";
import {Trans} from "@lingui/macro";
import classNames from "classnames";
import {ConnectionStateEnum, StreamStatusEnum} from "../../Types";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../store/user/selector";

export const MyVideoBtn: React.FC<IButtonBaseProps> = () => {
    const currentUserId = useSelector(currentUserIdSelector);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);
    const videoRoomActionsContext = useContext<IVideoRoomActionsContext>(VideoRoomActionsContext);
    const commonContext = useContext<ICommonContext>(CommonContext);

    const myUserConfParticipant = useMemo(() => {
        return commonContext.conferenceParticipants.find(item => item.id === currentUserId)??null;
    },[commonContext.conferenceParticipants, currentUserId]);

    const toggleButton = useCallback(() => {
        if (!myUserConfParticipant) {
            return;
        }

        if (myUserConfParticipant.videoStatus !== StreamStatusEnum.CONNECTED) {
            videoRoomActionsContext.enableMyVideo();
        } else {
            videoRoomActionsContext.disableMyVideo();
        }
    }, [myUserConfParticipant, videoRoomActionsContext]);

    const btnDisabled = useMemo<boolean>(
        () =>
            videoRoomContext.videoRoomConnectionState.publisherConnection.status !== ConnectionStateEnum.CONNECTED
            || myUserConfParticipant === null
            || myUserConfParticipant.videoStatus === StreamStatusEnum.CONNECTING
            || commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED,
        [commonContext.lessonConferenceState, myUserConfParticipant, videoRoomContext.videoRoomConnectionState.publisherConnection.status]
    );

    const videoIsDisabled = useMemo(() => {
        if ((btnDisabled) || (myUserConfParticipant === null)) {
            return false;
        }

        return myUserConfParticipant.videoStatus !== StreamStatusEnum.CONNECTED;
    }, [btnDisabled, myUserConfParticipant]);

    return <ControlPanelItem
        onClick={() => toggleButton()}
        className={
            classNames(
                'panel-item',
                btnDisabled && "disabled",
                videoIsDisabled && "warning"
            )
        }>
        <ControlPanelItemIcon>{(videoIsDisabled) ? <EyeClosedSvg/> :
            <EyeOpenedSvg/>}</ControlPanelItemIcon>
        <ControlPanelItemText><Trans>Моя камера</Trans></ControlPanelItemText>
    </ControlPanelItem>
}
