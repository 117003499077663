import {call, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {
    SetSelectedUserIdReducerInput,
    UserActionTypes,
    UserProfileLoadState,
    UserProfileType
} from "../../store/user/type";
import * as AppActionCreators from "../../store/app/actions";
import {setWebRtcSupported, setWsConnectionRequired} from "../../store/app/actions";
import * as UserActionCreators from "../../store/user/actions";
import * as LayoutActionCreators from "../../store/layout/actions";
import * as CommonPersistedActionCreators from "../../store/commonPersisted/actions";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {ApplicationState} from "../../store";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {BaseResponseDto} from "../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {
    UserProfileDataDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserProfileDataDto";
import {AccessDeniedException} from "../../components/HttpApiClient/Exception/AccessDeniedException";
import {
    TeacherProfileDataDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/TeacherProfileDataDto";
import {
    UserInSchoolAccountDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserInSchoolAccountDto";
import {CommonEnums} from "../../components/HttpApiClient/Enums/CommonEnums";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {IFeatureToggle} from "../../components/FeatureToggle/IFeatureToggle";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";
import {ISentryLogger} from "../../components/SentryLogger/ISentryLogger";
import {NoConnection} from "../../components/HttpApiClient/Exception/NoConnection";
import * as i18nActionCreators from "../../store/i18n/actions";
import {LocaleEnum} from "../../store/i18n/type";
import {IJanusConnection} from "../../components/JanusClient/IJanusConnection";
import {ICustomStorage} from "../../components/CustomStorage/ICustomStorage";
import {StorageKeysEnum} from "../../enums/StorageKeysEnum";
import {NotificationTypesEnum, openNotification} from "../../views/components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {NotFoundException} from "../../components/HttpApiClient/Exception/NotFoundException";
import {saveUserUtm} from "../../store/user/actions";

/**
 * Сага отвечает за запуск инициализации приложения и всех процессов, связанных с ним.
 */
export function* watchLoadUserDataBySessionToken() {
    yield takeEvery(
        UserActionTypes.LOAD_USER_DATA_BY_SESSION_TOKEN,
        loadUserDataBySessionToken
    );
}

const getSessionToken = (state: ApplicationState) => state.user.sessionToken;
const getProfileType = (state: ApplicationState) => state.user.profileType;

function* loadUserDataBySessionToken() {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const featureToggle = container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE);
    const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);

    yield put(LayoutActionCreators.setSplashScreenVisible(true));

    logger.info(LoggerSectionsEnum.START_APPLICATION, 'Start load user data process');

    yield put(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.WAIT_AUTH_RESULT));

    try {
        // Получаем токен сессии и тип профиля из store
        const sessionToken = (yield select(getSessionToken)) as string | null;
        const profileType = (yield select(getProfileType)) as number | null;

        if (
            (sessionToken === null)
            || (sessionToken === '')
            || (profileType === null)
            || (![UserProfileType.STUDENT, UserProfileType.TEACHER].includes(profileType))
        ) {
            logger.error(
                LoggerSectionsEnum.LOAD_USER_DATA_PROCESS,
                'Invalid user session token or profile type in store. Token: ',
                sessionToken,
                'Profile type: ',
                profileType
            );

            yield putResolve(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.AUTH_ERROR));

            yield put(LayoutActionCreators.setSplashScreenVisible(false));

            return;
        }

        logger.debug(LoggerSectionsEnum.LOAD_USER_DATA_PROCESS, 'Received user session token from store');

        let schoolsFound = false;

        // Запускаем отправку собранных UTM меток
        yield put(saveUserUtm());

        // Загружаем информацию профиля
        let userIdForSentry: string | null = null;

        try {
            if (profileType === UserProfileType.STUDENT) {
                // Если это профиль студента
                const userProfileData = (
                    yield call(() => apiClient.getUserAgreements(sessionToken))
                ) as BaseResponseDto<UserProfileDataDto>;

                // Информацию получили.
                // Отправляем в store и выбираем активные договор и программу обучения
                yield putResolve(UserActionCreators.setUserProfileData(userProfileData.data));

                yield put(i18nActionCreators.selectLocale(userProfileData.data.uiLocaleId as LocaleEnum));

                featureToggle.setCurrentUserId(userProfileData.data.id);
                featureToggle.setCurrentUserProfileType(UserProfileType.STUDENT);
                userIdForSentry = userProfileData.data.id;

                const selectedAgreementIds = getActiveAgreementFromApiResult(userProfileData.data);

                if (selectedAgreementIds !== null) {
                    yield put(UserActionCreators.activateAgreementId(selectedAgreementIds));
                    schoolsFound = true;
                }

                // Отправляем информацию об устройстве, если нужно
                if (
                    (!userProfileData.data.deviceInfoAlreadySaved)
                    || (userProfileData.data.userSavedTimezone !== dateHelperService.getCurrentDeviceTimezoneName())
                ) {
                    yield put(AppActionCreators.sendDeviceInfo());
                }

                // Если у нас не временный аккаунт и в хранилище есть merge токен - объединяем аккаунты и удаляем merge токен
                if (!userProfileData.data.isTempProfile) {
                    const mergeToken = (
                        yield call(() => customStorage.getItem(StorageKeysEnum.MERGE_TOKEN))
                    ) as string|null;

                    if (mergeToken) {
                        let hasErrors = false;

                        try {
                            yield call(() => apiClient.mergeProfile(sessionToken, mergeToken))
                        } catch (e) {
                            if (e instanceof NotFoundException) {
                                // Ничего делать не нужно, каким-то образом токен устарел
                            } else if (e instanceof NoConnection) {
                                hasErrors = true;

                                logger.warning(
                                    LoggerSectionsEnum.USER_MERGE_PROFILE,
                                    `Error on merge profile - no internet connection.`
                                );

                                openNotification(
                                    NotificationTypesEnum.ERROR,
                                    t`Возникла проблема со связью`,
                                    t`Не получилось кое-что обновить в этом аккаунте. Попробуйте перезагрузить страницу.`
                                );
                            } else {
                                logger.error(
                                    LoggerSectionsEnum.USER_MERGE_PROFILE,
                                    `Error on merge profile by merge token ${mergeToken}: `,
                                    e
                                );

                                // hasErrors true тут не ставим, т.к. само видимо уже не исправится - пускай mergeToken удаляется.
                            }
                        }

                        if (!hasErrors) {
                            yield call(() => customStorage.removeItem(StorageKeysEnum.MERGE_TOKEN))
                        }
                    }
                }
            } else {
                // Если это профиль преподавателя
                const userProfileData = (
                    yield call(() => apiClient.getTeacherAccountInfo(sessionToken))
                ) as BaseResponseDto<TeacherProfileDataDto>;

                // соберём структуру UserProfileDataDto перед отправкой
                const userProfileDataModel = new UserProfileDataDto();
                userProfileDataModel.id = userProfileData.data.id;
                userProfileDataModel.email = null;
                userProfileDataModel.avatarFileId = userProfileData.data.avatarFileId;

                const userAccountModel = new UserInSchoolAccountDto();

                userAccountModel.name = userProfileData.data.name;
                userAccountModel.agreements = [];
                userAccountModel.balance = 0;
                userAccountModel.onlinePaymentEnabled = false;
                userAccountModel.dateBorn = (userProfileData.data.dateBorn) ?? "";
                userAccountModel.id = CommonEnums.DUMMY_TEACHER_ID;
                userAccountModel.stId = userProfileData.data.teacherIdInSchool;

                userProfileDataModel.schools = [];
                userProfileDataModel.schools.push({
                    id: userProfileData.data.schoolId,
                    actualParams: userProfileData.data.actualParams,
                    name: userProfileData.data.schoolName,
                    timezoneName: userProfileData.data.schoolTimezoneName,
                    accounts: [
                        userAccountModel
                    ],
                    customizationCssPath: null,
                    schoolPageLocation: null
                });

                // Отправляем в store
                yield putResolve(UserActionCreators.setUserProfileData(userProfileDataModel));
                yield putResolve(UserActionCreators.setUserStToken(userProfileData.data.stToken));
                yield putResolve(UserActionCreators.setUserIsModerator(userProfileData.data.isModerator));
                yield putResolve(AppActionCreators.setInitialCustomizerValue(userProfileData.data.needInitialCustomizer));
                yield put(i18nActionCreators.selectLocale(userProfileData.data.uiLocaleId as LocaleEnum));

                featureToggle.setCurrentUserId(userProfileData.data.id);
                featureToggle.setCurrentUserProfileType(UserProfileType.TEACHER);
                featureToggle.setTeacherSchoolId(userProfileData.data.schoolId);
                userIdForSentry = userProfileData.data.id;

                // Устанавливаем id школы
                yield put(UserActionCreators.activateAgreementId({
                    schoolId: userProfileData.data.schoolId,
                    userInSchoolId: CommonEnums.DUMMY_TEACHER_ID,
                    agreementId: null
                }));

                schoolsFound = true;

                // Отправляем информацию об устройстве, если нужно
                if (
                    (!userProfileData.data.deviceInfoAlreadySaved)
                    || (userProfileData.data.userSavedTimezone !== dateHelperService.getCurrentDeviceTimezoneName())
                ) {
                    yield put(AppActionCreators.sendDeviceInfo());
                }
            }

            // Устанавливаем статус авторизации и выключаем SplashScreen.
            yield put(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.AUTHORIZED));

            yield put(LayoutActionCreators.setSplashScreenVisible(false));

            // Сохраним токен и тип профиля в persisted storage
            yield put(CommonPersistedActionCreators.setAuthToken(sessionToken));
            yield put(CommonPersistedActionCreators.setProfileType(profileType));

            // Стартуем подключение WebSocket
            let wsApiClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

            yield put(setWsConnectionRequired(true));

            yield call(() => {
                wsApiClient.createConnection()
            });

            if (schoolsFound) {
                const janusConnection = container.resolve<IJanusConnection>(DiTokens.JANUS_CONNECTION);

                yield put(setWebRtcSupported(janusConnection.webRtcSupported()));
            }

            // Зададим id пользователя в Sentry, если Sentry SDK инициализирован
            if (userIdForSentry) {
                const sentry = container.resolve<ISentryLogger>(DiTokens.SENTRY_LOGGER);

                if (sentry.isReady()) {
                    sentry.setUserInfo(userIdForSentry);
                }
            }
        } catch (e) {
            if (e instanceof AccessDeniedException) {
                // Токен не действителен - выходим из системы.
                logger.info(
                    LoggerSectionsEnum.LOAD_USER_DATA_PROCESS,
                    'Invalid access token. Go to welcome screen.'
                );

                yield put(UserActionCreators.logoutUser());

                return;
            }

            if (e instanceof NoConnection) {
                logger.info(LoggerSectionsEnum.LOAD_USER_DATA_PROCESS, e);
            } else {
                logger.error(LoggerSectionsEnum.LOAD_USER_DATA_PROCESS, e);
            }

            yield putResolve(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.AUTH_ERROR));

            yield put(LayoutActionCreators.setSplashScreenVisible(false));
        }
    } catch (e) {

    }
}

function getActiveAgreementFromApiResult(data: UserProfileDataDto): SetSelectedUserIdReducerInput | null {
    if (
        (Array.isArray(data.schools))
        && (data.schools.length === 0)
    ) {
        return null;
    }

    const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
    const currentDay = dateHelperService.getCurrentDateWithoutTime();

    const schoolsCount = data.schools.length;

    /**
     * Хороший вариант для автовыбора договора.
     * Хороший - этот когда договор актуальный на текущий момент
     */
    let goodVariantForAutoSelectAgreementId: SetSelectedUserIdReducerInput | null = null;

    /**
     * Плохой вариант для автовыбора договора.
     * Плохой - это просто первый возможный для выбора договор. Используется только если выбранного ранее нет
     * и актуального на текущий момент договора тоже нет.
     */
    let badVariantForAutoSelectAgreementId: SetSelectedUserIdReducerInput | null = null;

    for (let schoolIndex = 0; schoolIndex < schoolsCount; schoolIndex++) {
        const currentSchoolProfile = data.schools[schoolIndex];

        if (
            (!Array.isArray(currentSchoolProfile.accounts))
            || (currentSchoolProfile.accounts.length === 0)
        ) {
            continue;
        }

        const userInSchoolCount = currentSchoolProfile.accounts.length;

        for (let userInSchoolIndex = 0; userInSchoolIndex < userInSchoolCount; userInSchoolIndex++) {
            const currentUserInSchoolProfile = currentSchoolProfile.accounts[userInSchoolIndex];

            if (
                (!Array.isArray(currentUserInSchoolProfile.agreements))
                || (currentUserInSchoolProfile.agreements.length === 0)
            ) {
                continue;
            }

            const userAgreementsCount = currentUserInSchoolProfile.agreements.length;

            for (let userAgreementsIndex = 0; userAgreementsIndex < userAgreementsCount; userAgreementsIndex++) {
                if (currentUserInSchoolProfile.agreements[userAgreementsIndex].active) {
                    return {
                        schoolId: currentSchoolProfile.id,
                        userInSchoolId: currentUserInSchoolProfile.id,
                        agreementId: currentUserInSchoolProfile.agreements[userAgreementsIndex].id
                    }
                } else {
                    // Проверим - есть ли у нас "плохой вариант" для выбора
                    if (badVariantForAutoSelectAgreementId === null) {
                        // Применим этот договор как плохой для автовыбора (чтобы было хоть что-то)
                        badVariantForAutoSelectAgreementId = {
                            schoolId: currentSchoolProfile.id,
                            userInSchoolId: currentUserInSchoolProfile.id,
                            agreementId: currentUserInSchoolProfile.agreements[userAgreementsIndex].id
                        };
                    }

                    // Проверим - есть ли у нас "хороший вариант" для выбора
                    if (goodVariantForAutoSelectAgreementId === null) {
                        const endDate = dateHelperService.dateFromString(
                            currentUserInSchoolProfile.agreements[userAgreementsIndex].endWork
                        );

                        const annulled = currentUserInSchoolProfile.agreements[userAgreementsIndex].annulled;

                        if (!annulled && endDate >= currentDay) {
                            // Похоже, что договор актуален. Выберем его как подходящий вариант для автовыбора
                            goodVariantForAutoSelectAgreementId = {
                                schoolId: currentSchoolProfile.id,
                                userInSchoolId: currentUserInSchoolProfile.id,
                                agreementId: currentUserInSchoolProfile.agreements[userAgreementsIndex].id
                            };
                        }
                    }

                }
            }
        }
    }

    if (goodVariantForAutoSelectAgreementId) {
        return goodVariantForAutoSelectAgreementId;
    }

    if (badVariantForAutoSelectAgreementId) {
        return badVariantForAutoSelectAgreementId;
    }

    return null;
}
