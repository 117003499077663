import {ILogger} from "./ILogger";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";

export class ConsoleLogger implements ILogger {
    protected dateHelperService: IDateHelperService;

    constructor(dateHelperService: IDateHelperService) {
        this.dateHelperService = dateHelperService;
    }

    debug(section: string, ...optionalParams: any[]): void {
        console.debug(this.datePrefix, this.sectionPrefix(section), ...this.processOptionalParams(optionalParams));
    }

    info(section: string, ...optionalParams: any[]): void {
        console.log(this.datePrefix, this.sectionPrefix(section), ...this.processOptionalParams(optionalParams));
    }

    error(section: string, ...optionalParams: any[]): void {
        console.error(this.datePrefix, this.sectionPrefix(section), ...this.processOptionalParams(optionalParams));
    }

    warning(section: string, ...optionalParams: any[]): void {
        console.warn(this.datePrefix, this.sectionPrefix(section), ...this.processOptionalParams(optionalParams));
    }

    /**
     * Метод для переопределения в ProdConsoleLogger'e
     * 
     * @param optionalParams
     * @protected
     */
    protected processOptionalParams(optionalParams: any[]): any[] {
        return optionalParams;
    }

    private get datePrefix(): string {
        return `[${this.dateHelperService.nowUTC()}]`;
    }

    private sectionPrefix(section: string): string {
        return `[${section}]`;
    }
}
