import * as React from "react";
import {SelectedItemTitle} from "../index";
import {ModalResultType, TmSectionModal} from "./modal";
import {DtoTmSection} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";

interface SectionTitleItemProps {
    levelId: string;
    item: DtoTmSection;
    sections: DtoTmSection[] | null;
    setSections: (value: DtoTmSection[] | null) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const SectionTitleItem: React.FC<SectionTitleItemProps> = (props) => {

    const updateItemInSectionsPropsList = (newModel: DtoTmSection) => {
        if (props.sections === null || props.setSections === null) {
            return;
        }

        const newSectionsList = props.sections.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        props.setSections(newSectionsList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromSectionPropsList = () => {
        if (props.sections === null || props.setSections === null) {
            return;
        }

        const newSectionsList = props.sections.filter((item) => (
            (props.item.id !== item.id)
        ));

        props.setSections(newSectionsList);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmSection | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after section updated.');
            }

            updateItemInSectionsPropsList(data);

            return;
        }

        deleteCurrentItemFromSectionPropsList();
    }

    return (
        <TmSectionModal
            levelId={props.levelId}
            sectionItem={props.item}
            result={onResult}
            trigger={<SelectedItemTitle>{props.item.name}</SelectedItemTitle>}/>
    );
}