import * as React from "react";
import {useCallback, useContext, useMemo} from "react";
import {ReactComponent as LayoutSettingsSvg} from "../../../../../components/Ui/Svg/LayoutSettings.svg"
import {EditorContext, IEditorContext} from "../EditorContext";
import {t} from "@lingui/macro";
import {TmSlideLayoutSettingsModal} from "./modal/TmSlideLayoutSettingsModal";
import {
    DtoTmSlideContentLayoutSettings
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContentLayoutSettings";
import {cloneDeep} from "lodash";

export const EditLayoutSettingsBtn: React.FC = (props) => {
    const editorContext = useContext<IEditorContext>(EditorContext);

    const {selectedSlideId, slidesLayoutSettings, setSlidesLayoutSettings} = editorContext;

    const currentLayoutSettings = useMemo<null | DtoTmSlideContentLayoutSettings>(() => {
        if (selectedSlideId === null) {
            return null;
        }

        return slidesLayoutSettings[selectedSlideId] ?? null;
    }, [selectedSlideId, slidesLayoutSettings]);

    const onUpdate = useCallback((data: DtoTmSlideContentLayoutSettings | null) => {
        if (selectedSlideId === null) {
            return;
        }

        setSlidesLayoutSettings((existContent) => {
            const newContent = cloneDeep(existContent);

            if (newContent[selectedSlideId] === undefined) {
                if (data !== null) {
                    newContent[selectedSlideId] = data;
                }
            } else {
                if (data !== null) {
                    newContent[selectedSlideId] = data;
                } else {
                    delete newContent[selectedSlideId]
                }
            }

            return newContent;
        });
    }, [selectedSlideId, setSlidesLayoutSettings]);

    if (!selectedSlideId) {
        return null;
    }

    return (
        <TmSlideLayoutSettingsModal
            slideId={selectedSlideId}
            currentLayoutSettings={currentLayoutSettings}
            onUpdate={onUpdate}
            trigger={<LayoutSettingsSvg title={t`Редактировать параметры макета`}/>}/>
    );
}