import React from "react";
import {debounce} from "lodash";
import styled from "styled-components";
import {Slider, SliderAccentColorVairants} from "./Slider";
import {StudentRateParamPositionEnum} from "../../../../enums/StudentRateParamPositionEnum";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const ParamNameRow = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
`;

const TrackbarRow = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};

  display: flex;
  flex-direction: row;
`;

const TrackbarCol = styled.div`
  flex-grow: 1;
`;

const TrackbarTextValueCol = styled.div`
  padding-left: 20px;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

interface StudentRateSliderProps {
    paramName: string;
    paramPositionId: StudentRateParamPositionEnum;
    value: number;
    onChange: (newValue: number) => void;
}

interface StudentRateSliderState {
    valueForDebounce: number;
}

export class StudentRateSlider extends React.Component<StudentRateSliderProps, StudentRateSliderState> {
    protected sliderOnChangeDebounced;
    protected sliderOnChangeBind;

    constructor(props: Readonly<StudentRateSliderProps> | StudentRateSliderProps) {
        super(props);

        this.state = {
            valueForDebounce: this.props.value
        }

        this.sliderOnChangeDebounced = debounce(() => {
            this.props.onChange(this.state.valueForDebounce);
        }, 500);

        this.sliderOnChangeBind = this.sliderOnChange.bind(this);
    }

    componentWillUnmount() {
        this.sliderOnChangeDebounced.cancel();
    }

    protected sliderOnChange = (newValue: number) => {
        this.setState(
            () => {
                return {
                    valueForDebounce: newValue
                }
            },
            () => this.sliderOnChangeDebounced()
        );
    }

    protected paramPositionIdToSliderColorVariant = (positionId: StudentRateParamPositionEnum): SliderAccentColorVairants => {
        switch (positionId) {
            case StudentRateParamPositionEnum.FIRST: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM1;
            }
            case StudentRateParamPositionEnum.SECOND: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM2;
            }
            case StudentRateParamPositionEnum.THIRD: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM3;
            }
            case StudentRateParamPositionEnum.FOURTH: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM4;
            }
            case StudentRateParamPositionEnum.FIFTH: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM5;
            }
            default: {
                return SliderAccentColorVairants.STUDENT_RATE_PARAM1;
            }
        }
    }

    render() {
        return <Wrapper>
            <ParamNameRow>{this.props.paramName}</ParamNameRow>
            <TrackbarRow>
                <TrackbarCol>
                    <Slider tooltipVisible={false}
                            onChange={this.sliderOnChangeBind}
                            value={this.state.valueForDebounce}
                            accentColorVariant={
                                this.paramPositionIdToSliderColorVariant(this.props.paramPositionId)
                            }/>
                </TrackbarCol>
                <TrackbarTextValueCol>{this.state.valueForDebounce}</TrackbarTextValueCol>
            </TrackbarRow>
        </Wrapper>
    }
}