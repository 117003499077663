import {forwardRef, useImperativeHandle} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {
    CustomElement
} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import styled from "styled-components";
import {DivAsParagraphDefaultStyle} from "../../components/RichTextReader/elements/DivAsParagraph";

const TextComponent = styled(DivAsParagraphDefaultStyle)`
  margin-bottom: 0;
`;

const ElementWrapper = styled.div`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 20px;
  }
  
  div {
    margin-bottom: 0;
  }
`;

interface TitleProps extends IElementProps<CustomElement[]> {
}

interface TitleRefMethods extends IElementRefMethods {
}

export const Title = forwardRef<TitleRefMethods, TitleProps>(
    (props, ref) => {

        const {elementData} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <ElementWrapper>
            <RichTextReader elements={elementData.data} paragraphComponent={TextComponent}/>
        </ElementWrapper>;
    }
);

Title.displayName = 'Title';