type CustomRect = { x: number, y: number, width: number, height: number };

export class ViewportHelper {
    static fullIsInViewport(el: HTMLElement) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)

        );
    }

    static intersection = (r1: CustomRect, r2: CustomRect) => {
        const xOverlap = Math.max(0, Math.min(r1.x + r1.width, r2.x + r2.width) - Math.max(r1.x, r2.x));
        const yOverlap = Math.max(0, Math.min(r1.y + r1.height, r2.y + r2.height) - Math.max(r1.y, r2.y));
        return xOverlap * yOverlap;
    }

    static percentInView = (div: HTMLElement) => {
        const rect = div.getBoundingClientRect();

        const dimension: CustomRect = {x: rect.x, y: rect.y, width: rect.width, height: rect.height};
        const viewport: CustomRect = {x: 0, y: 0, width: window.innerWidth, height: window.innerHeight};
        const divsize = dimension.width * dimension.height;
        const overlap = ViewportHelper.intersection(dimension, viewport);

        return Math.round((overlap / divsize) * 100);
    }
}