import React, {useMemo} from "react";
import styled from "styled-components";
import {Avatar} from "../../../../../../components/Ui/Elements/Avatar";
import {StringHelper} from "../../../../../../../helpers/StringHelper";
import {ProgressBar} from "../../../../../../components/ProgressBar";
import {RegularText} from "../../../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {RoutesHelper} from "../../../../../../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../../../../../../enums/SlidePlayerIdEnum";
import {useSelector} from "react-redux";
import {slidesWorkDataStateSelector} from "../../../../../../../store/slidesWorkData/selector";
import {SlideWorkData} from "../../../../../../../store/slidesWorkData/type";
import StudentHomeworkSlideDataModal from "./StudentHomeworkSlideDataModal";
import classNames from "classnames";

const AwardsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    
    @media (${({theme}) => theme.media.small}) {
        max-width: 70%;
    }
    
    @media (${({theme}) => theme.media.medium}) {
        max-width: 60%;
    }
`;

const Wrapper = styled.div`
display: flex; 
flex-direction: row;
cursor: pointer;
align-items: center;

&:hover ${AwardsWrapper} {
    opacity: 1;
}
`;

const AvatarWrapper = styled.div`
    margin-right: 10px;
    
    @media (${({theme}) => theme.media.small}) {
        margin-right: 15px;
    }
`;

const NameAndResultWrapper = styled.div`
    flex-grow: 1;
    
    display: flex;
    flex-direction: column;
`;

const StudentName = styled(RegularText)`
    margin-bottom: 2px;
`;


const AwardedLabel = styled(RegularText)`
    color: ${({theme}) => theme.colors.progressBarRight};
    
    &.no-value {
        color: ${({theme}) => theme.colors.textSecondary};
    }
`;

const ProgressBarWrapper = styled.div`
    flex-grow: 1;
`;

const MissedAwardLabel = styled(RegularText)`
    color: ${({theme}) => theme.colors.progressBarWrong};
    
    &.no-value {
        color: ${({theme}) => theme.colors.textSecondary};
    }
`;

const StudentIsNotRegistered = styled.div`
    color: ${({theme}) => theme.colors.textSecondary};
    flex-grow: 1;
`;

const NoResults = styled.div`
    color: ${({theme}) => theme.colors.textSecondary};
    flex-grow: 1;
`;

interface StudentsInSlideProps {
    lessonRoomId: string;
    tmSlideId: string;
    studentUserId: string | null;
    studentName: string;
    studentAvatarFileId: string | null;
    className?: string;
}

export const StudentItem: React.FC<StudentsInSlideProps> = (props) => {
    const slidesWorkDataState = useSelector(slidesWorkDataStateSelector);

    const studentName = useMemo<string>(() => {
        return StringHelper.extractFirstAndMiddleName(props.studentName);
    }, [props.studentName]);

    const resultsPlayerId = useMemo<string | null>(() => {
        if (props.studentUserId === null) {
            return null;
        }

        return RoutesHelper.replaceParams(
            SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_HOMEWORK,
            [
                {
                    key: 'studentUserId',
                    value: props.studentUserId
                },
                {
                    key: 'lessonId',
                    value: props.lessonRoomId
                }
            ]
        );
    }, [props.lessonRoomId, props.studentUserId]);

    const slideWorkDataItem = useMemo<SlideWorkData | null>(() => {
        if (resultsPlayerId === null) {
            return null;
        }

        const playerIndex = slidesWorkDataState.indexByPlayerId[resultsPlayerId];

        if (playerIndex === undefined) {
            return null;
        }

        const slideIndex = slidesWorkDataState.indexByPlayerId[resultsPlayerId].indexBySlideId[props.tmSlideId];

        if (slideIndex === undefined || slidesWorkDataState.slides[slideIndex] === undefined) {
            return null;
        }

        return slidesWorkDataState.slides[slideIndex];
    }, [props.tmSlideId, resultsPlayerId, slidesWorkDataState.indexByPlayerId, slidesWorkDataState.slides]);

    const awardValues = useMemo<{ totalAward: number, totalMissedAward: number }>(() => {
        if (slideWorkDataItem === null) {
            return {
                totalAward: 0,
                totalMissedAward: 0
            }
        }

        return {
            totalAward: slideWorkDataItem.totalAward,
            totalMissedAward: slideWorkDataItem.missedAward
        };
    }, [slideWorkDataItem]);

    const workDataSlideContentVersion = useMemo<number | null>(() => {
        if (slideWorkDataItem === null) {
            return null;
        }

        return slideWorkDataItem.slideContentVersionNum;
    }, [slideWorkDataItem]);


    const awardData = useMemo<JSX.Element>(() => {
        if (props.studentUserId === null) {
            return <StudentIsNotRegistered>
                <Trans>Ученик не зарегистрирован в ЛК ученика</Trans>
            </StudentIsNotRegistered>;
        }

        if (awardValues.totalAward === 0 && awardValues.totalMissedAward === 0) {
            return <NoResults>
                <Trans>Упражнения не выполнялись</Trans>
            </NoResults>
        }

        return <>
            <AwardedLabel className={classNames(awardValues.totalAward === 0 && "no-value")}>
                {awardValues.totalAward}
            </AwardedLabel>
            <ProgressBarWrapper>
                <ProgressBar rightValue={awardValues.totalAward} wrongValue={awardValues.totalMissedAward}/>
            </ProgressBarWrapper>
            <MissedAwardLabel className={classNames(awardValues.totalMissedAward === 0 && "no-value")}>
                {awardValues.totalMissedAward}
            </MissedAwardLabel>
        </>;
    }, [awardValues.totalAward, awardValues.totalMissedAward, props.studentUserId]);

    const content = () => {
        return <Wrapper className={props.className}>
            <AvatarWrapper>
                <Avatar userName={props.studentName} fileId={props.studentAvatarFileId} size={45}/>
            </AvatarWrapper>
            <NameAndResultWrapper>
                <StudentName>{studentName}</StudentName>
                <AwardsWrapper>
                    {awardData}
                </AwardsWrapper>
            </NameAndResultWrapper>
        </Wrapper>
    };

    if (props.studentUserId && workDataSlideContentVersion && resultsPlayerId) {
        return <StudentHomeworkSlideDataModal
            tmSlideId={props.tmSlideId}
            playerId={resultsPlayerId}
            slideContentVersion={workDataSlideContentVersion}
            lessonId={props.lessonRoomId}
            studentId={props.studentUserId}
            studentName={studentName}>
            {content()}
        </StudentHomeworkSlideDataModal>
    }

    return content();
}
