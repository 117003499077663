import {instanceCachingFactory} from 'tsyringe';
import {IInternetConnectionChecker} from "../components/InternetConnectionChecker/IInternetConnectionChecker";
import {InternetConnectionChecker} from "../components/InternetConnectionChecker/InternetConnectionChecker";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";

export default instanceCachingFactory<IInternetConnectionChecker>((container) => {
    return new InternetConnectionChecker(
        container.resolve<ILogger>(DiTokens.LOGGER)
    );
});
