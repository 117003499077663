import styled from "styled-components";

export const MainPageItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const MainPageSection = styled.section`
`;

export const LoaderWrapper = styled.div`
    display: block;
    text-align: center;
`;