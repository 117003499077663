import {action} from "typesafe-actions";

import {ContentLayoutSettings, LayoutActionTypes} from "./type";
import {DefaultTheme} from "styled-components";
import {ThemeEnum} from "../../services/theme/ThemeEnum";

export const setSplashScreenVisible = (visible: boolean) => {
    return action(LayoutActionTypes.SET_SPLASH_SCREEN_VISIBLE, visible)
};

export const setRightPanelVisible = (visible: boolean) => {
    return action(LayoutActionTypes.SET_RIGHT_PANEL_VISIBLE, visible)
};

export const changeTheme = (name: ThemeEnum, dontPersist?: boolean) => {
    return action(LayoutActionTypes.CHANGE_THEME, {name: name, dontPersist: !!dontPersist});
};

export const setActiveThemeName = (name: ThemeEnum) => {
    return action(LayoutActionTypes.SET_ACTIVE_THEME_NAME, name)
};

export const setActiveTheme = (theme: DefaultTheme) => {
    return action(LayoutActionTypes.SET_ACTIVE_THEME, theme)
};

export const setBodyScrollAvailable = (newValue: boolean) => {
    return action(LayoutActionTypes.SET_BODY_SCROLL_AVAILABLE, newValue)
};

export const setContentLayoutSettings = (newValue: ContentLayoutSettings | null) => {
    return action(LayoutActionTypes.SET_CONTENT_LAYOUT_SETTINGS, newValue)
}