import {forwardRef, useImperativeHandle} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {
    CustomElement
} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {DivAsParagraphDefaultStyle} from "../../components/RichTextReader/elements/DivAsParagraph";

interface ExerciseFillGapsComboboxProps extends IElementProps<CustomElement[]> {
}

interface ExerciseFillGapsComboboxRefMethods extends IElementRefMethods {
}

export const ExerciseFillGapsCombobox = forwardRef<ExerciseFillGapsComboboxRefMethods, ExerciseFillGapsComboboxProps>(
    (props, ref) => {

        const {elementData} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <RichTextReader elements={elementData.data} paragraphComponent={DivAsParagraphDefaultStyle}/>;
    }
);

ExerciseFillGapsCombobox.displayName = 'ExerciseFillGapsCombobox';