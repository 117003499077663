import React, {forwardRef} from "react";
import {Input} from "../../TextInput";
import {t} from "@lingui/macro";
import {StTeacherSelectModalWindow, StTeacherSelectorItem} from "./StTeacherSelectModalWindow";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {InputRef} from "antd/lib/input/Input";

interface StTeacherSelectorProps {
    value?: StTeacherSelectorItem;
    onChange?: (value: StTeacherSelectorItem) => void;
}

export const StTeacherSelector = forwardRef<InputRef, StTeacherSelectorProps>(
    (props, ref) => {
        const stToken = useSelector(stTokenSelector);

        return <StTeacherSelectModalWindow
            stToken={stToken}
            trigger={
                <Input ref={ref} style={{cursor: "pointer"}} placeholder={t`Нажмите, чтобы выбрать`}
                       value={props.value?.longName}
                       readOnly/>}
            onChange={(newValue) => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue);
                }
            }}/>
    }
)