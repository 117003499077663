import React, {useMemo} from 'react';
import styled from "styled-components";

const MAX_VALUE = 100;

interface ProgressBarProps {
    wrongValue: number;
    rightValue: number;
}

const ProgressBarBase = styled.div`
  border-radius: 6px;
  transition: width 0.3s ease;
  overflow: hidden;
`;

const ProgressBarWrapper = styled(ProgressBarBase)`
  background-color: ${({theme}) => theme.colors.progressBarBackground};
  position: relative;
  height: 9px;
`;

const ProgressBarBg = styled(ProgressBarBase)`
  background-color: ${({theme}) => theme.colors.progressBarBackground};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 6px 0px 0px 6px;
  //width: X
`;

const ProgressBarRight = styled(ProgressBarBase)`
  background-color: ${({theme}) => theme.colors.progressBarRight};
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 6px 0px 0px 6px;
  //width: X
`;

const ProgressBarWrong = styled.div`
  background-color: ${({theme}) => theme.colors.progressBarWrong};
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 0px 6px 6px 0px;
  //width: X
`;

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
    const {wrongValue, rightValue} = props;

    const backgroundWidth = useMemo<number>(() => {
        return MAX_VALUE - wrongValue;
    }, [wrongValue]);

    return <ProgressBarWrapper>
        <ProgressBarWrong style={{width: wrongValue.toString(10) + "%"}}/>
        <ProgressBarBg style={{width: backgroundWidth.toString(10) + "%"}}/>
        <ProgressBarRight style={{width: rightValue.toString(10) + "%"}}/>
    </ProgressBarWrapper>;
}