import {ProgressBar} from "./ProgressBar";
import styled from "styled-components";
import Energy32 from "../../../../../components/Ui/Png/Energy32@2x.png";
import React from "react";

const Wrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
`;

const BarWrapper = styled.div`
    flex-grow: 1;
`;

const IconWrapper = styled.div`
    min-width: 32px;
    max-width: 32px;
    white-space: nowrap;
`;

const ImageStyled = styled.img`
    vertical-align: middle;
    display: inline;
`;

interface EnergyBarProps {
    className?: string;
}

export const EnergyBar: React.FC<EnergyBarProps> = ({className}) => {
    return <Wrapper className={className}>
        <BarWrapper>
            <ProgressBar/>
        </BarWrapper>
        <IconWrapper>
            <ImageStyled src={Energy32} alt={'Energy icon'} width={'32px'} height={'32px'}/>
        </IconWrapper>
    </Wrapper>
}