import React from "react";
import styled from "styled-components";
import {getItemsPos, ScrollMenu} from "react-horizontal-scrolling-menu";
import useDrag from "./useDrag";
import {HorizontalNavigationItemType} from "./HorizontalNavigationItemType";
import {Item} from "./Item";
import {ScrollVisibilityApiType} from "./ScrollVisibilityApiType";
import {ViewModeEnum} from "./ViewModeEnum";
import classNames from "classnames";

interface PageModeSelectorProps {
    items: HorizontalNavigationItemType[];
    activeItemId: string;
    viewMode: ViewModeEnum;
    onItemChange: (newItemId: string) => void;
    className?: string;
}

const Wrapper = styled.div`
  margin-bottom: 30px;
  box-sizing: border-box;
  touch-action: pan-y;

  .react-horizontal-scrolling-menu--wrapper {
    display: flex;
    flex-direction: column;
    box-sizing:inherit;
  }

  .react-horizontal-scrolling-menu--inner-wrapper {
    display: flex;
    overflow-y: hidden;
    position: relative;
    box-sizing:inherit;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    display: flex;
    height: max-content;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    gap: 10px;
    box-sizing:inherit;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  &.step-wizard-view {
    pointer-events: none;
  }
`;

function onWheel(apiObj: ScrollVisibilityApiType, ev: React.WheelEvent): void {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

export const HorizontalNavigation: React.FC<PageModeSelectorProps> = (
    {
        items,
        onItemChange,
        activeItemId,
        viewMode,
        className
    }
) => {
    const {dragStart, dragStop, dragMove, dragging} = useDrag();

    const handleDrag = ({scrollContainer}: ScrollVisibilityApiType) => (
        ev: React.MouseEvent
    ) =>
        dragMove(ev, (posDiff) => {
            if (scrollContainer.current) {
                scrollContainer.current.scrollLeft += posDiff;
            }
        });

    const handleItemClick = (itemId: string, {getItemById, scrollToItem}: ScrollVisibilityApiType) => {
        if (dragging) {
            return false;
        }

        onItemChange(itemId);
        // NOTE: for center items
        // scrollToItem(getItemById(itemId), "smooth", "center", "nearest");
    };

    return <Wrapper className={classNames(
        className,
        viewMode === ViewModeEnum.STEP_WIZARD && 'step-wizard-view'
    )}>

        {/*<LeftArrow/>*/}
        {/*<RightArrow/>*/}
        <div onMouseLeave={dragStop}>
            <ScrollMenu
                // LeftArrow={LeftArrow}
                // RightArrow={RightArrow}
                // Footer={Arrows}
                onWheel={onWheel}
                onMouseDown={() => dragStart}
                onMouseUp={({
                                getItemById,
                                scrollToItem,
                                visibleItems
                            }: ScrollVisibilityApiType) => () => {
                    // NOTE: for center items
                    dragStop();
                    const {center} = getItemsPos(visibleItems);
                    scrollToItem(getItemById(center), "smooth", "center");
                }}
                options={{throttle: 0}}
                onMouseMove={handleDrag}
            >
                {
                    items.map((item, index) => {
                        return <Item key={item.id}
                                     itemPayload={item}
                                     isActiveItem={item.id === activeItemId}
                                     onClick={handleItemClick}
                                     viewMode={viewMode}
                                     isLastItem={index + 1 === items.length}
                                     disabled={!!item.disabled}
                        />;
                    })
                }
            </ScrollMenu>
        </div>
    </Wrapper>
}
