import * as React from "react";
import styled from 'styled-components';
import {Link, RouterLink} from "../Ui/Elements/Link";
import {Trans} from "@lingui/macro"
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {LocaleEnum, LocaleNameEnum} from "../../../store/i18n/type";
import * as i18nActionCreators from "../../../store/i18n/actions";
import * as layoutActionCreators from "../../../store/layout/actions";
import {ThemeEnum} from "../../../services/theme/ThemeEnum";
import {useLocation} from "react-router-dom";
import {RoutesList} from "../../RoutesList";
import useMediaQuery from "../../../services/hooks/useMediaQuery";
import {ITheme} from "../../../services/theme/ITheme";
import {ReactComponent as DarkThemeIcon} from "../Ui/Svg/DarkThemeIconMedium.svg";
import {ReactComponent as LightThemeIcon} from "../Ui/Svg/LightThemeIconMedium.svg";

const Wrapper = styled.div`
  display: block;

  @media (${({theme}) => theme.media.small}) {
    display: flex;
    flex-direction: column;
  }
`;

const ChangeThemeIconLine = styled.div`
  display: none;
  
  @media (${({theme}) => theme.media.small}) {
    display: block;
  }
`;

const ChangeThemeIconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  user-select: none;
`;

const LinksList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const LinkStyled = styled(Link)`
  font-size: 12px;
  margin-bottom: 12px;
  margin-right: 12px;
  text-decoration: none;

  @media (${({theme}) => theme.media.small}) {
    font-size: 14px;
    margin-bottom: 8px;

    color: ${({theme}) => theme.colors.simpleWhite};

    :hover, :focus {
      color: ${({theme}) => theme.colors.simpleWhite};
      text-decoration: none;
    }
  }
`;

const RouterLinkStyled = styled(RouterLink)`
  font-size: 12px;
  margin-bottom: 12px;
  margin-right: 12px;
  text-decoration: none;

  @media (${({theme}) => theme.media.small}) {
    font-size: 14px;
    margin-bottom: 8px;
    color: ${({theme}) => theme.colors.simpleWhite};

    :hover {
      color: ${({theme}) => theme.colors.simpleWhite};
      text-decoration: none;
    }
  }
`;

export const CookieDocsLink: React.FC = () => {
    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const currentThemeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as ThemeEnum;

    const dispatch = useDispatch();
    const location = useLocation();

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const switchLanguage = () => {
        if (currentLocale === LocaleEnum.RU) {
            dispatch(i18nActionCreators.selectLocale(LocaleEnum.EN));

            return;
        }

        dispatch(i18nActionCreators.selectLocale(LocaleEnum.RU));
    }

    const langLinkText = () => {
        return (currentLocale === LocaleEnum.RU)
            ? LocaleNameEnum.EN
            : LocaleNameEnum.RU;
    }

    const switchTheme = () => {
        if (currentThemeName === ThemeEnum.light) {
            dispatch(layoutActionCreators.changeTheme(ThemeEnum.dark));

            return;
        }

        dispatch(layoutActionCreators.changeTheme(ThemeEnum.light));
    }

    return (
        <Wrapper>
            <ChangeThemeIconLine>
                <ChangeThemeIconWrapper onClick={switchTheme}>
                    {
                        (currentThemeName === ThemeEnum.light) ? <DarkThemeIcon/> : <LightThemeIcon/>
                    }
                </ChangeThemeIconWrapper>
            </ChangeThemeIconLine>
            <LinksList>
                <div>
                    {
                        (!isSmall) && (<LinkStyled onClick={switchTheme}>
                            {
                                (currentThemeName === ThemeEnum.light)
                                    ? <Trans>Тёмная тема</Trans>
                                    : <Trans>Светлая тема</Trans>
                            }
                        </LinkStyled>)
                    }

                    <LinkStyled onClick={switchLanguage}>
                        {langLinkText()}
                    </LinkStyled>
                </div>
                {
                    (location.pathname !== RoutesList.TEACHER_LOGIN_FORM) && (<div>
                        <RouterLinkStyled to={RoutesList.TEACHER_LOGIN_FORM}>
                            <Trans>Вход преподавателя</Trans>
                        </RouterLinkStyled>
                    </div>)
                }
                <div>
                    <LinkStyled href="https://stonline.club/privacy" target="_blank">
                        <Trans>Политика конфиденциальности</Trans>
                    </LinkStyled>
                </div>
                <div>
                    <LinkStyled href="https://stonline.club/cookie" target="_blank">
                        <Trans>Файлы cookie</Trans>
                    </LinkStyled>
                </div>
                <div>
                    <LinkStyled href={process.env.REACT_APP_LANDING_URL ?? "#"} target="_blank">
                        <Trans>О программе</Trans>
                    </LinkStyled>
                </div>
            </LinksList>
        </Wrapper>
    );
}