import React, {useMemo} from "react";
import styled from "styled-components";
import {ReactComponent as ImagePlaceholderSvg} from "../../../Ui/Svg/ImagePlaceholder.svg";
import {Trans} from "@lingui/macro";
import {SlidePictureMimeSupported} from "../../../../../enums/UserFileEnums";

const ImagePlaceholderStyled = styled.div`
  width: 100%;
  height: 300px;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 10px;
  text-align: center;
`;

const FirstWrapper = styled.div`
  border-radius: 5px;
  border: 1px dashed ${({theme}) => theme.colors.accentDivider};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const SecondWrapper = styled.div`
  color: ${({theme}) => theme.colors.textTertiary};
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

const TextWrapper = styled.div`

`;

interface ImagePlaceholderProps {
    onNewFileSelect: (file: File) => void;
}

export const ImagePlaceholder: React.FC<ImagePlaceholderProps> = (props) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.files) && (event.target.files[0])) {
            props.onNewFileSelect(event.target.files[0]);
        }
    };

    const supportedMimeTypes = useMemo<string>(() => {
        const arr = Object.values(SlidePictureMimeSupported);

        let stringList = "";

        arr.forEach((item) => {
            stringList += (stringList === "" ? '' : ',') + item;
        });

        return stringList;
    }, []);

    return <ImagePlaceholderStyled>
        <FirstWrapper onClick={handleClick}>
            <SecondWrapper>
                <IconWrapper>
                    <ImagePlaceholderSvg/>
                </IconWrapper>
                <TextWrapper>
                    <Trans>Нажмите, чтобы выбрать изображение</Trans>
                </TextWrapper>
            </SecondWrapper>
        </FirstWrapper>
        <input type="file"
               accept={supportedMimeTypes}
               ref={hiddenFileInput}
               onChange={handleChange}
               style={{display: 'none'}}
        />
    </ImagePlaceholderStyled>
}