import {action} from "typesafe-actions";

import {
    LessonRoomActionTypes,
    LessonRoomMember,
    LessonRoomStateTypes,
    MembersListLoadingStateEnum,
    RoomConnectionParams
} from "./type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {DtoResponseWithPagination} from "../../components/WsApiClient/ApiDto/Response/DtoResponseWithPagination";
import {DtoRoomParticipant} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoRoomParticipant";
import {DtoParticipantLeft} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoParticipantLeft";
import {
    DtoSEParticipantCurrentSlideInfo
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEParticipantCurrentSlideInfo";
import {
    DtoSEStudentNeedOpenSlide
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEStudentNeedOpenSlide";
import {
    DtoSENeedSelectRoomMemberVideo
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSelectRoomMemberVideo";
import {
    DtoSENeedSetMembersFullScreenModeValue
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSetMembersFullScreenModeValue";
import {
    DtoSEMemberPublicVideoPinState
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicVideoPinState";
import {
    DtoSEMemberPublicFullScreenValue
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicFullScreenValue";
import {
    DtoSEMemberPublicScreenOrientation
} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicScreenOrientation";

export const setLessonRoomState = (newState: LessonRoomStateTypes) => {
    return action(LessonRoomActionTypes.SET_LESSON_ROOM_STATE, newState);
};

export const setLessonRoomId = (roomId: string | null) => {
    return action(LessonRoomActionTypes.SET_LESSON_ROOM_ID, roomId);
};

export const setRoomConnectionParams = (newValue: RoomConnectionParams | null) => {
    return action(LessonRoomActionTypes.SET_ROOM_CONNECTION_PARAMS, newValue);
}

export const loadRoomMembersList = (roomId: string) => {
    return action(LessonRoomActionTypes.LOAD_ROOM_MEMBERS_LIST, roomId);
}

export const handleRoomMembersListResult = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoResponseWithPagination<DtoRoomParticipant>>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_ROOM_MEMBERS_LIST_RESULT, data);
}

export const handleSeMemberEntered = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoRoomParticipant>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_ENTERED, data);
}

export const handleSeMemberLeft = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoParticipantLeft>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_LEFT, data);
}

export const handleSeMemberOpenedSlideChanged = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEParticipantCurrentSlideInfo>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_OPENED_SLIDE_CHANGED, data);
}

export const handleSeStudentNeedOpenSlide = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEStudentNeedOpenSlide>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_STUDENT_NEED_OPEN_SLIDE, data);
}

export const handleSeNeedSelectRoomMemberVideo = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSENeedSelectRoomMemberVideo>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_NEED_SELECT_ROOM_MEMBER_VIDEO, data);
}

export const handleSeNeedSetMembersFullScreenModeValue = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSENeedSetMembersFullScreenModeValue>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE, data);
}

export const handleSeMemberPublicVideoPinState = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEMemberPublicVideoPinState>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_VIDEO_PIN, data);
}

export const handleSeMemberPublicFullScreenValue = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEMemberPublicFullScreenValue>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_FULL_SCREEN_MODE, data);
}

export const handleSeMemberPublicScreenOrientation = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEMemberPublicScreenOrientation>, string>) => {
    return action(LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_SCREEN_ORIENTATION, data);
}

export const processScreenOrientationOnChange = (lessonId: string, newValue: string | null) => {
    return action(LessonRoomActionTypes.PROCESS_SCREEN_ORIENTATION_ON_CHANGE, {
        lessonId: lessonId,
        newValue: newValue
    });
}

export const setRoomMembersListLoadingState = (newState: MembersListLoadingStateEnum) => {
    return action(LessonRoomActionTypes.SET_ROOM_MEMBERS_LIST_LOADING_STATE, newState);
}

export const setItemsToMembersList = (items: LessonRoomMember[]) => {
    return action(LessonRoomActionTypes.SET_ITEMS_TO_MEMBERS_LIST, items);
}

export const setMemberScreenOrientation = (memberId: string, value: string | null) => {
    return action(LessonRoomActionTypes.SET_MEMBER_SCREEN_ORIENTATION, {
        memberId,
        value
    });
}

export const setMemberIsInFullScreen = (memberId: string, value: any) => {
    return action(LessonRoomActionTypes.SET_MEMBER_IS_IN_FULL_SCREEN, {memberId, value});
}

export const setMemberPinedUser = (memberId: string, value: any) => {
    return action(LessonRoomActionTypes.SET_MEMBER_PINNED_USER, {memberId, value});
}

export const setMemberOpenedSlideId = (memberId: string, slideId: string) => {
    return action(LessonRoomActionTypes.SET_MEMBER_OPENED_SLIDE_ID, {
        memberId,
        slideId
    });
}

export const removeItemFromMembersList = (userId: string) => {
    return action(LessonRoomActionTypes.REMOVE_ITEM_FROM_MEMBERS_LIST, userId);
}

export const clearMembersList = () => {
    return action(LessonRoomActionTypes.CLEAR_MEMBERS_LIST);
}

export const handleLeaveFromRoomViaRouter = () => {
    return action(LessonRoomActionTypes.HANDLE_LEAVE_FROM_ROOM_VIA_ROUTER);
}

export const clearRoomState = () => {
    return action(LessonRoomActionTypes.CLEAR_ROOM_STATE);
}
