import React, {useCallback, useContext, useEffect, useRef} from "react";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {processScreenOrientationOnChange} from "../../../../../store/lessonRoom/actions";
import {useDispatch} from "react-redux";
import {IDeviceOrientation} from "../../../../../components/DeviceOrientation/IDeviceOrientation";
import {DeviceOrientatonTypeEnum} from "../../../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";
import {CommonContext, ICommonContext} from "../../contexts/CommonContext";

const DEVICE_ORIENTATION_SUBSCRIBER_ID = 'screenOrientationPublisher';

export const ScreenOrientationPublisher: React.FC = ({children}) => {
    const dispatch = useDispatch();

    const commonContext = useContext<ICommonContext>(CommonContext);
    const lastPublishState = useRef<DeviceOrientatonTypeEnum | null>(null);
    const deviceOrientationService = useRef(container.resolve<IDeviceOrientation>(DiTokens.DEVICE_ORIENTATION));

    const sendCurrentOrientation = useCallback(() => {
        const newValue = deviceOrientationService.current.getCurrentState();

        if (newValue !== lastPublishState.current) {
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.info(
                LoggerSectionsEnum.LESSON_ROOM_SCREEN_ORIENTATION_PUBLISHER,
                `New screen orientation is ${newValue}`
            );

            dispatch(processScreenOrientationOnChange(
                commonContext.lessonId,
                newValue
            ));

            lastPublishState.current = newValue
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        deviceOrientationService.current.registerSubscriber(
            DEVICE_ORIENTATION_SUBSCRIBER_ID,
            sendCurrentOrientation
        );

        sendCurrentOrientation();

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            deviceOrientationService.current.unregisterSubscriber(
                DEVICE_ORIENTATION_SUBSCRIBER_ID
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>
}
