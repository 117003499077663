import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes, LessonRoomUserTypeEnum} from "../../store/lessonRoom/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {removeItemFromMembersList} from "../../store/lessonRoom/actions";
import {ApplicationState} from "../../store";
import {DtoParticipantLeft} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoParticipantLeft";
import {NotificationTypesEnum, openNotification} from "../../views/components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {StreamEventStoreItem} from "../../store/streamEvent/type";

/**
 * Обработка сообщения об отключении пользователя от комнаты
 */
export function* watchHandleRoomMemberLeft() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoParticipantLeft>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_LEFT,
        handleRoomMemberLeft
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleRoomMemberLeft(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoParticipantLeft>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.roomId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    yield put(removeItemFromMembersList(data.payload.response.dto.payload.userId));

    openNotification(
        NotificationTypesEnum.INFO,
        (data.payload.response.dto.payload.userType === LessonRoomUserTypeEnum.TEACHER)
            ? t`Вышел учитель`
            : t`Вышел ученик`,
        data.payload.response.dto.payload.userName
    );
}
