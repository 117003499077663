import React, {useEffect} from "react";
import {unstable_usePrompt as usePrompt, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {reportLocationPosition} from "../../../store/app/actions";
import {ApplicationState} from "../../../store";

export const LocationWatcher: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const beforeUnloadMessage = useSelector(({app}: ApplicationState) => app.routeLeaveConfirmMessage);

    usePrompt({
        when: beforeUnloadMessage !== null,
        message: beforeUnloadMessage??''
    });

    useEffect(() => {
        dispatch(reportLocationPosition(document.location.pathname));
    }, [dispatch, location]);

    return null;
}
