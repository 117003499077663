import {IsBoolean, IsNotEmpty, IsNumber, IsString, Length, Max, Min} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO слайда урока
 */
export class DtoTmSlide {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    name: string;

    @IsNotEmpty()
    @IsNumber()
    @Min(0)
    @Max(50)
    exerciseCount: number;

    @IsNumber()
    @Min(1)
    @Max(200)
    currentVersion: number;

    @IsNotEmpty()
    @IsBoolean()
    isAdditional: boolean;

    @IsNotEmpty()
    @IsBoolean()
    forHomework: boolean;

    @IsNotEmpty()
    @IsNumber()
    @Min(1)
    @Max(5)
    actualParamNum: number;

    @IsNotEmpty()
    @IsNumber()
    @Max(32767)
    orderPosition: number;
}
