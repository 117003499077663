import {useContext, useMemo} from "react";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {Avatar} from "../../../../../components/Ui/Elements/Avatar";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {DateAsString} from "../../../../../components/Ui/Elements/TextPresenter/DateAsString";
import {RegularTextCss} from "../../../../../styles/global-elements";
import {
    IconWrapper,
    Row,
    TextWrapper,
    Wrapper as IconRowsWrapper
} from "../../../../../components/PageWrapper/ListWithIcons";
import {MoneyHelper} from "../../../../../../helpers/MoneyHelper";
import {ReactComponent as WalletIcon} from "../../../../../components/Ui/Svg/Wallet20.svg";
import {ReactComponent as PhoneIcon} from "../../../../../components/Ui/Svg/Phone20.svg";
import {ReactComponent as ClockIcon} from "../../../../../components/Ui/Svg/Clock20.svg";
import {InviteUserBtn} from "./InviteUserBtn";
import {GetAuthLinkBtn} from "./GetAuthLinkBtn";
import {ResetPasswordBtn} from "./ResetPasswordBtn";
import {PhoneString} from "./PhoneString";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const AvatarWrapper = styled.div`
    align-self: center;
`;

const HasOnlineString = styled.div`
    ${RegularTextCss};

    text-align: center;
    margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ButtonWrapper2 = styled.div`
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: max-content;
`;

const IconRowsWrapperStyled = styled(IconRowsWrapper)`
    margin-bottom: 32px;

    @media (${({theme}) => theme.media.large}) {
        margin-bottom: 20px;
    }
`;

export const RightBlock = () => {
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);
    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const lastSeenString = useMemo<JSX.Element>(() => {
        const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        const isMale = studentPageContext.studentGeneralInfoDto.gender === 1;

        if (!studentPageContext.studentEntranceInfoResponse) {
            return <HasOnlineString>
                <Trans>Нет возможности зарегистрировать на платформе - не указан email</Trans>
            </HasOnlineString>
        }

        if (studentPageContext.studentEntranceInfoResponse.registeredAt === null) {
            return <HasOnlineString>
                {(isMale) ? t`Не зарегистрирован на платформе` : t`Не зарегистрирована на платформе`}
            </HasOnlineString>
        }

        if (studentPageContext.studentEntranceInfoResponse.lastSeenAt === null) {
            return <HasOnlineString>
                {(isMale) ? t`Зарегистрирован, но ещё не был онлайн` : t`Зарегистрирована, но ещё не была онлайн`}
            </HasOnlineString>
        }

        const lastSeenAsDateObject = dateHelper
            .dateFromString(studentPageContext.studentEntranceInfoResponse.lastSeenAt);

        return <HasOnlineString>
            {
                (isMale) ? <Trans>Был онлайн</Trans> : <Trans>Была онлайн</Trans>
            }&nbsp;
            <DateAsString date={lastSeenAsDateObject}/>
        </HasOnlineString>
    }, [studentPageContext.studentEntranceInfoResponse, studentPageContext.studentGeneralInfoDto.gender]);

    const balanceString = useMemo<string>(() => {
        return MoneyHelper.formatMoney(
            parseFloat(studentPageContext.studentGeneralInfoDto.balance)
        );
    }, [studentPageContext.studentGeneralInfoDto.balance]);

    const studentLocalTime = useMemo<string>(() => {
        const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        const zoneTimeAsString = dateHelperService
            .dateFromStringToTimeString(studentPageContext.studentGeneralInfoDto.studentLocalTime);

        return `${zoneTimeAsString} (${studentPageContext.studentGeneralInfoDto.studentLocalUtcDiff})`;

    }, [
        studentPageContext.studentGeneralInfoDto.studentLocalTime,
        studentPageContext.studentGeneralInfoDto.studentLocalUtcDiff
    ]);

    const actionBtn = useMemo<JSX.Element | null>(() => {
        if (!studentPageContext.studentEntranceInfoResponse) {
            return null;
        }

        if (studentPageContext.studentEntranceInfoResponse.registeredAt === null) {
            return <InviteUserBtn/>;
        }

        return <><GetAuthLinkBtn/><ResetPasswordBtn onSuccess={() => {
        }}/></>
    }, [studentPageContext.studentEntranceInfoResponse]);

    return <Wrapper>
        <AvatarWrapper>
            <Avatar
                userName={null}
                fileId={
                    (studentPageContext.studentEntranceInfoResponse)
                        ? studentPageContext.studentEntranceInfoResponse.avatarFileId
                        : null
                }
                size={isSmall ? 96 : 64}
            />
        </AvatarWrapper>
        {lastSeenString}
        {
            (!isLarge) && <ButtonWrapper><ButtonWrapper2>{actionBtn}</ButtonWrapper2></ButtonWrapper>
        }
        <IconRowsWrapperStyled>
            <Row>
                <IconWrapper>
                    <WalletIcon/>
                </IconWrapper>
                <TextWrapper>
                    {balanceString}
                </TextWrapper>
            </Row>
            {
                (studentPageContext.studentGeneralInfoDto.smsPhone)
                && <Row>
                    <IconWrapper>
                        <PhoneIcon/>
                    </IconWrapper>
                    <TextWrapper>
                        <PhoneString
                            unformattedPhoneString={studentPageContext.studentGeneralInfoDto.smsPhone}
                        />
                    </TextWrapper>
                </Row>
            }
            <Row>
                <IconWrapper>
                    <ClockIcon/>
                </IconWrapper>
                <TextWrapper>
                    {studentLocalTime}
                </TextWrapper>
            </Row>
        </IconRowsWrapperStyled>
        {
            (isLarge)
            && <ButtonWrapper>
                <ButtonWrapper2>
                    {actionBtn}
                </ButtonWrapper2>
            </ButtonWrapper>
        }
    </Wrapper>
}