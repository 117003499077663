import {Sides} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/SlidesEnum";

export enum ElementRenderState {
    START = 'start',
    CALC_MAX_WIDTH = 'calc_max_width',
    CALC_TOP_POSITIONS = 'calc_top_positions',
    READY = 'ready'
}

export interface IItemIdWithSide {
    side: Sides,
    id: string
}
