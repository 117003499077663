import React, {useCallback, useContext, useEffect, useRef} from "react";
import {VideoRoomActionsContextProvider} from "./VideoRoomActionsContext";
import {useDispatch, useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../store/user/selector";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {IVideoRoomContext, VideoRoomContext} from "../../contexts/VideoRoomContext";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {JanusVideoRoomPlugin} from "../../../../../components/JanusClient/VideoRoom/JanusVideoRoomPlugin";
import {IMediaDeviceService} from "../../../../../services/media-device/IMediaDeviceService";
import {IDeviceMediaStreamFetcher} from "../../../../../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {IJanusConnection} from "../../../../../components/JanusClient/IJanusConnection";
import {IJanusVideoRoomPlugin} from "../../../../../components/JanusClient/VideoRoom/IJanusVideoRoomPlugin";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import * as appActionCreators from "../../../../../store/app/actions";
import {cloneDeep} from "lodash";
import {ConnectionStateEnum, StreamStatusEnum, SubscriptionRequestItem, VideoRoomConnectionState} from "../../Types";
import {DeviceInfo} from "../../../../../services/media-device/Types";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {ItemState} from "../../../../../components/JanusClient/VideoRoom/StreamsStore";
import {StreamUpdateParams} from "../../../../../components/JanusClient/VideoRoom/Types";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../common/RoomCommonActionsContext";
import {StreamTypeEnum} from "../../../../../components/JanusClient/Types";
import {SubscribeRequestItem} from "../../../../../components/JanusClient/VideoRoom/PeerConnectionHandlers/Subscriber";

interface VideoRoomActionsProviderProps {
    setVideoRoomConnectionState: React.Dispatch<React.SetStateAction<VideoRoomConnectionState>>;
}

export const VideoRoomActionsProvider: React.FC<VideoRoomActionsProviderProps> = (
    {children, setVideoRoomConnectionState}
) => {
    const dispatch = useDispatch();
    const currentUserId = useSelector(currentUserIdSelector);

    const commonContext = useContext<ICommonContext>(CommonContext);
    const roomCommonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);
    const videoRoomPlugin = useRef<IJanusVideoRoomPlugin | null>(null);

    const enableMyVideo = useCallback(() => {
        videoRoomPlugin.current?.startOwnVideo();

        setVideoRoomConnectionState((state) => {
            return {
                ...state,
                cameraMuted: false
            }
        });
    }, [setVideoRoomConnectionState]);

    const disableMyVideo = useCallback(() => {
        videoRoomPlugin.current?.stopOwnVideo();

        setVideoRoomConnectionState((state) => {
            return {
                ...state,
                cameraMuted: true
            }
        });
    }, [setVideoRoomConnectionState]);

    const startScreenSharing = useCallback(() => {
        videoRoomPlugin.current?.startScreenShare();
    }, []);

    const stopScreenSharing = useCallback(() => {
        videoRoomPlugin.current?.stopScreenShare();
    }, []);

    const setMyVideoIsAvailable = useCallback((value: boolean) => {
        setVideoRoomConnectionState((state) => {
            return {
                ...state,
                myVideoIsAvailable: value
            }
        });
    }, [setVideoRoomConnectionState]);

    const setMyScreenSharingIsAvailable = useCallback((value: boolean) => {
        setVideoRoomConnectionState((state) => {
            return {
                ...state,
                myScreenSharingIsAvailable: value
            }
        });
    }, [setVideoRoomConnectionState]);

    const connectToRoom = useCallback(async () => {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        videoRoomPlugin.current = new JanusVideoRoomPlugin(
            logger,
            container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE),
            container.resolve<IJanusConnection>(DiTokens.JANUS_CONNECTION),
            container.resolve<IDeviceMediaStreamFetcher>(DiTokens.DEVICE_MEDIA_STREAM_FETCHER),
        );

        if ((commonContext.conferenceConnectionParams === null) || (currentUserId === null)) {
            logger.error(
                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                `Empty conferenceConnectionParams`
            );

            dispatch(appActionCreators.setInFatalErrorState(true));

            return;
        }

        const emitConnectionError = () => {
            setVideoRoomConnectionState((state) => {
                const newState = cloneDeep(state);

                newState.publisherConnection.status = ConnectionStateEnum.CONNECTION_ERROR;
                newState.subscriberConnection.status = ConnectionStateEnum.CONNECTION_ERROR;

                return newState;
            });

            roomCommonActionsContext.setUnavailableAudioForAllParticipants();
        };

        videoRoomPlugin.current?.joinToVideoRoom(
            {
                userId: currentUserId,
                roomId: commonContext.conferenceConnectionParams.janusRoomId,
                roomJoinKey: commonContext.conferenceConnectionParams.janusJoinKey,
                callbacks: {
                    publisherJoinedSuccessfully: () => {
                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                publisherConnection: {
                                    status: ConnectionStateEnum.CONNECTED,
                                    badConnectionSignal: null,
                                    totalLossesPacked: 0
                                }
                            }
                        });
                    },
                    publisherError: () => {
                        setVideoRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            result.publisherConnection.status = ConnectionStateEnum.CONNECTION_ERROR;

                            return result;
                        });
                    },
                    publisherSlowLinkMessage: (lostPacketsCount: number) => {
                        setVideoRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            if (result.publisherConnection.badConnectionSignal === null) {
                                result.publisherConnection.badConnectionSignal = new Date();
                            }

                            result.publisherConnection.totalLossesPacked = lostPacketsCount;

                            return result;
                        });
                    },
                    publisherIceDisconnected: () => {
                        emitConnectionError();
                    },
                    publisherCleanup: () => {
                    },
                    publisherOnDestroy: () => {
                        roomCommonActionsContext.setUnavailableVideoForAllParticipants();

                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                publisherConnection: {
                                    status: ConnectionStateEnum.NOT_INIT,
                                    badConnectionSignal: null,
                                    totalLossesPacked: 0
                                }
                            }
                        });
                    },
                    subscriberJoinedSuccessfully: () => {
                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                subscriberConnection: {
                                    status: ConnectionStateEnum.CONNECTED,
                                    badConnectionSignal: null,
                                    totalLossesPacked: 0
                                }
                            }
                        });
                    },
                    subscriberError: () => {
                        setVideoRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            result.subscriberConnection.status = ConnectionStateEnum.CONNECTION_ERROR;

                            return result;
                        });
                    },
                    subscriberSlowLinkMessage: (lostPacketsCount: number) => {
                        setVideoRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            if (result.subscriberConnection.badConnectionSignal === null) {
                                result.subscriberConnection.badConnectionSignal = new Date();
                            }

                            result.subscriberConnection.totalLossesPacked = lostPacketsCount;

                            return result;
                        });
                    },
                    subscriberIceDisconnected: () => {
                        emitConnectionError();
                    },
                    subscriberCleanup: () => {
                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                subscriberConnection: {
                                    status: ConnectionStateEnum.NOT_INIT,
                                    badConnectionSignal: null,
                                    totalLossesPacked: 0
                                }
                            }
                        });
                    },
                    onCameraGoneAway: (oldCamera: DeviceInfo | null, fatal: boolean) => {
                        if (!fatal) {
                            openNotification(
                                NotificationTypesEnum.INFO,
                                t`Камера отключилась`,
                                (oldCamera)
                                    ? t`Камера ${oldCamera.name} отключилась, подключаем другую`
                                    : ''
                            );
                        } else {
                            openNotification(
                                NotificationTypesEnum.ERROR,
                                t`Камера отключилась`,
                                (oldCamera)
                                    ? t`Камера ${oldCamera.name} больше не работает`
                                    : ''
                            );

                            setVideoRoomConnectionState((state) => {
                                const result = cloneDeep(state);

                                result.publisherConnection.status = ConnectionStateEnum.CONNECTION_ERROR;

                                return result;
                            });
                        }
                    },
                    onCameraChangeError: (brokenCamera: DeviceInfo, activeCamera: DeviceInfo | null) => {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось сменить камеру`,
                            (activeCamera)
                                ? t`${brokenCamera.name} не подключилась, продолжает работать ${activeCamera.name}`
                                : t`${brokenCamera.name} не подключилась`
                        );
                    },
                    onTrackStateChange: (userId, trackType, state: ItemState | null) => {
                        let status: StreamStatusEnum = StreamStatusEnum.NOT_AVAILABLE;

                        if (state !== null) {
                            switch (state) {
                                case ItemState.AVAILABLE: {
                                    status = StreamStatusEnum.AVAILABLE;

                                    break;
                                }
                                case ItemState.PREPARING: {
                                    status = StreamStatusEnum.CONNECTING;

                                    break;
                                }
                                case ItemState.READY: {
                                    status = StreamStatusEnum.CONNECTED;

                                    break;
                                }
                            }
                        }

                        switch (trackType) {
                            case StreamUpdateParams.VIDEO: {
                                roomCommonActionsContext.setParticipantsListItemUpdate([{
                                    id: userId,
                                    videoStatus: status
                                }]);

                                break;
                            }
                            case StreamUpdateParams.SCREEN_VIDEO: {
                                roomCommonActionsContext.setParticipantsListItemUpdate([{
                                    id: userId,
                                    screenVideo: status
                                }]);
                                break;
                            }
                            case StreamUpdateParams.SCREEN_AUDIO: {
                                roomCommonActionsContext.setParticipantsListItemUpdate([{
                                    id: userId,
                                    screenAudio: status
                                }]);

                                break;
                            }
                        }
                    },
                    onCameraChanged: (newCameraInfo: DeviceInfo) => {
                        openNotification(
                            NotificationTypesEnum.INFO,
                            t`Камера изменена`,
                            `Сейчас используется ${newCameraInfo.name}`
                        );
                    },
                    onCameraStreamAvailable: (value) => {
                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                myVideoIsAvailable: value
                            }
                        });
                    },
                    onScreenSharingStreamAvailable: (value) => {
                        setVideoRoomConnectionState((state) => {
                            return {
                                ...state,
                                myScreenSharingIsAvailable: value
                            }
                        });
                    },
                    roomClosed: () => {

                    },
                    publisherOnHangUp: () => {
                        //TODO?
                    },
                    subscriberOnHangUp: () => {
                        //TODO?
                    }
                }
            }
        );

    }, [commonContext.conferenceConnectionParams, currentUserId, dispatch, roomCommonActionsContext, setVideoRoomConnectionState]);

    const subscribeToStreams = (subscribeRequestItems: SubscriptionRequestItem[]) => {
        const streamStore = videoRoomPlugin.current?.getStreamStore();

        const requestList: SubscribeRequestItem[] = [];

        subscribeRequestItems.forEach((item) => {
            const storeItem = streamStore?.findByUserIdAndType(item.userId, item.streamType);

            if (!storeItem || storeItem.mid === null || storeItem.feedId === null) {
                return;
            }

            requestList.push({
                mid: storeItem.mid,
                feedId: storeItem.feedId
            });
        });

        videoRoomPlugin.current?.subscribeToStreams(requestList);
    }

    const unsubscribeFromStreams = (unsubscribeRequestItems: SubscriptionRequestItem[]) => {
        const streamStore = videoRoomPlugin.current?.getStreamStore();

        const requestList: SubscribeRequestItem[] = [];

        unsubscribeRequestItems.forEach((item) => {
            const storeItem = streamStore?.findByUserIdAndType(item.userId, item.streamType);

            if (!storeItem || storeItem.mid === null || storeItem.feedId === null) {
                return;
            }

            requestList.push({
                mid: storeItem.mid,
                feedId: storeItem.feedId
            });
        });

        videoRoomPlugin.current?.unsubscribeFromStreams(requestList);
    }

    const attachVideoToElement = (userId: string, videoElement: HTMLVideoElement): boolean => {
        return videoRoomPlugin.current?.attachStreamToElement(userId, StreamTypeEnum.VIDEO, videoElement) ?? false;
    }

    const attachScreenVideoToElement = (userId: string, videoElement: HTMLVideoElement): boolean => {
        return videoRoomPlugin.current?.attachStreamToElement(userId, StreamTypeEnum.SCREEN, videoElement) ?? false;
    }

    const attachScreenAudioToElement = (userId: string, audioElement: HTMLAudioElement): boolean => {
        return videoRoomPlugin.current?.attachStreamToElement(userId, StreamTypeEnum.AUDIO, audioElement) ?? false;
    }

    useEffect(() => {
        if (commonContext.lessonConferenceState === LessonConferenceStatusEnum.STARTED) {
            connectToRoom();
        }

        if (
            (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CONNECTION_ERROR)
            || (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CONNECTION_ERROR_WAIT_NETWORK)
            || (commonContext.lessonConferenceState === LessonConferenceStatusEnum.ON_DESTROY)
        ){
            if (videoRoomPlugin.current !== null) {
                videoRoomPlugin.current?.destroy();

                videoRoomPlugin.current = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.lessonConferenceState]);

    useEffect(() => {
        if (videoRoomContext.videoRoomConnectionState.publisherConnection.status === ConnectionStateEnum.CONNECTED) {
            if (!videoRoomContext.videoRoomConnectionState.cameraMuted) {
                videoRoomPlugin.current?.startOwnVideo();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRoomContext.videoRoomConnectionState.publisherConnection.status]);

    // Размонтирование
    useEffect(() => {
        return () => {
            if (videoRoomPlugin.current !== null) {
                videoRoomPlugin.current?.destroy();

                videoRoomPlugin.current = null;
            }
        }
    }, []);

    return <VideoRoomActionsContextProvider value={{
        enableMyVideo,
        disableMyVideo,
        setMyVideoIsAvailable,
        setMyScreenSharingIsAvailable,
        stopScreenSharing,
        startScreenSharing,
        attachVideoToElement,
        attachScreenVideoToElement,
        attachScreenAudioToElement,
        subscribeToStreams,
        unsubscribeFromStreams
    }}>
        {children}
    </VideoRoomActionsContextProvider>
}
