import {IsString, Length,} from 'class-validator';
import {BaseRequestDto} from '../BaseRequestDto';

/**
 * DTO параметров запроса сохранения информации об устройстве пользователя
 */
export class SaveDeviceInfoRequestDto extends BaseRequestDto {
    @IsString()
    @Length(1, 100)
    timezoneName: string | null;

    @IsString()
    @Length(1, 100)
    deviceType: string | null;

    @IsString()
    @Length(1, 100)
    deviceBrand: string | null;

    @IsString()
    @Length(1, 100)
    osName: string | null;

    @IsString()
    @Length(1, 100)
    osVersion: string | null;

    @IsString()
    @Length(1, 100)
    browserType: string | null;

    @IsString()
    @Length(1, 100)
    browserName: string | null;

    @IsString()
    @Length(1, 100)
    browserVersion: string | null;
}
