import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Sortable, SortableItemComponent} from "../../../../../components/Sortable/Sortable";
import {SectionItem} from "./SectionItem";
import {DtoTmLevel} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {t, Trans} from "@lingui/macro";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {CreateNewSectionItem} from "./CreateNewSectionItem";
import {Notice} from "../../../../../components/Ui/Elements/Notice";
import {LoadMoreSectionItem} from "./LoadMoreSectionItem";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {DtoTmSection} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";

const SectionsListComponentWrapper = styled.div`
`;

const NoticeStyled = styled(Notice)`
  margin-top: 18px;
`;

const SortableWrapper = styled.div`
  margin-bottom: 10px;
`;

interface SectionsListProps {
    levelId: string;
    sections: DtoTmSection[] | null;
    setSections: React.Dispatch<React.SetStateAction<DtoTmSection[] | null>>;
    selectedSectionId: string | null;
    setSelectedSectionId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const SectionsList: React.FC<SectionsListProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [paginationPagesLoaded, setPaginationPagesLoaded] = useState<number>(0);
    const [totalSectionsItems, setTotalSectionsItems] = useState<number>(0);

    const [savingOrderResultNow, setSavingOrderResultNow] = useState<boolean>(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {levelId, sections, setSections, selectedSectionId, setSelectedSectionId} = {...props};

    const loadContent = useCallback((page?: number) => {
        if (!sessionToken) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        if (page === undefined) {
            page = 1;
        }

        httpApiClient.tmGetSections(sessionToken, levelId, page, 50)
            .then((data) => {
                setSections((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setPaginationPagesLoaded(data.data.page);
                setTotalSectionsItems(data.data.totalCount);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, 'Error on loading level sections list: ', err);

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [sessionToken, httpApiClient, levelId, setSections, logger]);

    const reorderItems = (from: number, to: number) => {
        if ((sections === null) || (sessionToken === null)) {
            return;
        }

        const firstItemId = sections[from].id;
        const secondItemId = sections[to].id;

        const newLevelsArray = arrayMove(sections, from, to);

        setSections(newLevelsArray);

        setSavingOrderResultNow(true);

        httpApiClient.tmSwapSectionOrderPosition(sessionToken, firstItemId, secondItemId)
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.TM_LEVEL_SECTIONS_API,
                    'Error on reorder level sections list: ',
                    err
                );

                setSections(arrayMove(newLevelsArray, to, from));

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок секций уровня. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                setSavingOrderResultNow(false);
            });
    }

    // Сброс уровней при изменении выбранного уровня
    useEffect(() => {
        setSections(() => null);

    }, [levelId, setSections]);

    // Загрузка уровней при сбросе списка уровней
    useEffect(() => {
        if (sections === null) {
            loadContent(1);
        }
    }, [sections, loadContent]);

    const sectionOnClick = (itemId: string) => {
        setSelectedSectionId(itemId);
    }

    const items = useMemo(() => {
        if (sections === null) {
            return [];
        }

        return sections?.map((item): SortableItemComponent<DtoTmSection> => {
            return {
                id: item.id,
                selected: (selectedSectionId === item.id),
                disabled: savingOrderResultNow,
                item: item
            }
        });

    }, [sections, selectedSectionId, savingOrderResultNow]);

    const levelsList = () => {
        if (nowLoading) {
            return <DefaultLoader/>;
        }

        return <SectionsListComponentWrapper>
            <SortableWrapper>
                <Sortable<DtoTmLevel>
                    items={items}
                    reorderItems={(array, from, to) => {
                        reorderItems(from, to);
                    }}
                    renderItemContent={(item, index) => {
                        return <SectionItem item={item}
                                            index={index}
                                            onSelect={() => sectionOnClick(item.item.id)}/>
                    }}
                />
            </SortableWrapper>

            {((props.sections !== null) && (totalSectionsItems > props.sections.length)) ?
                <LoadMoreSectionItem onClick={() => loadContent(paginationPagesLoaded + 1)}/> : null}

            <CreateNewSectionItem levelId={props.levelId}
                                  onItemCreated={() => props.setSections(null)}/>

            {(((props.sections?.length ?? 0) > 1)) ? <NoticeStyled>
                <Trans>Порядок секций имеет значение. Сверху нужно оставить секции с первыми уроками уровня, а внизу -
                    завершающие.</Trans>
            </NoticeStyled> : null}

        </SectionsListComponentWrapper>
    }

    return (
        <div>
            {
                (loadingError)
                    ? <ErrorLoadingContent
                        title={t`Не удалось загрузить список секций уровня`}
                        retryBtnClick={() => loadContent()}
                    />
                    : levelsList()
            }

        </div>
    );
}