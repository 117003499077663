/**
 * Класс - описание контекста вызываемого метода Web Socket API.
 * Содержит данные контекста, передаваемые кодом, вызывающим метод.
 */
export class RequestContextInfoItem {
    contextData: any;

    constructor(contextData: any) {
        this.contextData = contextData;
    }
}