import React from 'react';

export interface ISlideControlContext {
    // Вызывается элементом слайда, если он готов к прееходу, но у него уже некуда переходить
    goFocusToNextExercise: (callerSlideId: string, callerSlideItemId: string, callerExerciseId: string) => void;
}

export const SlideControlContext = React.createContext<ISlideControlContext>({
    goFocusToNextExercise: (callerSlideId: string, callerSlideItemId: string, callerExerciseId: string) => {}
});

export const SlideControlContextProvider = SlideControlContext.Provider;