import {IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator';

/**
 * DTO ответа на запрос базовой информации о пользователе по его id в Студенты.онлайн
 */
export class DtoUserProfileInfoBySchoolStudentProfile {

    @IsNotEmpty()
    @IsNumber()
    stApiStudentId: number;

    @IsOptional()
    @IsString()
    id: string | null;

    @IsNotEmpty()
    @IsString()
    name: string;

    @IsOptional()
    @IsString()
    avatarFileId: string | null;
}
