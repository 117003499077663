import {IsBoolean, IsNotEmpty, IsOptional, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {
    DtoRequestWithPagination
} from "../Common/DtoRequestWithPagination";
import {DtoLessonMaterialItem} from "../LessonMaterials/DtoLessonMaterialItem";
import {DtoLessonMaterialHomeworkItem} from "../LessonMaterials/DtoLessonMaterialHomeworkItem";
import {DtoUserProfileInfoBySchoolStudentProfile} from "../Students/DtoUserProfileInfoBySchoolStudentProfile";

/**
 * DTO ответа на запрос используемых когда-либо учебных материалах на уроке
 */
export class DtoLessonRoomOverviewTeacherResponse {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsString()
    @IsOptional()
    roomOpenedAt: string | null;

    @IsString()
    @IsOptional()
    roomClosedAt: string | null;

    @IsNotEmpty()
    @IsBoolean()
    hasVideo: boolean;

    @IsNotEmpty()
    lessonMaterialsList: DtoRequestWithPagination<DtoLessonMaterialItem>;

    @IsNotEmpty()
    lessonHomeworkMaterialsList: DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>;

    @IsNotEmpty()
    lessonStudentProfiles: DtoRequestWithPagination<DtoUserProfileInfoBySchoolStudentProfile>;

    @IsBoolean()
    homeworkIsAvailableForStudentsNow: boolean;
}
