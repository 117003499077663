import React from "react";
import styled from "styled-components";

const Wrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ValueItem = styled.li`
    color: ${({theme}) => theme.colors.textSecondary};
    font-size: 14px;
    line-height: 18px;
    display: inline-block;

    @media (${({theme}) => theme.media.small}) {
        font-size: 14px;
        line-height: 18px;
    }
    
    &::after {
        content: '•';
        margin: 0 5px;
    }
    
    &:nth-last-child(1) {
        &::after {
            content: none;
        }
    }
`;

interface AdditionalValuesInterface {
    values: string[];
}

export const AdditionalValues: React.FC<AdditionalValuesInterface> = ({values}) => {
    return <Wrapper>
        {
            values.map((value, index) => <ValueItem key={index}>{value}</ValueItem>)
        }
    </Wrapper>
}