import * as React from "react";
import {ChangeEvent, useState} from "react";
import styled from 'styled-components';
import {Input} from "../../../components/Ui/Elements/TextInput";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {Link} from "../../../components/Ui/Elements/Link";
import {i18n} from '@lingui/core'
import {useNavigate, useOutletContext} from "react-router-dom";
import {OutletContext} from "../index";
import {RoutesList} from "../../../RoutesList";
import {AnimatedContainer} from "./AnimatedContainer";
import {LengthEnum} from "../../../../components/HttpApiClient/Enums/LengthEnum";
import {container} from 'tsyringe';
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {AccessDeniedException} from "../../../../components/HttpApiClient/Exception/AccessDeniedException";
import {RequestValidationError} from "../../../../components/HttpApiClient/Exception/RequestValidationError";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {FormTitle} from "./FormTitle";


const Wrapper = styled.div`

`;

const TitleWrapper = styled.div`
  margin-bottom: 15px;
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const FieldWrapper = styled.div`

`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ForgotPasswordLink = styled.div`
  margin-bottom: 73px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 0;
  }
`;

export interface LoginFormProps {
}

export const SignUpForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
    const [emailInput, setEmailInput] = useState('');
    const [emailInputError, setEmailInputError] = useState(false);

    const [successMessage, setSuccessMessage] = useState(false);

    const outletContext = (useOutletContext() as OutletContext);

    const navigate = useNavigate();

    const signUpBtnClick = async () => {
        outletContext.setAlertMessage(null);
        setEmailInputError(false);

        if (emailInput.trim().length < 5) {
            // Т.е. в email как минимум должны быть 5 символов: "?@?.?"
            setEmailInputError(true);

            return;
        }

        outletContext.setLoader(true, i18n._(t`Регистрация...`));

        // Выполняем запрос на регистрацию
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        try {
            await httpApiClient.register(emailInput.trim());

            // Сообщить о том, что всё хорошо
            outletContext.setLoader(false);
            setSuccessMessage(true);
        } catch (e) {
            outletContext.setLoader(false);

            if (e instanceof AccessDeniedException) {
                outletContext.setAlertMessage(i18n._(t`Операция временно недоступна`));

                return;
            }

            if (e instanceof RequestValidationError) {
                outletContext.setAlertMessage(e.errorSummary);

                setEmailInputError(true);

                return;
            }

            outletContext.setAlertMessage(i18n._(t`Произошла ошибка. Попробуйте повторить попытку позднее.`));

            logger.error(LoggerSectionsEnum.UNKNOWN_API_RESPONSE_ERROR, e);

            return;
        }
    }

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (emailInputError) {
            setEmailInputError(false);
        }

        setEmailInput(event.target.value.trim());
    }

    const backBtnClick = () => {
        navigate(RoutesList.LOGIN_FORM);
    }

    const onEmailKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (event.key === 'Enter') {
            await signUpBtnClick();
        }
    }

    const content = () => {
        if (successMessage) {
            return (
                <Wrapper>
                    <InputsWrapper>
                        <h2 style={{marginBottom: "1.5em"}}><Trans>Регистрация завершена</Trans></h2>
                        <p>
                            <Trans
                                id="На почту {emailInput} отправлено сообщение с паролем и ссылкой для быстрого входа."
                                values={{emailInput: emailInput}}
                            />
                        </p>
                    </InputsWrapper>
                    <ButtonsWrapper>
                        <ButtonItem>
                            <Button btnStyle={BtnStyleEnum.Primary} onClick={backBtnClick}><Trans>Назад</Trans></Button>
                        </ButtonItem>
                    </ButtonsWrapper>
                </Wrapper>
            );
        }

        return (
            <Wrapper>
                <TitleWrapper>
                    <FormTitle titleText={t`Регистрация`}/>
                </TitleWrapper>
                <InputsWrapper>
                    <FieldWrapper>
                        <Input status={(emailInputError) ? "error" : ""}
                               maxLength={LengthEnum.USER_LOGIN_LENGTH}
                               type="email"
                               size="large"
                               onKeyDown={onEmailKeyDown}
                               onChange={onEmailChange}
                               placeholder={t`Электронная почта`}/>
                    </FieldWrapper>
                </InputsWrapper>
                <ButtonsWrapper>
                    <ButtonItem>
                        <Button btnStyle={BtnStyleEnum.Primary} onClick={signUpBtnClick}><Trans>Получить
                            пароль</Trans></Button>
                    </ButtonItem>
                </ButtonsWrapper>
                <ForgotPasswordLink>
                    <Link onClick={backBtnClick}><Trans>Назад</Trans></Link>
                </ForgotPasswordLink>
            </Wrapper>
        );
    }

    return (
        <AnimatedContainer>
            {content()}
        </AnimatedContainer>
    );
}