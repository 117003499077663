import React, {useCallback, useContext, useRef} from "react";
import {ConferenceModeEnum} from "../../Types";
import {CommonContext, ICommonContext} from "../../contexts/CommonContext";
import {DocumentInPictureActionsContextProvider} from "./DocumentInPictureActionsContext";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../common/RoomCommonActionsContext";

interface DocumentInPictureActionsProviderProps {
    mainZoneWrapperRef: React.RefObject<HTMLDivElement>;
    mainZoneRef: React.RefObject<HTMLDivElement>;
}

export const DocumentInPictureActionsProvider: React.FC<DocumentInPictureActionsProviderProps> = (
    {mainZoneWrapperRef, mainZoneRef, children}
) => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const commonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);

    const pipWindow = useRef<Window>(null);

    const startDocumentInPicture = useCallback(async () => {
        if (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE) {
            return;
        }

        // @ts-ignore
        pipWindow.current = await documentPictureInPicture.requestWindow({
            width: 300,
            height: 450
        });

        if (pipWindow.current && mainZoneRef.current) {
            const window = pipWindow.current;

            const copyStyles = () => {
                // @ts-ignore
                [...document.styleSheets].forEach((styleSheet) => {
                    try {
                        const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
                        const style = document.createElement('style');

                        style.textContent = cssRules;
                        window.document.head.appendChild(style);
                    } catch (e) {
                        const link = document.createElement('link');

                        link.rel = 'stylesheet';
                        link.type = styleSheet.type;
                        link.media = styleSheet.media;
                        link.href = styleSheet.href;
                        window.document.head.appendChild(link);
                    }
                });
            }

            copyStyles();

            pipWindow.current.document.body.append(mainZoneRef.current);

            pipWindow.current.document.body.style.minWidth = "unset";

            const rootElement = document.getElementById('root');

            pipWindow.current.document.addEventListener('click', (event) => {
                const e = new Event('click');
                //e.target = divBtn;
                Object.defineProperty(e, 'target', {value: event.target, enumerable: true});

                if (rootElement) {
                    rootElement.dispatchEvent(e);
                }
            });

            commonActionsContext.setCurrentConferenceMode(ConferenceModeEnum.DOCUMENT_IN_PICTURE);

            pipWindow.current.addEventListener("pagehide", (event) => {
                if (mainZoneWrapperRef.current && mainZoneRef.current) {
                    mainZoneWrapperRef.current?.append(mainZoneRef.current);
                }

                commonActionsContext.setCurrentConferenceMode(ConferenceModeEnum.NORMAL);
            });
        }
    }, [commonActionsContext, commonContext.currentMode, mainZoneRef, mainZoneWrapperRef]);

    const stopDocumentInPicture = useCallback(() => {
        if (pipWindow.current) {
            pipWindow.current.close();
        }
    }, []);

    return <DocumentInPictureActionsContextProvider
        value={{
            stopDocumentInPicture,
            startDocumentInPicture
        }}>
        {children}
    </DocumentInPictureActionsContextProvider>
}
