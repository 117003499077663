import {
    IsNotEmpty, IsString, Length, MaxLength, IsOptional
} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO дисциплины обучающих материалов
 */
export class DtoTmDiscipline {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    name: string;

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    description: string | null;
}
