import {call, put, select, takeEvery} from "redux-saga/effects";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {LessonMaterialsActionTypes} from "../../store/lessonMaterials/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {addSubscriptionId} from "../../store/lessonMaterials/actions";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {currentSubscriptionsList} from "../../store/lessonMaterials/selector";

/**
 * Сага оформляет подписку на изменение списка слайдов комнаты урока
 */
export function* watchSubscribeToSlideList() {
    yield takeEvery<WorkerPayloadType<string>>(
        LessonMaterialsActionTypes.SUBSCRIBE_TO_SLIDE_LIST,
        subscribeToSlideList
    );
}

function* subscribeToSlideList(data: WorkerPayloadType<string>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    logger.info(
        LoggerSectionsEnum.LESSON_AND_HOMEWORK,
        `Subscribing to slide list of lesson ${data.payload}`
    );

    const currentSubscriptions = (yield select(currentSubscriptionsList)) as string[];

    if (currentSubscriptions.indexOf(data.payload)>-1) {
        logger.warning(
            LoggerSectionsEnum.LESSON_AND_HOMEWORK,
            `Subscription to lesson ${data.payload} already exist`
        );

        return;
    }

    yield call(async () => {
            await wsApiClient.query(
                ApiMethodEnum.LESSON_AND_HOMEWORK_SUBSCRIBE_TO_SLIDE_LIST,
                {
                    lessonId: data.payload
                }
            );
        }
    );

    yield put(addSubscriptionId(data.payload));
}
