import styled from "styled-components";
import {Pagination as AntPagination, PaginationProps as AntPaginationProps} from "antd";
import {t} from "@lingui/macro";
import React from "react";
import {RegularTextCss} from "../../../styles/global-elements";

const PaginationStyled = styled(AntPagination)`
  a {
    ${RegularTextCss};
    line-height: 38px;
  }

  li {
    height: 40px;
    min-width: 40px;
    margin-bottom: 10px;
  }

  .ant-pagination-item {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    border-color: ${({theme}) => theme.colors.accentDivider};
  }

  .ant-pagination-item a {
    color: ${({theme}) => theme.colors.textPrimary};
  }

  .ant-pagination-item-active {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    border-color: ${({theme}) => theme.colors.accentPrimary};
  }

  .ant-pagination-item-active a {
    color: ${({theme}) => theme.colors.accentPrimary};
  }

  .ant-pagination-jump-next, .ant-pagination-jump-prev, .ant-pagination-next, .ant-pagination-prev {
    .ant-pagination-item-link {
      background-color: ${({theme}) => theme.colors.backgroundPrimary};
      border-color: ${({theme}) => theme.colors.accentDivider};
    }
    
    .anticon {
      color: ${({theme}) => theme.colors.textPrimary};
    }
    
    &.ant-pagination-disabled {
      .anticon {
        color: ${({theme}) => theme.colors.textTertiary};
      }
    }
  }
`;

interface PaginationProps extends AntPaginationProps {
}

export const Pagination: React.FC<PaginationProps> = (props) => {
    return <PaginationStyled
        locale={{
            items_per_page: t`Элементов на странице`,
            jump_to: t`Перейти к`,
            jump_to_confirm: t`Перейти к`,
            page: t`Страница`,
            prev_page: t`Предыдущая страница`,
            next_page: t`Следующая страница`,
            prev_5: t`Предыдущие 5`,
            next_5: t`Следующие 5`,
            prev_3: t`Предыдущие 3`,
            next_3: t`Следующие 3`
        }}
        showSizeChanger={false}
        {...props}
    />;
}