import React, {forwardRef, useMemo} from "react";
import {Input} from "../../TextInput";
import {InputRef} from "antd/lib/input/Input";
import {Popup, PopupItem} from "../../Popup";
import {PopupPosition} from "reactjs-popup/dist/types";

export interface PopupSelectorVariant {
    key: string;
    name: string;
}

interface PopupSelectorProps {
    variants: PopupSelectorVariant[];
    value?: PopupSelectorVariant;
    placeholder?: string;
    onChange?: (value: PopupSelectorVariant) => void;
    popupPosition?: PopupPosition;
    className?: string;
}

export const PopupSelector = forwardRef<InputRef, PopupSelectorProps>(
    (props, ref) => {
        const popupItems = useMemo<PopupItem[]>(() => {
            return props.variants.map(item => ({
                text: item.name,
                onClick: () => {
                    if (props.onChange) {
                        props.onChange(item);
                    }
                }
            }));
        }, [props]);

        return <Popup
            items={popupItems}
            position={props.popupPosition}
            trigger={
                <div>
                    <Input ref={ref}
                           className={props.className}
                           style={{cursor: "pointer"}}
                           placeholder={props.placeholder}
                           value={props.value?.name}
                           readOnly/>
                </div>
            }/>
    }
)
