import React, {useContext, useMemo} from 'react';
import styled from "styled-components";
import {IPlayerContext, PlayerContext} from "../SlidePlayer/PlayerContext";
import {SlideListItem} from "./SlideListItem";

export interface SlidesListProps {
    className?: string;
}

const ListWrapper = styled.div`
  padding: 0;
`;

const List = styled.ol`
  list-style: none;
  padding: 0 0 32px 0;
  margin: 0;
`;

export const SlidesList: React.FC<SlidesListProps | null> = (props) => {
    const playerContext = useContext<IPlayerContext>(PlayerContext);

    const {slides} = playerContext;

    const visibleSlides = useMemo(() => {
        if (slides === null) {
            return [];
        }

        return slides.filter((item) => {
            return item.visibleForStudent;
        });

    }, [slides]);

    const items = useMemo<JSX.Element[]>(() => {
        return visibleSlides.map((slideItem, index) => (
            <SlideListItem slideNumberInList={index + 1}
                           key={slideItem.tmSlideId}
                           playerId={playerContext.playerId}
                           slideId={slideItem.tmSlideId}/>
        ));
    }, [playerContext.playerId, visibleSlides]);

    return <ListWrapper className={props.className}>
        <List>
            {items}
        </List>
    </ListWrapper>;
}
