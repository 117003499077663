import {IsArray, IsNotEmpty, IsOptional, ValidateNested} from 'class-validator';
import {DtoTmSlideContentItem} from "./DtoTmSlideContentItem";
import {DtoTmSlideContentLayoutSettings} from "./DtoTmSlideContentLayoutSettings";
import {Type} from "class-transformer";

/**
 * DTO контента слайда урока
 */
export class DtoTmSlideContent {
    @IsNotEmpty()
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoTmSlideContentItem)
    items: DtoTmSlideContentItem[];

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoTmSlideContentLayoutSettings)
    layoutSettings: DtoTmSlideContentLayoutSettings | null;
}
