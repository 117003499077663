import React, {CSSProperties, RefObject} from 'react'
import isHotkey from 'is-hotkey'
import {Editable, ReactEditor, RenderElementProps, Slate, withReact} from 'slate-react'
import {BaseEditor, createEditor, Descendant, Range, Transforms} from 'slate'
import {HistoryEditor, withHistory} from 'slate-history'
import {MarkFormats, RichTextEditorMode} from "./Enums";
import {ToolbarUpdater, ToolbarUpdaterRefMethods} from './components'
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {ICustomText} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICustomText";
import {ItemPropsInterface, ItemRefMethodsInterface} from "../ItemPropsInterface";
import {ToolbarButtonEnum} from "../Toolbar/ToolbarButtonEnum";
import {elementTypeIsActive} from './CommonMethods';
import {withOnlyTitles} from './with/withOnlyTitles';
import {withInlines} from './with/withInlines';
import {withGapsCombobox} from './with/withGapsCombobox';
import {ElementCounter} from "./ElementCounter";
import {withGapsText} from "./with/withGapsText";
import {withCorrectExistInInput} from "./with/withCorrectExistInInput";
import {enterInFillGapsComboboxItem} from "./key-handlers/enterInFillComboboxItem";
import {enterInFillTextItem} from "./key-handlers/enterInFillTextItem";
import {enterInCorrectExistInInput} from "./key-handlers/enterInCorrectExistInInput";
import {withGapsDragDrop} from "./with/withGapsDragDrop";
import {enterInFillDragDropItem} from "./key-handlers/enterInFillDragDropItem";
import {withRadioList} from "./with/withRadioList";
import {withCheckList} from "./with/withCheckList";
import {toggleAlign} from "./rich-text-editor/ToggleAlign";
import {toggleBlock} from "./rich-text-editor/ToggleBlock";
import {toggleMark} from "./rich-text-editor/ToggleMark";
import {defaultInitialValue} from "./rich-text-editor/DefaultInitialValue";
import {Leaf, LeafProps} from "./rich-text-editor/Leaf";
import {Element} from "./rich-text-editor/Element";
import {hotKeys} from "./rich-text-editor/HotkeysConfig";
import {withText} from "./with/withText";
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";
import {withTranslated} from "./with/withTranslated";

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
    interface CustomTypes {
        Editor: CustomEditor
        Element: CustomElement
        Text: ICustomText
    }
}

export interface CustomComponentProps {
    style: CSSProperties | null | undefined;
}

export interface RichTextEditorProps extends ItemPropsInterface<string | Descendant[]> {
    editorMode: RichTextEditorMode;
    placeHolderText: string;
    paragraphCustomComponent?: React.FC<CustomComponentProps>;
    liCustomComponent?: React.FC<CustomComponentProps>;
    onFocus?: () => void
}

export interface RichTextEditorState {
    initialValue: Descendant[];
    debounceTimer: any | null;
}

export interface RichTextEditorRefMethods extends ItemRefMethodsInterface {
}

export class RichTextEditor extends React.Component<RichTextEditorProps, RichTextEditorState> {
    //const {paragraphCustomComponent, liCustomComponent, onFocus} = props;
    protected toolbarUpdaterRef: RefObject<ToolbarUpdaterRefMethods>;
    protected editor: CustomEditor;
    protected currentHotkeys: { [id: string]: string };

    protected onChangeDebounceTimer: any | null;
    protected tempEditorValueForSave: Descendant[] | null;

    protected renderElementBind;
    protected renderLeafBind;
    protected onEditorFocusBind;
    protected onSlateChangeBind;
    protected publishValueToParentBind;

    constructor(props: RichTextEditorProps) {
        super(props);

        this.tempEditorValueForSave = null;

        this.toolbarUpdaterRef = React.createRef<ToolbarUpdaterRefMethods>();

        this.state = {
            debounceTimer: null,
            initialValue: (
                (this.props.initialData === null)
                || (typeof this.props.initialData === "string")
            )
                ? defaultInitialValue
                : (this.props.initialData as Descendant[])
        };

        this.renderElementBind = this.renderElement.bind(this);
        this.renderLeafBind = this.renderLeaf.bind(this);
        this.onEditorFocusBind = this.onEditorFocus.bind(this);
        this.onSlateChangeBind = this.onSlateChange.bind(this);
        this.publishValueToParentBind = this.publishValueToParent.bind(this);

        this.editor = this.createEditor();
        this.currentHotkeys = hotKeys(this.props.editorMode);
    }

    protected createEditor() {
        switch (this.props.editorMode) {
            case RichTextEditorMode.FILL_GAPS_COMBOBOX: {
                return withGapsCombobox(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.FILL_GAPS_TEXT: {
                return withGapsText(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.FILL_GAPS_DRAG_DROP: {
                return withGapsDragDrop(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.CORRECT_EXIST_IN_INPUT: {
                return withCorrectExistInInput(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.TITLE: {
                return withOnlyTitles(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.RADIO_LIST: {
                return withRadioList(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
            case RichTextEditorMode.CHECKBOX_LIST: {
                return withCheckList(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }

            default: {
                // TEXT
                return withText(withTranslated(withInlines(withHistory(withReact(createEditor())))));
            }
        }
    };


    protected renderElement(elementProps: RenderElementProps): JSX.Element {
        return <Element {...elementProps} editor={this.editor}
                        paragraphCustomComponent={this.props.paragraphCustomComponent}
                        liCustomComponent={this.props.liCustomComponent}/>;
    }

    protected renderLeaf(leafProps: LeafProps): JSX.Element {
        return <Leaf {...leafProps} />;
    }

    public toolbarItemOnToggle(buttonType: ToolbarButtonEnum, newValue: boolean) {
        switch (buttonType) {
            case ToolbarButtonEnum.BOLD: {
                toggleMark(this.editor, MarkFormats.BOLD);

                break;
            }
            case ToolbarButtonEnum.ITALIC: {
                toggleMark(this.editor, MarkFormats.ITALIC);

                break;
            }
            case ToolbarButtonEnum.UNDERLINED: {
                toggleMark(this.editor, MarkFormats.UNDERLINE);

                break;
            }
            case ToolbarButtonEnum.LIST_NUMBERED: {
                toggleBlock(this.editor, ElementTypes.NUMBERED_LIST);

                break;
            }
            case ToolbarButtonEnum.LIST_BULLETED: {
                toggleBlock(this.editor, ElementTypes.BULLETED_LIST);

                break;
            }
            case ToolbarButtonEnum.TRANSLATED: {
                toggleBlock(this.editor, ElementTypes.TRANSLATED);

                break;
            }
            case ToolbarButtonEnum.ALIGN_LEFT: {
                toggleAlign(this.editor, AlignTypes.LEFT);

                break;
            }
            case ToolbarButtonEnum.ALIGN_CENTER: {
                toggleAlign(this.editor, AlignTypes.CENTER);

                break;
            }
            case ToolbarButtonEnum.ALIGN_JUSTIFY: {
                toggleAlign(this.editor, AlignTypes.JUSTIFY);

                break;
            }
            case ToolbarButtonEnum.ALIGN_RIGHT: {
                toggleAlign(this.editor, AlignTypes.RIGHT);

                break;
            }
            case ToolbarButtonEnum.LIST_RADIO: {
                toggleBlock(this.editor, ElementTypes.EXERCISE_RADIO_LIST);

                break;
            }
            case ToolbarButtonEnum.LIST_CHECK: {
                toggleBlock(this.editor, ElementTypes.EXERCISE_CHECKBOX_LIST);

                break;
            }
        }
    };

    public getExercisesCount() {
        switch (this.props.editorMode) {
            case RichTextEditorMode.FILL_GAPS_COMBOBOX: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX,
                    this.props.initialData as CustomElement[]
                );
            }
            case RichTextEditorMode.FILL_GAPS_TEXT: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_FILL_GAPS_TEXT,
                    this.props.initialData as CustomElement[]
                );
            }
            case RichTextEditorMode.CORRECT_EXIST_IN_INPUT: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT,
                    this.props.initialData as CustomElement[]
                );
            }
            case RichTextEditorMode.FILL_GAPS_DRAG_DROP: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP,
                    this.props.initialData as CustomElement[]
                );
            }
            case RichTextEditorMode.RADIO_LIST: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_RADIO_LIST,
                    this.props.initialData as CustomElement[]
                );
            }
            case RichTextEditorMode.CHECKBOX_LIST: {
                return ElementCounter.countElementTypesInEditorData(
                    ElementTypes.EXERCISE_CHECKBOX_LIST,
                    this.props.initialData as CustomElement[]
                );
            }
            default: {
                return 0;
            }
        }
    }

    protected onEditorFocus() {
        if (this.props.onFocus) {
            this.props.onFocus();
        }

        this.toolbarUpdaterRef.current?.setToolbarConfig();
    }

    protected publishValueToParent() {
        if (this.tempEditorValueForSave !== null) {
            this.props.onChange(this.tempEditorValueForSave);

            this.tempEditorValueForSave = null;
        }

        clearTimeout(this.onChangeDebounceTimer);
        this.onChangeDebounceTimer = null;
    }

    componentWillUnmount() {
        if (this.onChangeDebounceTimer !== null) {
            this.publishValueToParent();
        }
    }

    protected onSlateChange(value: Descendant[]) {
        if (this.onChangeDebounceTimer !== null) {
            clearTimeout(this.onChangeDebounceTimer);
            this.onChangeDebounceTimer = null;
        }

        this.tempEditorValueForSave = value;

        this.onChangeDebounceTimer = setTimeout(this.publishValueToParentBind, 500);
    }

    render() {
        return <Slate editor={this.editor}
                      value={this.state.initialValue}
                      onChange={this.onSlateChangeBind}>
            <ToolbarUpdater id={this.props.id}
                            ref={this.toolbarUpdaterRef}
                            setToolbarConfigById={this.props.setToolbarConfigById}
                            editorMode={this.props.editorMode}
            />
            <Editable
                renderElement={this.renderElementBind}
                renderLeaf={this.renderLeafBind}
                placeholder={this.props.placeHolderText}
                onFocus={this.onEditorFocusBind}
                onBlur={this.publishValueToParentBind}
                spellCheck
                onKeyDown={event => {
                    for (const hotkey in this.currentHotkeys) {
                        if (isHotkey(hotkey, event as any)) {
                            event.preventDefault();

                            if (this.currentHotkeys[hotkey] === 'code') {
                                switch (this.props.editorMode) {
                                    case RichTextEditorMode.FILL_GAPS_COMBOBOX: {
                                        toggleBlock(this.editor, ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX);

                                        break;
                                    }
                                    case RichTextEditorMode.FILL_GAPS_TEXT: {
                                        toggleBlock(this.editor, ElementTypes.EXERCISE_FILL_GAPS_TEXT);

                                        break;
                                    }
                                    case RichTextEditorMode.CORRECT_EXIST_IN_INPUT: {
                                        toggleBlock(this.editor, ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT);

                                        break;
                                    }
                                    case RichTextEditorMode.FILL_GAPS_DRAG_DROP: {
                                        toggleBlock(this.editor, ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP);

                                        break;
                                    }
                                }

                                return;
                            } else if (this.currentHotkeys[hotkey] === 'translate') {
                                toggleBlock(this.editor, ElementTypes.TRANSLATED);
                            } else {
                                const mark: any = this.currentHotkeys[hotkey];

                                toggleMark(this.editor, mark);
                            }
                        }
                    }

                    if (event.key === "Enter") {
                        if (elementTypeIsActive(this.editor, ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX)) {
                            enterInFillGapsComboboxItem(event, this.editor);
                            event.preventDefault();
                        }
                        if (elementTypeIsActive(this.editor, ElementTypes.EXERCISE_FILL_GAPS_TEXT)) {
                            enterInFillTextItem(event, this.editor);
                            event.preventDefault();
                        }
                        if (elementTypeIsActive(this.editor, ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT)) {
                            enterInCorrectExistInInput(event, this.editor);
                            event.preventDefault();
                        }
                        if (elementTypeIsActive(this.editor, ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP)) {
                            enterInFillDragDropItem(event, this.editor);
                            event.preventDefault();
                        }
                    }

                    // Default left/right behavior is unit:'character'.
                    // This fails to distinguish between two cursor positions, such as
                    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
                    // Here we modify the behavior to unit:'offset'.
                    // This lets the user step into and out of the inline without stepping over characters.
                    // You may wish to customize this further to only use unit:'offset' in specific cases.
                    const {selection} = this.editor

                    if (selection && Range.isCollapsed(selection)) {
                        const {nativeEvent} = event
                        if (isHotkey('left', nativeEvent)) {
                            event.preventDefault()
                            Transforms.move(this.editor, {unit: 'offset', reverse: true})
                            return
                        }
                        if (isHotkey('right', nativeEvent)) {
                            event.preventDefault()
                            Transforms.move(this.editor, {unit: 'offset'})
                            return
                        }
                    }
                }}
            />
        </Slate>
    };
}