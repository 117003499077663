import {IsNotEmpty, IsNumber, IsOptional, IsString} from "class-validator";

/**
 * DTO элемента ответа на запрос поиска временной зоны
 */
export class DtoGetPaymentsByStudentId {
    @IsNumber()
    @IsNotEmpty()
    id: number;

    @IsOptional()
    @IsString()
    summ: string;

    @IsNotEmpty()
    @IsString()
    datePay: string;

    @IsOptional()
    @IsString()
    comment: string | null;

    @IsOptional()
    @IsString()
    printDocNumber: string | null;

    @IsNotEmpty()
    @IsNumber()
    payType: number;

    @IsOptional()
    @IsString()
    refId: string | null;

    @IsOptional()
    @IsString()
    groupName: string | null;
}
