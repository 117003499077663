import {
    DtoAgreementItem
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {ConfirmDialog} from "../../../../../../components/Ui/Elements/ConfirmDialog";
import {t} from "@lingui/macro";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";

interface RestoreAgreementModalProps {
    dto: DtoAgreementItem;
}

export const RestoreAgreementModal = forwardRef<PopupActions, RestoreAgreementModalProps>(
    (
        {
            dto
        },
        ref
    ) => {
        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
        const modalRef = useRef<PopupActions>(null);
        const stToken = useSelector(stTokenSelector);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            close: () => {
                modalRef.current?.close()
            },
            open: () => {
                modalRef.current?.open()
            },
            toggle: () => {
                modalRef.current?.toggle()
            },
        }));

        const restoreAgreement = useCallback(async () => {
            if (!stToken) {
                return;
            }
            
            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
            
            try {
                await stApiClient.restoreAgreement(stToken, dto.id);
                
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Сохранено`,
                    t`Информация успешно сохранена`
                );

                studentPageContext.reloadBaseInfo();
            } catch (e) {
                if (e instanceof NoConnection) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось применить изменения`,
                        t`Проверьте соединение с интернетом`
                    );

                    return;
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось применить изменения`,
                    t`Попробуйте повторить попытку позднее`
                );

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.STONLINE_SCHEDULE_API,
                    `Error on restore agreement ${dto.id}: `,
                    e
                );
            }
        }, [dto.id, stToken, studentPageContext]);

        // if (dto.annulled === 0) {
        //     return null;
        // }

        return <ConfirmDialog
            ref={modalRef}
            okText={t`Возобновить`}
            cancelText={t`Отмена`}
            title={t`Возобновить обучение?`}
            text={t`Обычно после приостановки обучения рекомендуется начинать новый курс, а возобновлять обучение стоит только если обучение было завершено по ошибке.`}
            okMethod={restoreAgreement}
        />
    });