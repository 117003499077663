import {IsNotEmpty, IsNumber, Max, Min} from 'class-validator';
import {DtoTmLesson} from "./DtoTmLesson";

/**
 * DTO общей информации об уроке
 */
export class DtoTmLessonSummaryResponse extends DtoTmLesson {
    @IsNotEmpty()
    @IsNumber()
    @Min(0)
    @Max(200)
    slidesCount: number;
}
