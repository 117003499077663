import React from "react";
import styled from "styled-components";
import {Trans} from "@lingui/macro";
import {TextArea} from "../../../Ui/Elements/TextArea";

const FormWrapper = styled.div`
  padding: 20px;
  background-color: ${({theme}) => theme.slideContent.blueBg};
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const ExamplesWrapper = styled.div`
  color: ${({theme}) => theme.slideContent.blueText};
  font-size: 12px;
`;

const ExamplesTitle = styled.p`
`;

const ExamplesList = styled.ul`
`;

const ExamplesListItem = styled.li`
  margin-bottom: 5px;
`;

const InputWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 5px 10px;
`;

const TextAreaStyled = styled(TextArea)`
  font-size: 14px;
`;

interface InsertYoutubeLinkProps {
    onIdSelect: (videoId: string) => void
}

export const InsertYoutubeLink: React.FC<InsertYoutubeLinkProps> = (props) => {
    const {onIdSelect} = props;

    const youTubeGetID = (url: string): string | null => {
        let ID = '';
        //eslint-disable-next-line
        const urlList = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/);

        if (urlList[2] !== undefined) {
            //eslint-disable-next-line
            const tempStr = urlList[2].split(/[^0-9a-z_\-]/i);

            ID = tempStr[0];
        } else {
            ID = urlList.toString();
        }

        ID = ID.trim();

        if (ID === "") {
            return null;
        }

        //eslint-disable-next-line
        if (/[\s|\'|\"]/g.test(ID)) {
            return null;
        }

        return ID;
    }

    const inputOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const id = youTubeGetID(e.target.value);

        if (id === null) {
            return;
        }

        onIdSelect(id);
    };

    return <FormWrapper>
        <Title><Trans>Вставьте ссылку на видео или код для вставки</Trans></Title>
        <ExamplesWrapper>
            <ExamplesTitle><Trans>Например:</Trans></ExamplesTitle>
            <ExamplesList>
                <ExamplesListItem>https://youtu.be/QCVxQ_3Ejkg</ExamplesListItem>
                <ExamplesListItem>https://www.youtube.com/watch?v=QCVxQ_3Ejkg&ab_channel=YouTube</ExamplesListItem>
                <ExamplesListItem>
                    &lt;iframe
                    width=&quot;1004&quot; height=&quot;753&quot; src=&quot;https://www.youtube.com/embed/QCVxQ_3Ejkg&quot;
                    title=&quot;A Message From Chad and Steve&quot; frameBorder=&quot;0&quot;
                    allow=&quot;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
                    picture-in-picture&quot;
                    allowFullScreen&gt;&lt;/iframe&gt;
                </ExamplesListItem>
            </ExamplesList>
        </ExamplesWrapper>
        <InputWrapper>
            <TextAreaStyled
                status={""}
                size="large"
                onChange={inputOnChange}
                placeholder={''}/>
        </InputWrapper>
    </FormWrapper>
}