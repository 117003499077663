import React, {CSSProperties, useEffect, useMemo, useState} from 'react';
import {ElementData} from "./Common";
import styled from "styled-components";
import {PresenterPlaceholder, PresenterPlaceholderShowModeEnum} from "./PresenterPlaceholder";
import {t} from "@lingui/macro";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../../store/fileFormatsRepo/type";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../../store/fileFormatsRepo/selector";
import * as FileFormatsRepoActionCreators from "../../../../../store/fileFormatsRepo/actions";
import {UserFileFormatsEnum} from "../../../../../enums/UserFileEnums";
import classNames from "classnames";

interface PresenterComponentProps {
    elementData: ElementData
}

enum PresenterInternalState {
    WAIT_FOR_INIT,
    NO_IMAGE,
    FETCHING_LINK,
    READY,
    ERROR
}

const PresenterWrapper = styled.div`
  position: relative;
`;

const ImageHtml = styled.img`
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.loading {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  &.ready {
    opacity: 1;
    position: relative;
  }
`;

export const PresenterComponent: React.FC<PresenterComponentProps> = (props) => {
    const {elementData} = props;
    const fileRepoState = useSelector(repoStateSelector);

    const dispatch = useDispatch();


    const [state, setState] = useState<PresenterInternalState>(PresenterInternalState.WAIT_FOR_INIT);
    const [imageLoadedSuccessfully, setImageLoadedSuccessfully] = useState<boolean>(false);

    const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
        if (elementData.imageId === null) {
            return null
        }

        const itemIndex = fileRepoState.indexByFileId[elementData.imageId];

        if (itemIndex === undefined) {
            return null;
        }

        return fileRepoState.files[itemIndex] ?? null;
    }, [fileRepoState, elementData]);


    const fileLink = useMemo<string | null>(() => {
        if (currentFileInFileRepo === null) {
            return null;
        }

        if (currentFileInFileRepo.availableFormats.length === 0) {
            return null;
        }

        const originalFile = currentFileInFileRepo.availableFormats.find((item) => {
            return item.formatType === UserFileFormatsEnum.TM_SLIDE_PICTURE_ORIGINAL;
        }) ?? null;

        return (originalFile) ? originalFile.url : null;
    }, [currentFileInFileRepo]);

    useEffect(() => {
        const fetchNewFileInfo = (fileId: string) =>
            dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

        if (elementData.imageId === null) {
            setState(PresenterInternalState.NO_IMAGE);

            return;
        }

        if (currentFileInFileRepo === null) {
            // Запросим ссылку
            fetchNewFileInfo(elementData.imageId);
            setState(PresenterInternalState.FETCHING_LINK);

            return;
        }

        switch (currentFileInFileRepo.fetchInfoStatus) {
            case FileFetchInfoProcessStatus.WAIT_FOR_START:
            case FileFetchInfoProcessStatus.IN_PROCESS: {
                setState(PresenterInternalState.FETCHING_LINK);

                break;
            }
            case FileFetchInfoProcessStatus.SUCCESS: {
                setState(PresenterInternalState.READY);

                break;
            }
            default: {
                setState(PresenterInternalState.ERROR);

                break;
            }
        }
    }, [currentFileInFileRepo, dispatch, elementData]);

    const presenterStyle = useMemo<CSSProperties>(() => {
        if (
            ((state !== PresenterInternalState.READY) || (fileLink === null) || (!imageLoadedSuccessfully))
            && (elementData.heightInPx !== null)
        ) {
            if (elementData.widthInPx === undefined || elementData.widthInPx === null) {
                return {
                    width: elementData.widthInPercent.toString(10)+'%',
                    height: elementData.heightInPx.toString(10) + 'px'
                }
            } else {
                return {
                    width: elementData.widthInPercent.toString(10)+'%',
                    aspectRatio: `${elementData.widthInPx}/${elementData.heightInPx}`
                }
            }
        } else {
            return {
                width: "100%",
                height: 'auto'
            }
        }
    }, [elementData.heightInPx, elementData.widthInPercent, elementData.widthInPx, fileLink, imageLoadedSuccessfully, state]);

    const content = () => {
        switch (state) {
            case PresenterInternalState.WAIT_FOR_INIT:
            case PresenterInternalState.FETCHING_LINK: {
                return <PresenterPlaceholder
                    showMode={PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING}
                    text={t`Картинка загружается...`}
                />;
            }
            case PresenterInternalState.READY: {
                if (fileLink) {
                    return <>
                        {
                            (!imageLoadedSuccessfully)
                            && <PresenterPlaceholder
                                showMode={PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING}
                                text={t`Картинка загружается...`}
                            />
                        }
                        <ImageHtml src={fileLink ?? ""}
                                   className={classNames(
                                       imageLoadedSuccessfully && "ready",
                                       !imageLoadedSuccessfully && "loading"
                                   )}
                                   onLoad={() => {
                                       setImageLoadedSuccessfully(true);
                                   }}
                        /></>;
                }
            }
        }

        return <PresenterPlaceholder
            showMode={PresenterPlaceholderShowModeEnum.LOADING_ERROR}
            text={t`Ошибка загрузки картинки`}
        />;
    }

    return <PresenterWrapper style={presenterStyle}>
        {content()}
    </PresenterWrapper>
}