import {IsNotEmpty, IsNumber, IsString, Length} from "class-validator";

/**
 * DTO расписания занятий группы
 */
export class DtoGroupSchedule {
    @IsNumber()
    @IsNotEmpty()
    id: number;

    @IsNumber()
    @IsNotEmpty()
    groupId: number;

    @IsString()
    @IsNotEmpty()
    startDate: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    weekDays: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    mondayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    mondayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    mondayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    tuesdayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    tuesdayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    tuesdayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    wednesdayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    wednesdayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    wednesdayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    thursdayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    thursdayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    thursdayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    fridayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    fridayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    fridayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    saturdayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    saturdayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    saturdayBreakTime: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    sundayTimeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    sundayTimeEnd: string;

    @IsNotEmpty()
    @IsString()
    @Length(0, 8)
    sundayBreakTime: string;
}
