import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PageTitle} from "../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {debounce, DebouncedFunc} from "lodash";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {DtoStudentListItem} from "../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentListItem";
import {stTokenSelector} from "../../../../store/app/selector";
import {PageLoadingStateEnum} from "../../../../enums/PageLoadingStateEnum";
import {EmptyListNotificationBox} from "./EmptyListNotificationBox";
import {StudentItemsList} from "./StudentItemsList";
import {ChipsFilterArea} from "../../../components/Ui/Elements/ChipsFilterArea";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {PageSectionsEnum} from "../student/new-student-page/PageSectionsEnum";

const ITEMS_PER_PAGE = 30;

const PageWrapper = styled.div``;
const ResultWrapper = styled.div``;

export const StudentsList = () => {
    const stToken = useSelector(stTokenSelector);

    const isFirstRender = useRef<boolean>(true);
    const logger = useRef<ILogger>(container.resolve<ILogger>(DiTokens.LOGGER));
    const abortController = useRef<AbortController | null>(null);
    const stonlineApiClient = useRef<IStonlineApiClient>(
        container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT)
    );

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [searchString, setSearchString] = useState<string>('');
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const [totalStudentsCount, setTotalStudentsCount] = useState<number | null>(null);
    const [studentsList, setStudentsList] = useState<DtoStudentListItem[] | null>(null);

    const fetchStudents = useCallback(async () => {
        if (!stToken) {
            return;
        }

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        if (abortController.current !== null) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        try {
            const result = await stonlineApiClient.current.getStudentsList(
                stToken,
                searchString,
                currentPageNum,
                ITEMS_PER_PAGE,
                abortController.current
            );

            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            setStudentsList(result.result.items);
            setTotalStudentsCount(result.result.totalCount);
        } catch (e) {
            logger.current.error(LoggerSectionsEnum.STONLINE_STUDENTS_LIST_API, e);

            setPageLoadingState(PageLoadingStateEnum.ERROR);
        }
    }, [currentPageNum, searchString, stToken]);

    const lastDebounced = useRef<DebouncedFunc<any> | null>(null);

    const fetchStudentsDebounced = useMemo<DebouncedFunc<any>>(() => {
        lastDebounced.current?.cancel();

        lastDebounced.current = debounce(fetchStudents, 500);

        return lastDebounced.current;
    }, [fetchStudents]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        fetchStudentsDebounced.cancel();

        fetchStudents().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageNum]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        abortController.current?.abort();
        abortController.current = null;

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        fetchStudentsDebounced();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(
        () => {
            isFirstRender.current = false;

            fetchStudents().then();

            return () => {
                lastDebounced.current?.cancel();

                if (abortController.current !== null) {
                    abortController.current.abort();
                    abortController.current = null;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const content = useMemo(() => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.SUCCESS: {
                if ((studentsList === null) || (totalStudentsCount === null)) {
                    return <DefaultLoader/>;
                }

                if (studentsList.length === 0) {
                    return <EmptyListNotificationBox searchStringIsEmpty={searchString === ''}/>;
                }

                return <StudentItemsList
                    items={studentsList}
                    currentPageNum={currentPageNum}
                    totalItemsCount={totalStudentsCount}
                    itemsPerPage={ITEMS_PER_PAGE}
                    onPageChange={(page: number) => setCurrentPageNum(page)}
                />
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchStudents}/>
            }
        }
    }, [currentPageNum, fetchStudents, pageLoadingState, searchString, studentsList, totalStudentsCount])

    return <div>
        <PageTitle><Trans>Ученики</Trans></PageTitle>
        <PageWrapper>
            <ChipsFilterArea
                placeholder={t`Поиск по ФИО, номеру телефона, email ученика...`}
                searchString={searchString}
                onChange={setSearchString}
                createNewBtn={{
                    label: t`Добавить ученика`,
                    link: RoutesHelper.replaceParams(
                        RoutesList.TEACHER_CREATE_STUDENT,
                        [
                            {
                                key: 'stepId',
                                value: PageSectionsEnum.GENERAL
                            }
                        ]
                    )
                }}
            />
            <ResultWrapper>
                {content}
            </ResultWrapper>
        </PageWrapper>
    </div>;
}