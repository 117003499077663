import React, {useMemo} from "react";
import {
    DtoAgreementItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import styled from "styled-components";
import {BlockWithBorder, RegularTextCss} from "../../../../../styles/global-elements";
import {
    DtoGroupData
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoGroupData";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {container} from "tsyringe";
import {Plural, Trans} from "@lingui/macro";
import {CostRegisterCostModeEnum} from "../../../../../../components/StonlineApiClient/Enums/CostRegisterCostModeEnum";
import {MoneyHelper} from "../../../../../../helpers/MoneyHelper";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";
import {SmallVerticalScheduleTable} from "../../../../../components/ScheduleTables/SmallVerticalScheduleTable";
import {HorizontalScheduleTable} from "../../../../../components/ScheduleTables/HorizontalScheduleTable";
import {ChangeBtn} from "./ChangeBtn";

const Wrapper = styled(BlockWithBorder)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ArchiveReasonWrapper = styled.div`
  ${RegularTextCss};
`;

const ProgramNameAndStartDateWrapper = styled.div`
  ${RegularTextCss};

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 20px;
  }
`;

const ProgramNameWrapper = styled.div`
  font-weight: bold;
`;

const ProgramNameDividerSmall = styled.div`
  display: initial;

  @media (${({theme}) => theme.media.small}) {
    display: none;
  }
`;

const ProgramNameDividerLarge = styled.div`
  display: none;
  color: ${({theme}) => theme.colors.textTertiary};

  @media (${({theme}) => theme.media.small}) {
    display: initial;
  }
`;

const AgreementStartDateWrapper = styled.div`
  display: none;
  color: ${({theme}) => theme.colors.textTertiary};

  @media (${({theme}) => theme.media.medium}) {
    display: block;
    min-width: 260px;
    text-align: right;
  }
`;

const AgreementCost = styled.div`
  ${RegularTextCss};
`;

const ScheduleArea = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;

  @media (${({theme}) => theme.media.small}) {
    padding-top: 30px;
    margin-bottom: 30px;
  }

  @media (${({theme}) => theme.media.medium}) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
`;

const HorizontalChangeBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const HorizontalScheduleTableStyled = styled(HorizontalScheduleTable)`
  @media (${({theme}) => theme.media.small}) {
    justify-content: space-between;
  }

  @media (${({theme}) => theme.media.medium}) {
    justify-content: flex-start;
  }
`;

interface AgreementItemProps {
    dto: DtoAgreementItem;
    dtoGroupData: DtoGroupData;
}

export const AgreementItem: React.FC<AgreementItemProps> = ({dto, dtoGroupData}) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);
    const isMedium = useMediaQuery(`(${currentTheme.media.medium})`);

    const dateHelper = useMemo<IDateHelperService>(
        () => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE),
        []
    );

    const agreementStartDateString = useMemo<JSX.Element>(() => {
        const agreementStartAsDateObject = dateHelper.dateFromString(dto.startDate);

        return <><Trans>Старт</Trans>&nbsp;
            {dateHelper.formatAsDate(agreementStartAsDateObject)}
        </>;
    }, [dateHelper, dto.startDate]);

    const workEndToday = useMemo<boolean>(() => {
        const currentDate = dateHelper.getCurrentDateWithoutTime();
        const dateEndWork = dateHelper.setZeroTimeForDateObject(
            dateHelper.dateFromString(dtoGroupData.groupDateEndWork)
        );

        return currentDate.getTime() === dateEndWork.getTime();
    }, [dateHelper, dtoGroupData.groupDateEndWork]);

    const isArchiveItemString = useMemo<JSX.Element | null>(() => {
        if (dto.annulled === 1) {
            return <Trans>Договор расторгнут</Trans>;
        }

        const currentDate = dateHelper.getCurrentDateWithoutTime();
        const dateEndWork = dateHelper.dateFromString(dtoGroupData.groupDateEndWork);

        if (currentDate > dateEndWork) {
            return <>
                <Trans>Обучение завершено</Trans>&nbsp;{dateHelper.formatAsDate(dateEndWork)}
            </>
        }

        return null;
    }, [dateHelper, dto.annulled, dtoGroupData.groupDateEndWork]);

    const agreementCostString = useMemo<JSX.Element | null>(() => {
        let costModeStartDate: Date | null = null;
        let costMode: CostRegisterCostModeEnum = CostRegisterCostModeEnum.COST_MODE_DEFAULT;
        let costPerMonth = parseFloat(dtoGroupData.trainingProgramCostPerMonth ?? '0');

        if (dto.costRegisterData !== null) {
            costPerMonth = parseFloat(dto.costRegisterData.cost);
            costMode = dto.costRegisterData.costMode;
            costModeStartDate = dateHelper.dateFromString(dto.costRegisterData.startDateTime);
        }

        let prefix = null;

        if (costModeStartDate) {
            const currentDate = dateHelper.getCurrentDateWithoutTime();

            if (currentDate < costModeStartDate) {
                prefix = <><Trans>C {dateHelper.formatAsDate(costModeStartDate)} будет</Trans>&nbsp;</>;
            }
        }

        switch (costMode) {
            case CostRegisterCostModeEnum.COST_MODE_DEFAULT:
            case CostRegisterCostModeEnum.COST_MODE_INDIVIDUAL_COST: {
                return <>
                    {prefix}
                    {MoneyHelper.formatMoney(costPerMonth)}&nbsp;
                    <Plural
                        value={dtoGroupData.trainingProgramCostPeriod}
                        zero="за 0 занятий"
                        one="за # занятие"
                        few="за # занятия"
                        many="за # занятий"
                        other="за # занятий"
                    />
                </>
            }
            case CostRegisterCostModeEnum.COST_MODE_FIX_LESSON_PRICE: {
                return <>
                    {prefix}
                    {MoneyHelper.formatMoney(costPerMonth)}&nbsp;
                    <Trans>за урок</Trans>
                </>
            }
            default: {
                return null
            }
        }
    }, [dateHelper, dto.costRegisterData, dtoGroupData.trainingProgramCostPerMonth, dtoGroupData.trainingProgramCostPeriod]);

    // const scheduleStartDateString = useMemo<JSX.Element | null>(() => {
    //     if (!dtoGroupData.schedule) {
    //         return null;
    //     }
    //
    //     const scheduleStartDate = dateHelper.dateFromString(dtoGroupData.schedule.startDate);
    //     const currentDate = dateHelper.getCurrentDateWithoutTime();
    //
    //     if (currentDate < scheduleStartDate) {
    //         return <RegularText>
    //             <Trans>*это расписание начнёт действовать с {dateHelper.formatAsDate(scheduleStartDate)}</Trans>
    //         </RegularText>;
    //     }
    //
    //     return null;
    // }, [dtoGroupData, dateHelper]);

    return <Wrapper>
        {
            (workEndToday && !isArchiveItemString)
            && <ArchiveReasonWrapper>
                <Trans>Обучение завершается сегодня</Trans>
            </ArchiveReasonWrapper>
        }
        {
            (isArchiveItemString)
            && <ArchiveReasonWrapper>
                {isArchiveItemString}
            </ArchiveReasonWrapper>
        }
        <ProgramNameAndStartDateWrapper>
            <ProgramNameWrapper>
                {
                    (!dtoGroupData.groupForOneStudent)
                    && <>
                        <span>{dtoGroupData.groupName}</span><br/>
                    </>
                }
                <span>{dtoGroupData.courseName}</span>
                <ProgramNameDividerSmall><br/></ProgramNameDividerSmall>
                <ProgramNameDividerLarge>&nbsp;&mdash;&nbsp;</ProgramNameDividerLarge>
                <span>{dtoGroupData.trainingProgramName}</span>
            </ProgramNameWrapper>
            <AgreementStartDateWrapper>
                {agreementStartDateString}
            </AgreementStartDateWrapper>
        </ProgramNameAndStartDateWrapper>
        <AgreementCost>
            {agreementCostString}
        </AgreementCost>
        <ScheduleArea>
            {
                (!isSmall) && <SmallVerticalScheduleTable schedule={dtoGroupData.schedule}/>
            }
            {
                (isSmall) && <>
                    <HorizontalScheduleTableStyled schedule={dtoGroupData.schedule}/>
                </>
            }
            {
                (isMedium) &&
                <HorizontalChangeBtnWrapper>
                    <ChangeBtn dto={dto} dtoGroupData={dtoGroupData}/>
                </HorizontalChangeBtnWrapper>
            }
        </ScheduleArea>
        {
            (!isMedium) && <ChangeBtn dto={dto} dtoGroupData={dtoGroupData}/>
        }
    </Wrapper>
}