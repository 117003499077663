import React, {useEffect} from 'react';
import classNames from 'classnames';
import type {DraggableSyntheticListeners} from '@dnd-kit/core';
import type {Transform} from '@dnd-kit/utilities';

import {Handle} from './components/Handle';
import {Edit} from './components/Edit';
import styled from "styled-components";
import {Remove} from "./components/Remove";

export interface ItemProps {
    dragOverlay?: boolean;
    color?: string;
    disabled?: boolean;
    selected?: boolean;
    dragging?: boolean;
    handle?: boolean;
    handleProps?: any;
    height?: number;
    index?: number;
    fadeIn?: boolean;
    transform?: Transform | null;
    listeners?: DraggableSyntheticListeners;
    sorting?: boolean;
    style?: React.CSSProperties;
    transition?: string | null;
    wrapperStyle?: React.CSSProperties;
    value: React.ReactNode;
    tinyItems?: boolean;
    active?: boolean;

    onRemove?(): void;

    onEdit?(): void;
}

interface ItemWrapperProps {
    translateX?: string;
    translateY?: string;
    scaleX?: string;
    scaleY?: string;
    transition?: string | null;
}

const ItemWrapper = styled.li<ItemWrapperProps>`
  display: flex;
  box-sizing: border-box;
  transform: translate3d(${(props) => (props.translateX) ?? 0}, ${(props) => (props.translateY) ?? 0}, 0) scaleX(${(props) => (props.scaleX) ?? 1}) scaleY(${(props) => (props.scaleY) ?? 1});
  transform-origin: 0 0;
  touch-action: manipulation;
  transition: ${(props) => (props.transition) ?? "none"};

  max-width: 100%;
  min-width: 100%;

  &.fadeIn {
    animation: fadeIn 500ms ease;
  }

  &.dragOverlay {
    box-shadow: 0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05), 0 1px calc(3px / 1) 0 rgba(34, 33, 81, 0.15);
    z-index: ${({theme}) => theme.zIndices.tempHighest};
  }
`;

interface ItemStyledProps {
    tinyItems?: boolean;
}

export const ItemStyled = styled.div<ItemStyledProps>`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 5px 15px;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  outline: none;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 20px;
  box-sizing: border-box;
  list-style: none;
  transform-origin: 50% 50%;

  -webkit-tap-highlight-color: transparent;

  font-size: 1rem;
  white-space: nowrap;

  transform: scale(1);
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

  overflow: hidden;

  &.tinyItems {
    border-radius: 0;
    border-style: none;
    padding: 0;
  }

  &.dragging:not(.dragOverlay) {
    opacity: 0.5;
    z-index: 0;
  }

  &.disabled {
    color: #999;
    background-color: #f1f1f1;

    cursor: not-allowed;
  }

  &.dragOverlay {
    cursor: inherit;
    animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
    transform: scale(1.05);
    box-shadow: 0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01),
    0 15px 15px 0 rgba(34, 33, 81, 0.25);
    opacity: 1;
  }

  &.color:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 3px;
    display: block;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &.selected:not(.tinyItems) {
    border-color: ${({theme}) => theme.colors.textPrimary};
    border-width: 2px;
    padding: 4px 14px;
  }
`;

const ItemContentWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

const ItemControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const Item = React.memo(
    React.forwardRef<HTMLLIElement, ItemProps>(
        (
            {
                color,
                dragOverlay,
                dragging,
                disabled,
                selected,
                fadeIn,
                handle,
                handleProps,
                height,
                index,
                listeners,
                onRemove,
                onEdit,
                sorting,
                style,
                transition,
                transform,
                value,
                tinyItems,
                wrapperStyle,
                active,
                ...props
            },
            ref
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = 'grabbing';

                return () => {
                    document.body.style.cursor = '';
                };
            }, [dragOverlay]);

            return (
                <ItemWrapper
                    className={classNames(
                        fadeIn && "fadeIn",
                        dragOverlay && "dragOverlay"
                    )}
                    translateX={transform ? `${Math.round(transform.x)}px` : undefined}
                    translateY={transform ? `${Math.round(transform.y)}px` : undefined}
                    scaleX={transform?.scaleX ? `${transform.scaleX}` : undefined}
                    scaleY={transform?.scaleY ? `${transform.scaleY}` : undefined}
                    transition={transition}
                    ref={ref}
                >
                    <ItemStyled
                        className={classNames(
                            dragging && "dragging",
                            handle && "withHandle",
                            dragOverlay && "dragOverlay",
                            disabled && "disabled",
                            color && "color",
                            selected && "selected",
                            tinyItems && "tinyItems"
                        )}
                    >
                        <ItemContentWrapper>
                            {value}
                        </ItemContentWrapper>
                        <ItemControlsWrapper>
                            {onEdit ? (
                                <Edit onClick={onEdit}/>
                            ) : null}
                            {onRemove ? (
                                <Remove onClick={onRemove}/>
                            ) : null}
                            {handle ? <Handle active={active} {...handleProps} {...listeners} /> : null}
                        </ItemControlsWrapper>

                        {/*    <div*/}
                        {/*    className={classNames(*/}
                        {/*        styles.Item,*/}
                        {/*        dragging && styles.dragging,*/}
                        {/*        handle && styles.withHandle,*/}
                        {/*        dragOverlay && styles.dragOverlay,*/}
                        {/*        disabled && styles.disabled,*/}
                        {/*        color && styles.color*/}
                        {/*    )}*/}
                        {/*    style={style}*/}
                        {/*    data-cypress="draggable-item"*/}
                        {/*    {...(!handle ? listeners : undefined)}*/}
                        {/*    {...props}*/}
                        {/*    tabIndex={!handle ? 0 : undefined}*/}
                        {/*    >*/}
                        {/*    {value}*/}
                        {/*    <span className={styles.Actions}>*/}
                        {/*    {onRemove ? (*/}
                        {/*        <Remove className={styles.Remove} cursor={"pointer"} onClick={onRemove}/>*/}
                        {/*    ) : null}*/}
                        {/*    {handle ? <Handle {...handleProps} {...listeners} /> : null}*/}
                        {/*</span>*/}
                        {/*</div>*/}
                    </ItemStyled>
                </ItemWrapper>
            );
        }
    )
);
