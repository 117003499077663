import {IActionHandler} from "./IActionHandler";
import {EditorItemInteractivityConfigAction} from "../../../SlidePlayerEditorCommonParts/EditorData";
import {handleUserEventSetOverriddenParams} from "../../../../../store/slidesWorkData/actions";
import {RunParams} from "../RunParams";
import {SlideItemInteractivityActionTypeEnum} from "../../../../../enums/SlideItemInteractivityActionNameEnum";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";

export class ShowOrHideItemByAlias implements IActionHandler {
    protected logger: ILogger;

    constructor(logger: ILogger) {
        this.logger = logger;
    }

    run(runParams: RunParams, actionConfig: EditorItemInteractivityConfigAction): Promise<void> {
        // 1. Нужно найти целевой элемент слайда
        const targetSlideItem = runParams.slideContent.items.find(item => {
            return item.alias === actionConfig.actionAdditionalInfo
        })??null

        if (targetSlideItem === null) {
            this.logger.error(LoggerSectionsEnum.SLIDE_INTERACTIVITY, `Not found target slide item id by alias ${actionConfig.actionAdditionalInfo}`);

            return Promise.resolve();
        }

        runParams.dispatch(handleUserEventSetOverriddenParams({
            playerId: runParams.playerId,
            slideId: runParams.slideId,
            slideItemId: targetSlideItem.id,
            exerciseId: runParams.slideItemId,
            value: '',
            overriddenParams: {
                visible: (actionConfig.actionType === SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS)
            }
        }));

        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);

        return Promise.resolve();
    }
}