import React, {useCallback} from "react";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {ContentList, ContentListProps} from "../../../components/Ui/Elements/ContentList";
import {ListItem} from "../../../components/Ui/Elements/ContentList/ListItem";
import {
    DtoSelfStudyTrackListItem
} from "../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackListItem";
import {ReactComponent as OpenInNewSvg} from "../../../components/Ui/Svg/OpenInNew9px.svg";
import {t, Trans} from "@lingui/macro";
import styled, {css} from "styled-components";
import {container} from "tsyringe";
import {IClipboardHelper} from "../../../../components/ClipboardHelper/IClipboardHelper";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../components/Ui/Elements/Notification";

const LinkWrapperCss = css`
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-top: 10px;
    align-items: center;
    color: ${({theme}) => theme.colors.textSecondary};
    opacity: 0.8;

    &:hover {
        color: ${({theme}) => theme.colors.textSecondary};
        opacity: 1;
    }
`;

interface ItemsGridProps extends Omit<ContentListProps, 'children'> {
    items: DtoSelfStudyTrackListItem[];
}

export const ItemsList: React.FC<ItemsGridProps> = (
    {
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange,
        items
    }
) => {
    const linkToTrack = useCallback((trackId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_SELF_STUDY_TRACK_VIEW,
            [
                {
                    key: 'trackId',
                    value: trackId
                }
            ]
        );
    }, []);

    return <ContentList
        currentPageNum={currentPageNum}
        itemsPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={onPageChange}
    >
        {
            items.map((item) => {
                return <ListItem
                    key={`item${item.id}`}
                    link={linkToTrack(item.id)}
                    firstLine={item.name}
                    secondLine={
                        (item.internalDescription) ? <div>
                                {item.internalDescription}
                            </div>
                            : null
                    }
                />
            })
        }
    </ContentList>
}
