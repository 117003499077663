import React from "react";
import {Dispatch} from "redux";
import {setAllowedToUseCameraEarly} from "../../../../store/commonPersisted/actions";
import {ApplicationState} from "../../../../store";
import {setCameraAllowedInSession, setCameraRequestAccessNow} from "../../../../store/app/actions";
import {connect, ConnectedProps} from "react-redux";
import styled, {css} from "styled-components";
import {PageSubtitleCss, RegularTextCss} from "../../../styles/global-elements";
import {ReactComponent as CameraIconSvg} from "../../../components/Ui/Svg/VideoCallPlaceholder.svg";
import {ReactComponent as MicrophoneIconSvg} from "../../../components/Ui/Svg/Microphone.svg";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import OperaIconSrc from "./BrowserIcons/Opera.png"
import EdgeIconSrc from "./BrowserIcons/Edge.png"
import ChromeIconSrc from "./BrowserIcons/Chrome.png"
import SafariIconSrc from "./BrowserIcons/Safari.png"
import YandexIconSrc from "./BrowserIcons/Yandex.png"
import FirefoxIconSrc from "./BrowserIcons/Firefox.png"
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";

const PageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.backgroundSecondary}f5;
  z-index: ${({theme}) => theme.zIndices.cameraAccessRequestSplash};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  padding: 10px 20px;
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconCss = css`
  width: 80px;
  height: 80px;
`;

const CameraIcon = styled(CameraIconSvg)`
  ${IconCss};
`;

const MicrophoneIcon = styled(MicrophoneIconSvg)`
  ${IconCss};
`;

const Title = styled.div`
  ${PageSubtitleCss};

  text-align: center;
  margin-bottom: 10px !important;
`;

const Text = styled.div`
  ${RegularTextCss};

  text-align: center;
  margin-bottom: 20px !important;
`;

const BrowserIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
`;

const BrowserIcon = styled.img`
  width: 60px;
  height: 60px;

  @media (${({theme}) => theme.media.large}) {
    width: 80px;
    height: 80px;
  }
`;

const LoaderWrapper = styled.div``;

const ButtonsWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CancelButton = styled(Button)`
  box-shadow: none;
`;

interface CameraAccessSplashProps extends PropsFromRedux {

}

interface CameraAccessSplashState {
    requestInProcess: boolean;
    requestFailed: boolean;
}

class CameraAccessSplash extends React.Component<CameraAccessSplashProps, CameraAccessSplashState> {

    protected logger: ILogger;

    constructor(props: Readonly<CameraAccessSplashProps> | CameraAccessSplashProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.state = {
            requestInProcess: false,
            requestFailed: false
        }
    }

    componentDidMount() {
        // Проверим - получали ли доступ ранее. Если получали, то сразу запросим доступ.
        if (this.props.allowedToUseCameraEarly === true) {
            this.allowAccessOnClick();
        }
    }

    protected allowAccessOnClick = () => {
        this.setState(() => {
            return {
                requestFailed: false,
                requestInProcess: true
            }
        });

        if (navigator.mediaDevices === undefined) {
            this.logger.error(LoggerSectionsEnum.ACCESS_TO_CAMERA, 'mediaDevices is unknown');

            this.setState(() => {
                return {
                    requestFailed: true,
                    requestInProcess: false
                }
            });

            this.props.setAllowedEarly(false);
            this.props.setAllowedInSession(false);

            return;
        }

        navigator.mediaDevices.getUserMedia({video: true, audio: true})
            .then((data) => {
                data.getTracks().forEach((item) => {
                    item.stop();
                })

                this.props.setAllowedEarly(true);
                this.props.setAllowedInSession(true);
                this.props.setCameraRequestAccessNow(false);
            })
            .catch((error) => {
                if (error.message === 'Permission denied') {
                    this.logger.info(LoggerSectionsEnum.ACCESS_TO_CAMERA, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.ACCESS_TO_CAMERA, error);
                }

                this.setState(() => {
                    return {
                        requestFailed: true,
                        requestInProcess: false
                    }
                });

                this.props.setAllowedEarly(false);
                this.props.setAllowedInSession(false);
            })
    }

    protected cancelBtnOnClick = () => {
        this.props.setAllowedEarly(false);
        this.props.setAllowedInSession(false);
        this.props.setCameraRequestAccessNow(false);
    }

    render() {
        return <PageWrapper>
            <ContentWrapper>
                <IconsWrapper>
                    <CameraIcon/>
                    <MicrophoneIcon/>
                </IconsWrapper>
                {
                    ((!this.state.requestInProcess) && (!this.state.requestFailed))
                    && <>
                        <Title><Trans>Понадобится доступ к камере и микрофону.</Trans></Title><br/>
                        <ButtonsWrapper>
                            <Button btnStyle={BtnStyleEnum.Primary} onClick={this.allowAccessOnClick}>
                                <Trans>Предоставить доступ</Trans>
                            </Button>
                            <CancelButton btnStyle={BtnStyleEnum.Secondary} onClick={this.cancelBtnOnClick}>
                                <Trans>Отмена</Trans>
                            </CancelButton>
                        </ButtonsWrapper>
                    </>
                }
                {
                    (this.state.requestInProcess)
                    && <>
                        <Title><Trans>Понадобится доступ к камере и микрофону.</Trans></Title>
                        <Text><Trans>Подтвердите разрешение на доступ в браузере, если он запросит</Trans></Text>
                        <LoaderWrapper>
                            <DefaultLoader/>
                        </LoaderWrapper>
                        <ButtonsWrapper>
                            <CancelButton btnStyle={BtnStyleEnum.Secondary} onClick={this.cancelBtnOnClick}>
                                <Trans>Отмена</Trans>
                            </CancelButton>
                        </ButtonsWrapper>
                    </>
                }
                {
                    ((!this.state.requestInProcess) && (this.state.requestFailed))
                    && <>
                        <Title><Trans>Не удалось получить доступ к камере и микрофону.</Trans></Title><br/>
                        <Text>
                            <Trans>Нажмите на иконку вашего браузера, чтобы узнать - как это исправить:</Trans>
                        </Text>
                        <BrowserIconsWrapper>
                            <a href={"https://help.webinar.ru/ru/articles/1734870-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%81%D1%82%D0%B2%D0%B0-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B0%D1%85-chrome-%D0%B8-%D1%8F%D0%BD%D0%B4%D0%B5%D0%BA%D1%81-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={ChromeIconSrc}/></a>

                            <a href={"https://help.webinar.ru/ru/articles/1732901-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D0%BA%D0%B0%D0%BC%D0%B5%D1%80%D0%B0-%D0%B8-%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%84%D0%BE%D0%BD-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B5-firefox"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={FirefoxIconSrc}/></a>

                            <a href={"https://help.webinar.ru/ru/articles/1734905-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D0%BA%D0%B0%D0%BC%D0%B5%D1%80%D0%B0-%D0%B8-%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%84%D0%BE%D0%BD-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B5-edge"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={EdgeIconSrc}/></a>

                            <a href={"https://help.webinar.ru/ru/articles/1732864-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D0%BA%D0%B0%D0%BC%D0%B5%D1%80%D0%B0-%D0%B8-%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%84%D0%BE%D0%BD-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B5-safari"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={SafariIconSrc}/></a>

                            <a href={"https://help.webinar.ru/ru/articles/1734870-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D1%83%D1%81%D1%82%D1%80%D0%BE%D0%B9%D1%81%D1%82%D0%B2%D0%B0-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B0%D1%85-chrome-%D0%B8-%D1%8F%D0%BD%D0%B4%D0%B5%D0%BA%D1%81-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={YandexIconSrc}/></a>

                            <a href={"https://help.webinar.ru/ru/articles/1732868-%D0%BD%D0%B5%D1%82-%D1%81%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D0%B0-%D0%B8%D0%BB%D0%B8-%D0%BA%D0%B0%D0%BC%D0%B5%D1%80%D0%B0-%D0%B8-%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%84%D0%BE%D0%BD-%D0%B7%D0%B0%D0%B1%D0%BB%D0%BE%D0%BA%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D1%8B-%D0%B2-%D0%B1%D1%80%D0%B0%D1%83%D0%B7%D0%B5%D1%80%D0%B5-opera"}
                               target={"_blank"} rel="noreferrer"
                            ><BrowserIcon src={OperaIconSrc}/></a>
                        </BrowserIconsWrapper>
                        <ButtonsWrapper>
                            <Button btnStyle={BtnStyleEnum.Primary} onClick={this.allowAccessOnClick}>
                                <Trans>Повторить попытку</Trans>
                            </Button>
                            <CancelButton btnStyle={BtnStyleEnum.Secondary} onClick={this.cancelBtnOnClick}>
                                <Trans>Отмена</Trans>
                            </CancelButton>
                        </ButtonsWrapper>
                    </>
                }
            </ContentWrapper>
        </PageWrapper>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAllowedEarly: (value: boolean) =>
        dispatch(setAllowedToUseCameraEarly(value)),
    setAllowedInSession: (value: boolean) =>
        dispatch(setCameraAllowedInSession(value)),
    setCameraRequestAccessNow: (value: boolean) =>
        dispatch(setCameraRequestAccessNow(value))
});

const mapStateToProps = ({commonPersisted, app}: ApplicationState) => ({
    allowedToUseCameraEarly: commonPersisted.allowedToUseCameraEarly,
    allowedToUseCameraInSession: app.cameraAccess.allowedInSession
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CameraAccessSplash);
