import React from "react";
import {PageSubtitle2Css} from "../../../styles/global-elements";
import styled from "styled-components";
import {RouterLink} from "./Link";
import {ReactComponent as ArrowBack} from "../Svg/ArrowBack.svg";
import {t} from "@lingui/macro";

const PageBackLinkWrapper = styled.div`
  margin-bottom: 20px;
`;

const PageBackLinkLink = styled(RouterLink)`
  ${PageSubtitle2Css};

  color: ${({theme}) => theme.colors.textTertiary};
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
`;

const PageBackLinkLinkBtn = styled.div`
  ${PageSubtitle2Css};

  color: ${({theme}) => theme.colors.textTertiary};
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  width: fit-content;
`;

const PageBackLinkIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (${({theme}) => theme.media.medium}) {
    padding-top: 4px;
  }
`;

const PageBackLinkIcon = styled(ArrowBack)`
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
`;

const PageBackLinkText = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 5px;
`;

interface PageBackLinkProps {
    link?: string;
    onClick?: () => void;
    text?: string;
}

export const PageBackLink: React.FC<PageBackLinkProps> = (props) => {
    if (props.onClick !== undefined) {
        return <PageBackLinkWrapper>
            <PageBackLinkLinkBtn onClick={props.onClick}>
                <PageBackLinkIconWrapper>
                    <PageBackLinkIcon/>
                </PageBackLinkIconWrapper>
                <PageBackLinkText>{(props.text) ?? t`Назад`}</PageBackLinkText>
            </PageBackLinkLinkBtn>
        </PageBackLinkWrapper>;
    }

    if (props.link !== undefined) {
        return <PageBackLinkWrapper>
            <PageBackLinkLink to={props.link}>
                <PageBackLinkIconWrapper>
                    <PageBackLinkIcon/>
                </PageBackLinkIconWrapper>
                <PageBackLinkText>{(props.text) ?? t`Назад`}</PageBackLinkText>
            </PageBackLinkLink>
        </PageBackLinkWrapper>;
    }

    throw new Error('PageBackLink component user without link on onClick handler');
}