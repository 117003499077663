import React, {useCallback} from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {
    StudentValueProgressBlock,
    StudentValueProgressBlockTypes
} from "../../../../components/Ui/Elements/StudentValueProgressBlock";
import {StudentRateParamPositionEnum} from "../../../../../enums/StudentRateParamPositionEnum";
import styled from "styled-components";

export interface MetricItem {
    name: string;
    value: number;
    paramPosition: StudentRateParamPositionEnum | null;
}

interface AverageValuesComponentProps {
    totalAverageItem: MetricItem;
    detailValues?: MetricItem[];
}

const ItemsWrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const AverageValuesComponent: React.FC<AverageValuesComponentProps> = ({totalAverageItem, detailValues}) => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);
    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    /**
     * Представление - в столбик
     */
    const extraSmallVariant = useCallback(() => {
        // Если только один параметр оценки
        if (detailValues === undefined || detailValues.length === 0) {
            return <ItemsWrapper>
                <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                           paramPosition={totalAverageItem.paramPosition}
                                           paramName={totalAverageItem.name}
                                           paramValue={totalAverageItem.value}
                />
            </ItemsWrapper>
        }

        // Если несколько параметров оценки
        return <ItemsWrapper>
            <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                       paramPosition={totalAverageItem.paramPosition}
                                       paramName={totalAverageItem.name}
                                       paramValue={totalAverageItem.value}
            />

            {
                (detailValues[0] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.LIKE_BADGE}
                                              paramPosition={detailValues[0].paramPosition}
                                              paramName={detailValues[0].name}
                                              paramValue={detailValues[0].value}
                />
            }

            {
                (detailValues[1] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.LIKE_BADGE}
                                              paramPosition={detailValues[1].paramPosition}
                                              paramName={detailValues[1].name}
                                              paramValue={detailValues[1].value}
                />
            }

            {
                (detailValues[2] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.LIKE_BADGE}
                                              paramPosition={detailValues[2].paramPosition}
                                              paramName={detailValues[2].name}
                                              paramValue={detailValues[2].value}
                />
            }

            {
                (detailValues[3] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.LIKE_BADGE}
                                              paramPosition={detailValues[3].paramPosition}
                                              paramName={detailValues[3].name}
                                              paramValue={detailValues[3].value}
                />
            }

            {
                (detailValues[4] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.LIKE_BADGE}
                                              paramPosition={detailValues[4].paramPosition}
                                              paramName={detailValues[4].name}
                                              paramValue={detailValues[4].value}
                />
            }
        </ItemsWrapper>
    }, [detailValues, totalAverageItem.paramPosition, totalAverageItem.name, totalAverageItem.value]);

    /**
     * Представление: среднее значение в строку, значение параметров в другой строке
     */
    const smallValiant = useCallback(() => {
        // Если только один параметр оценки
        if (detailValues === undefined || detailValues.length === 0) {
            return <ItemsWrapper>
                <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_LEFT}
                                           paramPosition={totalAverageItem.paramPosition}
                                           paramName={totalAverageItem.name}
                                           paramValue={totalAverageItem.value}
                />
            </ItemsWrapper>
        }

        const gridTemplates = {
            2: '"a a" auto '
                + '"b c" auto / 1fr 1fr ',
            3: '"a a a" auto '
                + '"b c d" auto / 1fr 1fr 1fr ',
            4: '"a a" '
                + '"b c" '
                + '"d e" ',
            5: '"a a a a a a" '
                + '"b b b c c c" '
                + '"d d e e f f" ',
        }

        // Если несколько параметров оценки
        const templateId = detailValues.length as unknown as "2" | "3" | "4" | "5";

        if (gridTemplates[templateId] === undefined) {
            throw new Error('Unsupported detail values count');
        }

        return <ItemsWrapper style={{gridTemplate: gridTemplates[templateId]}}>
            <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_LEFT}
                                       paramPosition={totalAverageItem.paramPosition}
                                       paramName={totalAverageItem.name}
                                       paramValue={totalAverageItem.value}
                                       style={{gridArea: 'a'}}
            />

            {
                (detailValues[0] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[0].paramPosition}
                                              paramName={detailValues[0].name}
                                              paramValue={detailValues[0].value}
                                              style={{gridArea: 'b'}}
                />
            }

            {
                (detailValues[1] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[1].paramPosition}
                                              paramName={detailValues[1].name}
                                              paramValue={detailValues[1].value}
                                              style={{gridArea: 'c'}}
                />
            }

            {
                (detailValues[2] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[2].paramPosition}
                                              paramName={detailValues[2].name}
                                              paramValue={detailValues[2].value}
                                              style={{gridArea: 'd'}}
                />
            }

            {
                (detailValues[3] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[3].paramPosition}
                                              paramName={detailValues[3].name}
                                              paramValue={detailValues[3].value}
                                              style={{gridArea: 'e'}}
                />
            }

            {
                (detailValues[4] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[4].paramPosition}
                                              paramName={detailValues[4].name}
                                              paramValue={detailValues[4].value}
                                              style={{gridArea: 'f'}}
                />
            }
        </ItemsWrapper>
    }, [detailValues, totalAverageItem.paramPosition, totalAverageItem.name, totalAverageItem.value]);

    /**
     * Представление: все идут в строчку
     */
    const largeVariant = useCallback(() => {
        // Если только один параметр оценки
        if (detailValues === undefined || detailValues.length === 0) {
            return <ItemsWrapper>
                <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_LEFT}
                                           paramPosition={totalAverageItem.paramPosition}
                                           paramName={totalAverageItem.name}
                                           paramValue={totalAverageItem.value}
                />
            </ItemsWrapper>
        }

        const gridTemplates = {
            2: '"a a b c"',
            3: '"a a a b c d"',
            4: '"a a b c d e" ',
            5: '"a b c d e f" '
        }

        // Если несколько параметров оценки
        const templateId = detailValues.length as unknown as "2" | "3" | "4" | "5";

        if (gridTemplates[templateId] === undefined) {
            throw new Error('Unsupported detail values count');
        }

        return <ItemsWrapper style={{gridTemplate: gridTemplates[templateId]}}>
            <StudentValueProgressBlock
                showVariant={
                    ((templateId as unknown as number) < 5)
                        ? StudentValueProgressBlockTypes.VALUE_NAME_LEFT
                        : StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM
                }
                paramPosition={totalAverageItem.paramPosition}
                paramName={totalAverageItem.name}
                paramValue={totalAverageItem.value}
                style={{gridArea: 'a'}}
            />

            {
                (detailValues[0] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[0].paramPosition}
                                              paramName={detailValues[0].name}
                                              paramValue={detailValues[0].value}
                                              style={{gridArea: 'b'}}
                />
            }

            {
                (detailValues[1] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[1].paramPosition}
                                              paramName={detailValues[1].name}
                                              paramValue={detailValues[1].value}
                                              style={{gridArea: 'c'}}
                />
            }

            {
                (detailValues[2] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[2].paramPosition}
                                              paramName={detailValues[2].name}
                                              paramValue={detailValues[2].value}
                                              style={{gridArea: 'd'}}
                />
            }

            {
                (detailValues[3] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[3].paramPosition}
                                              paramName={detailValues[3].name}
                                              paramValue={detailValues[3].value}
                                              style={{gridArea: 'e'}}
                />
            }

            {
                (detailValues[4] !== undefined)
                && <StudentValueProgressBlock showVariant={StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM}
                                              paramPosition={detailValues[4].paramPosition}
                                              paramName={detailValues[4].name}
                                              paramValue={detailValues[4].value}
                                              style={{gridArea: 'f'}}
                />
            }
        </ItemsWrapper>
    }, [detailValues, totalAverageItem.paramPosition, totalAverageItem.name, totalAverageItem.value]);

    if (!isSmall) {
        return extraSmallVariant();
    }

    if (isLarge) {
        return largeVariant();
    }

    if (isSmall) {
        return smallValiant();
    }

    return <div/>;
}
