import React, {CSSProperties, useMemo} from 'react';
import {UniqueIdentifier, useDroppable} from '@dnd-kit/core';
import classNames from 'classnames';
import styled, {keyframes} from "styled-components";
import {InternalExerciseData} from "./ExerciseDragDropContext";
import {DraggableItem} from "./DraggableItem";
import {ShowMode} from "./Exercise";

const ErrorTremor = keyframes`
  0% {
    transform: translateX(-3px);
  }

  15% {
    transform: translateX(+3px);
  }

  30% {
    transform: translateX(-2px);
  }

  45% {
    transform: translateX(+2px);
  }

  60% {
    transform: translateX(-1px);
  }

  75% {
    transform: translateX(+1);
  }

  90% {
    transform: translateX(0);
  }
`;

export const DroppableStyled = styled.div`
  border-radius: 4px;
  position: relative;
  padding: 2px 6px;

  transition: transform 0.1s ease, background-position .4s ease-out;
  background: linear-gradient(to left, ${({theme}) => theme.colors.backgroundSecondary} 50%, /*#ecf9dd*/ ${({theme}) => theme.colors.backgroundHover} 50%) right;
  background-size: 200% 100%;

  &.dragging {
      // background: linear-gradient(to left, ${({theme}) => theme.colors.backgroundSecondary} 50%, #fafff4 50%) right;
    background-position: left center;
  }

  &.dropped {
    padding: 0;
  }

  &.correct {
    //border: none;
    //box-shadow: none;
    //cursor: text;
    background: linear-gradient(to left, ${({theme}) => theme.colors.backgroundSecondary} 50%, ${({theme}) => theme.slideContent.textInputCorrectBg} 50%) right;
    background-size: 200% 100%;
    background-position: left center;
  }

  &.error {
    animation: ${ErrorTremor} 0.9s ease-in-out;
  }
`;

const DroppablePlaceholderStyled = styled.span`
  min-height: 1em;
  margin: 6px 0;
  width: 100%;
  border-bottom: 1px solid #a1a1a1;
  display: inline-block;
  vertical-align: bottom;
`;

interface Props {
    id: UniqueIdentifier;
    dragging: boolean;
    selectedWord: InternalExerciseData | null;
    showMode?: ShowMode;
    onAnimationEnd?: () => void;
}

export function Droppable({selectedWord, id, dragging, showMode, onAnimationEnd}: Props) {
    const {isOver, setNodeRef} = useDroppable({
        id,
        disabled: selectedWord !== null
    });

    const styles = useMemo<CSSProperties>(() => {
        return {
            width: (selectedWord) ? "auto" : "92px"
        };
    }, [selectedWord]);

    // const currentItem = useMemo(() => {
    //
    // }, );

    return (
        <DroppableStyled
            ref={setNodeRef}
            className={classNames(
                isOver && "over",
                dragging && "dragging",
                selectedWord && "dropped",
                (showMode === ShowMode.ERROR) && "error",
                (showMode === ShowMode.CORRECT) && "correct"
            )}
            onAnimationEnd={onAnimationEnd}
            aria-label="Droppable region"
            style={styles}
        >
            {
                (selectedWord)
                    ? <DraggableItem element={selectedWord.element} showMode={showMode}/>
                    : <DroppablePlaceholderStyled/>
            }
        </DroppableStyled>
    );
}