import {action} from "typesafe-actions";

import {
    ItemHandleState,
    QueueItem,
    QueueItemContext,
    QueueSendCycleStateEnum,
    SlidesWorkDataSaveQueueActionTypes
} from "./type";

export const newItemToQueue = (item: QueueItem<QueueItemContext>) => {
    return action(SlidesWorkDataSaveQueueActionTypes.NEW_ITEM_TO_QUEUE, item);
};

export const startSaveDataAfterReconnect = () => {
    return action(SlidesWorkDataSaveQueueActionTypes.START_SAVE_DATA_AFTER_RECONNECT);
};

export const startHandleCycle = () => {
    return action(SlidesWorkDataSaveQueueActionTypes.START_HANDLE_CYCLE);
};

export const stopHandleCycle = () => {
    return action(SlidesWorkDataSaveQueueActionTypes.STOP_HANDLE_CYCLE);
};

export const sendQueue = () => {
    return action(SlidesWorkDataSaveQueueActionTypes.SEND_QUEUE);
};

export const setNewItem = (item: QueueItem<QueueItemContext>) => {
    return action(SlidesWorkDataSaveQueueActionTypes.SET_NEW_ITEM, item);
};

export const setItemsHandleState = (itemIds: number[], newState: ItemHandleState) => {
    return action(SlidesWorkDataSaveQueueActionTypes.SET_ITEMS_HANDLE_STATE, {itemIds, newState});
};

export const deleteItems = (itemIds: number[]) => {
    return action(SlidesWorkDataSaveQueueActionTypes.DELETE_ITEMS, itemIds);
};

export const clearAll = () => {
    return action(SlidesWorkDataSaveQueueActionTypes.CLEAR_ALL);
};

export const setCycleEnabledValue = (value: boolean) => {
    return action(SlidesWorkDataSaveQueueActionTypes.SET_CYCLE_ENABLED_VALUE, value);
};

export const setCycleStateValue = (newState: QueueSendCycleStateEnum) => {
    return action(SlidesWorkDataSaveQueueActionTypes.SET_CYCLE_STATE_VALUE, newState);
};