import {CommonPersistedState} from "./type";
import {ThemeEnum} from "../../services/theme/ThemeEnum";

export const initialState: CommonPersistedState = {
    profileType: null,
    authToken: null,
    locale: null,
    themeName: ThemeEnum.light,
    allowedToUseCameraEarly: false,
    lastAudioInputDeviceId: null,
    lastAudioOutputDeviceId: null,
    lastVideoInputDeviceId: null,
    otpEmail: null,
    utmForSave: null
};
