import {IsNotEmpty, IsNumber, IsString} from 'class-validator';

/**
 * DTO ответа на запрос следующего урока
 */
export class DtoNextLessonInfoResponseItem {
    @IsNotEmpty()
    @IsString()
    date: string;

    @IsNotEmpty()
    @IsString()
    startTime: string;

    @IsNotEmpty()
    @IsString()
    endTime: string;

    @IsNotEmpty()
    @IsNumber()
    duration: number;

    @IsNotEmpty()
    @IsString()
    address: string;

    @IsNotEmpty()
    @IsString()
    utcStartDateTime: string;
}