import {put, select, takeEvery} from 'redux-saga/effects';
import {setCycleEnabledValue} from "../../store/slidesWorkDataSaveQueue/actions";
import {SlidesWorkDataSaveQueueActionTypes} from "../../store/slidesWorkDataSaveQueue/type";
import {handleCycleEnabledSelector} from "../../store/slidesWorkDataSaveQueue/selector";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";

export function* watchStopHandleCycle() {
    yield takeEvery(
        SlidesWorkDataSaveQueueActionTypes.STOP_HANDLE_CYCLE,
        stopHandleCycle
    );
}

function* stopHandleCycle() {
    const handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

    if (handleCycleEnabled) {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue disabled');

        yield put(setCycleEnabledValue(false));
    }
}
