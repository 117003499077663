import * as React from "react";
import {useMemo} from "react";
import {Delimiter, HeaderContentItemWrapper, HeaderContentWrapper} from "./styles";
import useMediaQuery from "../../../../services/hooks/useMediaQuery";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ITheme} from "../../../../services/theme/ITheme";
import {UserAvatar} from "./UserAvatar";
import {
    selectedUserInSchool,
    userIsTeacher as userIsTeacherSelector,
    userProfileIsEmpty as userProfileIsEmptySelector
} from "../../../../store/user/selector";
import {ThemeSwitcher} from "./ThemeSwitcher";
import {Balance} from "./Balance";
import classNames from "classnames";

export const HeaderContent: React.FC = () => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const userIsTeacher = useSelector(userIsTeacherSelector) as boolean;

    const userInSchool = useSelector(selectedUserInSchool);

    const noSchoolContracts = useSelector(userProfileIsEmptySelector);

    const sum = useMemo<number>(() => {
        if (userInSchool === null) {
            return 0;
        }

        return userInSchool.balance;
    }, [userInSchool]);


    return (
        <HeaderContentWrapper>
            {isSmall && (
                <UserAvatar showName={true} menuToRight={true}/>
            )}

            <Delimiter/>

            {
                (!userIsTeacher && !noSchoolContracts)
                && <HeaderContentItemWrapper className={classNames(sum < 0 && "warning")}>
                    <Balance/>
                </HeaderContentItemWrapper>
            }

            {/*<HeaderContentItemWrapper>*/}
            {/*    <Notifications/>*/}
            {/*</HeaderContentItemWrapper>*/}

            <HeaderContentItemWrapper>
                <ThemeSwitcher/>
            </HeaderContentItemWrapper>

            {
                !isSmall && (
                    <UserAvatar showName={false} menuToRight={false}/>
                )
            }
        </HeaderContentWrapper>
    );
}