import {Reducer} from "redux";
import {LessonMaterialsActionTypes, LessonMaterialsState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<LessonMaterialsState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case LessonMaterialsActionTypes.SET_LOADING_STATE: {
            draft.loadingState = action.payload;

            break;
        }
        case LessonMaterialsActionTypes.SET_LESSON_ID: {
            draft.lessonId = action.payload;

            break;
        }
        case LessonMaterialsActionTypes.SET_MATERIALS_TYPE: {
            draft.homeworkMaterials = !!action.payload;

            break;
        }
        case LessonMaterialsActionTypes.SET_LESSON_MATERIALS_LIST: {
            draft.slidesList = action.payload;

            break;
        }
        case LessonMaterialsActionTypes.RESET_LESSON_MATERIALS_STATE: {
            return initialState;
        }
        case LessonMaterialsActionTypes.ADD_SUBSCRIPTION_ID: {
            draft.subscriptions = [...draft.subscriptions, action.payload];

            break;
        }
        case LessonMaterialsActionTypes.REMOVE_SUBSCRIPTION_ID: {
            draft.subscriptions = draft.subscriptions.filter((item) => item !== action.payload);

            break;
        }
        case LessonMaterialsActionTypes.CLEAR_SUBSCRIPTIONS_LIST: {
            draft.subscriptions = [];

            break;
        }
    }
}, initialState);

export {reducer as lessonMaterialsReducer}
