import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {
    DtoGroupSchedule
} from "../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {WeekDayNameByNumber, WeekDayType} from "../Ui/Elements/TextPresenter/WeekDayNameByNumber";
import {container} from "tsyringe";
import {DiTokens} from "../../../di-factory/DiTokens";
import {Trans} from "@lingui/macro";
import {RegularText, RegularTextCss} from "../../styles/global-elements";
import {IGroupScheduleReader} from "../GroupScheduleReader/IGroupScheduleReader";
import {
    GroupScheduleReaderResultItemType
} from "../GroupScheduleReader/GroupScheduleReaderResultItemType";
import {ReactComponent as CloseCross} from "../Ui/Svg/CloseCross.svg";
import classNames from "classnames";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  
  &.disabled {
    opacity: 0.5;
  }
`;

const DayNameWrapper = styled.div`
  ${RegularTextCss};
  text-align: center;
`;

const TimeWrapper = styled.div`
  ${RegularTextCss};
  text-align: center;
`;

const EmptyDateWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({theme}) => theme.colors.textSecondary};
`;

type ItemType = Omit<GroupScheduleReaderResultItemType, 'timeStart' | 'timeEnd'> & {
    timeStart: string | null;
    timeEnd: string | null;
}

interface ScheduleTableProps {
    schedule: DtoGroupSchedule | null;
    className?: string;
}

export const HorizontalScheduleTable: React.FC<ScheduleTableProps> = ({schedule, className}) => {
    const groupScheduleReader = useMemo<IGroupScheduleReader>(
        () => container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER),
        []
    );

    const scheduleItems = useMemo<ItemType[]>(() => {
        if (schedule === null) {
            return [];
        }

        const items = groupScheduleReader.readSchedule(schedule);

        if (items.length === 0) {
            return [];
        }

        const result: ItemType[] = [];

        for (let i = 1; i <= 7; i++) {
            const index = (i === 7) ? 0 : i;
            const scheduleItem = items.find((item) => item.weekDayNum === index);

            result.push({
                weekDayNum: index,
                timeStart: (scheduleItem) ? scheduleItem.timeStart : null,
                timeEnd: (scheduleItem) ? scheduleItem.timeEnd : null,
            });
        }

        return result;
    }, [groupScheduleReader, schedule]);

    const prepareRowItem = useCallback((weekDayNum: number, timeStart: string | null, timeEnd: string | null) => {
        return <Column key={weekDayNum} className={classNames(!timeStart && 'disabled')}>
            <DayNameWrapper>
                <WeekDayNameByNumber weekDayNumber={weekDayNum} nameType={WeekDayType.SHORT}/>
            </DayNameWrapper>
            {
                (timeStart && timeEnd)
                && <>
                    <TimeWrapper>
                        {timeStart}
                    </TimeWrapper>
                    <TimeWrapper>
                        {timeEnd}
                    </TimeWrapper>
                </>
            }
            {
                (!timeStart)
                && <EmptyDateWrapper>
                    <CloseCross/>
                </EmptyDateWrapper>
            }
        </Column>
    }, []);

    if (scheduleItems.length === 0) {
        return <RegularText><Trans>Расписание не задано</Trans></RegularText>;
    }

    return <Wrapper className={classNames(className)}>
        {
            scheduleItems.map((item) => {
                return prepareRowItem(
                    item.weekDayNum,
                    item.timeStart,
                    item.timeEnd
                )
            })
        }
    </Wrapper>
}