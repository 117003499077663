import {IsBoolean, IsNotEmpty} from 'class-validator';
import {DtoSlideItemBase} from "../Common/DtoSlideItemBase";

/**
 * DTO материалов урока (не домашнего задания урока)
 */
export class DtoLessonMaterialItem extends DtoSlideItemBase {
    @IsNotEmpty()
    @IsBoolean()
    hiddenForStudent: boolean;
}
