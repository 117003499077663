import React, {forwardRef, useContext, useImperativeHandle, useMemo} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {ReactComponent as EyeClosedIcon} from "../../../Ui/Svg/EyeClosed24.svg";
import {ElementName} from "../../../SlidePlayerEditorCommonParts/ElementName";
import {ElementTypeEnum} from "../../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {NoticeBlockTextCss} from "../../../Ui/Elements/NoticeBlock";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {
    ElementWrapper,
    NoticeBlockColored,
    NoticeBlockTitleIcon,
    NoticeBlockTitleStyled,
    NoticeBlockTitleText
} from "../../../SlidePlayerEditorCommonParts/components/TeacherAlsoDiscuss";
import styled from "styled-components";
import {ListItem} from "../../components/RichTextReader/elements/ListItem";
import {DivAsParagraph} from "../../components/RichTextReader/elements/DivAsParagraph";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {container} from "tsyringe";
import {
    ISlidePlayerPatternHelper
} from "../../../../../services/slide-player-id-pattern-helper/ISlidePlayerPatternHelper";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {SlidePlayerIdEnum} from "../../../../../enums/SlidePlayerIdEnum";

export const TeacherAlsoDiscussTextComponent = styled(DivAsParagraph)`
  ${NoticeBlockTextCss};
`;

export const TeacherAlsoDiscussLiComponent = styled(ListItem)`
  ${NoticeBlockTextCss};
`;

interface TeacherAlsoDiscussProps extends IElementProps<CustomElement[]> {
}

interface TeacherAlsoDiscussRefMethods extends IElementRefMethods {
}


export const TeacherAlsoDiscuss = forwardRef<TeacherAlsoDiscussRefMethods, TeacherAlsoDiscussProps>(
    (props, ref) => {

        const {elementData} = props;

        const playerContext = useContext<IPlayerContext>(PlayerContext);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const elementEnabled = useMemo<boolean>(() => {
            const slidePlayerPatternHelper = container.resolve<ISlidePlayerPatternHelper>(
                DiTokens.SLIDE_PLAYER_PATTERN_HELPER
            );

            const playerIdPatternData = slidePlayerPatternHelper.findPlayerIdPattern(playerContext.playerId);

            if (playerIdPatternData === null) {
                return true;
            }

            if (
                (playerIdPatternData.pattern === SlidePlayerIdEnum.STUDENT_SLIDE_ON_HOMEWORK)
                || (playerIdPatternData.pattern === SlidePlayerIdEnum.STUDENT_SLIDE_ON_LESSON)
            ) {
                return false;
            }

            return true;
        }, [playerContext.playerId]);

        if (!elementEnabled) {
            return null;
        }

        return <ElementWrapper>
            <NoticeBlockColored>
                <>
                    <NoticeBlockTitleStyled>
                        <NoticeBlockTitleText>
                            <ElementName elementType={ElementTypeEnum.TEACHER_ALSO_DISCUSS}/>
                        </NoticeBlockTitleText>
                        <NoticeBlockTitleIcon>
                            <EyeClosedIcon/>
                        </NoticeBlockTitleIcon>
                    </NoticeBlockTitleStyled>
                    <RichTextReader elements={elementData.data}
                                    paragraphComponent={TeacherAlsoDiscussTextComponent}
                                    liComponent={TeacherAlsoDiscussLiComponent}
                    />
                </>
            </NoticeBlockColored>
        </ElementWrapper>;
    }
);

TeacherAlsoDiscuss.displayName = 'TeacherAlsoDiscuss';