import {FeaturesEnum} from "./FeaturesEnum";
import {IFeatureToggle} from "./IFeatureToggle";
import {UserProfileType} from "../../store/user/type";

export class FeatureToggle implements IFeatureToggle {
    allFeatureUserId: string;
    currentUserId: string | null;
    currentIsDev: boolean;
    userProfileType: UserProfileType | null;
    teacherUserSchoolId: string | null;

    constructor(allFeatureUserId: string, currentIsDev: boolean) {
        this.allFeatureUserId = allFeatureUserId;
        this.currentIsDev = currentIsDev;
        this.currentUserId = null;
        this.userProfileType = null;
        this.teacherUserSchoolId = null;
    }

    /**
     * @inheritDoc
     */
    featureIsAvailable(feature: FeaturesEnum): boolean {
        if (feature === FeaturesEnum.SELF_STUDY_TRACKS_NAV_LINK) {
            return (this.teacherUserSchoolId === '8a6fbfa5-0d1e-402d-b53b-de62b6db0a0b')
                || (this.teacherUserSchoolId === 'f0ce1161-c336-41e5-ad9f-9bf353e8e979')
                || (this.currentUserId === this.allFeatureUserId);
        }

        return (this.currentUserId === this.allFeatureUserId)
            || (this.currentUserId === '979b37eb-abca-4808-bc94-2e3ce8752e84')
    }

    /**
     * @inheritDoc
     */
    setCurrentUserId(userId: string): void {
        this.currentUserId = userId;
    }

    setCurrentUserProfileType(userProfileType: UserProfileType): void {
        this.userProfileType = userProfileType;
    }

    setTeacherSchoolId(schoolId: string): void {
        this.teacherUserSchoolId = schoolId;
    }
}
