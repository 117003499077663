import React from "react";
import {Trans} from "@lingui/macro";
import {RegularText} from "../../../../../styles/global-elements";

export const NewAgreementCostSectionNotice: React.FC = () => {
    return <RegularText>
        <Trans>У каждого вашего ученика в системе есть свой счёт, который можно пополнять. С этого счёта
            автоматически списывается стоимость каждого проведённого урока.</Trans>
        <br/>
        <br/>
        <Trans>Как пополнять счёт ученика:</Trans>
        <br/>
        <ul>
            <li>
                <Trans>Можно подключить приём платежей онлайн</Trans>
            </li>
            <li>
                <Trans>Вы можете принимать платежи самостоятельно любым способом и просто фиксировать данные в
                    системе</Trans>
            </li>
        </ul>
    </RegularText>
}