import {put, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {SlideWorkDataActionTypes} from "../../store/slidesWorkData/type";
import {setSlideWorkDataAwardValues, setSlideWorkDataEmptyObject} from "../../store/slidesWorkData/actions";
import {RoutesHelper} from "../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../enums/SlidePlayerIdEnum";
import {
    DtoStudentLessonWorkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoStudentLessonWorkDataItem";

export function* watchProcessStudentLessonWorkDataItemsList() {
    yield takeEvery<WorkerPayloadType<{lessonId: string, items: DtoStudentLessonWorkDataItem[]}>>(
        SlideWorkDataActionTypes.PROCESS_STUDENT_LESSON_WORK_DATA_ITEMS_LIST,
        processStudentLessonWorkDataItemsList
    );
}

function* processStudentLessonWorkDataItemsList(data: WorkerPayloadType<{lessonId: string, items: DtoStudentLessonWorkDataItem[]}>) {
    const lessonId = data.payload.lessonId;
    const items = data.payload.items.length;

    for (let index = 0; index < items; index++) {
        const studentLessonWorkDataItem = data.payload.items[index];

        const playerId = RoutesHelper.replaceParams(
            SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_LESSON,
            [
                {
                    key: 'studentUserId',
                    value: studentLessonWorkDataItem.userId
                },
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );

        const slidesCount = studentLessonWorkDataItem.slides.list.length;

        for (let slideIndex = 0; slideIndex < slidesCount; slideIndex++) {
            yield put(setSlideWorkDataEmptyObject({
                playerId: playerId,
                slideId: studentLessonWorkDataItem.slides.list[slideIndex].tmSlideId,
                slideContentVersionNum: studentLessonWorkDataItem.slides.list[slideIndex].tmSlideVersionNum,
                value: null
            }));

            yield put(setSlideWorkDataAwardValues({
                playerId: playerId,
                slideId: studentLessonWorkDataItem.slides.list[slideIndex].tmSlideId,
                value: {
                    totalAward: studentLessonWorkDataItem.slides.list[slideIndex].totalAward,
                    totalMissedAward: studentLessonWorkDataItem.slides.list[slideIndex].totalMissedAward
                }
            }));
        }
    }
}
