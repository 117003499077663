import React, {useContext, useMemo} from 'react';
import styled from "styled-components";
import {DefaultHeaderWrapper} from "../common-styles";
import classNames from "classnames";
import {t, Trans} from "@lingui/macro";
import {EditSlideBtn} from "./EditSlideBtn";
import {EditLayoutSettingsBtn} from "./EditLayoutSettingsBtn";
import {ReactComponent as SaveSvg} from "../../../../../components/Ui/Svg/Save.svg"
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {SaveState} from "./index";
import {EditorContext, IEditorContext} from "../EditorContext";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {EditorDataDtoMapper} from "../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {cloneDeep} from "lodash";
import {PlayLessonForTestBtn} from "./PlayLessonForTestBtn";
import {ExercisesCountInSlideItem} from "../../../../../components/SlideEditor";
import {IFeatureToggle} from "../../../../../../components/FeatureToggle/IFeatureToggle";
import {FeaturesEnum} from "../../../../../../components/FeatureToggle/FeaturesEnum";


const HeaderWrapper = styled(DefaultHeaderWrapper)`
    justify-content: space-between;
    padding: 0 5px;
`;

const SlideTitle = styled.div`
    font-weight: bold;
    color: ${({theme}) => theme.colors.textPrimary};
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.no-slide {
        opacity: 0.5;
    }
`;

const TitleIconWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TitleIcon = styled.div`
    opacity: 0.5;
    cursor: pointer;

    transition: opacity 0.3s ease;

    :hover {
        opacity: 1;
    }
`;

export interface HeaderProps {
    needSave: boolean;
    setContentSaveState: React.Dispatch<React.SetStateAction<SaveState>>;
    getExercisesCount: () => ExercisesCountInSlideItem[];
}

export const Header: React.FC<HeaderProps> = (props) => {
    const {needSave, setContentSaveState} = props;

    const editorContext = useContext<IEditorContext>(EditorContext);

    const featureToggle = useMemo(() => container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE), []);
    const layoutSettingsBtnAllowed = useMemo(() => featureToggle.featureIsAvailable(FeaturesEnum.LAYOUT_CONFIG_EDIT), [featureToggle]);

    const {
        slides,
        selectedSlideId,
        selectedSlide,
        slidesSavedContent,
        setSlidesSavedContent,
        slidesDraftContent,
        setSlidesDraftContent
    } = editorContext;

    const sessionToken = useSelector(sessionTokenSelector);

    const saveBtnClick = () => {
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        if (
            (!slides) || (!selectedSlideId)
            || (!slidesDraftContent[selectedSlideId]) || (!sessionToken)
            || (!selectedSlide)
        ) {
            return;
        }

        setContentSaveState(SaveState.IN_PROCESS);

        // 1. Выполняем запрос на сохранение информации
        const newCurrentSlideItem = {
            ...selectedSlide
        };

        let totalSlideExercisesCount = 0;

        const contentForSave = slidesDraftContent[newCurrentSlideItem.id];
        const countsInItems = props.getExercisesCount();

        contentForSave.items.forEach((slideItem, index) => {
            const itemWithCount = countsInItems.find((item) => item.slideItemId === slideItem.id);

            if (!itemWithCount) {
                return;
            }

            totalSlideExercisesCount += itemWithCount.exercisesCount;
            contentForSave.items[index].exercisesCount = itemWithCount.exercisesCount;
        });

        httpApiClient.tmSetSlideContent(
            sessionToken,
            newCurrentSlideItem.id,
            EditorDataDtoMapper.editorDataToDto(contentForSave),
            totalSlideExercisesCount
        )
            .then((value) => {
                // 2. Загрузим обновлённые данные, чтобы они далее фигурировали как initialData.
                const newSlidesSavedContent = cloneDeep(slidesSavedContent);

                newSlidesSavedContent[selectedSlideId] = EditorDataDtoMapper.dtoToEditorData(value.data.content);

                setSlidesSavedContent(newSlidesSavedContent);

                // 3. Удаляем черновые данные, чтобы затем загрузить информацию снова из content
                const newSlidesDraftContent = cloneDeep(slidesDraftContent);

                delete newSlidesDraftContent[selectedSlideId];

                setSlidesDraftContent(newSlidesDraftContent);

                // 4. Загружаем информацию снова через useEffect, когда лоадер остановится
                setContentSaveState(SaveState.SUCCESS);
            })
            .catch((err) => {
                setContentSaveState(SaveState.ERROR);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось сохранить контент`
                );
            });
    }

    return <HeaderWrapper>
        <SlideTitle className={classNames((!selectedSlide) && "no-slide")}>
            {
                (selectedSlide) ? selectedSlide.name : <Trans>Не выбран слайд</Trans>
            }
        </SlideTitle>
        {(selectedSlide) &&
            <TitleIconWrapper>
                {
                    (needSave) && <TitleIcon>
                        <SaveSvg onClick={saveBtnClick}/>
                    </TitleIcon>
                }
                <TitleIcon>
                    <PlayLessonForTestBtn/>
                </TitleIcon>
                <TitleIcon>
                    <EditSlideBtn/>
                </TitleIcon>
                {
                    (layoutSettingsBtnAllowed)
                    && <TitleIcon>
                        <EditLayoutSettingsBtn/>
                    </TitleIcon>
                }
            </TitleIconWrapper>
        }
    </HeaderWrapper>;
}