import {IsBoolean, IsNumber, IsOptional, IsString} from 'class-validator';

/**
 * DTO параметров layout контента слайда
 */
export class DtoTmSlideContentLayoutSettings {
    @IsOptional()
    @IsString()
    backgroundFileId: string | null;

    @IsOptional()
    @IsNumber()
    backgroundMode: number | null;

    @IsBoolean()
    hideLeftNavigation: boolean;

    @IsOptional()
    @IsString()
    nextButtonLabel: string | null;

    @IsString()
    themeName: string;

    @IsBoolean()
    transparentHeader: boolean;
}
