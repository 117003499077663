import {DeviceOrientatonTypeEnum} from "../../components/DeviceOrientation/DeviceOrientatonTypeEnum";

export enum LessonRoomStateTypes {
    NOT_INIT,
    CONNECTING,
    ACTIVE
}

export enum LessonRoomActionTypes {
    SET_LESSON_ROOM_STATE = '@@lessonRoom/SET_LESSON_ROOM_STATE',
    SET_LESSON_ROOM_ID = '@@lessonRoom/SET_LESSON_ROOM_ID',
    SET_ROOM_CONNECTION_PARAMS = '@@lessonRoom/SET_ROOM_CONNECTION_PARAMS',

    LOAD_ROOM_MEMBERS_LIST = '@@lessonRoom/LOAD_ROOM_MEMBERS_LIST',
    HANDLE_ROOM_MEMBERS_LIST_RESULT = '@@lessonRoom/HANDLE_ROOM_MEMBERS_LIST_RESULT',

    HANDLE_SE_MEMBER_ENTERED = '@@lessonRoom/HANDLE_SE_MEMBER_ENTERED',
    HANDLE_SE_MEMBER_LEFT = '@@lessonRoom/HANDLE_SE_MEMBER_LEFT',
    HANDLE_SE_MEMBER_OPENED_SLIDE_CHANGED = '@@lessonRoom/HANDLE_SE_MEMBER_OPENED_SLIDE_CHANGED',
    HANDLE_SE_STUDENT_NEED_OPEN_SLIDE = '@@lessonRoom/HANDLE_SE_STUDENT_NEED_OPEN_SLIDE',
    HANDLE_SE_NEED_SELECT_ROOM_MEMBER_VIDEO = '@@lessonRoom/HANDLE_SE_NEED_SELECT_ROOM_MEMBER_VIDEO',
    HANDLE_SE_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE = '@@lessonRoom/HANDLE_SE_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE',

    HANDLE_SE_MEMBER_PUBLIC_VIDEO_PIN = '@@lessonRoom/HANDLE_SE_MEMBER_PUBLIC_VIDEO_PIN',
    HANDLE_SE_MEMBER_PUBLIC_FULL_SCREEN_MODE = '@@lessonRoom/HANDLE_SE_MEMBER_PUBLIC_FULL_SCREEN_MODE',
    HANDLE_SE_MEMBER_PUBLIC_SCREEN_ORIENTATION = '@@lessonRoom/HANDLE_SE_MEMBER_PUBLIC_SCREEN_ORIENTATION',

    // Это не обработка входящего сообщения. Это обработка изменения ориентации собственного экрана.
    PROCESS_SCREEN_ORIENTATION_ON_CHANGE = '@@lessonRoom/PROCESS_SCREEN_ORIENTATION_ON_CHANGE',

    SET_ROOM_MEMBERS_LIST_LOADING_STATE = '@@lessonRoom/SET_ROOM_MEMBERS_LIST_LOADING_STATE',
    SET_ITEMS_TO_MEMBERS_LIST = '@@lessonRoom/ADD_ITEMS_TO_MEMBERS_LIST',
    SET_MEMBER_PINNED_USER = '@@lessonRoom/SET_MEMBER_PINNED_USER',
    SET_MEMBER_IS_IN_FULL_SCREEN = '@@lessonRoom/SET_MEMBER_IS_IN_FULL_SCREEN',
    SET_MEMBER_SCREEN_ORIENTATION = '@@lessonRoom/SET_MEMBER_SCREEN_ORIENTATION',
    SET_MEMBER_OPENED_SLIDE_ID = '@@lessonRoom/SET_MEMBER_OPENED_SLIDE_ID',
    REMOVE_ITEM_FROM_MEMBERS_LIST = '@@lessonRoom/DELETE_ITEM_FROM_MEMBERS_LIST',
    CLEAR_MEMBERS_LIST = '@@lessonRoom/DELETE_ITEM_FROM_MEMBERS_LIST',
    CLEAR_ROOM_STATE = '@@lessonRoom/CLEAR_ROOM_STATE',

    HANDLE_LEAVE_FROM_ROOM_VIA_ROUTER = '@@lessonRoom/HANDLE_LEAVE_FROM_ROOM_VIA_ROUTER'
}

export enum LessonRoomUserTypeEnum {
    STUDENT = 'student',
    TEACHER = 'teacher'
}

export enum MembersListLoadingStateEnum {
    NOT_INIT,
    LOADING_NOW,
    SUCCESS,
    ERROR
}

export interface RoomConnectionParams {
    turnServerEntrypoint: string;
    turnServerTlsEntrypoint: string;
    turnServerLogin: string;
    turnServerPassword: string;
    janusWsApiEntrypoint: string;
    janusApiKey: string;
    janusRoomId: string;
    janusJoinKey: string;
    expectedNumberOfStudents: number;
}

export interface LessonRoomMemberDetailsForTeacher {
    stApiUserId: number;
    openedSlideId: string | null;
}

export interface LessonRoomMember {
    userId: string;
    userName: string;
    userAvatarFileId: string | null;
    userType: LessonRoomUserTypeEnum;
    screenOrientation: DeviceOrientatonTypeEnum | null;
    pinnedUser: string | null;
    inFullScreen: boolean;
    detailsForTeacher: LessonRoomMemberDetailsForTeacher | null;
}

export interface LessonRoomState {
    state: LessonRoomStateTypes,
    roomId: string | null,
    roomConnectionParams: RoomConnectionParams | null,
    members: LessonRoomMember[],
    membersListLoadingState: MembersListLoadingStateEnum
}
