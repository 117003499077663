import React, {CSSProperties} from 'react';
import styled, {css} from "styled-components";
import classNames from "classnames";
import {BlockWithBorder, RegularFontSize} from "../../../styles/global-elements";

const NoticeBlockWrapper = styled(BlockWithBorder)`
  margin: 10px 0;
  padding: 19px 24px;
  border: none;
  background-color: ${({theme}) => theme.colors.coloredNoticeBackground};

  @media (${({theme}) => theme.media.small}) {
    padding: 27px 32px;
  }
`;

export const NoticeBlockTitleCss = css`
  ${RegularFontSize};
  font-weight: bold;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textPrimary};
  margin-bottom: 7px;
`;

export const NoticeBlockTitle = styled.p`
  ${NoticeBlockTitleCss};
`;

export const NoticeBlockTitleAsDiv = styled.div`
  ${NoticeBlockTitleCss};
`;

export const NoticeBlockTextCss = css`
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.colors.textSecondary};
  margin-bottom: 0;

  @media (${({theme}) => theme.media.small}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const NoticeBlockText = styled.p`
  ${NoticeBlockTextCss};
`;

interface NoticeBlockProps {
    children: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}

export const NoticeBlock: React.FC<NoticeBlockProps> = (props) => {
    return <NoticeBlockWrapper className={classNames(props.className)} style={props.style}>
        {props.children}
    </NoticeBlockWrapper>;
}