import {call, select, takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {IDeviceDetector} from "../../components/DeviceDetector/IDeviceDetector";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {sessionTokenSelector} from "../../store/app/selector";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {NoConnection} from "../../components/HttpApiClient/Exception/NoConnection";

/**
 * Сага отвечает за отправку информации об устройстве
 */
export function* watchSendDeviceInfo() {
    yield takeEvery(
        AppActionTypes.SEND_DEVICE_INFO,
        sendDeviceInfo
    );
}

function* sendDeviceInfo() {
    const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);
    const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const sessionToken = (yield select(sessionTokenSelector)) as string | null;

    if (!sessionToken) {
        logger.warning(
            LoggerSectionsEnum.SAVE_DEVICE_INFO,
            'Error on save device info: not found session token'
        );

        return;
    }

    const model = deviceDetector.collectForApi();

    yield call(async () => {
        try {
            await apiClient.saveStudentDeviceInfo(sessionToken, model)
        } catch (error) {
            if (error instanceof NoConnection) {
                return;
            }

            logger.warning(
                LoggerSectionsEnum.SAVE_DEVICE_INFO,
                'Error on save device info: ',
                error
            );
        }
    });
}
