import {DtoTmSlideContent} from "../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContent";
import {ElementTypeEnum} from "../views/components/SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ElementData} from "../views/components/SlidePlayerEditorCommonParts/components/Image/Common";
import {DialogBubbleType} from "../views/components/SlidePlayer/elements/DialogBubble";
import {UserFileFormatsEnum} from "../enums/UserFileEnums";

interface FileFromSlide {
    typeForPrefetch: UserFileFormatsEnum;
    fileId: string;
}

export class FileHelper {
    static getFileIdsForPrefetchFromSlideContent(slideContent: DtoTmSlideContent[]): FileFromSlide[] {
        const list: FileFromSlide[] = [];

        slideContent.forEach((item) => {
            if (item.layoutSettings && item.layoutSettings.backgroundFileId) {
                list.push({
                    fileId: item.layoutSettings.backgroundFileId,
                    typeForPrefetch: UserFileFormatsEnum.TM_SLIDE_BACKGROUND_PICTURE_ORIGINAL
                });
            }

            item.items.forEach((slideItem) => {
                if (slideItem.type === ElementTypeEnum.MEDIA_PICTURE) {
                    if (slideItem.data) {
                        const data = JSON.parse(slideItem.data) as ElementData;

                        if (data.imageId) {
                            list.push({
                                fileId: data.imageId,
                                typeForPrefetch: UserFileFormatsEnum.TM_SLIDE_PICTURE_ORIGINAL
                            });
                        }
                    }

                    return
                }

                if (slideItem.type === ElementTypeEnum.DIALOG_BUBBLE) {
                    if (slideItem.data) {
                        const data = JSON.parse(slideItem.data) as DialogBubbleType;

                        if (data.avatarFileId) {
                            list.push({
                                fileId: data.avatarFileId,
                                typeForPrefetch: UserFileFormatsEnum.TM_SLIDE_BUBBLE_ELEMENT_AVATAR_ORIGINAL
                            });
                        }

                        if (data.soundFileId) {
                            list.push({
                                fileId: data.soundFileId,
                                typeForPrefetch: UserFileFormatsEnum.AUDIO_MP3_best
                            });
                        }
                    }
                }
            });
        });

        return list;
    }

    /**
     * Добавить тег preload для старта предварительной загрузки файла
     *
     * @param fileUrl
     * @param format
     */
    static addForPrefetch(fileUrl: string, format: UserFileFormatsEnum): void {
        const link = document.createElement('link');

        let browserFileTypeForFormat: string | null = null;

        switch (format) {
            case UserFileFormatsEnum.AUDIO_MP3_best:{
                browserFileTypeForFormat = "audio";
                break;
            }
            case UserFileFormatsEnum.TM_SLIDE_PICTURE_ORIGINAL:
            case UserFileFormatsEnum.TM_SLIDE_BACKGROUND_PICTURE_ORIGINAL:
            case UserFileFormatsEnum.TM_SLIDE_BUBBLE_ELEMENT_AVATAR_ORIGINAL: {
                browserFileTypeForFormat = "image";
                break;
            }
        }

        link.rel = "prefetch";
        link.href = fileUrl;

        if (browserFileTypeForFormat) {
            link.as = browserFileTypeForFormat;
        }

        document.head.appendChild(link);
    }
}