import React, {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import {EditorDataItem} from "../../SlidePlayerEditorCommonParts/EditorData";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ItemPropsInterface, ItemRefMethodsInterface} from "../components/ItemPropsInterface";
import {TestDiv} from "./TestDiv";
import {Text} from "./Text";
import {ButtonState} from "../components/Toolbar";
import {ToolbarButtonEnum} from "../components/Toolbar/ToolbarButtonEnum";
import {ExerciseFillGapsCombobox} from "./ExerciseFillGapsCombobox";
import {Title} from "./Title";
import {TeacherNotes} from "./TeacherNotes";
import {TeacherCanSay} from "./TeacherCanSay";
import {TeacherAlsoDiscuss} from "./TeacherAlsoDiscuss";
import {UnfoldBlock} from "./UnfoldBlock";
import {ExerciseFillGapsText} from "./ExerciseFillGapsText";
import {ExerciseCorrectExistInInput} from "./ExerciseCorrectExistInInput";
import {ExerciseFillGapsDragDrop} from "./ExerciseFillGapsDragDrop";
import {ExerciseRadio} from "./ExerciseRadio";
import {ExerciseCheckbox} from "./ExerciseCheckbox";
import {ExerciseMatch} from "./ExerciseMatch";
import {Image} from "./Image";
import {VideoYoutube} from "./VideoYoutube";
import {Video} from "./Video";
import {Audio} from "./Audio";
import {DialogBlock} from "./DialogBubble";

interface ElementClassProps {
    element: EditorDataItem<any>;
    onItemDataChange: (newValue: string | Object) => void;
    setToolbarConfigById: (elementId: string, buttonState: ButtonState[]) => void;
}

export interface ElementClassRefMethods {
    toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => void;
    getExercisesCount: () => number;
}

export const ElementClass = forwardRef<ElementClassRefMethods, ElementClassProps>((props, ref) => {
    const {element, onItemDataChange, setToolbarConfigById} = props;

    const elemRef = useRef<ItemRefMethodsInterface>(null);

    const Elem = useMemo((): React.ForwardRefExoticComponent<ItemPropsInterface<any> & React.RefAttributes<ItemRefMethodsInterface>> => {
        switch (element.type) {
            case ElementTypeEnum.TEACHER_NOTES: {
                return TeacherNotes;
            }
            case ElementTypeEnum.TEACHER_CAN_SAY: {
                return TeacherCanSay;
            }
            case ElementTypeEnum.TEACHER_ALSO_DISCUSS: {
                return TeacherAlsoDiscuss;
            }
            case ElementTypeEnum.TITLE: {
                return Title;
            }
            case ElementTypeEnum.TEXT: {
                return Text;
            }
            case ElementTypeEnum.UNFOLD_BLOCK: {
                return UnfoldBlock;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT: {
                return ExerciseFillGapsText;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX: {
                return ExerciseFillGapsCombobox;
            }
            case ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT: {
                return ExerciseCorrectExistInInput;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP: {
                return ExerciseFillGapsDragDrop;
            }
            case ElementTypeEnum.EXERCISES_RADIO: {
                return ExerciseRadio;
            }
            case ElementTypeEnum.EXERCISES_CHECKBOX: {
                return ExerciseCheckbox;
            }
            case ElementTypeEnum.EXERCISES_MATCH: {
                return ExerciseMatch;
            }
            case ElementTypeEnum.MEDIA_PICTURE: {
                return Image;
            }
            case ElementTypeEnum.MEDIA_YOUTUBE: {
                return VideoYoutube;
            }
            case ElementTypeEnum.MEDIA_VIDEO: {
                return Video;
            }
            case ElementTypeEnum.MEDIA_AUDIO: {
                return Audio;
            }
            case ElementTypeEnum.DIALOG_BUBBLE: {
                return DialogBlock;
            }
            default: {
                return TestDiv;
            }
        }
    }, [element]);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
            elemRef.current?.toolbarItemOnToggle(buttonType, newValue);
        },
        getExercisesCount: () => {
            if (!elemRef.current) {
                return 0;
            }

            return elemRef.current?.getExercisesCount();
        }
    }));

    return <Elem initialData={element.data} id={element.id}
                 ref={elemRef}
                 onChange={onItemDataChange}
                 setToolbarConfigById={setToolbarConfigById}
    />;
})