import {IsEnum, IsNotEmpty, IsNumber, IsString} from "class-validator";

export enum QueueItemTargetEnum {
    STUDENT_IN_LESSON = 'student_in_lesson',
    STUDENT_HOMEWORK = 'student_homework',

    TEACHER_IN_LESSON = 'teacher_in_lesson',
    TEACHER_HOMEWORK = 'teacher_homework',

    SELF_STUDY_TRACK_SLIDE = 'self_study_track_slide'
}

export enum QueueItemDataTypeEnum {
    VALUE = 'value',
    ANSWER = 'answer',
    ADDITIONAL_DATA = 'additional_data',
    OVERRIDDEN_PARAMS = 'overridden_params'
}

export class QueueItem {
    @IsNotEmpty()
    @IsNumber()
    itemNumber: number;

    @IsNotEmpty()
    @IsEnum(QueueItemTargetEnum)
    targetType: QueueItemTargetEnum;

    @IsNotEmpty()
    @IsString()
    targetUserId: string;

    @IsNotEmpty()
    @IsString()
    lessonId: string;

    @IsNotEmpty()
    @IsString()
    slideId: string;

    @IsNotEmpty()
    @IsString()
    slideItemId: string;

    @IsString()
    exerciseId: string | null;

    @IsNotEmpty()
    @IsEnum(QueueItemDataTypeEnum)
    dataType: QueueItemDataTypeEnum;

    payload: any;

    contextData: any;
}
