import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../store/app/selector";
import {AsyncSelect, ItemType} from "../../../../components/Ui/Elements/Selectors/AsyncSelect";
import {FormItem, FormUseWatch} from "../../../../components/Ui/Elements/Form";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {FormInstance} from "antd";


const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

export enum FormFieldNamesEnum {
    COURSE = 'course',
    TRAINING_PROGRAM = 'training_program'
}

interface DisciplineAndLevelFormProps {
    form: FormInstance;
    courseIdInitValue: number | null;
    courseNameInitValue: string;
    trainingProgramIdInitValue: number | null;
    trainingProgramNameInitValue: string;
}

export const DisciplineAndLevelForm: React.FC<DisciplineAndLevelFormProps> =
    (
        {
            form,
            courseIdInitValue,
            courseNameInitValue,
            trainingProgramIdInitValue,
            trainingProgramNameInitValue
        }
    ) => {

        const stToken = useSelector(stTokenSelector);
        const tpInputRef = useRef<AsyncSelect>(null);

        const stApiClient = useMemo(() => container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT), []);

        const [coursesSearchAbortController, setCoursesSearchAbortController] = useState<AbortController | null>(null);
        const [tpSearchAbortController, setTpSearchAbortController] = useState<AbortController | null>(null);
        const [lastSelectedCourseId, setLastSelectedCourseId] = useState<number | null>(courseIdInitValue);

        const formCourseValue = FormUseWatch(FormFieldNamesEnum.COURSE, form);

        useEffect(() => {
            const formCourseValue: ItemType | null = form.getFieldValue(FormFieldNamesEnum.COURSE);

            let id = (formCourseValue && formCourseValue.id) ? parseInt(formCourseValue.id) : null;

            if (lastSelectedCourseId === id) {
                return;
            }

            setLastSelectedCourseId(id);

            form.setFieldValue(FormFieldNamesEnum.TRAINING_PROGRAM, null);
            tpInputRef.current?.startSearchItems(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [form, formCourseValue]);

        const trainingProgramsSearch = useCallback((searchingString: string): Promise<ItemType[]> => {
            return new Promise<ItemType[]>((resolve) => {
                if (stToken === null) {
                    resolve([]);

                    return;
                }

                if (tpSearchAbortController !== null) {
                    tpSearchAbortController.abort();
                }

                const selectedCourse: ItemType = form.getFieldValue(FormFieldNamesEnum.COURSE);

                if (!selectedCourse || !selectedCourse.id) {
                    setTpSearchAbortController(null);
                    resolve([]);

                    return;
                }

                const courseId = parseInt(selectedCourse.id);

                const newAbortController = new AbortController();

                setTpSearchAbortController(newAbortController);

                stApiClient.trainingProgramsInCourse(stToken, courseId, searchingString, 1, 5, newAbortController)
                    .then((items) => {
                        resolve(items.result.items.map(dtoItem => ({
                            id: dtoItem.id.toString(10),
                            name: dtoItem.name
                        })));
                    })
                    .catch((e) => {
                        if (e instanceof NoConnection) {
                            resolve([]);
                        }

                        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                        logger.error(
                            LoggerSectionsEnum.STONLINE_TRAINING_PRROGRAM_API,
                            `Error on get training programs list: `,
                            e
                        );
                    })
            });
        }, [form, stApiClient, stToken, tpSearchAbortController]);


        const disciplineSearch = useCallback((searchingString: string): Promise<ItemType[]> => {
            return new Promise<ItemType[]>((resolve) => {
                if (stToken === null) {
                    resolve([]);

                    return;
                }

                if (coursesSearchAbortController !== null) {
                    coursesSearchAbortController.abort();
                }

                const newAbortController = new AbortController();

                setCoursesSearchAbortController(newAbortController);

                stApiClient.coursesGetList(stToken, searchingString, 1, 5, newAbortController)
                    .then((items) => {
                        resolve(items.result.items.map(dtoItem => ({
                            id: dtoItem.id.toString(10),
                            name: dtoItem.nominativeName
                        })));
                    })
                    .catch((e) => {
                        if (e instanceof NoConnection) {
                            resolve([]);
                        }

                        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                        logger.error(
                            LoggerSectionsEnum.STONLINE_TRAINING_PRROGRAM_API,
                            `Error on get courses list: `,
                            e
                        );
                    })
            });
        }, [coursesSearchAbortController, stApiClient, stToken]);


        return <FormItemsWrapper>
            <FormItem
                name={FormFieldNamesEnum.COURSE}
                initialValue={{
                    id: courseIdInitValue,
                    name: courseNameInitValue
                }}
                label={<Trans>Курс (например, «Английский»)</Trans>}
                rules={[
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !value.name) {
                                return Promise.reject(t`Необходимо указать название курса`);
                            }

                            return Promise.resolve()
                        }
                    })
                ]}
            >
                <AsyncSelect searchMethod={disciplineSearch}/>
            </FormItem>
            <FormItem
                name={FormFieldNamesEnum.TRAINING_PROGRAM}
                initialValue={{
                    id: trainingProgramIdInitValue,
                    name: trainingProgramNameInitValue
                }}
                label={<Trans>Уровень (например, «Beginner»)</Trans>}
                rules={[
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            if (!value || !value.name) {
                                return Promise.reject(t`Необходимо указать название уровня`);
                            }

                            return Promise.resolve()
                        }
                    })
                ]}
            >
                <AsyncSelect ref={tpInputRef}
                             disableSearch={!lastSelectedCourseId}
                             searchMethod={trainingProgramsSearch}/>
            </FormItem>
        </FormItemsWrapper>
    }