import React, {useContext, useMemo} from "react";
import {AdminViewContext, IAdminViewContext} from "./Context";
import styled from "styled-components";
import {DefaultLayoutWrapper, ValueWidget} from "../../../../components/ValueWidget";
import {MainValue} from "../../../../components/ValueWidget/MainValue";
import {AdditionalValues} from "../../../../components/ValueWidget/AdditionalValues";
import {t, Trans} from "@lingui/macro";
import {AnswerTypeEmojiEnum} from "../../../student/self-study-track/episode-end/EpisodeSurvey/AnswerTypeEnum";
import {RegularFontSize, RegularTextCss} from "../../../../styles/global-elements";

const WidgetsGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-evenly;
    margin-bottom: 30px;

    @media (${({theme}) => theme.media.small}) {
        gap: 24px;
    }

    @media (${({theme}) => theme.media.large}) {
        //justify-content: flex-start;
        justify-content: space-between;
        gap: 10px;
        flex-wrap: nowrap;
    }
`;

const ValueWidgetStyled = styled(ValueWidget)`
    width: auto !important;
    flex-basis: 100%;

    @media (min-width: 600px) {
        flex-basis: 45%;
    }

    @media (${({theme}) => theme.media.large}) {
        flex-basis: calc(25% - 3px);
    }
`;

const SurveyValuesList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const SurveyItem = styled.li`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const SurveyEmoji = styled.span`
    ${RegularFontSize};
`;

const SurveyValue = styled.span`
    ${RegularTextCss};
`;

export const Widgets: React.FC = () => {
    const {summaryResponse} = useContext<IAdminViewContext>(AdminViewContext);

    const registeredInPercent = useMemo<number>(() => {
        return Math.round((summaryResponse.registeredSubscribersCount / summaryResponse.totalSubscribersCount) * 100);
    }, [summaryResponse.registeredSubscribersCount, summaryResponse.totalSubscribersCount]);

    const firstEpisodeCompleteScore = useMemo<number>(() => {
        return Math.round((summaryResponse.firstEpisodeCompletedCount / summaryResponse.firstEpisodeStartedCount) * 100);
    }, [summaryResponse.firstEpisodeCompletedCount, summaryResponse.firstEpisodeStartedCount]);

    return <WidgetsGrid>
        <ValueWidgetStyled>
            <DefaultLayoutWrapper>
                <MainValue><Trans>{summaryResponse.totalSubscribersCount} участников</Trans></MainValue>
                <AdditionalValues values={[
                    t`всего`
                ]}/>
            </DefaultLayoutWrapper>
        </ValueWidgetStyled>

        <ValueWidgetStyled>
            <DefaultLayoutWrapper>
                <MainValue><Trans>{registeredInPercent} %</Trans></MainValue>
                <AdditionalValues values={[
                    t`зарегистрировано`,
                    t`${summaryResponse.registeredSubscribersCount} из ${summaryResponse.totalSubscribersCount}`
                ]}/>
            </DefaultLayoutWrapper>
        </ValueWidgetStyled>

        <ValueWidgetStyled>
            <DefaultLayoutWrapper>
                <MainValue>{summaryResponse.newRegisteredSubscribersCount}</MainValue>
                <AdditionalValues values={[
                    t`новых регистраций`
                ]}/>
            </DefaultLayoutWrapper>
        </ValueWidgetStyled>

        <ValueWidgetStyled>
            <DefaultLayoutWrapper>
                <MainValue>{firstEpisodeCompleteScore} %</MainValue>
                <AdditionalValues values={[
                    t`${summaryResponse.firstEpisodeCompletedCount} из ${summaryResponse.firstEpisodeStartedCount} завершили 1-й эпизод`,
                ]}/>
            </DefaultLayoutWrapper>
        </ValueWidgetStyled>

        <ValueWidgetStyled>
            <DefaultLayoutWrapper>
                <SurveyValuesList>
                    <SurveyItem>
                        <SurveyEmoji>{AnswerTypeEmojiEnum.GOOD}</SurveyEmoji>
                        <SurveyValue>{summaryResponse.surveySummary.goodCount}</SurveyValue>
                    </SurveyItem>
                    <SurveyItem>
                        <SurveyEmoji>{AnswerTypeEmojiEnum.NORMAL}</SurveyEmoji>
                        <SurveyValue>{summaryResponse.surveySummary.normalCount}</SurveyValue>
                    </SurveyItem>
                    <SurveyItem>
                        <SurveyEmoji>{AnswerTypeEmojiEnum.BAD}</SurveyEmoji>
                        <SurveyValue>{summaryResponse.surveySummary.badCount}</SurveyValue>
                    </SurveyItem>
                </SurveyValuesList>
            </DefaultLayoutWrapper>
        </ValueWidgetStyled>
    </WidgetsGrid>
}