import {UserProfileLoadState, UserState} from "./type";

export const initialState: UserState = {
    profileType: null,
    sessionToken: null,
    stToken: null,
    authState: UserProfileLoadState.WAIT_FOR_AUTH,
    profileData: null,
    selectedSchoolId: null,
    selectedUserInSchoolId: null,
    selectedAgreementId: null,
    wsReconnectionToken: null,
    userIsModerator: false,
    support: {
        phone: '79050944407'
    }
};