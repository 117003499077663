import React, {CSSProperties, useContext, useMemo, useRef, useState} from "react";
import {VideoGalleryParticipantProfile} from "../../Types";
import styled, {css} from "styled-components";
import {IVideoGalleryContext, VideoGalleryContext} from "../../VideoGalleryContext";
import {StringHelper} from "../../../../../../../../helpers/StringHelper";
import {ParticipantItem} from "../../../../../Types";
import {ReactComponent as MicOff} from "../../../../../../Ui/Svg/MicOff24v2.svg";
import {ReactComponent as NoSound} from "../../../../../../Ui/Svg/NoSound24.svg";
import useResizeObserver from "@react-hook/resize-observer";
import classNames from "classnames";
import {CommonContext, ICommonContext} from "../../../../../contexts/CommonContext";
import {ReactComponent as FullScreen} from "../../../../../../Ui/Elements/ControlPanel/icons/Expand.svg";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  border-radius: 8px;
  border-style: solid;
  border-width: 3px;
  border-color: rgba(0, 0, 0, 0);
  transition: 0.3s linear border-color;

  &.talking {
    border-color: #1979ff;
  }
`;

const NameWrapper = styled.div`
  max-width: 80%;
  //font-weight: bold;
  position: absolute;
  width: 80%;
  left: 10%;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6), 0 0 2px rgba(0, 0, 0, .3);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: .2px;
  bottom: 6px;
`;

const IconsArea = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const IconWrapperCss = css`
  width: 26px;
  height: 26px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled.div`
  ${IconWrapperCss};
`;

const ButtonsArea = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  ${IconWrapperCss};
  border-radius: 25%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
`;

const MicOffStyled = styled(MicOff)`
  height: 18px;
  width: 18px;
`;

const NoSoundStyled = styled(NoSound)`
  height: 18px;
  width: 18px;
`;

interface ControlsLayerProps {
    profile: VideoGalleryParticipantProfile;
    userConferenceParticipantItem: ParticipantItem | null;
    noSound?: boolean;
    toggleFullScreenBtnOnClick?: () => void;
}

export const ControlsLayer: React.FC<ControlsLayerProps> = (
    {
        profile,
        userConferenceParticipantItem,
        noSound,
        toggleFullScreenBtnOnClick
    }
) => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const context = useContext<IVideoGalleryContext>(VideoGalleryContext);

    const wrapperRef = useRef<HTMLDivElement>(null);

    const [nameFontSize, setNameFontSize] = useState<number>(14);
    const [nameFontWeight, setNameFontWeight] = useState<string>('bold');
    const [nameBottomPosition, setNameBottomPosition] = useState<number>(6);
    const [nameDisplay, setNameDisplay] = useState<boolean>(true);

    useResizeObserver(wrapperRef, (entry) => {
        const smallestSideSize = (entry.contentRect.width > entry.contentRect.height)
            ? entry.contentRect.height
            : entry.contentRect.width;

        if (entry.contentRect.width < 110) {
            setNameDisplay(false);
        } else {
            setNameDisplay(true);
        }

        if (smallestSideSize < 150) {
            setNameFontSize(10);
            setNameFontWeight('normal');
            setNameBottomPosition(0);

            return;
        }

        setNameFontSize(14);
        setNameFontWeight('bold');
        setNameBottomPosition(6);
    });

    const wrapperOnClick = () => {
        if (nameDisplay) {
            // Если окно достаточно большое
            if (context.selectedMemberId === profile.id) {
                context.setSelectedMemberId(null);

                return;
            }

            context.setSelectedMemberId(profile.id);

            return;
        }

        // Если окно очень маленькое - попробуем переключиться на следующего участника

        if (context.totalMembersCount < 2) {
            // Переключаться некуда
            return;
        }

        const currentParticipantIndex = commonContext.conferenceParticipants.findIndex(item => item.id === profile.id);

        if (currentParticipantIndex === -1) {
            return;
        }

        if (currentParticipantIndex < commonContext.conferenceParticipants.length - 1) {
            if (commonContext.conferenceParticipants[currentParticipantIndex + 1] !== undefined) {
                context.setSelectedMemberId(commonContext.conferenceParticipants[currentParticipantIndex + 1].id);
            }
        } else {
            if (commonContext.conferenceParticipants[0] !== undefined) {
                context.setSelectedMemberId(commonContext.conferenceParticipants[0].id);
            }
        }
    }

    const shortName = useMemo<string>(() => {
        return StringHelper.extractFirstAndMiddleName(profile.name);
    }, [profile.name]);

    const micIsOff = useMemo<boolean>(() => {
        return profile.micMuted;
    }, [profile.micMuted]);

    const isTalking = useMemo<boolean>(() => {
        if (!userConferenceParticipantItem) {
            return false;
        }

        return userConferenceParticipantItem.talkingNow;
    }, [userConferenceParticipantItem]);

    const nameWrapperStyle = useMemo<CSSProperties>(() => {
        return {
            fontSize: `${nameFontSize}px`,
            fontWeight: nameFontWeight,
            bottom: `${nameBottomPosition}px`,
            display: (nameDisplay) ? 'block' : 'none'
        };
    }, [nameBottomPosition, nameDisplay, nameFontSize, nameFontWeight]);

    const fullScreenBtnOnClick = (e:React.MouseEvent) => {
        if (toggleFullScreenBtnOnClick) {
            e.stopPropagation();
            toggleFullScreenBtnOnClick();
        }
    }

    return <Wrapper
        ref={wrapperRef}
        onClick={wrapperOnClick}
        className={
            classNames('controls-layer', isTalking && 'talking')
        }>
        <IconsArea>
            {
                (noSound)
                && <IconWrapper>
                <NoSoundStyled/>
              </IconWrapper>
            }
            {
                (micIsOff)
                && <IconWrapper>
                <MicOffStyled/>
              </IconWrapper>
            }
        </IconsArea>
        <NameWrapper
            style={nameWrapperStyle}
        >{shortName}</NameWrapper>

        {
            (toggleFullScreenBtnOnClick)
            && <ButtonsArea>
            <ButtonWrapper onClick={fullScreenBtnOnClick}>
              <FullScreen/>
            </ButtonWrapper>
          </ButtonsArea>
        }
    </Wrapper>
}
