import {useDraggable} from "@dnd-kit/core";
import {Draggable} from "./Draggable";
import {IFillGapsDrapDrop} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsDrapDrop";
import {ShowMode} from "./Exercise";
import {useContext} from "react";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";

interface DraggableItemProps {
    element: IFillGapsDrapDrop;
    handle?: boolean;
    showMode?: ShowMode;
}

export function DraggableItem(props: DraggableItemProps) {
    const {element, handle, showMode} = props;

    const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

    const {isDragging, setNodeRef, listeners} = useDraggable({
        id: element.id,
        disabled: (showMode === ShowMode.CORRECT) || (slideItemWorkContext.readOnly)
    });

    return (
        <Draggable
            dragging={isDragging}
            ref={setNodeRef}
            handle={handle}
            listeners={listeners}
            element={element}
            showMode={showMode}
        />
    );
}
