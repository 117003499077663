import React, {useContext} from "react";
import {IPlayerContext, PlayerContext} from "../../../../../../components/SlidePlayer/PlayerContext";
import {ISubscriptionContext, SubscriptionContext} from "./SubscriptionContext";
import styled from "styled-components";
import {ImageCover} from "../../../../../../components/Ui/Elements/SelfStudyTrackCover/Common/ImageCover";
import {SelfStudyTrackCoverMaskTypeEnum} from "../../../../../../../enums/SelfStudyTrackCoverMaskTypeEnum";
import {PowerCounter} from "../../../../../../components/PowerCounter";

const Wrapper = styled.div`
    border-radius: 25px;
    padding: 24px;
    height: 379px;
    overflow: hidden;
    position: relative;

    @media (${({theme}) => theme.media.small}) {
        padding: 40px 32px;
        height: auto;
        min-height: 240px;
    }

    @media (${({theme}) => theme.media.medium}) {
        padding: 56px 32px;
        min-height: 224px;

        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (${({theme}) => theme.media.large}) {
        padding: 360px 24px 32px 24px;
        width: 344px;
        min-height: 528px;
        height: 100%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (${({theme}) => theme.media.small}) {
        max-width: 240px;
    }

    @media (${({theme}) => theme.media.medium}) {
        max-width: 380px;
    }
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: ${({theme}) => theme.colors.textPrimary};

    @media (${({theme}) => theme.media.small}) {
        font-size: 20px;
        line-height: 24px;
    }
`;

const Description = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${({theme}) => theme.colors.textSecondary};

    @media (${({theme}) => theme.media.small}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

const ImageWrapper = styled.div`
    aspect-ratio: 1/1;
    width: 348px;
    margin-top: 16px;

    position: absolute;
    right: -78px;

    @media (min-width: 430px) {
        right: -30px;
    }

    @media (min-width: 490px) {
        right: -10px;
    }

    @media (min-width: 560px) {
        right: 0;
    }

    @media (min-width: 610px) {
        right: 10px;
    }

    @media (min-width: 710px) {
        right: 30px;
    }

    @media (${({theme}) => theme.media.small}) {
        margin-top: 0;
        width: 300px;
        right: -56px;
        bottom: -93px;
    }

    @media (${({theme}) => theme.media.medium}) {
        right: 24px;
        bottom: -100px;
    }

    @media (${({theme}) => theme.media.large}) {
        right: unset;
        bottom: unset;
        left: 24px;
        top: 40px;
        width: 296px;
    }
`;

const PowerCounterWrapper = styled.div`
    position: absolute;
    bottom: 16px;
    left: 16px;

    @media (${({theme}) => theme.media.small}) {
        top: 16px;
        right: 16px;
        left: unset;
        bottom: unset;
    }
`;

export const Header: React.FC = () => {
    const {backgroundColor, overviewItem} = useContext<ISubscriptionContext>(SubscriptionContext);

    return <Wrapper style={{backgroundColor: backgroundColor}}>
        <TextWrapper>
            <Title>{overviewItem.name}</Title>
            <Description>{overviewItem.shortDescription}</Description>
        </TextWrapper>

        <ImageWrapper>
            <ImageCover
                maskType={overviewItem.coverMaskId as SelfStudyTrackCoverMaskTypeEnum}
                imageFileId={overviewItem.coverFileId}
            />
        </ImageWrapper>

        <PowerCounterWrapper>
            <PowerCounter value={overviewItem.chargingValue}/>
        </PowerCounterWrapper>
    </Wrapper>
}