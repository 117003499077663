import styled from "styled-components";

export const DefaultHeaderWrapper = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4em;
  align-items: center;
  padding: 0 25px;
`;