import styled from 'styled-components';
import {ReactComponent as BurgerCloseSvg} from "../../../components/Ui/Svg/CloseBurger.svg";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";

export const NavigationContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;

  @media (${({theme}) => theme.media.small}) {
    width: 240px;
  }
`;

export const LogoWrapper = styled.div`
  padding: 21px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
`;

LogoWrapper.displayName = 'LogoWrapper';

export const LogoIcon = styled.div`
  flex-grow: 1;
`;

export const BurgerClose = styled.div`
  display: flex;
  
  @media (${({theme}) => theme.media.large}) {
    display: none;
  }
`;

export const BurgerCloseSvgStyled = styled(BurgerCloseSvg)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
  transition: opacity .3s;
  
  :hover {
    opacity: 1;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Links = styled.ul`
  list-style: none;
  padding: 0;
`;

interface LinkProps {
    $active?: boolean;
    $muted?: boolean;
}

export const LinkWrapper = styled.li`
`;

export const Link = styled(RouterLink)<LinkProps & RouterLinkProps>`
  display: inline-block;
  border-left: 4px solid ${p => (p.$active === true) ? p.theme.colors.textPrimary : "rgba(0, 0, 0, 0)"};
  padding: 8px 20px;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 8px;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: ${p => {
    if (p.$active === true) {
      return 1;
    }

    if (p.$muted) {
      return 0.2;
    }

    return 0.6;
  }};

  cursor: ${p => (p.$muted || p.$active) ? "default" : "pointer"};

  ${p => (p.$muted || p.$active) ? "pointer-events: none" : ""};

  &:hover {
    ${p => (p.$muted) ? "" : "opacity: 1"};
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

