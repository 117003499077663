import {call, put, takeEvery} from 'redux-saga/effects';
import {I18nActionTypes, LocaleEnum, LocaleStateEnum} from "../../store/i18n/type";
import * as u18nActionCreators from "../../store/i18n/actions";
import * as commonPersistedActionCreators from "../../store/commonPersisted/actions";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {ILocaleDictionaryDownloader} from "../../components/LocaleDictionaryDownloader/ILocaleDictionaryDownloader";
import {Ii18nService} from "../../services/i18n/Ii18nService";
import {Messages} from '@lingui/core';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {en, ru} from "make-plural/plurals";

/**
 * Сага отвечает за смену и загрузку локали приложения.
 */
export function* watchSelectLocale() {
    yield takeEvery<WorkerPayloadType<LocaleEnum>>(
        I18nActionTypes.SELECT_LOCALE,
        selectLocale
    );
}

function* selectLocale(data: WorkerPayloadType<LocaleEnum>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    const localeDictionaryDownloader: ILocaleDictionaryDownloader
        = container.resolve<ILocaleDictionaryDownloader>(DiTokens.LOCALE_DICTIONARY_DOWNLOADER);

    const i18nService: Ii18nService = container.resolve<Ii18nService>(DiTokens.I18N_SERVICE);

    yield put(u18nActionCreators.setLocaleState(LocaleStateEnum.DOWNLOADING_DICTIONARY));

    logger.info(LoggerSectionsEnum.LOCALE_SERVICE, 'Start change locale to: ', data.payload);

    try {
        logger.info(LoggerSectionsEnum.LOCALE_SERVICE, 'Start download locale: ', data.payload);

        // Загружаем словарь
        const localeData = (yield call(() => localeDictionaryDownloader.downloadDictionary(data.payload))) as Messages;

        // Применяем plurals
        let plurals: Function = ru as Function;

        switch (data.payload) {
            case LocaleEnum.EN: {
                plurals = en as Function;
            }
        }

        i18nService.loadLocale(data.payload, localeData, plurals);

        i18nService.activateLocale(data.payload);

        document.documentElement.lang = data.payload;

        logger.info(LoggerSectionsEnum.LOCALE_SERVICE, 'Locale successfully changed to: ', data.payload);

        yield put(u18nActionCreators.setLocaleState(LocaleStateEnum.READY));

        yield put(u18nActionCreators.setCurrentLocaleName(data.payload));
        yield put(commonPersistedActionCreators.setLocale(data.payload));
    } catch (e) {
        logger.info(LoggerSectionsEnum.LOCALE_SERVICE, 'Locale change to: ', data.payload, ' failed. Reason: ', e);

        yield put(u18nActionCreators.setLocaleState(LocaleStateEnum.FAILED));
    }
}
