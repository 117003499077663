import styled from "styled-components";
import {RouterLink} from "../../../../components/Ui/Elements/Link";
import {RegularTextCss} from "../../../../styles/global-elements";

export const List = styled.ul`
  list-style: none;
  padding: 0;
`;
export const Item = styled.li``;
export const ItemLink = styled(RouterLink)`
  ${RegularTextCss};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const ItemLinkText = styled.span`
  display: inline-block;
  padding-top: 7px;
`;
