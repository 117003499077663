import React, {useContext, useMemo, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {t, Trans} from "@lingui/macro";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {IClipboardHelper} from "../../../../../../components/ClipboardHelper/IClipboardHelper";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import styled from "styled-components";
import {IStudentPageContext, StudentPageContext} from "../StudentPageContext";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";

const LinkArea = styled.span`
  user-select: all;
  -moz-user-select: all;
  -webkit-user-select: all;
`;

const ButtonStyled = styled(Button)`
    width: 100%;
`;

export const GetAuthLinkBtn: React.FC = () => {
    const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
    const dateHelperService = useMemo(
        () => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), 
        []
    );

    const authLinkModal = useRef<PopupActions>(null);
    
    const linkCreatedAtAsString = useMemo((): string | null => {
        if (!studentPageContext.studentEntranceInfoResponse?.authLinkCreatedAt) {
            return null;
        }
        
        const value = studentPageContext.studentEntranceInfoResponse.authLinkCreatedAt;
        
        const dateValue = dateHelperService.utcDateFromString(value);
        
        return dateHelperService.formatAsDate(dateValue) + ' '+dateHelperService.formatAsTime(dateValue);
    }, [dateHelperService, studentPageContext.studentEntranceInfoResponse]);

    const copyStudentAuthLinkToClipboard = () => {
        return new Promise<void>((resolve, reject) => {
            if (!studentPageContext.studentEntranceInfoResponse) {
                reject();

                return;
            }

            const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

            clipboardHelper.copyText(studentPageContext.studentEntranceInfoResponse.authLink ?? '')
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Ссылка скопирована`,
                        ''
                    );

                    resolve();
                })
                .catch(() => {
                    reject();
                });
        });
    }

    return <div>
        {
            (studentPageContext.studentEntranceInfoResponse)
            && <ButtonStyled btnStyle={BtnStyleEnum.Secondary} onClick={() => authLinkModal.current?.open()}>
                <Trans>Ссылка для быстрого входа</Trans>
            </ButtonStyled>
        }

        {
            (studentPageContext.studentEntranceInfoResponse)
            && <ConfirmDialog
                ref={authLinkModal}
                okText={t`Скопировать`}
                cancelText={t`Закрыть`}
                title={t`Ссылка для входа ученика`}
                okMethod={copyStudentAuthLinkToClipboard}
                text={<>
                    <Trans>Можете передать её ученику или войти в его аккаунт самостоятельно: </Trans>
                    <br/>
                    <br/>
                    <LinkArea>{studentPageContext.studentEntranceInfoResponse.authLink}</LinkArea>
                    <br/>
                    <br/>
                    <Trans>Ссылка обновлена: </Trans>
                    <br/>{linkCreatedAtAsString}
                </>}
            />
        }
    </div>
}
