import * as React from "react";
import {CSSProperties, FormEvent, forwardRef, useCallback, useImperativeHandle, useMemo, useRef} from "react";
import {default as NpmContentEditable} from './OriginalComponent';
import styled from "styled-components";

export const NpmContentEditableStyled = styled(NpmContentEditable)`
  &[placeholder]:empty::before {
    content: attr(placeholder);
    color: ${({theme}) => theme.colors.textTertiary};
    cursor: text;
  }
`;

export interface ContentEditableProps {
    id: string;
    maxLength: number;
    value: string;
    onChange?: (value: string) => void;
    onPressEnter?: (value: string) => void;
    className?: string;
    onFocus?: () => void;
    onBlur?: (value: string) => void;
    placeholder?: string;
    style?: CSSProperties;
    disabled?: boolean;
    customAttributes?: {[id: string]: string};
}

export interface ContentEditableRefMethods {
    currentWidth: () => number | null;
}

export const ContentEditable = forwardRef<ContentEditableRefMethods, ContentEditableProps>(
    (props, ref) => {

        const {
            onFocus,
            onBlur,
            onChange,
            onPressEnter,
            style,
            id,
            disabled,
            placeholder,
            value,
            maxLength,
            customAttributes
        } = props;

        const inputRef = useRef<HTMLElement>(null);

        const onBlurCb = useCallback((event: FormEvent<HTMLDivElement>) => {
            if (onBlur) {
                onBlur(event.currentTarget.innerHTML);
            }
        }, [onBlur]);

        const onFocusCb = useCallback((event: FormEvent<HTMLDivElement>) => {
            if (onFocus) {
                onFocus();
            }
        }, [onFocus]);

        const styles = useMemo((): CSSProperties => {
            const defaultStyles: CSSProperties = {
                minHeight: '1em',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap'
            };

            if (style) {
                return Object.assign(defaultStyles, style);
            }

            return defaultStyles;
        }, [style]);

        const onChangeCb = useCallback((event: FormEvent<HTMLDivElement>) => {
            if (onChange) {
                onChange(
                    event.currentTarget.innerHTML.substring(
                        0,
                        maxLength
                    )
                );
            }
        }, [maxLength, onChange]);

        useImperativeHandle(ref, () => ({
            currentWidth: () => {
                if (!inputRef.current) {
                    return null;
                }

                return inputRef.current.offsetWidth;
            }
        }));

        const onKeyDownCb = useCallback((event: React.KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();

                if (onPressEnter) {
                    onPressEnter(event.currentTarget.innerHTML.substring(
                        0,
                        maxLength
                    ));
                }
            }
        }, [maxLength, onPressEnter]);

        const onClickOnWrapper = () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }

        const onPasteCb = useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
            event.preventDefault();

            let text = event.clipboardData.getData('text/plain');

            document.execCommand("insertText", false, text);
        }, []);

        return <div className={props.className} onClick={onClickOnWrapper}>
            <NpmContentEditableStyled
                tagName='div'
                html={value}
                onPaste={onPasteCb}
                style={styles}
                onChange={onChangeCb}
                id={id}
                disabled={disabled}
                onBlur={onBlurCb}
                placeholder={placeholder}
                onFocus={onFocusCb}
                innerRef={inputRef}
                onKeyDown={onKeyDownCb}
                customAttributes={customAttributes}
            />
        </div>;
    });

ContentEditable.displayName = 'ContentEditable';