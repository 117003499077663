import {call, put, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ActionParamItemAdditionalData, SlideWorkDataActionTypes} from "../../store/slidesWorkData/type";
import {prepareOrFindExerciseObject} from "./common/prepareOrFindExerciseObject";
import {setExerciseAdditionalData} from "../../store/slidesWorkData/actions";
import {DataTypeEnum, pushDataToSaveQueue} from "./common/pushDataToSaveQueue";

export function* watchHandleUserEventChangeAdditionalData() {
    yield takeEvery<WorkerPayloadType<ActionParamItemAdditionalData>>(
        SlideWorkDataActionTypes.HANDLE_USER_EVENT_CHANGE_ADDITIONAL_DATA,
        handleUserEventChangeAdditionalData
    );
}

function* handleUserEventChangeAdditionalData(data: WorkerPayloadType<ActionParamItemAdditionalData>) {
    const [slideIndex, slideItemIndex, exerciseIndex]
        = (
        yield call(
            prepareOrFindExerciseObject,
            {
                playerId: data.payload.playerId,
                slideId: data.payload.slideId,
                slideItemId: data.payload.slideItemId,
                exerciseId: data.payload.exerciseId,
                value: null
            }
        )
    ) as [number, number, number];

    yield put(setExerciseAdditionalData({
        slideIndex: slideIndex,
        slideItemIndex: slideItemIndex,
        exerciseIndex: exerciseIndex,
        value: data.payload.value
    }));

    yield call(pushDataToSaveQueue, DataTypeEnum.ADDITIONAL_DATA, data.payload);

    return;
}
