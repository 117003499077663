import {delay, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import * as AppActionCreators from "../../../store/app/actions";
import {incWsReconnectNum} from "../../../store/app/actions";
import {ILogger} from "../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {WsHandlersActionType} from "../../../store/wsHandlers/type";
import {WsConnectionStatusEnum} from "../../../components/WsApiClient/WsConnectionStatusEnum";
import {IWsApiClient} from "../../../components/WsApiClient/IWsApiClient";
import {stopHandlingEvents} from "../../../store/streamEvent/actions";
import {container} from "tsyringe";
import {DiTokens} from "../../../di-factory/DiTokens";
import {sessionTokenSelector} from "../../../store/app/selector";
import {stopHandleCycle} from "../../../store/slidesWorkDataSaveQueue/actions";
import {clearSubscriptionsList} from "../../../store/lessonMaterials/actions";

/**
 * Сага отвечает за обработку события отключения от WebSocket сервера.
 */

export function* watchWsDisconnected() {
    yield takeEvery(
        WsHandlersActionType.DISCONNECTED,
        wsDisconnected
    );
}

function* wsDisconnected() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.debug(LoggerSectionsEnum.WS_COMPONENT, 'WebSocket connection closed');

    // Фиксируем, что ws подключение отключено
    yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.DISCONNECTED));

    // Приостанавливаем счётчик ping сообщений
    yield putResolve(AppActionCreators.setPingTimeSuspendedValue(true));

    // Останавливаем обработку stream Event
    yield put(stopHandlingEvents());

    // Останавливаем обработку очереди сохранения результатов работы над слайдом
    yield put(stopHandleCycle());

    // Сбрасываем список подписок на список слайдов
    yield put(clearSubscriptionsList());

    const userAuthToken = (yield select(sessionTokenSelector)) as string | null;

    if (userAuthToken === null) {
        // По каким-то причинам токена сеанса больше нет. Не переподключаемся.
        return;
    }

    yield put(incWsReconnectNum());

    // Через 3 секунды пытаемся повторить попытку соединения
    const secForReconnect = parseInt((process.env.REACT_APP_WS_RECONNECTION_PERIOD_SEC) ?? "3");
    yield delay(secForReconnect * 1000);
    let wsApiClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
    wsApiClient.softReconnect();
}
