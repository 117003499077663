import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import React from "react";

export interface ISubscriptionContext {
    overviewItem: DtoSelfStudyTrackSubscriptionUserOverviewItem;
    backgroundColor: string;
    linkToLastEpisode: string;
}

export const SubscriptionContext = React.createContext<ISubscriptionContext>({
    overviewItem: {} as DtoSelfStudyTrackSubscriptionUserOverviewItem,
    backgroundColor: 'white',
    linkToLastEpisode: ''
});

export const SubscriptionContextProvider = SubscriptionContext.Provider;