import styled from "styled-components";
import {ElementProps} from "../rich-text-editor/Element";
import React, {CSSProperties} from "react";
import {InlineChromiumBugfix} from "../InlineChromiumBugfix";
import {ICorrectExistInInput} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICorrectExistInInput";
import classNames from "classnames";

export const CorrectExistInInputStyled = styled.span`
  display: inline;
  padding: 5px 5px 10px 5px;
  position: relative;

  border-bottom: 1px dashed ${({theme}) => theme.colors.textSecondary};

  //&::before {
  //  content: " ";
  //  position: absolute;
  //  top: -5px;
  //  left: 0;
  //  right: 2px;
  //  bottom: -5px;
  //  border: 1px silver dashed;
  //  pointer-events: none;
  //  border-radius: 5px;
  //}
`;

export const CorrectExistInInputItem = styled.span`
  padding: 5px;
  border-radius: 5px;
  margin-right: 2px;

  &.correct {
    background: ${({theme}) => theme.colors.constructorCorrectAnswer};
    color: ${({theme}) => theme.colors.constructorCorrectAnswerFont};
  }

  &.incorrect {
    background: ${({theme}) => theme.colors.constructorIncorrectAnswer};
    color: ${({theme}) => theme.colors.constructorIncorrectAnswerFont};
  }
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const CorrectExistInInput: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        element,
        children
    } = props;

    return <CorrectExistInInputStyled style={{...style}} {...attributes}>
        <InlineChromiumBugfix/>
        {
            (element as ICorrectExistInInput).values.map((item, index) => {
                return <CorrectExistInInputItem contentEditable={false}
                                                className={classNames((index === 0) ? "incorrect" : "correct")}
                                                key={item + index.toString(10)}>
                    {item}
                </CorrectExistInInputItem>
            })
        }
        {children}
        <InlineChromiumBugfix/>
    </CorrectExistInInputStyled>;
}