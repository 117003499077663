import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {selectedAgreement, selectedUserInSchool} from "../../../../../store/user/selector";
import {Modal} from "../../../../components/Ui/Elements/Modal";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {PageTitle} from "../../../../styles/global-elements";
import {
    DtoOnlinePaymentDocumentResponseItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {Trans} from "@lingui/macro";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {PaymentForm} from "../../../student/common/online-payment/PaymentForm";
import {PopupActions} from "reactjs-popup/dist/types";

interface PayOnlinePopupProps {
}

enum LoadingStateEnum {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export const PayOnlinePopup = forwardRef<PopupActions, PayOnlinePopupProps>(
    (props, ref) => {
        const sessionToken = useSelector(sessionTokenSelector);
        const userInSchool = useSelector(selectedUserInSchool);
        const agreement = useSelector(selectedAgreement);

        const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_INIT);
        const [abortController, setAbortController] = useState<AbortController | null>(null);
        const [documentsList, setDocumentsList] = useState<DtoOnlinePaymentDocumentResponseItem[]>([]);

        const fetchDocuments = useCallback(() => {
            if ((sessionToken === null) || (userInSchool === null) || (agreement === null)) {
                setLoadingState(LoadingStateEnum.ERROR);

                return;
            }

            if (abortController !== null) {
                abortController.abort();
            }

            setLoadingState(LoadingStateEnum.LOADING);
            const newAbortController = new AbortController();
            setAbortController(newAbortController);

            const httpClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            httpClient.getAvailableAcquiringDocuments(
                sessionToken,
                agreement.id,
                newAbortController
            )
                .then((data) => {
                    setDocumentsList(data.data.list);
                    setLoadingState(LoadingStateEnum.SUCCESS);
                })
                .catch((error) => {
                    logger.error(LoggerSectionsEnum.PAYMENT_MODAL, 'Error on load acquiring documents list', error);

                    setLoadingState(LoadingStateEnum.ERROR);
                })

        }, [abortController, agreement, sessionToken, userInSchool]);

        const onOpen = useCallback(() => {
            fetchDocuments();
        }, [fetchDocuments]);

        const onClose = useCallback(() => {
            if (abortController !== null) {
                abortController.abort();
                setAbortController(null);
            }
        }, [abortController]);

        useEffect(() => {
            return () => {
                if (abortController !== null) {
                    abortController.abort();
                    setAbortController(null);
                }
            }
        }, [abortController])


        const content = useMemo<JSX.Element>(() => {
            switch (loadingState) {
                case LoadingStateEnum.NOT_INIT:
                case LoadingStateEnum.LOADING: {
                    return <DefaultLoader/>
                }
                case LoadingStateEnum.ERROR: {
                    return <ErrorLoadingContent retryBtnClick={fetchDocuments}/>
                }
                case LoadingStateEnum.SUCCESS: {
                    return <PaymentForm acquiringDocuments={documentsList}/>;
                }
                default: {
                    throw new Error('Unknown loading state');
                }
            }
        }, [documentsList, fetchDocuments, loadingState]);

        return <Modal onOpen={onOpen} onClose={onClose} closeAllowed={true} innerRef={ref}
                      footer={
                          (controls) => {
                              return <div>
                                  <Button btnStyle={BtnStyleEnum.Primary}
                                          onClick={controls.closeModal}
                                  >
                                      <Trans>Закрыть</Trans>
                                  </Button>
                              </div>
                          }
                      }
                      children={(controls) => {
                          return <div>
                              <PageTitle><Trans>Пополнение счёта</Trans></PageTitle>
                              {content}
                          </div>
                      }}
        />
    })