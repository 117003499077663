import {ParticipantItem} from "../../../Types";
import {DeviceOrientatonTypeEnum} from "../../../../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";

export enum GallerySizeMode {
    SMALL = 0,
    MEDIUM = 1,
    LARGE = 2,
    EXTRA_LARGE = 3,
    FLOAT_ON_PAGE = 4,
    FLOAT_ON_SMALL_PAGE = 5
}

export const GalleryItemsCountBySizeMode: { [id: number]: number } = {};
GalleryItemsCountBySizeMode[GallerySizeMode.SMALL] = 2;
GalleryItemsCountBySizeMode[GallerySizeMode.MEDIUM] = 4;
GalleryItemsCountBySizeMode[GallerySizeMode.LARGE] = 4;
GalleryItemsCountBySizeMode[GallerySizeMode.EXTRA_LARGE] = 9;
GalleryItemsCountBySizeMode[GallerySizeMode.FLOAT_ON_PAGE] = 4;
GalleryItemsCountBySizeMode[GallerySizeMode.FLOAT_ON_SMALL_PAGE] = 1;

export const GalleryListItemsCountBySizeMode: { [id: number]: number } = {};
GalleryListItemsCountBySizeMode[GallerySizeMode.SMALL] = 2;
GalleryListItemsCountBySizeMode[GallerySizeMode.MEDIUM] = 3;
GalleryListItemsCountBySizeMode[GallerySizeMode.LARGE] = 4;
GalleryListItemsCountBySizeMode[GallerySizeMode.EXTRA_LARGE] = 5;
GalleryListItemsCountBySizeMode[GallerySizeMode.FLOAT_ON_PAGE] = 0;
GalleryListItemsCountBySizeMode[GallerySizeMode.FLOAT_ON_SMALL_PAGE] = 0;

export enum GallerySizeModeConstraints {
    MIN_HEIGHT_FOR_MEDIUM = 500,
    MIN_WIDTH_FOR_MEDIUM = 540,

    MIN_HEIGHT_FOR_LARGE = 510,
    MIN_WIDTH_FOR_LARGE = 760,

    MIN_HEIGHT_FOR_EXTRA_LARGE = 740,
    MIN_WIDTH_FOR_EXTRA_LARGE = 1500,
}

export interface VideoGalleryParticipantProfile {
    id: string;
    name: string;
    avatarFileId: string | null;
    micMuted: boolean;
    screenOrientation: DeviceOrientatonTypeEnum;
}

export interface ProfileWithConfInfo {
    profile: VideoGalleryParticipantProfile;
    confInfo: ParticipantItem | null;
}
