import {Editor, Element as SlateElement, Transforms} from "slate";
import {LIST_TYPES} from "../Enums";
import {toggleGapsCombobox} from "../toggle-handlers/toggleGapsCombobox";
import {toggleGapsText} from "../toggle-handlers/toggleGapsText";
import {toggleCorrectExistInInput} from "../toggle-handlers/toggleCorrectExistInInput";
import {toggleGapsDragDrop} from "../toggle-handlers/toggleGapsDragDrop";
import {toggleRadioList} from "../toggle-handlers/toggleRadioList";
import {toggleCheckboxList} from "../toggle-handlers/toggleCheckboxList";
import {elementTypeIsActive} from "../CommonMethods";
import {IBaseElement} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";
import {toggleTranslated} from "../toggle-handlers/toggleTranslated";

export const toggleBlock = (editor: Editor, blockType: ElementTypes) => {
    switch (blockType) {
        case ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX: {
            toggleGapsCombobox(editor);

            return;
        }
        case ElementTypes.EXERCISE_FILL_GAPS_TEXT: {
            toggleGapsText(editor);

            return;
        }
        case ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT: {
            toggleCorrectExistInInput(editor);

            return;
        }
        case ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP: {
            toggleGapsDragDrop(editor);

            return;
        }
        case ElementTypes.EXERCISE_RADIO_LIST: {
            toggleRadioList(editor);

            return;
        }
        case ElementTypes.EXERCISE_CHECKBOX_LIST: {
            toggleCheckboxList(editor);

            return;
        }
        case ElementTypes.TRANSLATED: {
            toggleTranslated(editor);

            return;
        }
    }

    const isActive = elementTypeIsActive(editor, blockType);

    const targetIsList = (LIST_TYPES as unknown as Array<string>).includes(blockType);

    Transforms.unwrapNodes(editor, {
        match: (node) => {
            if (Editor.isEditor(node)) {
                return false;
            }

            if (!SlateElement.isElement(node)) {
                return false;
            }

            if (!LIST_TYPES.includes(node.type)) {
                return false;
            }

            return !(Object.values(AlignTypes) as Array<string>).includes(blockType);
        },
        split: true,
    })

    let newProperties: Partial<SlateElement>;

    newProperties = {
        type:
            isActive
                ? ElementTypes.PARAGRAPH
                : targetIsList
                    ? ElementTypes.LIST_ITEM
                    : blockType,
    }

    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && targetIsList) {
        const block: IBaseElement = {
            type: blockType,
            children: []
        }

        Transforms.wrapNodes(editor, block);
    }
}