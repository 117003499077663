import {PageWrapper} from "../../../../components/PageWrapper";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {t} from "@lingui/macro";
// import styled from "styled-components";
// import {SupportWhatsAppString} from "../../../../components/SupportWhatsAppString";
import {useSelector} from "react-redux";
// import {ApplicationState} from "../../../../../store";
// import {ITheme} from "../../../../../services/theme/ITheme";
// import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";
import {useNavigate, useParams} from "react-router-dom";
import {RoutesList} from "../../../../RoutesList";
import {
    DtoSelfStudyTrackForEditResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackForEditResponse";
import {PageLoadingStateEnum} from "../../../../../enums/PageLoadingStateEnum";
import {CanceledByUser} from "../../../../../components/HttpApiClient/Exception/CanceledByUser";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {SelfStudyTrackForm} from "./SelfStudyTrackForm";
// import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {
    SelfStudyTrackCoverMaskTypeEnum
} from "../../../../../enums/SelfStudyTrackCoverMaskTypeEnum";

// const RightBlockText = styled.div`
//     margin-bottom: 32px;
// `;

export const EditTrack = () => {
    const routerParams = useParams();
    const navigate = useNavigate();

    const logger = useMemo(() => container.resolve<ILogger>(DiTokens.LOGGER), []);
    const apiClient = useMemo(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);
    const abortController = useRef<AbortController | null>(null);

    // const currentTheme = useSelector<ApplicationState>(
    //     ({layout}: ApplicationState) => layout.activeTheme
    // ) as ITheme;

    const sessionToken = useSelector(sessionTokenSelector);

    // const large = useMediaQuery(`(${currentTheme.media.large})`);

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);

    const [initialData, setInitialData] = useState<DtoSelfStudyTrackForEditResponse | null>(null);
    const [saveInProcess, setSaveInProcess] = useState<boolean>(false);

    const loadTrackData = useCallback(async (trackId: string) => {
        if (sessionToken === null) {
            setPageLoadingState(PageLoadingStateEnum.ERROR);

            return;
        }

        if (abortController.current !== null) {
            abortController.current.abort();

            abortController.current = null;
        }

        abortController.current = new AbortController();

        try {
            const data = await apiClient.getStudyTrackForEditById(
                sessionToken,
                trackId,
                abortController.current
            );

            setInitialData(data.data);

            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
        } catch (e) {
            if (e instanceof CanceledByUser) {
                return;
            }

            if (e instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось загрузить данные`,
                    t`Проверьте интернет соединение`
                );

                setPageLoadingState(PageLoadingStateEnum.ERROR);

                return;
            }

            logger.error(
                LoggerSectionsEnum.SELF_STUDY_TRACK_API,
                `Error on load track with id ${trackId}: `,
                e
            );

            setPageLoadingState(PageLoadingStateEnum.ERROR);

            return;
        }
    },[apiClient, logger, sessionToken]);

    useEffect(() => {
        if (routerParams.trackId === undefined) {
            setInitialData(new DtoSelfStudyTrackForEditResponse());
            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
        } else {
            loadTrackData(routerParams.trackId).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveData = useCallback(async (dto: DtoSelfStudyTrackForEditResponse) => {
        if (!sessionToken) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setSaveInProcess(true);

        try {
            const result = (!dto.id)
                ? await httpApiClient.selfStudyCreateTrack(
                    sessionToken,
                    dto.subjectAreaLevel.id,
                    dto.name,
                    dto.shortDescription,
                    dto.landingDescription,
                    dto.internalDescription,
                    dto.tmSection.id,
                    dto.tutor.stApiId as number,
                    dto.coverFileId,
                    dto.coverMaskId as SelfStudyTrackCoverMaskTypeEnum,
                    dto.accentColor
                )
                : await httpApiClient.selfStudyUpdateTrack(
                    sessionToken,
                    dto.id,
                    dto.subjectAreaLevel.id,
                    dto.name,
                    dto.shortDescription,
                    dto.landingDescription,
                    dto.internalDescription,
                    dto.tmSection.id,
                    dto.tutor.stApiId as number,
                    dto.coverFileId,
                    dto.coverMaskId as SelfStudyTrackCoverMaskTypeEnum,
                    dto.accentColor
                );

            navigate(RoutesList.TEACHER_SELF_STUDY_TRACKS);
            // navigate(RoutesHelper.replaceParams( //TODO перенаправить на view, когда view появится
            //     RoutesList.TEACHER_SELF_STUDY_TRACK_EDIT,
            //     [
            //         {
            //             key: 'trackId',
            //             value: result.data.id
            //         }
            //     ]
            // ));
        } catch (error) {
            setSaveInProcess(false);

            if (error instanceof CanceledByUser) {
                return;
            }

            if (error instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Проверьте интернет соединение`,
                );

                return;
            }

            logger.error(LoggerSectionsEnum.SELF_STUDY_TRACK_API, "Error on create self-study track: ", error);

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось выполнить операцию`,
                t`Попробуйте повторить попытку позднее`,
            );
        }
    }, [navigate, sessionToken]);

    const onDeleteTrack = useCallback(async () => {
        if (sessionToken === null) {
            throw new Error('Not found session token');
        }
        
        const trackId = initialData?.id;
        
        if (!trackId) {
            throw new Error('Not found track id');
        }
        
        await apiClient.deleteStudyTrack(sessionToken, trackId);
        
        navigate(RoutesList.TEACHER_SELF_STUDY_TRACKS);
    }, [apiClient, initialData?.id, navigate, sessionToken]);

    const pageName = useMemo(() => {
        if (!!routerParams.trackId) {
            return t`Редактирование self-study трека`;
        }

        return t`Новый self-study трек`;
    }, [routerParams.trackId]);

    const content = useMemo(() => {
        if (pageLoadingState === PageLoadingStateEnum.NOT_INIT || pageLoadingState === PageLoadingStateEnum.LOADING) {
            return <DefaultLoader/>;
        }

        if (pageLoadingState === PageLoadingStateEnum.ERROR || !initialData) {
            return <ErrorLoadingContent retryBtnClick={() => loadTrackData(routerParams.trackId ?? '')}/>
        }

        return <SelfStudyTrackForm
            onDeleteTrack={onDeleteTrack}
            initialData={initialData}
            saveInProcess={saveInProcess}
            onSave={(dto) => saveData(dto)}/>
    }, [initialData, loadTrackData, onDeleteTrack, pageLoadingState, routerParams.trackId, saveData, saveInProcess]);

    return <PageWrapper
        pageTitle={pageName}
        pageContent={content}
        rightBlockContent={
        null
        // (!large) ? null :
        //     <RightBlockText>
        //         <SupportWhatsAppString/>
        //     </RightBlockText>
        }
        backLink={{
            href: RoutesList.TEACHER_SELF_STUDY_TRACKS,
            title: t`Обратно, к списку треков`
        }}>
    </PageWrapper>
}