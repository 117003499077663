import React, {forwardRef, useMemo} from "react";
import styled from "styled-components";
import {Popup as ReactjsPopup} from "reactjs-popup";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {useSelector} from "react-redux";
import {selectedUserInSchool} from "../../../../../store/user/selector";
import {i18n} from "@lingui/core";
import classNames from "classnames";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {PopupActions} from "reactjs-popup/dist/types";

const StyledPopup = styled(ReactjsPopup)`
`;

const PopupContentWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 260px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
`;

const Title = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 8px;
`;

const SumText = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 16px;

  &.warning {
    color: ${({theme}) => theme.colors.moneyNegativeBalance};
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  padding: 12px;
`;

interface BalancePopupProps {
    trigger: JSX.Element;
    payOnloneOnClick: () => void;
}

export const BalancePopup = forwardRef<PopupActions, BalancePopupProps>(
    (props, ref) => {
        const userInSchool = useSelector(selectedUserInSchool);

        const currentTheme = useSelector<ApplicationState>(
            ({layout}: ApplicationState) => layout.activeTheme
        ) as ITheme;

        const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

        const sum = useMemo<number>(() => {
            if (userInSchool === null) {
                return 0;
            }

            return userInSchool.balance;
        }, [userInSchool]);

        const formattedSum = useMemo<string>(() => {
            return i18n.number(sum, {style: "currency", currency: "RUB"});
        }, [sum]);

        return <StyledPopup ref={ref} trigger={props.trigger} position={(isSmall) ? "bottom right" : "bottom center"} arrow={false}>
            <PopupContentWrapper>
                <Title><Trans>баланс</Trans></Title>
                <SumText className={classNames(sum < 0 && "warning")}>{formattedSum}</SumText>
                {
                    (userInSchool?.onlinePaymentEnabled)
                    && <ButtonStyled btnStyle={BtnStyleEnum.Secondary} onClick={props.payOnloneOnClick}>
                        <Trans>Пополнить</Trans>
                    </ButtonStyled>
                }
            </PopupContentWrapper>
        </StyledPopup>;
    });