import styled from 'styled-components';
import {ReactComponent as DefaultUserAvatarPart} from "../../../components/Ui/Svg/DefaultUserAvatarPart.svg";
import React, {useMemo} from "react";

const SvgWrapper = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  display: flex;
  flex-direction: column;
`;

const DefaultUserAvatarStyled = styled(DefaultUserAvatarPart)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.3;
  width: 100%;
  height: 100%;
`;

interface DefaultUserAvatarProps {
    size?: number;
}

export const DefaultUserAvatar: React.FC<DefaultUserAvatarProps> = (props) => {
    const size = useMemo<string>(() => {
        if (!props.size) {
            return '40px';
        }

        return `${props.size}px`;
    }, [props.size]);

    return <SvgWrapper>
        <DefaultUserAvatarStyled style={{width: size, height: size}}/>
    </SvgWrapper>
}