import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../../store/lessonRoom/type";
import {WorkerPayloadType} from "../../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../../components/WsApiClient/ResponseActionCreatorPayload";
import {ApplicationState} from "../../../store";
import {StreamEventStoreItem} from "../../../store/streamEvent/type";
import {
    DtoSEMemberPublicFullScreenValue
} from "../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicFullScreenValue";
import {setMemberIsInFullScreen} from "../../../store/lessonRoom/actions";

export function* watchHandleSeMemberPublicFullScreenState() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicFullScreenValue>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_FULL_SCREEN_MODE,
        handleFullScreenState
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleFullScreenState(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicFullScreenValue>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    yield put(setMemberIsInFullScreen(
        data.payload.response.dto.payload.userId,
        data.payload.response.dto.payload.value
    ));
}
