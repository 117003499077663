import React from "react";
import {ReactComponent as HeadsetOffIcon} from "../../../../Ui/Svg/HeadsetOff.svg";
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const Wrapper = styled.div`
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  color: ${({theme}) => theme.colors.textSecondary};

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
`;

const TextWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 15px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const AudioRoomFatalError: React.FC = () => {
    return <Wrapper>
        <IconWrapper><HeadsetOffIcon/></IconWrapper>
        <TextWithLoader>
            <TextWrapper>
                <Trans>Возникла проблема с подключением звука. Попробуйте перезагрузить страницу.</Trans>
            </TextWrapper>
        </TextWithLoader>
    </Wrapper>
}
