import React from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ContentWidthWrapper} from "../styles";
import {RegularTextCss} from "../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum} from "../../../components/Ui/Elements/Button";
import {RoutesList} from "../../../RoutesList";
import {LinkAsButton} from "../../../components/Ui/Elements/LinkAsButton";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {PageSectionsEnum} from "../../teacher/initial-customizer/PageSectionsEnum";


const Wrapper = styled.div`
  padding: 5px 20px;
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight}px;
  z-index: ${({theme}) => theme.zIndices.header};
  color: ${({theme}) => theme.colors.textPrimary};
  backdrop-filter: blur(10px);
  height: auto;
  background: rgba(177, 74, 255, 0.2);
`;

const ContentWidthWrapperStyled = styled(ContentWidthWrapper)`
  margin: auto;
  padding: 5px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  ${RegularTextCss};

  color: inherit;
  padding-left: 5px;

  font-weight: bold;
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    width: auto;
  }
`;

const ButtonStyled = styled(LinkAsButton)`
  white-space: nowrap;
  text-align: center;
  width: 100%;
`;

export const NeedStartInitialCustomizer: React.FC = (props) => {
    const needInitialCustomizer = useSelector(({app}: ApplicationState) => app.needInitialCustomizer);

    if (!needInitialCustomizer) {
        return null;
    }

    return <Wrapper>
        <ContentWidthWrapperStyled>
            <ContentWrapper>
                <TextWrapper>
                    <Trans>Настройте личный кабинет &mdash; несколько простых шагов, чтобы начать первый урок</Trans>
                </TextWrapper>
                <ButtonWrapper>
                    <ButtonStyled btnStyle={BtnStyleEnum.Primary}
                                  to={RoutesHelper.replaceParams(
                                      RoutesList.TEACHER_SETUP,
                                      [
                                          {
                                              key: 'stepId',
                                              value: PageSectionsEnum.WELCOME
                                          }
                                      ]
                                  )}>
                        <Trans>Настроить</Trans>
                    </ButtonStyled>
                </ButtonWrapper>
            </ContentWrapper>
        </ContentWidthWrapperStyled>
    </Wrapper>;
}
