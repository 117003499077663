import {instanceCachingFactory} from 'tsyringe';
import {ConsoleLogger} from '../components/Logger/ConsoleLogger';
import {ILogger} from '../components/Logger/ILogger';
import {DiTokens} from "./DiTokens";
import {IDateHelperService} from "../services/date-helper/IDateHelperService";
import {ProdConsoleLogger} from "../components/Logger/ProdConsoleLogger";

export default instanceCachingFactory<ILogger>((container) => {
    const dateHelperService = container.resolve(DiTokens.DATE_HELPER_SERVICE) as IDateHelperService;

    const logAllAsString = process.env.REACT_APP_LOG_ALL_AS_STRING;

    if (logAllAsString === undefined) {
        throw new Error('Check REACT_APP_LOG_ALL_AS_STRING env variable');
    }

    if (logAllAsString === 'true') {
        return new ProdConsoleLogger(dateHelperService);
    }

    return new ConsoleLogger(dateHelperService);
});
