import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PageTitle} from "../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {PageLoadingStateEnum} from "../../../../enums/PageLoadingStateEnum";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../store/app/selector";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {
    DtoSelfStudyTrackListItem
} from "../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackListItem";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {debounce, DebouncedFunc} from "lodash";
import {EmptyListNotificationBox} from "./EmptyListNotificationBox";
import {ChipsFilterArea} from "../../../components/Ui/Elements/ChipsFilterArea";
import {ItemsList} from "./ItemsList";
import {RoutesList} from "../../../RoutesList";

const ITEMS_PER_PAGE = 30;

export const SelfTracksCatalog: React.FC = () => {
    const apiToken = useSelector(sessionTokenSelector);

    const isFirstRender = useRef<boolean>(true);
    const logger = useRef<ILogger>(container.resolve<ILogger>(DiTokens.LOGGER));
    const abortController = useRef<AbortController | null>(null);
    const httpApiClient = useRef<IHttpApiClient>(
        container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT)
    );

    const [pageLoadingState, setPageLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [searchString, setSearchString] = useState<string>('');
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const [totalTracksCount, setTotalTracksCount] = useState<number | null>(null);
    const [tracksList, setTracksList] = useState<DtoSelfStudyTrackListItem[] | null>(null);

    const fetchTracks = useCallback(async () => {
        if (!apiToken) {
            return;
        }

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        if (abortController.current !== null) {
            abortController.current.abort();
        }

        abortController.current = new AbortController();

        try {
            const result = await httpApiClient.current.selfStudySchoolGetList(
                apiToken,
                searchString,
                currentPageNum,
                ITEMS_PER_PAGE,
                abortController.current
            );

            setPageLoadingState(PageLoadingStateEnum.SUCCESS);
            setTracksList(result.data.list);
            setTotalTracksCount(result.data.totalCount);
        } catch (e) {
            logger.current.error(LoggerSectionsEnum.SELF_STUDY_TRACK_API, e);

            setPageLoadingState(PageLoadingStateEnum.ERROR);
        }
    }, [apiToken, currentPageNum, searchString]);

    const lastDebounced = useRef<DebouncedFunc<any> | null>(null);

    const fetchTracksDebounced = useMemo<DebouncedFunc<any>>(() => {
        lastDebounced.current?.cancel();

        lastDebounced.current = debounce(fetchTracks, 500);

        return lastDebounced.current;
    }, [fetchTracks]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        fetchTracksDebounced.cancel();

        fetchTracks().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageNum]);

    useEffect(() => {
        if (isFirstRender.current) {
            return;
        }

        abortController.current?.abort();
        abortController.current = null;

        setPageLoadingState(PageLoadingStateEnum.LOADING);

        fetchTracksDebounced();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(
        () => {
            isFirstRender.current = false;

            fetchTracks().then();

            return () => {
                lastDebounced.current?.cancel();

                if (abortController.current !== null) {
                    abortController.current.abort();
                    abortController.current = null;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const content = useMemo(() => {
        switch (pageLoadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.SUCCESS: {
                if ((tracksList === null) || (totalTracksCount === null)) {
                    return <DefaultLoader/>;
                }

                if (tracksList.length === 0) {
                    return <EmptyListNotificationBox searchStringIsEmpty={searchString === ''}/>;
                }

                return <ItemsList
                    totalItemsCount={totalTracksCount}
                    onPageChange={(page: number) => setCurrentPageNum(page)}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPageNum={currentPageNum}
                    items={tracksList}
                />
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchTracks}/>
            }
        }
    }, [currentPageNum, fetchTracks, pageLoadingState, searchString, totalTracksCount, tracksList]);

    return <div>
        <PageTitle><Trans>Self-study треки</Trans></PageTitle>
        <ChipsFilterArea
            placeholder={t`Поиск по названию...`}
            searchString={searchString}
            onChange={setSearchString}
            createNewBtn={{
                label: t`Создать новый`,
                link: RoutesList.TEACHER_SELF_STUDY_TRACK_CREATE
            }}
        />
        {content}
    </div>
}