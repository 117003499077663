import React, {forwardRef, useContext, useMemo} from 'react';
import classNames from 'classnames';
import type {DraggableSyntheticListeners} from '@dnd-kit/core';
import type {Transform} from '@dnd-kit/utilities';
import styled, {css} from "styled-components";
import {IFillGapsDrapDrop} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsDrapDrop";
import {RegularText} from "../../components/RichTextReader/elements/common-styles";
import {ShowMode} from "./Exercise";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";

interface DraggableProps {
    element: IFillGapsDrapDrop;
    dragging?: boolean;
    ghost?: boolean;
    dragOverlay?: boolean;

    handle?: boolean;
    label?: string;
    listeners?: DraggableSyntheticListeners;
    transform?: Transform | null;

    showMode?: ShowMode;
}

const DraggableStyled = styled.div`
  touch-action: none;
 
`;

const BaseBlock = css`
  background: ${({theme}) => theme.colors.backgroundPrimary};
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 5px;
`;

export const WordStyled = styled.button`
  ${BaseBlock};

  ${RegularText};
  line-height: 1.7em;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  transform: translateY(0px);
  box-shadow: rgb(0 0 0 / 6%) 0 1px 1px;
  border-radius: 4px;
  padding: 1px 10px;
  cursor: grab;
  transition: transform 0.1s ease;
  
  &.read-only {
    cursor: not-allowed;
  }

  &:hover:not(.correct):not(.muted):not(.active) {
    transform: translateY(-2px);
  }

  &.correct {
    background: none;
    border: none;
    pointer-events: none;
    cursor: default;
  }

  &.muted {
    cursor: default;
    background: ${({theme}) => theme.colors.backgroundSecondary};
    color: ${({theme}) => theme.colors.textSecondary};
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0);
  }
`;

export const Draggable = forwardRef<HTMLDivElement, DraggableProps>(
    function Draggable(
        {
            dragOverlay,
            dragging,
            handle,
            label,
            listeners,
            transform,
            element,
            ghost,
            showMode,
            ...props
        },
        ref
    ) {
        const muted = useMemo<boolean>(() => {
            return ((dragging && !dragOverlay) || (ghost)) ?? false;
        }, [dragOverlay, dragging, ghost]);

        const correct = useMemo<boolean>(() => {
            return showMode === ShowMode.CORRECT;
        }, [showMode]);

        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        return (
            <DraggableStyled
                className={classNames(
                    !!dragOverlay && "drag-overlay",
                    !!handle && "handle",
                    correct && "correct",

                )}
                {...(handle ? {} : listeners)}
                ref={ref}
                style={
                    {
                        '--translate-x': `${transform?.x ?? 0}px`,
                        '--translate-y': `${transform?.y ?? 0}px`,
                    } as React.CSSProperties
                }
            >
                <WordStyled
                    aria-label="Draggable"
                    // data-cypress="draggable-handle"
                    //{{...props}}
                    //{...(handle ? {} : listeners)}
                    //ref={ref}
                    className={classNames(
                        muted && "muted",
                        correct && "correct",
                        slideItemWorkContext.readOnly && "read-only"
                    )}
                >
                    {element.values[0]}
                </WordStyled>
            </DraggableStyled>
        );
    }
);
