import {call, delay, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {LessonMaterialsActionTypes, LoadingStateEnum, SlideItemData} from "../../store/lessonMaterials/type";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {sessionTokenSelector} from "../../store/app/selector";
import {BaseResponseDto} from "../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {DtoRequestWithPagination} from "../../components/HttpApiClient/ApiDto/Response/Common/DtoRequestWithPagination";
import {
    DtoLessonMaterialItem
} from "../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialItem";
import {
    DtoLessonMaterialHomeworkItem
} from "../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialHomeworkItem";
import {
    loadLessonMaterialsList as actionLoadLessonMaterialsList,
    setLessonId,
    setLessonMaterialsList,
    setLoadingState,
    setMaterialsType
} from "../../store/lessonMaterials/actions";
import {ApplicationState} from "../../store";

/**
 * Сага отвечает за запуск запроса списка учебных материалов комнаты
 */
export function* watchLoadLessonMaterialsList() {
    yield takeEvery<WorkerPayloadType<{ lessonId: string; homeworkMaterials: boolean; silentLoad: boolean, tryNum: number }>>(
        LessonMaterialsActionTypes.LOAD_LESSON_MATERIALS_LIST,
        loadLessonMaterialsList
    );
}

const currentLoadingStateSelector = ({lessonMaterials}: ApplicationState) => lessonMaterials.loadingState;

function* loadLessonMaterialsList(data: WorkerPayloadType<{ lessonId: string; homeworkMaterials: boolean; silentLoad: boolean, tryNum: number }>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

    const currentLoadingState = (yield select(currentLoadingStateSelector)) as LoadingStateEnum;

    if (currentLoadingState === LoadingStateEnum.NOT_INIT) {
        // На момент очередной (возможно) итерации надобность в данных отпала
        return;
    }

    const sessionToken = (yield select(sessionTokenSelector)) as string | null;

    if (sessionToken === null) {
        logger.error(LoggerSectionsEnum.LESSON_ROOM, "Loading lesson materials without session token.");

        return;
    }

    logger.info(LoggerSectionsEnum.LESSON_ROOM, "Loading lesson materials...");

    if (data.payload.silentLoad) {
        yield putResolve(setLoadingState(LoadingStateEnum.SILENT_LOADING));
    } else {
        yield putResolve(setLoadingState(LoadingStateEnum.INIT_LOADING));
    }

    let lessonWorkResult: null | BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialItem>> = null;
    let homeworkResult: null | BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>> = null;

    try {
        if (data.payload.homeworkMaterials) {
            homeworkResult = (yield call(() => httpApiClient.getLessonHomeworkMaterialsForStudent(
                sessionToken,
                data.payload.lessonId,
                1,
                200
            )));
        } else {
            lessonWorkResult = (yield call(() => httpApiClient.getLessonMaterialsForStudent(
                sessionToken,
                data.payload.lessonId,
                1,
                200
            )));
        }
    } catch (err) {
        const currentLoadingState = (yield select(currentLoadingStateSelector)) as LoadingStateEnum;

        if (currentLoadingState === LoadingStateEnum.INIT_LOADING) {
            put(setLoadingState(LoadingStateEnum.ERROR));

            return;
        }

        if (currentLoadingState === LoadingStateEnum.SILENT_LOADING) {
            if (data.payload.tryNum > 5) {
                // Уже 5 раз не сработало. Показываем ошибку.
                put(setLoadingState(LoadingStateEnum.ERROR));

                return;
            }

            // Загружали без отображения процесса. Перезапустим процесс через несколько секунд.
            yield delay(3000);

            put(actionLoadLessonMaterialsList(
                data.payload.lessonId,
                data.payload.homeworkMaterials,
                data.payload.silentLoad,
                data.payload.tryNum + 1
            ));

            return;
        }

        logger.error(LoggerSectionsEnum.LESSON_ROOM, "Error on loading lesson materials for student. ", err);

        put(setLoadingState(LoadingStateEnum.ERROR));

        return;
    }

    yield put(setLessonId(data.payload.lessonId));
    yield put(setMaterialsType(data.payload.homeworkMaterials));

    if (homeworkResult !== null) {
        // Загружали домашнюю работу
        yield put(setLessonMaterialsList(homeworkResult.data.list.map((dtoItem): SlideItemData => {
            return {
                tmId: dtoItem.slideTmId,
                tmName: dtoItem.slideTmName,
                exerciseCount: dtoItem.slideExerciseCount,
                contentVersion: dtoItem.slideContentVersion,
                orderPosition: dtoItem.orderPosition,
                hiddenForStudent: false
            }
        })));
    } else if (lessonWorkResult !== null) {
        // Загружали работу на уроке
        yield put(setLessonMaterialsList(lessonWorkResult.data.list

            .filter(item => !item.hiddenForStudent)

            .map((dtoItem): SlideItemData => {
                return {
                    tmId: dtoItem.slideTmId,
                    tmName: dtoItem.slideTmName,
                    exerciseCount: dtoItem.slideExerciseCount,
                    contentVersion: dtoItem.slideContentVersion,
                    orderPosition: dtoItem.orderPosition,
                    hiddenForStudent: dtoItem.hiddenForStudent
                }
            })));
    }

    yield put(setLoadingState(LoadingStateEnum.SUCCESS));
}
