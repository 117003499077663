import {instanceCachingFactory} from 'tsyringe';
import {configureStore} from "../store";
import {createBrowserHistory} from "history";
import {IStoreService} from "../services/store/IStoreService";
import {StoreService} from "../services/store/StoreService";

export default instanceCachingFactory<IStoreService>(() => {
    const history = createBrowserHistory();
    const {persistor, store} = configureStore(history);

    return new StoreService(
        history,
        persistor,
        store
    );
});
