import {action} from "typesafe-actions";
import {UserFileTypeEnum} from "../../enums/UserFileEnums";
import {FileUploadProcessState, UploadProcessDetails, UploadQueueActionTypes} from "./type";

export const uploadNewFile = (fileId: string, fileType: UserFileTypeEnum, uploadUrl: string, file: File | Blob) => {
    const processDetailsObject: UploadProcessDetails = {
        state: FileUploadProcessState.WAIT_FOR_START,
        fileId: fileId,
        fileType: fileType,
        url: uploadUrl,
        file: file,
        fileSize: file.size,
        completedInPercent: 0,
        needCancel: false
    }

    return action(UploadQueueActionTypes.UPLOAD_NEW_FILE, processDetailsObject);
};

export const restartFileUploading = (fileId: string) => {
    return action(UploadQueueActionTypes.RESTART_FILE_UPLOADING, fileId);
}

export const setNewUploadProcess = (processDetails: UploadProcessDetails) => {
    return action(UploadQueueActionTypes.SET_NEW_UPLOAD_PROCESS, processDetails);
}

export const setProcessState = (processDetails: Partial<UploadProcessDetails>) => {
    return action(UploadQueueActionTypes.SET_PROCESS_STATE, processDetails);
}

export const cancelUploadFile = (fileId: string) => {
    return action(UploadQueueActionTypes.SET_CANCEL_UPLOAD_FILE, fileId);
}

export const removeFromQueue = (fileId: string) => {
    return action(UploadQueueActionTypes.REMOVE_FROM_QUEUE, fileId);
}