import React, {useCallback, useMemo} from "react";
import {StringHelper} from "../../../../../../helpers/StringHelper";
import styled from "styled-components";
import {ReactComponent as CopySvg} from "../../../../../components/Ui/Svg/Copy20.svg";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {t} from "@lingui/macro";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const PhoneStringWrapper = styled.div`
`;

const PhoneLink = styled.a`
  color: ${({theme}) => theme.colors.textPrimary};

  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const CopyIconWrapper = styled.div`
  cursor: pointer;
  
  display: flex;
  align-items: center;
  max-width: 16px;
  min-width: 16px;
  max-height: 16px;
  min-height: 16px;

  @media (${({theme}) => theme.media.small}) {
    max-width: 18px;
    min-width: 18px;
    max-height: 18px;
    min-height: 18px;
  }
`;

interface PhoneStringProps {
    unformattedPhoneString: string;
}

export const PhoneString: React.FC<PhoneStringProps> = ({unformattedPhoneString}) => {

    const phoneString = useMemo<string | null>(() => {
        return StringHelper.formatPhone(
            unformattedPhoneString
        );
    }, [unformattedPhoneString]);

    const copyBtnOnClick = useCallback(() => {
        const showErrorMsg = () => {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не получается скопировать. Попробуйте скопировать вручную.`,
                ''
            );
        }

        if (navigator.clipboard === undefined) {
            showErrorMsg();

            return;
        }

        navigator.clipboard.writeText(unformattedPhoneString)
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Номер телефона скопирован`,
                    ''
                );

                return;
            })
            .catch(() => {
                showErrorMsg();
            });
    }, [unformattedPhoneString]);

    return <Wrapper>
        <PhoneStringWrapper>
            <PhoneLink href={`tel:+${unformattedPhoneString}`}>
                {phoneString}
            </PhoneLink>
        </PhoneStringWrapper>
        <CopyIconWrapper onClick={copyBtnOnClick}>
            <CopySvg/>
        </CopyIconWrapper>
    </Wrapper>
}