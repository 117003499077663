import {IClipboardHelper} from "./IClipboardHelper";

export class ClipboardHelper implements IClipboardHelper {
    async copyText(text: string): Promise<void> {
        if (navigator.clipboard === undefined) {
            throw new Error('navigator.clipboard is undefined');
        }

        await navigator.clipboard.writeText(text);
    }

    async getTextFromBuffer(): Promise<string> {
        if (navigator.clipboard === undefined) {
            throw new Error('navigator.clipboard is undefined');
        }

        return await navigator.clipboard.readText();
    }
}
