import {
    UserProfileDataDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserProfileDataDto";

export enum UserProfileLoadState {
    WAIT_FOR_AUTH = 0,
    NOT_AUTHORIZED = 1,
    WAIT_AUTH_RESULT = 2,
    AUTHORIZED = 3,
    AUTH_ERROR = 4
}

export enum UserProfileType {
    STUDENT = 1,
    TEACHER = 2
}

export enum UserActionTypes {
    LOAD_USER_DATA_BY_SESSION_TOKEN = '@@user/START_USER_AUTHORIZATION',
    START_USER_AUTH_ON_WS_CONNECTION = '@@user/START_USER_AUTH_ON_WS_CONNECTION',
    START_USER_RECONNECTION_AUTH_ON_WS_CONNECTION = '@@user/START_USER_RECONNECTION_AUTH_ON_WS_CONNECTION',
    PROCESS_USER_WS_AUTH_RESULT = '@@user/PROCESS_USER_WS_AUTH_RESULT',
    ACTIVATE_AGREEMENT_ID = '@@user/ACTIVATE_AGREEMENT_ID',
    SAVE_USER_UTM = '@@user/SAVE_USER_UTM',
    SET_USER_PROFILE_TYPE = '@@user/SET_USER_PROFILE_TYPE',
    SET_USER_AVATAR_FILE_ID = '@@user/SET_USER_AVATAR_FILE_ID',
    SET_USER_SESSION_TOKEN = '@@user/SET_USER_SESSION_TOKEN',
    SET_USER_ST_TOKEN = '@@user/SET_USER_ST_TOKEN',
    SET_USER_PROFILE_LOAD_STATE = '@@user/SET_USER_PROFILE_LOAD_STATE',
    SET_USER_PROFILE_DATA = '@@user/SET_USER_PROFILE_DATA',
    SET_USER_TEACHER_ACTUAL_PARAMS = '@@user/SET_USER_TEACHER_ACTUAL_PARAMS',
    SET_USER_IS_MODERATOR = '@@user/SET_USER_IS_MODERATOR',
    SET_SELECTED_AGREEMENT_ID = '@@user/SET_SELECTED_AGREEMENT_ID',
    SET_WS_RECONNECTION_TOKEN = '@@user/SET_WS_RECONNECTION_TOKEN',
    SET_UPDATES_AFTER_INITIAL_CUSTOMIZER_FINISHED = '@@user/SET_UPDATES_AFTER_INITIAL_CUSTOMIZER_FINISHED',
    LOGOUT_USER = '@@user/LOGOUT_USER'
}

export interface UserState {
    profileType: UserProfileType | null;
    sessionToken: string | null;
    stToken: string | null;
    authState: UserProfileLoadState;
    profileData: UserProfileDataDto | null;
    selectedSchoolId: string | null;
    selectedUserInSchoolId: string | null;
    selectedAgreementId: string | null;
    wsReconnectionToken: string | null;
    userIsModerator: boolean;
    support: {
        phone: string,
    }
}

export interface SetSelectedUserIdReducerInput {
    schoolId: string;
    userInSchoolId: string;
    agreementId: string | null;
}

export class AuthUserRequestContext {
    authByReconnectionToken: boolean;

    constructor(authByReconnectionToken: boolean) {
        this.authByReconnectionToken = authByReconnectionToken;
    }
}