import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import styled from "styled-components";
import {ReactComponent as ArrowDownSvgIcon} from "../../../../components/Ui/Svg/ArrowDown16.svg";
import {ParagraphDefaultStyle} from "../../components/RichTextReader/elements/Paragraph";
import classNames from "classnames";

type UnfoldBlockType = {
    title: CustomElement[];
    body: CustomElement[];
}

interface UnfoldBlockProps extends IElementProps<UnfoldBlockType> {
}

interface UnfoldBlockRefMethods extends IElementRefMethods {
}

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.accentDivider};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 20px;
  user-select: none;
  cursor: pointer;

  &.deployed {
    border-radius: 20px 20px 0 0;
  }
`;

const RichTextWrapper = styled.div`
  flex-grow: 1;

  padding-top: 3px;

  @media (${({theme}) => theme.media.small}) {
    padding-top: 0;
  }
`;

const FoldIconWrapper = styled.div`
  padding-top: 0.5em;
`;

const BodyWrapper = styled.div`
  flex-grow: 1;
  border-width: 1px;
  border-style: none solid solid solid;
  border-color: ${({theme}) => theme.colors.accentDivider};
  border-radius: 0 0 20px 20px;
  padding: 14px 20px;
`;

const UnfoldBlockTitleTextComponent = styled(ParagraphDefaultStyle)`
  margin-bottom: 0;
`;

const UnfoldBlockBodyTextComponent = styled(ParagraphDefaultStyle)`
  margin-bottom: 0;
`;

const ArrowDownSvgIconStyled = styled(ArrowDownSvgIcon)`
  &.deployed {
    transform: rotate(180deg);
  }
`;

export const UnfoldBlock = forwardRef<UnfoldBlockRefMethods, UnfoldBlockProps>(
    (props, ref) => {

        const [deployed, setDeployed] = useState<boolean>(false);

        const {elementData} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <WrapperStyle>
            <TitleWrapper onClick={() => setDeployed(!deployed)} className={classNames(deployed && "deployed")}>
                <RichTextWrapper>
                    <RichTextReader elements={elementData.data.title}
                                    paragraphComponent={UnfoldBlockTitleTextComponent}/>
                </RichTextWrapper>
                <FoldIconWrapper>
                    <ArrowDownSvgIconStyled className={classNames(deployed && "deployed")}/>
                </FoldIconWrapper>
            </TitleWrapper>
            {(deployed) && <BodyWrapper>
                <RichTextReader elements={elementData.data.body} paragraphComponent={UnfoldBlockBodyTextComponent}/>
            </BodyWrapper>}
        </WrapperStyle>;
    }
);

UnfoldBlock.displayName = 'UnfoldBlock';