export enum StreamTypeEnum {
    AUDIO = 'audio',
    VIDEO = 'video',
    SCREEN = 'screen',
    DATA = 'data'
}

export enum TrackContentHintEnum {
    MIC_AUDIO = 'speech',
    SCREEN_AUDIO = 'music',
    CAMERA_VIDEO = 'motion',
    SCREEN_VIDEO = ''
}
