import React from "react";
import {ReactComponent as OpenInNewWindowIcon} from "../../../../Ui/Svg/OpenInNewWindow.svg";
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  color: ${({theme}) => theme.colors.textSecondary};

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
`;

const TextWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 15px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const FloatVideoPlaceholder: React.FC = () => {
    return <Wrapper>
        <IconWrapper><OpenInNewWindowIcon/></IconWrapper>
        <TextWithLoader>
            <TextWrapper>
                <Trans>Видео отображается рядом</Trans>
            </TextWrapper>
        </TextWithLoader>
    </Wrapper>
}
