import React from "react";
import styled from "styled-components";
import {RegularText} from "../../../../../../styles/global-elements";
import {ReactComponent as ThreeDotsIcon} from "../../../../../../components/Ui/Svg/ThreeDots.svg";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
`;

const TextWrapper = styled(RegularText)`
    font-weight: bold;
`;

interface SlideNameProps {
    number: number;
    name: string;
    showDotsIcon: boolean;
    onClick?: () => void;
    className?: string;
}

export const SlideName = React.forwardRef<HTMLDivElement, SlideNameProps>(
    ({number, name, showDotsIcon, onClick, className}, ref) => {
        return <Wrapper ref={ref} onClick={onClick} className={className}>
            <TextWrapper>{number}. {name}</TextWrapper>
            {
                (showDotsIcon) && <ThreeDotsIcon/>
            }
        </Wrapper>
    });

