import React from 'react';
import {DtoTmSlide} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {SlidesCachedContent} from "../../../../components/SlidePlayer/PlayerContext";
import {
    DtoTmSlideContentLayoutSettings
} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlideContentLayoutSettings";

export enum LoadingStateEnum {
    NOW_LOADING,
    SUCCESS,
    ERROR
}

export type LoadedSlidesLayoutSettings = { [slideId: string]: DtoTmSlideContentLayoutSettings | null };

export interface IEditorContext {
    lessonId: string;
    slides: DtoTmSlide[] | null;
    setSlides: React.Dispatch<React.SetStateAction<DtoTmSlide[] | null>>;

    selectedSlideId: string | null;
    setSelectedSlideId: React.Dispatch<React.SetStateAction<string | null>>;

    slidesSavedContent: SlidesCachedContent;
    setSlidesSavedContent: React.Dispatch<React.SetStateAction<SlidesCachedContent>>;

    slidesDraftContent: SlidesCachedContent;
    setSlidesDraftContent: React.Dispatch<React.SetStateAction<SlidesCachedContent>>;

    slidesLayoutSettings: LoadedSlidesLayoutSettings;
    setSlidesLayoutSettings: React.Dispatch<React.SetStateAction<LoadedSlidesLayoutSettings>>;

    selectedSlide: DtoTmSlide | null;

    slideListLoadingState: LoadingStateEnum;
    setNeedReloadSlideList: (value: boolean) => void;
}

export const EditorContext = React.createContext<IEditorContext>({
    lessonId: "",
    slides: null,
    setSlides: () => {
    },

    selectedSlideId: null,
    setSelectedSlideId: () => {
    },

    slidesSavedContent: {},
    setSlidesSavedContent: () => {
    },

    slidesDraftContent: {},
    setSlidesDraftContent: () => {
    },

    slidesLayoutSettings: {},
    setSlidesLayoutSettings: () => {
    },

    selectedSlide: null,

    slideListLoadingState: LoadingStateEnum.NOW_LOADING,
    setNeedReloadSlideList: (val) => {
    }
});

export const EditorContextProvider = EditorContext.Provider;