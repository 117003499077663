import {IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length, ValidateNested} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {DtoSelfStudyTrackSubscriptionEpisodeOverviewItem} from "./DtoSelfStudyTrackSubscriptionEpisodeOverviewItem";
import {Type} from "class-transformer";

/**
 * DTO обзорных данных self-study трека для отображения в списке подписок ученика
 */
export class DtoSelfStudyTrackSubscriptionUserOverviewItem {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    name: string;

    @IsNotEmpty()
    @IsString()
    coverFileId: string;

    @IsNotEmpty()
    @IsString()
    coverMaskId: string;

    @IsNotEmpty()
    @IsString()
    accentColor: string;

    @IsNotEmpty()
    @IsString()
    shortDescription: string;

    @IsOptional()
    @IsString()
    lastChargeTs: string | null;

    @IsNotEmpty()
    @IsNumber()
    chargingValue: number;

    @IsNotEmpty()
    @IsBoolean()
    hasNewEpisode: boolean;

    @IsNotEmpty()
    @IsString()
    lastEpisodeId: string;

    @IsNotEmpty()
    @IsNumber()
    lastEpisodeNumber: number;

    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoSelfStudyTrackSubscriptionEpisodeOverviewItem)
    episodes: DtoSelfStudyTrackSubscriptionEpisodeOverviewItem[];
}
