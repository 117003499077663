import {instanceCachingFactory} from 'tsyringe';
import {IRouter} from "../components/WsApiClient/Router/IRouter";
import {Router} from "../components/WsApiClient/Router/Router";
import {wsRoutesList} from "../config/WsRoutes";

export default instanceCachingFactory<IRouter>((container) => {
    return new Router(
        wsRoutesList
    );
});
