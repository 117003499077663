import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {EditorNavigationSide as EditorNavigationSideOriginal, EditorNavigationSideProps} from "./navigation";
import {EditorContentSide as EditorContentSideOriginal, EditorContentSideRefMethods} from "./content-side";
import {ElementTypeEnum} from "../../../../components/SlidePlayerEditorCommonParts/ElementTypeEnum";
import {EditorContextProvider, IEditorContext, LoadedSlidesLayoutSettings, LoadingStateEnum} from "./EditorContext";
import {DtoTmSlide} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {SlidesCachedContent} from "../../../../components/SlidePlayer/PlayerContext";

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const EditorLargeNavigation = styled(EditorNavigationSideOriginal)<EditorNavigationSideProps>`
  min-width: 344px;
  max-width: 344px;
`;

const EditorSmallNavigation = styled(EditorNavigationSideOriginal)<EditorNavigationSideProps>``;

const EditorContentSide = styled(EditorContentSideOriginal)`
  flex-grow: 1;
  max-width: 700px;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
  }
`;

interface LessonEditorProps {

}

export const LessonEditor: React.FC<LessonEditorProps> = (props) => {
    const {lessonId} = useParams();

    const editorContentSide = useRef<EditorContentSideRefMethods>(null);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLargeScreen = useMediaQuery(`(${currentTheme.media.large})`);
    const sessionToken = useSelector(sessionTokenSelector);

    // Оригинальные dto слайдов
    const [slides, setSlides] = useState<DtoTmSlide[] | null>(null);
    const [selectedSlideId, setSelectedSlideId] = useState<string | null>(null);
    const [slidesSavedContent, setSlidesSavedContent] = useState<SlidesCachedContent>({});
    const [slidesLayoutSettings, setSlidesLayoutSettings] = useState<LoadedSlidesLayoutSettings>({});
    const [needSelectFirstSlide, setNeedSelectFirstSlide] = useState<boolean>(false);

    const [needReloadSlideList, setNeedReloadSlideList] = useState<boolean>(false);
    const [slideListLoadingState, setSlideListLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOW_LOADING);

    // Данные слайдов текущего сеанса (черновые, ещё не отправленные на сервер)
    const [slidesDraftContent, setSlidesDraftContent] = useState<SlidesCachedContent>({});

    const selectedSlide = useMemo<DtoTmSlide | null>(() => {
        if ((slides === null) || (selectedSlideId === null)) {
            return null;
        }

        const item = slides.find((item) => (item.id === selectedSlideId));

        if (item === undefined) {
            return null;
        }

        return item;
    }, [slides, selectedSlideId]);

    const editorContext = useMemo<IEditorContext>(() => {
        return {
            lessonId: (lessonId) ?? "",
            slides,
            setSlides,
            selectedSlideId,
            setSelectedSlideId,
            slidesSavedContent,
            setSlidesSavedContent,
            slidesDraftContent,
            setSlidesDraftContent,
            slidesLayoutSettings,
            setSlidesLayoutSettings,
            selectedSlide,
            slideListLoadingState,
            setNeedReloadSlideList
        }
    }, [lessonId, slides, selectedSlideId, slidesSavedContent, slidesDraftContent, slidesLayoutSettings, selectedSlide, slideListLoadingState]);

    const paletteElementOnSelect = (elementType: ElementTypeEnum) => {
        editorContentSide.current?.putElement(elementType);
    }

    const loadSlidesList = useCallback(() => {
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if ((!sessionToken) || (!lessonId)) {
            return;
        }

        setSlideListLoadingState(LoadingStateEnum.NOW_LOADING);

        httpApiClient.tmGetSlides(sessionToken, lessonId, 1, 200)
            .then((data) => {
                setSlides((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setNeedSelectFirstSlide(true);
                setSlideListLoadingState(LoadingStateEnum.SUCCESS);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_SLIDES_API, 'Error on loading slides list: ', err);

                setSlides([]);
                setSlideListLoadingState(LoadingStateEnum.ERROR);
            });
    }, [lessonId, sessionToken]);

    useEffect(() => {
        if ((!needSelectFirstSlide) || (slides === null) || (slides.length < 1)) {
            return;
        }

        setNeedSelectFirstSlide(false);
        setSelectedSlideId(slides[0].id);
    }, [needSelectFirstSlide, slides]);

    useEffect(() => {
        if (slideListLoadingState === LoadingStateEnum.NOW_LOADING) {
            loadSlidesList();
        }
    }, [loadSlidesList, slideListLoadingState]);

    useEffect(() => {
        if ((!needSelectFirstSlide) || (slides === null) || (slides.length < 1)) {
            return;
        }

        setNeedSelectFirstSlide(false);
        setSelectedSlideId(slides[0].id);
    }, [needSelectFirstSlide, slides]);

    useEffect(() => {
        if (needReloadSlideList) {
            setNeedReloadSlideList(false);
            setSlideListLoadingState(LoadingStateEnum.NOW_LOADING);
            setSlides([]);
        }
    }, [needReloadSlideList]);

    return (!lessonId) ? <div></div> :
        (
            <>
                <PageTitle><Trans>Редактирование урока</Trans></PageTitle>
                <EditorContextProvider value={editorContext}>
                    <EditorWrapper>
                        <EditorContentSide ref={editorContentSide}/>
                        {(isLargeScreen) && <EditorLargeNavigation paletteElementOnSelect={paletteElementOnSelect}/>}
                    </EditorWrapper>
                    {(!isLargeScreen) && <EditorSmallNavigation paletteElementOnSelect={paletteElementOnSelect}/>}
                </EditorContextProvider>
            </>
        );
}