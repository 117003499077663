import React from "react";
import {StreamTypeEnum} from "../../../../../components/JanusClient/Types";

export interface IVideoSubscriptionsActionsContext {
    subscribeToVideoStream: (userId: string, streamType: StreamTypeEnum, subscriptionId: string) => void;
    unsubscribeFromVideoStream: (userId: string, streamType: StreamTypeEnum, subscriptionId: string) => void;
}

export const VideoSubscriptionsActionsContext = React.createContext<IVideoSubscriptionsActionsContext>({
    subscribeToVideoStream: () => {
    },
    unsubscribeFromVideoStream: () => {
    }
});

export const VideoSubscriptionsActionsContextProvider = VideoSubscriptionsActionsContext.Provider;
