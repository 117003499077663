import {
    DtoGroupSchedule
} from "../../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import React, {useMemo} from "react";
import {t} from "@lingui/macro";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IGroupScheduleReader} from "../../../GroupScheduleReader/IGroupScheduleReader";
import {GroupScheduleReaderResultItemType} from "../../../GroupScheduleReader/GroupScheduleReaderResultItemType";

interface GroupScheduleAsTextProps {
    schedule: DtoGroupSchedule;
    delimiter?: JSX.Element | string;
}

export const GroupScheduleAsText: React.FC<GroupScheduleAsTextProps> = (props) => {
    
    const groupScheduleReader = useMemo<IGroupScheduleReader>(
        () => container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER),
        []
    );
    
    const scheduleItems = useMemo<GroupScheduleReaderResultItemType[]>(() => {
        return groupScheduleReader.readSchedule(props.schedule);
    }, [groupScheduleReader, props.schedule]);
    
    const weekDayName = (num: number) => {
        switch (num) {
            case 1:
                return t`Пн`;
            case 2:
                return t`Вт`;
            case 3:
                return t`Ср`;
            case 4:
                return t`Чт`;
            case 5:
                return t`Пт`;
            case 6:
                return t`Сб`;
            case 0:
                return t`Вс`;
        }
    }

    const delimiter = useMemo<JSX.Element | string>(() => {
        return (props.delimiter) ?? ', ';
    }, [props]);

    return <span>
        {
            scheduleItems.map((item, index) => {
                return <span key={item.weekDayNum}>
                    {weekDayName(item.weekDayNum)}: {item.timeStart} - {item.timeEnd}{(index < scheduleItems.length - 1) ? delimiter : ''}
                </span>;
            })
        }
    </span>
}