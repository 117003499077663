import {takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";

/**
 * Сага отвечает за запуск перезагрузки приложения.
 */
export function* watchRebootApp() {
    yield takeEvery(
        AppActionTypes.REBOOT_APP,
        rebootApp
    );
}

function* rebootApp() {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.APPLICATION, 'Restarting app...');

    yield window.location.href = "/";
}
