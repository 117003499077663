import {all, fork} from 'redux-saga/effects';
import {watchStartAppInitialization} from "./app/AppInit";
import {watchSelectLocale} from "./i18n/i18nSelectLocale";
import {watchChangeTheme} from "./layout/ChangeTheme";
import {watchLoadUserDataBySessionToken} from "./user/LoadUserDataBySessionToken";
import {watchRebootApp} from "./app/RebootApp";
import {watchLogoutUser} from "./user/Logout";
import {watchActivateAgreementId} from "./user/ActivateAgreementId";
import {watchUploadNewFile} from "./uploadQueue/uploadNewFile";
import {watchRestartFileUploading} from "./uploadQueue/restartFileUploading";
import {watchFetchFileInfo} from "./fileFormatsRepo/fetchFileInfo";
import {watchPingResult} from "./app/PingResult";
import {watchHandleEvent} from "./streamEvent/HandleEventCycle";
import {watchIncomingEvent} from "./streamEvent/IncomingEvent";
import {watchLoadEventsAfterReconnect} from "./streamEvent/LoadEventsAfterReconnect";
import {watchLoadEventsAfterReconnectResult} from "./streamEvent/LoadEventsAfterReconnectResult";
import {watchStartHandleEvent} from "./streamEvent/StartHandleEvent";
import {watchStopHandleEvent} from "./streamEvent/StopHandleEvent";
import {watchWsConnected} from "./ws-handlers/connection/WsConnected";
import {watchWsDisconnected} from "./ws-handlers/connection/WsDisconnected";
import {watchStartUserAuthOnWsConnection} from "./user/StartUserAuthOnWsConnection";
import {watchStartUserReconnectionAuthOnWsConnection} from "./user/StartUserReconnectionAuthOnWsConnection";
import {watchUserWsAuthorizationResult} from "./user/UserWsAuthorizationResult";
import {watchReportLocationPosition} from "./app/ReportLocationPosition";
import {watchLoadRoomMembersList} from "./lessonRoom/loadRoomMembersList";
import {watchHandleRoomMembersListResult} from "./lessonRoom/handleRoomMembersListResult";
import {watchHandleGetOpenedRoomsResponse} from "./lessonRooms/handleGetOpenedRoomsResponse";
import {watchHandleSeLessonRoomOpened} from "./lessonRooms/handleSeLessonRoomOpened";
import {watchHandleSeLessonRoomClosed} from "./lessonRooms/handleSeLessonRoomClosed";
import {watchHandleSeLessonRoomKicked} from "./lessonRooms/handleSeLessonRoomKicked";
import {watchHandleLeaveFromRoomViaRouter} from "./lessonRoom/handleLeaveFromRoomViaRouter";
import {watchHandleRoomMemberEntered} from "./lessonRoom/handleRoomMemberEntered";
import {watchHandleRoomMemberLeft} from "./lessonRoom/handleRoomMemberLeft";
import {watchLoadLessonMaterialsList} from "./lessonMaterials/loadLessonMaterialsList";
import {watchHandleNeedSyncSlideList} from "./lessonMaterials/handleNeedSyncSlideList";
import {watchHandleUserEventValueChange} from "./slidesWorkData/handleUserEventValueChange";
import {watchHandleUserEventAddAnswer} from "./slidesWorkData/handleUserEventAddAnswer";
import {watchHandleUserEventChangeAdditionalData} from "./slidesWorkData/handleUserEventChangeAdditionalData";
import {watchNewItemToQueue} from "./slidesWorkDataSaveQueue/newItemToQueue";
import {watchStartSaveDataAfterReconnect} from "./slidesWorkDataSaveQueue/startSaveDataAfterReconnect";
import {watchSendQueue} from "./slidesWorkDataSaveQueue/sendQueue";
import {watchStopHandleCycle} from "./slidesWorkDataSaveQueue/stopHandleCycle";
import {watchStartHandleCycle} from "./slidesWorkDataSaveQueue/startHandleCycle";
import {watchLoadSlidesWorkData} from "./slidesWorkData/loadSlidesWorkData";
import {watchSetBodyScrollAvailable} from "./layout/BodyScroll";
import {watchSubscribeToSlideList} from "./lessonMaterials/subscribeToSlideList";
import {watchUnsubscribeFromSlideList} from "./lessonMaterials/unsubscribeFromSlideList";
import {watchProcessStudentHomeworkDataItemsList} from "./slidesWorkData/processStudentHomeworkDataItemsList";
import {watchProcessStudentLessonWorkDataItemsList} from "./slidesWorkData/processStudentLessonWorkDataItemsList";
import {watchHandleRoomMemberOpenedSlideChanged} from "./lessonRoom/handleRoomMemberOpenedSlideChanged";
import {watchHandleSEStudentLessonWorkDataItemsList} from "./slidesWorkData/handleSEStudentLessonWorkDataItem";
import {
    watchHandleSEStudentLessonSlideWorkDataUpdated
} from "./slidesWorkData/handleSEStudentLessonSlideWorkDataUpdated";
import {watchHandleUserEventAdmitDefeat} from "./slidesWorkData/handleUserEventAdmitDefeat";
import {watchHandleSeMemberPublicFullScreenState} from "./lessonRoom/publicState/handleFullScreenState";
import {watchHandleSeMemberPublicVideoPinState} from "./lessonRoom/publicState/handleVideoPinState";
import {watchHandleSeMemberPublicScreenOrientation} from "./lessonRoom/publicState/handleScreenOrientation";
import {watchProcessScreenOrientationOnChange} from "./lessonRoom/publicState/processScreenOrientationOnChange";
import {watchNewWindowOpened} from "./app/NewWindowOpened";
import {watchLogoutInAnotherWindow} from "./app/LogoutInAnotherWindow";
import {watchSendDeviceInfo} from "./app/SendDeviceInfo";
import {watchContentLayoutSettings} from "./layout/ContentLayoutSettingsHandler";
import {watchHandleUserEventSetOverriddenParams} from "./slidesWorkData/handleUserEventSetOverriddenParams";
import {watchCollectUtm} from "./app/CollectUtm";
import {watchSaveUserUtm} from "./user/SaveUserUtm";

export const rootSaga = function* root() {
    yield all([
        fork(watchStartAppInitialization),
        fork(watchSelectLocale),
        fork(watchChangeTheme),
        fork(watchLoadUserDataBySessionToken),
        fork(watchRebootApp),
        fork(watchLogoutUser),
        fork(watchActivateAgreementId),
        fork(watchUploadNewFile),
        fork(watchRestartFileUploading),
        fork(watchFetchFileInfo),
        fork(watchPingResult),
        fork(watchHandleEvent),
        fork(watchIncomingEvent),
        fork(watchLoadEventsAfterReconnect),
        fork(watchLoadEventsAfterReconnectResult),
        fork(watchStartHandleEvent),
        fork(watchStopHandleEvent),
        fork(watchWsConnected),
        fork(watchWsDisconnected),
        fork(watchStartUserAuthOnWsConnection),
        fork(watchStartUserReconnectionAuthOnWsConnection),
        fork(watchUserWsAuthorizationResult),
        fork(watchReportLocationPosition),
        fork(watchLoadRoomMembersList),
        fork(watchHandleRoomMembersListResult),
        fork(watchHandleGetOpenedRoomsResponse),
        fork(watchHandleSeLessonRoomOpened),
        fork(watchHandleSeLessonRoomKicked),
        fork(watchHandleSeLessonRoomClosed),
        fork(watchHandleLeaveFromRoomViaRouter),
        fork(watchHandleRoomMemberEntered),
        fork(watchHandleRoomMemberLeft),
        fork(watchLoadLessonMaterialsList),
        fork(watchHandleNeedSyncSlideList),
        fork(watchHandleUserEventValueChange),
        fork(watchHandleUserEventAddAnswer),
        fork(watchHandleUserEventChangeAdditionalData),
        fork(watchNewItemToQueue),
        fork(watchStartSaveDataAfterReconnect),
        fork(watchSendQueue),
        fork(watchStartHandleCycle),
        fork(watchStopHandleCycle),
        fork(watchLoadSlidesWorkData),
        fork(watchSetBodyScrollAvailable),
        fork(watchSubscribeToSlideList),
        fork(watchUnsubscribeFromSlideList),
        fork(watchProcessStudentHomeworkDataItemsList),
        fork(watchProcessStudentLessonWorkDataItemsList),
        fork(watchHandleRoomMemberOpenedSlideChanged),
        fork(watchHandleSEStudentLessonWorkDataItemsList),
        fork(watchHandleSEStudentLessonSlideWorkDataUpdated),
        fork(watchHandleUserEventAdmitDefeat),
        fork(watchHandleSeMemberPublicFullScreenState),
        fork(watchHandleSeMemberPublicVideoPinState),
        fork(watchHandleSeMemberPublicScreenOrientation),
        fork(watchProcessScreenOrientationOnChange),
        fork(watchNewWindowOpened),
        fork(watchLogoutInAnotherWindow),
        fork(watchSendDeviceInfo),
        fork(watchContentLayoutSettings),
        fork(watchHandleUserEventSetOverriddenParams),
        fork(watchCollectUtm),
        fork(watchSaveUserUtm),
    ]);
};
