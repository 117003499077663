import React, {forwardRef, useState} from "react";
import styled, {css} from "styled-components";
import {DefaultLoader} from "../../../../../../DefaultLoader";

const VideoElementCss = css`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  ${VideoElementCss};
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const VideoElement = styled.video`
  ${VideoElementCss};
`;

interface VideoPlayerProps {
    className?: string;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>((
    {className},
    ref
) => {
    const [showLoader, setShowLoader] = useState<boolean>(true);

    return <Wrapper>
        {
            (showLoader)
            && <LoaderWrapper>
            <DefaultLoader simple={true}/>
          </LoaderWrapper>
        }
        <VideoElement className={className} ref={ref} playsInline={true} autoPlay={true} onPlay={() => setShowLoader(false)}/>
    </Wrapper>
});
