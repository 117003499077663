import React from 'react';
import styled, {keyframes} from "styled-components";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ContentWidthWrapper} from "../styles";
import {RegularTextCss} from "../../../styles/global-elements";
import {Avatar} from "../../../components/Ui/Elements/Avatar";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum} from "../../../components/Ui/Elements/Button";
import {ITheme} from "../../../../services/theme/ITheme";
import useMediaQuery from "../../../../services/hooks/useMediaQuery";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {LinkAsButton} from "../../../components/Ui/Elements/LinkAsButton";

const wrapperKeyframes = (firstColor: string, secondColor: string) => keyframes`
  0% {
    background: ${firstColor};
  }
  50% {
    background: ${secondColor};
  }
  100% {
    background: ${firstColor};
  }
`;

const Wrapper = styled.div`
  padding: 5px 20px;
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight}px;
  z-index: ${({theme}) => theme.zIndices.header};
  color: ${({theme}) => theme.colors.textPrimary};
  backdrop-filter: blur(10px);
  height: auto;
  background: ${({theme}) => theme.colors.availableLessonRoomHeaderFirst};

  animation-name: ${({theme}) => wrapperKeyframes(theme.colors.availableLessonRoomHeaderFirst, theme.colors.availableLessonRoomHeaderSecond)};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const ContentWidthWrapperStyled = styled(ContentWidthWrapper)`
  margin: auto;
  padding: 5px 0;
`;

const ContentWrapper = styled.div`
  display: grid;
  //flex-direction: row;
  //align-items: center
  grid-gap: 10px;
  grid-template: "a b" "a c";
  grid-template-columns: min-content auto;

  @media (${({theme}) => theme.media.small}) {
    grid-template: "a b c";
    grid-template-columns: min-content auto min-content;
    align-items: center;
  }
`;

const IconWrapper = styled.div`
`;

const TextWrapper = styled.div`
  ${RegularTextCss};

  color: inherit;
  padding-left: 5px;

  font-weight: bold;
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ButtonStyled = styled(LinkAsButton)`
  white-space: nowrap;
  text-align: center;
`;

export const AvailableLessonRoom: React.FC = (props) => {
    const roomsList = useSelector(({lessonRooms}: ApplicationState) => lessonRooms.openedLessonRooms.list);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;
    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    if (roomsList.length === 0) {
        return null;
    }

    return <Wrapper>
        <ContentWidthWrapperStyled>
            {
                roomsList.map((item, index) => {
                    return <ContentWrapper key={index}>
                        <IconWrapper style={{gridArea: 'a'}}>
                            <Avatar userName={item.teacherName} fileId={item.teacherAvatarFileId}
                                    size={(isSmall) ? 60 : 40}/>
                        </IconWrapper>
                        <TextWrapper style={{gridArea: 'b'}}>
                            <Trans id={"{teacher} приглашает вас на занятие"} values={{teacher: item.teacherName}}/>
                        </TextWrapper>
                        <ButtonWrapper style={{gridArea: 'c'}}>
                            <ButtonStyled btnStyle={BtnStyleEnum.Primary}
                                          to={RoutesHelper.replaceParams(
                                              RoutesList.STUDENT_LESSON_PAGE,
                                              [{key: 'lessonId', value: item.roomId}]
                                          )}>
                                <Trans>Войти на занятие</Trans>
                            </ButtonStyled>
                        </ButtonWrapper>
                    </ContentWrapper>
                })
            }
        </ContentWidthWrapperStyled>
    </Wrapper>;
}
