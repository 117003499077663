import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {t} from "@lingui/macro";
import {ReactComponent as ExitSvg} from "../../../../../components/Ui/Svg/Exit.svg";
import React, {useCallback, useContext, useMemo} from "react";
import {IPlayerContext, PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";
import {HomeworkPageContext, IHomeworkPageContext} from "../HomeworkPageContext";
import styled from "styled-components";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {useNavigate} from "react-router-dom";
import {RoutesList} from "../../../../../RoutesList";
import {NextButton} from "./NextButton";

const ButtonsWrapper = styled.div`
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonStyled = styled(Button)`
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const NextButtonStyled = styled(NextButton)`
    padding: 0 10px;
    font-weight: normal;
    min-height: 40px;
`;


export enum CurrentSlideTypeEnum {
    HIDDEN,
    REGULAR_SLIDE,
    LAST_SLIDE,
    FINISH_SLIDE
}

interface NavigationButtonsProps {
    exitBtnVisible: boolean;
    className?: string;
}

export const NavigationButtons: React.FC<NavigationButtonsProps> = (props) => {
    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const pageContext = useContext<IHomeworkPageContext>(HomeworkPageContext);
    const navigate = useNavigate();

    const currentMode = useMemo<CurrentSlideTypeEnum>(() => {
        if (playerContext.selectedSlideIndex === null) {
            return CurrentSlideTypeEnum.HIDDEN;
        }

        if (playerContext.slides === null || playerContext.slides.length === 0) {
            return CurrentSlideTypeEnum.HIDDEN;
        }

        if (playerContext.slides.length - 1 > playerContext.selectedSlideIndex) {
            return CurrentSlideTypeEnum.REGULAR_SLIDE
        }

        if (playerContext.slides.length - 1 === playerContext.selectedSlideIndex) {
            return CurrentSlideTypeEnum.LAST_SLIDE;
        }

        return CurrentSlideTypeEnum.HIDDEN;
    }, [playerContext.selectedSlideIndex, playerContext.slides]);

    const cancelBtnOnClick = useCallback(() => {
        navigate(RoutesList.MAIN_PAGE);
    }, [navigate]);

    const exitButton = useMemo<JSX.Element>(() => {
        if (pageContext.finishSlideIsAvailable) {
            return <ButtonStyled btnStyle={BtnStyleEnum.Secondary} icon={<ExitSvg/>} onClick={cancelBtnOnClick}/>;
        }

        return <ConfirmDialog okText={t`Выйти`}
                              cancelText={t`Не выходить`}
                              title={t`Выйти из домашней работы?`}
                              text={t`Ещё не все задания выполнены. Вы уверены, что хотите на этом приостановиться?`}
                              okMethod={() => {
                                  cancelBtnOnClick();
                                  return Promise.resolve()
                              }}
                              trigger={
                                  <ButtonStyled btnStyle={BtnStyleEnum.Secondary}
                                                icon={<ExitSvg/>}/>
                              }
        />
    }, [cancelBtnOnClick, pageContext.finishSlideIsAvailable]);



    switch (currentMode) {
        case CurrentSlideTypeEnum.REGULAR_SLIDE: {
            return <ButtonsWrapper className={props.className}>
                {(props.exitBtnVisible) ? exitButton : <div/>}
                <NextButtonStyled/>
            </ButtonsWrapper>;
        }

        case CurrentSlideTypeEnum.LAST_SLIDE: {
            return <ButtonsWrapper className={props.className}>
                {(props.exitBtnVisible) ? exitButton : <div/>}
                <NextButtonStyled/>
            </ButtonsWrapper>
        }

        default: {
            return null;
        }
    }
}
