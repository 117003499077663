import {IsArray, IsNotEmpty, IsNumber, Max, Min} from 'class-validator';
import {DtoTmDisciplineWithLevels} from "./DtoTmDisciplineWithLevels";

/**
 * DTO ответа на запрос обзорных данных по организации-поставщику обучающих материалов
 */
export class DtoTmOverviewByOrganization {
    @IsNotEmpty()
    @IsNumber()
    @Max(10000)
    @Min(1)
    page: number;

    @IsNotEmpty()
    @IsNumber()
    @Max(500)
    @Min(1)
    limit: number;

    @IsNotEmpty()
    @IsNumber()
    @Max(10000000)
    @Min(0)
    totalCount: number;

    @IsArray()
    disciplines: Array<DtoTmDisciplineWithLevels>;
}
