import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';
import { BaseRequestDto } from '../BaseRequestDto';
import { LengthEnum } from '../../../Enums/LengthEnum';

/**
 * DTO параметров запроса восстановления пароля с исопльзованием токена восстановления пароля.
 */
export class RestorePasswordViaTokenDto extends BaseRequestDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.API_AUTH_TOKEN_LENGTH)
    token: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 250)
    authLinkUrlTemplate: string;
}
