import React, {useRef, useState} from "react";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../../DefaultLoader";
import {Avatar} from "../../../../../../Ui/Elements/Avatar";
import useResizeObserver from "@react-hook/resize-observer";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

interface AvatarStatusLayerProps {
    avatarFileId: string | null;
    userName: string;
    statusText: string | null;
}

export const AvatarStatusLayer: React.FC<AvatarStatusLayerProps> = (
    {avatarFileId, userName, statusText}
) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [avatarSize, setAvatarSize] = useState<number>(40);
    const [statusFontSize, setStatusFontSize] = useState<number>(14);

    useResizeObserver(wrapperRef, (entry) => {
        const smallestSideSize = (entry.contentRect.width > entry.contentRect.height)
            ? entry.contentRect.height
            : entry.contentRect.width;

        if (smallestSideSize < 150) {
            setStatusFontSize(10);
        } else {
            setStatusFontSize(14);
        }

        if (smallestSideSize < 300) {
            setAvatarSize(40);

            return;
        }

        if (smallestSideSize < 400) {
            setAvatarSize(60);

            return
        }

        setAvatarSize(100);
    });

    return <Wrapper ref={wrapperRef}>
        <Avatar fileId={avatarFileId} userName={userName} size={avatarSize}/>

        {
            (statusText)
            && <StatusWrapper>
            <DefaultLoader simple={true}/>
            <div style={{fontSize: `${statusFontSize}px`}}>{statusText}</div>
          </StatusWrapper>
        }
    </Wrapper>
}
