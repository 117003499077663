import React from "react";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../../../../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {LossPowerIndicator} from "./LossPowerIndicator";
import {ButtonStyleCss} from "./ButtonStyle";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    position: relative;
`;

const ButtonStyled = styled(Button)`
    ${ButtonStyleCss};
`;

const LossPowerIndicatorStyled = styled(LossPowerIndicator)`
    @media (${({theme}) => theme.media.small}) {
        position: absolute;
        right: -8px;
        top: -8px;
    }
`;

export const GoButton: React.FC = () => {
    return <Wrapper>
        <ButtonStyled
            btnStyle={BtnStyleEnum.Primary}
        >
            <Trans>Пройти</Trans>
        </ButtonStyled>
        <LossPowerIndicatorStyled/>
    </Wrapper>
}