import {IsOptional, IsString} from 'class-validator';

/**
 * DTO ответа на запрос базовой информации о школе
 */
export class DtoSchoolBaseInfoResponse {
    @IsOptional()
    @IsString()
    name: string | null;

    @IsOptional()
    @IsString()
    email: string | null;

    @IsOptional()
    @IsString()
    contacts: string | null;

    @IsOptional()
    @IsString()
    addresses: string | null;

    @IsOptional()
    @IsString()
    requisites: string | null;

    @IsOptional()
    @IsString()
    legalName: string | null;

    @IsOptional()
    @IsString()
    detailedInformation: string | null;
}