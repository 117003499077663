import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const FirstLine = styled.div`
    font-size: 14px;
    line-height: 16px;

    @media (${({theme}) => theme.media.small}) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const SecondLine = styled.div`
    font-size: 13px;
    line-height: 14px;
    color: ${({theme}) => theme.colors.textSecondary};

    @media (${({theme}) => theme.media.small}) {
        font-size: 14px;
        line-height: 18px;
    }
`;

interface ItemProps {
    firstLine: React.ReactNode;
    secondLine: React.ReactNode;
}

export const Item: React.FC<ItemProps> = (
    {
        firstLine,
        secondLine
    }
) => {
    return <Wrapper>
        <FirstLine>{firstLine}</FirstLine>
        <SecondLine>{secondLine}</SecondLine>
    </Wrapper>
}