import React, {useContext, useMemo} from "react";
import {EpisodeContext, IEpisodeContext} from "./EpisodeContext";
import styled from "styled-components";
import {Trans} from "@lingui/macro";

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    z-index: 1;
    width: 80%;

    @media (${({theme}) => theme.media.small}) {
        width: unset;
        gap: 10px;
    }
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: ${({theme}) => theme.colors.textPrimary};

    &.secret {
        opacity: 0.7;
    }

    @media (${({theme}) => theme.media.small}) {
        font-size: 18px;
    }
`;

const Subtitle = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${({theme}) => theme.colors.textSecondary};

    @media (${({theme}) => theme.media.small}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const Text: React.FC = () => {
    const {episodeItem, prevEpisodeItem, thisIsNextAfterLastActive} = useContext<IEpisodeContext>(EpisodeContext);

    const titleText = useMemo<JSX.Element>(() => {
        return (episodeItem.isSecretEpisode) ? <Trans>Секретный эпизод</Trans> : <>{episodeItem.name}</>;
    }, [episodeItem.isSecretEpisode, episodeItem.name]);

    const descriptionText = useMemo<JSX.Element | null>(() => {
        if (episodeItem.isSecretEpisode) {
            if (thisIsNextAfterLastActive && episodeItem.name !== null) {
                return <Trans>Завершите «{prevEpisodeItem?.name}», чтобы получить доступ.</Trans>;
            } else {
                return <Trans>Продолжайте заниматься, чтобы получить доступ</Trans>;
            }
        }

        if (!episodeItem.description) {
            return null;
        }

        return <>{episodeItem.description}</>;
    }, [episodeItem.description, episodeItem.isSecretEpisode, episodeItem.name, prevEpisodeItem?.name, thisIsNextAfterLastActive]);


    return <TextWrapper>
        <Title>{titleText}</Title>
        {(descriptionText) && <Subtitle>{descriptionText}</Subtitle>}
    </TextWrapper>
}