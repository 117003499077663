import React, {forwardRef, useImperativeHandle, useMemo} from "react";
import {t} from "@lingui/macro";
import {Form, FormItem, useForm} from "../../../Ui/Elements/Form";
import {Input} from "../../../Ui/Elements/TextInput";
import styled from "styled-components";
import {PopupSelector, PopupSelectorVariant} from "../../../Ui/Elements/Selectors/PopupSelector";
import {
    SlideItemInteractivityActionConditionModeEnum
} from "../../../../../enums/SlideItemInteractivityActionConditionModeEnum";
import {SlideItemInteractivityActionTypeEnum} from "../../../../../enums/SlideItemInteractivityActionNameEnum";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ActionFormMethodsInterface} from "./ActionFormMethodsInterface";
import {EditorItemInteractivityConfigAction} from "../../../SlidePlayerEditorCommonParts/EditorData";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        gap: 10px;
    }
`;

enum FormFieldNames {
    TARGET_ELEMENT = 'target_element',
    FIELD = 'field',
    CONDITION = 'condition',
    VALUE = 'value'
}

interface ShowHideSlideItemActionFormProps {
    actionConfig: EditorItemInteractivityConfigAction;
}

export const ShowHideSlideItemActionForm = forwardRef<ActionFormMethodsInterface, ShowHideSlideItemActionFormProps>(
    ({actionConfig}, ref) => {
        const [form] = useForm();

        const onFinish = () => {
        };

        useImperativeHandle(ref, () => ({
            getData: async (): Promise<EditorItemInteractivityConfigAction> => {
                const fields = await form.validateFields();

                return {
                    actionType: actionConfig.actionType,
                    actionAdditionalInfo: fields[FormFieldNames.TARGET_ELEMENT],
                    condition: {
                        fieldForCondition: fields[FormFieldNames.FIELD],
                        conditionMode: fields[FormFieldNames.CONDITION].key,
                        targetValue: fields[FormFieldNames.VALUE]
                    }
                }
            }
        }));

        const conditionTypeVariants = useMemo<PopupSelectorVariant[]>(() => {
            return [
                {
                    key: SlideItemInteractivityActionConditionModeEnum.POSITIVE,
                    name: t`Равно`
                },
                {
                    key: SlideItemInteractivityActionConditionModeEnum.NEGATIVE,
                    name: t`Не равно`
                },
                {
                    key: SlideItemInteractivityActionConditionModeEnum.INCLUDE,
                    name: t`Содержит`
                }
            ] as PopupSelectorVariant[]
        }, []);

        const conditionModeInitialValue = useMemo<PopupSelectorVariant>(() => {
            const selectedVariant = conditionTypeVariants.find(
                (item) => item.key === actionConfig.condition.conditionMode
            );

            if (!selectedVariant) {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.warning(
                    LoggerSectionsEnum.SLIDE_INTERACTIVITY_CONFIG_MODAL,
                    `Not found variant for saved condition mode: ${actionConfig.condition.conditionMode}`
                );
            }

            return selectedVariant ?? conditionTypeVariants[0];
        }, [actionConfig.condition.conditionMode, conditionTypeVariants]);

        const targetElementAliasFieldName = useMemo(() => {
            return (actionConfig.actionType === SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS)
                ? t`То показать элемент (alias)`
                : t`То скрыть элемент (alias)`
        }, [actionConfig.actionType]);

        return <div>
            <Form form={form} layout={"vertical"} onFinish={onFinish}>
                <Wrapper>
                    <FormItem
                        name={FormFieldNames.FIELD}
                        initialValue={actionConfig.condition ? actionConfig.condition.fieldForCondition : 'answer'}
                        label={t`Если поле этого элемента`}
                        rules={[
                            {
                                type: "string",
                                max: 50,
                                message: t`Название должно быть не длиннее 50 символов`
                            }
                        ]}
                    >
                        <Input status={""} type="text" size="large" readOnly={true} disabled={true}/>
                    </FormItem>
                    <FormItem
                        name={FormFieldNames.CONDITION}
                        initialValue={conditionModeInitialValue}
                        label={t`Условие сравнения`}
                    >
                        <PopupSelector variants={conditionTypeVariants}/>
                    </FormItem>
                    <FormItem
                        name={FormFieldNames.VALUE}
                        initialValue={actionConfig.condition ? actionConfig.condition.targetValue : ''}
                        label={t`Значение`}
                        rules={[
                            {
                                type: "string",
                                max: 50,
                                message: t`Название должно быть не длиннее 50 символов`
                            }
                        ]}
                    >
                        <Input status={""} type="text" size="large" readOnly={false}/>
                    </FormItem>
                </Wrapper>
                <FormItem
                    name={FormFieldNames.TARGET_ELEMENT}
                    initialValue={actionConfig.actionAdditionalInfo}
                    label={targetElementAliasFieldName}
                    rules={[
                        {
                            type: "string",
                            max: 50,
                            message: t`Alias Должен быть не длиннее 50 символов`
                        }
                    ]}
                >
                    <Input status={""} type="text" size="large" readOnly={false}/>
                </FormItem>
            </Form>
        </div>
    })