import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {LoaderWrapper, MainPageItemsWrapper} from "./style";
import {PageLoadStatus} from "../../teacher/knowledge-base/common/Enums";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {SelfStudySubscriptionsLarge} from "./sections/SelfStudySubscriptionsLarge";
import {
    DtoFreeStudentMainPageDataResponse
} from "../../../../components/HttpApiClient/ApiDto/Response/MainPage/DtoFreeStudentMainPageDataResponse";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../store/app/selector";
import {NoContent} from "./sections/NoContent";
import {EnterEmail} from "../../common/enter-email";
import {isTempProfileSelector} from "../../../../store/user/selector";
import {Header} from "./sections/Header";

export const NoSchoolContractMainPage: React.FC = () => {
    const sessionToken = useSelector(sessionTokenSelector);
    const isTempProfile = useSelector(isTempProfileSelector);

    const [pageLoadStatus, setPageLoadStatus] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [pageData, setPageData] = useState<DtoFreeStudentMainPageDataResponse | null>(null);

    const httpApiClient = useMemo(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);
    const logger = useMemo(() => container.resolve<ILogger>(DiTokens.LOGGER), []);
    const abortController = useRef<AbortController | null>(null);

    const fetchPageContent = useCallback(() => {
        if (abortController.current !== null) {
            abortController.current.abort();
            abortController.current = null;
        }

        if (!sessionToken) {
            setPageLoadStatus(PageLoadStatus.ERROR);

            return;
        }

        setPageLoadStatus(PageLoadStatus.LOADING);

        abortController.current = new AbortController();

        httpApiClient
            .getFreeStudentMainPageContent(sessionToken)
            .then((response) => {
                setPageLoadStatus(PageLoadStatus.SUCCESS);
                setPageData(response.data);
            })
            .catch((error) => {
                if (error instanceof CanceledByUser) {
                    return;
                }

                const message = 'Error fetch free student main page content';

                if (error instanceof NoConnection) {
                    logger.info(LoggerSectionsEnum.FREE_STUDENT_MAIN_PAGE, message, error);
                } else {
                    logger.error(LoggerSectionsEnum.FREE_STUDENT_MAIN_PAGE, message, error);
                }

                setPageLoadStatus(PageLoadStatus.ERROR);
            });

    }, [httpApiClient, logger, sessionToken]);

    useEffect(() => {
        fetchPageContent();

        return () => {
            if (abortController.current !== null) {
                abortController.current.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = useMemo(() => {
        switch (pageLoadStatus) {
            case PageLoadStatus.NOT_INIT:
            case PageLoadStatus.LOADING: {
                return <LoaderWrapper>
                    <DefaultLoader simple={true}/>
                </LoaderWrapper>;
            }
            case PageLoadStatus.ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => {
                }}/>;
            }

            case PageLoadStatus.SUCCESS: {
                if (pageData === null) {
                    return <></>;
                }

                if (pageData.selfStudyTrackSubscriptions.list.length === 0) {
                    return <NoContent/>; // Пока нет каталога продолжаем показывать заглушку
                }

                return <>
                    {
                        (pageData.selfStudyTrackSubscriptions.list)
                        && <SelfStudySubscriptionsLarge items={pageData.selfStudyTrackSubscriptions.list}/>
                    }
                </>;
            }
        }
    }, [pageData, pageLoadStatus]);

    return <MainPageItemsWrapper>
        {
            (isTempProfile)
                ? <EnterEmail/>
                : <Header/>
        }
        {content}
    </MainPageItemsWrapper>
}