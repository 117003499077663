import {forwardRef, useImperativeHandle} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {
    ICustomText
} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICustomText";
import classNames from "classnames";
import styled from "styled-components";

const SpanStyled = styled.span`
  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }

  &.underline {
    text-decoration: underline;
  }
`;

interface RichEditSpanProps {
    element: ICustomText;
}

export const RichEditSpan = forwardRef<IRichTextElementRefMethods, RichEditSpanProps>(
    (props, ref) => {

        const {element} = props;

        useImperativeHandle(ref, () => ({}));

        return <SpanStyled className={classNames(
            element.bold && "bold",
            element.italic && "italic",
            element.underline && "underline"
        )}>{element.text}</SpanStyled>;
    }
);

RichEditSpan.displayName = 'RichEditSpan';