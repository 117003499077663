import React from "react";
import styled from "styled-components";
import {Avatar} from "../../../../../components/Ui/Elements/Avatar";
import {connect, ConnectedProps} from "react-redux";
import {
    currentAvatarFileIdSelector,
    selectedUserInSchool as selectedUserInSchoolSelector
} from "../../../../../../store/user/selector";
import {ApplicationState} from "../../../../../../store";
import {PageSubtitleCss, RegularTextCss} from "../../../../../styles/global-elements";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ReactComponent as CakeIcon} from "../../../../../components/Ui/Svg/Cake16.svg";
import {
    ImageCropperUploader,
    ImageCropperUploaderRefMethods
} from "../../../../../components/Ui/Elements/ImageCropperUploader";
import {SetLocaleField} from "./SetLocaleField";
import {setUserAvatarFileId} from "../../../../../../store/user/actions";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {Dispatch} from "redux";
import {UserFileTypeEnum} from "../../../../../../enums/UserFileEnums";


const SMALL_SCREEN_AVATAR_SIZE = 100;
const LARGE_SCREEN_AVATAR_SIZE = 120;

const NameAndAvatarSection = styled.section`
    display: flex;
    flex-direction: column;

    margin-bottom: 50px;

    @media (${({theme}) => theme.media.large}) {
        flex-direction: row;
    }
`;

const AvatarWrapper = styled.div`
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (${({theme}) => theme.media.large}) {
        width: ${LARGE_SCREEN_AVATAR_SIZE.toString(10)}px;
        margin-bottom: 0;
        margin-right: 15px;
    }
`;

const NameWrapper = styled.div`
    flex-grow: 1;

    text-align: center;

    @media (${({theme}) => theme.media.large}) {
        text-align: left;
    }
`;

const UserName = styled.div`
    ${PageSubtitleCss};
    margin-bottom: 5px !important;

    font-weight: bold;
    display: block;

    text-align: inherit;
`;

const UserBornDateWrapper = styled.div`
    ${RegularTextCss};
    color: ${({theme}) => theme.colors.textTertiary};

    text-align: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (${({theme}) => theme.media.large}) {
        justify-content: flex-start;
    }
`;

const UserBornDateIconWrapper = styled.div`
    margin-right: 5px;
    padding-top: 1px;
`;

const UserBornDateTextWrapper = styled.div`
`;

interface GeneralSectionProps extends PropsFromRedux {
}

interface GeneralSectionState {
    windowIsLarge: boolean;
    userDateBorn: string | null;
}

class GeneralSection extends React.Component<GeneralSectionProps, GeneralSectionState> {

    protected mediaQueryLarge;
    protected dateHelperService: IDateHelperService;

    protected cropperRef: React.RefObject<ImageCropperUploaderRefMethods>;

    constructor(props: Readonly<GeneralSectionProps> | GeneralSectionProps) {
        super(props);

        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        this.state = {
            windowIsLarge: this.mediaQueryLarge.matches,
            userDateBorn: (this.props.selectedUserIdInSchool && this.props.selectedUserIdInSchool.dateBorn)
                ? this.dateHelperService.formatAsDate(
                    this.dateHelperService.dateFromString(this.props.selectedUserIdInSchool.dateBorn)
                )
                : null,
        };

        this.cropperRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    protected onWindowResize = () => {
        this.setState(() => {
            return {
                windowIsLarge: this.mediaQueryLarge.matches
            }
        })
    }

    protected handleFileId = async (uploadedFileId: string) => {
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (!this.props.sessionToken) {
            logger.error(
                LoggerSectionsEnum.AVATAR_UPLOADER,
                'Not found session token for save file id as avatar'
            );

            throw new Error('Not found session token');
        }

        try {
            await httpApiClient.setUserAvatarFile(this.props.sessionToken, uploadedFileId);
            this.props.setAvatarFileToAppState(uploadedFileId);
        } catch (e) {
            logger.error(
                LoggerSectionsEnum.AVATAR_UPLOADER,
                'Error on set avatar by file id.',
                e
            );

            throw e;
        }
    }

    render() {
        return <div>
            <NameAndAvatarSection>
                <AvatarWrapper>
                    <Avatar userName={(this.props.selectedUserIdInSchool?.name) ?? null}
                            fileId={this.props.currentAvatarFileId}
                            allowUpload={true}
                            onUploadClick={() => this.cropperRef.current?.selectFile()}
                            size={(this.state.windowIsLarge) ? LARGE_SCREEN_AVATAR_SIZE : SMALL_SCREEN_AVATAR_SIZE}/>
                    <ImageCropperUploader ref={this.cropperRef}
                                          handleFile={this.handleFileId}
                                          fileType={UserFileTypeEnum.USER_AVATAR}
                                          config={{
                                              width: 240,
                                              height: 240,
                                              shape: "round",
                                              aspectRatio: 1
                                          }}
                    />
                </AvatarWrapper>
                {
                    (this.props.selectedUserIdInSchool)
                    && <NameWrapper>
                        <UserName>{this.props.selectedUserIdInSchool.name}</UserName>
                        {
                            (this.state.userDateBorn !== null)
                            && <UserBornDateWrapper>
                                <UserBornDateIconWrapper><CakeIcon/></UserBornDateIconWrapper>
                                <UserBornDateTextWrapper>{this.state.userDateBorn}</UserBornDateTextWrapper>
                            </UserBornDateWrapper>
                        }
                    </NameWrapper>
                }
            </NameAndAvatarSection>

            <SetLocaleField/>
        </div>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    selectedUserIdInSchool: selectedUserInSchoolSelector(state),
    currentAvatarFileId: currentAvatarFileIdSelector(state),
    currentTheme: state.layout.activeTheme,
    sessionToken: sessionTokenSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAvatarFileToAppState: (avatarFileId: string) => dispatch(setUserAvatarFileId(avatarFileId))
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GeneralSection);
