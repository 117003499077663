import {IsArray, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested,} from 'class-validator';
import {DtoStudentHomeworkSlideData} from './DtoStudentHomeworkSlideData';
import {DtoResponseWithPagination} from "../DtoResponseWithPagination";
import {Type} from "class-transformer";

class DtoStudentHomeworkDataItemSlidesValidate extends DtoResponseWithPagination<DtoStudentHomeworkSlideData> {
  @IsArray()
  @ValidateNested({each: true})
  @Type(() => DtoStudentHomeworkSlideData)
  list: Array<DtoStudentHomeworkSlideData>;
}

export class DtoStudentHomeworkDataItem {
  @IsNotEmpty()
  @IsString()
  userId: string;

  @IsNotEmpty()
  @IsString()
  userName: string;

  @IsOptional()
  @IsString()
  userAvatarFileId: string | null;

  @IsNotEmpty()
  @IsNumber()
  studentStApiId: number;

  @IsNumber()
  homeworkTotalAward: number;

  @IsNumber()
  homeworkTotalMissedAward: number;

  @IsOptional()
  @IsString()
  homeworkValuesByActualParams: string | null;

  @IsOptional()
  @IsString()
  homeworkStartedAt: string | null;

  @IsOptional()
  @IsString()
  homeworkCompletedAt: string | null;

  @ValidateNested()
  @Type(() => DtoStudentHomeworkDataItemSlidesValidate)
  slides: DtoStudentHomeworkDataItemSlidesValidate;
}
