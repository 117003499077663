import {Reducer} from "redux";
import {initialState} from "./initialState";
import {StreamEventActionTypes, StreamEventItemState, StreamEventState, StreamEventStoreItem} from "./type";
import {container} from "tsyringe";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../di-factory/DiTokens";

const reducer: Reducer<StreamEventState> = (state = initialState, action) => {
    switch (action.type) {
        case StreamEventActionTypes.PUSH_EVENTS_TO_LIST: {
            let result = {...state};

            let eventsList: Array<StreamEventStoreItem<object | null>> = action.payload;

            let maxIdInBatch: string = '0';
            let maxIdIndex: number = -1;

            eventsList.forEach((eventItem) => {
                result.eventList.push(eventItem);

                if (parseInt(maxIdInBatch, 10) < parseInt(eventItem.dto.id, 10)) {
                    maxIdInBatch = eventItem.dto.id;
                    maxIdIndex = result.eventList.length - 1;
                }
            });

            if (maxIdInBatch !== '0') {
                if (
                    (result.newestEventId === null)
                    || parseInt(maxIdInBatch, 10) > parseInt(result.newestEventId, 10)
                ) {
                    result.newestEventId = maxIdInBatch;
                    result.newestEventIndex = maxIdIndex;
                }
            }

            return result;
        }
        case StreamEventActionTypes.SET_HANDLE_EVENTS_ENABLED: {
            return {...state, readyToHandle: action.payload};
        }
        case StreamEventActionTypes.SET_EVENT_STATE: {
            let payload: { eventId: string, newState: StreamEventItemState } = action.payload;

            let newEventList = state.eventList.map((value) => {
                if (value.dto.id === payload.eventId) {
                    value.state = payload.newState;
                }

                return value;
            });

            return {
                ...state,
                eventList: newEventList
            }
        }
        case StreamEventActionTypes.SET_LAST_EVENT_DATE: {
            const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

            let lastDate: string | null = state.dateOfLastEvent;
            let newDate: string = action.payload;

            if (lastDate === null) {
                return {
                    ...state,
                    dateOfLastEvent: newDate
                }
            }

            const lastDateAsObj = dateHelperService.dateFromString(lastDate);
            const newDateAsObj = dateHelperService.dateFromString(newDate);

            if (lastDateAsObj.getUTCSeconds() < newDateAsObj.getUTCSeconds()) {
                return {
                    ...state,
                    dateOfLastEvent: newDate
                }
            }

            return {...state};
        }
        case StreamEventActionTypes.DELETE_HANDLED_EVENTS: {
            let newEventList = state.eventList.filter(value => {
                return value.state !== StreamEventItemState.HANDLED;
            });

            return {
                ...state,
                eventList: newEventList
            }
        }
        default: {
            return state;
        }
    }
};

export {reducer as streamEventReducer}
