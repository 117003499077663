import React from "react";
import {StudentGroupsListModeEnum} from "../../../../components/StonlineApiClient/Enums/StudentGroupsListModeEnum";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";

interface EmptyListNotificationBoxProps {
    searchStringIsEmpty: boolean;
    listActiveMode: StudentGroupsListModeEnum;
}

export const EmptyListNotificationBox: React.FC<EmptyListNotificationBoxProps> = (
    {
        listActiveMode,
        searchStringIsEmpty
    }
) => {
    if (!searchStringIsEmpty) {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle>
                    <Trans>Не найдены группы</Trans>
                </NoticeBlockTitle>

                <NoticeBlockText>
                    <Trans>По указанному запросу не нашлось групп</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    switch (listActiveMode) {
        case StudentGroupsListModeEnum.MY_ACTIVE_GROUPS: {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Список ваших групп пуст</Trans>
                    </NoticeBlockTitle>

                    <NoticeBlockText>
                        <Trans>Группа отображается здесь, когда вы указаны в ней основным преподавателем.</Trans>
                    </NoticeBlockText>

                    <NoticeBlockText>
                        <Trans>Измените переключатель, например, на «Все группы», чтобы посмотреть больше
                            групп или создайте новую группу.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }
        case StudentGroupsListModeEnum.ALL_ACTIVE_GROUPS: {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Список активных групп пуст</Trans>
                    </NoticeBlockTitle>

                    <NoticeBlockText>
                        <Trans>Группа отображается здесь, когда сегодняшний день находится между датами начала и
                            завершения обучения в группы.</Trans>
                    </NoticeBlockText>

                    <NoticeBlockText>
                        <Trans>Измените переключатель, например, на «Все группы», чтобы посмотреть больше
                            групп или создайте новую группу.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }
        case StudentGroupsListModeEnum.ENDED_GROUPS: {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Список завершивших обучение групп пуст</Trans>
                    </NoticeBlockTitle>

                    <NoticeBlockText>
                        <Trans>Группа отображается здесь, когда дата завершения обучения группы осталась в
                            прошлом.</Trans>
                    </NoticeBlockText>

                    <NoticeBlockText>
                        <Trans>Измените переключатель, например, на «Все группы», чтобы посмотреть больше
                            групп.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }

        case StudentGroupsListModeEnum.DELETED_GROUPS: {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Список удалённых групп пуст</Trans>
                    </NoticeBlockTitle>

                    <NoticeBlockText>
                        <Trans>Некоторые группы удалить нет возможности. Когда вы удалите такую группу, то она будет
                            отображаться здесь.</Trans>
                    </NoticeBlockText>

                    <NoticeBlockText>
                        <Trans>Измените переключатель, например, на «Все группы», чтобы посмотреть больше
                            групп.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }

        case StudentGroupsListModeEnum.ALL_GROUPS: {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Список групп пуст</Trans>
                    </NoticeBlockTitle>

                    <NoticeBlockText>
                        <Trans>Сейчас у вас не создано групп, но обычно в этом режиме отображаются все группы: и
                            активные, и удалённые, и завершившие обучение.</Trans>
                    </NoticeBlockText>

                    <NoticeBlockText>
                        <Trans>Создайте свою первую группу</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }
    }
}