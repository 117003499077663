import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';
import { BaseRequestDto } from '../BaseRequestDto';
import { LengthEnum } from '../../../Enums/LengthEnum';

/**
 * DTO параметров запроса регистрации пользователя.
 */
export class RegisterDto extends BaseRequestDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.USER_LOGIN_LENGTH)
    email: string;

    @IsString()
    @Length(1, 250)
    authLinkUrlTemplate: string;
}
