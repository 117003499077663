import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useTheme} from "styled-components";

export enum CanvasLineType {
    BLUE,
    GREEN
}

interface Point {
    X: number;
    Y: number;
}

export interface Line {
    from: Point;
    to: Point;
}

export interface CanvasProps {
    width: number;
    height: number;
    greenLines: Line[];
    blueLine: Line | null;
    className?: string;
}

interface CanvasRefMethods {
}

export const Canvas = forwardRef<CanvasRefMethods, CanvasProps>(
    (props, ref) => {

        const {width, height, blueLine, greenLines, className} = props;

        const theme = useTheme();

        const canvasRef = useRef<HTMLCanvasElement>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        useEffect(() => {
            if (!canvasRef.current) {
                return;
            }

            const ctx = canvasRef.current.getContext('2d');

            if (!ctx) {
                return;
            }

            ctx.clearRect(0, 0, width, height);

            greenLines.forEach((line) => {
                ctx.beginPath();

                ctx.moveTo(line.from.X, line.from.Y);
                ctx.lineTo(line.to.X, line.to.Y);

                ctx.lineWidth = 2;

                ctx.strokeStyle = theme.slideContent.matchExerciseConnected;
                ctx.stroke();
            });

            if (blueLine !== null) {
                ctx.beginPath();

                ctx.moveTo(blueLine.from.X, blueLine.from.Y);
                ctx.lineTo(blueLine.to.X, blueLine.to.Y);

                ctx.lineWidth = 2;

                ctx.strokeStyle = theme.slideContent.matchExerciseBlue;
                ctx.stroke();
            }

        }, [blueLine, greenLines, height, theme, width]);

        return <canvas className={className} ref={canvasRef} width={width} height={height}></canvas>;
    }
);

Canvas.displayName= 'Canvas';