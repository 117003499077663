import {IsNotEmpty} from 'class-validator';
import {DtoGetUsedInGroupTmItemsResponseItem} from "./DtoGetUsedInGroupTmItemsResponseItem";

/**
 * DTO ответа на запрос используемых когда-либо учебных материалах на уроке
 */
export class DtoGetUsedInGroupTmItemsResponse {
    @IsNotEmpty()
    organizations: DtoGetUsedInGroupTmItemsResponseItem[];

    @IsNotEmpty()
    disciplines: DtoGetUsedInGroupTmItemsResponseItem[];

    @IsNotEmpty()
    levels: DtoGetUsedInGroupTmItemsResponseItem[];

    @IsNotEmpty()
    sections: DtoGetUsedInGroupTmItemsResponseItem[];

    @IsNotEmpty()
    lessons: DtoGetUsedInGroupTmItemsResponseItem[];
}
