import styled from "styled-components";
import React from "react";
import {ReactComponent as CloudDownloadSvg} from "../../../components/Ui/Svg/CloudDownload.svg";
import {Trans} from "@lingui/macro";
import {RegularTextCss} from "../../../styles/global-elements";
import {LinkAsButton} from "../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum} from "../../../components/Ui/Elements/Button";

const Wrapper = styled(LinkAsButton)`
    // color: ${({theme}) => theme.colors.textPrimary};
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

const IconWrapper = styled.div`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
`;
const TextWrapper = styled.div`
    //${RegularTextCss};
`;

export const DownloadBtn: React.FC = () => {
    return <Wrapper btnStyle={BtnStyleEnum.Primary}
                    to={'https://old-api.stonline.club/ac_students.exe'}
                    target={'_blank'}
    >
        <ContentWrapper>
            <IconWrapper>
                <CloudDownloadSvg/>
            </IconWrapper>
            <TextWrapper>
                <Trans>Скачать программу (16 Мб)</Trans>
            </TextWrapper>
        </ContentWrapper>
    </Wrapper>
}
