import React, {forwardRef, useImperativeHandle, useMemo} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import styled from "styled-components";
import {AUDIO_ELEMENT_HEIGHT, ElementData} from "../../../SlidePlayerEditorCommonParts/components/Audio/Common";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Audio/PresenterComponent";

const AudioElementWrapper = styled.div`
  padding-top: 5px;
`;

interface AudioProps extends IElementProps<ElementData> {
}

interface AudioRefMethods extends IElementRefMethods {
}

export const Audio = forwardRef<AudioRefMethods, AudioProps>(
    (props, ref) => {

        const {elementData} = props;

        const videoData = useMemo<ElementData>(() => {
            return elementData.data;
        }, [elementData]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <AudioElementWrapper style={{height: AUDIO_ELEMENT_HEIGHT.toString(10) + 'px'}}>
            <PresenterComponent elementData={videoData} inConstructor={true}/>
        </AudioElementWrapper>;
    }
);

Audio.displayName = 'SlideElementAudio';