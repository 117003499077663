import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {ICustomText} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICustomText";
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export class ElementCounter {
    static countElementTypesInEditorData(desiredElementType: ElementTypes, editorDataElements: CustomElement[] | ICustomText[]): number {
        let foundItemsCount = 0;

        const editorDataItemsCount = (editorDataElements !== null) ? editorDataElements.length : 0;

        for (let index = 0; index < editorDataItemsCount; index++) {
            const elementAsUnknown = editorDataElements[index] as any;

            if (elementAsUnknown.type === undefined) {
                // Если тип не указан, значит это просто текст
                continue;
            }

            // Если указан тип, это какой-то сложный компонент с children
            const elementAsElement = elementAsUnknown as CustomElement;

            if (elementAsElement.type === desiredElementType) {
                foundItemsCount++;
            }

            if (
                (elementAsElement.children !== undefined)
                && (Array.isArray(elementAsElement.children))
                && (elementAsElement.children.length > 0)
            ) {
                foundItemsCount += ElementCounter.countElementTypesInEditorData(
                    desiredElementType,
                    elementAsElement.children
                );
            }
        }

        return foundItemsCount;
    }
}
