import React, {useContext, useMemo} from 'react';
import styled, {css} from "styled-components";
import {Trans} from "@lingui/macro";
import {ProgressBar} from "../ProgressBar";
import {NumberEasing} from "../NumberEasing";
import {IPlayerContext, PlayerContext} from "../SlidePlayer/PlayerContext";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {SlideWorkData} from "../../../store/slidesWorkData/type";

const LessonPlayerScoreCounterStyled = styled.div`
  background: ${({theme}) => theme.colors.backgroundPrimary};
  text-align: left;
`;

const ScoreCountWrapper = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
`;

const CurrentScoreCountSpan = styled.div`
  display: inline-block;
`;

const ScoreCountStyle = css`
  display: inline-block;
  color: ${({theme}) => theme.colors.textPrimary};
`;

const ScoreCount = styled(NumberEasing)`
  ${ScoreCountStyle};
`;

const ScoreCountNoValue = styled.div`
  ${ScoreCountStyle};
`;

const TotalScoreCountSpan = styled.div`
  display: inline-block;
  color: ${({theme}) => theme.colors.textTertiary};
`;

export interface LessonPlayerScoreCounterProps {
    onlyProgress: boolean;
    className?: string;
}

export const LessonPlayerScoreCounter: React.FC<LessonPlayerScoreCounterProps> = (props) => {
    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const slidesWorkDataState = useSelector(({slidesWorkData}: ApplicationState) => slidesWorkData);

    const {selectedSlideId} = playerContext;

    const selectedSlideData = useMemo<SlideWorkData | null>(() => {
        if (!selectedSlideId) {
            return null;
        }
        
        const playerIndex = slidesWorkDataState.indexByPlayerId[playerContext.playerId];
        
        if (playerIndex === undefined) {
            return null;
        }
        
        const slideIndex = playerIndex.indexBySlideId[selectedSlideId];
        
        if ((slideIndex === undefined) || (slidesWorkDataState.slides[slideIndex] === undefined)) {
            return null;
        }

        return slidesWorkDataState.slides[slideIndex];
    }, [playerContext.playerId, selectedSlideId, slidesWorkDataState.indexByPlayerId, slidesWorkDataState.slides]);

    const totalAward = useMemo<number>(() => {
        return (selectedSlideData) ? selectedSlideData.totalAward : 0;
    }, [selectedSlideData]);

    const missedAward = useMemo<number>(() => {
        return (selectedSlideData) ? selectedSlideData.missedAward : 0;
    }, [selectedSlideData]);

    const noValue = useMemo<boolean>(() => {
        if (!selectedSlideId) {
            return true;
        }

        return (totalAward + missedAward === 0);
    }, [selectedSlideId, totalAward, missedAward]);

    return <LessonPlayerScoreCounterStyled className={props.className}>
        {!props.onlyProgress && <ScoreCountWrapper>
            <CurrentScoreCountSpan>
                {
                    (noValue)
                        ? <ScoreCountNoValue>0</ScoreCountNoValue>
                        : <ScoreCount value={totalAward}/>
                }
            </CurrentScoreCountSpan>&nbsp;<TotalScoreCountSpan>
            <Trans>из 100</Trans>
        </TotalScoreCountSpan>
        </ScoreCountWrapper>}
        <ProgressBar wrongValue={missedAward} rightValue={totalAward}/>
    </LessonPlayerScoreCounterStyled>;
};