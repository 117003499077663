import {forwardRef, useImperativeHandle} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {Exercise} from "../../../../elements/ExerciseFillGapsDragDrop/Exercise";
import {RegularText} from "../common-styles";
import {IFillGapsText} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsText";

const ElementStyled = styled.span`
  ${RegularText};
  line-height: 45px;
  
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const ExerciseFillGapsDragDrop = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IFillGapsText>>(
    (props, ref) => {

        const {element} = props;

        useImperativeHandle(ref, () => ({}));

        return <ElementStyled>
            <span><Exercise exerciseId={props.element.id ?? ""}
                            values={(element as unknown as { values: string[] }).values}/></span>
        </ElementStyled>;
    }
);

ExerciseFillGapsDragDrop.displayName = 'ExerciseFillGapsDragDrop';