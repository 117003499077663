import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {SlideItemInteractivityActionTypeEnum} from "../../../../../enums/SlideItemInteractivityActionNameEnum";
import {ShowHideSlideItemActionForm} from "./ShowHideSlideItemActionForm";
import {PopupSelector, PopupSelectorVariant} from "../../../Ui/Elements/Selectors/PopupSelector";
import {InputLabel} from "../../../../styles/global-elements";
import {ActionFormMethodsInterface} from "./ActionFormMethodsInterface";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {EditorItemInteractivityConfigAction} from "../../../SlidePlayerEditorCommonParts/EditorData";

const ActionTitle = styled.h3`
    font-feature-settings: 'pnum' on, 'lnum' on;
`;

const ActionFormWrapper = styled.div`
    list-style: none;
    margin-bottom: 50px;
`;

const DetailsWrapper = styled.div`
    margin-left: 10px;
    border-left: 1px  ${({theme}) => theme.colors.accentDivider} dashed;
    padding-left: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const FieldWrapper = styled.div`
    margin-bottom: 30px;
    
    &:nth-last-child(1) {
        margin-bottom: 0;
    }
`;

interface ActionFormProps {
    actionIndex: number;
    actionConfig: EditorItemInteractivityConfigAction;
}

export const ActionForm = forwardRef<ActionFormMethodsInterface, ActionFormProps>(
    ({actionIndex, actionConfig}, ref) => {

    const conditionFormRef = useRef<ActionFormMethodsInterface>(null);

    const [selectedActionType, setSelectedActionType]
        = useState<SlideItemInteractivityActionTypeEnum>(actionConfig.actionType as SlideItemInteractivityActionTypeEnum);

        useImperativeHandle(ref, () => ({
            getData: async ():Promise<EditorItemInteractivityConfigAction> => {
                if (!conditionFormRef.current) {
                    throw new Error('conditionFormRef.current is null');
                }

                return conditionFormRef.current.getData();
            }
        }));

    const actionFormByType = useMemo(() => {
        switch (selectedActionType) {
            case SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS:
            case SlideItemInteractivityActionTypeEnum.HIDE_ITEM_BY_ALIAS: {
                return <ShowHideSlideItemActionForm
                    ref={conditionFormRef}
                    actionConfig={{...actionConfig, actionType: selectedActionType}}
                />;
            }
        }

        return null;
    }, [actionConfig, selectedActionType]);

    const actionTypeVariants = useMemo<PopupSelectorVariant[]>(() => {
        return [
            {
                key: SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS,
                name: t`Отобразить элемент по alias`
            },
            {
                key: SlideItemInteractivityActionTypeEnum.HIDE_ITEM_BY_ALIAS,
                name: t`Скрыть элемент по alias`
            }
        ] as PopupSelectorVariant[]
    }, []);

    const selectedTypeVariant = useMemo<PopupSelectorVariant>(() => {
        const selectedVariant = actionTypeVariants.find((item) => item.key === selectedActionType);

        if (!selectedVariant) {
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.warning(
                LoggerSectionsEnum.SLIDE_INTERACTIVITY_CONFIG_MODAL,
                `Not found variant for saved action type: ${selectedActionType}`
            );
        }

        return selectedVariant ?? actionTypeVariants[0];
    }, [actionTypeVariants, selectedActionType]);

    return <ActionFormWrapper>
        <ActionTitle><Trans>Действие №{actionIndex + 1}</Trans></ActionTitle>
        <DetailsWrapper>

            <FieldWrapper>
                <InputLabel><Trans>Тип действия</Trans></InputLabel>
                <PopupSelector
                    variants={actionTypeVariants}
                    onChange={(value) =>
                        setSelectedActionType(value.key as SlideItemInteractivityActionTypeEnum)
                    }
                    value={selectedTypeVariant}
                />
            </FieldWrapper>

            <FieldWrapper>
                {actionFormByType}
            </FieldWrapper>
        </DetailsWrapper>
    </ActionFormWrapper>;
});