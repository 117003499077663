import {IsNotEmpty, IsNumber, IsString} from "class-validator";

/**
 * DTO элемента списка доступных дисциплин
 */
export class DtoCourseListItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    nominativeName: string;
}
