import {instanceCachingFactory} from 'tsyringe';
import {IDateHelperService} from "../services/date-helper/IDateHelperService";
import {DateHelperService} from "../services/date-helper/DateHelperService";
import {Ii18nService} from "../services/i18n/Ii18nService";
import {DiTokens} from "./DiTokens";

export default instanceCachingFactory<IDateHelperService>((container) => {
    const i18n = container.resolve<Ii18nService>(DiTokens.I18N_SERVICE) as Ii18nService;

    return new DateHelperService(i18n);
});
