import React, {useEffect} from 'react';
import {RoutesConfig} from "../RoutesConfig";
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {UserProfileLoadState} from "../../store/user/type";
import {RoutesList} from "../RoutesList";
import {
    userIsTeacher as userIsTeacherSelector,
    userProfileIsEmpty as userProfileIsEmptySelector
} from "../../store/user/selector";
import {container} from "tsyringe";
import {IRouterService} from "../../services/router/IRouterService";
import {DiTokens} from "../../di-factory/DiTokens";
import {needInitialCustomizerSelector} from "../../store/app/selector";

export const Index: React.FC = () => {
    const authState = useSelector<ApplicationState>(
        ({user}: ApplicationState) => user.authState
    ) as UserProfileLoadState;

    const userProfileIsEmpty = useSelector(userProfileIsEmptySelector);

    const userIsTeacher = useSelector(userIsTeacherSelector);

    const needInitialCustomizer = useSelector(needInitialCustomizerSelector);

    const router = createBrowserRouter(
        RoutesConfig(
            authState,
            userProfileIsEmpty,
            userIsTeacher,
            needInitialCustomizer
        )
    );

    useEffect(() => {
        const routerService = container.resolve<IRouterService>(DiTokens.ROUTER_SERVICE);

        routerService.navigate = router.navigate;
    }, [router.navigate]);

    useEffect(() => {
        if (authState === UserProfileLoadState.AUTH_ERROR) {
            router.navigate(RoutesList.LOAD_PROFILE_ERR);
        }
    }, [authState, router]);

    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}
