import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';
import { LengthEnum } from '../../../Enums/LengthEnum';

/**
 * DTO параметров ответа на запрос восстановления пароля с исопльзованием токена
 * восстановления пароля.
 */
export class RestorePasswordViaTokenDto {
    /**
     * Токен для быстрого входа.
     */
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.API_AUTH_TOKEN_LENGTH)
    token: string;
}
