import React from "react";
import {Editor, Element as SlateElement, Node as SlateNode, Range, Transforms} from "slate";
import {IFillGapsCombobox} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsCombobox";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const enterInFillGapsComboboxItem = (event: React.KeyboardEvent<HTMLDivElement>, editor: Editor) => {
    const {selection} = editor;

    if (!selection) return false;

    if (!Range.isCollapsed(selection)) {
        return false;
    }

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                return node.type === ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX;
            }
        })
    )

    if (!match) {
        return;
    }

    const currentNode = (match[0] as IFillGapsCombobox);

    const nodeAsString = SlateNode.string(currentNode);

    if (nodeAsString.length === 0) {
        // Ничего не введено - переходим дальше
        const nextNode = Editor.next(editor, {at: selection});

        if (!nextNode) {
            return;
        }

        const [, nextNodePath] = nextNode;

        Transforms.select(editor, Editor.start(editor, nextNodePath));
        Editor.insertText(editor, " ");

        return;
    }

    // Если что-то есть, то проверим - где стоит выделение. Если в начале, то берём всё.
    // Если нет, то берём только то, что до выделения.

    if ((selection.anchor.offset === 0) || (selection.anchor.offset === nodeAsString.length)) {
        // Если нужно взять всю строку

        const newProperties: Partial<IFillGapsCombobox> = {
            values: [
                ...currentNode.values,
                nodeAsString
            ],
            children: [{text: ""}]
        };

        Transforms.setNodes(editor, newProperties, {at: match[1]});

        const childs = Array.from(SlateNode.children(editor, match[1]));

        for (const [, childPath] of childs) {
            Transforms.removeNodes(editor, {at: childPath});
        }

        Transforms.select(editor, Editor.start(editor, match[1]));

        return;
    }

    // Курсор не в начале и не в конце строки. Возьмём только то, что ДО курсора
    const newProperties: Partial<IFillGapsCombobox> = {
        values: [
            ...currentNode.values,
            nodeAsString.substring(0, selection.anchor.offset)
        ],
        // children: [{text: nodeAsString.substring(selection.anchor.offset)}] здесь дочерний узел задать нельзя,
        // но мы сделаем это ниже
    };

    Transforms.setNodes(editor, newProperties, {at: match[1]});

    const children = Array.from(SlateNode.children(editor, match[1]));

    for (const [, childPath] of children) {
        Transforms.removeNodes(editor, {at: childPath});
    }

    Transforms.insertText(editor, nodeAsString.substring(selection.anchor.offset), {at: match[1]});

    Transforms.select(editor, Editor.start(editor, match[1]));
}