import React, {CSSProperties} from "react";
import styled from "styled-components";
import {IFillGapsCombobox} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsCombobox";
import classNames from "classnames";
import {ElementProps} from "../rich-text-editor/Element";
import {InlineChromiumBugfix} from "../InlineChromiumBugfix";

export const FillGapsComboboxStyled = styled.span`
  display: inline;
  padding: 5px 5px 10px 5px;
  position: relative;

  border-bottom: 1px dashed ${({theme}) => theme.colors.textSecondary};

  //&::before {
  //  content: " ";
  //  position: absolute;
  //  top: -5px;
  //  left: 0;
  //  right: 2px;
  //  bottom: -5px;
  //  border: 1px silver dashed;
  //  pointer-events: none;
  //  border-radius: 5px;
  //}
`;

export const FillGapsComboboxItem = styled.span`
  padding: 5px;
  border-radius: 5px;
  margin-right: 2px;

  &.correct {
    background: ${({theme}) => theme.colors.constructorCorrectAnswer};
    color: ${({theme}) => theme.colors.constructorCorrectAnswerFont};
  }

  &.incorrect {
    background: ${({theme}) => theme.colors.constructorIncorrectAnswer};
    color: ${({theme}) => theme.colors.constructorIncorrectAnswerFont};
  }
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const FillGapsCombobox: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        element,
        children
    } = props;

    return <FillGapsComboboxStyled style={{...style}} {...attributes}>
        <InlineChromiumBugfix/>
        {
            (element as IFillGapsCombobox).values.map((item, index) => {
                return <FillGapsComboboxItem contentEditable={false}
                                             className={classNames((index === 0) ? "correct" : "incorrect")}
                                             key={item + index.toString(10)}>
                    {item}
                </FillGapsComboboxItem>
            })
        }
        {children}
        <InlineChromiumBugfix/>
    </FillGapsComboboxStyled>;
}