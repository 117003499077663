import styled from "styled-components";

export const UserNameWrapper = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
  transition: opacity 0.3s;
`

export const UserAvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 40px;
  cursor: pointer;
  
  :hover ${UserNameWrapper} {
    opacity: 1;
  }
`;

export const AvatarWrapper = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;

  @media (${({theme}) => theme.media.small}) {
    margin-left: 0;
    margin-right: 12px;
  }
`