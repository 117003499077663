import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString} from 'class-validator';

/**
 * DTO элемента таблицы подписчиков трека (админка)
 */
export class DtoSelfStudyTrackAdminSubscriberListItem {
    @IsNotEmpty()
    @IsString()
    userId: string;

    @IsOptional()
    @IsString()
    email: string | null;

    @IsBoolean()
    isGuestUser: boolean;

    @IsOptional()
    @IsNumber()
    idInSchool: number | null;

    @IsOptional()
    @IsString()
    fioInSchool: string | null;

    @IsNotEmpty()
    @IsString()
    subscriptionCreatedAtUtc: string;

    @IsNotEmpty()
    @IsString()
    userRegisteredAtUtc: string;

    @IsNotEmpty()
    @IsNumber()
    currentEpisodeNumber: number;

    @IsNotEmpty()
    @IsString()
    currentEpisodeOpenedAtUtc: string;

    @IsNumber()
    chargeValue: number;

    @IsBoolean()
    hasMlUtmSource: boolean;
}
