import * as React from "react";
import {useContext, useMemo} from "react";
import {ReactComponent as PlaySvg} from "../../../../../components/Ui/Svg/Play.svg"
import {EditorContext, IEditorContext} from "../EditorContext";
import styled from "styled-components";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {RouterLink} from "../../../../../components/Ui/Elements/Link";
import {t} from "@lingui/macro";

const ButtonStyled = styled(RouterLink)`
  cursor: pointer;
  color: inherit;
`;

export const PlayLessonForTestBtn: React.FC = () => {
    const editorContext = useContext<IEditorContext>(EditorContext);

    const {lessonId} = editorContext;

    const linkToPlayer = useMemo<string>((): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_PLAYER,
            [
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );
    }, [lessonId]);

    return <ButtonStyled to={linkToPlayer} target={"_blank"} title={t`Протестировать слайды`}>
        <PlaySvg/>
    </ButtonStyled>;
}