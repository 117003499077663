import {takeEvery} from "redux-saga/effects";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {LayoutActionTypes} from "../../store/layout/type";

/**
 * Сага отвечает за фиксацию прокрутки body.
 */
export function* watchSetBodyScrollAvailable() {
    yield takeEvery<WorkerPayloadType<boolean>>(
        LayoutActionTypes.SET_BODY_SCROLL_AVAILABLE,
        setBodyScrollAvailable
    );
}

// eslint-disable-next-line require-yield
function* setBodyScrollAvailable(data: WorkerPayloadType<boolean>) {
    const tagsList = document.getElementsByTagName("body");

    if (tagsList.length === 0) {
        return;
    }

    tagsList[0].style.overflow = (data.payload) ? "" : "hidden";
}
