import React, {forwardRef, useMemo} from "react";
import {Input} from "../../TextInput";
import {t} from "@lingui/macro";
import {SubjectAreaSelectModalWindow} from "./SubjectAreaSelectModalWindow";
import {InputRef} from "antd/lib/input/Input";
import {
    DtoSubjectAreaLevel
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SubjetArea/DtoSubjectAreaLevel";

interface SubjectAreaSelectorProps {
    value?: DtoSubjectAreaLevel;
    onChange?: (value: DtoSubjectAreaLevel) => void;
}

export const SubjectAreaSelector = forwardRef<InputRef, SubjectAreaSelectorProps>(
    (props, ref) => {
        const currentValue = useMemo<string>(() => {
            if (!props.value) {
                return '';
            }

            return `${props.value.subjectArea.internationalName} ${props.value.internationalName}`;
        }, [props.value]);

        return <SubjectAreaSelectModalWindow
            trigger={
                <Input ref={ref} style={{cursor: "pointer"}} placeholder={t`Нажмите, чтобы выбрать`}
                       value={currentValue}
                       readOnly/>}
            onChange={(newValue) => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue);
                }
            }}/>
    }
)
