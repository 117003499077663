import React from 'react';
import {connect, ConnectedProps, Provider} from "react-redux";
import {Dispatch, Store} from "redux";
import {History} from "history";
import {AppInitializationStateTypes} from "../store/app/type";
import {UserProfileLoadState} from "../store/user/type";
import {ApplicationState} from "../store";
import {ThemeProvider} from "styled-components";
import {GlobalStyle} from "./styles/global";
import {SplashScreen} from "./pages/splash";
import * as appActions from '../store/app/actions';
import {Index} from "./pages";
import 'antd/dist/antd.min.css';
import {i18n} from '@lingui/core'
import {I18nProvider} from '@lingui/react'
import './fonts.css';
import {AnimatePresence, motion, Variants} from "framer-motion";
import {ConfigProvider as AntConfigProvider} from "antd";
import antEngLocale from "antd/es/locale/en_US";
import antRuLocale from "antd/es/locale/ru_RU";
import 'moment/locale/ru';
import {IWsApiClient} from "../components/WsApiClient/IWsApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../di-factory/DiTokens";
import {ApiMethodEnum} from "../components/WsApiClient/ApiMethodEnum";
import {IAnswerChecker} from "../components/AnswerChecker/IAnswerChecker";
import {LocaleStateEnum} from "../store/i18n/type";

interface AppProps extends PropsFromRedux {
    store: Store<ApplicationState>,
    storeHistory: History
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    startAppInitialization: () => dispatch(appActions.startAppInitialization())
});

class App extends React.Component<AppProps> {

    wsApiClient: IWsApiClient;

    constructor(props: Readonly<AppProps> | AppProps) {
        super(props);

        this.wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        // @ts-ignore
        window['answerChecker'] = container.resolve<IAnswerChecker>(DiTokens.ANSWER_CHECKER);
    }

    splashScreen() {
        const animations: Variants = {
            initial: {opacity: 0},
            animate: {opacity: 1},
            exit: {opacity: 0},
        }

        return (
            <AnimatePresence initial={false}>
                {
                    this.props.splashScreenVisible && (
                        <motion.div
                            variants={animations}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            style={{
                                zIndex: this.props.activeTheme.zIndices.splashScreen,
                                position: "fixed",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0
                            }}
                            transition={{type: "tween", ease: "easeInOut", duration: .2}} //*/[.25, .73, .66, .86]
                        >
                            <SplashScreen/>
                        </motion.div>
                    )
                }
            </AnimatePresence>
        );
    }

    antLocale() {
        switch (this.props.locale) {
            case 'ru-RU': {
                return antRuLocale;
            }
            default: {
                return antEngLocale;
            }
        }
    }

    render() {
        return (
            <I18nProvider i18n={i18n}>
                <Provider store={this.props.store}>
                    <ThemeProvider theme={this.props.activeTheme}>
                        <AntConfigProvider locale={this.antLocale()}>
                            <GlobalStyle/>
                            <div className="app">
                                {
                                    (
                                        (this.props.userAuthState !== UserProfileLoadState.WAIT_AUTH_RESULT)
                                        && (this.props.userAuthState !== UserProfileLoadState.WAIT_FOR_AUTH)
                                        && (
                                            (this.props.localeState === LocaleStateEnum.READY)
                                            || (this.props.localeState === LocaleStateEnum.FAILED)
                                        )
                                    ) && <Index/>
                                }
                                {this.splashScreen()}
                            </div>
                        </AntConfigProvider>
                    </ThemeProvider>
                </Provider>
            </I18nProvider>
        )
    }

    componentDidMount() {
        this.props.startAppInitialization();
        const pingPeriod = (process.env.REACT_APP_PING_REQUEST_PERIOD_SECONDS)
            ? parseInt(process.env.REACT_APP_PING_REQUEST_PERIOD_SECONDS)
            : null;

        if (!pingPeriod) {
            throw new Error('Not found ping period config value');
        }

        setInterval(
            () => {
                if (this.wsApiClient.connectionIsOpen()) {
                    this.wsApiClient.query(ApiMethodEnum.PING, {}, null).then();
                }
            },
            pingPeriod * 1000
        );
    }
}

const mapStateToProps = ({layout, i18n, user, app}: ApplicationState) => ({
    splashScreenVisible: layout.splashScreenVisible,
    locale: i18n.currentLocale,
    localeState: i18n.currentState,
    i18nCatalogs: i18n.catalogs,
    appInitState: app.appInitializationState,
    userAuthState: user.authState,
    appInFatalError: app.inFatalErrorState,
    activeTheme: layout.activeTheme,
    userAuthorized:
        (
            (app.appInitializationState === AppInitializationStateTypes.COMPLETED)
            && (user.sessionToken !== null)
        )
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
