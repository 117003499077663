import styled, {useTheme} from "styled-components";
import React, {CSSProperties, useMemo} from "react";
import {BlockWithBorderCss} from "../../../styles/global-elements";
import {StudentRateParamPositionEnum} from "../../../../enums/StudentRateParamPositionEnum";
import {CircleProgress} from "./CircleProgress";

// Вид NameLeft
const NameLeftWrapper = styled.div`
  ${BlockWithBorderCss};
  padding: 32px;
  width: 100%;
`;

const NameLeftGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100% 
`;

const NameLeftCircleCol = styled.div`
  min-width: 140px;
  max-width: 140px;
  min-height: 140px;
  max-height: 140px;
`;

const NameLeftTextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const NameLeftPercentText = styled.div`
  line-height: 28px;
  font-size: 24px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

const NameLeftNameText = styled.div`
  line-height: 18px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.textSecondary};
`;

// Вид NameBottom
const NameBottomWrapper = styled.div`
  ${BlockWithBorderCss};
  padding: 32px 26px;
  width: 100%;
  
  //.ant-progress {
  //  width: 100%;
  //}
  //
  //.ant-progress-inner {
  //  width: 100% !important;
  //  height: auto !important;
  //}
`;

const NameBottomGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100% 
`;

const NameBottomCircleCol = styled.div`
  //min-width: 100%;
  min-width: 112px;
  max-width: 112px;
  min-height: 112px;
  max-height: 112px;
  margin-bottom: 10px;

  .ant-progress-text {
    font-size: 18px;
  }
`;

const NameBottomNameText = styled.div`
  line-height: 18px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.textSecondary};
`;

// Вид LikeBadge
const LikeBadgeWrapper = styled.div`
  ${BlockWithBorderCss};
  padding: 24px;
  width: 100%;
`;

const LikeBadgeGrid = styled.div`
  display: flex;
  flex-direction: row;
  width: 100% 
`;

const LikeBadgeCircleCol = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-right: 16px;
`;

const LikeBadgeTextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const LikeBadgePercentText = styled.div`
  line-height: 18px;
  font-size: 18px;
  margin-bottom: 4px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

const LikeBadgeNameText = styled.div`
  line-height: 18px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.textSecondary};
`;

export enum StudentValueProgressBlockTypes {
    LIKE_BADGE,
    VALUE_NAME_BOTTOM,
    VALUE_NAME_LEFT
}

interface StudentValueProgressBlockProps {
    showVariant: StudentValueProgressBlockTypes;
    paramPosition: StudentRateParamPositionEnum | null; // null, если среднее значение
    paramName: string;
    paramValue: number;
    style?: CSSProperties;
}

export const StudentValueProgressBlock: React.FC<StudentValueProgressBlockProps> = (
    {showVariant, paramPosition, paramName, paramValue, style}
) => {
    const theme = useTheme();

    const currentColor = useMemo<string>(() => {
        switch (paramPosition) {
            case StudentRateParamPositionEnum.FIRST: {
                return theme.colors.studentRateParam1Color;
            }
            case StudentRateParamPositionEnum.SECOND: {
                return theme.colors.studentRateParam2Color;
            }
            case StudentRateParamPositionEnum.THIRD: {
                return theme.colors.studentRateParam3Color;
            }
            case StudentRateParamPositionEnum.FOURTH: {
                return theme.colors.studentRateParam4Color;
            }
            case StudentRateParamPositionEnum.FIFTH: {
                return theme.colors.studentRateParam5Color;
            }
            default: {
                return theme.colors.studentRateTotalAverageColor;
            }
        }
    }, [paramPosition, theme]);

    // render:
    switch (showVariant) {
        case StudentValueProgressBlockTypes.LIKE_BADGE: {
            return <LikeBadgeWrapper style={style}>
                <LikeBadgeGrid>
                    <LikeBadgeCircleCol>
                        <CircleProgress showPercent={false} color={currentColor} width={40} value={paramValue}/>
                    </LikeBadgeCircleCol>

                    <LikeBadgeTextWrapper>
                        <LikeBadgePercentText>{paramValue}&nbsp;%</LikeBadgePercentText>
                        <LikeBadgeNameText>{paramName}</LikeBadgeNameText>
                    </LikeBadgeTextWrapper>
                </LikeBadgeGrid>
            </LikeBadgeWrapper>
        }

        case StudentValueProgressBlockTypes.VALUE_NAME_BOTTOM: {
            return <NameBottomWrapper style={style}>
                <NameBottomGrid>
                    <NameBottomCircleCol>
                        <CircleProgress showPercent={true} color={currentColor} width={112} value={paramValue}/>
                    </NameBottomCircleCol>
                    <NameBottomNameText>{paramName}</NameBottomNameText>
                </NameBottomGrid>
            </NameBottomWrapper>
        }

        case StudentValueProgressBlockTypes.VALUE_NAME_LEFT: {
            return <NameLeftWrapper style={style}>
                <NameLeftGrid>
                    <NameLeftTextWrapper>
                        <NameLeftPercentText>{paramValue}&nbsp;%</NameLeftPercentText>
                        <NameLeftNameText>{paramName}</NameLeftNameText>
                    </NameLeftTextWrapper>

                    <NameLeftCircleCol>
                        <CircleProgress showPercent={false} color={currentColor} width={140} value={paramValue}/>
                    </NameLeftCircleCol>
                </NameLeftGrid>
            </NameLeftWrapper>
        }

        default: {
            throw new Error('Unknown StudentValueProgressBlockType: ' + showVariant);
        }

    }
}