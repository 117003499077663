import styled from "styled-components";
import {PageSubtitle2} from "../../../styles/global-elements";
import React from "react";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {ApplicationState} from "../../../../store";
import {withRouter} from "../../WithRouterHoc";
import {connect} from "react-redux";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {DtoTmDiscipline} from "../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {DtoTmLevel} from "../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {DtoTmSection} from "../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {ModalResultType as LessonModalResultType, TmLessonModal} from "../modals/lesson-modal";
import {LessonsList, SlideSelectorConfig} from "./LessonsList";
import {ModalResultType as LevelModalResultType} from "../modals/level-modal";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../Ui/Elements/Notification";
import {t, Trans} from "@lingui/macro";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../Ui/Elements/NoticeBlock";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {DtoTmLesson} from "../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";

const LESSONS_COUNT_PER_PAGE = 30;

const Wrapper = styled.div``;

const BtnWrapper = styled.div`
  margin-bottom: 20px;
  display: block;
  //text-align: right;
`;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

enum LoadingState {
    NOT_INIT,
    LOADING_OVERVIEW,
    OVERVIEW_LOADING_ERROR,
    LOADING_LESSONS,
    SUCCESS,
    SECTIONS_ERROR
}

interface TmSectionDetailsSectionProps {
    sectionId: string;
    apiToken?: string | null;
    selectMode?: boolean;
    selectLessonMethod?: (lessonItem: DtoTmLesson) => void;
    usedLessonIds?: string[];
    slideSelector?: SlideSelectorConfig;
}

interface TmSectionDetailsSectionState {
    loadingState: LoadingState;
    disciplineItem: DtoTmDiscipline | null;
    levelItem: DtoTmLevel | null;
    sectionItem: DtoTmSection | null;
    lessons: DtoTmLesson[];
    totalLessonsCount: number | null;
    savingLessonsOrderNow: boolean;
}

export class TmSectionDetailsSection extends React.Component<TmSectionDetailsSectionProps, TmSectionDetailsSectionState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    constructor(props: Readonly<TmSectionDetailsSectionProps> | TmSectionDetailsSectionProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            disciplineItem: null,
            levelItem: null,
            sectionItem: null,
            lessons: [],
            totalLessonsCount: null,
            savingLessonsOrderNow: false
        }
    }

    componentDidMount() {
        this.fetchOverview().then();
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }
    }

    resetLessonsList = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_LESSONS,
                lessons: [],
                totalLessonsCount: null
            }
        }, () => {
            this.fetchLessons().then()
        });
    }

    protected fetchOverview = async () => {
        if ((this.props.apiToken === undefined) || (this.props.apiToken === null)) {
            return;
        }

        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_OVERVIEW,
                levelItem: null,
                disciplineItem: null,
                sectionItem: null,
                lessons: [],
                totalLessonsCount: null
            }
        });

        try {
            const result = await this.apiClient.tmGetSectionOverview(
                this.props.apiToken,
                this.props.sectionId,
                LESSONS_COUNT_PER_PAGE,
                this.abortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    levelItem: result.data.levelItem,
                    disciplineItem: result.data.disciplineItem,
                    sectionItem: result.data.sectionItem,
                    lessons: result.data.lessonsList,
                    totalLessonsCount: result.data.totalLessonsCount
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.OVERVIEW_LOADING_ERROR
                }
            });

            if (e instanceof CanceledByUser) {
                return;
            }

            const errorMessage = 'Error loading section overview: ';

            if (e instanceof NoConnection) {
                this.logger.info(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, errorMessage, e);
            } else {
                this.logger.error(LoggerSectionsEnum.TM_LEVEL_SECTIONS_API, errorMessage, e);
            }
        }
    }

    protected fetchLessons = async () => {
        if ((this.props.apiToken === undefined) || (this.props.apiToken === null)) {
            return;
        }

        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_LESSONS
            }
        });

        try {
            const result = await this.apiClient.tmGetLessons(
                this.props.apiToken,
                this.props.sectionId,
                (this.state.lessons.length === 0)
                    ? 1
                    : (Math.round(this.state.lessons.length / LESSONS_COUNT_PER_PAGE) + 1),
                LESSONS_COUNT_PER_PAGE,
                this.abortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    lessons: [...this.state.lessons, ...result.data.list],
                    totalLessonsCount: result.data.totalCount
                }
            });
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.SECTIONS_ERROR
                }
            });

            this.logger.error(LoggerSectionsEnum.TM_LESSONS_API, "Error loading lessons: ", e);
        }
    }

    protected lessonModalOnResult = (action: LessonModalResultType, editedItem: (DtoTmLesson | null)) => {
        switch (action) {
            case LevelModalResultType.CREATED:
            case LevelModalResultType.DELETED: {
                this.resetLessonsList();

                return;
            }
            case LevelModalResultType.UPDATED: {
                if (editedItem === null) {
                    return;
                }

                this.setState((state) => {
                    return {
                        lessons: state.lessons.map((item) => {
                            if (item.id === editedItem.id) {
                                return editedItem;
                            }

                            return item;
                        })
                    }
                })
            }
        }
    }

    protected reorderSectionItems = (from: number, to: number) => {
        if (!this.props.apiToken) {
            return;
        }

        const firstItemId = this.state.lessons[from].id;
        const secondItemId = this.state.lessons[to].id;

        const newLessonsArray = arrayMove(this.state.lessons, from, to);

        this.setState(() => {
            return {
                lessons: newLessonsArray,
                savingLessonsOrderNow: true
            }
        });

        this.apiClient.tmSwapLessonOrderPosition(this.props.apiToken, firstItemId, secondItemId)
            .catch((err) => {
                this.logger.error(
                    LoggerSectionsEnum.TM_LESSONS_API,
                    'Error on reorder lessons list: ',
                    err
                );

                this.setState(() => {
                    return {
                        lessons: arrayMove(newLessonsArray, to, from),
                        savingLessonsOrderNow: false
                    }
                });

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок уроков. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                this.setState(() => {
                    return {
                        savingLessonsOrderNow: false
                    }
                });
            });
    }

    protected loadMoreButton = () => {
        return <BtnWrapper>
            <Button btnStyle={BtnStyleEnum.Secondary} onClick={this.fetchLessons}>Показать ещё</Button>
        </BtnWrapper>;
    }

    protected addLessonButton = () => {
        if (this.props.selectMode === true) {
            return;
        }

        return <BtnWrapper>
            <TmLessonModal
                sectionId={this.props.sectionId}
                lessonItem={null}
                result={this.resetLessonsList}
                trigger={
                    <Button btnStyle={
                        (this.state.lessons.length > 0)
                            ? BtnStyleEnum.Secondary
                            : BtnStyleEnum.Primary
                    }><Trans>Добавить урок</Trans></Button>
                }/>
        </BtnWrapper>;
    }

    protected emptyListMessage = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle><Trans>Список уроков пуст</Trans></NoticeBlockTitle>
                <NoticeBlockText>
                    <Trans>«Урок» в учебных материалах - это набор слайдов с информацией и упражнениями для занятия с
                        учеником или группой учеников.</Trans>&nbsp;
                    <Trans>При этом не обязательно, чтобы реальное занятие включало все слайды урока учебных материалов:
                        при необходимости каждое конкретное занятие можно собрать из части слайдов урока учебных
                        материалов или из слайдов разных уроков учебных материалов.</Trans><br/><br/>

                    {
                        (this.props.selectMode !== true)
                        && <Trans>Создать урок можно, нажав по кнопке «Добавить урок»</Trans>
                    }
                    {
                        (this.props.selectMode === true)
                        && <Trans>Создать урок можно в разделе «Учебные материалы»</Trans>
                    }
                </NoticeBlockText>
            </>
        </NoticeBlock>
    }

    render() {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING_OVERVIEW: {
                return <Wrapper><DefaultLoader/></Wrapper>;
            }
            case LoadingState.OVERVIEW_LOADING_ERROR: {
                return <Wrapper><ErrorLoadingContent retryBtnClick={this.fetchOverview}/></Wrapper>;
            }
            case LoadingState.LOADING_LESSONS:
            case LoadingState.SECTIONS_ERROR:
            case LoadingState.SUCCESS: {
                if (this.state.disciplineItem === null) {
                    throw new Error("Try render contend with disciplineItem === null");
                }

                if (this.state.levelItem === null) {
                    throw new Error("Try render contend with levelItem === null");
                }

                if (this.state.sectionItem === null) {
                    throw new Error("Try render contend with sectionItem === null");
                }

                if (this.state.loadingState === LoadingState.SUCCESS && this.state.lessons.length === 0) {
                    return <Wrapper>
                        <Title>{this.state.disciplineItem.name}, {this.state.levelItem.name}, {this.state.sectionItem.name}</Title>
                        {this.emptyListMessage()}<br/>
                        {this.addLessonButton()}
                    </Wrapper>
                }

                return <Wrapper>
                    <Title>{this.state.disciplineItem.name}, {this.state.levelItem.name}, {this.state.sectionItem.name}</Title>
                    {
                        ((this.state.lessons.length > 0) && (!!this.props.apiToken))
                        && <LessonsList lessonItems={this.state.lessons}
                                        apiToken={this.props.apiToken}
                                        reorderItems={(this.props.selectMode === true) ? undefined : this.reorderSectionItems}
                                        sectionId={this.state.sectionItem.id}
                                        selectMode={this.props.selectMode}
                                        itemsDisabled={this.state.savingLessonsOrderNow}
                                        selectLessonMethod={this.props.selectLessonMethod}
                                        usedLessonIds={this.props.usedLessonIds}
                                        slideSelector={this.props.slideSelector}
                                        modalOnResult={this.lessonModalOnResult}/>
                    }
                    {
                        (this.state.loadingState === LoadingState.LOADING_LESSONS)
                        && <DefaultLoader/>
                    }
                    {
                        (this.state.loadingState === LoadingState.SECTIONS_ERROR)
                        && <ErrorLoadingContent retryBtnClick={this.fetchLessons}/>
                    }
                    {(
                        (this.state.totalLessonsCount !== null)
                        && (this.state.loadingState === LoadingState.SUCCESS)
                        && (this.state.lessons.length > 0)
                        && (this.state.lessons.length < this.state.totalLessonsCount)
                    ) && this.loadMoreButton()}
                    {(this.state.loadingState === LoadingState.SUCCESS) && this.addLessonButton()}
                </Wrapper>;
            }
            default: {
                throw new Error("Unknown loadingState for TmLevelSectionsList");
            }
        }
    }
}


const mapStateToProps = ({user}: ApplicationState) => ({
    apiToken: user.sessionToken,
});

export default withRouter(connect(mapStateToProps)(TmSectionDetailsSection));
