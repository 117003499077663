import {FooterMode, Modal, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import React, {forwardRef, useCallback, useContext, useMemo, useState} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin, PageTitle} from "../../../../../../styles/global-elements";
import {Form, FormItem, useForm} from "../../../../../../components/Ui/Elements/Form";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {container} from "tsyringe";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoAnotherParticipantItem
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAnotherParticipantItem";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {WillBeAffectedToOtherStudents} from "../../../../../../components/WillBeAffectedToOtherStudents";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {
    DtoGroupSchedule
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoGroupSchedule";
import {RadioGroup, RadioInput} from "../../../../../../components/Ui/Elements/RadioInput";
import moment from "moment";
import {DatePicker} from "../../../../../../components/Ui/Elements/DatePicker";
import {ScheduleForm} from "../../../common/ScheduleForm";
import {IGroupScheduleReader} from "../../../../../../components/GroupScheduleReader/IGroupScheduleReader";

const WillBeAffectedToOtherStudentsStyled = styled(WillBeAffectedToOtherStudents)`
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  margin-bottom: 100px;
`;

const StartFromGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  margin-bottom: 30px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StartFromInputWrapper = styled.div`
  padding-left: 25px;
  max-width: 300px;
`;

enum FormFieldNamesEnum {
    START_MODE_SELECTOR = 'start_mode_selector',
    START_FROM = 'start_from'
}

enum StartFromEnum {
    NOW = 0,
    FROM_DATE = 1
}

interface ScheduleModalProps {
    groupId: number;
    currentSchedule: DtoGroupSchedule | null;
    anotherParticipants: DtoAnotherParticipantItem[];
}

export const ScheduleModal = forwardRef<PopupActions, ScheduleModalProps>(
    (
        {
            groupId,
            currentSchedule,
            anotherParticipants
        },
        ref
    ) => {
        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);

        const [savingNow, setSavingNow] = useState<boolean>(false);

        const [startFromVisible, setStartFromVisible] = useState<boolean>(false);

        const stToken = useSelector(stTokenSelector);

        const [form] = useForm();

        const stApiClient = useMemo(() => container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT), []);

        const onFormValuesChange = useCallback(() => {
            const startModeSelectorValue =
                form.getFieldValue(FormFieldNamesEnum.START_MODE_SELECTOR) === StartFromEnum.FROM_DATE;

            if (startModeSelectorValue !== startFromVisible) {
                setStartFromVisible(startModeSelectorValue);
            }
        }, [form, startFromVisible]);

        const onFinish = async (controls: ModalControlParams, values: any) => {
            if (!stToken) {
                return;
            }

            setSavingNow(true);

            const scheduleReader = container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER);

            const scheduleDto = scheduleReader.createScheduleDtoByForm(values);

            const startFromDateMode =
                (form.getFieldValue(FormFieldNamesEnum.START_MODE_SELECTOR) === StartFromEnum.FROM_DATE)
                && (!!form.getFieldValue(FormFieldNamesEnum.START_FROM));

            stApiClient.crateGroupSchedule(
                stToken,
                groupId,
                scheduleDto,
                startFromDateMode,
                (startFromDateMode) ? form.getFieldValue(FormFieldNamesEnum.START_FROM).format('YYYY-MM-DD') : null
            )
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Сохранено`,
                        t`Информация успешно сохранена`
                    );

                    studentPageContext.reloadBaseInfo();
                })
                .catch((e) => {
                    setSavingNow(false);

                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось применить изменения`,
                            t`Проверьте соединение с интернетом`
                        );

                        return;
                    }

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось применить изменения`,
                        t`Попробуйте повторить попытку позднее`
                    );

                    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                    logger.error(
                        LoggerSectionsEnum.STONLINE_SCHEDULE_API,
                        `Error on save schedule for group: `,
                        e
                    );
                });
        }

        return <Modal
            innerRef={ref}
            footerMode={FooterMode.SPACE_BETWEEN}
            onOpen={() => form.resetFields()}
            closeAllowed={!savingNow}
            footer={
                (controls) => {
                    return <>
                        <Button btnStyle={BtnStyleEnum.Primary}
                                loading={savingNow}
                                onClick={() => form.submit()}
                        >
                            <Trans>Сохранить</Trans>
                        </Button>
                        <Button btnStyle={BtnStyleEnum.Secondary}
                                disabled={savingNow}
                                onClick={controls.closeModal}
                        >
                            <Trans>Закрыть</Trans>
                        </Button>
                    </>
                }
            }
            children={(controls) => <>
                <PageTitle tabIndex={0}><Trans>Задать расписание занятий</Trans></PageTitle>
                {
                    (anotherParticipants.length > 0)
                    && <WillBeAffectedToOtherStudentsStyled
                        selectedStudent={{
                            studentId: studentPageContext.studentGeneralInfoDto.id,
                            longName: studentPageContext.studentGeneralInfoDto.longName,
                            gender: studentPageContext.studentGeneralInfoDto.gender,
                        }}
                        students={anotherParticipants.map(item => {
                            return {
                                studentId: item.studentId,
                                longName: item.studentLongName,
                                gender: item.gender,
                            }
                        })}/>
                }
                <PageSubtitleSmallMargin>
                    <Trans>Начало действия</Trans>
                </PageSubtitleSmallMargin>
                <FormWrapper>
                    <Form form={form} layout={"inline"}
                          onFinish={(values: any) => onFinish(controls, values)}
                          onValuesChange={onFormValuesChange}
                    >
                        <FormItemsWrapper>
                            <StartFromGroup>
                                <FormItem
                                    name={FormFieldNamesEnum.START_MODE_SELECTOR}
                                    initialValue={StartFromEnum.NOW}
                                >
                                    <RadioGroup>
                                        <RadioWrapper>
                                            <RadioInput value={StartFromEnum.NOW}>
                                                <Trans>Новое расписание действует сразу</Trans></RadioInput>
                                            <RadioInput value={StartFromEnum.FROM_DATE}>
                                                <Trans>Запланировать изменение с даты</Trans></RadioInput>
                                        </RadioWrapper>
                                    </RadioGroup>
                                </FormItem>

                                {
                                    (startFromVisible) &&
                                    <StartFromInputWrapper>
                                        <FormItem
                                            name={FormFieldNamesEnum.START_FROM}
                                            initialValue={moment(new Date())}
                                            label={<Trans>Дата начала действия</Trans>}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t`Необходимо указать дату начала действия расписания`,
                                                }
                                            ]}
                                        >
                                            <DatePicker/>
                                        </FormItem>
                                    </StartFromInputWrapper>
                                }
                            </StartFromGroup>

                            <div>
                                <PageSubtitleSmallMargin>
                                    <Trans>Время занятий</Trans>
                                </PageSubtitleSmallMargin>

                                <ScheduleForm form={form} scheduleData={currentSchedule}/>
                            </div>
                        </FormItemsWrapper>
                    </Form>
                </FormWrapper>
            </>}
        />
    });