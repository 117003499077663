import React from 'react';
import {SlideItemWorkData} from "../../../store/slidesWorkData/type";
import {initialSlideItemWorkData} from "../../../store/slidesWorkData/initialState";
import {cloneDeep} from "lodash";

export interface ISlideItemWorkContext {
    playerId: string;
    lessonId: string;
    slideId: string;
    itemId: string;
    slideItemWorkData: SlideItemWorkData;
    saveExerciseValue: (exerciseId: string, value: string) => void;
    saveExerciseAnswer: (exerciseId: string, value: string, award: number, missedAward: number, slideExercisesCount: number, answerIsCorrect: boolean) => void;
    saveAdditionalData: (exerciseId: string, value: string | null) => void;
    saveAdmitDefeat: (exerciseId: string, value: string, slideExercisesCount: number) => void;
    showCorrectAnswers?: boolean; // Показывать выбранными верные ответы
    readOnly?: boolean; // Не давать возможность выполнять упражнения
}

export const SlideItemWorkContext = React.createContext<ISlideItemWorkContext>({
    playerId: '',
    lessonId: '',
    slideId: '',
    itemId: '',
    slideItemWorkData: cloneDeep(initialSlideItemWorkData),
    saveExerciseAnswer: () => {
    },
    saveExerciseValue: () => {
    },
    saveAdditionalData: () => {
    },
    saveAdmitDefeat: () => {
    }
});

export const SlideItemWorkContextProvider = SlideItemWorkContext.Provider;
