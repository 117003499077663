import {IsArray, IsNotEmpty, IsNumber, IsOptional, Max, Min, ValidateNested} from 'class-validator';
import {DtoHometaskOverviewResponse} from "../Lessons/DtoHometaskOverviewResponse";
import {DtoRequestWithPagination} from "../Common/DtoRequestWithPagination";
import {DtoNextLessonInfoResponseItem} from "../Lessons/DtoNextLessonInfoResponseItem";
import {DtoAverageResultResponse} from "../Lessons/DtoAverageResultResponse";
import {DtoLastLessonItem} from "../Lessons/DtoLastLessonItem";
import {DtoOnlinePaymentDocumentResponseItem} from "../OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {Type} from "class-transformer";
import {DtoUserProfileInfo} from "../User/DtoUserProfileInfo";
import {DtoLessonPlanItem} from "../Lessons/DtoLessonPlanItem";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";

class LastLessonsListTypeForValidate extends DtoRequestWithPagination<DtoLastLessonItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoLastLessonItem)
    list: Array<DtoLastLessonItem>;
}

class NextLessonInfoTypeForValidate extends DtoRequestWithPagination<DtoNextLessonInfoResponseItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoNextLessonInfoResponseItem)
    list: Array<DtoNextLessonInfoResponseItem>;
}

class NextLessonsListTypeForValidate extends DtoRequestWithPagination<DtoLessonPlanItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoLessonPlanItem)
    list: Array<DtoLessonPlanItem>;
}


class SelfStudyTrackSubscriptionsTypeForValidate extends DtoRequestWithPagination<DtoSelfStudyTrackSubscriptionUserOverviewItem> {
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoSelfStudyTrackSubscriptionUserOverviewItem)
    list: Array<DtoSelfStudyTrackSubscriptionUserOverviewItem>;
}

class OnlinePaymentCommonDocumentsTypeForValidate extends DtoRequestWithPagination<DtoOnlinePaymentDocumentResponseItem> {
    @IsNotEmpty()
    @IsNumber()
    @Max(200)
    @Min(0)
    limit: number;

    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoOnlinePaymentDocumentResponseItem)
    list: Array<DtoOnlinePaymentDocumentResponseItem>;
}

/**
 * DTO ответа на запрос контента для главной страницы ученика
 */
export class DtoStudentMainPageDataResponse {
    @IsOptional()
    @ValidateNested()
    @Type(() => DtoHometaskOverviewResponse)
    hometaskData: DtoHometaskOverviewResponse | null;

    @ValidateNested()
    @Type(() => NextLessonInfoTypeForValidate)
    nextLessonInfo: NextLessonInfoTypeForValidate;

    @ValidateNested()
    @Type(() => NextLessonsListTypeForValidate)
    nextLessonsList: NextLessonsListTypeForValidate;

    @ValidateNested()
    @Type(() => DtoAverageResultResponse)
    averageResults: DtoAverageResultResponse;

    @ValidateNested()
    @Type(() => LastLessonsListTypeForValidate)
    lastLessons: LastLessonsListTypeForValidate;

    @ValidateNested()
    @Type(() => OnlinePaymentCommonDocumentsTypeForValidate)
    onlinePaymentCommonDocuments: OnlinePaymentCommonDocumentsTypeForValidate;

    @ValidateNested()
    @Type(() => DtoUserProfileInfo)
    primaryTeacher: DtoUserProfileInfo;

    @ValidateNested()
    @Type(() => SelfStudyTrackSubscriptionsTypeForValidate)
    selfStudyTrackSubscriptions: SelfStudyTrackSubscriptionsTypeForValidate;
}