import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import React from "react";
import {
    DtoStudentEntranceInfoResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/Students/DtoStudentEntranceInfoResponse";
import {CreateAgreementRequestData} from "./CreateAgreementRequestData";

export interface ICreateAgreementPageContext {
    studentGeneralInfoDto: DtoStudentGeneralInfo;
    setStudentGeneralInfoDto: (studentGeneralInfoDto: DtoStudentGeneralInfo) => void;
    studentEntranceInfoResponse: DtoStudentEntranceInfoResponse | null;
    reloadBaseInfo: () => void;
    formData: CreateAgreementRequestData;
    setFormData: React.Dispatch<React.SetStateAction<CreateAgreementRequestData>>;
    shortStudentName: string;
}

export const CreateAgreementPageContext = React.createContext<ICreateAgreementPageContext>({
    studentGeneralInfoDto: new DtoStudentGeneralInfo(),
    setStudentGeneralInfoDto: studentGeneralInfoDto => {
    },
    studentEntranceInfoResponse: new DtoStudentEntranceInfoResponse(),
    reloadBaseInfo: () => {
    },
    formData: new CreateAgreementRequestData(),
    setFormData: () => {
    },
    shortStudentName: '',
});

export const CreateAgreementPageContextProvider = CreateAgreementPageContext.Provider;
