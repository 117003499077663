import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {
    GalleryItemsCountBySizeMode,
    GalleryListItemsCountBySizeMode,
    GallerySizeMode,
    GallerySizeModeConstraints,
    VideoGalleryParticipantProfile
} from "./Types";
import useResizeObserver from "@react-hook/resize-observer";
import classNames from "classnames";
import {Grid, GridWrapper, Wrapper} from "./styles";
import {getGridClassName} from "./getGridClassName";
import {VideoItem} from "./VideoItem";
import {MemberCounter} from "./MemberCounter";
import {VideoGalleryContextProvider} from "./VideoGalleryContext";
import {ConferenceModeEnum, StreamStatusEnum} from "../../../Types";
import {CommonContext, ICommonContext} from "../../../contexts/CommonContext";
import {VideoControlPanel} from "../../../VideoControlPanel";
import {useDispatch, useSelector} from "react-redux";
import {setBodyScrollAvailable} from "../../../../../../store/layout/actions";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";

interface VideoGalleryProps {
    participantProfiles: VideoGalleryParticipantProfile[];
}

export const VideoGallery = forwardRef<HTMLDivElement, VideoGalleryProps>(
    ({participantProfiles}, forwardedRef) => {
        const dispatch = useDispatch();

        const wrapperRef = useRef<HTMLDivElement>(null);
        useImperativeHandle(forwardedRef, () => wrapperRef.current as HTMLDivElement);
        const commonContext = useContext<ICommonContext>(CommonContext);

        const currentTheme = useSelector<ApplicationState>(
            ({layout}: ApplicationState) => layout.activeTheme
        ) as ITheme;

        const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

        const [memberOffset, setMemberOffset] = useState<number>(0);
        const [gallerySizeMode, setGallerySizeMode] = useState<GallerySizeMode>(GallerySizeMode.SMALL);

        useEffect(() => {
            dispatch(setBodyScrollAvailable(
                commonContext.currentMode !== ConferenceModeEnum.FULL_SCREEN
            ));
        }, [commonContext.currentMode, dispatch]);

        const modeClass = useMemo<string>(
            () => {
                switch (commonContext.currentMode) {
                    case ConferenceModeEnum.NORMAL: {
                        return 'mode-default';
                    }
                    case ConferenceModeEnum.FULL_SCREEN: {
                        return 'mode-fs';
                    }
                    case ConferenceModeEnum.DOCUMENT_IN_PICTURE: {
                        return 'mode-doc-in-picture';
                    }
                    case ConferenceModeEnum.FLOAT_ON_PAGE: {
                        return 'mode-float-on-document';
                    }
                }
            },
            [commonContext.currentMode]
        );

        useResizeObserver(wrapperRef, (entry) => {
            const width = entry.contentRect.width;
            const windowHeight = window.innerHeight;

            let newSizeMode: GallerySizeMode = GallerySizeMode.SMALL;

            if (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE) {
                // В режиме DocumentInPicture устанавливаем безусловно маленький объём сетки,
                // т.к. ResizeObserver там почему-то работает не отзывчиво
            } else if (commonContext.currentMode === ConferenceModeEnum.FLOAT_ON_PAGE) {
                if (isLarge) {
                    newSizeMode = GallerySizeMode.FLOAT_ON_PAGE;
                } else {
                    newSizeMode = GallerySizeMode.FLOAT_ON_SMALL_PAGE;
                }
            } else if (
                (width >= GallerySizeModeConstraints.MIN_WIDTH_FOR_EXTRA_LARGE)
                && (windowHeight >= GallerySizeModeConstraints.MIN_HEIGHT_FOR_EXTRA_LARGE)
            ) {
                newSizeMode = GallerySizeMode.EXTRA_LARGE;
            } else if (
                (width >= GallerySizeModeConstraints.MIN_WIDTH_FOR_LARGE)
                && (windowHeight >= GallerySizeModeConstraints.MIN_HEIGHT_FOR_LARGE)
            ) {
                newSizeMode = GallerySizeMode.LARGE;
            } else if (
                (width >= GallerySizeModeConstraints.MIN_WIDTH_FOR_MEDIUM)
                && (windowHeight >= GallerySizeModeConstraints.MIN_HEIGHT_FOR_MEDIUM)
            ) {
                newSizeMode = GallerySizeMode.MEDIUM;
            }

            setGallerySizeMode(newSizeMode);
        });

        // ParticipantProfiles без selectedMemberId, если мы его уже показываем как закреплённого
        const filteredParticipantProfiles = useMemo(() => {
            // Если нет выбранного элемента - показываем всех
            if (commonContext.selectedMemberId === null) {
                return participantProfiles;
            }

            // Если выбран участник, то зависит от того - показывает ли он свой экран сейчас
            const selectedMemberDetails = commonContext.conferenceParticipants.find(
                item => item.id === commonContext.selectedMemberId
            );

            if (selectedMemberDetails && selectedMemberDetails.screenVideo !== StreamStatusEnum.NOT_AVAILABLE) {
                // Если выбранный участник демонстрирует экран, то видео его камеры показываем вместе со всеми в галерее
                return participantProfiles;
            }

            return participantProfiles.filter(item => item.id !== commonContext.selectedMemberId);
        }, [commonContext.conferenceParticipants, participantProfiles, commonContext.selectedMemberId]);

        const gridItemCountForShowOnScreen = useMemo(() => {
            if (filteredParticipantProfiles.length === 0) {

                if (memberOffset !== 0) {
                    setMemberOffset(0);
                }

                return 0;
            }

            let membersCount = filteredParticipantProfiles.length - memberOffset;

            if (membersCount <= 0) {
                setMemberOffset(0);

                membersCount = filteredParticipantProfiles.length;
            }

            if (commonContext.selectedMemberId !== null) {
                if (membersCount > GalleryListItemsCountBySizeMode[gallerySizeMode]) {
                    membersCount = GalleryListItemsCountBySizeMode[gallerySizeMode];
                }
            } else {
                if (membersCount > GalleryItemsCountBySizeMode[gallerySizeMode]) {
                    membersCount = GalleryItemsCountBySizeMode[gallerySizeMode];
                }
            }

            return membersCount;
        }, [filteredParticipantProfiles.length, gallerySizeMode, memberOffset, commonContext.selectedMemberId]);

        const gridClassName = useMemo(() => {
            return getGridClassName(gridItemCountForShowOnScreen);
        }, [gridItemCountForShowOnScreen]);

        const videoItems = useMemo<JSX.Element[]>(() => {
            const items = [];

            if (
                (memberOffset + gridItemCountForShowOnScreen > filteredParticipantProfiles.length)
                && (memberOffset > 0)
            ) {
                setMemberOffset(0);

                return [];
            }

            for (let i = memberOffset; i < memberOffset + gridItemCountForShowOnScreen; i++) {
                const item = filteredParticipantProfiles[i];

                items.push(
                    <VideoItem key={item.id} profile={item} ignoreScreenVideo={commonContext.selectedMemberId !== null}/>
                );
            }

            return items;
        }, [memberOffset, gridItemCountForShowOnScreen, filteredParticipantProfiles, commonContext.selectedMemberId]);

        const mainVideoZone = useMemo(() => {
            if (commonContext.selectedMemberId !== null) {
                const selectedMemberItem = participantProfiles.find(item => commonContext.selectedMemberId === item.id);

                if (selectedMemberItem) {
                    return <GridWrapper>
                        <Grid className={classNames('one-item', 'video-grid')}>
                            <VideoItem key={selectedMemberItem.id} profile={selectedMemberItem}
                                       ignoreScreenVideo={false}/>
                        </Grid>
                    </GridWrapper>
                }
            }

            return <GridWrapper>
                <Grid className={classNames(gridClassName, 'video-grid')}>
                    {videoItems}
                </Grid>
            </GridWrapper>
        }, [gridClassName, participantProfiles, commonContext.selectedMemberId, videoItems]);

        const galleryMembersList = useMemo<JSX.Element | null>(() => {
            if (commonContext.selectedMemberId === null) {
                return null;
            }

            return <div className={'video-list-wrapper'}>
                {videoItems}
            </div>
        }, [commonContext.selectedMemberId, videoItems]);

        const needShowControlPanel = useMemo<boolean>(() => {
            return (
                (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN)
                || (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE)
            );
        }, [commonContext.currentMode]);

        const allowedGalleryUnderMainZone = useMemo<boolean>(() => {
            return (
                (commonContext.currentMode === ConferenceModeEnum.NORMAL)
                || (commonContext.currentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE)
                || (commonContext.currentMode === ConferenceModeEnum.FULL_SCREEN)
            );
        }, [commonContext.currentMode]);

        const allowedMembersCounter = useMemo<boolean>(() => {
            if (commonContext.currentMode !== ConferenceModeEnum.FLOAT_ON_PAGE) {
                return true;
            }

            return isLarge;
        }, [commonContext.currentMode, isLarge]);

        return <VideoGalleryContextProvider value={{
            selectedMemberId: commonContext.selectedMemberId,
            memberOffset,
            totalMembersCount: participantProfiles.length,
            membersCountForScroll: filteredParticipantProfiles.length,
            gallerySize: gridItemCountForShowOnScreen,
            setMemberOffset,
            setSelectedMemberId: commonContext.onMemberSelect,
            gallerySizeMode
        }}>
            <Wrapper ref={wrapperRef} className={classNames(modeClass)}>
                {
                    (needShowControlPanel)
                    && <div className={'control-panel-wrapper'}>
                    <div className={'control-panel-width-wrapper'}>
                      <VideoControlPanel/>
                    </div>
                  </div>
                }

                {mainVideoZone}

                {
                    (allowedGalleryUnderMainZone)
                    && galleryMembersList
                }
                {
                    (allowedMembersCounter)
                    && <MemberCounter/>
                }
            </Wrapper>
        </VideoGalleryContextProvider>
    });
