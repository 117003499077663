import * as React from "react";
import {BlockWithBorder} from "../../../../../styles/global-elements";
import styled from "styled-components";
import {
    DtoTmDiscipline
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";

interface DisciplineItemStyledProps {
    active: boolean
}

export const DisciplineItemStyled = styled(BlockWithBorder)<DisciplineItemStyledProps>`
  padding: ${p => (p.active) ? "9px 14px" : "10px 15px"};
  border-style: solid;
  border-width: ${p => (p.active) ? "2px" : "1px"};
  border-color: ${p => (p.active) ? p.theme.colors.backgroundInverse : p.theme.colors.accentDivider};
  min-width: 100px;
  min-height: 50px;
  max-width: 250px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  user-select: none;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.backgroundInverse};
  }
`;

const DisciplineItemName = styled.span`
  font-size: 14px;
  white-space: nowrap;
`;

interface DisciplineItemProps {
    active: boolean;
    item: DtoTmDiscipline;
    onClick: () => void;
}

export const DisciplineItem: React.FC<DisciplineItemProps> = (props) => {
    return (
        <DisciplineItemStyled active={props.active} onClick={props.onClick}>
            <DisciplineItemName>{props.item.name}</DisciplineItemName>
        </DisciplineItemStyled>
    );
}