export enum ApiMethodEnum {
    AUTH_BY_AUTH_KEY = 'auth/login',
    AUTH_BY_RECONNECTION_TOKEN = 'auth/reconnect',

    USER_LIST = 'user/list',
    USER_UPDATE_CLIENT_LOCATION = 'user/update-client-location',

    USER_ONLINE_STATE_LIST = 'user-online-state/list',
    USER_ONLINE_STATE_SUBSCRIBE = 'user-online-state/subscribe',
    USER_ONLINE_STATE_UNSUBSCRIBE = 'user-online-state/unsubscribe',
    USER_ONLINE_STATE_CLEAR_ALL_SUBSCRIPTIONS = 'user-online-state/clear-all-subscriptions',

    LESSON_TEACHER_START_ONLINE = 'lesson/teacher-start-online-lesson',
    LESSON_TEACHER_STOP_ONLINE = 'lesson/teacher-stop-online-lesson',
    LESSON_PREPARE_STUDENT_IN_LESSON_ITEMS = 'lesson/prepare-student-in-lesson-items',

    LESSON_ROOM_GET_OPENED_ROOMS = 'lesson-room/get-opened-rooms',
    LESSON_ROOM_GET_BASE_INFO = 'lesson-room/get-base-info',
    LESSON_ROOM_JOIN_TO_ROOM = 'lesson-room/join-to-room',
    LESSON_ROOM_GET_MEMBERS_LIST = 'lesson-room/get-members-list',
    LESSON_ROOM_LEAVE_FROM_ROOM = 'lesson-room/leave-from-room',
    LESSON_ROOM_SAVE_STUDENT_CURRENT_SLIDE_ID = 'lesson-room/save-student-current-slide-id',
    LESSON_ROOM_MOVE_STUDENTS_TO_SLIDE = 'lesson-room/move-students-to-slide',
    LESSON_ROOM_MOVE_STUDENT_TO_SLIDE = 'lesson-room/move-student-to-slide',
    LESSON_ROOM_SELECT_ROOM_MEMBER_VIDEO_FOR_ALL = 'lesson-room/select-room-video-for-all',
    LESSON_ROOM_SET_MEMBERS_VIDEO_TO_FULL_SCREEN_FOR_ALL = 'lesson-room/set-members-video-to-full-screen-for-all',

    LESSON_ROOM_PUBLIC_STATE_MICROPHONE_MUTE = 'lesson-room/public-state/microphone-mute',
    LESSON_ROOM_PUBLIC_STATE_CAMERA_VIDEO_MUTE = 'lesson-room/public-state/camera-video-mute',
    LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN = 'lesson-room/public-state/video-pin',
    LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN = 'lesson-room/public-state/full-screen',
    LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION = 'lesson-room/public-state/screen-orientation',

    LESSON_AND_HOMEWORK_NEED_SYNC_SLIDE_LIST = 'lesson-and-homework/need-sync-lesson-slides',
    LESSON_AND_HOMEWORK_SUBSCRIBE_TO_SLIDE_LIST = 'lesson-and-homework/subscribe-to-slide-list',
    LESSON_AND_HOMEWORK_UNSUBSCRIBE_FROM_SLIDE_LIST = 'lesson-and-homework/unsubscribe-from-slide-list',
    LESSON_AND_HOMEWORK_SUBSCRIBE_TEACHER_TO_SLIDE_WORK_DATA = 'lesson-and-homework/subscribe-teacher-to-slide-work-data',
    LESSON_AND_HOMEWORK_UNSUBSCRIBE_TEACHER_FROM_SLIDE_WORK_DATA = 'lesson-and-homework/unsubscribe-teacher-from-slide-work-data',


    HOMEWORK_TEACHER_GET_STUDENTS_HOMEWORK_SUMMARY = 'homework/teacher-get-students-homework-summary',
    HOMEWORK_TEACHER_GET_STUDENTS_LESSON_WORK_SUMMARY = 'homework/teacher-get-students-lesson-work-summary',

    SLIDES_WORK_DATA_SAVE_QUEUE_ITEMS = 'slides-work-data/save-queue-items',
    SLIDES_WORK_DATA_GET_STUDENT_IN_LESSON_SLIDES_DATA = 'slides-work-data/get-student-in-lesson-slides-data',
    SLIDES_WORK_DATA_GET_STUDENT_HOMEWORK_SLIDES_DATA = 'slides-work-data/get-student-homework-slides-data',
    SLIDES_WORK_DATA_TEACHER_GET_STUDENT_LESSON_WORK_SLIDES_DATA = 'slides-work-data/teacher-get-student-lesson-work-slides-data',
    SLIDES_WORK_DATA_TEACHER_GET_STUDENT_HOMEWORK_SLIDES_DATA = 'slides-work-data/teacher-get-student-homework-slides-data',

    SLIDES_WORK_DATA_GET_SELF_STUDY_TRACK_SLIDES_DATA = 'slides-work-data/get-self-study-track-slides-data',

    STREAM_EVENT_HISTORY = 'stream/history',

    PING = 'ping/ping',

    // Перечисление роутов потока событий
    STREAM_EVENT_USER_ONLINE_STATE_UPDATE = 'stream/user-online-state-update',
    STREAM_EVENT_LESSON_ROOM_OPENED = 'stream/lesson-room-opened',
    STREAM_EVENT_LESSON_ROOM_CLOSED = 'stream/lesson-room-closed',
    STREAM_EVENT_LESSON_ROOM_KICKED = 'stream/lesson-room-kicked',
    STREAM_EVENT_LESSON_ROOM_PARTICIPANT_LEFT = 'stream/lesson-room-participant-left',
    STREAM_EVENT_LESSON_ROOM_PARTICIPANT_ENTERED = 'stream/lesson-room-participant-entered',
    STREAM_EVENT_LESSON_ROOM_STUDENT_SLIDE_SUMMARY_UPDATE = 'stream/lesson-room-student-slide-summary-update',
    STREAM_EVENT_LESSON_ROOM_STUDENT_CURRENT_SLIDE_CHANGED = 'stream/lesson-room-student-current-slide-changed',
    STREAM_EVENT_LESSON_ROOM_STUDENT_NEED_OPEN_SLIDE = 'stream/lesson-room-student-need-open-slide',
    STREAM_EVENT_LESSON_ROOM_NEED_SELECT_ROOM_MEMBER_VIDEO = 'stream/lesson-room-need-select-room-member-video',
    STREAM_EVENT_LESSON_ROOM_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE = 'stream/lesson-room-need-set-members-full-screen-mode-value',
    STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN_STATE = 'stream/lesson-room/public-state/video-pin-state',
    STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN = 'stream/lesson-room/public-state/full-screen',
    STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_CAMERA_VIDEO_MUTE = 'stream/lesson-room/public-state/camera-video-mute',
    STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_MICROPHONE_MUTE = 'stream/lesson-room/public-state/microphone-mute',
    STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION = 'stream/lesson-room/public-state/screen-orientation',

    STREAM_EVENT_LESSON_AND_HOMEWORK_ROOM_NEED_SYNC_SLIDE_LIST = 'stream/lesson-and-homework-need-sync-slide-list',
    STREAM_EVENT_LESSON_AND_HOMEWORK_STUDENT_SLIDE_WORK_DATA_UPDATE = 'stream/lesson-and-homework-student-slide-work-data-update',
}
