import * as React from "react";
import styled from 'styled-components';
import {Alert as AntAlert} from 'antd';
import {AlertProps as AntAlertProps} from 'antd/lib/alert';

export const Alert: React.FunctionComponent<AntAlertProps> = styled(AntAlert)`
  background-color: ${({theme}) => theme.colors.alertErrorBg};
  border: ${({theme}) => theme.colors.alertErrorBorder};

  .ant-alert-message {
    color: ${({theme}) => theme.colors.alertErrorText};
  }
`;
//
// export const Alert = (props: AntAlertProps) => {
//     return <AlertStyled {...props}/>;
// }