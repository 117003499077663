import React, {useMemo} from "react";
import {DtoLessonPlanItem} from "../../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLessonPlanItem";
import {DtoUserProfileInfo} from "../../../../../../../components/HttpApiClient/ApiDto/Response/User/DtoUserProfileInfo";
import {BlockAvatarWrapper, BlockContentWrapper, BlockWithBorderFlex} from "../../../../../../styles/global-elements";
import {AvatarNameBlock} from "../../../../../../components/Ui/Elements/AvatarNameBlock";
import {t} from "@lingui/macro";
import {Table} from "./Table";
import {StringHelper} from "../../../../../../../helpers/StringHelper";

interface NextLessonsProps {
    items: DtoLessonPlanItem[];
    primaryTeacher: DtoUserProfileInfo;
}

export const NextLessons: React.FC<NextLessonsProps> = (
    {
        primaryTeacher,
        items
    }
) => {
    
    const teacherName = useMemo(() => StringHelper.extractFirstAndMiddleName(primaryTeacher.name, true), [primaryTeacher.name]);
    
    return <BlockWithBorderFlex>
        <BlockAvatarWrapper>
            <AvatarNameBlock name={teacherName}
                             avatarFileId={primaryTeacher.avatarFileId}
                             position={t`Учитель`}
            />
        </BlockAvatarWrapper>
        <BlockContentWrapper>
            <Table items={items}/>
        </BlockContentWrapper>
    </BlockWithBorderFlex>
}