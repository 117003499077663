import {call, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {ILogger} from "../../components/Logger/ILogger";
import {AuthUserRequestContext, UserActionTypes} from "../../store/user/type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {AuthLoginResultDto} from "../../components/WsApiClient/ApiDto/Response/Auth/LoginResultDto";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";
import {ApplicationState} from "../../store";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import * as AppActionCreators from "../../store/app/actions";
import {zeroWsReconnectCount} from "../../store/app/actions";
import * as UserActionCreators from "../../store/user/actions";
import * as StreamEventsActionCreators from "../../store/streamEvent/actions";
import {startHandlingEvents} from "../../store/streamEvent/actions";
import * as UserOnlineStateSubscriptionsActionCreators from "../../store/userOnlineStateSubscriptions/actions";
import * as SlidesWorkDataSaveQueueActionCreators from "../../store/slidesWorkDataSaveQueue/actions";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {userIsTeacher} from "../../store/user/selector";
import {IPagesBroadcastService} from "../../services/pages-broadcast-service/IPagesBroadcastService";
import {ICustomStorage} from "../../components/CustomStorage/ICustomStorage";
import {TargetsEnum} from "../../components/CustomStorage/TargetsEnum";

/**
 *  Сага отвечает за обработку результата авторизации пользователя на WS сервере вне зависимости от места начала авторизации
 *  (авторизация по токену при старте приложения или же авторизация по токену переподключение по время работы приложения).
 */
export function* watchUserWsAuthorizationResult() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<AuthLoginResultDto>, AuthUserRequestContext>>>(
        UserActionTypes.PROCESS_USER_WS_AUTH_RESULT,
        userAuthorizationResult
    );
}

const getPingTimeUpdateSuspendedValue = (state: ApplicationState) => state.app.pingTimeUpdateSuspended;
const getReconnectionToken = (state: ApplicationState) => state.user.wsReconnectionToken;

function* userAuthorizationResult(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<AuthLoginResultDto>, AuthUserRequestContext>>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
    const wsApiClient: IWsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    logger.info(
        LoggerSectionsEnum.WS_COMPONENT,
        (data.payload.responseContext.authByReconnectionToken)
            ? 'Ws reconnect auth completed'
            : 'Ws connect auth completed'
    );

    // Если возникла какая-то проблема загрузки данных
    if (data.payload.response.status !== WsResponseStatusEnum.OK) {
        let logErrorText = '';

        if (!data.payload.responseContext.authByReconnectionToken) {
            logErrorText = 'Can not auth user by session token on app initialization.';
        } else {
            logErrorText = 'Can not auth user by reconnection token.';
        }

        logErrorText = logErrorText + ` Response status code: ${data.payload.response.status}.`;

        if (data.payload.response.errors === null) {
            logErrorText = logErrorText + ' Errors in response object is undefined.';
        } else {
            logErrorText = logErrorText + ` Technical error text: ${data.payload.response.errors.technicalText}`;
        }

        if (data.payload.response.status === WsResponseStatusEnum.ACCESS_DENIED) {
            logger.info(LoggerSectionsEnum.WS_COMPONENT, logErrorText);
        } else {
            logger.error(LoggerSectionsEnum.WS_COMPONENT, logErrorText);
        }

        yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.DISCONNECTED));

        if (data.payload.response.status === WsResponseStatusEnum.ACCESS_DENIED) {
            if (data.payload.responseContext.authByReconnectionToken) {
                logger.info(LoggerSectionsEnum.WS_COMPONENT, 'Try auth by session token');

                yield put(UserActionCreators.startUserAuthOnWsConnection());
            }

            return;
        }

        yield put(AppActionCreators.setInFatalErrorState(true));

        return;
    }

    const reconnectionToken = (yield select(getReconnectionToken)) as string | null;

    let updateDateDisabled: boolean = yield select(getPingTimeUpdateSuspendedValue);

    if (!updateDateDisabled) {
        yield putResolve(AppActionCreators.setLastPingDate(data.payload.response.result.currentTime));
    }

    if (reconnectionToken !== null) {
        // Соединение после разрыва. Нужно загрузить свежие данные.
        // Восстанавливаем обработку событий stream events - нужно выполнить загрузку упущенных событий
        yield put(StreamEventsActionCreators.loadEventsAfterReconnect());

        // Восстанавливаем подписки на статусы (онлайн/оффлайн) пользователей.
        yield put(UserOnlineStateSubscriptionsActionCreators.resubscribeActiveSubscriptions());

        yield put(SlidesWorkDataSaveQueueActionCreators.startSaveDataAfterReconnect());
    } else {
        yield put(startHandlingEvents());

        yield put(SlidesWorkDataSaveQueueActionCreators.startHandleCycle());
    }

    yield put(UserActionCreators.setUserWsReconnectionToken(data.payload.response.result.reconnectionToken));
    yield put(AppActionCreators.setWsConnectionStatus(WsConnectionStatusEnum.AUTHORIZED));
    yield put(AppActionCreators.setWsDisconnectAlertImmediately());
    yield put(zeroWsReconnectCount());
    yield put(AppActionCreators.reportLocationPosition(document.location.pathname));

    // Если в этом окне используется localStorage, оповестим остальные вкладки об открытии вкладки с этим пользователем
    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);

    if (customStorage.getCurrentTarget() === TargetsEnum.LOCAL_STORAGE) {
        const pagesBroadcastService = container.resolve<IPagesBroadcastService>(DiTokens.PAGES_BROADCAST_SERVICE);
        pagesBroadcastService.noticeAboutNewWindow(data.payload.response.result.userId);
    }

    // Проверим: есть ли открытые комнаты урока
    const isTeacher = (yield select(userIsTeacher)) as boolean;

    if (!isTeacher) {
        yield call(() => wsApiClient.query(
            ApiMethodEnum.LESSON_ROOM_GET_OPENED_ROOMS,
            {},
            undefined,
            false
        ));
    }
}
