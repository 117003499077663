import React from "react";
import {EnergyBar} from "./EnergyBar";
import styled from "styled-components";
import {NextButton} from "./NextButton";

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
`;

interface RightBlockProps {
    nextBtnLabel: string;
}

export const RightBlock: React.FC<RightBlockProps> = ({nextBtnLabel}) => {
    return <div>
        <EnergyBar/>
        <ButtonsWrapper>
            <NextButton label={nextBtnLabel}/>
        </ButtonsWrapper>
    </div>
}