import React from "react";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";

interface EmptyListNotificationBoxProps {
    searchStringIsEmpty: boolean;
}

export const EmptyListNotificationBox: React.FC<EmptyListNotificationBoxProps> = (
    {
        searchStringIsEmpty
    }
) => {
    if (!searchStringIsEmpty) {
        return <NoticeBlock>
            <>
                <NoticeBlockTitle>
                    <Trans>Не найдены ученики</Trans>
                </NoticeBlockTitle>

                <NoticeBlockText>
                    <Trans>По указанному запросу не нашлось учеников</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    return <NoticeBlock>
        <>
            <NoticeBlockTitle>
                <Trans>Список учеников пока пуст</Trans>
            </NoticeBlockTitle>

            <NoticeBlockText>
                <Trans>Добавьте своего первого ученика</Trans>
            </NoticeBlockText>
        </>
    </NoticeBlock>;
}