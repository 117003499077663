import {IsNotEmpty, IsNumber} from "class-validator";
import {IBaseApiDto} from "../IBaseApiDto";

export class ListDto<T> implements IBaseApiDto {
    @IsNotEmpty()
    @IsNumber()
    count: number;

    @IsNotEmpty()
    @IsNumber()
    totalCount: number;

    items: Array<T>;
}
