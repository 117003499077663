import {IsNotEmpty, IsNumber, IsString, Length} from "class-validator";
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO ответа на запрос информации о следующем занятии
 */
export class DtoGroupNextLessonInfo {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_DATE_FORMAT_LENGTH, LengthEnum.DEFAULT_DATE_FORMAT_LENGTH)
    date: string;

    @IsNotEmpty()
    @IsString()
    startTime: string;

    @IsNotEmpty()
    @IsString()
    endTime: string;

    @IsNotEmpty()
    @IsNumber()
    duration: number;

    @IsNotEmpty()
    @IsString()
    address: string;
}