import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length} from "class-validator";
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO элемента списка уроков группы
 */
export class DtoLessonListItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsNumber()
    teacherId: number;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_DATE_FORMAT_LENGTH, LengthEnum.DEFAULT_DATE_FORMAT_LENGTH)
    lessonDate: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_TIME_FORMAT_LENGTH, LengthEnum.DEFAULT_TIME_FORMAT_LENGTH)
    timeStart: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_TIME_FORMAT_LENGTH, LengthEnum.DEFAULT_TIME_FORMAT_LENGTH)
    timeEnd: string;

    @IsOptional()
    @IsNumber()
    breakTime: number;

    @IsBoolean()
    hasComment: boolean;

    @IsBoolean()
    hasHomeTask: boolean;

    @IsBoolean()
    isFake: boolean;

    @IsBoolean()
    isDraft: boolean;

    @IsNotEmpty()
    @IsString()
    teacherName: string;
}
