import React, {useContext, useEffect, useMemo, useRef} from "react";
import {VideoGalleryParticipantProfile} from "../../Types";
import {CommonContext, ICommonContext} from "../../../../../contexts/CommonContext";
import {VideoPlayer} from "./VideoPlayer";
import styled from "styled-components";
import {ControlsLayer} from "../Common/ControlsLayer";
import {
    IVideoSubscriptionsActionsContext,
    VideoSubscriptionsActionsContext
} from "../../../../../actions/video-subscriptions/VideoSubscriptionsActionsContext";
import {ConferenceModeEnum, StreamStatusEnum} from "../../../../../Types";
import {useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../../../../store/user/selector";
import {
    IVideoRoomActionsContext,
    VideoRoomActionsContext
} from "../../../../../actions/video-room/VideoRoomActionsContext";
import {StreamTypeEnum} from "../../../../../../../../components/JanusClient/Types";
import classNames from "classnames";
import {AvatarStatusLayer} from "../Common/AvatarStatusLayer";
import {t} from "@lingui/macro";
import {AudioPlayer} from "./AudioPlayer";
import {NotificationTypesEnum, openNotification} from "../../../../../../Ui/Elements/Notification";

const VideoPlayerStyled = styled(VideoPlayer)`
  object-fit: contain;

  &.hidden {
    visibility: hidden;
  }
`;

interface ScreenVideoItemProps {
    profile: VideoGalleryParticipantProfile;
    randomItemId: string;
}

export const ScreenVideoItem: React.FC<ScreenVideoItemProps> = ({profile, randomItemId}) => {
    const currentUserId = useSelector(currentUserIdSelector);

    const subscriptionVideoRequestWasSend = useRef<boolean>(false);
    const subscriptionAudioRequestWasSend = useRef<boolean>(false);
    const subscriptionVideoConnectedToVideo = useRef<boolean>(false);
    const subscriptionAudioConnectedToAudio = useRef<boolean>(false);

    const commonRoomContext = useContext<ICommonContext>(CommonContext);
    const videoSubscriptionsActionsContext = useContext<IVideoSubscriptionsActionsContext>(VideoSubscriptionsActionsContext);

    const videoRoomActionsContext = useContext<IVideoRoomActionsContext>(VideoRoomActionsContext);
    const videoPlayerRef = useRef<HTMLVideoElement>(null);
    const audioPlayerRef = useRef<HTMLAudioElement>(null);

    const currentUserInVideoRoom = useMemo(() => {
        return commonRoomContext.conferenceParticipants.find(item => item.id === profile.id) ?? null;
    }, [commonRoomContext.conferenceParticipants, profile.id]);

    const screenVideoStatus = useMemo<StreamStatusEnum>(() => {
        if (!currentUserInVideoRoom) {
            return StreamStatusEnum.NOT_AVAILABLE;
        }

        return currentUserInVideoRoom.screenVideo;
    }, [currentUserInVideoRoom]);

    const screenAudioStatus = useMemo<StreamStatusEnum>(() => {
        if (!currentUserInVideoRoom) {
            return StreamStatusEnum.NOT_AVAILABLE;
        }

        return currentUserInVideoRoom.screenAudio;
    }, [currentUserInVideoRoom]);

    const statusText = useMemo<string | null>(() => {
        if (currentUserInVideoRoom && currentUserInVideoRoom.videoStatus === StreamStatusEnum.CONNECTING) {
            return t`Подключение видео экрана`;
        }

        return null;
    }, [currentUserInVideoRoom]);

    const toggleFullScreen = () => {
        if (!videoPlayerRef.current) {
            return;
        }

        videoPlayerRef.current.requestFullscreen().catch(() => {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Ошибка`,
                t`Не удалось активировать полноэкранный режим`
            )
        });
    }

    useEffect(() => {
        if ((screenVideoStatus === StreamStatusEnum.AVAILABLE) || (screenVideoStatus === StreamStatusEnum.CONNECTED)) {
            videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.SCREEN, randomItemId);

            subscriptionVideoRequestWasSend.current = true;
        }

        if (profile.id !== currentUserId) { // Для себя своё аудио подключать нельзя
            if ((screenAudioStatus === StreamStatusEnum.AVAILABLE) || (screenAudioStatus === StreamStatusEnum.CONNECTED)) {
                videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.AUDIO, randomItemId);

                subscriptionAudioRequestWasSend.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Если мы подписывались на видео, но оно стало недоступно
        if ((screenVideoStatus === StreamStatusEnum.NOT_AVAILABLE) && (subscriptionVideoRequestWasSend.current)) {
            subscriptionVideoRequestWasSend.current = false;

            return;
        }

        // Если далее видео стало доступно и мы ранее на него не подписывались
        if (screenVideoStatus === StreamStatusEnum.AVAILABLE && !subscriptionVideoRequestWasSend.current) {
            videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.VIDEO, randomItemId);

            subscriptionVideoRequestWasSend.current = true;
        }

        // Если видео подключено, мы на него подписывались, но не подключили к html компоненту
        if ((screenVideoStatus === StreamStatusEnum.CONNECTED) && (!subscriptionVideoConnectedToVideo.current)) {
            subscriptionVideoConnectedToVideo.current = true;

            if (videoPlayerRef.current) {
                videoRoomActionsContext.attachScreenVideoToElement(profile.id, videoPlayerRef.current);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenVideoStatus]);

    useEffect(() => {
        // Если мы подписывались на аудио, но оно стало недоступно
        if ((screenAudioStatus === StreamStatusEnum.NOT_AVAILABLE) && (subscriptionAudioRequestWasSend.current)) {
            subscriptionAudioRequestWasSend.current = false;

            return;
        }

        if (profile.id !== currentUserId) { // Для себя своё аудио подключать нельзя
            // Если далее аудио стало доступно и мы ранее на него не подписывались
            if (screenAudioStatus === StreamStatusEnum.AVAILABLE && !subscriptionAudioRequestWasSend.current) {
                videoSubscriptionsActionsContext.subscribeToVideoStream(profile.id, StreamTypeEnum.AUDIO, randomItemId);

                subscriptionAudioRequestWasSend.current = true;
            }

            // Если аудио подключено, мы на него подписывались, но не подключили в html компоненту
            if ((screenAudioStatus === StreamStatusEnum.CONNECTED) && (!subscriptionAudioConnectedToAudio.current)) {
                subscriptionAudioConnectedToAudio.current = true;

                if (audioPlayerRef.current) {
                    videoRoomActionsContext.attachScreenAudioToElement(profile.id, audioPlayerRef.current);
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenAudioStatus]);

    useEffect(() => {
        return () => {
            if (profile.id !== currentUserId) {
                if (subscriptionVideoRequestWasSend.current) {
                    videoSubscriptionsActionsContext.unsubscribeFromVideoStream(profile.id, StreamTypeEnum.SCREEN, randomItemId);
                }

                if (subscriptionAudioRequestWasSend.current) {
                    videoSubscriptionsActionsContext.unsubscribeFromVideoStream(profile.id, StreamTypeEnum.AUDIO, randomItemId);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <VideoPlayerStyled
            ref={videoPlayerRef}
            className={classNames(screenVideoStatus !== StreamStatusEnum.CONNECTED && 'hidden')}
        />
        {
            (profile.id !== currentUserId) && <AudioPlayer ref={audioPlayerRef}/>
        }
        {
            (screenVideoStatus !== StreamStatusEnum.CONNECTED)
            && <AvatarStatusLayer avatarFileId={profile.avatarFileId} userName={profile.name} statusText={statusText}/>
        }
        <ControlsLayer
            profile={profile}
            userConferenceParticipantItem={currentUserInVideoRoom}
            noSound={screenAudioStatus === StreamStatusEnum.NOT_AVAILABLE || screenAudioStatus === StreamStatusEnum.ERROR}
            toggleFullScreenBtnOnClick={
                (commonRoomContext.currentMode === ConferenceModeEnum.FULL_SCREEN)
                    ? toggleFullScreen
                    : undefined
            }
        />
    </>
}
