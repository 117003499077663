import {IsNotEmpty, IsNumber, Max, Min} from "class-validator";
import {DtoResponseWithPagination} from "../DtoResponseWithPagination";
import {DtoSELessonOpened} from "./DtoSELessonOpened";

export class DtoSELessonOpenedList extends DtoResponseWithPagination<DtoSELessonOpened> {
    @IsNotEmpty()
    @IsNumber()
    @Max(200)
    @Min(0)
    limit: number;
}
