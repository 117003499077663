import {IsNotEmpty, IsNumber, IsString, Length, MaxLength} from 'class-validator';

/**
 * DTO предметной области
 */
export class DtoSubjectArea {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsString()
    @Length(1, 100)
    alias: string;

    @IsNotEmpty()
    @IsString()
    @MaxLength(100)
    internationalName: string;
}
