import {IsNotEmpty, IsNumber} from "class-validator";

/**
 * DTO результата создания договора на обучение ученика
 */
export class DtoCreateAgreementResponse {
    @IsNotEmpty()
    @IsNumber()
    agreementId: number;

    @IsNotEmpty()
    @IsNumber()
    groupId: number;
}
