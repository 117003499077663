import {JanusJS} from "../OfficialClient/janus";
import {IJanusVideoRoomPlugin} from "./IJanusVideoRoomPlugin";
import {ILogger} from "../../Logger/ILogger";
import {LoggerSectionsEnum} from "../../Logger/LoggerSectionsEnum";
import {JoinVideoRoomParams, StreamUpdateParams, VideoRoomCallbacks} from "./Types";
import {Publisher} from "./PeerConnectionHandlers/Publisher";
import {PublisherNameUtils} from "./PublisherNameUtils";
import {IMediaDeviceService} from "../../../services/media-device/IMediaDeviceService";
import {IJanusConnection} from "../IJanusConnection";
import {IDeviceMediaStreamFetcher} from "../../DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {Item, ItemState, StreamsStore} from "./StreamsStore";
import {PublisherMidDescriptionUtils} from "./PublisherMidDescriptionUtils";
import {StreamTypeEnum} from "../Types";
import {Subscriber, SubscribeRequestItem} from "./PeerConnectionHandlers/Subscriber";

const STREAM_STORE_SUBSCRIBER_ID = 'VideoRoomPlugin';

export class JanusVideoRoomPlugin implements IJanusVideoRoomPlugin {
    protected logger: ILogger;
    protected mediaDeviceService: IMediaDeviceService;
    protected deviceMediaStreamFetcher: IDeviceMediaStreamFetcher;
    protected janusConnection: IJanusConnection;

    protected videoRoomOpaqueId: string;
    protected myPrivateId: string | null;
    protected myUserId: string | null;
    protected publisherUserName: string | null;

    protected roomId: string | null;
    protected roomJoinKey: string | null;

    protected callbacks: VideoRoomCallbacks | null;

    protected publisher: Publisher | null;
    protected subscriber: Subscriber | null;
    protected stuckFeedIds: string[];

    protected localCameraStream: MediaStream | null;
    protected localScreenSharingStreamIsAvailable: boolean;

    protected inDisconnectProcess: boolean;

    protected streamStore: StreamsStore;

    constructor(logger: ILogger, mediaDeviceService: IMediaDeviceService, janusConnection: IJanusConnection, deviceMediaStreamFetcher: IDeviceMediaStreamFetcher) {
        this.logger = logger;
        this.mediaDeviceService = mediaDeviceService;
        this.janusConnection = janusConnection;
        this.deviceMediaStreamFetcher = deviceMediaStreamFetcher;

        this.videoRoomOpaqueId = "videoroom-" + JanusJS.Janus.randomString(12);

        this.myPrivateId = null;
        this.myUserId = null;
        this.publisherUserName = null;

        this.roomId = null;
        this.roomJoinKey = null;

        this.callbacks = null;
        this.publisher = null;
        this.subscriber = null;
        this.stuckFeedIds = [];

        this.inDisconnectProcess = false;
        this.localScreenSharingStreamIsAvailable = false;
        this.localCameraStream = null;

        this.streamStore = new StreamsStore(this.logger);

        this.streamStore.subscribe(
            STREAM_STORE_SUBSCRIBER_ID,
            this.streamStoreEventHandler.bind(this)
        );
    }

    /**
     * @inheritDoc
     */
    public destroy(): void {
        this.inDisconnectProcess = true;
        this.logger.info(LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN, 'Called destroy proc');

        this.streamStore.unsubscribe(STREAM_STORE_SUBSCRIBER_ID);

        if (this.publisher) {
            this.publisher.destroy();
        }

        this.streamStore.destroy();
    }

    /**
     * @param params
     */
    public joinToVideoRoom(params: JoinVideoRoomParams): void {
        const joinDate = new Date();

        this.roomId = params.roomId;
        this.roomJoinKey = params.roomJoinKey;
        this.myUserId = params.userId;
        this.publisherUserName = PublisherNameUtils.createComplexUserId(this.myUserId, joinDate, false);
        this.callbacks = params.callbacks;

        if (!this.myUserId) {
            throw new Error('User id for publisher is null');
        }
        if (!this.publisherUserName) {
            throw new Error('Publisher user name is null');
        }

        if (!this.roomId || !this.roomJoinKey) {
            throw new Error(`roomId or roomJoinKey is null`);
        }

        if (this.publisher !== null) {
            throw new Error(`Publisher already connected`);
        }

        if (this.subscriber !== null) {
            throw new Error(`Subscriber already connected`);
        }

        this.publisher = new Publisher(
            this.logger,
            this.mediaDeviceService,
            this.deviceMediaStreamFetcher,
            this.myUserId,
            this.publisherUserName,
            this.janusConnection,
            {
                roomId: this.roomId,
                roomJoinKey: this.roomJoinKey,
                opaqueId: this.videoRoomOpaqueId
            },
            this.streamStore,
            {
                onMyPrivateIdReceived: (myPrivateId: string) => {
                    this.myPrivateId = myPrivateId;
                },
                successfullyJoined: () => {
                    this.callbacks?.publisherJoinedSuccessfully();
                },
                // trackStateChange: (changeType: StreamUpdateParams, isReady: boolean) => {
                //     if (!this.callbacks || !this.myUserId) {
                //         return;
                //     }
                //
                //     this.callbacks.onTrackReadyChange(this.myUserId, changeType, isReady);
                // },
                onProblemWithCodecs: () => {
                    this.logger.error(
                        LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                        'Problem with codecs'
                    );

                    this.callbacks?.publisherError();
                },
                handleParticipants: this.handleParticipants.bind(this),
                handleDisconnectedParticipants: this.handleDisconnectedParticipants.bind(this),
                onError: (errorType) => {
                    this.logger.error(
                        LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                        `Publisher error, type: `,
                        errorType
                    );

                    this.callbacks?.publisherError();
                },
                onCameraChanged: this.callbacks?.onCameraChanged,
                onCameraGoneAway: this.callbacks?.onCameraGoneAway,
                onCameraChangeError: this.callbacks?.onCameraChangeError,
                slowLinkMessage: this.callbacks?.publisherSlowLinkMessage,
                iceDisconnected: this.callbacks?.publisherIceDisconnected,
                onCleanup: this.callbacks?.publisherCleanup,
                onDestroy: this.callbacks?.publisherOnDestroy
                // onLocalCameraStream: (stream) => {
                //     this.localCameraStream = stream;
                //
                //     this.callbacks?.onCameraStreamAvailable(!!stream);
                // },
                // onLocalScreenStreamAvailable: (value) => {
                //     this.localScreenSharingStreamIsAvailable = value;
                //
                //     this.callbacks?.onScreenSharingStreamAvailable(value);
                // }
            }
        );

        this.publisher.publish();

        this.subscriber = new Subscriber(
            this.logger,
            this.janusConnection,
            {
                roomId: this.roomId,
                roomJoinKey: this.roomJoinKey,
                opaqueId: this.videoRoomOpaqueId,
                myPrivateId: this.myPrivateId ?? ''//TODO удалить, если не актуально
            },
            {
                successfullyJoined: () => {
                    this.callbacks?.subscriberJoinedSuccessfully();
                },
                slowLinkMessage: (packetsList) => {
                    this.callbacks?.subscriberSlowLinkMessage(packetsList);
                },
                onError: () => {
                    this.callbacks?.subscriberError();
                },
                onHangUp: () => {
                    this.callbacks?.subscriberOnHangUp();
                },
                handleStartedStreams: (userId: string, feedId: string, mid: string, type: StreamTypeEnum, track: MediaStreamTrack) => {
                    this.streamStore.updateItem(
                        userId,
                        feedId,
                        type,
                        mid,
                        {
                            trackId: track.id,
                            track: track,
                            state: ItemState.READY,
                            stream: null
                        }
                    );
                },
                handleStoppedStreams: (userId: string, feedId: string, mid: string, type: StreamTypeEnum) => {
                    this.streamStore.updateItem(
                        userId,
                        feedId,
                        type,
                        mid,
                        {
                            trackId: null,
                            track: null,
                            state: ItemState.AVAILABLE,
                            stream: null
                        }
                    );
                },
                iceDisconnected: this.callbacks?.subscriberIceDisconnected,
                onCleanup: this.callbacks?.subscriberCleanup
            }
        );

        this.subscriber.subscribe();
    }

    public subscribeToStreams(subscribeRequestItem: SubscribeRequestItem[]): void {
        this.subscriber?.subscribeToStreams(subscribeRequestItem);
    }

    public unsubscribeFromStreams(subscribeRequestItem: SubscribeRequestItem[]): void {
        this.subscriber?.unsubscribeFromStreams(subscribeRequestItem);
    }

    public attachStreamToElement(userId: string, streamType: StreamTypeEnum, element: HTMLVideoElement | HTMLAudioElement): boolean {
        const streamInfo = this.streamStore.findByUserIdAndType(userId, streamType);

        if (!streamInfo) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                `Not found stream user ${userId} stream with type ${streamType}`
            );

            return false;
        }

        if (streamInfo.track === null) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                `Stream track is null in stream item: `,
                streamInfo
            );

            return false;
        }

        if (streamInfo.stream === null) {
            streamInfo.stream = new MediaStream([streamInfo.track]);
        }

        JanusJS.Janus.attachMediaStream(
            element,
            streamInfo.stream
        );

        return true;
    }

    protected handleDisconnectedParticipants(feedId: string): void {
        if (this.stuckFeedIds.some(item => item === feedId)) {
            // Это старый зависший и уже обработанный feedid. Игнорируем событие его отключения.
            this.logger.info(
                LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                `Participant disconnecting ignored (disconnected old stuck feed id). FeedId: `,
                feedId
            );

            return;
        }

        const streams = this.streamStore.findAllByFeedId(feedId);

        streams.forEach(item => {
            this.streamStore.deleteItem(
                item.userId,
                item.feedId,
                item.type,
                item.mid ?? undefined
            )
        })
    }

    protected streamStoreEventHandler(oldItemState: Item | null, newItemState: Item | null, _diff?: Partial<Item>) {
        // Произошло создание или обновление элемента
        if (newItemState !== null) {

            // Создание элемента текущего пользователя (локальные потоки)
            switch (newItemState.type) {
                case StreamTypeEnum.SCREEN: {
                    this.callbacks?.onTrackStateChange(
                        newItemState.userId,
                        StreamUpdateParams.SCREEN_VIDEO,
                        newItemState.state
                    );

                    break;
                }
                case StreamTypeEnum.VIDEO: {
                    this.callbacks?.onTrackStateChange(
                        newItemState.userId,
                        StreamUpdateParams.VIDEO,
                        newItemState.state
                    );

                    break;
                }
                case StreamTypeEnum.AUDIO: {
                    this.callbacks?.onTrackStateChange(
                        newItemState.userId,
                        StreamUpdateParams.SCREEN_AUDIO,
                        newItemState.state
                    );

                    break;
                }
            }
        }

        // Произошло удаление элемента
        if (oldItemState !== null && newItemState === null) {

            // Удаление элемента текущего пользователя (локальные потоки)
            switch (oldItemState.type) {
                case StreamTypeEnum.SCREEN: {
                    this.callbacks?.onTrackStateChange(
                        oldItemState.userId,
                        StreamUpdateParams.SCREEN_VIDEO,
                        null
                    );

                    break;
                }
                case StreamTypeEnum.VIDEO: {
                    this.callbacks?.onTrackStateChange(
                        oldItemState.userId,
                        StreamUpdateParams.VIDEO,
                        null
                    );

                    break;
                }
                case StreamTypeEnum.AUDIO: {
                    this.callbacks?.onTrackStateChange(
                        oldItemState.userId,
                        StreamUpdateParams.SCREEN_AUDIO,
                        null
                    );

                    break;
                }
            }
        }
    }

    protected handleParticipants(feedsList: JanusJS.PublisherFeed[]): void {
        if (!this.roomId || !this.roomJoinKey || !this.myPrivateId) {
            throw new Error('roomId or roomJoinKey or myPrivateId is null');
        }

        this.logger.info(LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN, "Got a list of available publishers/feeds:", feedsList);

        for (let itemIndex in feedsList) {
            if (feedsList[itemIndex].dummy) {
                continue;
            }

            const feedId = feedsList[itemIndex].id;
            const complexUserId = feedsList[itemIndex].display;
            const streams = feedsList[itemIndex].streams;

            const userData = PublisherNameUtils.parseComplexUserId(complexUserId);

            if (userData.userId === this.myUserId) {
                // Это какой-то наш залипший прошлый сеанс, пропускаем.
                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                    `Find our old deprecated publisher in room with complexId ${complexUserId}`
                );

                continue;
            }

            streams.forEach((stream) => {
                if (!stream.description) {
                    if (!stream.disabled) {
                        this.logger.warning(
                            LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                            `Stream description is empty (stream is not disabled)`,
                            stream
                        );

                        return;
                    }

                    if (stream.disabled) {
                        // Проверим - если этот поток у нас является подключенным, запишем как отключенный
                        const streamsByMid = this.streamStore.findByMid(stream.mid);

                        if (streamsByMid?.length === 0) {
                            // Поток отключен и мы это уже ранее у себя зафиксировали - пропускаем
                            return;
                        }

                        streamsByMid.forEach((item) => {
                            this.streamStore.deleteItem(
                                item.userId,
                                item.feedId,
                                item.type,
                                item.mid as string // Здесь Mid точно есть
                            );
                        });
                    }

                    return;
                }

                const descriptionData = PublisherMidDescriptionUtils.parseMidDescription(
                    stream.description
                );

                // Проверим - нет ли уже данных об этом потоке
                const existStreamInfo = this.streamStore.findByUserIdAndType(userData.userId, descriptionData.streamType);

                let subscribeImmediately = false;

                if ((existStreamInfo !== null) && (existStreamInfo.mid === stream.mid)) {
                    // Информация об этом потоке уже есть.

                    // Сравним время, указанное в display
                    if (existStreamInfo.joinTs > descriptionData.ts) {
                        // Если полученный display содержит старый или такой же ts подключения, то игнорируем запись
                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                            (existStreamInfo.joinTs > descriptionData.ts)
                                ? `handleParticipants called for old ts for user id ${existStreamInfo.userId}`
                                : `handleParticipants called for equal ts for user id ${existStreamInfo.userId}`,
                            stream
                        );

                        // игнорируем
                        return;
                    } else {
                        // Полученный display содержит новый ts подключения,
                        // значит старый поток зависший - нужно его удалить.

                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                            `FeedId ${existStreamInfo.feedId} for user ${userData.userId} is deprecated. Found new feedId ${feedId}.`
                        );

                        if (existStreamInfo.feedId !== null) {
                            // Записываем feedId в список зависших, чтобы когда когда-то в будущем придёт сигнал
                            // об его отключении, мы игнорировали этот сигнал
                            this.stuckFeedIds.push(existStreamInfo.feedId);
                        }

                        if (existStreamInfo.stream !== null) {
                            // Была подписка
                            subscribeImmediately = true;
                        }

                        this.streamStore.deleteItem(
                            existStreamInfo.userId,
                            null,
                            StreamTypeEnum.SCREEN
                        );

                        this.streamStore.deleteItem(
                            existStreamInfo.userId,
                            null,
                            StreamTypeEnum.AUDIO
                        );

                        this.streamStore.deleteItem(
                            existStreamInfo.userId,
                            null,
                            StreamTypeEnum.VIDEO
                        );
                    }
                }

                this.streamStore.addItem({
                    userId: userData.userId,
                    feedId: feedId,
                    joinTs: userData.ts,
                    mid: stream.mid,
                    type: descriptionData.streamType,
                    trackId: null,
                    track: null,
                    state: ItemState.AVAILABLE,
                    stream: null
                });

                if (subscribeImmediately) {
                    this.logger.info(
                        LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
                        `Try to subscribe immediately to user ${userData.userId}.`
                    );

                    this.subscribeToStreams([
                        {
                            mid: stream.mid,
                            feedId: feedId
                        }
                    ]);
                }
            });
        }
    }

    /**
     * @inheritDoc
     */
    public startOwnVideo = () => {
        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
            `Start own video`
        );

        if (this.publisher) {
            this.publisher.publishCamera();
        }
    }

    /**
     * @inheritDoc
     */
    public stopOwnVideo = () => {
        if (this.publisher) {
            this.publisher.unpublishCamera();
        }
    }

    protected closeScreenPublisher = () => {
        if (!this.publisher) {
            return;
        }

        this.publisher.unpublishScreen();
    }

    public stopScreenShare = () => {
        this.closeScreenPublisher();
    }

    public startScreenShare = (): void => {
        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEOROOM_PLUGIN,
            `Start screen sharing`
        );

        if (this.publisher) {
            this.publisher.publishScreen();
        }
    }

    getStreamStore(): StreamsStore {
        return this.streamStore;
    }
}
