import {Editor} from "slate";
import {IBaseElement} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withInlines = (editor: Editor) => {
    const {isInline} = editor

    editor.isInline = (element: IBaseElement) =>
        [
            ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX,
            ElementTypes.EXERCISE_FILL_GAPS_TEXT,
            ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT,
            ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP,
            ElementTypes.TRANSLATED
        ].includes(element.type) || isInline(element)

    return editor
}