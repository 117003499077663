import {css} from "styled-components";

export const RegularText = css`
  font-size: 16px;
  line-height: 1.5em;
  //line-height: 36px;
  font-weight: normal;

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

export const HeadingOne = css`
  font-size: 17px;
  line-height: 1.5em;
  font-weight: bold;

  @media (${({theme}) => theme.media.small}) {
    font-size: 20px;
  }
`;