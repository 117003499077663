import React, {MouseEvent} from "react";
import {ModalResultType as LessonModalResultType, TmLessonModal} from "../modals/lesson-modal";
import styled from "styled-components";
import {RegularTextCss, TopIndex} from "../../../styles/global-elements";
import {Sortable, SortableItemComponent} from "../../Sortable/Sortable";
import {RouterLink} from "../../Ui/Elements/Link";
import {MinusIconStyled} from "../common/styled-components";
import {ReactComponent as SettingsIcon} from "../../Ui/Svg/Settings.svg";
import {ReactComponent as AddSlideIcon} from "../../Ui/Svg/AddSlide.svg";
import {DtoTmLesson} from "../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {Popup, PopupItem} from "../../Ui/Elements/Popup";
import {t} from "@lingui/macro";
import {PopupActions} from "reactjs-popup/dist/types";
import {ReactComponent as SmallCircleOutlineIcon} from "../../Ui/Svg/SmallCircleOutline.svg";
import {ReactComponent as CheckIcon} from "../../Ui/Svg/Check.svg";
import {DtoTmSlide} from "../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {TmSlideListModal} from "../slide-list-modal";

const LessonsListStyled = styled.div`
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 30px;
  }
`;

const SortableWrapper = styled.div`
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 30px;
  }

  .handle-btn {
    align-self: flex-start;
    padding-top: 5px;
  }
`;

const LessonItem = styled.div`
  ${RegularTextCss};

  display: flex;
  flex-direction: row;
  padding: 5px 0 15px 0;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LessonItemIconWrapper = styled.div`
  max-height: 40px;
`;

const LessonItemTextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const LessonItemName = styled(RouterLink)`
  ${RegularTextCss};

  padding-top: 7px;

  color: ${({theme}) => theme.colors.textPrimary};
  white-space: normal;
  word-break: break-all;

  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const LessonItemDescription = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
  white-space: normal;
  word-break: break-all;
  padding-top: 10px;
  cursor: default;
`;

const LessonItemControlsWrapper = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LessonItemBtnWrapper = styled.div`
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;

  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export interface SlideSelectorConfig {
    selectedSlides: string[];
    onSelectSlide: (tmSlide: DtoTmSlide) => void;
}

interface LessonsListProps {
    apiToken: string;
    lessonItems: DtoTmLesson[];
    sectionId: string;
    modalOnResult: (action: LessonModalResultType, item: (DtoTmLesson | null)) => void;
    reorderItems?: (from: number, to: number) => void;
    itemsDisabled?: boolean;
    selectLessonMethod?: (lessonItem: DtoTmLesson) => void;
    selectMode?: boolean;
    usedLessonIds?: string[];
    slideSelector?: SlideSelectorConfig;
}

interface LessonsListState {
    currentItemForModal: DtoTmLesson | null;
}

export class LessonsList extends React.Component<LessonsListProps, LessonsListState> {
    protected modalRef;
    protected slidesListModalRef;

    constructor(props: Readonly<LessonsListProps> | LessonsListProps) {
        super(props);

        this.state = {
            currentItemForModal: null
        }

        this.modalRef = React.createRef<PopupActions>();
        this.slidesListModalRef = React.createRef<TmSlideListModal>();
    }

    protected linkToEditor = (lessonId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_EDITOR,
            [
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );
    }

    protected linkToPlayer = (lessonId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_LESSON_PLAYER,
            [
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );
    }

    protected lessonItemOnClick = (e: MouseEvent<HTMLAnchorElement>, item: DtoTmLesson) => {
        if (this.props.selectLessonMethod === undefined) {
            return;
        }

        this.props.selectLessonMethod(item);
        e.preventDefault();
    };

    protected selectPopupItems = (item: DtoTmLesson) => {
        const items: PopupItem[] = [];

        if (this.props.selectLessonMethod !== undefined) {
            items.push({
                text: t`Выбрать все слайды урока`,
                onClick: () =>
                    (this.props.selectLessonMethod !== undefined)
                        ? this.props.selectLessonMethod(item)
                        : null
            });
        }

        if (this.props.slideSelector !== undefined) {
            items.push({
                text: t`Выбрать определённые слайды`,
                onClick: () => {
                    this.setState(() => {
                        return {
                            currentItemForModal: item
                        }
                    }, () => {
                        this.slidesListModalRef.current?.open()
                    })
                }
            })
        }

        return items;
    }

    protected popupItems = (item: DtoTmLesson) => {
        const items: PopupItem[] = [];

        items.push({
            text: t`Редактировать слайды`,
            href: {
                link: this.linkToEditor(item.id),
                target: "_blank"
            }
        });
        items.push({
            text: t`Протестировать урок`,
            href: {
                link: this.linkToPlayer(item.id),
                target: "_blank"
            }
        });

        if (this.props.selectMode !== true) {
            items.push({
                text: t`Изменить имя или описание`,
                onClick: () => {
                    this.setState(() => {
                        return {
                            currentItemForModal: item
                        }
                    }, () => {
                        this.modalRef.current?.open()
                    })
                }
            });
        }

        return items;
    }

    protected icon = (itemId: string) => {
        if ((this.props.usedLessonIds === undefined) || (this.props.usedLessonIds.length === 0)) {
            return <MinusIconStyled/>;
        }

        const index = this.props.usedLessonIds.indexOf(itemId);

        if (index === -1) {
            return <MinusIconStyled/>;
        }

        if (index === this.props.usedLessonIds.length - 1) {
            return <SmallCircleOutlineIcon/>;
        }

        return <CheckIcon/>;
    }

    protected lessonItem = (item: DtoTmLesson) => {
        return <LessonItem>
            <LessonItemIconWrapper>{this.icon(item.id)}</LessonItemIconWrapper>
            <LessonItemTextWrapper>
                <LessonItemName onClick={(e) => this.lessonItemOnClick(e, item)}
                                to={this.linkToEditor(item.id)}
                                target={"_blank"}>{item.name}<TopIndex>{item.slidesCount.toString(10)}</TopIndex></LessonItemName>
                {
                    (item.descriptionForTeacher) &&
                    <LessonItemDescription>{item.descriptionForTeacher}</LessonItemDescription>
                }
            </LessonItemTextWrapper>
            <LessonItemControlsWrapper>
                {
                    (this.props.selectMode === true)
                    && <LessonItemBtnWrapper>
                        <Popup items={this.selectPopupItems(item)}
                               trigger={<AddSlideIcon/>}
                               position={"bottom right"}/>
                    </LessonItemBtnWrapper>
                }
                <LessonItemBtnWrapper>
                    <Popup items={this.popupItems(item)}
                           trigger={<SettingsIcon/>}
                           position={"bottom right"}/>
                </LessonItemBtnWrapper>
            </LessonItemControlsWrapper>
        </LessonItem>
    }

    protected modalElement = () => {
        return <TmLessonModal ref={this.modalRef}
                              sectionId={this.props.sectionId}
                              lessonItem={this.state.currentItemForModal}
                              result={this.props.modalOnResult}/>;
    }

    protected lessonSlidesModalElement = () => {
        if ((this.props.slideSelector === undefined) || (this.state.currentItemForModal === null)) {
            return;
        }

        return <TmSlideListModal ref={this.slidesListModalRef}
                                 apiToken={this.props.apiToken}
                                 onSelectSlide={this.props.slideSelector.onSelectSlide}
                                 selectedSlides={this.props.slideSelector.selectedSlides}
                                 tmLesson={this.state.currentItemForModal}
        />;
    }

    render() {
        if (this.props.reorderItems === undefined) {
            return <LessonsListStyled>
                {
                    this.props.lessonItems.map((item) => {
                        return this.lessonItem(item);
                    })
                }
                {this.modalElement()}
                {this.lessonSlidesModalElement()}
            </LessonsListStyled>
        } else {
            return <SortableWrapper><Sortable<DtoTmLesson>
                tinyItems={true}
                items={this.props.lessonItems.map((item): SortableItemComponent<DtoTmLesson> => {
                    return {
                        id: item.id,
                        selected: false,
                        disabled: (this.props.itemsDisabled === true),
                        item: item
                    }
                })}
                reorderItems={(array, from, to) => {
                    if (this.props.reorderItems !== undefined) {
                        this.props.reorderItems(from, to);
                    }
                }}
                renderItemContent={(item, index) => {
                    return this.lessonItem(item.item);
                }}
            />{this.modalElement()}{this.lessonSlidesModalElement()}</SortableWrapper>
        }
    }
}