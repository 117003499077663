import React from "react";
import {cloneDeep} from "lodash";
import {videoRoomConnectionInitState, VideoRoomConnectionState} from "../Types";

export interface IVideoRoomContext {
    videoRoomConnectionState: VideoRoomConnectionState;
}

export const VideoRoomContext = React.createContext<IVideoRoomContext>({
    videoRoomConnectionState: cloneDeep(videoRoomConnectionInitState)
});

export const VideoRoomContextProvider = VideoRoomContext.Provider;
