import React, {useContext, useMemo} from "react";
import {PlayerContextProvider, SlidesCachedContent} from "../../../../components/SlidePlayer/PlayerContext";
import {SlidePlayer} from "../../../../components/SlidePlayer";
import {CommonContext, ICommonContext} from "./CommonContext";
import {PlayerSlideModel} from "../../../../components/SlidePlayer/PlayerSlideModel";
import {EditorDataDtoMapper} from "../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {
    EditorItemInteractivityConfigWithParentItem
} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";

interface ContentWrapperProps {
    setInteractivityConfig: (config: EditorItemInteractivityConfigWithParentItem | null) => void;
    className?: string;
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({setInteractivityConfig, className}) => {
    const {
        playerId,
        slideList,
        selectedTmSlideId,
        setSelectedTmSlideId,
        selectedSlideIndex,
        slideItemsParamsBySlide
    } = useContext<ICommonContext>(CommonContext);
    
    const playerSlideModels = useMemo(() => {
        return slideList.map((item) => {
            return new PlayerSlideModel(
                item.slideTmId,
                item.slideTmName,
                item.slideContentVersion,
                item.slideExerciseCount,
                true,
                item.orderPosition
            );
        })
    }, [slideList]);

    const slidesContent = useMemo(() => {
        const content: SlidesCachedContent = {};
        slideList.forEach((item) => {
            content[item.slideTmId] = EditorDataDtoMapper.dtoToEditorData(item.content);
        });

        return content;
    }, [slideList]);

    return <PlayerContextProvider value={{
        lessonId: "",
        playerId: playerId,
        slides: playerSlideModels,
        slidesContent: slidesContent,
        setSlidesContent: () => {/*Не нужен, т.к. подгрузки во время работы не будет*/
        },
        selectedSlideId: selectedTmSlideId,
        setSelectedSlideId: (value: string | null) => {
            if (value) setSelectedTmSlideId(value);
        },
        selectedSlide: playerSlideModels[selectedSlideIndex],
        selectedSlideIndex: selectedSlideIndex,
        setInteractivityConfig: setInteractivityConfig
    }}>
        {
            (selectedTmSlideId)
            && <SlidePlayer
                className={className}
                playerId={playerId}
                slideId={selectedTmSlideId}
                slideContent={slidesContent[selectedTmSlideId]}
                slideItemsParams={slideItemsParamsBySlide}
            />
        }
    </PlayerContextProvider>
}

ContentWrapper.displayName = 'ContentWrapper';