import React, {FocusEvent, useContext, useMemo, useState} from 'react';
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import styled, {css, keyframes} from "styled-components";
import {ReactComponent as ArrowDownSvgIcon} from "../../../../components/Ui/Svg/ArrowDown16.svg";
import classNames from "classnames";
import {ArrayHelpers} from "../../../../../helpers/ArrayHelpers";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {ExerciseProgressBar} from "../../../ExerciseProgressBar";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {InputHistoryTip} from "../Common/InputHistoryTip";

interface ExerciseProps {
    // Список элементов для выбора. Первый из них - верный.
    values: string[];
    exerciseId: string;
}

const ErrorTremor = keyframes`
    0% {
        transform: translateX(-3px);
    }

    15% {
        transform: translateX(+3px);
    }

    30% {
        transform: translateX(-2px);
    }

    45% {
        transform: translateX(+2px);
    }

    60% {
        transform: translateX(-1px);
    }

    75% {
        transform: translateX(+1px);
    }

    90% {
        transform: translateX(0);
    }
`;

const BaseBlock = css`
    background: ${({theme}) => theme.colors.backgroundPrimary};
    border: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-radius: 5px;
`;

const DropdownDummy = styled.div`
    ${BaseBlock};

    position: absolute;
    left: 10px;
    right: 10px;
    height: 100%;
    transform: translateY(5px);
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.active {
        transform: translateY(15px) !important;
        opacity: 0;
    }
`;

const PopupBtn = styled.button`
    ${BaseBlock};

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    transform: translateY(0px);
    box-shadow: rgb(0 0 0 / 6%) 0 1px 1px;
    border-radius: 4px;
    padding: 0 3px;

    transition: transform 0.1s ease, background-position .4s ease-out;

    background: linear-gradient(to left, ${({theme}) => theme.slideContent.textInputDefaultBg} 50%, ${({theme}) => theme.slideContent.textInputCorrectBg} 50%) right;
    background-size: 200% 100%;

    &.correct {
        border: none;
        box-shadow: none;
        cursor: text;
        background-position: left center;
    }

    &.error {
        animation: ${ErrorTremor} 0.9s ease-in-out;
    }

    &:hover:not(.correct):not(.active) {
        transform: translateY(-2px);
    }
`;

const SelectedValue = styled.div`
    flex-grow: 1;
    margin-right: 3px;
    min-height: 1.73em;
    text-align: left;
    transition: width 0.3s ease;

    &.correct {
        margin: 0 6px;
    }
`;

const ArrowIconWrapper = styled.div`
    min-width: 20px;
    opacity: 0.3;
    display: flex;
    flex-direction: column;
    align-items: center;

    transition: opacity 0.3s ease;
`;

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    transform: translateY(-1px);
    line-height: 1.7em;
    font-size: 1em;
    vertical-align: middle;

    &.correct {
        font-size: 1em;
    }

    &.active {
        z-index: ${({theme}) => theme.zIndices.dropDownList};
    }

    &:hover ${ArrowIconWrapper} {
        opacity: 0.7;
    }

    &:hover ${DropdownDummy} {
        transform: translateY(7px);
    }
`;

const ControlWrapper = styled.div`
    position: relative;
`;

const ProgressbarWrapper = styled.div`
    margin: 0 4px;
    width: 6px;
    height: 27px;
`;

const VariantsList = styled.div`
    position: absolute;
    bottom: 0;
    transform: translateY(calc(100% - 10px));
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    background-color: ${({theme}) => theme.colors.backgroundPrimary}de;
    box-shadow: none;
    height: 0;

    &.active {
        height: auto;
        box-shadow: 0 4px 34px 20px ${({theme}) => theme.colors.backgroundPrimary};
        transform: translateY(100%);
        opacity: 1;
    }
`;

const VariantsListItem = styled.button`
    ${BaseBlock};
    margin: 10px 0;
    display: block;
    cursor: pointer;
    padding: 1px 6px;

    &:hover {
        border-color: ${({theme}) => theme.colors.accentPrimary};
    }

    &.read-only {
        cursor: not-allowed;
    }
`;

enum ShowMode {
    INPUT,
    ERROR,
    CORRECT
}

export const Exercise: React.FC<ExerciseProps> = (props) => {
    const {exerciseId, values} = props;

    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

    const [variantsListActive, setVariantsListActive] = useState<boolean>(false);

    const [correctVariant,] = useState<string>(() => props.values[0]);
    const [shuffledVariants,] = useState<string[]>(() => ArrayHelpers.shuffleArray(props.values));

    const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);
    const [spanWidth, setSpanWidth] = useState<number>(0);

    const soundPlayer = useMemo<ISoundPlayer>(() => {
        return container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);
    }, []);

    // Максимальная длина ответа (чтобы подстроить ширину блока)
    const maxCharWidth = useMemo<number>(() => {
        let maxCharLength = 2;

        values.forEach((item) => {
            if (item.length > maxCharLength) {
                maxCharLength = item.length;
            }
        });

        return maxCharLength;
    }, [values]);

    const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
        const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

        const exerciseIndex = slideItemWorkData.exercisesIndexById[exerciseId];

        if (exerciseIndex === undefined) {
            return null;
        }

        return slideItemWorkData.exercises[exerciseIndex];
    }, [exerciseId, slideItemWorkContext]);

    const currentValue = useMemo<string>(() => {
        if (!currentExerciseHistoryData) {
            return "";
        }

        return currentExerciseHistoryData.value;
    }, [currentExerciseHistoryData]);

    const showMode = useMemo<ShowMode>(() => {
        if ((currentExerciseHistoryData) && (currentExerciseHistoryData.value === correctVariant)) {
            return ShowMode.CORRECT;
        }

        if (nowShowErrorAnimation) {
            return ShowMode.ERROR;
        }

        return ShowMode.INPUT;
    }, [correctVariant, currentExerciseHistoryData, nowShowErrorAnimation]);

    const onBlurHandle = (event: FocusEvent<HTMLDivElement>) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setVariantsListActive(false);
        }
    }

    const onVariantClick = (event: React.MouseEvent<HTMLButtonElement>, variant: string) => {
        if (
            (slideItemWorkContext.itemId === null)
            || (slideItemWorkContext.slideId === null)
            || (playerContext.selectedSlide === null)
            || (slideItemWorkContext.readOnly === true)
        ) {
            return;
        }

        setVariantsListActive(false);

        if (variant === correctVariant) {
            // setShowMode(ShowMode.CORRECT);
            setSpanWidth(event.currentTarget.offsetWidth);

            soundPlayer.playSound(SoundsEnum.RIGHT);
        } else {
            setNowShowErrorAnimation(true);

            soundPlayer.playSound(SoundsEnum.ERROR);
        }

        let award = 0;
        let missedAward = 0;
        let historyItemsCount = 0;

        // Рассчитываем значения упущенной выгоды и награды
        if (currentExerciseHistoryData) {
            historyItemsCount = currentExerciseHistoryData.inputHistory.length;
            award = currentExerciseHistoryData.award;
            missedAward = currentExerciseHistoryData.missedAward;
        }

        const answerIsCorrect = variant === correctVariant;

        if (answerIsCorrect) {
            // Если сейчас был дан верный ответ - присуждаем все доступные баллы
            award = Math.ceil(
                MAX_AWARD_SCORE - missedAward
            );
        } else {
            // Если сейчас был дан ошибочный ответ

            // Сколько ещё доступно баллов
            const availableScore = MAX_AWARD_SCORE - missedAward;

            // Посчитаем, сколько ещё ошибочных ответов есть, вычитая из общего
            // количества уже имеющихся в истории и один ответ, который точно верный
            const availableWrongAnswerCount = shuffledVariants.length - historyItemsCount - 1;

            if (availableWrongAnswerCount > 0) {
                missedAward += Math.ceil(availableScore / availableWrongAnswerCount);
            }
        }

        if (missedAward > MAX_AWARD_SCORE) {
            missedAward = MAX_AWARD_SCORE;
        }

        if (award > MAX_AWARD_SCORE) {
            award = MAX_AWARD_SCORE;
        }

        slideItemWorkContext.saveExerciseAnswer(
            exerciseId,
            variant,
            award,
            missedAward,
            playerContext.selectedSlide.exercisesCount,
            answerIsCorrect
        );
    }

    const answerSpanWidth = useMemo<string>(() => {
        if (showMode !== ShowMode.CORRECT) {
            return maxCharWidth.toString(10) + 'em';
        }

        return (spanWidth - 14).toString(10) + 'px';
    }, [showMode, spanWidth, maxCharWidth]);

    const popupBtnClick = () => {
        if (showMode === ShowMode.CORRECT) {
            return;
        }

        setVariantsListActive(!variantsListActive);
    }

    // Рендер результата
    if (slideItemWorkContext.showCorrectAnswers === true) {
        // Если нужно показать сразу верный вариант
        return <Wrapper
            data-exercise-id={exerciseId}
            className={"correct"}>
            <ControlWrapper>
                <PopupBtn className={"correct"}>
                    <SelectedValue className={"correct"}>{correctVariant}</SelectedValue>
                </PopupBtn>
            </ControlWrapper>
        </Wrapper>
    }

    return <Wrapper
        data-exercise-id={exerciseId}
        className={classNames(
            variantsListActive && "active",
            (showMode === ShowMode.CORRECT) && "correct",
            (showMode === ShowMode.ERROR) && "error",
            (slideItemWorkContext.readOnly && showMode !== ShowMode.CORRECT) && "read-only",
        )}
        onBlur={onBlurHandle}>
        <ControlWrapper>
            <InputHistoryTip
                inputHistory={currentExerciseHistoryData?.inputHistory}
                lastIsCorrect={showMode === ShowMode.CORRECT}
            >
                {(showMode !== ShowMode.CORRECT) && <DropdownDummy
                    className={classNames(variantsListActive && "active",)}/>
                }
                <VariantsList className={classNames(variantsListActive && "active")}>
                    {
                        shuffledVariants.map((item, index) => {
                            return <VariantsListItem key={index}
                                                     className={classNames(slideItemWorkContext.readOnly && "read-only")}
                                                     onClick={(event) => onVariantClick(event, item)}>{item}</VariantsListItem>;
                        })
                    }
                </VariantsList>
                <PopupBtn className={classNames(
                    variantsListActive && "active",
                    (showMode === ShowMode.CORRECT) && "correct",
                    (showMode === ShowMode.ERROR) && "error",
                )} onAnimationEnd={() => setNowShowErrorAnimation(false)}
                          onClick={popupBtnClick}>
                    <SelectedValue style={{width: answerSpanWidth}}
                                   className={classNames((showMode === ShowMode.CORRECT) && "correct")}>{currentValue}</SelectedValue>
                    {(showMode !== ShowMode.CORRECT) && <ArrowIconWrapper>
                        <ArrowDownSvgIcon/>
                    </ArrowIconWrapper>}
                </PopupBtn>
            </InputHistoryTip>
        </ControlWrapper>
        <ProgressbarWrapper>
            <ExerciseProgressBar wrongValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.missedAward : 0}
                                 rightValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.award : 0}/>
        </ProgressbarWrapper>
    </Wrapper>;
}

Exercise.displayName = 'ExerciseFillGapsComboboxExercise';