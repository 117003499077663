import {ArrayMaxSize, ArrayMinSize, IsArray, IsNotEmpty, IsString, MaxLength, MinLength} from "class-validator";

export class DtoGetStudentHomeworkSlidesWorkDataRequest {
    @IsNotEmpty()
    @IsString()
    @MinLength(36)
    @MaxLength(36)
    lessonId: string;

    @IsNotEmpty()
    @IsString()
    @MinLength(36)
    @MaxLength(36)
    studentId: string;

    @IsNotEmpty()
    @IsArray()
    @ArrayMinSize(1)
    @ArrayMaxSize(200)
    slideIds: string[];
}
