import React, {useCallback, useEffect, useMemo, useState} from "react";
import {t, Trans} from "@lingui/macro";
import {BlockWithBorderCss, PageTitle} from "../../../../styles/global-elements";
import {PageLoadStatus} from "../common/Enums";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {ApplicationState} from "../../../../../store";
import {LocaleEnum} from "../../../../../store/i18n/type";
import {DtoKbSection} from "../../../../../components/HttpApiClient/ApiDto/Response/KnowledgeBase/DtoKbSection";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {NoticeBlock, NoticeBlockText} from "../../../../components/Ui/Elements/NoticeBlock";
import {ReactComponent as FolderIcon} from "../../../../components/Ui/Svg/Folder.svg";
import {Item, ItemLink, ItemLinkText, List} from "../common/styles";
import styled from "styled-components";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {SupportWhatsAppString} from "../../../../components/SupportWhatsAppString";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ContentWrapper = styled.div`
    flex-grow: 1;
    max-width: 680px;
    padding-bottom: 100px;

    @media (${({theme}) => theme.media.large}) {
        margin-right: 20px;
        padding-bottom: 40px;
    }
`;

const RightContactBlockWrapper = styled.div`
    ${BlockWithBorderCss};
    height: fit-content;

    padding: 18px;
    display: block;
    max-width: 344px;
    margin-top: 40px;

    @media (${({theme}) => theme.media.large}) {
        margin-bottom: 0;
        min-width: 344px;
        position: sticky;
        top: ${({theme}) => 40 + theme.size.headerHeight}px;
        margin-top: 15px;
    }
`;


const FolderIconStyled = styled(FolderIcon)`
    min-width: 40px;
`;

export const KnowledgeBase: React.FC = () => {

    const [contentLoadState, setContentLoadState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [sections, setSections] = useState<DtoKbSection[]>([]);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const sessionToken = useSelector(sessionTokenSelector);

    const currentLocale = useSelector<ApplicationState>(
        ({i18n}: ApplicationState) => i18n.currentLocale
    ) as LocaleEnum;


    const loadContent = useCallback(() => {
        if (!sessionToken) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setContentLoadState(PageLoadStatus.LOADING);

        httpApiClient.kbGetSections(sessionToken, currentLocale, 1, 20)
            .then((data) => {
                setSections(data.data.list);

                setContentLoadState(PageLoadStatus.SUCCESS);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.KNOWLEDGE_BASE_API, "Error loading data: ", err);

                setContentLoadState(PageLoadStatus.ERROR);
            });
    }, [currentLocale, sessionToken]);

    useEffect(() => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT: {
                loadContent();

                break;
            }
        }
    }, [contentLoadState, loadContent]);

    const linkToSection = useCallback((sectionId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_KNOWLEDGE_BASE_SECTION,
            [
                {
                    key: 'sectionId',
                    value: sectionId
                }
            ]
        );
    }, []);

    const content = () => {
        switch (contentLoadState) {
            case PageLoadStatus.NOT_INIT:
            case PageLoadStatus.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadStatus.SUCCESS: {
                if (sections.length === 0) {
                    return <NoticeBlock>
                        <NoticeBlockText>
                            <Trans>Похоже, что инструкции ещё не добавлены</Trans>
                        </NoticeBlockText>
                    </NoticeBlock>;
                }

                return <List>
                    {
                        sections.map((item) => {
                            return <Item key={item.id}>
                                <ItemLink to={linkToSection(item.id)}>
                                    <FolderIconStyled/><ItemLinkText>{item.name}</ItemLinkText>
                                </ItemLink>
                            </Item>;
                        })
                    }
                </List>;
            }
            case PageLoadStatus.ERROR: {
                return <ErrorLoadingContent
                    title={t`Не удалось загрузить список разделов`}
                    retryBtnClick={() => loadContent()}
                />;
            }
        }
    }

    const SupportPhone = useMemo(() => {
        return <RightContactBlockWrapper>
            <SupportWhatsAppString/>
        </RightContactBlockWrapper>
    }, []);

    return <Wrapper>
        <ContentWrapper>
            <PageTitle>
                <Trans>Доступные разделы справки</Trans>
            </PageTitle>
            {content()}
            {(!isLarge) && SupportPhone}
        </ContentWrapper>
        {(isLarge) && SupportPhone}
    </Wrapper>;
}
