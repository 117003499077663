import React, {forwardRef, useState} from "react";
import styled, {css} from "styled-components";
import classNames from "classnames";
import {DefaultLoader} from "../../../../../../DefaultLoader";

const VideoElementCss = css`
  width: 100%;
  height: 100%;

  &.landscape {
    object-fit: cover;
  }

  &.portrait {
    object-fit: contain;
  }
`;

const Wrapper = styled.div`
  ${VideoElementCss};
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const VideoElement = styled.video`
  ${VideoElementCss};
  
  &.mirrored {
    transform: scaleX(-1);
  }
`;

interface VideoPlayerProps {
    mirrored: boolean;
    className?: string;
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>((
    {className, mirrored},
    ref
) => {
    const [showLoader, setShowLoader] = useState<boolean>(true);

    return <Wrapper>
        {
            (showLoader)
            && <LoaderWrapper>
            <DefaultLoader simple={true}/>
          </LoaderWrapper>
        }
        <VideoElement className={classNames(className, mirrored && 'mirrored')} ref={ref} playsInline={true} autoPlay={true} onPlay={() => setShowLoader(false)}/>
    </Wrapper>
});
