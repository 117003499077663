import React from 'react';
import {PlayerSlideModel} from "./PlayerSlideModel";
import {EditorData, EditorItemInteractivityConfigWithParentItem} from "../SlidePlayerEditorCommonParts/EditorData";

export type SlidesCachedContent = { [slideId: string]: EditorData };

export interface IPlayerContext {
    lessonId: string;
    playerId: string;

    slides: PlayerSlideModel[] | null;

    slidesContent: SlidesCachedContent;
    setSlidesContent: (slideId: string, content: EditorData) => void;

    selectedSlideId: string | null;
    setSelectedSlideId: (newValue: string | null) => void;

    selectedSlide: PlayerSlideModel | null;
    selectedSlideIndex: number | null;

    setInteractivityConfig: null | ((specialActionConfig: EditorItemInteractivityConfigWithParentItem | null) => void);
}

export const PlayerContext = React.createContext<IPlayerContext>({
    lessonId: "",
    playerId: "",

    slides: null,

    slidesContent: {},
    setSlidesContent: () => {
    },

    selectedSlideId: null,
    setSelectedSlideId: () => {
    },

    selectedSlide: null,
    selectedSlideIndex: null,
    setInteractivityConfig: null
});

export const PlayerContextProvider = PlayerContext.Provider;