import {forwardRef, useImperativeHandle} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {
    CustomElement
} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {DivAsParagraphDefaultStyle} from "../../components/RichTextReader/elements/DivAsParagraph";

interface ExerciseFillGapsTextProps extends IElementProps<CustomElement[]> {
}

interface ExerciseFillGapsTextRefMethods extends IElementRefMethods {
}

export const ExerciseFillGapsText = forwardRef<ExerciseFillGapsTextRefMethods, ExerciseFillGapsTextProps>(
    (props, ref) => {

        const {elementData} = props;

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <RichTextReader elements={elementData.data} paragraphComponent={DivAsParagraphDefaultStyle}/>;
    }
);

ExerciseFillGapsText.displayName = 'ExerciseFillGapsText';