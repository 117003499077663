import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, ValidateNested} from "class-validator";
import {Type} from "class-transformer";
import {DtoGroupSchedule} from "./DtoGroupSchedule";

export class DtoGroupData {
    @IsNotEmpty()
    @IsString()
    groupName: string;

    @IsNumber()
    autoGenerated: number;

    @IsNotEmpty()
    @IsNumber()
    trainingProgramId: number;

    @IsNotEmpty()
    @IsString()
    trainingProgramName: string;

    @IsNotEmpty()
    @IsNumber()
    courseId: number;

    @IsNotEmpty()
    @IsString()
    courseName: string;

    @IsNotEmpty()
    @IsNumber()
    trainingProgramCostPeriod: number;

    @IsOptional()
    @IsString()
    trainingProgramCostPerMonth: string | null;

    @IsNotEmpty()
    @IsString()
    groupDateEndWork: string;

    @IsNumber()
    groupDeleted: number;

    @IsBoolean()
    groupForOneStudent: boolean;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoGroupSchedule)
    schedule: DtoGroupSchedule | null;
}