import {Editor, Element as SlateElement, Node as SlateNode, Path as SlatePath, Point, Range, Transforms} from "slate";
import {ALLOWED_FOR_EXERCISE_FILL_GAPS_COMBOBOX} from "../Enums";
import {IFillGapsCombobox} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsCombobox";
import {IBaseElement} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withGapsCombobox = (editor: Editor) => {
    const {deleteBackward, insertData, normalizeNode} = editor

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        // Проверим - есть ли такой элемент в списке разрешённых
        if (SlateElement.isElement(node)) {
            if (ALLOWED_FOR_EXERCISE_FILL_GAPS_COMBOBOX.indexOf(node.type) < 0) {
                Transforms.setNodes(editor, {type: ElementTypes.PARAGRAPH}, {at: path});
            }
        }

        // Если нормализуется корень, проверим все упражнения, чтобы их ID были не пустыми и уникальными
        if (path.length === 0) {
            const idList = new Set();

            const allExercises = Array.from(Editor.nodes(editor, {
                at: [],
                match: (node1, path1) => {
                    if (Editor.isEditor(node1)) {
                        return false;
                    }

                    if (!SlateElement.isElement(node1)) {
                        return false;
                    }

                    return node1.type === ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX;
                },
                mode: "all"
            }));

            for (const [child, childPath] of allExercises) {
                const childElement = (child as IFillGapsCombobox);

                if (childElement.id === undefined) {
                    const newId = ExerciseIdGenerator.generateId();
                    Transforms.setNodes<IFillGapsCombobox>(editor, {id: newId}, {at: childPath});
                    idList.add(newId);
                } else {
                    // Если id есть - проверим, чтобы он был уникален
                    if (idList.has(childElement.id)) {
                        const newId = ExerciseIdGenerator.generateId();
                        Transforms.setNodes<IFillGapsCombobox>(editor, {id: newId}, {at: childPath});
                        idList.add(newId);
                    } else {
                        idList.add(childElement.id);
                    }
                }
            }
        }

        normalizeNode(entry);
    }

    editor.insertData = (data) => {
        const dataAsText = data.getData('text/plain');

        const {selection} = editor;

        if (selection && Range.isCollapsed(selection)) {
            const [match] = Array.from(
                Editor.nodes(editor, {
                    match: (node) => {
                        if (Editor.isEditor(node)) {
                            return false;
                        }

                        if (!SlateElement.isElement(node)) {
                            return false;
                        }

                        return node.type === ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX;
                    }
                })
            );

            if (match) {
                editor.insertText(dataAsText);

                return;
            }
        }

        insertData(data);
    }

    editor.deleteBackward = (...args) => {
        const {selection} = editor;

        const previousSelection = Object.assign({}, selection);

        let allowOriginalDeleteBackward = true;

        if (selection && Range.isCollapsed(selection)) {
            const [match] = Array.from(
                Editor.nodes(editor, {
                    match: (node) => {
                        if (Editor.isEditor(node)) {
                            return false;
                        }

                        if (!SlateElement.isElement(node)) {
                            return false;
                        }

                        return node.type === ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX;
                    }
                })
            );

            if (match) {
                const [currentNode, path] = match
                const start = Editor.start(editor, path)

                if (Point.equals(selection.anchor, start)) {
                    if ((currentNode as IFillGapsCombobox).values.length > 0) {
                        let newValues = [...(currentNode as IFillGapsCombobox).values];

                        const removedValueString = (newValues.pop() as string);

                        const newProperties: Partial<IFillGapsCombobox> = {
                            values: [
                                ...newValues,
                            ],
                        };

                        Transforms.setNodes(editor, newProperties, {at: path});

                        Transforms.insertText(
                            editor,
                            removedValueString + SlateNode.string(currentNode),
                            {at: path}
                        );

                        // previousSelection.anchor.offset = removedValueString.length;
                        // previousSelection.focus.offset = removedValueString.length;
                        //
                        Transforms.select(editor, {
                            ...previousSelection,
                            anchor: {
                                ...previousSelection.anchor,
                                offset: removedValueString.length
                            },
                            focus: {
                                ...previousSelection.focus,
                                offset: removedValueString.length
                            }
                        });

                        // Transforms.collapse(editor, {edge: 'end'})

                        allowOriginalDeleteBackward = false;
                    } else {
                        // Если ничего в блоке не осталось
                        Transforms.removeNodes(editor, {at: path});
                        allowOriginalDeleteBackward = false;
                    }
                }
            } else {
                // Проверим - не является ли предыдущим элементом наш элемент. Если предыдущий элемент - наш,
                // то предотвращаем его удаление и устанавливаем каретку внутрь его
                if (selection.anchor.offset === 0) {
                    const prevElement = Editor.previous(editor);

                    if ((prevElement) && (!Editor.isEditor(prevElement))) {
                        const parent = SlatePath.parent(prevElement[1]);

                        if (parent) {
                            const parentElement = Editor.node(editor, parent);

                            if ((parentElement) && (Array.isArray(parentElement)) && (!Editor.isEditor(parentElement[0])) && (SlateElement.isElement(parentElement[0]))) {
                                if ((parentElement[0] as IBaseElement).type === ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX) {
                                    Transforms.select(editor, parentElement[1]);

                                    allowOriginalDeleteBackward = false;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (allowOriginalDeleteBackward) {
            deleteBackward(...args);
        }
    }

    return editor
}