import React from "react";
import {audioRoomConnectionInitState, AudioRoomConnectionState} from "../Types";
import {cloneDeep} from "lodash";

export interface IAudioRoomContext {
    audioRoomConnectionState: AudioRoomConnectionState;
}

export const AudioRoomContext = React.createContext<IAudioRoomContext>({
    audioRoomConnectionState: cloneDeep(audioRoomConnectionInitState)
});

export const AudioRoomContextProvider = AudioRoomContext.Provider;
