import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {EditorData} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../components/Ui/Elements/NoticeBlock";
import {SlidePlayer} from "../../../../components/SlidePlayer";
import {DtoTmSlide} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {EditorDataDtoMapper} from "../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {PlayerContextProvider} from "../../../../components/SlidePlayer/PlayerContext";
import {SlideItemsParamsBySlide} from "../../../../components/SlidePlayer/SlideItemParamsStore";

const SlidePlayerWrapper = styled.div`
  max-width: 680px;
  padding: 0;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
`;

enum LoadingState {
    NOT_INIT,
    LOADING_BASE_DATA,
    ERROR_LOADING_BASE_DATA,
    BASE_DATA_LOADED,
    LOADING_CONTENT,
    SUCCESS,
    ERROR
}

export const SlidePreview: React.FC = () => {
    const {slideId, contentVersionId} = useParams();

    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.NOT_INIT);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const [slideDto, setSlideDto] = useState<DtoTmSlide | null>(null);
    const [slideContent, setSlideContent] = useState<EditorData | null>(null);

    const loadBaseData = useCallback(() => {
        if ((!sessionToken) || (!slideId)) {
            return;
        }

        setLoadingState(LoadingState.LOADING_BASE_DATA);

        httpApiClient.getSlideInfo(sessionToken, slideId)
            .then((data) => {
                setSlideDto(data.data);

                setLoadingState(LoadingState.BASE_DATA_LOADED);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_SLIDES_API, 'Error on get slide info: ', err);

                setSlideDto(null);
                setSlideContent(null);
                setLoadingState(LoadingState.ERROR_LOADING_BASE_DATA);
            });
    }, [httpApiClient, logger, sessionToken, slideId]);

    const loadContent = useCallback(() => {
        if ((!sessionToken) || (!slideId) || (!slideDto)) {
            return;
        }

        setLoadingState(LoadingState.LOADING_BASE_DATA);

        let routerContentVersion: number | null = null;

        if (contentVersionId) {
            routerContentVersion = parseInt(contentVersionId);

            if (routerContentVersion < 1) {
                routerContentVersion = null;
            }
        }

        httpApiClient.tmGetSlideContent(
            sessionToken,
            slideDto.id,
            (routerContentVersion) ?? slideDto.currentVersion
        )
            .then((data) => {
                setSlideContent(EditorDataDtoMapper.dtoToEditorData(data.data.content));
                setLoadingState(LoadingState.SUCCESS);
            })
            .catch((error) => {
                logger.error(LoggerSectionsEnum.TM_SLIDES_API, 'Error on get slide content for preview: ', error);

                setSlideContent(null);
                setLoadingState(LoadingState.ERROR);
            });
    }, [contentVersionId, httpApiClient, logger, sessionToken, slideDto, slideId]);

    useEffect(() => {
        if (loadingState === LoadingState.NOT_INIT) {
            loadBaseData();
        }
    }, [loadBaseData, loadingState]);

    useEffect(() => {
        if (loadingState === LoadingState.BASE_DATA_LOADED) {
            loadContent();
        }
    }, [loadBaseData, loadContent, loadingState]);

    const slideItemsParams = useMemo<SlideItemsParamsBySlide>(() => {
        const result: SlideItemsParamsBySlide = {};

        if (!slideContent) {
            return result;
        }

        slideContent.items.forEach((slideContentItem) => {
            result[slideContentItem.id] = slideContentItem.params;
        });

        return result;
    }, [slideContent]);
    
    const content = () => {
        switch (loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING_BASE_DATA:
            case LoadingState.LOADING_CONTENT: {
                return <DefaultLoader/>;
            }
            case LoadingState.ERROR_LOADING_BASE_DATA: {
                return <ErrorLoadingContent retryBtnClick={() => setLoadingState(LoadingState.NOT_INIT)}/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => setLoadingState(LoadingState.BASE_DATA_LOADED)}/>;
            }
            default: {
                if ((!slideContent) || (!slideDto)) {
                    return <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Слайд пуст</Trans></NoticeBlockTitle>
                            <NoticeBlockText><Trans>Наполнить слайд можно в разделе «Учебные
                                материалы»</Trans></NoticeBlockText>
                        </>
                    </NoticeBlock>
                }

                return <SlidePlayerWrapper>
                    <PlayerContextProvider value={{
                        lessonId: "none",
                        playerId: "slide-preview",
                        slides: null,
                        slidesContent: {},
                        setSlidesContent: () => {
                        },
                        selectedSlideId: slideDto.id,
                        setSelectedSlideId: () => {
                        },
                        selectedSlide: null,
                        selectedSlideIndex: null,
                        setInteractivityConfig: null
                    }}>
                        <SlidePlayer playerId={"slide-preview"}
                                     slideContent={slideContent}
                                     slideId={slideDto.id}
                                     showCorrectAnswers={true}
                                     slideItemsParams={slideItemsParams}
                        />
                    </PlayerContextProvider>
                </SlidePlayerWrapper>
            }
        }
    }

    return <div>
        <PageTitle><Trans>Просмотр слайда</Trans>{(slideDto !== null) && <>&nbsp;«{slideDto.name}»</>}</PageTitle>
        {content()}
    </div>
}
