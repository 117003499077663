import {forwardRef, useImperativeHandle, useMemo} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {RichTextReader} from "../../RichTextReader";
import {ITranslated} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ITranslated";
import {Tooltip} from "antd";

const TooltipText = styled.div`
    font-weight: bold;
`;

const TranslatedWrapper = styled.span`
    display: inline-block;
    cursor: pointer;

    :hover {
        background-color: ${({theme}) => theme.colors.backgroundSecondary};
    }
`;

export const Translated = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<ITranslated>>(
    (props, ref) => {

        const {element, ...otherProps} = props;

        useImperativeHandle(ref, () => ({}));

        const tooltipContent = useMemo(() => {
            return <TooltipText>{element.translate}</TooltipText>
        }, [element.translate]);

        return <Tooltip title={tooltipContent} overlayClassName={'student-translation-tooltip'}>
            <TranslatedWrapper>
                <RichTextReader elements={element.children} {...otherProps}/>
            </TranslatedWrapper>
        </Tooltip>;
    }
);

Translated.displayName = 'Translated';