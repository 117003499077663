import React, {useCallback, useContext, useMemo} from "react";
import {IPlayerContext, PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";
import {HomeworkPageContext, IHomeworkPageContext} from "../HomeworkPageContext";
import {useNavigate} from "react-router-dom";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {t, Trans} from "@lingui/macro";
import {MessageDialog} from "../../../../../components/Ui/Elements/MessageDialog";
import {ReactComponent as ArrowNextSvg} from "../../../../../components/Ui/Svg/ArrowNext.svg";
import styled from "styled-components";

enum CurrentSlideTypeEnum {
    HIDDEN,
    REGULAR_SLIDE,
    LAST_SLIDE
}

const ButtonStyled = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const ButtonLabelWrapper = styled.span`
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 1.5em;
`;

interface NextButtonProps {
    className?: string;
}

export const NextButton: React.FC<NextButtonProps> = (props) => {
    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const pageContext = useContext<IHomeworkPageContext>(HomeworkPageContext);
    const navigate = useNavigate();

    const currentMode = useMemo<CurrentSlideTypeEnum>(() => {
        if (playerContext.selectedSlideIndex === null) {
            return CurrentSlideTypeEnum.HIDDEN;
        }

        if (playerContext.slides === null || playerContext.slides.length === 0) {
            return CurrentSlideTypeEnum.HIDDEN;
        }

        if (playerContext.slides.length - 1 > playerContext.selectedSlideIndex) {
            return CurrentSlideTypeEnum.REGULAR_SLIDE
        }

        if (playerContext.slides.length - 1 === playerContext.selectedSlideIndex) {
            return CurrentSlideTypeEnum.LAST_SLIDE;
        }

        return CurrentSlideTypeEnum.HIDDEN;
    }, [playerContext.selectedSlideIndex, playerContext.slides]);


    const getNextSlideId = useCallback((): string | null => {
        if ((playerContext.selectedSlideIndex === null) || (playerContext.slides === null)) {
            return null;
        }

        if (playerContext.selectedSlideIndex >= playerContext.slides.length - 1) {
            return null;
        }

        if (playerContext.slides[playerContext.selectedSlideIndex + 1]) {
            return playerContext.slides[playerContext.selectedSlideIndex + 1].tmSlideId;
        }

        return null;
    }, [playerContext.selectedSlideIndex, playerContext.slides]);

    const finishButton = useMemo<JSX.Element>(() => {
        if (pageContext.finishSlideIsAvailable) {
            return <ButtonStyled btnStyle={BtnStyleEnum.Primary}
                           className={props.className}
                           onClick={
                               () => {
                                   navigate(RoutesHelper.replaceParams(
                                       RoutesList.STUDENT_HOMEWORK_FINISH_PAGE,
                                       [{key: 'lessonId', value: pageContext.lessonId ?? ""}]
                                   ));
                               }
                           }
            ><Trans>Завершить</Trans></ButtonStyled>;
        }

        return <MessageDialog title={t`Ещё не всё`}
                              text={t`Ещё не все задания выполнены. Текущий прогресс будет засчитан, но перейти к результатам можно только когда будут выполнены все задания.`}
                              trigger={
                                  <ButtonStyled btnStyle={BtnStyleEnum.Primary} className={props.className}><Trans>Завершить</Trans></ButtonStyled>
                              }
        />
    }, [navigate, pageContext.finishSlideIsAvailable, pageContext.lessonId, props.className]);

    const goToNextSlideOnClick = useCallback(() => {
        const nextSlideId = getNextSlideId();

        if (nextSlideId === null) {
            return;
        }

        playerContext.setSelectedSlideId(nextSlideId);
    }, [getNextSlideId, playerContext]);

    switch (currentMode) {
        case CurrentSlideTypeEnum.REGULAR_SLIDE: {
            return <ButtonStyled btnStyle={BtnStyleEnum.Primary} onClick={goToNextSlideOnClick} className={props.className}>
                <ButtonLabelWrapper>
                    <span><Trans>Дальше</Trans></span>
                    <ArrowNextSvg/>
                </ButtonLabelWrapper>
            </ButtonStyled>
        }
        case CurrentSlideTypeEnum.LAST_SLIDE: {
            return <>{finishButton}</>;
        }
        default: {
            return null;
        }
    }
}
