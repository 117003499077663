import React, {useMemo} from "react";
import classNames from "classnames";
import styled from "styled-components";
import {Tooltip} from "antd";

// См также input-history-tooltip в {@see global.ts}
const ItemsList = styled.ul`
    margin: 0;
    padding-left: 18px;
`;

const Item = styled.li`
    line-height: 1.5em;
    word-wrap: break-word;

    &.incorrect {
        color: ${({theme}) => theme.slideContent.historyTipIncorrectText};
    }

    &.correct {
        color: ${({theme}) => theme.slideContent.historyTipCorrectText};
    }
`;

interface InputHistoryTipProps {
    inputHistory?: string[];
    lastIsCorrect?: boolean;
    children: React.ReactNode;
}

export const InputHistoryTip: React.FC<InputHistoryTipProps> = (
    {
        inputHistory,
        lastIsCorrect,
        children
    }
) => {
    const tipWrapper = useMemo(() => {
        if (!inputHistory || inputHistory.length === 0) {
            return null;
        }

        return <ItemsList>
            {
                inputHistory.map((item, index) => {
                    return <Item
                        key={item}
                        className={classNames((lastIsCorrect && index === inputHistory.length - 1) ? 'correct' : 'incorrect')}>
                        {item}
                    </Item>;
                })
            }
        </ItemsList>
    }, [inputHistory, lastIsCorrect]);

    return <div>
        <Tooltip
            title={tipWrapper}
            overlayClassName={'input-history-tooltip'}
            placement="topLeft"
            mouseEnterDelay={0.5}
        >
            {children}
        </Tooltip>
    </div>
}