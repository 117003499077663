import * as React from "react";
import {ChangeEvent, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react";
import {PopupActions as ReactjsPopupActions, PopupActions} from "reactjs-popup/dist/types";
import styled, {css} from "styled-components";
import {StyledConfirmDialog} from "../Ui/Elements/ConfirmDialog";
import {ReactComponent as CloseCrossSvg} from "../Ui/Svg/CloseCross.svg";
import {ReactComponent as EmailSvg} from "../Ui/Svg/EmailOutlineNoPadding.svg";
import {ReactComponent as EmailReadSvg} from "../Ui/Svg/EmailReadNoPadding.svg";
import {ModalP, RegularTextCss} from "../../styles/global-elements";
import {Input} from "../Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../Ui/Elements/Button";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import VerificationInput from "react-verification-input";
import * as CommonPersistedActionCreators from "../../../store/commonPersisted/actions";
import {setOtpEmail} from "../../../store/commonPersisted/actions";
import {IHttpApiClient} from "../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../di-factory/DiTokens";
import {container} from "tsyringe";
import {ILogger} from "../../../components/Logger/ILogger";
import {NoConnection} from "../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {RequestValidationError} from "../../../components/HttpApiClient/Exception/RequestValidationError";
import {NotFoundException} from "../../../components/HttpApiClient/Exception/NotFoundException";
import {UserProfileType} from "../../../store/user/type";
import * as UserActionCreators from "../../../store/user/actions";
import {setSplashScreenVisible} from "../../../store/layout/actions";
import {rebootApp} from "../../../store/app/actions";
import {IPagesBroadcastService} from "../../../services/pages-broadcast-service/IPagesBroadcastService";
import {currentUserIdSelector} from "../../../store/user/selector";

const OtpAuthDialogModal = styled(StyledConfirmDialog)`
`;

const ModalHeader = styled.div`
  min-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CloseCross = styled(CloseCrossSvg)`
  opacity: 0.3;
  cursor: pointer;
  padding: 2px;
  color: ${({theme}) => theme.colors.textPrimary};

  &:hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  padding: 16px 20px;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ModalContent = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

const IconWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
  color: ${({theme}) => theme.colors.accentPrimary};
  opacity: 0.5;

  @media (max-height: 570px) {
    display: none;
  }
`;

const IconStyleCss = css`
  width: 100px;
  height: 100px;
`;

const EmailSvgStyled = styled(EmailSvg)`
  ${IconStyleCss};
`;

const EmailReadSvgStyled = styled(EmailReadSvg)`
  ${IconStyleCss};
`;

// const Title = styled(ModalSubTitle)`
//   text-align: center;
//   margin-bottom: 10px;
// `;

const Text = styled(ModalP)`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  color: ${({theme}) => theme.colors.textSecondary};
  margin-bottom: 24px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 24px;
  }
`;

const FormWrapper = styled.div`
  padding: 0 32px 32px 32px;

  @media (max-height: 380px) {
    padding: 0 32px 20px 32px;
  }
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .vi__container {
    justify-content: center;
  }

  .vi__character {
    border-radius: 10px;
    color: ${({theme}) => theme.colors.textPrimary};
    border-color: ${({theme}) => theme.colors.textSecondary};
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    max-width: 45px;
  }
`;

const ButtonStyled = styled(Button)`
  width: 100%;
`;

const InputStyled = styled(Input)`
  text-align: center;
`;

const ErrorText = styled.div`
  ${RegularTextCss};
  color: ${({theme}) => theme.colors.textPrimary};
  background-color: ${({theme}) => theme.colors.fatalErrorHeader};
  padding: 10px;
  text-align: center;
  margin: 10px 0;
`;

export const OptAuthPopup = forwardRef<PopupActions>((props, ref) => {
    const dispatch = useDispatch();

    const otpEmail = useSelector<ApplicationState>(
        ({commonPersisted}: ApplicationState) => commonPersisted.otpEmail ?? null
    ) as string | null;

    const currentUserId = (useSelector(currentUserIdSelector)) as string | null;

    const modalRef = useRef<ReactjsPopupActions>(null);

    const [emailInput, setEmailInput] = useState('');
    const [emailInputError, setEmailInputError] = useState(false);

    const [errorText, setErrorText] = useState<string | null>(null);

    const [onProcess, setOnProcess] = useState<boolean>(false);

    const logger = useMemo<ILogger>(() => container.resolve<ILogger>(DiTokens.LOGGER), []);
    const httpApiClient = useMemo<IHttpApiClient>(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        close: () => {
            modalRef.current?.close()
        },
        open: () => {
            modalRef.current?.open()
        },
        toggle: () => {
            modalRef.current?.toggle()
        },
    }));

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(event.target.value);
    }

    const emailButtonClick = useCallback(async () => {
        setErrorText(null);
        setOnProcess(true);

        try {
            await httpApiClient.requestOtp(emailInput);
        } catch (e) {
            if (e instanceof NoConnection) {
                setErrorText(t`Проверьте соединение с интернетом`);
                setOnProcess(false);

                return;
            }

            if (e instanceof RequestValidationError) {
                setErrorText(t`Проверьте введённый email`);
                setOnProcess(false);

                return;
            }

            setErrorText(t`Не удалось отправить код, повторите попытку позднее`);
            setOnProcess(false);

            logger.error(LoggerSectionsEnum.OTP_REQUEST_MODAL, 'Error in otp request process: ', e);

            return;
        }

        setOnProcess(false);

        dispatch(setOtpEmail(emailInput));

    }, [dispatch, emailInput, httpApiClient, logger]);

    const onEmailKeyDown = useCallback(async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (event.key === 'Enter') {
            emailButtonClick();
            setEmailInputError(false);
        }
    }, [emailButtonClick]);

    const clearEmailOnClick = useCallback(() => {
        dispatch(setOtpEmail(null));
    }, [dispatch]);

    const optOnComplete = useCallback(async (value: string) => {
        setOnProcess(true);
        
        if (!otpEmail) {
            setErrorText(t`Произошла ошибка, попробуйте повторить попытку ввода email.`);
            
            return;
        }

        try {
            const response = await httpApiClient.authByOtp(
                otpEmail,
                value,
            );

            const pageBroadcastService = container.resolve<IPagesBroadcastService>(DiTokens.PAGES_BROADCAST_SERVICE);

            // Нужно сохранить токен и перезагрузить страницу
            dispatch(setSplashScreenVisible(true));
            dispatch(setOtpEmail(null));
            dispatch(UserActionCreators.setUserSessionToken(response.data.token));
            dispatch(CommonPersistedActionCreators.setAuthToken(response.data.token));
            dispatch(CommonPersistedActionCreators.setProfileType(UserProfileType.STUDENT));

            setTimeout(() => {
                if (currentUserId) {
                    pageBroadcastService.noticeAboutLogout(currentUserId);
                }

                dispatch(rebootApp());
            }, 500);
        } catch (e) {
            if (e instanceof NoConnection) {
                setErrorText(t`Проверьте соединение с интернетом`);
                setOnProcess(false);

                return;
            }

            if (e instanceof NotFoundException) {
                setErrorText(t`Проверьте введённый код`);
                setOnProcess(false);

                return;
            }

            setErrorText(t`Не удалось применить код, повторите попытку позднее`);
            setOnProcess(false);
            dispatch(setOtpEmail(null));

            logger.error(LoggerSectionsEnum.OTP_REQUEST_MODAL, 'Error in otp apply process: ', e);

            return;
        }
    }, [currentUserId, dispatch, httpApiClient, logger, otpEmail]);

    const content = useMemo(() => {
        if (otpEmail === null) {
            return <Wrapper>
                <IconWrapper>
                    <EmailSvgStyled/>
                </IconWrapper>
                <Text><Trans>Введите email, чтобы сохранить достижения</Trans></Text>

                <FormWrapper>
                    {
                        (errorText) && <ErrorText>{errorText}</ErrorText>
                    }

                    <InputsWrapper>
                        <InputStyled
                            status={(emailInputError) ? "error" : ""}
                            type="email"
                            size="large"
                            onChange={onEmailChange}
                            onKeyDown={onEmailKeyDown}
                            placeholder={t`Электронная почта`}
                        />
                    </InputsWrapper>

                    <ButtonStyled
                        btnStyle={BtnStyleEnum.Primary}
                        onClick={emailButtonClick}
                        loading={onProcess}
                    >
                        <Trans>Продолжить</Trans>
                    </ButtonStyled>
                </FormWrapper>
            </Wrapper>
        }

        return <Wrapper>
            <IconWrapper>
                <EmailReadSvgStyled/>
            </IconWrapper>

            <Text><Trans>Введите код, который был вам отправлен на<br/><b>{otpEmail}</b></Trans></Text>

            <FormWrapper>
                {
                    (errorText) && <ErrorText>{errorText}</ErrorText>
                }

                <InputsWrapper>
                    <VerificationInput
                        placeholder={''}
                        length={4}
                        autoFocus={true}
                        onComplete={optOnComplete}
                        inputProps={{
                            type: "number"
                        }}
                    />
                </InputsWrapper>

                <ButtonStyled
                    btnStyle={BtnStyleEnum.Secondary}
                    onClick={clearEmailOnClick}
                    loading={onProcess}
                >
                    <Trans>Другой email</Trans>
                </ButtonStyled>
            </FormWrapper>
        </Wrapper>
    }, [clearEmailOnClick, emailButtonClick, emailInputError, errorText, onEmailKeyDown, onProcess, optOnComplete, otpEmail]);

    return <OtpAuthDialogModal
        closeOnDocumentClick={!onProcess}
        closeOnEscape={!onProcess}
        ref={modalRef}
    >
        {
            (closeModal: () => void) => (
                <ModalContentWrapper>
                    <ModalHeader>
                        <CloseCross onClick={() => {
                            if (!onProcess) {
                                closeModal();
                            }
                        }}/>
                    </ModalHeader>
                    <ModalContent>
                        {content}
                    </ModalContent>
                </ModalContentWrapper>
            )
        }
    </OtpAuthDialogModal>
})