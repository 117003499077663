import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    ContentArea,
    ContentWidthWrapper,
    Header,
    HeaderBurgerIconWrapper,
    LayoutContainer,
    NavigationArea,
    NavigationAreaBackdrop,
    NavigationAreaWrapper,
    PageWrapper
} from "./styles";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {NavigationContent} from "./NavigationContent";
import {HeaderContent} from "./HeaderContent";
import {ReactComponent as BurgerSvg} from "../../components/Ui/Svg/Burger.svg";
import useMediaQuery from "../../../services/hooks/useMediaQuery";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {ITheme} from "../../../services/theme/ITheme";
import styled from "styled-components";
import {HeaderWarning} from "./HeaderWarning";
import {AvailableLessonRoom} from "./AvailableLessonRoom";
import CameraAccessSplash from "./CameraAccessSplash";
import {userIsTeacher} from "../../../store/user/selector";
import {RoutesList} from "../../RoutesList";
import {RoutesHelper} from "../../../helpers/RoutesHelper";
import {LocationWatcher} from "../../components/LocationWatcher";
import {NeedStartInitialCustomizer} from "./NeedStartInitialCustomizer";
import {contentLayoutSettingsSelector} from "../../../store/layout/selector";
import classNames from "classnames";
import {ContentBackground} from "./ContentBackground";

const BurgerStyled = styled(BurgerSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
  transition: opacity 0.3s;

  :hover {
    opacity: 1;
  }
`;

export const Layout: React.FC = () => {
    const [navigationIsActive, setNavigationIsActive] = useState(false);
    const needRequestCameraAllowed = useSelector(({app}: ApplicationState) => app.cameraAccess.requestAccessNow);
    const contentLayoutSettings = useSelector(contentLayoutSettingsSelector);
    const location = useLocation();
    const routerParams = useParams();
    const isTeacher = useSelector(userIsTeacher)

    const navigationBurgerOnClick = useCallback(
        () => {
            setNavigationIsActive(!navigationIsActive);
        },
        [
            navigationIsActive, setNavigationIsActive
        ]
    );

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.extraLarge})`);

    // Проверка - открыта ли страница занятия у ученика
    const inLessonPage = useMemo<boolean>(() => {
        if (isTeacher) {
            return false;
        }

        // Соберём текущие параметры и составим из них и шаблона путь.
        // Если итог совпадёт с текущим pathName, значит мы на странице занятия ученика
        const paramsList: Array<{ key: string, value: string }> = [];

        for (const [key, value] of Object.entries(routerParams)) {
            if (value && key) {
                paramsList.push({
                    key,
                    value
                });
            }
        }

        const resultString = RoutesHelper.replaceParams(
            RoutesList.STUDENT_LESSON_PAGE,
            paramsList
        );

        return resultString === location.pathname;

    }, [isTeacher, location.pathname, routerParams]);

    const leftNavigationAlwaysShowAllowed = useMemo<boolean>(() => {
        if (!contentLayoutSettings) {
            return true;
        }

        return contentLayoutSettings.hideLeftNavigation !== true;
    }, [contentLayoutSettings]);

    const transparentHeader = useMemo<boolean>(() => {
        if (!contentLayoutSettings) {
            return false;
        }

        return contentLayoutSettings.transparentHeader === true;
    }, [contentLayoutSettings]);

    // Проверка - открыта ли страница начального настройщика у учителя
    const inInitialCustomizerPage = useMemo<boolean>(() => {
        if (!isTeacher) {
            return false;
        }

        // Соберём текущие параметры и составим из них и шаблона путь.
        // Если итог совпадёт с текущим pathName, значит мы на странице занятия ученика
        const paramsList: Array<{ key: string, value: string }> = [];

        for (const [key, value] of Object.entries(routerParams)) {
            if (value && key) {
                paramsList.push({
                    key,
                    value
                });
            }
        }

        const resultString = RoutesHelper.replaceParams(
            RoutesList.TEACHER_SETUP,
            paramsList
        );

        return resultString === location.pathname;

    }, [isTeacher, location.pathname, routerParams]);

    useEffect(() => {
        if (isLarge && leftNavigationAlwaysShowAllowed && navigationIsActive) {
            setNavigationIsActive(false);
        }
    }, [isLarge, leftNavigationAlwaysShowAllowed, navigationIsActive, setNavigationIsActive]);

    return (
        <LayoutContainer>
            <NavigationAreaWrapper
                className={classNames(!leftNavigationAlwaysShowAllowed && 'static-disabled')}
                active={navigationIsActive}
            >
                <NavigationAreaBackdrop
                    className={classNames(!leftNavigationAlwaysShowAllowed && 'static-disabled')}
                    active={navigationIsActive}
                    onClick={navigationBurgerOnClick}
                />
                <NavigationArea
                    className={classNames(!leftNavigationAlwaysShowAllowed && 'static-disabled')}
                    active={navigationIsActive}
                >
                    <NavigationContent closeBtnClick={navigationBurgerOnClick}/>
                </NavigationArea>
            </NavigationAreaWrapper>
            <ContentBackground/>
            <ContentArea
                className={classNames(!leftNavigationAlwaysShowAllowed && 'left-navigation-static-disabled')}
            >
                <Header
                    className={classNames(transparentHeader && 'transparent-header')}
                >
                    <HeaderBurgerIconWrapper
                        className={classNames(
                            leftNavigationAlwaysShowAllowed && 'hide-on-extra-large',
                            transparentHeader && 'transparent-header'
                        )}
                        onClick={navigationBurgerOnClick}
                    >
                        <BurgerStyled/>
                    </HeaderBurgerIconWrapper>
                    {
                        !transparentHeader
                        && <ContentWidthWrapper>
                            <HeaderContent/>
                        </ContentWidthWrapper>
                    }
                </Header>
                <HeaderWarning/>
                {
                    (!inLessonPage)
                    && <AvailableLessonRoom/>
                }
                {
                    (!inInitialCustomizerPage)
                    && <NeedStartInitialCustomizer/>
                }
                <PageWrapper>
                    <ContentWidthWrapper>
                        <Outlet/>
                    </ContentWidthWrapper>
                </PageWrapper>
            </ContentArea>
            {
                (needRequestCameraAllowed)
                && <CameraAccessSplash/>
            }
            <LocationWatcher/>
        </LayoutContainer>
    );
}

Layout.displayName = 'Layout';
