import React from 'react';
import {createPortal} from 'react-dom';
import type {DropAnimation} from '@dnd-kit/core';
import {DragOverlay, useDndContext} from '@dnd-kit/core';
import {CSS} from '@dnd-kit/utilities';

import {Draggable} from './Draggable';
import {restrictToWindowEdges} from "@dnd-kit/modifiers";
import {IFillGapsDrapDrop} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsDrapDrop";
import classNames from "classnames";

const dropAnimationConfig: DropAnimation = {
    keyframes({transform}) {
        return [
            {transform: CSS.Transform.toString(transform.initial)},
            {
                transform: CSS.Transform.toString({
                    ...transform.final,
                    // scaleX: 0.94,
                    // scaleY: 0.94,
                }),
            },
        ];
    },
    sideEffects({active, dragOverlay, droppableContainers}) {
        // active.node.style.opacity = '0.3';
        const originalClassName = active.node.className;
        active.node.className= classNames(originalClassName, 'muted');

        const button = dragOverlay.node.querySelector('button');

        if (button) {
            button.animate(
                [
                    // {
                    //     boxShadow:
                    //         '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)',
                    // },
                    // {
                    //     boxShadow:
                    //         '-1px 0 15px 0 rgba(34, 33, 81, 0), 0px 15px 15px 0 rgba(34, 33, 81, 0)',
                    // },
                ],
                {
                    duration: 250,
                    easing: 'ease',
                    fill: 'forwards',
                }
            );
        }

        return () => {
            active.node.style.opacity = '';
            active.node.className = originalClassName;
        };
    },
};

interface Props {
    dropAnimation?: DropAnimation | null;
    element: IFillGapsDrapDrop;
}

export function DraggableOverlay({
                                     element,
                                     dropAnimation = dropAnimationConfig,
                                 }: Props) {
    const {active} = useDndContext();

    return createPortal(
        <DragOverlay dropAnimation={dropAnimation} modifiers={[restrictToWindowEdges]}>
            {
                active
                    ? <Draggable dragging dragOverlay element={element}/>
                    : null
            }
        </DragOverlay>,
        document.body
    );
}