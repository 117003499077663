import {IsNotEmpty, IsNumber, IsString} from "class-validator";
import {DtoGroupBase} from "./DtoGroupBase";

/**
 * DTO ответа на запрос информации о группе по id
 */
export class DtoGroupGetByIdResponse extends DtoGroupBase {
    @IsNotEmpty()
    @IsNumber()
    deleted: number;

    @IsNotEmpty()
    @IsString()
    teacherName: string;
}