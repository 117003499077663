export const DEFAULT_WIDTH_PX = 680;

export enum ImageAlignVariants {
    LEFT, RIGHT, CENTER
}

export enum ElementStateEnum {
    WAIT_INIT,
    NO_PICTURE,
    WAIT_FOR_UPLOADING,
    UPLOADING_NOW,
    UPLOAD_SUCCESSFULLY,
    UPLOAD_ERROR
}

export const UploadStateList = [
    ElementStateEnum.WAIT_INIT,
    ElementStateEnum.NO_PICTURE,
    ElementStateEnum.WAIT_FOR_UPLOADING,
    ElementStateEnum.UPLOADING_NOW,
    ElementStateEnum.UPLOAD_ERROR
]

export type ElementData = {
    imageId: string | null;
    align: ImageAlignVariants;
    widthInPercent: number;
    heightInPx: number | null;
    widthInPx?: number | null;
}