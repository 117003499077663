import styled from "styled-components";

export const PageWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (${({theme}) => theme.media.small}) and (max-height: 624px) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: absolute;
    padding-top: 11px;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    width: 100%;
  }
}
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: 50%;
  background-size: cover;
  z-index: -1;
  filter: ${({theme}) => theme.filters.authBackground}
`

export const DialogWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

export const BottomLineWrapper = styled.div`
  //display: none; Видимостью до small управляет js

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  align-items: flex-end;
`;

export const ButtomLineLeftCol = styled.div`
  text-align: left;
  width: 40%;

  @media (${({theme}) => theme.media.medium}) {
    width: 50%;
  }
`;

export const ButtomLineRightCol = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
`;

export const Dialog = styled.div`
  text-align: center;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px 20px;
  overflow: auto;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  transition: background-color 0.3s;

  @media (${({theme}) => theme.media.small}) {
    overflow: hidden;
    display: flex;
    position: relative;
    width: 400px;
    border-radius: 20px;
    padding: 32px 32px;
    border: 1px solid ${({theme}) => theme.colors.accentDivider};
  }
`;

export const LogoWrapper = styled.a`
  padding: 10px;
  margin-bottom: 52px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 32px;
  }
`;

export const FormWrapper = styled.div`
  position: relative;
  margin-bottom: 32px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 0;
  }
`;

export const FormLoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (${({theme}) => theme.media.small}) {
    display: none;
  }
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 16px;
`;