import React from "react";
import styled from "styled-components";
import {ReactComponent as Clock18} from "../../../components/Ui/Svg/Clock18.svg";

const ClockIconStyled = styled(Clock18)`
    display: inline-block;
    margin-bottom: 2px;
    vertical-align: middle;
`;

const TimeStringSpan = styled.span`
    vertical-align: middle;
`;

interface TimePeriodWithClockIconProps {
    timeString: string
}

export const TimePeriodWithClockIcon: React.FC<TimePeriodWithClockIconProps> = (
    {
        timeString
    }
) => {
    return <span>
        <ClockIconStyled/>&nbsp;
        <TimeStringSpan>{timeString}</TimeStringSpan>
    </span>
}