import React from "react";

export interface IVcsConnectionActionsContext {
    startConnection: () => void;
}

export const VcsConnectionActionsContext = React.createContext<IVcsConnectionActionsContext>({
    startConnection: () => {
    },
});

export const VcsConnectionActionsContextProvider = VcsConnectionActionsContext.Provider;
