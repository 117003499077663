import {instanceCachingFactory} from 'tsyringe';
import {IAppVersionHelper} from "../components/AppVersionHelper/IAppVersionHelper";
import {AppVersionHelper} from "../components/AppVersionHelper/AppVersionHelper";

export default instanceCachingFactory<IAppVersionHelper>(() => {
    if (!process.env.REACT_APP_VERSION) {
        throw new Error('Check REACT_APP_VERSION env param');
    }

    const appVersion = process.env.REACT_APP_VERSION as string;

    return new AppVersionHelper(appVersion);
});
