import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {ReactComponent as GradientMask} from "./gradient-mask.svg";
import Energy32 from "../Ui/Png/Energy32@2x.png";
import Energy160 from "../Ui/Png/Energy160@2x.png";

const LINE_HEIGHT = 36;

const Wrapper = styled.div`
    border-radius: 16px;
    border: 3px ${({theme}) => theme.colors.backgroundPrimary} solid;

    background: radial-gradient(84.68% 77.37% at 80.98% 75%, #FDC911 16.12%, rgba(253, 201, 17, 0.00) 58.93%), ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
    height: 64px;
    width: 92px;
    overflow: hidden;
    position: relative;

    &.two-digit {
        width: 108px;

        .energy-icon-wrapper {
            right: 4px;
        }

        .values-wrapper {
            left: 8px;
        }

        .values-mask {
            width: 108px;
            height: 64px;
        }
    }
`

const EnergyIconWrapper = styled.div`
    position: absolute;
    right: 7px;
    top: 9px;
`;

const ValuesMaskWrapper = styled(GradientMask)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: ${({theme}) => theme.colors.backgroundPrimary};
    width: 92px;
    height: 64px;
`;

const ValuesWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-size: 45px;
    line-height: ${LINE_HEIGHT}px;
    display: flex;
    flex-direction: column;
    gap: 0;

    position: absolute;
    bottom: 12px;
    left: 2px;

    transition: 0.7s cubic-bezier(0.68, -0.8, 0.32, 1.8) bottom;
`;

const ValueItem = styled.li`
    text-align: center;
    opacity: 0.5;
    transition: 0.3s ease opacity;

    &.active {
        opacity: 1;
    }
`;

interface PowerCounterProps {
    value: number;
    largeEnergyIcon?: boolean;
    className?: string;
}

export const PowerCounter: React.FC<PowerCounterProps> = ({value, largeEnergyIcon, className}) => {
    const [visibleValue, setVisibleValue] = useState<number | null>(null);

    const valueOffset = useMemo(() => {
        if (visibleValue === null) {
            return '50px';
        }

        return `${((value - 1) * LINE_HEIGHT * -1) - 25}px`;
    }, [value, visibleValue]);

    useEffect(() => {
        const timer = setTimeout(() => setVisibleValue(value), 100);

        return () => {
            clearTimeout(timer);
        }
    }, [value]);

    return <Wrapper className={classNames(value === 10 && 'two-digit', className)}>
        <ValuesWrapper className={'values-wrapper'} style={{bottom: valueOffset}}>
            {
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                    return <ValueItem
                        key={item}
                        className={classNames('value-item', 10 - item === value && 'active')}
                    >{10 - item}</ValueItem>
                })
            }
        </ValuesWrapper>

        <EnergyIconWrapper className={'energy-icon-wrapper'}>
            <img src={largeEnergyIcon ? Energy160 : Energy32} alt={'Energy icon'} width={'38px'} height={'38px'}/>
        </EnergyIconWrapper>

        <ValuesMaskWrapper className={'values-mask'}/>
    </Wrapper>
}