import {IBaseResponseDto} from './IBaseResponseDto';
import {ResponseStatusEnum} from "../../Enums/ResponseStatusEnum";

export type ValidationErrorDetails = { [attributeName: string]: Array<string> };

export class BaseResponseDto<T> implements IBaseResponseDto {
    status: ResponseStatusEnum;

    errorText?: string;
    validationSummary?: string;
    validationErrorDetails?: ValidationErrorDetails;

    data: T
}
