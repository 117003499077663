import * as React from "react";
import {useMemo} from "react";
import styled from 'styled-components';
import {Spin} from 'antd';

const LoaderBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpinContainer = styled.div`
  display: flex;
  margin-top: 2px;
`;

interface DefaultLoaderProps {
    height?: string;
    simple?: boolean;
}

export const DefaultLoader: React.FC<DefaultLoaderProps> = (props) => {
    const height = useMemo<string>(() => {
        if (props.simple === true) {
            return 'auto';
        }

        return (props.height !== undefined)
            ? props.height : '100px';
    }, [props]);

    return (
        <LoaderBlockWrapper style={{height: height}}>
            <LoaderWrapper>
                <SpinContainer>
                    <Spin/>
                </SpinContainer>
            </LoaderWrapper>
        </LoaderBlockWrapper>
    );
}