import * as React from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";
import {
    Wrapper,
    ImageWrapper,
    TextWrapper,
    Title,
    TextNotice,
    Subtitle,
    ItemsWrapper,
    Item,
    ItemIconWrapper,
    ItemIconText
} from "./styles";
import NoDataExtraSmall from "./img/NoDataExtraSmall.png"
import {ReactComponent as ScheduleSvg} from "./img/NoContentSchedule.svg";
import {ReactComponent as LessonPositionSvg} from "./img/NoContentLessonPosition.svg";
import {ReactComponent as HomeTaskSvg} from "./img/NoContentHomeTask.svg";
import {ReactComponent as WatchProgressSvg} from "./img/NoContentWatchProgress.svg";
import {ReactComponent as TeacherCallBackSvg} from "./img/NoContentTeacherCallBack.svg";
import {ReactComponent as PaymentSvg} from "./img/NoContentPayment.svg";
import NoDataSmall from "./img/NoDataSmall.png"
import NoDataLarge from "./img/NoDataLarge.png"
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const ScheduleSvgStyled = styled(ScheduleSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`

const LessonPositionSvgStyled = styled(LessonPositionSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`

const HomeTaskSvgStyled = styled(HomeTaskSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`

const WatchProgressSvgStyled = styled(WatchProgressSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`

const TeacherCallBackSvgStyled = styled(TeacherCallBackSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`

const PaymentSvgStyled = styled(PaymentSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
`


export const NoContent: React.FC = () => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);
    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const image = () => {
        if (!isSmall && !isLarge) {
            return (
                <img src={NoDataExtraSmall} width="220px" height="292px" alt=""/>
            )
        }

        if (isSmall && !isLarge) {
            return (
                <img src={NoDataSmall} width="540px" height="293px" alt=""/>
            )
        }

        return (
            <img src={NoDataLarge} width="444px" height="592px" alt=""/>
        )
    }

    return (
        <Wrapper>
            <ImageWrapper>
                {image()}
            </ImageWrapper>
            <TextWrapper>
                <Title>
                    <Trans>Это ваш дневник</Trans>
                </Title>
                <TextNotice>
                    <Trans>Попросите своего преподавателя открыть вам доступ к личному кабинету и попробуйте зайти
                        позднее.</Trans>
                </TextNotice>
                <Subtitle>
                    <Trans>Что здесь можно будет делать</Trans>
                </Subtitle>
                <ItemsWrapper>
                    <Item>
                        <ItemIconWrapper>
                            <ScheduleSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Посмотреть график занятий</Trans>
                        </ItemIconText>
                    </Item>
                    <Item>
                        <ItemIconWrapper>
                            <LessonPositionSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Узнать, где будет следующий урок</Trans>
                        </ItemIconText>
                    </Item>
                    <Item>
                        <ItemIconWrapper>
                            <HomeTaskSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Получить домашнее задание</Trans>
                        </ItemIconText>
                    </Item>
                    <Item>
                        <ItemIconWrapper>
                            <WatchProgressSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Следить за успеваемостью</Trans>
                        </ItemIconText>
                    </Item>
                    <Item>
                        <ItemIconWrapper>
                            <TeacherCallBackSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Получить обратную связь от учителя</Trans>
                        </ItemIconText>
                    </Item>
                    <Item>
                        <ItemIconWrapper>
                            <PaymentSvgStyled/>
                        </ItemIconWrapper>
                        <ItemIconText>
                            <Trans>Оплачивать и следить за счетами</Trans>
                        </ItemIconText>
                    </Item>
                </ItemsWrapper>
            </TextWrapper>
        </Wrapper>
    );
}