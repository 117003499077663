import * as React from "react";
import styled from "styled-components";
import {DisciplineItemStyled} from "./DisciplineItem";
import {ReactComponent as PlusSvg} from "../../../../../components/Ui/Svg/Plus.svg";
import {Trans} from "@lingui/macro";
import {
    DtoTmDiscipline
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {ModalResultType} from "../organizations-list/modal";
import {TmDisciplineModal} from "./modal";

const AddDisciplineItem = styled(DisciplineItemStyled)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 15px 5px 10px;
`;

const PlusIcon = styled(PlusSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 40px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CreateNewDisciplineItemProps {
    organizationId: string;
    onItemCreated: (data: DtoTmDiscipline) => void
}

export const CreateNewDisciplineItem: React.FC<CreateNewDisciplineItemProps> = (props) => {
    const onResult = (action: ModalResultType, data: DtoTmDiscipline | null) => {
        if (data !== null) {
            props.onItemCreated(data);
        }
    }

    return (
        <TmDisciplineModal
            organizationId={props.organizationId}
            disciplineItem={null}
            result={onResult}
            trigger={
                <AddDisciplineItem active={false}>
                    <PlusIcon/>
                    <PlusText><Trans>Добавить дисциплину</Trans></PlusText>
                </AddDisciplineItem>
            }/>
    );
}