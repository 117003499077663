import React from "react";
import styled from "styled-components";
import classNames from "classnames";

export const ChipLabel = styled.span`
  color: inherit;
  font-size: 15px;
`;

export const ChipWrapper = styled.button`
  cursor: pointer;
  border-radius: 4px;
  padding: 9px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.colors.accentDivider};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  outline: none;
  color: ${({theme}) => theme.colors.textPrimary};
  line-height: 1.2em;
  
  &.active {
    border-color: ${({theme}) => theme.colors.backgroundInverse};
  }
`;

export interface ChipProps {
    active: boolean;
    children: React.ReactNode;
    onClick: () => void
}

export const Chip: React.FC<ChipProps> = ({active, children, onClick}) => {
    return <ChipWrapper className={classNames(active && 'active')} onClick={onClick}>
        <ChipLabel>{children}</ChipLabel>
    </ChipWrapper>
};