import {ApplicationState} from "../index";
import {createSelector} from "reselect";
import {currentUserIdSelector} from "../user/selector";

export const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;
export const roomMembersSelector = ({lessonRoom}: ApplicationState) => lessonRoom.members;

export const currentUserInLessonRoom = createSelector(
    [
        currentUserIdSelector,
        roomMembersSelector
    ],
    (currentUserId, roomMembers) => {
        if (currentUserId === null) {
            return null;
        }

        const item = roomMembers.find((item) => item.userId === currentUserId);

        return item ?? null;
    }
);