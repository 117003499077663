import React, {useState} from "react";
import styled from "styled-components";
import {InputLabel} from "../../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {Form, FormItem, useForm} from "../../../../../components/Ui/Elements/Form";
import {Input} from "../../../../../components/Ui/Elements/TextInput";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {userIsTeacher} from "../../../../../../store/user/selector";
import {NoticeBlock, NoticeBlockText} from "../../../../../components/Ui/Elements/NoticeBlock";
import {RequestValidationError} from "../../../../../../components/HttpApiClient/Exception/RequestValidationError";
import {AccessDeniedException} from "../../../../../../components/HttpApiClient/Exception/AccessDeniedException";

const FormComponentsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template: "a" "b" "c" "d";
  grid-gap: 20px;
`;

const FormFieldWrapper = styled.div``;

enum FormFieldNames {
    CURRENT_PASS = 'currentPass',
    NEW_PASS = 'newPass',
    NEW_PASS_REPEAT = 'newPassRepeat'
}

export const NewPasswordSection: React.FC = (props) => {
    const [form] = useForm();
    const [nowLoading, setNowLoading] = useState<boolean>(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const isTeacher = useSelector(userIsTeacher);

    const onFinish = (values: any) => {
        if (!sessionToken) {
            return;
        }

        setNowLoading(true);

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        httpApiClient.setNewPassword(
            sessionToken,
            values[FormFieldNames.CURRENT_PASS],
            values[FormFieldNames.NEW_PASS],
        )
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Пароль изменён`,
                    t`Пароль успешно изменён`
                );
            })
            .catch((error) => {
                if (error instanceof RequestValidationError) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Ошибка`,
                        t`Проверьте указанный старый пароль`
                    );

                    return;
                }

                if (error instanceof AccessDeniedException) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Ошибка`,
                        t`Превышено допустимое количество попыток. Повторите позднее.`
                    );

                    return;
                }

                logger.error(LoggerSectionsEnum.SETTINGS_PAGE, "Error on update password", error);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось изменить пароль`
                );
            })
            .finally(() => {
                setNowLoading(false);
            })
    }

    if (isTeacher) {
        return <NoticeBlock>
            <NoticeBlockText>
                <Trans>Для изменения пароля преподавателя пока вы можете воспользоваться программой для Windows</Trans>
            </NoticeBlockText>
        </NoticeBlock>
    }

    return <div>
        <Form form={form} layout={"inline"} onFinish={onFinish}>
            <FormComponentsWrapper>
                <FormFieldWrapper style={{gridArea: "a"}}>
                    <InputLabel><Trans>Ваш текущий пароль</Trans></InputLabel>
                    <FormItem name={FormFieldNames.CURRENT_PASS}
                              rules={[
                                  {
                                      required: true,
                                      message: t`Укажите текущий пароль`,
                                  }
                              ]}>
                        <Input type={"password"}/>
                    </FormItem>
                </FormFieldWrapper>
                <FormFieldWrapper style={{gridArea: "b"}}>
                    <InputLabel><Trans>Новый пароль</Trans></InputLabel>
                    <FormItem name={FormFieldNames.NEW_PASS}
                              rules={[
                                  ({getFieldValue}) => ({
                                      validator(_, value) {
                                          if (!value) {
                                              return Promise.reject(t`Укажите новый пароль`);
                                          }

                                          if (/\s/g.test(value)) {
                                              return Promise.reject(new Error(t`Не используйте пробелы`));
                                          }

                                          if (value.length > 0 && value.length < 4) {
                                              return Promise.reject(new Error(t`Добавьте ещё несколько символов`));
                                          }

                                          return Promise.resolve();
                                      },
                                  })
                              ]}>
                        <Input type={"password"}/>
                    </FormItem>
                </FormFieldWrapper>
                <FormFieldWrapper style={{gridArea: "c"}}>
                    <InputLabel><Trans>Повторите пароль, чтобы не ошибиться</Trans></InputLabel>
                    <FormItem name={FormFieldNames.NEW_PASS_REPEAT}
                              rules={[
                                  {
                                      required: true,
                                      message: t`Повторите новый пароль`,
                                  },
                                  ({getFieldValue}) => ({
                                      validator(_, value) {
                                          if (!value || getFieldValue(FormFieldNames.NEW_PASS) === value) {
                                              return Promise.resolve();
                                          }
                                          return Promise.reject(new Error(t`Повтор нового пароля не совпадает`));
                                      },
                                  }),
                              ]}>
                        <Input type={"password"}/>
                    </FormItem>
                </FormFieldWrapper>
            </FormComponentsWrapper>
            <FormFieldWrapper style={{gridArea: "d"}}>
                <Button btnStyle={BtnStyleEnum.Primary} loading={nowLoading} onClick={() => form.submit()}>
                    <Trans>Изменить пароль</Trans>
                </Button>
            </FormFieldWrapper>
        </Form>
    </div>;
}