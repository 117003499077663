import {put, select, takeEvery} from "redux-saga/effects";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ContentLayoutSettings, LayoutActionTypes} from "../../store/layout/type";
import * as layoutActionCreators from "../../store/layout/actions";
import {ThemeEnum} from "../../services/theme/ThemeEnum";
import {themeNameSelector} from "../../store/commonPersisted/selector";
import {activeThemeNameSelector} from "../../store/layout/selector";

/**
 * Сага отвечает за запуск действий при изменении contentLayoutSettings
 */
export function* watchContentLayoutSettings() {
    yield takeEvery<WorkerPayloadType<ContentLayoutSettings | null>>(
        LayoutActionTypes.SET_CONTENT_LAYOUT_SETTINGS,
        setContentLayoutSettings
    );
}

// eslint-disable-next-line require-yield
function* setContentLayoutSettings(data: WorkerPayloadType<ContentLayoutSettings | null>) {
    if (data.payload === null) {
        const savedThemeName = (yield select(themeNameSelector)) as ThemeEnum | null;
        const selectedThemeName = (yield select(activeThemeNameSelector)) as string | null;

        if (savedThemeName !== selectedThemeName) {
            yield put(layoutActionCreators.changeTheme(savedThemeName ?? ThemeEnum.light, true));
        }

        return;
    }

    if (data.payload.themeName) {
        const selectedThemeName = (yield select(activeThemeNameSelector)) as string | null;

        if (data.payload.themeName !== selectedThemeName) {
            yield put(layoutActionCreators.changeTheme(data.payload.themeName, true));
        }
    }
}
