import * as React from "react";
import {useEffect} from "react";
import styled from 'styled-components';
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro"
import {Link} from "../../../components/Ui/Elements/Link";
import * as UserActionCreators from "../../../../store/user/actions";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {UserProfileLoadState} from "../../../../store/user/type";
import {useNavigate, useOutletContext} from "react-router-dom";
import {RoutesList} from "../../../RoutesList";
import {OutletContext} from "../index";

const Wrapper = styled.div`
`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ForgotPasswordLink = styled.div`
  margin-bottom: 73px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 0;
  }
`;

export const LoadProfileError: React.FC = () => {
    const dispatch = useDispatch();
    const startLoadingUserProfile = () => dispatch(UserActionCreators.startLoadingUserProfile());

    const logout = () => dispatch(UserActionCreators.logoutUser());

    const navigate = useNavigate();
    const outletContext = (useOutletContext() as OutletContext);

    const authState = useSelector<ApplicationState>(
        ({user}: ApplicationState) => user.authState
    ) as UserProfileLoadState;

    useEffect(() => {
        outletContext.setLoader(false);
    });

    useEffect(() => {
        if ((authState === UserProfileLoadState.AUTH_ERROR) || (authState === UserProfileLoadState.WAIT_AUTH_RESULT)) {
            return;
        }

        navigate(RoutesList.LOGIN_FORM);
    }, [authState, navigate]);

    const tryAuthAgain = () => {
        startLoadingUserProfile();
    }

    const resetAuthData = () => {
        logout();
    }

    return (
        <Wrapper>
            <InputsWrapper>
                <h2 style={{marginBottom: "1.5em"}}><Trans>Не удалось выполнить вход</Trans></h2>
                <p>
                    <Trans>Попробуйте повторить попытку входа или зайти, используя пароль</Trans>
                </p>
            </InputsWrapper>
            <ButtonsWrapper>
                <ButtonItem>
                    <Button
                        btnStyle={BtnStyleEnum.Primary}
                        onClick={tryAuthAgain}>
                        <Trans>Повторить попытку входа</Trans>
                    </Button>
                </ButtonItem>
            </ButtonsWrapper>
            <ForgotPasswordLink>
                <Link onClick={resetAuthData}><Trans>Войти с паролем</Trans></Link>
            </ForgotPasswordLink>
        </Wrapper>
    );
}