import {EditorItemDataParams} from "../../views/components/SlidePlayerEditorCommonParts/EditorData";
import {
    SlideItemOverriddenParams
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/StudentSlideWorkDataAnswersData/SlideItemOverriddenParams";

export enum SlideWorkDataActionTypes {
    LOAD_SLIDES_WORK_DATA = '@@slidesWorkData/LOAD_SLIDES_WORK_DATA',

    HANDLE_USER_EVENT_VALUE_CHANGE = '@@slidesWorkData/HANDLE_USER_EVENT_VALUE_CHANGE',
    HANDLE_USER_EVENT_ADD_ANSWER = '@@slidesWorkData/HANDLE_USER_EVENT_ADD_ANSWER',
    HANDLE_USER_EVENT_ADMIT_DEFEAT = '@@slidesWorkData/HANDLE_USER_EVENT_ADMIT_DEFEAT',
    HANDLE_USER_EVENT_CHANGE_ADDITIONAL_DATA = '@@slidesWorkData/HANDLE_USER_EVENT_CHANGE_ADDITIONAL_DATA',
    HANDLE_USER_EVENT_SET_OVERRIDDEN_PARAMS = '@@slidesWorkData/HANDLE_USER_EVENT_SET_OVERRIDDEN_PARAMS',

    HANDLE_SE_STUDENT_LESSON_WORK_DATA_SUMMARY_ITEM = '@@slidesWorkData/HANDLE_SE_STUDENT_LESSON_WORK_DATA_SUMMARY_ITEM',
    HANDLE_SE_STUDENT_LESSON_SLIDE_WORK_DATA_UPDATED = '@@slidesWorkData/HANDLE_SE_STUDENT_LESSON_SLIDE_WORK_DATA_UPDATED',

    PROCESS_STUDENT_LESSON_WORK_DATA_ITEMS_LIST = '@@slidesWorkData/PROCESS_STUDENT_LESSON_WORK_DATA_ITEMS_LIST',
    PROCESS_STUDENT_HOMEWORK_DATA_ITEMS_LIST = '@@slidesWorkData/PROCESS_STUDENT_HOMEWORK_DATA_ITEMS_LIST',

    SET_SLIDE_WORK_DATA_EMPTY_OBJECT = '@@slidesWorkData/SET_SLIDE_WORK_DATA_EMPTY_OBJECT',
    SET_SLIDE_WORK_DATA_AWARD_VALUES = '@@slidesWorkData/SET_SLIDE_WORK_DATA_AWARD_VALUES',
    SET_SLIDE_EXERCISE_WORK_DATA_EMPTY_OBJECT = '@@slidesWorkData/SET_SLIDE_EXERCISE_WORK_DATA_EMPTY_OBJECT',

    SET_EXERCISE_VALUE = '@@slidesWorkData/SET_EXERCISE_VALUE',
    SET_EXERCISE_ANSWER = '@@slidesWorkData/SET_EXERCISE_ANSWER',

    SET_SLIDE_ITEM_ADDITIONAL_DATA = '@@slidesWorkData/SET_SLIDE_ITEM_ADDITIONAL_DATA',
    SET_SLIDE_ITEM_OVERRIDDEN_PARAMS = '@@slidesWorkData/SET_SLIDE_ITEM_OVERRIDDEN_PARAMS',

    SET_SLIDE_WORK_DATA_LOADING_STATE = '@@slidesWorkData/SET_SLIDE_WORK_DATA_LOADING_STATE',

    SET_SLIDE_WORK_DATA_ITEMS_LIST = '@@slidesWorkData/SET_SLIDE_WORK_DATA_ITEMS_LIST',
    SET_SLIDE_WORK_DATA_ITEMS_LIST_ITEM_UPDATE = '@@slidesWorkData/SET_SLIDE_WORK_DATA_ITEMS_LIST_ITEM_UPDATE',

    SET_SLIDE_TOTAL_VALUES = '@@slidesWorkData/SET_SLIDE_TOTAL_VALUES',

    RESET_ALL_WORK_DATA_STATE = '@@slidesWorkData/RESET_ALL_WORK_DATA_STATE'
}

export interface ActionParamSetSlideTotalValues {
    totalAward: number;
    missedAward: number;
}

export interface LoadSlideWorkDataActionParams {
    playerId: string;
    lessonId: string;
    userId: string;
    slideIds: string[];
}

export interface ActionParamWithSlideIdAndValue<T> {
    playerId: string;
    slideId: string;
    slideContentVersionNum?: number;
    value: T;
}

export interface ActionParamExerciseUserValue<T> {
    playerId: string;
    slideId: string;
    slideItemId: string;
    exerciseId: string;
    value: T;
}

export interface ActionParamItemAdditionalData {
    playerId: string;
    slideId: string;
    slideItemId: string;
    exerciseId: string; // Можно указать любой exercise id из имеющихся в элементе слайда
    value: string;
}

export interface ActionParamItemOverriddenParams {
    playerId: string;
    slideId: string;
    slideItemId: string;
    exerciseId: string; // Можно указать любой exercise id из имеющихся в элементе слайда
    value: string; // Можно указать пустую строку
    overriddenParams: Partial<EditorItemDataParams>;
}

export interface ActionParamExerciseUserAnswer {
    playerId: string;
    slideId: string;
    slideItemId: string;
    exerciseId: string;
    value: string;
    award: number;
    missedAward: number;
    slideExercisesCount: number;
    answerIsCorrect: boolean | null; // Значение появилось со self-study треками, поэтому может отсутствовать
}

export interface ActionParamExerciseUserAdmitDefeat {
    playerId: string;
    slideId: string;
    slideItemId: string;
    exerciseId: string;
    value: string;
    slideExercisesCount: number;
}

export interface ActionParamExerciseValueByIndexes<T> {
    slideIndex: number;
    slideItemIndex: number;
    exerciseIndex: number;
    value: T;
}

export interface ActionParamExerciseUserAnswerByIndexes {
    slideIndex: number;
    slideItemIndex: number;
    exerciseIndex: number;
    value: string;
    award: number;
    missedAward: number;
    slideExercisesCount: number;
    answerIsCorrect: boolean | null; // Значение появилось со self-study треками, поэтому может отсутствовать
}

export enum SlideWorkDataLoadingStateEnum {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export interface ExerciseWorkData {
    exerciseId: string;
    inputHistory: string[];
    value: string;
    award: number;
    missedAward: number;
    completed: boolean | null; // Т.к. появилось лишь со selfStudy треками, значение может отсутствовать.
}

export interface SlideItemWorkData {
    slideItemId: string;
    exercises: ExerciseWorkData[];
    exercisesIndexById: {[exerciseId: string]: number};
    additionalData: null | string;
    overriddenParams: null | Partial<EditorItemDataParams>;
}

export interface SlideWorkData {
    slideId: string;
    slideContentVersionNum: number | null;
    playerId: string;
    items: SlideItemWorkData[];
    itemsIndexById: { [slideItemId: string]: number };
    slideCompleted: boolean;
    totalAward: number;
    missedAward: number;
    loadState: SlideWorkDataLoadingStateEnum;
}

export type IndexBySlideId = { [slideId: string]: number };

export type IndexByPlayerId = { [playerId: string]: { indexBySlideId: IndexBySlideId } };

export interface SlidesWorkDataState {
    slides: SlideWorkData[];
    indexByPlayerId: IndexByPlayerId;
}
