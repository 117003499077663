import {ISoundVolumeMeter} from "./ISoundVolumeMeter";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class SoundVolumeMeter implements ISoundVolumeMeter {
    protected logger: ILogger;
    protected audioContext: AudioContext | null;
    protected alreadyConnected: boolean;

    protected sourceNode: MediaStreamAudioSourceNode | null;
    protected analyzerNode: AnalyserNode | null;
    protected pcmData: Float32Array | null;

    constructor(logger: ILogger) {
        this.logger = logger;
        this.alreadyConnected = false;

        this.sourceNode = null;
        this.analyzerNode = null;
        this.pcmData = null;

        if (window.AudioContext !== undefined) {
            this.audioContext = new window.AudioContext();
        } else {
            this.logger.warning(LoggerSectionsEnum.BROWSER_AUDIO_CONTEXT, 'AudioContext unsupported');
            this.audioContext = null;

            return;
        }
    }

    /**
     * @inheritDoc
     */
    connectToStream(stream: MediaStream): void {
        if (!this.audioContext) {
            throw new Error('Audio context is not initialized');
        }

        if (this.alreadyConnected) {
            throw new Error('Sound meter is busy');
        }

        this.sourceNode = this.audioContext.createMediaStreamSource(stream);
        this.analyzerNode = this.audioContext.createAnalyser();
        this.sourceNode.connect(this.analyzerNode);
        this.pcmData = new Float32Array(this.analyzerNode.fftSize);
    }

    /**
     * @inheritDoc
     */
    disconnect(): void {
        if (this.analyzerNode) {
            this.analyzerNode.disconnect();

            this.analyzerNode = null;
        }

        if (this.sourceNode) {
            this.sourceNode.disconnect();

            this.sourceNode = null;
        }
    }

    /**
     * @inheritDoc
     */
    getCurrentValue(): number {
        if (!this.sourceNode || !this.analyzerNode || !this.sourceNode || !this.pcmData) {
            return 0;
        }

        this.analyzerNode.getFloatTimeDomainData(this.pcmData);

        let sumSquares = 0.0;

        // @ts-ignore
        for (const amplitude of this.pcmData) {
            sumSquares += amplitude * amplitude;
        }

        return Math.sqrt(sumSquares / this.pcmData.length);
    }

    /**
     * @inheritDoc
     */
    isSupported(): boolean {
        return this.audioContext !== null;
    }
}
