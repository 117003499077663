import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import styled, {keyframes} from "styled-components";
import {IRichTextElementRefMethods} from "../../components/RichTextReader/IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../components/RichTextReader/IRichTextElementProps";
import {IRadioList} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioList";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {RegularText} from "../../components/RichTextReader/elements/common-styles";
import classNames from "classnames";
import {ExerciseRadioContext, IExerciseRadioContext, ShowMode} from "./ExerciseRadioContext";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {AlignTypes} from "../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

const ErrorTremor = keyframes`
  0% {
    transform: translateX(-3px);
  }

  15% {
    transform: translateX(+3px);
  }

  30% {
    transform: translateX(-2px);
  }

  45% {
    transform: translateX(+2px);
  }

  60% {
    transform: translateX(-1px);
  }

  75% {
    transform: translateX(+1px);
  }

  90% {
    transform: translateX(0);
  }
`;

const RadioListItem = styled.li`
  ${RegularText};

  position: relative;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &.correct, &.incorrect, &.muted {
    pointer-events: none;
    cursor: default;
  }

  &.incorrect, &.muted {
    opacity: 0.5;
  }

  &.error-animation {
    animation: ${ErrorTremor} 0.9s ease-in-out;
  }

  &.align-right {
    text-align: right;
  }

  &.align-justify {
    text-align: justify;
  }

  &.align-center {
    text-align: center;
  }

  &.align-left {
    text-align: left;
  }
  
  &.read-only {
    cursor: not-allowed;
  }
`;

const RadioStateIconWrapper = styled.div`
  min-width: 30px;
  padding: 0.4em;
`;

const BodyWrapper = styled.div`
  padding-top: 4px;
  padding-left: 2px;
  flex-grow: 1;
`;

const RadioStateIconBase = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.slideContent.radioBorder};
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 6%) 0 1px 1px;

  transition: border 0.3s linear;

  &.correct {
    border-width: 6px;
    border-color: ${({theme}) => theme.colors.progressBarRight};
  }

  &.incorrect {
    border-width: 6px;
    border-color: ${({theme}) => theme.colors.progressBarWrong};
  }
`;
export const ExerciseRadioListItem = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IRadioList>>(
    (props, ref) => {

        const {element, className, ...otherProps} = props;

        const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);

        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const alignClass = useMemo(() => {
            switch (element.align) {
                case AlignTypes.RIGHT: {
                    return "align-right";
                }
                case AlignTypes.JUSTIFY: {
                    return "align-justify";
                }
                case AlignTypes.CENTER: {
                    return "align-center";
                }
                default: {
                    return "align-left";
                }
            }
        }, [element]);

        const exerciseContext = useContext<IExerciseRadioContext>(ExerciseRadioContext);

        const {showErrorAnimationInVariantId, setShowErrorAnimationInVariantId} = exerciseContext;

        useEffect(() => {
            if (showErrorAnimationInVariantId === element.id) {
                setNowShowErrorAnimation(true);
                setShowErrorAnimationInVariantId(null);
            }
        }, [element.id, setShowErrorAnimationInVariantId, showErrorAnimationInVariantId])

        const currentShowMode = useMemo<ShowMode>(() => {
            return exerciseContext.variantState[element.id] ?? ShowMode.INPUT;
        }, [element.id, exerciseContext.variantState]);

        return <RadioListItem
            className={
                classNames(
                    alignClass,
                    className,
                    (currentShowMode === ShowMode.ERROR) && "incorrect",
                    (currentShowMode === ShowMode.CORRECT) && "correct",
                    (currentShowMode === ShowMode.MUTED) && "muted",
                    nowShowErrorAnimation && "error-animation",
                    (slideItemWorkContext.readOnly && currentShowMode === ShowMode.INPUT) && "read-only"
                )
            }
            onClick={() => {
                exerciseContext.variantOnClick(element.id)
            }}
            onAnimationEnd={() => setNowShowErrorAnimation(false)}
        >
            <RadioStateIconWrapper>
                <RadioStateIconBase className={classNames(
                    (currentShowMode === ShowMode.ERROR) && "incorrect",
                    (currentShowMode === ShowMode.CORRECT) && "correct"
                )}/>
            </RadioStateIconWrapper>
            <BodyWrapper>
                <RichTextReader elements={element.children} {...otherProps}/>
            </BodyWrapper>
        </RadioListItem>
    }
);

ExerciseRadioListItem.displayName = 'ExerciseRadioListItem';