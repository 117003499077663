import React, {useContext} from "react";
import {AdminViewContext, IAdminViewContext} from "../Context";
import styled from "styled-components";
import {Item} from "./Item";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const Subscribers: React.FC = () => {
    const {subscribersList} = useContext<IAdminViewContext>(AdminViewContext);

    return <Wrapper>
        {
            subscribersList.list.map((subscriber) => {
                return <Item key={subscriber.userId} subscriber={subscriber}/>
            })
        }
    </Wrapper>
}