import styled from "styled-components";
import {RegularTextCss} from "../../../../../styles/global-elements";

export const ResultListItem = styled.li`
    ${RegularTextCss};

    padding: 5px 0;
    cursor: pointer;
    color: ${({theme}) => theme.colors.textPrimary};
    opacity: 0.8;
    transition: opacity 0.3s ease;

    :hover {
        opacity: 1;
    }
`;