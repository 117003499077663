export enum UserOnlineStateSubscriptionsActionTypes {
    // Подписаться на новых пользователей. Добавляет список переданных
    // идентификаторов в список ожидания и запускает цикл подписки (если он ещё не запущен).
    SUBSCRIBE_TO_NEW_USERS = '@@userOnlineStateSubscriptions/SUBSCRIBE_TO_NEW_USERS',

    // Помещает переданных пользователей в список ожидания на подписку.
    PUSH_USERS_TO_WAIT_LIST = '@@userOnlineStateSubscriptions/PUSH_USERS_TO_WAIT_LIST',

    // Исключает переданных пользователей из списка ожидания на подписку.
    DELETE_USERS_FROM_WAIT_LIST = '@@userOnlineStateSubscriptions/DELETE_USERS_FROM_WAIT_LIST',

    // Обработать очередную пачку пользователей из списка ожидания.
    PROCESS_BATCH_FROM_WAIT_LIST = '@@userOnlineStateSubscriptions/PROCESS_BATCH_FROM_WAIT_LIST',

    // Разместить данные о подписках в списке активных подписок.
    PUSH_TO_ACTIVE_SUBSCRIPTIONS_LIST = '@@userOnlineStateSubscriptions/PUSH_TO_ACTIVE_SUBSCRIPTIONS_LIST',

    // Обновить состояния пользователей из списка активных подписок.
    UPDATE_STATE_IN_ACTIVE_SUBSCRIPTIONS_LIST = '@@userOnlineStateSubscriptions/UPDATE_STATE_IN_ACTIVE_SUBSCRIPTIONS_LIST',

    // Исключить данные о подписках из списка активных подписок.
    DELETE_FROM_ACTIVE_SUBSCRIPTIONS_LIST = '@@userOnlineStateSubscriptions/DELETE_FROM_ACTIVE_SUBSCRIPTIONS_LIST',

    // Очистить список активных подписок
    CLEAR_ACTIVE_SUBSCRIPTIONS_LIST = '@@userOnlineStateSubscriptions/CLEAR_ACTIVE_SUBSCRIPTIONS_LIST',

    // Событие, вызываемое при получении нового сообщения потока событий "обновлено состояние пользователя"
    STREAM_EVENT_USER_STATE_UPDATE = '@@userOnlineStateSubscriptions/STREAM_EVENT_USER_STATE_UPDATE',

    // Выполнить переподписку (например, после разрыва соединения).
    RESUBSCRIBE_ACTIVE_SUBSCRIPTIONS = '@@userOnlineStateSubscriptions/RESUBSCRIBE_ACTIVE_SUBSCRIPTIONS'
}

export enum OnlineStateEnum {
    OFFLINE = 0,
    ONLINE = 1
}

export class ActiveSubscriptionUserItem {
    currentState: OnlineStateEnum;
    createdAt: Date;
    updatedAt: Date;
}

export type ActiveSubscriptionsItemsList = { [userId: number]: ActiveSubscriptionUserItem };

export interface ActiveSubscriptionsInterface {
    items: ActiveSubscriptionsItemsList,
    itemsCount: number;
}

export interface UserOnlineStateSubscriptionsState {
    activeSubscriptions: ActiveSubscriptionsInterface;
    waitForSubscribe: Array<number>
}