import React, {useEffect, useMemo, useState} from 'react';
import {AUDIO_ELEMENT_HEIGHT, ElementData} from "./Common";
import styled from "styled-components";
import {PresenterPlaceholder, PresenterPlaceholderShowModeEnum} from "./PresenterPlaceholder";
import {t} from "@lingui/macro";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../../store/fileFormatsRepo/type";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../../store/fileFormatsRepo/selector";
import * as FileFormatsRepoActionCreators from "../../../../../store/fileFormatsRepo/actions";
import {AudioPlayer, QualityItem} from "../../../Ui/Elements/AudioPlayer";
import {BlockWithBorder} from "../../../../styles/global-elements";

interface PresenterComponentProps {
    elementData: ElementData;
    inConstructor: boolean;
    noVolume?: boolean;
    className?: string;
}

enum PresenterInternalState {
    WAIT_FOR_INIT,
    NO_IMAGE,
    FETCHING_LINK,
    READY,
    ERROR
}

const PresenterWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlayerWrapper = styled(BlockWithBorder)`
  padding: 7px;
  width: 100%;
`;

export const PresenterComponent: React.FC<PresenterComponentProps> = (props) => {
    const {elementData, inConstructor} = props;
    const fileRepoState = useSelector(repoStateSelector);

    const dispatch = useDispatch();

    const [state, setState] = useState<PresenterInternalState>(PresenterInternalState.WAIT_FOR_INIT);

    const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
        if (elementData.audioId === null) {
            return null
        }

        const itemIndex = fileRepoState.indexByFileId[elementData.audioId];

        if (itemIndex === undefined) {
            return null;
        }

        return fileRepoState.files[itemIndex] ?? null;
    }, [fileRepoState, elementData]);


    const fileLinks = useMemo<QualityItem[] | null>(() => {
        if (currentFileInFileRepo === null) {
            return null;
        }

        if (currentFileInFileRepo.availableFormats.length === 0) {
            return null;
        }

        return currentFileInFileRepo.availableFormats.map((item): QualityItem => {
            return {
                mimeType: item.mimeType,
                url: item.url,
                format: item.formatType
            }
        });
    }, [currentFileInFileRepo]);

    useEffect(() => {
        const fetchNewFileInfo = (fileId: string) =>
            dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

        if (elementData.audioId === null) {
            setState(PresenterInternalState.NO_IMAGE);

            return;
        }

        if (currentFileInFileRepo === null) {
            // Запросим ссылку
            fetchNewFileInfo(elementData.audioId);
            setState(PresenterInternalState.FETCHING_LINK);

            return;
        }

        switch (currentFileInFileRepo.fetchInfoStatus) {
            case FileFetchInfoProcessStatus.WAIT_FOR_START:
            case FileFetchInfoProcessStatus.IN_PROCESS: {
                setState(PresenterInternalState.FETCHING_LINK);

                break;
            }
            case FileFetchInfoProcessStatus.SUCCESS: {
                setState(PresenterInternalState.READY);

                break;
            }
            default: {
                setState(PresenterInternalState.ERROR);

                break;
            }
        }
    }, [currentFileInFileRepo, dispatch, elementData]);

    const content = () => {
        switch (state) {
            case PresenterInternalState.WAIT_FOR_INIT:
            case PresenterInternalState.FETCHING_LINK: {
                return <div style={{height: AUDIO_ELEMENT_HEIGHT.toString(10) + 'px', width: "100%"}}>
                    <PresenterPlaceholder
                        showMode={PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING}
                        text={t`Аудио загружается...`}
                    /></div>;
            }
            case PresenterInternalState.READY: {
                if (fileLinks) {
                    return <PlayerWrapper className={'audio-player-wrapper'}>
                        <AudioPlayer
                            volumeDisabled={props.noVolume}
                            qualityItems={fileLinks}
                            height={AUDIO_ELEMENT_HEIGHT.toString(10) + 'px'}
                            settingsEnabled={inConstructor}
                        />
                    </PlayerWrapper>;
                }
            }
        }

        return <PresenterPlaceholder
            showMode={PresenterPlaceholderShowModeEnum.LOADING_ERROR}
            text={t`Ошибка загрузки аудио`}
        />;
    }

    return <PresenterWrapper className={props.className}>
        {content()}
    </PresenterWrapper>
}

PresenterComponent.displayName = 'AudioPresenterComponent';