import React, {useMemo} from "react";
import {
    DtoGroupNextLessonInfo
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupNextLessonInfo";
import {Trans} from "@lingui/macro";
import {DateAsString} from "../../../components/Ui/Elements/TextPresenter/DateAsString";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ReactComponent as CalendarIcon} from "../../../components/Ui/Svg/Calendar20.svg";
import {ReactComponent as ClockIcon} from "../../../components/Ui/Svg/Clock20.svg";
import {ReactComponent as HourglassIcon} from "../../../components/Ui/Svg/Hourglass20.svg";
import {ReactComponent as PlaceIcon} from "../../../components/Ui/Svg/Place20.svg";
import {WeekDayNameByDate} from "../../../components/Ui/Elements/TextPresenter/WeekDayNameByDate";
import {WeekDayType} from "../../../components/Ui/Elements/TextPresenter/WeekDayNameByNumber";
import {IconWrapper, Row, TextWrapper, Wrapper} from "../../../components/PageWrapper/ListWithIcons";

interface NextLessonDetailsProps {
    nextLesson: DtoGroupNextLessonInfo;
}

export const NextLessonDetails: React.FC<NextLessonDetailsProps> = (props) => {
    const dateHelper = useMemo<IDateHelperService>(() => {
        return container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
    }, []);

    const nextLessonDate = useMemo<Date>(() => {
        return dateHelper.dateFromString(props.nextLesson.date);
    }, [dateHelper, props.nextLesson]);

    return <Wrapper>
        <Row>
            <IconWrapper><CalendarIcon/></IconWrapper>
            <TextWrapper>
                <WeekDayNameByDate date={nextLessonDate} nameType={WeekDayType.NOMINATIVE}/>,&nbsp;
                <DateAsString date={nextLessonDate}/>
            </TextWrapper>
        </Row>

        <Row>
            <IconWrapper><ClockIcon/></IconWrapper>
            <TextWrapper>
                {dateHelper.removeSecondsFromTimeString(props.nextLesson.startTime)}
                &nbsp;-&nbsp;
                {dateHelper.removeSecondsFromTimeString(props.nextLesson.endTime)}
            </TextWrapper>
        </Row>

        <Row>
            <IconWrapper><HourglassIcon/></IconWrapper>
            <TextWrapper>
                {props.nextLesson.duration} <Trans>минут</Trans>
            </TextWrapper>
        </Row>

        <Row>
            <IconWrapper><PlaceIcon/></IconWrapper>
            <TextWrapper>
                {props.nextLesson.address}
            </TextWrapper>
        </Row>

    </Wrapper>;
}
