import React, {CSSProperties, forwardRef, useImperativeHandle, useMemo} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import styled from "styled-components";
import {ElementData, ImageAlignVariants} from "../../../SlidePlayerEditorCommonParts/components/Image/Common";
import classNames from "classnames";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Image/PresenterComponent";

const ImageElementWrapper = styled.figure`
  margin: 0;
  position: relative;

  &.al-left {
    text-align: left;
  }

  &.al-center {
    text-align: center;
  }

  &.al-right {
    text-align: right;
  }
`;

const SizeContainer = styled.div`
  margin: auto;
`;

interface ImageProps extends IElementProps<ElementData> {
}

interface ImageRefMethods extends IElementRefMethods {
}

export const Image = forwardRef<ImageRefMethods, ImageProps>(
    (props, ref) => {

        const {elementData} = props;

        const imageData = useMemo<ElementData>(() => {
            return elementData.data;
        }, [elementData]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const sizeContainerStyles = useMemo<CSSProperties>(() => {
            return {
                width: imageData.widthInPercent.toString(10) + '%',
                height: '100%',
                display: "inline-block"
            };
        }, [imageData]);

        return <ImageElementWrapper
            className={classNames(
                imageData.align === ImageAlignVariants.LEFT && "al-left",
                imageData.align === ImageAlignVariants.CENTER && "al-center",
                imageData.align === ImageAlignVariants.RIGHT && "al-right"
            )}>
            <SizeContainer style={sizeContainerStyles}>
                <PresenterComponent elementData={imageData}/>
            </SizeContainer>
        </ImageElementWrapper>;
    }
);

Image.displayName = 'Image';