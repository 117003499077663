import {ReactComponent as LogoSvg} from "../../../components/Ui/Svg/WithStonlineLogo.svg";
import {ReactComponent as LogoDarkThemeSvg} from "../../../components/Ui/Svg/WithStonlineLogoDarkTheme.svg";
import * as React from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ThemeEnum} from "../../../../services/theme/ThemeEnum";

interface LogoProps {
    width: string;
}

export const LogoWithStonline: React.FunctionComponent<LogoProps> = (props) => {
    const themeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as ThemeEnum;

    return (
        <>
            {(themeName === ThemeEnum.light) ? <LogoSvg style={{width: props.width}}/> :
                <LogoDarkThemeSvg style={{width: props.width}}/>}
        </>
    );
};