import {IsNumber, IsOptional} from 'class-validator';

/**
 * DTO ответа на запрос оценок по параметрам для домашней работы
 */
export class DtoHomeworkAverageResults {
    @IsOptional()
    @IsNumber()
    param1: number | null;

    @IsOptional()
    @IsNumber()
    param2: number | null;

    @IsOptional()
    @IsNumber()
    param3: number | null;

    @IsOptional()
    @IsNumber()
    param4: number | null;

    @IsOptional()
    @IsNumber()
    param5: number | null;

    @IsNumber()
    totalAverage: number;
}
