import {instanceCachingFactory} from 'tsyringe';
import {IWsApiClient} from "../components/WsApiClient/IWsApiClient";
import {IStoreService} from "../services/store/IStoreService";
import {DiTokens} from "./DiTokens";
import {WsApiClient} from "../components/WsApiClient/WsApiClient";
import {ILogger} from "../components/Logger/ILogger";
import {IRouter} from "../components/WsApiClient/Router/IRouter";

export default instanceCachingFactory<IWsApiClient>((dependencyContainer) => {
    const storeService = dependencyContainer.resolve<IStoreService>(DiTokens.STORE_SERVICE);
    const logger = dependencyContainer.resolve<ILogger>(DiTokens.LOGGER);
    const wsResponseRouter = dependencyContainer.resolve<IRouter>(DiTokens.WS_RESPONSE_ROUTER);

    if (process.env.REACT_APP_WS_API_ENTRY_POINT === undefined) {
        throw new Error('Not found WS_API_ENTRY_POINT value');
    }

    return new WsApiClient(
        process.env.REACT_APP_WS_API_ENTRY_POINT,
        storeService.store.dispatch,
        wsResponseRouter,
        logger
    )
});
