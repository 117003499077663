import React, {useContext, useState} from "react";
import {IInitialCustomizerPageContext, InitialCustomizerPageContext} from "../InitialCustomizerPageContext";
import styled from "styled-components";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {Form, FormItem, useForm} from "../../../../components/Ui/Elements/Form";
import {FormFieldNamesEnum} from "../FormFieldNamesEnum";
import {RadioButton, RadioGroupButton} from "../../../../components/Ui/Elements/RadioInput";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {ButtonArea} from "../../student/create-agreement-page/ButtonArea";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const RadioLabelWideScreen = styled.span`
  display: none;

  @media (${({theme}) => theme.media.small}) {
    display: inline;
  }
`;

const RadioLabelNarrowScreen = styled.span`
  display: inline;

  @media (${({theme}) => theme.media.small}) {
    display: none;
  }
`;

const ACTUAL_PARAM_NAME_LENGTH = 20;

export const SetActParamsSection: React.FC = () => {
    const [form] = useForm();

    const pageContext = useContext<IInitialCustomizerPageContext>(InitialCustomizerPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const [formParamsCount, setFormParamsCount] = useState<number>(pageContext.formData.actualParamsCount);

    const onFinish = (values: any) => {
        pageContext.setFormData((oldValues) => {
            return {
                ...oldValues,
                actualParamsCount: values[FormFieldNamesEnum.ACT_PARAMS_COUNT],
                actualParam1Name: values[FormFieldNamesEnum.ACT_PARAM1_NAME],
                actualParam2Name: values[FormFieldNamesEnum.ACT_PARAM2_NAME],
                actualParam3Name: values[FormFieldNamesEnum.ACT_PARAM3_NAME],
                actualParam4Name: values[FormFieldNamesEnum.ACT_PARAM4_NAME],
                actualParam5Name: values[FormFieldNamesEnum.ACT_PARAM5_NAME],
            }
        });

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.SET_ACT_PARAMS,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    const onFormValuesChange = () => {
        const paramsCount = form.getFieldValue(FormFieldNamesEnum.ACT_PARAMS_COUNT);

        if (formParamsCount !== paramsCount) {
            setFormParamsCount(paramsCount);
        }
    }

    return <div>
        <FormWrapper>
            <Form form={form} layout={"inline"}
                  onFinish={onFinish}
                  onValuesChange={onFormValuesChange}
            >
                <PageSubtitleSmallMargin>
                    <Trans>Сколько и каких параметров оценки вы используете в работе?</Trans>
                </PageSubtitleSmallMargin>
                <FormItemsWrapper>
                    <FormItem
                        name={FormFieldNamesEnum.ACT_PARAMS_COUNT}
                        initialValue={pageContext.formData.actualParamsCount}
                    >
                        <RadioGroupButton>
                            <RadioButton value={1}>
                                <RadioLabelNarrowScreen>1</RadioLabelNarrowScreen>
                                <RadioLabelWideScreen><Trans>Один</Trans></RadioLabelWideScreen>
                            </RadioButton>
                            <RadioButton value={2}>
                                <RadioLabelNarrowScreen>2</RadioLabelNarrowScreen>
                                <RadioLabelWideScreen><Trans>Два</Trans></RadioLabelWideScreen>
                            </RadioButton>
                            <RadioButton value={3}>
                                <RadioLabelNarrowScreen>3</RadioLabelNarrowScreen>
                                <RadioLabelWideScreen><Trans>Три</Trans></RadioLabelWideScreen>
                            </RadioButton>
                            <RadioButton value={4}>
                                <RadioLabelNarrowScreen>4</RadioLabelNarrowScreen>
                                <RadioLabelWideScreen><Trans>Четыре</Trans></RadioLabelWideScreen>
                            </RadioButton>
                            <RadioButton value={5}>
                                <RadioLabelNarrowScreen>5</RadioLabelNarrowScreen>
                                <RadioLabelWideScreen><Trans>Пять</Trans></RadioLabelWideScreen>
                            </RadioButton>
                        </RadioGroupButton>
                    </FormItem>

                    <RegularText>
                        <Trans>Нужно указать лишь количество и их названия - для работы этого будет
                            достаточно.</Trans><br/>
                        <Trans>Если вы обычно ставите одну оценку, можно задать один параметр и так его и назвать -
                            «Оценка».</Trans>
                    </RegularText>

                    <FormItem
                        name={FormFieldNamesEnum.ACT_PARAM1_NAME}
                        initialValue={pageContext.formData.actualParam1Name}
                        label={<Trans>Название первого параметра</Trans>}
                        rules={[{
                            required: true,
                            message: t`Необходимо указать название параметра. Можете просто написать «Оценка».`,
                        }]}
                    >
                        <Input maxLength={ACTUAL_PARAM_NAME_LENGTH}/>
                    </FormItem>

                    {
                        (formParamsCount > 1) && <FormItem
                            name={FormFieldNamesEnum.ACT_PARAM2_NAME}
                            initialValue={pageContext.formData.actualParam2Name}
                            label={<Trans>Название второго параметра</Trans>}
                        >
                            <Input maxLength={ACTUAL_PARAM_NAME_LENGTH}/>
                        </FormItem>
                    }

                    {
                        (formParamsCount > 2) && <FormItem
                            name={FormFieldNamesEnum.ACT_PARAM3_NAME}
                            initialValue={pageContext.formData.actualParam3Name}
                            label={<Trans>Название третьего параметра</Trans>}
                        >
                            <Input maxLength={ACTUAL_PARAM_NAME_LENGTH}/>
                        </FormItem>
                    }

                    {
                        (formParamsCount > 3) && <FormItem
                            name={FormFieldNamesEnum.ACT_PARAM4_NAME}
                            initialValue={pageContext.formData.actualParam4Name}
                            label={<Trans>Название четвёртого параметра</Trans>}
                        >
                            <Input maxLength={ACTUAL_PARAM_NAME_LENGTH}/>
                        </FormItem>
                    }

                    {
                        (formParamsCount > 4) && <FormItem
                            name={FormFieldNamesEnum.ACT_PARAM5_NAME}
                            initialValue={pageContext.formData.actualParam5Name}
                            label={<Trans>Название пятого параметра</Trans>}
                        >
                            <Input maxLength={ACTUAL_PARAM_NAME_LENGTH}/>
                        </FormItem>
                    }
                </FormItemsWrapper>
            </Form>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}
            nextBtnOnClick={async () => {
                await form.validateFields();

                form.submit();
            }}
        />
    </div>
}