import {ElementTypeEnum} from "./ElementTypeEnum";
import {IsBoolean, IsNotEmpty, IsOptional, IsString, ValidateNested} from "class-validator";
import {Type} from "class-transformer";

export class EditorItemInteractivityConfigActionCondition {
    @IsString()
    fieldForCondition: string; // Field for compare ("answer" for example)

    @IsString()
    conditionMode: string; // {@see SlideItemInteractivityActionConditionModeEnum}

    @IsString()
    targetValue: string; // Value for compare
}

export class EditorItemInteractivityConfigAction {
    @IsNotEmpty()
    @IsString()
    actionType: string; // {@see SlideItemInteractivityActionNameEnum}

    @IsOptional()
    @IsString()
    actionAdditionalInfo: any; // Target Slide Item alias.

    @IsOptional()
    @ValidateNested()
    @Type(() => EditorItemInteractivityConfigActionCondition)
    condition: EditorItemInteractivityConfigActionCondition;
}

export class EditorItemInteractivityConfig {
    @IsNotEmpty()
    @IsString()
    actionRunMode: string; // {@see SlideItemInteractivityActionRunModeEnum}

    @IsOptional()
    @IsString()
    nextBtnLabel: string | null;

    @IsNotEmpty()
    @ValidateNested({each: true})
    @Type(() => EditorItemInteractivityConfigAction)
    actions: EditorItemInteractivityConfigAction[];
}

export class EditorItemInteractivityConfigWithParentItem extends EditorItemInteractivityConfig {
    parentItemId: string;

    constructor(parentItemId: string, config: EditorItemInteractivityConfig) {
        super();
        this.parentItemId = parentItemId;
        this.actionRunMode = config.actionRunMode;
        this.nextBtnLabel = config.nextBtnLabel;
        this.actions = config.actions;
    }
}

export class EditorItemDataParams {
    @IsBoolean()
    visible: boolean;
}

export class EditorDataItem<T> {
    id: string;
    type: ElementTypeEnum;
    alias: string | null;
    params: EditorItemDataParams;
    interactivityConfig: EditorItemInteractivityConfig | null;
    data: T;
    exercisesCount?: number;
}

export class EditorData {
    items: EditorDataItem<any>[];

    constructor() {
        this.items = [];
    }
}