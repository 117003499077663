import * as React from "react";
import {useCallback, useMemo} from "react";
import {
    BurgerClose,
    BurgerCloseSvgStyled,
    Link,
    Links,
    LinksWrapper,
    LinkWrapper,
    LogoIcon,
    LogoWrapper,
    NavigationContentWrapper
} from "./styles";
import {Logo} from "../../../components/Ui/Elements/Logo";
import {Trans} from "@lingui/macro";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {RoutesList} from "../../../RoutesList";
import {
    selectedAgreement as selectedAgreementSelector,
    userIsTeacher as userIsTeacherSelector,
    userProfileIsEmpty as userProfileIsEmptySelector
} from "../../../../store/user/selector";
import {useSelector} from "react-redux";
import {ProgramSelector} from "./ProgramSelector";
import {KnowledgeBaseLink} from "./KnowledgeBaseLink";
import {AboutWinAppLink} from "./AboutWinAppLink";
import {ApplicationState} from "../../../../store";
import {container} from "tsyringe";
import {IFeatureToggle} from "../../../../components/FeatureToggle/IFeatureToggle";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {FeaturesEnum} from "../../../../components/FeatureToggle/FeaturesEnum";

interface NavigationContentProps {
    closeBtnClick: () => void
}

export const NavigationContent: React.FC<NavigationContentProps> = (props) => {
    const location = useLocation();
    const userIsTeacher = useSelector(userIsTeacherSelector);
    const userProfileIsEmpty = useSelector(userProfileIsEmptySelector);
    const selectedAgreement = useSelector(selectedAgreementSelector);

    const featureToggle = useMemo(() => container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE), []);
    const selfStudyNavLinkIsAvailable = useMemo(() => featureToggle.featureIsAvailable(FeaturesEnum.SELF_STUDY_TRACKS_NAV_LINK), [featureToggle]);
    
    const userIsModerator = useSelector(({user}: ApplicationState) => user.userIsModerator) as boolean;

    const closeCallBack = useCallback(
        () => {
            props.closeBtnClick()
        },
        [props]
    );

    const studentItems = () => {
        return <Links>
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.MAIN_PAGE}
                    $active={location.pathname === RoutesList.MAIN_PAGE}>
                    <Trans>Главная</Trans>
                </Link>
            </LinkWrapper>
            {/*<LinkWrapper>*/}
            {/*    <Link*/}
            {/*        onClick={closeCallBack}*/}
            {/*        to={RoutesList.SCHEDULE_PAGE}*/}
            {/*        $muted={userProfileIsEmpty}*/}
            {/*        $active={location.pathname === RoutesList.SCHEDULE_PAGE}>*/}
            {/*        <Trans>График занятий</Trans>*/}
            {/*    </Link>*/}
            {/*</LinkWrapper>*/}
            {/*<LinkWrapper>*/}
            {/*    <Link*/}
            {/*        onClick={closeCallBack}*/}
            {/*        to={RoutesList.PERFORMANCE_PAGE}*/}
            {/*        $muted={userProfileIsEmpty}*/}
            {/*        $active={location.pathname === RoutesList.PERFORMANCE_PAGE}>*/}
            {/*        <Trans>Успеваемость</Trans>*/}
            {/*    </Link>*/}
            {/*</LinkWrapper>*/}
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.STUDENT_PAYMENTS_PAGE}
                    $muted={userProfileIsEmpty}
                    $active={location.pathname === RoutesList.STUDENT_PAYMENTS_PAGE}>
                    <Trans>Платежи</Trans>
                </Link>
            </LinkWrapper>
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.ABOUT_SCHOOL_PAGE}
                    $muted={userProfileIsEmpty}
                    $active={location.pathname === RoutesList.ABOUT_SCHOOL_PAGE}>
                    <Trans>О школе</Trans>
                </Link>
            </LinkWrapper>
        </Links>
    };

    const teacherItems = () => {
        return <Links>
            {/*<LinkWrapper>*/}
            {/*    <Link*/}
            {/*        onClick={closeCallBack}*/}
            {/*        to={RoutesList.MAIN_PAGE}*/}
            {/*        $active={location.pathname === RoutesList.MAIN_PAGE}>*/}
            {/*        <Trans>Главная</Trans>*/}
            {/*    </Link>*/}
            {/*</LinkWrapper>*/}
            {
                // Опции модератора
                (userIsModerator) && <LinkWrapper>
                    <Link
                        onClick={closeCallBack}
                        to={RoutesList.TEACHER_MODERATOR}
                        $active={location.pathname === RoutesList.TEACHER_MODERATOR}>
                        <Trans>Управление</Trans>
                    </Link>
                </LinkWrapper>
            }
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.TEACHER_ALL_STUDENTS}
                    $active={location.pathname === RoutesList.TEACHER_ALL_STUDENTS}>
                    <Trans>Ученики</Trans>
                </Link>
            </LinkWrapper>
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.TEACHER_ALL_GROUPS}
                    $active={location.pathname === RoutesList.TEACHER_ALL_GROUPS}>
                    <Trans>Группы и уроки</Trans>
                </Link>
            </LinkWrapper>
            <LinkWrapper>
                <Link
                    onClick={closeCallBack}
                    to={RoutesList.TEACHER_TEACHING_MATERIALS}
                    $muted={userProfileIsEmpty}
                    $active={location.pathname === RoutesList.TEACHER_TEACHING_MATERIALS}>
                    <Trans>Учебные материалы</Trans>
                </Link>
            </LinkWrapper>
            {
                // Временно закрытый раздел self-study треков
                (selfStudyNavLinkIsAvailable) && <LinkWrapper>
                    <Link
                        onClick={closeCallBack}
                        to={RoutesList.TEACHER_SELF_STUDY_TRACKS}
                        $muted={userProfileIsEmpty}
                        $active={location.pathname === RoutesList.TEACHER_SELF_STUDY_TRACKS}>
                        <Trans>Self-study треки</Trans>
                    </Link>
                </LinkWrapper>
            }
        </Links>
    };

    const bottomLink = () => {
        if (userIsTeacher) {
            return <>
                <KnowledgeBaseLink onClick={closeCallBack}/>
                <AboutWinAppLink onClick={closeCallBack}/>
            </>;
        }

        return (selectedAgreement !== null) && <ProgramSelector onClick={closeCallBack}/>;
    }

    return (
        <NavigationContentWrapper>
            <LogoWrapper>
                <LogoIcon>
                    <RouterLink to={RoutesList.MAIN_PAGE}>
                        <Logo width="158px"/>
                    </RouterLink>
                </LogoIcon>
                <BurgerClose onClick={props.closeBtnClick}>
                    <BurgerCloseSvgStyled/>
                </BurgerClose>
            </LogoWrapper>
            <LinksWrapper>
                {
                    (userIsTeacher) ? teacherItems() : studentItems()
                }
            </LinksWrapper>
            <div id={'custom-content-area'}/> {/*Область рендеринга порталов*/}
            {bottomLink()}
        </NavigationContentWrapper>
    );
}
