import React from "react";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../components/Logger/ILogger";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {DtoTmOrganization} from "../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {NoOrganizationsMessage} from "../NoOrganizationsMessage";
import {TmCatalogContextProvider} from "../TmCatalogContext";
import {TmOrganizationSection} from "../organization/TmOrganizationSection";
import {TmDisciplineSection} from "../discipline";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";

enum LoadingState {
    NOT_INIT,
    LOADING_ORGANIZATIONS,
    NO_ORGANIZATIONS,
    ORGANIZATIONS_LOADING_ERROR,
    ORGANIZATION_LOADED
}

interface TeachingMaterialsCatalogProps {
    apiToken: string;
    navigateToLevelMethod?: (toLevelId: string) => void;
    selectMode?: boolean; // если true, то запрещено редактирование
    usedDisciplineIds?: string[]; // отмечаются кружком дисциплины из списка, последняя из списка выделяется флажком
    usedLevelIds?: string[]; // отмечаются кружком уровни из списка, последняя из списка выделяется флажком
}

interface TeachingMaterialsCatalogState {
    loadingState: LoadingState;
    selectedOrganization: DtoTmOrganization | null;
    totalOrganizationsCount: number | null;
}

export class TmOrganizationAndDisciplineSection extends React.Component<TeachingMaterialsCatalogProps, TeachingMaterialsCatalogState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    protected disciplineSectionRef;

    constructor(props: Readonly<TeachingMaterialsCatalogProps> | TeachingMaterialsCatalogProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;
        this.disciplineSectionRef = React.createRef<TmDisciplineSection>();

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            selectedOrganization: null,
            totalOrganizationsCount: null
        }
    }

    componentDidMount() {
        this.fetchOrganizations().then();
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();

            this.abortController = null;
        }
    }

    protected setSelectedOrganization = (newOrganization: DtoTmOrganization | null) => {
        this.setState(() => {
            return {
                selectedOrganization: newOrganization,
                loadingState:
                    (newOrganization === null) ? LoadingState.LOADING_ORGANIZATIONS : LoadingState.ORGANIZATION_LOADED
            }
        }, () => {
            if (newOrganization === null) {
                this.fetchOrganizations().then();
            } else {
                this.disciplineSectionRef.current?.resetList()
            }
        });
    }

    protected fetchOrganizations = async () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING_ORGANIZATIONS
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        try {
            const items = await this.apiClient.tmGetOrganizations(this.props.apiToken, 1, 2, this.abortController);

            if (items.data.list.length === 0) {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.NO_ORGANIZATIONS,
                        totalOrganizationsCount: 0
                    }
                });

                return;
            }

            let organizationIndexForSelect = -1;

            if (items.data.list.length > 0) {
                organizationIndexForSelect = 0;
            }

            if (this.state.selectedOrganization !== null) {
                // Если уже была какая-то организация выбрана - пробуем выбрать её снова

                let indexForSelectedOrg = items.data.list.findIndex(
                    (item) => {
                        if (this.state.selectedOrganization === null) {
                            return false;
                        }

                        return item.id === this.state.selectedOrganization.id;
                    }
                );

                if (indexForSelectedOrg > -1) {
                    organizationIndexForSelect = indexForSelectedOrg;
                }
            }

            if (organizationIndexForSelect === -1) {
                this.setState(() => {
                    return {
                        selectedOrganization: null,
                        loadingState: LoadingState.NO_ORGANIZATIONS,
                        totalOrganizationsCount: 0
                    }
                });

                return;
            }

            this.setState(() => {
                    return {
                        selectedOrganization: items.data.list[organizationIndexForSelect],
                        loadingState: LoadingState.ORGANIZATION_LOADED,
                        totalOrganizationsCount: items.data.totalCount
                    }
                },
                () => {
                    this.disciplineSectionRef.current?.resetList()
                }
            );
        } catch (e) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ORGANIZATIONS_LOADING_ERROR
                }
            });

            if (e instanceof CanceledByUser) {
                return;
            }

            const errorMessage = 'Error loading organizations: ';

            if (e instanceof NoConnection) {
                this.logger.info(LoggerSectionsEnum.TM_ORGANIZATIONS_API, errorMessage, e);
            } else {
                this.logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, errorMessage, e);
            }
        }
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING_ORGANIZATIONS: {
                return <DefaultLoader/>;
            }
            case LoadingState.NO_ORGANIZATIONS: {
                if (this.props.selectMode === true) {
                    return <div>
                        <NoOrganizationsMessage readonly={true}/>
                    </div>
                }

                return <div>
                    <NoOrganizationsMessage readonly={false}/>
                    <br/>
                    <TmOrganizationSection onChange={this.setSelectedOrganization}/>
                </div>
            }
            case LoadingState.ORGANIZATIONS_LOADING_ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.fetchOrganizations}/>;
            }
            case LoadingState.ORGANIZATION_LOADED: {
                if (this.state.selectedOrganization === null) {
                    throw new Error('Catalog in state ORGANIZATION_LOADED but selectedOrganization is null');
                }

                return <div>
                    {
                        (this.state.totalOrganizationsCount !== 1)
                        && <TmOrganizationSection onChange={this.setSelectedOrganization} selectMode={this.props.selectMode}/>
                    }
                    <TmDisciplineSection ref={this.disciplineSectionRef}
                                         apiToken={this.props.apiToken}
                                         selectMode={this.props.selectMode}
                                         usedDisciplineIds={this.props.usedDisciplineIds}
                                         usedLevelIds={this.props.usedLevelIds}
                                         navigateToLevelMethod={this.props.navigateToLevelMethod}
                                         selectedOrganization={this.state.selectedOrganization}/>
                </div>;
            }
            default: {
                throw new Error('Unknown catalog loadingState: ' + JSON.stringify(this.state.loadingState));
            }
        }
    }

    render() {
        return <div>
            <TmCatalogContextProvider
                value={{
                    selectedOrganization: this.state.selectedOrganization,
                    setSelectedOrganization: this.setSelectedOrganization
                }}>
                {this.content()}
            </TmCatalogContextProvider>
        </div>;
    }
}
