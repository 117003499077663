import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {Resizable as ResizableOriginal, Size} from "re-resizable";
import classNames from "classnames";
import {ElementWrapperContext, IElementWrapperContext} from "../../element-wrapper/ElementWrapperContext";
import {UploadComponent} from "./UploadComponent";
import {ElementContextProvider, IElementContext} from "./ElementContext";
import {
    DEFAULT_WIDTH_PX,
    ElementData,
    ElementStateEnum,
    ImageAlignVariants,
    UploadStateList
} from "../../../SlidePlayerEditorCommonParts/components/Image/Common";
import {FileUploadProcessState, UploadProcessDetails} from "../../../../../store/uploadQueue/type";
import {useSelector} from "react-redux";
import {queueStateSelector} from "../../../../../store/uploadQueue/selector";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Image/PresenterComponent";

const ImageElementWrapper = styled.figure`
  margin: 0;
  position: relative;

  &.al-left {
    text-align: left;
  }

  &.al-center {
    text-align: center;
  }

  &.al-right {
    text-align: right;
  }
`;

const Resizable = styled(ResizableOriginal)`
  min-width: 130px;

  &.al-left, &.al-center {
    margin-right: auto;
  }

  &.al-right, &.al-center {
    margin-left: auto;
  }
`;

const ResizeElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  user-select: none;
  width: 1.5rem;
  height: 100%;
  top: 0;
  z-index: ${({theme}) => theme.zIndices.pageContent};

  &.left {
    left: -0.75rem;
    margin-left: -0.75rem;
    padding-left: 0.75rem;
  }

  &.right {
    right: -0.75rem;
    align-items: flex-end;
    margin-right: -0.75rem;
    padding-right: 0.75rem;
  }

  &::after {
    opacity: 0;
    display: flex;
    background-color: rgb(156, 163, 175);
    content: " ";
    width: 3px;
    height: 64px;
    border-radius: 6px;
  }

  &.visible::after {
    opacity: 1;
  }

  &:hover::after {
    background-color: rgba(59, 130, 246);
  }
`;


const ImageResizableContainer = styled.div`
  display: block;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  object-fit: cover;

  &.focused {
    box-shadow: rgb(59 130 249) 0 0 0 1px;
  }
`;

interface ImageProps extends ItemPropsInterface<ElementData> {
}

interface ImageRefMethods extends ItemRefMethodsInterface {
}

export const Image = forwardRef<ImageRefMethods, ImageProps>(
        (props, ref) => {

            const {id, setToolbarConfigById, initialData, onChange} = props;
            const queueState = useSelector(queueStateSelector);
            const [elementState, setElementState] = useState<ElementStateEnum>(ElementStateEnum.WAIT_INIT);
            const elementWrapperContext = useContext<IElementWrapperContext>(ElementWrapperContext);
            const elementWrapperRef = useRef<HTMLDivElement>(null);
            const resizeObjectRef = useRef<ResizableOriginal>(null);

            const elementData = useMemo((): ElementData => {
                if (initialData === null) {
                    return {
                        imageId: null,
                        widthInPercent: DEFAULT_WIDTH_PX,
                        align: ImageAlignVariants.CENTER,
                        heightInPx: null
                    }
                }

                return initialData;
            }, [initialData]);

            const currentFileInUploadQueue = useMemo<UploadProcessDetails | null>(() => {
                if (elementData.imageId === null) {
                    return null
                }

                const itemIndex = queueState.indexByFileId[elementData.imageId];

                if (itemIndex === undefined) {
                    return null;
                }

                return queueState.process[itemIndex] ?? null;
            }, [queueState, elementData]);

            const elementProviderData = useMemo<IElementContext>(() => {
                return {
                    elementData: elementData,
                    elementState: elementState,
                    setElementState: setElementState,
                    onChangeElementData: onChange,
                    currentFileInUploadQueue: currentFileInUploadQueue
                }
            }, [currentFileInUploadQueue, elementData, elementState, onChange]);

            useEffect(() => {
                setToolbarConfigById(id, [
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                        active: elementData.align === ImageAlignVariants.LEFT,
                        disabled: false
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                        active: elementData.align === ImageAlignVariants.CENTER,
                        disabled: false
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                        active: elementData.align === ImageAlignVariants.RIGHT,
                        disabled: false
                    }
                ]);
            }, [elementData, id, setToolbarConfigById]);

            // Методы, доступные родителю
            useImperativeHandle(ref, () => ({
                toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                    let newAlign: null | ImageAlignVariants = null;

                    switch (buttonType) {
                        case ToolbarButtonEnum.ALIGN_LEFT: {
                            newAlign = ImageAlignVariants.LEFT;

                            break;
                        }
                        case ToolbarButtonEnum.ALIGN_CENTER: {
                            newAlign = ImageAlignVariants.CENTER;

                            break;
                        }
                        case ToolbarButtonEnum.ALIGN_RIGHT: {
                            newAlign = ImageAlignVariants.RIGHT;

                            break;
                        }
                    }

                    if (newAlign !== null) {
                        onChange({
                            ...elementData,
                            align: newAlign
                        });
                    }
                },
                getExercisesCount: () => {
                    return 0;
                }
            }));

            useEffect(() => {
                // Обновление статуса компонента
                if (!currentFileInUploadQueue) {
                    if (elementData.imageId !== null) {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    } else {
                        setElementState(ElementStateEnum.NO_PICTURE);
                    }

                    return;
                }

                if (elementState === ElementStateEnum.NO_PICTURE) {
                    return;
                }

                switch (currentFileInUploadQueue.state) {
                    case FileUploadProcessState.WAIT_FOR_START:
                    case FileUploadProcessState.IN_PROCESS: {
                        if (elementState !== ElementStateEnum.UPLOADING_NOW) {
                            setElementState(ElementStateEnum.UPLOADING_NOW);
                            savePictureSize();
                        }

                        break;
                    }
                    case FileUploadProcessState.CANCELLED: {
                        setElementState(ElementStateEnum.NO_PICTURE);
                        // Данные файла и preview обнули UploadComponent

                        break;
                    }
                    case FileUploadProcessState.FAILED: {
                        if (elementState !== ElementStateEnum.UPLOAD_ERROR) {
                            setElementState(ElementStateEnum.UPLOAD_ERROR);
                        }

                        break;
                    }
                    case FileUploadProcessState.SUCCESS: {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [currentFileInUploadQueue]);

            // const setSize = useCallback((newWidth: number, newHeight: number) => {
            //     onChange({
            //         ...elementData,
            //         width: newWidth,
            //         height: newHeight
            //     })
            // }, [elementData, onChange]);

            const savePictureSize = useCallback(() => {
                if ((!resizeObjectRef.current) || (!elementWrapperRef.current)) {
                    return;
                }

                onChange({
                    ...elementData,
                    widthInPercent: Math.round((resizeObjectRef.current.size.width / elementWrapperRef.current.offsetWidth) * 100),
                    heightInPx: resizeObjectRef.current.size.height,
                    widthInPx: resizeObjectRef.current.size.width
                })
            }, [elementData, onChange]);

            const resizableContainerStyles = useMemo<Size>(() => {
                return {
                    width: elementData.widthInPercent.toString(10) + '%',
                    height: '100%'
                };
            }, [elementData.widthInPercent]);


            return <ImageElementWrapper
                ref={elementWrapperRef}
                className={classNames(
                    elementData.align === ImageAlignVariants.LEFT && "al-left",
                    elementData.align === ImageAlignVariants.CENTER && "al-center",
                    elementData.align === ImageAlignVariants.RIGHT && "al-right"
                )}>
                <Resizable size={resizableContainerStyles}
                           maxWidth="100%"
                           lockAspectRatio
                           className={classNames(
                               elementData.align === ImageAlignVariants.LEFT && "al-left",
                               elementData.align === ImageAlignVariants.CENTER && "al-center",
                               elementData.align === ImageAlignVariants.RIGHT && "al-right"
                           )}
                           resizeRatio={elementData.align === ImageAlignVariants.CENTER ? 2 : 1}
                           enable={{
                               left: [ImageAlignVariants.CENTER, ImageAlignVariants.RIGHT].includes(elementData.align),
                               right: [ImageAlignVariants.CENTER, ImageAlignVariants.LEFT].includes(elementData.align),
                           }}
                           handleComponent={{
                               left: (
                                   <ResizeElement
                                       className={
                                           classNames("left", elementWrapperContext.elementInFocus && "visible")
                                       }/>
                               ),
                               right: (
                                   <ResizeElement
                                       className={
                                           classNames("right", elementWrapperContext.elementInFocus && "visible")
                                       }/>
                               ),
                           }}
                           handleStyles={{
                               left: {left: 0},
                               right: {right: 0},
                           }}
                           ref={resizeObjectRef}
                           onResizeStop={(e, direction, ref) => savePictureSize()}
                >
                    <ImageResizableContainer className={
                        classNames(elementWrapperContext.elementInFocus && "focused")
                    }>
                        <ElementContextProvider value={elementProviderData}>
                            {(UploadStateList.includes(elementState)) ? <UploadComponent/> :
                                <PresenterComponent elementData={elementData}/>}
                        </ElementContextProvider>
                    </ImageResizableContainer>
                </Resizable>
            </ImageElementWrapper>;
        }
    )
;