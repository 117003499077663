import * as React from "react";
import {Slider as AntSlider} from "antd";
import {SliderSingleProps} from "antd/lib/slider";
import styled from "styled-components";
import classNames from "classnames";

export enum SliderAccentColorVairants {
    STUDENT_RATE_PARAM1,
    STUDENT_RATE_PARAM2,
    STUDENT_RATE_PARAM3,
    STUDENT_RATE_PARAM4,
    STUDENT_RATE_PARAM5,
}

export const SliderStyled = styled(AntSlider)`
  margin: 10px 0;

  .ant-slider-rail, .ant-slider-track {
    height: 6px;
  }

  .ant-slider-handle {
    width: 18px;
    height: 18px;
    margin-top: -6px;

    &:focus {
      box-shadow: none;
    }
  }

  &.color-var-1 {
    .ant-slider-handle {
      border-color: ${({theme}) => theme.colors.studentRateParam1Color};
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${({theme}) => theme.colors.studentRateParam1Color};
    }


    .ant-slider-track {
      background-color: ${({theme}) => theme.colors.studentRateParam1Color};
    }

    .ant-slider-rail {
      transition: opacity 0.3s ease;
      opacity: 0.3;
      background-color: ${({theme}) => theme.colors.studentRateParam1Color};
    }

    &:hover .ant-slider-rail {
      opacity: 0.5;
      background-color: ${({theme}) => theme.colors.studentRateParam1Color};
    }
  }

  &.color-var-2 {
    .ant-slider-handle {
      border-color: ${({theme}) => theme.colors.studentRateParam2Color};
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${({theme}) => theme.colors.studentRateParam2Color};
    }


    .ant-slider-track {
      background-color: ${({theme}) => theme.colors.studentRateParam2Color};
    }

    .ant-slider-rail {
      transition: opacity 0.3s ease;
      opacity: 0.3;
      background-color: ${({theme}) => theme.colors.studentRateParam2Color};
    }

    &:hover .ant-slider-rail {
      opacity: 0.5;
      background-color: ${({theme}) => theme.colors.studentRateParam2Color};
    }
  }

  &.color-var-3 {
    .ant-slider-handle {
      border-color: ${({theme}) => theme.colors.studentRateParam3Color};
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${({theme}) => theme.colors.studentRateParam3Color};
    }


    .ant-slider-track {
      background-color: ${({theme}) => theme.colors.studentRateParam3Color};
    }

    .ant-slider-rail {
      transition: opacity 0.3s ease;
      opacity: 0.3;
      background-color: ${({theme}) => theme.colors.studentRateParam3Color};
    }

    &:hover .ant-slider-rail {
      opacity: 0.5;
      background-color: ${({theme}) => theme.colors.studentRateParam3Color};
    }
  }

  &.color-var-4 {
    .ant-slider-handle {
      border-color: ${({theme}) => theme.colors.studentRateParam4Color};
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${({theme}) => theme.colors.studentRateParam4Color};
    }


    .ant-slider-track {
      background-color: ${({theme}) => theme.colors.studentRateParam4Color};
    }

    .ant-slider-rail {
      transition: opacity 0.3s ease;
      opacity: 0.3;
      background-color: ${({theme}) => theme.colors.studentRateParam4Color};
    }

    &:hover .ant-slider-rail {
      opacity: 0.5;
      background-color: ${({theme}) => theme.colors.studentRateParam4Color};
    }
  }

  &.color-var-5 {
    .ant-slider-handle {
      border-color: ${({theme}) => theme.colors.studentRateParam5Color};
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: ${({theme}) => theme.colors.studentRateParam5Color};
    }


    .ant-slider-track {
      background-color: ${({theme}) => theme.colors.studentRateParam5Color};
    }

    .ant-slider-rail {
      transition: opacity 0.3s ease;
      opacity: 0.3;
      background-color: ${({theme}) => theme.colors.studentRateParam5Color};
    }

    &:hover .ant-slider-rail {
      opacity: 0.5;
      background-color: ${({theme}) => theme.colors.studentRateParam5Color};
    }
  }
`;

interface SliderProps extends SliderSingleProps {
    accentColorVariant?: SliderAccentColorVairants;
}

export const Slider: React.FunctionComponent<SliderProps> = ({accentColorVariant, ...props}) => {
    return <SliderStyled
        className={classNames(
            (accentColorVariant === SliderAccentColorVairants.STUDENT_RATE_PARAM1) && "color-var-1",
            (accentColorVariant === SliderAccentColorVairants.STUDENT_RATE_PARAM2) && "color-var-2",
            (accentColorVariant === SliderAccentColorVairants.STUDENT_RATE_PARAM3) && "color-var-3",
            (accentColorVariant === SliderAccentColorVairants.STUDENT_RATE_PARAM4) && "color-var-4",
            (accentColorVariant === SliderAccentColorVairants.STUDENT_RATE_PARAM5) && "color-var-5"
        )}
        {...props} />;
}