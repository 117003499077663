import styled from "styled-components";
import React from "react";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ElementName} from "../../SlidePlayerEditorCommonParts/ElementName";
import classNames from "classnames";

const ElementNamePanelStyled = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.textTertiary};
  color: ${({theme}) => theme.colors.textSecondary};
  border-style: solid solid dotted solid;
  position: absolute;
  top: 0;
  left: -1px;
  transform: translateY(-100%);
  padding: 3px 5px;
  border-radius: 10px 10px 0 0;
  font-size: 12px;

  &.controlsBottom:not(.always-visible) {
    top: auto;
    bottom: 0;
    transform: translateY(+100%);
    z-index: ${({theme}) => theme.zIndices.pageContent};
    border-radius: 0 0 10px 10px;
    border-style: dotted solid solid solid;
  }
  
  &.always-visible {
    position: initial;
    transform: none;
    border: none;
  }
`;

interface ElementControlPanelProps {
    elementType: ElementTypeEnum;
    className?: string;
    alwaysVisible?: boolean;
}

export const ElementNamePanel: React.FC<ElementControlPanelProps> = (props) => {
    return <ElementNamePanelStyled className={classNames(props.className, props.alwaysVisible && "always-visible")}>
        <ElementName elementType={props.elementType}/>
    </ElementNamePanelStyled>;
}