import {IsNotEmpty, Max, Min} from "class-validator";
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO для запроса списка событий из истории событий пользователя.
 */
export class StreamHistoryRequestDto implements IBaseApiDto {
    @IsNotEmpty()
    afterTs: string;

    @IsNotEmpty()
    @Min(0)
    offset: number;

    @IsNotEmpty()
    @Min(1)
    @Max(100)
    limit: number;
}
