import {
    FooterMode,
    Modal,
    ModalChildProps,
    ModalControlParams
} from "../../../../../../../components/Ui/Elements/Modal";
import React, {useMemo, useRef, useState} from "react";
import {Form, FormItem, useForm} from '../../../../../../../components/Ui/Elements/Form';
import {BtnStyleEnum, Button} from "../../../../../../../components/Ui/Elements/Button";
import {Input} from "../../../../../../../components/Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {InputLabel, ModalSubTitle, ModalTitle} from "../../../../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../../../../../components/Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../../../store/app/selector";
import {ILogger} from "../../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../../../../../components/Ui/Elements/ConfirmDialog";
import {DtoTmSlide} from "../../../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {RadioGroup, RadioInput} from "../../../../../../../components/Ui/Elements/RadioInput";
import {Space} from "antd";
import {selectedSchoolActualParams, selectedSchoolActualParamsCount} from "../../../../../../../../store/user/selector";

export enum ModalResultType {
    CREATED,
    UPDATED,
    DELETED
}

export enum SlideType {
    REGULAR = 1,
    ADDITIONAL = 2,
    FOR_HOMEWORK = 3
}

interface TmSlideModalProps extends ModalChildProps {
    lessonId: string;
    slideItem: DtoTmSlide | null;
    result: (action: ModalResultType, item: DtoTmSlide | null) => void;
}

export const TmSlideModal: React.FC<TmSlideModalProps> = (props) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const actualParams = useSelector(selectedSchoolActualParams);
    const actualParamsCount = useSelector(selectedSchoolActualParamsCount);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        setCloseAllowed(false);
        setSaveInProcess(true);

        if (values.actualParamNum === undefined) {
            values.actualParamNum = 1;
        }

        const promise = (props.slideItem) ?
            httpApiClient.tmUpdateSlide(
                sessionToken,
                props.slideItem.id,
                values.name,
                values.slideType === SlideType.ADDITIONAL,
                values.slideType === SlideType.FOR_HOMEWORK,
                values.actualParamNum
            )
            : httpApiClient.tmCreateSlide(
                sessionToken,
                props.lessonId,
                values.name,
                values.slideType === SlideType.ADDITIONAL,
                values.slideType === SlideType.FOR_HOMEWORK,
                values.actualParamNum
            );

        promise
            .then((data) => {
                props.result(
                    (props.slideItem) ? ModalResultType.UPDATED : ModalResultType.CREATED,
                    data.data
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (props.slideItem) {
                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on update slide: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on create slide: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const slideTypeRadioGroupValue = useMemo<SlideType>(() => {
        if (props.slideItem === null) {
            return SlideType.REGULAR;
        }

        if (props.slideItem.forHomework) {
            return SlideType.FOR_HOMEWORK;
        }

        if (props.slideItem.isAdditional) {
            return SlideType.ADDITIONAL;
        }

        return SlideType.REGULAR;
    }, [props.slideItem]);

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef) || (!props.slideItem)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteSlide(
                sessionToken,
                props.slideItem.id
            )
        } catch (error) {
            logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on delete slide: ", error);

            throw error;
        }

        props.result(
            ModalResultType.DELETED,
            null
        );

        modalRef?.current?.close();
    }

    const footerContent = (controls: ModalControlParams) => {
        if (props.slideItem === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Создать слайд</Trans>
                </Button>
            </div>;
        }

        // Если сейчас мы редактируем слайд
        return <>
            <ConfirmDialog trigger={
                <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                    <Trans>Удалить</Trans>
                </Button>
            }
                           okText={t`Удалить`}
                           cancelText={t`Отмена`}
                           title={t`Удалить слайд?`}
                           text={t`Вы уверены, что нужно удалить слайд ${props.slideItem.name}?`}
                           errorText={t`Не удалось удалить информацию`}
                           okMethod={onDelete}/>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>;
    }

    const actualParamsField = useMemo<JSX.Element | undefined>(() => {
        if ((actualParamsCount === null) || (actualParamsCount === 1) || (actualParams === null)) {
            return;
        }

        let initialValue = 1;

        if (props.slideItem !== null) {
            if (props.slideItem.actualParamNum <= actualParamsCount) {
                initialValue = props.slideItem.actualParamNum;
            }
        }

        return <>
            <br/>
            <InputLabel><Trans>Оцениваемый навык (при наличии на слайде упражнений)</Trans></InputLabel><br/>
            <FormItem
                style={{marginBottom: "10px"}}
                name={`actualParamNum`}
                initialValue={initialValue}>
                <RadioGroup>
                    <Space direction={"vertical"}>
                        <RadioInput value={1}>{actualParams.first}</RadioInput>
                        <RadioInput value={2}>{actualParams.second}</RadioInput>
                        {
                            (actualParams.third !== null)
                            && <RadioInput value={3}>{actualParams.third}</RadioInput>
                        }
                        {
                            (actualParams.fourth !== null)
                            &&
                            <RadioInput value={4}>{actualParams.fourth}</RadioInput>
                        }
                        {
                            (actualParams.fifth !== null)
                            && <RadioInput value={5}>{actualParams.fifth}</RadioInput>
                        }
                    </Space>
                </RadioGroup>
            </FormItem>
        </>
    }, [actualParams, actualParamsCount, props.slideItem]);

    return <Modal innerRef={modalRef}
                  trigger={props.trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      setCloseAllowed(true);
                      setSaveInProcess(false);

                      form.resetFields();
                  }}
                  footer={footerContent}
                  footerMode={(props.slideItem) ? FooterMode.SPACE_BETWEEN : FooterMode.DEFAULT}
                  children={
                      (controls) => {
                          return <div>
                              <ModalTitle>
                                  {
                                      (props.slideItem === null)
                                          ? <Trans>Создать слайд</Trans>
                                          : <Trans>Редактировать слайд</Trans>
                                  }
                              </ModalTitle>
                              <ModalSubTitle> </ModalSubTitle>
                              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={`name`}
                                      initialValue={props.slideItem?.name}
                                      rules={[
                                          {
                                              required: true,
                                              message: t`Необходимо указать название слайда`,
                                          },
                                          {
                                              type: "string",
                                              min: 3,
                                              message: t`Название должно быть не короче трёх символов`
                                          },
                                          {
                                              type: "string",
                                              max: 255,
                                              message: t`Название должно быть не длиннее 255 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                          placeholder={'Название слайда'}/>
                                  </FormItem>
                                  <InputLabel><Trans>Применение на занятиях</Trans></InputLabel><br/>
                                  <FormItem
                                      style={{marginBottom: "10px"}}
                                      name={`slideType`}
                                      initialValue={slideTypeRadioGroupValue}>
                                      <RadioGroup>
                                          <Space direction={"vertical"}>
                                              <RadioInput value={SlideType.REGULAR}>
                                                  <Trans>Обычный слайд для занятия на уроке</Trans>
                                              </RadioInput>
                                              <RadioInput value={SlideType.ADDITIONAL}>
                                                  <Trans>Дополнительный слайд, изначально скрытый для учеников</Trans>
                                              </RadioInput>
                                              <RadioInput value={SlideType.FOR_HOMEWORK}>
                                                  <Trans>Слайд для домашней работы</Trans>
                                              </RadioInput>
                                          </Space>
                                      </RadioGroup>
                                  </FormItem>
                                  {actualParamsField}
                              </Form>
                          </div>
                      }
                  }
    />
}
