import {instanceCachingFactory} from 'tsyringe';
import {DiTokens} from "./DiTokens";
import {ILocaleDictionaryDownloader} from "../components/LocaleDictionaryDownloader/ILocaleDictionaryDownloader";
import {LocaleDictionaryDownloader} from "../components/LocaleDictionaryDownloader/LocaleDictionaryDownloader";
import {IHttpClient} from "../components/HttpClient/IHttpClient";
import {IAppVersionHelper} from "../components/AppVersionHelper/IAppVersionHelper";

export default instanceCachingFactory<ILocaleDictionaryDownloader>((container) => {
    const httpClient = container.resolve<IHttpClient>(DiTokens.HTTP_CLIENT) as IHttpClient;
    const appVersionHelper = container.resolve<IAppVersionHelper>(DiTokens.APP_VERSION_HELPER) as IAppVersionHelper;

    return new LocaleDictionaryDownloader(httpClient, appVersionHelper);
});
