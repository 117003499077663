import {instanceCachingFactory} from 'tsyringe';
import {IStreamEventService} from "../services/stream-event-service/IStreamEventService";
import {StreamEventService} from "../services/stream-event-service/StreamEventService";
import {StreamEventRouter} from "../services/stream-event-service/StreamEventRouter";
import {streamEventRoutes} from "../config/StreamEventRoutes";

export default instanceCachingFactory<IStreamEventService>((container) => {
    return new StreamEventService(
        new StreamEventRouter(streamEventRoutes)
    );
});
