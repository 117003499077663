import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {RichTextEditor} from "../../components/text-editor/RichTextEditor";
import {RichTextEditorMode} from "../../components/text-editor/Enums";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {t} from "@lingui/macro";

const ExerciseCorrectExistInInputStyle = styled.div`
`

interface ExerciseCorrectExistInInputProps extends ItemPropsInterface<any> {
}

interface ExerciseCorrectExistInInputRefMethods extends ItemRefMethodsInterface {
}

export const ExerciseCorrectExistInInput = forwardRef<ExerciseCorrectExistInInputRefMethods, ExerciseCorrectExistInInputProps>(
    (props, ref) => {

        const richTextEditorRef = useRef<RichTextEditor>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                richTextEditorRef.current?.toolbarItemOnToggle(buttonType, newValue);
            },
            getExercisesCount: () => {
                if (!richTextEditorRef.current) {
                    return 0;
                }

                return richTextEditorRef.current?.getExercisesCount();
            }
        }));

        return <ExerciseCorrectExistInInputStyle>
            <RichTextEditor editorMode={RichTextEditorMode.CORRECT_EXIST_IN_INPUT}
                            placeHolderText={t`Упражнение "Исправить текст"`}
                            setToolbarConfigById={props.setToolbarConfigById}
                            ref={richTextEditorRef}
                            onChange={props.onChange}
                            initialData={props.initialData}
                            id={props.id}
            />
        </ExerciseCorrectExistInInputStyle>;
    }
);