import styled from "styled-components";
import {ElementProps} from "../rich-text-editor/Element";
import React, {CSSProperties} from "react";

const RadioListStyled = styled.ol`
  list-style: none;
  padding-left: 50px;
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const RadioList: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        children
    } = props;

    return <RadioListStyled style={style} {...attributes}>
        {children}
    </RadioListStyled>;
}