import styled from "styled-components";
import React from "react";
import {Trans} from "@lingui/macro";
import Energy32 from "../../../../../../../components/Ui/Png/Energy32@2x.png";

const RewardWrapper = styled.div`
    color: ${({theme}) => theme.colors.textSecondary};
    font-size: 14px;

    @media (${({theme}) => theme.media.small}) {
        font-size: 16px;
    }
`;

const RewardEnergyText = styled.span`
    vertical-align: middle;
    display: inline-block;
`;

const RewardEnergyIcon = styled.img`
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: middle;
`;

const RewardEnergyTextValue = styled.span`
    color: ${({theme}) => theme.colors.textPrimary};
`;

interface RewardTextProps {
    className?: string;
}

export const RewardText: React.FC<RewardTextProps> = ({className}) => {
    return <RewardWrapper className={className}>
        <RewardEnergyText>
            <Trans>Награда </Trans>&nbsp;&nbsp;
        </RewardEnergyText>
        <RewardEnergyIcon src={Energy32} alt={'Energy icon'}/>&nbsp;
        <RewardEnergyText>
            <RewardEnergyTextValue>1</RewardEnergyTextValue>
        </RewardEnergyText>
    </RewardWrapper>
}