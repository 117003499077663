import { ISentryLogger } from './ISentryLogger';
import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";

export class SentryLogger implements ISentryLogger {

  isInitialized: boolean;

  constructor() {
    this.isInitialized = false;
  }

  /**
   * @inheritDoc
   */
  init(sentryDsn: string): void {
    Sentry.init({
      dsn: sentryDsn,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new BrowserTracing(),
        new CaptureConsoleIntegration(
            {
              // array of methods that should be captured
              // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
              levels: ['warn', 'error']
            }
        ),
        new Sentry.Replay()
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });

    this.isInitialized = true;
  }

  /**
   * @inheritDoc
   */
  isReady(): boolean {
    return this.isInitialized;
  }

  /**
   * @inheritDoc
   */
  setUserInfo(userId: string): void {
    if (!this.isInitialized) {
      throw new Error('Sentry is not ready');
    }

    Sentry.setUser({
      id: userId
    });
  }
}
