import React, {RefObject} from "react";
import {DtoTmLesson} from "../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {DtoTmSlide} from "../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {PopupActions} from "reactjs-popup/dist/types";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {FooterMode, Modal, ModalControlParams} from "../../Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";
import {PageSubtitle2, RegularTextCss} from "../../../styles/global-elements";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../Ui/Elements/NoticeBlock";
import {ReactComponent as AddSlideIcon} from "../../Ui/Svg/AddSlide.svg";
import {ReactComponent as SlideAddedIcon} from "../../Ui/Svg/SlideAdded.svg";
import {ReactComponent as PreviewSlideIcon} from "../../Ui/Svg/Preview.svg";
import classNames from "classnames";
import {SlideIndexIconForTeacher, TypesEnum} from "../../Ui/Elements/SlideIndexForTeacher";
import {SlidePreviewModal} from "../slide-preview-modal";

const Wrapper = styled.div``;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

const SlidesList = styled.ul`
  padding: 0;
`;

const SlideItem = styled.li`
  display: flex;
  flex-direction: row;
`;

const SlideIconWrapper = styled.div`
  ${RegularTextCss};

  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.9;
`;

const SlideNameWrapper = styled.div`
  ${RegularTextCss};

  flex-grow: 1;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
  }
`;

const SlideButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SlideButtonWrapper = styled.div`
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:not(.disabled) {
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

type DtoTmSlideWithImageTypeAndIndex = DtoTmSlide & {
    index: number | null;
    imageType: TypesEnum | null;
}

interface TmSlideListModalProps {
    apiToken: string;
    tmLesson: DtoTmLesson;
    selectedSlides?: string[];
    onSelectSlide: (tmSlide: DtoTmSlide) => void;
}

interface TmSlideListModalState {
    loadingState: LoadingState;
    slideList: DtoTmSlideWithImageTypeAndIndex[];
    slideForPreview: DtoTmSlide | null;
}

export class TmSlideListModal extends React.Component<TmSlideListModalProps, TmSlideListModalState> {
    protected apiClient: IHttpApiClient;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    protected modalRef: RefObject<PopupActions>;

    protected modalPreviewSlide: RefObject<SlidePreviewModal>;

    constructor(props: Readonly<TmSlideListModalProps> | TmSlideListModalProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            slideList: [],

            slideForPreview: null
        };

        this.modalRef = React.createRef<PopupActions>();
        this.modalPreviewSlide = React.createRef<SlidePreviewModal>();
    }

    public open = () => {
        if (this.modalRef.current) {
            this.modalRef.current.open();

            this.fetchItems();
        }
    }

    public close = () => {
        if (this.modalRef.current) {
            this.modalRef.current.close();
        }
    }

    protected onClose = () => {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }
    }

    protected fetchItems = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING,
                slideList: []
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.tmGetSlides(
            this.props.apiToken,
            this.props.tmLesson.id,
            1,
            200,
            this.abortController
        )
            .then((slides) => {
                let tempIndex = 1;

                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                        slideList: slides.data.list.map((item): DtoTmSlideWithImageTypeAndIndex => {
                            let imageType: TypesEnum | null = null;
                            let index: number | null = null;

                            if (item.forHomework) {
                                imageType = TypesEnum.HOMEWORK_ICON
                            } else if (item.isAdditional) {
                                imageType = TypesEnum.ADDITIONAL_ICON
                            } else {
                                index = tempIndex;

                                tempIndex++;
                            }

                            return {
                                ...item,
                                imageType: imageType,
                                index: index
                            }
                        })
                    }
                })
            })
            .catch((error) => {
                this.logger.error(
                    LoggerSectionsEnum.TM_SLIDES_API,
                    "Error on fetch tmSlides for tmLesson: " + this.props.tmLesson.id,
                    error
                );

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }

    protected slideItemPreviewOnSelect = (item: DtoTmSlide) => {
        this.setState(
            () => {
                return {
                    slideForPreview: item
                }
            },
            () => {
                if (this.modalPreviewSlide.current !== null) {
                    this.modalPreviewSlide.current.open();
                }
            }
        )
    }

    protected slidesList = () => {
        return <SlidesList>
            {
                this.state.slideList.map((item) => {
                    let slideAlreadyExist = false;

                    if ((this.props.selectedSlides !== undefined) && (this.props.selectedSlides.length > 0)) {
                        slideAlreadyExist = this.props.selectedSlides.indexOf(item.id) > -1;
                    }

                    return <SlideItem key={item.id}>
                        <SlideIconWrapper>{
                            (item.index !== null) ? item.index.toString(10) :
                                <SlideIndexIconForTeacher type={item.imageType as TypesEnum}/>
                        }</SlideIconWrapper>
                        <SlideNameWrapper onClick={() => this.props.onSelectSlide(item)}
                                          className={classNames(slideAlreadyExist && "disabled")}>
                            {item.name}
                        </SlideNameWrapper>
                        <SlideButtonsWrapper>
                            <SlideButtonWrapper onClick={() => this.props.onSelectSlide(item)}
                                                className={classNames(slideAlreadyExist && "disabled")}>
                                {
                                    (slideAlreadyExist) ? <SlideAddedIcon/> : <AddSlideIcon/>
                                }
                            </SlideButtonWrapper>
                            <SlideButtonWrapper onClick={() => this.slideItemPreviewOnSelect(item)}>
                                <PreviewSlideIcon/>
                            </SlideButtonWrapper>
                        </SlideButtonsWrapper>
                    </SlideItem>
                })
            }
        </SlidesList>
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <Wrapper>
                    <DefaultLoader/>
                </Wrapper>;
            }
            case LoadingState.ERROR: {
                return <Wrapper>
                    <ErrorLoadingContent retryBtnClick={this.fetchItems}/>
                </Wrapper>
            }
            case LoadingState.SUCCESS: {
                if (this.state.slideList.length === 0) {
                    return <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Слайды не созданы</Trans></NoticeBlockTitle>
                            <NoticeBlockText><Trans>Создать слайды можно, перейдя в раздел «Учебные
                                материалы»</Trans></NoticeBlockText>
                        </>
                    </NoticeBlock>
                }

                return <Wrapper>{this.slidesList()}</Wrapper>;
            }
            default: {
                throw new Error('Unknown loading state in select tmSlide modal');
            }
        }
    }

    protected modalContent = (controls: ModalControlParams) => {
        return <Wrapper>
            <Title><Trans>Слайды урока</Trans>&nbsp;«{this.props.tmLesson.name}»:</Title>
            {this.content()}
            {
                (this.state.slideForPreview !== null)
                && <SlidePreviewModal ref={this.modalPreviewSlide}
                                      tmSlide={this.state.slideForPreview}
                                      apiToken={this.props.apiToken}
                                      slideContentVersion={this.state.slideForPreview.currentVersion}/>
            }
        </Wrapper>;
    }

    render() {
        return <Modal innerRef={this.modalRef}
                      closeAllowed={true}
                      onClose={() => this.setState(() => {
                          return {loadingState: LoadingState.NOT_INIT}
                      })}
                      footer={(controls: ModalControlParams) => {
                          return <Button btnStyle={BtnStyleEnum.Secondary}
                                         onClick={controls.closeModal}>
                              <Trans>Закрыть</Trans>
                          </Button>
                      }}
                      footerMode={FooterMode.DEFAULT}
                      children={this.modalContent}/>
    }
}