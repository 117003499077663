import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (${({theme}) => theme.media.large}) {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  text-align: center;
  margin-bottom: 23px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 60px;
  }

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 0;
    margin-right: 100px;
  }
`;

export const TextWrapper = styled.div`
  text-align: left;
`;

export const Title = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 24px;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.small}) {
    font-size: 32px;
  }

  @media (${({theme}) => theme.media.medium}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const TextNotice = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border-left: 4px solid #FFC400;
  padding-left: 16px;
  margin-bottom: 40px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const Subtitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 24px;
    line-height: 36px;
  }
`;

export const ItemsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  width: 200px;

  @media (${({theme}) => theme.media.small}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
    grid-row-gap: 10px;
  }
`;

export const Item = styled.li`
  margin-bottom: 20px;
  
  @media (${({theme}) => theme.media.medium}) {
    max-width: 160px;
  }
`;

export const ItemIconWrapper = styled.div`
  display: block;
  opacity: 0.5;
`;

export const ItemIconText = styled.div`
  display: block;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: ${({theme}) => theme.colors.textPrimary};
`;