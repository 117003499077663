import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {ModeSelector} from "./ModeSelector";
import {SlidesList} from "./slides-list";
import {ElementsPalette} from "../../../../../components/SlideEditor/elements-palette";
import {ElementTypeEnum} from "../../../../../components/SlidePlayerEditorCommonParts/ElementTypeEnum";
import {BlockWithBorderCss} from "../../../../../styles/global-elements";
import classNames from "classnames";
import {ContentWidthWrapperCss} from "../../../../layout/styles";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../store";
import {ITheme} from "../../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../../services/hooks/useMediaQuery";
import {EditorContext, IEditorContext} from "../EditorContext";

const EditorNavigationStyled = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 70px;
  max-height: calc(100% - ${({theme}) => theme.size.headerHeight.toString(10) + 'px'});
  transform: translateY(100%) translateY(-70px);
  transition: transform 0.3s ease;
  user-select: none;
  z-index: ${({theme}) => theme.zIndices.bottomPageNavigation};

  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};

  &.deployed {
    transform: translateY(0);

    @media (${({theme}) => theme.media.large}) {
      transform: unset;
    }
  }

  @media (${({theme}) => theme.media.small}) {
    min-height: 65px;
    transform: translateY(100%) translateY(-65px);
  }

  @media (${({theme}) => theme.media.large}) {
    z-index: unset;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: unset;
    transition: unset;
    border-top: none;
  }
`;

const EditorLeftSideWrapper = styled.div`
  ${ContentWidthWrapperCss};
  margin: auto;
  padding: 0 20px 16px 20px;


  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  @media (${({theme}) => theme.media.small}) {
    padding: 0 0 16px 0;
  }

  @media (${({theme}) => theme.media.large}) {
    ${BlockWithBorderCss};
    padding: 10px 0 0 0;
    margin: 0;
    display: block;
    position: sticky;
    overflow: unset;
    max-height: unset;
    top: ${({theme}) => 100 + theme.size.headerHeight}px;
  };
`

const ContentWrapper = styled.div`
  max-height: calc(100vh - 400px);
  overflow: auto;
  padding: 0 32px 40px 32px;
`;

export enum ModesList {
    NO_ACTIVE_MODE,
    SLIDES_LIST,
    ELEMENTS_PALETTE
}

export interface EditorNavigationSideProps {
    className?: string;
    paletteElementOnSelect: (elementType: ElementTypeEnum) => void;
}

export const EditorNavigationSide: React.FC<EditorNavigationSideProps> = (props) => {
    const [deployed, setDeployed] = useState<boolean>(false);
    const [activeMode, setActiveMode] = useState<ModesList>(ModesList.NO_ACTIVE_MODE);

    const editorContext = useContext<IEditorContext>(EditorContext);
    const {lessonId, selectedSlideId} = {...editorContext};

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;
    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    useEffect(() => {
        if (isLarge) {
            if (deployed) {
                setDeployed(false);
            }

            // Переходим в состояние широкого экрана
            if (activeMode === ModesList.NO_ACTIVE_MODE) {
                setActiveMode(ModesList.SLIDES_LIST);
            }
        } else {
            // Переходим в состояние узкого экрана
            if (activeMode !== ModesList.NO_ACTIVE_MODE) {
                setActiveMode(ModesList.NO_ACTIVE_MODE);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLarge]);

    const setActiveItemProc = useCallback((newMode: ModesList) => {
        if (isLarge) {
            setActiveMode(newMode);
        }

        if (activeMode === newMode) {
            // Если нажата кнопка активного режима
            if (deployed) {
                setDeployed(false);
                setActiveMode(ModesList.NO_ACTIVE_MODE);
            }

            return;
        }

        if (activeMode === ModesList.NO_ACTIVE_MODE) {
            if (!deployed) {
                setDeployed(true);
            }
        }

        setActiveMode(newMode);
    }, [activeMode, deployed, isLarge]);

    useEffect(() => {
        if (!isLarge) {
            setDeployed(false);
            setActiveMode(ModesList.NO_ACTIVE_MODE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lessonId, selectedSlideId]);

    const onPaletteItemSelect = useCallback((itemType: ElementTypeEnum) => {
        props.paletteElementOnSelect(itemType);

        if (!isLarge) {
            setDeployed(false);
            setActiveMode(ModesList.NO_ACTIVE_MODE);
        }
    }, [isLarge, props]);

    return <EditorNavigationStyled className={classNames(props.className, deployed && "deployed")}>
        <EditorLeftSideWrapper>
            <ModeSelector activeMode={activeMode} setActiveItem={setActiveItemProc}/>
            <ContentWrapper>
                <SlidesList style={{display: (activeMode === ModesList.SLIDES_LIST) ? 'block' : 'none'}}/>
                <ElementsPalette style={{display: (activeMode === ModesList.ELEMENTS_PALETTE) ? 'block' : 'none'}}
                                 onElementSelect={onPaletteItemSelect}
                />
            </ContentWrapper>
        </EditorLeftSideWrapper>
    </EditorNavigationStyled>;
}