import React, {RefObject} from "react";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {
    DtoGetUsedInGroupTmItemsResponse
} from "../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoGetUsedInGroupTmItemsResponse";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {FooterMode, Modal, ModalControlParams} from "../../Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {TmOrganizationAndDisciplineSection} from "../organization-and-discipline";
import {PopupActions} from "reactjs-popup/dist/types";
import {TmLevelDetailsSection} from "../level-details";
import {TmSectionDetailsSection} from "../section-details";
import {PageBackLink} from "../../Ui/Elements/PageBackLink";
import {DtoTmLesson} from "../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {ReactComponent as SmallCircleOutlineIcon} from "../../Ui/Svg/SmallCircleOutline.svg";
import {ReactComponent as CheckIcon} from "../../Ui/Svg/Check.svg";
import {SlideSelectorConfig} from "../section-details/LessonsList";

const Wrapper = styled.div``;
const Legend = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  opacity: 0.7;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const LegendItemIconWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LegendItemTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 8px;
`;

enum ModalShowMode {
    ORGANIZATIONS_AND_DISCIPLINE,
    LEVEL,
    SECTION
}

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

interface SelectMaterialsModalProps {
    apiToken: string;
    stLessonId: number;
    stGroupId: number;
    alreadySelectedSlideIds: string[];
    addTmLessonToLesson: (tmLessonItem: DtoTmLesson) => void;
    slideSelector?: SlideSelectorConfig;
}

interface SelectMaterialsModalState {
    loadingState: LoadingState;

    showMode: ModalShowMode;
    selectedOrganizationId: string | null;
    selectedDisciplineId: string | null;
    selectedLevelId: string | null;
    selectedSectionId: string | null;

    usedInGroupItems: DtoGetUsedInGroupTmItemsResponse | null;
    modalCloseAllowed: boolean;
}

export class SelectMaterialsModal extends React.Component<SelectMaterialsModalProps, SelectMaterialsModalState> {
    protected apiClient: IHttpApiClient;
    protected logger: ILogger;
    protected abortController: AbortController | null;

    protected modalRef: RefObject<PopupActions>;

    constructor(props: Readonly<SelectMaterialsModalProps> | SelectMaterialsModalProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.modalRef = React.createRef();

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            showMode: ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE,

            selectedOrganizationId: null,
            selectedDisciplineId: null,
            selectedLevelId: null,
            selectedSectionId: null,

            usedInGroupItems: null,
            modalCloseAllowed: true
        }

        this.abortController = null;
    }

    public open = () => {
        if (this.modalRef.current) {
            this.modalRef.current.open();

            this.fetchUsedInGroupItems();
        }
    }

    public close = () => {
        if (this.modalRef.current) {
            this.modalRef.current.close();
        }
    }

    protected onClose = () => {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }
    }

    protected fetchUsedInGroupItems = () => {
        if (this.props.apiToken === null) {
            return null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.getUsedInGroupTmItems(this.props.apiToken, this.props.stGroupId, this.abortController)
            .then((data) => {
                if (data.data.disciplines.length === 0) {
                    this.setState(() => {
                        return {
                            loadingState: LoadingState.SUCCESS,
                            usedInGroupItems: data.data
                        }
                    })
                } else {
                    this.setState(() => {
                        return {
                            loadingState: LoadingState.SUCCESS,
                            usedInGroupItems: data.data,
                            selectedSectionId: data.data.sections[data.data.sections.length - 1].id,
                            selectedLevelId: data.data.levels[data.data.levels.length - 1].id,
                            selectedDisciplineId: data.data.disciplines[data.data.disciplines.length - 1].id,
                            selectedOrganizationId: data.data.organizations[data.data.organizations.length - 1].id,
                            showMode: ModalShowMode.SECTION
                        }
                    });
                }
            })
            .catch((error) => {
                this.logger.error(
                    LoggerSectionsEnum.LESSON_MATERIALS,
                    "Error on fetch used materials in group: ",
                    error
                );

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }

    protected goToSection = (sectionId: string) => {
        this.setState(() => {
            return {
                showMode: ModalShowMode.SECTION,
                selectedSectionId: sectionId
            }
        })
    }

    protected goToLevel = (levelId: string) => {
        this.setState(() => {
            return {
                showMode: ModalShowMode.LEVEL,
                selectedLevelId: levelId
            }
        });
    }

    protected goToRoot = () => {
        this.setState(() => {
            return {
                showMode: ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE
            }
        })
    }

    protected backBtn = () => {
        switch (this.state.showMode) {
            case ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE: {
                return;
            }
            case ModalShowMode.LEVEL: {
                return <PageBackLink onClick={this.goToRoot} text={t`К уровням и дисциплинам`}/>
            }
            case ModalShowMode.SECTION: {
                return <PageBackLink
                    onClick={() => {
                        if (this.state.selectedLevelId !== null) {
                            this.goToLevel(this.state.selectedLevelId);
                        }
                    }}
                    text={t`К уровням`}/>
            }
        }
    }

    protected body = () => {
        switch (this.state.showMode) {
            case ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE: {
                return <TmOrganizationAndDisciplineSection apiToken={this.props.apiToken}
                                                           selectMode={true}
                                                           usedDisciplineIds={(!this.state.usedInGroupItems) ? [] : this.state.usedInGroupItems.disciplines.map((item) => item.id)}
                                                           usedLevelIds={(!this.state.usedInGroupItems) ? [] : this.state.usedInGroupItems.levels.map((item) => item.id)}
                                                           navigateToLevelMethod={this.goToLevel}/>;
            }
            case ModalShowMode.LEVEL: {
                if (this.state.selectedLevelId !== null) {
                    return <TmLevelDetailsSection apiToken={this.props.apiToken}
                                                  levelId={this.state.selectedLevelId}
                                                  selectMode={true}
                                                  usedSectionIds={(!this.state.usedInGroupItems) ? [] : this.state.usedInGroupItems.sections.map((item) => item.id)}
                                                  onSelectSection={(dtoTmSection) => this.goToSection(dtoTmSection.id)}
                    />;
                }

                return;
            }
            case ModalShowMode.SECTION: {
                if (this.state.selectedSectionId !== null) {
                    return <>
                        <TmSectionDetailsSection apiToken={this.props.apiToken}
                                                 sectionId={this.state.selectedSectionId}
                                                 selectLessonMethod={this.props.addTmLessonToLesson}
                                                 selectMode={true}
                                                 slideSelector={this.props.slideSelector}
                                                 usedLessonIds={(!this.state.usedInGroupItems) ? [] : this.state.usedInGroupItems.lessons.map((item) => item.id)}
                        />
                    </>;
                }

                return;
            }
        }
    }

    protected legend = () => {
        return <Legend>
            <LegendItem>
                <LegendItemIconWrapper><SmallCircleOutlineIcon/></LegendItemIconWrapper>
                <LegendItemTextWrapper><span>&ndash;&nbsp;<Trans>элемент использовался в этой группе в прошлый
                    раз</Trans></span></LegendItemTextWrapper>
            </LegendItem>
            <LegendItem>
                <LegendItemIconWrapper><CheckIcon/></LegendItemIconWrapper>
                <LegendItemTextWrapper><span>&ndash;&nbsp;<Trans>элемент когда-либо использовался на занятиях
                    группы</Trans></span></LegendItemTextWrapper>
            </LegendItem>
        </Legend>
    }

    protected selectContent = () => {
        return <Wrapper>
            {this.backBtn()}
            {this.body()}
            {this.legend()}
        </Wrapper>;
    }

    protected modalContent = (controls: ModalControlParams) => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <Wrapper>
                    <DefaultLoader/>
                </Wrapper>;
            }
            case LoadingState.ERROR: {
                return <Wrapper>
                    <ErrorLoadingContent retryBtnClick={this.fetchUsedInGroupItems}/>
                </Wrapper>
            }
            case LoadingState.SUCCESS: {
                return <Wrapper>{this.selectContent()}</Wrapper>;
            }
            default: {
                throw new Error('Unknown loading state in select materials modal');
            }
        }
    }

    render() {
        return <Modal innerRef={this.modalRef}
                      closeAllowed={this.state.modalCloseAllowed}
                      onClose={() => this.setState(() => {
                          return {loadingState: LoadingState.NOT_INIT}
                      })}
                      footer={(controls: ModalControlParams) => {
                          return <Button btnStyle={BtnStyleEnum.Secondary}
                                         onClick={controls.closeModal}>
                              <Trans>Закрыть</Trans>
                          </Button>
                      }}
                      footerMode={FooterMode.DEFAULT}
                      children={this.modalContent}/>
    }
}