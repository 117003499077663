import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {contentLayoutSettingsSelector} from "../../../../store/layout/selector";
import styled from "styled-components";
import {ApplicationState} from "../../../../store";
import {ThemeEnum} from "../../../../services/theme/ThemeEnum";
import {BackgroundImage} from "./BackgroundImage";
import {ContentLayoutSettingsBackgroundModeEnum} from "../../../../enums/ContentLayoutSettingsBackgroundModeEnum";

const BackgroundWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    overflow: hidden;
`;

interface InternalBackgroundDataObject {
    backgroundType: ContentLayoutSettingsBackgroundModeEnum;
    fileId: string;
    themeName: string;
}

export const ContentBackground: React.FC = () => {
    const contentLayoutSettings = useSelector(contentLayoutSettingsSelector);

    const [backgroundFileIds, setBackgroundFileIds] = useState<InternalBackgroundDataObject[]>([]);

    const currentThemeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as string;

    useLayoutEffect(() => {
        setBackgroundFileIds((prevIds) => {
            if (!contentLayoutSettings || !contentLayoutSettings.backgroundFileId) {
                return [];
            }

            const newObject = {
                backgroundType: contentLayoutSettings.backgroundMode ?? ContentLayoutSettingsBackgroundModeEnum.COVER,
                fileId: contentLayoutSettings.backgroundFileId,
                themeName: contentLayoutSettings.themeName??currentThemeName
            }

            if (prevIds.length > 0) {
                return [
                    prevIds[prevIds.length - 1],
                    newObject
                ]
            }

            return [newObject];
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentLayoutSettings]);

    if (backgroundFileIds.length === 0) {
        return null;
    }

    return <BackgroundWrapper>
        {
            backgroundFileIds.map((backgroundItem) => {
                return <BackgroundImage
                    key={backgroundItem.fileId}
                    backgroundType={backgroundItem.backgroundType}
                    fileId={backgroundItem.fileId}
                    themeName={backgroundItem.themeName}
                />
            })
        }
    </BackgroundWrapper>
}

ContentBackground.displayName = 'ContentBackground';