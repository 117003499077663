export enum StudentGroupsListModeEnum {
    MY_ACTIVE_GROUPS = 0,
    ALL_ACTIVE_GROUPS = 1,
    ENDED_GROUPS = 2,
    DELETED_GROUPS = 3,
    ALL_GROUPS = 4,
}

export const AllStudentGroupsListMode = [
    StudentGroupsListModeEnum.ALL_ACTIVE_GROUPS,
    StudentGroupsListModeEnum.MY_ACTIVE_GROUPS,
    StudentGroupsListModeEnum.ENDED_GROUPS,
    StudentGroupsListModeEnum.DELETED_GROUPS,
    StudentGroupsListModeEnum.ALL_GROUPS,
]