import "plyr-react/plyr.css"
import React, {useMemo} from "react";
import Plyr from "plyr-react";
import styled from "styled-components";
import {UserFileFormatsEnum} from "../../../../enums/UserFileEnums";
import {t} from "@lingui/macro";

export type QualityItem = {
    url: string;
    mimeType: string;
    format: UserFileFormatsEnum;
}

interface VideoPlayerProps {
    qualityItems: QualityItem[];
    height: string;
}

type PlyrSource = Plyr.Source;

interface PlyrWrapperProps {
    height: string;
}

const PlyrWrapper = styled.div<PlyrWrapperProps>`
  --plyr-color-main: ${({theme}) => theme.slideContent.playerAccent};
  font-family: Arial, serif;

  .plyr {
    width: 100%;
    height: ${(props) => props.height};
  }
`;

// В компоненте ничего не меняется, поэтому никогда не вызываем его перерендер
const notShouldRerender = (_prevProps: VideoPlayerProps, _nextProps: VideoPlayerProps) => true;

export const VideoPlayer: React.FC<VideoPlayerProps> = React.memo((props) => {
    const sources = useMemo<PlyrSource[]>(() => {
        // Если варианты есть, выполняем поиск известных
        const knownItems = props.qualityItems.filter((qualityItem) => {
            return [
                UserFileFormatsEnum.VIDEO_MP4_480,
                UserFileFormatsEnum.VIDEO_MP4_720
            ].includes(qualityItem.format);
        });

        if (knownItems.length === 0) {
            // Нет известных форматов, вываливаем всё, что есть
            // Если вариантов нет, показываем что есть
            return props.qualityItems.map((qualityItem): PlyrSource => {
                return {
                    src: qualityItem.url,
                    provider: "html5",
                    type: qualityItem.mimeType
                }
            });
        }

        return knownItems.map((qualityItem): PlyrSource => {
            return {
                src: qualityItem.url,
                provider: "html5",
                type: qualityItem.mimeType,
                size: (qualityItem.format === UserFileFormatsEnum.VIDEO_MP4_480) ? 480 : 720
            }
        });

    }, [props]);

    const localization = useMemo<any>(() => {
        return {
            restart: t`Перезапустить`,
            rewind: t`Перейти к {seektime}сек`,
            play: t`Воспроизвести`,
            pause: t`Пауза`,
            fastForward: t`Перейти к {seektime}сек`,
            seek: t`Перемотка`,
            seekLabel: t`{currentTime} / {duration}`,
            played: t`Проигрывается`,
            buffered: t`Загружено`,
            currentTime: t`Текущая позиция`,
            duration: t`Длительность`,
            volume: t`Громкость`,
            mute: t`Отключить звук`,
            unmute: t`Включить звук`,
            enableCaptions: t`Включить субтитры`,
            disableCaptions: t`Отключить субтитры`,
            download: t`Скачать`,
            enterFullscreen: t`Полноэкранный режим`,
            exitFullscreen: t`Выйти из полноэкранного режима`,
            frameTitle: t`Проигрывается {title}`,
            captions: t`Субтитры`,
            settings: t`Настройки`,
            pip: t`Картинка в картинке`,
            menuBack: t`Назад`,
            speed: t`Скорость`,
            normal: t`Нормально`,
            quality: t`Качество`,
            loop: t`Повторять`,
            start: t`Начало`,
            end: t`Конец`,
            all: t`Всё`,
            reset: t`Перезапустить`,
            disabled: t`Отключить`,
            enabled: t`Включить`,
            advertisement: t`Реклама`,
            qualityBadge: {
                2160: '4K',
                1440: 'HD',
                1080: 'HD',
                720: 'HD',
                576: 'SD',
                480: 'SD',
            }
        }
    }, []);

    return <PlyrWrapper height={props.height}>
        <Plyr height={props.height}
              source={{
                  type: "video",
                  sources: sources
              }}
              options={{
                  i18n: localization,
                  resetOnEnd: true,
                  hideControls: false,
                  controls: [
                      'play-large',
                      'play',
                      'progress',
                      'current-time',
                      'volume',
                      'settings',
                      'pip',
                      'airplay',
                      'fullscreen'
                  ],
                  settings: [
                      'quality',
                      'speed',
                  ]
              }}
        />
    </PlyrWrapper>;
}, notShouldRerender);

VideoPlayer.displayName = 'VideoPlayer';