import React, {forwardRef} from 'react';
import classNames from 'classnames';

import styled from "styled-components";

export interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
    active?: boolean;
}

const ActionStyled = styled.button`
{
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  opacity: 0.3;
  color: ${({theme}) => theme.colors.textPrimary};
  transition: opacity 0.3s ease;

  &:not(.active):hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}
`;

export const Action = forwardRef<HTMLButtonElement, ActionProps>(
    ({active, ...props}, ref) => {
        return <ActionStyled
            ref={ref}
            {...props}
            className={classNames(props.className, active && "active")}
            tabIndex={0}
        />;
    }
);