import React, {useMemo} from 'react';
import {Paragraph, ParagraphDefaultStyle} from "./elements/Paragraph";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {RichEditSpan} from "./elements/span";
import {ICustomText} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICustomText";
import {HeadingOne} from "./elements/HeadingOne";
import {ExerciseFillGapsCombobox} from "./elements/ExerciseFillGapsCombobox";
import {BulletedList} from "./elements/BulletedList";
import {ListItem, ListItemDefaultStyle} from "./elements/ListItem";
import {NumberedList} from "./elements/NumberedList";
import {IFillGapsCombobox} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsCombobox";
import {ExerciseFillGapsText} from "./elements/ExerciseFillGapsText";
import {IFillGapsText} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsText";
import {ExerciseCorrectExistInInput} from "./elements/ExerciseCorrectExistInInput";
import {ICorrectExistInInput} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICorrectExistInInput";
import {ExerciseFillGapsDragDrop} from "./elements/ExerciseFillGapsDragDrop";
import {ExerciseRadioList} from "../../elements/ExerciseRadio/RadioList";
import {IRadioList} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioList";
import {ExerciseRadioListItem} from "../../elements/ExerciseRadio/RadioListItem";
import {IRadioListItem} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioListItem";
import {ExerciseCheckboxList} from "../../elements/ExerciseCheckbox/CheckboxList";
import {ICheckboxList} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxList";
import {ICheckboxListItem} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxListItem";
import {ExerciseCheckboxListItem} from "../../elements/ExerciseCheckbox/CheckboxListItem";
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {ITranslated} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ITranslated";
import {Translated} from "./elements/Translated";

interface RichTextItemReaderProps {
    isFirst: boolean;
    element: CustomElement | ICustomText;
    paragraphComponent?: typeof Paragraph;
    liComponent?: typeof ListItem;
}

const RichTextItemReader: React.FC<RichTextItemReaderProps> = (props) => {
    const {element, isFirst, ...otherProps} = props;

    const {paragraphComponent, liComponent} = otherProps;

    const ParagraphComponentMemo = useMemo<typeof Paragraph>(() => {
        if (paragraphComponent) {
            return paragraphComponent;
        }

        return ParagraphDefaultStyle;
    }, [paragraphComponent]);

    const ListComponentMemo = useMemo<typeof ListItem>(() => {
        if (liComponent) {
            return liComponent;
        }

        return ListItemDefaultStyle;
    }, [liComponent]);

    const elem = () => {
        const elementAsUnknown = element as any;

        // Если указан тип, это какой-то сложный компонент с children
        if (elementAsUnknown.type !== undefined) {
            const elementAsElement = elementAsUnknown as CustomElement;

            switch (elementAsElement.type) {
                case ElementTypes.HEADING_ONE: {
                    return <HeadingOne element={elementAsElement} {...otherProps}/>;
                }
                case ElementTypes.TRANSLATED: {
                    return <Translated element={elementAsElement as ITranslated} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_FILL_GAPS_COMBOBOX: {
                    return <ExerciseFillGapsCombobox element={elementAsElement as IFillGapsCombobox} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_FILL_GAPS_TEXT: {
                    return <ExerciseFillGapsText element={elementAsElement as IFillGapsText} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT: {
                    return <ExerciseCorrectExistInInput
                        element={elementAsElement as ICorrectExistInInput} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP: {
                    return <ExerciseFillGapsDragDrop
                        element={elementAsElement as ICorrectExistInInput} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_RADIO_LIST: {
                    return <ExerciseRadioList element={elementAsElement as IRadioList} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_RADIO_LIST_ITEM: {
                    return <ExerciseRadioListItem element={elementAsElement as IRadioListItem} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_CHECKBOX_LIST: {
                    return <ExerciseCheckboxList element={elementAsElement as ICheckboxList} {...otherProps}/>;
                }
                case ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM: {
                    return <ExerciseCheckboxListItem element={elementAsElement as ICheckboxListItem} {...otherProps}/>;
                }
                case ElementTypes.BULLETED_LIST: {
                    return <BulletedList element={elementAsElement} {...otherProps}/>;
                }
                case ElementTypes.NUMBERED_LIST: {
                    return <NumberedList element={elementAsElement} {...otherProps}/>;
                }
                case ElementTypes.LIST_ITEM: {
                    return <ListComponentMemo element={elementAsElement} {...otherProps}/>;
                }
                default: {
                    return <ParagraphComponentMemo element={elementAsElement} {...otherProps}/>;
                }
            }
        } else {
            const elementAsText = elementAsUnknown as ICustomText;

            let text = elementAsText.text;

            if (
                (
                    (elementAsText.text === '')
                    || (elementAsText.text === ' ')
                )
                && (!isFirst)
            ) {
                text = ' ';
            }

            // Если тип не указан, значит это просто текст
            return <RichEditSpan element={{...elementAsText, text: text}}/>;
        }

    };

    return <>{elem()}</>;
}

interface RichTextReaderProps {
    elements: CustomElement[] | ICustomText[];
    paragraphComponent?: typeof Paragraph;
    liComponent?: typeof ListItem;
}

export const RichTextReader: React.FC<RichTextReaderProps> = (props) => {
    const {elements, paragraphComponent, liComponent} = props;

    return <>
        {
            elements.map((item, index) => {
                return <RichTextItemReader key={(((item as any).type) ?? "_") + index.toString(10)}
                                           isFirst={index === 0}
                                           element={item}
                                           paragraphComponent={paragraphComponent}
                                           liComponent={liComponent}
                />;
            })
        }
    </>;
}