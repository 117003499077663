import React from "react";
import {Trans} from "@lingui/macro";

// Получить строку названия месяца по номеру (объект Date в строку вида "Январь" или "Января")

interface MonthAsStringProps {
    monthNum: number;
    inGenitiveCase: boolean;
}

export const MonthAsString: React.FC<MonthAsStringProps> = (props) => {
    const monthByNumberInNominative = (mNumber: number) => {
        switch (mNumber) {
            case 1:
                return <Trans>Январь</Trans>;
            case 2:
                return <Trans>Февраль</Trans>;
            case 3:
                return <Trans>Март</Trans>;
            case 4:
                return <Trans>Апрель</Trans>;
            case 5:
                return <Trans>Май</Trans>;
            case 6:
                return <Trans>Июнь</Trans>;
            case 7:
                return <Trans>Июль</Trans>;
            case 8:
                return <Trans>Август</Trans>;
            case 9:
                return <Trans>Сентябрь</Trans>;
            case 10:
                return <Trans>Октябрь</Trans>;
            case 11:
                return <Trans>Ноябрь</Trans>;
            case 12:
                return <Trans>Декабрь</Trans>;
            default:
                return <></>;
        }
    };

    const monthByNumberInGenitive = (mNumber: number) => {
        switch (mNumber) {
            case 1:
                return <Trans>Января</Trans>;
            case 2:
                return <Trans>Февраля</Trans>;
            case 3:
                return <Trans>Марта</Trans>;
            case 4:
                return <Trans>Апреля</Trans>;
            case 5:
                return <Trans>Мая</Trans>;
            case 6:
                return <Trans>Июня</Trans>;
            case 7:
                return <Trans>Июля</Trans>;
            case 8:
                return <Trans>Августа</Trans>;
            case 9:
                return <Trans>Сентября</Trans>;
            case 10:
                return <Trans>Октября</Trans>;
            case 11:
                return <Trans>Ноября</Trans>;
            case 12:
                return <Trans>Декабря</Trans>;
            default:
                return <></>;
        }
    };

    return props.inGenitiveCase
        ? monthByNumberInGenitive(props.monthNum)
        : monthByNumberInNominative(props.monthNum);
}
