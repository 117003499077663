import {IConditionChecker} from "./IConditionChecker";
import {SlideItemWorkData} from "../../../../../store/slidesWorkData/type";
import {
    SlideItemInteractivityActionConditionModeEnum
} from "../../../../../enums/SlideItemInteractivityActionConditionModeEnum";
import {EditorItemInteractivityConfigActionCondition} from "../../../SlidePlayerEditorCommonParts/EditorData";

/**
 * Проверяет совпадение только поля answer первого exercise
 */
export class DefaultConditionChecker implements IConditionChecker {
    async checkCondition(conditionConfig: EditorItemInteractivityConfigActionCondition, currentItemWorkData: SlideItemWorkData): Promise<boolean> {
        let value = '';

        if (currentItemWorkData.exercises.length > 0) {
            value = currentItemWorkData.exercises[0].value;
        }

        let resolution = false;

        switch (conditionConfig.conditionMode) {
            case SlideItemInteractivityActionConditionModeEnum.POSITIVE: {
                resolution = value === conditionConfig.targetValue;

                break;
            }
            case SlideItemInteractivityActionConditionModeEnum.NEGATIVE: {
                resolution = value !== conditionConfig.targetValue;

                break;
            }
            case SlideItemInteractivityActionConditionModeEnum.INCLUDE: {
                resolution = value.toUpperCase().includes(conditionConfig.targetValue.toUpperCase());

                break;
            }
        }

        return Promise.resolve(resolution);
    }
}