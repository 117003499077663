import React, {useCallback, useMemo} from "react";
import styled, {css} from "styled-components";
import {ReactComponent as AbsentCheck24} from "../../../../../components/Ui/Svg/AbsentCheck24.svg";
import {ReactComponent as AbsentCross24} from "../../../../../components/Ui/Svg/AbsentCross24.svg";
import {DtoLastLessonItem} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLastLessonItem";
import {useSelector} from "react-redux";
import {selectedSchoolActualParams, selectedSchoolActualParamsCount} from "../../../../../../store/user/selector";
import {Value, ValueShowMode} from "./Value";
import {t, Trans} from "@lingui/macro";
import {StudentRateParamPositionEnum} from "../../../../../../enums/StudentRateParamPositionEnum";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {DateAsString} from "../../../../../components/Ui/Elements/TextPresenter/DateAsString";
import classNames from "classnames";
import {HometaskModal} from "../Modals/Hometask/HometaskModal";
import {HomeTaskBtnLabel} from "./HomeTaskBtnLabel";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../di-factory/DiTokens";

const KEY_FOR_AVERAGE_VALUE = 'average';

export enum ItemShowMode {
    SMALL,
    MEDIUM,
    WIDE
}

const BottomLineStyle = css`
  border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
`;

// Стили для варианта SMALL
const SmallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  &.bottom-line {
    ${BottomLineStyle};
  }
`;

const SmallDateLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

const SmallDateStringWrapper = styled.div`
  flex-grow: 1;

  font-size: 16px;
  line-height: 24px;
`;

const SmallAbsentIconWrapper = styled.div`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
`;

const SmallValuesWrapper = styled.div`
  margin-bottom: 16px;
`;

const SmallBtnWrapper = styled.div``;

const SmallBtn = styled(Button)`
  padding: 8px 16px;
`;

// Стили для варианта MEDIUM
const MediumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  width: 100%;

  &.bottom-line {
    ${BottomLineStyle};
  }
`;

const MediumFirstLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  justify-content: space-between;
`;

const MediumDateColWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MediumDateStringWrapper = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;
`;

const MediumAbsentIconWrapper = styled.div`
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
`;

const MediumBtnWrapper = styled.div``;

const MediumBtn = styled(Button)`
  padding: 8px 16px;

  &[disabled] {
    background: none !important;
    cursor: default;
  }
`;

const MediumValuesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;

  &.one-value {
    justify-content: flex-start;
  }
`;

// Стили для варианта WIDE
const WideWrapper = styled.div`
  display: grid;

  &.bottom-line {
    ${BottomLineStyle};
  }
`;

const WideGridItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 3px;
`;

interface ItemProps {
    showMode: ItemShowMode;
    data: DtoLastLessonItem;
    bottomLine: boolean;
}

export const Item: React.FC<ItemProps> = ({showMode, data, bottomLine}) => {
    const params = useSelector(selectedSchoolActualParams);
    const paramsCount = useSelector(selectedSchoolActualParamsCount);

    const absent = useMemo<boolean>(() => {
        return data.absent !== 0;
    }, [data]);

    const noValues = useMemo<boolean>(() => {
        return absent || data.isFake;
    }, [absent, data]);

    const hasHomework = useMemo<boolean>(() => {
        return !!(
            (data.hometask)
            && (
                (data.hometask.hasExercises)
                || (
                    (data.hometask.teacherText !== null)
                    && data.hometask.teacherText.trim() !== ''
                )
            )
        );
    }, [data]);

    const hometaskIsNew = useMemo<boolean>(() => {
        return !!(
            (data.hometask)
            && (data.hometask.hasExercises)
            && (!data.hometask.exercisesCompleted)
        );
    }, [data.hometask]);

    const lessonDate = useMemo<Date>(() => {
        const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        return dateHelper.dateFromString(data.lessonDate);
    }, [data.lessonDate]);

    const hometaskPopup = useCallback((trigger: JSX.Element) => {
        return <HometaskModal trigger={trigger}
                              hometaskDate={lessonDate}
                              hometaskTeacherText={
                                  (data.hometask && data.hometask.teacherText)
                                      ? data.hometask.teacherText
                                      : undefined
                              }
                              lessonId={(data.hometask) ? data.hometask.lessonId : undefined}
                              slidesCount={(data.hometask) ? data.hometask.slidesCount : 0}
                              exerciseCount={(data.hometask) ? data.hometask.exercisesCount : 0}
                              exerciseStarted={(data.hometask) ? data.hometask.exercisesStarted : false}
                              exerciseCompleted={(data.hometask) ? data.hometask.exercisesCompleted : false}
                              teacherComment={data.comment !== "" ? data.comment : undefined}
        />

    }, [data.comment, data.hometask, lessonDate]);

    const valuesList = useMemo<JSX.Element[]>(() => {
        if (paramsCount === 1) {
            return [
                <Value value={(noValues) ? null : data.param1}
                       key={StudentRateParamPositionEnum.FIRST}
                       name={t`Оценка`}
                       showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}/>
            ];
        }

        // Есть несколько параметров оценки
        const valuesArray: JSX.Element[] = [];

        valuesArray.push(
            <Value value={(noValues) ? null : data.totalAverage}
                   key={KEY_FOR_AVERAGE_VALUE}
                   name={t`Общее среднее`}
                   showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />
        );

        if (params?.first) {
            valuesArray.push(<Value value={(noValues) ? null : data.param1}
                                    key={StudentRateParamPositionEnum.FIRST}
                                    name={params.first}
                                    showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />)
        }

        if (params?.second) {
            valuesArray.push(<Value value={(noValues) ? null : data.param2}
                                    key={StudentRateParamPositionEnum.SECOND}
                                    name={params.second}
                                    showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />);
        }

        if (params?.third) {
            valuesArray.push(<Value value={(noValues) ? null : data.param3}
                                    key={StudentRateParamPositionEnum.THIRD}
                                    name={params.third}
                                    showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />);
        }

        if (params?.fourth) {
            valuesArray.push(<Value value={(noValues) ? null : data.param4}
                                    key={StudentRateParamPositionEnum.FOURTH}
                                    name={params.fourth}
                                    showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />);
        }

        if (params?.fifth) {
            valuesArray.push(<Value value={(noValues) ? null : data.param5}
                                    key={StudentRateParamPositionEnum.FIFTH}
                                    name={params.fifth}
                                    showMode={(showMode === ItemShowMode.SMALL) ? ValueShowMode.LINE : ValueShowMode.COLUMN}
            />);
        }

        return valuesArray;
    }, [params, paramsCount, data, noValues, showMode]);

    // return:
    switch (showMode) {
        case ItemShowMode.SMALL: {
            return <SmallWrapper className={classNames(bottomLine && "bottom-line")}>
                <SmallDateLineWrapper>
                    <SmallDateStringWrapper><DateAsString date={lessonDate}/></SmallDateStringWrapper>
                    <SmallAbsentIconWrapper>
                        {
                            (absent || data.isFake)
                                ? <AbsentCross24/>
                                : <AbsentCheck24/>
                        }
                    </SmallAbsentIconWrapper>
                </SmallDateLineWrapper>
                <SmallValuesWrapper>
                    {valuesList}
                </SmallValuesWrapper>
                <SmallBtnWrapper>
                    {
                        (hasHomework)

                            ? hometaskPopup(<SmallBtn
                                btnStyle={BtnStyleEnum.Secondary}>
                                <HomeTaskBtnLabel isNew={hometaskIsNew}>
                                    <Trans>задание</Trans>
                                </HomeTaskBtnLabel>
                            </SmallBtn>)
                            : <SmallBtn btnStyle={BtnStyleEnum.Secondary}
                                        disabled={true}><Trans>нет заданий</Trans></SmallBtn>
                    }
                </SmallBtnWrapper>
            </SmallWrapper>;
        }
        case ItemShowMode.MEDIUM: {
            return <MediumWrapper className={classNames(bottomLine && "bottom-line")}>
                <MediumFirstLine>
                    <MediumDateColWrapper>
                        <MediumDateStringWrapper><DateAsString
                            date={lessonDate}/></MediumDateStringWrapper>
                        <MediumAbsentIconWrapper>
                            {
                                (absent || data.isFake)
                                    ? <AbsentCross24/>
                                    : <AbsentCheck24/>
                            }
                        </MediumAbsentIconWrapper>
                    </MediumDateColWrapper>
                    <MediumBtnWrapper>
                        {
                            (hasHomework)
                                ? hometaskPopup(<MediumBtn
                                    btnStyle={BtnStyleEnum.Secondary}>
                                    <HomeTaskBtnLabel isNew={hometaskIsNew}>
                                        <Trans>задание</Trans>
                                    </HomeTaskBtnLabel>
                                </MediumBtn>)
                                : <MediumBtn btnStyle={BtnStyleEnum.Secondary}
                                             disabled={true}><Trans>нет заданий</Trans></MediumBtn>
                        }
                    </MediumBtnWrapper>
                </MediumFirstLine>
                <MediumValuesWrapper className={classNames(paramsCount < 2 && "one-value")}>
                    {valuesList}
                </MediumValuesWrapper>
            </MediumWrapper>
        }
        case ItemShowMode.WIDE: {
            const items: JSX.Element[] = [];

            items.push(<WideGridItem key={'date'} style={{justifyContent: "flex-start"}}>
                <DateAsString date={lessonDate}/>
            </WideGridItem>);
            items.push(...valuesList.map(
                    (item, index) =>
                        <WideGridItem key={'value-' + index.toString(10)}>{item}</WideGridItem>
                )
            );
            items.push(<WideGridItem key={'absent-icon'}>{
                (absent || data.isFake)
                    ? <AbsentCross24/>
                    : <AbsentCheck24/>
            }</WideGridItem>);
            items.push(<WideGridItem key={'hometask-btn'}>
                <MediumBtnWrapper>
                    {
                        (hasHomework)
                            ? hometaskPopup(<MediumBtn
                                btnStyle={BtnStyleEnum.Secondary}>
                                <HomeTaskBtnLabel isNew={hometaskIsNew}>
                                    <Trans>задание</Trans>
                                </HomeTaskBtnLabel>
                            </MediumBtn>)
                            : <MediumBtn btnStyle={BtnStyleEnum.Secondary}
                                         disabled={true}><Trans>задание</Trans></MediumBtn>
                    }
                </MediumBtnWrapper>
            </WideGridItem>);

            const gridColumnsConfig = items.reduce(
                (previousValue, currentValue, currentIndex) => {
                    return previousValue + ((currentIndex === items.length - 2) ? ' 1fr' : ' 2fr');
                },
                ''
            );

            return <WideWrapper className={classNames(bottomLine && "bottom-line")}
                                style={{gridTemplateColumns: gridColumnsConfig}}>
                {items}
            </WideWrapper>
        }
        default: {
            throw new Error("Unknown show mode " + showMode);
        }
    }
}
