import {IsNotEmpty, IsNumber, IsString} from 'class-validator';

/**
 * DTO элемента списка уроков
 */
export class DtoLessonPlanItem {
    @IsNotEmpty()
    @IsNumber()
    itemTypeId: number;

    @IsNotEmpty()
    @IsString()
    utcStartDateTime: string;

    @IsNotEmpty()
    @IsNumber()
    duration: number;
}