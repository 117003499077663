import React from "react";
import styled from "styled-components";
import {MainPageSection} from "../../style";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {SelfStudySubscriptionOverview} from "./subscription";

const Wrapper = styled(MainPageSection)`
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;
`;

interface SelfStudySubscriptionsLargeProps {
    items: DtoSelfStudyTrackSubscriptionUserOverviewItem[];
}

export const SelfStudySubscriptionsLarge: React.FC<SelfStudySubscriptionsLargeProps> = ({items}) => {
    return <Wrapper>
        {
            items.map((item) => {
                return <SelfStudySubscriptionOverview key={item.id} item={item}/>
            })
        }
    </Wrapper>
}