import {CustomElement} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

export const defaultInitialValue: CustomElement[] = [
    {
        type: ElementTypes.PARAGRAPH,
        align: AlignTypes.LEFT,
        children: [{text: ""}],
    },
]