import React, {useCallback, useContext, useEffect, useState} from "react";
import {IInitialCustomizerPageContext, InitialCustomizerPageContext} from "../InitialCustomizerPageContext";
import styled from "styled-components";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {Form, FormItem, useForm} from "../../../../components/Ui/Elements/Form";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";
import {PageSubtitleSmallMargin} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {FormFieldNamesEnum} from "../FormFieldNamesEnum";
import {RadioGroup, RadioInput} from "../../../../components/Ui/Elements/RadioInput";
import {SchoolTypeEnum} from "../../../../../components/StonlineApiClient/Enums/SchoolTypeEnum";
import {ButtonArea} from "../../student/create-agreement-page/ButtonArea";
import {TimezoneSelector} from "../../../../components/Ui/Elements/Selectors/TimezoneSelector";
import {PageLoadStatus} from "../../knowledge-base/common/Enums";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../store/app/selector";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {NoConnection} from "../../../../../components/StonlineApiClient/Exception/NoConnection";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SchoolNameInputWrapper = styled.div`
  padding-left: 25px;
`;

export const TeacherNameSection: React.FC = () => {
    const [form] = useForm();

    const pageContext = useContext<IInitialCustomizerPageContext>(InitialCustomizerPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const stToken = useSelector(stTokenSelector);

    const [schoolNameVisible, setSchoolNameVisible] = useState<boolean>(
        pageContext.formData.schoolType === SchoolTypeEnum.SCHOOL
    );
    const [pageLoadingState, setPageLoadingState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);

    const onFinish = (values: any) => {
        pageContext.setFormData((oldValues) => {
            return {
                ...oldValues,
                timezoneName: values[FormFieldNamesEnum.TIMEZONE_NAME].zoneName,
                timezoneLocalTime: values[FormFieldNamesEnum.TIMEZONE_NAME].currentTime,
                timezoneUtcDiff: values[FormFieldNamesEnum.TIMEZONE_NAME].offset,
                schoolType: values[FormFieldNamesEnum.SCHOOL_TYPE],
                schoolName: values[FormFieldNamesEnum.SCHOOL_NAME],
                userName: values[FormFieldNamesEnum.USER_NAME],
            }
        });

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.TEACHER_NAME,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    const onFormValuesChange = useCallback(() => {
        const schoolTypeVisibleNewValue =
            form.getFieldValue(FormFieldNamesEnum.SCHOOL_TYPE) === SchoolTypeEnum.SCHOOL;

        if (schoolTypeVisibleNewValue !== schoolNameVisible) {
            setSchoolNameVisible(schoolTypeVisibleNewValue);
        }
    }, [form, schoolNameVisible]);

    const autoSetTimezone = useCallback(() => {
        if (!stToken) {
            setPageLoadingState(PageLoadStatus.SUCCESS);

            return;
        }

        setPageLoadingState(PageLoadStatus.LOADING);

        let browserTimezoneName = '';
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        try {
            browserTimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (e) {
            logger.error(
                LoggerSectionsEnum.BROWSER_TIMEZONE,
                'Error on get browser timezone name:',
                e
            );

            setPageLoadingState(PageLoadStatus.SUCCESS);

            return;
        }

        if (!browserTimezoneName) {
            logger.error(
                LoggerSectionsEnum.BROWSER_TIMEZONE,
                'Error on get browser timezone name - received empty string',
                browserTimezoneName
            );

            setPageLoadingState(PageLoadStatus.SUCCESS);

            return;
        }

        // Название временной зоны получили. Поищем такую в API stonline
        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        stApiClient.searchTimezone(stToken, browserTimezoneName, 1, 10)
            .then((data) => {
                data.result.items.forEach(item => {
                    if (item.zoneName.toLowerCase() === browserTimezoneName.toLowerCase()) {
                        pageContext.setFormData((oldValues) => {
                            return {
                                ...oldValues,
                                timezoneName: item.zoneName,
                                timezoneLocalTime: item.currentTime,
                                timezoneUtcDiff: item.offset
                            }
                        });
                    }
                });

                setPageLoadingState(PageLoadStatus.SUCCESS);
            })
            .catch((e) => {
                setPageLoadingState(PageLoadStatus.SUCCESS);

                if (e instanceof NoConnection) {
                    return;
                }

                logger.error(
                    LoggerSectionsEnum.BROWSER_TIMEZONE,
                    'Error on fetch timezones list for initial customizer: ',
                    e
                );
            })
    }, [pageContext, stToken]);

    useEffect(() => {
        if (pageLoadingState === PageLoadStatus.NOT_INIT) {
            if (pageContext.formData.timezoneName === null) {
                autoSetTimezone();
            } else {
                setPageLoadingState(PageLoadStatus.SUCCESS);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if ((pageLoadingState === PageLoadStatus.LOADING) || (pageLoadingState === PageLoadStatus.NOT_INIT)) {
        return <DefaultLoader/>;
    }

    return <div>
        <FormWrapper>
            <Form form={form} layout={"inline"}
                  onFinish={onFinish}
                  onValuesChange={onFormValuesChange}
            >
                <PageSubtitleSmallMargin>
                    <Trans>Расскажите о себе</Trans>
                </PageSubtitleSmallMargin>

                <FormItemsWrapper>
                    <FormItem
                        name={FormFieldNamesEnum.TIMEZONE_NAME}
                        initialValue={(pageContext.formData.timezoneName) ? {
                            offset: pageContext.formData.timezoneUtcDiff,
                            zoneName: pageContext.formData.timezoneName,
                            currentTime: pageContext.formData.timezoneLocalTime,
                        } : null}
                        label={<Trans>Ваше местное время</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`Укажите ваш часовой пояс`,
                            }
                        ]}
                    >
                        <TimezoneSelector/>
                    </FormItem>


                    <FormItem
                        name={FormFieldNamesEnum.USER_NAME}
                        initialValue={pageContext.formData.userName}
                        label={<Trans>Ваше имя (лучше ФИО полностью)</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`Укажите ваше имя`,
                            }
                        ]}
                    >
                        <Input/>
                    </FormItem>

                    <FormItem
                        name={FormFieldNamesEnum.SCHOOL_TYPE}
                        initialValue={pageContext.formData.schoolType}
                    >
                        <RadioGroup>
                            <RadioWrapper>
                                <RadioInput value={SchoolTypeEnum.PERSONAL_TUTOR}>
                                    <Trans>Я планирую вести частные уроки</Trans></RadioInput>
                                <RadioInput value={SchoolTypeEnum.SCHOOL}>
                                    <Trans>Я планирую использовать Мои занятия в организации</Trans></RadioInput>
                            </RadioWrapper>
                        </RadioGroup>
                    </FormItem>

                    {
                        (schoolNameVisible) &&
                        <SchoolNameInputWrapper>
                            <FormItem
                                name={FormFieldNamesEnum.SCHOOL_NAME}
                                initialValue={pageContext.formData.schoolName}
                                label={<Trans>Введите название школы</Trans>}
                                rules={[
                                    {
                                        required: true,
                                        message: t`Укажите название организации`,
                                    }
                                ]}
                            >
                                <Input/>
                            </FormItem>
                        </SchoolNameInputWrapper>
                    }
                </FormItemsWrapper>
            </Form>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}
            nextBtnOnClick={async () => {
                await form.validateFields();

                form.submit();
            }}
        />
    </div>
}