import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {container} from "tsyringe";
import {useSelector} from "react-redux";
import {t} from "@lingui/macro";
import styled from "styled-components";
import {SlidePlayer} from "../../../../../components/SlidePlayer";
import {IPlayerContext, PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {EditorDataDtoMapper} from "../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {slidesWorkDataStateSelector} from "../../../../../../store/slidesWorkData/selector";
import {SlideWorkData, SlideWorkDataLoadingStateEnum} from "../../../../../../store/slidesWorkData/type";
import {NavigationButtons} from "../SlidesList/NavigationButtons";
import {NextButton} from "../SlidesList/NextButton";
import {SlideItemsParamsBySlide} from "../../../../../components/SlidePlayer/SlideItemParamsStore";

const Wrapper = styled.div`
  flex-grow: 1;
  max-width: 680px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
  }
`;

const SlidePlayerStyled = styled(SlidePlayer)`
    margin-bottom: 30px;
`;

const NextButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export interface MaterialsPlayerRefMethods {
}

enum SlideContentLoadingStateEnum {
    NOT_LOADED,
    IN_PROCESS,
    SUCCESS,
    ERROR
}

enum TotalStateEnum {
    IN_PROCESS,
    SUCCESS,
    ERROR
}


export const MaterialsPlayer = forwardRef<MaterialsPlayerRefMethods, {}>(
    (props, ref) => {
        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const {selectedSlide, selectedSlideId, slidesContent, setSlidesContent} = playerContext;
        const sessionToken = useSelector(sessionTokenSelector);
        const slideWorkDataState = useSelector(slidesWorkDataStateSelector);

        const [slideContentLoadingState, setSlideContentLoadingState] = useState<SlideContentLoadingStateEnum>(SlideContentLoadingStateEnum.NOT_LOADED);

        const currentSlideWorkData = useMemo<SlideWorkData | null>(() => {
            if (selectedSlideId === null) {
                return null;
            }

            const playerIndex = slideWorkDataState.indexByPlayerId[playerContext.playerId];

            if (playerIndex === undefined) {
                return null;
            }

            const slideIndex = playerIndex.indexBySlideId[selectedSlideId];

            if ((slideIndex === undefined) || (slideWorkDataState.slides[slideIndex] === undefined)) {
                return null;
            }

            return slideWorkDataState.slides[slideIndex];
        }, [playerContext.playerId, selectedSlideId, slideWorkDataState.indexByPlayerId, slideWorkDataState.slides]);

        useEffect(() => {
            const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            if (
                (slideContentLoadingState !== SlideContentLoadingStateEnum.NOT_LOADED)
                || (!selectedSlideId)
                || (!sessionToken)
            ) {
                return;
            }

            if (slidesContent[selectedSlideId] !== undefined) {
                setSlideContentLoadingState(SlideContentLoadingStateEnum.SUCCESS);

                return;
            }

            setSlideContentLoadingState(SlideContentLoadingStateEnum.IN_PROCESS);

            // Похоже, контента слайда нет. Загрузим с сервера.
            httpApiClient.tmGetSlideContentForStudent(
                sessionToken,
                playerContext.lessonId,
                selectedSlideId
            )
                .then((data) => {
                    setSlidesContent(
                        selectedSlideId,
                        EditorDataDtoMapper.dtoToEditorData(data.data.content)
                    );

                    setSlideContentLoadingState(SlideContentLoadingStateEnum.SUCCESS);
                })
                .catch((err) => {
                    setSlideContentLoadingState(SlideContentLoadingStateEnum.ERROR);

                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on load slide content: ", err);
                });

        }, [slideContentLoadingState, selectedSlideId, sessionToken, setSlidesContent, slidesContent, playerContext.lessonId]);

        useEffect(() => {
            setSlideContentLoadingState(SlideContentLoadingStateEnum.NOT_LOADED);
        }, [selectedSlideId]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const totalState = useMemo<TotalStateEnum>((): TotalStateEnum => {
            const contentInError = slideContentLoadingState === SlideContentLoadingStateEnum.ERROR;
            const workDataInError = (currentSlideWorkData !== null) && (currentSlideWorkData.loadState === SlideWorkDataLoadingStateEnum.ERROR);

            if (contentInError || workDataInError) {
                return TotalStateEnum.ERROR;
            }

            if (
                (currentSlideWorkData === null)
                || (currentSlideWorkData.loadState === SlideWorkDataLoadingStateEnum.LOADING)
                || (slideContentLoadingState === SlideContentLoadingStateEnum.NOT_LOADED)
                || (slideContentLoadingState === SlideContentLoadingStateEnum.IN_PROCESS)
            ) {
                return TotalStateEnum.IN_PROCESS;
            }

            return TotalStateEnum.SUCCESS;
        }, [currentSlideWorkData, slideContentLoadingState]);

        const slideItemsParams = useMemo<SlideItemsParamsBySlide>(() => {
            const result: SlideItemsParamsBySlide = {};

            if (!selectedSlide) {
                return result;
            }

            const selectedSlideData = playerContext.slidesContent[selectedSlide.tmSlideId];

            if (!selectedSlideData) {
                return result;
            }

            selectedSlideData.items.forEach((slideContentItem) => {
                result[slideContentItem.id] = slideContentItem.params;
            });

            return result;
        }, [playerContext.slidesContent, selectedSlide]);

        const content = () => {
            if (!selectedSlideId) {
                return;
            }

            switch (totalState) {
                case TotalStateEnum.IN_PROCESS: {
                    return <DefaultLoader/>;
                }
                case TotalStateEnum.ERROR: {
                    if (slideContentLoadingState === SlideContentLoadingStateEnum.ERROR) {
                        return <ErrorLoadingContent
                            title={t`Не удалось загрузить содержимое слайда`}
                            retryBtnClick={() => {
                                setSlideContentLoadingState(SlideContentLoadingStateEnum.NOT_LOADED);
                            }}/>
                    }

                    return <ErrorLoadingContent
                        title={t`Не удалось загрузить содержимое слайда`}
                        retryBtnClick={() => {
                            // TODO: Перезапустить загрузку данных слайда
                        }}/>
                }
                case TotalStateEnum.SUCCESS: {
                    return ((selectedSlide) && (slidesContent[selectedSlideId]))
                        && <>
                            <SlidePlayerStyled
                                playerId={playerContext.playerId}
                                slideId={selectedSlide.tmSlideId}
                                slideContent={slidesContent[selectedSlideId]}
                                slideItemsParams={slideItemsParams}
                            />
                            <NextButtonWrapper>
                                <NextButton/>
                            </NextButtonWrapper>
                        </>;
                }
                default: {
                    return;
                }
            }
        }

        return <Wrapper>
            {content()}
        </Wrapper>;
    }
);
