import React, {forwardRef, useMemo} from "react";
import {Input} from "../../TextInput";
import {InputRef} from "antd/lib/input/Input";
import {PopupPosition} from "reactjs-popup/dist/types";
import {PopupMultiselect, PopupMultiselectItem} from "../../PopupMultiselect";
import {t} from "@lingui/macro";

interface PopupMultipleSelectorProps {
    items: PopupMultiselectItem[];
    value?: string[];
    placeholder?: string;
    onChange?: (selectedKeys: string[]) => void;
    popupPosition?: PopupPosition;
}

export const PopupMultipleSelector = forwardRef<InputRef, PopupMultipleSelectorProps>(
    (props, ref) => {
        const valuesText = useMemo<string>(() => {
            if (!props.value) {
                return '';
            }

            if (props.value.length === 0) {
                return '';
            }

            if (props.value.length === props.items.length) {
                return t`Все`;
            }

            return props.items
                .filter(item => {
                    if (!props.value) {
                        return false;
                    }

                    return props.value.indexOf(item.key) > -1;
                })
                .map(item => item.text)
                .join(', ');
        }, [props.value, props.items]);

        return <PopupMultiselect
            items={props.items}
            onChange={(props.onChange) ?? (() => {
            })}
            position={props.popupPosition}
            selectedKeys={props.value ?? []}
            trigger={
                <div>
                    <Input ref={ref} style={{cursor: "pointer"}}
                           placeholder={props.placeholder}
                           value={valuesText}
                           readOnly/>
                </div>
            }/>
    }
)
