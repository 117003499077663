import React from "react";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {IWsApiClient} from "../../../../../../../components/WsApiClient/IWsApiClient";
import {LessonPageContext} from "../../../LessonPageContext";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import {SlidesList} from "./SlidesList";
import {StudentSlideViewDetailsType} from "../Common/StudentSlideViewDetailsType";

const Wrapper = styled.div`
`;

interface ResultsBySlidesProps extends PropsFromRedux {
    openStudentSlideViewProc: (data: StudentSlideViewDetailsType) => void;
}

interface ResultsBySlidesState {
}

class StudentLessonResultsBySlides extends React.Component<ResultsBySlidesProps, ResultsBySlidesState> {
    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<ResultsBySlidesProps> | ResultsBySlidesProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        this.state = {}
    }

    render() {
        return <Wrapper>
            {
                (this.context.lessonRoomId)
                && <SlidesList
                    lessonId={this.context.lessonRoomId}
                    openStudentSlideViewProc={this.props.openStudentSlideViewProc}
                />
            }
        </Wrapper>;
    }
}
//
// const mapDispatchToProps = (dispatch: Dispatch) => ({});
//
// const mapStateToProps = ({user, app, layout}: ApplicationState) => ({
//     // userId: (user.profileData?.id) ?? null,
//     // stToken: user.stToken,
//     // apiToken: user.sessionToken
// });

const connector = connect(null, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StudentLessonResultsBySlides);
