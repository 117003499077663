import React from 'react';
import styled from "styled-components";
import {BlockWithBorderCss} from "../../../../styles/global-elements";
import classNames from "classnames";
import {connect, ConnectedProps} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ContentWidthWrapperCss} from "../../../layout/styles";
import {withRouter} from "../../../../components/WithRouterHoc";
import {ListTitle, MembersList} from "./MembersList";
import {SelectedSlideNameWrapper} from "../../../../components/SelectedSlideNameWrapper";
import {LessonPlayerScoreCounter} from "../../../../components/ScoreCounter";
import {PlayerContext} from "../../../../components/SlidePlayer/PlayerContext";
import {SlidesList} from "../../../../components/SlidesList";
import {Trans} from "@lingui/macro";
import {LoadingStateEnum} from "../../../../../store/lessonMaterials/type";
import {DefaultLoader} from "../../../../components/DefaultLoader";

enum ListModeEnum {
    SLIDES,
    STUDENTS
}

const RoomControlBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 78px;
  max-height: calc(100% - ${({theme}) => theme.size.headerHeight.toString(10) + 'px'});
  transform: translateY(100%) translateY(-78px);
  transition: transform 0.3s ease;
  user-select: none;
  z-index: ${({theme}) => theme.zIndices.bottomPageNavigation};

  border-top: 1px solid ${({theme}) => theme.colors.accentDivider};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};

  &.deployed {
    transform: translateY(0);

    @media (${({theme}) => theme.media.large}) {
      transform: unset;
    }
  }

  @media (${({theme}) => theme.media.small}) {
    min-height: 92px;
    transform: translateY(100%) translateY(-92px);
  }

  @media (${({theme}) => theme.media.large}) {
    z-index: unset;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    transform: unset;
    //transition: unset;
    border-top: none;

    max-width: 344px;
    min-width: 344px;

    &.lower {
      transform: translateY(243px);
    }
  }
`;

const RoomControlBlockContentWrapper = styled.div`
  ${ContentWidthWrapperCss};
  margin: auto;
  padding: 0 20px 16px 20px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;

  @media (${({theme}) => theme.media.small}) {
    padding: 0 0 16px 0;
  }

  @media (${({theme}) => theme.media.large}) {
    ${BlockWithBorderCss};
    padding: 11px 0;
    margin: 0;
    display: block;
    position: sticky;
    overflow: unset;
    max-height: unset;
    top: ${({theme}) => 40 + theme.size.headerHeight}px;
  };
`;

const ContentWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (${({theme}) => theme.media.large}) {
    display: block;
    max-height: calc(100vh - 400px);
    margin-right: 6px;
  }
`;

const LessonPlayerScoreCounterWrapper = styled.div`
  margin-bottom: 15px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 0;
    padding: 21px 32px 40px 32px;
  }
`;

const ListSelector = styled(ListTitle)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;
`;

const ListSelectorItem = styled.div`
  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &.selected {
    opacity: 1;
  }
`;

interface RoomControlBlockProps extends PropsFromRedux {
    showLower: boolean;
    className?: string;
    elementForTop?: JSX.Element;
}

interface RoomControlBlockState {
    deployed: boolean;
    showOnBottom: boolean;
    windowBiggerThanSmall: boolean;
    currentListMode: ListModeEnum;
}

class RoomControlBlock extends React.Component<RoomControlBlockProps, RoomControlBlockState> {
    protected mediaQuerySmall;
    protected mediaQueryMedium;
    static contextType = PlayerContext;
    context!: React.ContextType<typeof PlayerContext>;

    constructor(props: Readonly<RoomControlBlockProps> | RoomControlBlockProps) {
        super(props);

        if (this.props.currentTheme === undefined) {
            throw new Error("currentTheme is undefined");
        }

        this.mediaQuerySmall = window.matchMedia(`(${this.props.currentTheme.media.large})`);
        this.mediaQueryMedium = window.matchMedia(`(${this.props.currentTheme.media.small})`);

        this.state = {
            deployed: false,
            showOnBottom: !this.mediaQuerySmall.matches,
            windowBiggerThanSmall: this.mediaQueryMedium.matches,
            currentListMode: ListModeEnum.STUDENTS
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidUpdate(prevProps: Readonly<RoomControlBlockProps>, prevState: Readonly<RoomControlBlockState>, snapshot?: any) {
        // Если задания появились
        if ((prevProps.lessonMaterialsList.length === 0) && (this.props.lessonMaterialsList.length > 0)) {
            this.setState(() => {
                return {
                    currentListMode: ListModeEnum.SLIDES
                }
            })
        }

        // Если задания исчезли
        if ((prevProps.lessonMaterialsList.length > 0) && (this.props.lessonMaterialsList.length === 0)) {
            this.setState(() => {
                return {
                    currentListMode: ListModeEnum.STUDENTS
                }
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
    }

    protected onWindowResize = () => {
        if (this.mediaQuerySmall.matches) {
            if (this.state.showOnBottom) {
                this.setState(() => {
                    return {
                        showOnBottom: false,
                        deployed: false
                    }
                })
            }
        } else {
            if (!this.state.showOnBottom) {
                this.setState(() => {
                    return {
                        showOnBottom: true,
                        deployed: false
                    }
                })
            }
        }

        if (this.mediaQueryMedium.matches) {
            if (!this.state.windowBiggerThanSmall) {
                this.setState(() => {
                    return {
                        windowBiggerThanSmall: true
                    }
                })
            }
        } else {
            if (this.state.windowBiggerThanSmall) {
                this.setState(() => {
                    return {
                        windowBiggerThanSmall: false
                    }
                })
            }
        }
    }

    protected toggleDeployed = () => {
        if (!this.state.showOnBottom) {
            return;
        }

        const newState = !this.state.deployed;

        this.setState((state) => {
            return {
                deployed: newState
            }
        });
    }

    protected getNextSlideId = (): string | null => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return null;
        }

        if (this.context.selectedSlideIndex >= this.context.slides.length - 1) {
            return null;
        }

        if (this.context.slides[this.context.selectedSlideIndex + 1]) {
            return this.context.slides[this.context.selectedSlideIndex + 1].tmSlideId;
        }

        return null;
    }

    protected getPrevSlideId = (): string | null => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return null;
        }

        if (this.context.selectedSlideIndex <= 0) {
            return null;
        }

        if (this.context.slides[this.context.selectedSlideIndex - 1]) {
            return this.context.slides[this.context.selectedSlideIndex - 1].tmSlideId;
        }

        return null;
    }

    protected onSlideSelect = (slideId: string) => {
        this.context.setSelectedSlideId(slideId);
    }

    protected getCurrentSlideName = (): string => {
        if ((this.context.selectedSlideIndex === null) || (this.context.slides === null)) {
            return '';
        }

        return (this.context.slides[this.context.selectedSlideIndex])
            ? (this.context.slides[this.context.selectedSlideIndex].tmSlideName ?? '')
            : '';
    }

    protected selectListMode = (newListMode: ListModeEnum) => {
        this.setState(() => {
            return {
                currentListMode: newListMode
            }
        });
    }

    render() {
        return <RoomControlBlockWrapper className={classNames(
            this.props.className,
            this.state.deployed && "deployed",
            this.props.showLower && "lower"
        )}>
            <RoomControlBlockContentWrapper>
                {
                    this.props.elementForTop
                }
                {
                    (this.state.showOnBottom)
                    && <SelectedSlideNameWrapper nextSlideId={this.getNextSlideId()}
                                                 prevSlideId={this.getPrevSlideId()}
                                                 currentSlideId={this.context.selectedSlideId}
                                                 currentSlideName={this.getCurrentSlideName()}
                                                 onSlideSelect={this.onSlideSelect}
                                                 onClickByName={this.toggleDeployed}
                    />
                    // <RoomControlBlockHeaderWrapper onClick={this.toggleDeployed}>
                    //     <RoomControlBlockHeaderAvatarsWrapper>
                    //         <HorizontalAvatarGroup users={[]}
                    //                                extraLargeAvatars={this.state.windowBiggerThanSmall}/>
                    //     </RoomControlBlockHeaderAvatarsWrapper>
                    //     <RoomControlBlockHeaderBtnWrapper>
                    //         <ArrowDown className={classNames(!this.state.deployed && "up")}/>
                    //     </RoomControlBlockHeaderBtnWrapper>
                    // </RoomControlBlockHeaderWrapper>
                }
                <ContentWrapper>
                    <LessonPlayerScoreCounterWrapper onClick={this.toggleDeployed}>
                        <LessonPlayerScoreCounter onlyProgress={this.state.showOnBottom}/>
                    </LessonPlayerScoreCounterWrapper>
                    <MembersList showStudents={false}/>
                    <ListSelector>
                        {
                            (this.props.lessonMaterialsList.length > 0)
                            && <ListSelectorItem
                                className={classNames(this.state.currentListMode === ListModeEnum.SLIDES && "selected")}
                                onClick={() => this.selectListMode(ListModeEnum.SLIDES)}
                            ><Trans>Задания</Trans></ListSelectorItem>
                        }

                        <ListSelectorItem
                            className={classNames(this.state.currentListMode === ListModeEnum.STUDENTS && "selected")}
                            onClick={() => this.selectListMode(ListModeEnum.STUDENTS)}
                        ><Trans>Ученики</Trans></ListSelectorItem>
                    </ListSelector>

                    {
                        (this.state.currentListMode === ListModeEnum.SLIDES)
                        && (this.props.lessonMaterialsLoadingState === LoadingStateEnum.INIT_LOADING)
                        && <DefaultLoader/>
                    }
                    <div onClick={() => {
                        if (this.state.showOnBottom) {
                            this.toggleDeployed();
                        }
                    }}>
                        {
                            (this.state.currentListMode === ListModeEnum.SLIDES)
                            && (this.props.lessonMaterialsLoadingState !== LoadingStateEnum.INIT_LOADING)
                            && <SlidesList/>
                        }
                        {
                            (this.state.currentListMode === ListModeEnum.STUDENTS)
                            && <MembersList showTeachers={false} noStudentsTitle={true}/>
                        }
                    </div>
                </ContentWrapper>
            </RoomControlBlockContentWrapper>
        </RoomControlBlockWrapper>
    }
}

const mapStateToProps = ({layout, lessonMaterials}: ApplicationState) => ({
    currentTheme: layout.activeTheme,
    lessonMaterialsLoadingState: lessonMaterials.loadingState,
    lessonMaterialsList: lessonMaterials.slidesList
});

const connector = connect(mapStateToProps, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(RoomControlBlock));
