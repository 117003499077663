import {IsBoolean, IsNotEmpty, IsNumber, IsString} from "class-validator";

export class DtoRoomBaseInfoResponse {
  @IsNotEmpty()
  @IsString()
  lessonRoomId: string;

  @IsNotEmpty()
  @IsBoolean()
  hasVideo: boolean;

  @IsNumber()
  expectedNumberOfStudents: number;

  // @IsNotEmpty()
  // participants: DtoRoomParticipant[];
}
