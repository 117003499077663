import {instanceCachingFactory} from 'tsyringe';
import {IMediaDeviceService} from "../services/media-device/IMediaDeviceService";
import {MediaDeviceService} from "../services/media-device/MediaDeviceService";
import {IStoreService} from "../services/store/IStoreService";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";

export default instanceCachingFactory<IMediaDeviceService>((container) => {
    return new MediaDeviceService(
        container.resolve<ILogger>(DiTokens.LOGGER),
        container.resolve<IStoreService>(DiTokens.STORE_SERVICE)
    );
});
