import React, {useMemo} from "react";
import {StringHelper} from "../../../helpers/StringHelper";
import {RouterLink} from "../Ui/Elements/Link";
import {RoutesHelper} from "../../../helpers/RoutesHelper";
import {RoutesList} from "../../RoutesList";
import {PageSectionsEnum} from "../../pages/teacher/student/profile-page/PageSectionsEnum";
import {Trans} from "@lingui/macro";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../Ui/Elements/NoticeBlock";

export interface WillBeAffectedToOtherStudentsStudentItem {
    studentId: number,
    longName: string,
    gender: number
}

interface WillBeAffectedToOtherStudentsProps {
    selectedStudent: WillBeAffectedToOtherStudentsStudentItem;
    students: WillBeAffectedToOtherStudentsStudentItem[];
    className?: string;
}

export const WillBeAffectedToOtherStudents: React.FC<WillBeAffectedToOtherStudentsProps> = (
    {
        selectedStudent,
        students,
        className
    }
) => {
    const studentName = StringHelper.extractFirstName(selectedStudent.longName);

    const anotherStudentLinks = useMemo<JSX.Element[]>(
        () => students.map((studentItem, index) => {
            return <RouterLink
                key={studentItem.studentId.toString(10)}
                target={"_blank"}
                to={RoutesHelper.replaceParams(
                    RoutesList.TEACHER_STUDENT_PROFILE,
                    [
                        {
                            key: 'studentId',
                            value: studentItem.studentId.toString(10)
                        },
                        {
                            key: 'sectionId',
                            value: PageSectionsEnum.GENERAL
                        }
                    ]
                )}
            >{studentItem.longName}{
                (index + 1 < students.length) && <>,&nbsp;</>
            }
            </RouterLink>
        }),
        [students]
    );

    let endingText: JSX.Element | null = null;

    if (students.length === 1) {
        endingText = (students[0].gender === 0)
            ? <Trans>Корректировки окажут влияние и на неё.</Trans>
            : <Trans>Корректировки окажут влияние и на него.</Trans>
    } else {
        endingText = <Trans>Корректировки и на них окажут влияние.</Trans>
    }

    return <NoticeBlock className={className}>
        <NoticeBlockTitle><Trans>Правки повлияют и на других учеников</Trans></NoticeBlockTitle>
        <NoticeBlockText>
            <Trans>{studentName} обучается в группе с</Trans>&nbsp;
            {anotherStudentLinks}.<br/>
            {endingText}
        </NoticeBlockText>
    </NoticeBlock>
}