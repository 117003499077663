import React from "react";
import {DtoLastLessonItem} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLastLessonItem";
import {
    DtoRequestWithPagination
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Common/DtoRequestWithPagination";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {Trans} from "@lingui/macro";
import {BlockWithBorderCss, PageSubtitle} from "../../../../../styles/global-elements";
import {NoticeBlock, NoticeBlockText} from "../../../../../components/Ui/Elements/NoticeBlock";
import styled from "styled-components";
import {debounce, DebouncedFunc} from "lodash";
import {Item, ItemShowMode} from "./Item";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {ApplicationState} from "../../../../../../store";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {connect, ConnectedProps} from "react-redux";
import {ReactComponent as ArrowDown16Icon} from "../../../../../components/Ui/Svg/ArrowDown16.svg";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {CanceledByUser} from "../../../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../../../components/HttpApiClient/Exception/NoConnection";
import {MainPageSection} from "../../style";

const ITEMS_PER_PAGE = 10;

enum ContentLoadingState {
    LOADING,
    ERROR,
    SUCCESS
}

const GridWrapper = styled.div`
  ${BlockWithBorderCss};
  padding: 24px;
`;

const ItemsList = styled.div``;

const LoadMoreItemsWrapper = styled.div`
  display: flex;
  color: ${({theme}) => theme.colors.accentPrimary};
  flex-direction: row;
  cursor: pointer;
  margin-top: 24px;
  align-items: center;
`;

const LoadModeItemsTextWrapper = styled.div`
  align-content: center;
  margin-right: 4px;
`;

const LoadModeItemsIconWrapper = styled.div`
  align-content: center;
  max-height: 16px;
`;

interface LastLessonsProps extends PropsFromRedux {
    currentAgreementId: string;
    presetData: DtoRequestWithPagination<DtoLastLessonItem>;
}

interface LastLessonsState {
    totalItemsCount: number;
    currentPageNum: number;
    moreItemsAvailable: boolean;
    itemsList: Array<DtoLastLessonItem>;
    loadingState: ContentLoadingState;
    windowIsSmall: boolean;
    windowIsLarge: boolean;
}

class LastLessons extends React.Component<LastLessonsProps, LastLessonsState> {
    protected apiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    protected mediaQuerySmall;
    protected mediaQueryLarge;

    protected onWindowResizeDebounced: DebouncedFunc<() => void> | null;

    constructor(props: Readonly<LastLessonsProps> | LastLessonsProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.mediaQuerySmall = window.matchMedia(`(${this.props.currentTheme.media.small})`);
        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);

        this.onWindowResizeDebounced = null;

        this.state = {
            totalItemsCount: this.props.presetData.totalCount,
            currentPageNum: 1,
            moreItemsAvailable: this.props.presetData.totalCount > this.props.presetData.list.length,
            itemsList: this.props.presetData.list,
            loadingState: ContentLoadingState.SUCCESS,
            windowIsSmall: this.mediaQuerySmall.matches,
            windowIsLarge: this.mediaQueryLarge.matches,
        };
    }

    componentDidMount() {
        this.onWindowResizeDebounced = debounce(this.onWindowResize, 300);

        window.addEventListener("resize", this.onWindowResizeDebounced);
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        if (this.onWindowResizeDebounced !== null) {
            window.removeEventListener("resize", this.onWindowResizeDebounced);

            this.onWindowResizeDebounced.cancel();
            this.onWindowResizeDebounced = null;
        }
    }

    protected fetchItems = () => {
        if (this.props.sessionToken === null) {
            return;
        }

        const pageForLoad =
            (this.state.loadingState === ContentLoadingState.ERROR)
                ? this.state.currentPageNum
                : this.state.currentPageNum + 1;

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.setState(() => {
            return {
                loadingState: ContentLoadingState.LOADING
            }
        });

        this.apiClient.getLastLessonsResults(
            this.props.sessionToken,
            this.props.currentAgreementId,
            false,
            true,
            pageForLoad,
            ITEMS_PER_PAGE,
            this.abortController
        )
            .then((data) => {
                this.setState((state) => {
                    const newItemsList = [
                        ...state.itemsList,
                        ...data.data.list
                    ];

                    return {
                        currentPageNum: pageForLoad,
                        loadingState: ContentLoadingState.SUCCESS,
                        itemsList: newItemsList,
                        totalItemsCount: data.data.totalCount,
                        moreItemsAvailable: data.data.totalCount > newItemsList.length
                    }
                })
            })
            .catch((error) => {
                if (error instanceof CanceledByUser) {
                    return;
                }

                const message = 'Cant load additional items for last lessons list';

                if (error instanceof NoConnection) {
                    this.logger.info(LoggerSectionsEnum.SCHOOL_STUDENT_MAIN_PAGE, message, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.SCHOOL_STUDENT_MAIN_PAGE, message, error);
                }

                this.setState(() => {
                    return {
                        loadingState: ContentLoadingState.ERROR,
                        currentPageNum: pageForLoad
                    }
                })
            })
    }

    protected onWindowResize = () => {
        this.setState(() => {
            return {
                windowIsSmall: this.mediaQuerySmall.matches,
                windowIsLarge: this.mediaQueryLarge.matches
            }
        })
    }

    protected noValues = () => {
        return <NoticeBlock>
            <NoticeBlockText>
                <Trans>Значений пока нет. Данные появятся как только вы посетите первый урок.</Trans>
            </NoticeBlockText>
        </NoticeBlock>
    }


    protected items = () => {
        // Представление "в столбик"
        if (!this.state.windowIsSmall) {
            return <ItemsList>
                {
                    this.state.itemsList.map((item, index) => {
                        return <Item showMode={ItemShowMode.SMALL} data={item} key={index}
                                     bottomLine={(
                                         (index < this.state.itemsList.length - 1)
                                         || (this.state.moreItemsAvailable)
                                     )}/>
                    })
                }
            </ItemsList>
        }

        if (this.state.windowIsLarge) {
            return <ItemsList>
                {
                    this.state.itemsList.map((item, index) => {
                        return <Item showMode={ItemShowMode.WIDE} data={item} key={index}
                                     bottomLine={(
                                         (index < this.state.itemsList.length - 1)
                                         || (this.state.moreItemsAvailable)
                                     )}/>
                    })
                }
            </ItemsList>
        }

        if (this.state.windowIsSmall) {
            return <ItemsList>
                {
                    this.state.itemsList.map((item, index) => {
                        return <Item showMode={ItemShowMode.MEDIUM} data={item} key={index}
                                     bottomLine={(
                                         (index < this.state.itemsList.length - 1)
                                         || (this.state.moreItemsAvailable)
                                     )}/>
                    })
                }
            </ItemsList>
        }
    }

    protected content = () => {
        if (this.state.totalItemsCount === 0) {
            return this.noValues();
        }

        return <GridWrapper>
            {this.items()}
            {
                (this.state.loadingState === ContentLoadingState.LOADING)
                && <DefaultLoader/>
            }
            {
                (this.state.loadingState === ContentLoadingState.ERROR)
                && <ErrorLoadingContent retryBtnClick={this.fetchItems}/>
            }
            {
                (this.state.loadingState === ContentLoadingState.SUCCESS) && (this.state.moreItemsAvailable)
                && <LoadMoreItemsWrapper onClick={this.fetchItems}>
                    <LoadModeItemsTextWrapper><Trans>ещё 10</Trans></LoadModeItemsTextWrapper>
                    <LoadModeItemsIconWrapper><ArrowDown16Icon/></LoadModeItemsIconWrapper>
                </LoadMoreItemsWrapper>
            }
        </GridWrapper>;
    }

    render() {
        return <MainPageSection>
            <PageSubtitle><Trans>Прошлые занятия</Trans></PageSubtitle>
            {this.content()}
        </MainPageSection>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    sessionToken: sessionTokenSelector(state),
    currentTheme: state.layout.activeTheme,
});

const connector = connect(mapStateToProps, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LastLessons);
