import {StreamTypeEnum} from "../../../../../components/JanusClient/Types";
import {SubscriptionRequestItem} from "../../Types";

type StreamItem = {
    userId: string;
    streamType: StreamTypeEnum;
    isNew: boolean;
    subscribersIds: string[]
}

export class SubscribersList {
    protected streamList: StreamItem[];

    constructor() {
        this.streamList = [];
    }

    /**
     * Добавляем подписчика, если stream отсутствует в списке - добавляем новый с пометкой isNew.
     *
     * @param userId
     * @param streamType
     * @param subscriberId
     */
    public addSubscriber(userId: string, streamType: StreamTypeEnum, subscriberId: string): void {
        let index = this.findStreamItemIndex(userId, streamType);

        if (index === null) {
            this.streamList.push({
                userId: userId,
                streamType: streamType,
                subscribersIds: [],
                isNew: true
            });

            index = this.streamList.length - 1;
        }

        const subscriberItem = this.streamList[index].subscribersIds.find((item) => item === subscriberId);

        if (!subscriberItem) {
            this.streamList[index].subscribersIds.push(subscriberId);
        }
    }

    /**
     * Удаляем подписчика. Если на stream не осталось подписчиков,
     * ничего не делаем - просто остаётся пустой массив subscribersIds.
     *
     * @param userId
     * @param streamType
     * @param subscriberId
     */
    public removeSubscriber(userId: string, streamType: StreamTypeEnum, subscriberId: string): void {
        let index = this.findStreamItemIndex(userId, streamType);

        if (index === null) {
            return;
        }

        const subscriberItemIndex = this.streamList[index].subscribersIds.findIndex((item) => item === subscriberId);

        if (subscriberItemIndex < 0) {
            return;
        }

        this.streamList[index].subscribersIds.splice(subscriberItemIndex, 1);

        if (this.streamList[index].isNew && this.streamList[index].subscribersIds.length === 0) {
            // Если элемент новый, но уже пустой (перестал быть нужен до вызова flash).
            this.streamList.splice(index, 1);
        }
    }

    /**
     * Получить список новых и устаревших подписок и удалить устаревшую информацию о подписках.
     *
     * В ходе прохода isNew устанавливаются в false, элементы с пустыми subscribersIds удаляются.
     */
    public flushList(): { newSubscriptions: SubscriptionRequestItem[], deprecatedSubscriptions: SubscriptionRequestItem[] } {
        const newItems: SubscriptionRequestItem[] = [];
        const oldItems: SubscriptionRequestItem[] = [];

        if (this.streamList.length > 0) {
            this.streamList.forEach((item, index) => {
                if (item.isNew) {
                    this.streamList[index].isNew = false;

                    newItems.push({
                        userId: item.userId,
                        streamType: item.streamType
                    });

                    return;
                }

                if (item.subscribersIds.length === 0) {
                    oldItems.push({
                        userId: item.userId,
                        streamType: item.streamType
                    });

                    delete this.streamList[index];
                }
            });

            if (oldItems.length > 0) {
                this.streamList = this.streamList.filter(item => item !== undefined);
            }
        }

        return {
            newSubscriptions: newItems,
            deprecatedSubscriptions: oldItems
        }
    }

    protected findStreamItemIndex(userId: string, streamType: StreamTypeEnum): number | null {
        const index = this.streamList.findIndex(
            (item) => ((item.userId === userId) && (item.streamType === streamType))
        );

        return (index > -1) ? index : null;
    }
}
