import {IsOptional, IsString} from "class-validator";

/**
 * DTO ответа на запрос информации о входе ученика в ЛК
 */
export class DtoStudentEntranceInfoResponse {
    @IsString()
    studentEmail: string;

    @IsOptional()
    @IsString()
    id: string | null;

    @IsOptional()
    @IsString()
    registeredAt: string | null;

    @IsOptional()
    @IsString()
    lastSeenAt: string | null;

    @IsOptional()
    @IsString()
    authLink: string | null;

    @IsOptional()
    @IsString()
    authLinkCreatedAt: string | null;

    @IsOptional()
    @IsString()
    avatarFileId: string | null;
}
