import React from "react";
import styled from "styled-components";
import {BlockWithBorderAndPaddingCss} from "../../styles/global-elements";

const Wrapper = styled.div`
    ${BlockWithBorderAndPaddingCss};
`;

export const DefaultLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 100%;
    justify-content: center;
`;

interface ValueWidgetProps {
    children?: React.ReactNode;
    className?: string;
}

export const ValueWidget: React.FC<ValueWidgetProps> = ({className, children}) => {
    return <Wrapper className={className}>
        {children}
    </Wrapper>
}