import React from 'react';
import styled from "styled-components";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ElementIcon} from "../elements/ElementIcon";
import {ElementName} from "../../SlidePlayerEditorCommonParts/ElementName";
import classNames from "classnames";

const ItemStyled = styled.div`
  text-align: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;

  :hover {
    background-color: ${({theme}) => theme.colors.backgroundHover};
  }

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const ItemIconWrapper = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  height: 40px;
`;

const ItemName = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  font-size: 13px;
  word-break: break-word;
  line-height: 1.1em;
`;

interface ItemProps {
    itemType: ElementTypeEnum;
    onClick: () => void;
    disabled?: boolean;
}

export const Item: React.FC<ItemProps> = (props) => {
    const onClick = () => {
        if (!props.disabled) {
            props.onClick();
        }
    }

    return <ItemStyled onClick={onClick} className={classNames(props.disabled && "disabled")}>
        <ItemIconWrapper>
            <ElementIcon elementType={props.itemType}/>
        </ItemIconWrapper>
        <ItemName>
            <ElementName elementType={props.itemType}/>
        </ItemName>
    </ItemStyled>
}