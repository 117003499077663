import {Editor, Element as SlateElement, Element, Node, NodeEntry, Point, Range, Transforms} from "slate";
import {ALLOWED_FOR_EXERCISE_CHECKBOX} from "../Enums";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {ICheckboxListItem} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxListItem";
import {ICheckboxList} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICheckboxList";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const withCheckList = (editor: Editor) => {
    const {normalizeNode, deleteBackward} = editor

    editor.normalizeNode = (entry) => {
        const [node, path] = entry;

        // Проверим - есть ли такой элемент в списке разрешённых
        if (SlateElement.isElement(node)) {
            if (ALLOWED_FOR_EXERCISE_CHECKBOX.indexOf(node.type) < 0) {
                Transforms.setNodes(editor, {type: ElementTypes.PARAGRAPH}, {at: path});
            }
        }

        // Если нормализуется корень, проверим все элементы LIST, чтобы их ID были не пустыми и уникальными
        if (path.length === 0) {
            const idList = new Set();

            const allExercises = Array.from(Editor.nodes(editor, {
                at: [],
                match: (node1, path1) => {
                    if (Editor.isEditor(node1)) {
                        return false;
                    }

                    if (!SlateElement.isElement(node1)) {
                        return false;
                    }

                    return node1.type === ElementTypes.EXERCISE_CHECKBOX_LIST;
                },
                mode: "all"
            }));

            for (const [listElement, listElementPath] of allExercises) {
                const listElementId: string | undefined = (listElement as ICheckboxList).id;

                if (listElementId === undefined) {
                    const newId = ExerciseIdGenerator.generateId();
                    Transforms.setNodes<ICheckboxList>(editor, {id: newId}, {at: listElementPath});
                    idList.add(newId);
                } else {
                    // Если id есть - проверим, чтобы он был уникален
                    if (idList.has(listElementId)) {
                        const newId = ExerciseIdGenerator.generateId();
                        Transforms.setNodes<ICheckboxList>(editor, {id: newId}, {at: listElementPath});
                        idList.add(newId);
                    } else {
                        idList.add(listElementId);
                    }
                }
            }
        }

        // Удалим id у всех элементов, не EXERCISE_CHECKBOX_LIST и EXERCISE_CHECKBOX_LIST_ITEM
        if (
            Element.isElement(node)
            && node.type !== ElementTypes.EXERCISE_CHECKBOX_LIST
            && node.type !== ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM
        ) {
            if ((node as { id?: string }).id !== undefined) {
                Transforms.setNodes<ICheckboxListItem>(editor, {id: undefined}, {at: path})
            }

            if ((node as { checked?: boolean }).checked !== undefined) {
                Transforms.setNodes<ICheckboxListItem>(editor, {checked: undefined}, {at: path})
            }
        }

        // Проверим, чтобы в EXERCISE_CHECKBOX_LIST не существовало дочерних элементов кроме EXERCISE_CHECKBOX_LIST_ITEM
        if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_CHECKBOX_LIST) {
            for (const [child, childPath] of Array.from(Node.children(editor, path))) {
                if (
                    Element.isElement(child)
                    && !editor.isInline(child)
                    && child.type !== ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM
                ) {
                    Transforms.liftNodes(
                        editor,
                        {
                            at: childPath
                        }
                    );
                }
            }
        }

        // Проследим, чтобы EXERCISE_CHECKBOX_LIST всегда имел хотя бы один
        // выбранный верным вариант ответа + все EXERCISE_CHECKBOX_LIST_ITEM имели уникальный ID
        if (Element.isElement(node) && node.type === ElementTypes.EXERCISE_CHECKBOX_LIST) {
            const idList = new Set();
            let firstItem: NodeEntry<ICheckboxListItem> | null = null;
            const checkedItems: NodeEntry<ICheckboxListItem>[] = [];

            for (const [child, childPath] of Array.from(Node.children(editor, path))) {
                const childElement = (child as ICheckboxListItem);

                if (
                    Element.isElement(childElement)
                    && !editor.isInline(childElement)
                    && (childElement.type === ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM)
                ) {

                    if (childElement.id === undefined) {
                        const newId = ExerciseIdGenerator.generateId();
                        Transforms.setNodes<ICheckboxListItem>(editor, {id: newId}, {at: childPath});
                        idList.add(newId);
                    } else {
                        // Если id есть - проверим, чтобы он был уникален
                        if (idList.has(childElement.id)) {
                            const newId = ExerciseIdGenerator.generateId();
                            Transforms.setNodes<ICheckboxListItem>(editor, {id: newId}, {at: childPath});
                            idList.add(newId);
                        } else {
                            idList.add(childElement.id);
                        }
                    }

                    if (firstItem === null) {
                        firstItem = [childElement, childPath];
                    }

                    if ((childElement as ICheckboxListItem).checked) {
                        checkedItems.push([childElement, childPath]);
                    }
                }
            }

            if (checkedItems.length === 0) {
                // Нет выбранных элемнетов. Выбираем первый элемент.
                // Если первый элемент существует.
                if (firstItem !== null) {
                    const [, firstItemPath] = firstItem;
                    Transforms.setNodes<ICheckboxListItem>(editor, {checked: true}, {at: firstItemPath})
                }
            }
        }

        normalizeNode(entry);
    }

    editor.deleteBackward = (...args) => {
        const {selection} = editor

        if (selection && Range.isCollapsed(selection)) {
            const [match] = Array.from(
                Editor.nodes(editor, {
                    match: (node) =>
                        !Editor.isEditor(node) &&
                        Element.isElement(node) &&
                        node.type === ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM,
                })
            );

            if (match) {
                const [, path] = match
                const start = Editor.start(editor, path)

                if (Point.equals(selection.anchor, start)) {
                    const newProperties: Partial<Element> = {
                        type: ElementTypes.PARAGRAPH,
                    }

                    Transforms.setNodes(
                        editor,
                        newProperties,
                        {
                            at: path
                        }
                    );

                    return
                }
            }
        }

        deleteBackward(...args)
    }

    return editor
}