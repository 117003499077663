import {IsNotEmpty, IsNumber, IsString} from "class-validator";

/**
 * DTO ответа на запрос информации о занятиях на дату
 */
export class DtoPlanLessonsForDateResponse {
    @IsNotEmpty()
    @IsNumber()
    type: number;

    @IsNotEmpty()
    @IsString()
    lessonDate: string;

    @IsNotEmpty()
    @IsString()
    timeStart: string;

    @IsNotEmpty()
    @IsString()
    timeEnd: string;

    @IsNotEmpty()
    @IsString()
    breakTime: string;

    @IsNotEmpty()
    @IsNumber()
    duration: number;
}