import React, {useMemo} from "react";
import {MainPageSection} from "../../style";
import {CurrentTimeLine} from "./CurrentTimeLine";
import styled from "styled-components";
import {PageTitle} from "../../../../../styles/global-elements";
import {GreetingText} from "./GreetingText";
import {StringHelper} from "../../../../../../helpers/StringHelper";
import {useSelector} from "react-redux";
import {selectedUserInSchool as selectedUserInSchoolSelector} from "../../../../../../store/user/selector";

const PageTitleStyled = styled(PageTitle)`
    text-align: center;
    margin-bottom: 0;
`;

export const Header: React.FC = () => {

    const selectedUserInSchool = useSelector(selectedUserInSchoolSelector);

    const userName = useMemo(() => {
        if (selectedUserInSchool) {
            return StringHelper.extractFirstName(selectedUserInSchool.name);
        }

        return null;
    }, [selectedUserInSchool]);

    return <MainPageSection>
        <CurrentTimeLine/>

        <PageTitleStyled>
            <GreetingText userName={userName}/>
        </PageTitleStyled>
    </MainPageSection>
}