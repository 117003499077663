import {IsBoolean, IsNotEmpty, IsNumber, IsString} from "class-validator";

/**
 * DTO элемента ответа на запрос поиска преподавателя по имени
 */
export class DtoTeacherSearchResponseItem {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsString()
    longName: string;

    @IsBoolean()
    isActive: boolean;
}