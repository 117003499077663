import {call, put, select, takeEvery} from 'redux-saga/effects';
import {SetSelectedUserIdReducerInput, UserActionTypes, UserProfileType} from "../../store/user/type";
import * as UserActionCreators from "../../store/user/actions";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {ApplicationState} from "../../store";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {
    UserProfileDataDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserProfileDataDto";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {selectedAgreement} from "../../store/user/selector";

/**
 * Сага отвечает за запуск установки активного договора
 */
export function* watchActivateAgreementId() {
    yield takeEvery(
        UserActionTypes.ACTIVATE_AGREEMENT_ID,
        activateAgreementId
    );
}

const userProfileTypeSelector = ({user}: ApplicationState) => user.profileType;
const userProfilesSelector = ({user}: ApplicationState) => user.profileData;
const sessionTokenSelector = (state: ApplicationState) => state.user.sessionToken;

function* activateAgreementId(data: WorkerPayloadType<SetSelectedUserIdReducerInput>) {
    const schoolId = data.payload.schoolId;
    const userInSchoolId = data.payload.userInSchoolId;
    const agreementId = data.payload.agreementId;

    // 1. Проверим - есть ли такой договор у пользователя
    const userProfileType = (yield select(userProfileTypeSelector)) as UserProfileType | null;
    const userProfiles = (yield select(userProfilesSelector)) as UserProfileDataDto | null;

    if (
        (userProfiles === null)
        || (!Array.isArray(userProfiles.schools))
        || (userProfiles.schools.length === 0)
    ) {
        return;
    }

    const schoolsCount = userProfiles.schools.length;

    let targetUserInSchoolAccountWasFound = false;
    let targetAgreementWasFound = false;

    firstLoop:
        for (let schoolIndex = 0; schoolIndex < schoolsCount; schoolIndex++) {
            if (userProfiles.schools[schoolIndex].id === schoolId) {
                if (
                    (!Array.isArray(userProfiles.schools[schoolIndex].accounts))
                    || (userProfiles.schools[schoolIndex].accounts.length === 0)
                ) {
                    continue;
                }

                const currentSchoolProfile = userProfiles.schools[schoolIndex];

                if (
                    (!Array.isArray(currentSchoolProfile.accounts))
                    || (currentSchoolProfile.accounts.length === 0)
                ) {
                    continue;
                }

                const userInSchoolCount = currentSchoolProfile.accounts.length;

                for (let userInSchoolIndex = 0; userInSchoolIndex < userInSchoolCount; userInSchoolIndex++) {
                    if (currentSchoolProfile.accounts[userInSchoolIndex].id === userInSchoolId) {

                        targetUserInSchoolAccountWasFound = true;

                        if (userProfileType === UserProfileType.TEACHER) {
                            // У преподавателя нет договоров - можно выходить.
                            break firstLoop;
                        }

                        const currentUserInSchoolProfile = currentSchoolProfile.accounts[userInSchoolIndex];

                        if (
                            (!Array.isArray(currentUserInSchoolProfile.agreements))
                            || (currentUserInSchoolProfile.agreements.length === 0)
                        ) {
                            continue;
                        }

                        const userAgreementsCount = currentUserInSchoolProfile.agreements.length;

                        for (let userAgreementsIndex = 0; userAgreementsIndex < userAgreementsCount; userAgreementsIndex++) {
                            if (currentUserInSchoolProfile.agreements[userAgreementsIndex].id === agreementId) {
                                targetAgreementWasFound = true;

                                break firstLoop;
                            }
                        }
                    }
                }
            }
        }


    if (
        ((userProfileType === UserProfileType.STUDENT) && (!targetAgreementWasFound))
        || ((userProfileType === UserProfileType.TEACHER) && (!targetUserInSchoolAccountWasFound))
    ) {
        return;
    }

    const prevAgreement = (yield select(selectedAgreement)) as string | null;

    // 2. Значения существуют, задаём из в store
    yield put(UserActionCreators.setSelectedAgreementId({schoolId, userInSchoolId, agreementId}));

    if ((userProfileType === UserProfileType.TEACHER) || (agreementId === null)) {
        // Если это профиль учителя, выходим - сохранять выбранное состояние не нужно
        return;
    }

    // 3. Если это профиль ученика, то сохраняем выбранный договор в API
    if (prevAgreement !== null) {
        const sessionToken = (yield select(sessionTokenSelector)) as string | null;

        if (sessionToken === null) {
            return;
        }

        const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);
        const apiClient: IHttpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        try {
            yield call(
                () => apiClient.setActiveAgreement(sessionToken, agreementId)
            )
        } catch (e) {
            logger.error(LoggerSectionsEnum.UPDATE_ACTIVE_AGREEMENT_ID, e);
        }
    }
}