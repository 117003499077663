import React, {forwardRef} from "react";
import {Input} from "../../TextInput";
import {t} from "@lingui/macro";
import {TmOrganizationSelectModalWindow, TmOrganizationSelectorItem} from "./TmOrganizationSelectModalWindow";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {InputRef} from "antd/lib/input/Input";

interface TmOrganizationSelectorProps {
    value?: TmOrganizationSelectorItem;
    onChange?: (value: TmOrganizationSelectorItem) => void;
}

export const TmOrganizationSelector = forwardRef<InputRef, TmOrganizationSelectorProps>(
    (props, ref) => {
        const apiToken = useSelector(sessionTokenSelector);

        return <TmOrganizationSelectModalWindow
            apiToken={apiToken}
            trigger={
                <Input ref={ref} style={{cursor: "pointer"}} placeholder={t`Выберите владельца (издателя)`}
                       value={props.value?.name}
                       readOnly/>}
            onChange={(newValue) => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue);
                }
            }}/>
    }
)