import styled from "styled-components";
import {CustomComponentProps} from "../RichTextEditor";
import {ElementProps} from "../rich-text-editor/Element";
import React, {CSSProperties, useMemo} from "react";
import {RegularLi} from "./General";
import {IRadioListItem} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioListItem";
import {ReactEditor} from "slate-react";
import {Transforms} from "slate";
import {RadioIcon} from "../../../../Ui/Elements/RadioIcon";

const CheckedIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: -3px;
  left: -40px;
  height: 40px;
  width: 40px;
`;

interface Props extends ElementProps {
    style: CSSProperties;
}

export const RadioListItem: React.FC<Props> = (props) => {
    const {
        attributes,
        style,
        element,
        liCustomComponent,
        children,
        editor
    } = props;

    const elementData = element as IRadioListItem;


    const LiComponentMemo = useMemo<React.FC<CustomComponentProps>>(() => {
        if (liCustomComponent) {
            return liCustomComponent;
        }

        return RegularLi;
    }, [liCustomComponent]);

    const setItemChecked = () => {
        // Укажем, что собрались включить этот элемент.
        // Он включится в ходе нормализации элементов.
        const path = ReactEditor.findPath(editor, element);

        const newProperties: Partial<IRadioListItem> = {
            needCheck: true
        }

        Transforms.setNodes(editor, newProperties, {at: path});
    };

    return <LiComponentMemo style={{...style, position: "relative"}} {...attributes}>
        <CheckedIcon contentEditable={false} onClick={setItemChecked}>
            <RadioIcon checked={elementData.checked}/>
        </CheckedIcon>
        {children}
    </LiComponentMemo>;
}