import {IsArray, IsIn, IsNotEmpty, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import {DtoFileUrlWithFormat} from "./DtoFileUrlWithFormat";

/**
 * DTO ответа на запрос url для скачивания файла
 */
export class DtoUrlForDownloadResponse {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    fileId: string;

    @IsNotEmpty()
    @IsString()
    @IsIn(Object.values(UserFileTypeEnum))
    fileType: UserFileTypeEnum;

    @IsNotEmpty()
    @IsArray()
    links: DtoFileUrlWithFormat[];
}
