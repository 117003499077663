import {putResolve, takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {setUtmForSave} from "../../store/commonPersisted/actions";

/**
 * Сага отвечает за сбор utm меток в самом начале загрузки приложения
 */
export function* watchCollectUtm() {
    yield takeEvery(
        AppActionTypes.COLLECT_UTM,
        collectUtm
    );
}

function* collectUtm() {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.UTM_COLLECTOR, 'Start collecting utm');

    const urlParams = new URLSearchParams(window.location.search);

    const paramKeys = Array.from(urlParams.keys());

    const utmForCollect: { [id: string]: string } = {};

    let hasValues = false;

    for (const paramKey of paramKeys) {
        if (paramKey.slice(0, 3) === 'utm') {
            const value = urlParams.get(paramKey);

            if (value !== null) {
                utmForCollect[paramKey] = value;

                if (!hasValues) {
                    hasValues = true;
                }
            }
        }
    }

    if (hasValues) {
        utmForCollect['routeName'] = window.location.pathname;

        yield putResolve(setUtmForSave(utmForCollect));
    }
}
