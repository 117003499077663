import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {
    DtoTmOrganization
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {CreateNewOrganizationItem} from "./CreateNewOrganizationItem";
import {OrganizationItem} from "./OrganizationItem";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {t} from "@lingui/macro";
import {LoadMoreOrganizationsItem} from "./LoadMoreOrganizationsItem";

const OrganizationListWrapper = styled.div`
`;

const OrganizationList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

interface OrganizationsListProps {
    organizations: DtoTmOrganization[] | null;
    setOrganizations: React.Dispatch<React.SetStateAction<DtoTmOrganization[] | null>>;
    selectedOrganizationId: string | null;
    setSelectedOrganizationId: (value: string | null) => void;
}

export const OrganizationsList: React.FC<OrganizationsListProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [paginationPagesLoaded, setPaginationPagesLoaded] = useState<number>(0);
    const [totalOrganizationItems, setTotalOrganizationItems] = useState<number>(0);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {organizations, setOrganizations} = {...props};

    const loadContent = useCallback((page?: number) => {
        if (!sessionToken) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        if (page === undefined) {
            page = 1;
        }

        httpApiClient.tmGetOrganizations(sessionToken, page, 20, new AbortController())
            .then((data) => {
                setOrganizations((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setPaginationPagesLoaded(data.data.page);
                setTotalOrganizationItems(data.data.totalCount);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, 'Error on loading organizations list: ', err);

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [sessionToken, httpApiClient, setOrganizations, logger]);

    // Начальная загрузка организации
    useEffect(() => {
        if (organizations !== null) {
            return;
        }

        loadContent(1);
    }, [organizations, loadContent]);


    const organizationOnClick = (item: DtoTmOrganization) => {
        props.setSelectedOrganizationId(item.id);
    }

    const organizationList = () => {
        if (nowLoading) {
            return <DefaultLoader/>;
        }

        let listItems: null | JSX.Element[] = null;

        if (props.organizations !== null) {
            listItems = props.organizations.map((item) => {
                return <OrganizationItem active={item.id === props.selectedOrganizationId}
                                         key={item.id}
                                         item={item}
                                         onClick={() => organizationOnClick(item)}/>
            });
        }

        return (<OrganizationList>
            {listItems}
            {((props.organizations !== null) && (totalOrganizationItems > props.organizations.length)) ?
                <LoadMoreOrganizationsItem onClick={() => loadContent(paginationPagesLoaded + 1)}/> : null}
            <CreateNewOrganizationItem onItemCreated={() => props.setOrganizations(null)}/>
        </OrganizationList>)
    }

    return <OrganizationListWrapper>
        {
            (loadingError)
                ? <ErrorLoadingContent
                    title={t`Не удалось загрузить список организаций`}
                    retryBtnClick={() => loadContent()}
                />
                : organizationList()
        }
    </OrganizationListWrapper>
}