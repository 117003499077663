import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    color: ${({theme}) => theme.colors.textPrimary};
    font-size: 18px;
    line-height: 18px;

    @media (${({theme}) => theme.media.small}) {
        font-size: 24px;
        line-height: 28px;
    }
`;

export const MainValue: React.FC = ({children}) => {
    return <Wrapper>
        {children}
    </Wrapper>
}