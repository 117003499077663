import {transformAndValidate} from "class-transformer-validator";
import {ValidationError} from "class-validator";
import {ClassType} from "../../types/ClassType";
import {FieldValidationErrorsList} from "./FieldValidationErrorsList";
import {FieldValidationException} from "./FieldValidationException";

/**
 * Класс - валидатор моделей.
 */
export class ModelValidator {
    static async validateAndTransformRawRequestData<T extends object>(
        classType: ClassType<T>,
        rawRequestObject: object,
    ): Promise<T> {
        try {
            let resultObject: T | Array<T>;

            if (rawRequestObject === undefined) {
                rawRequestObject = {};
            }

            resultObject = await transformAndValidate(classType, rawRequestObject);

            if (Array.isArray(resultObject)) {
                return resultObject[0];
            }

            return resultObject;
        } catch (errorsArray: any) {
            throw new FieldValidationException(ModelValidator.collectErrors(errorsArray));
        }
    }

    static collectErrors(errorsArray: any, parentPropertyName?: string): FieldValidationErrorsList {
        let errorsList: FieldValidationErrorsList = {};

        errorsArray.forEach((field: ValidationError) => {
            let fieldErrors: Array<string> = [];

            const prefix = (parentPropertyName) ? parentPropertyName + '.' : '';

            if (field.constraints) {
                for (let validatorName in field.constraints) {
                    fieldErrors.push(prefix + field.constraints[validatorName]);
                }

                errorsList[prefix + field.property] = fieldErrors;
            } else {
                // Похоже, что ошибки в дочернем элементе
                if (field.children !== undefined) {
                    errorsList = {
                        ...errorsList,
                        ...ModelValidator.collectErrors(field.children, prefix + field.property)
                    }
                }
            }
        });

        return errorsList;
    }
}
