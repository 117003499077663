import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {
    audioRoomConnectionInitState,
    AudioRoomConnectionState,
    ConferenceConnectionParams,
    ConferenceModeEnum,
    ParticipantItem,
    videoRoomConnectionInitState,
    VideoRoomConnectionState
} from "./Types";
import {cloneDeep} from "lodash";
import {CommonContextProvider, ICommonContext, LessonConferenceStatusEnum} from "./contexts/CommonContext";
import {AudioRoomContextProvider, IAudioRoomContext} from "./contexts/AudioRoomContext";
import {IVideoRoomContext, VideoRoomContextProvider} from "./contexts/VideoRoomContext";
import {AudioRoomActionsProvider} from "./actions/audio-room";
import {VideoRoomActionsProvider} from "./actions/video-room";
import {VideoControlPanel} from "./VideoControlPanel";
import styled from "styled-components";
import {RoomCommonActionsProvider} from "./actions/common";
import {MainZone} from "./MainZone";
import {VcsConnectionActionsProvider} from "./actions/vcs-connection";
import {ScreenSaverLocker} from "./ScreenSaverLocker";
import {AudioRoomStudentListActions} from "./actions/audio-room/AudioRoomStudentListActions";
import {useDispatch, useSelector} from "react-redux";
import {roomMembersSelector} from "../../../store/lessonRoom/selector";
import {DocumentInPictureActionsProvider} from "./actions/document-in-picture";
import {VideoSubscriptionsActionsProvider} from "./actions/video-subscriptions";
import {setAudioRoomState, setParticipants, setStatus, setVideoRoomState} from "../../../store/vcsState/actions";
import {FullScreenPublisher} from "./actions/ws-publishers/FullScreenPublisher";
import {ScreenOrientationPublisher} from "./actions/ws-publishers/ScreenOrientationPublisher";
import {SelectedMemberPublisher} from "./actions/ws-publishers/SelectedMemberPublisher";

const VideoControlPanelStyled = styled(VideoControlPanel)`
  margin-bottom: 14px;

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 32px;
  }
`;

interface LessonConferenceProps {
    className?: string;
    lessonId: string;
    wsRoomIsConnected: boolean;
    selectedMemberId: string | null;
    onMemberSelect: (memberId: string | null) => void;
}

export interface LessonConferenceMethods {
    startConnection: (roomConnectionParams: ConferenceConnectionParams) => void;
    stopConnection: () => void;
    emergencyShutdown: () => void;
    getCurrentMode: () => ConferenceModeEnum;
    setCurrentMode: (newMode: ConferenceModeEnum) => void;
    getMainZoneObj: () => HTMLDivElement | null;
}

export const LessonConference = forwardRef<LessonConferenceMethods, LessonConferenceProps>(
    (props, ref) => {
        const dispatch = useDispatch();

        const roomWsMembers = useSelector(roomMembersSelector);

        const mainZoneWrapperRef = useRef<HTMLDivElement>(null);
        const mainZoneRef = useRef<HTMLDivElement>(null);

        const [lessonConferenceState, setLessonConferenceState] = useState<LessonConferenceStatusEnum>(LessonConferenceStatusEnum.NOT_INIT);
        const [conferenceConnectionParams, setConferenceConnectionParams] = useState<ConferenceConnectionParams | null>(null);
        const [currentMode, setCurrentMode] = useState<ConferenceModeEnum>(ConferenceModeEnum.NORMAL);
        const [conferenceParticipants, setConferenceParticipants] = useState<ParticipantItem[]>([]);
        const [audioRoomConnectionState, setAudioRoomConnectionState] = useState<AudioRoomConnectionState>(() => cloneDeep(audioRoomConnectionInitState));
        const [videoRoomConnectionState, setVideoRoomConnectionState] = useState<VideoRoomConnectionState>(() => cloneDeep(videoRoomConnectionInitState));

        const commonContextValue = useMemo<ICommonContext>(() => ({
            conferenceParticipants,
            currentMode,
            lessonConferenceState,
            conferenceConnectionParams,
            lessonId: props.lessonId,
            roomWsMembers,
            wsRoomIsConnected: props.wsRoomIsConnected,
            selectedMemberId: props.selectedMemberId,
            onMemberSelect: props.onMemberSelect
        }), [
            conferenceParticipants,
            currentMode,
            lessonConferenceState,
            conferenceConnectionParams,
            props.lessonId,
            props.wsRoomIsConnected,
            props.selectedMemberId,
            props.onMemberSelect,
            roomWsMembers
        ]);

        const audioRoomContextValue = useMemo<IAudioRoomContext>(() => ({
            audioRoomConnectionState
        }), [audioRoomConnectionState]);

        const videoRoomContextValue = useMemo<IVideoRoomContext>(() => ({
            videoRoomConnectionState
        }), [videoRoomConnectionState]);

        const showMainZone = useMemo<boolean>(() => {
            return !(
                (lessonConferenceState === LessonConferenceStatusEnum.NOT_INIT)
                || (lessonConferenceState === LessonConferenceStatusEnum.ON_DESTROY)
            );
        }, [lessonConferenceState]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            startConnection: (roomConnectionParams: ConferenceConnectionParams) => {
                setConferenceConnectionParams(roomConnectionParams);

                setCurrentMode(ConferenceModeEnum.NORMAL);
                setAudioRoomConnectionState(cloneDeep(audioRoomConnectionInitState));
                setVideoRoomConnectionState(cloneDeep(videoRoomConnectionInitState));

                setLessonConferenceState(LessonConferenceStatusEnum.INIT_DEVICES);
            },
            stopConnection: () => {
                setLessonConferenceState(LessonConferenceStatusEnum.ON_DESTROY);
            },
            emergencyShutdown: () => {
                setLessonConferenceState(LessonConferenceStatusEnum.CONNECTION_ERROR_WAIT_NETWORK);
            },
            getCurrentMode: (): ConferenceModeEnum => {
                return currentMode;
            },
            setCurrentMode: (newMode: ConferenceModeEnum) => {
                setCurrentMode(newMode)
            },
            getMainZoneObj: (): HTMLDivElement | null => {
                return mainZoneRef.current;
            }
        }));

        // Публикация значений в store
        useEffect(() => {
            dispatch(setStatus(lessonConferenceState));
        }, [dispatch, lessonConferenceState]);

        useEffect(() => {
            dispatch(setParticipants(conferenceParticipants));
        }, [dispatch, conferenceParticipants]);

        useEffect(() => {
            dispatch(setAudioRoomState(audioRoomConnectionState));
        }, [dispatch, audioRoomConnectionState]);

        useEffect(() => {
            dispatch(setVideoRoomState(videoRoomConnectionState));
        }, [dispatch, videoRoomConnectionState]);

        return <CommonContextProvider value={commonContextValue}>
            <AudioRoomContextProvider value={audioRoomContextValue}>
                <VideoRoomContextProvider value={videoRoomContextValue}>
                    <RoomCommonActionsProvider
                        setLessonConferenceState={setLessonConferenceState}
                        setConferenceParticipants={setConferenceParticipants}
                        setCurrentConferenceMode={setCurrentMode}
                    >
                        <DocumentInPictureActionsProvider
                            mainZoneRef={mainZoneRef}
                            mainZoneWrapperRef={mainZoneWrapperRef}>
                            <VcsConnectionActionsProvider>
                                <AudioRoomStudentListActions>
                                    <AudioRoomActionsProvider setAudioRoomConnectionState={setAudioRoomConnectionState}>
                                        <VideoRoomActionsProvider
                                            setVideoRoomConnectionState={setVideoRoomConnectionState}>
                                            <VideoSubscriptionsActionsProvider>
                                                {
                                                    showMainZone && <>
                                                        {
                                                            (currentMode !== ConferenceModeEnum.FULL_SCREEN)
                                                            && <VideoControlPanelStyled/>
                                                        }
                                                    <div ref={mainZoneWrapperRef}>
                                                      <div ref={mainZoneRef}>
                                                        <MainZone className={props.className}/>
                                                      </div>
                                                    </div>
                                                    <ScreenSaverLocker/>
                                                  </>
                                                }
                                                {
                                                    (props.wsRoomIsConnected)
                                                    && <>
                                                    <FullScreenPublisher/>
                                                    <ScreenOrientationPublisher/>
                                                    <SelectedMemberPublisher/>
                                                  </>
                                                }
                                            </VideoSubscriptionsActionsProvider>
                                        </VideoRoomActionsProvider>
                                    </AudioRoomActionsProvider>
                                </AudioRoomStudentListActions>
                            </VcsConnectionActionsProvider>
                        </DocumentInPictureActionsProvider>
                    </RoomCommonActionsProvider>
                </VideoRoomContextProvider>
            </AudioRoomContextProvider>
        </CommonContextProvider>
    }
)
