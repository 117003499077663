import * as React from "react";
import {
    DtoTmOrganization
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {SelectedItemTitle} from "../index";
import {ModalResultType, TmOrganizationModal} from "./modal";

interface OrganizationTitleItemProps {
    item: DtoTmOrganization;
    organizations: DtoTmOrganization[] | null;
    setOrganizations: (value: DtoTmOrganization[] | null) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const OrganizationTitleItem: React.FC<OrganizationTitleItemProps> = (props) => {

    const updateItemInOrganizationsPropsList = (newModel: DtoTmOrganization) => {
        if (props.organizations === null || props.setOrganizations === null) {
            return;
        }

        const newOrganizationsList = props.organizations.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        props.setOrganizations(newOrganizationsList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromOrganizationsPropsList = () => {
        if (props.organizations === null || props.setOrganizations === null) {
            return;
        }

        const newOrganizationsList = props.organizations.filter((item) => (
            (props.item.id !== item.id)
        ));

        props.setOrganizations(newOrganizationsList);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmOrganization | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after organization updated.');
            }

            updateItemInOrganizationsPropsList(data);

            return;
        }

        deleteCurrentItemFromOrganizationsPropsList();
    }

    return (
        <TmOrganizationModal
            organizationItem={props.item}
            result={onResult}
            trigger={<SelectedItemTitle>{props.item.name}</SelectedItemTitle>}/>
    );
}