import React, {useContext} from "react";
import styled from "styled-components";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const ImageWrapper = styled.div`
  text-align: center;

  @media (${({theme}) => theme.media.small}) {
    text-align: left;
  }
`;

const Image = styled.img`
  box-shadow: 0px 4px 50px 0px rgba(25, 121, 255, 0.50);
  width: 80%;
  height: auto;

  @media (${({theme}) => theme.media.small}) {
    height: 343px;
    width: auto;
  }
`;

const ButtonWrapper = styled.div`
  text-align: right;
`;

const ButtonStyled = styled(Button)`
  width: 100%;

  @media (${({theme}) => theme.media.small}) {
    width: auto;
  }
`;

export const WelcomeSection: React.FC = () => {
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const onFinish = () => {
        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.WELCOME,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        )
    }

    return <div>
        <FormWrapper>
            <PageSubtitleSmallMargin>
                <Trans>«Мои занятия» - платформа, с которой учителя:</Trans>
            </PageSubtitleSmallMargin>
            <RegularText>
                <Trans><b>Создают цифровые обучающие материалы</b>, включающие упражнения с автопроверкой и используют
                    их для своих онлайн и оффлайн уроков на платформе, а также задают их в качестве домашней
                    работы.</Trans>
            </RegularText>
            <br/>
            <RegularText>
                <Trans>Результат выглядят классно и на мобильном и с тёмной темой.</Trans>
            </RegularText>
            <br/>
            <br/>
            <ImageWrapper>
                <Image src={'/img/tm-on-themes.png'}/>
            </ImageWrapper>
            <br/>
            <br/>
            <RegularText>
                <Trans>«Мои занятия» - за доступное современное образование. Платформа доступна для репетиторов и
                    благотворительных организаций без обязательных платежей.</Trans>
            </RegularText>
        </FormWrapper>
        <ButtonWrapper>
            <ButtonStyled
                btnStyle={BtnStyleEnum.Primary}
                onClick={onFinish}
            >Дальше</ButtonStyled>
        </ButtonWrapper>
    </div>;
}