export enum ElementTypes {
    PARAGRAPH = 'paragraph',
    BLOCK_QUOTE = 'block-quote',
    BULLETED_LIST = 'bulleted-list',
    NUMBERED_LIST = 'numbered-list',
    HEADING_ONE = 'heading-one',
    HEADING_TWO = 'heading-two',
    LIST_ITEM = 'list-item',
    TRANSLATED = 'translated',
    EXERCISE_FILL_GAPS_COMBOBOX = 'exercise_fill_gaps_combobox',
    EXERCISE_FILL_GAPS_TEXT = 'exercise_fill_gaps_text',
    EXERCISE_CORRECT_EXIST_IN_INPUT = 'exercise_correct_exist_in_input',
    EXERCISE_FILL_GAPS_DRAG_DROP = 'exercise_fill_gaps_drag_drop',
    EXERCISE_RADIO_LIST = 'exercise_radio_list',
    EXERCISE_RADIO_LIST_ITEM = 'exercise_radio_list_item',
    EXERCISE_CHECKBOX_LIST = 'exercise_checkbox_list',
    EXERCISE_CHECKBOX_LIST_ITEM = 'exercise_checkbox_list_item',
}