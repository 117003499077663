import {IsBoolean, IsOptional, IsString, Length, ValidateNested} from "class-validator";
import {LengthEnum} from "../../../Enums/LengthEnum";
import {Type} from "class-transformer";
import {DtoHomeworkAverageResults} from "./DtoHomeworkAverageResults";

/**
 * DTO ответа на запрос общей информации о домашнем задании
 */
export class DtoHomeworkSummaryResponse {
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    lessonId: string;

    @IsBoolean()
    hasExercises: boolean;

    @IsBoolean()
    workCompleted: boolean;

    @IsOptional()
    @ValidateNested()
    @Type(() => DtoHomeworkAverageResults)
    averageResults: DtoHomeworkAverageResults | null;
}
