export enum ResponseStatusEnum {
    SUCCESS = 1,
    UNKNOWN_MODULE = 2,
    VALIDATION_ERROR = 3,
    UNKNOWN_METHOD = 4,
    TOKEN_CHECK = 5,
    PERMISSION_DENIED = 6,
    IP_BLOCKED = 7,
    UNKNOWN_ERROR = 8,
    DATABASE_BLOCKED = 9,
    ACTION_ERROR_DATA_NOT_FOUND = 10,
    RECORD_IS_READ_ONLY = 16,
    NOT_ENOUGH_MONEY = 17,
    METHOD_NOT_SUPPORTED_APPS = 18
}
