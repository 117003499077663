import {action} from "typesafe-actions";

import {StreamEventActionTypes, StreamEventItemState, StreamEventStoreItem} from "./type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {AuthUserRequestContext} from "../user/type";
import {StreamEventBaseDto} from "../../components/WsApiClient/ApiDto/Response/StreamEvent/StreamEventBaseDto";
import {ListDto} from "../../components/WsApiClient/ApiDto/Response/ListDto";

export const incomingEvent = (baseDto: ResponseActionCreatorPayload<ResponseBaseDto<StreamEventBaseDto<object | null>>, AuthUserRequestContext>) => {
    return action(StreamEventActionTypes.INCOMING_EVENT, baseDto);
};

export const loadEventsAfterReconnect = () => {
    return action(StreamEventActionTypes.LOAD_EVENTS_AFTER_RECONNECT);
};

export const processEventsResponseBatch = (value: ResponseActionCreatorPayload<ResponseBaseDto<ListDto<StreamEventBaseDto<object | number>>>, null>) => {
    return action(StreamEventActionTypes.PROCESS_EVENTS_RESPONSE_BATCH, value);
};

export const pushEventsToList = (event: Array<StreamEventStoreItem<object | null>>) => {
    return action(StreamEventActionTypes.PUSH_EVENTS_TO_LIST, event);
};

export const startHandlingEvents = () => {
    return action(StreamEventActionTypes.START_HANDLING_EVENTS);
};

export const stopHandlingEvents = () => {
    return action(StreamEventActionTypes.STOP_HANDLING_EVENTS);
};

export const setEventState = (eventId: string, newState: StreamEventItemState) => {
    return action(StreamEventActionTypes.SET_EVENT_STATE, {eventId: eventId, newState: newState});
};

export const setAppIsReadyToHandleItemsValue = (value: boolean) => {
    return action(StreamEventActionTypes.SET_HANDLE_EVENTS_ENABLED, value);
};

export const startHandleEventCycle = () => {
    return action(StreamEventActionTypes.START_HANDLE_EVENT_CYCLE);
};

export const deleteHandledEvents = () => {
    return action(StreamEventActionTypes.DELETE_HANDLED_EVENTS);
};

export const setLastEventDate = (value: string) => {
    return action(StreamEventActionTypes.SET_LAST_EVENT_DATE, value);
};