export enum SlidePlayerIdEnum {
    TEACHER_LESSON_TEST = 'teacher/lessonTest/:lessonId',

    STUDENT_SLIDE_ON_LESSON = 'student/slideOnLesson/:lessonId',

    STUDENT_SLIDE_ON_HOMEWORK = 'student/slideOnHomework/:lessonId',

    TEACHER_STUDENT_SLIDE_ON_LESSON = 'teacher/studentSlideOnLesson/:lessonId/:studentUserId',

    TEACHER_STUDENT_SLIDE_ON_HOMEWORK = 'teacher/studentSlideOnHomework/:lessonId/:studentUserId',

    SELF_STUDY_TRACK_EPISODE = 'self-study-track/:episodeId'
}
