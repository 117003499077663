import {
    IsString,
    Length,
    IsNotEmpty,
    IsBoolean,
    IsNumber,
    Min,
    Max,
    ValidateNested, IsOptional
} from 'class-validator';
import {LengthEnum} from '../../../../Enums/LengthEnum';
import {UserAgreementDto} from "./UserAgreementDto";

/**
 * DTO пользователя в школе
 */
export class UserInSchoolAccountDto {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsOptional()
    @IsNumber()
    stId: number | null;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.USER_NAME_LENGTH)
    name: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_DATE_FORMAT_LENGTH, LengthEnum.DEFAULT_DATE_FORMAT_LENGTH)
    dateBorn: string;

    @IsNotEmpty()
    @IsNumber()
    @Min(LengthEnum.BALANCE_VALUE_MIN)
    @Max(LengthEnum.BALANCE_VALUE_MAX)
    balance: number;

    @IsNotEmpty()
    @IsBoolean()
    onlinePaymentEnabled: boolean;

    @IsString()
    @Length(1, LengthEnum.TIMEZONE_NAME_LENGTH)
    timezoneName: string;

    @IsNotEmpty()
    @ValidateNested()
    agreements: UserAgreementDto[];
}
