import React from "react";
import {PageTitle, RegularTextCss} from "../../../../../styles/global-elements";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {debounce} from "lodash";
import {NoticeBlock, NoticeBlockText} from "../../NoticeBlock";
import {Trans, t} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../../../DefaultLoader";
import {Pagination} from "../../Pagination";
import {ErrorLoadingContent} from "../../ErrorLoadingContent";
import {Modal, ModalControlParams} from "../../Modal";
import {BtnStyleEnum, Button} from "../../Button";
import {SingleNameFilter} from "../Common/SingleNameFilter";

const ITEMS_PER_PAGE = 6;

const PageWrapper = styled.div``;
const ResultWrapper = styled.div`
  padding: 0 10px;
  min-height: 310px;
`;

const ResultList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 30px;
  }
`;

const ResultItem = styled.li`
  ${RegularTextCss};

  padding: 5px 0;
  cursor: pointer;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.8;
  transition: opacity 0.3s ease;

  :hover {
    opacity: 1;
  }
`;

const TeacherName = styled.div``;
const TeacherStatusComment = styled.span`
  color: ${({theme}) => theme.colors.textSecondary};
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export interface StTeacherSelectorItem {
    id: number;
    longName: string;
    isActive: boolean;
}

export interface StTeacherSelectModalProps {
    stToken?: string | null;
    trigger: React.ReactElement;
    onChange: (value: StTeacherSelectorItem) => void;
}

interface StTeacherSelectModalState {
    loadingState: LoadingState;
    searchString: string;
    teachersList: StTeacherSelectorItem[] | null;
    currentPageNum: number;
    totalTeachersCount: number | null;
}

export class StTeacherSelectModalWindow extends React.Component<StTeacherSelectModalProps, StTeacherSelectModalState> {
    protected searchAbortController: AbortController | null;

    protected stonlineApiClient: IStonlineApiClient;
    protected logger: ILogger;

    protected setSearchStringBind;
    protected onOpenWindowBind;
    protected onCloseWindowBind;
    protected searchRequestDebounced;

    constructor(props: Readonly<StTeacherSelectModalProps>) {
        super(props);

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            searchString: '',
            teachersList: null,
            totalTeachersCount: null,
            currentPageNum: 1
        };

        this.searchAbortController = null;

        this.setSearchStringBind = this.setSearchString.bind(this);
        this.onOpenWindowBind = this.onOpenWindow.bind(this);
        this.onCloseWindowBind = this.onCloseWindow.bind(this);
        this.searchRequestDebounced = debounce(() => {
            this.setState(() => {
                return {
                    currentPageNum: 1
                }
            }, () => {
                this.searchRequest().then();
            })
        }, 500);
    }

    componentWillUnmount() {
        this.onCloseWindowBind();
    }

    protected onOpenWindow() {
        this.setState(() => {
            return {
                currentPageNum: 1,
                searchString: ''
            }
        }, () => {
            this.searchRequest().then();
        })
    }

    protected onCloseWindow() {
        this.searchRequestDebounced.cancel();

        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
            this.searchAbortController = null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOT_INIT
            }
        })
    }

    protected setSearchString = (newSearchString: string) => {
        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
            this.searchAbortController = null;
        }

        this.setState(() => {
            return {
                searchString: newSearchString
            }
        }, () => this.searchRequestDebounced());
    }

    protected searchRequest = async () => {
        if ((this.props.stToken === null) || (this.props.stToken === undefined)) {
            return null;
        }

        this.setState(
            () => {
                return {
                    loadingState: LoadingState.LOADING
                }
            }
        );

        if (this.searchAbortController !== null) {
            this.searchAbortController.abort();
        }

        this.searchAbortController = new AbortController();

        try {
            const result = await this.stonlineApiClient.searchTeacher(
                this.props.stToken,
                this.state.searchString,
                this.state.currentPageNum,
                ITEMS_PER_PAGE,
                this.searchAbortController
            );

            this.setState(() => {
                return {
                    loadingState: LoadingState.SUCCESS,
                    teachersList: result.result.items.map((item) => {
                        return {
                            id: item.id,
                            longName: item.longName,
                            isActive: item.isActive
                        }
                    }),
                    totalTeachersCount: result.result.totalCount
                }
            });
        } catch (e) {
            this.logger.error(LoggerSectionsEnum.STONLINE_TEACHERS_API, "Error on search teacher: ", e);

            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });
        }
    }

    protected emptyListNotificationBox = () => {
        return <NoticeBlock>
            <>
                <NoticeBlockText>
                    <Trans>По вашему запросу не нашлось учителей</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    protected resultContent = (controls: ModalControlParams) => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.SUCCESS: {
                if ((this.state.teachersList !== null) && (this.state.totalTeachersCount !== null)) {
                    if (this.state.teachersList.length > 0) {
                        return <>
                            <ResultList>
                                {
                                    this.state.teachersList.map((item) => {
                                        return <ResultItem key={item.id} onClick={() => {
                                            this.props.onChange(item);
                                            controls.closeModal();
                                        }}>
                                            <TeacherName>
                                                {item.longName}
                                                {
                                                    item.isActive
                                                    || <TeacherStatusComment>&nbsp;
                                                        <Trans>(уже не работает)</Trans>
                                                    </TeacherStatusComment>
                                                }
                                            </TeacherName>
                                        </ResultItem>;
                                    })
                                }
                            </ResultList>
                            <Pagination defaultCurrent={this.state.currentPageNum}
                                        total={this.state.totalTeachersCount}
                                        hideOnSinglePage={true}
                                        onChange={(page, _pageSize) => {
                                            this.setState({
                                                currentPageNum: page
                                            }, () => this.searchRequest());
                                        }}
                                        defaultPageSize={ITEMS_PER_PAGE}/>
                        </>;
                    } else {
                        return <>{this.emptyListNotificationBox()}</>;
                    }
                }

                return <ErrorLoadingContent retryBtnClick={this.searchRequest}/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.searchRequest}/>
            }
        }
    }

    protected modalContent = (controls: ModalControlParams) => {
        return <div>
            <PageTitle><Trans>Выбор преподавателя</Trans></PageTitle>
            <PageWrapper>
                <SingleNameFilter
                         placeholder={t`Поиск по имени преподавателя...`}
                         searchString={this.state.searchString}
                         setSearchString={(newValue: string) => this.setSearchString(newValue)}/>
                <ResultWrapper>
                    {this.resultContent(controls)}
                </ResultWrapper>
            </PageWrapper>
        </div>
    }

    render() {
        return <Modal trigger={this.props.trigger}
                      onOpen={this.onOpenWindowBind}
                      onClose={this.onCloseWindowBind}
                      closeAllowed={true}
                      footer={(controls) => {
                          return <div>
                              <Button btnStyle={BtnStyleEnum.Secondary}
                                      onClick={() => controls.closeModal()}><Trans>Закрыть</Trans></Button>
                          </div>
                      }}
                      children={(controls) => this.modalContent(controls)}
        />
    }
}
