import {instanceCachingFactory} from 'tsyringe';
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";
import {IJanusConnection} from "../components/JanusClient/IJanusConnection";
import {JanusConnection} from "../components/JanusClient/JanusConnection";

export default instanceCachingFactory<IJanusConnection>((dependencyContainer) => {
    const pingPeriod = (process.env.REACT_APP_PING_REQUEST_PERIOD_SECONDS)
        ? parseInt(process.env.REACT_APP_PING_REQUEST_PERIOD_SECONDS)
        : null;

    if (!pingPeriod) {
        throw new Error('Not found ping period config value');
    }

    return new JanusConnection(
        dependencyContainer.resolve<ILogger>(DiTokens.LOGGER),
        pingPeriod * 2
    );
});
