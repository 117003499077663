import React, {RefObject, useMemo} from "react";
import {DtoTmSlide} from "../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {FooterMode, Modal, ModalControlParams} from "../../Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {PopupActions} from "reactjs-popup/dist/types";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import styled from "styled-components";
import {PageSubtitle2} from "../../../styles/global-elements";
import {DefaultLoader} from "../../DefaultLoader";
import {ErrorLoadingContent} from "../../Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../Ui/Elements/NoticeBlock";
import {SlidePlayer} from "../../SlidePlayer";
import {EditorData} from "../../SlidePlayerEditorCommonParts/EditorData";
import {EditorDataDtoMapper} from "../../SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {ReactComponent as OpenInNewWindowIcon} from "../../Ui/Svg/OpenInNewWindow.svg";
import {RouterLink} from "../../Ui/Elements/Link";
import {PlayerContextProvider} from "../../SlidePlayer/PlayerContext";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {SlideItemsParamsBySlide} from "../../SlidePlayer/SlideItemParamsStore";

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const TitleTextWrapper = styled.div`
  padding-top: 6px;
  flex-grow: 1;
`;

const TitleOpenInNewWindowIconWrapper = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled(PageSubtitle2)`
  margin-bottom: 10px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 10px;
  }

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 15px;
  }
`;

const SlidePlayerBg = styled.div`
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  padding: 2px 0;
`;

const SlidePlayerWrapper = styled.div`
  max-width: 680px;
  padding: 0;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  margin: auto;
  
  @media (${({theme}) => theme.media.small}) {
    padding: 10px 20px 10px 20px;
  }
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

interface SlidePreviewModalProps {
    apiToken: string;
    tmSlide: DtoTmSlide;
    slideContentVersion: number;
    onClose?: () => void;
}

interface SlidePreviewModalState {
    loadingState: LoadingState;
    slideContent: EditorData | null;
}

export class SlidePreviewModal extends React.Component<SlidePreviewModalProps, SlidePreviewModalState> {
    protected apiClient: IHttpApiClient;
    protected logger: ILogger;

    protected abortController: AbortController | null;

    protected modalRef: RefObject<PopupActions>;

    constructor(props: Readonly<SlidePreviewModalProps> | SlidePreviewModalProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.abortController = null;

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            slideContent: null
        }

        this.modalRef = React.createRef<PopupActions>();
    }

    public open = () => {
        if (this.modalRef.current) {
            this.modalRef.current.open();

            this.fetchSlideContent();
        }
    }

    public close = () => {
        if (this.modalRef.current) {
            this.modalRef.current.close();
        }
    }

    protected onClose = () => {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        if (this.props.onClose !== undefined) {
            this.props.onClose();
        }
    }

    protected fetchSlideContent = () => {
        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING,
                slideContent: null
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.tmGetSlideContent(
            this.props.apiToken,
            this.props.tmSlide.id,
            this.props.slideContentVersion,
            this.abortController
        )
            .then((data) => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                        slideContent: EditorDataDtoMapper.dtoToEditorData(data.data.content)
                    }
                })
            })
            .catch((error) => {
                const messageText = "Error on fetch tmSlide content for preview: " + this.props.tmSlide.id;

                if (error instanceof NoConnection) {
                    this.logger.info(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                }

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }

    protected linkToPreviewItem = (slideId: string, contentVersion: number) => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_SLIDE_PREVIEW_W_VERSION,
            [
                {
                    key: 'slideId',
                    value: slideId
                },
                {
                    key: 'contentVersionId',
                    value: contentVersion.toString(10)
                }
            ]
        );
    }

    protected slideItemsParams = () => {
        const result: SlideItemsParamsBySlide = {};

        if (!this.state.slideContent) {
            return result;
        }

        this.state.slideContent.items.forEach((slideContentItem) => {
            result[slideContentItem.id] = slideContentItem.params;
        });

        return result;
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <Wrapper>
                    <DefaultLoader/>
                </Wrapper>
            }
            case LoadingState.ERROR: {
                return <Wrapper>
                    <ErrorLoadingContent retryBtnClick={this.fetchSlideContent}/>
                </Wrapper>
            }
            case LoadingState.SUCCESS: {
                if (this.state.slideContent === null) {
                    return <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Слайд пуст</Trans></NoticeBlockTitle>
                            <NoticeBlockText><Trans>Наполнить слайд можно в разделе «Учебные
                                материалы»</Trans></NoticeBlockText>
                        </>
                    </NoticeBlock>
                }

                return <Wrapper>
                    <SlidePlayerBg>
                        <SlidePlayerWrapper>
                            <PlayerContextProvider value={{
                                lessonId: "none",
                                playerId: "slide-preview",
                                slides: null,
                                slidesContent: {},
                                setSlidesContent: () => {
                                },
                                selectedSlideId: this.props.tmSlide.id,
                                setSelectedSlideId: () => {
                                },
                                selectedSlide: null,
                                selectedSlideIndex: null,
                                setInteractivityConfig: null
                            }}>
                                <SlidePlayer playerId={"slide-preview"}
                                             slideId={this.props.tmSlide.id}
                                             slideContent={this.state.slideContent}
                                             showCorrectAnswers={true}
                                             slideItemsParams={this.slideItemsParams()}
                                />
                            </PlayerContextProvider>
                        </SlidePlayerWrapper>
                    </SlidePlayerBg>
                </Wrapper>;
            }
            default: {
                throw new Error('Unknown loading state in select tmSlide preview modal');
            }
        }
    }

    protected modalContent = (controls: ModalControlParams) => {
        return <Wrapper>
            <TitleWrapper>
                <TitleTextWrapper>
                    <Title><Trans>Предпросмотр слайда</Trans>&nbsp;«{this.props.tmSlide.name}»:</Title>
                </TitleTextWrapper>
                <TitleOpenInNewWindowIconWrapper>
                    <RouterLink to={this.linkToPreviewItem(this.props.tmSlide.id, this.props.slideContentVersion)}
                                target={"_blank"}>
                        <OpenInNewWindowIcon/>
                    </RouterLink>
                </TitleOpenInNewWindowIconWrapper>
            </TitleWrapper>
            {this.content()}
        </Wrapper>;
    }

    render() {
        return <Modal innerRef={this.modalRef}
                      closeAllowed={true}
                      onClose={() => this.setState(() => {
                          return {loadingState: LoadingState.NOT_INIT}
                      })}
                      footer={(controls: ModalControlParams) => {
                          return <Button btnStyle={BtnStyleEnum.Secondary}
                                         onClick={controls.closeModal}>
                              <Trans>Закрыть</Trans>
                          </Button>
                      }}
                      footerMode={FooterMode.DEFAULT}
                      children={this.modalContent}/>
    }
}
