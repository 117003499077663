import React from "react";
import styled from "styled-components";
import {
    DtoHometaskOverviewResponse
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoHometaskOverviewResponse";
import {
    DtoNextLessonInfoResponseItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoNextLessonInfoResponseItem";
import {DtoUserProfileInfo} from "../../../../../../components/HttpApiClient/ApiDto/Response/User/DtoUserProfileInfo";
import {NextLessons} from "./NextLessons";
import {DtoLessonPlanItem} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLessonPlanItem";
import {NextLessonAndHomeTask} from "./NextLessonAndHomeTask";
import {MainPageSection} from "../../style";

const WrapperGrid = styled(MainPageSection)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  width: 100%;

  @media (${({theme}) => theme.media.large}) {
    grid-template-columns: 1fr 1fr;
  }
`;

interface ActualBlockProps {
    hometaskData: DtoHometaskOverviewResponse | null;
    nextLessonInfo: DtoNextLessonInfoResponseItem;
    nextLessonsList: DtoLessonPlanItem[];
    primaryTeacher: DtoUserProfileInfo;
    lastLessonTeacherPersonalComment?: string;
}

/**
 * Блок с актуальной информацией: актуальное домашнее задание и время следующего занятия
 *
 * @param props
 * @constructor
 */
export const ScheduleAndHometask: React.FC<ActualBlockProps> = (props) => {
    return <>
        <WrapperGrid>
            <NextLessons items={props.nextLessonsList} primaryTeacher={props.primaryTeacher}/>
            <NextLessonAndHomeTask
                nextLessonInfo={props.nextLessonInfo}
                hometaskInfo={props.hometaskData}
                teacherPersonalComment={props.lastLessonTeacherPersonalComment}
            />
        </WrapperGrid>

    {/*    <br/><br/>*/}

    {/*    <WrapperGrid>*/}
    {/*    {*/}
    {/*        (hasHomeTask && props.hometaskData)*/}
    {/*        && <HometaskBlock hometaskInfo={props.hometaskData}*/}
    {/*                          teacherPersonalComment={props.lastLessonTeacherPersonalComment}/>*/}
    {/*    }*/}
    {/*    <NextLessonBlock nextLessonInfo={props.nextLessonInfo} primaryTeacher={props.primaryTeacher}/>*/}
    {/*</WrapperGrid>*/}
        </>
}

ScheduleAndHometask.displayName = 'ScheduleAndHometask';