import {DtoResponseWithPagination} from "../DtoResponseWithPagination";
import {DtoStudentHomeworkDataItem} from "./DtoStudentHomeworkDataItem";
import {IsArray, IsNotEmpty, ValidateNested} from "class-validator";
import {Type} from "class-transformer";

export class DtoGetStudentsHomeworkDataResult extends DtoResponseWithPagination<DtoStudentHomeworkDataItem> {
    @IsNotEmpty()
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoStudentHomeworkDataItem)
    list: Array<DtoStudentHomeworkDataItem>;
}
