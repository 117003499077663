import {ILogger} from "../Logger/ILogger";
import {SubscriberCallbackFunc, SubscriberItem} from "./Types";
import {debounce, DebouncedFunc} from "lodash";
import {DeviceOrientatonTypeEnum} from "./DeviceOrientatonTypeEnum";
import {IDeviceOrientation} from "./IDeviceOrientation";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class DeviceOrientation implements IDeviceOrientation {
    protected logger: ILogger;
    protected onChangeSubscribers: SubscriberItem[];
    protected currentOrientation: DeviceOrientatonTypeEnum;

    protected onChangeHandlerDebounced: DebouncedFunc<any>;

    constructor(logger: ILogger) {
        this.logger = logger;

        this.onChangeSubscribers = [];
        this.currentOrientation = DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY;
        this.onChangeHandlerDebounced = debounce(this.onChangeHandler.bind(this), 500);

        if (window.screen && window.screen.orientation) {
            window.screen.orientation.addEventListener('change', this.onChangeHandlerDebounced);

            this.currentOrientation = this.convertBrowserOrientationToEnum(
                window.screen.orientation.type
            );

            this.logger.info(
                LoggerSectionsEnum.DEVICE_ORIENTATION,
                `Supported. Current orientation is  ${this.currentOrientation}.`
            );
        } else {
            this.logger.info(
                LoggerSectionsEnum.DEVICE_ORIENTATION,
                `Unsupported. Fixed orientation ${this.currentOrientation}.`
            );
        }
    }

    /**
     * @inheritDoc
     */
    getCurrentState(): DeviceOrientatonTypeEnum {
        return this.currentOrientation;
    }

    /**
     * @inheritDoc
     */
    registerSubscriber(subscriberId: string, func: SubscriberCallbackFunc): void {
        const existSubscriber = this.onChangeSubscribers.find(item => item.id === subscriberId);

        if (existSubscriber) {
            throw new Error(`Subscriber with id ${subscriberId} already exist`);
        }

        this.onChangeSubscribers.push({
            id: subscriberId,
            func: func
        });

        this.logger.info(
            LoggerSectionsEnum.DEVICE_ORIENTATION,
            `New subscriber ${subscriberId} registered`
        );
    }

    /**
     * @inheritDoc
     */
    checkSubscriber(subscriberId: string): boolean {
        return this.onChangeSubscribers.some(item => item.id === subscriberId);
    }

    /**
     * @inheritDoc
     */
    unregisterSubscriber(subscriberId: string): void {
        const existSubscriber = this.onChangeSubscribers.find(item => item.id === subscriberId);

        if (!existSubscriber) {
            throw new Error(`deviceOnChange subscriber with id ${subscriberId} not found`);
        }

        this.onChangeSubscribers = this.onChangeSubscribers.filter(item => item.id !== subscriberId);

        this.logger.info(
            LoggerSectionsEnum.DEVICE_ORIENTATION,
            `Subscriber ${subscriberId} unregistered`
        );
    }

    /**
     * Вызвать подписчиков на изменение
     *
     * @protected
     */
    protected callDeviceOnChangeSubscribers(): void {
        this.onChangeSubscribers.forEach(item => {

            try {
                item.func(this.currentOrientation);
            } catch (e) {
                this.logger.error(
                    LoggerSectionsEnum.DEVICE_ORIENTATION,
                    `Error on call subscriber for change device orientation (id ${item.id}): `, e
                );
            }
        })
    }

    /**
     *
     * @protected
     */
    protected onChangeHandler() {
        if (window.screen && window.screen.orientation && window.screen.orientation.type) {
            this.currentOrientation = this.convertBrowserOrientationToEnum(
                window.screen.orientation.type
            );

            this.logger.info(
                LoggerSectionsEnum.DEVICE_ORIENTATION,
                `Orientation has changed. New value: ${this.currentOrientation}.`
            )

            this.callDeviceOnChangeSubscribers();
        }
    }

    /**
     *
     * @param browserOrientation
     * @protected
     */
    protected convertBrowserOrientationToEnum(browserOrientation: string): DeviceOrientatonTypeEnum {
        switch (browserOrientation) {
            case 'portrait-primary': {
                return DeviceOrientatonTypeEnum.PORTRAIT_PRIMARY;
            }
            case 'portrait-secondary': {
                return DeviceOrientatonTypeEnum.PORTRAIT_SECONDARY;
            }
            case 'landscape-primary': {
                return DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY;
            }
            case 'landscape-secondary': {
                return DeviceOrientatonTypeEnum.LANDSCAPE_SECONDARY;
            }
            default: {
                this.logger.warning(
                    LoggerSectionsEnum.DEVICE_ORIENTATION,
                    'Received unknown screen orientation: ' + browserOrientation
                );

                return DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY;
            }
        }
    }
}
