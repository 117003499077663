import {useParams} from "react-router";
import React, {useCallback, useMemo, useState} from "react";
import {InitialCustomizerRequestData} from "./InitialCustomizerRequestData";
import {useDispatch, useSelector} from "react-redux";
import {sessionTokenSelector, stTokenSelector} from "../../../../store/app/selector";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {StepInitItem, StepWizard} from "../../../components/StepWizard";
import {RoutesList} from "../../../RoutesList";
import {t} from "@lingui/macro";
import {InitialCustomizerPageContextProvider} from "./InitialCustomizerPageContext";
import {PageWrapper} from "../../../components/PageWrapper";
import {WelcomeSection} from "./welcome";
import {TeacherNameSection} from "./teacher-name";
import {AboutActParamsSection} from "./about-act-params";
import {SetActParamsSection} from "./set-act-params";
import {AboutNavigationSection} from "./about-navigation";
import {FinishSection} from "./finish";
import {selectedSchoolActualParams} from "../../../../store/user/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../components/StonlineApiClient/Exception/NoConnection";
import {NotificationTypesEnum, openNotification} from "../../../components/Ui/Elements/Notification";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {SchoolTypeEnum} from "../../../../components/StonlineApiClient/Enums/SchoolTypeEnum";
import {
    ActualParamsDto
} from "../../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import * as UserActionCreators from "../../../../store/user/actions";

export const InitialCustomizerPage: React.FC = () => {
    const {stepId} = useParams();

    const dispatch = useDispatch();
    const setActualParams = (
        actualParamsDto: ActualParamsDto
    ) => dispatch(UserActionCreators.setTeacherActualParams(actualParamsDto));

    const stToken = useSelector(stTokenSelector);
    const apiToken = useSelector(sessionTokenSelector);

    const currentActualParams = useSelector(selectedSchoolActualParams);
    const [formData, setFormData] = useState<InitialCustomizerRequestData>(
        () => {
            const obj = new InitialCustomizerRequestData();

            if (currentActualParams !== null) {
                obj.actualParamsCount = 1;
                obj.actualParam1Name = currentActualParams.first;

                if (currentActualParams.second !== null) {
                    obj.actualParamsCount = 2;
                    obj.actualParam2Name = currentActualParams.second;
                }

                if (currentActualParams.third !== null) {
                    obj.actualParamsCount = 3;
                    obj.actualParam3Name = currentActualParams.third;
                }

                if (currentActualParams.fourth !== null) {
                    obj.actualParamsCount = 4;
                    obj.actualParam4Name = currentActualParams.fourth;
                }

                if (currentActualParams.fifth !== null) {
                    obj.actualParamsCount = 5;
                    obj.actualParam5Name = currentActualParams.fifth;
                }
            }

            return obj;
        }
    );

    const saveData = useCallback((formData: InitialCustomizerRequestData) => {
        return new Promise<void>((resolve, reject) => {
            if (!stToken || !apiToken) {
                reject();

                return;
            }

            const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            const actualParamsDto: ActualParamsDto = {
                first: formData.actualParam1Name,
                second: (formData.actualParamsCount > 1 && formData.actualParam2Name) ? formData.actualParam2Name : null,
                third: (formData.actualParamsCount > 2 && formData.actualParam3Name) ? formData.actualParam3Name : null,
                fourth: (formData.actualParamsCount > 3 && formData.actualParam4Name) ? formData.actualParam4Name : null,
                fifth: (formData.actualParamsCount > 4 && formData.actualParam5Name) ? formData.actualParam5Name : null
            };

            const handledErrorMessage = 'handled';

            stApiClient.initActualParamsList(
                stToken,
                actualParamsDto.first,
                actualParamsDto.second,
                actualParamsDto.third,
                actualParamsDto.fourth,
                actualParamsDto.fifth
            )
                .catch((e) => {
                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось сохранить данные`,
                            t`Проверьте подключение к интернету`
                        );

                        throw new Error(handledErrorMessage);
                    }

                    if ((e instanceof Error) && (e.message === handledErrorMessage)) {
                        throw e;
                    }

                    logger.error(
                        LoggerSectionsEnum.INITIAL_CUSTOMIZER,
                        'Error on save actual params. Form data: ',
                        formData,
                        'Error: ',
                        e
                    );

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось сохранить данные`,
                        ''
                    );

                    throw new Error(handledErrorMessage);
                })
                .then((data) => {
                    // Запускаем следующий запрос
                    return stApiClient.setupWizardSetData(
                        stToken,
                        formData.userName,
                        formData.schoolType === SchoolTypeEnum.PERSONAL_TUTOR,
                        (formData.schoolType === SchoolTypeEnum.PERSONAL_TUTOR)
                            ? formData.userName
                            : formData.schoolName,
                        formData.timezoneName ?? "Asia/Novosibirsk"
                    );
                })
                .catch((e) => {
                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось сохранить данные`,
                            t`Проверьте подключение к интернету`
                        );

                        throw new Error(handledErrorMessage);
                    }

                    if ((e instanceof Error) && (e.message === handledErrorMessage)) {
                        throw e;
                    }

                    logger.error(
                        LoggerSectionsEnum.INITIAL_CUSTOMIZER,
                        'Error on save initial customizer params. Form data: ',
                        formData,
                        'Error: ',
                        e
                    );

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось сохранить данные`,
                        ''
                    );
                })
                .then(() => {
                    return apiClient.schoolReloadLessonMarksList(apiToken);
                })
                .then((result) => {
                    setActualParams(result.data);

                    resolve();
                })
                .catch((e) => {
                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось сохранить данные`,
                            t`Проверьте подключение к интернету`
                        );

                        throw new Error(handledErrorMessage);
                    }

                    if ((e instanceof Error) && (e.message === handledErrorMessage)) {
                        reject();

                        return;
                    }

                    logger.error(
                        LoggerSectionsEnum.INITIAL_CUSTOMIZER,
                        'Error on reload actual params.',
                        'Error: ',
                        e
                    );

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось сохранить данные`,
                        ''
                    );

                    throw new Error(handledErrorMessage);
                })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiToken, stToken]);

    const activeSectionId = useMemo<PageSectionsEnum>(() => {
        const allSections = Object.values(PageSectionsEnum) as string[];

        if (allSections.indexOf(stepId ?? '') > -1) {
            return stepId as PageSectionsEnum;
        }

        return PageSectionsEnum.WELCOME;
    }, [stepId]);

    const stepsConfig = useMemo<StepInitItem[]>(() => {
        return [
            {
                id: PageSectionsEnum.WELCOME,
                component: <WelcomeSection/>
            },
            {
                id: PageSectionsEnum.TEACHER_NAME,
                component: <TeacherNameSection/>
            },
            {
                id: PageSectionsEnum.ABOUT_ACT_PARAMS,
                component: <AboutActParamsSection/>
            },
            {
                id: PageSectionsEnum.SET_ACT_PARAMS,
                component: <SetActParamsSection/>
            },
            {
                id: PageSectionsEnum.ABOUT_NAVIGATION,
                component: <AboutNavigationSection/>
            },
            {
                id: PageSectionsEnum.FINISH,
                component: <FinishSection/>
            },
        ]
    }, []);

    const activeSectionTitle = useMemo<string | null>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.WELCOME:
            case PageSectionsEnum.TEACHER_NAME: {
                return t`Давайте познакомимся`;
            }
            case PageSectionsEnum.ABOUT_ACT_PARAMS:
            case PageSectionsEnum.SET_ACT_PARAMS: {
                return t`Теперь кое-что настроим`;
            }
            case PageSectionsEnum.ABOUT_NAVIGATION: {
                return t`Маленькое напутствие`;
            }
            case PageSectionsEnum.FINISH: {
                return t`Всё готово, можем начинать`;
            }
            default: {
                return null;
            }
        }
    }, [activeSectionId]);


    return <InitialCustomizerPageContextProvider value={{
        formData: formData,
        setFormData: setFormData,
        saveData: saveData
    }}>
        <PageWrapper
            backLink={null}
            pageTitle={activeSectionTitle}
            pageContent={
                <StepWizard
                    steps={stepsConfig}
                    pageLink={RoutesList.TEACHER_SETUP}/>
            }
            rightBlockContent={null}
        />
    </InitialCustomizerPageContextProvider>
}