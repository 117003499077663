import {IsNotEmpty, IsString, Length} from "class-validator";
import {IBaseApiDto} from "../../IBaseApiDto";

/**
 * DTO параметров сообщения - результата запроса на авторизацию пользователя.
 */
export class AuthLoginResultDto implements IBaseApiDto {
    @IsNotEmpty()
    @IsString()
    userId: string;

    @IsString()
    @IsNotEmpty()
    @Length(1, 255)
    reconnectionToken: string;

    @IsNotEmpty()
    currentTime: string;
}
