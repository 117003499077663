import {PayTypeEnum} from "../../../components/StonlineApiClient/Enums/PayTypeEnum";
import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react";
import {FooterMode, Modal, ModalControlParams} from "../Ui/Elements/Modal";
import {PopupActions} from "reactjs-popup/dist/types";
import {BtnStyleEnum, Button} from "../Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {ModalTitle} from "../../styles/global-elements";
import {Form, FormItem, useForm} from "../Ui/Elements/Form";
import {StringHelper} from "../../../helpers/StringHelper";
import moment from "moment";
import {DatePicker} from "../Ui/Elements/DatePicker";
import {ConfirmDialog} from "../Ui/Elements/ConfirmDialog";
import styled from "styled-components";
import {MoneyInput} from "../Ui/Elements/MoneyInput";
import {TextArea} from "../Ui/Elements/TextArea";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../Ui/Elements/NoticeBlock";
import {container} from "tsyringe";
import {ILogger} from "../../../components/Logger/ILogger";
import {DiTokens} from "../../../di-factory/DiTokens";
import {IStonlineApiClient} from "../../../components/StonlineApiClient/IStonlineApiClient";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../store/app/selector";
import {NotificationTypesEnum, openNotification} from "../Ui/Elements/Notification";
import {IDateHelperService} from "../../../services/date-helper/IDateHelperService";
import {BaseResponseDto} from "../../../components/StonlineApiClient/ApiDto/Response/BaseResponseDto";
import {AccessDeniedException} from "../../../components/HttpApiClient/Exception/AccessDeniedException";
import {NoConnection} from "../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";

const RowInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`

const NoticeBlockStyled = styled(NoticeBlock)`
  margin-bottom: 25px;
`;

export interface PayEditModalProps {
    itemId: number | null;
    payType: PayTypeEnum;
    subjectId: number;
    subjectName: string;
    paymentDate: Date;
    sum: number;
    comment: string;
    onUpdate: () => void;
    trigger?: JSX.Element;
}

enum FormItemNames {
    PAYMENT_DATE = 'paymentDate',
    SUM = 'sum',
    COMMENT = 'comment'
}

export const PayEditModal = forwardRef<PopupActions, PayEditModalProps>((
    {
        itemId,
        payType,
        subjectId,
        subjectName,
        paymentDate,
        sum,
        comment,
        onUpdate,
        trigger
    },
    ref
) => {
    const modalRef = useRef<PopupActions>(null);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        close: () => {
            modalRef.current?.close();
        },
        open: () => {
            modalRef.current?.open();
        },
        toggle: () => {
            modalRef.current?.toggle();
        }
    }));

    const [form] = useForm();

    const [closeAllowed, setCloseAllowed] = useState<boolean>(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const stToken = useSelector(stTokenSelector);

    const modalTitle = useMemo<JSX.Element>(() => {
        const name = StringHelper.extractFirstAndMiddleName(subjectName);

        return <Trans>Финансовая операция {name}</Trans>;
    }, [subjectName]);

    const readOnly = useMemo<boolean>(() => {
        switch (payType) {
            case PayTypeEnum.STUDENT_MANUAL_REFILL:
            case PayTypeEnum.TEACHER_HAND_OPERATION: {
                return false;
            }
            default: {
                return true;
            }
        }
    }, [payType]);

    const editDisableReason = useMemo<{ title: string, description: string }>(() => {
        switch (payType) {
            case PayTypeEnum.STUDENT_MANUAL_REFILL:
            case PayTypeEnum.TEACHER_HAND_OPERATION:
            case PayTypeEnum.ADJUSTMENT_TEACHER_BALANCE_FOR_RKO:
            case PayTypeEnum.CUSTOMER_BALANCE_OPERATION: {
                return {
                    title: '',
                    description: ''
                };
            }
            case PayTypeEnum.LESSON_PAYMENT:
            case PayTypeEnum.STUDENT_AGREEMENT_PAY_FOR_LESSON: {
                return {
                    title: t`Эта платёж ученика за занятие`,
                    description: t`Запись создана автоматически по настройкам в профиле ученика. Для корректировки суммы скорректируйте настройки в профиле ученика.`
                }
            }
            case PayTypeEnum.TEACHER_PAY_FOR_LESSON: {
                return {
                    title: t`Это начисление преподавателю за проведение урока`,
                    description: t`Запись создана автоматически по настройкам в профиле учителя. Для корректировки суммы скорректируйте настройки в профиле учителя.`
                }
            }
            case PayTypeEnum.STUDENT_BANK_STATEMENT_DOC_OPERATION:
            case PayTypeEnum.BANK_STATEMENT_DOC_OPERATION_FOR_CUSTOMER: {
                return {
                    title: t`Эта операция по данным банка`,
                    description: t`Запись создана автоматически по данным, полученным из банковской выписки. Вы можете скорректировать операцию в разделе банковских операций.`
                }
            }
            case PayTypeEnum.STUDENT_PAYMENT_VIA_ACQUIRING: {
                return {
                    title: t`Это платёж ученика из ЛК ученика`,
                    description: `Если нужно внести изменение, вы можете обратиться в службу поддержки.`
                }
            }
        }
    }, [payType]);

    const onFinish = useCallback(() => {
        if (stToken === null) {
            return;
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        let promise: null | Promise<BaseResponseDto<null>> = null;

        const dateAsMomentObj = form.getFieldValue(FormItemNames.PAYMENT_DATE);

        if (!dateAsMomentObj) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось сохранить данные`,
                t`Укажите дату финансовой операции`
            );

            return;
        }

        const dateAsString = dateHelperService.formatAsSQLDate(dateAsMomentObj.toDate() as Date);
        const sum = form.getFieldValue(FormItemNames.SUM);
        const comment = form.getFieldValue(FormItemNames.COMMENT) ?? null;

        setSaveInProcess(true);
        setCloseAllowed(false);

        if (itemId === null) {
            // Нужно создать элемент
            promise = stApiClient.addStudentPaymentOperation(
                stToken,
                subjectId,
                sum,
                dateAsString,
                comment
            );
        } else {
            // Нужно обновить элемент
            promise = stApiClient.editStudentPaymentOperation(
                stToken,
                itemId,
                sum,
                dateAsString,
                comment
            );
        }

        promise
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Информация успешно сохранена`,
                    ''
                );

                onUpdate();

                modalRef.current?.close();
            })
            .catch((err) => {
                if (err instanceof AccessDeniedException) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось выполнить операцию`,
                        t`Похоже у вас нет прав на корректировку данных взаиморасчётов с учениками`
                    );

                    return;
                }

                if (err instanceof NoConnection) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось выполнить операцию`,
                        t`Проверьте соединение с интернетом`
                    );

                    return;
                }

                const payload = JSON.stringify({
                    dateAsString,
                    sum,
                    comment
                });

                logger.error(
                    LoggerSectionsEnum.STONLINE_PAYMENTS_API,
                    `Error on save ${itemId ?? 'new'} payment item for student.`,
                    `Request: ${payload}, error: `,
                    err
                );

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Попробуйте повторить позднее`
                );
            })
            .finally(() => {
                setSaveInProcess(false);
                setCloseAllowed(true);
            });
    }, [form, itemId, onUpdate, stToken, subjectId]);

    const onDelete = useCallback(async () => {
        if (stToken === null || itemId === null) {
            return;
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        try {
            await stApiClient.deleteStudentPaymentOperation(stToken, itemId);

            openNotification(
                NotificationTypesEnum.SUCCESS,
                t`Операция успешно удалена`,
                ''
            );

            onUpdate();

            modalRef.current?.close();
        } catch (err) {
            if (err instanceof AccessDeniedException) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Похоже у вас нет прав на корректировку данных взаиморасчётов с учениками`
                );

                return;
            }

            if (err instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Проверьте соединение с интернетом`
                );

                return;
            }

            logger.error(
                LoggerSectionsEnum.STONLINE_PAYMENTS_API,
                `Error on delete ${itemId} payment item for student.`,
                err
            );

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось выполнить операцию`,
                t`Попробуйте повторить позднее`
            );
        }
    }, [itemId, onUpdate, stToken]);

    const footerContent = (controls: ModalControlParams) => {
        if (readOnly) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary}
                        onClick={controls.closeModal}
                ><Trans>Ок</Trans></Button>
            </div>
        }

        if (itemId === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary}
                        disabled={!closeAllowed}
                        onClick={() => form.submit()}
                >
                    <Trans>Добавить</Trans>
                </Button>
            </div>
        }

        // Если мы редактируем операцию
        return <>
            <ConfirmDialog
                trigger={
                    <Button style={{marginRight: "20px"}}
                            btnStyle={BtnStyleEnum.Secondary}
                            disabled={saveInProcess}>
                        <Trans>Удалить</Trans>
                    </Button>
                }
                okText={t`Удалить`}
                cancelText={t`Отмена`}
                title={t`Удалить?`}
                text={t`Вы уверены, что нужно удалить финансовую операцию?`}
                errorText={t`Не удалось удалить операцию`}
                okMethod={onDelete}/>

            <Button btnStyle={BtnStyleEnum.Primary}
                    loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>
    };

    return <Modal
        innerRef={modalRef}
        trigger={trigger}
        closeAllowed={closeAllowed}
        footerMode={(readOnly || itemId === null) ? FooterMode.DEFAULT : FooterMode.SPACE_BETWEEN}
        onClose={() => {
            setCloseAllowed(true);
            setSaveInProcess(false);

            form.resetFields();
        }}
        footer={footerContent}
        children={(_controls) =>
            <div>
                <ModalTitle>{modalTitle}</ModalTitle>
                {
                    (readOnly) && <NoticeBlockStyled>
                    <>
                      <NoticeBlockTitle>
                          {editDisableReason.title}
                      </NoticeBlockTitle>
                      <NoticeBlockText>
                          {editDisableReason.description}
                      </NoticeBlockText>
                    </>
                  </NoticeBlockStyled>
                }
                <Form form={form} layout={"vertical"} onFinish={onFinish}>
                    <RowInputsWrapper>
                        <FormItem
                            name={FormItemNames.PAYMENT_DATE}
                            initialValue={moment(paymentDate)}
                            label={<Trans>Дата операции</Trans>}
                            rules={[
                                {
                                    required: true,
                                    message: t`Необходимо указать дату`,
                                }
                            ]}
                        >
                            <DatePicker placeholder={t`Дата операции`} readOnly={readOnly}/>
                        </FormItem>
                        <FormItem
                            name={FormItemNames.SUM}
                            initialValue={sum}
                            label={<Trans>Сумма операции</Trans>}
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return Promise.reject(t`Необходимо указать сумму операции`);
                                        }

                                        if (!(/^[-.\d]+$/.test(value))) {
                                            return Promise.reject(new Error(t`Используйте, пожалуйста, целое значение`));
                                        }

                                        const numberValue = parseInt(value);

                                        if (numberValue < -100000) {
                                            return Promise.reject(new Error(t`Минимальная сумма -100 000 рублей`));
                                        }

                                        if (numberValue > 100000) {
                                            return Promise.reject(new Error(t`Максимальная сумма 100 000 рублей`));
                                        }

                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <MoneyInput placeholder={t`Сумма операции`}
                                        style={{textAlign: "right"}}
                                        readOnly={readOnly}/>
                        </FormItem>
                    </RowInputsWrapper>
                    <FormItem
                        name={FormItemNames.COMMENT}
                        initialValue={comment}
                        label={<Trans>Описание</Trans>}
                        rules={[
                            {
                                type: "string",
                                max: 500,
                                message: t`Максимальная длина - 500 символов`
                            }
                        ]}
                    >
                        <TextArea placeholder={t`Описание`} readOnly={readOnly}/>
                    </FormItem>
                </Form>
            </div>
        }
    />
});
