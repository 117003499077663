import React, {useCallback} from "react";
import {
    DtoSchoolsSummaryInfo
} from "../../../../components/StonlineApiClient/ApiDto/Response/SchoolsManagement/DtoSchoolsSummaryInfo";
import {BlockWithBorder, PageSubtitleSmallMargin, RegularTextCss} from "../../../styles/global-elements";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {Modal} from "../../../components/Ui/Elements/Modal";
import {container} from "tsyringe";
import {IClipboardHelper} from "../../../../components/ClipboardHelper/IClipboardHelper";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../components/Ui/Elements/Notification";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import classNames from "classnames";

const Wrapper = styled(BlockWithBorder)`
  padding: 20px;
`;

const ParamsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const IdWrapper = styled.div`
  ${RegularTextCss};
`;

const NameWrapper = styled(PageSubtitleSmallMargin)`
`;

const RegularItem = styled.div`
  ${RegularTextCss};
`;

const DraftCountWrapper = styled.span`
  color: ${({theme}) => theme.colors.textSecondary};
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const TeachersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TeacherName = styled(PageSubtitleSmallMargin)`
`;

const TeacherItem = styled(BlockWithBorder)`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  &.no-border {
    border: none;
    padding: 0;
  }
`;

interface SchoolCardProps {
    dto: DtoSchoolsSummaryInfo;
}

export const SchoolCard: React.FC<SchoolCardProps> = ({dto}) => {

    const copyLinkOnClick = useCallback((link: string) => {
        const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

        if (link === null) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось скопировать ссылку`,
                ''
            );

            return;
        }

        clipboardHelper.copyText(link)
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Ссылка успешно скопирована`,
                    ''
                );

                return;
            })
            .catch((e) => {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось скопировать ссылку`,
                    ''
                );

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.MODERATOR_PANEL,
                    'Error on copy login link to clipboard. Link:',
                    link,
                    'Error',
                    e
                );
            })
    }, []);

    return <Wrapper>
        <ParamsList>
            <NameWrapper>{dto.name}</NameWrapper>

            <IdWrapper>ID: <b>{dto.id}</b></IdWrapper>

            <RegularItem>
                <Trans>Репетитор:</Trans>&nbsp;
                <b>{(dto.isTutor) ? <Trans>Да</Trans> : <Trans>Нет</Trans>}</b>
            </RegularItem>

            <RegularItem><Trans>Часовой пояс:</Trans>&nbsp;<b>{dto.timezoneName}</b></RegularItem>

            <RegularItem><Trans>Учеников:</Trans>&nbsp;
                <b>{dto.studentsCount}</b>&nbsp;
                <DraftCountWrapper>(<Trans>черновиков:</Trans>&nbsp;{dto.studentsDraft})</DraftCountWrapper>
            </RegularItem>

            <RegularItem><Trans>Договоров:</Trans>&nbsp;
                <b>{dto.agreementsCount}</b>&nbsp;
                <DraftCountWrapper>(<Trans>черновиков:</Trans>&nbsp;{dto.agreementsDraft})</DraftCountWrapper>
            </RegularItem>

            <RegularItem><Trans>Уроков:</Trans>&nbsp;<b>{dto.lessonsCount}</b></RegularItem>

            <RegularItem><Trans>Последняя активность:</Trans>&nbsp;
                {
                    dto.lastTeacherActivity === null
                        ? <Trans>Нет</Trans>
                        : <>
                            {dto.lastTeacherActivity.lastCall}&nbsp;
                            (старт {dto.lastTeacherActivity.dateLogin})
                        </>
                }</RegularItem>
        </ParamsList>

        <ButtonWrapper>
            <Modal
                trigger={<Button btnStyle={BtnStyleEnum.Secondary}><Trans>Сотрудники</Trans></Button>}
                closeAllowed={true}
                children={
                    () => {
                        return <TeachersList>
                            {
                                dto.teachers.map((item) => {
                                    return <TeacherItem key={item.id}
                                                        className={classNames(dto.teachers.length === 1 && 'no-border')}>
                                        <TeacherName>{item.longName}</TeacherName>
                                        <RegularItem>
                                            <Trans>Номер телефона: </Trans>&nbsp;<b>{item.smsPhone}</b>
                                        </RegularItem>
                                        <RegularItem>
                                            <Trans>Последняя активность: </Trans>&nbsp;<b>{item.lastCall}</b>
                                        </RegularItem>
                                        <ButtonWrapper>
                                            {
                                                (item.authToken !== '') &&
                                                <Button
                                                    btnStyle={BtnStyleEnum.Secondary}
                                                    onClick={() => {
                                                        copyLinkOnClick(
                                                            window.location.origin + RoutesHelper.replaceParams(
                                                                RoutesList.TEACHER_LOGIN_VIA_TOKEN,
                                                                [
                                                                    {
                                                                        key: 'token',
                                                                        value: item.authToken
                                                                    }
                                                                ]
                                                            ))
                                                    }}
                                                >
                                                    <Trans>Скопировать ссылку для быстрого входа</Trans>
                                                </Button>
                                            }
                                            {
                                                (item.authToken === '') && <RegularItem>
                                                    <Trans>Нет ссылки быстрого входа</Trans>
                                                </RegularItem>
                                            }
                                        </ButtonWrapper>
                                    </TeacherItem>;
                                })
                            }
                        </TeachersList>
                    }
                }
            />
        </ButtonWrapper>
    </Wrapper>
}