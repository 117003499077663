import * as React from "react";
import {ChangeEvent, useRef, useState} from "react";
import styled from 'styled-components';
import {Input} from "../../../components/Ui/Elements/TextInput";
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {Link} from "../../../components/Ui/Elements/Link";
import {i18n} from '@lingui/core'
import {useNavigate, useOutletContext} from "react-router-dom";
import {RoutesList} from "../../../RoutesList";
import {OutletContext} from "../index";
import {AnimatedContainer} from "./AnimatedContainer";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {AccessDeniedException} from "../../../../components/HttpApiClient/Exception/AccessDeniedException";
import {RequestValidationError} from "../../../../components/HttpApiClient/Exception/RequestValidationError";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {useDispatch} from "react-redux";
import * as UserActionCreators from "../../../../store/user/actions";
import {NotFoundException} from "../../../../components/HttpApiClient/Exception/NotFoundException";
import {InputRef} from "antd/lib/input/Input";
import {MaskedInput} from "../../../components/Ui/Elements/MaskedInput";
import {UserProfileType} from "../../../../store/user/type";


const Wrapper = styled.div`

`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const FieldWrapper = styled.div`

`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;


const AnotherLinksWrapper = styled.div`
  margin-bottom: 73px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 0;
  }
`;

const AnotherLink = styled.div`
  padding: 12px;

  @media (${({theme}) => theme.media.small}) {
    padding: 16px;
  }
`;

export const TeacherLoginForm: React.FC = () => {
    const [phoneInput, setPhoneInput] = useState('');
    const [phoneInputError, setPhoneInputError] = useState(false);

    const [passwordInput, setPasswordInput] = useState('');
    const [passwordInputError, setPasswordInputError] = useState(false);

    let passwordInputRef = useRef<InputRef | null>(null);

    const dispatch = useDispatch();
    const setUserProfileType = (profileType: UserProfileType) => dispatch(UserActionCreators.setUserProfileType(profileType));
    const setSessionToken = (token: string) => dispatch(UserActionCreators.setUserSessionToken(token));
    const startLoadingUserProfile = () => dispatch(UserActionCreators.startLoadingUserProfile());

    const outletContext = (useOutletContext() as OutletContext);

    const navigate = useNavigate();

    const loginBtnClick = async () => {
        outletContext.setAlertMessage(null);

        setPhoneInputError(false);
        setPasswordInputError(false);

        if (phoneInput.trim().length < 11) {
            setPhoneInputError(true);
        }

        if (passwordInput.trim().length === 0) {
            setPasswordInputError(true);
        }

        if (phoneInputError || passwordInputError) {
            return;
        }

        outletContext.setLoader(true, i18n._(t`Выполняется вход...`));

        // Выполняем запрос на регистрацию
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        try {
            const loginResult = await httpApiClient.loginTeacher(
                phoneInput.trim(), passwordInput.trim()
            );

            // При внесении правок в процесс, не забыть про аналогичный блок в
            // {@see LoginForm} и {@see AppInit} и {@see LoginViaTokenForm} форме авторизации ученика.
            setUserProfileType(UserProfileType.TEACHER);
            setSessionToken(loginResult.data.token);
            startLoadingUserProfile();
        } catch (e) {
            outletContext.setLoader(false);

            if (e instanceof NotFoundException) {
                outletContext.setAlertMessage(i18n._(t`Не найден пользователь.`));

                return;
            }

            if (e instanceof AccessDeniedException) {
                outletContext.setAlertMessage(i18n._(t`Операция временно недоступна`));

                return;
            }

            if (e instanceof RequestValidationError) {
                outletContext.setAlertMessage(e.errorSummary);

                if (e.errorDetails) {
                    if (e.errorDetails['email']) {
                        setPhoneInputError(true);
                    }

                    if (e.errorDetails['password']) {
                        setPasswordInputError(true);
                    }
                }

                return;
            }

            outletContext.setAlertMessage(i18n._(t`Произошла ошибка. Попробуйте повторить попытку позднее.`));

            logger.error(LoggerSectionsEnum.UNKNOWN_API_RESPONSE_ERROR, e);

            return;
        }
    }

    const onPhoneKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (event.key === 'Enter') {
            if ((passwordInputRef !== null) && (passwordInputRef.current !== null)) {
                passwordInputRef.current.focus();
            }
        }
    }

    const onPasswordKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
        if (event.key === 'Enter') {
            loginBtnClick();
        }
    }

    const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneInput(event.target.value.replace(/\D/g, ''));
    }

    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordInput(event.target.value);
    }

    return (
        <AnimatedContainer>
            <Wrapper>
                <InputsWrapper>
                    <FieldWrapper>
                        <MaskedInput
                            mask="+{7} 000 000-00-00"
                            status={(phoneInputError) ? "error" : ""}
                            type={"text"}
                            size="large"
                            onChange={onPhoneChange}
                            onKeyDown={onPhoneKeyDown}
                            placeholder={t`Номер телефона`}/>
                    </FieldWrapper>
                    <FieldWrapper>
                        <Input
                            type="password"
                            status={(passwordInputError) ? "error" : ""}
                            size="large"
                            ref={passwordInputRef}
                            onChange={onPasswordChange}
                            onKeyDown={onPasswordKeyDown}
                            placeholder={t`Пароль`}/>
                    </FieldWrapper>
                </InputsWrapper>
                <ButtonsWrapper>
                    <ButtonItem>
                        <Button btnStyle={BtnStyleEnum.Primary} onClick={loginBtnClick}><Trans>Войти как
                            преподаватель</Trans></Button>
                    </ButtonItem>
                </ButtonsWrapper>
                <AnotherLinksWrapper>
                    <AnotherLink>
                        <Link onClick={() => navigate(RoutesList.LOGIN_FORM)}><Trans>Войти как ученик</Trans></Link>
                    </AnotherLink>
                    <AnotherLink>
                        <Link
                            href={process.env.REACT_APP_LANDING_URL ?? "#"}
                            target={"_blank"}>
                            <Trans>О программе</Trans>
                        </Link>
                    </AnotherLink>
                </AnotherLinksWrapper>
            </Wrapper>
        </AnimatedContainer>
    );
}