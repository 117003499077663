import {ILogger} from "../Logger/ILogger";
import {IInternetConnectionChecker} from "./IInternetConnectionChecker";
import {SubscriberCallbackFunc, SubscriberItem} from "./SubscriberItem";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class InternetConnectionChecker implements IInternetConnectionChecker {
    protected logger: ILogger;

    protected subscribersOnOffline: SubscriberItem[];
    protected subscribersOnOnline: SubscriberItem[];

    constructor(logger: ILogger) {
        this.logger = logger;

        this.subscribersOnOffline = [];
        this.subscribersOnOnline = [];

        if (navigator.onLine === undefined) {
            this.logger.warning(
                LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
                'navigator.onLine is unsupported!'
            );

            return;
        }

        window.addEventListener(
            "offline",
            this.onOffline.bind(this)
        );

        window.addEventListener(
            "online",
            this.onOnline.bind(this)
        );
    }

    /**
     * @inheritDoc
     */
    registerOnOfflineSubscriber(subscriberId: string, subscriberFunc: SubscriberCallbackFunc): void {
        const existSubscriber = this.subscribersOnOffline.find(item => item.id === subscriberId);

        if (existSubscriber) {
            throw new Error(`OnOffline subscriber with id '${subscriberId}' already exist`);
        }

        this.subscribersOnOffline.push({
            id: subscriberId,
            func: subscriberFunc
        });

        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `OnOffline subscriber with id '${subscriberId}' registered`
        );
    }

    /**
     * @inheritDoc
     */
    registerOnOnlineSubscriber(subscriberId: string, subscriberFunc: SubscriberCallbackFunc): void {
        const existSubscriber = this.subscribersOnOnline.find(item => item.id === subscriberId);

        if (existSubscriber) {
            throw new Error(`OnOnline subscriber with id '${subscriberId}' already exist`);
        }

        this.subscribersOnOnline.push({
            id: subscriberId,
            func: subscriberFunc
        });

        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `OnOnline subscriber with id '${subscriberId}' registered`
        );
    }

    /**
     * @inheritDoc
     */
    unregisterOnOfflineSubscriber(subscriberId: string): void {
        const existSubscriber = this.subscribersOnOffline.find(item => item.id === subscriberId);

        if (!existSubscriber) {
            throw new Error(`OnOffline subscriber with id '${subscriberId}' not found`);
        }

        this.subscribersOnOffline = this.subscribersOnOffline.filter(item => item.id !== subscriberId);

        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `OnOffline subscriber with id '${subscriberId}' unregistered`
        );
    }

    /**
     * @inheritDoc
     */
    unregisterOnOnlineSubscriber(subscriberId: string): void {
        const existSubscriber = this.subscribersOnOnline.find(item => item.id === subscriberId);

        if (!existSubscriber) {
            throw new Error(`OnOnline subscriber with id '${subscriberId}' not found`);
        }

        this.subscribersOnOnline = this.subscribersOnOnline.filter(item => item.id !== subscriberId);

        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `OnOnline subscriber '${subscriberId}' unregistered`
        );
    }

    /**
     * Обработка события online
     *
     * @protected
     */
    protected onOnline(): void {
        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `Internet connection is online`
        );

        this.subscribersOnOnline.forEach(item => {
            try {
                item.func();
            } catch (e) {
                this.logger.error(
                    LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
                    `Error on call onOnline subscriber with id '${item.id}': `, e
                );
            }
        })
    }

    /**
     * Обработка события offline
     *
     * @protected
     */
    protected onOffline(): void {
        this.logger.info(
            LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
            `Internet connection is offline`
        );

        this.subscribersOnOffline.forEach(item => {
            try {
                item.func();
            } catch (e) {
                this.logger.error(
                    LoggerSectionsEnum.INTERNET_CONNECTION_CHECKER,
                    `Error on call onOffline subscriber with id '${item.id}': `, e
                );
            }
        })
    }
}
