import React, {useContext} from "react";
import {Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin} from "../../../../../styles/global-elements";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import {Form, useForm} from "../../../../../components/Ui/Elements/Form";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {DisciplineAndLevelForm, FormFieldNamesEnum} from "../../common/DisciplineAndLevelForm";
import {ItemType} from "../../../../../components/Ui/Elements/Selectors/AsyncSelect";
import styled from "styled-components";
import {ButtonArea} from "../ButtonArea";
import {StepWizardStatusEnum} from "../../../../../components/StepWizard/StepWizardStatusEnum";
import {IStepWizardContext, StepWizardContext} from "../../../../../components/StepWizard/StepWizardContext";

const FormWrapper = styled.div`
  margin-bottom: 100px;
`;

const FormFieldsWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

export const NewAgreementTrainingProgramSection: React.FC = () => {
    const [form] = useForm();

    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const onFinish = async (values: any) => {
        const courseFieldValue = form.getFieldValue(FormFieldNamesEnum.COURSE) as ItemType;
        const tpFieldValue = form.getFieldValue(FormFieldNamesEnum.TRAINING_PROGRAM) as ItemType;

        pageContext.setFormData((oldValue) => {
            return {
                ...oldValue,
                courseId: (courseFieldValue.id) ? parseInt(courseFieldValue.id) : null,
                courseName: courseFieldValue.name,
                tpId: (tpFieldValue.id) ? parseInt(tpFieldValue.id) : null,
                tpName: tpFieldValue.name
            }
        });

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.TRAINING_PROGRAM,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    return <div>
        <PageSubtitleSmallMargin>
            <Trans>Что {pageContext.shortStudentName} будет изучать?</Trans>
        </PageSubtitleSmallMargin>
        <FormWrapper>
            <Form form={form} layout={"inline"}
                  onFinish={onFinish}>
                <FormFieldsWrapper>
                    <DisciplineAndLevelForm
                        form={form}
                        courseIdInitValue={pageContext.formData.courseId}
                        courseNameInitValue={pageContext.formData.courseName}
                        trainingProgramIdInitValue={pageContext.formData.tpId}
                        trainingProgramNameInitValue={pageContext.formData.tpName}
                    />
                </FormFieldsWrapper>
            </Form>
            <ButtonArea
                prevSectionAvailable={true}
                nextSectionAvailable={true}
                prevBtnOnClick={() => {
                    stepWizardContext.goPrev();

                    return Promise.resolve();
                }}

                nextBtnOnClick={async () => {
                    await form.validateFields();
                    form.submit();
                }}
            />
        </FormWrapper>
    </div>
}