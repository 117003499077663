import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../../Ui/Elements/Modal";
import React, {useRef, useState} from "react";
import {Form, FormItem, useForm} from '../../../Ui/Elements/Form';
import {BtnStyleEnum, Button} from "../../../Ui/Elements/Button";
import {Input} from "../../../Ui/Elements/TextInput";
import {DtoTmDiscipline} from "../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {t, Trans} from "@lingui/macro";
import {ModalSubTitle, ModalTitle} from "../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../Ui/Elements/ConfirmDialog";
import {TextArea} from "../../../Ui/Elements/TextArea";
import {RequestValidationError} from "../../../../../components/HttpApiClient/Exception/RequestValidationError";

export enum ModalResultType {
    CREATED,
    UPDATED,
    DELETED
}

interface TmDisciplineModalProps extends ModalChildProps {
    organizationId: string;
    disciplineItem: DtoTmDiscipline | null;
    result: (action: ModalResultType, item: DtoTmDiscipline | null) => void;
}

export const TmDisciplineModal: React.FC<TmDisciplineModalProps> = (props) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        const filteredDescription = (
            (typeof values.description === "string") && (values.description.trim() !== '')
        ) ? values.description : null;

        setCloseAllowed(false);
        setSaveInProcess(true);

        const promise = (props.disciplineItem) ?
            httpApiClient.tmUpdateDiscipline(
                sessionToken,
                props.disciplineItem.id,
                values.name,
                filteredDescription
            )
            : httpApiClient.tmCreateDiscipline(
                sessionToken,
                props.organizationId,
                values.name,
                filteredDescription
            );

        promise
            .then((data) => {
                props.result(
                    (props.disciplineItem) ? ModalResultType.UPDATED : ModalResultType.CREATED,
                    data.data
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (props.disciplineItem) {
                    logger.error(LoggerSectionsEnum.TM_DISCIPLINES_API, "Error on update discipline: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_DISCIPLINES_API, "Error on create discipline: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef) || (!props.disciplineItem)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteDiscipline(
                sessionToken,
                props.disciplineItem.id
            )
        } catch (error) {
            if (error instanceof RequestValidationError) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Дисциплина содержит уровни`,
                    t`Попробуйте сначала удалить их`
                );

                return;
            }

            logger.error(LoggerSectionsEnum.TM_DISCIPLINES_API, "Error on delete discipline: ", error);

            throw error;
        }

        props.result(
            ModalResultType.DELETED,
            null
        );

        modalRef?.current?.close();
    }

    const footerContent = (_controls: ModalControlParams) => {
        if (props.disciplineItem === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Создать дисциплину</Trans>
                </Button>
            </div>;
        }

        // Если сейчас мы редактируем дисциплину
        return <>
            <ConfirmDialog trigger={
                <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                    <Trans>Удалить</Trans>
                </Button>
            }
                           okText={t`Удалить`}
                           cancelText={t`Отмена`}
                           title={t`Удалить дисциплину?`}
                           text={t`Вы уверены, что нужно удалить дисциплину ${props.disciplineItem.name}?`}
                           errorText={t`Не удалось удалить информацию`}
                           okMethod={onDelete}/>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>;
    }

    return <Modal innerRef={modalRef}
                  trigger={props.trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      setCloseAllowed(true);
                      setSaveInProcess(false);

                      form.resetFields();
                  }}
                  footer={footerContent}
                  footerMode={(props.disciplineItem) ? FooterMode.SPACE_BETWEEN : FooterMode.DEFAULT}
                  children={
                      (_controls) => {
                          return <div>
                              <ModalTitle>
                                  {
                                      (props.disciplineItem === null)
                                          ? <Trans>Создать дисциплину</Trans>
                                          : <Trans>Редактировать дисциплину</Trans>
                                  }
                              </ModalTitle>
                              <ModalSubTitle> </ModalSubTitle>
                              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={`name`}
                                      initialValue={props.disciplineItem?.name}
                                      rules={[
                                          {
                                              required: true,
                                              message: t`Необходимо указать название дисциплины`,
                                          },
                                          {
                                              type: "string",
                                              min: 3,
                                              message: t`Имя должно быть не короче трёх символов`
                                          },
                                          {
                                              type: "string",
                                              max: 255,
                                              message: t`Имя должно быть не длиннее 255 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={t`Название дисциплины`}/>
                                  </FormItem>
                                  <FormItem
                                      name={`description`}
                                      initialValue={props.disciplineItem?.description}
                                      rules={[
                                          {
                                              type: "string",
                                              max: 1000,
                                              message: t`Описание должно быть не длиннее 1000 символов`
                                          }
                                      ]}
                                  >
                                      <TextArea
                                          status={""}
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={t`Описание дисциплины`}/>
                                  </FormItem>
                              </Form>
                          </div>
                      }
                  }
    />
}
