import {FindPlayerIdPatternResult, ISlidePlayerPatternHelper} from "./ISlidePlayerPatternHelper";
import {matchPath} from "react-router";
import {SlidePlayerIdEnum} from "../../enums/SlidePlayerIdEnum";

export class SlidePlayerPatternHelper implements ISlidePlayerPatternHelper {

    /**
     * @inheritDoc
     */
    findPlayerIdPattern(playerId: string): FindPlayerIdPatternResult | null {
        let matchPathResult = null;

        const playerIdTypes = Object.values(SlidePlayerIdEnum);

        for (const slidePlayerIdTypeIndex in playerIdTypes) {
            matchPathResult = matchPath(
                {
                    path: '/' + playerIdTypes[slidePlayerIdTypeIndex]
                },
                '/' + playerId
            );

            if (matchPathResult) {
                return {
                    pattern: playerIdTypes[slidePlayerIdTypeIndex] as SlidePlayerIdEnum,
                    matchResult: matchPathResult
                };
            }
        }

        return null;
    }
}
