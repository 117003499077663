import storage from 'redux-persist/lib/storage';
import {createTransform} from "redux-persist";
import {QueueSendCycleStateEnum, SlidesWorkDataSaveQueueState} from "../store/slidesWorkDataSaveQueue/type";
import {StorageKeysEnum} from "../enums/StorageKeysEnum";

export const reduxPersistConfig = {
    key: StorageKeysEnum.REDUX_PERSIST_KEY, //'persistent-store',
    keyPrefix: StorageKeysEnum.REDUX_PERSIST_PREFIX, //'my-lesson:',
    version: 1,
    storage: storage,
    whitelist: ['commonPersisted', 'slidesWorkDataSaveQueue'],// Перечислить ветки, когда потребуется
    throttle: 500,
    debug: false,
    serialize: true,
    transforms: [
        createTransform(
            undefined,
            (outboundState: SlidesWorkDataSaveQueueState, _key) => {
                if (outboundState.sendCycleState === QueueSendCycleStateEnum.WORKING) {
                    outboundState.sendCycleState = QueueSendCycleStateEnum.STOPPED;
                }

                return outboundState;
            },
            {
                whitelist: ['slidesWorkDataSaveQueue']
            }
        )
    ]
};
