import React from "react";
import {Input} from "../../TextInput";
import {Trans} from "@lingui/macro";
import {InputRef} from "antd/lib/input/Input";
import styled from "styled-components";
import {InputLabelCss, RegularTextCss} from "../../../../../styles/global-elements";
import {DefaultLoader} from "../../../../DefaultLoader";
import {debounce, isEqual} from "lodash";


const ListWrapper = styled.div`
  padding: 10px;
  border-style: none solid solid solid;
  border-width: 1px;
  border-color: silver;
  border-radius: 0 0 20px 20px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${({theme}) => theme.zIndices.dropDownList};

  background: ${({theme}) => theme.colors.backgroundPrimary};
  top: calc(100% - 1px);
  border-top-style: dashed;
`;

const Wrapper = styled.div`
  ${ListWrapper} {
    display: none;
  }

  &:focus-within {
    ${ListWrapper} {
      display: block;
    }
  }
`;

const ListTitle = styled.div`
  ${InputLabelCss};
`;

const ListPlaceholder = styled.div`
  ${RegularTextCss};
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  ${RegularTextCss};

  cursor: pointer;
  padding: 5px 0;
`;

export type ItemType = {
    id: string | null;
    name: string;
}

enum InputState {
    NO_INIT,
    NORMAL,
    WAIT_RESULTS,
    ERROR_LOAD_RESULTS
}

interface AsyncSelectProps {
    value?: ItemType;
    onChange?: (value: ItemType) => void;
    placeholder?: string;
    searchMethod: (searchString: string) => Promise<ItemType[]>;
    className?: string;
    disableSearch?: boolean;
}

interface AsyncSelectState {
    inputState: InputState;
    lastSearchString: string | null;
    listOpened: boolean;
    searchResults: ItemType[];
}

export class AsyncSelect extends React.Component<AsyncSelectProps, AsyncSelectState> {

    protected inputRef: React.RefObject<InputRef>;
    protected wrapperRef: React.RefObject<HTMLDivElement>;
    protected searchItemsDebounced;
    protected ignoreNextChange: boolean;

    constructor(props: Readonly<AsyncSelectProps>) {
        super(props);

        this.state = {
            inputState: InputState.NO_INIT,
            lastSearchString: null,
            listOpened: false,
            searchResults: []
        }

        this.searchItemsDebounced = debounce(this.searchItems, 500);
        this.wrapperRef = React.createRef();
        this.inputRef = React.createRef();
        this.ignoreNextChange = false;
    }

    componentDidUpdate(prevProps: Readonly<AsyncSelectProps>, prevState: Readonly<AsyncSelectState>, snapshot?: any) {
        if (this.ignoreNextChange) {
            this.ignoreNextChange = false;

            return;
        }

        if (!isEqual(prevProps.value, this.props.value)) {
            this.startSearchItems();
        }

        if (prevProps.disableSearch === true && this.props.disableSearch === false) {
            this.startSearchItems(true);
        }
    }

    public startSearchItems(dontShowList?: boolean) {
        if (this.props.disableSearch) {
            return;
        }

        this.setState((prevState) => {
            return {
                inputState: InputState.WAIT_RESULTS,
                listOpened: (dontShowList) ? prevState.listOpened : true
            }
        });

        this.searchItemsDebounced(dontShowList);
    }

    protected searchItems(dontShowList?: boolean) {
        if (
            !!(this.props.value?.name)
            && (!!this.state.lastSearchString)
            && (this.props.value.name === this.state.lastSearchString)
        ) {
            this.setState(() => {
                return {
                    inputState: InputState.NORMAL
                }
            });

            return;
        }

        this.setState((prevState) => {
            return {
                inputState: InputState.WAIT_RESULTS,
                listOpened: (dontShowList) ? prevState.listOpened : true
            }
        });

        this.props.searchMethod(this.props.value?.name ?? '')
            .then((items) => {
                this.setState(() => {
                    return {
                        inputState: InputState.NORMAL,
                        searchResults: items,
                        listOpened: items.length > 0
                    }
                });
            })
            .catch((e) => {
                this.setState(() => {
                    return {
                        inputState: InputState.ERROR_LOAD_RESULTS,
                        searchResults: []
                    }
                });
            });
    }

    protected inputOnChange(value: React.ChangeEvent<HTMLInputElement>) {
        if (!this.props.onChange) {
            return;
        }

        this.props.onChange({
            id: null,
            name: value.target.value
        });
    };

    protected onItemClick(value: ItemType) {
        if (!this.props.onChange) {
            return;
        }

        this.ignoreNextChange = true;
        this.props.onChange(value);

        this.setState(() => {
            return {
                listOpened: false,
                searchResults: []
            }
        });
    }

    protected onBlur(event: React.FocusEvent<HTMLDivElement>) {
    }

    protected onFocus(event: React.FocusEvent<HTMLDivElement>) {
        if (this.state.listOpened) {
            return;
        }

        if (this.props.value && this.props.value.name !== '' && this.state.searchResults.length === 0) {
            // Если значение есть но результатов нет, каждый раз не открываем
            return;
        }

        if (this.wrapperRef.current) {
            this.setState(() => {
                return {
                    listOpened: true
                }
            });
        }
    }

    protected resultListContent() {
        switch (this.state.inputState) {
            case InputState.NO_INIT:
            case InputState.NORMAL: {
                const searchTextFoundInResults = this.state.searchResults.some(
                    (item) => item.name === ((this.props.value) ? this.props.value.name : null)
                );

                return <List>
                    {
                        (this.props.value) && (!searchTextFoundInResults)
                        && <ListItem key={'current'} onClick={() => {
                            if (this.props.value) {
                                this.onItemClick(this.props.value);
                            }
                        }}>
                            {this.props.value.name}
                        </ListItem>
                    }
                    {
                        this.state.searchResults.map((searchItem, index) => {
                            return <ListItem
                                key={searchItem.id}
                                onClick={() => this.onItemClick(searchItem)}
                            >
                                {searchItem.name}
                            </ListItem>
                        })
                    }
                </List>
            }
            case InputState.WAIT_RESULTS: {
                return <DefaultLoader height={'auto'}/>;
            }
            case InputState.ERROR_LOAD_RESULTS:
            default: {
                return <ListPlaceholder>{this.props.placeholder}</ListPlaceholder>;
            }
        }
    };

    render() {
        return <Wrapper ref={this.wrapperRef}
                        className={this.props.className}
                        onFocus={(event) => this.onFocus(event)}
                        onBlur={(event) => this.onBlur(event)}
                        tabIndex={0}
        >
            <Input ref={this.inputRef}
                   placeholder={this.props.placeholder}
                   value={this.props.value?.name}
                   onChange={(event) => this.inputOnChange(event)}
            />
            {
                (this.state.listOpened) && (!this.props.disableSearch) && <ListWrapper>
                    <ListTitle>
                        <Trans>Выберите вариант или оставьте свой текст</Trans>
                    </ListTitle>
                    {this.resultListContent()}
                </ListWrapper>
            }
        </Wrapper>
    }
}
