import {
    IsString,
    Length,
    IsNotEmpty,
} from 'class-validator';
import {LengthEnum} from '../../../Enums/LengthEnum';

/**
 * DTO параметров ответа на запрос создания временного пользователя
 */
export class DtoRegisterTempResponse {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    sessionToken: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    mergeToken: string;
}