import React, {CSSProperties, useMemo} from "react";
import {WeekDayNameByNumber, WeekDayType} from "./WeekDayNameByNumber";

interface WeekDayNameByDateProps {
    date: Date;
    nameType: WeekDayType;
    style?: CSSProperties;
    className?: string;
    lowerCase?: boolean;
}

export const WeekDayNameByDate: React.FC<WeekDayNameByDateProps> = (props) => {

    const weekDayNumber = useMemo<number>(() => {
        return props.date.getDay();
    }, [props]);

    return <WeekDayNameByNumber
        weekDayNumber={weekDayNumber}
        nameType={props.nameType}
        style={props.style}
        className={props.className}
        lowerCase={props.lowerCase}
    />;
}
