export enum LessonMaterialsActionTypes {
    LOAD_LESSON_MATERIALS_LIST = '@@lessonMaterials/LOAD_LESSON_MATERIALS',

    SET_LOADING_STATE = '@@lessonMaterials/SET_LOADING_STATE',
    SET_LESSON_ID = '@@lessonMaterials/SET_LESSON_ID',
    SET_MATERIALS_TYPE = '@@lessonMaterials/SET_MATERIALS_TYPE',
    SET_LESSON_MATERIALS_LIST = '@@lessonMaterials/SET_LESSON_MATERIALS_LIST',
    RESET_LESSON_MATERIALS_STATE = '@@lessonMaterials/RESET_LESSON_MATERIALS_STATE',

    SUBSCRIBE_TO_SLIDE_LIST = '@@lessonMaterials/SUBSCRIBE_TO_SLIDE_LIST',
    UNSUBSCRIBE_FROM_SLIDE_LIST = '@@lessonMaterials/UNSUBSCRIBE_FROM_SLIDE_LIST',

    ADD_SUBSCRIPTION_ID = '@@lessonMaterials/ADD_SUBSCRIPTION_ID',
    REMOVE_SUBSCRIPTION_ID = '@@lessonMaterials/REMOVE_SUBSCRIPTION_ID',
    CLEAR_SUBSCRIPTIONS_LIST = '@@lessonMaterials/CLEAR_SUBSCRIPTIONS_LIST',

    HANDLE_SE_NEED_SYNC_SLIDE_LIST = '@@lessonMaterials/HANDLE_SE_NEED_SYNC_SLIDE_LIST',
}

export enum LoadingStateEnum {
    NOT_INIT,
    INIT_LOADING,
    SUCCESS,
    ERROR,
    SILENT_ERROR,
    SILENT_LOADING
}

export interface SlideItemData {
    tmId: string;
    tmName: string;
    exerciseCount: number;
    contentVersion: number;
    orderPosition: number;
    hiddenForStudent: boolean;
}

export interface LessonMaterialsState {
    loadingState: LoadingStateEnum;
    lessonId: string | null;
    homeworkMaterials: boolean | null;
    slidesList: SlideItemData[];

    // Список id уроков, на изменение списка слайдов которых мы подписаны (в store просто для облегчения диагностики)
    subscriptions: string[];
}
