import React, {useContext, useMemo} from "react";
import styled from "styled-components";
import {ISubscriptionContext, SubscriptionContext} from "../../SubscriptionContext";
import {EpisodeContext, IEpisodeContext} from "../EpisodeContext";
import {ButtonPlaceholder} from "./ButtonPlaceholder";
import {GoButton} from "./GoButton";

const Wrapper = styled.div`
    display: flex;
`;

export const ButtonArea: React.FC = () => {
    const {overviewItem} = useContext<ISubscriptionContext>(SubscriptionContext);
    const {episodeItem} = useContext<IEpisodeContext>(EpisodeContext);

    const isActive = useMemo<boolean>(() => {
        return episodeItem.id === overviewItem.lastEpisodeId && episodeItem.completedAt === null;
    }, [episodeItem.completedAt, episodeItem.id, overviewItem.lastEpisodeId]);

    return <Wrapper>
        {
            (isActive) ? <GoButton/> : <ButtonPlaceholder/>
        }
    </Wrapper>
}