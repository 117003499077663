import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .video-item {
    background-color: #2b313b;
    color: ${({theme}) => theme.colors.textAlwaysWhite};
  }

  .video-list-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
  }

  .control-panel-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-shrink: 0;
  }

  &.mode-default {
    background: none;

    .video-list-wrapper {
      height: auto;
    }

    .video-item {
      aspect-ratio: 16/9;
      max-width: 100%;
      flex-grow: 1;
    }

    .video-grid {
      // Один элемент
      &.one-item {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      &.one-item .video-item {
        width: 100%;
        height: auto;
      }

      // Два элемента
      &.two-item {
        grid-template: repeat(1,1fr) / repeat(2,1fr);
      }

      &.two-item .video-item {
        min-width: 0;
        min-height: 0;
        flex-shrink: 0;
        flex-grow: 1;
      }

      // Три и 4 элемента
      &.three-item, &.four-item {
        grid-template: repeat(2,minmax(0, 1fr)) / repeat(2,minmax(0, 1fr));

        .video-item {
          min-width: 0;
          min-height: 0;
          flex-shrink: 0;
          flex-grow: 1;
        }

        .video-item-wrapper {
          height: 100%;

          @media (${({theme}) => theme.media.small}) {
            height: 100%;
          }
        }

        .video-item-wrapper:nth-child(odd) .video-item {
          margin-left: auto;
          margin-right: unset;
        }

        .video-item-wrapper:nth-child(even) .video-item {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }

    .video-list-wrapper {
      flex-shrink: 0;
      max-width: 100%;
      width: fit-content;
      margin: auto;

      .video-item {
        aspect-ratio: 16/9;
        width: auto;
        height: 100%;
        max-height: 100px;
      }
    }
  }


  &.mode-fs, &.mode-doc-in-picture {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100 dvh;
    max-height: 100 dvh;
    background-color: ${({theme}) => theme.colors.backgroundAlwaysDark};
    z-index: ${({theme}) => theme.zIndices.videoFullScreenArea};
    overflow: auto;

    .control-panel-width-wrapper {
      width: 80%;

      @media (${({theme}) => theme.media.small}) {
        width: 70%;
      }

      @media (${({theme}) => theme.media.large}) {
        width: 60%;
      }
    }

    .panel-item {
      background-color: ${({theme}) => theme.colors.backgroundSecondary}bd;
      backdrop-filter: blur(10px);
    }

    .video-list-wrapper {
      height: 100px;
      flex-shrink: 0;
      max-width: 100%;

      .video-item {
        aspect-ratio: 16/9;
        width: auto;
        height: 100%;
      }
    }
  }

  &.mode-doc-in-picture {
    padding: 10px;

    color: ${({theme}) => theme.colors.textInverse};

    .video-grid {
      // Один элемент
      &.one-item {
        height: 100%;
      }

      &.one-item .video-item {
        height: 100%;
        width: 100%;
      }

      // Два элемента
      &.two-item {
        grid-template: repeat(1,1fr) / repeat(2,1fr);
      }

      &.two-item .video-item {
        min-width: 0;
        min-height: 0;
        flex-shrink: 0;
        flex-grow: 1;
        aspect-ratio: 9/16;
        max-height: 100%;
        margin: auto;

        @media (${({theme}) => theme.media.small}) {
          aspect-ratio: 16/9;
          max-height: 100%;
          width: 100%;
        }
      }
    }
  }

  &.mode-fs {
    padding: 10px;

    color: ${({theme}) => theme.colors.textInverse};

    .video-grid {
      // Один элемент
      &.one-item {
        height: 100%;
      }

      &.one-item .video-item {
        height: 100%;
        width: 100%;
      }

      // Два элемента
      &.two-item {
        grid-template: repeat(1,1fr) / repeat(2,1fr);
      }

      &.two-item .video-item {
        min-width: 0;
        min-height: 0;
        flex-shrink: 0;
        flex-grow: 1;
        aspect-ratio: 9/16;
        max-height: 100%;
        margin: auto;
        height: 100%;
        max-width: 100%;

        @media (${({theme}) => theme.media.small}) {
          aspect-ratio: 16/9;
          height: auto;
          width: 100%;
        }
      }

      // Три и 4 элемента
      &.three-item, &.four-item {
        grid-template: repeat(2,minmax(0, 1fr)) / repeat(2,minmax(0, 1fr));

        .video-item {
          min-width: 0;
          min-height: 0;
          flex-shrink: 0;
          flex-grow: 1;
          aspect-ratio: 9/16;
          height: 100%;
          max-width: 100%;

          @media (${({theme}) => theme.media.small}) {
            aspect-ratio: 16/9;
          }

          &:nth-child(odd) {
            margin-left: auto;
            margin-right: unset;
          }

          &:nth-child(even) {
            margin-right: auto;
            margin-left: unset;
          }
        }
      }

      &.many-items {
        height: auto;
      }

      &.five-item, &.six-item, &.seven-item, &.eight-item, &.nine-item {
        height: auto;

        @media (${({theme}) => theme.media.large}) {
          height: 100%;
        }
      }

      // Пять и больше элементов
      &.five-item, &.six-item, &.seven-item, &.eight-item, &.nine-item, &.many-items {
        grid-template: repeat(auto-fit,minmax(0, 1fr)) / repeat(2,minmax(0, 1fr));

        @media (${({theme}) => theme.media.small}) {
          grid-template: repeat(auto-fit,minmax(0, 1fr)) / repeat(2,minmax(0, 1fr));
        }

        @media (${({theme}) => theme.media.large}) {
          grid-template: repeat(auto-fit,minmax(0, 1fr)) / repeat(3,minmax(0, 1fr));
        }

        .video-item {
          min-width: 0;
          min-height: 0;
          flex-shrink: 0;
          flex-grow: 1;
          aspect-ratio: 9/16;
          height: 100%;
          max-width: 100%;

          @media (${({theme}) => theme.media.small}) {
            aspect-ratio: 16/9;
          }


          @media (${({theme}) => theme.media.small}) {
            &:nth-child(odd) {
              margin-left: auto;
              margin-right: unset;
            }

            &:nth-child(even) {
              margin-right: auto;
              margin-left: unset;
            }
          }

          @media (${({theme}) => theme.media.large}) {
            &:nth-child(odd) {
              margin-left: auto;
              margin-right: auto;
            }

            &:nth-child(even) {
              margin-right: auto;
              margin-left: auto;
            }

            &:nth-child(3n+1) {
              margin-left: auto;
              margin-right: unset;
            }

            &:nth-child(3n+3) {
              margin-left: unset;
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  &.mode-float-on-document {
    position: fixed;
    z-index: ${({theme}) => theme.zIndices.tinyVideoOnPage};

    top: ${({theme}) => theme.size.headerHeight + 10}px;
    right: 10px;

    max-width: 100px;
    max-height: 100px;

    @media (${({theme}) => theme.media.large}) {
      max-width: 342px;
      max-height: 242px;
      right: calc(50% - 539px);
      top: 100px;
    }

    @media (${({theme}) => theme.media.extraLarge}) {
      right: calc(50% - 655px);
      top: 100px;
    }

    background: none;

    .video-list-wrapper {
      height: auto;
    }

    .video-item {
      max-width: 100%;
      flex-grow: 1;

      aspect-ratio: 1/1;
      border-radius: 50%;

      @media (${({theme}) => theme.media.large}) {
        aspect-ratio: 16/9;
        border-radius: 8px;
      }
    }

    .controls-layer {
      border-radius: 50%;
      
      @media (${({theme}) => theme.media.large}) {
        border-radius: 8px;
      }
    }

    .video-grid {
      // Один элемент
      &.one-item {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
      }

      &.one-item .video-item {
        width: 100%;
        height: auto;
      }

      // Два элемента
      &.two-item {
        grid-template: repeat(1,1fr) / repeat(2,1fr);
      }

      &.two-item .video-item {
        min-width: 0;
        min-height: 0;
        flex-shrink: 0;
        flex-grow: 1;
        transform: translateY(50%);
      }

      // 3-4 элемента
      &.three-item, &.four-item {
        grid-template: repeat(2,minmax(0, 1fr)) / repeat(2,minmax(0, 1fr));

        .video-item {
          min-width: 0;
          min-height: 0;
          flex-shrink: 0;
          flex-grow: 1;
        }

        .video-item-wrapper {
          height: 100%;

          @media (${({theme}) => theme.media.small}) {
            height: 100%;
          }
        }

        .video-item-wrapper:nth-child(odd) .video-item {
          margin-left: auto;
          margin-right: unset;
        }

        .video-item-wrapper:nth-child(even) .video-item {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }
  }
`;

export const VideoZonesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  min-height: 0;
  justify-content: flex-start;
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: 10px;
  justify-content: center;
`;
