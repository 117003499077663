import React, {useMemo} from "react";
import {FooterMode, Modal} from "../../../../../../components/Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {PageTitle, RegularText} from "../../../../../../styles/global-elements";
import styled from "styled-components";
import bgImage from "./hometaskBg.jpg";
import {LinkAsButton} from "../../../../../../components/Ui/Elements/LinkAsButton";
import {ReactComponent as Pencil} from "../../../../../../components/Ui/Svg/Pencil.svg";
import {RoutesHelper} from "../../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../../RoutesList";
import {DateAsString} from "../../../../../../components/Ui/Elements/TextPresenter/DateAsString";

const InfoLine = styled.div`
  font-size: 14px;
  color: ${({theme}) => theme.colors.textSecondary};

  @media (${({theme}) => theme.media.large}) {
    font-size: 18px;
  }
`;

const WideImage = styled.div`
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  background-size: cover;
  background-position: center;
  min-height: 160px;
  max-height: 160px;
  background-image: url(${bgImage});

  @media (${({theme}) => theme.media.small}) {
    min-height: 200px;
    max-height: 200px;
  }

  @media (${({theme}) => theme.media.large}) {
    min-height: 240px;
    max-height: 240px;
  }
`;

const PageTitleStyled = styled(PageTitle)`
  margin-bottom: 0;
`;

const TitleWrapper = styled.div`
  margin-bottom: 32px;
`;

const ContentBlock = styled.div`
  margin-bottom: 20px;
`;

const FooterButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    
    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const StartBtnStyled = styled(LinkAsButton)`
      padding: 6px 12px;
      
      @media (${({theme}) => theme.media.small}) {
          padding: 12px 16px;
      }
`;

interface HometaskModalProps {
    trigger: JSX.Element;
    hometaskDate: Date;
    lessonId?: string;
    teacherComment?: string;
    hometaskTeacherText?: string;
    slidesCount: number;
    exerciseCount: number;
    exerciseStarted: boolean;
    exerciseCompleted: boolean;
}

export const HometaskModal: React.FC<HometaskModalProps> =
    ({
         trigger,
         hometaskDate,
         lessonId,
         teacherComment,
         hometaskTeacherText,
         slidesCount,
         exerciseCount,
         exerciseStarted,
         exerciseCompleted
     }) => {

        const linkToHometask = useMemo<string>(() => {
            if (lessonId === undefined) {
                return '';
            }

            return RoutesHelper.replaceParams(
                RoutesList.STUDENT_HOMEWORK_PAGE,
                [{key: 'lessonId', value: lessonId}]
            )
        }, [lessonId]);

        const startBtn = useMemo<JSX.Element | null>(() => {
            if ((exerciseCount === 0) && (slidesCount === 0)) {
                return null;
            }

            const hasExercises = (exerciseCount > 0);

            if (!hasExercises) {
                // Есть слайды, но нет упражнений
                return <StartBtnStyled btnStyle={BtnStyleEnum.Primary}
                                       to={linkToHometask}
                                       icon={<Pencil/>}
                                       alignCenter={true}
                ><Trans>Посмотреть материалы</Trans></StartBtnStyled>
            }

            if (!exerciseCompleted) {
                return <StartBtnStyled btnStyle={BtnStyleEnum.Primary}
                                       to={linkToHometask}
                                       icon={<Pencil/>}
                                       alignCenter={true}
                >
                    {
                        (!exerciseStarted)
                            ? <Trans>Выполнить упражнения</Trans>
                            : <Trans>Продолжить выполнение</Trans>
                    }
                </StartBtnStyled>
            }

            return <StartBtnStyled btnStyle={BtnStyleEnum.Secondary}
                                   to={linkToHometask}
                                   icon={<Pencil/>}
                                   alignCenter={true}
            >
                <Trans>Посмотреть упражнения</Trans>
            </StartBtnStyled>
        }, [exerciseCompleted, exerciseCount, linkToHometask]);

        return <Modal trigger={trigger}
                      closeAllowed={true}
                      footerMode={(startBtn === null) ? FooterMode.SPACE_BETWEEN : FooterMode.SPACE_BETWEEN}
                      footer={
                          (controls) => {
                              if (startBtn === null) {
                                  return <Button btnStyle={BtnStyleEnum.Primary}
                                                 onClick={controls.closeModal}
                                  >
                                      <Trans>Закрыть</Trans>
                                  </Button>;
                              }

                              return <FooterButtonsWrapper>
                                  {startBtn}
                                  <Button btnStyle={BtnStyleEnum.Secondary}
                                          onClick={controls.closeModal}
                                  >
                                      <Trans>Закрыть</Trans>
                                  </Button>
                              </FooterButtonsWrapper>
                          }
                      }
                      wideHeaderBlock={
                          <WideImage/>
                      }
                      children={
                          (controls) => {
                              return <>
                                  <TitleWrapper>
                                      <PageTitleStyled><Trans>Домашнее задание</Trans></PageTitleStyled>
                                      <InfoLine>
                                          <span><Trans>за</Trans>&nbsp;<DateAsString date={hometaskDate}/></span>
                                      </InfoLine>
                                  </TitleWrapper>
                                  {
                                      (teacherComment)
                                      && <ContentBlock>
                                          <RegularText>
                                              <b>
                                                  <Trans>Персональное сообщение преподавателя:</Trans>&nbsp;
                                              </b><br/>
                                              {teacherComment}
                                          </RegularText>
                                      </ContentBlock>
                                  }
                                  {
                                      (hometaskTeacherText)
                                      && <ContentBlock>
                                          <RegularText>
                                              <b>
                                                  <Trans>Домашнее задание: </Trans>&nbsp;
                                              </b><br/>
                                              {hometaskTeacherText}
                                          </RegularText>
                                      </ContentBlock>
                                  }
                              </>
                          }
                      }
        />;
    }
