import {RichTextEditorMode} from "../Enums";

export const hotKeys = (editorMode: RichTextEditorMode): { [id: string]: string } => {
    switch (editorMode) {
        case RichTextEditorMode.FILL_GAPS_COMBOBOX:
        case RichTextEditorMode.FILL_GAPS_TEXT:
        case RichTextEditorMode.FILL_GAPS_DRAG_DROP:
        case RichTextEditorMode.CORRECT_EXIST_IN_INPUT: {
            return {
                'mod+b': 'bold',
                'mod+i': 'italic',
                'mod+u': 'underline',
                'mod+enter': 'code',
                'cmd+enter': 'code',
                'mod+/': 'code',
                'mod+q': 'translate'
            };
        }
        case RichTextEditorMode.TITLE: {
            return {
                'mod+i': 'italic',
                'mod+u': 'underline',
                'mod+q': 'translate'
            };
        }
        default: {
            return {
                'mod+b': 'bold',
                'mod+i': 'italic',
                'mod+u': 'underline',
                'mod+q': 'translate'
            };
        }
    }
}
