import React, {RefObject} from "react";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {IWsApiClient} from "../../../../../../../components/WsApiClient/IWsApiClient";
import {LessonPageContext} from "../../../LessonPageContext";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {Dispatch} from "redux";
import {ApplicationState} from "../../../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import styled from "styled-components";
import {StudentSlideViewDetailsType} from "../Common/StudentSlideViewDetailsType";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {PopupActions} from "reactjs-popup/dist/types";
import {EditorData} from "../../../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {ApiMethodEnum} from "../../../../../../../components/WsApiClient/ApiMethodEnum";
import {LoadSlideWorkDataActionParams} from "../../../../../../../store/slidesWorkData/type";
import {EditorDataDtoMapper} from "../../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {loadSlideWorkData} from "../../../../../../../store/slidesWorkData/actions";
import {DefaultLoader} from "../../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../../components/Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import {PlayerContextProvider} from "../../../../../../components/SlidePlayer/PlayerContext";
import {SlidePlayer} from "../../../../../../components/SlidePlayer";
import {SlideName} from "../Common/SlideName";
import {StudentItem} from "../Common/StudentItem";
import {StudentLessonWorkSlideDataModal} from "./StudentResultsBySlidesModal";
import {ReactComponent as ArrowBackIcon} from "../../../../../../components/Ui/Svg/ArrowBack.svg";
import {roomMembersSelector} from "../../../../../../../store/lessonRoom/selector";
import {LessonRoomMember} from "../../../../../../../store/lessonRoom/type";
import {isEqual} from "lodash";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {WsConnectionStatusEnum} from "../../../../../../../components/WsApiClient/WsConnectionStatusEnum";
import {NoConnection} from "../../../../../../../components/HttpApiClient/Exception/NoConnection";
import {SlideItemsParamsBySlide} from "../../../../../../components/SlidePlayer/SlideItemParamsStore";

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

const Wrapper = styled.div`
`;

const NavigationPanel = styled.div`
    width: 100%;
    padding: 10px 10px 10px 0;
    top: ${({theme}) => theme.size.headerHeight}px;
    position: sticky;
    z-index: ${({theme}) => theme.zIndices.pageContent};
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
`;

const NavigationPanelBackItem = styled.div`
    max-width: 40px;
    min-width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
   
    &:hover {
        opacity: 1;
    }
`;

const NavigationPanelItem = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &.slide-name {
        display: none;
    }
    
    @media  (${({theme}) => theme.media.small}) {
        flex-basis: 50%;
        
        &.slide-name {
            display: flex;
        }
    }
`;

const MoveStudentToSlideBtn = styled(Button)`
    padding: 10px;
`;

interface StudentSlideViewProps extends PropsFromRedux {
    data: StudentSlideViewDetailsType;
    openStudentSlideViewProc: (data: StudentSlideViewDetailsType) => void;
    closeStudentSlideView: () => void;
}

interface StudentSlideViewState {
    loadingState: LoadingState;
    slideContent: EditorData | null;
    useShortStudentName: boolean;
    studentIsOnline: boolean;
    studentIsOnSlide: boolean;

    moveStudentToSlideSignalSending: boolean;
}

class StudentSlideView extends React.Component<StudentSlideViewProps, StudentSlideViewState> {
    protected apiClient: IHttpApiClient;
    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    protected mediaQuerySmall;
    protected mediaQueryMedium;

    protected abortController: AbortController | null;
    protected modalRef: RefObject<PopupActions>;

    protected moveStudentToSlideSignalSendingTimer: ReturnType<typeof setTimeout> | null;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<StudentSlideViewProps> | StudentSlideViewProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        this.abortController = null;

        if (this.props.currentTheme === undefined) {
            throw new Error("currentTheme is undefined");
        }

        this.mediaQuerySmall = window.matchMedia(`(${this.props.currentTheme.media.small})`);
        this.mediaQueryMedium = window.matchMedia(`(${this.props.currentTheme.media.medium})`);

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            slideContent: null,
            useShortStudentName: this.mediaQuerySmall.matches && !this.mediaQueryMedium.matches,
            studentIsOnline: this.getStudentIsOnline(),
            studentIsOnSlide: this.getStudentIsOnSlide(),
            moveStudentToSlideSignalSending: false
        }

        this.moveStudentToSlideSignalSendingTimer = null;

        this.modalRef = React.createRef<PopupActions>();
    }

    componentDidMount() {
        // Если подключение готово, загружаем базовую информацию. Иначе просто ждём установки соединения.
        if (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED) {
            this.subscribeToStudentWorkData(
                this.props.data.lessonId,
                this.props.data.studentId,
                this.props.data.tmSlideId
            );

            this.fetchSlideContent();
        }

        window.addEventListener("resize", this.onWindowResize);
    }

    componentDidUpdate(prevProps: Readonly<StudentSlideViewProps>, prevState: Readonly<StudentSlideViewState>, snapshot?: any) {
        if (this.state.loadingState === LoadingState.NOT_INIT) {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если страница не инициализирована
                // и ws соединение только сейчас подготовилось (не было готово на этапе монтирования)
                this.subscribeToStudentWorkData(
                    this.props.data.lessonId,
                    this.props.data.studentId,
                    this.props.data.tmSlideId
                );

                this.fetchSlideContent();
            }
        } else if (this.state.loadingState === LoadingState.SUCCESS) {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если соединение установлено после какой-то проблемы, когда страница уже прошла инициализацию
                this.subscribeToStudentWorkData(
                    this.props.data.lessonId,
                    this.props.data.studentId,
                    this.props.data.tmSlideId
                );

                this.fetchSlideContent(true);
            }
        }

        if (prevProps.data.tmSlideId !== this.props.data.tmSlideId) {
            this.fetchSlideContent();
        }

        if (
            (prevProps.data.tmSlideId !== this.props.data.tmSlideId)
            || (prevProps.data.studentId !== this.props.data.studentId)
        ) {
            this.subscribeToStudentWorkData(
                this.props.data.lessonId,
                this.props.data.studentId,
                this.props.data.tmSlideId
            );

            this.unsubscribeFromStudentWorkData(
                prevProps.data.lessonId,
                prevProps.data.studentId,
                prevProps.data.tmSlideId
            );
        }

        if (
            !isEqual(prevProps.roomMembers, this.props.roomMembers)
            || (prevProps.data.tmSlideId !== this.props.data.tmSlideId)
        ) {
            const studentIsOnline = this.getStudentIsOnline();
            const studentIsOnSlide = this.getStudentIsOnSlide();

            if (
                (this.state.studentIsOnline !== studentIsOnline)
                || (this.state.studentIsOnSlide !== studentIsOnSlide)
            ) {
                this.setState(() => {
                    return {
                        studentIsOnline: studentIsOnline,
                        studentIsOnSlide: studentIsOnSlide
                    }
                })
            }
        }
    }

    componentWillUnmount() {
        this.unsubscribeFromStudentWorkData(
            this.props.data.lessonId,
            this.props.data.studentId,
            this.props.data.tmSlideId
        );

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOT_INIT
            }
        });

        window.removeEventListener("resize", this.onWindowResize);

        if (this.moveStudentToSlideSignalSendingTimer !== null) {
            clearTimeout(this.moveStudentToSlideSignalSendingTimer);
        }
    }

    protected subscribeToStudentWorkData(lessonId: string, studentId: string, tmSlideId: string) {
        this.wsClient.query(
            ApiMethodEnum.LESSON_AND_HOMEWORK_SUBSCRIBE_TEACHER_TO_SLIDE_WORK_DATA,
            {
                lessonId: lessonId,
                studentUserId: studentId,
                tmSlideId: tmSlideId,
                homeworkSlide: false
            },
            undefined,
            true
        ).then();
    }

    protected unsubscribeFromStudentWorkData(lessonId: string, studentId: string, tmSlideId: string) {
        this.wsClient.query(
            ApiMethodEnum.LESSON_AND_HOMEWORK_UNSUBSCRIBE_TEACHER_FROM_SLIDE_WORK_DATA,
            {
                lessonId: lessonId,
                studentUserId: studentId,
                tmSlideId: tmSlideId,
                homeworkSlide: false
            },
            undefined,
            true
        ).then();
    }

    protected getStudentCurrentRoomMemberItem = (): LessonRoomMember | null => {
        if (this.props.data.studentId === null) {
            return null;
        }

        return this.props.roomMembers.find(item => item.userId === this.props.data.studentId) ?? null;
    }

    protected getStudentIsOnline = (): boolean => {
        return !!this.getStudentCurrentRoomMemberItem();
    }

    protected getStudentIsOnSlide = (): boolean => {
        const roomMemberItem = this.getStudentCurrentRoomMemberItem();

        if (!roomMemberItem || !roomMemberItem.detailsForTeacher) {
            return false;
        }

        return roomMemberItem.detailsForTeacher.openedSlideId === this.props.data.tmSlideId;
    }

    protected modeStudentToCurrentSlide = () => {
        this.wsClient.query(
            ApiMethodEnum.LESSON_ROOM_MOVE_STUDENT_TO_SLIDE,
            {
                lessonId: this.props.data.lessonId,
                studentId: this.props.data.studentId,
                tmSlideId: this.props.data.tmSlideId,
            },
            undefined,
            true
        ).then();

        this.setState(() => {
            return {
                moveStudentToSlideSignalSending: true
            }
        });

        this.moveStudentToSlideSignalSendingTimer = setTimeout(() => {
            this.setState(() => {
                return {
                    moveStudentToSlideSignalSending: false
                }
            })
        }, 2000);
    }

    protected onWindowResize = () => {
        const userShortStudentNameStatus = (this.mediaQuerySmall.matches && !this.mediaQueryMedium.matches);

        if (this.state.useShortStudentName !== userShortStudentNameStatus) {
            this.setState(() => {
                return {
                    useShortStudentName: userShortStudentNameStatus
                }
            })
        }
    }

    protected fetchSlideContent = (afterReconnect?: boolean) => {
        if (this.props.apiToken === null) {
            return;
        }

        const loadStudentWorkDataParams: LoadSlideWorkDataActionParams = {
            playerId: this.props.data.resultsPlayerId,
            slideIds: [this.props.data.tmSlideId],
            lessonId: this.props.data.lessonId,
            userId: this.props.data.studentId
        }

        this.props.loadSlideWorkData(loadStudentWorkDataParams);

        if (afterReconnect) {
            // После переподключения нас интересуют только работа ученика. Содержимое слайда наверное не изменилось.
            return;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING,
                slideContent: null
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.tmGetSlideContent(
            this.props.apiToken,
            this.props.data.tmSlideId,
            this.props.data.slideContentVersion,
            this.abortController
        )
            .then((data) => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                        slideContent: EditorDataDtoMapper.dtoToEditorData(data.data.content)
                    }
                })
            })
            .catch((error) => {
                const messageText = "Error on fetch tmSlide content for preview: " + this.props.data.tmSlideId;

                if (error instanceof NoConnection) {
                    this.logger.info(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.TM_SLIDES_API, messageText, error);
                }

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }

    protected openStudentResultsBySlidesModal() {
        this.modalRef.current?.open();
    }

    protected warnings() {
        if (!this.context.onlineRoomIsConnected) {
            if (this.context.onlineRoomIsOpened) {
                return <NoticeBlock>
                    <>
                        <NoticeBlockTitle><Trans>Вы не подключены к уроку</Trans></NoticeBlockTitle>
                        <NoticeBlockText>
                            <Trans>Подключитесь к уроку, чтобы смотреть результаты ученика в реальном времени</Trans>
                        </NoticeBlockText>
                    </>
                </NoticeBlock>
            }

            return <NoticeBlock>
                <>
                    <NoticeBlockText>
                        <Trans>Урок не запущен, ученик не может повлиять на результаты</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        }

        if (!this.state.studentIsOnline) {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Ученик сейчас не на уроке</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Ученик сейчас не видит этот слайд</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        }

        if (!this.state.studentIsOnSlide) {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Ученик сейчас на другом слайде</Trans>
                    </NoticeBlockTitle>
                    <br/>
                    <MoveStudentToSlideBtn btnStyle={BtnStyleEnum.Primary}
                                           loading={this.state.moveStudentToSlideSignalSending}
                                           onClick={() => this.modeStudentToCurrentSlide()}>
                        <Trans>Переключить ученика на этот слайд</Trans>
                    </MoveStudentToSlideBtn>
                </>
            </NoticeBlock>
        }
    }

    protected slideItemsParams = () => {
        const result: SlideItemsParamsBySlide = {};

        if (!this.state.slideContent) {
            return result;
        }

        this.state.slideContent.items.forEach((slideContentItem) => {
            result[slideContentItem.id] = slideContentItem.params;
        });

        return result;
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <Wrapper>
                    <DefaultLoader/>
                </Wrapper>
            }
            case LoadingState.ERROR: {
                return <Wrapper>
                    <ErrorLoadingContent retryBtnClick={this.fetchSlideContent}/>
                </Wrapper>
            }
            case LoadingState.SUCCESS: {
                if (this.state.slideContent === null) {
                    return <NoticeBlock>
                        <>
                            <NoticeBlockTitle><Trans>Слайд пуст</Trans></NoticeBlockTitle>
                        </>
                    </NoticeBlock>
                }

                return <Wrapper>
                    <PlayerContextProvider value={{
                        lessonId: "none",
                        playerId: "slide-preview",
                        slides: null,
                        slidesContent: {},
                        setSlidesContent: () => {
                        },
                        selectedSlideId: this.props.data.tmSlideId,
                        setSelectedSlideId: () => {
                        },
                        selectedSlide: null,
                        selectedSlideIndex: null,
                        setInteractivityConfig: null
                    }}>
                        <SlidePlayer playerId={this.props.data.resultsPlayerId}
                                     slideId={this.props.data.tmSlideId}
                                     slideContent={this.state.slideContent}
                                     readOnly={true}
                                     showCorrectAnswers={false}
                                     slideItemsParams={this.slideItemsParams()}
                        />
                    </PlayerContextProvider>
                </Wrapper>;
            }
            default: {
                throw new Error('Unknown loading state in select tmSlide preview modal');
            }
        }
    }

    render() {
        if (!this.context.lessonRoomId) {
            return;
        }

        return <Wrapper>
            <NavigationPanel>
                <NavigationPanelBackItem onClick={this.props.closeStudentSlideView}>
                    <ArrowBackIcon/>
                </NavigationPanelBackItem>
                <NavigationPanelItem onClick={() => this.openStudentResultsBySlidesModal()} className="slide-name">
                    <SlideName number={this.props.data.slideNumber}
                               name={this.props.data.slideName}
                               showDotsIcon={false}
                    />
                </NavigationPanelItem>
                <NavigationPanelItem onClick={() => this.openStudentResultsBySlidesModal()}>
                    <StudentItem lessonRoomId={this.context.lessonRoomId}
                                 tmSlideId={this.props.data.tmSlideId}
                                 studentUserId={this.props.data.studentId}
                                 studentName={this.props.data.studentName}
                                 studentAvatarFileId={this.props.data.studentAvatarFileId}
                                 slideName={this.props.data.slideName}
                                 slideNumber={this.props.data.slideNumber}
                                 tmSlideVersionNum={0}
                                 dontUseStudentMiddleName={this.state.useShortStudentName}
                                 dontUseOpacityForResultLine={true}
                                 openStudentSlideViewProc={undefined}/>
                </NavigationPanelItem>
            </NavigationPanel>
            {this.warnings()}
            {this.content()}
            <StudentLessonWorkSlideDataModal ref={this.modalRef}
                                             openStudentSlideViewProc={this.props.openStudentSlideViewProc}/>
        </Wrapper>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadSlideWorkData: (params: LoadSlideWorkDataActionParams) => {
        dispatch(loadSlideWorkData(params))
    },
});

const mapStateToProps = (state: ApplicationState) => ({
    // userId: (user.profileData?.id) ?? null,
    // stToken: user.stToken,
    // apiToken: user.sessionToken
    roomMembers: roomMembersSelector(state),
    apiToken: state.user.sessionToken,
    currentTheme: state.layout.activeTheme,
    wsStatus: state.app.wsConnectionStatus
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StudentSlideView);
