import styled from "styled-components";
import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react";
import {EditorDataItem, EditorItemInteractivityConfig} from "../../SlidePlayerEditorCommonParts/EditorData";
import {ElementControlPanel} from "./ElementControlPanel";
import classNames from "classnames";
import {ElementNamePanel} from "./ElementNamePanel";
import {ElementClass, ElementClassRefMethods} from "../elements/ElementClass";
import {ButtonState} from "../components/Toolbar";
import {ToolbarButtonEnum} from "../components/Toolbar/ToolbarButtonEnum";
import {ElementWrapperContextProvider, IElementWrapperContext} from "./ElementWrapperContext";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {ReactComponent as EyeClosedSvg} from "../../Ui/Svg/EyeClosed.svg";
import {t} from "@lingui/macro";
import {ElementInteractivityConfigModal} from "../element-interactivity-config-modal";
import {PopupActions} from "reactjs-popup/dist/types";
import {SlideItemInteractivityActionsRunModeEnum} from "../../../../enums/SlideItemInteractivityActionsRunModeEnum";

/**
 * Обёртка элемента в редакторе слайдов
 */

const ElementControlsWrapperStyled = styled.div`
    border-width: 1px;
    padding: 3px;
    margin: 3px;
    border-color: ${({theme}) => theme.colors.headerDivider};
    border-style: dashed;
    position: relative;
    transition: border-color 0.3s ease;

    &.focused {
        border-style: solid;
        border-color: ${({theme}) => theme.colors.textTertiary};
    }

    &.controls-always-visible {
        padding-bottom: 45px;
    }
`;

const ItemHiddenIndicator = styled.div`
    position: absolute;
    right: -30px;
    top: 0;
    width: 30px;
    height: 30px;
`;

const EyeClosedSvgStyled = styled(EyeClosedSvg)`
    width: 30px;
    height: 30px;
`;

interface ElementWrapperProps {
    tabIndex: number;
    slideElementData: EditorDataItem<any>;
    copyElement: () => void;
    moveToTop: () => void;
    moveToBottom: () => void;
    deleteItem: () => void;
    onFocused: () => void;
    contentOnChange: (content: any) => void;
    setToolbarConfigById: (elementId: string, buttonState: ButtonState[]) => void;
    interactivityConfigOnChange: (visibility: boolean, slideItemAlias: string | null, interactivityConfig: EditorItemInteractivityConfig | null) => void;
}

interface ElementWrapperRefMethods {
    toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => void;
    getExercisesCount: () => number;
}

export const ElementWrapper = forwardRef<ElementWrapperRefMethods, ElementWrapperProps>((props, ref) => {
    const [elementInFocus, setElementInFocus] = useState<boolean>(false);

    const {slideElementData, onFocused, setToolbarConfigById, contentOnChange} = props;

    const elementClassRef = useRef<ElementClassRefMethods>(null);

    const interactivityConfigModalRef = useRef<PopupActions>(null);

    const onItemDataChange = useCallback((newValue: string | Object) => {
        contentOnChange(newValue);
    }, [contentOnChange]);

    const controlsAlwaysVisible = useMemo<boolean>(() => {
        return slideElementData.type === ElementTypeEnum.MEDIA_YOUTUBE;
    }, [slideElementData]);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
            elementClassRef.current?.toolbarItemOnToggle(buttonType, newValue);
        },
        getExercisesCount: () => {
            if (!elementClassRef.current) {
                return 0;
            }

            return elementClassRef.current.getExercisesCount();
        }
    }));

    const onFocus = useCallback(() => {
        onFocused();
        setElementInFocus(true);
    }, [onFocused, setElementInFocus]);

    const onBlur = useCallback(() => {
        setElementInFocus(false);
    }, [setElementInFocus]);

    const elementWrapperProviderData = useMemo<IElementWrapperContext>(() => {
        return {
            elementInFocus: elementInFocus
        }
    }, [elementInFocus]);

    const interactivityConfigInitialValue = useMemo<EditorItemInteractivityConfig>(() => {
        if (slideElementData.interactivityConfig) {
            return slideElementData.interactivityConfig;
        }

        return {
            actionRunMode: SlideItemInteractivityActionsRunModeEnum.IMMEDIATELY,
            nextBtnLabel: null,
            actions: [],
        }
    }, [slideElementData.interactivityConfig]);

    return <div tabIndex={props.tabIndex} onFocus={onFocus} onBlur={onBlur}>
        <ElementControlsWrapperStyled
            className={classNames(
                elementInFocus && "focused",
                controlsAlwaysVisible && "controls-always-visible"
            )}>
            {
                (!props.slideElementData.params.visible)
                && <ItemHiddenIndicator
                    title={t`Элемент по умолчанию скрыт`}
                ><EyeClosedSvgStyled/></ItemHiddenIndicator>
            }
            {
                (elementInFocus || controlsAlwaysVisible)
                && <ElementNamePanel
                    className={classNames((props.tabIndex === 0) && "controlsBottom")}
                    alwaysVisible={controlsAlwaysVisible}
                    elementType={slideElementData.type}/>
            }
            {
                (elementInFocus || controlsAlwaysVisible)
                && <ElementControlPanel
                    className={classNames((props.tabIndex === 0) && "controlsBottom")}
                    copyElement={props.copyElement}
                    alwaysVisible={controlsAlwaysVisible}
                    moveUp={props.moveToTop}
                    moveDown={props.moveToBottom}
                    editInteractivityConfig={() => interactivityConfigModalRef.current?.open()}
                    deleteItem={props.deleteItem}
                    elementType={props.slideElementData.type}
                    elementParams={props.slideElementData.params}
                />}
            <ElementWrapperContextProvider value={elementWrapperProviderData}>
                <ElementClass element={slideElementData}
                              ref={elementClassRef}
                              onItemDataChange={onItemDataChange}
                              setToolbarConfigById={setToolbarConfigById}/>
            </ElementWrapperContextProvider>
            <ElementInteractivityConfigModal
                ref={interactivityConfigModalRef}
                elementType={slideElementData.type}
                visibility={slideElementData.params.visible}
                slideItemAlias={slideElementData.alias}
                interactivityConfig={interactivityConfigInitialValue}
                onSave={props.interactivityConfigOnChange}
            />
        </ElementControlsWrapperStyled>
    </div>;
})