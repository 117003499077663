import React, {useMemo} from "react";
import {t} from "@lingui/macro";

interface CityNameProps {
    engName: string;
}

export const CityName: React.FC<CityNameProps> = ({engName}) => {
    const translated = useMemo(() => {
        const list: { [id: string]: string } = {
            Yerevan: t`Ереван`,
            Kabul: t`Кабул`,
            Baku: t`Баку`,
            Bahrain: t`Бахрейн`,
            Shanghai: t`Шанхай`,
            Tbilisi: t`Тбилиси`,

            Aqtau: t`Актау`,
            Aqtobe: t`Актобе`,
            Atyrau: t`Атырау`,
            Oral: t`Уральск`,
            Qostanay: t`Костанай`,


            Anadyr: t`Анадырь`,
            Barnaul: t`Барнаул`,
            Chita: t`Чита`,
            Irkutsk: t`Иркутск`,
            Kamchatka: t`Камчатка`,
            Khandyga: t`Хандыга`,
            Krasnoyarsk: t`Красноярск`,
            Magadan: t`Магадан`,
            Novokuznetsk: t`Новокузнецк`,
            Novosibirsk: t`Новосибирск`,
            Omsk: t`Омск`,
            Sakhalin: t`Сахалин`,
            Srednekolymsk: t`Среднеколымск`,
            Tomsk: t`Томск`,
            Ust: t`Усть-Каменогорск`,
            Vladivostok: t`Владивосток`,
            Yakutsk: t`Якутск`,
            Yekaterinburg: t`Екатеринбург`,


            Astrakhan: t`Астрахань`,
            Kaliningrad: t`Калининград`,
            Kirov: t`Киров`,
            Moscow: t`Москва`,
            Samara: t`Самара`,
            Saratov: t`Саратов`,
            Ulyanovsk: t`Ульяновск`,
            Volgograd: t`Волгоград`,

            Tashkent: t`Ташкент`
        }

        if (list[engName] === undefined) {
            return engName
        }

        return list[engName];
    }, [engName]);

    return <>{translated}</>
}