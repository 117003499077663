import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import styled from "styled-components";
import {ExerciseProgressBar} from "../../../ExerciseProgressBar";
import {Droppable, DroppableStyled} from "./Droppable";
import {ExerciseDragDropContext, IExerciseDragDropContext, InternalExerciseData} from "./ExerciseDragDropContext";
import {WordStyled} from "./Draggable";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {InputHistoryTip} from "../Common/InputHistoryTip";

interface ExerciseProps {
    // Список элементов для выбора. Первый из нах - верный.
    values: string[];
    exerciseId: string;
}

const DroppableWrapper = styled.div`
    flex-grow: 1;
`;

const EnterIconWrapper = styled.div`
    min-width: 25px;
    opacity: 0.3;
    align-items: center;
    cursor: pointer;

    transition: opacity 0.3s ease;
    padding-left: 5px;
`;

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    transform: translateY(-2px);
    line-height: 1.7em;
    font-size: 1em;
    vertical-align: middle;

    &.correct {
        //font-size: 1em;
        //background: none;
        //border: none;
        //pointer-events: none;
        //cursor: default;
    }

    &.active {
        z-index: ${({theme}) => theme.zIndices.pageContent};
    }

    &:hover ${EnterIconWrapper} {
        opacity: 0.7;
    }
`;

const ProgressbarWrapper = styled.div`
    margin: 0 4px;
    width: 6px;
    height: 27px;
`;

export enum ShowMode {
    INPUT,
    ERROR,
    CORRECT
}

export const Exercise: React.FC<ExerciseProps> = (props) => {
    const {exerciseId} = props;

    const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);
    const dragDropContext = useContext<IExerciseDragDropContext>(ExerciseDragDropContext);

    const {
        dragging,
        allWords,
        showErrorAnimationInExerciseId,
        setShowErrorAnimationInExerciseId
    } = dragDropContext;

    const [nowShowErrorAnimation, setNowShowErrorAnimation] = useState<boolean>(false);

    useEffect(() => {
        if (showErrorAnimationInExerciseId === exerciseId) {
            setNowShowErrorAnimation(true);

            setShowErrorAnimationInExerciseId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showErrorAnimationInExerciseId]);

    const [correctVariant,] = useState<string>(() => props.values[0]);

    const selectedWord = useMemo<InternalExerciseData | null>(() => {
        return (allWords.find((item) => item.parent === exerciseId)) ?? null;
    }, [allWords, exerciseId]);

    const currentExerciseHistoryData = useMemo<ExerciseWorkData | null>(() => {
        const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

        const exerciseIndex = slideItemWorkData.exercisesIndexById[exerciseId];

        if (exerciseIndex === undefined) {
            return null;
        }

        return slideItemWorkData.exercises[exerciseIndex];
    }, [exerciseId, slideItemWorkContext]);

    const showMode = useMemo<ShowMode>(() => {
        if ((currentExerciseHistoryData) && (currentExerciseHistoryData.value === correctVariant)) {
            return ShowMode.CORRECT;
        }

        if (nowShowErrorAnimation) {
            return ShowMode.ERROR;
        }

        return ShowMode.INPUT;
    }, [correctVariant, currentExerciseHistoryData, nowShowErrorAnimation]);

    const onErrorAnimationEnd = useCallback(() => {
        setNowShowErrorAnimation(false);
    }, []);

    // Рендер результата
    if (slideItemWorkContext.showCorrectAnswers === true) {
        // Если нужно показать сразу верный вариант
        return <Wrapper
            data-exercise-id={exerciseId}>
            <DroppableWrapper>
                <DroppableStyled className={"correct dropped"}>
                    <WordStyled className={"correct"}>{correctVariant}</WordStyled>
                </DroppableStyled>
            </DroppableWrapper>
        </Wrapper>;
    }

    return <Wrapper
        data-exercise-id={exerciseId}>
        <InputHistoryTip
            inputHistory={currentExerciseHistoryData?.inputHistory}
            lastIsCorrect={showMode === ShowMode.CORRECT}
        >
            <DroppableWrapper>
                <Droppable dragging={dragging}
                           id={exerciseId}
                           selectedWord={selectedWord}
                           showMode={showMode}
                           onAnimationEnd={onErrorAnimationEnd}
                />
            </DroppableWrapper>
        </InputHistoryTip>
        <ProgressbarWrapper>
            <ExerciseProgressBar wrongValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.missedAward : 0}
                                 rightValue={(currentExerciseHistoryData) ? currentExerciseHistoryData.award : 0}/>
        </ProgressbarWrapper>
    </Wrapper>;
}