import * as React from "react";
import styled from "styled-components";
import {ReactComponent as PlusSvg} from "../../../../../../components/Ui/Svg/Plus.svg";
import {Trans} from "@lingui/macro";
import {ModalResultType, TmSlideModal} from "./modal";
import {ItemStyled} from "../../../../../../components/Sortable/components/Item/Item";
import {DtoTmSlide} from "../../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";

export const AddSlideItem = styled(ItemStyled)`
  border-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;
  padding: 0 0 0 5px;
  font-size: 14px;
  border-radius: 0;
  opacity: 0.5;

  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &.narrow {
    padding: 0;
    justify-content: center;
  }
`;

const PlusIcon = styled(PlusSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 40px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  //flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CreateNewSlideItemProps {
    lessonId: string;
    onItemCreated: (data: DtoTmSlide) => void
}

export const CreateNewSlideItem: React.FC<CreateNewSlideItemProps> = (props) => {

    const onResult = (action: ModalResultType, data: DtoTmSlide | null) => {
        if (data !== null) {
            props.onItemCreated(data);
        }
    }

    return (
        <TmSlideModal
            lessonId={props.lessonId}
            slideItem={null}
            result={onResult}
            trigger={
                <AddSlideItem>
                    <PlusText><Trans>Добавить слайд</Trans></PlusText>
                    <PlusIcon/>
                </AddSlideItem>
            }/>
    );
}