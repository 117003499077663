import React, {forwardRef, useMemo} from "react";
import {Input} from "../../TextInput";
import {t} from "@lingui/macro";
import {TmSectionSelectModalWindow} from "./TmSectionSelectModalWindow";
import {InputRef} from "antd/lib/input/Input";
import {DtoTmSection} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";

interface TmSectionSelectorProps {
    value?: DtoTmSection;
    onChange?: (value: DtoTmSection) => void;
}

export const TmSectionSelector = forwardRef<InputRef, TmSectionSelectorProps>(
    (props, ref) => {
        const currentValue = useMemo<string>(() => {
            if (!props.value) {
                return '';
            }

            return props.value.name;
        }, [props.value]);

        return <TmSectionSelectModalWindow
            trigger={
                <Input ref={ref} style={{cursor: "pointer"}} placeholder={t`Нажмите, чтобы выбрать`}
                       value={currentValue}
                       readOnly/>}
            onChange={(newValue) => {
                if (props.onChange !== undefined) {
                    props.onChange(newValue);
                }
            }}/>
    }
)
