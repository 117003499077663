import React, {CSSProperties, useEffect, useMemo, useState} from "react";
import {PageLoadingStateEnum} from "../../../../enums/PageLoadingStateEnum";
import styled from "styled-components";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../store/fileFormatsRepo/selector";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../store/fileFormatsRepo/type";
import {UserFileFormatsEnum} from "../../../../enums/UserFileEnums";
import * as FileFormatsRepoActionCreators from "../../../../store/fileFormatsRepo/actions";

const Wrapper = styled.div`
    overflow: hidden;
`;

const ImageObject = styled.img`
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.ready {
        opacity: 1;
    }
`;

interface UserImageProps {
    fileId: string;
    fileFormat: UserFileFormatsEnum;
    width?: string;
    height?: string;
    style?: CSSProperties;
    className?: string;
    onStateUpdate?: (newState: PageLoadingStateEnum) => void;
}


export const UserImage: React.FC<UserImageProps> = (
    {
        fileId,
        fileFormat,
        width,
        height,
        style,
        className
    }
) => {
    const dispatch = useDispatch();

    const fileRepoState = useSelector(repoStateSelector);

    const [componentState, setComponentState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [imageFileDownloaded, setImageFileDownloaded] = useState<boolean>(false);

    const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
        if (fileId === null) {
            return null;
        }

        const itemIndex = fileRepoState.indexByFileId[fileId];

        if (itemIndex === undefined) {
            return null;
        }

        return fileRepoState.files[itemIndex] ?? null;
    }, [fileRepoState, fileId]);

    const fileLink = useMemo<string | null>(() => {
        if (currentFileInFileRepo === null) {
            return null;
        }

        if (currentFileInFileRepo.availableFormats.length === 0) {
            return null;
        }

        const file = currentFileInFileRepo.availableFormats.find((item) => {
            return item.formatType === fileFormat;
        }) ?? null;

        return (file) ? file.url : null;
    }, [currentFileInFileRepo, fileFormat]);

    useEffect(() => {
        const fetchNewFileInfo = (fileId: string) =>
            dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

        if (fileId === null) {
            setComponentState(PageLoadingStateEnum.ERROR);

            return;
        }

        if (currentFileInFileRepo === null) {
            // Запросим ссылку
            fetchNewFileInfo(fileId);
            setComponentState(PageLoadingStateEnum.LOADING);

            return;
        }

        switch (currentFileInFileRepo.fetchInfoStatus) {
            case FileFetchInfoProcessStatus.WAIT_FOR_START:
            case FileFetchInfoProcessStatus.IN_PROCESS:
            case FileFetchInfoProcessStatus.SUCCESS: {
                break;
            }
            default: {
                setComponentState(PageLoadingStateEnum.ERROR);

                break;
            }
        }
    }, [currentFileInFileRepo, fileId, dispatch]);

    return <Wrapper className={className}
                    style={{width: width, height: height, ...style}}
    >
        {
            (fileLink) && (componentState !== PageLoadingStateEnum.ERROR)
            && <ImageObject
                src={fileLink}
                className={classNames(imageFileDownloaded && 'ready')}
                onLoad={() => setImageFileDownloaded(true)}
                onError={() => setComponentState(PageLoadingStateEnum.ERROR)}
            />
        }
    </Wrapper>
}

