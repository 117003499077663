import {IsNotEmpty, IsNumber, IsString, Length} from "class-validator";
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO базовой информации о группе
 */
export class DtoGroupBase {
    @IsNotEmpty()
    @IsNumber()
    id: number;

    @IsNotEmpty()
    @IsNumber()
    teacherId: number;

    @IsNotEmpty()
    @IsNumber()
    trainingProgramId: number;

    @IsNotEmpty()
    @IsNumber()
    eventPlaceId: number;

    @IsNotEmpty()
    @IsString()
    name: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_DATE_FORMAT_LENGTH, LengthEnum.DEFAULT_DATE_FORMAT_LENGTH)
    dateStartWork: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.DEFAULT_DATE_FORMAT_LENGTH, LengthEnum.DEFAULT_DATE_FORMAT_LENGTH)
    dateEndWork: string;
}