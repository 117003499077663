import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {sendQueue, setCycleEnabledValue} from "../../store/slidesWorkDataSaveQueue/actions";
import {QueueSendCycleStateEnum, SlidesWorkDataSaveQueueActionTypes} from "../../store/slidesWorkDataSaveQueue/type";
import {handleCycleEnabledSelector, handleCycleStatusSelector} from "../../store/slidesWorkDataSaveQueue/selector";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";

export function* watchStartHandleCycle() {
    yield takeEvery(
        SlidesWorkDataSaveQueueActionTypes.START_HANDLE_CYCLE,
        startHandleCycle
    );
}

function* startHandleCycle() {
    const handleCycleStatus = (yield select(handleCycleStatusSelector)) as QueueSendCycleStateEnum;
    const handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

    if (!handleCycleEnabled || handleCycleStatus === QueueSendCycleStateEnum.STOPPED) {
        if (!handleCycleEnabled) {
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);
            logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue enabled');

            yield putResolve(setCycleEnabledValue(true));
        }

        yield put(sendQueue());
    }
}
