import React, {useState} from "react";
import {t, Trans} from "@lingui/macro";
import {SettingsSectionsEnum} from "./SettingsSectionsEnum";
import {PageWithNavigation} from "../../../components/PageWithNavigation";
import {ReactComponent as ProfileSvg} from "../../../components/Ui/Svg/BottomNavigationIcons/Profile25.svg";
import {
    ReactComponent as SecuritySettingsSvg
} from "../../../components/Ui/Svg/BottomNavigationIcons/SecuritySettings25.svg";
import GeneralSection from "./sections/general/GeneralSection";
import {SecuritySection} from "./sections/security/SecuritySection";

export const ProfileSettingsPage: React.FC = () => {
    const [activeSection, setActiveSection] = useState<SettingsSectionsEnum | string>(SettingsSectionsEnum.GENERAL);

    return <PageWithNavigation
        title={t`Настройки профиля`}
        activeSection={activeSection}
        onSelectSection={(sectionName) => setActiveSection(sectionName)}
        items={[
            {
                key: SettingsSectionsEnum.GENERAL,
                name: <Trans>Основные</Trans>,
                icon: <ProfileSvg/>,
                body: <GeneralSection/>
            },
            {
                key: SettingsSectionsEnum.SECURITY,
                name: <Trans>Безопасность</Trans>,
                icon: <SecuritySettingsSvg/>,
                body: <SecuritySection/>
            }
        ]}
    />
}
