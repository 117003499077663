import {action} from "typesafe-actions";

import {LessonRoomsActionTypes, OpenedLessonRoom} from "./type";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {DtoSELessonOpened} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSELessonOpened";
import {StreamEventStoreItem} from "../streamEvent/type";
import {DtoBaseWithLessonRoomId} from "../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoBaseWithLessonRoomId";
import {DtoLessonClosed} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoLessonClosed";

export const handleGetOpenedRoomsResponse = (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoSELessonOpened>, null>) => {
    return action(LessonRoomsActionTypes.HANDLE_GET_OPENED_ROOMS_RESPONSE, data);
}

export const handleSeLessonRoomOpened = (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoSELessonOpened>, null>) => {
    return action(LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_OPENED, data);
}

export const handleSeLessonRoomKicked = (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoBaseWithLessonRoomId>, null>) => {
    return action(LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_KICKED, data);
}

export const handleSeLessonRoomClosed = (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoLessonClosed>, null>) => {
    return action(LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_CLOSED, data);
}

export const addOpenedLessonRooms = (roomsList: OpenedLessonRoom[]) => {
    return action(LessonRoomsActionTypes.ADD_OPENED_LESSON_ROOMS, roomsList);
}

export const removeOpenedLessonRoom = (roomId: string) => {
    return action(LessonRoomsActionTypes.REMOVE_OPENED_LESSON_ROOM, roomId);
}