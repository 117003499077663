import {UserFileTypeEnum} from "../../enums/UserFileEnums";

export enum UploadQueueActionTypes {
    UPLOAD_NEW_FILE = '@@uploadQueue/UPLOAD_NEW_FILE',
    RESTART_FILE_UPLOADING = '@@uploadQueue/RESTART_FILE_UPLOADING',

    SET_NEW_UPLOAD_PROCESS = '@@uploadQueue/SET_NEW_UPLOAD_PROCESS',
    SET_PROCESS_STATE = '@@uploadQueue/SET_PROCESS_STATE',
    SET_CANCEL_UPLOAD_FILE = '@@uploadQueue/SET_CANCEL_UPLOAD_FILE',
    REMOVE_FROM_QUEUE = '@@uploadQueue/REMOVE_FROM_QUEUE'
}

export type IndexByFileIdType = { [fileId: string]: number };

export enum FileUploadProcessState {
    WAIT_FOR_START,
    IN_PROCESS,
    SUCCESS,
    CANCELLED,
    FAILED
}

export interface UploadProcessDetails {
    state: FileUploadProcessState;
    fileType: UserFileTypeEnum;
    fileId: string;
    url: string;
    fileSize: number;
    file: File | Blob;
    completedInPercent: number;
    needCancel: boolean;
}

export interface UploadQueueState {
    process: UploadProcessDetails[];
    indexByFileId: IndexByFileIdType
}