import {
    IsNotEmpty, IsString, Length, MaxLength, IsOptional, IsNumber, Max
} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO уровня дисциплины обучающих материалов
 */
export class DtoTmSection {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, 255)
    name: string;

    @IsOptional()
    @IsString()
    @MaxLength(1000)
    description: string | null;

    @IsNotEmpty()
    @IsNumber()
    @Max(32767)
    orderPosition: number;
}
