import {instanceCachingFactory} from 'tsyringe';
import {ILogger} from "../components/Logger/ILogger";
import {DiTokens} from "./DiTokens";
import {IPagesBroadcastService} from "../services/pages-broadcast-service/IPagesBroadcastService";
import {PagesBroadcastService} from "../services/pages-broadcast-service/PagesBroadcastService";
import {IStoreService} from "../services/store/IStoreService";

export default instanceCachingFactory<IPagesBroadcastService>((container) => {
    return new PagesBroadcastService(
        container.resolve<IStoreService>(DiTokens.STORE_SERVICE),
        container.resolve<ILogger>(DiTokens.LOGGER)
    );
});
