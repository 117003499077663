import React, {useCallback, useContext, useMemo, useRef} from "react";
import {ILessonPageContext, LessonPageContext} from "../../../LessonPageContext";
import styled from "styled-components";
import {StudentItem} from "../Common/StudentItem";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../../components/Ui/Elements/NoticeBlock";
import {t, Trans} from "@lingui/macro";
import {Popup, PopupItem} from "../../../../../../components/Ui/Elements/Popup";
import {ApiMethodEnum} from "../../../../../../../components/WsApiClient/ApiMethodEnum";
import {container} from "tsyringe";
import {IWsApiClient} from "../../../../../../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {MessageDialog} from "../../../../../../components/Ui/Elements/MessageDialog";
import {PopupActions} from "reactjs-popup/dist/types";
import {StudentSlideViewDetailsType} from "../Common/StudentSlideViewDetailsType";
import {SlideName} from "../Common/SlideName";

const ListWrapper = styled.ul`
    list-style: none;
    padding: 0;
`;

const ItemWrapper = styled.li`
    
`;

const SlideNameStyled = styled(SlideName)`
    margin-bottom: 15px;
`;

const StudentItemsWrapper = styled.div`
    margin-bottom: 20px;
    padding-left: 20px;
`;

const StudentItemStyled = styled(StudentItem)`
    margin-bottom: 15px;
`;

interface SlidesListProps {
    lessonId: string;
    openStudentSlideViewProc: (data: StudentSlideViewDetailsType) => void;
}

export const SlidesList: React.FC<SlidesListProps> = (props) => {
    const {
        lessonMaterialsList,
        students,
        lessonRoomId,
        onlineRoomIsOpened,
        onlineRoomIsConnected
    } = useContext<ILessonPageContext>(LessonPageContext);

    const offlineModalRef = useRef<PopupActions>(null);

    const popupItems = useCallback((slideId: string) => {
        const items: PopupItem[] = [];

        items.push({
            text: t`Переключить учеников на этот слайд`,
            onClick: () => {
                if (!onlineRoomIsConnected) {
                    offlineModalRef.current?.open();

                    return;
                }

                const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

                wsClient.query(
                    ApiMethodEnum.LESSON_ROOM_MOVE_STUDENTS_TO_SLIDE,
                    {
                        lessonId: props.lessonId,
                        tmSlideId: slideId
                    },
                    undefined,
                    true
                ).then();
            }
        });

        return items;
    }, [onlineRoomIsConnected, props.lessonId]);

    const roomNotConnectedDialog = useMemo<JSX.Element | null>(() => {
        if (!onlineRoomIsOpened) {
            return <MessageDialog
                ref={offlineModalRef}
                title={t`Урок не начался`}
                text={t`Начните урок, чтобы ученики могли подключиться к занятию.`}
            />
        }

        if (!onlineRoomIsConnected) {
            return <MessageDialog
                ref={offlineModalRef}
                title={t`Вы не подключены к уроку`}
                text={t`Подключитесь к уроку, чтобы иметь возможность переключать слайды учеников.`}
            />
        }

        return null;
    }, [onlineRoomIsConnected, onlineRoomIsOpened]);

    const filteredMaterials = useMemo(() => {
        return lessonMaterialsList.filter(item => !item.hiddenForStudent);
    }, [lessonMaterialsList]);

    if (lessonRoomId === null) {
        return <div/>;
    }

    if (lessonMaterialsList.length === 0) {
        return <div>
            <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Результатов ещё нет</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Результаты появятся когда вы добавите слайды с упражнениями</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        </div>
    } else if (filteredMaterials.length === 0) {
        return <div>
            <NoticeBlock>
                <>
                    <NoticeBlockTitle>
                        <Trans>Результатов ещё нет</Trans>
                    </NoticeBlockTitle>
                    <NoticeBlockText>
                        <Trans>Результаты появятся когда вы добавите слайды с упражнениями или
                            покажете ученикам скрытые, но уже добавленные к уроку слайды</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>
        </div>
    }

    return <ListWrapper>
        {
            filteredMaterials.map((item, index) => {
                return <ItemWrapper key={item.slideTmId}>
                    <Popup items={popupItems(item.slideTmId)} trigger={
                        <SlideNameStyled number={index + 1} name={item.slideTmName} showDotsIcon={true}/>
                    } position={"bottom left"}/>
                    <StudentItemsWrapper>
                        {
                            students.map(studentItem => {
                                return <StudentItemStyled key={studentItem.stId}
                                                          lessonRoomId={lessonRoomId}
                                                          tmSlideId={item.slideTmId}
                                                          studentUserId={studentItem.myLessonsUserId}
                                                          studentName={studentItem.name}
                                                          studentAvatarFileId={studentItem.avatarFileId}
                                                          slideName={item.slideTmName}
                                                          slideNumber={index + 1}
                                                          tmSlideVersionNum={item.slideContentVersion}
                                                          openStudentSlideViewProc={props.openStudentSlideViewProc}
                                                          limitAwardsWrapperWidth={true}
                                />
                            })
                        }
                    </StudentItemsWrapper>
                </ItemWrapper>
            })
        }
        {roomNotConnectedDialog}
    </ListWrapper>;
}
