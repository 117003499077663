import * as React from "react";
import {SelectedItemTitle} from "../index";
import {ModalResultType, TmLessonModal} from "./modal";
import {DtoTmLesson} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";

interface LessonTitleItemProps {
    sectionId: string;
    item: DtoTmLesson;
    lessons: DtoTmLesson[] | null;
    setLessons: (value: DtoTmLesson[] | null) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const LessonTitleItem: React.FC<LessonTitleItemProps> = (props) => {

    const updateItemInLessonsPropsList = (newModel: DtoTmLesson) => {
        if (props.lessons === null || props.setLessons === null) {
            return;
        }

        const newLessonsList = props.lessons.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        props.setLessons(newLessonsList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromLessonPropsList = () => {
        if (props.lessons === null || props.setLessons === null) {
            return;
        }

        const newSectionsList = props.lessons.filter((item) => (
            (props.item.id !== item.id)
        ));

        props.setLessons(newSectionsList);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmLesson | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after lesson updated.');
            }

            updateItemInLessonsPropsList(data);

            return;
        }

        deleteCurrentItemFromLessonPropsList();
    }

    return (
        <TmLessonModal
            sectionId={props.sectionId}
            lessonItem={props.item}
            result={onResult}
            trigger={<SelectedItemTitle>{props.item.name}</SelectedItemTitle>}/>
    );
}