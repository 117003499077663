import {IBaseApiDto} from "../../IBaseApiDto";
import {IsBoolean, IsNotEmpty, IsNumber} from "class-validator";

/**
 * DTO параметров запроса на запуск онлайн урока учителем.
 */
export class DtoTeacherStartOnlineLesson implements IBaseApiDto {
    @IsNotEmpty()
    @IsNumber()
    stGroupId: number;

    @IsNotEmpty()
    @IsNumber()
    stLessonId: number;

    @IsNotEmpty()
    @IsBoolean()
    noVideo: boolean;
}
