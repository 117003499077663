import {IsNotEmpty, IsNumber, IsOptional, Max} from 'class-validator';
import {DtoTmDiscipline} from "../TmDisciplines/DtoTmDiscipline";
import {DtoTmSection} from "./DtoTmSection";
import {DtoTmLevel} from "../TmLevels/DtoTmLevel";
import {DtoTmLesson} from "../TmLesson/DtoTmLesson";

/**
 * DTO ответа на обзорный запрос секции обучающих материалов
 */
export class DtoTmSectionOverview {
    @IsNotEmpty()
    disciplineItem: DtoTmDiscipline;

    @IsNotEmpty()
    levelItem: DtoTmLevel;

    @IsNotEmpty()
    sectionItem: DtoTmSection;

    @IsOptional()
    lessonsList: DtoTmLesson[];

    @IsNotEmpty()
    @IsNumber()
    @Max(32767)
    totalLessonsCount: number;
}
