import styled from "styled-components";

export const ElementWrapper = styled.div`
  padding: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  margin-bottom: 20px;
  table-layout: fixed;
`;

export const HeaderCell = styled.th`
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  width: calc(50% - 20px);

  &.left, &.right {
    border-right: 1px solid ${({theme}) => theme.colors.headerDivider};
  }
`;

export const HeaderControlCell = styled(HeaderCell)`
  width: 40px;
  padding: 0;
`;

export const ItemCell = styled.td`
  padding: 10px;
  width: calc(50% - 20px);
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};

  &.left, &.right {
    border-right: 1px solid ${({theme}) => theme.colors.headerDivider};
  }
`;

export const ControlCell = styled(ItemCell)`
  width: 40px;
  padding: 0;
`;

export const ControlCellButtonsWrapper = styled.div`

`;

export const ControlCellButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const BottomControls = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const BottomControlItem = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({theme}) => theme.colors.accentDivider};
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  padding: 2px 10px 2px 5px;
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const BottomControlItemIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BottomControlItemTextWrapper = styled.div`
`;