import * as React from "react";
import {forwardRef, useCallback, useMemo} from "react";
import {InputRef} from "antd/lib/input/Input";
import styled, {css} from "styled-components";
import classNames from "classnames";
import {ReactComponent as CopySvg} from "../Svg/Copy.svg";
import {NotificationTypesEnum, openNotification} from "./Notification";
import {t} from "@lingui/macro";
import {InputProps} from "antd/lib/input";
import {Input} from "./TextInput";

const Wrapper = styled.div`
  position: relative;
`;

const InputStyled = styled(Input)`
  &.oneBtn {
    padding-right: 40px;
  }
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: row;
`;

const ButtonCss = css`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.5;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const CopyBtnWrapper = styled.div`
  ${ButtonCss};
  cursor: pointer;
`;

interface EmailInputProps extends InputProps {
    showCopyBtn?: boolean;
}

export const EmailInput: React.ForwardRefExoticComponent<EmailInputProps & React.RefAttributes<InputRef>> = forwardRef<InputRef, EmailInputProps>(
    ({showCopyBtn, ...props}, ref) => {
        const buttonsAllowed = useMemo<boolean>(() => {
            return props.value !== '';
        }, [props.value]);

        const additionalClassName = useMemo<string>(() => {
            if (!buttonsAllowed || !showCopyBtn) {
                return '';
            }

            return 'oneBtn';
        }, [buttonsAllowed, showCopyBtn]);

        const showBtnWrapper = useMemo<boolean>(() => {
            return !!(buttonsAllowed && showCopyBtn);
        }, [buttonsAllowed, showCopyBtn]);

        const copyBtnOnClick = useCallback(() => {
            const showErrorMsg = () => {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не получается скопировать. Попробуйте скопировать вручную.`,
                    ''
                );
            }

            if (navigator.clipboard === undefined) {
                showErrorMsg();

                return;
            }

            navigator.clipboard.writeText((props.value ?? '') as string)
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Email скопирован`,
                        ''
                    );

                    return;
                })
                .catch(() => {
                    showErrorMsg();
                });
        }, [props.value]);

        const buttons = useMemo<JSX.Element | null>(() => {
            if (!showBtnWrapper) {
                return null;
            }

            return <ButtonsWrapper>
                {
                    (showCopyBtn) && <CopyBtnWrapper onClick={copyBtnOnClick}>
                        <CopySvg/>
                    </CopyBtnWrapper>
                }
            </ButtonsWrapper>
        }, [copyBtnOnClick, showBtnWrapper, showCopyBtn]);


        return <Wrapper>
            <InputStyled ref={ref} className={classNames(additionalClassName, props.className)}
                               autoComplete="false" {...props}/>
            {buttons}
        </Wrapper>
    })
