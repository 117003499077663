import {Reducer} from "redux";
import {LessonRoomActionTypes, LessonRoomMember, LessonRoomState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";
import {cloneDeep} from "lodash";
import {DeviceOrientatonTypeEnum} from "../../components/DeviceOrientation/DeviceOrientatonTypeEnum";

const reducer: Reducer<LessonRoomState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case LessonRoomActionTypes.SET_LESSON_ROOM_STATE: {
            draft.state = action.payload;

            break;
        }
        case LessonRoomActionTypes.SET_LESSON_ROOM_ID: {
            draft.roomId = action.payload;

            break;
        }
        case LessonRoomActionTypes.SET_ROOM_CONNECTION_PARAMS: {
            draft.roomConnectionParams = action.payload;

            break;
        }
        case LessonRoomActionTypes.SET_ROOM_MEMBERS_LIST_LOADING_STATE: {
            draft.membersListLoadingState = action.payload;

            break;
        }
        case LessonRoomActionTypes.SET_ITEMS_TO_MEMBERS_LIST: {
            const payload = action.payload as LessonRoomMember[];

            let membersList = cloneDeep(draft.members);

            payload.forEach((item) => {
                let itemIndexInList = membersList.findIndex(
                    (existItem) => existItem.userId === item.userId
                );

                if (itemIndexInList > 0) {
                    membersList[itemIndexInList] = item;
                } else {
                    membersList.push(item);
                }
            });

            draft.members = membersList;

            break;
        }
        case LessonRoomActionTypes.SET_MEMBER_SCREEN_ORIENTATION: {
            const payload: { memberId: string, value: string } = action.payload;

            draft.members = draft.members.map((item) => {
                if (item.userId === payload.memberId) {
                    const newItem = cloneDeep(item);

                    newItem.screenOrientation = payload.value as DeviceOrientatonTypeEnum;

                    return newItem;
                }

                return item;
            });

            break;
        }
        case LessonRoomActionTypes.SET_MEMBER_IS_IN_FULL_SCREEN: {
            const payload: { memberId: string, value: any } = action.payload;

            draft.members = draft.members.map((item) => {
                if (item.userId === payload.memberId) {
                    const newItem = cloneDeep(item);

                    newItem.inFullScreen = payload.value;

                    return newItem;
                }

                return item;
            });

            break;
        }
        case LessonRoomActionTypes.SET_MEMBER_PINNED_USER: {
            const payload: { memberId: string, value: any } = action.payload;

            draft.members = draft.members.map((item) => {
                if (item.userId === payload.memberId) {
                    const newItem = cloneDeep(item);

                    newItem.pinnedUser = payload.value;

                    return newItem;
                }

                return item;
            });

            break;
        }
        case LessonRoomActionTypes.SET_MEMBER_OPENED_SLIDE_ID: {
            const payload: { memberId: string, slideId: string | null } = action.payload;

            draft.members = draft.members.map((item) => {
                if ((item.userId === payload.memberId) && (item.detailsForTeacher !== null)) {
                    const newItem = cloneDeep(item);

                    if (newItem.detailsForTeacher !== null) {
                        newItem.detailsForTeacher.openedSlideId = payload.slideId;
                    }

                    return newItem;
                }

                return item;
            });

            break;
        }
        case LessonRoomActionTypes.REMOVE_ITEM_FROM_MEMBERS_LIST: {
            const itemIndex = draft.members.findIndex((item) => item.userId === action.payload);

            if (itemIndex > -1) {
                draft.members = cloneDeep(draft.members);
                draft.members.splice(itemIndex, 1)
            }

            break;
        }
        case LessonRoomActionTypes.CLEAR_MEMBERS_LIST: {
            draft.members = [];

            break;
        }
        case LessonRoomActionTypes.CLEAR_ROOM_STATE: {
            return initialState;
        }
    }
}, initialState);

export {reducer as lessonRoomReducer}
