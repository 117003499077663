import React, {useCallback, useContext, useMemo} from "react";
import {IButtonBaseProps} from "./IButtonBaseProps";
import {
    ControlPanelItem,
    ControlPanelItemIcon,
    ControlPanelItemText
} from "../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as Microphone} from "../../../Ui/Elements/ControlPanel/icons/Microphone.svg";
import {ReactComponent as MicrophoneOff} from "../../../Ui/Elements/ControlPanel/icons/MicrophoneOff.svg";
import {Trans} from "@lingui/macro";
import classNames from "classnames";
import {AudioRoomContext, IAudioRoomContext} from "../../contexts/AudioRoomContext";
import {AudioRoomActionsContext, IAudioRoomActionsContext} from "../../actions/audio-room/AudioRoomActionsContext";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";

export const MyMicBtn: React.FC<IButtonBaseProps> = () => {
    const audioRoomContext = useContext<IAudioRoomContext>(AudioRoomContext);
    const audioRoomActionsContext = useContext<IAudioRoomActionsContext>(AudioRoomActionsContext);
    const commonContext = useContext<ICommonContext>(CommonContext);

    const toggleButton = useCallback(() => {
        if (audioRoomContext.audioRoomConnectionState.myMicMuted) {
            audioRoomActionsContext.unmuteMyAudio()
        } else {
            audioRoomActionsContext.muteMyAudio()
        }
    }, [audioRoomActionsContext, audioRoomContext.audioRoomConnectionState.myMicMuted]);

    const btnDisabled = useMemo<boolean>(
        () =>
            (
                commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED
                || !audioRoomContext.audioRoomConnectionState.connection
                || !audioRoomContext.audioRoomConnectionState.streamIsReady
            ),
        [
            audioRoomContext.audioRoomConnectionState.connection,
            audioRoomContext.audioRoomConnectionState.streamIsReady,
            commonContext.lessonConferenceState
        ]
    );

    return <ControlPanelItem
        onClick={() => toggleButton()}
        className={
            classNames(
                'panel-item',
                btnDisabled && "disabled",
                audioRoomContext.audioRoomConnectionState.myMicMuted && "warning"
            )
        }>
        <ControlPanelItemIcon>{
            (audioRoomContext.audioRoomConnectionState.myMicMuted)
                ? <MicrophoneOff/>
                : <Microphone/>
        }</ControlPanelItemIcon>

        <ControlPanelItemText><Trans>Мой микрофон</Trans></ControlPanelItemText>
    </ControlPanelItem>
}
