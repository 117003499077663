import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import {
    DtoStudentEntranceInfoResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/Students/DtoStudentEntranceInfoResponse";
import {useSelector} from "react-redux";
import {sessionTokenSelector, stTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {PageSectionsEnum as ProfilePagePageSectionsEnum} from "../profile-page/PageSectionsEnum";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {t} from "@lingui/macro";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {CreateAgreementPageContextProvider} from "./CreateAgreementPageContext";
import {PageWrapper} from "../../../../components/PageWrapper";
import {StringHelper} from "../../../../../helpers/StringHelper";
import {NewAgreementTeamSection} from "./team";
import {NewAgreementTeamSectionNotice} from "./team/notice";
import {NewAgreementTrainingProgramSection} from "./training-program";
import {NewAgreementScheduleSection} from "./schedule";
import {NewAgreementScheduleSectionNotice} from "./schedule/notice";
import {NewAgreementCostSection} from "./cost";
import {NewAgreementCostSectionNotice} from "./cost/notice";
import {NewAgreementFinishSection} from "./finish";
import {CreateAgreementRequestData} from "./CreateAgreementRequestData";
import styled from "styled-components";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {StepInitItem, StepWizard} from "../../../../components/StepWizard";
import {NewAgreementInvitationSection} from "./invitation";
import {PageLoadStatus} from "../../knowledge-base/common/Enums";

const RightBlockWrapper = styled.div`
  margin-bottom: 32px;
`;

export const CreateAgreementPage: React.FC = () => {
    const {studentId, stepId} = useParams();

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isMedium = useMediaQuery(`(${currentTheme.media.medium})`);

    const [pageState, setPageState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);

    const [studentGeneralInfo, setStudentGeneralInfo] = useState<DtoStudentGeneralInfo | null>(null);
    const [studentEntranceInfoResponse, setStudentEntranceInfoResponse] = useState<DtoStudentEntranceInfoResponse | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);
    const [formData, setFormData] = useState<CreateAgreementRequestData>(new CreateAgreementRequestData());

    const stToken = useSelector(stTokenSelector);
    const apiToken = useSelector(sessionTokenSelector);

    const fetchBaseInfo = useCallback(() => {
        const studentIdInt = parseInt(studentId ?? '');

        if ((stToken === null) || (apiToken === null) || (!studentIdInt)) {
            setPageState(PageLoadStatus.ERROR);

            return;
        }

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        setPageState(PageLoadStatus.LOADING);

        stApiClient.getStudentProfileGeneralInfo(
            stToken,
            studentIdInt,
            newAbortController
        )
            .then((model) => {
                setStudentGeneralInfo(model.result);

                if (model.result.email) {
                    return apiClient.getStudentEntranceInfo(
                        apiToken,
                        model.result.email
                    );
                }

                return Promise.resolve(null);
            })
            .then((model) => {
                if (model) {
                    setStudentEntranceInfoResponse(model.data);
                } else {
                    setStudentEntranceInfoResponse(null);
                }

                setPageState(PageLoadStatus.SUCCESS);
            })
            .catch((err) => {
                setPageState(PageLoadStatus.ERROR);

                logger.error(LoggerSectionsEnum.STUDENT_PROFILE, err);
            });
    }, [studentId, stToken, apiToken, abortController]);

    const activeSectionId = useMemo<PageSectionsEnum>(() => {
        const allSections = Object.values(PageSectionsEnum) as string[];

        if (allSections.indexOf(stepId ?? '') > -1) {
            return stepId as PageSectionsEnum;
        }

        return PageSectionsEnum.TEAM;
    }, [stepId]);

    const stepsConfig = useMemo<StepInitItem[]>(() => {
        return [
            {
                id: PageSectionsEnum.TEAM,
                component: <NewAgreementTeamSection/>
            },
            {
                id: PageSectionsEnum.TRAINING_PROGRAM,
                component: <NewAgreementTrainingProgramSection/>
            },
            {
                id: PageSectionsEnum.SCHEDULE,
                component: <NewAgreementScheduleSection/>
            },
            {
                id: PageSectionsEnum.COST,
                component: <NewAgreementCostSection/>
            },
            {
                id: PageSectionsEnum.INVITATION,
                component: <NewAgreementInvitationSection/>
            },
            {
                id: PageSectionsEnum.FINISH,
                component: <NewAgreementFinishSection/>
            },
        ]
    }, []);

    const pageLink = useMemo<string>(() => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_STUDENT_NEW_AGREEMENT,
            [
                {
                    key: 'studentId',
                    value: studentId ?? '0'
                }
            ]
        )
    }, [studentId]);

    const activeSectionNotice = useMemo<JSX.Element | null>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.TEAM: {
                return <NewAgreementTeamSectionNotice/>;
            }
            case PageSectionsEnum.TRAINING_PROGRAM: {
                return null; // <NewAgreementCourseSectionNotice/>;
            }
            case PageSectionsEnum.SCHEDULE: {
                return <NewAgreementScheduleSectionNotice/>;
            }
            case PageSectionsEnum.COST: {
                return <NewAgreementCostSectionNotice/>;
            }
            case PageSectionsEnum.INVITATION: {
                return null;
            }
            case PageSectionsEnum.FINISH: {
                return null; //<NewAgreementFinishSectionNotice/>;
            }
            default: {
                return null;
            }
        }
    }, [activeSectionId]);

    const activeSectionTitle = useMemo<string>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.INVITATION: {
                return t`Курс добавлен`;
            }
            case PageSectionsEnum.FINISH: {
                return t`Что дальше`;
            }
            default: {
                return t`Добавление курса`;
            }
        }
    }, [activeSectionId]);

    const shortStudentName = useMemo<string>(() => {
        if (studentGeneralInfo === null) {
            return '';
        }

        return StringHelper.extractFirstName(studentGeneralInfo.longName);
    }, [studentGeneralInfo]);


    useEffect(() => {
        if (pageState === PageLoadStatus.NOT_INIT) {
            fetchBaseInfo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (pageState === PageLoadStatus.NOT_INIT || pageState === PageLoadStatus.LOADING) {
        return <DefaultLoader/>;
    }

    if (
        (pageState === PageLoadStatus.ERROR)
        || (studentGeneralInfo === null)
    ) {
        return <ErrorLoadingContent retryBtnClick={fetchBaseInfo}/>;
    }

    return <CreateAgreementPageContextProvider value={{
        studentGeneralInfoDto: studentGeneralInfo,
        setStudentGeneralInfoDto: (dto) => setStudentGeneralInfo(dto),
        studentEntranceInfoResponse: studentEntranceInfoResponse,
        reloadBaseInfo: fetchBaseInfo,
        formData: formData,
        setFormData: setFormData,
        shortStudentName: shortStudentName,
    }}>
        <PageWrapper
            backLink={{
                title: t`К профилю ученика`,
                href: RoutesHelper.replaceParams(
                    RoutesList.TEACHER_STUDENT_PROFILE,
                    [
                        {
                            key: 'studentId',
                            value: studentId ?? '',
                        },
                        {
                            key: 'sectionId',
                            value: ProfilePagePageSectionsEnum.EDUCATION
                        }
                    ]
                )
            }}
            pageTitle={activeSectionTitle}
            pageContent={
                <StepWizard
                    steps={stepsConfig}
                    pageLink={pageLink}/>
            }
            rightBlockContent={
                (isMedium && activeSectionNotice) ?
                    <RightBlockWrapper>
                        {activeSectionNotice}
                    </RightBlockWrapper>
                    : null
            }
        />
    </CreateAgreementPageContextProvider>
}