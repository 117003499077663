import "plyr-react/plyr.css"
import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import Plyr, {APITypes} from "plyr-react";
import styled from "styled-components";
import {UserFileFormatsEnum} from "../../../../enums/UserFileEnums";
import {t} from "@lingui/macro";
import classNames from "classnames";
import {debounce} from "lodash";

const PLYR_SPEED_MENU_HEIGHT = 350;

export type QualityItem = {
    url: string;
    mimeType: string;
    format: UserFileFormatsEnum;
}

interface AudioPlayerProps {
    qualityItems: QualityItem[];
    height: string;
    settingsEnabled: boolean;
    volumeDisabled?: boolean;
    onPause?: () => void;
    onEnd?: () => void;
    onError?: () => void;
    onReady?: () => void;
}

type PlyrSource = Plyr.Source;

const PlyrWrapper = styled.div`
  --plyr-color-main: ${({theme}) => theme.slideContent.playerAccent};
  --plyr-audio-control-color: ${({theme}) => theme.colors.textPrimary};

  font-family: Arial, serif;
  width: 100%;

  .plyr {
    width: 100%;
  }

  .plyr__controls {
    background: ${({theme}) => theme.colors.backgroundPrimary};
  }

  &.menu-to-bottom {
    .plyr__menu__container {
      bottom: auto;
      top: 100%;
      
      &:after {
        display: none;
      }
    }
  }
`;

export const AudioPlayer = React.memo(
    forwardRef<APITypes, AudioPlayerProps>(
        (props, ref) => {
    const plyrWrapperRef = useRef<HTMLDivElement>(null);
    const [menuToBottom, setMenuToBottom] = useState<boolean>(false);

    const sources = useMemo<PlyrSource[]>(() => {
        // Если варианты есть, выполняем поиск известных
        const knownItems = props.qualityItems.filter((qualityItem) => {
            return [
                UserFileFormatsEnum.AUDIO_MP3_best
            ].includes(qualityItem.format);
        });

        if (knownItems.length === 0) {
            // Нет известных форматов, вываливаем всё, что есть
            // Если вариантов нет, показываем что есть
            return props.qualityItems.map((qualityItem): PlyrSource => {
                return {
                    src: qualityItem.url,
                    provider: "html5",
                    type: qualityItem.mimeType
                }
            });
        }

        return knownItems.map((qualityItem): PlyrSource => {
            return {
                src: qualityItem.url,
                provider: "html5",
                type: qualityItem.mimeType
            }
        });

    }, [props]);

    const settings = useMemo<string[]>(() => {
        return (props.settingsEnabled) ? ['speed'] : [];
    }, [props]);
    
    const controls = useMemo<string[]>(() => {
        const list = [
            'play',
            'progress',
            'current-time',
            'settings',
            'airplay'
        ];
        
        if (!props.volumeDisabled) {
            list.push('volume');
        }

        return list
    }, [props.volumeDisabled]);

    const setMenuToBottomState = useCallback(() => {
        if (plyrWrapperRef.current === null) {
            return;
        }

        const rect = plyrWrapperRef.current.getBoundingClientRect();

        setMenuToBottom(rect.top < PLYR_SPEED_MENU_HEIGHT);
    }, []);

    const setMenuToBottomStateDebounced = debounce(setMenuToBottomState, 500);

    useEffect(() => {
        setMenuToBottomStateDebounced();
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            setMenuToBottomStateDebounced();
        }

        document.addEventListener("scroll", scrollHandler);

        return () => {
            document.removeEventListener("scroll", scrollHandler);
        }
    }, []);

    const localization = useMemo<any>(() => {
        return {
            restart: t`Перезапустить`,
            rewind: t`Перейти к {seektime}сек`,
            play: t`Воспроизвести`,
            pause: t`Пауза`,
            fastForward: t`Перейти к {seektime}сек`,
            seek: t`Перемотка`,
            seekLabel: t`{currentTime} / {duration}`,
            played: t`Проигрывается`,
            buffered: t`Загружено`,
            currentTime: t`Текущая позиция`,
            duration: t`Длительность`,
            volume: t`Громкость`,
            mute: t`Отключить звук`,
            unmute: t`Включить звук`,
            enableCaptions: t`Включить субтитры`,
            disableCaptions: t`Отключить субтитры`,
            download: t`Скачать`,
            enterFullscreen: t`Полноэкранный режим`,
            exitFullscreen: t`Выйти из полноэкранного режима`,
            frameTitle: t`Проигрывается {title}`,
            captions: t`Сутбирты`,
            settings: t`Настройки`,
            pip: t`Картинка в картинке`,
            menuBack: t`Назад`,
            speed: t`Скорость`,
            normal: t`1x`,
            quality: t`Качество`,
            loop: t`Повторять`,
            start: t`Начало`,
            end: t`Конец`,
            all: t`Всё`,
            reset: t`Перезапустить`,
            disabled: t`Отключить`,
            enabled: t`Включить`,
            advertisement: t`Реклама`,
            qualityBadge: {
                2160: '4K',
                1440: 'HD',
                1080: 'HD',
                720: 'HD',
                576: 'SD',
                480: 'SD',
            }
        }
    }, []);

    return <PlyrWrapper
        ref={plyrWrapperRef}
        className={classNames(menuToBottom && 'menu-to-bottom')}
    >
        <Plyr
            ref={ref}
            height={props.height}
              onPause={() => {
                  if (props.onPause) {
                      props.onPause();
                  }
              }}
              onEnded={() => {
                  if (props.onEnd) {
                      props.onEnd();
                  }
              }}
              onError={() => {
                  if (props.onError) {
                      props.onError();
                  }
              }}
              onCanPlayThrough={() => {
                  if (props.onReady) {
                      props.onReady();
                  }
              }}
              source={{
                  type: "audio",
                  sources: sources
              }}
              options={{
                  i18n: localization,
                  resetOnEnd: true,
                  controls: controls,
                  settings: settings,
                  speed: {
                      selected: 1,
                      options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
                  }
              }}
        />
    </PlyrWrapper>;
}));

AudioPlayer.displayName = 'AudioPlayer';