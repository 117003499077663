import React, {useMemo} from 'react';
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";

import {ReactComponent as TitleSvg} from "../components/icons/Title.svg";
import {ReactComponent as TextSvg} from "../components/icons/Text.svg";
import {ReactComponent as UnfoldBlockSvg} from "../components/icons/UnfoldBlock.svg";
import {ReactComponent as TeacherNotesSvg} from "../components/icons/TeacherNotes.svg";
import {ReactComponent as TeacherCanSaySvg} from "../components/icons/TeacherCanSay.svg";
import {ReactComponent as TeacherAlsoDiscussSvg} from "../components/icons/TeacherAlsoDiscuss.svg";
import {ReactComponent as MediaImageSvg} from "../components/icons/MediaImage.svg";
import {ReactComponent as MediaVideoSvg} from "../components/icons/MediaVideo.svg";
import {ReactComponent as MediaAudioSvg} from "../components/icons/MediaAudio.svg";
import {ReactComponent as MediaYoutubeSvg} from "../components/icons/MediaYoutube.svg";
import {ReactComponent as ExercisesRadioSvg} from "../components/icons/ExercisesRadio.svg";
import {ReactComponent as ExercisesCheckboxSvg} from "../components/icons/ExercisesCheckbox.svg";
import {ReactComponent as ExercisesFillGapsComboboxSvg} from "../components/icons/ExercisesFillGapsCombobox.svg";
import {ReactComponent as ExercisesFillGapsTextSvg} from "../components/icons/ExercisesFillGapsText.svg";
import {ReactComponent as ExercisesInsertDragDropSvg} from "../components/icons/ExercisesInsertDragDrop.svg";
import {ReactComponent as ExercisesMatchSvg} from "../components/icons/ExercisesMatch.svg";
import {ReactComponent as ExercisesCorrectExistInInputSvg} from "../components/icons/ExercisesCorrectExistInInput.svg";
import {ReactComponent as ClipboardPasteSvg} from "../components/icons/ClipboardPaste.svg";
import {ReactComponent as DialogBubbleSvg} from "../components/icons/DialogBubble.svg";

interface ElementIconProps {
    elementType: ElementTypeEnum;
}

export const ElementIcon: React.FC<ElementIconProps> = (props) => {
    const {elementType} = props;

    const elementIcon = useMemo(() => {
        switch (elementType) {
            case ElementTypeEnum.TITLE: {
                return <TitleSvg/>;
            }
            case ElementTypeEnum.TEXT: {
                return <TextSvg/>;
            }
            case ElementTypeEnum.UNFOLD_BLOCK: {
                return <UnfoldBlockSvg/>;
            }
            case ElementTypeEnum.TEACHER_NOTES: {
                return <TeacherNotesSvg/>;
            }
            case ElementTypeEnum.TEACHER_CAN_SAY: {
                return <TeacherCanSaySvg/>;
            }
            case ElementTypeEnum.TEACHER_ALSO_DISCUSS: {
                return <TeacherAlsoDiscussSvg/>;
            }
            case ElementTypeEnum.MEDIA_PICTURE: {
                return <MediaImageSvg/>;
            }
            case ElementTypeEnum.MEDIA_VIDEO: {
                return <MediaVideoSvg/>;
            }
            case ElementTypeEnum.MEDIA_AUDIO: {
                return <MediaAudioSvg/>;
            }
            case ElementTypeEnum.MEDIA_YOUTUBE: {
                return <MediaYoutubeSvg/>;
            }
            case ElementTypeEnum.EXERCISES_RADIO: {
                return <ExercisesRadioSvg/>;
            }
            case ElementTypeEnum.EXERCISES_CHECKBOX: {
                return <ExercisesCheckboxSvg/>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX: {
                return <ExercisesFillGapsComboboxSvg/>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT: {
                return <ExercisesFillGapsTextSvg/>;
            }
            case ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP: {
                return <ExercisesInsertDragDropSvg/>;
            }
            case ElementTypeEnum.EXERCISES_MATCH: {
                return <ExercisesMatchSvg/>;
            }
            case ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT: {
                return <ExercisesCorrectExistInInputSvg/>;
            }
            case ElementTypeEnum.CLIPBOARD_PASTE: {
                return <ClipboardPasteSvg/>;
            }
            case ElementTypeEnum.DIALOG_BUBBLE: {
                return <DialogBubbleSvg/>;
            }
            default: {
                return <div>?</div>;
            }
        }
    }, [elementType]);

    return <>{elementIcon}</>;
}