import * as React from "react";
import {DatePicker as AntDatePicker} from "antd";
import {DatePickerProps as AntDatePickerProps} from "antd/lib/date-picker";
import styled from "styled-components";
import classNames from "classnames";
import {RegularFontSize} from "../../../styles/global-elements";

export const DatePickerStyled = styled(AntDatePicker)`
  ${RegularFontSize};
  border: none;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 200px;
  cursor: pointer;
  width: 100%;

  &:focus, &:hover {
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-right-width: inherit;
    box-shadow: none;
  }

  &.ant-picker-focused {
    box-shadow: none;
  }

  .ant-picker-suffix {
    color: inherit;
  }

  .ant-picker-clear {
    color: inherit;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
  }

  input {
    color: inherit;
    cursor: pointer;
    font-size: inherit;
  }
  
  &.read-only {
    pointer-events: none;
  }

  @media (${({theme}) => theme.media.small}) {
    input {
      font-size: inherit;
    }
  }
`;

type DatePickerProps = {
    readOnly?: boolean;
} & AntDatePickerProps;

export const DatePicker: React.FunctionComponent<DatePickerProps> = ({readOnly, ...props}) => {
    return <DatePickerStyled
        className={classNames(readOnly && 'read-only')}
        format={"DD MMMM YYYY"}
        picker={"date"}
        placement={"bottomRight"}
        inputReadOnly
        allowClear={false}
        {...props} />;
}
