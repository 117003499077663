import {
    DtoAgreementItem
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAgreementItem";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {ConfirmDialog} from "../../../../../../components/Ui/Elements/ConfirmDialog";
import {t} from "@lingui/macro";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";

interface CloseAgreementModalProps {
    dto: DtoAgreementItem;
}

export const CloseAgreementModal = forwardRef<PopupActions, CloseAgreementModalProps>(
    (
        {
            dto
        },
        ref
    ) => {
        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);
        const modalRef = useRef<PopupActions>(null);
        const stToken = useSelector(stTokenSelector);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            close: () => {
                modalRef.current?.close()
            },
            open: () => {
                modalRef.current?.open()
            },
            toggle: () => {
                modalRef.current?.toggle()
            },
        }));

        const closeAgreement = useCallback(async () => {
            if (!stToken) {
                return;
            }
            
            const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
            
            try {
                await stApiClient.closeAgreement(stToken, dto.id);
                
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Сохранено`,
                    t`Информация успешно сохранена`
                );

                studentPageContext.reloadBaseInfo();
            } catch (e) {
                if (e instanceof NoConnection) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось применить изменения`,
                        t`Проверьте соединение с интернетом`
                    );

                    return;
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось применить изменения`,
                    t`Попробуйте повторить попытку позднее`
                );

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.STONLINE_SCHEDULE_API,
                    `Error on close agreement ${dto.id}: `,
                    e
                );
            }
        }, [dto.id, stToken, studentPageContext]);

        if (dto.annulled === 1) {
            return null;
        }

        if (dto.anotherGroupParticipants.length > 0) {
            return <ConfirmDialog
                ref={modalRef}
                okText={t`Завершить обучение`}
                cancelText={t`Отмена`}
                title={t`Завершить обучение?`}
                text={t`Студент обучается в группе. Данные о его участии на прошлых уроках сохранятся, но в новых занятиях группы он уже не будет участвовать.`}
                okMethod={closeAgreement}
            />
        }

        return <ConfirmDialog
            ref={modalRef}
            okText={t`Завершить обучение`}
            cancelText={t`Отмена`}
            title={t`Завершить обучение?`}
            text={t`Данные о его участии на прошлых уроках сохранятся.`}
            okMethod={closeAgreement}
        />
    });