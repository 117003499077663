import React, {useMemo} from 'react';
import {ToolbarButtonEnum} from "./ToolbarButtonEnum";
import styled from "styled-components";
import {ToolbarIcon} from "./ToolbarIcon";
import classNames from "classnames";

export interface ButtonState {
    buttonType: ToolbarButtonEnum;
    active: boolean;
    disabled: boolean;
}

export interface ToolbarProps {
    buttonConfig: ButtonState[] | null;
    toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => void;
}

const ToolbarStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${({theme}) => theme.colors.accentDivider};
  min-height: 40px;
  color: ${({theme}) => theme.colors.textPrimary};
`;

const ToolbarIconWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }
`;

export const Toolbar: React.FC<ToolbarProps> = (props) => {
    const {buttonConfig} = props;

    const defaultButtonConfig = useMemo((): ButtonState[] => {
        return [
            {
                buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                active: false,
                disabled: true
            },
            {
                buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                active: false,
                disabled: true
            },
            {
                buttonType: ToolbarButtonEnum.ALIGN_JUSTIFY,
                active: false,
                disabled: true
            },
            {
                buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                active: false,
                disabled: true
            },
            {
                buttonType: ToolbarButtonEnum.TRANSLATED,
                active: false,
                disabled: true
            }
        ]
    }, []);

    const activeButtonConfig = useMemo(() => {
        return (buttonConfig) ? buttonConfig : defaultButtonConfig;
    }, [defaultButtonConfig, buttonConfig]);

    return <ToolbarStyled>
        {
            activeButtonConfig.map((item) => {
                return <ToolbarIconWrapper key={item.buttonType}
                                           onClick={() => props.toolbarItemOnToggle(item.buttonType, !item.active)}
                                           className={
                                               classNames(item.active && "active", item.disabled && "disabled")
                                           }>
                    <ToolbarIcon iconType={item.buttonType}/>
                </ToolbarIconWrapper>;
            })
        }
    </ToolbarStyled>
}