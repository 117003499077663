import React, {CSSProperties, useMemo} from 'react';
import styled from "styled-components";
import {Trans} from "@lingui/macro";

import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";
import {Item} from './Item';
import {PaletteGroupEnum} from "./PaletteGroupEnum";
import {container} from "tsyringe";
import {IFeatureToggle} from "../../../../components/FeatureToggle/IFeatureToggle";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {FeaturesEnum} from "../../../../components/FeatureToggle/FeaturesEnum";

interface ElementsPaletteProps {
    style?: CSSProperties;
    onElementSelect: (elementType: ElementTypeEnum) => void,
    disabledItems?: Array<ElementTypeEnum>,
    disabledPalettes?: Array<PaletteGroupEnum>
}

const ElementsGroup = styled.div`
`;

const GroupTitle = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  font-size: 13px;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  position: relative;

  &::before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    border-bottom: 1px dashed ${({theme}) => theme.colors.accentDivider};
  }
`;

const GroupTitleText = styled.span`
  background: ${({theme}) => theme.colors.backgroundPrimary};
  display: inline-block;
  position: relative;
  padding: 0 10px;
`;

const ItemList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  @media (${({theme}) => theme.media.small}) {
    grid-template-columns: 33% 33% 33%;
  }

  @media (${({theme}) => theme.media.large}) {
    grid-template-columns: 50% 50%;
  }
`;

export const ElementsPalette: React.FC<ElementsPaletteProps> = (
    {
        style,
        onElementSelect,
        disabledItems,
        disabledPalettes
    }
) => {
    const featureToggle = useMemo(() => container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE), []);
    const bubbleDialogButtonAllowed = useMemo(() => featureToggle.featureIsAvailable(FeaturesEnum.BUBBLE_DIALOG_IN_SLIDE_EDITOR), [featureToggle]);

    
    const contentItemsList = useMemo((): Array<ElementTypeEnum> => {
        return [ElementTypeEnum.TITLE, ElementTypeEnum.TEXT, ElementTypeEnum.UNFOLD_BLOCK];
    }, []);

    const notForStudentItemsList = useMemo((): Array<ElementTypeEnum> => {
        return [
            ElementTypeEnum.TEACHER_NOTES,
            ElementTypeEnum.TEACHER_CAN_SAY,
            ElementTypeEnum.TEACHER_ALSO_DISCUSS
        ];
    }, []);

    const mediaItemsList = useMemo((): Array<ElementTypeEnum> => {
        return [
            ElementTypeEnum.MEDIA_PICTURE,
            ElementTypeEnum.MEDIA_VIDEO,
            ElementTypeEnum.MEDIA_AUDIO,
            ElementTypeEnum.MEDIA_YOUTUBE
        ];
    }, []);

    const exercisesItemsList = useMemo((): Array<ElementTypeEnum> => {
        return [
            ElementTypeEnum.EXERCISES_RADIO,
            ElementTypeEnum.EXERCISES_CHECKBOX,
            ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX,
            ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT,
            ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP,
            ElementTypeEnum.EXERCISES_MATCH,
            ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT
        ]
    }, []);

    const otherItemsList = useMemo((): Array<ElementTypeEnum> => {
        const buttons = [
            ElementTypeEnum.CLIPBOARD_PASTE
        ];

        if (bubbleDialogButtonAllowed) {
            buttons.push(ElementTypeEnum.DIALOG_BUBBLE);
        }
        
        return buttons;
    }, [bubbleDialogButtonAllowed]);

    const renderGroup = (groupType: PaletteGroupEnum, groupTitle: JSX.Element, items: Array<ElementTypeEnum>, isFirstGroup: boolean) => {
        if (disabledPalettes && disabledPalettes.indexOf(groupType)>-1) {
            return null;
        }

        return <ElementsGroup>
            <GroupTitle>
                <GroupTitleText>{groupTitle}</GroupTitleText>
            </GroupTitle>
            <ItemList>
                {
                    items.map((item) => {
                        return <Item key={item} itemType={item}
                                     onClick={() => onElementSelect(item)}
                                     disabled={(disabledItems) && (disabledItems.indexOf(item) > -1)}
                        />;
                    })
                }
            </ItemList>
        </ElementsGroup>
    }

    return (
        <div style={style}>
            {renderGroup(PaletteGroupEnum.FOR_TEACHER, <Trans>Для учителя</Trans>, notForStudentItemsList, true)}
            {renderGroup(PaletteGroupEnum.TEXT, <Trans>Текстовый контент</Trans>, contentItemsList, false)}
            {renderGroup(PaletteGroupEnum.MEDIA, <Trans>Медиа</Trans>, mediaItemsList, false)}
            {renderGroup(PaletteGroupEnum.EXERCISES, <Trans>Упражнения</Trans>, exercisesItemsList, false)}
            {renderGroup(PaletteGroupEnum.OTHER, <Trans>Остальное</Trans>, otherItemsList, false)}
        </div>
    );
}