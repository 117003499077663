import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {ReactComponent as DarkThemeIconSmall} from "../../../../components/Ui/Svg/DarkThemeIconSmall.svg";
import {ReactComponent as LightThemeIconSmall} from "../../../../components/Ui/Svg/LightThemeIconSmall.svg";
import {ApplicationState} from "../../../../../store";
import {ThemeEnum} from "../../../../../services/theme/ThemeEnum";
import * as layoutActionCreators from "../../../../../store/layout/actions";
import {contentLayoutSettingsSelector} from "../../../../../store/layout/selector";

export const ThemeSwitcherWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  line-height: 18px;
  cursor: pointer;
`;

export const DarkThemeIconSmallStyled = styled(DarkThemeIconSmall)`
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const LightThemeIconSmallStyled = styled(LightThemeIconSmall)`
  color: ${({theme}) => theme.colors.textPrimary};
`;

export const ThemeSwitcher: React.FC = () => {
    const dispatch = useDispatch();
    const contentLayoutSettings = useSelector(contentLayoutSettingsSelector);

    const currentThemeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as ThemeEnum;

    const switchTheme = () => {
        if (currentThemeName === ThemeEnum.light) {
            dispatch(layoutActionCreators.changeTheme(ThemeEnum.dark));

            return;
        }

        dispatch(layoutActionCreators.changeTheme(ThemeEnum.light));
    }

    if (contentLayoutSettings?.themeName) {
        return null;
    }

    return <ThemeSwitcherWrapper onClick={switchTheme}>
        {
            (currentThemeName === ThemeEnum.light) ? <DarkThemeIconSmallStyled/> : <LightThemeIconSmallStyled/>
        }
    </ThemeSwitcherWrapper>
};