import React from "react";
import {BlockHeaderMargin, BlockWithBorderAndMargin, RegularTextCss} from "../../../../../styles/global-elements";
import {
    DtoStudentInLessonListItem
} from "../../../../../../components/StonlineApiClient/ApiDto/Response/StudentInLesson/DtoStudentInLessonListItem";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {AvatarNameBlock} from "../../../../../components/Ui/Elements/AvatarNameBlock";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {Checkbox} from "../../../../../components/Ui/Elements/Checkbox";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import {TeacherRatesBlock} from "./TeacherRatesBlock";
import {TextArea} from "../../../../../components/Ui/Elements/TextArea";
import {TextAreaRef} from "antd/es/input/TextArea";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {
    ActualParamsDto
} from "../../../../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {ApplicationState} from "../../../../../../store";
import {connect} from "react-redux";
import {selectedSchoolActualParams} from "../../../../../../store/user/selector";
import {cloneDeep, debounce} from "lodash";
import {StudentRateParamPositionEnum} from "../../../../../../enums/StudentRateParamPositionEnum";
import {StudentInLessonAbsentEnum} from "../../../../../../components/StonlineApiClient/Enums/StudentInLessonAbsentEnum";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";

const ContainerForAvatarSticky = styled.div``;

const AvatarBlockStyled = styled(AvatarNameBlock)`
  ${BlockHeaderMargin};
  padding-top: 10px;

  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight + 'px'};
  z-index: 2;
`;

const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

const RatesWrapper = styled.div`
  margin-bottom: 20px;
`;
const RatesWrapperTitle = styled.div`
  ${RegularTextCss};

  margin-bottom: 10px;
`;

const TeacherCommentWrapper = styled.div`
  margin-bottom: 20px
`;

const ButtonWrapper = styled.div``;

const SaveButton = styled(Button)`
  &[disabled] {
    cursor: default;
  }
`;

interface StudentBlockProps {
    stToken: string | null;
    stStudentInLesson: DtoStudentInLessonListItem;
    actualParams?: ActualParamsDto | null;
    updateSavedData: (newDto: DtoStudentInLessonListItem) => void;
}

interface StudentBlockState {
    absentChecked: boolean;
    absentRespectfulReasonChecked: boolean;
    teacherComment: string;
    param1Value: number | null;
    param2Value: number | null;
    param3Value: number | null;
    param4Value: number | null;
    param5Value: number | null;

    needSave: boolean;
    saveInProcess: boolean;
}

class StudentBlock extends React.Component<StudentBlockProps, StudentBlockState> {
    protected stonlineApiClient: IStonlineApiClient;
    protected dateHelperService: IDateHelperService;

    protected logger: ILogger;

    protected absentCheckboxRef: React.RefObject<HTMLInputElement>;
    protected absentRespectfulReasonCheckboxRef: React.RefObject<HTMLInputElement>;
    protected teacherCommentRef: React.RefObject<TextAreaRef>;

    protected absentCheckboxOnChangeBind;
    protected absentRespectfulReasonCheckboxOnChangeBind;
    protected teacherCommentOnChangeBind;

    protected checkNeedSaveDebounced;

    protected saveAbortController: AbortController | null;

    constructor(props: Readonly<StudentBlockProps> | StudentBlockProps) {
        super(props);

        if ((this.props.actualParams === undefined) || (this.props.actualParams === null)) {
            throw new Error("Actual params is null in StudentBlock");
        }

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.state = {
            absentChecked: this.props.stStudentInLesson.absent !== StudentInLessonAbsentEnum.NO_ABSENT,
            absentRespectfulReasonChecked: this.props.stStudentInLesson.absent === StudentInLessonAbsentEnum.ABSENT_RESPECTFUL_REASON,
            teacherComment: this.props.stStudentInLesson.comment,

            param1Value: (this.props.actualParams.first !== null) ? this.props.stStudentInLesson.param1 : null,
            param2Value: (this.props.actualParams.second !== null) ? this.props.stStudentInLesson.param2 : null,
            param3Value: (this.props.actualParams.third !== null) ? this.props.stStudentInLesson.param3 : null,
            param4Value: (this.props.actualParams.fourth !== null) ? this.props.stStudentInLesson.param4 : null,
            param5Value: (this.props.actualParams.fifth !== null) ? this.props.stStudentInLesson.param5 : null,

            needSave: false,
            saveInProcess: false
        }

        this.saveAbortController = null;

        this.absentCheckboxRef = React.createRef<HTMLInputElement>();
        this.absentRespectfulReasonCheckboxRef = React.createRef<HTMLInputElement>();

        this.absentCheckboxOnChangeBind = this.absentCheckboxOnChange.bind(this);
        this.absentRespectfulReasonCheckboxOnChangeBind = this.absentRespectfulReasonCheckboxOnChange.bind(this);
        this.teacherCommentOnChangeBind = this.teacherCommentOnChange.bind(this);
        this.checkNeedSaveDebounced = debounce(this.checkNeedSave.bind(this), 500);
    }

    componentWillUnmount() {
        this.checkNeedSaveDebounced.cancel();
    }

    protected checkNeedSave = () => {
        // absent
        const receivedAbsentValue = this.props.stStudentInLesson.absent !== StudentInLessonAbsentEnum.NO_ABSENT;

        if (this.state.absentChecked !== receivedAbsentValue) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }

        // absentRespectfulReason
        if (this.state.absentChecked) {
            if (this.state.absentRespectfulReasonChecked !== (this.props.stStudentInLesson.absent === StudentInLessonAbsentEnum.ABSENT_RESPECTFUL_REASON)) {
                this.setState(() => {
                    return {needSave: true}
                });

                return;
            }
        }

        // teacherComment
        if (this.state.teacherComment !== this.props.stStudentInLesson.comment) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }

        // params
        if ((this.state.param1Value !== null) && (this.props.stStudentInLesson.param1 !== this.state.param1Value)) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }
        if ((this.state.param2Value !== null) && (this.props.stStudentInLesson.param2 !== this.state.param2Value)) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }
        if ((this.state.param3Value !== null) && (this.props.stStudentInLesson.param3 !== this.state.param3Value)) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }
        if ((this.state.param4Value !== null) && (this.props.stStudentInLesson.param4 !== this.state.param4Value)) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }
        if ((this.state.param5Value !== null) && (this.props.stStudentInLesson.param5 !== this.state.param5Value)) {
            this.setState(() => {
                return {needSave: true}
            });

            return;
        }

        this.setState(() => {
            return {needSave: false}
        });
    }

    protected absentCheckboxOnChange = (e: CheckboxChangeEvent) => {
        // Изменяется состояния основной галочки отсутствия
        this.setState(() => {
            return {
                absentRespectfulReasonChecked: false,
                absentChecked: e.target.checked
            }
        }, this.checkNeedSaveDebounced);
    }

    protected absentRespectfulReasonCheckboxOnChange = (e: CheckboxChangeEvent) => {
        // Изменяется состояние дополнительной галочки отсутствия
        this.setState(() => {
            return {
                absentRespectfulReasonChecked: e.target.checked
            }
        }, this.checkNeedSaveDebounced);
    }

    protected teacherCommentOnChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        this.setState(() => {
            return {
                teacherComment: e.currentTarget.value
            }
        }, this.checkNeedSaveDebounced)
    }

    protected studentInLessonParamValueOnChange = (paramNum: StudentRateParamPositionEnum, newValue: number) => {
        switch (paramNum) {
            case StudentRateParamPositionEnum.FIRST: {
                this.setState(() => {
                    return {
                        param1Value: newValue
                    }
                }, this.checkNeedSaveDebounced);

                break;
            }
            case StudentRateParamPositionEnum.SECOND: {
                this.setState(() => {
                    return {
                        param2Value: newValue
                    }
                }, this.checkNeedSaveDebounced);

                break;
            }
            case StudentRateParamPositionEnum.THIRD: {
                this.setState(() => {
                    return {
                        param3Value: newValue
                    }
                }, this.checkNeedSaveDebounced);

                break;
            }
            case StudentRateParamPositionEnum.FOURTH: {
                this.setState(() => {
                    return {
                        param4Value: newValue
                    }
                }, this.checkNeedSaveDebounced);

                break;
            }
            case StudentRateParamPositionEnum.FIFTH: {
                this.setState(() => {
                    return {
                        param5Value: newValue
                    }
                }, this.checkNeedSaveDebounced);

                break;
            }
        }
    }

    protected saveBtnClick = async () => {
        if (this.props.stToken === null) {
            return;
        }

        this.setState(() => {
            return {
                saveInProcess: true
            }
        });

        this.saveAbortController = new AbortController();

        let absentValue = StudentInLessonAbsentEnum.NO_ABSENT;

        if (this.state.absentChecked) {
            absentValue = (this.state.absentRespectfulReasonChecked)
                ? StudentInLessonAbsentEnum.ABSENT_RESPECTFUL_REASON
                : StudentInLessonAbsentEnum.ABSENT_NO_REASON;
        }

        try {
            await this.stonlineApiClient.updateStudentInLesson(
                this.props.stToken,
                this.props.stStudentInLesson.id,
                (this.state.param1Value) ?? 0,
                this.state.param2Value,
                this.state.param3Value,
                this.state.param4Value,
                this.state.param5Value,
                this.state.teacherComment,
                absentValue,
                this.saveAbortController
            );

            this.setState(() => {
                return {
                    saveInProcess: false
                }
            });

            // Отправим обновлённую информацию выше, чтобы там она была актуальная TODO
            const newDto: DtoStudentInLessonListItem = cloneDeep(this.props.stStudentInLesson);

            newDto.absent = absentValue;
            newDto.comment = this.state.teacherComment;

            if (this.state.param1Value !== null) {
                newDto.param1 = this.state.param1Value;
            }
            if (this.state.param2Value !== null) {
                newDto.param2 = this.state.param2Value;
            }
            if (this.state.param3Value !== null) {
                newDto.param3 = this.state.param3Value;
            }
            if (this.state.param4Value !== null) {
                newDto.param4 = this.state.param4Value;
            }
            if (this.state.param5Value !== null) {
                newDto.param5 = this.state.param5Value;
            }

            this.props.updateSavedData(newDto);

            this.checkNeedSave();

            openNotification(
                NotificationTypesEnum.SUCCESS,
                t`Информация сохранена`,
                ''
            );
        } catch (e) {
            this.setState(() => {
                return {
                    saveInProcess: false
                }
            });

            this.logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, "Error on save student in lesson: ", e);

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Ошибка сохранения`,
                t`Не удалось сохранить информацию`
            );
        }
    }

    render() {
        return <BlockWithBorderAndMargin>
            <ContainerForAvatarSticky>
                <AvatarBlockStyled avatarFileId={null} name={this.props.stStudentInLesson.studentName}
                                   position={t`Ученик`}/>
                <CheckboxesWrapper>
                    <CheckboxWrapper>
                        <Checkbox ref={this.absentCheckboxRef}
                                  onChange={this.absentCheckboxOnChangeBind}
                                  checked={this.state.absentChecked}>
                            <Trans>Ученик отсутствовал на занятии</Trans>
                        </Checkbox>
                    </CheckboxWrapper>
                    <CheckboxWrapper style={{display: (this.state.absentChecked) ? "block" : "none"}}>
                        <Checkbox ref={this.absentRespectfulReasonCheckboxRef}
                                  onChange={this.absentRespectfulReasonCheckboxOnChangeBind}
                                  checked={this.state.absentRespectfulReasonChecked}>
                            <Trans>Отсутствие по уважительной причине</Trans>
                        </Checkbox>
                    </CheckboxWrapper>
                </CheckboxesWrapper>

                {/*{*/}
                {/*    (this.state.absentChecked) ||*/}
                {/*    <RatesWrapper>*/}
                {/*        <RatesWrapperTitle><Trans>Автоматическая оценка</Trans></RatesWrapperTitle>*/}
                {/*        <AutoRatesBlock/>*/}
                {/*    </RatesWrapper>*/}
                {/*}*/}

                {
                    (this.state.absentChecked) ||
                    <RatesWrapper>
                        <RatesWrapperTitle><Trans>Оценка учителем</Trans></RatesWrapperTitle>
                        {
                            (this.props.actualParams)
                            && <TeacherRatesBlock
                                actualParams={this.props.actualParams}
                                param1Value={this.state.param1Value}
                                param2Value={this.state.param2Value}
                                param3Value={this.state.param3Value}
                                param4Value={this.state.param4Value}
                                param5Value={this.state.param5Value}
                                onChange={this.studentInLessonParamValueOnChange}
                            />
                        }
                    </RatesWrapper>
                }
            </ContainerForAvatarSticky>

            <TeacherCommentWrapper>
                <TextArea ref={this.teacherCommentRef}
                          value={this.state.teacherComment}
                          placeholder={t`Комментарий к работе на уроке`}
                          onChange={this.teacherCommentOnChangeBind}/>
            </TeacherCommentWrapper>

            <ButtonWrapper>
                <SaveButton btnStyle={BtnStyleEnum.Primary} disabled={!this.state.needSave}
                            loading={this.state.saveInProcess} onClick={this.saveBtnClick}>
                    <Trans>Сохранить</Trans>
                </SaveButton>
            </ButtonWrapper>

        </BlockWithBorderAndMargin>;
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    actualParams: selectedSchoolActualParams(state),
});

export default connect(mapStateToProps)(StudentBlock);
