import React from "react";
import {Dispatch} from "redux";
import {LessonRoomStateTypes, LessonRoomUserTypeEnum, RoomConnectionParams} from "../../../../store/lessonRoom/type";
import {
    clearRoomState,
    handleLeaveFromRoomViaRouter,
    loadRoomMembersList,
    setLessonRoomId,
    setLessonRoomState,
    setRoomConnectionParams
} from "../../../../store/lessonRoom/actions";
import {rebootApp, setCameraRequestAccessNow} from "../../../../store/app/actions";
import {ApplicationState} from "../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {withRouter, WithRouterProps} from "../../../components/WithRouterHoc";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {WsConnectionStatusEnum} from "../../../../components/WsApiClient/WsConnectionStatusEnum";
import {DefaultLoader} from "../../../components/DefaultLoader";
import styled from "styled-components";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {t, Trans} from "@lingui/macro";
import {IWsApiClient} from "../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../components/WsApiClient/ApiMethodEnum";
import {
    DtoBaseWithLessonRoomId
} from "../../../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoBaseWithLessonRoomId";
import {
    DtoRoomBaseInfoResponse
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoRoomBaseInfoResponse";
import {WsResponseStatusEnum} from "../../../../components/WsApiClient/WsResponseStatusEnum";
import {DtoJoinToRoomRequest} from "../../../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoJoinToRoomRequest";
import {
    DtoJoinToRoomResponse
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoJoinToRoomResponse";
import RoomControlBlock from "./RoomControlBlock";
import {LessonPageContextProvider} from "./LessonPageContext";
import {debounce, isEqual} from "lodash";
import {PlayerSlideModel} from "../../../components/SlidePlayer/PlayerSlideModel";
import {PlayerContextProvider, SlidesCachedContent} from "../../../components/SlidePlayer/PlayerContext";
import {EditorData} from "../../../components/SlidePlayerEditorCommonParts/EditorData";
import {MaterialsPlayer} from "./MetarialsPlayer";
import {
    loadLessonMaterialsList,
    setLoadingState,
    subscribeToSlideList,
    unsubscribeFromSlideList
} from "../../../../store/lessonMaterials/actions";
import {LoadingStateEnum} from "../../../../store/lessonMaterials/type";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../components/Ui/Elements/NoticeBlock";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../../../enums/SlidePlayerIdEnum";
import {loadSlideWorkData} from "../../../../store/slidesWorkData/actions";
import {LoadSlideWorkDataActionParams} from "../../../../store/slidesWorkData/type";
import {NotificationTypesEnum, openNotification} from "../../../components/Ui/Elements/Notification";
import {ResponseActionCreatorPayload} from "../../../../components/WsApiClient/ResponseActionCreatorPayload";
import {
    DtoSEStudentNeedOpenSlide
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEStudentNeedOpenSlide";
import {IStreamEventService} from "../../../../services/stream-event-service/IStreamEventService";
import {StreamEventTypeEnum} from "../../../../services/stream-event-service/StreamEventTypeEnum";
import {StreamEventStoreItem} from "../../../../store/streamEvent/type";
import {setBodyScrollAvailable} from "../../../../store/layout/actions";
import {
    DtoSENeedSelectRoomMemberVideo
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSelectRoomMemberVideo";
import {
    DtoSENeedSetMembersFullScreenModeValue
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSENeedSetMembersFullScreenModeValue";
import {roomMembersSelector} from "../../../../store/lessonRoom/selector";
import {IMediaDeviceService} from "../../../../services/media-device/IMediaDeviceService";
import {VideoRoomErrorModalTypeEnum} from "../common/Lesson/VideoRoomErrorModal";
import {ErrorMessage} from "./ErrorMessage";
import {LessonConference, LessonConferenceMethods} from "../../../components/LessonConference";
import {ConferenceModeEnum} from "../../../components/LessonConference/Types";
import {ViewportHelper} from "../../../../helpers/ViewportHelper";

enum PageStatusEnum {
    NOT_INIT,
    INITIALIZATION,
    READY,
    FATAL_ERROR,
    CAMERA_PERMISSIONS_FATAL_ERROR
}

enum LeaveFromRoomReasonEnum {
    ROUTER_EVENT,
    WS_DISCONNECTED
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageMainContentWrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
    max-width: 680px;
  }
`;

const PageLoaderWrapper = styled.div`
  width: 100%;
`;

const LessonConferenceStyled = styled(LessonConference)`
  margin-bottom: 44px;
`;

const STREAM_EVENT_OPEN_SLIDE_SUBSCRIBER_ID = 'StudentLessonNeedOpenSlide';
const STREAM_EVENT_NEED_SELECT_ROOM_MEMBER_VIDEO_ID = 'StudentRoomNeedSelectRoomMemberVideo';
const STREAM_EVENT_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE = 'StudentRoomNeedSetMembersFullScreenModeValue';

interface RouterParams {
    lessonId: string;
}

interface LessonPageProps extends PropsFromRedux {
}

type LessonPagePropsWRouter = WithRouterProps<RouterParams> & LessonPageProps;

interface LessonPageState {
    pageStatus: PageStatusEnum,
    lessonId: string | null;

    onlineRoomHasVideo: boolean;
    onlineRoomIsConnected: boolean;

    selectedRoomMemberId: string | null;

    videoAreaInViewport: boolean;
    windowIsLarge: boolean;

    selectedSlideId: string | null;
    selectedSlideIndex: number | null;

    slidesContent: SlidesCachedContent;

    videoRoomError: VideoRoomErrorModalTypeEnum | null;
}

class LessonPage extends React.Component<LessonPagePropsWRouter, LessonPageState> {
    protected logger: ILogger;
    protected mediaDeviceService: IMediaDeviceService;

    protected wsClient: IWsApiClient;
    protected streamEventService: IStreamEventService;

    protected lessonConferenceRef: React.RefObject<LessonConferenceMethods>;

    protected accessToCameraPromiseResolve: ((value: boolean) => void) | null;

    protected checkVideoAreaInViewportDebounced;

    protected mediaQueryLarge;

    constructor(props: Readonly<LessonPagePropsWRouter>) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        this.streamEventService = container.resolve<IStreamEventService>(DiTokens.WS_STREAM_EVENT_SERVICE);
        this.mediaDeviceService = container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE);

        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);

        this.state = {
            pageStatus: PageStatusEnum.NOT_INIT,
            lessonId: null,
            onlineRoomHasVideo: false,
            onlineRoomIsConnected: false,
            selectedRoomMemberId: null,
            videoAreaInViewport: true,
            windowIsLarge: this.mediaQueryLarge.matches,
            selectedSlideId: null,
            selectedSlideIndex: null,
            slidesContent: {},
            videoRoomError: null
        }

        this.accessToCameraPromiseResolve = null;

        this.lessonConferenceRef = React.createRef();

        this.checkVideoAreaInViewportDebounced = debounce(this.checkVideoAreaInViewport, 100);
    }

    componentDidMount() {
        if (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED) {
            this.joinToRoom().then();
            this.subscribeToNeedOpenSlideEvent();
            this.subscribeToNeedSelectRoomMember();
            this.subscribeToNeedSetMembersFullScreenModeValue();
            // Если подключение не готово, то просто ждём готовности
        }

        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(prevProps: Readonly<LessonPagePropsWRouter>, prevState: Readonly<LessonPageState>, snapshot?: any) {
        if (this.state.pageStatus === PageStatusEnum.NOT_INIT) {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если страница не инициализирована
                // и ws соединение только сейчас подготовилось (не было готово на этапе монтирования)
                this.joinToRoom().then();
                this.subscribeToNeedOpenSlideEvent();
                this.subscribeToNeedSelectRoomMember();
                this.subscribeToNeedSetMembersFullScreenModeValue();
            }

            return;
        } else if (this.state.pageStatus === PageStatusEnum.READY) {
            if (
                (prevProps.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если WS соединение потерялось
                this.leaveFromRoom(LeaveFromRoomReasonEnum.WS_DISCONNECTED);
                this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_OPEN_SLIDE_SUBSCRIBER_ID);
                this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_NEED_SELECT_ROOM_MEMBER_VIDEO_ID);
                this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE);
            } else if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если соединение установлено после какой-то проблемы, когда страница уже прошла инициализацию
                this.joinToRoom(true).then();
                this.subscribeToNeedOpenSlideEvent();
                this.subscribeToNeedSelectRoomMember();
                this.subscribeToNeedSetMembersFullScreenModeValue();
            }
        }

        // Проверим - не нужно ли разрезолвить промис доступа к камере
        if (this.accessToCameraPromiseResolve !== null) {
            if ((prevProps.accessToCameraRequestingNow === true) && (this.props.accessToCameraRequestingNow === false)) {
                // Если вернулись из "окна" запроса доступа
                this.accessToCameraPromiseResolve(this.props.accessToCameraAllowedInSession ?? false);
            }
        }

        // Если появились слайды
        let needCheckActiveSlide = false;

        if (this.props.lessonMaterialsSlideList.length > 0 && prevProps.lessonMaterialsSlideList.length === 0) {
            if (this.state.selectedSlideId === null) {
                this.setSelectedSlideId(this.props.lessonMaterialsSlideList[0].tmSlideId);
            }

            this.startFetchSlidesWorkData();

            needCheckActiveSlide = true;
        } else if (this.props.lessonMaterialsSlideList.length === 0 && prevProps.lessonMaterialsSlideList.length > 0) {
            // Если исчезли слайды
            if (this.state.selectedSlideId !== null) {
                this.setSelectedSlideId(null);
            }
        } else if (!isEqual(this.props.lessonMaterialsSlideList, prevProps.lessonMaterialsSlideList)) {
            // Если как-то ещё обновился список слайдов
            this.startFetchSlidesWorkData();

            openNotification(
                NotificationTypesEnum.INFO,
                t`Задания обновлены`,
                t`Учитель скорректировал задания`
            );

            needCheckActiveSlide = true;
        }

        if (needCheckActiveSlide) {
            // Проверим: есть ли ещё активный слайд в списке
            const slideFound = this.props.lessonMaterialsSlideList
                .some(item => item.tmSlideId === this.state.selectedSlideId);

            if (!slideFound) {
                this.setSelectedSlideId(this.props.lessonMaterialsSlideList[0].tmSlideId);
            } else {
                this.setSelectedSlideId(this.state.selectedSlideId);
            }
        }

        if (!isEqual(this.props.roomMembers, prevProps.roomMembers)) {
            // Если обновился список участников комнаты
            const teacherInPrevProps = prevProps.roomMembers
                .find(item => item.userType === LessonRoomUserTypeEnum.TEACHER);

            const teacherInCurrentProps = this.props.roomMembers
                .find(item => item.userType === LessonRoomUserTypeEnum.TEACHER);

            if (!teacherInPrevProps && teacherInCurrentProps) {
                // Преподаватель подключился. Переключаемся на него
                this.setSelectedRoomMemberId(teacherInCurrentProps.userId);
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);

        this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_OPEN_SLIDE_SUBSCRIBER_ID);
        this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_NEED_SELECT_ROOM_MEMBER_VIDEO_ID);
        this.streamEventService.unsubscribeFromEventType(STREAM_EVENT_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE);

        this.checkVideoAreaInViewportDebounced.cancel();

        this.leaveFromRoom(LeaveFromRoomReasonEnum.ROUTER_EVENT);
    }

    protected subscribeToNeedOpenSlideEvent = () => {
        this.streamEventService.subscribeToEventType(
            StreamEventTypeEnum.LESSON_ROOM_STUDENT_NEED_OPEN_SLIDE,
            STREAM_EVENT_OPEN_SLIDE_SUBSCRIBER_ID,
            this.streamEventHandlerNeedChangeSlide.bind(this)
        )
    }

    protected subscribeToNeedSelectRoomMember = () => {
        this.streamEventService.subscribeToEventType(
            StreamEventTypeEnum.LESSON_ROOM_NEED_SELECT_ROOM_MEMBER_VIDEO,
            STREAM_EVENT_NEED_SELECT_ROOM_MEMBER_VIDEO_ID,
            (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoSENeedSelectRoomMemberVideo>, string>) => {
                if (this.state.lessonId === data.response.dto.payload.lessonId) {
                    this.setSelectedRoomMemberId(data.response.dto.payload.userId)
                }
            }
        )
    }

    protected subscribeToNeedSetMembersFullScreenModeValue = () => {
        this.streamEventService.subscribeToEventType(
            StreamEventTypeEnum.LESSON_ROOM_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE,
            STREAM_EVENT_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE,
            (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoSENeedSetMembersFullScreenModeValue>, string>) => {
                if ((this.state.lessonId === data.response.dto.payload.lessonId) && (this.lessonConferenceRef.current)) {
                    const currentMode = this.lessonConferenceRef.current.getCurrentMode();

                    if (data.response.dto.payload.value) {
                        if (currentMode !== ConferenceModeEnum.FULL_SCREEN) {
                            this.lessonConferenceRef.current.setCurrentMode(ConferenceModeEnum.FULL_SCREEN);
                        }
                    } else {
                        if (currentMode === ConferenceModeEnum.FULL_SCREEN) {
                            this.lessonConferenceRef.current.setCurrentMode(ConferenceModeEnum.NORMAL);
                        }
                    }
                }
            }
        )
    }

    protected streamEventHandlerNeedChangeSlide = (data: ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEStudentNeedOpenSlide>, string>) => {
        if (this.state.lessonId !== data.response.dto.payload.lessonId) {
            return;
        }

        this.setSelectedSlideId(data.response.dto.payload.slideId);
    }

    protected startFetchSlidesWorkData = () => {
        if ((this.state.lessonId === null) || (this.props.userId === null)) {
            return;
        }

        if (this.props.lessonMaterialsSlideList.length === 0) {
            return;
        }

        this.props.loadSlideWorkData({
            lessonId: this.state.lessonId,
            playerId: this.getPlayerId(),
            userId: this.props.userId,
            slideIds: this.props.lessonMaterialsSlideList.map(item => item.tmSlideId)
        });
    }

    protected getPlayerId = () => {
        return RoutesHelper.replaceParams(
            SlidePlayerIdEnum.STUDENT_SLIDE_ON_LESSON,
            [
                {
                    key: 'lessonId',
                    value: this.state.lessonId ?? ""
                }
            ]
        );
    }

    protected setSlideContent = (slideId: string, data: EditorData) => {
        const value = this.state.slidesContent;

        value[slideId] = data;

        this.setState(() => {
            return {
                slidesContent: value
            }
        })
    }

    protected setSelectedSlideId = (slideId: string | null) => {
        if ((slideId === null) || (this.props.lessonMaterialsSlideList === null) || (this.state.lessonId === null)) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        const selectedSlideIndex = this.props.lessonMaterialsSlideList.findIndex((item) => item.tmSlideId === slideId);

        if (selectedSlideIndex < 0) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        const needSaveSlideId = (this.state.selectedSlideId !== slideId);

        if (this.state.selectedSlideId !== slideId || this.state.selectedSlideIndex !== selectedSlideIndex) {
            this.setState(() => {
                return {
                    selectedSlideId: slideId,
                    selectedSlideIndex: selectedSlideIndex
                }
            });
        }

        if (needSaveSlideId) {
            // Сохраним на сервере текущий slideId
            this.wsClient.query(
                ApiMethodEnum.LESSON_ROOM_SAVE_STUDENT_CURRENT_SLIDE_ID,
                {
                    lessonId: this.state.lessonId,
                    tmSlideId: slideId
                },
                undefined,
                true
            ).then();
        }
    }

    protected onWindowResize = () => {
        this.checkVideoAreaInViewportDebounced();
    }

    protected onWindowScroll = () => {
        this.checkVideoAreaInViewportDebounced();
    }

    protected checkVideoAreaInViewport = () => {
        if (this.lessonConferenceRef.current) {
            const confCurrentMode = this.lessonConferenceRef.current.getCurrentMode();
            const mainZoneObj = this.lessonConferenceRef.current.getMainZoneObj();

            if (
                (confCurrentMode === ConferenceModeEnum.FULL_SCREEN)
                || (confCurrentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE)
                || (mainZoneObj === null)
            ) {
                return;
            }

            const mainZoneRect = mainZoneObj.getBoundingClientRect();

            const result = (mainZoneRect.top < 100) ? ViewportHelper.fullIsInViewport(mainZoneObj) : true;

            if (this.state.videoAreaInViewport !== result) {
                this.setState(() => {
                    return {
                        videoAreaInViewport: result
                    }
                });

                this.lessonConferenceRef.current.setCurrentMode(
                    (result) ? ConferenceModeEnum.NORMAL : ConferenceModeEnum.FLOAT_ON_PAGE
                );
            }
        }

        if (this.state.windowIsLarge !== this.mediaQueryLarge.matches) {
            this.setState(() => {
                return {
                    windowIsLarge: this.mediaQueryLarge.matches
                }
            });
        }
    }

    protected joinToRoom = async (afterReconnect?: boolean) => {
        if (!this.props.match.params.lessonId || this.props.match.params.lessonId.trim() === '') {
            this.setState(() => {
                return {
                    pageStatus: PageStatusEnum.FATAL_ERROR
                }
            });

            return;
        }

        if (!afterReconnect) {
            this.setState(() => {
                return {
                    pageStatus: PageStatusEnum.INITIALIZATION,
                    lessonId: this.props.match.params.lessonId
                }
            });
        }

        // Выполним запрос на получение информации
        const requestBaseInfoDto = new DtoBaseWithLessonRoomId();
        requestBaseInfoDto.lessonId = this.props.match.params.lessonId;

        const roomResult = await this.wsClient.queryAsPromise<DtoRoomBaseInfoResponse>(
            ApiMethodEnum.LESSON_ROOM_GET_BASE_INFO,
            requestBaseInfoDto,
            DtoRoomBaseInfoResponse
        );

        if (roomResult.response.status !== WsResponseStatusEnum.OK) {
            this.setState(() => {
                return {
                    pageStatus: PageStatusEnum.FATAL_ERROR
                }
            });

            return;
        }

        // Если есть видео, проверим разрешение на получение видео с камеры
        if (roomResult.response.result.hasVideo) {
            // Проверим - есть ли нудные устройства
            const deviceIsReadyForCalls = await this.mediaDeviceService.userDeviceIsReadyForCalls();

            if (!deviceIsReadyForCalls) {
                this.setState(() => {
                    return {
                        videoRoomError: VideoRoomErrorModalTypeEnum.NOT_FOUND_CAPTURE_DEVICES
                    }
                });

                return
            }

            // Проверим - было ли ранее в этом сеансе дано разрешение на камеру
            if (this.props.accessToCameraAllowedInSession !== true) {
                this.props.setCameraRequestAccessNow(true);

                const cameraAccessPromise = new Promise<boolean>((resolve) => {
                    this.accessToCameraPromiseResolve = resolve;
                });

                const result = await cameraAccessPromise;

                if (!result) {
                    this.setState(() => {
                        return {
                            pageStatus: PageStatusEnum.CAMERA_PERMISSIONS_FATAL_ERROR
                        }
                    });

                    return;
                }
            }
        }

        // Всё в порядке, входим в комнату
        const requestDto = new DtoJoinToRoomRequest();
        requestDto.lessonId = this.props.match.params.lessonId;

        const roomDetails = await this.wsClient.queryAsPromise<DtoJoinToRoomResponse>(
            ApiMethodEnum.LESSON_ROOM_JOIN_TO_ROOM,
            requestDto,
            DtoJoinToRoomResponse
        );

        let connectionParams: RoomConnectionParams | null = null;

        if (roomDetails.response.result.videoRoomParams !== null) {
            connectionParams = {
                turnServerEntrypoint: roomDetails.response.result.videoRoomParams.turnServerEntrypoint,
                turnServerTlsEntrypoint: roomDetails.response.result.videoRoomParams.turnServerTlsEntrypoint,
                turnServerLogin: roomDetails.response.result.videoRoomParams.turnServerLogin,
                turnServerPassword: roomDetails.response.result.videoRoomParams.turnServerPassword,
                janusWsApiEntrypoint: roomDetails.response.result.videoRoomParams.janusWsApiEntrypoint,
                janusApiKey: roomDetails.response.result.videoRoomParams.janusApiKey,
                janusRoomId: roomDetails.response.result.videoRoomParams.janusRoomId,
                janusJoinKey: roomDetails.response.result.videoRoomParams.janusJoinKey,
                expectedNumberOfStudents: roomResult.response.result.expectedNumberOfStudents
            };
        }

        this.props.setRoomConnectionState(LessonRoomStateTypes.ACTIVE);
        this.props.setRoomId(this.props.match.params.lessonId);
        this.props.setRoomConnectionParams(connectionParams);

        this.setState(
            () => {
                return {
                    pageStatus: PageStatusEnum.READY
                }
            },
            () => {
                if (connectionParams !== null) {
                    this.connectToConferenceRoom(connectionParams);
                }
            });

        this.setState(() => {
            return {
                onlineRoomIsConnected: true,
                onlineRoomHasVideo: (roomDetails.response.result.videoRoomParams !== null),
                selectedSlideId: roomDetails.response.result.currentSlideId
            }
        });

        this.props.loadLessonRoomMembers(requestDto.lessonId);
        this.props.loadLessonMaterialsList(requestDto.lessonId, afterReconnect === true);
        this.props.subscribeToSlideList(requestDto.lessonId);
    }

    protected connectToConferenceRoom = async (connectionParams: RoomConnectionParams) => {
        if (this.props.userId === null) {
            throw new Error('Not found user id');
        }

        this.lessonConferenceRef.current?.startConnection(connectionParams);
    }

    protected leaveFromRoom = (reason: LeaveFromRoomReasonEnum) => {
        this.props.unsubscribeFromSlideList(this.props.match.params.lessonId);

        if (!this.state.onlineRoomIsConnected) {
            return;
        }

        this.setState(() => {
            return {
                onlineRoomIsConnected: false
            }
        });

        this.lessonConferenceRef.current?.stopConnection();

        if (reason === LeaveFromRoomReasonEnum.ROUTER_EVENT) {
            this.lessonConferenceRef.current?.stopConnection();

            this.props.leaveFromRoomViaRouter();

            return;
        }

        if (reason === LeaveFromRoomReasonEnum.WS_DISCONNECTED) {
            this.lessonConferenceRef.current?.emergencyShutdown();

            this.props.clearActiveRoomState();

            return;
        }
    }

    protected setSelectedRoomMemberId = (memberId: string | null) => {
        this.setState(() => {
            return {
                selectedRoomMemberId: memberId
            }
        });
    }

    protected content = () => {
        if (this.state.pageStatus === PageStatusEnum.FATAL_ERROR) {
            return <ErrorLoadingContent
                retryBtnClick={() => {
                    this.props.rebootApp()
                }}
                title={t`Возникла проблема`}
                message={t`Возникла проблема с подключением. Попробуйте повторить попытку, перезагрузив страницу.`}
            />
        }

        if (this.state.pageStatus === PageStatusEnum.CAMERA_PERMISSIONS_FATAL_ERROR) {
            return <ErrorLoadingContent
                retryBtnClick={() => {
                    this.props.rebootApp()
                }}
                title={t`Возникла проблема`}
                message={t`Не удалось получить доступ к вашим камере и микрофону.`}
            />
        }

        if (this.state.pageStatus !== PageStatusEnum.READY) {
            return <PageLoaderWrapper><DefaultLoader/></PageLoaderWrapper>;
        }

        return <>
            <PageMainContentWrapper>
                <LessonConferenceStyled
                    ref={this.lessonConferenceRef}
                    lessonId={this.state.lessonId ?? ''}
                    selectedMemberId={this.state.selectedRoomMemberId}
                    onMemberSelect={this.setSelectedRoomMemberId}
                    wsRoomIsConnected={this.state.onlineRoomIsConnected && this.state.onlineRoomHasVideo}
                />
                {
                    (this.props.lessonMaterialsSlideListReadyForShow && this.props.lessonMaterialsSlideList.length > 0)
                    && <MaterialsPlayer/>
                }
                {
                    (this.props.lessonMaterialsSlideListReadyForShow && this.props.lessonMaterialsSlideList.length === 0)
                    && <NoticeBlock>
                    <>
                      <NoticeBlockTitle><Trans>Материала к изучению пока нет</Trans></NoticeBlockTitle>
                      <NoticeBlockText><Trans>Учитель пока не добавил материалы к изучению на
                        урок</Trans></NoticeBlockText>
                    </>
                  </NoticeBlock>
                }
                {
                    (this.props.lessonMaterialsLoadingState === LoadingStateEnum.ERROR)
                    && <ErrorLoadingContent retryBtnClick={() => {
                        if (this.state.lessonId) {
                            this.props.loadLessonMaterialsList(this.state.lessonId, false);
                        } else {
                            this.props.rebootApp();
                        }
                    }}/>
                }
                {
                    (this.props.lessonMaterialsLoadingState === LoadingStateEnum.INIT_LOADING)
                    && <PageLoaderWrapper><DefaultLoader/></PageLoaderWrapper>
                }
            </PageMainContentWrapper>
            <RoomControlBlock showLower={
                this.state.onlineRoomIsConnected
                && this.state.onlineRoomHasVideo
                && !this.state.videoAreaInViewport
                && this.mediaQueryLarge.matches
            }
            />
        </>;
    }

    render() {
        if (this.state.videoRoomError !== null) {
            return <ErrorMessage errorType={this.state.videoRoomError}/>
        }

        return <div>
            <LessonPageContextProvider value={{
                lessonId: this.state.lessonId,
                onlineRoomHasVideo: this.state.onlineRoomHasVideo,
                onlineRoomIsConnected: this.state.onlineRoomIsConnected,
                selectedRoomMemberId: this.state.selectedRoomMemberId,
                setSelectedRoomMemberId: this.setSelectedRoomMemberId
            }}>
                <PlayerContextProvider value={{
                    lessonId: this.state.lessonId ?? "",
                    playerId: this.getPlayerId(),
                    slides: (this.props.lessonMaterialsSlideListReadyForShow)
                        ? this.props.lessonMaterialsSlideList
                        : null,
                    slidesContent: this.state.slidesContent,
                    setSlidesContent: this.setSlideContent,
                    selectedSlideId: this.state.selectedSlideId,
                    setSelectedSlideId: this.setSelectedSlideId,
                    selectedSlide: (this.state.selectedSlideIndex !== null && this.props.lessonMaterialsSlideListReadyForShow)
                        ? (this.props.lessonMaterialsSlideList[this.state.selectedSlideIndex] ?? null)
                        : null,
                    selectedSlideIndex: this.state.selectedSlideIndex,
                    setInteractivityConfig: null
                }}>
                    <PageWrapper>
                        {this.content()}
                    </PageWrapper>
                </PlayerContextProvider>
            </LessonPageContextProvider>
        </div>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setRoomConnectionParams: (connectionParams: RoomConnectionParams | null) =>
        dispatch(setRoomConnectionParams(connectionParams)),
    setCameraRequestAccessNow: (value: boolean) =>
        dispatch(setCameraRequestAccessNow(value)),
    rebootApp: () =>
        dispatch(rebootApp()),
    setRoomId: (roomId: string | null) =>
        dispatch(setLessonRoomId(roomId)),
    setRoomConnectionState: (state: LessonRoomStateTypes) =>
        dispatch(setLessonRoomState(state)),
    clearActiveRoomState: () =>
        dispatch(clearRoomState()),
    leaveFromRoomViaRouter: () =>
        dispatch(handleLeaveFromRoomViaRouter()),
    loadLessonRoomMembers: (roomId: string) =>
        dispatch(loadRoomMembersList(roomId)),
    loadLessonMaterialsList: (lessonId: string, silent: boolean) => {
        if (!silent) {
            dispatch(setLoadingState(LoadingStateEnum.INIT_LOADING));
        }

        dispatch(loadLessonMaterialsList(lessonId, false, silent));
    },
    loadSlideWorkData: (params: LoadSlideWorkDataActionParams) => {
        dispatch(loadSlideWorkData(params))
    },
    subscribeToSlideList: (lessonId: string) => {
        dispatch(subscribeToSlideList(lessonId));
    },
    unsubscribeFromSlideList: (lessonId: string) => {
        dispatch(unsubscribeFromSlideList(lessonId))
    },
    setBodyScrollAvailable: (value: boolean) =>
        dispatch(setBodyScrollAvailable(value))
});

const mapStateToProps = (state: ApplicationState) => ({
    userId: (state.user.profileData?.id) ?? null,
    stToken: state.user.stToken,
    apiToken: state.user.sessionToken,
    accessToCameraAllowedInSession: state.app.cameraAccess.allowedInSession,
    accessToCameraRequestingNow: state.app.cameraAccess.requestAccessNow,
    wsStatus: state.app.wsConnectionStatus,
    currentTheme: state.layout.activeTheme,
    lessonMaterialsLoadingState: state.lessonMaterials.loadingState,
    lessonMaterialsSlideList: state.lessonMaterials.slidesList.map((item): PlayerSlideModel => {
        return new PlayerSlideModel(
            item.tmId,
            item.tmName,
            item.contentVersion,
            item.exerciseCount,
            !item.hiddenForStudent,
            item.orderPosition
        );
    }),
    lessonMaterialsSlideListReadyForShow:
        (state.lessonMaterials.loadingState === LoadingStateEnum.SUCCESS)
        || (state.lessonMaterials.loadingState === LoadingStateEnum.SILENT_LOADING)
        || (state.lessonMaterials.loadingState === LoadingStateEnum.SILENT_ERROR),
    roomMembers: roomMembersSelector(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(LessonPage));
