import React from 'react';
import {Alert, Button} from 'antd';
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const AlertStyled = styled(Alert)`
    flex-wrap: wrap;
  
  .ant-alert-action {
    flex-basis: 100%;
    margin-left: 39px;
    margin-top: 10px;
  }
`;

interface ErrorLoadingContentProps {
    title?: string,
    message?: string,
    retryBtnClick: () => void
}

export const ErrorLoadingContent: React.FC<ErrorLoadingContentProps> = (props) => {
    return <div>
        <AlertStyled
            showIcon
            message={(props.title) ? props.title : <Trans>Не удалось загрузить информацию</Trans>}
            description={(props.message) ? props.message :
                <Trans>Проверьте наличие доступа в интернет и повторите попытку загрузки данных</Trans>}
            type={"error"}
            action={
                <Button size="small" danger onClick={props.retryBtnClick}>
                    Повторить попытку загрузки
                </Button>
            }
        />
    </div>
}