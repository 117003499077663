import React from "react";
import styled from "styled-components";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";

const EditorAreaPlaceholderWrapper = styled.div`
    margin: 10px 0;
`;

export const LandingEditorAreaPlaceholder: React.FC = () => {
    return <EditorAreaPlaceholderWrapper>
        <NoticeBlock>
            <NoticeBlockTitle><Trans>Лендинг пока пуст</Trans></NoticeBlockTitle>
            <NoticeBlockText>
                <Trans>Нажмите «Добавить контент», чтобы добавить текст, видео и прочие материалы на лендинг трека.</Trans>
            </NoticeBlockText>
        </NoticeBlock>
    </EditorAreaPlaceholderWrapper>;
}