import React, {useMemo} from "react";
import {
    DtoNextLessonInfoResponseItem
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoNextLessonInfoResponseItem";
import {
    DtoHometaskOverviewResponse
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoHometaskOverviewResponse";
import {BlockWithBorderAndPadding} from "../../../../../../styles/global-elements";
import styled from "styled-components";
import {Item} from "./Item";
import {t, Trans} from "@lingui/macro";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {TimePeriodWithClockIcon} from "../../../../common/TimePeriodWithClockIcon";
import {HometaskModal} from "../../Modals/Hometask/HometaskModal";
import {DateAsString} from "../../../../../../components/Ui/Elements/TextPresenter/DateAsString";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";

const NextLessonBlock = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
    margin-bottom: 24px;
`;

const HomeTaskBlockWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const HomeTaskTextWrapper = styled.div`
    flex-grow: 1;
`;

const ButtonStyled = styled(Button)`
    @media (${({theme}) => theme.media.small}) {
        padding: 12px 24px;
        font-size: 16px;
    }
`;

interface NextLessonAndHomeTaskProps {
    nextLessonInfo: DtoNextLessonInfoResponseItem;
    hometaskInfo: DtoHometaskOverviewResponse | null;
    teacherPersonalComment?: string;
}

export const NextLessonAndHomeTask: React.FC<NextLessonAndHomeTaskProps> = (
    {
        hometaskInfo,
        nextLessonInfo,
        teacherPersonalComment
    }
) => {

    const dateHelper = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);

    const nextLessonDate = useMemo(() => dateHelper.dateFromString(nextLessonInfo.utcStartDateTime, true), [dateHelper, nextLessonInfo.utcStartDateTime]);

    const nextLessonSecondLine = useMemo<React.ReactNode>(() => {
        let weekDayName = dateHelper.formatAsDateWithWeek(nextLessonDate);

        weekDayName = weekDayName.charAt(0).toUpperCase() + weekDayName.slice(1)

        const timeString = dateHelper.timeRangeFromStartAndDuration(nextLessonDate, nextLessonInfo.duration);

        return <>{weekDayName}&nbsp;&nbsp;•&nbsp;&nbsp;<TimePeriodWithClockIcon timeString={timeString}/></>;
    }, [dateHelper, nextLessonDate, nextLessonInfo.duration]);

    const homeTaskBlock = useMemo<React.ReactNode | null>(() => {
        if (hometaskInfo === null || (!hometaskInfo.hasExercises && (hometaskInfo.teacherText === null))) {
            return <Item
                firstLine={t`Домашнее задание не задано`}
                secondLine={''}
            />;
        }

        const hometaskDate = dateHelper.dateFromString(hometaskInfo.lessonDate);

        const hasExercises = (hometaskInfo.exercisesCount > 0);
        const hasSlides = (hometaskInfo.slidesCount > 0);

        let button: JSX.Element = <ButtonStyled btnStyle={BtnStyleEnum.Secondary}><Trans>Посмотреть</Trans></ButtonStyled>;

        if (hasSlides && hasExercises && !hometaskInfo.exercisesCompleted) {
            if (!hometaskInfo.exercisesStarted) {
                button = <ButtonStyled btnStyle={BtnStyleEnum.Primary}><Trans>Сделать</Trans></ButtonStyled>
            } else {
                button = <ButtonStyled btnStyle={BtnStyleEnum.Secondary}><Trans>Продолжить</Trans></ButtonStyled>
            }
        }

        return <HomeTaskBlockWrapper>
            <HomeTaskTextWrapper>
                <Item
                    firstLine={t`Домашнее задание`}
                    secondLine={<span><Trans>за</Trans>&nbsp;<DateAsString date={hometaskDate}/></span>}
                />
            </HomeTaskTextWrapper>
            <div>
                <HometaskModal trigger={button}
                               hometaskDate={hometaskDate}
                               hometaskTeacherText={hometaskInfo.teacherText ?? undefined}
                               teacherComment={teacherPersonalComment}
                               lessonId={(hometaskInfo) ? hometaskInfo.lessonId : undefined}
                               slidesCount={(hometaskInfo) ? hometaskInfo.slidesCount : 0}
                               exerciseCount={(hometaskInfo) ? hometaskInfo.exercisesCount : 0}
                               exerciseStarted={(hometaskInfo) ? hometaskInfo.exercisesStarted : false}
                               exerciseCompleted={(hometaskInfo) ? hometaskInfo.exercisesCompleted : false}
                />
            </div>
        </HomeTaskBlockWrapper>
    }, [dateHelper, hometaskInfo, teacherPersonalComment]);

    return <BlockWithBorderAndPadding>
        <NextLessonBlock>
            <Item
                firstLine={t`Следующее занятие`}
                secondLine={nextLessonSecondLine}
            />
        </NextLessonBlock>
        {homeTaskBlock}
    </BlockWithBorderAndPadding>
}