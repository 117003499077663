import React from "react";
import styled from "styled-components";
import {
    ActualParamsDto
} from "../../../../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {StudentRateSlider} from "../../../../../components/Ui/Elements/StudentRateSlider";
import {StudentRateParamPositionEnum} from "../../../../../../enums/StudentRateParamPositionEnum";

const Wrapper = styled.div``;

interface RateItem {
    positionId: StudentRateParamPositionEnum;
    name: string;
    value: number;
}

interface TeacherRatesBlockProps {
    actualParams: ActualParamsDto;
    onChange: (paramPositionId: StudentRateParamPositionEnum, newValue: number) => void;
    param1Value: number | null;
    param2Value: number | null;
    param3Value: number | null;
    param4Value: number | null;
    param5Value: number | null;
}

interface TeacherRatesBlockState {
    rateItems: RateItem[];
}

export class TeacherRatesBlock extends React.Component<TeacherRatesBlockProps, TeacherRatesBlockState> {
    constructor(props: Readonly<TeacherRatesBlockProps> | TeacherRatesBlockProps) {
        super(props);

        this.state = {
            rateItems: this.loadStateFromProps()
        }
    }

    protected loadStateFromProps(): RateItem[] {
        const result: RateItem[] = [];

        result.push({
            positionId: StudentRateParamPositionEnum.FIRST,
            name: this.props.actualParams.first,
            value: (this.props.param1Value) ?? 0
        });

        if (this.props.actualParams.second !== null) {
            result.push({
                positionId: StudentRateParamPositionEnum.SECOND,
                name: this.props.actualParams.second,
                value: (this.props.param2Value) ?? 0
            })
        }

        if (this.props.actualParams.third !== null) {
            result.push({
                positionId: StudentRateParamPositionEnum.THIRD,
                name: this.props.actualParams.third,
                value: (this.props.param3Value) ?? 0
            })
        }

        if (this.props.actualParams.fourth !== null) {
            result.push({
                positionId: StudentRateParamPositionEnum.FOURTH,
                name: this.props.actualParams.fourth,
                value: (this.props.param4Value) ?? 0
            })
        }

        if (this.props.actualParams.fifth !== null) {
            result.push({
                positionId: StudentRateParamPositionEnum.FIFTH,
                name: this.props.actualParams.fifth,
                value: (this.props.param5Value) ?? 0
            })
        }

        return result;
    }

    render() {
        return <Wrapper>
            {
                this.state.rateItems.map((item) => {
                    return <StudentRateSlider key={item.positionId}
                                              paramName={item.name}
                                              value={item.value}
                                              paramPositionId={item.positionId}
                                              onChange={
                                                  (newValue: number) => this.props.onChange(item.positionId, newValue)
                                              }
                    />
                })
            }
        </Wrapper>;
    }
}
