import {
    CSSProperties,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {MatchItem} from "./Item";
import {ExerciseWrapper, LeftCol, RightCol} from "./styles";
import classNames from "classnames";
import {useWindowSize} from "../../../../../services/hooks/useWindowSize";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {ArrayHelpers} from "../../../../../helpers/ArrayHelpers";
import {ElementRenderState, IItemIdWithSide} from "./Types";
import {Canvas, CanvasProps, Line} from "./Canvas";
import styled from "styled-components";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";
import {isEqual} from "lodash";
import {Sides} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/SlidesEnum";
import {ElementData} from "../../../SlidePlayerEditorCommonParts/elements/ExerciseMatch/ElementDataType";
import {EditorItemInteractivityConfigWithParentItem} from "../../../SlidePlayerEditorCommonParts/EditorData";

const COL_DELIMITER = 30;
const RIGHT_PADDING = 60;
const ROW_DELIMITER = 10;

type TopPositionsList = { [id: string]: number };

const CanvasStyled = styled(Canvas)<CanvasProps>`
  position: absolute;
  top: 0;
  left: 0;
`;

interface IMatchItemAdditionalData {
    leftSideSort: string[],
    rightSideSort: string[],
    successfullyMatchedVariants: string[]
}

interface ExerciseMatchProps extends IElementProps<CustomElement[]> {
}

interface ExerciseMatchRefMethods extends IElementRefMethods {
}

export const ExerciseMatch = forwardRef<ExerciseMatchRefMethods, ExerciseMatchProps>(
    (props, ref) => {

        const {elementData} = props;

        const windowSize = useWindowSize();

        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);
        const {showCorrectAnswers} = slideItemWorkContext;

        const wrapperRef = useRef<HTMLDivElement | null>(null);
        const elementLeftSideRefs = useRef<{ [id: string]: HTMLDivElement | null }>({});
        const elementRightSideRefs = useRef<{ [id: string]: HTMLDivElement | null }>({});

        const [renderState, setRenderState] = useState<ElementRenderState>(ElementRenderState.START);
        const [lastWrapperWidth, setLastWrapperWidth] = useState<number | null>(null);

        const [leftSideWidth, setLeftSideWidth] = useState<number | null>(null);
        const [rightSideWidth, setRightSideWidth] = useState<number | null>(null);

        const [leftSideLeftPos,] = useState<number | null>(0);
        const [rightSideLeftPos, setRightSideLeftPos] = useState<number | null>(null);

        const [showErrorAnimationInExercise, setShowErrorAnimationInExercise] = useState<IItemIdWithSide | null>(null);

        const [fixedWrapperHeight, setFixedWrapperHeight] = useState<number | null>(null);

        const [leftElementsTopPositions, setLeftElementsTopPositions] = useState<TopPositionsList>({});
        const [rightElementsTopPositions, setRightElementsTopPositions] = useState<TopPositionsList>({});

        const [allowedTransitions, setAllowedTransitions] = useState<boolean>(false);

        const [canvasBlueLine, setCanvasBlueLine] = useState<Line | null>(null);
        const [canvasGreenLines, setCanvasGreenLines] = useState<Line[]>([]);

        const [blueLineTo, setBlueLineTo] = useState<IItemIdWithSide | null>(null);

        const [selectedItem, setSelectedItem] = useState<IItemIdWithSide | null>(null);

        useEffect(() => {
            if (wrapperRef.current) {
                if (wrapperRef.current.clientWidth !== lastWrapperWidth) {
                    setRenderState(ElementRenderState.START);
                }
            }
        }, [lastWrapperWidth, windowSize]);

        const allExercises = useMemo<ElementData[]>(() => {
            return elementData.data.map((item) => item as unknown as ElementData);
        }, [elementData]);

        const leftSideItems = useMemo<ElementData[]>(() => {
            return elementData.data.map((item) => {
                let newItem = {...item as unknown as ElementData};

                newItem.rightSideText = "";

                return newItem;
            });
        }, [elementData]);

        const rightSideItems = useMemo<ElementData[]>(() => {
            return elementData.data.map((item) => {
                let newItem = {...item as unknown as ElementData};

                newItem.leftSideText = "";

                return newItem;
            });
        }, [elementData]);

        // Получить дополнительную информацию истории работы ученика над элементом слайда
        const matchItemAdditionalData = useMemo<IMatchItemAdditionalData>(() => {

            if (
                (slideItemWorkContext.slideItemWorkData.additionalData === null)
                || (slideItemWorkContext.slideItemWorkData.additionalData === "")
            ) {
                // Ничего нет. Сформируем данные с нуля.

                if (showCorrectAnswers === true) {
                    // Если нужно показать сразу верный вариант
                    const data: IMatchItemAdditionalData = {
                        leftSideSort: leftSideItems.map(item => item.id),
                        rightSideSort: rightSideItems.map(item => item.id),
                        successfullyMatchedVariants: leftSideItems.map(item => item.id)
                    }

                    return data;
                }

                const data: IMatchItemAdditionalData = {
                    leftSideSort: ArrayHelpers.shuffleArray(leftSideItems.map(item => item.id)),
                    rightSideSort: ArrayHelpers.shuffleArray(rightSideItems.map(item => item.id)),
                    successfullyMatchedVariants: []
                }

                return data;
            }

            return JSON.parse(slideItemWorkContext.slideItemWorkData.additionalData);
        }, [slideItemWorkContext.slideItemWorkData, leftSideItems, rightSideItems, showCorrectAnswers]);

        const correctAnswersIndexedById = useMemo(() => {
            const result: { [id: string]: boolean } = {};

            matchItemAdditionalData.successfullyMatchedVariants.forEach((item) => {
                result[item] = true;
            });

            return result;
        }, [matchItemAdditionalData]);

        const calcItemsWidth = useCallback(() => {
            if ((!elementLeftSideRefs) || (!elementRightSideRefs) || (!wrapperRef.current)) {
                return;
            }

            let leftSideMaxWidth = 0;
            let rightSideMaxWidth = 0;

            for (let index = 0; index < allExercises.length; index++) {
                const element = elementLeftSideRefs.current[allExercises[index].id];

                if (element !== null) {
                    let itemWidth = element.clientWidth;

                    if (leftSideMaxWidth < itemWidth) {
                        leftSideMaxWidth = itemWidth;
                    }
                }
            }

            for (let index = 0; index < allExercises.length; index++) {
                const element = elementRightSideRefs.current[allExercises[index].id];

                if (element !== null) {
                    let itemWidth = element.clientWidth;

                    if (rightSideMaxWidth < itemWidth) {
                        rightSideMaxWidth = itemWidth;
                    }
                }
            }

            const totalMaxWidth = leftSideMaxWidth + rightSideMaxWidth;
            const proportion = (wrapperRef.current.clientWidth - COL_DELIMITER - RIGHT_PADDING) / totalMaxWidth;

            leftSideMaxWidth = leftSideMaxWidth * proportion;
            rightSideMaxWidth = rightSideMaxWidth * proportion;

            setLeftSideWidth(leftSideMaxWidth);
            setRightSideWidth(rightSideMaxWidth);
            setRightSideLeftPos(leftSideMaxWidth + COL_DELIMITER);

            setLastWrapperWidth(wrapperRef.current.clientWidth);

        }, [allExercises]);

        const calcLeftElementsTopPositions = useCallback((): TopPositionsList => {
            const result: TopPositionsList = {};

            let currentTopPosition = 0;

            matchItemAdditionalData.leftSideSort.forEach((itemId, index) => {
                const currentDivElement = elementLeftSideRefs.current[itemId];

                if (!currentDivElement) {
                    return;
                }

                result[itemId] = currentTopPosition;

                currentTopPosition += currentDivElement.clientHeight + ROW_DELIMITER;
            });

            return result;
        }, [matchItemAdditionalData]);

        const calcRightElementsTopPositions = useCallback((): TopPositionsList => {
            const result: TopPositionsList = {};

            let currentTopPosition = 0;

            matchItemAdditionalData.rightSideSort.forEach((itemId, index) => {
                const currentDivElement = elementRightSideRefs.current[itemId];

                if (!currentDivElement) {
                    return;
                }

                result[itemId] = currentTopPosition;

                currentTopPosition += currentDivElement.clientHeight + ROW_DELIMITER;
            });

            return result;
        }, [matchItemAdditionalData]);

        const wrapperHeight = useMemo<number | null>(() => {
            if ((!elementRightSideRefs.current) || (!elementLeftSideRefs.current) || (renderState !== ElementRenderState.READY)) {
                return null;
            }

            const leftColLowerItemId = matchItemAdditionalData.leftSideSort[matchItemAdditionalData.leftSideSort.length - 1];
            const leftColLowerDiv = elementLeftSideRefs.current[leftColLowerItemId];
            const leftColMaxTopPosition = leftElementsTopPositions[leftColLowerItemId];
            const leftColHeight = leftColMaxTopPosition + (
                (leftColLowerDiv)
                    ? leftColLowerDiv.clientHeight
                    : 0
            );

            const rightColLowerItemId = matchItemAdditionalData.rightSideSort[matchItemAdditionalData.rightSideSort.length - 1];
            const rightColLowerDiv = elementRightSideRefs.current[rightColLowerItemId];
            const rightColMaxTopPosition = rightElementsTopPositions[rightColLowerItemId];
            const rightColHeight = rightColMaxTopPosition + (
                (rightColLowerDiv)
                    ? rightColLowerDiv.clientHeight
                    : 0
            );

            return (leftColHeight > rightColHeight) ? leftColHeight : rightColHeight;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [leftElementsTopPositions, matchItemAdditionalData, matchItemAdditionalData, renderState, rightElementsTopPositions]);

        const wrapperWidth = useMemo<number | null>(() => {
            if ((leftSideWidth === null) || (rightSideWidth === null) || (renderState !== ElementRenderState.READY)) {
                return null;
            }

            return leftSideWidth + rightSideWidth + COL_DELIMITER;
        }, [leftSideWidth, renderState, rightSideWidth]);

        useEffect(() => {
            if (renderState !== ElementRenderState.CALC_TOP_POSITIONS) {
                return;
            }

            setLeftElementsTopPositions(calcLeftElementsTopPositions);
            setRightElementsTopPositions(calcRightElementsTopPositions);

            setFixedWrapperHeight(null);
            setRenderState(ElementRenderState.READY);
        }, [calcLeftElementsTopPositions, calcRightElementsTopPositions, renderState]);

        useEffect(() => {
            if (renderState !== ElementRenderState.CALC_MAX_WIDTH) {
                return;
            }

            calcItemsWidth();

            setRenderState(ElementRenderState.CALC_TOP_POSITIONS);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [renderState]);

        useEffect(() => {
            if (renderState !== ElementRenderState.START) {
                return;
            }

            setRenderState(ElementRenderState.CALC_MAX_WIDTH);
        }, [renderState]);

        useEffect(() => {
            if (
                (renderState === ElementRenderState.READY)
                && (slideItemWorkContext.slideItemWorkData.additionalData)
                && (slideItemWorkContext.readOnly)
            ) {
                setFixedWrapperHeight(wrapperHeight);

                setRenderState(ElementRenderState.CALC_TOP_POSITIONS);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },[slideItemWorkContext.readOnly, slideItemWorkContext.slideItemWorkData, renderState]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const wrapperStyles = useMemo<CSSProperties>(() => {
            if (fixedWrapperHeight !== null) {
                return {
                    height: fixedWrapperHeight.toString(10) + 'px'
                };
            }

            if (
                (wrapperHeight === null)
                || (wrapperWidth === null)
                || (renderState !== ElementRenderState.READY)
            ) {
                return {};
            }

            return {
                height: wrapperHeight.toString(10) + 'px'
            }
        }, [fixedWrapperHeight, renderState, wrapperHeight, wrapperWidth]);

        const getExerciseHistoryData = useCallback((exerciseId: string): ExerciseWorkData | null => {
            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[exerciseId];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
        }, [slideItemWorkContext.slideItemWorkData]);

        const onItemClick = useCallback((item: IItemIdWithSide) => {
            if (selectedItem === null) {
                setSelectedItem(item);

                return;
            }

            if (selectedItem.side === item.side) {
                if (selectedItem.id === item.id) {
                    setSelectedItem(null);
                } else {
                    setSelectedItem(item);
                }

                return;
            }

            setSelectedItem(null);
            setCanvasBlueLine(null);
            setBlueLineTo(null);

            // Пользователь соединяет ответы
            let leftItem: IItemIdWithSide;
            let rightItem: IItemIdWithSide;

            if (item.side === Sides.LEFT) {
                leftItem = item;
                rightItem = selectedItem;
            } else {
                leftItem = selectedItem;
                rightItem = item;
            }

            if (
                (slideItemWorkContext.itemId === null)
                || (slideItemWorkContext.slideId === null)
                || (playerContext.selectedSlide === null)
            ) {
                return;
            }

            const soundPlayer = container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);

            // Рассчитываем значения упущенной выгоды и награды
            const currentExerciseHistoryData = getExerciseHistoryData(rightItem.id);

            let award = 0;
            let missedAward = 0;
            let historyItemsCount = 0;

            if (currentExerciseHistoryData) {
                historyItemsCount = currentExerciseHistoryData.inputHistory.length;
                award = currentExerciseHistoryData.award;
                missedAward = currentExerciseHistoryData.missedAward;
            }

            const answerIsCorrect = leftItem.id === rightItem.id;

            if (answerIsCorrect) {
                // Был дан верный ответ - присуждаем все доступные баллы
                award = Math.ceil(
                    MAX_AWARD_SCORE - missedAward
                );

                soundPlayer.playSound(SoundsEnum.RIGHT);
            } else {
                // Если сейчас был дан ошибочный ответ

                // Сколько ещё доступно баллов
                const availableScore = MAX_AWARD_SCORE - missedAward;

                // Посчитаем, сколько ещё ошибочных ответов есть, вычитая из общего количество
                // количество уже имеющихся в истории и один ответ, который точно верный
                const availableWrongAnswerCount
                    = allExercises.length
                    - matchItemAdditionalData.successfullyMatchedVariants.length
                    - historyItemsCount - 1;

                missedAward += Math.ceil(availableScore / availableWrongAnswerCount);

                soundPlayer.playSound(SoundsEnum.ERROR);

                setShowErrorAnimationInExercise({
                    side: item.side,
                    id: item.id
                });
            }

            if (missedAward > MAX_AWARD_SCORE) {
                missedAward = MAX_AWARD_SCORE;
            }

            if (award > MAX_AWARD_SCORE) {
                award = MAX_AWARD_SCORE;
            }

            if (answerIsCorrect) {
                // Посчитаем, сколько верных ответов есть, вычитая из общего количества
                // количество уже имеющихся в истории и один ответ, который точно верный
                if (matchItemAdditionalData.successfullyMatchedVariants.length === matchItemAdditionalData.leftSideSort.length - 1) {
                    // Передаём информацию о действия, которые нужно выполнить
                    if ((playerContext.setInteractivityConfig !== null) && (elementData.interactivityConfig !== null)) {
                        playerContext.setInteractivityConfig(
                            new EditorItemInteractivityConfigWithParentItem(elementData.id, elementData.interactivityConfig)
                        );
                    }
                }
            }

            slideItemWorkContext.saveExerciseAnswer(
                rightItem.id,
                leftItem.id,
                award,
                missedAward,
                playerContext.selectedSlide.exercisesCount,
                answerIsCorrect
            );

            const getAdditionalData = (): string | null => {
                // Сохранение дополнительных данных
                if (leftItem.id !== rightItem.id) {

                    // Если давался ответ с ошибкой, то если сортировка не зафиксирована, фиксируем сортировку
                    if (slideItemWorkContext.slideItemWorkData.additionalData === null) {
                        return JSON.stringify(matchItemAdditionalData);
                    }

                    // Если сортировка была сохранена, ничего не меняем
                    return slideItemWorkContext.slideItemWorkData.additionalData;
                }

                const newMatchItemAdditionalData = {...matchItemAdditionalData};

                // 1. Поменять сортировку объектов, чтобы текущие варианты встали над предыдущими верными
                // 1.1 - Правый столбец
                const currentIndexInRightSort = newMatchItemAdditionalData.rightSideSort.indexOf(rightItem.id);
                // const currentIndexInLeftSort = newMatchItemAdditionalData.leftSideSort.indexOf(leftItem.id);

                if (currentIndexInRightSort < 0) {
                    // Почему-то не нашлось. Ничего не делаем
                    return slideItemWorkContext.slideItemWorkData.additionalData;
                }

                // Рассчитываем - куда установить значение
                let targetValueIndexInRightSort =
                    (newMatchItemAdditionalData.successfullyMatchedVariants.length > 0)
                        ? newMatchItemAdditionalData.rightSideSort.findIndex(
                            (item) => newMatchItemAdditionalData.successfullyMatchedVariants.includes(item)
                        )
                        : newMatchItemAdditionalData.rightSideSort.length;

                if (targetValueIndexInRightSort < 0) {
                    targetValueIndexInRightSort = newMatchItemAdditionalData.rightSideSort.length;
                }

                // Если нужно - перемещаем элементы
                if (targetValueIndexInRightSort !== currentIndexInRightSort) {
                    newMatchItemAdditionalData.rightSideSort.splice(targetValueIndexInRightSort, 0, rightItem.id);

                    // Удаляем с текущей позиции
                    newMatchItemAdditionalData.rightSideSort.splice(currentIndexInRightSort, 1);
                }


                // 1.2 - Левый столбец
                const currentIndexInLeftSort = newMatchItemAdditionalData.leftSideSort.indexOf(leftItem.id);

                if (currentIndexInLeftSort < 0) {
                    // Почему-то не нашлось. Ничего не делаем
                    return slideItemWorkContext.slideItemWorkData.additionalData;
                }

                // Рассчитываем - куда установить значение
                let targetValueIndexInLeftSort =
                    (newMatchItemAdditionalData.successfullyMatchedVariants.length > 0)
                        ? newMatchItemAdditionalData.leftSideSort.findIndex(
                            (item) => newMatchItemAdditionalData.successfullyMatchedVariants.includes(item)
                        )
                        : newMatchItemAdditionalData.leftSideSort.length;

                if (targetValueIndexInLeftSort < 0) {
                    targetValueIndexInLeftSort = newMatchItemAdditionalData.leftSideSort.length;
                }

                // Если нужно - перемещаем элементы
                if (targetValueIndexInLeftSort !== currentIndexInLeftSort) {
                    newMatchItemAdditionalData.leftSideSort.splice(targetValueIndexInLeftSort, 0, leftItem.id);

                    // Удаляем с текущей позиции
                    newMatchItemAdditionalData.leftSideSort.splice(currentIndexInLeftSort, 1);
                }

                // 2. Добавить верные ответы в список верных ответов
                newMatchItemAdditionalData.successfullyMatchedVariants.push(rightItem.id);

                // 3. Вернуть это
                return JSON.stringify(newMatchItemAdditionalData);
            }

            const newAdditionalData = getAdditionalData();

            if (!isEqual(slideItemWorkContext.slideItemWorkData.additionalData, newAdditionalData)) {
                slideItemWorkContext.saveAdditionalData(
                    rightItem.id,
                    newAdditionalData
                )
            }
            // Конец сохранения дополнительных данных

            setFixedWrapperHeight(wrapperHeight);
            setRenderState(ElementRenderState.CALC_TOP_POSITIONS);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedItem, wrapperHeight]);

        const onHoverStart = useCallback((item: IItemIdWithSide) => {
            if (slideItemWorkContext.readOnly === true) {
                return;
            }

            if (selectedItem === null) {
                if (canvasBlueLine !== null) {
                    setCanvasBlueLine(null);
                    setBlueLineTo(null);
                }

                return;
            }

            if (item.side === selectedItem.side) {
                if (canvasBlueLine !== null) {
                    setCanvasBlueLine(null);
                    setBlueLineTo(null);
                }

                return;
            }

            // Пользователь затевает соединить ответы
            const wrapperDiv = wrapperRef.current;
            let leftDiv, rightDiv;

            if (selectedItem.side === Sides.LEFT) {
                leftDiv = elementLeftSideRefs.current[selectedItem.id];
                rightDiv = elementRightSideRefs.current[item.id];
            } else {
                leftDiv = elementLeftSideRefs.current[item.id];
                rightDiv = elementRightSideRefs.current[selectedItem.id];
            }

            if ((leftDiv === null) || (rightDiv === null) || (wrapperDiv === null)) {
                return;
            }

            const wrapperDivRect = wrapperDiv.getBoundingClientRect();
            const leftDivRect = leftDiv.getBoundingClientRect();
            const rightDivRect = rightDiv.getBoundingClientRect();

            setCanvasBlueLine({
                from: {
                    X: leftDivRect.left - wrapperDivRect.left + leftDivRect.width,
                    Y: leftDivRect.top - wrapperDivRect.top + Math.round(leftDivRect.height / 2)
                },
                to: {
                    X: rightDivRect.left - wrapperDivRect.left,
                    Y: rightDivRect.top - wrapperDivRect.top + Math.round(rightDivRect.height / 2)
                }
            });

            setBlueLineTo({
                side: item.side,
                id: item.id
            });
        }, [canvasBlueLine, selectedItem, slideItemWorkContext.readOnly]);

        const onHoverEnd = useCallback((item: IItemIdWithSide) => {
            if (canvasBlueLine !== null) {
                setCanvasBlueLine(null);
                setBlueLineTo(null);
            }
        }, [canvasBlueLine]);

        const drawGreenLines = useCallback(() => {
            // Рассчитываем количество и положение зелёных линий canvas
            const wrapperDiv = wrapperRef.current;

            if (wrapperDiv === null) {
                return;
            }

            const wrapperDivRect = wrapperDiv.getBoundingClientRect();

            if (matchItemAdditionalData.successfullyMatchedVariants.length === 0) {
                if (canvasGreenLines.length > 0) {
                    setCanvasGreenLines([]);
                }

                return;
            }

            const newGreenLinesList: Line[] = [];

            matchItemAdditionalData.successfullyMatchedVariants.forEach((itemId) => {
                let leftDiv, rightDiv;

                leftDiv = elementLeftSideRefs.current[itemId];
                rightDiv = elementRightSideRefs.current[itemId];

                if ((leftDiv === null) || (rightDiv === null)) {
                    return;
                }

                const leftDivRect = leftDiv.getBoundingClientRect();
                const rightDivRect = rightDiv.getBoundingClientRect();

                if (
                    (leftElementsTopPositions[itemId] !== leftDivRect.top - wrapperDivRect.top)
                    || (rightElementsTopPositions[itemId] !== rightDivRect.top - wrapperDivRect.top)
                ) {
                    return;
                }

                newGreenLinesList.push({
                    from: {
                        X: leftDivRect.left - wrapperDivRect.left + leftDivRect.width,
                        Y: leftDivRect.top - wrapperDivRect.top + Math.round(leftDivRect.height / 2)
                    },
                    to: {
                        X: rightDivRect.left - wrapperDivRect.left,
                        Y: rightDivRect.top - wrapperDivRect.top + Math.round(rightDivRect.height / 2)
                    }
                });
            });

            setCanvasGreenLines(newGreenLinesList);
        }, [canvasGreenLines, leftElementsTopPositions, matchItemAdditionalData, rightElementsTopPositions]);

        useEffect(() => {
            if (allowedTransitions) {
                return;
            }

            if (renderState === ElementRenderState.READY) {
                setAllowedTransitions(true);
                drawGreenLines();
            }
        }, [allowedTransitions, drawGreenLines, renderState]);

        const onTransitionEnd = useCallback((item: IItemIdWithSide) => {
            if (!correctAnswersIndexedById[item.id]) {
                return;
            }

            drawGreenLines();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [drawGreenLines]);

        return <ExerciseWrapper
            className={
                classNames(renderState === ElementRenderState.READY && "ready")
            }
            ref={wrapperRef}
            style={wrapperStyles}
        >
            {
                (wrapperRef.current) && <CanvasStyled
                    width={wrapperRef.current.clientWidth}
                    height={(fixedWrapperHeight) ?? ((wrapperHeight) ?? 0)}
                    greenLines={canvasGreenLines}
                    blueLine={canvasBlueLine}/>
            }
            <LeftCol>
                {
                    leftSideItems.map((exercise) => {
                        return <MatchItem key={exercise.id}
                                          ref={ref => elementLeftSideRefs.current[exercise.id] = ref}
                                          id={exercise.id}
                                          side={Sides.LEFT}
                                          text={exercise.leftSideText}
                                          width={leftSideWidth}
                                          leftPos={leftSideLeftPos}
                                          renderState={renderState}
                                          topPosition={(leftElementsTopPositions[exercise.id]) ?? 0}
                                          onClick={onItemClick}
                                          selected={!!(selectedItem && selectedItem.side === Sides.LEFT && selectedItem.id === exercise.id)}
                                          onHoverStart={onHoverStart}
                                          onHoverEnd={onHoverEnd}
                                          asCorrectAnswer={correctAnswersIndexedById[exercise.id]}
                                          onTransitionEnd={onTransitionEnd}
                                          showErrorAnimationInExercise={showErrorAnimationInExercise}
                                          setShowErrorAnimationInExercise={setShowErrorAnimationInExercise}
                                          allowedTransitions={allowedTransitions}
                                          blueLineTo={(blueLineTo !== null) && (exercise.id === blueLineTo.id) && (blueLineTo.side === Sides.LEFT)}
                        />
                    })
                }
            </LeftCol>
            <RightCol>
                {
                    rightSideItems.map((exercise) => {
                        return <MatchItem key={exercise.id}
                                          ref={ref => elementRightSideRefs.current[exercise.id] = ref}
                                          id={exercise.id}
                                          side={Sides.RIGHT}
                                          text={exercise.rightSideText}
                                          width={rightSideWidth}
                                          leftPos={rightSideLeftPos}
                                          renderState={renderState}
                                          topPosition={(rightElementsTopPositions[exercise.id]) ?? 0}
                                          onClick={onItemClick}
                                          selected={!!(selectedItem && selectedItem.side === Sides.RIGHT && selectedItem.id === exercise.id)}
                                          onHoverStart={onHoverStart}
                                          onHoverEnd={onHoverEnd}
                                          asCorrectAnswer={correctAnswersIndexedById[exercise.id]}
                                          onTransitionEnd={onTransitionEnd}
                                          showErrorAnimationInExercise={showErrorAnimationInExercise}
                                          setShowErrorAnimationInExercise={setShowErrorAnimationInExercise}
                                          allowedTransitions={allowedTransitions}
                                          blueLineTo={(blueLineTo !== null) && (exercise.id === blueLineTo.id) && (blueLineTo.side === Sides.RIGHT)}
                                          noPrograssBar={slideItemWorkContext.showCorrectAnswers}
                        />
                    })
                }
            </RightCol>
        </ExerciseWrapper>;
    }
);

ExerciseMatch.displayName = 'ExerciseMatch';