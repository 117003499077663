import React from 'react';

import {Action, ActionProps} from './Action';
import {ReactComponent as CrossSvg} from "../../../../Ui/Svg/CloseCross.svg";
import styled from "styled-components";

const ActionStyled = styled(Action)<ActionProps>`
  cursor: pointer;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 1 !important;
  }
`;

export const Remove: React.FC<ActionProps> = (props) => {
    return (
        <ActionStyled
            className={props.className}
            {...props}
        >
            <CrossSvg/>
        </ActionStyled>
    );
}