import {IsBoolean, IsNotEmpty, IsNumber, IsString} from "class-validator";
import {DtoGroupCompiledSchedule} from "./DtoGroupCompiledSchedule";
import {DtoGroupBase} from "./DtoGroupBase";

/**
 * DTO элемента списка групп
 */
export class DtoGroupListItem extends DtoGroupBase {
    @IsNotEmpty()
    @IsNumber()
    courseId: number;

    @IsNotEmpty()
    @IsString()
    courseNominativeName: string;

    @IsNotEmpty()
    @IsString()
    teacherName: string;

    @IsNumber()
    debtorsCount: number;

    @IsBoolean()
    deleted: boolean;

    weekSchedule: DtoGroupCompiledSchedule | null;
}
