import React from "react";
import {AudioRoomParticipantItem} from "../../../../../components/JanusClient/AudioBridge/Types";

export interface IAudioRoomStudentListActionsContext {
    setJoinedAudioStreams: (userIds: AudioRoomParticipantItem[]) => void;
    setLeaveAudioStreams: (userIds: string[]) => void;
}

export const AudioRoomStudentListActionsContext = React.createContext<IAudioRoomStudentListActionsContext>({
    setJoinedAudioStreams: (userIds: AudioRoomParticipantItem[]) => {
    },
    setLeaveAudioStreams: (userIds: string[]) => {
    }
});

export const IAudioRoomStudentListActionsContextProvider = AudioRoomStudentListActionsContext.Provider;
