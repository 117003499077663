import {InitialCustomizerRequestData} from "./InitialCustomizerRequestData";
import React from "react";

export interface IInitialCustomizerPageContext {
    formData: InitialCustomizerRequestData;
    setFormData: React.Dispatch<React.SetStateAction<InitialCustomizerRequestData>>;
    saveData: (formData: InitialCustomizerRequestData) => Promise<void>;
}

export const InitialCustomizerPageContext = React.createContext<IInitialCustomizerPageContext>({
    formData: new InitialCustomizerRequestData(),
    setFormData: () => {

    },
    saveData: () => Promise.resolve()
});

export const InitialCustomizerPageContextProvider = InitialCustomizerPageContext.Provider;