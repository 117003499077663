import {forwardRef, useImperativeHandle} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {RichTextReader} from "../../RichTextReader";
import {IBaseElement} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";

const NumberedListStyled = styled.ol`
  padding-left: 0;
  list-style-position: inside;
  margin-bottom: 0;
`;

export const NumberedList = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IBaseElement>>(
    (props, ref) => {

        const {element, ...otherProps} = props;

        useImperativeHandle(ref, () => ({}));

        return <NumberedListStyled>
            <RichTextReader elements={element.children}  {...otherProps}/>
        </NumberedListStyled>;
    }
);

NumberedList.displayName = 'NumberedList';