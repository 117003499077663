import {instanceCachingFactory} from 'tsyringe';
import {IDeviceOrientation} from "../components/DeviceOrientation/IDeviceOrientation";
import {DeviceOrientation} from "../components/DeviceOrientation/DeviceOrientation";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";

export default instanceCachingFactory<IDeviceOrientation>((conatiner) => {
    return new DeviceOrientation(
        conatiner.resolve<ILogger>(DiTokens.LOGGER)
    );
});
