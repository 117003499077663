import * as React from "react";
import styled from "styled-components";
import {ReactComponent as PlusSvg} from "../../../../../components/Ui/Svg/Plus.svg";
import {Trans} from "@lingui/macro";
import {ModalResultType} from "../organizations-list/modal";
import {TmLessonModal} from "./modal";
import {ItemStyled} from "../../../../../components/Sortable/components/Item/Item";
import {DtoTmLesson} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";

export const AddLessonItem = styled(ItemStyled)`
  border-style: dashed;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 5px 15px 5px 10px;

  cursor: pointer;
  user-select: none;
  transition: border-color 0.2s ease;

  &:hover {
    border-color: ${p => p.theme.colors.backgroundInverse};
  }
`;

const PlusIcon = styled(PlusSvg)`
  color: ${({theme}) => theme.colors.textPrimary};
  min-width: 40px;
`;

const PlusText = styled.span`
  color: ${({theme}) => theme.colors.textPrimary};
  flex-grow: 1;

  overflow: hidden;
  text-overflow: ellipsis;
`;

interface CreateNewLessonItemProps {
    sectionId: string;
    onItemCreated: (data: DtoTmLesson) => void
}

export const CreateNewLessonItem: React.FC<CreateNewLessonItemProps> = (props) => {
    const onResult = (action: ModalResultType, data: DtoTmLesson | null) => {
        if (data !== null) {
            props.onItemCreated(data);
        }
    }

    return (
        <TmLessonModal
            sectionId={props.sectionId}
            lessonItem={null}
            result={onResult}
            trigger={
                <AddLessonItem>
                    <PlusIcon/>
                    <PlusText><Trans>Добавить урок</Trans></PlusText>
                </AddLessonItem>
            }/>
    );
}