import React from "react";
import styled from "styled-components";
import {BlockAdditionalTextCss, RegularTextCss} from "../../../../styles/global-elements";
import {RouterLink} from "../Link";

const FirstLineStyled = styled.div`
    ${RegularTextCss};

    font-weight: normal;
    color: ${({theme}) => theme.colors.textPrimary};
`;

const SecondLineStyled = styled.div`
    ${BlockAdditionalTextCss};

    margin-top: 5px;
`;

const ItemWrapper = styled(RouterLink)`
    display: block;
    margin: 5px 0 20px 0;
    padding: 5px;
    cursor: pointer;

    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: ${({theme}) => theme.colors.backgroundPrimary};
    transition: border-left-color 0.3s ease;

    &:hover {
        border-left-color: ${({theme}) => theme.colors.backgroundInverse};
    }
`;

interface ListItemProps {
    link: string;
    firstLine: React.ReactNode;
    secondLine: React.ReactNode;
}

export const ListItem: React.FC<ListItemProps> = (
    {
        link,
        firstLine,
        secondLine
    }
) => {
    return <ItemWrapper to={link}>
        <FirstLineStyled>{firstLine}</FirstLineStyled>
        {
            (secondLine)
            && <SecondLineStyled>{secondLine}</SecondLineStyled>
        }
    </ItemWrapper>
}