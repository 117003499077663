import {Editor, Element as SlateElement, Transforms} from "slate";
import {LIST_TYPES} from "../Enums";
import {alignTypeIsActive} from "../CommonMethods";
import {AlignTypes} from "../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

export const toggleAlign = (editor: Editor, alignType: AlignTypes) => {
    const isActive = alignTypeIsActive(editor, alignType);

    Transforms.unwrapNodes(editor, {
        match: (node) => {
            if (Editor.isEditor(node)) {
                return false;
            }

            if (!SlateElement.isElement(node)) {
                return false;
            }

            if (!LIST_TYPES.includes(node.type)) {
                return false;
            }

            return !(Object.values(AlignTypes) as Array<string>).includes(alignType);
        },
        split: true,
    });

    let newProperties: Partial<SlateElement>;

    newProperties = {
        align: isActive ? undefined : alignType,
    }

    Transforms.setNodes<SlateElement>(editor, newProperties);
}
