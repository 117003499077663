import React, {useContext} from "react";
import {Trans, t} from "@lingui/macro";
import {PageSubtitleSmallMargin, RegularText} from "../../../../../styles/global-elements";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import styled from "styled-components";
import {LinkAsButton} from "../../../../../components/Ui/Elements/LinkAsButton";
import {BtnStyleEnum} from "../../../../../components/Ui/Elements/Button";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {PageSectionsEnum as NewStudentPageSectionsEnum} from "../../new-student-page/PageSectionsEnum";
import {PageSectionsEnum} from "../../profile-page/PageSectionsEnum";
import {KbLinks} from "../../../../../components/KbLinksList";
import {KbPageIdsEnum} from "../../../../../../enums/KbPageIdsEnum";

const BlocksList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const TextBlock = styled.div`
  margin-bottom: 30px;
`;

export const NewAgreementFinishSection: React.FC = () => {
    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);

    return <BlocksList>
        <div>
            <TextBlock>
                <PageSubtitleSmallMargin><Trans>Начать урок</Trans></PageSubtitleSmallMargin>
                <RegularText>
                    <Trans>Можно начать урок прямо сейчас или создать черновик ближайшего занятия, чтобы подготовиться.</Trans>
                </RegularText>
            </TextBlock>
            <LinkAsButton btnStyle={BtnStyleEnum.Primary} to={
                RoutesHelper.replaceParams(
                    RoutesList.TEACHER_GROUP_INFO,
                    [
                        {
                            key: 'groupId',
                            value: (pageContext.formData.teamId ?? 0).toString(10)
                        }
                    ]
                )
            }>
                <Trans>Перейти к занятиям</Trans>
            </LinkAsButton>
        </div>

        <div>
            <TextBlock>
                <PageSubtitleSmallMargin><Trans>Посмотреть профиль ученика</Trans></PageSubtitleSmallMargin>
                <RegularText>
                    <Trans>Перейти к профилю ученика и проверить данные</Trans>
                </RegularText>
            </TextBlock>
            <LinkAsButton btnStyle={BtnStyleEnum.Secondary} to={
                RoutesHelper.replaceParams(
                    RoutesList.TEACHER_STUDENT_PROFILE,
                    [
                        {
                            key: 'studentId',
                            value: pageContext.studentGeneralInfoDto.id.toString(10)
                        },
                        {
                            key: 'sectionId',
                            value: PageSectionsEnum.EDUCATION
                        }
                    ]
                )
            }>
                <Trans>К профилю ученика</Trans>
            </LinkAsButton>
        </div>

        <div>
            <TextBlock>
                <PageSubtitleSmallMargin><Trans>Добавить ещё ученика</Trans></PageSubtitleSmallMargin>
            </TextBlock>
            <LinkAsButton btnStyle={BtnStyleEnum.Secondary} to={
                RoutesHelper.replaceParams(
                    RoutesList.TEACHER_CREATE_STUDENT,
                    [
                        {
                            key: 'stepId',
                            value: NewStudentPageSectionsEnum.GENERAL
                        }
                    ]
                )
            }>
                <Trans>Добавить ещё ученика</Trans>
            </LinkAsButton>
        </div>

        <div>
            <TextBlock>
                <PageSubtitleSmallMargin><Trans>Изучить инструменты</Trans></PageSubtitleSmallMargin>
                <RegularText>
                    <Trans>Познакомьтесь с доступными инструментами для онлайн урока и домашних заданий (ссылки
                        откроются в новых вкладках):</Trans>
                </RegularText>
            </TextBlock>

            <KbLinks linksList={[
                {
                    name: t`Электронный учебник - основы`,
                    pageId: KbPageIdsEnum.ABOUT_TEACHING_MATERIALS_CATALOG
                },
                {
                    name: t`Как начать онлайн урок`,
                    pageId: KbPageIdsEnum.HOW_TO_START_ONLINE_LESSON
                },
                {
                    name: t`Видеосвязь на уроке`,
                    pageId: KbPageIdsEnum.VIDEOCONFERENCE_ON_LESSON
                },
                {
                    name: t`Работа со слайдами на уроке`,
                    pageId: KbPageIdsEnum.WORK_WITH_SLIDES_ON_LESSON
                }
            ]}/>
        </div>
    </BlocksList>
}