/**
 * Ошибки выше 1000 - ошибки клиента.
 */
export enum WsResponseStatusEnum {
    OK = 1,
    UNKNOWN_METHOD = 2,
    BAD_REQUEST = 3,
    VALIDATION_ERROR = 4,
    UNAUTHORIZED = 5,
    ACCESS_DENIED = 6,
    NOT_FOUND = 7,
    FLOOD_CONTROL = 8,
    UNKNOWN_ERROR = 100,
    CONNECTION_ERROR = 1000,
}
