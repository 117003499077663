import {IsBoolean, IsNotEmpty, IsOptional, IsString, Length, ValidateNested} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";
import {Type} from "class-transformer";
import {DtoSubjectAreaLevel} from "../SubjetArea/DtoSubjectAreaLevel";
import {DtoTmSection} from "../TmSection/DtoTmSection";
import {DtoTutorProfile} from "../User/DtoTutorProfile";

/**
 * DTO Self-study трека (list item)
 */
export class DtoSelfStudyTrackListItem {
    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string;

    @IsNotEmpty()
    @IsString()
    name: string;

    @IsNotEmpty()
    @IsString()
    slug: string;

    @IsOptional()
    @IsString()
    internalDescription: string;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoTutorProfile)
    tutor: DtoTutorProfile;

    @IsBoolean()
    availableForAll: boolean;

    @IsNotEmpty()
    @IsString()
    createdAt: string;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoSubjectAreaLevel)
    subjectAreaLevel: DtoSubjectAreaLevel;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoTmSection)
    tmSection: DtoTmSection;
}
