import React from "react";
import {ElementStateEnum} from "../../../SlidePlayerEditorCommonParts/components/Audio/Common";
import {UploadProcessDetails} from "../../../../../store/uploadQueue/type";

export interface IVoiceoverElementContext {
    fileId: string | null;
    elementState: ElementStateEnum;
    currentFileInUploadQueue: UploadProcessDetails | null,
    onChangeFileId: (newFileId: string | null) => void;
    setElementState: React.Dispatch<React.SetStateAction<ElementStateEnum>>;
}

export const VoiceoverElementContext = React.createContext<IVoiceoverElementContext>({
    fileId: null,
    elementState: ElementStateEnum.WAIT_INIT,
    currentFileInUploadQueue: null,
    onChangeFileId: () => {
    },
    setElementState: () => {
    }
});

export const VoiceoverElementContextProvider = VoiceoverElementContext.Provider;