import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {AppActionTypes} from "../../store/app/type";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {currentUserIdSelector} from "../../store/user/selector";
import {ICustomStorage} from "../../components/CustomStorage/ICustomStorage";
import {TargetsEnum} from "../../components/CustomStorage/TargetsEnum";
import {setAuthToken} from "../../store/commonPersisted/actions";
import {sessionTokenSelector} from "../../store/app/selector";

export function* watchNewWindowOpened() {
    yield takeEvery(
        AppActionTypes.NEW_WINDOW_OPENED,
        newWindowOpened
    );
}

function* newWindowOpened(data: WorkerPayloadType<string>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const newWindowUserId = data.payload;

    const currentUserId = (yield select(currentUserIdSelector)) as string | null;
    const currentSessionToken = (yield select(sessionTokenSelector)) as string | null;

    if ((currentUserId === null) || (currentSessionToken === null)) {
        return;
    }

    if (currentUserId === newWindowUserId) {
        logger.info(LoggerSectionsEnum.APPLICATION, 'New window opened with same userId');

        return;
    }

    // Новое окно оказалось открыто с другим ID пользователя. Отвязываемся от LocalStorage.
    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);

    if (customStorage.getCurrentTarget() !== TargetsEnum.LOCAL_STORAGE) {
        // Текущее окно уже не работает с localStorage
        logger.info(
            LoggerSectionsEnum.APPLICATION,
            `New window opened with another user id. Current window already don't use localStorage.`
        );

        return;
    }

    logger.info(LoggerSectionsEnum.APPLICATION, 'New window opened with another user id. Disconnect from localStorage...');

    customStorage.setNewTarget(TargetsEnum.SESSION_STORAGE);

    // Переустановим token, чтобы persist снова записал состояние в хранилище
    yield putResolve(setAuthToken(''));
    yield put(setAuthToken(currentSessionToken));
}
