import {History} from "history";
import {Persistor} from "redux-persist/es/types";
import {Store} from "redux";
import {IStoreService} from "./IStoreService";
import {ApplicationState} from "../../store";

/**
 * Сервис лишь для хранения ссылок на объекты в di контейнере.
 */
export class StoreService implements IStoreService {
    private _history: History;
    private _persistor: Persistor;
    private _store: Store;

    constructor(history: History, persistor: Persistor, store: Store<ApplicationState>) {
        this._history = history;
        this._persistor = persistor;
        this._store = store;
    }

    get history(): History {
        return this._history;
    }

    get persistor(): Persistor {
        return this._persistor;
    }

    get store(): Store<ApplicationState> {
        return this._store;
    }
}
