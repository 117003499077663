import React, {useMemo} from "react";
import {Avatar, AvatarGroup} from "./Avatar";
import styled from "styled-components";

const AvatarGroupStyled = styled(AvatarGroup)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export interface StudentItem {
    avatarFileId: string | null;
    name: string;
}

interface HorizontalAvatarGroupProps {
    users: StudentItem[];
    extraLargeAvatars: boolean;
    noBorder?: boolean;
}

export const HorizontalAvatarGroup: React.FC<HorizontalAvatarGroupProps> = (props) => {
    const delimiter = useMemo<number>(() => {
        return (props.extraLargeAvatars) ? 49 : 40;
    }, [props.extraLargeAvatars]);

    const avatarSize = useMemo<number>(() => {
        return (props.extraLargeAvatars) ? 64 : 48;
    }, [props.extraLargeAvatars]);

    const avatarList = useMemo<JSX.Element[]>(() => {
        const avatarCount = props.users.length;

        const itemsArray: JSX.Element[] = [];

        for (let index = avatarCount - 1; index > -1; index--) {
            itemsArray.push(
                <Avatar fileId={props.users[index].avatarFileId}
                        key={index}
                        userName={props.users[index].name}
                        noBorder={props.noBorder}
                        size={avatarSize}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: (delimiter * index) + 'px'
                        }}
                />
            );
        }

        return itemsArray;
    }, [avatarSize, delimiter, props.noBorder, props.users]);

    return <AvatarGroupStyled>
        {avatarList}
    </AvatarGroupStyled>
};