import React from "react";
import styled from "styled-components";
import {Chip} from "./Chip";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  overflow: auto;
`;

type ChipsRadioKeyType = string | number;

export type ChipsRadioItemType = {
    key: ChipsRadioKeyType;
    label: React.ReactNode;
}

export interface ChipsRadioProps {
    variants: ChipsRadioItemType[];
    value: ChipsRadioKeyType;
    onChange: (newValue: ChipsRadioKeyType) => void;
}

export const ChipsRadio: React.FC<ChipsRadioProps> = (
    {variants, value, onChange}
) => {
    return <Wrapper>
        {
            variants.map((item) => {
                return <Chip
                    key={item.key}
                    active={item.key === value}
                    onClick={() => onChange(item.key)}
                >{item.label}</Chip>
            })
        }
    </Wrapper>
};