import React from "react";
import {ConferenceModeEnum, ConferenceConnectionParams, ParticipantItem} from "../Types";
import {LessonRoomMember} from "../../../../store/lessonRoom/type";

export enum LessonConferenceStatusEnum {
    NOT_INIT,
    INIT_DEVICES,
    CHECK_CONNECTION,
    STARTED,
    INIT_DEVICES_ERROR,
    CONNECTION_ERROR,
    CONNECTION_ERROR_WAIT_NETWORK,
    ON_DESTROY
}

export interface ICommonContext {
    lessonConferenceState: LessonConferenceStatusEnum;
    currentMode: ConferenceModeEnum;
    conferenceParticipants: ParticipantItem[];
    roomWsMembers: LessonRoomMember[];
    conferenceConnectionParams: ConferenceConnectionParams | null;
    lessonId: string;
    wsRoomIsConnected: boolean;
    selectedMemberId: string | null;
    onMemberSelect: (memberId: string | null) => void;
}

export const CommonContext = React.createContext<ICommonContext>({
    lessonConferenceState: LessonConferenceStatusEnum.NOT_INIT,
    currentMode: ConferenceModeEnum.NORMAL,
    conferenceParticipants: [],
    roomWsMembers: [],
    conferenceConnectionParams: null,
    lessonId: '',
    wsRoomIsConnected: false,
    selectedMemberId: null,
    onMemberSelect: () => {}
});

export const CommonContextProvider = CommonContext.Provider;
