import {IHttpApiClient} from "./IHttpApiClient";
import axios, {AxiosInstance} from "axios";
import {IBaseRequestDto} from "./ApiDto/Request/IBaseRequestDto";
import {BaseResponseDto} from "./ApiDto/Response/BaseResponseDto";
import {ClassType} from "../../types/ClassType";
import {ModelValidator} from "../ModelValidator/ModelValidator";
import {ResponseStatusEnum} from "./Enums/ResponseStatusEnum";
import {RequestValidationError} from "./Exception/RequestValidationError";
import {ServerErrorException} from "./Exception/ServerErrorException";
import {Exception} from "./Exception/Exception";
import {IncorrectRequest} from "./Exception/IncorrectRequest";
import {IncorrectResponse} from "./Exception/IncorrectResponse";
import {NotFoundException} from "./Exception/NotFoundException";
import {NotFoundMethodException} from "./Exception/NotFoundMethodException";
import {AccessDeniedException} from "./Exception/AccessDeniedException";
import {RegisterDto} from "./ApiDto/Request/Auth/RegisterDto";
import {RestorePasswordDto} from "./ApiDto/Request/Auth/RestorePasswordDto";
import {
    RestorePasswordViaTokenDto as RespRestorePasswordViaTokenDto
} from "./ApiDto/Response/Auth/RestorePasswordViaTokenDto";
import {
    RestorePasswordViaTokenDto as ReqRestorePasswordViaTokenDto
} from "./ApiDto/Request/Auth/RestorePasswordViaTokenDto";
import {LoginByAuthTokenDto} from "./ApiDto/Request/Auth/LoginByAuthTokenDto";
import {AuthLoginDto} from "./ApiDto/Response/Auth/LoginDto";
import {UserProfileDataDto} from "./ApiDto/Response/User/GetUserAgreementResponse/UserProfileDataDto";
import {LoginDto} from "./ApiDto/Request/Auth/LoginDto";
import {FieldValidationException} from "../ModelValidator/FieldValidationException";
import {LoginTeacherDto} from "./ApiDto/Request/Auth/LoginTeacherDto";
import {TeacherProfileDataDto} from "./ApiDto/Response/User/GetUserAgreementResponse/TeacherProfileDataDto";
import {DtoRequestWithPagination} from "./ApiDto/Response/Common/DtoRequestWithPagination";
import {DtoTmOrganization} from "./ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {DtoTmDiscipline} from "./ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {DtoTmLevel} from "./ApiDto/Response/TmLevels/DtoTmLevel";
import {DtoTmSection} from "./ApiDto/Response/TmSection/DtoTmSection";
import {DtoTmLesson} from "./ApiDto/Response/TmLesson/DtoTmLesson";
import {DtoTmLessonSummaryResponse} from "./ApiDto/Response/TmLesson/DtoTmLessonSummaryResponse";
import {DtoTmSlide} from "./ApiDto/Response/TmSlide/DtoTmSlide";
import {DtoTmSlideContentResponse} from "./ApiDto/Response/TmSlide/DtoTmSlideContentResponse";
import {DtoTmSlideContent} from "./ApiDto/Response/TmSlide/DtoTmSlideContent";
import {UserFileTypeEnum} from "../../enums/UserFileEnums";
import {DtoUrlForUploadResponse} from "./ApiDto/Response/Files/DtoUrlForUploadResponse";
import {DtoUrlForDownloadResponse} from "./ApiDto/Response/Files/DtoUrlForDownloadResponse";
import {DtoKbSection} from "./ApiDto/Response/KnowledgeBase/DtoKbSection";
import {DtoKbPage} from "./ApiDto/Response/KnowledgeBase/DtoKbPage";
import {DtoKbPageWBody} from "./ApiDto/Response/KnowledgeBase/DtoKbPageWBody";
import {DtoTmOverviewByOrganization} from "./ApiDto/Response/TmOrganizations/DtoTmOverviewByOrganization";
import {DtoTmLevelOverview} from "./ApiDto/Response/TmLevels/DtoTmLevelOverview";
import {DtoTmSectionOverview} from "./ApiDto/Response/TmSection/DtoTmSectionOverview";
import {DtoLessonMaterialItem} from "./ApiDto/Response/LessonMaterials/DtoLessonMaterialItem";
import {DtoGetUsedInGroupTmItemsResponse} from "./ApiDto/Response/LessonMaterials/DtoGetUsedInGroupTmItemsResponse";
import {DtoLessonMaterialHomeworkItem} from "./ApiDto/Response/LessonMaterials/DtoLessonMaterialHomeworkItem";
import {DtoLessonRoomOverviewTeacherResponse} from "./ApiDto/Response/Lessons/DtoLessonRoomOverviewTeacherResponse";
import {DtoStudentMainPageDataResponse} from "./ApiDto/Response/MainPage/DtoStudentMainPageDataResponse";
import {DtoLastLessonItem} from "./ApiDto/Response/Lessons/DtoLastLessonItem";
import {
    DtoOnlinePaymentDocumentResponseItem
} from "./ApiDto/Response/OnlinePayments/DtoOnlinePaymentDocumentResponseItem";
import {DtoSchoolBaseInfoResponse} from "./ApiDto/Response/School/DtoSchoolBaseInfoResponse";
import {DtoOnlinePaymentOrderStateResponse} from "./ApiDto/Response/OnlinePayments/DtoOnlinePaymentOrderStateResponse";
import {DtoPayOnlineViaCardResponse} from "./ApiDto/Response/OnlinePayments/DtoPayOnlineViaCardResponse";
import {DtoHomeworkSummaryResponse} from "./ApiDto/Response/Homework/DtoHomeworkSummaryResponse";
import {
    DtoUserProfileInfoBySchoolStudentProfile
} from "./ApiDto/Response/Students/DtoUserProfileInfoBySchoolStudentProfile";
import {NoConnection} from "./Exception/NoConnection";
import {CanceledByUser} from "./Exception/CanceledByUser";
import {LoginTeacherByAuthTokenDto} from "./ApiDto/Request/Auth/LoginTeacherByAuthTokenDto";
import {DtoStudentEntranceInfoResponse} from "./ApiDto/Response/Students/DtoStudentEntranceInfoResponse";
import {DtoPaymentsHistoryItem} from "./ApiDto/Response/Payments/DtoPaymentsHistoryItem";
import {CreateDemoStudentDto} from "./ApiDto/Response/Demo/CreateDemoStudentDto";
import {ActualParamsDto} from "./ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";
import {DtoSelfStudyTrackListItem} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackListItem";
import {DtoSubjectAreaLevel} from "./ApiDto/Response/SubjetArea/DtoSubjectAreaLevel";
import {DtoSelfStudyTrackCreateResponse} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackCreateResponse";
import {DtoSelfStudyTrackForEditResponse} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackForEditResponse";
import {SelfStudyTrackCoverMaskTypeEnum} from "../../enums/SelfStudyTrackCoverMaskTypeEnum";
import {DtoSelfStudyTrackSlideItem} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSlideItem";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {SaveDeviceInfoRequestDto} from "./ApiDto/Request/User/SaveDeviceInfoRequestDto";
import {DtoFreeStudentMainPageDataResponse} from "./ApiDto/Response/MainPage/DtoFreeStudentMainPageDataResponse";
import {DtoRegisterTempResponse} from "./ApiDto/Response/Auth/DtoRegisterTempResponse";
import {DtoTmSlideContentLayoutSettings} from "./ApiDto/Response/TmSlide/DtoTmSlideContentLayoutSettings";
import {
    DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview
} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview";
import {UserUtmSaveRequestDto} from "./ApiDto/Request/UserUtm/UserUtmSaveRequestDto";
import {
    DtoSelfStudyTrackAdminOverviewResponse
} from "./ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackAdminOverviewResponse";

export class HttpApiClient implements IHttpApiClient {
    /**
     * Точка входа в API ЛК
     * @private
     */
    protected apiEntryPoint: string;

    protected axios: AxiosInstance | null;

    constructor(apiEntryPoint: string) {
        this.apiEntryPoint = apiEntryPoint;

        this.axios = axios.create();
    }

    /**
     * @inheritDoc
     */
    async loginByPassword(login: string, password: string): Promise<BaseResponseDto<AuthLoginDto>> {
        const requestDto = new LoginDto();

        requestDto.email = login;
        requestDto.password = password;

        return this.execRequest<AuthLoginDto>(
            null,
            'auth/login',
            requestDto,
            AuthLoginDto
        );
    }

    /**
     * @inheritDoc
     */
    loginTeacher(phone: string, password: string): Promise<BaseResponseDto<AuthLoginDto>> {
        const requestDto = new LoginTeacherDto();

        requestDto.phone = phone;
        requestDto.password = password;

        return this.execRequest<AuthLoginDto>(
            null,
            'auth/login-teacher',
            requestDto,
            AuthLoginDto
        );
    }

    /**
     * @inheritDoc
     */
    async loginByAuthenticationToken(authToken: string): Promise<BaseResponseDto<AuthLoginDto>> {
        const requestDto = new LoginByAuthTokenDto();

        requestDto.token = authToken;

        return this.execRequest<AuthLoginDto>(
            null,
            'auth/login-by-authentication-token',
            requestDto,
            AuthLoginDto
        );
    }

    /**
     * @inheritDoc
     */
    async loginTeacherByAuthenticationToken(authToken: string): Promise<BaseResponseDto<AuthLoginDto>> {
        const requestDto = new LoginTeacherByAuthTokenDto();

        requestDto.token = authToken;

        return this.execRequest<AuthLoginDto>(
            null,
            'auth/login-teacher-by-authentication-token',
            requestDto,
            AuthLoginDto
        );
    }

    /**
     * @inheritDoc
     */
    async register(login: string): Promise<void> {
        const requestDto = new RegisterDto();

        requestDto.email = login;
        requestDto.authLinkUrlTemplate = '/auth/via-token/{token}';

        await this.execRequest<{}>(
            null,
            'auth/register',
            requestDto,
            null
        );
    }

    /**
     * @inheritDoc
     */
    registerTemp(localeId: string): Promise<BaseResponseDto<DtoRegisterTempResponse>> {
        return this.execRequest<DtoRegisterTempResponse>(
            null,
            'auth/register-temp',
            {
                localeId: localeId
            },
            DtoRegisterTempResponse
        );
    }

    /**
     * @inheritDoc
     */
    requestOtp(email: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            null,
            'auth/request-otp',
            {
                email: email
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    authByOtp(email: string, otp: string): Promise<BaseResponseDto<AuthLoginDto>> {
        return this.execRequest<AuthLoginDto>(
            null,
            'auth/auth-by-otp',
            {
                email,
                otp,
                authLinkUrlTemplate: '/auth/via-token/{token}'
            },
            AuthLoginDto
        );
    }

    /**
     * @inheritdoc
     */
    mergeProfile(token: string, mergeToken: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user/merge-profile',
            {
                mergeToken: mergeToken
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    async restorePasswordRequest(login: string): Promise<void> {
        const requestDto = new RestorePasswordDto();

        requestDto.email = login;
        requestDto.urlTemplate = '/auth/restore-via-token/{token}';

        await this.execRequest<{}>(
            null,
            'auth/restore-password-request',
            requestDto,
            null
        );
    }

    /**
     * @inheritDoc
     */
    async restorePasswordViaToken(token: string): Promise<BaseResponseDto<RespRestorePasswordViaTokenDto>> {
        const requestDto = new ReqRestorePasswordViaTokenDto();

        requestDto.token = token;
        requestDto.authLinkUrlTemplate = '/auth/via-token/{token}';

        return this.execRequest<RespRestorePasswordViaTokenDto>(
            null,
            'auth/restore-password-by-token',
            requestDto,
            RespRestorePasswordViaTokenDto
        );
    }

    /**
     * @inheritDoc
     */
    async getUserAgreements(token: string): Promise<BaseResponseDto<UserProfileDataDto>> {
        return this.execRequest<UserProfileDataDto>(
            token,
            'user/get-user-agreements',
            null,
            UserProfileDataDto
        );
    }

    /**
     * @inheritDoc
     */
    getTeacherAccountInfo(token: string): Promise<BaseResponseDto<TeacherProfileDataDto>> {
        return this.execRequest<TeacherProfileDataDto>(
            token,
            'user/get-teacher-account-info',
            null,
            TeacherProfileDataDto
        );
    }

    /**
     * @inheritDoc
     */
    async setActiveAgreement(token: string, agreementId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user/set-active-agreement',
            {
                agreementId
            },
            null
        )
    }

    /**
     * @inheritDoc
     */
    async tmGetOrganizations(token: string, pageNum: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmOrganization>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmOrganization>>(
            token,
            'tm-organizations/get-organizations',
            {
                page: pageNum,
                limit: limit
            },
            [DtoTmOrganization],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateOrganization(token: string, name: string): Promise<BaseResponseDto<DtoTmOrganization>> {
        return this.execRequest<DtoTmOrganization>(
            token,
            'tm-organizations/create-organization',
            {name: name},
            DtoTmOrganization
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateOrganization(token: string, id: string, name: string): Promise<BaseResponseDto<DtoTmOrganization>> {
        return this.execRequest<DtoTmOrganization>(
            token,
            'tm-organizations/update-organization',
            {id, name},
            DtoTmOrganization
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteOrganization(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-organizations/delete-organization',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmOverviewByOrganization(token: string, organizationId: string, pageNum: number, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoTmOverviewByOrganization>> {
        return this.execRequest<DtoTmOverviewByOrganization>(
            token,
            'tm-organizations/overview-by-organization',
            {
                id: organizationId,
                page: pageNum,
                limit: limit
            },
            DtoTmOverviewByOrganization
        );
    }

    /**
     * @inheritDoc
     */
    tmGetDisciplines(token: string, organizationId: string, pageNum: number, limit: number): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmDiscipline>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmDiscipline>>(
            token,
            'tm-discipline/get-disciplines',
            {
                organizationId: organizationId,
                page: pageNum,
                limit: limit
            },
            [DtoTmDiscipline]
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateDiscipline(token: string, organizationId: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmDiscipline>> {
        return this.execRequest<DtoTmDiscipline>(
            token,
            'tm-discipline/create-discipline',
            {
                organizationId, name, description
            },
            DtoTmDiscipline
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateDiscipline(token: string, id: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmDiscipline>> {
        return this.execRequest<DtoTmDiscipline>(
            token,
            'tm-discipline/update-discipline',
            {
                id, name, description
            },
            DtoTmDiscipline
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteDiscipline(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-discipline/delete-discipline',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmGetLevels(token: string, disciplineId: string, pageNum: number, limit: number): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmLevel>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmLevel>>(
            token,
            'tm-level/get-levels',
            {
                disciplineId: disciplineId,
                page: pageNum,
                limit: limit
            },
            [DtoTmLevel]
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateLevel(token: string, disciplineId: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmLevel>> {
        return this.execRequest<DtoTmLevel>(
            token,
            'tm-level/create-level',
            {
                disciplineId, name, description
            },
            DtoTmLevel
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateLevel(token: string, id: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmLevel>> {
        return this.execRequest<DtoTmLevel>(
            token,
            'tm-level/update-level',
            {
                id, name, description
            },
            DtoTmLevel
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteLevel(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-level/delete-level',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmSwapLevelOrderPosition(token: string, firstId: string, secondId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-level/swap-order-position',
            {
                firstLevelId: firstId,
                secondLevelId: secondId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmGetLevelOverview(token: string, id: string, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoTmLevelOverview>> {
        return this.execRequest<DtoTmLevelOverview>(
            token,
            'tm-level/level-overview',
            {
                levelId: id,
                sectionsLimit: limit
            },
            DtoTmLevelOverview,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmGetSections(token: string, levelId: string, pageNum: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmSection>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmSection>>(
            token,
            'tm-section/get-sections',
            {
                levelId: levelId,
                page: pageNum,
                limit: limit
            },
            [DtoTmSection],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateSection(token: string, levelId: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmSection>> {
        return this.execRequest<DtoTmSection>(
            token,
            'tm-section/create-section',
            {
                levelId, name, description
            },
            DtoTmSection
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateSection(token: string, id: string, name: string, description: string | null): Promise<BaseResponseDto<DtoTmSection>> {
        return this.execRequest<DtoTmSection>(
            token,
            'tm-section/update-section',
            {
                id, name, description
            },
            DtoTmSection
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteSection(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-section/delete-section',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmSwapSectionOrderPosition(token: string, firstId: string, secondId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-section/swap-order-position',
            {
                firstSectionId: firstId,
                secondSectionId: secondId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmGetSectionOverview(token: string, id: string, limit: number, abortController: AbortController): Promise<BaseResponseDto<DtoTmSectionOverview>> {
        return this.execRequest<DtoTmSectionOverview>(
            token,
            'tm-section/section-overview',
            {
                sectionId: id,
                lessonsLimit: limit
            },
            DtoTmSectionOverview,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmGetLessons(token: string, sectionId: string, pageNum: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmLesson>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmLesson>>(
            token,
            'tm-lesson/get-lessons',
            {
                sectionId: sectionId,
                page: pageNum,
                limit: limit
            },
            [DtoTmLesson],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateLesson(token: string, sectionId: string, name: string, descriptionForStudent: string | null, descriptionForTeacher: string | null, coverFileId: string | null): Promise<BaseResponseDto<DtoTmLesson>> {
        return this.execRequest<DtoTmLesson>(
            token,
            'tm-lesson/create-lesson',
            {
                sectionId, name, descriptionForStudent, descriptionForTeacher, coverFileId
            },
            DtoTmLesson
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateLesson(token: string, id: string, name: string, descriptionForStudent: string | null, descriptionForTeacher: string | null, coverFileId: string | null): Promise<BaseResponseDto<DtoTmLesson>> {
        return this.execRequest<DtoTmLesson>(
            token,
            'tm-lesson/update-lesson',
            {
                id, name, descriptionForStudent, descriptionForTeacher, coverFileId
            },
            DtoTmLesson
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteLesson(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-lesson/delete-lesson',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmSwapLessonOrderPosition(token: string, firstId: string, secondId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-lesson/swap-order-position',
            {
                firstLessonId: firstId,
                secondLessonId: secondId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmGetLessonSummaryInfo(token: string, id: string): Promise<BaseResponseDto<DtoTmLessonSummaryResponse>> {
        return this.execRequest<DtoTmLessonSummaryResponse>(
            token,
            'tm-lesson/get-lesson-summary',
            {
                id
            },
            DtoTmLessonSummaryResponse
        );
    }

    /**
     * @inheritDoc
     */
    tmGetSlides(token: string, lessonId: string, pageNum: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoTmSlide>>> {
        return this.execRequest<DtoRequestWithPagination<DtoTmSlide>>(
            token,
            'tm-slide/get-slides',
            {
                lessonId: lessonId,
                page: pageNum,
                limit: limit
            },
            [DtoTmSlide],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmCreateSlide(token: string, lessonId: string, name: string, isAdditional: boolean, forHomework: boolean, actualParamNum: number): Promise<BaseResponseDto<DtoTmSlide>> {
        return this.execRequest<DtoTmSlide>(
            token,
            'tm-slide/create-slide',
            {
                lessonId, name, isAdditional, forHomework, actualParamNum
            },
            DtoTmSlide
        );
    }

    /**
     * @inheritDoc
     */
    tmUpdateSlide(token: string, id: string, name: string, isAdditional: boolean, forHomework: boolean, actualParamNum: number): Promise<BaseResponseDto<DtoTmSlide>> {
        return this.execRequest<DtoTmSlide>(
            token,
            'tm-slide/update-slide',
            {
                id, name, isAdditional, forHomework, actualParamNum
            },
            DtoTmSlide
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteSlide(token: string, id: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-slide/delete-slide',
            {id},
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmSwapSlideOrderPosition(token: string, firstId: string, secondId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-slide/swap-order-position',
            {
                firstSlideId: firstId,
                secondSlideId: secondId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmGetSlideContent(token: string, slideId: string, version: number | null, abortController?: AbortController): Promise<BaseResponseDto<DtoTmSlideContentResponse>> {
        return this.execRequest<DtoTmSlideContentResponse>(
            token,
            'tm-slide/get-slide-content',
            {
                slideId, version
            },
            DtoTmSlideContentResponse
        );
    }

    /**
     * @inheritDoc
     */
    tmGetSlideContentForStudent(token: string, lessonId: string, slideId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoTmSlideContentResponse>> {
        return this.execRequest<DtoTmSlideContentResponse>(
            token,
            'tm-slide/get-slide-content-for-student',
            {
                lessonId, slideId
            },
            DtoTmSlideContentResponse
        );
    }

    /**
     * @inheritDoc
     */
    tmSetSlideContent(token: string, slideId: string, content: DtoTmSlideContent, exerciseCount: number): Promise<BaseResponseDto<DtoTmSlideContentResponse>> {
        return this.execRequest<DtoTmSlideContentResponse>(
            token,
            'tm-slide/set-slide-content',
            {
                slideId, content, exerciseCount
            },
            DtoTmSlideContentResponse
        );
    }

    /**
     * @inheritDoc
     */
    tmSetSlideContentLayoutSettings(token: string, tmSlideId: string, layoutSettings: DtoTmSlideContentLayoutSettings): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-slide/set-slide-content-layout-settings',
            {
                tmSlideId,
                ...layoutSettings
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    tmDeleteSlideContentLayoutSettings(token: string, tmSlideId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'tm-slide/delete-slide-content-layout-settings',
            {
                tmSlideId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    fileGetUrlForUpload(token: string, fileType: UserFileTypeEnum, fileSize: string): Promise<BaseResponseDto<DtoUrlForUploadResponse>> {
        return this.execRequest<DtoUrlForUploadResponse>(
            token,
            'files/get-url-for-upload',
            {
                fileType, fileSize
            },
            DtoUrlForUploadResponse
        );
    }

    /**
     * @inheritDoc
     */
    fileGetUrlForDownload(token: string, fileId: string): Promise<BaseResponseDto<DtoUrlForDownloadResponse>> {
        return this.execRequest<DtoUrlForDownloadResponse>(
            token,
            'files/get-url-for-download',
            {
                fileId: fileId
            },
            DtoUrlForDownloadResponse
        );
    }

    /**
     * @inheritDoc
     */
    kbGetSections(token: string, localeId: string, pageNum: number, limit: number): Promise<BaseResponseDto<DtoRequestWithPagination<DtoKbSection>>> {
        return this.execRequest<DtoRequestWithPagination<DtoKbSection>>(
            token,
            'knowledge-base/get-sections',
            {
                localeId: localeId,
                page: pageNum,
                limit: limit
            },
            [DtoKbSection]
        );
    }

    /**
     * @inheritDoc
     */
    kbGetSectionDetails(token: string, localeId: string, sectionId: string): Promise<BaseResponseDto<DtoKbSection>> {
        return this.execRequest<DtoKbSection>(
            token,
            'knowledge-base/get-section-details',
            {
                localeId: localeId,
                sectionId: sectionId
            },
            DtoKbSection
        );
    }

    /**
     * @inheritDoc
     */
    kbGetPages(token: string, localeId: string, sectionId: string, pageNum: number, limit: number): Promise<BaseResponseDto<DtoRequestWithPagination<DtoKbPage>>> {
        return this.execRequest<DtoRequestWithPagination<DtoKbPage>>(
            token,
            'knowledge-base/get-section-pages',
            {
                localeId: localeId,
                sectionId: sectionId,
                page: pageNum,
                limit: limit
            },
            [DtoKbPage]
        );
    }

    /**
     * @inheritDoc
     */
    kbGetPageSectionDetails(token: string, localeId: string, pageId: string): Promise<BaseResponseDto<DtoKbSection>> {
        return this.execRequest<DtoKbSection>(
            token,
            'knowledge-base/get-page-section-details',
            {
                localeId: localeId,
                pageId: pageId
            },
            DtoKbSection
        );
    }

    /**
     * @inheritDoc
     */
    kbGetPageWBody(token: string, localeId: string, pageId: string): Promise<BaseResponseDto<DtoKbPageWBody>> {
        return this.execRequest<DtoKbPageWBody>(
            token,
            'knowledge-base/get-page-content',
            {
                localeId: localeId,
                pageId: pageId
            },
            DtoKbPageWBody
        );
    }

    /**
     * @inheritDoc
     */
    getLessonRoomOverview(token: string, stGroupId: number, stLessonId: number, abortController?: AbortController): Promise<BaseResponseDto<DtoLessonRoomOverviewTeacherResponse>> {
        return this.execRequest<DtoLessonRoomOverviewTeacherResponse>(
            token,
            'lessons/lesson-room-overview',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
            },
            DtoLessonRoomOverviewTeacherResponse
        );
    }

    /**
     * @inheritDoc
     */
    getLessonMaterials(token: string, stGroupId: number, stLessonId: number, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoLessonMaterialItem>>(
            token,
            'lesson-materials/get-lesson-materials',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                page: page,
                limit: limit
            },
            [DtoLessonMaterialItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getLessonMaterialsForStudent(token: string, lessonId: string, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoLessonMaterialItem>>(
            token,
            'lesson-materials/get-lesson-materials-for-student',
            {
                lessonId: lessonId,
                page: page,
                limit: limit
            },
            [DtoLessonMaterialItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getLessonHomeworkMaterials(token: string, stGroupId: number, stLessonId: number, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>>(
            token,
            'lesson-materials/get-lesson-homework-materials',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                page: page,
                limit: limit
            },
            [DtoLessonMaterialHomeworkItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getLessonHomeworkMaterialsForStudent(token: string, lessonId: string, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoLessonMaterialHomeworkItem>>(
            token,
            'lesson-materials/get-lesson-homework-materials-for-student',
            {
                lessonId: lessonId,
                page: page,
                limit: limit
            },
            [DtoLessonMaterialHomeworkItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getUsedInGroupTmItems(token: string, stGroupId: number, abortController?: AbortController): Promise<BaseResponseDto<DtoGetUsedInGroupTmItemsResponse>> {
        return this.execRequest<DtoGetUsedInGroupTmItemsResponse>(
            token,
            'lesson-materials/get-used-in-group-tm-items',
            {
                stGroupId: stGroupId
            },
            DtoGetUsedInGroupTmItemsResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    addAllSlidesFromTmLessonToLesson(token: string, stGroupId: number, stLessonId: number, tmLessonId: string, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/add-all-slides-from-tm-lesson-to-lesson',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                tmLessonId: tmLessonId
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    setLessonMaterialsSlideVisibility(token: string, stGroupId: number, stLessonId: number, tmSlideId: string, hiddenForStudent: boolean, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/set-slide-visibility',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                tmSlideId: tmSlideId,
                hiddenForStudent: hiddenForStudent
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    addTmSlideToLesson(token: string, stGroupId: number, stLessonId: number, tmSlideId: string, addToHomework: boolean, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/add-tm-slide-to-lesson',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                tmSlideId: tmSlideId,
                addToHomework: addToHomework
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    tmSwapLessonSlideOrderPosition(token: string, stGroupId: number, stLessonId: number, firstTmSlideId: string, secondTmSlideId: string, inHomework: boolean, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/swap-lesson-slide-order-position',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                firstTmSlideId: firstTmSlideId,
                secondTmSlideId: secondTmSlideId,
                inHomework: inHomework
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getSlideInfo(token: string, tmSlideId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoTmSlide>> {
        return this.execRequest<DtoTmSlide>(
            token,
            'tm-slide/get-slide-info',
            {
                id: tmSlideId
            },
            DtoTmSlide,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    removeSlideFromLesson(token: string, stGroupId: number, stLessonId: number, tmSlideId: string, fromHomework: boolean, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/remove-slide-from-lesson',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                tmSlideId: tmSlideId,
                fromHomework: fromHomework,
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    clearAllLessonSlides(token: string, stGroupId: number, stLessonId: number, fromHomework: boolean, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'lesson-materials/clear-all-lesson-slides',
            {
                stGroupId: stGroupId,
                stLessonId: stLessonId,
                fromHomework: fromHomework,
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    setUserAvatarFile(token: string, fileId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user/set-avatar',
            {
                fileId: fileId,
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    getSchoolStudentMainPageContent(token: string, agreementId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoStudentMainPageDataResponse>> {
        return this.execRequest<DtoStudentMainPageDataResponse>(
            token,
            'main-page/school-student-main-page-data',
            {
                agreementId: agreementId
            },
            DtoStudentMainPageDataResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getFreeStudentMainPageContent(token: string, abortController?: AbortController): Promise<BaseResponseDto<DtoFreeStudentMainPageDataResponse>> {
        return this.execRequest<DtoFreeStudentMainPageDataResponse>(
            token,
            'main-page/free-student-main-page-data',
            null,
            DtoFreeStudentMainPageDataResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getLastLessonsResults(token: string, agreementId: string, withCosts: boolean, sortDesc: boolean, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoLastLessonItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoLastLessonItem>>(
            token,
            'lessons/get-last-lessons-results',
            {
                agreementId, withCosts, sortDesc, page, limit
            },
            [DtoLastLessonItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getAcquiringDocumentBody(token: string, agreementId: string, docId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoOnlinePaymentDocumentResponseItem>> {
        return this.execRequest<DtoOnlinePaymentDocumentResponseItem>(
            token,
            'online-payment/get-acquiring-document-body',
            {
                agreementId, docId
            },
            DtoOnlinePaymentDocumentResponseItem,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getSchoolBaseInfo(token: string, agreementId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoSchoolBaseInfoResponse>> {
        return this.execRequest<DtoSchoolBaseInfoResponse>(
            token,
            'school/get-school-base-info',
            {
                agreementId
            },
            DtoSchoolBaseInfoResponse,
            abortController
        );
    }

    /**
     * Задать новый пароль
     *
     * @param token
     * @param oldPassword
     * @param newPassword
     */
    setNewPassword(token: string, oldPassword: string, newPassword: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'auth/set-new-password',
            {
                oldPassword: oldPassword,
                newPassword: newPassword
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    getAvailableAcquiringDocuments(token: string, agreementId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoOnlinePaymentDocumentResponseItem>>> {
        return this.execRequest <DtoRequestWithPagination<DtoOnlinePaymentDocumentResponseItem>>(
            token,
            'online-payment/get-available-acquiring-documents',
            {
                agreementId: agreementId
            },
            [DtoOnlinePaymentDocumentResponseItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    payOnlineViaCard(token: string, sum: number, email: string, successUrl: string, failUrl: string, agreementId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoPayOnlineViaCardResponse>> {
        return this.execRequest<DtoPayOnlineViaCardResponse>(
            token,
            'online-payment/pay-online-via-card',
            {
                sum: sum,
                email: email,
                successUrl: successUrl,
                failUrl: failUrl,
                agreementId: agreementId
            },
            DtoPayOnlineViaCardResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    onlinePaymentGetOrderState(token: string, orderId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoOnlinePaymentOrderStateResponse>> {
        return this.execRequest <DtoOnlinePaymentOrderStateResponse>(
            token,
            'online-payment/get-order-state',
            {
                orderId: orderId
            },
            DtoOnlinePaymentOrderStateResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getHomeworkSummary(token: string, lessonId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoHomeworkSummaryResponse>> {
        return this.execRequest <DtoHomeworkSummaryResponse>(
            token,
            'homework/get-homework-summary',
            {
                lessonId: lessonId
            },
            DtoHomeworkSummaryResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    async getUserProfileInfoBySchoolStudentProfile(token: string, studentStApiIds: number[]): Promise<BaseResponseDto<DtoRequestWithPagination<DtoUserProfileInfoBySchoolStudentProfile>>> {
        return this.execRequest<DtoRequestWithPagination<DtoUserProfileInfoBySchoolStudentProfile>>(
            token,
            'students/get-user-profile-info-by-school-student-profile',
            {
                stApiIds: studentStApiIds.join(',')
            },
            [DtoUserProfileInfoBySchoolStudentProfile]
        );
    }

    /**
     * @inheritDoc
     */
    getStudentEntranceInfo(token: string, studentEmail: string, autoSignUp?: boolean, abortController?: AbortController): Promise<BaseResponseDto<DtoStudentEntranceInfoResponse>> {
        return this.execRequest <DtoStudentEntranceInfoResponse>(
            token,
            'students/get-student-entrance-info',
            {
                studentEmail: studentEmail,
                autoSignUp: !!autoSignUp
            },
            DtoStudentEntranceInfoResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    sendInvitationToStudent(token: string, studentEmail: string, abortController?: AbortController): Promise<BaseResponseDto<null>> {
        return this.execRequest <null>(
            token,
            'students/send-invitation-to-student',
            {
                studentEmail: studentEmail
            },
            null,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    resetStudentPassword(token: string, studentEmail: string, abortController?: AbortController): Promise<BaseResponseDto<DtoStudentEntranceInfoResponse>> {
        return this.execRequest<DtoStudentEntranceInfoResponse>(
            token,
            'students/reset-student-password',
            {
                studentEmail: studentEmail
            },
            DtoStudentEntranceInfoResponse,
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    getPaymentsHistory(token: string, agreementId: string, moneyIn: boolean, moneyOut: boolean, moneyLessons: Boolean, dateStart: string | null, dateEnd: string | null, pageNum: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoPaymentsHistoryItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoPaymentsHistoryItem>>(
            token,
            'payments/get-payments-history',
            {
                agreementId,
                moneyIn,
                moneyOut,
                moneyLessons,
                dateStart,
                dateEnd,
                page: pageNum,
                limit: limit
            },
            [DtoPaymentsHistoryItem],
            abortController
        );
    }

    /**
     * @inheritDoc
     */
    requestDemoStudentLk(): Promise<BaseResponseDto<CreateDemoStudentDto>> {
        return this.execRequest <CreateDemoStudentDto>(
            null,
            'demo/create-demo-student',
            {},
            CreateDemoStudentDto
        );
    }

    /**
     * @inheritDoc
     */
    setUiLocale(token: string, localeId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user/set-ui-locale',
            {
                localeId: localeId
            },
            null
        );
    }

    /**
     * @inheritDoc
     */
    schoolReloadLessonMarksList(token: string): Promise<BaseResponseDto<ActualParamsDto>> {
        return this.execRequest<ActualParamsDto>(
            token,
            'school/sync-lesson-marks-list',
            null,
            ActualParamsDto
        );
    }

    selfStudySchoolGetList(token: string, searchString: string, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoSelfStudyTrackListItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoSelfStudyTrackListItem>>(
            token,
            'self-study-track-admin-catalog/get-list',
            {
                searchString: searchString,
                page: page,
                limit: limit
            },
            [DtoSelfStudyTrackListItem]
        );
    }

    selfStudyCreateTrack(token: string, subjectAreaLevelId: number, name: string, shortDescription: string, landingDescription: DtoTmSlideContent, internalDescription: string, tmSectionId: string, tutorStId: number, coverFileId: string, coverMaskId: SelfStudyTrackCoverMaskTypeEnum, accentColor: string): Promise<BaseResponseDto<DtoSelfStudyTrackCreateResponse>> {
        return this.execRequest<DtoSelfStudyTrackCreateResponse>(
            token,
            'self-study-track-admin-catalog/create-track',
            {
                subjectAreaLevelId,
                name,
                shortDescription,
                landingDescription,
                internalDescription,
                tmSectionId,
                tutorStId,
                coverFileId,
                coverMaskId,
                accentColor
            },
            DtoSelfStudyTrackCreateResponse
        );
    }

    selfStudyUpdateTrack(token: string, trackId: string, subjectAreaLevelId: number, name: string, shortDescription: string, landingDescription: DtoTmSlideContent, internalDescription: string, tmSectionId: string, tutorStId: number, coverFileId: string, coverMaskId: SelfStudyTrackCoverMaskTypeEnum, accentColor: string): Promise<BaseResponseDto<DtoSelfStudyTrackCreateResponse>> {
        return this.execRequest<DtoSelfStudyTrackCreateResponse>(
            token,
            'self-study-track-admin-catalog/update-track',
            {
                trackId,
                subjectAreaLevelId,
                name,
                shortDescription,
                landingDescription,
                internalDescription,
                tmSectionId,
                tutorStId,
                coverFileId,
                coverMaskId,
                accentColor
            },
            DtoSelfStudyTrackCreateResponse
        );
    }

    getStudyTrackForEditById(token: string, trackId: string, abortController?: AbortController): Promise<BaseResponseDto<DtoSelfStudyTrackForEditResponse>> {
        return this.execRequest<DtoSelfStudyTrackForEditResponse>(
            token,
            'self-study-track-admin-catalog/get-for-edit-by-id',
            {
                trackId: trackId
            },
            DtoSelfStudyTrackForEditResponse
        );
    }

    deleteStudyTrack(token: string, trackId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'self-study-track-admin-catalog/delete-track',
            {
                trackId: trackId
            },
            null
        );
    }

    subscribeToTrack(token: string, trackId: string): Promise<BaseResponseDto<DtoSelfStudyTrackSubscriptionUserOverviewItem>> {
        return this.execRequest<DtoSelfStudyTrackSubscriptionUserOverviewItem>(
            token,
            'self-study-track/subscribe-to-track',
            {
                trackId: trackId
            },
            DtoSelfStudyTrackSubscriptionUserOverviewItem
        );
    }

    selfStudyTrackEpisodeMaterials(token: string, episodeId: string): Promise<BaseResponseDto<DtoRequestWithPagination<DtoSelfStudyTrackSlideItem>>> {
        return this.execRequest<DtoRequestWithPagination<DtoSelfStudyTrackSlideItem>>(
            token,
            'self-study-track/episode-materials',
            {
                episodeId: episodeId
            },
            [DtoSelfStudyTrackSlideItem]
        );
    }

    selfStudySubscriptionOnEpisodeCompleteOverview(token: string, episodeId: string, localeId: string): Promise<BaseResponseDto<DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview>> {
        return this.execRequest<DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview>(
            token,
            'self-study-track/on-episode-complete-overview',
            {
                episodeId: episodeId,
                localeId: localeId
            },
            DtoSelfStudyTrackSubscriptionOnEpisodeCompleteOverview
        );
    }

    /**
     * @inheritdoc
     */
    saveEpisodeSurveyAnswer(token: string, episodeId: string, questionId: string, answer: number, comment: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'self-study-track/save-episode-survey-answer',
            {
                episodeId,
                questionId,
                answer,
                comment
            },
            null
        );
    }

    /**
     * @inheritdoc
     */
    getSubjectAreaList(token: string, searchString: string, page: number, limit: number, abortController?: AbortController): Promise<BaseResponseDto<DtoRequestWithPagination<DtoSubjectAreaLevel>>> {
        return this.execRequest<DtoRequestWithPagination<DtoSubjectAreaLevel>>(
            token,
            'subject-area/get-list',
            {
                searchString: searchString,
                page: page,
                limit: limit
            },
            [DtoSubjectAreaLevel]
        );
    }

    /**
     * @inheritdoc
     */
    saveStudentDeviceInfo(token: string, deviceInfo: SaveDeviceInfoRequestDto): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user/save-device-info',
            deviceInfo,
            null
        );
    }

    /**
     * @inheritdoc
     */
    saveUserUtm(token: string, utmParams: UserUtmSaveRequestDto): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'user-utm/save-utm',
            utmParams,
            null
        );
    }

    /**
     * @inheritdoc
     */
    selfStudyTrackAdminOverview(token: string, trackId: string): Promise<BaseResponseDto<DtoSelfStudyTrackAdminOverviewResponse>> {
        return this.execRequest<DtoSelfStudyTrackAdminOverviewResponse>(
            token,
            'self-study-track-admin-catalog/admin-overview',
            {
                trackId
            },
            DtoSelfStudyTrackAdminOverviewResponse
        );
    }

    /**
     * @inheritdoc
     */
    selfStudyClearSlideWorkData(token: string, episodeId: string, tmSlideId: string): Promise<BaseResponseDto<null>> {
        return this.execRequest<null>(
            token,
            'self-study-track/clear-slide-work-data',
            {
                episodeId,
                tmSlideId
            },
            null
        );
    }

    async execRequest<T extends object | null>(token: string | null, methodName: string, data: IBaseRequestDto | null, responseType: ClassType<any> | Array<ClassType<any>> | null, abortController?: AbortController): Promise<BaseResponseDto<T>> {
        return new Promise((resolve, reject) => {
            const queryBody: {
                method: string;
                data: any;
                token?: string;
            } = {
                method: methodName,
                data: data
            }

            if (token) {
                queryBody.token = token;
            }

            axios.post<string>(this.apiEntryPoint, JSON.stringify(queryBody), {signal: (abortController) ? abortController.signal : undefined})
                .then(async response => {
                    if (!response) {
                        reject(new Exception(
                            `Received empty response model.`
                        ));

                        return;
                    }

                    // Валидируем основную часть сообщения
                    let dataAsBaseClassObject: BaseResponseDto<any>;

                    try {
                        dataAsBaseClassObject = await this.transformAndValidateRequestJsonObject(
                            BaseResponseDto,
                            this.convertRawStringToObject(
                                ((typeof response.data) === "object")
                                    ? JSON.stringify(response.data)
                                    : response.data
                            )
                        );
                    } catch (e) {
                        reject(new IncorrectResponse(
                            (e instanceof Error) ? e.message : "",
                            JSON.stringify(queryBody),
                            JSON.stringify(response)
                        ));

                        return;
                    }

                    // На этом этапе у нас точно есть корректная базовая часть. Проверяем дальше.

                    // Проверяем статус
                    if (dataAsBaseClassObject.status !== ResponseStatusEnum.SUCCESS) {
                        try {
                            this.throwExceptionByStatus(dataAsBaseClassObject.status, dataAsBaseClassObject);
                        } catch (e) {
                            reject(e);

                            return;
                        }
                    }

                    // Пытаемся преобразовать поле data в нужный тип.
                    if (responseType !== null) {
                        try {
                            if (Array.isArray(responseType)) {
                                // Речь про то, что мы получим DTO пагинации элементов.
                                // Сначала валидируем DTO пагинации...
                                dataAsBaseClassObject.data = await this.transformAndValidateRequestJsonObject(
                                    // @ts-ignore
                                    DtoRequestWithPagination,
                                    dataAsBaseClassObject.data
                                ) as unknown as DtoRequestWithPagination<any>;

                                // ... а затем каждый элемент внутри
                                const itemsCount = dataAsBaseClassObject.data.list.length;

                                for (let index = 0; index < itemsCount; index++) {
                                    dataAsBaseClassObject.data.list[index]
                                        = await this.transformAndValidateRequestJsonObject(
                                        responseType[0],
                                        dataAsBaseClassObject.data.list[index]
                                    );
                                }
                            } else {
                                dataAsBaseClassObject.data = await this.transformAndValidateRequestJsonObject(
                                    responseType,
                                    dataAsBaseClassObject.data
                                );
                            }
                        } catch (e) {
                            reject(new IncorrectResponse(
                                (e instanceof Error) ? e.message : "",
                                JSON.stringify(queryBody),
                                JSON.stringify(response)
                            ));

                            return;
                        }
                    }

                    resolve(dataAsBaseClassObject);
                })
                .catch(e => {
                    if (e.message === 'canceled') {
                        reject(new CanceledByUser('Canceled by user'));

                        return;
                    }

                    if (e.message === 'Network Error') {
                        reject(new NoConnection('Network error'));

                        return;
                    }

                    reject(new Exception(e));

                    return;
                })
        });
    }

    /**
     * Метод генерирует исключение в соответствии со статусом ответа сервера.
     *
     * @param status
     * @param responseModel
     *
     * @protected
     */
    protected throwExceptionByStatus(status: number, responseModel: BaseResponseDto<any>): never {
        switch (status) {
            case ResponseStatusEnum.VALIDATION_ERROR: {
                if ((!responseModel.validationErrorDetails) && (!responseModel.validationSummary)) {
                    throw new ServerErrorException(
                        "Response status value is 'ValidationError', " +
                        "but validationErrorDetails and validationSummary is empty"
                    );
                }

                throw new RequestValidationError(
                    (responseModel.validationSummary) ?? "",
                    (responseModel.validationErrorDetails) ?? null
                );
            }
            case ResponseStatusEnum.INVALID_QUERY: {
                throw new IncorrectRequest((responseModel.errorText) ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.NOT_FOUND: {
                throw new NotFoundException((responseModel.errorText) ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.NOT_FOUND_METHOD: {
                throw new NotFoundMethodException((responseModel.errorText) ?? JSON.stringify(responseModel));
            }
            case ResponseStatusEnum.ACCESS_DENIED: {
                throw new AccessDeniedException((responseModel.errorText) ?? JSON.stringify(responseModel));
            }
            default: {
                throw new ServerErrorException((responseModel.errorText) ?? JSON.stringify(responseModel));
            }
        }
    }

    /**
     * Преобразование JSON строки в некоторый Object
     *
     * @param rawString
     *
     * @protected
     */
    protected convertRawStringToObject(rawString: string): object {
        let object: object | undefined;

        try {
            object = JSON.parse(rawString);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(
                    'Error on handling string, received from server: ' + JSON.stringify(rawString)
                    + '. Error: ' + e.message
                );
            } else {
                throw e;
            }
        }

        return (object === undefined) ? {} : object;
    }

    /**
     * Валидация параметров ответа сервера в соответствии с моделью
     * и конвертация сырого объекта в DTO.
     *
     * @param classType
     * @param jsonRequestData
     */
    protected async transformAndValidateRequestJsonObject<T extends object>(
        classType: ClassType<T>,
        jsonRequestData: object,
    ): Promise<T> {
        try {
            return await ModelValidator
                .validateAndTransformRawRequestData(classType, jsonRequestData);
        } catch (e) {

            if (e instanceof FieldValidationException) {
                throw new Error(
                    'Response validation error: ' + JSON.stringify(e.fieldValidationErrorsList)
                    + ', object: ' + JSON.stringify(jsonRequestData)
                );
            }

            if (e instanceof Error) {
                throw new Error(
                    'Response validation error: ' + e.message
                    + ', object: ' + JSON.stringify(jsonRequestData)
                );
            }

            throw new Error(
                'Response validation error: ' + JSON.stringify(e)
                + ', object: ' + JSON.stringify(jsonRequestData)
            );
        }
    }
}
