import {LocaleEnum} from "../i18n/type";
import {ThemeEnum} from "../../services/theme/ThemeEnum";
import {UserProfileType} from "../user/type";

export enum CommonPersistedActionTypes {
    SET_PROFILE_TYPE = '@@commonPersisted/SET_PROFILE_TYPE',
    SET_AUTH_TOKEN = '@@commonPersisted/SET_AUTH_TOKEN',
    SET_LOCALE = '@@commonPersisted/SET_LOCALE',
    SET_THEME_NAME = '@@commonPersisted/SET_THEME_NAME',
    SET_ALLOWED_TO_USER_CAMERA_EARLY = '@@commonPersisted/SET_ALLOWED_TO_USER_CAMERA_EARLY',
    SET_LAST_AUDIO_INPUT_DEVICE_ID = '@@commonPersisted/setLastAudioInputDeviceId',
    SET_LAST_AUDIO_OUTPUT_DEVICE_ID = '@@commonPersisted/setLastAudioOutputDeviceId',
    SET_LAST_VIDEO_INPUT_DEVICE_ID = '@@commonPersisted/setLastVideoInputDeviceId',
    SET_OTP_EMAIL = '@@commonPersisted/SET_OTP_EMAIL',
    SET_UTM_FOR_SAVE = '@@commonPersisted/SET_UTM_FOR_SAVE',

    CLEAR_ALL_DATA = '@@commonPersisted/CLEAR_ALL_DATA',
}

export interface CommonPersistedState {
    profileType: UserProfileType | null,
    authToken: string | null,
    locale: LocaleEnum | null,
    themeName: ThemeEnum | null,

    // true, если ранее выдавалось разрешение.
    // В этом случае не требуем нажатия кнопки подтверждения перед попыткой проверки доступа к камере.
    allowedToUseCameraEarly: boolean,

    // Предыдущие используемые пользователем медиа устройства для звонка
    lastAudioInputDeviceId: string | null,
    lastAudioOutputDeviceId: string | null,
    lastVideoInputDeviceId: string | null,

    otpEmail: string | null,
    utmForSave: { [id: string]: string } | null
}
