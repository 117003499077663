import {IsNotEmpty, IsString} from "class-validator";

/**
 * DTO созданного урока
 */
export class DtoLessonCreateResponse {
    @IsNotEmpty()
    @IsString()
    lessonId: string;
}
