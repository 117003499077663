import * as React from 'react';
import deepEqual from 'fast-deep-equal';
import * as PropTypes from 'prop-types';

function normalizeHtml(str: string): string {
    return str && str.replace(/&nbsp;|\u202F|\u00A0/g, ' ');
}

function getCaret(el: HTMLElement): {startPos: number, endPos: number} {
    const sel = window.getSelection();

    if ((!sel) || (sel.rangeCount === 0)) {
        return {
            startPos: 0,
            endPos: 0
        };
    }

    const range = sel.getRangeAt(0);
    // const preRange = range.cloneRange();
    // preRange.selectNodeContents(el);
    // preRange.setEnd(range.endContainer, range.endOffset);
    // caretAt = preRange.toString().length;

    return {
        startPos: range.startOffset,
        endPos: range.endOffset
    };
}

function setCaret(el: HTMLElement, startPos: number, endPos: number) {
    // do not move caret if element was not focused
    const isTargetFocused = document.activeElement === el;

    // Place the caret at the end of the element
    let target: Node | null = null;

    if (el.hasChildNodes()) {
        if ((el.childNodes.length === 1) && (el.childNodes[0].nodeType === Node.TEXT_NODE)) {
            target = el.childNodes[0];
        } else {
            // Если ноды есть, но какие-то не те
            el.childNodes.forEach((item) => {
                el.removeChild(item);
            })
        }
    }

    if (target === null) {
        target = document.createTextNode(el.innerHTML);
        el.appendChild(target);
    }

    if (target !== null && target.nodeValue !== null && isTargetFocused) {
        let sel = window.getSelection();

        if ((sel !== null) && (target.nodeValue.length >= startPos) && (target.nodeValue.length >= endPos)) {
            let range = document.createRange();
            range.setStart(target, startPos);
            range.setEnd(target, endPos);

            if (startPos === endPos) {
                range.collapse(true);
            }

            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
}

function replaceCaret(el: HTMLElement) {
    // Place the caret at the end of the element
    const target = document.createTextNode('');
    el.appendChild(target);
    // do not move caret if element was not focused
    const isTargetFocused = document.activeElement === el;
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
        var sel = window.getSelection();
        if (sel !== null) {
            var range = document.createRange();
            range.setStart(target, target.nodeValue.length);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
        if (el instanceof HTMLElement) el.focus();
    }
}

/**
 * A simple component for an html element with editable contents.
 */
export default class ContentEditable extends React.Component<Props> {
    lastHtml: string = this.props.html;
    el: any = typeof this.props.innerRef === 'function' ? {current: null} : React.createRef<HTMLElement>();

    getEl = () => (this.props.innerRef && typeof this.props.innerRef !== 'function' ? this.props.innerRef : this.el).current;

    render() {
        const {tagName, html, innerRef, customAttributes, ...props} = this.props;

        return React.createElement(
            tagName || 'div',
            {
                ...props,
                ref: typeof innerRef === 'function' ? (current: HTMLElement) => {
                    innerRef(current)
                    this.el.current = current
                } : innerRef || this.el,
                onInput: this.emitChange,
                onBlur: this.props.onBlur || this.emitChange,
                onKeyUp: this.props.onKeyUp || this.emitChange,
                onKeyDown: this.props.onKeyDown || this.emitChange,
                contentEditable: !this.props.disabled,
                dangerouslySetInnerHTML: {__html: html}
            });
    }

    shouldComponentUpdate(nextProps: Props): boolean {
        const {props} = this;
        const el = this.getEl();

        // We need not rerender if the change of props simply reflects the user's edits.
        // Rerendering in this case would make the cursor/caret jump

        // Rerender if there is no element yet... (somehow?)
        if (!el) return true;

        // ...or if html really changed... (programmatically, not by user edit)
        if (
            normalizeHtml(nextProps.html) !== normalizeHtml(el.innerHTML)
        ) {
            return true;
        }

        // Handle additional properties
        return props.disabled !== nextProps.disabled ||
            props.tagName !== nextProps.tagName ||
            props.className !== nextProps.className ||
            props.innerRef !== nextProps.innerRef ||
            props.onBlur !== nextProps.onBlur ||
            props.onKeyDown !== nextProps.onKeyDown ||
            props.placeholder !== nextProps.placeholder ||
            !deepEqual(props.style, nextProps.style);
    }

    componentDidMount() {
        if (this.props.customAttributes) {
            const el = this.getEl();
            if (!el) return true;

            for (let key in this.props.customAttributes) {
                el.setAttribute(key, this.props.customAttributes[key]);
            }
        }
    }

    componentDidUpdate() {
        const el = this.getEl();
        if (!el) return;

        // Perhaps React (whose VDOM gets outdated because we often prevent
        // rerendering) did not update the DOM. So we update it manually now.
        if (this.props.html !== el.innerHTML) {
            el.innerHTML = this.props.html;
        }

        this.lastHtml = this.props.html;

        if (this.props.disabled) {
            return;
        }

        const {props} = this;

        if ((props.id) && (window.contentEditableData) && (window.contentEditableData[props.id])) {
            el.innerHTML = window.contentEditableData[props.id].content;
            setCaret(el, window.contentEditableData[props.id].caretStart, window.contentEditableData[props.id].caretEnd);
        } else {
            replaceCaret(el);
        }
    }

    componentWillUnmount() {
        const {props} = this;

        if ((props.id) && (window.contentEditableData) && (window.contentEditableData[props.id])) {
            delete window.contentEditableData[props.id];

            if (Object.keys(window.contentEditableData).length === 0) {
                delete window.contentEditableData;
            }
        }
    }

    emitChange = (originalEvt: React.SyntheticEvent<any>) => {
        const el = this.getEl();
        if (!el) return;

        if (this.props.id) {
            if (window.contentEditableData === undefined) {
                window.contentEditableData = {};
            }

            if (window.contentEditableData[this.props.id] === undefined) {
                window.contentEditableData[this.props.id] = {
                    caretStart: 0,
                    caretEnd: 0,
                    content: ""
                };
            }

            const caretPos = getCaret(el);

            window.contentEditableData[this.props.id].caretStart = caretPos.startPos;
            window.contentEditableData[this.props.id].caretEnd = caretPos.endPos;

            window.contentEditableData[this.props.id].content = el.innerHTML;
        }

        const html = el.innerHTML;
        if (this.props.onChange && html !== this.lastHtml) {
            // Clone event with Object.assign to avoid
            // "Cannot assign to read only property 'target' of object"
            const evt = Object.assign({}, originalEvt, {
                target: {
                    value: html
                }
            });
            this.props.onChange(evt);
        }
        this.lastHtml = html;
    }

    static propTypes = {
        html: PropTypes.string.isRequired,
        id: PropTypes.string,
        onChange: PropTypes.func,
        disabled: PropTypes.bool,
        tagName: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        innerRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.func,
        ]),
        customAttributes: PropTypes.object
    }
}

export type ContentEditableEvent = React.SyntheticEvent<any, Event> & { target: { value: string } };
type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;
type DivProps = Modify<JSX.IntrinsicElements["div"], { onChange: ((event: ContentEditableEvent) => void) }>;

export interface Props extends DivProps {
    html: string,
    disabled?: boolean,
    tagName?: string,
    className?: string,
    style?: Object,
    innerRef?: React.RefObject<HTMLElement> | Function,
    customAttributes?: { [id: string]: string }
}
