import React from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {Modal, ModalControlParams} from "../../../../../Ui/Elements/Modal";
import {BtnStyleEnum, Button} from "../../../../../Ui/Elements/Button";
import {Trans} from "@lingui/macro";
import {PageSubtitle, PageTitle} from "../../../../../../styles/global-elements";
import {RadioGroup, RadioInput} from "../../../../../Ui/Elements/RadioInput";
import {Space} from "antd";
import {DeviceInfo, DeviceKindEnum} from "../../../../../../../services/media-device/Types";
import {IMediaDeviceService} from "../../../../../../../services/media-device/IMediaDeviceService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {DefaultLoader} from "../../../../../DefaultLoader";
import {ErrorLoadingContent} from "../../../../../Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";

const AUDIO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID = 'selectModalAudioInputDeviceSubscriber';
const VIDEO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID = 'selectModalVideoInputDeviceSubscriber';

const DeviceTypeGroup = styled.div`
  margin-bottom: 30px;
`;

const PageSubtitleStyled = styled(PageSubtitle)`
  margin-bottom: 15px;
`;

interface MediaDeviceSelectModalProps {
    trigger: JSX.Element;
    allowMicSection: boolean;
    allowCameraSection: boolean;
}

interface MediaDeviceSelectModalState {
    nowLoading: boolean;
    nowChangeDevice: boolean;
    loadingError: boolean;

    audioInputDevices: DeviceInfo[];
    videoInputDevices: DeviceInfo[];

    selectedAudioInputDevice: DeviceInfo | null;
    selectedVideoInputDevice: DeviceInfo | null;
}

export class MediaDeviceSelectModal extends React.Component<MediaDeviceSelectModalProps, MediaDeviceSelectModalState> {
    protected modalRef: React.RefObject<PopupActions>;

    protected logger: ILogger;
    protected mediaDeviceService: IMediaDeviceService;

    constructor(props: Readonly<MediaDeviceSelectModalProps> | MediaDeviceSelectModalProps) {
        super(props);

        this.modalRef = React.createRef();

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.mediaDeviceService = container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE);

        this.state = {
            nowChangeDevice: false,
            nowLoading: true,
            loadingError: false,

            audioInputDevices: [],
            videoInputDevices: [],

            selectedAudioInputDevice: null,
            selectedVideoInputDevice: null,
        };
    }

    componentDidMount() {
        this.fetchDevices();

        this.mediaDeviceService.registerSubscriber(
            DeviceKindEnum.AUDIO_INPUT,
            AUDIO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID,
            this.audioDeviceOnChange
        );

        this.mediaDeviceService.registerSubscriber(
            DeviceKindEnum.VIDEO_INPUT,
            VIDEO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID,
            this.videoDeviceOnChange
        );
    }

    componentWillUnmount() {
        this.mediaDeviceService.unregisterSubscriber(AUDIO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID);
        this.mediaDeviceService.unregisterSubscriber(VIDEO_INPUT_DEVICE_CHANGE_SUBSCRIBER_ID);
    }

    open = () => {
        this.modalRef.current?.open();
    }

    protected videoDeviceOnChange = (deviceInfo: DeviceInfo) => {
        this.setState(() => {
            return {
                selectedVideoInputDevice: deviceInfo
            }
        })
    }

    protected audioDeviceOnChange = (deviceInfo: DeviceInfo) => {
        this.setState(() => {
            return {
                selectedAudioInputDevice: deviceInfo
            }
        })
    }

    protected fetchDevices = () => {
        this.setState(() => {
            return {
                nowLoading: true,
                loadingError: false
            }
        });

        Promise.all([
            this.mediaDeviceService.getAudioInputDevices(),
            this.mediaDeviceService.getVideoInputDevices(),
            this.mediaDeviceService.getDevicesForCall()
        ])
            .then((value) => {
                const audioDevices = value[0];
                const videoDevices = value[1];
                const devicesForCall = value[2];

                this.setState(() => {
                    return {
                        nowLoading: false,

                        audioInputDevices: audioDevices,
                        videoInputDevices: videoDevices,

                        selectedAudioInputDevice: devicesForCall.audioInput,
                        selectedVideoInputDevice: devicesForCall.videoInput,
                    }
                });
            })
            .catch(() => {
                this.setState(() => {
                    return {
                        nowLoading: false,
                        loadingError: true
                    }
                });
            })
    }

    protected content = (controls: ModalControlParams) => {
        if (this.state.nowLoading) {
            return <DefaultLoader/>;
        }

        if (this.state.loadingError) {
            return <ErrorLoadingContent retryBtnClick={this.fetchDevices}/>
        }

        return <>
            {
                (this.props.allowCameraSection)
                && <DeviceTypeGroup>
                <PageSubtitleStyled>
                  <Trans>Камера</Trans>
                </PageSubtitleStyled>
                <div>
                  <RadioGroup value={this.state.selectedVideoInputDevice?.id}
                              onChange={(e) => {
                                  this.mediaDeviceService.setVideoInputDevice(e.target.value);
                                  controls.closeModal();
                              }}>
                    <Space direction={"vertical"}>
                        {
                            this.state.videoInputDevices.map(item => {
                                return <RadioInput value={item.id} key={item.id}>
                                    {item.name}
                                </RadioInput>
                            })
                        }
                    </Space>
                  </RadioGroup>
                </div>
              </DeviceTypeGroup>
            }

            {
                (this.props.allowMicSection)
                && <DeviceTypeGroup>
                <PageSubtitleStyled>
                  <Trans>Микрофон</Trans>
                </PageSubtitleStyled>
                <div>
                  <RadioGroup value={this.state.selectedAudioInputDevice?.id}
                              onChange={(e) => {
                                  this.mediaDeviceService.setAudioInputDevice(e.target.value);
                                  controls.closeModal();
                              }}>
                    <Space direction={"vertical"}>
                        {
                            this.state.audioInputDevices.map(item => {
                                return <RadioInput value={item.id} key={item.id}>
                                    {item.name}
                                </RadioInput>
                            })
                        }
                    </Space>
                  </RadioGroup>
                </div>
              </DeviceTypeGroup>
            }
        </>
    }

    render() {
        return <Modal closeAllowed={true}
                      innerRef={this.modalRef}
                      clearFocusAfterOpen={true}
                      footer={
                          (controls) => {
                              return <div>
                                  <Button
                                      btnStyle={BtnStyleEnum.Primary}
                                      onClick={controls.closeModal}
                                  >
                                      <Trans>Закрыть</Trans>
                                  </Button>
                              </div>;
                          }
                      }
                      children={
                          (controls) => {
                              return <>
                                  <PageTitle>
                                      <Trans>Настройки</Trans>
                                  </PageTitle>

                                  {this.content(controls)}
                              </>
                          }
                      }
                      trigger={
                          this.props.trigger
                      }
        />
    }
}
