import {IsBoolean, IsNotEmpty, IsNumber, IsString, ValidateNested} from 'class-validator';
import {Type} from 'class-transformer';
import {DtoHometaskOverviewResponse} from "./DtoHometaskOverviewResponse";

/**
 * DTO ответа на запрос предыдущего состоявшегося урока
 */
export class DtoLastLessonItem {
    @IsNotEmpty()
    @IsString()
    lessonDate: string;

    @IsNotEmpty()
    @IsString()
    timeStart: string;

    @IsNotEmpty()
    @IsString()
    timeEnd: string;

    @IsNumber()
    param1: number;

    @IsNumber()
    param2: number;

    @IsNumber()
    param3: number;

    @IsNumber()
    param4: number;

    @IsNumber()
    param5: number;

    @IsNumber()
    totalAverage: number;

    @IsNumber()
    absent: number;

    @IsBoolean()
    isFake: boolean;

    @IsString()
    comment: string;

    @IsNumber()
    financialLosses: number;

    @IsNumber()
    lessonCost: number;

    @IsNumber()
    duration: number;

    @IsNotEmpty()
    @ValidateNested()
    @Type(() => DtoHometaskOverviewResponse)
    hometask: DtoHometaskOverviewResponse | null;
}