import {IsNotEmpty, IsNumber, IsOptional, Max} from 'class-validator';
import {DtoTmDiscipline} from "../TmDisciplines/DtoTmDiscipline";
import {DtoTmSection} from "../TmSection/DtoTmSection";
import {DtoTmLevel} from "./DtoTmLevel";

/**
 * DTO ответа на обзорный запрос уровня обучающих материалов
 */
export class DtoTmLevelOverview {
    @IsNotEmpty()
    disciplineItem: DtoTmDiscipline;

    @IsNotEmpty()
    levelItem: DtoTmLevel;

    @IsOptional()
    sectionsList: DtoTmSection[];

    @IsNotEmpty()
    @IsNumber()
    @Max(32767)
    totalSectionsCount: number;
}
