export const VIDEO_ELEMENT_HEIGHT = 366;
export const VIDEO_ELEMENT_HEIGHT_MOBILE = 200;

export enum ElementStateEnum {
    WAIT_INIT,
    NO_VIDEO,
    WAIT_FOR_UPLOADING,
    UPLOADING_NOW,
    UPLOAD_SUCCESSFULLY,
    UPLOAD_ERROR
}

export const UploadStateList = [
    ElementStateEnum.WAIT_INIT,
    ElementStateEnum.NO_VIDEO,
    ElementStateEnum.WAIT_FOR_UPLOADING,
    ElementStateEnum.UPLOADING_NOW,
    ElementStateEnum.UPLOAD_ERROR
]

export type ElementData = {
    videoId: string | null;
}