import {Reducer} from "redux";
import {UploadProcessDetails, UploadQueueActionTypes, UploadQueueState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<UploadQueueState> = produce((draft: UploadQueueState, action) => {
    switch (action.type) {
        case UploadQueueActionTypes.SET_NEW_UPLOAD_PROCESS: {
            const payloadData: UploadProcessDetails = action.payload as UploadProcessDetails;

            draft.process.push(payloadData);
            draft.indexByFileId[payloadData.fileId] = draft.process.length - 1;

            break;
        }
        case UploadQueueActionTypes.SET_PROCESS_STATE: {
            const payloadData: Partial<UploadProcessDetails> = action.payload as Partial<UploadProcessDetails>;

            if (!payloadData.fileId) {
                throw new Error('Undefined fileId in payload UploadQueueActionTypes.SET_PROCESS_STATE');
            }

            const objectIndex = draft.indexByFileId[payloadData.fileId];

            if (objectIndex === undefined) {
                throw new Error(`Undefined index for fileId "${payloadData.fileId}" upload object.`);
            }

            draft.process[objectIndex] = {
                ...draft.process[objectIndex],
                ...payloadData
            };

            break;
        }
        case UploadQueueActionTypes.SET_CANCEL_UPLOAD_FILE: {
            const objectIndex = draft.indexByFileId[action.payload];

            if (objectIndex === undefined) {
                return;
            }

            if (draft.process[objectIndex] === undefined) {
                return;
            }

            draft.process[objectIndex] = {
                ...draft.process[objectIndex],
                needCancel: true
            };

            break;
        }
        case UploadQueueActionTypes.REMOVE_FROM_QUEUE: {
            const objectIndex = draft.indexByFileId[action.payload];

            if (objectIndex === undefined) {
                return;
            }

            if (draft.process[objectIndex] === undefined) {
                delete draft.indexByFileId[action.payload];

                return;
            }

            draft.process = draft.process.filter(item => item.fileId !== action.payload);

            break;
        }
    }
}, initialState);

export {reducer as uploadQueueReducer}