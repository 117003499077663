import React, {CSSProperties, useMemo} from "react";
import styled from "styled-components";
import {PageBackLink} from "../Ui/Elements/PageBackLink";
import {BlockWithBorderCss, PageTitle} from "../../styles/global-elements";
import classNames from "classnames";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
    
  &.content-on-center{
    justify-content: center;
  }  
  
  @media (${({theme}) => theme.media.large}) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  max-width: 680px;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    //margin-right: 20px;
    padding-bottom: 40px;
  }
    
    &.content-full-width {
        width: 100%;
        max-width: unset;
    }
`;

const RightBlockWrapper = styled.div`
  @media (${({theme}) => theme.media.large}) {
    ${BlockWithBorderCss};
    overflow: hidden;
    max-width: 344px;
    min-width: 344px;

    padding: 32px 32px 0 32px;
    margin: 0;
    display: block;
    
    overflow: unset;
    height: fit-content;

      position: sticky;
      top: ${({theme}) => 100 + theme.size.headerHeight}px;
      
      &.no-title {
          top: ${({theme}) => 43 + theme.size.headerHeight}px;
      }
  }
`;

type BackLinkType = {
    title: string;
    href: string;
}

interface PageWrapperProps {
    pageTitle: React.ReactNode | string | null;
    pageContent: React.ReactNode;
    rightBlockContent: React.ReactNode;
    backLink: null | BackLinkType;
    rightBlockWrapperStyle?: CSSProperties;
    contentOnCenter?: boolean;
    contentFullWidth?: boolean;
}

export const PageWrapper: React.FC<PageWrapperProps> = (
    {
        pageTitle,
        pageContent,
        rightBlockContent,
        backLink,
        rightBlockWrapperStyle,
        contentOnCenter,
        contentFullWidth
    }
) => {
    const backLinkElement = useMemo(() => {
        if (!backLink) {
            return null;
        }

        return <PageBackLink text={backLink.title} link={backLink.href}/>
    }, [backLink]);

    const pageTitleElement = useMemo(() => {
        if (!pageTitle) {
            return null;
        }

        return <PageTitle>{pageTitle}</PageTitle>
    }, [pageTitle]);

    const rightBlockElement = useMemo(() => {
        if (!rightBlockContent) {
            return <div/>;
        }

        return <RightBlockWrapper 
            className={classNames(pageTitle === null && 'no-title')}
            style={rightBlockWrapperStyle}
        >{rightBlockContent}</RightBlockWrapper>;
    }, [pageTitle, rightBlockContent, rightBlockWrapperStyle]);

    return <>
        {backLinkElement}
        {pageTitleElement}
        <Wrapper className={classNames(contentOnCenter && 'content-on-center')}>
            {rightBlockElement}
            <ContentWrapper className={classNames(contentFullWidth && 'content-full-width')}>{pageContent}</ContentWrapper>
        </Wrapper>
    </>
}