import {PluginNameEnum} from "../../PluginNameEnum";
import {LoggerSectionsEnum} from "../../../Logger/LoggerSectionsEnum";
import {StreamTypeEnum, TrackContentHintEnum} from "../../Types";
import {ILogger} from "../../../Logger/ILogger";
import {JanusJS} from "../../OfficialClient/janus";
import {IMediaDeviceService} from "../../../../services/media-device/IMediaDeviceService";
import {DeviceInfo, DeviceKindEnum} from "../../../../services/media-device/Types";
import {cloneDeep} from "lodash";
import {IDeviceMediaStreamFetcher} from "../../../DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {IJanusConnection} from "../../IJanusConnection";
import {PresetIdsEnum} from "../../../DeviceMediaStreamFetcher/PresetIdsEnum";
import {PublisherMidDescriptionUtils} from "../PublisherMidDescriptionUtils";
import {Item as StreamsStoreItem, ItemState, OWN_FEED_ID, StreamsStore} from "../StreamsStore";

const MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID = 'videoRoomPlugin';

enum BitratesEnum {
    CAMERA_BITRATE = 256000,
    SCREEN_VIDEO_BITRATE = 1536000,
    SCREEN_AUDIO_BITRATE = 50000
}

export type PublisherRoomCredentials = {
    roomId: string;
    roomJoinKey: string;
    opaqueId: string;
}

export interface PublisherCallbacks {
    onMyPrivateIdReceived: (myPrivateId: string) => void;
    onProblemWithCodecs: () => void;
    handleDisconnectedParticipants: (feedId: string) => void;
    handleParticipants: (feedsList: JanusJS.PublisherFeed[]) => void;
    slowLinkMessage: (packetsList: number) => void;
    onError: (error: any) => void;
    iceDisconnected: () => void;
    onCameraChanged: (newCamera: DeviceInfo) => void;
    onCameraChangeError: (brokenCamera: DeviceInfo, activeCamera: DeviceInfo | null) => void;
    onCameraGoneAway: (oldCamera: DeviceInfo | null, fatal: boolean) => void;
    successfullyJoined: () => void;
    onCleanup: () => void;
    onDestroy: () => void;
}

export class Publisher {
    protected logger: ILogger;
    protected mediaDeviceService: IMediaDeviceService;
    protected deviceMediaStreamFetcher: IDeviceMediaStreamFetcher;
    protected janusConnection: IJanusConnection;

    protected userId: string;
    protected complexUserId: string;
    protected callbacks: PublisherCallbacks;
    protected roomCredentials: PublisherRoomCredentials;

    protected descriptor: JanusJS.PluginHandle | null;

    protected myId: string; // Точно ли нужно?
    protected myPrivateId: string; // Точно ли нужно?

    protected currentInputDevice: DeviceInfo | null;
    protected onCameraChangedByUserBind;

    protected streamsStore: StreamsStore;

    protected initialized: boolean;

    constructor(
        logger: ILogger,
        mediaDeviceService: IMediaDeviceService,
        deviceMediaStreamFetcher: IDeviceMediaStreamFetcher,
        userId: string,
        complexUserId: string,
        janusConnection: IJanusConnection,
        roomCredentials: PublisherRoomCredentials,
        streamsStore: StreamsStore,
        callbacks: PublisherCallbacks
    ) {
        this.logger = logger;
        this.janusConnection = janusConnection;
        this.mediaDeviceService = mediaDeviceService;
        this.onCameraChangedByUserBind = this.onCameraChangedByUser.bind(this);

        this.subscribeOnCameraChangedByUser();

        this.deviceMediaStreamFetcher = deviceMediaStreamFetcher;

        this.userId = userId;
        this.complexUserId = complexUserId;
        this.callbacks = callbacks;
        this.roomCredentials = roomCredentials;

        this.myId = '';
        this.myPrivateId = '';

        this.streamsStore = streamsStore;

        this.initialized = true;
        this.currentInputDevice = null;
        this.descriptor = null;

        // @ts-ignore
        window['useFreezeForSendDescription'] = () => {
            // @ts-ignore
            window.useFreezeForSendDescriptionValue = true;
        }
    }

    destroy(): void {
        if (!this.initialized) {
            return;
        }

        this.initialized = false;

        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
            'Called destroy proc'
        );

        const streams = this.streamsStore.findAllByUserId(this.userId);
        let foundScreenTracks = false;

        streams.forEach(item => {
            switch (item.type) {
                case StreamTypeEnum.SCREEN: {
                    if (item.track) {
                        item.track.removeEventListener(
                            'ended',
                            this.onScreenStreamEnded
                        );
                    }

                    foundScreenTracks = true;

                    break;
                }
                case StreamTypeEnum.AUDIO: {
                    foundScreenTracks = true;

                    break;
                }
                case StreamTypeEnum.VIDEO: {
                    item.track?.removeEventListener('ended', this.onCameraStreamEnded);
                }
            }
        });

        this.callbacks.onDestroy();

        if (foundScreenTracks) {
            this.deviceMediaStreamFetcher.stopScreenStream();
        }

        if (this.currentInputDevice !== null) {
            this.deviceMediaStreamFetcher.stopCameraStream(this.currentInputDevice.id);
        }

        this.mediaDeviceService
            .unregisterSubscriber(MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID);

        if (this.descriptor) {
            this.descriptor.hangup(true);

            this.descriptor = null;
        }
    }

    public publish(): void {
        this.janusConnection.getInstance().attach({
            plugin: PluginNameEnum.VIDEO_ROOM,
            opaqueId: this.roomCredentials.opaqueId,
            success: (pluginHandle) => {
                this.descriptor = pluginHandle;

                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Plugin attached! (" + this.descriptor.getPlugin() + ", id=" + this.descriptor.getId() + ")");

                this.descriptor.send({
                    message: {
                        request: "join",
                        room: this.roomCredentials.roomId,
                        pin: this.roomCredentials.roomJoinKey,
                        ptype: "publisher",
                        display: this.complexUserId
                    }
                });
            },
            error: (error: string) => {
                this.logger.error(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    "Error attaching plugin: ",
                    error
                );

                this.callbacks.onError("Error attaching plugin: " + JSON.stringify(error));
            },
            consentDialog: (on: boolean) => {
                this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Consent dialog should be " + (on ? "on" : "off") + " now");
            },
            webrtcState: (isConnected: boolean) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Janus says our WebRTC PeerConnection is " + (isConnected ? "up" : "down") + " now");
            },
            iceState: (state) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "ICE state changed to " + state);

                if (state === "failed") {
                    this.callbacks?.iceDisconnected();
                }
            },
            mediaState: (medium, receiving, mid) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Janus " + (receiving ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
            },
            slowLink: (uplink, lost, mid) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Janus reports problems " + (uplink ? "sending" : "receiving") +
                    " packets on mid " + mid + " (" + lost + " lost packets)");

                this.callbacks.slowLinkMessage(lost);
            },
            onmessage: (message, jsep) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Got a message", message);
                const event = message["videoroom"];
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Event: " + event);

                if (event !== undefined && event !== null) {
                    this.handleVideoRoomPublisherPluginEvent(message);
                }

                if (jsep) {
                    // this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Handling SDP as well...", jsep);

                    if (this.descriptor !== null) {
                        this.descriptor.handleRemoteJsep({jsep: jsep});
                    }

                    // Check if any of the media we wanted to publish has
                    // been rejected (e.g., wrong or unsupported codec)
                    // const audio = message["audio_codec"];
                    //
                    // if (!audio) {
                    //     // Audio has been rejected
                    //     this.callbacks.onProblemWithCodecs();
                    // }

                    const video = message["video_codec"];

                    if (!video) {
                        // Video has been rejected
                        this.callbacks.onProblemWithCodecs();
                    }
                }
            },
            onlocaltrack: (track, on) => {
                switch (track.contentHint) {
                    case TrackContentHintEnum.CAMERA_VIDEO: {
                        if (!on) {
                            this.logger.info(
                                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                                `Local camera track removed`
                            );

                            this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.VIDEO);

                            return;
                        }

                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Local camera track added`
                        );

                        this.streamsStore.updateItem(
                            this.userId,
                            OWN_FEED_ID,
                            StreamTypeEnum.VIDEO,
                            null,
                            {
                                state: ItemState.READY,
                                track
                            }
                        );

                        break;
                    }
                    case TrackContentHintEnum.SCREEN_VIDEO: {
                        if (!on) {
                            this.logger.info(
                                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                                `Local screen sharing track removed`
                            );

                            // Удаляем именно в обработчике ended
                            // this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.SCREEN);

                            return;
                        }

                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Local screen sharing track added`
                        );

                        this.streamsStore.updateItem(
                            this.userId,
                            OWN_FEED_ID,
                            StreamTypeEnum.SCREEN,
                            null,
                            {
                                state: ItemState.READY,
                                track
                            }
                        );

                        break;
                    }
                    case TrackContentHintEnum.SCREEN_AUDIO: {
                        if (!on) {
                            this.logger.info(
                                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                                `Local screen sharing audio track removed`
                            );

                            // Удаляем именно в обработчике ended
                            // this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.AUDIO);

                            return;
                        }

                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Local screen sharing audio track added`
                        );

                        this.streamsStore.updateItem(
                            this.userId,
                            OWN_FEED_ID,
                            StreamTypeEnum.AUDIO,
                            null,
                            {
                                state: ItemState.READY,
                                track
                            }
                        );

                        break;
                    }
                    default: {
                        this.logger.warning(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Unknown local track ${(on) ? 'added' : 'removed'}:`,
                            track
                        );
                    }
                }
            },
            onremotetrack: (_track, _mid, _on) => {
                // The publisher stream is sendonly, we don't expect anything here
            },
            oncleanup: () => {
                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    "Got a cleanup notification: we are unpublished now"
                );

                this.callbacks?.onCleanup();
            },
        })
    }

    protected handleVideoRoomPublisherPluginEvent = (message: JanusJS.Message) => {
        const event = message["videoroom"] as string;

        switch (event) {
            case "joined": {
                // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                this.myId = message["id"];
                this.myPrivateId = message["private_id"];

                this.callbacks.onMyPrivateIdReceived(this.myPrivateId);

                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    "Successfully joined room " + message["room"] + " with ID " + this.myId
                );

                // Any new feed to attach to?
                if (message["publishers"]) {
                    this.callbacks.handleParticipants(message["publishers"] as JanusJS.PublisherFeed[]);
                }

                this.callbacks.successfullyJoined();

                // Ничего не публикующие участники
                // Пока игнорируем, т.к. они нам и не нужны
                // if (message["attendees"]) {
                //     this.callbacks.processAvailableAttendees(message["attendees"] as JanusJS.VideoRoomAttendee[]);
                // }

                break;
            }
            case "event": {
                // Any info on our streams or a new feed to attach to?
                if (message["streams"]) {
                    // this.streams = message["streams"];
                } else if (message["publishers"]) {
                    this.callbacks.handleParticipants(message["publishers"] as JanusJS.PublisherFeed[]);
                    // } else if (message["joining"]) {
                    //     Ничего не публикующие участники пока игнорируем, т.к. кажется они и не нужны
                    //     this.callbacks.processAvailableAttendees([message["joining"]] as JanusJS.VideoRoomAttendee[]);
                } else if (message["leaving"]) {
                    // Вызывается, когда МЫ как подписчик отключаемся от комнаты.
                    // Или когда неактивный участник отключается от комнаты.
                    // Нас это не интересует, поэтому не обрабатываем.
                    const leavingFeedId = message["leaving"];

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        "Publisher left (event - leaving): " + leavingFeedId
                    );
                } else if (message["unpublished"]) {
                    // One of the publishers has unpublished?
                    const unpublishedFeedId = message["unpublished"];

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        "Publisher unpublished (event - unpublished): " + unpublishedFeedId
                    );

                    if (unpublishedFeedId === 'ok') {
                        // That's us
                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            "Own feed " + unpublishedFeedId + " has left the room"
                        );

                        return;
                    }

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        "Feed " + unpublishedFeedId + " has left the room"
                    );

                    this.callbacks.handleDisconnectedParticipants(unpublishedFeedId);
                } else if (message["error"]) {
                    if (message["error_code"] === 426) {
                        // This is a "no such room" error: give a more meaningful description
                        this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Not found room")
                    } else {
                        this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, message["error"]);
                    }
                }

                break;
            }

            case "destroyed": {
                // The room has been destroyed
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "The room has been destroyed!");

                break;
            }
        }
    }

    /**
     * @inheritDoc
     */
    public publishCamera = async () => {
        const cameraStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.VIDEO
            );

        if (cameraStream) {
            return;
        }

        this.streamsStore.addItem({
            userId: this.userId,
            feedId: OWN_FEED_ID,
            joinTs: 0,
            type: StreamTypeEnum.VIDEO,
            trackId: null,
            mid: null,
            state: ItemState.PREPARING,
            track: null,
            stream: null
        });

        let track = null;

        try {
            track = await this.getActiveCameraTrack();
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Error on fetch camera stream.`
            );

            this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.VIDEO);

            return;
        }

        if (!(track.capture instanceof MediaStreamTrack)) {
            this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.VIDEO);

            throw new Error('Camera stream is not a MediaStreamTrack');
        }

        this.streamsStore.updateItem(
            this.userId,
            OWN_FEED_ID,
            StreamTypeEnum.VIDEO,
            null,
            {
                trackId: track.capture.id
            }
        );

        this.publishOwnFeed(
            [track],
            this.descriptorCreator,
            undefined,
            () => {
                this.logger.error(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    `Error on publish camera stream.`
                );

                this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.VIDEO);
            }
        );
    }

    public publishScreen = async () => {
        const screenStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.SCREEN
            );

        if (screenStream) {
            return;
        }

        this.streamsStore.addItem({
            userId: this.userId,
            feedId: OWN_FEED_ID,
            joinTs: 0,
            type: StreamTypeEnum.SCREEN,
            trackId: null,
            mid: null,
            state: ItemState.PREPARING,
            track: null,
            stream: null
        });

        let screenShareTracks = null;

        try {
            screenShareTracks = await this.getScreenSharingTracks();
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Error on fetch screen share stream.`
            );

            this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.SCREEN);

            return;
        }

        if (!(screenShareTracks.video.capture instanceof MediaStreamTrack)) {
            this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.SCREEN);

            throw new Error('Screen video stream track is not a MediaStreamTrack');
        }

        this.streamsStore.updateItem(
            this.userId,
            OWN_FEED_ID,
            StreamTypeEnum.SCREEN,
            null,
            {
                trackId: screenShareTracks.video.capture.id
            }
        );

        if (screenShareTracks.audio !== null) {
            if (!(screenShareTracks.audio.capture instanceof MediaStreamTrack)) {
                // Здесь специально удаляем именно StreamTypeEnum.SCREEN, т.к. его мы создали, а AUDIO не создали
                this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.SCREEN);

                throw new Error('Screen audio stream track is not a MediaStreamTrack');
            }

            this.streamsStore.addItem({
                userId: this.userId,
                feedId: OWN_FEED_ID,
                joinTs: 0,
                type: StreamTypeEnum.AUDIO,
                trackId: screenShareTracks.audio.capture.id,
                mid: null,
                state: ItemState.PREPARING,
                track: null,
                stream: null
            });
        }

        const tracks = [
            screenShareTracks.video
        ];

        if (screenShareTracks.audio !== null) {
            tracks.push(screenShareTracks.audio);
        }

        this.publishOwnFeed(
            tracks,
            this.descriptorCreator,
            undefined,
            () => {
                this.logger.error(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    `Error on publish screen sharing stream.`
                );

                this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.SCREEN);
                this.streamsStore.deleteItem(this.userId, OWN_FEED_ID, StreamTypeEnum.AUDIO);
            }
        );
    }

    /**
     * @inheritDoc
     */
    public unpublishCamera = async () => {
        const cameraStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.VIDEO
            );

        if (!cameraStream) {
            return;
        }

        const screenStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.SCREEN
            );

        if (!screenStream) {
            this.unpublishAll();
        } else {
            this.publishOwnFeed([{
                    type: cameraStream.type,
                    // @ts-ignore
                    mid: cameraStream.mid,
                    remove: true
                }],
                this.descriptorCreator,
                undefined,
                () => {
                    this.logger.error(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        `Error on unpublish screen share stream.`
                    );
                }
            );
        }

        if (cameraStream.track) {
            cameraStream.track.removeEventListener('ended', this.onCameraStreamEnded);
            cameraStream.track = null;
        }

        if (this.currentInputDevice) {
            this.deviceMediaStreamFetcher.stopCameraStream(this.currentInputDevice.id);
        }

        this.streamsStore.deleteItem(
            cameraStream.userId,
            cameraStream.feedId,
            cameraStream.type,
            cameraStream.mid ?? undefined
        );
    }

    public unpublishScreen = async () => {
        const screenStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.SCREEN
            );

        if (!screenStream) {
            return;
        }

        const screenAudioStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.AUDIO
            );

        const cameraStream = this
            .streamsStore
            .findByUserIdAndType(
                this.userId,
                StreamTypeEnum.VIDEO
            );

        if (!cameraStream) {
            this.unpublishAll();
        } else {
            const tracks = [{
                type: screenStream.type,
                mid: screenStream.mid,
                remove: true
            }];

            if (screenAudioStream) {
                tracks.push({
                    type: screenAudioStream.type,
                    mid: screenAudioStream.mid,
                    remove: true
                });
            }

            this.publishOwnFeed(
                tracks,
                this.descriptorCreator,
                undefined,
                () => {
                    this.logger.error(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        `Error on unpublish screen share stream.`
                    );
                }
            );
        }

        if (screenStream.track) {
            screenStream.track.removeEventListener('ended', this.onScreenStreamEnded);
        }

        this.deviceMediaStreamFetcher.stopScreenStream();

        this.streamsStore.deleteItem(
            screenStream.userId,
            screenStream.feedId,
            screenStream.type,
            screenStream.mid ?? undefined
        );

        if (screenAudioStream) {
            this.streamsStore.deleteItem(
                screenAudioStream.userId,
                screenAudioStream.feedId,
                screenAudioStream.type,
                screenAudioStream.mid ?? undefined
            );
        }
    }

    protected unpublishAll = () => {
        let unpublish = {request: "unpublish"};
        this.descriptor?.send({message: unpublish});
    }

    protected calcTargetBitrate(localTracks: JanusJS.TrackDesc[]): number {
        let targetBitrate = 0;

        localTracks.forEach(item => {
            if ((item.mid === undefined) || (item.id === undefined)) {
                return;
            }

            const foundInStore = this.streamsStore.findByUserIdTrackId(
                this.userId,
                item.id
            );

            if (foundInStore) {
                switch (foundInStore.type) {
                    case StreamTypeEnum.VIDEO: {
                        targetBitrate += BitratesEnum.CAMERA_BITRATE;

                        break;
                    }
                    case StreamTypeEnum.AUDIO: {
                        targetBitrate += BitratesEnum.SCREEN_AUDIO_BITRATE;

                        break;
                    }
                    case StreamTypeEnum.SCREEN: {
                        targetBitrate += BitratesEnum.SCREEN_VIDEO_BITRATE;

                        break;
                    }
                }
            }
        });

        return targetBitrate;
    }

    protected publishOwnFeed = (
        tracks: JanusJS.Track[],
        descriptionCreator: (trackId: string, mid: string) => string,
        successCb?: Function,
        errorCb?: Function
    ) => {
        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
            `Creating offer`
        );

        this.descriptor?.createOffer(
            {
                trickle: true,
                tracks: tracks,
                customizeSdp: function (jsep) {
                    // enable DTX
                    jsep.sdp = jsep.sdp
                        .replace("useinbandfec=1", "useinbandfec=1;usedtx=1")
                },
                success: async (jsep: JanusJS.JSEP) => {
                    if (!this.descriptor) {
                        throw new Error('Descriptor is undefined');
                    }

                    // @ts-ignore
                    if (window['useFreezeForSendDescriptionValue'] === true) {
                        console.warn('Wait 10sec');
                        await new Promise((resolve) => setTimeout(resolve, 10000));
                        console.warn('Wait 10sec completed');
                    }

                    this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Got publisher SDP!");
                    // this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, jsep);

                    const localTracks = this.descriptor.getLocalTracks();

                    const descriptionList: JanusJS.TrackDescription[] = [];

                    localTracks.forEach(item => {
                        if ((item.mid === undefined) || (item.id === undefined)) {
                            return;
                        }

                        descriptionList.push({
                            mid: item.mid,
                            description: descriptionCreator(item.id, item.mid)
                        });
                    });

                    const targetBitrate = this.calcTargetBitrate(localTracks);

                    this.logger.info(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        `Target bitrate: ${targetBitrate} bit/s`
                    );

                    const publish = {
                        request: "configure",
                        bitrate: targetBitrate,
                        // video: true,
                        descriptions: descriptionList
                    };

                    this.descriptor?.send(
                        {
                            message: publish,
                            jsep: jsep,
                            success: (_data) => {
                                if (successCb) {
                                    successCb();
                                }
                            },
                            error: (error) => {
                                this.logger.error(
                                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                                    "Request 'configure' error:",
                                    error
                                );

                                if (errorCb) {
                                    errorCb();
                                } else {
                                    this.callbacks.onError(
                                        "Request 'configure' error:" + JSON.stringify(error)
                                    );
                                }
                            }
                        }
                    );
                },
                error: (error) => {
                    this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER, "Create offer error:", error);

                    if (errorCb) {
                        errorCb();
                    } else {
                        this.callbacks.onError(
                            "Create offer error:" + JSON.stringify(error)
                        );
                    }
                }
            });
    }

    protected descriptorCreator = (trackId: string, mid: string): string => {
        const foundInStore = this.streamsStore.findByUserIdTrackId(
            this.userId,
            trackId
        );

        if (!foundInStore) {
            this.logger.warning(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `track not found in streamsStore. TrackId: `,
                trackId,
                ' streamsStore: ',
                this.streamsStore
            );

            return '';
        }

        this.streamsStore.updateItem(
            this.userId,
            OWN_FEED_ID,
            foundInStore.type,
            null,
            {
                mid: mid
            }
        );

        return PublisherMidDescriptionUtils.createMid(
            this.userId,
            new Date(),
            foundInStore.type
        );
    }

    protected async getActiveCameraTrack(): Promise<JanusJS.Track> {
        const availableDevices = await this.mediaDeviceService.getDevicesForCall();

        if (!availableDevices.videoInput) {
            throw new Error(`Not found videoInput device`);
        }

        this.currentInputDevice = availableDevices.videoInput;

        const cameraStream = await this.deviceMediaStreamFetcher.getCameraStream(
            PresetIdsEnum.CAMERA_MEDIUM,
            availableDevices.videoInput.id
        );

        const videoTrack = cameraStream.getVideoTracks()[0];

        videoTrack.addEventListener('ended', this.onCameraStreamEnded);

        videoTrack.contentHint = TrackContentHintEnum.CAMERA_VIDEO;

        return {
            type: StreamTypeEnum.VIDEO,
            capture: videoTrack,
            recv: false //TODO: Попробовать сделать false
        };
    }

    protected async getScreenSharingTracks(): Promise<{ video: JanusJS.Track, audio: JanusJS.Track | null }> {
        const stream = await this.deviceMediaStreamFetcher.getScreenStream();

        const videoTrack = stream.getVideoTracks()[0];

        videoTrack.contentHint = TrackContentHintEnum.SCREEN_VIDEO;

        videoTrack.addEventListener('ended', this.onScreenStreamEnded);

        let audioTrack: MediaStreamTrack | null = null;

        if (stream.getAudioTracks().length > 0) {
            audioTrack = stream.getAudioTracks()[0];

            audioTrack.contentHint = TrackContentHintEnum.SCREEN_AUDIO;
        }

        return {
            video: {
                type: StreamTypeEnum.SCREEN,
                capture: videoTrack,
                recv: false
            },
            audio: (audioTrack) ? {
                type: StreamTypeEnum.AUDIO,
                capture: audioTrack,
                recv: false
            } : null
        };
    }

    protected onCameraStreamEnded = () => {
        this.logger.warning(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
            'Camera stream is ended'
        );

        // Попробуем включить другой микрофон
        this.mediaDeviceService.getDevicesForCall()
            .then((devices) => {
                if (devices.videoInput === null) {
                    this.logger.warning(
                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                        'Camera stream in ended and not found another camera in system'
                    );

                    this.callbacks.onCameraGoneAway(this.currentInputDevice, true);

                    return;
                }

                this.logger.warning(
                    LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                    `Try to use camera ${devices.videoInput.name}`
                );

                this.callbacks?.onCameraGoneAway(this.currentInputDevice, false);

                if (this.currentInputDevice) {
                    this.deviceMediaStreamFetcher.stopCameraStream(this.currentInputDevice.id);
                    this.currentInputDevice = null;
                }

                this.onCameraChangedByUser(devices.videoInput);
            });
    }

    protected onCameraChangedByUser = (newDevice: DeviceInfo, thisIsRestartAfterCameraChangeFail?: boolean, cameraChangeFailDeviceInfo?: DeviceInfo) => {
        if (!this.descriptor) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Camera changed by user but descriptor is null`
            );

            return;
        }

        if (thisIsRestartAfterCameraChangeFail) {
            this.logger.info(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Restarting camera ${newDevice.name}`
            );
        } else {
            this.logger.info(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Camera changed by user to ${newDevice.name}, start track replacing`
            );
        }

        let currentCameraTrack: StreamsStoreItem | null = null;
        let currentCameraDevice: DeviceInfo | null = null;

        if (!thisIsRestartAfterCameraChangeFail) {
            // Для своего view указываем, что видео подгатавливается
            this.streamsStore.updateItem(
                this.userId,
                OWN_FEED_ID,
                StreamTypeEnum.VIDEO,
                null,
                {
                    state: ItemState.PREPARING,
                    track: null,
                    stream: null
                }
            );

            // Мьютим видео, чтобы не вызывать пересогласование
            this.descriptor?.muteVideo();

            // Останавливаем камеру, т.к. возможно пользователь хочет не другую камеру, а эту же, но в другом режиме.
            currentCameraTrack = this.streamsStore.findByUserIdAndType(
                this.userId,
                StreamTypeEnum.VIDEO
            );

            if (currentCameraTrack && currentCameraTrack.track) {
                currentCameraTrack.track.removeEventListener('ended', this.onCameraStreamEnded);
            }

            currentCameraDevice = cloneDeep(this.currentInputDevice);

            if (currentCameraDevice) {
                this.deviceMediaStreamFetcher.stopCameraStream(currentCameraDevice.id);
            }
        }

        // Инициализируем новую камеру
        this.getActiveCameraTrack()
            .then((track) => {
                this.currentInputDevice = cloneDeep(newDevice);

                this.descriptor?.replaceTracks({
                    tracks: [
                        {
                            ...track,
                            replace: true
                        }
                    ],
                    success: (_data) => {
                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Replace track to ${newDevice.name} finished.`
                        );

                        // Останавливаем старый поток
                        if (currentCameraDevice && currentCameraDevice.id === newDevice.id) {
                            this.logger.warning(
                                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                                `New device and current device is equal`
                            );

                            return;
                        }

                        // Снимаем мьют, "продолжая" воспроизведение без пересогласования
                        this.descriptor?.unmuteVideo();

                        if (thisIsRestartAfterCameraChangeFail && cameraChangeFailDeviceInfo) {
                            this.callbacks.onCameraChangeError(cameraChangeFailDeviceInfo, newDevice);
                        } else {
                            this.callbacks.onCameraChanged(newDevice);
                        }

                        this.streamsStore.updateItem(
                            this.userId,
                            OWN_FEED_ID,
                            StreamTypeEnum.VIDEO,
                            null,
                            {
                                state: ItemState.READY,
                                track: track.capture as MediaStreamTrack,
                                stream: new MediaStream([track.capture as MediaStreamTrack])
                            }
                        );
                    },
                    error: (error) => {
                        this.logger.error(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Error on replace current track from device ${currentCameraDevice?.name} to track from device ${newDevice.name}`,
                            error
                        );

                        // Останавливаем новый поток

                        // Отключаем событие onended
                        if (track.capture) {
                            (track.capture as MediaStreamTrack).removeEventListener('ended', this.onCameraStreamEnded);
                        }

                        this.streamsStore.deleteItem(
                            this.userId,
                            OWN_FEED_ID,
                            StreamTypeEnum.VIDEO
                        );

                        this.deviceMediaStreamFetcher.stopCameraStream(newDevice.id);

                        this.logger.error(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                            `Track from ${newDevice.name} stopped.`
                        );
                    }
                })
            })
            .catch((err) => {
                // Не удалось инициализировать новую камеру
                this.logger.warning(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                    `Error on change camera by user: `,
                    err
                );

                // Если старой камеры нет, уходим в фатальную ошибку
                if ((currentCameraDevice === null) || (currentCameraTrack === null)) {
                    this.callbacks?.onCameraGoneAway(null, true);

                    return;
                }

                // Пытаемся включить обратно старую камеру
                this.unsubscribeOnCameraChangedByUser();

                this.currentInputDevice = currentCameraDevice;
                this.mediaDeviceService.setVideoInputDevice(currentCameraDevice.id)
                    .then(() => {
                        this.subscribeOnCameraChangedByUser();
                        this.onCameraChangedByUser(currentCameraDevice as DeviceInfo, true, newDevice);
                    })
                    .catch(() => {
                        this.callbacks?.onCameraGoneAway(null, true);
                    });
            });
    }

    protected onScreenStreamEnded = () => {
        if (!this.descriptor) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
                `Screen stream ended by user but descriptor is null`
            );

            return;
        }

        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_PUBLISHER,
            `Screen stream ended by user`
        );

        this.unpublishScreen();
    }

    protected subscribeOnCameraChangedByUser() {
        this.mediaDeviceService
            .registerSubscriber(
                DeviceKindEnum.VIDEO_INPUT,
                MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID,
                this.onCameraChangedByUserBind
            );
    }

    protected unsubscribeOnCameraChangedByUser() {
        this.mediaDeviceService
            .unregisterSubscriber(
                MEDIA_DEVICE_SERVICE_SUBSCRIBER_ID
            );
    }
}
