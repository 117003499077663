import {FooterMode, Modal, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import React, {forwardRef, useContext, useMemo, useState} from "react";
import {PopupActions} from "reactjs-popup/dist/types";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {PageTitle, RegularText} from "../../../../../../styles/global-elements";
import {ItemType} from "../../../../../../components/Ui/Elements/Selectors/AsyncSelect";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../../store/app/selector";
import {IStonlineApiClient} from "../../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {container} from "tsyringe";
import {NoConnection} from "../../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoAnotherParticipantItem
} from "../../../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoAnotherParticipantItem";
import {IStudentPageContext, StudentPageContext} from "../../StudentPageContext";
import {WillBeAffectedToOtherStudents} from "../../../../../../components/WillBeAffectedToOtherStudents";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {Form, useForm} from "../../../../../../components/Ui/Elements/Form";
import {DisciplineAndLevelForm, FormFieldNamesEnum} from "../../../common/DisciplineAndLevelForm";

const Comment = styled(RegularText)`
  margin-bottom: 20px
`;

const WillBeAffectedToOtherStudentsStyled = styled(WillBeAffectedToOtherStudents)`
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  margin-bottom: 100px;
`;

interface DisciplineAndLevelModalProps {
    groupId: number;
    courseId: number | null;
    courseName: string;
    trainingProgramId: number | null;
    trainingProgramName: string;
    anotherParticipants: DtoAnotherParticipantItem[];
}

export const DisciplineAndLevelModal = forwardRef<PopupActions, DisciplineAndLevelModalProps>(
    (
        {
            groupId,
            courseId,
            courseName,
            trainingProgramId,
            trainingProgramName,
            anotherParticipants
        },
        ref
    ) => {
        const [form] = useForm();

        const studentPageContext = useContext<IStudentPageContext>(StudentPageContext);

        const [savingNow, setSavingNow] = useState<boolean>(false);

        const stToken = useSelector(stTokenSelector);

        const stApiClient = useMemo(() => container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT), []);

        const onFinish = async (controls: ModalControlParams, values: any) => {
            if (!stToken) {
                return;
            }

            setSavingNow(true);

            const courseFieldValue = form.getFieldValue(FormFieldNamesEnum.COURSE) as ItemType;
            const tpFieldValue = form.getFieldValue(FormFieldNamesEnum.TRAINING_PROGRAM) as ItemType;

            stApiClient.setTrainingProgramToGroup(
                stToken,
                groupId,
                courseFieldValue.name,
                tpFieldValue.name,
                (courseFieldValue.id) ? parseInt(courseFieldValue.id) : null,
                (tpFieldValue.id) ? parseInt(tpFieldValue.id) : null
            )
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Сохранено`,
                        t`Информация успешно сохранена`
                    );

                    studentPageContext.reloadBaseInfo();
                })
                .catch((e) => {
                    setSavingNow(false);

                    if (e instanceof NoConnection) {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Не удалось применить изменения`,
                            t`Проверьте соединение с интернетом`
                        );

                        return;
                    }

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось применить изменения`,
                        t`Попробуйте повторить попытку позднее`
                    );

                    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                    logger.error(
                        LoggerSectionsEnum.STONLINE_TRAINING_PRROGRAM_API,
                        `Error on save training program for group: `,
                        e
                    );
                });
        }

        return <Modal
            innerRef={ref}
            footerMode={FooterMode.SPACE_BETWEEN}
            onOpen={() => form.resetFields()}
            closeAllowed={!savingNow}
            footer={
                (controls) => {
                    return <>
                        <Button btnStyle={BtnStyleEnum.Primary}
                                loading={savingNow}
                                onClick={() => form.submit()}
                        >
                            <Trans>Сохранить</Trans>
                        </Button>
                        <Button btnStyle={BtnStyleEnum.Secondary}
                                disabled={savingNow}
                                onClick={controls.closeModal}
                        >
                            <Trans>Закрыть</Trans>
                        </Button>
                    </>
                }
            }
            children={(controls) => <>
                <PageTitle tabIndex={0}><Trans>Редактирование дисциплины</Trans></PageTitle>
                <Comment>
                    <Trans>Вы можете изменить название дисциплины и уровня на любое другое. Ученику будет видно
                        указанное значение.</Trans>
                </Comment>
                {
                    (anotherParticipants.length > 0)
                    && <WillBeAffectedToOtherStudentsStyled
                        selectedStudent={{
                            studentId: studentPageContext.studentGeneralInfoDto.id,
                            longName: studentPageContext.studentGeneralInfoDto.longName,
                            gender: studentPageContext.studentGeneralInfoDto.gender,
                        }}
                        students={anotherParticipants.map(item => {
                            return {
                                studentId: item.studentId,
                                longName: item.studentLongName,
                                gender: item.gender,
                            }
                        })}/>
                }
                <FormWrapper>
                    <Form form={form} layout={"inline"}
                          onFinish={(values: any) => onFinish(controls, values)}>
                        <DisciplineAndLevelForm
                            form={form}
                            courseIdInitValue={courseId}
                            courseNameInitValue={courseName}
                            trainingProgramIdInitValue={trainingProgramId}
                            trainingProgramNameInitValue={trainingProgramName}
                        />
                    </Form>
                </FormWrapper>
            </>}
        />
    });