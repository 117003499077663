import {ContentLayoutSettingsBackgroundModeEnum} from "../../../../../enums/ContentLayoutSettingsBackgroundModeEnum";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../../store/fileFormatsRepo/selector";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../../store/fileFormatsRepo/type";
import {UserFileFormatsEnum} from "../../../../../enums/UserFileEnums";
import * as FileFormatsRepoActionCreators from "../../../../../store/fileFormatsRepo/actions";
import {Presenter} from "./Presenter";
import {PageLoadingStateEnum} from "../../../../../enums/PageLoadingStateEnum";
import styled from "styled-components";
import {ThemeEnum} from "../../../../../services/theme/ThemeEnum";

const HiddenImage = styled.img`
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
`;


const BackdropFilter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    backdrop-filter: blur(5px);
    
    &.pave-mode {
      backdrop-filter: unset;
    }
        
    
    @media (${({theme}) => theme.media.small}) {
        background-color: unset !important;
    }

    &.theme-dark {
        background-color: rgba(0, 0, 0, 0.4);
    }

    &.theme-light {
        background-color: rgba(255, 255, 255, 0.75);
    }
}
`;

interface BackgroundImageProps {
    fileId: string;
    backgroundType: ContentLayoutSettingsBackgroundModeEnum;
    themeName: string;
}

export const BackgroundImage: React.FC<BackgroundImageProps> = ({backgroundType, fileId, themeName}) => {
    const dispatch = useDispatch();

    const fileRepoState = useSelector(repoStateSelector);

    const [componentState, setComponentState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [imageFileDownloaded, setImageFileDownloaded] = useState<boolean>(false);

    const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
        if (fileId === null) {
            return null;
        }

        const itemIndex = fileRepoState.indexByFileId[fileId];

        if (itemIndex === undefined) {
            return null;
        }

        return fileRepoState.files[itemIndex] ?? null;
    }, [fileRepoState, fileId]);

    const fileLink = useMemo<string | null>(() => {
        if (currentFileInFileRepo === null) {
            return null;
        }

        if (currentFileInFileRepo.availableFormats.length === 0) {
            return null;
        }

        const file = currentFileInFileRepo.availableFormats.find((item) => {
            return item.formatType === UserFileFormatsEnum.TM_SLIDE_BACKGROUND_PICTURE_ORIGINAL;
        }) ?? null;

        return (file) ? file.url : null;
    }, [currentFileInFileRepo]);

    const backdropFilterClassName = useMemo<string | undefined>(() => {
        return (themeName === ThemeEnum.light ? 'theme-light' : 'theme-dark')
            + ' ' + (backgroundType === ContentLayoutSettingsBackgroundModeEnum.PAVE ? 'pave-mode' : '');
    }, [backgroundType, themeName]);

    useEffect(() => {
        const fetchNewFileInfo = (fileId: string) =>
            dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

        if (fileId === null) {
            setComponentState(PageLoadingStateEnum.ERROR);

            return;
        }

        if (currentFileInFileRepo === null) {
            // Запросим ссылку
            fetchNewFileInfo(fileId);
            setComponentState(PageLoadingStateEnum.LOADING);

            return;
        }

        switch (currentFileInFileRepo.fetchInfoStatus) {
            case FileFetchInfoProcessStatus.WAIT_FOR_START:
            case FileFetchInfoProcessStatus.IN_PROCESS:
            case FileFetchInfoProcessStatus.SUCCESS: {
                break;
            }
            default: {
                setComponentState(PageLoadingStateEnum.ERROR);

                break;
            }
        }
    }, [currentFileInFileRepo, fileId, dispatch]);

    if (componentState === PageLoadingStateEnum.ERROR) {
        return null;
    }

    return <>
        {
            (!imageFileDownloaded) && <HiddenImage src={fileLink ?? ''} onLoad={() => setImageFileDownloaded(true)}/>
        }
        {
            (imageFileDownloaded && fileLink) && <Presenter imageUrl={fileLink} backgroundType={backgroundType}/>
        }
        {
            <BackdropFilter className={backdropFilterClassName}/>
        }
    </>
}
