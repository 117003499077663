import {DeviceInfo} from "../../../services/media-device/Types";
import {ItemState} from "./StreamsStore";

export enum StreamUpdateParams {
    VIDEO,
    SCREEN_VIDEO,
    SCREEN_AUDIO,
    AUDIO_MUTED,
    VIDEO_MUTED,
    CONNECTING
}

export type VideoRoomCallbacks = {
    roomClosed: () => void;
    publisherJoinedSuccessfully: () => void;
    publisherOnHangUp: () => void;
    publisherError: () => void;
    publisherIceDisconnected: () => void;
    publisherCleanup: () => void;
    publisherOnDestroy: () => void;
    subscriberJoinedSuccessfully: () => void;
    subscriberOnHangUp: () => void;
    subscriberError: () => void;
    subscriberIceDisconnected: () => void;
    subscriberCleanup: () => void;
    onTrackStateChange: (userId: string, trackType: StreamUpdateParams, state: ItemState | null) => void;
    publisherSlowLinkMessage: (lostPacketsCount: number) => void;
    subscriberSlowLinkMessage: (lostPacketsCount: number) => void;
    onCameraChanged: (newCamera: DeviceInfo) => void;
    onCameraGoneAway: (oldCamera: DeviceInfo | null, fatal: boolean) => void;
    onCameraChangeError: (brokenCamera: DeviceInfo, activeCamera: DeviceInfo | null) => void;
    onCameraStreamAvailable: (available: boolean) => void;
    onScreenSharingStreamAvailable: (available: boolean) => void;
}

export type JoinVideoRoomParams = {
    roomId: string;
    roomJoinKey: string;
    userId: string;
    callbacks: VideoRoomCallbacks;
}
