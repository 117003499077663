import * as React from "react";
import styled, {keyframes} from 'styled-components';
import {ReactComponent as LogoMask} from "../../components/Ui/Svg/SpinnerLogoMask.svg";

const LoaderSizePx = 240;

const PageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.backgroundSecondary};
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: ${LoaderSizePx}px;
  height: ${LoaderSizePx}px;
  overflow: hidden;
`;

const GradientShapeKeyFrames = keyframes`
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const GradientShape = styled.div`
  position: absolute;
  top: 0;
  left: 80px;

  background: conic-gradient(from 180deg at 50% 50%, #FF6B00 0deg, #A54AFF 73.13deg, #199EFF 146.25deg, #19FFF1 221.25deg, #FFC400 288.75deg, #FF8A00 340deg, #FF6B00 360deg);
  height: ${LoaderSizePx}px;
  width: ${LoaderSizePx}px;
  border-radius: ${LoaderSizePx / 2}px;

  animation-name: ${GradientShapeKeyFrames};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const Mask = styled(LogoMask)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${LoaderSizePx}px;
  height: ${LoaderSizePx}px;
  color: ${props => props.theme.colors.backgroundSecondary};
`;


export const SplashScreen: React.FC = () => {
    return (
        <PageWrapper>
            <LoaderWrapper>
                <GradientShape/>
                <Mask/>
            </LoaderWrapper>
        </PageWrapper>
    );
}