import React from "react";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LessonPageContext} from "../../LessonPageContext";
import {Dispatch} from "redux";
import {ApplicationState} from "../../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../../components/Ui/Elements/NoticeBlock";
import {Trans} from "@lingui/macro";
import {IWsApiClient} from "../../../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../../../components/WsApiClient/ApiMethodEnum";
import {
    DtoGetStudentsHomeworkDataResult
} from "../../../../../../components/WsApiClient/ApiDto/Response/Homework/DtoGetStudentsHomeworkDataResult";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {WsResponseStatusEnum} from "../../../../../../components/WsApiClient/WsResponseStatusEnum";
import {
    DtoStudentHomeworkDataItem
} from "../../../../../../components/WsApiClient/ApiDto/Response/Homework/DtoStudentHomeworkDataItem";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {WsConnectionStatusEnum} from "../../../../../../components/WsApiClient/WsConnectionStatusEnum";
import {processStudentHomeworkDataItemsList} from "../../../../../../store/slidesWorkData/actions";
import StudentHomeworkResultsBySlides from "../LessonHomeworkResultsMode/StudentHomeworkResultsBySlides";

enum PageState {
    NOT_INIT,
    LOADING,
    LOADING_ERROR,
    READY
}

interface LessonHomeworkResultsModePageProps extends PropsFromRedux {
    // Значение передаётся через пропсы, а не читается из контекста,
    // чтобы отслеживать его изменение через componentDidUpdate
    homeworkIsAvailableForStudents: boolean;
}

interface LessonHomeworkResultsModePageState {
    pageState: PageState;
}

class LessonHomeworkResultsModePage extends React.Component<LessonHomeworkResultsModePageProps, LessonHomeworkResultsModePageState> {

    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<LessonHomeworkResultsModePageProps> | LessonHomeworkResultsModePageProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        this.state = {
            pageState: PageState.NOT_INIT
        }
    }

    componentDidMount() {
        if (this.props.homeworkIsAvailableForStudents) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps: Readonly<LessonHomeworkResultsModePageProps>, prevState: Readonly<LessonHomeworkResultsModePageState>, snapshot?: any) {
        const wsConnectedFromNow = this.props.wsIsConnected && !prevProps.wsIsConnected;
        const homeworkAvailableFromNow = this.props.homeworkIsAvailableForStudents && !prevProps.homeworkIsAvailableForStudents;

        if (wsConnectedFromNow || homeworkAvailableFromNow) {
            this.fetchData();
        }
    }

    protected fetchData() {
        if (this.props.apiToken === null) {
            return;
        }

        const lessonRoomId = this.context.lessonRoomId;

        if (lessonRoomId === null) {
            this.setState(() => {
                return {
                    pageState: PageState.LOADING_ERROR
                }
            });

            return;
        }

        this.wsClient.queryAsPromise(
            ApiMethodEnum.HOMEWORK_TEACHER_GET_STUDENTS_HOMEWORK_SUMMARY,
            {
                lessonId: lessonRoomId
            },
            DtoGetStudentsHomeworkDataResult
        )
            .then((data) => {
                if (data.response.status !== WsResponseStatusEnum.OK) {
                    throw new Error(
                        `Response status is ${data.response.status}, message: ${JSON.stringify(data.response.errors)}`
                    );
                }

                this.props.processStudentHomeworkDataItemsList(lessonRoomId, data.response.result.list);

                this.setState(() => {
                    return {
                        pageState: PageState.READY,
                    }
                });

            })
            .catch((err) => {
                this.logger.error(LoggerSectionsEnum.HOMEWORK_ROOM, 'Error on load students homework results: ', err);
            });
    }

    protected content() {
        switch (this.state.pageState) {
            case PageState.LOADING: {
                return <DefaultLoader/>;
            }
            case PageState.LOADING_ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => this.fetchData()}/>;
            }
            case PageState.READY: {
                return <StudentHomeworkResultsBySlides/>;
            }
        }

        return null;
    }

    render() {
        if (!this.props.homeworkIsAvailableForStudents) {
            return <div>
                <NoticeBlock>
                    <>
                        <NoticeBlockTitle>
                            <Trans>Результатов ещё нет</Trans>
                        </NoticeBlockTitle>
                        <NoticeBlockText>
                            <Trans>Результаты появятся когда домашняя работа будет отправлена ученикам</Trans>
                        </NoticeBlockText>
                    </>
                </NoticeBlock>
            </div>
        }

        return <div>
            {this.content()}
        </div>
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
    processStudentHomeworkDataItemsList: (lessonId: string, items: DtoStudentHomeworkDataItem[]) =>
        dispatch(processStudentHomeworkDataItemsList({lessonId, items}))
});

const mapStateToProps = ({user, app}: ApplicationState) => ({
    userId: (user.profileData?.id) ?? null,
    stToken: user.stToken,
    apiToken: user.sessionToken,
    wsIsConnected: app.wsConnectionStatus === WsConnectionStatusEnum.AUTHORIZED
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LessonHomeworkResultsModePage);
