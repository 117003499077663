import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {EditorData, EditorDataItem, EditorItemInteractivityConfig} from "../SlidePlayerEditorCommonParts/EditorData";
import {arrayMove} from "@dnd-kit/sortable";
import {ElementWrapper} from "./element-wrapper/ElementWrapper";
import styled from "styled-components";
import {ButtonState, Toolbar} from "./components/Toolbar";
import {ItemRefMethodsInterface} from "./components/ItemPropsInterface";
import {ToolbarButtonEnum} from "./components/Toolbar/ToolbarButtonEnum";
import {cloneDeep} from "lodash";
import {container} from "tsyringe";
import {IClipboardHelper} from "../../../components/ClipboardHelper/IClipboardHelper";
import {DiTokens} from "../../../di-factory/DiTokens";
import {NotificationTypesEnum, openNotification} from "../Ui/Elements/Notification";
import {ExerciseCopyToBuffer} from "./components/ExerciseCopyToBuffer";
import {t} from "@lingui/macro";

/**
 * Корневой компонент редактора слайдов
 */

interface SlideEditorProps {
    contentOnChange: (content: EditorData) => void;
}

export interface ExercisesCountInSlideItem {
    slideItemId: string;
    exercisesCount: number;
}

export interface SlideEditorRefMethods {
    putElement: (element: EditorDataItem<any>) => void;
    reloadInitialData: (newData: EditorData) => void;
    getExercisesCount: () => ExercisesCountInSlideItem[];
}

const EditorStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToolbarStyled = styled.div`
  background: ${({theme}) => theme.colors.backgroundPrimary};
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight.toString() + 'px'};
  z-index: ${({theme}) => theme.zIndices.pageContent + 1};
`;

const EditAreaStyled = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
`;

const EditElementsWrapper = styled.div`
  padding: 0 10px;

  @media (${({theme}) => theme.media.large}) {
    max-width: 700px;
    min-width: 700px;
    align-self: center;
  }
`;

type ToolbarConfigs = { [id: string]: ButtonState[] };

export const SlideEditor = forwardRef<SlideEditorRefMethods, SlideEditorProps>(
    (props, ref) => {

        const [slideData, setSlideData] = useState<EditorData>(new EditorData());
        const [lastFocusedElementIndex, setLastFocusedElementIndex] = useState<number | null>(null);
        const [lastFocusedElementId, setLastFocusedElementId] = useState<string | null>(null);
        const [toolbarConfigs, setToolbarConfigs] = useState<ToolbarConfigs>({});

        const elementRefs = useRef<{ [id: string]: ItemRefMethodsInterface | null }>({});

        const {contentOnChange} = props;

        const currentRef = useMemo(() => {
            if (lastFocusedElementId === null) {
                return null;
            }

            return elementRefs.current[lastFocusedElementId];
        }, [lastFocusedElementId]);

        const currentToolbarConfig = useMemo(() => {
            if (lastFocusedElementId === null) {
                return null;
            }

            if (!toolbarConfigs[lastFocusedElementId]) {
                return null;
            }

            return toolbarConfigs[lastFocusedElementId];
        }, [lastFocusedElementId, toolbarConfigs]);

        const setToolbarConfigById = useCallback((elementId: string, buttonState: ButtonState[]) => {
            setToolbarConfigs((state): ToolbarConfigs => {
                let newToolbarConfigs = {
                    ...state
                };

                newToolbarConfigs[elementId] = buttonState;

                return newToolbarConfigs;
            });
        }, [setToolbarConfigs]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            putElement: (element: EditorDataItem<any>) => {
                let items = slideData.items;

                if (lastFocusedElementIndex === null) {
                    items.push(element);
                } else {
                    items.splice(lastFocusedElementIndex + 1, 0, element);
                }

                const newSlideData = new EditorData();

                newSlideData.items = [
                    ...items
                ];

                setSlideData(newSlideData);
                contentOnChange(newSlideData);
            },
            reloadInitialData: (newData) => {
                setLastFocusedElementIndex(null);
                setLastFocusedElementId(null);

                const newSlideData = new EditorData();

                newSlideData.items = cloneDeep([...newData.items]);

                setSlideData(newSlideData);
            },
            getExercisesCount: () => {
                let result: ExercisesCountInSlideItem[] = [];

                if (!elementRefs.current) {
                    return result;
                }

                slideData.items.forEach((item) => {
                    const ref = elementRefs.current[item.id];

                    if (!ref) {
                        return;
                    }

                    result.push({
                        slideItemId: item.id,
                        exercisesCount: ref.getExercisesCount()
                    })
                });

                return result;
            }
        }));

        const copyElementIntoBuffer = (index: number) => {
            const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

            const element = slideData.items[index];

            ExerciseCopyToBuffer
                .prepareToCopy(element)
                .then((string) => {
                    return clipboardHelper.copyText(string);
                })
                .then(() => {
                    openNotification(
                        NotificationTypesEnum.SUCCESS,
                        t`Данные скопированы`,
                        t`Вы можете вставить элемент на другой слайд используя "Вставить из буфера" в списке элементов`
                    );
                }).catch(() => {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось скопировать информацию`
                );
            });
        }

        // Перемещение элемента относительно других элементов
        const moveItem = (index: number, newIndex: number) => {
            let items = slideData.items;

            const newItems = arrayMove(items, index, newIndex);

            const newSlideData = new EditorData();

            newSlideData.items = [
                ...newItems
            ];

            setSlideData(newSlideData);
            contentOnChange(newSlideData);
        }

        // Изменение контента элемента
        const elementOnChange = (index: number, content: EditorDataItem<any>) => {
            let newItems = [
                ...slideData.items
            ];

            newItems[index].data = content;

            const newSlideData = new EditorData();

            newSlideData.items = [
                ...newItems
            ];

            setSlideData(newSlideData);
            contentOnChange(newSlideData);
        }

        const interactivityConfigOnChange = (index: number, visibility: boolean, slideItemAlias: string | null, interactivityConfig: EditorItemInteractivityConfig | null) => {
            let newItems = [
                ...slideData.items
            ];

            newItems[index].params.visible = visibility;
            newItems[index].alias = slideItemAlias;
            newItems[index].interactivityConfig = (interactivityConfig === null) ? null : cloneDeep(interactivityConfig);

            const newSlideData = new EditorData();

            newSlideData.items = [
                ...newItems
            ];

            setSlideData(newSlideData);
            contentOnChange(newSlideData);
        }

        // Удаление элемента из списка элементов
        const deleteItem = (index: number) => {
            let newItems = [
                ...slideData.items
            ];

            /*const deletedItem = */
            newItems.splice(index, 1);

            const newSlideData = new EditorData();

            newSlideData.items = [
                ...newItems
            ];

            setSlideData(newSlideData);
            contentOnChange(newSlideData);

            setLastFocusedElementId(null);
        }

        const toolbarItemOnToggle = (buttonType: ToolbarButtonEnum, newValue: boolean) => {
            if (currentRef === null) {
                return;
            }

            currentRef.toolbarItemOnToggle(buttonType, newValue);
        }

        return <EditorStyled>
            <ToolbarStyled><Toolbar buttonConfig={currentToolbarConfig}
                                    toolbarItemOnToggle={toolbarItemOnToggle}
            /></ToolbarStyled>
            <EditAreaStyled>
                <EditElementsWrapper>
                    {
                        slideData.items.map((slideItem, slideItemIndex) => {
                            return <ElementWrapper tabIndex={slideItemIndex}
                                                   ref={ref => elementRefs.current[slideItem.id] = ref}
                                                   key={slideItem.id}
                                                   slideElementData={slideItem}
                                                   contentOnChange={(content) => elementOnChange(slideItemIndex, content)}
                                                   copyElement={() => copyElementIntoBuffer(slideItemIndex)}
                                                   moveToBottom={() => moveItem(slideItemIndex, slideItemIndex + 1)}
                                                   moveToTop={() => moveItem(slideItemIndex, slideItemIndex - 1)}
                                                   deleteItem={() => deleteItem(slideItemIndex)}
                                                   onFocused={() => {
                                                       setLastFocusedElementIndex(slideItemIndex);
                                                       setLastFocusedElementId(slideItem.id);
                                                   }}
                                                   setToolbarConfigById={setToolbarConfigById}
                                                   interactivityConfigOnChange={
                                                       (visibility, slideItemAlias, interactivityConfig) =>
                                                           interactivityConfigOnChange(slideItemIndex, visibility, slideItemAlias, interactivityConfig)
                                                   }
                            />;
                        })
                    }
                </EditElementsWrapper>
            </EditAreaStyled>
        </EditorStyled>;
    })