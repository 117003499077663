import {Editor, Element as SlateElement, Transforms} from "slate";
import {elementTypeIsActive} from "../CommonMethods";
import {IRadioList} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IRadioList";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

export const toggleCheckboxList = (editor: Editor) => {
    const isActive = elementTypeIsActive(editor, ElementTypes.EXERCISE_CHECKBOX_LIST);

    const targetIsList = true;

    Transforms.unwrapNodes(editor, {
        match: (node) => {
            if (Editor.isEditor(node)) {
                return false;
            }

            if (!SlateElement.isElement(node)) {
                return false;
            }

            // кажется, это можно убрать
            if (![ElementTypes.EXERCISE_CHECKBOX_LIST].includes(node.type)) {
                return false;
            }

            return !(Object.values(AlignTypes) as Array<string>).includes(ElementTypes.EXERCISE_CHECKBOX_LIST);
        },
        split: true,
    })

    let newProperties: Partial<SlateElement>;

    newProperties = {
        type:
            isActive
                ? ElementTypes.PARAGRAPH
                : targetIsList
                    ? ElementTypes.EXERCISE_CHECKBOX_LIST_ITEM
                    : ElementTypes.EXERCISE_CHECKBOX_LIST,
    }

    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && targetIsList) {
        const block: IRadioList = {
            id: ExerciseIdGenerator.generateId(),
            type: ElementTypes.EXERCISE_CHECKBOX_LIST,
            children: []
        }

        Transforms.wrapNodes(editor, block);
    }

    editor.selection = null;
}