import YouTube from "react-youtube";
import React, {CSSProperties, useMemo, useState} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {PresenterPlaceholder, PresenterPlaceholderShowModeEnum} from "./PresenterPlaceholder";
import {t} from "@lingui/macro";

const IFRAME_CLASS_NAME = 'youtube-frame';

const PlayerWrapper = styled.div`
  position: relative;
`;

const YoutubeIFrameWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.3s ease;
  min-height: 100px;

  &.ready, &.error {
    opacity: 1;
  }

  iframe {
    width: 100%;
  }
`;

interface YouTubePlayerProps {
    videoId: string | null;
    height?: number | null;
    onPlayerReady?: () => void;
}

enum PlayerLoadingState {
    LOADING,
    ERROR,
    READY
}

export const YouTubePlayer: React.FC<YouTubePlayerProps> = (props) => {
    const [youtubePlayerState, setYoutubePlayerState] = useState<PlayerLoadingState>(PlayerLoadingState.LOADING);

    const onReady = () => {
        setYoutubePlayerState(PlayerLoadingState.READY);

        if (props.onPlayerReady !== undefined) {
            props.onPlayerReady();
        }
    }

    const wrapperStyle = useMemo<CSSProperties>((): CSSProperties => {
        if (youtubePlayerState !== PlayerLoadingState.LOADING) {
            return {};
        } else {
            if ((props.height !== null) && (props.height !== undefined)) {
                return {
                    minHeight: props.height.toString(10) + 'px'
                }
            } else {
                return {};
            }
        }
    }, [props.height, youtubePlayerState]);

    return <PlayerWrapper style={wrapperStyle}>
        {
            (
                (youtubePlayerState === PlayerLoadingState.LOADING)
            ) && <PresenterPlaceholder
                showMode={
                    (youtubePlayerState !== PlayerLoadingState.LOADING)
                        ? PresenterPlaceholderShowModeEnum.LOADING_ERROR
                        : PresenterPlaceholderShowModeEnum.WAIT_FOR_LOADING
                }
                text={
                    (youtubePlayerState !== PlayerLoadingState.LOADING)
                        ? t`Не удалось загрузить видео`
                        : t`Видео загружается...`
                }/>
        }
        <YoutubeIFrameWrapper className={
            classNames(
                youtubePlayerState === PlayerLoadingState.LOADING && "loading",
                youtubePlayerState === PlayerLoadingState.ERROR && "error",
                youtubePlayerState === PlayerLoadingState.READY && "ready",
            )
        }>
            {
                (props.videoId) ? <YouTube iframeClassName={IFRAME_CLASS_NAME}
                                           videoId={props.videoId}
                                           onReady={onReady}
                                           onError={() => setYoutubePlayerState(PlayerLoadingState.ERROR)}
                /> : <div style={{width: "100%", height: "200px"}}/>
            }
        </YoutubeIFrameWrapper>
    </PlayerWrapper>;
}