import React, {useMemo} from "react";
import styled from "styled-components";
import {ReactComponent as PencilIcon} from "../../../Ui/Svg/Pencil.svg";
import {ReactComponent as FolderIcon} from "../../../Ui/Svg/Folder.svg";
import {ReactComponent as FolderWithOutlineDotIcon} from "../../../Ui/Svg/FolderWithOutlineDot.svg";
import {ReactComponent as FolderWithCheckIcon} from "../../../Ui/Svg/FolderWithCheck.svg";
import {Trans} from "@lingui/macro";
import {ModalResultType, TmDisciplineModal} from "../../modals/discipline-modal";
import {RegularTextCss} from "../../../../styles/global-elements";
import {DtoTmDiscipline} from "../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";

const DisciplineTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const DisciplineTitleIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const DisciplineTitleTextWrapper = styled.div`
  ${RegularTextCss};

  flex-grow: 1;
  padding-top: 7px;
  padding-left: 5px;
`;

const DisciplineTitleEditBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export enum DisciplineIconTypeEnum {
    NO_USED,
    USED,
    LAST_USED
}

interface DisciplineTitleProps {
    selectedDiscipline: DtoTmDiscipline;
    totalLevelsCount: number | null;
    organizationId: string
    modalOnResult: (action: ModalResultType, item: (DtoTmDiscipline | null)) => void;
    selectMode?: boolean;
    iconType?: DisciplineIconTypeEnum;
}

export const DisciplineTitle: React.FC<DisciplineTitleProps> = (props) => {
    const icon = useMemo<JSX.Element>(() => {
        switch (props.iconType) {
            case DisciplineIconTypeEnum.LAST_USED: {
                return <FolderWithOutlineDotIcon/>;
            }
            case DisciplineIconTypeEnum.USED: {
                return <FolderWithCheckIcon/>;
            }
            default: {
                return <FolderIcon/>;
            }
        }
    }, [props.iconType]);

    return <DisciplineTitleWrapper>
        <DisciplineTitleIconWrapper>{icon}</DisciplineTitleIconWrapper>
        <DisciplineTitleTextWrapper>
            <Trans>{props.selectedDiscipline.name}</Trans>
            {/*{*/}
            {/*    (props.totalLevelsCount !== null)*/}
            {/*    && <TopIndex>{props.totalLevelsCount}</TopIndex>*/}
            {/*}*/}
        </DisciplineTitleTextWrapper>
        {
            (props.selectMode !== true)
            && <DisciplineTitleEditBtnWrapper>
                <TmDisciplineModal
                    organizationId={props.organizationId}
                    disciplineItem={props.selectedDiscipline}
                    result={props.modalOnResult}
                    trigger={<PencilIcon/>}/>
            </DisciplineTitleEditBtnWrapper>
        }
    </DisciplineTitleWrapper>;
}