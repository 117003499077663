import React, {forwardRef} from "react";
import styled from "styled-components";

const AudioElement = styled.audio`
  display: none;
`;

interface AudioPlayerProps {
    className?: string;
}

export const AudioPlayer = forwardRef<HTMLAudioElement, AudioPlayerProps>((
    {className},
    ref
) => {
    return <AudioElement className={className} ref={ref} autoPlay={true}/>
});
