import React from "react";
import styled from "styled-components";
import {Avatar} from "./Avatar";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ITheme} from "../../../../services/theme/ITheme";
import useMediaQuery from "../../../../services/hooks/useMediaQuery";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AvatarWrapper = styled.div`
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    margin-right: 12px;

    @media (${({theme}) => theme.media.small}) {
        min-width: 80px;
        min-height: 80px;
        max-width: 80px;
        max-height: 80px;
    }
`;

const TextWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 12px;
  line-height: 16px;

  @media (${({theme}) => theme.media.small}) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const NameWrapper = styled.div`
    font-size: 14px;
    line-height: 16px;

    overflow: hidden;
    margin-bottom: 4px;

    color: ${({theme}) => theme.colors.textPrimary};

    @media (${({theme}) => theme.media.small}) {
        font-size: 24px;
        line-height: 28px;
    }
`;

const PositionWrapper = styled.div`
  overflow: hidden;
  font-size: inherit;
  color: ${({theme}) => theme.colors.textSecondary};
`;

interface AvatarBlockProps {
    avatarFileId: string | null;
    name: string;
    position?: string;
    className?: string;
}

export const AvatarNameBlock: React.FC<AvatarBlockProps> = (props) => {

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;
    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    return <Wrapper className={props.className}>
        <AvatarWrapper>
            <Avatar size={(isSmall)?80:40} userName={props.name} fileId={props.avatarFileId} noBorder={true}/>
        </AvatarWrapper>
        <TextWrapper>
            <NameWrapper>{props.name}</NameWrapper>
            <PositionWrapper>{(props.position) ?? ""}</PositionWrapper>
        </TextWrapper>
    </Wrapper>
}