import {forwardRef, useImperativeHandle, useMemo} from "react";
import {IRichTextElementRefMethods} from "../../IRichTextElementRefMethods";
import {IRichTextElementProps} from "../../IRichTextElementProps";
import styled from "styled-components";
import {RichTextReader} from "../../RichTextReader";
import {IBaseElement} from "../../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {HeadingOne as HeadingOneCss} from "../common-styles";
import {AlignTypes} from "../../../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

const HeadingOneStyled = styled.h1`
  ${HeadingOneCss};

  margin-bottom: 0;
  
  color: ${({theme}) => theme.colors.textPrimary};

  &.align-right {
    text-align: right;
  }

  &.align-justify {
    text-align: justify;
  }

  &.align-center {
    text-align: center;
  }

  &.align-left {
    text-align: left;
  }
`;

export const HeadingOne = forwardRef<IRichTextElementRefMethods, IRichTextElementProps<IBaseElement>>(
    (props, ref) => {

        const {element, ...otherProps} = props;

        useImperativeHandle(ref, () => ({}));

        const alignClass = useMemo(() => {
            switch (element.align) {
                case AlignTypes.RIGHT: {
                    return "align-right";
                }
                case AlignTypes.JUSTIFY: {
                    return "align-justify";
                }
                case AlignTypes.CENTER: {
                    return "align-center";
                }
                default: {
                    return "align-left";
                }
            }
        }, [element]);

        return <HeadingOneStyled className={alignClass}>
                <RichTextReader elements={element.children} {...otherProps}/>
            </HeadingOneStyled>;
    }
);

HeadingOne.displayName = 'HeadingOne';