import {IJanusConnection} from "./IJanusConnection";
import {JanusJS} from "./OfficialClient/janus";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class JanusConnection implements IJanusConnection {
    protected logger: ILogger;

    protected serverUrl: string | null;
    protected iceServers: RTCIceServer[] | null;
    protected userApiToken: string | null;
    protected disconnectPeriodSec: number;

    protected janusInstance: JanusJS.Janus | null;

    constructor(logger: ILogger, disconnectPeriodSec: number) {
        this.logger = logger;
        this.disconnectPeriodSec = disconnectPeriodSec;

        this.serverUrl = null;
        this.iceServers = null;
        this.userApiToken = null;
        this.janusInstance = null;
    }

    /**
     * @inheritDoc
     */
    async init(wsApiUrl: string, userApiToken: string, iceServers: RTCIceServer[], errorHandler?: (e: any) => void): Promise<void> {
        if (this.janusInstance !== null) {
            throw new Error('Already initialized');
        }

        this.serverUrl = wsApiUrl;
        this.iceServers = iceServers;
        this.userApiToken = userApiToken;

        await new Promise<void>((resolve, reject) => {
            JanusJS.Janus.init({
                debug: ['warn', 'error'],
                disconnectPeriodSec: this.disconnectPeriodSec,
                callback: () => {
                    if (!JanusJS.Janus.isWebrtcSupported()) {
                        reject(new Error("Start Janus init is not supported browser"));
                    }

                    resolve();
                }
            })
        });

        let resolvedBefore = false;

        this.janusInstance = await new Promise<JanusJS.Janus>((resolve, reject) => {
            const instance = new JanusJS.Janus(
                {
                    server: wsApiUrl,
                    iceServers: iceServers,
                    token: this.userApiToken ?? undefined,
                    error: (error) => {
                        this.janusInstance = null;

                        this.logger.error(LoggerSectionsEnum.JANUS_CONNECTION, error);

                        if (resolvedBefore) {
                            if (errorHandler) {
                                errorHandler(error);
                            }

                            return;
                        }

                        reject(error);
                    },
                    //destroyed: this.janusClientOnDestroyed,
                    success: () => {
                        this.logger.info(LoggerSectionsEnum.JANUS_CONNECTION, 'Janus connection opened');

                        resolvedBefore = true;

                        resolve(instance);
                    }
                }
            )
        });
    }

    /**
     * @inheritDoc
     */
    isInitialized(): boolean {
        return this.janusInstance !== null;
    }

    /**
     * @inheritDoc
     */
    getInstance(): JanusJS.Janus {
        if (this.janusInstance === null) {
            throw new Error('Instance is null');
        }

        return this.janusInstance;
    }

    /**
     * @inheritDoc
     */
    destroy(): Promise<void> {
        return new Promise((resolve,reject) => {
            if (this.janusInstance === null) {
                reject(new Error('Connection already destroyed'));

                return;
            }

            this.janusInstance.destroy({
                unload: true,
                notifyDestroyed: true,
                cleanupHandles: true,
                success: () => {
                    this.logger.info(LoggerSectionsEnum.JANUS_CONNECTION, 'Connection destroyed');

                    resolve();
                }
            });

            this.janusInstance = null;
        });
    }

    /**
     * @inheritDoc
     */
    webRtcSupported() {
        return JanusJS.Janus.isWebrtcSupported();
    }
}
