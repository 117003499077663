import {VcsStateType} from "./types";
import {LessonConferenceStatusEnum} from "../../views/components/LessonConference/contexts/CommonContext";
import {cloneDeep} from "lodash";
import {
    audioRoomConnectionInitState,
    videoRoomConnectionInitState
} from "../../views/components/LessonConference/Types";

export const vscStateInitialState: VcsStateType = {
    status: LessonConferenceStatusEnum.NOT_INIT,
    participants: [],
    audioRoomState: cloneDeep(audioRoomConnectionInitState),
    videoRoomState: cloneDeep(videoRoomConnectionInitState),
}
