import {IsNumber} from 'class-validator';

/**
 * DTO Суммарных результатов оценки содержимого эпизодов self-study трека
 */
export class DtoSelfStudyTrackSurveySummary {
    @IsNumber()
    goodCount: number;

    @IsNumber()
    normalCount: number;

    @IsNumber()
    badCount: number;
}
