import React, {useCallback, useContext, useEffect, useRef} from "react";
import {VcsConnectionActionsContextProvider} from "./VcsConnectionActionsContext";
import {container} from "tsyringe";
import {IJanusConnection} from "../../../../../components/JanusClient/IJanusConnection";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../common/RoomCommonActionsContext";
import {AudioRoomContext, IAudioRoomContext} from "../../contexts/AudioRoomContext";
import {ConferenceModeEnum, ConnectionStateEnum} from "../../Types";
import {IVideoRoomContext, VideoRoomContext} from "../../contexts/VideoRoomContext";

const RECONNECTION_INTERVAL = 3000;

export const VcsConnectionActionsProvider: React.FC = (
    {children}
) => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const audioRoomContext = useContext<IAudioRoomContext>(AudioRoomContext);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);
    const commonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);

    const reconnectTimer = useRef<number | null>(null);
    const reconnectingNow = useRef<boolean>(false);

    const connectionErrorHandler = useCallback((onConnectProcess: boolean, error: any) => {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        logger.error(
            LoggerSectionsEnum.JANUS_CONNECTION,
            (onConnectProcess)
                ? 'VCS server start connection error. Connection params: '
                : 'VCS server exist connection error. Connection params:',
            commonContext.conferenceConnectionParams,
            '. Error: ',
            error
        );

        commonActionsContext.setCurrentConferenceMode(() => ConferenceModeEnum.NORMAL);
        commonActionsContext.setLessonConferenceState(() => LessonConferenceStatusEnum.CONNECTION_ERROR);
    }, [commonActionsContext, commonContext.conferenceConnectionParams]);

    const startConnection = useCallback(async () => {
        if (commonContext.lessonConferenceState === LessonConferenceStatusEnum.ON_DESTROY) {
            return;
        }

        if (commonContext.conferenceConnectionParams === null) {
            commonActionsContext.setLessonConferenceState(LessonConferenceStatusEnum.CONNECTION_ERROR);

            return;
        }

        const janusConnection = container.resolve<IJanusConnection>(DiTokens.JANUS_CONNECTION);

        try {
            await janusConnection.init(
                commonContext.conferenceConnectionParams.janusWsApiEntrypoint,
                commonContext.conferenceConnectionParams.janusApiKey,
                [
                    {
                        urls: 'stun:' + commonContext.conferenceConnectionParams.turnServerTlsEntrypoint,
                    },
                    {
                        urls: 'turn:' + commonContext.conferenceConnectionParams.turnServerTlsEntrypoint,
                        username: commonContext.conferenceConnectionParams.turnServerLogin,
                        credential: commonContext.conferenceConnectionParams.turnServerPassword,
                        credentialType: "password"
                    }
                ],
                (error) => {
                    connectionErrorHandler(false, error);

                    reconnectTimer.current = setTimeout(startConnection, RECONNECTION_INTERVAL) as unknown as number;
                }
            );

            commonActionsContext.setLessonConferenceState(LessonConferenceStatusEnum.STARTED);
            reconnectingNow.current = false;
        } catch (error) {
            connectionErrorHandler(true, error);

            reconnectTimer.current = setTimeout(startConnection, RECONNECTION_INTERVAL) as unknown as number;

            return;
        }
    }, [commonActionsContext, commonContext.conferenceConnectionParams, commonContext.lessonConferenceState, connectionErrorHandler]);

    const stopConnection = useCallback(async () => {
        const janusConnection = container.resolve<IJanusConnection>(DiTokens.JANUS_CONNECTION);

        if (janusConnection.isInitialized()) {
            await janusConnection.destroy();
        }
    }, []);

    const reconnectAll = useCallback(async () => {
        if (reconnectingNow.current) {
            return;
        }

        reconnectingNow.current = true;
        connectionErrorHandler(false, null);

        await stopConnection();

        reconnectTimer.current = setTimeout(startConnection, RECONNECTION_INTERVAL) as unknown as number;
    }, [connectionErrorHandler, startConnection, stopConnection]);

    useEffect(() => {
        if (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CHECK_CONNECTION) {
            startConnection();
        }

        // Если не нужно пытаться переподключаться
        if (
            (commonContext.lessonConferenceState === LessonConferenceStatusEnum.ON_DESTROY)
            || (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CONNECTION_ERROR_WAIT_NETWORK)
        ) {
            if (reconnectTimer.current !== null) {
                clearTimeout(reconnectTimer.current);
                reconnectTimer.current = null;
            }

            stopConnection();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.lessonConferenceState]);

    useEffect(() => {
        return () => {
            if (reconnectTimer.current !== null) {
                clearTimeout(reconnectTimer.current);

                reconnectTimer.current = null;
            }

            stopConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Наблюдение за внезапным отключением audio room
    useEffect(() => {
        if (audioRoomContext.audioRoomConnectionState.connection.status === ConnectionStateEnum.CONNECTION_ERROR) {
            // Отключилось audio. Скорее всего видео тоже сломается/сломалось, поэтому переподключаем всё.
            reconnectAll();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRoomContext.audioRoomConnectionState.connection.status]);

    // Наблюдение за внезапным отключением video room
    useEffect(() => {
        if (videoRoomContext.videoRoomConnectionState.publisherConnection.status === ConnectionStateEnum.CONNECTION_ERROR) {
            // Отключился Publisher.
            // Subscriber даже не проверяем.
            // Скорее всего аудио тоже сломается/сломалось, поэтому переподключаем всё.
            reconnectAll();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRoomContext.videoRoomConnectionState.publisherConnection.status]);

    return <VcsConnectionActionsContextProvider value={{
        startConnection: startConnection
    }}>
        {children}
    </VcsConnectionActionsContextProvider>
}
