import React from "react";
import {ReactComponent as HeadsetOffIcon} from "../../../../Ui/Svg/HeadsetOff.svg";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {DefaultLoader} from "../../../../DefaultLoader";
import {RouterLink} from "../../../../Ui/Elements/Link";
import {RoutesList} from "../../../../../RoutesList";

const Wrapper = styled.div`
  aspect-ratio: 16/9;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  color: ${({theme}) => theme.colors.textSecondary};

  svg {
    width: 100%;
    height: 100%;
    opacity: 0.7;
  }
`;

const TextWithLoader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 15px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const RouterLinkStyled = styled(RouterLink)`
  display: inline;
  text-decoration: underline;
`;

export const InitDevicesError: React.FC = () => {
    return <Wrapper>
        <IconWrapper><HeadsetOffIcon/></IconWrapper>
        <TextWithLoader>
            <LoaderWrapper>
                <DefaultLoader simple={true}/>
            </LoaderWrapper>
            <TextWrapper>
                <div>
                    <Trans>Не удалось подключить камеру или микрофон.
                        Попробуйте изменить настроить устройства на странице&nbsp;
                        <RouterLinkStyled to={RoutesList.VIDEO_CALL_TEST}>Проверки камеры и микрофона</RouterLinkStyled>.
                    </Trans>
                </div>
            </TextWrapper>
        </TextWithLoader>
    </Wrapper>
}
