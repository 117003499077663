import {delay, put, select, takeEvery} from 'redux-saga/effects';
import {startHandleCycle} from "../../store/slidesWorkDataSaveQueue/actions";
import {SlidesWorkDataSaveQueueActionTypes} from "../../store/slidesWorkDataSaveQueue/type";
import {handleCycleEnabledSelector} from "../../store/slidesWorkDataSaveQueue/selector";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";

export function* watchStartSaveDataAfterReconnect() {
    yield takeEvery(
        SlidesWorkDataSaveQueueActionTypes.START_SAVE_DATA_AFTER_RECONNECT,
        startSaveDataAfterReconnect
    );
}

function* startSaveDataAfterReconnect() {
    // const handleCycleStatus = (yield select(handleCycleStatusSelector)) as QueueSendCycleStateEnum;
    const handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

    if (!handleCycleEnabled) {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue starting after reconnect');

        yield delay(3000); // Подождём 3 секунды после подключения, чтобы не врываться между других процессов

        yield put(startHandleCycle());
    }
}
