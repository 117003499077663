import {forwardRef, useEffect, useImperativeHandle} from 'react'
import {ButtonState} from "../Toolbar";
import {ToolbarButtonEnum} from "../Toolbar/ToolbarButtonEnum";
import {MarkFormats, RichTextEditorMode} from "./Enums";
import {useSlate} from "slate-react";
import {alignTypeIsActive, elementTypeIsActive, isMarkActive} from './CommonMethods';
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

interface ToolbarUpdaterProps {
    id: string;
    setToolbarConfigById: (elementId: string, buttonState: ButtonState[]) => void;
    editorMode: RichTextEditorMode;
}

export interface ToolbarUpdaterRefMethods {
    setToolbarConfig: () => void
}

export const ToolbarUpdater = forwardRef<ToolbarUpdaterRefMethods, ToolbarUpdaterProps>(
    (props, ref) => {
        const {id, setToolbarConfigById} = props;
        const editor = useSlate();

        const {editorMode} = props;

        const setToolbarConfig = () => {
            const textMarkItems = [
                {
                    buttonType: ToolbarButtonEnum.BOLD,
                    active: isMarkActive(editor, MarkFormats.BOLD),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.ITALIC,
                    active: isMarkActive(editor, MarkFormats.ITALIC),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.UNDERLINED,
                    active: isMarkActive(editor, MarkFormats.UNDERLINE),
                    disabled: false
                }
            ];

            const lists = [
                {
                    buttonType: ToolbarButtonEnum.LIST_NUMBERED,
                    active: elementTypeIsActive(editor, ElementTypes.NUMBERED_LIST),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.LIST_BULLETED,
                    active: elementTypeIsActive(editor, ElementTypes.BULLETED_LIST),
                    disabled: false
                }
            ]

            const alignItems = [
                {
                    buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                    active: alignTypeIsActive(editor, AlignTypes.LEFT),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                    active: alignTypeIsActive(editor, AlignTypes.CENTER),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_JUSTIFY,
                    active: alignTypeIsActive(editor, AlignTypes.JUSTIFY),
                    disabled: false
                },
                {
                    buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                    active: alignTypeIsActive(editor, AlignTypes.RIGHT),
                    disabled: false
                }
            ];

            const radioListItem = {
                buttonType: ToolbarButtonEnum.LIST_RADIO,
                active: elementTypeIsActive(editor, ElementTypes.EXERCISE_RADIO_LIST),
                disabled: false
            };

            const checkboxListItem = {
                buttonType: ToolbarButtonEnum.LIST_CHECK,
                active: elementTypeIsActive(editor, ElementTypes.EXERCISE_CHECKBOX_LIST),
                disabled: false
            };

            const translatedItem = {
                buttonType: ToolbarButtonEnum.TRANSLATED,
                active: elementTypeIsActive(editor, ElementTypes.TRANSLATED),
                disabled: false
            }

            switch (editorMode) {
                case RichTextEditorMode.TITLE: {
                    const availableTextMarks = [
                        ...textMarkItems
                    ];

                    availableTextMarks.shift();

                    setToolbarConfigById(id, [
                        ...availableTextMarks,
                        ...alignItems,
                        translatedItem
                    ]);

                    break;
                }
                case RichTextEditorMode.ONLY_TEXT: {
                    setToolbarConfigById(id, [
                        ...textMarkItems,
                        ...alignItems
                    ]);

                    break;
                }
                case RichTextEditorMode.RADIO_LIST: {
                    setToolbarConfigById(id, [
                        ...textMarkItems,
                        ...alignItems,
                        radioListItem,
                        translatedItem
                    ]);

                    break;
                }
                case RichTextEditorMode.CHECKBOX_LIST: {
                    setToolbarConfigById(id, [
                        ...textMarkItems,
                        ...alignItems,
                        checkboxListItem,
                        translatedItem
                    ]);

                    break;
                }
                case RichTextEditorMode.TEXT_WITH_ADDONS:
                default: {
                    setToolbarConfigById(id, [
                        ...textMarkItems,
                        ...lists,
                        ...alignItems,
                        translatedItem
                    ]);

                    break;
                }
            }
        }

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            setToolbarConfig: () => {
                setToolbarConfig();
            }
        }));

        useEffect(() => {
            setToolbarConfig();
        }, [editor, editor.selection, editor.children, id, setToolbarConfigById, editorMode]);

        return null;
    }
);