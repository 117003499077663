import * as React from "react";
import styled from 'styled-components';
import {Input as AntInput} from 'antd';
import {TextAreaProps} from 'antd/lib/input';
import {TextAreaRef} from "antd/lib/input/TextArea";
import classNames from "classnames";

const InputStyled: React.FunctionComponent<TextAreaProps & React.RefAttributes<TextAreaRef>> = styled(AntInput.TextArea)`
  border: none;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  font-size: 16px;
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};

  &:focus, &:hover {
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-right-width: inherit;
    box-shadow: none;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    border-color: #ff4d4f;
    box-shadow: none;
    border-right-width: inherit;
    outline: 0;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.read-only {
    cursor: default;
  }

  @media (${({theme}) => theme.media.small}) {
    font-size: 18px;
  }
`;

export const TextArea: React.FunctionComponent<TextAreaProps & React.RefAttributes<TextAreaRef>> = (
    {className, ...props}
) => {
    return <InputStyled
        className={classNames(
            props.readOnly && 'read-only',
            className
        )}
        {...props}/>
}
