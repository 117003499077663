import React, {useCallback} from "react";
import styled from "styled-components";
import {Link} from "../Ui/Elements/Link";
import {RoutesHelper} from "../../../helpers/RoutesHelper";
import {RoutesList} from "../../RoutesList";
import {ReactComponent as ArrowNextSvg} from "../../components/Ui/Svg/ArrowNext.svg";
import {RegularTextCss} from "../../styles/global-elements";

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const ListItem = styled.li`
`;

const ItemWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
`;

const ItemIcon = styled.div`
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
`;

const ItemText = styled.div`
  ${RegularTextCss};

  padding-top: 7px;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.small}) {
    padding-top: 5px;
  }
`;


export type KbLinksListItem = {
    pageId: string;
    name: string;
    fullHref?: string;
}

export interface KbLinksProps {
    linksList: KbLinksListItem[];
}

export const KbLinks: React.FC<KbLinksProps> = ({linksList}) => {
    const getLink = useCallback((item: KbLinksListItem) => {
        if (item.fullHref) {
            return item.fullHref;
        }

        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_KNOWLEDGE_BASE_PAGE,
            [
                {
                    key: 'pageId',
                    value: item.pageId
                }
            ]
        );
    }, []);

    return <List>
        {
            linksList.map((item, index) => {
                return <ListItem key={index}>
                    <ItemWrapper href={getLink(item)} target='_blank'>
                        <ItemIcon><ArrowNextSvg/></ItemIcon>
                        <ItemText>{item.name}</ItemText>
                    </ItemWrapper>
                </ListItem>
            })
        }
    </List>
}