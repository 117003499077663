import React from "react";
import {StudentGroupsListModeEnum} from "../../../../components/StonlineApiClient/Enums/StudentGroupsListModeEnum";
import {Trans} from "@lingui/macro";

interface ListModeLocaleNameProps {
    listMode: StudentGroupsListModeEnum;
    className?: string;
}

export const ListModeLocaleName: React.FC<ListModeLocaleNameProps> = (props) => {
    const localeModeName = () => {
        switch (props.listMode) {
            case StudentGroupsListModeEnum.MY_ACTIVE_GROUPS: {
                return <Trans>Мои группы</Trans>;
            }
            case StudentGroupsListModeEnum.ALL_ACTIVE_GROUPS: {
                return <Trans>Все активные</Trans>;
            }
            case StudentGroupsListModeEnum.ENDED_GROUPS: {
                return <Trans>Завершившие обучение</Trans>;
            }
            case StudentGroupsListModeEnum.DELETED_GROUPS: {
                return <Trans>Удалённые</Trans>;
            }
            case StudentGroupsListModeEnum.ALL_GROUPS: {
                return <Trans>Все группы</Trans>;
            }
        }
    }

    return <span className={props.className}>{localeModeName()}</span>
}