import {RoutesList} from "../components/WsApiClient/Router/RoutesList";
import {ConnectionEventsRoutesEnum} from "../components/WsApiClient/ConnectionEventsRoutesEnum";
import {wsConnected, wsDisconnected} from "../store/wsHandlers/actions";
import {ApiMethodEnum} from "../components/WsApiClient/ApiMethodEnum";
import {incomingEvent, processEventsResponseBatch} from "../store/streamEvent/actions";
import {AuthLoginResultDto} from "../components/WsApiClient/ApiDto/Response/Auth/LoginResultDto";
import {StreamEventBaseDto} from "../components/WsApiClient/ApiDto/Response/StreamEvent/StreamEventBaseDto";
import {ListDto} from "../components/WsApiClient/ApiDto/Response/ListDto";
import {processPingResult} from "../store/app/actions";
import {handleGetOpenedRoomsResponse} from "../store/lessonRooms/actions";
import {PingResultDto} from "../components/WsApiClient/ApiDto/Response/Ping/PingResultDto";
import {processUserWsAuthResult} from "../store/user/actions";
import {DtoResponseWithPagination} from "../components/WsApiClient/ApiDto/Response/DtoResponseWithPagination";
import {DtoSELessonOpened} from "../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSELessonOpened";
import {handleRoomMembersListResult} from "../store/lessonRoom/actions";
import {DtoSELessonOpenedList} from "../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSELessonOpenedList";

/**
 * Перечисление маршрутов для направления сообщений, получаемых от Web Socket сервера.
 */
export const wsRoutesList: RoutesList = [
    {
        method: ConnectionEventsRoutesEnum.WS_CONNECTED,
        actionCreator: wsConnected,
        paramsDto: null
    },
    {
        method: ConnectionEventsRoutesEnum.WS_DISCONNECTED,
        actionCreator: wsDisconnected,
        paramsDto: null
    },
    {
        method: ApiMethodEnum.AUTH_BY_AUTH_KEY,
        actionCreator: processUserWsAuthResult,
        paramsDto: AuthLoginResultDto
    },
    {
        method: ApiMethodEnum.AUTH_BY_RECONNECTION_TOKEN,
        actionCreator: processUserWsAuthResult,
        paramsDto: AuthLoginResultDto
    },
    {
        method: ApiMethodEnum.LESSON_ROOM_GET_OPENED_ROOMS,
        actionCreator: handleGetOpenedRoomsResponse,
        paramsDto: DtoSELessonOpenedList
    },
    {
        method: ApiMethodEnum.LESSON_ROOM_GET_MEMBERS_LIST,
        actionCreator: handleRoomMembersListResult,
        paramsDto: DtoResponseWithPagination<DtoSELessonOpened>
    },
    {
        method: ApiMethodEnum.PING,
        actionCreator: processPingResult,
        paramsDto: PingResultDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_HISTORY,
        actionCreator: processEventsResponseBatch,
        paramsDto: ListDto
    },

    // Вызовы по событиям истории событий
    {
        method: ApiMethodEnum.STREAM_EVENT_USER_ONLINE_STATE_UPDATE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_OPENED,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_KICKED,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_CLOSED,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PARTICIPANT_ENTERED,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PARTICIPANT_LEFT,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_STUDENT_SLIDE_SUMMARY_UPDATE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_AND_HOMEWORK_ROOM_NEED_SYNC_SLIDE_LIST,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_AND_HOMEWORK_STUDENT_SLIDE_WORK_DATA_UPDATE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_STUDENT_CURRENT_SLIDE_CHANGED,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_NEED_SELECT_ROOM_MEMBER_VIDEO,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_STUDENT_NEED_OPEN_SLIDE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN_STATE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_CAMERA_VIDEO_MUTE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_MICROPHONE_MUTE,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    },
    {
        method: ApiMethodEnum.STREAM_EVENT_LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION,
        actionCreator: incomingEvent,
        paramsDto: StreamEventBaseDto
    }
];
