import {IPagesBroadcastService} from "./IPagesBroadcastService";
import {IStoreService} from "../store/IStoreService";
import {ILogger} from "../../components/Logger/ILogger";
import {BaseMessageDto} from "./dto/BaseMessageDto";
import {NewWindowOpenedDto} from "./dto/NewWindowOpenedDto";
import {MessageTypeIdEnum} from "./MessageTypeIdEnum";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {ModelValidator} from "../../components/ModelValidator/ModelValidator";
import {logoutInAnotherWindow, newWindowOpened} from "../../store/app/actions";
import {LogoutDto} from "./dto/LogoutDto";

const BROADCAST_CHANNEL_NAME = 'my-lessons-channel';

export class PagesBroadcastService implements IPagesBroadcastService {
    protected channel: BroadcastChannel | null;
    protected storeService: IStoreService;
    protected logger: ILogger;

    constructor(storeService: IStoreService, logger: ILogger) {
        this.storeService = storeService;
        this.logger = logger;

        if (window.BroadcastChannel !== undefined) {
            this.channel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

            this.channel.onmessage = this.handleIncomingMessage.bind(this);
        } else {
            this.logger.info(
                LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                'Browser not supported BroadcastChannel'
            );

            this.channel = null;
        }
    }

    /**
     * @inheritDoc
     */
    public noticeAboutNewWindow(windowUserId: string): void {
        if (this.channel === null) {
            return;
        }

        const messagePayload: BaseMessageDto<NewWindowOpenedDto> = {
            typeId: MessageTypeIdEnum.NEW_WINDOW_OPENED,
            payload: {
                userId: windowUserId
            }
        };

        this.channel.postMessage(messagePayload);

        this.logger.info(
            LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
            `Send message about new window with userId ${windowUserId}`
        );
    }

    /**
     * @inheritDoc
     */
    noticeAboutLogout(windowUserId: string): void {
        if (this.channel === null) {
            return;
        }

        const messagePayload: BaseMessageDto<LogoutDto> = {
            typeId: MessageTypeIdEnum.LOGOUT,
            payload: {
                userId: windowUserId
            }
        };

        this.channel.postMessage(messagePayload);

        this.logger.info(
            LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
            `Send message about logout with userId ${windowUserId}`
        );
    }

    /**
     * Обработка входящего сообщения
     *
     * @param message
     * @protected
     */
    protected handleIncomingMessage(message: MessageEvent) {
        ModelValidator.validateAndTransformRawRequestData(
            BaseMessageDto, message.data
        )
            .then((data) => {
                switch (data.typeId) {
                    case MessageTypeIdEnum.NEW_WINDOW_OPENED: {
                        const payload = data.payload as NewWindowOpenedDto;

                        if (!payload.userId) {
                            this.logger.info(
                                LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                                `Received message about new window with empty userId`
                            );

                            return;
                        }

                        this.logger.info(
                            LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                            `Received message about new window with userId ${payload.userId}`
                        );

                        this.storeService.store.dispatch(newWindowOpened(payload.userId));

                        break;
                    }

                    case MessageTypeIdEnum.LOGOUT: {
                        const payload = data.payload as LogoutDto;

                        if (!payload.userId) {
                            this.logger.info(
                                LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                                `Received message about logout with empty userId`
                            );

                            return;
                        }

                        this.logger.info(
                            LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                            `Received message about logout with userId ${payload.userId}`
                        );

                        this.storeService.store.dispatch(logoutInAnotherWindow(payload.userId));

                        break;
                    }

                    default: {
                        this.logger.info(
                            LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                            `Received message with unknown typeId ${data.typeId}`
                        );
                    }
                }
            })
            .catch((e) => {
                this.logger.error(
                    LoggerSectionsEnum.PAGES_BROADCAST_SERVICE,
                    'Error on validate incoming message payload: ',
                    e
                );
            })
    }
}
