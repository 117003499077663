import React from 'react';
import styled from "styled-components";
import {SortableItemComponent} from "../../../../../components/Sortable/Sortable";
import {RadioIcon} from "../../../../../components/Ui/Elements/RadioIcon";
import {DtoTmSection} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";

const ItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RadioIconStyled = styled(RadioIcon)`
  cursor: pointer;
`;

const UserContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
`;

interface SectionItemProps {
    item: SortableItemComponent<DtoTmSection>,
    index: number,
    onSelect: () => void
}

export const SectionItem: React.FC<SectionItemProps> = (props) => {
    return <ItemStyled>
        <RadioIconStyled checked={!!props.item.selected} onClick={props.onSelect}/>

        <UserContentWrapper>
            <SectionTitle>{props.item.item.name}</SectionTitle>
        </UserContentWrapper>
    </ItemStyled>
}