import {forwardRef, useImperativeHandle} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const UnknownSlideElement = styled.div`
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: ${({theme}) => theme.colors.textSecondary};
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  position: relative;
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.accentDivider};
  border-style: dashed;
  
  
  &::before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    border-bottom: 1px dashed ${({theme}) => theme.colors.accentDivider};
`;

const UnknownSlideElementText = styled.span`
  background: ${({theme}) => theme.colors.backgroundPrimary};
  display: inline-block;
  position: relative;
  padding: 0 10px;
`;

interface UnknownElementProps extends IElementProps<any> {
}

interface UnknownElementRefMethods extends IElementRefMethods {
}

export const UnknownElement = forwardRef<UnknownElementRefMethods, UnknownElementProps>(
    (params, ref) => {

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        return <UnknownSlideElement>
            <UnknownSlideElementText>
                <Trans>Неизвестное упражнение</Trans>
            </UnknownSlideElementText>
        </UnknownSlideElement>;
    }
);

UnknownElement.displayName = 'UnknownElement';