import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import {ItemPropsInterface, ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import {ToolbarButtonEnum} from "../../components/Toolbar/ToolbarButtonEnum";
import {ElementData, VideohostingEnum} from "../../../SlidePlayerEditorCommonParts/components/VideoYoutube/Common";
import styled from "styled-components";
import {InsertYoutubeLink} from "./InsertYoutubeLink";
import {YouTubePlayer} from "../../../SlidePlayerEditorCommonParts/components/VideoYoutube/YouTubePlayer";

const ElementWrapper = styled.div``;

interface VideoYoutubeProps extends ItemPropsInterface<ElementData> {
}

interface VideoYoutubeRefMethods extends ItemRefMethodsInterface {
}

export const VideoYoutube = forwardRef<VideoYoutubeRefMethods, VideoYoutubeProps>(
        (props, ref) => {

            const {id, setToolbarConfigById, initialData, onChange} = props;
            const elementWrapperRef = useRef<HTMLDivElement>(null);

            const elementData = useMemo((): ElementData => {
                if (initialData === null) {
                    return {
                        videoId: null,
                        videohostingType: VideohostingEnum.YOUTUBE,
                        height: null
                    }
                }

                return initialData;
            }, [initialData]);

            useEffect(() => {
                setToolbarConfigById(id, [
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_LEFT,
                        active: false,
                        disabled: true
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_CENTER,
                        active: false,
                        disabled: true
                    },
                    {
                        buttonType: ToolbarButtonEnum.ALIGN_RIGHT,
                        active: false,
                        disabled: true
                    }
                ]);
            }, [id, setToolbarConfigById]);

            // Методы, доступные родителю
            useImperativeHandle(ref, () => ({
                toolbarItemOnToggle: (buttonType: ToolbarButtonEnum, newValue: boolean) => {
                },
                getExercisesCount: () => {
                    return 0;
                }
            }));

            const onLinkSelect = (videoId: string) => {
                onChange({
                    ...elementData,
                    videoId: videoId
                });
            }

            const onPlayerReady = () => {
                if (elementWrapperRef.current) {
                    onChange({
                        ...elementData,
                        height: elementWrapperRef.current.offsetHeight
                    });
                }
            }

            return <ElementWrapper ref={elementWrapperRef}>
                {
                    (elementData.videoId === null)
                        ? <InsertYoutubeLink onIdSelect={onLinkSelect}/>
                        : <YouTubePlayer videoId={elementData.videoId}
                                         onPlayerReady={onPlayerReady}
                                         height={elementData.height}/>
                }
            </ElementWrapper>;
        }
    )
;