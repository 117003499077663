import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {FooterMode, Modal} from "../Modal";
import {PopupActions} from "reactjs-popup/dist/types";
import {BtnStyleEnum, Button} from "../Button";
import {t, Trans} from "@lingui/macro";
import styled from "styled-components";
import {NotificationTypesEnum, openNotification} from "../Notification";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DefaultImageMimeSupported, UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import {useDispatch, useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import * as UploadQueueActionCreators from "../../../../../store/uploadQueue/actions";
import {FileUploadProcessState, UploadProcessDetails} from "../../../../../store/uploadQueue/type";
import {queueStateSelector} from "../../../../../store/uploadQueue/selector";
import {CircleStencil, Cropper as NewCropper, CropperRef as NewCropperRef} from 'react-advanced-cropper';
import "react-advanced-cropper/dist/style.css";

const DEFAULT_QUALITY_VALUE = 0.7;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
`;

const CropContainer = styled.div`
    position: relative;
    max-width: 80%;
`;

enum UploadState {
    NO_PICTURE,
    WAIT_FOR_UPLOADING,
    UPLOADING_NOW,
    UPLOAD_SUCCESSFULLY,
    UPLOAD_ERROR
}

export interface ImageCropperUploaderProps {
    config: {
        width: number,
        height: number,
        aspectRatio?: number,
        shape: "rect" | "round",
        mimeTypesSupported?: string[],
        quality?: number
    }
    fileType: UserFileTypeEnum;
    handleFile: (fileId: string) => Promise<void>;
    onClose?: () => void;
}

export interface ImageCropperUploaderRefMethods {
    selectFile: () => void;
}

export const ImageCropperUploader = forwardRef<ImageCropperUploaderRefMethods, ImageCropperUploaderProps>((props, ref) => {
    const cropperRef = useRef<NewCropperRef>(null);
    const [uploadState, setUploadState] = useState<UploadState>(UploadState.NO_PICTURE);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [uploadFileId, setUploadFileId] = useState<string | null>(null);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const sessionToken = useSelector(sessionTokenSelector);
    const queueState = useSelector(queueStateSelector);

    const modalRef = useRef<PopupActions>(null);

    const fileLocalUrl = useMemo<string | null>(() => {
        if (selectedFile === null) {
            return null;
        }

        return URL.createObjectURL(selectedFile);
    }, [selectedFile]);

    // Методы, доступные родителю
    useImperativeHandle(ref, () => ({
        selectFile: () => {
            setUploadFileId(null);
            setUploadState(UploadState.NO_PICTURE);

            hiddenFileInput.current?.click();
        }
    }));

    const currentFileInUploadQueue = useMemo<UploadProcessDetails | null>(() => {
        if (uploadFileId === null) {
            return null
        }

        const itemIndex = queueState.indexByFileId[uploadFileId];

        if (itemIndex === undefined) {
            return null;
        }

        return queueState.process[itemIndex] ?? null;
    }, [queueState, uploadFileId]);

    useEffect(() => {
        // Обновление статуса компонента
        if (!currentFileInUploadQueue) {
            if (uploadFileId !== null) {
                modalRef.current?.close();
            } else {
                setUploadState(UploadState.NO_PICTURE);
            }

            return;
        }

        if (uploadState === UploadState.NO_PICTURE) {
            return;
        }

        switch (currentFileInUploadQueue.state) {
            case FileUploadProcessState.WAIT_FOR_START:
            case FileUploadProcessState.IN_PROCESS: {
                setUploadState(UploadState.UPLOADING_NOW);

                break;
            }
            case FileUploadProcessState.FAILED: {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка`,
                    t`Не удалось выполнить операцию`
                );

                setUploadState(UploadState.NO_PICTURE);

                break;
            }
            case FileUploadProcessState.SUCCESS: {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                if (!uploadFileId) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Ошибка`,
                        t`Не удалось выполнить операцию`
                    );

                    logger.error(
                        LoggerSectionsEnum.AVATAR_UPLOADER,
                        'Not found uploadFileId after upload image file.'
                    );

                    setUploadState(UploadState.NO_PICTURE);

                    return;
                }

                props.handleFile(uploadFileId)
                    .then(() => {
                        modalRef.current?.close();
                    })
                    .catch((e) => {
                        openNotification(
                            NotificationTypesEnum.ERROR,
                            t`Ошибка`,
                            t`Не удалось выполнить операцию`
                        );

                        setUploadState(UploadState.NO_PICTURE);
                    });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFileInUploadQueue, sessionToken]);

    const closeLocked = useMemo<boolean>(() => {
        return ((uploadState === UploadState.UPLOADING_NOW) || (uploadState === UploadState.WAIT_FOR_UPLOADING));
    }, [uploadState]);

    const getImage = useCallback((sourceCanvas: HTMLCanvasElement): Promise<Blob | null> => {
        const qualityValue = props.config.quality ?? DEFAULT_QUALITY_VALUE;

        if (sourceCanvas.width <= props.config.width && sourceCanvas.height <= props.config.height) {
            return new Promise((resolve, reject) => {
                sourceCanvas.toBlob(
                    (file) => {
                        resolve(file)
                    },
                    'image/jpeg',
                    qualityValue
                )
            });
        }

        const newCanvas = document.createElement('canvas')

        const ratio = Math.min(props.config.width / sourceCanvas.width, props.config.height / sourceCanvas.height);

        newCanvas.width = Math.floor(sourceCanvas.width * ratio);
        newCanvas.height = Math.floor(sourceCanvas.height * ratio);

        const ctx = newCanvas.getContext('2d')

        if (!ctx) {
            throw new Error('Error on get context from newCanvas');
        }

        ctx.drawImage(
            sourceCanvas,
            0, 0, sourceCanvas.width, sourceCanvas.height,
            0, 0, newCanvas.width, newCanvas.height
        );

        return new Promise((resolve, reject) => {
            newCanvas.toBlob(
                (file) => {
                    resolve(file)
                },
                'image/jpeg',
                qualityValue
            )
        });
    }, [props.config.height, props.config.quality, props.config.width]);

    const uploadCrop = useCallback(async (): Promise<void> => {
        if (closeLocked || sessionToken === null || fileLocalUrl === null || !cropperRef.current) {
            return;
        }

        let cropperCanvas = cropperRef.current.getCanvas();

        if (!cropperCanvas) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setUploadState(UploadState.WAIT_FOR_UPLOADING);

        try {
            const croppedImage = await getImage(cropperCanvas);

            if (croppedImage === null) {
                throw new Error('getCropperImage returned null');
            }

            const data = await httpApiClient.fileGetUrlForUpload(
                sessionToken,
                props.fileType,
                croppedImage.size.toString(10)
            );

            setUploadFileId(data.data.fileId);

            const startUploadNewFile = (fileId: string, fileType: UserFileTypeEnum, uploadUrl: string, file: File | Blob) =>
                dispatch(UploadQueueActionCreators.uploadNewFile(fileId, fileType, uploadUrl, file));

            startUploadNewFile(
                data.data.fileId,
                props.fileType,
                data.data.uploadUrl,
                croppedImage
            );
        } catch (e) {
            logger.error(LoggerSectionsEnum.AVATAR_UPLOADER, e);

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Ошибка`,
                t`Не удалось выполнить операцию`
            );

            setUploadState(UploadState.NO_PICTURE);
        }
    }, [closeLocked, dispatch, fileLocalUrl, getImage, props.fileType, sessionToken]);

    const supportedCoverMimeTypes = useMemo<string>(() => {
        const arr = (props.config.mimeTypesSupported)
            ? props.config.mimeTypesSupported
            : Object.values(DefaultImageMimeSupported);

        return arr.join(',');
    }, [props.config.mimeTypesSupported]);

    const coverFileInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target.files) && (event.target.files[0])) {
            setSelectedFile(event.target.files[0]);

            modalRef.current?.open();

            if (hiddenFileInput.current) {
                hiddenFileInput.current.value = '';
            }
        }
    };

    return <>
        <input type={"file"}
               accept={supportedCoverMimeTypes}
               ref={hiddenFileInput}
               onChange={coverFileInputOnChange}
               style={{display: 'none'}}
        />

        <Modal innerRef={modalRef}
               closeAllowed={!closeLocked}
               footer={
                   (controls) => {
                       return <>
                           <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Primary}
                                   loading={closeLocked}
                                   onClick={uploadCrop}>
                               <Trans>Сохранить</Trans>
                           </Button>
                           <Button btnStyle={BtnStyleEnum.Secondary} disabled={closeLocked}
                                   onClick={controls.closeModal}>
                               <Trans>Отмена</Trans>
                           </Button>
                       </>;
                   }
               }
               onClose={props.onClose}
               footerMode={FooterMode.SPACE_BETWEEN}
               children={
                   (controls) => {
                       return <ContentContainer>
                           <CropContainer>
                               {
                                   (fileLocalUrl)
                                   && <NewCropper
                                       ref={cropperRef}
                                       stencilComponent={
                                           (props.config.shape === 'round')
                                               ? CircleStencil
                                               : undefined
                                       }
                                       aspectRatio={
                                           props.config.aspectRatio
                                               ? {
                                                   minimum: props.config.aspectRatio,
                                                   maximum: props.config.aspectRatio
                                               }
                                               : undefined
                                       }
                                       canvas={true}
                                       src={fileLocalUrl}
                                       className={''}
                                   />
                               }
                           </CropContainer>
                       </ContentContainer>
                   }
               }
        />
    </>;
});