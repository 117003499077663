import {JanusJS} from "../../OfficialClient/janus";
import {ILogger} from "../../../Logger/ILogger";
import {LoggerSectionsEnum} from "../../../Logger/LoggerSectionsEnum";
import {PluginNameEnum} from "../../PluginNameEnum";
import {IJanusConnection} from "../../IJanusConnection";
import {PublisherNameUtils} from "../PublisherNameUtils";
import {StreamTypeEnum} from "../../Types";
import {PublisherMidDescriptionUtils} from "../PublisherMidDescriptionUtils";

export type SubscribeRequestItem = {
    feedId: string;
    mid: string;
}

export type SubscriberRoomCredentials = {
    roomId: string;
    myPrivateId: string;
    roomJoinKey: string;
    opaqueId: string;
}

export interface SubscriberCallbacks {
    successfullyJoined: () => void;
    onError: () => void;
    iceDisconnected: () => void;
    slowLinkMessage: (packetsList: number) => void;
    handleStartedStreams: (userId: string, feedId: string, mid: string, type: StreamTypeEnum, track: MediaStreamTrack) => void;
    handleStoppedStreams: (userId: string, feedId: string, mid: string, type: StreamTypeEnum) => void;
    onHangUp: () => void;
    onCleanup: () => void;
}

type SubscriberStreamsMapItem = { mid: string, userId: string, feedId: string, feedMid: string, type: StreamTypeEnum };

export class Subscriber {
    protected logger: ILogger;
    protected callbacks: SubscriberCallbacks;
    protected roomCredentials: SubscriberRoomCredentials;
    protected descriptor: JanusJS.PluginHandle | null;
    protected janusConnection: IJanusConnection;

    protected alreadyJoined: boolean;

    protected subscriberStreamsMap: Array<SubscriberStreamsMapItem>;

    protected deletedStreams: Array<SubscriberStreamsMapItem>;

    constructor(
        logger: ILogger,
        janusConnection: IJanusConnection,
        roomCredentials: SubscriberRoomCredentials,
        callbacks: SubscriberCallbacks
    ) {
        this.logger = logger;
        this.janusConnection = janusConnection;

        this.callbacks = callbacks;
        this.roomCredentials = roomCredentials;

        this.alreadyJoined = false;

        this.descriptor = null;

        this.subscriberStreamsMap = [];
        this.deletedStreams = [];
    }

    public subscribe(): void {
        this.janusConnection.getInstance().attach({
            plugin: PluginNameEnum.VIDEO_ROOM,
            opaqueId: this.roomCredentials.opaqueId,
            success: (pluginHandle) => {
                this.descriptor = pluginHandle;

                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                    "Plugin attached! (" + pluginHandle.getPlugin() + ", id=" + pluginHandle.getId() + ")"
                );

                this.subscriberStreamsMap = [];

                this.callbacks.successfullyJoined();
            },
            error: (error) => {
                this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "  -- Error attaching subscriber plugin...", error);
            },
            iceState: (state) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "ICE state changed to " + state);

                if (state === "failed") {
                    this.callbacks?.iceDisconnected();
                }
            },
            webrtcState: (on) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Janus says this WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
            },
            slowLink: (uplink, lost, mid) => {
                this.logger.warning(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Janus reports problems " + (uplink ? "sending" : "receiving") +
                    " packets on mid " + mid + " (" + lost + " lost packets)");
            },
            onmessage: (msg, jsep) => {
                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, " ::: Got a message :::", msg);

                const event = msg["videoroom"];

                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Event: " + event);

                if (msg["error"]) {
                    this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, msg["error"]);
                } else if (event) {
                    switch (event) {
                        case "attached": {
                            this.alreadyJoined = true;
                            const streams = msg["streams"] as undefined | JanusJS.SubscriberStreamItem[];

                            if (streams === undefined) {
                                this.logger.info(
                                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                                    `Received ${event} event without "streams".`
                                );
                            } else {
                                this.handleAttachStreamsUpdate(streams, false);
                            }

                            break;
                        }
                        case "updated": {
                            const streams = msg["streams"];

                            if (streams === undefined) {
                                this.logger.info(
                                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                                    `Received ${event} event without "streams".`
                                );
                            } else {
                                this.handleAttachStreamsUpdate(streams, true);
                            }

                            break;
                        }

                        case "event": {
                            // Check if we got an event on a simulcast-related event from this publisher
                            const substream = msg["substream"];
                            const temporal = msg["temporal"];

                            if ((substream !== null && substream !== undefined) || (temporal !== null && temporal !== undefined)) {
                                if (substream !== null && substream !== undefined) {
                                    this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Switched to stream: " + substream)
                                }

                                if (temporal !== null && temporal !== undefined) {
                                    this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Switched to temporal: " + temporal);
                                }
                            }

                            break;
                        }

                        default: {
                            this.logger.warning(
                                LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                                `Received unexpected event with name ${event}`
                            );
                        }
                    }
                }

                if (jsep) {
                    // JanusJS.Janus.debug("Handling SDP as well...", jsep);
                    const stereo = (jsep.sdp) ? (jsep.sdp.indexOf("stereo=1") !== -1) : false;

                    if (this.descriptor === null) {
                        this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, 'Descriptor is null');

                        return;
                    }

                    // Answer and attach
                    this.descriptor.createAnswer(
                        {
                            jsep: jsep,
                            customizeSdp: function (jsep: JanusJS.JSEP) {
                                if (stereo && jsep.sdp && (jsep.sdp.indexOf("stereo=1") === -1)) {
                                    // Make sure that our answer contains stereo too
                                    jsep.sdp = jsep.sdp.replace("useinbandfec=1", "useinbandfec=1;stereo=1");
                                }
                            },
                            success: (jsep: JanusJS.JSEP) => {
                                this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "Got SDP!");
                                // this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, jsep);

                                const body = {
                                    request: "start",
                                    // room: this.roomCredentials.roomId
                                };

                                if (this.descriptor === null) {
                                    this.logger.error(
                                        LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                                        'Error on createAnswer - descriptor is null'
                                    );

                                    return;
                                }

                                this.descriptor.send({message: body, jsep: jsep});
                            },
                            error: (error: any) => {
                                this.logger.error(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, "WebRTC error:", error);
                            }
                        });
                }
            },
            onlocaltrack: function (_track, _on) {
                // The subscriber stream is recvonly, we don't expect anything here
            },
            onremotetrack: (track, mid, on) => {
                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                    "Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":",
                    track
                );

                if (on) {
                    const indexInStreamsMap = this.subscriberStreamsMap.findIndex(item => item.mid === mid);

                    if (indexInStreamsMap < 0) {
                        this.logger.warning(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                            `Fetched remote track with unexpected mid ${mid}`
                        );

                        return;
                    }

                    this.callbacks?.handleStartedStreams(
                        this.subscriberStreamsMap[indexInStreamsMap].userId,
                        this.subscriberStreamsMap[indexInStreamsMap].feedId,
                        this.subscriberStreamsMap[indexInStreamsMap].feedMid,
                        this.subscriberStreamsMap[indexInStreamsMap].type,
                        track
                    );
                } else {
                    const indexInDeletedStreams = this.deletedStreams.findIndex(item => item.mid === mid);

                    if (indexInDeletedStreams < 0) {
                        this.logger.info(
                            LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                            `Ended remote track with unexpected mid ${mid}`
                        );

                        return;
                    }

                    this.deletedStreams.splice(indexInDeletedStreams, 1);
                }
            },
            oncleanup: () => {
                this.logger.info(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                    'onCleanUp called'
                );

                this.alreadyJoined = false;

                this.callbacks?.onCleanup();
            },
            // ondetached: () => {
            //     this.logger.info(LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER, 'Plugin detached');
            //
            //     this.descriptor = null;
            //
            //     this.callbacks.onHangUp();
            // },
        })
    }

    public subscribeToStreams(items: SubscribeRequestItem[]) {
        if (!this.descriptor) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                `subscribeToStreams called with empty descriptor`
            );

            throw new Error('subscribeToStreams called with empty descriptor');
        }

        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
            `Try to subscribe items: `,
            items
        );

        const streams: Array<{ feed: string, mid: string }> = items.map(item => ({
            feed: item.feedId,
            mid: item.mid
        }));

        if (this.alreadyJoined) {
            const subscribe = {
                request: "subscribe",
                streams: streams
            };

            this.descriptor.send({message: subscribe});
        } else {
            let join = {
                request: "join",
                room: this.roomCredentials.roomId,
                ptype: "subscriber",
                streams: streams,
                use_msid: false,
                // private_id: this.roomCredentials.myPrivateId,
                pin: this.roomCredentials.roomJoinKey,
            };

            this.descriptor.send({message: join});
        }
    }

    public unsubscribeFromStreams(items: SubscribeRequestItem[]) {
        if (!this.descriptor) {
            this.logger.error(
                LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                `unsubscribeFromStreams called with empty descriptor`
            );

            throw new Error('unsubscribeFromStreams called with empty descriptor');
        }

        this.logger.info(
            LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
            `Try to unsubscribe from items: `,
            items
        );

        if (!this.alreadyJoined) {
            return;
        }

        const streams: Array<{ feed: string, mid: string }> = items.map(item => ({
            feed: item.feedId,
            mid: item.mid
        }));


        const unsubscribe = {
            request: "unsubscribe",
            streams: streams
        };

        this.descriptor.send({message: unsubscribe});
    }

    protected handleAttachStreamsUpdate(subscriberStreamItems: JanusJS.SubscriberStreamItem[], clearMap: boolean): void {
        subscriberStreamItems.forEach(item => {
            // Если подписчик завершается
            if (!item.active) {
                this.subscriberStreamsMap = this.subscriberStreamsMap
                    .filter((mapItem) => {
                        if (item.mid === mapItem.mid) {
                            this.deletedStreams.push(mapItem);

                            this.callbacks?.handleStoppedStreams(
                                mapItem.userId,
                                mapItem.feedId,
                                mapItem.feedMid,
                                mapItem.type
                            );
                        }

                        return item.mid !== mapItem.mid;
                    });

                return;
            }
        });

        if (clearMap) {
            this.subscriberStreamsMap = [];
        }

        subscriberStreamItems.forEach(item => {
            if (!item.active) {
                return;
            }

            const userData = PublisherNameUtils.parseComplexUserId(item.feed_display);

            if (item.feed_description === undefined) {
                this.logger.error(
                    LoggerSectionsEnum.JANUS_VIDEO_ROOM_SUBSCRIBER,
                    `Stream item without mid description: `,
                    item
                )

                return;
            }

            const midDescriptionData = PublisherMidDescriptionUtils.parseMidDescription(item.feed_description);

            this.subscriberStreamsMap.push({
                mid: item.mid,
                userId: userData.userId,
                feedId: item.feed_id,
                feedMid: item.feed_mid,
                type: midDescriptionData.streamType
            });
        });
    }
}
