import {DtoRequestWithPagination} from "../../../../HttpApiClient/ApiDto/Response/Common/DtoRequestWithPagination";
import {IsNotEmpty, IsNumber, Max, Min} from "class-validator";

export class DtoSaveSlideWorkDataQueueResponse extends DtoRequestWithPagination<number> {
    @IsNotEmpty()
    @IsNumber()
    @Max(200)
    @Min(0)
    limit: number;
}
