import {
    ExerciseWorkData,
    SlideItemWorkData,
    SlidesWorkDataState,
    SlideWorkData,
    SlideWorkDataLoadingStateEnum
} from "./type";

export const initialState: SlidesWorkDataState = {
    slides: [],
    indexByPlayerId: {}
};

export const initialSlideWorkDataObject: SlideWorkData = {
    playerId: '',
    slideId: '',
    slideContentVersionNum: null,
    items: [],
    loadState: SlideWorkDataLoadingStateEnum.LOADING,
    itemsIndexById: {},
    slideCompleted: false,
    missedAward: 0,
    totalAward: 0
}

export const initialSlideItemWorkData: SlideItemWorkData = {
    slideItemId: '',
    additionalData: null,
    exercises: [],
    exercisesIndexById: {},
    overriddenParams: null
}

export const initialExerciseWorkDataObject: ExerciseWorkData = {
    exerciseId: '',
    award: 0,
    missedAward: 0,
    value: '',
    inputHistory: [],
    completed: false
}
