import React, {useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {WsConnectionStatusEnum} from "../../../../components/WsApiClient/WsConnectionStatusEnum";
import {ContentWidthWrapper} from "../styles";
import classNames from "classnames";
import {Trans} from "@lingui/macro";
import {RegularTextCss} from "../../../styles/global-elements";
import {ReactComponent as NoInternetIcon} from "../../../components/Ui/Svg/NoInternet.svg";
import {ReactComponent as FatalErrorIcon} from "../../../components/Ui/Svg/FatalError.svg";
import {ReactComponent as OldBrowserIcon} from "../../../components/Ui/Svg/OldBrowser.svg";

const Wrapper = styled.div`
  display: none;
  padding: 5px 20px;
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight}px;
  z-index: ${({theme}) => theme.zIndices.header};
  color: ${({theme}) => theme.colors.textPrimary};
  backdrop-filter: blur(10px);

  &.warning, &.error {
    display: block;
  }

  &.warning {
    height: auto;
    background-color: ${({theme}) => theme.colors.warningHeader};
  }

  &.error {
    height: auto;
    background-color: ${({theme}) => theme.colors.fatalErrorHeader};
  }
`;

const ContentWidthWrapperStyled = styled(ContentWidthWrapper)`
  margin: auto;
  padding: 5px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
`;

const TextWrapper = styled.div`
  ${RegularTextCss};

  color: inherit;
  padding-left: 5px;

  flex-grow: 1;
`;

export const HeaderWarning: React.FC = () => {
    const wsConnectionStatus = useSelector(({app}: ApplicationState) => app.wsConnectionStatus);
    const wsConnectionRequired = useSelector(({app}: ApplicationState) => app.wsConnectionRequired);
    const wsReconnectCount = useSelector(({app}: ApplicationState) => app.wsReconnectNum);
    const wsDisconnectAlertImmediately = useSelector(
        ({app}: ApplicationState) => app.wsDisconnectAlertImmediately
    );
    const appInFatalError = useSelector(({app}: ApplicationState) => app.inFatalErrorState);
    const appInitializedAt = useSelector(({app}: ApplicationState) => app.appInitializedAt);
    const webRtcSupported = useSelector(({app}: ApplicationState) => app.webRtcSupported);

    const wsConnectionWarning = useMemo<boolean>(() => {
        if (
            (wsConnectionStatus !== WsConnectionStatusEnum.AUTHORIZED)
            && wsConnectionRequired
            && (wsReconnectCount > 0 || wsDisconnectAlertImmediately)
        ) {
            return appInitializedAt !== null;
            // if (appInitializedAt === null) {
            //     return false;
            // }
            //
            // const currentDate = new Date();
            //
            // const currentTime = currentDate.getTime();
            // const initTime = appInitializedAt.getTime();
            //
            // if (currentTime - initTime > 10000) {
            //     return true;
            // }
        }

        return false;
    }, [wsConnectionStatus, wsConnectionRequired, wsReconnectCount, wsDisconnectAlertImmediately, appInitializedAt]);

    const content = () => {
        if (appInFatalError) {
            return <ContentWidthWrapperStyled>
                <ContentWrapper>
                    <IconWrapper>
                        <FatalErrorIcon/>
                    </IconWrapper>
                    <TextWrapper style={{paddingTop: "6px"}}>
                        <Trans>Произошла ошибка. Перезагрузите страницу при первой возможности.</Trans>
                    </TextWrapper>
                </ContentWrapper>
            </ContentWidthWrapperStyled>;
        }

        if (wsConnectionWarning) {
            return <ContentWidthWrapperStyled>
                <ContentWrapper>
                    <IconWrapper>
                        <NoInternetIcon/>
                    </IconWrapper>
                    <TextWrapper>
                        <Trans>Возникла проблема со связью.</Trans><br/>
                        <Trans>Пытаемся исправить, но пока соединение не восстановится, результат выполнения
                            упражнений не сохранится.</Trans>
                    </TextWrapper>
                </ContentWrapper>
            </ContentWidthWrapperStyled>;
        }

        if (!webRtcSupported) {
            return <ContentWidthWrapperStyled>
                <ContentWrapper>
                    <IconWrapper>
                        <OldBrowserIcon/>
                    </IconWrapper>
                    <TextWrapper>
                        <Trans>К сожалению, с этой версией браузера не получится подключиться к онлайн занятиям.</Trans>
                        <br/>
                        <Trans>Обновите браузер.</Trans>
                    </TextWrapper>
                </ContentWrapper>
            </ContentWidthWrapperStyled>;
        }
    }

    return <Wrapper className={classNames(
        (wsConnectionWarning || !webRtcSupported) && "warning",
        appInFatalError && "error"
    )}>
        {content()}
    </Wrapper>;
}
