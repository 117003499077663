import {IWsDisconnector} from "./IWsDisconnector";
import {IWsApiClient} from "../WsApiClient/IWsApiClient";
import {IInternetConnectionChecker} from "../InternetConnectionChecker/IInternetConnectionChecker";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class WsDisconnector implements IWsDisconnector {
    logger: ILogger;
    wsClient: IWsApiClient;
    internetConnectionChecker: IInternetConnectionChecker;

    constructor(logger: ILogger, wsClient: IWsApiClient, internetConnectionChecker: IInternetConnectionChecker) {
        this.logger = logger;
        this.wsClient = wsClient;
        this.internetConnectionChecker = internetConnectionChecker;
    }

    /**
     * @inheritDoc
     */
    startObserving(): void {
        this.internetConnectionChecker.registerOnOfflineSubscriber(
            'ws_disconnector',
            this.internetOnDisconnect.bind(this)
        );
    }

    /**
     * Обработка события разрыва соединения с интернетом
     *
     * @protected
     */
    protected internetOnDisconnect(): void {
        if (!this.wsClient.connectionIsOpen()) {
            this.logger.info(
                LoggerSectionsEnum.WS_DISCONNECTOR,
                'Ws connection already is not ready'
            );

            return;
        }

        this.logger.info(
            LoggerSectionsEnum.WS_DISCONNECTOR,
            'Disconnecting ws connection'
        );

        this.wsClient.closeConnection();
    }
}
