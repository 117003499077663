import React from "react";
import {t} from "@lingui/macro";
import {MessageDialog} from "../../../../../components/Ui/Elements/MessageDialog";
import {useDispatch} from "react-redux";
import {rebootApp} from "../../../../../../store/app/actions";

export enum VideoRoomErrorModalTypeEnum {
    NOT_FOUND_CAPTURE_DEVICES,
    USER_DEVICE_ERROR,
    UNKNOWN_ERROR
}

export interface VideoRoomErrorModalProps {
    errorType: VideoRoomErrorModalTypeEnum,
    onOk: () => void
}

export const VideoRoomErrorModal: React.FC<VideoRoomErrorModalProps> = (props) => {
    const dispatch = useDispatch();

    switch (props.errorType) {
        case VideoRoomErrorModalTypeEnum.NOT_FOUND_CAPTURE_DEVICES: {
            return <MessageDialog
                open={true}
                title={t`Устройство не подходит`}
                text={t`На вашем устройстве на найдена камера или микрофон, поэтому подключиться к этому уроку не получится.`}
                okMethod={() => {
                    props.onOk();

                    return Promise.resolve();
                }}
            />
        }
    }

    if (props.errorType === VideoRoomErrorModalTypeEnum.USER_DEVICE_ERROR) {
        return <MessageDialog
            open={true}
            title={t`Не удаётся подключиться по видеосвязи`}
            text={t`Проверьте, не запущены ли на устройстве другие приложения, показывающие изображение с камеры и нажмите «Повторить».`}
            okText={t`Повторить`}
            okMethod={() => {
                dispatch(rebootApp());

                props.onOk();

                return Promise.resolve();
            }}
        />
    }

    return <MessageDialog
        open={true}
        title={t`Не удаётся подключиться по видеосвязи`}
        text={t`Проверьте соединение с интернетом и нажмите «Повторить»`}
        okText={t`Повторить`}
        okMethod={() => {
            dispatch(rebootApp());

            props.onOk();

            return Promise.resolve();
        }}
    />
}
