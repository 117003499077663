import {put, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {SlideWorkDataActionTypes} from "../../store/slidesWorkData/type";
import {setSlideWorkDataAwardValues, setSlideWorkDataEmptyObject} from "../../store/slidesWorkData/actions";
import {
    DtoStudentHomeworkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Homework/DtoStudentHomeworkDataItem";
import {RoutesHelper} from "../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../enums/SlidePlayerIdEnum";

export function* watchProcessStudentHomeworkDataItemsList() {
    yield takeEvery<WorkerPayloadType<{lessonId: string, items: DtoStudentHomeworkDataItem[]}>>(
        SlideWorkDataActionTypes.PROCESS_STUDENT_HOMEWORK_DATA_ITEMS_LIST,
        processStudentHomeworkDataItemsList
    );
}

function* processStudentHomeworkDataItemsList(data: WorkerPayloadType<{lessonId: string, items: DtoStudentHomeworkDataItem[]}>) {
    const lessonId = data.payload.lessonId;
    const items = data.payload.items.length;

    for (let index = 0; index < items; index++) {
        const studentHomeworkDataItem = data.payload.items[index];

        const playerId = RoutesHelper.replaceParams(
            SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_HOMEWORK,
            [
                {
                    key: 'studentUserId',
                    value: studentHomeworkDataItem.userId
                },
                {
                    key: 'lessonId',
                    value: lessonId
                }
            ]
        );

        const slidesCount = studentHomeworkDataItem.slides.list.length;

        for (let slideIndex = 0; slideIndex < slidesCount; slideIndex++) {
            yield put(setSlideWorkDataEmptyObject({
                playerId: playerId,
                slideId: studentHomeworkDataItem.slides.list[slideIndex].tmSlideId,
                slideContentVersionNum: studentHomeworkDataItem.slides.list[slideIndex].tmSlideVersionNum,
                value: null
            }));

            yield put(setSlideWorkDataAwardValues({
                playerId: playerId,
                slideId: studentHomeworkDataItem.slides.list[slideIndex].tmSlideId,
                value: {
                    totalAward: studentHomeworkDataItem.slides.list[slideIndex].totalAward,
                    totalMissedAward: studentHomeworkDataItem.slides.list[slideIndex].totalMissedAward
                }
            }));
        }
    }
}
