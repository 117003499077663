export const STREAM_IS_SCREEN_SUFFIX = 'screen';
export const USER_ID_SUFFIX_DELIMITER = '_';

export class PublisherNameUtils {
    static createComplexUserId(userId: string, date: Date, forScreenSharing: boolean): string {
        let result = userId + USER_ID_SUFFIX_DELIMITER + date.getTime();

        if (forScreenSharing) {
            result = result + USER_ID_SUFFIX_DELIMITER + STREAM_IS_SCREEN_SUFFIX;
        }

        return result;
    }

    static parseComplexUserId(complexUserId: string): {userId: string, ts: number, isScreenSharing: boolean} {
        const complexUserIdParts = complexUserId.split(USER_ID_SUFFIX_DELIMITER);

        return {
            userId: complexUserIdParts[0],
            ts: parseInt(complexUserIdParts[1], 10),
            isScreenSharing: (complexUserIdParts.length>2 && complexUserIdParts[2] === STREAM_IS_SCREEN_SUFFIX)
        }
    }
}
