import React, {useContext, useMemo} from "react";
import {EpisodeContext, IEpisodeContext} from "./EpisodeContext";
import {ISubscriptionContext, SubscriptionContext} from "../SubscriptionContext";
import styled from "styled-components";
import {ImageCover} from "../../../../../../../components/Ui/Elements/SelfStudyTrackCover/Common/ImageCover";
import {SelfStudyTrackCoverMaskTypeEnum} from "../../../../../../../../enums/SelfStudyTrackCoverMaskTypeEnum";
import {UserFileFormatsEnum} from "../../../../../../../../enums/UserFileEnums";
import {ReactComponent as LockOutlinedSvg} from "../../../../../../../components/Ui/Svg/Key.svg";
import {ReactComponent as NoCoverSvg} from "../../../../../../../components/Ui/Svg/Book.svg";

const LockPlaceholder = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.colors.textSecondary}11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.no-bg {
        background-color: unset;
    }
`;

const LockPlaceholderIcon = styled(LockOutlinedSvg)`
    opacity: 0.5;
    height: 60px;
    width: 60px;
    transform: rotate(-25deg);
`;

const NoCoverSvgIcon = styled(NoCoverSvg)`
    opacity: 0.5;
    height: 60px;
    width: 60px;
    transform: rotate(-25deg);
`;

// const UserImageAsPlaceholder = styled(UserImage)`
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
// `;
//
// const UserImagePlaseholderBackdropFilter = styled.div`
//     backdrop-filter: blur(5px);
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
// `;

const ImageWrapper = styled.div`
    position: absolute;
    width: 120px;
    aspect-ratio: 1/1;
    right: -24px;
    bottom: -24px;
    z-index: 0;

    @media (${({theme}) => theme.media.small}) {
        width: 94px;
        top: 16px;
        left: 16px;
        right: unset;
        bottom: unset;
    }
`;

export const Cover: React.FC = () => {
    const {overviewItem} = useContext<ISubscriptionContext>(SubscriptionContext);
    const {episodeItem} = useContext<IEpisodeContext>(EpisodeContext);

    const imageElement = useMemo(() => {
        if (episodeItem.isSecretEpisode) {
            return <ImageCover
                maskType={overviewItem.coverMaskId as SelfStudyTrackCoverMaskTypeEnum}
                imageFileId={''}
                fileFormat={UserFileFormatsEnum.TM_LESSON_COVER_ORIGINAL}
            >
                {/*{*/}
                {/*    (episodeItem.coverFileId !== null)*/}
                {/*        ? <LockPlaceholder className={'no-bg'}>*/}
                {/*            <UserImageAsPlaceholder fileId={episodeItem.coverFileId}*/}
                {/*                                    fileFormat={UserFileFormatsEnum.TM_LESSON_COVER_ORIGINAL}/>*/}
                {/*            <UserImagePlaseholderBackdropFilter/>*/}
                {/*            <LockPlaceholderIcon/>*/}
                {/*        </LockPlaceholder>*/}
                {/*        : <LockPlaceholder>*/}
                {/*            <LockPlaceholderIcon/>*/}
                {/*        </LockPlaceholder>*/}
                {/*}*/}
                <LockPlaceholder>
                    <LockPlaceholderIcon/>
                </LockPlaceholder>
            </ImageCover>
        }

        if (!episodeItem.coverFileId) {
            return <ImageCover
                maskType={overviewItem.coverMaskId as SelfStudyTrackCoverMaskTypeEnum}
                imageFileId={''}
                fileFormat={UserFileFormatsEnum.TM_LESSON_COVER_ORIGINAL}
            >
                <LockPlaceholder>
                    <NoCoverSvgIcon/>
                </LockPlaceholder>
            </ImageCover>
        }

        return <ImageCover
            maskType={overviewItem.coverMaskId as SelfStudyTrackCoverMaskTypeEnum}
            imageFileId={episodeItem.coverFileId}
            fileFormat={UserFileFormatsEnum.TM_LESSON_COVER_ORIGINAL}
        />
    }, [episodeItem.coverFileId, episodeItem.isSecretEpisode, overviewItem.coverMaskId]);

    return <ImageWrapper>
        {imageElement}
    </ImageWrapper>
}