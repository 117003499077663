import {instanceCachingFactory} from 'tsyringe';
import {IInternetConnectionChecker} from "../components/InternetConnectionChecker/IInternetConnectionChecker";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";
import {IWsDisconnector} from "../components/WsDisconnector/IWsDisconnector";
import {WsDisconnector} from "../components/WsDisconnector/WsDisconnector";
import {IWsApiClient} from "../components/WsApiClient/IWsApiClient";

export default instanceCachingFactory<IWsDisconnector>((container) => {
    return new WsDisconnector(
        container.resolve<ILogger>(DiTokens.LOGGER),
        container.resolve<IWsApiClient>(DiTokens.WS_CLIENT),
        container.resolve<IInternetConnectionChecker>(DiTokens.INTERNET_CONNECTION_CHECKER),
    );
});
