/**
 * Структура запроса к WebSocket серверу.
 */
export class RequestBaseDto {
    id: number | string;
    method: string;
    params: any;


    constructor(id: number | string, method: string, params: any) {
        this.id = id;
        this.method = method;
        this.params = params;
    }
}