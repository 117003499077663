import { IsNotEmpty, IsNumber, IsString } from 'class-validator';

export class DtoStudentLessonWorkSlideData {
  @IsNotEmpty()
  @IsString()
  tmSlideId: string;

  @IsNotEmpty()
  @IsNumber()
  tmSlideVersionNum: number;

  @IsNumber()
  totalAward: number;

  @IsNumber()
  totalMissedAward: number;
}
