import styled from 'styled-components';

export const HeaderContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

export const HeaderContentItemWrapper = styled.div`
  margin-right: 12px;
  user-select: none;
  opacity: 0.5;
  transition: opacity 0.3s;
  
  :hover {
    opacity: 1;
  }
  
  &.warning {
    opacity: 1;
  }
  
  @media (${({theme}) => theme.media.small}) {
    margin-right: 7px;
  }
`;

export const Delimiter = styled.div`
  flex-grow: 1;
`