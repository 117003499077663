import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import {StepInitItem, StepWizard} from "../../../../components/StepWizard";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {GeneralForm} from "./GeneralForm";
import {FinishPage} from "./FinishPage";
import {RegularText} from "../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {StringHelper} from "../../../../../helpers/StringHelper";
import {LoadingState} from "../../lesson/Common/LessonMaterialsModeBase";
import {stTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {CreateStudentPageContextProvider} from "./CreateStudentPageContextProvider";
import {PageWrapper} from "../../../../components/PageWrapper";
import {RoutesList} from "../../../../RoutesList";
import styled from "styled-components";

const RightBlockWrapper = styled.div`
  margin-bottom: 32px;
`;

export const NewStudentPage: React.FC = () => {
    const {stepId} = useParams();

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isMedium = useMediaQuery(`(${currentTheme.media.medium})`);

    const [pageState, setPageState] = useState<LoadingState>(LoadingState.NOT_INIT);
    const [studentGeneralInfo, setStudentGeneralInfo] = useState<DtoStudentGeneralInfo | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const stToken = useSelector(stTokenSelector);

    const createDraft = useCallback(() => {
        if (stToken === null) {
            setPageState(LoadingState.ERROR);

            return;
        }

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        setPageState(LoadingState.LOADING);

        stApiClient.createStudentProfileDraft(stToken, newAbortController)
            .then((data) => {
                setStudentGeneralInfo(data.result);
                setPageState(LoadingState.SUCCESS);
            })
            .catch((e) => {
                setPageState(LoadingState.ERROR);

                if (e instanceof NoConnection) {
                    return;
                }

                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.STONLINE_STUDENTS_API,
                    `Error on create student draft: `,
                    e
                );
            });

    }, [abortController, stToken]);

    const activeSectionId = useMemo<PageSectionsEnum>(() => {
        const allSections = Object.values(PageSectionsEnum) as string[];

        if (allSections.indexOf(stepId ?? '') > -1) {
            return stepId as PageSectionsEnum;
        }

        return PageSectionsEnum.GENERAL;
    }, [stepId]);

    const stepsConfig = useMemo<StepInitItem[]>(() => {
        return [
            {
                id: PageSectionsEnum.GENERAL,
                component: <GeneralForm/>
            },
            {
                id: PageSectionsEnum.FINISH,
                component: <FinishPage/>
            }
        ];
    }, []);

    const activeSectionNotice = useMemo<JSX.Element | null>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.GENERAL: {
                return <RegularText>
                    <Trans>Количество учеников в базе не ограничено.</Trans>
                </RegularText>;
            }
            default: {
                return null;
            }
        }
    }, [activeSectionId]);

    const shortStudentName = useMemo<string>(() => {
        if (studentGeneralInfo === null) {
            return '';
        }

        return StringHelper.extractFirstName(studentGeneralInfo.longName);
    }, [studentGeneralInfo]);

    const activeSectionTitle = useMemo<string>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.GENERAL: {
                return t`Добавить ученика`;
            }
            case PageSectionsEnum.FINISH: {
                if (
                    (studentGeneralInfo === null)
                    || (shortStudentName === null)
                ) {
                    return t`Готово`
                }

                const suffix = (studentGeneralInfo.gender === 0)
                    ? t`готова к учёбе`
                    : t`готов к учёбе`;

                return `${shortStudentName} ${suffix}`;
            }
            default: {
                return t`Добавление курса`;
            }
        }
    }, [activeSectionId, shortStudentName, studentGeneralInfo]);


    useEffect(() => {
        if (pageState === LoadingState.NOT_INIT) {
            createDraft();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (pageState === LoadingState.NOT_INIT || pageState === LoadingState.LOADING) {
        return <DefaultLoader/>;
    }

    if (
        (pageState === LoadingState.ERROR)
        || (studentGeneralInfo === null)
    ) {
        return <ErrorLoadingContent retryBtnClick={createDraft}/>;
    }

    return <CreateStudentPageContextProvider value={{
        studentGeneralInfoDto: studentGeneralInfo,
        setStudentGeneralInfoDto: setStudentGeneralInfo,
        shortStudentName: shortStudentName
    }}>
        <PageWrapper
            backLink={null}
            pageTitle={activeSectionTitle}
            pageContent={
                <StepWizard
                    steps={stepsConfig}
                    pageLink={RoutesList.TEACHER_CREATE_STUDENT}/>
            }
            rightBlockContent={
                (isMedium && activeSectionNotice) ?
                    <RightBlockWrapper>
                        {activeSectionNotice}
                    </RightBlockWrapper>
                    : null
            }
        />
    </CreateStudentPageContextProvider>;
}