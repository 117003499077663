import {IDeviceDetector} from "./IDeviceDetector";
import DeviceDetectorJs from "device-detector-js";
import {SaveDeviceInfoRequestDto} from "../HttpApiClient/ApiDto/Request/User/SaveDeviceInfoRequestDto";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";

export class DeviceDetector implements IDeviceDetector {
    protected dateHelperService: IDateHelperService;

    constructor(dateHelperService: IDateHelperService) {
        this.dateHelperService = dateHelperService;
    }

    /**
     * @inheritDoc
     */
    isIPhone(): boolean {
        const info = this.getInfo();

        if (info.device === null) {
            return false;
        }

        return info.device.brand === 'Apple';
    }

    /**
     * @inheritDoc
     */
    isSafari(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name === 'Mobile Safari';
    }

    isDesktop(): boolean {
        const info = this.getInfo();

        return !!(info.device && info.device.type === "desktop");
    }

    collectForApi(): SaveDeviceInfoRequestDto {
        const info = this.getInfo();

        const model = new SaveDeviceInfoRequestDto();

        model.timezoneName = this.dateHelperService.getCurrentDeviceTimezoneName();
        model.deviceType = (info.device && info.device.type) ? info.device.type : null;
        model.deviceBrand = (info.device && info.device.brand) ? info.device.brand : null;
        model.osName = (info.os && info.os.name) ? info.os.name : null;
        model.osVersion = (info.os && info.os.version) ? info.os.version : null;
        model.browserType = (info.client && info.client.type) ? info.client.type : null;
        model.browserName = (info.client && info.client.name) ? info.client.name : null;
        model.browserVersion = (info.client && info.client.version) ? info.client.version : null;

        return model;
    }

    protected getInfo() {
        const deviceDetector = new DeviceDetectorJs();

        return deviceDetector.parse(navigator.userAgent);
    }
}
