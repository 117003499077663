import * as React from "react";
import {useMemo} from "react";
import {AvatarWrapper, UserAvatarWrapper, UserNameWrapper} from "./styles";
import {Avatar} from "../../../../components/Ui/Elements/Avatar";
import {Popup, PopupItem} from "../../../../components/Ui/Elements/Popup";
import {useDispatch, useSelector} from "react-redux";
import * as UserActionCreators from "../../../../../store/user/actions";
import {
    currentAvatarFileIdSelector,
    selectedUserInSchool as selectedUserInSchoolSelector
} from "../../../../../store/user/selector";
import {t} from "@lingui/macro";
import {StringHelper} from "../../../../../helpers/StringHelper";
import {RoutesList} from "../../../../RoutesList";

interface UserAvatarProps {
    showName: boolean,
    menuToRight?: boolean
}

export const UserAvatar = (props: UserAvatarProps) => {
    const dispatch = useDispatch();
    const selectedUserInSchool = useSelector(selectedUserInSchoolSelector);
    const currentAvatarFileId = useSelector(currentAvatarFileIdSelector);

    const popupItems = useMemo((): PopupItem[] => {
        const logout = () => dispatch(UserActionCreators.logoutUser());
        //const setSplash = () => dispatch(LayoutActionCreators.setSplashScreenVisible(true));

        return [
            {
                text: t`Настроить профиль`,
                href: {
                    link: RoutesList.PROFILE_SETTINGS
                }
            },
            {
                text: t`Проверить камеру и микрофон`,
                href: {
                    link: RoutesList.VIDEO_CALL_TEST
                }
            },
            {
                text: t`Выйти из профиля`,
                onClick: () => {
                    logout()
                }
            }
        ]
    }, [dispatch]);
    
    const userName = useMemo<string>(() => {
        if (selectedUserInSchool !== null) {
            return StringHelper.createShortName(selectedUserInSchool.name);
        }
        
        return t`Профиль`;
    }, [selectedUserInSchool]);

    const content = useMemo(() => {
        const avatarContent = () => {
            return <AvatarWrapper>
                <Avatar size={40}
                        fileId={currentAvatarFileId}
                        userName={(selectedUserInSchool) ? selectedUserInSchool.name : null}/>
            </AvatarWrapper>
        }

        return <UserAvatarWrapper>
            {avatarContent()}
            {props.showName && (
                <UserNameWrapper>
                    {userName}
                </UserNameWrapper>
            )}
        </UserAvatarWrapper>;
    }, [currentAvatarFileId, props.showName, selectedUserInSchool, userName]);

    return <Popup
        nested={true}
        items={popupItems}
        trigger={content}
        position={(props.menuToRight) ? "bottom left" : "bottom right"}
        arrow={false}
        keepTooltipInside={true}
        contentStyle={{transform: "translateY(8px)"}}
        on="click"
    />;
};
