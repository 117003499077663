import {IsNotEmpty, IsOptional, IsString} from 'class-validator';
import {IBaseApiDto} from "../../../IBaseApiDto";

/**
 * DTO
 */
export class DtoVideoPin implements IBaseApiDto {
    @IsNotEmpty()
    @IsString()
    lessonId: string;

    @IsOptional()
    @IsString()
    pinnedUserId: string | null;
}
