import React, {forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {SlidePlayer} from "../../../../../components/SlidePlayer";
import {IPlayerContext, PlayerContext} from "../../../../../components/SlidePlayer/PlayerContext";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {EditorDataDtoMapper} from "../../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {t} from "@lingui/macro";
import {SlideItemsParamsBySlide} from "../../../../../components/SlidePlayer/SlideItemParamsStore";

export interface PlayerRightSideProps {
    className?: string;
}

export interface PlayerContentRefMethods {
}

enum LoadingStateEnum {
    NOT_LOADED,
    IN_PROCESS,
    SUCCESS,
    ERROR
}

export const PlayerContentSide = forwardRef<PlayerContentRefMethods, PlayerRightSideProps>(
    (props, ref) => {
        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const {selectedSlide, selectedSlideId, slidesContent, setSlidesContent} = playerContext;
        const sessionToken = useSelector(sessionTokenSelector);

        const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_LOADED);

        useEffect(() => {
            const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            if (
                (loadingState !== LoadingStateEnum.NOT_LOADED)
                || (!selectedSlideId)
                || (!sessionToken)
            ) {
                return;
            }

            if (slidesContent[selectedSlideId] !== undefined) {
                setLoadingState(LoadingStateEnum.SUCCESS);

                return;
            }

            setLoadingState(LoadingStateEnum.IN_PROCESS);

            // Похоже, контента слайда нет. Загрузим с сервера.
            httpApiClient.tmGetSlideContent(sessionToken, selectedSlideId, null)
                .then((data) => {
                    setSlidesContent(
                        selectedSlideId,
                        EditorDataDtoMapper.dtoToEditorData(data.data.content)
                    );

                    setLoadingState(LoadingStateEnum.SUCCESS);
                })
                .catch((err) => {
                    setLoadingState(LoadingStateEnum.ERROR);

                    logger.error(LoggerSectionsEnum.TM_SLIDES_API, "Error on load slide content: ", err);
                });

        }, [loadingState, selectedSlideId, sessionToken, setSlidesContent, slidesContent]);

        useEffect(() => {
            setLoadingState(LoadingStateEnum.NOT_LOADED);
        }, [selectedSlideId]);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const slideItemsParams = useMemo<SlideItemsParamsBySlide>(() => {
            const result: SlideItemsParamsBySlide = {};

            if (!selectedSlideId || !slidesContent[selectedSlideId]) {
                return result;
            }

            slidesContent[selectedSlideId].items.forEach((slideContentItem) => {
                result[slideContentItem.id] = slideContentItem.params;
            });

            return result;
        }, [selectedSlideId, slidesContent]);

        const content = () => {
            if (!selectedSlideId) {
                return;
            }

            switch (loadingState) {
                case LoadingStateEnum.NOT_LOADED:
                case LoadingStateEnum.IN_PROCESS: {
                    return <DefaultLoader/>;
                }
                case LoadingStateEnum.ERROR: {
                    return <ErrorLoadingContent title={t`Не удалось загрузить содержимое слайда`}
                                                retryBtnClick={() => {
                                                    setLoadingState(LoadingStateEnum.NOT_LOADED);
                                                }}/>
                }
                case LoadingStateEnum.SUCCESS: {
                    if ((!selectedSlide) || (!slidesContent[selectedSlideId])) {
                        return;
                    }

                    return <SlidePlayer
                        playerId={playerContext.playerId}
                        slideId={selectedSlide.tmSlideId}
                        slideContent={slidesContent[selectedSlideId]}
                        slideItemsParams={slideItemsParams}
                    />;
                }
                default: {
                    return;
                }
            }
        }

        return <div className={props.className}>
            {content()}
        </div>;
    }
);