import React from "react";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILessonPageContext} from "../../LessonPageContext";
import styled from "styled-components";
import StudentBlock from "./StudentBlock";

const StudentBlockGrid = styled.div`
  display: grid;

  grid-template-columns: 100%;
  grid-gap: 10px;

  @media (${({theme}) => theme.media.medium}) {
    grid-template-columns: 50% 50%;
  }
`;

interface StudentsInLessonModePageProps {
    stToken: string | null;
    lessonPageContext: ILessonPageContext;
}

interface StudentsInLessonModePageState {

}

export class StudentsInLessonModePage extends React.Component<StudentsInLessonModePageProps, StudentsInLessonModePageState> {
    protected stonlineApiClient: IStonlineApiClient;
    protected dateHelperService: IDateHelperService;

    protected logger: ILogger;

    constructor(props: Readonly<StudentsInLessonModePageProps> | StudentsInLessonModePageProps) {
        super(props);

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
    }

    render() {
        return <StudentBlockGrid>
            {
                this.props.lessonPageContext.studentInLessonItems.map((item) => {
                    return <StudentBlock stToken={this.props.stToken} key={item.id}
                                         stStudentInLesson={item}
                                         updateSavedData={this.props.lessonPageContext.updateStudentInLessonInCache}/>
                })
            }
        </StudentBlockGrid>;
    }
}
