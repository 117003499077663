import styled, {css} from 'styled-components';

export const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;  
`;

LayoutContainer.displayName = 'LayoutContainer';

interface NavigationAreaProps {
    active: boolean
}

export const NavigationAreaWrapper = styled.div<NavigationAreaProps>`
  z-index: ${({theme}) => theme.zIndices.navigation};

  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${p => (p.active === true) ? "100%" : "0%"};
  transition: width .2s ease;


  @media (${({theme}) => theme.media.small}) {
    transition: none;
  }

  @media (${({theme}) => theme.media.extraLarge}) {
   &:not(.static-disabled) {
      width: 240px;
   }
  }
`;

NavigationAreaWrapper.displayName = 'NavigationAreaWrapper';

export const NavigationAreaBackdrop = styled.div<NavigationAreaProps>`
  display: none;

  @media (${({theme}) => theme.media.small}) {
    display: block;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;
    background-color: ${({theme}) => theme.colors.navigationBackdrop};
    opacity: ${p => (p.active === true) ? p.theme.opacity.navigationBackdrop : "0"};
  }

  @media (${({theme}) => theme.media.extraLarge}) {
    &:not(.static-disabled) {
      display: none;
    }
  }
`;

export const NavigationArea = styled.div<NavigationAreaProps>`
  background-color: ${({theme}) => theme.colors.backgroundSecondary};

  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${p => (p.active === true) ? "100%" : "0%"};
  transition: width .2s ease;
  overflow-x: hidden;

  @media (${({theme}) => theme.media.small}) {
    width: ${p => (p.active === true) ? "240px" : "0"};
  }

  @media (${({theme}) => theme.media.extraLarge}) {
    &:not(.static-disabled) {
      width: 240px;
    }
  }
`;

export const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-grow: 1;

  @media (${({theme}) => theme.media.extraLarge}) {
    &:not(.left-navigation-static-disabled) {
      margin-left: 240px;
    }
  }
`;

ContentArea.displayName = 'ContentArea';

export const ContentWidthWrapperCss = css`
  display: block;

  width: 100%;
  margin: 0;

  @media (${({theme}) => theme.media.small}) {
    max-width: 540px;
  }

  @media (${({theme}) => theme.media.medium}) {
    max-width: 768px;
  }

  @media (${({theme}) => theme.media.large}) {
    max-width: 1080px;
  }
`;

export const ContentWidthWrapper = styled.div`
  ${ContentWidthWrapperCss};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${({theme}) => theme.size.headerHeight}px;
  max-height: ${({theme}) => theme.size.headerHeight}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
  justify-content: center;

  position: sticky;
  top: 0;
  background: ${({theme}) => theme.colors.backgroundPrimary};
  z-index: ${({theme}) => theme.zIndices.header};
  
  &.transparent-header {
    border-bottom: none;
    background: unset;
  }  
`;

export const HeaderBurgerIconWrapper = styled.div`
  position: absolute;
  left: 24px;
  top: 16px;
  height: 40px;
  width: 40px;
  cursor: pointer;
    
  &.transparent-header {
      background-color: ${({theme}) => theme.colors.backgroundPrimary}80;
      border-radius: 5px;
  }

  @media (${({theme}) => theme.media.extraLarge}) {
    &.hide-on-extra-large {
      display: none;
    }    
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 20px;

  max-width: 100%;
  width: 100px;
  min-width: 100%;

  flex-grow: 1;
`;

PageWrapper.displayName = 'LayoutPageWrapper';