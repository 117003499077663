import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length} from 'class-validator';
import {LengthEnum} from "../../../Enums/LengthEnum";

/**
 * DTO обзорных данных эпизода self-study трека для отображения в списке подписок ученика
 */
export class DtoSelfStudyTrackSubscriptionEpisodeOverviewItem {
    @IsOptional()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    id: string | null;

    @IsOptional()
    @IsString()
    name: string | null;

    @IsOptional()
    @IsString()
    coverFileId: string | null;

    @IsOptional()
    @IsString()
    description: string | null;

    @IsOptional()
    @IsString()
    openedAt: string | null;

    @IsOptional()
    @IsString()
    completedAt: string | null;

    @IsNotEmpty()
    @IsNumber()
    numberOfEpisode: number;

    @IsBoolean()
    isSecretEpisode: boolean;
}
