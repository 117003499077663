import styled from "styled-components";

export const ControlPanelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  height: 48px;
  gap: 2px;
  overflow: hidden;

  @media (${({theme}) => theme.media.small}) {
    height: 50px;
  }
`;

export const ControlPanelItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;
  background-color: ${({theme}) => theme.colors.backgroundSecondary};
  cursor: pointer;
  flex-direction: row;
  color: ${({theme}) => theme.colors.textPrimary};

  @media (${({theme}) => theme.media.small}) {
    flex-direction: column;
  }

  @media (${({theme}) => theme.media.medium}) {
    flex-direction: row;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    color: ${({theme}) => theme.colors.textTertiary};
  }

  &.warning {
    color: ${({theme}) => theme.colors.accentSecondary};
  }
`;

export const ControlPanelItemIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  color: inherit;
  
  &.no-margin {
    margin-right: 0;
  }

  @media (${({theme}) => theme.media.large}) {
    margin-right: 10px;
    
    &.no-margin {
        margin-right: 0;
    }
  }
`;

export const ControlPanelItemText = styled.div`
  color: inherit;
  font-size: 14px;
  display: none;

  @media (${({theme}) => theme.media.medium}) {
    display: block;
  }
`;
