export enum UserFileTypeEnum {
    USER_AVATAR = 'user_avatar',
    SCHOOL_AVATAR = 'school_avatar',
    SELF_STUDY_TRACK_COVER = 'self_study_track_cover',
    TM_SLIDE_BACKGROUND_PICTURE = 'tm_slide_background_picture',
    TM_LESSON_COVER = 'tm_lesson_cover',
    TM_SLIDE_PICTURE = 'tm_slide_picture',
    TM_SLIDE_VIDEO = 'tm_slide_video',
    TM_SLIDE_AUDIO = 'tm_slide_audio',
    TM_SLIDE_DIALOG_BUBBLE_ELEMENT_AVATAR = 'tm_slide_dialog_bubble_element_avatar',
    TM_SLIDE_DIALOG_BUBBLE_ELEMENT_VOICEOVER = 'tm_slide_dialog_bubble_element_voiceover',
}

export enum UserFileFormatsEnum {
    VIDEO_MP4_720 = 'video_mp4_720',
    VIDEO_MP4_480 = 'video_mp4_480',
    AUDIO_MP3_best = 'audio_mp3_best',

    TM_LESSON_COVER_ORIGINAL = 'tm_lesson_cover_original',

    TM_SLIDE_BACKGROUND_PICTURE_ORIGINAL = 'tm_slide_background_picture_original',

    TM_SLIDE_PICTURE_ORIGINAL = 'tm_slide_picture_original',

    TM_SLIDE_BUBBLE_ELEMENT_AVATAR_ORIGINAL = 'tm_slide_bubble_element_avatar_original',
    TM_SLIDE_BUBBLE_ELEMENT_VOICEOVER_ORIGINAL = 'tm_slide_bubble_element_voiceover_original',

    TM_USER_AVATAR_ORIGINAL = 'user_avatar_original',

    SELF_STUDY_TRACK_COVER_ORIGINAL = 'self_study_track_cover_original',

    VIDEO_UNKNOWN_ORIGINAL = 'video_unknown_original',
    AUDIO_UNKNOWN_ORIGINAL = 'audio_unknown_original',
    PICTURE_UNKNOWN_ORIGINAL = 'picture_unknown_original',
    UNKNOWN_ORIGINAL = 'unknown_original',
}

export enum DefaultImageMimeSupported {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
}

export enum SlidePictureMimeSupported {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif'
}

export enum SlideVideoMimeSupported {
    AVI = 'video/x-msvideo',
    MP4 = 'video/mp4',
    tGP = 'video/3gpp',
    MOV = 'video/quicktime',
    WMV = 'video/x-ms-wmv',
    M4V = 'video/x-m4v',
    MKV = 'video/x-matroska',
    WEBM = 'video/webm',
    MPEG = 'video/mpeg',
}

export enum SlideAudioMimeSupported {
    AAC = 'audio/aac',
    FLAC = 'audio/flac',
    WAV = 'audio/x-wav',
    MP3 = 'audio/mpeg',
    OGG = 'audio/ogg',
    OGG2 = 'application/ogg',
    M4A = 'audio/m4a',
    ASF = 'video/x-ms-asf', // WMA
    WMA = 'video/x-ms-wma'
}