import React, {MouseEvent, useCallback, useMemo} from "react";
import {DtoTmSection} from "../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {ModalResultType, TmSectionModal} from "../modals/section-modal";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import styled from "styled-components";
import {RegularTextCss} from "../../../styles/global-elements";
import {Sortable, SortableItemComponent} from "../../Sortable/Sortable";
import {RouterLink} from "../../Ui/Elements/Link";
import {ReactComponent as PencilIcon} from "../../Ui/Svg/Pencil.svg";
import {MinusIconStyled} from "../common/styled-components";
import {ReactComponent as SmallCircleOutlineIcon} from "../../Ui/Svg/SmallCircleOutline.svg";
import {ReactComponent as CheckIcon} from "../../Ui/Svg/Check.svg";

const SectionsListStyled = styled.div`
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 30px;
  }
`;

const SortableWrapper = styled.div`
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.medium}) {
    margin-bottom: 30px;
  }

  .handle-btn {
    align-self: flex-start;
    padding-top: 5px;
  }
`;

const SectionItem = styled.div`
  ${RegularTextCss};

  display: flex;
  flex-direction: row;
  padding: 5px 0 15px 0;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SectionItemIconWrapper = styled.div`
  max-height: 40px;
`;

const SectionItemTextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  opacity: 0.8;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

const SectionItemName = styled(RouterLink)`
  ${RegularTextCss};

  padding-top: 7px;

  color: ${({theme}) => theme.colors.textPrimary};
  white-space: normal;
  word-break: break-all;

  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;

const SectionItemDescription = styled.div`
  color: ${({theme}) => theme.colors.textSecondary};
  white-space: normal;
  word-break: break-all;
  padding-top: 10px;
  cursor: default;
`;

const SectionItemControlsWrapper = styled.div`
  color: ${({theme}) => theme.colors.textPrimary};
  opacity: 0.3;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
`;

const SectionItemBtnWrapper = styled.div`
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
`;

export enum SectionIconTypeEnum {
    NO_USED,
    USED,
    LAST_USED
}

interface SectionsListProps {
    sectionItems: DtoTmSection[];
    levelId: string;
    modalOnResult: (action: ModalResultType, item: (DtoTmSection | null)) => void;
    reorderItems?: (from: number, to: number) => void;
    itemsDisabled?: boolean;
    navigateToSectionMethod?: (dtoTmSection: DtoTmSection) => void;
    selectMode?: boolean;
    usedSectionIds?: string[]; // отмечаются кружком уровни из списка, последняя из списка выделяется флажком
}

export const SectionsList: React.FC<SectionsListProps> = (props) => {
    const defaultLinkToSection = (levelId: string, sectionId: string) => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_TEACHING_MATERIALS_SECTION_DETAILS,
            [
                {
                    key: 'tmLevelId',
                    value: levelId
                },
                {
                    key: 'tmSectionId',
                    value: sectionId
                }
            ]
        )
    };

    const sectionItemOnClick = useCallback((e: MouseEvent<HTMLAnchorElement>, item: DtoTmSection) => {
        if (props.navigateToSectionMethod === undefined) {
            return;
        }

        props.navigateToSectionMethod(item);
        e.preventDefault();
    }, [props]);

    const lastUsedSectionId = useMemo<string | null>(() => {
        if (props.usedSectionIds === undefined) {
            return null;
        }

        if (props.usedSectionIds.length === 0) {
            return null;
        }

        return props.usedSectionIds[props.usedSectionIds.length - 1];
    }, [props.usedSectionIds]);

    const itemIcon = (item: DtoTmSection) => {
        if ((lastUsedSectionId === null) || (props.usedSectionIds === undefined)) {
            return <MinusIconStyled/>;
        }

        if (lastUsedSectionId === item.id) {
            return <SmallCircleOutlineIcon/>;
        }

        if (props.usedSectionIds.indexOf(item.id) > -1) {
            return <CheckIcon/>;
        }

        return <MinusIconStyled/>;
    }

    const sectionItem = (item: DtoTmSection) => {
        return <SectionItem>
            <SectionItemIconWrapper>{itemIcon(item)}</SectionItemIconWrapper>
            <SectionItemTextWrapper>
                <SectionItemName
                    onClick={(e) => sectionItemOnClick(e, item)}
                    to={defaultLinkToSection(props.levelId, item.id)}>{item.name}</SectionItemName>
                {
                    (item.description) && <SectionItemDescription>{item.description}</SectionItemDescription>
                }
            </SectionItemTextWrapper>
            {
                (props.selectMode !== true)
                && <SectionItemControlsWrapper>
                    <SectionItemBtnWrapper>
                        <TmSectionModal levelId={props.levelId} sectionItem={item} result={props.modalOnResult}
                                        trigger={<PencilIcon/>}/>
                    </SectionItemBtnWrapper>
                </SectionItemControlsWrapper>
            }
        </SectionItem>
    }

    if (props.reorderItems === undefined) {
        return <SectionsListStyled>
            {
                props.sectionItems.map((item) => {
                    return sectionItem(item);
                })
            }
        </SectionsListStyled>
    } else {
        return <SortableWrapper><Sortable<DtoTmSection>
            tinyItems={true}
            items={props.sectionItems.map((item): SortableItemComponent<DtoTmSection> => {
                return {
                    id: item.id,
                    selected: false,
                    disabled: (props.itemsDisabled === true),
                    item: item
                }
            })}
            reorderItems={(array, from, to) => {
                if (props.reorderItems !== undefined) {
                    props.reorderItems(from, to);
                }
            }}
            renderItemContent={(item, index) => {
                return sectionItem(item.item);
            }}
        /></SortableWrapper>
    }
}