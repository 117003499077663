import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {SlidesWorkDataState, SlideWorkDataActionTypes} from "../../store/slidesWorkData/type";
import {setSlideWorkDataAwardValues, setSlideWorkDataEmptyObject} from "../../store/slidesWorkData/actions";
import {RoutesHelper} from "../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../enums/SlidePlayerIdEnum";
import {
    DtoSEStudentLessonWorkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoSEStudentLessonWorkDataItem";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {currentRoomId} from "../../store/lessonRoom/selector";
import {slidesWorkDataStateSelector} from "../../store/slidesWorkData/selector";

export function* watchHandleSEStudentLessonWorkDataItemsList() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEStudentLessonWorkDataItem>, null>>>(
        SlideWorkDataActionTypes.HANDLE_SE_STUDENT_LESSON_WORK_DATA_SUMMARY_ITEM,
        handleSEStudentLessonWorkDataItemsList
    );
}

function* handleSEStudentLessonWorkDataItemsList(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEStudentLessonWorkDataItem>, null>>) {
    const studentLessonWorkDataItem = data.payload.response.dto.payload;

    const currentRoom = (yield select(currentRoomId)) as string | null;

    if (studentLessonWorkDataItem.lessonId !== currentRoom) {
        return;
    }

    const playerId = RoutesHelper.replaceParams(
        SlidePlayerIdEnum.TEACHER_STUDENT_SLIDE_ON_LESSON,
        [
            {
                key: 'studentUserId',
                value: studentLessonWorkDataItem.userId
            },
            {
                key: 'lessonId',
                value: studentLessonWorkDataItem.lessonId
            }
        ]
    );

    // Проверим - есть ли объект
    const currentSlidesWorkData = (yield select(slidesWorkDataStateSelector)) as SlidesWorkDataState;

    // Если slideItem нет, то создадим объект
    if (
        (currentSlidesWorkData.indexByPlayerId[playerId] === undefined)
        || (currentSlidesWorkData.indexByPlayerId[playerId].indexBySlideId[studentLessonWorkDataItem.slideData.tmSlideId] === undefined)
    ) {
        yield putResolve(setSlideWorkDataEmptyObject({
            playerId: playerId,
            slideId: studentLessonWorkDataItem.slideData.tmSlideId,
            slideContentVersionNum: studentLessonWorkDataItem.slideData.tmSlideVersionNum,
            value: null
        }));
    }

    yield put(setSlideWorkDataAwardValues({
        playerId: playerId,
        slideId: studentLessonWorkDataItem.slideData.tmSlideId,
        value: {
            totalAward: studentLessonWorkDataItem.slideData.totalAward,
            totalMissedAward: studentLessonWorkDataItem.slideData.totalMissedAward
        }
    }));
}
