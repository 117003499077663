import * as React from "react";
import {forwardRef} from "react";
import styled, {useTheme} from 'styled-components';
import {BtnStyleEnum, ButtonLoader, ButtonStyleCss, DefaultButtonProps} from './Button';
import {Link as DefaultRouterLink} from "react-router-dom";
import classNames from "classnames";

type ButtonProps = DefaultButtonProps & {
    ref?: React.Ref<HTMLAnchorElement>;
    to: string;
    target?: string;
    className?: string;
    alignCenter?: boolean;
};

const Link: React.FC<ButtonProps> = forwardRef<HTMLAnchorElement, ButtonProps>(
    ({btnStyle, ...props}, ref) => {
        return <DefaultRouterLink {...props}>{props.children}</DefaultRouterLink>;
    }
)

const ButtonStyle = styled(Link)<ButtonProps>`
  ${ButtonStyleCss};
  display: inline-block;
  text-align: center;
`;

const FlexWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &.al-center {
    justify-content: center;
  }
`;

export const LinkAsButton: React.FC<ButtonProps> = forwardRef<HTMLAnchorElement, ButtonProps>((
    {btnStyle, loading, disabled, to, className, icon, alignCenter, target, ...props}, ref
) => {
    const disabledForAnt = (disabled) ? disabled : !!loading;

    const theme = useTheme();

    const content = () => {
        return <>{icon}
            <span style={{visibility: (loading) ? 'hidden' : 'visible'}}>{props.children}</span>
            {
                loading && <ButtonLoader accentColor={
                    (btnStyle === BtnStyleEnum.Primary)
                        ? theme.colors.accentPrimary
                        : theme.colors.textSecondary
                }/>
            }</>;
    }

    return <ButtonStyle to={to} btnStyle={btnStyle} disabled={disabledForAnt} ref={ref} target={target} className={className}>
        {(icon) ? <FlexWrapper className={classNames(alignCenter && 'al-center')}>{content()}</FlexWrapper> : content()}
    </ButtonStyle>;
});
