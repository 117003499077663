import {put, takeEvery} from 'redux-saga/effects';
import {LessonRoomsActionTypes, OpenedLessonRoom} from "../../store/lessonRooms/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {addOpenedLessonRooms} from "../../store/lessonRooms/actions";
import {StreamEventStoreItem} from "../../store/streamEvent/type";
import {DtoSELessonOpened} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSELessonOpened";

/**
 * Обработка сообщения о начале занятия (открытии комнаты урока)
 */
export function* watchHandleSeLessonRoomOpened() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSELessonOpened>, null>>>(
        LessonRoomsActionTypes.HANDLE_SE_LESSON_ROOM_OPENED,
        handleSeLessonRoomOpened
    );
}

function* handleSeLessonRoomOpened(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSELessonOpened>, null>>) {
    const localRoomModel: OpenedLessonRoom = {
        roomId: data.payload.response.dto.payload.lessonRoomId,
        teacherId: data.payload.response.dto.payload.teacherId,
        teacherAvatarFileId: data.payload.response.dto.payload.teacherAvatarFileId,
        teacherName: data.payload.response.dto.payload.teacherName,
    }

    yield put(addOpenedLessonRooms([localRoomModel]));
}
