import * as React from "react";
import {Link} from "./styles";
import {RoutesList} from "../../../RoutesList";
import {Trans} from "@lingui/macro";
import {useLocation} from "react-router-dom";
import styled from "styled-components";

const LinkStyled = styled(Link)`
    margin-bottom: 0;
`;

interface KnowledgeBaseLinkProps {
    onClick: () => void;
}

export const KnowledgeBaseLink: React.FC<KnowledgeBaseLinkProps> = (props) => {
    const location = useLocation();

    return <LinkStyled
        onClick={props.onClick}
        to={RoutesList.TEACHER_KNOWLEDGE_BASE_MAIN}
        $active={location.pathname === RoutesList.TEACHER_KNOWLEDGE_BASE_MAIN}>
        <Trans>Справка и поддержка</Trans>
    </LinkStyled>;
}
