import {DtoTmOrganization} from "../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import React from "react";

export interface ITmCatalogContext {
    selectedOrganization: DtoTmOrganization | null;
    setSelectedOrganization: (newOrganization: DtoTmOrganization) => void;
}

export const TmCatalogContext = React.createContext<ITmCatalogContext>({
    selectedOrganization: null,
    setSelectedOrganization: () => {}
});

export const TmCatalogContextProvider = TmCatalogContext.Provider;