import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {RegularTextCss} from "../../../styles/global-elements";
import {RouterLink} from "../../../components/Ui/Elements/Link";
import {DtoLessonListItem} from "../../../../components/StonlineApiClient/ApiDto/Response/Lesson/DtoLessonListItem";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {DateAsString} from "../../../components/Ui/Elements/TextPresenter/DateAsString";
import {WeekDayNameByDate} from "../../../components/Ui/Elements/TextPresenter/WeekDayNameByDate";
import {WeekDayType} from "../../../components/Ui/Elements/TextPresenter/WeekDayNameByNumber";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    margin-bottom: 30px;
  }
`;

const LessonsListWrapper = styled.div``;

const LessonItemName = styled.div`
  ${RegularTextCss};  
`;

const LessonItemWrapper = styled(RouterLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -5px;
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: ${({theme}) => theme.colors.backgroundPrimary};
  color: ${({theme}) => theme.colors.textPrimary};
  transition: border-left-color 0.3s ease;
  border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
  
  &:hover {
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &:hover {
    border-left-color: ${({theme}) => theme.colors.backgroundInverse};
  }
`;

const LessonItemSubtitle = styled.div`
  ${RegularTextCss};
`;


interface GroupItemProps {
    items: DtoLessonListItem[];
    groupId: number;
    groupTeacherId: number;
}

export const LessonsListGrid: React.FC<GroupItemProps> = ({items, groupId}) => {

    const dateHelper = useMemo<IDateHelperService>(() => {
        return container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
    }, []);

    const linkToLesson = useCallback((lessonId: number): string => {
            return RoutesHelper.replaceParams(
                RoutesList.TEACHER_LESSON_PAGE,
                [
                    {
                        key: 'groupId',
                        value: groupId.toString(10)
                    },
                    {
                        key: 'lessonId',
                        value: lessonId.toString(10)
                    }
                ]
            );
    }, [groupId]);

    return <Wrapper>
        <LessonsListWrapper>
            {
                items.map((lesson) => {
                    let date = dateHelper.dateFromString(lesson.lessonDate);

                    return <LessonItemWrapper key={lesson.id} to={linkToLesson(lesson.id)}>
                        <LessonItemName>
                            <DateAsString date={date}/>&nbsp;

                        </LessonItemName>
                        <LessonItemSubtitle>
                            <WeekDayNameByDate date={date} nameType={WeekDayType.NOMINATIVE} lowerCase={true}/>
                        </LessonItemSubtitle>
                    </LessonItemWrapper>
                })
            }
        </LessonsListWrapper>
    </Wrapper>
}
