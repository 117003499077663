import {ConsoleLogger} from "./ConsoleLogger";

export class ProdConsoleLogger extends ConsoleLogger {
    /**
     * Для прода попробуем преобразовать все объекты к строке
     *
     * @param optionalParams
     * @protected
     */
    protected processOptionalParams(optionalParams: any[]): any[] {
        return optionalParams.map((item): string => {
            return this.handleObject(item);
        });
    }

    /**
     * Обработка объекта для преобразования в строку.
     *
     * @param object
     * @param callNum номер рекурсивного вызова
     *
     * @protected
     */
    protected handleObject(object: any, callNum?: number): string {
        if (object === null) {
            return "NULL";
        }

        const typeOf = typeof object;

        switch (typeOf) {
            case "undefined": {
                return 'undefined';
            }

            case "symbol": {
                return 'symbol, json representation: ' + JSON.stringify(object);
            }

            case "boolean": {
                return (object) ? 'true (boolean)' : 'false (boolean)';
            }

            case "bigint": {
                return (object as Number).toString(10);
            }

            case "number": {
                if (isNaN(object)) {
                    return 'NaN';
                }

                return (object as Number).toString(10);
            }

            case "string": {
                return object as string;
            }

            case "function": {
                return 'function';
            }
        }

        if (typeOf !== "object") {
            return "Unknown typeof: " + JSON.stringify(object);
        }

        // Если у объекта нет поля "Message"
        if (object.message === undefined) {
            return `${this.readObjectNameOrConstructorName(object)}, json: ${JSON.stringify(object)}`;
        }

        // Если у объекта есть поле message
        if (typeof object.message === "string") {
            return `${this.readObjectNameOrConstructorName(object)}, message: ${object.message}`;
        }

        if (callNum !== undefined && callNum > 5) {
            return 'large nesting, json representation: ' + JSON.stringify(object);
        }

        return this.handleObject(object.message, (callNum === undefined) ? 1 : callNum);
    }

    protected readObjectNameOrConstructorName(object: any): string {
        let objectName: string | undefined = undefined;
        let constructorName: string | undefined = undefined;

        if (object.name !== undefined) {
            objectName = object.name;
        }

        if (object.constructor && object.constructor.name) {
            constructorName = object.constructor.name;
        }

        let result = '';

        if (objectName !== undefined) {
            result = result + `Object name is ${objectName}`;
        }

        if (constructorName !== undefined) {
            if (result !== '') {
                result = result + `,  constructor name is ${constructorName}`;
            } else {
                result = result + `Constructor name is ${constructorName}`;
            }
        }

        return (result) ? result : 'Name and constructor name is undefined';
    }
}
