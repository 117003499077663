import React, {useContext} from "react";
import {t, Trans} from "@lingui/macro";
import {PageSubtitleSmallMargin, RegularText} from "../../../../../styles/global-elements";
import styled from "styled-components";
import {CreateAgreementPageContext, ICreateAgreementPageContext} from "../CreateAgreementPageContext";
import {Form, FormItem, useForm} from "../../../../../components/Ui/Elements/Form";
import {MoneyInput} from "../../../../../components/Ui/Elements/MoneyInput";
import {ButtonArea} from "../ButtonArea";
import {IStepWizardContext, StepWizardContext} from "../../../../../components/StepWizard/StepWizardContext";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../../../store/app/selector";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {NoConnection} from "../../../../../../components/StonlineApiClient/Exception/NoConnection";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {cloneDeep} from "lodash";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../../components/StepWizard/StepWizardStatusEnum";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const FormItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Comment = styled(RegularText)`
  color: ${({theme}) => theme.colors.textPrimary};
  margin-bottom: 20px;
`;

const FormItemWrapper = styled.div`
  max-width: 300px;
  margin-bottom: 30px;
`;

enum FormFieldNamesEnum {
    COST = 'cost'
}

export const NewAgreementCostSection: React.FC = () => {
    const [form] = useForm();

    const pageContext = useContext<ICreateAgreementPageContext>(CreateAgreementPageContext);
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const stToken = useSelector(stTokenSelector);

    const onFinish = async (values: any) => {
        const newFormData = cloneDeep(pageContext.formData);

        newFormData.lessonCost = values[FormFieldNamesEnum.COST];

        pageContext.setFormData(newFormData);

        if (stToken === null) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удаётся выполнить операцию`,
                ''
            );

            throw new Error();
        }

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        try {
            const result = await stApiClient.createAgreement(
                stToken,
                pageContext.studentGeneralInfoDto.id,
                newFormData
            );

            pageContext.setFormData((oldData) => {
                return {
                    ...oldData,
                    teamId: result.result.groupId
                }
            });
        } catch (e) {
            if (e instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить операцию`,
                    t`Проверьте подключение к интернету`
                );

                return;
            }

            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось выполнить операцию`,
                ''
            );

            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.error(
                LoggerSectionsEnum.STONLINE_CREATE_AGREEMENT_API,
                'Error on create agreement with params: ',
                newFormData,
                'Error: ',
                e
            );

            throw new Error();
        }

        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.COST,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        );
    }

    return <div>
        <FormWrapper>
            <Form form={form} layout={"inline"} onFinish={onFinish}>
                <FormItemsWrapper>
                    <PageSubtitleSmallMargin>
                        <Trans>Сколько {pageContext.shortStudentName} будет платить за занятие?</Trans>
                    </PageSubtitleSmallMargin>

                    <Comment>
                        <Trans>Стоимость занятия будет автоматически списываться после каждого урока с внутреннего счёта
                            ученика.</Trans>
                    </Comment>

                    <FormItemWrapper>
                        <FormItem
                            name={FormFieldNamesEnum.COST}
                            label={<Trans>Стоимость одного занятия</Trans>}
                            initialValue={pageContext.formData.lessonCost}
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        const numberValue = parseInt(value);

                                        if (numberValue < 1) {
                                            return Promise.reject(new Error(t`Стоимость должна быть больше нуля`));
                                        }

                                        if (numberValue > 100000) {
                                            return Promise.reject(new Error(t`Максимальная сумма 100 000 рублей`));
                                        }

                                        return Promise.resolve()
                                    }
                                })
                            ]}
                        >
                            <MoneyInput
                                placeholder={'0'}
                                style={{textAlign: "right"}}/>
                        </FormItem>
                    </FormItemWrapper>

                    {/*<PageSubtitleSmallMargin>*/}
                    {/*    <Trans>Стартовый баланс</Trans>*/}
                    {/*</PageSubtitleSmallMargin>*/}

                    {/*<Comment>*/}
                    {/*    <Trans>Если ученик уже внёс платёж, можно прямо сейчас отразить это на его внутреннем*/}
                    {/*        счёте.</Trans>*/}
                    {/*</Comment>*/}

                    {/*<FormItemWrapper>*/}
                    {/*    <FormItem*/}
                    {/*        name={FormFieldNamesEnum.START_BALANCE}*/}
                    {/*        initialValue={pageContext.formData.startBalance}*/}
                    {/*        label={<Trans>Стартовый баланс</Trans>}*/}
                    {/*        rules={[*/}
                    {/*            () => ({*/}
                    {/*                validator(_, value) {*/}
                    {/*                    const numberValue = parseInt(value);*/}

                    {/*                    if (numberValue > 100000) {*/}
                    {/*                        return Promise.reject(new Error(t`Максимальная сумма 100 000 рублей`));*/}
                    {/*                    }*/}

                    {/*                    return Promise.resolve()*/}
                    {/*                }*/}
                    {/*            })*/}
                    {/*        ]}*/}
                    {/*    >*/}
                    {/*        <MoneyInput*/}
                    {/*            placeholder={'0'}*/}
                    {/*            style={{textAlign: "right"}}/>*/}
                    {/*    </FormItem>*/}
                    {/*</FormItemWrapper>*/}
                </FormItemsWrapper>
            </Form>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}

            nextBtnOnClick={async () => {
                await form.validateFields();
                await onFinish(form.getFieldsValue());
            }}
        />
    </div>
}