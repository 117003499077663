export enum LengthEnum {
    USER_LOGIN_LENGTH = 255,
    USER_PASSWORD_LENGTH = 255,

    API_AUTH_TOKEN_LENGTH = 50,

    ACTUAL_PARAM_NAME_MAX_LENGTH = 20,

    UUID_LENGTH = 36,

    ST_TOKEN_LENGTH = 50,

    CUSTOMIZATION_CSS_PATH_MAX_LENGTH = 100,

    SCHOOL_PAGE_LOCATION_MAX_LENGTH = 100,

    SCHOOL_NAME_LENGTH = 100,

    USER_NAME_LENGTH = 150,

    BALANCE_VALUE_MIN = -9999999,

    BALANCE_VALUE_MAX = 9999999,

    DEFAULT_DATE_FORMAT_LENGTH = 10,
    DEFAULT_TIME_FORMAT_LENGTH = 8,

    TRAINING_PROGRAM_NAME_LENGTH = 100,

    COURSE_NAME_LENGTH = 100,

    DOC_PREFIX_LENGTH = 10,

    DOC_NUMBER_LENGTH = 4294967295,

    TIMEZONE_NAME_LENGTH = 150
}