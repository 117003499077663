import {IStreamEventRouter} from "./IStreamEventRouter";
import {StreamEventRouteItem} from "./StreamEventRouteItem";
import {StreamEventRoutesList} from "./StreamEventRoutesList";
import {StreamEventTypeEnum} from "./StreamEventTypeEnum";

export class StreamEventRouter implements IStreamEventRouter {
    protected routes: StreamEventRoutesList;

    protected indexedRoutes: { [key: string]: number };

    constructor(routes: StreamEventRoutesList) {
        this.routes = routes;

        this.indexedRoutes = {};

        this.createRoutesIndex();
    }

    /**
     * {@inheritDoc}
     */
    getRouteByType(type: StreamEventTypeEnum): StreamEventRouteItem | null {
        if (type in this.indexedRoutes) {
            return this.routes[this.indexedRoutes[type]];
        }

        return null;
    }

    /**
     * Сформировать индекс роутов.
     */
    protected createRoutesIndex() {
        for (let i = 0; i < this.routes.length; i++) {
            this.indexedRoutes[this.routes[i].streamType] = i;
        }
    }
}
