import {call, putResolve, select, takeEvery} from "redux-saga/effects";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {
    FileFetchInfoProcessStatus,
    FileFormatInfo,
    FileFormatsRepoActionTypes,
    FileFormatsRepoState,
    FileInfo
} from "../../store/fileFormatsRepo/type";
import {repoStateSelector} from "../../store/fileFormatsRepo/selector";
import * as FileFormatsRepoActionCreators from "../../store/fileFormatsRepo/actions";
import {sessionTokenSelector} from "../../store/app/selector";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {BaseResponseDto} from "../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {
    DtoUrlForDownloadResponse
} from "../../components/HttpApiClient/ApiDto/Response/Files/DtoUrlForDownloadResponse";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {NoConnection} from "../../components/HttpApiClient/Exception/NoConnection";
import {FileHelper} from "../../helpers/FileHelper";

/**
 * Сага запускает процесс получения доступных форматов файла.
 */
export function* watchFetchFileInfo() {
    yield takeEvery<WorkerPayloadType<FileInfo>>(
        FileFormatsRepoActionTypes.FETCH_FILE_INFO,
        fetchFileInfo
    );
}

function* fetchFileInfo(data: WorkerPayloadType<FileInfo>) {
    // Проверим - нет ли имеющейся записи о файле
    const repoState = (yield select(repoStateSelector)) as FileFormatsRepoState;
    const currentIndex = repoState.indexByFileId[data.payload.id];

    if (currentIndex !== undefined) {
        let needReloadInfo = false;

        if (repoState.files[currentIndex] === undefined) {
            // Индекс есть, а данных нет. Выполним загрузку.
            needReloadInfo = true;
        } else {
            // Данные есть. Проверяем их состояние
            const currentObject = repoState.files[currentIndex];

            switch (currentObject.fetchInfoStatus) {
                case FileFetchInfoProcessStatus.IN_PROCESS:
                case FileFetchInfoProcessStatus.SUCCESS:
                case FileFetchInfoProcessStatus.WAIT_FOR_START: {
                    // Значит процесс получения уже идёт или успешно завершён.
                    // Не меняем needReloadInfo, чтобы выйти из саги.
                    break;
                }
                default: {
                    // Какой-то иной статус. Вероятно - ошибка. Перезагрузим информацию.
                    needReloadInfo = true;
                }
            }
        }

        if (!needReloadInfo) {
            // Перезагружать информацию не требуется. Выходим из саги.
            return;
        }
    }

    // Устанавливаем статус IN_PROCESS
    yield putResolve(FileFormatsRepoActionCreators.setFileInfo({
        ...data.payload,
        fetchInfoStatus: FileFetchInfoProcessStatus.IN_PROCESS
    }));

    // Запрашиваем информацию
    const sessionToken = (yield select(sessionTokenSelector)) as string | null;
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    if (!sessionToken) {
        yield putResolve(FileFormatsRepoActionCreators.setFileInfo({
            ...data.payload,
            fetchInfoStatus: FileFetchInfoProcessStatus.FAILED
        }));
    }

    const apiClient: IHttpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

    try {
        const fileInfo = (
            yield call(() => apiClient.fileGetUrlForDownload(sessionToken ?? "", data.payload.id))
        ) as BaseResponseDto<DtoUrlForDownloadResponse>;

        const fileAvailableFormats: FileFormatInfo[] = fileInfo.data.links.map((item): FileFormatInfo => {
            return {
                id: item.id,
                formatType: item.formatType,
                url: item.url,
                mimeType: item.mimeType
            }
        });

        yield putResolve(FileFormatsRepoActionCreators.setFileInfo({
            ...data.payload,
            fetchInfoStatus: FileFetchInfoProcessStatus.SUCCESS,
            type: fileInfo.data.fileType,
            availableFormats: fileAvailableFormats
        }));

        if (data.payload.formatForPrefetch !== null) {
            // Нужно какой-то тип этого файла поставить на prefetch.
            const targetFormatUrl = fileAvailableFormats
                .find(item => item.formatType === data.payload.formatForPrefetch);

            if (targetFormatUrl) {
                FileHelper.addForPrefetch(targetFormatUrl.url,data.payload.formatForPrefetch);
            }
        }

    } catch (error) {
        const message = 'Error on load fileInfo: ';

        if (error instanceof NoConnection) {
            logger.info(LoggerSectionsEnum.USER_FILES_API, message, error);
        } else {
            logger.error(LoggerSectionsEnum.USER_FILES_API, message, error);
        }

        yield putResolve(FileFormatsRepoActionCreators.setFileInfo({
            ...data.payload,
            fetchInfoStatus: FileFetchInfoProcessStatus.FAILED
        }));

        return;
    }
}
