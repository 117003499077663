import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes} from "../../../store/lessonRoom/type";
import {WorkerPayloadType} from "../../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../../components/WsApiClient/ResponseActionCreatorPayload";
import {ApplicationState} from "../../../store";
import {StreamEventStoreItem} from "../../../store/streamEvent/type";
import {setMemberScreenOrientation} from "../../../store/lessonRoom/actions";
import {
    DtoSEMemberPublicScreenOrientation
} from "../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoSEMemberPublicScreenOrientation";
import {DeviceOrientatonTypeEnum} from "../../../components/DeviceOrientation/DeviceOrientatonTypeEnum";

export function* watchHandleSeMemberPublicScreenOrientation() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicScreenOrientation>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_PUBLIC_SCREEN_ORIENTATION,
        handleFullScreenState
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleFullScreenState(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoSEMemberPublicScreenOrientation>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    const knownOrientationVariants: string[] = [
        DeviceOrientatonTypeEnum.PORTRAIT_PRIMARY,
        DeviceOrientatonTypeEnum.PORTRAIT_SECONDARY,
        DeviceOrientatonTypeEnum.LANDSCAPE_PRIMARY,
        DeviceOrientatonTypeEnum.LANDSCAPE_SECONDARY,
    ];

    let filteredOrientationTypeValue: DeviceOrientatonTypeEnum | null = null;

    if (
        (data.payload.response.dto.payload.value !== null)
        && (knownOrientationVariants.indexOf(data.payload.response.dto.payload.value) > -1)
    ) {
        filteredOrientationTypeValue = data.payload.response.dto.payload.value as DeviceOrientatonTypeEnum;
    }

    yield put(setMemberScreenOrientation(
        data.payload.response.dto.payload.userId,
        filteredOrientationTypeValue
    ));
}
