import React, {CSSProperties, useContext, useMemo, useState} from 'react';
import {DtoTmSlide} from "../../../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {Sortable, SortableItemComponent} from "../../../../../../components/Sortable/Sortable";
import {t} from "@lingui/macro";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {SlideItem} from "./SlideItem";
import {CreateNewSlideItem} from "./CreateNewSlideItem";
import {EditorContext, IEditorContext, LoadingStateEnum} from "../../EditorContext";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../../components/Ui/Elements/ErrorLoadingContent";

const SortableListWrapper = styled.div`
  .item-label {
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }

  li:hover .item-label,
  .item-label.selected {
    opacity: 1;
  }
`;

export interface SlidesListProps {
    style?: CSSProperties;
}

export type CustomSortableItemComponent = SortableItemComponent<DtoTmSlide> & { requiredIndex: number };

export const SlidesList: React.FC<SlidesListProps | null> = (props) => {
    const editorContext = useContext<IEditorContext>(EditorContext);

    const [savingOrderResultNow, setSavingOrderResultNow] = useState<boolean>(false);

    const sessionToken = useSelector(sessionTokenSelector);

    const {lessonId, slides, setSlides, selectedSlideId, setSelectedSlideId} = {...editorContext};

    const reorderItems = (from: number, to: number) => {
        if ((slides === null) || (sessionToken === null)) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        const firstItemId = slides[from].id;
        const secondItemId = slides[to].id;

        const newLevelsArray = arrayMove(slides, from, to);

        setSlides(newLevelsArray);

        setSavingOrderResultNow(true);

        httpApiClient.tmSwapSlideOrderPosition(sessionToken, firstItemId, secondItemId)
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.TM_SLIDES_API,
                    'Error on reorder slides list: ',
                    err
                );

                setSlides(arrayMove(newLevelsArray, to, from));

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок слайдов. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                setSavingOrderResultNow(false);
            });
    }

    const slideOnClick = (itemId: string) => {
        setSelectedSlideId(itemId);
    }

    const items = useMemo(() => {
        if (slides === null) {
            return [];
        }

        let requiredIndex = 0;

        return slides.map((item): CustomSortableItemComponent => {
            if (!item.isAdditional) {
                ++requiredIndex;
            }

            return {
                id: item.id,
                selected: (selectedSlideId === item.id),
                disabled: savingOrderResultNow,
                item: item,
                requiredIndex: requiredIndex
            }
        });

    }, [slides, selectedSlideId, savingOrderResultNow]);

    const slidesList = () => {
        switch (editorContext.slideListLoadingState) {
            case LoadingStateEnum.NOW_LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingStateEnum.ERROR: {
                return <ErrorLoadingContent
                    title={t`Не удалось загрузить список слайдов`}
                    retryBtnClick={() => editorContext.setNeedReloadSlideList(true)}
                />;
            }
            default: {
                return <div style={props.style}>
                    <CreateNewSlideItem lessonId={lessonId} onItemCreated={() => editorContext.setNeedReloadSlideList(true)}/>
                    <SortableListWrapper>
                        <Sortable<DtoTmSlide>
                            tinyItems={true}
                            items={items}
                            reorderItems={(array, from, to) => {
                                reorderItems(from, to);
                            }}
                            renderItemContent={(item, index) => {
                                return <SlideItem item={(item) as unknown as CustomSortableItemComponent}
                                                  index={index}
                                                  selected={item.id === selectedSlideId}
                                                  onSelect={() => slideOnClick(item.item.id)}/>
                            }}
                        />
                    </SortableListWrapper>
                </div>;
            }
        }
    }

    return slidesList();
}