import React, {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {IElementProps} from "../../IElementProps";
import {IElementRefMethods} from "../../IElementRefMethods";
import {RichTextReader} from "../../components/RichTextReader/RichTextReader";
import {CustomElement} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/CustomElement";
import {DivAsParagraphDefaultStyle} from "../../components/RichTextReader/elements/DivAsParagraph";
import styled from "styled-components";
import {DndContext, DragEndEvent, DragStartEvent, rectIntersection} from "@dnd-kit/core";
import {restrictToWindowEdges} from '@dnd-kit/modifiers';
import {DraggableItem} from "./DraggableItem";
import {DraggableOverlay} from "./DraggableOverlay";
import {
    ExerciseDragDropContextProvider,
    IExerciseDragDropContext,
    InternalExerciseData,
    WordParentList
} from "./ExerciseDragDropContext";
import {ElementFetcher} from "../../../SlidePlayerEditorCommonParts/ElementFetcher";
import {IFillGapsDrapDrop} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IFillGapsDrapDrop";
import {Draggable, WordStyled} from "./Draggable";
import {RegularText} from "../../components/RichTextReader/elements/common-styles";
import {IPlayerContext, PlayerContext} from "../../PlayerContext";
import {ISlideItemWorkContext, SlideItemWorkContext} from "../../SlideItemWorkContext";
import {MAX_AWARD_SCORE} from "../../../../../Constants";
import {SoundsEnum} from "../../../../../components/SoundPlayer/SoundsEnum";
import {ISoundPlayer} from "../../../../../components/SoundPlayer/ISoundPlayer";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ArrayHelpers} from "../../../../../helpers/ArrayHelpers";
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {ExerciseWorkData} from "../../../../../store/slidesWorkData/type";

const WrapperStyled = styled.div``;

const WordSourcePanel = styled.div`
  ${RegularText};
  line-height: 1.7em;

  background: ${({theme}) => theme.colors.backgroundPrimary};
  position: sticky;
  top: ${({theme}) => theme.size.headerHeight.toString() + 'px'};
  z-index: ${({theme}) => theme.zIndices.pageContent};
  padding: 20px 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  border-color: ${({theme}) => theme.colors.headerDivider};
  border-width: 1px;
  border-style: solid none;
  margin: 10px 0;
`;

interface ExerciseFillGapsDragDropProps extends IElementProps<CustomElement[]> {
}

interface ExerciseFillGapsDragDropRefMethods extends IElementRefMethods {
}

export const ExerciseFillGapsDragDrop = forwardRef<ExerciseFillGapsDragDropRefMethods, ExerciseFillGapsDragDropProps>(
    (props, ref) => {

        const [isDragging, setIsDragging] = useState(false);
        const [draggingItem, setDraggingItem] = useState<InternalExerciseData | null>(null);

        const [wordParentList, setWordParentList] = useState<WordParentList>({});

        const [showErrorAnimationInExerciseId, setShowErrorAnimationInExerciseId] = useState<string | null>(null);

        const playerContext = useContext<IPlayerContext>(PlayerContext);
        const slideItemWorkContext = useContext<ISlideItemWorkContext>(SlideItemWorkContext);

        const {elementData} = props;

        // Перемешаем элементы слайда
        const shuffledData = useMemo<IFillGapsDrapDrop[]>(() => {
            return ArrayHelpers.shuffleArray(
                ElementFetcher.fetchElementsByTypesInEditorData<IFillGapsDrapDrop>(
                    ElementTypes.EXERCISE_FILL_GAPS_DRAG_DROP,
                    elementData.data
                )
            );
        }, [elementData.data]);

        // Получим все слова (задания) этого элемента слайда
        const allWords = useMemo<InternalExerciseData[]>(
            () => {
                return shuffledData.map((item) => {
                    return {
                        element: item,
                        parent: (wordParentList[item.id]) ?? null
                    }
                });
            },
            [shuffledData, wordParentList]
        );

        // Зададим из истории работы состояние wordParentList
        useEffect(() => {
            const valueToIdMap = new Map();
            allWords.forEach(item => valueToIdMap.set(item.element.values[0], item.element.id));

            let newWordParentList: WordParentList = {};

            slideItemWorkContext.slideItemWorkData.exercises.forEach((item) => {
                if (!item.value) {
                    return;
                }

                // Если нашли элемент с value
                if (valueToIdMap.has(item.value)) {
                    newWordParentList[valueToIdMap.get(item.value)] = item.exerciseId;
                }
            });

            setWordParentList(newWordParentList);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [slideItemWorkContext.slideItemWorkData.exercises]);

        const wordsWithoutParent = useMemo<string[]>(() => {
            let arr: string[] = [];

            allWords.forEach((item) => {
                if (item.parent === null) {
                    arr.push(item.element.id);
                }
            });

            return arr;
        }, [allWords]);

        const providerData = useMemo<IExerciseDragDropContext>(
            () => {
                return {
                    dragging: isDragging,
                    draggingItem: draggingItem,
                    wordParentList: wordParentList,
                    allWords: allWords,
                    wordsWithoutParent: wordsWithoutParent,
                    showErrorAnimationInExerciseId: showErrorAnimationInExerciseId,
                    setShowErrorAnimationInExerciseId: setShowErrorAnimationInExerciseId
                }
            },
            [allWords, draggingItem, isDragging, showErrorAnimationInExerciseId, wordParentList, wordsWithoutParent]
        );

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({}));

        const onDragStart = useCallback(({active}: DragStartEvent) => {
            setIsDragging(true);
            setDraggingItem(allWords.find(item => item.element.id === active.id) ?? null)
        }, [allWords]);

        const onDragEnd = useCallback(({over, active}: DragEndEvent) => {
            if (over) {
                const newList = {...wordParentList};

                newList[active.id] = over.id;

                setWordParentList(newList);

                const exerciseItem = (allWords.find(item => item.element.id === active.id));

                if (exerciseItem) {
                    selectVariant(over.id as string, exerciseItem);
                }
            } else {
                const newList = {...wordParentList};

                const exerciseId = newList[active.id] as string;

                if (newList[active.id]) {
                    delete newList[active.id];
                }

                setWordParentList(newList);

                if (slideItemWorkContext.slideId && slideItemWorkContext.itemId && exerciseId !== undefined) {
                    slideItemWorkContext.saveExerciseValue(exerciseId, '');
                }
            }

            setIsDragging(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [wordParentList]);

        const onDragCancel = useCallback(() => setIsDragging(false), []);

        const getExerciseHistoryData = useCallback((exerciseId: string) => {
            const slideItemWorkData = slideItemWorkContext.slideItemWorkData;

            const exerciseIndex = slideItemWorkData.exercisesIndexById[exerciseId];

            if (exerciseIndex === undefined) {
                return null;
            }

            return slideItemWorkData.exercises[exerciseIndex];
        }, [slideItemWorkContext]);

        const selectVariant = useCallback((exerciseId: string, selectedVariant: InternalExerciseData) => {
            if (
                (slideItemWorkContext.itemId === null)
                || (slideItemWorkContext.slideId === null)
                || (playerContext.selectedSlide === null)
            ) {
                return;
            }

            const soundPlayer = container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);

            const variant = selectedVariant.element.values[0];

            let award = 0;
            let missedAward = 0;
            let historyItemsCount = 0;

            const currentExerciseHistoryData = getExerciseHistoryData(exerciseId);

            // Рассчитываем значения упущенной выгоды и награды
            if (currentExerciseHistoryData) {
                historyItemsCount = currentExerciseHistoryData.inputHistory.length;
                award = currentExerciseHistoryData.award;
                missedAward = currentExerciseHistoryData.missedAward;
            }

            if (selectedVariant.element.id === exerciseId) {
                // Если сейчас был дан верный ответ - присуждаем все доступные баллы
                award = Math.ceil(
                    MAX_AWARD_SCORE - missedAward
                );
            } else {
                // Если сейчас был дан ошибочный ответ

                // Сколько ещё доступно баллов
                const availableScore = MAX_AWARD_SCORE - missedAward;

                // Посчитаем, сколько ещё ошибочных ответов есть, вычитая из общего
                // количество уже имеющихся в истории и один ответ, который точно верный
                let availableWrongAnswerCount =
                    (
                        // Может получиться так, что пользователь тянет элемент не из панели
                        // свободных слов, а из соседней ячейки. В этом случае нужно прибавить
                        // к возможным вариантам ещё один.
                        (wordsWithoutParent.indexOf(selectedVariant.element.id) > -1)
                            ? wordsWithoutParent.length
                            : wordsWithoutParent.length + 1
                    )
                    - historyItemsCount - 1;

                if (availableWrongAnswerCount < 1) {
                    availableWrongAnswerCount = 1;
                }

                missedAward += Math.ceil(availableScore / availableWrongAnswerCount);
            }

            if (missedAward > MAX_AWARD_SCORE) {
                missedAward = MAX_AWARD_SCORE;
            }

            if (award > MAX_AWARD_SCORE) {
                award = MAX_AWARD_SCORE;
            }

            setTimeout(() => {
                if (
                    (!slideItemWorkContext.slideId)
                    || (!slideItemWorkContext.itemId)
                    || (!playerContext.selectedSlide)
                ) {
                    return;
                }

                const answerIsCorrect = selectedVariant.element.id === exerciseId;

                if (answerIsCorrect) {
                    soundPlayer.playSound(SoundsEnum.RIGHT);
                } else {
                    setShowErrorAnimationInExerciseId(exerciseId);

                    soundPlayer.playSound(SoundsEnum.ERROR);
                }

                // На случай, если пользователь потянул ответ не из панели свободных ответов, а из соседней ячейки.
                // Нужно удалить значение из соседней ячейки.
                slideItemWorkContext.slideItemWorkData.exercises.forEach((item: ExerciseWorkData) => {
                    if ((item.exerciseId !== exerciseId) && (item.value === variant)) {
                        slideItemWorkContext.saveExerciseValue(
                            item.exerciseId,
                            ''
                        );
                    }
                });

                slideItemWorkContext.saveExerciseAnswer(
                    exerciseId,
                    variant,
                    award,
                    missedAward,
                    playerContext.selectedSlide.exercisesCount,
                    answerIsCorrect
                );


            }, 200);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [slideItemWorkContext]);

        // Рендер результата
        if (slideItemWorkContext.showCorrectAnswers === true) {
            // Если нужно показать сразу верный вариант
            return <WrapperStyled>
                <WordSourcePanel>
                    {
                        allWords.map((wordItem) => {
                            return <div key={wordItem.element.id}><WordStyled className={"muted"}>
                                {wordItem.element.values[0]}
                            </WordStyled></div>
                        })
                    }
                </WordSourcePanel>
                <RichTextReader elements={elementData.data} paragraphComponent={DivAsParagraphDefaultStyle}/>
            </WrapperStyled>
        }

        return <WrapperStyled>
            <ExerciseDragDropContextProvider value={providerData}>
                <DndContext collisionDetection={rectIntersection}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            modifiers={[restrictToWindowEdges]}
                            onDragCancel={onDragCancel}
                >
                    <WordSourcePanel>
                        {
                            allWords.map((wordItem) => {
                                if (wordItem.parent === null) {
                                    return <DraggableItem key={wordItem.element.id} element={wordItem.element}/>;
                                } else {
                                    return <Draggable key={wordItem.element.id} element={wordItem.element} ghost/>;
                                }
                            })
                        }
                    </WordSourcePanel>
                    <RichTextReader elements={elementData.data} paragraphComponent={DivAsParagraphDefaultStyle}/>
                    {
                        (draggingItem) && <DraggableOverlay element={draggingItem.element}/>
                    }
                </DndContext>
            </ExerciseDragDropContextProvider>
        </WrapperStyled>;
    }
);

ExerciseFillGapsDragDrop.displayName = 'ExerciseFillGapsDragDrop';