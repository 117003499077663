export enum StreamEventTypeEnum {
    USER_ONLINE_STATE_UPDATE = 9,

    LESSON_ROOM_OPENED = 30,
    LESSON_ROOM_KICKED = 31,
    LESSON_ROOM_CLOSED = 32,
    LESSON_ROOM_PARTICIPANT_LEFT = 33,
    LESSON_ROOM_PARTICIPANT_ENTERED = 34,
    LESSON_ROOM_STUDENT_CURRENT_SLIDE_CHANGED = 37,
    LESSON_ROOM_STUDENT_NEED_OPEN_SLIDE = 38,
    LESSON_ROOM_STUDENT_SLIDE_SUMMARY_UPDATE = 39,
    LESSON_ROOM_NEED_SELECT_ROOM_MEMBER_VIDEO = 41,
    LESSON_ROOM_NEED_SET_MEMBERS_FULL_SCREEN_MODE_VALUE = 42,
    LESSON_ROOM_PUBLIC_STATE_VIDEO_PIN_STATE = 43,
    LESSON_ROOM_PUBLIC_STATE_FULL_SCREEN = 44,
    LESSON_ROOM_PUBLIC_STATE_SCREEN_ORIENTATION = 47,

    LESSON_AND_HOMEWORK_STUDENT_SLIDE_WORK_DATA_UPDATE = 40,

    LESSON_AND_HOMEWORK_NEED_SYNC_SLIDE_LIST = 36
}
