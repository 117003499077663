import {WsConnectionStatusEnum} from "../../components/WsApiClient/WsConnectionStatusEnum";

export enum AppInitializationStateTypes {
    WAIT_FOR_INIT = 'waitForInit',
    IN_PROGRESS = 'inProgress',
    COMPLETED = 'completed',
    FAILED = 'failed'
}

// // Дополнительные состояния запуска, когда на старте мы делаем
// // или не смогли сделать что-то ещё, кроме, непосредственно, запуска
// export enum AppInitializationAdditionalStateTypes {
//     NO_ADDITIONAL_STATE = 'noAdditionalState',
//     OPENING_CONVERSATION = 'openingConversation',
//     OPENING_CONVERSATION_FAILED = 'openingConversationFailed'
// }

export enum AppActionTypes {
    START_APP_INITIALIZATION = '@@app/START_APP_INITIALIZATION',
    SET_APP_INITIALIZATION_STATE = '@@app/SET_APP_INITIALIZATION_STATE',
    PROCESS_STARTUP_GET_PARAMETERS = '@@app/PROCESS_STARTUP_GET_PARAMETERS',

    SEND_DEVICE_INFO = '@@user/SEND_DEVICE_INFO',

    SET_WS_CONNECTION_STATUS = '@@app/SET_WS_CONNECTION_STATUS',
    PROCESS_PING_RESULT = '@@app/PROCESS_PING_RESULT',
    SET_LAST_PING_DATE = '@@app/SET_LAST_PING_DATE',
    SET_PING_TIME_SUSPENDED_VALUE = '@@app/SET_PING_TIME_SUSPENDED_VALUE',

    SET_IN_FATAL_ERROR_STATE = '@@app/SET_IN_FATAL_ERROR_STATE',

    // Action запускает в компоненте WsApiClient имитацию ошибок сервера
    // код ошибки можно передать в actionCreator.
    START_SIMULATE_SERVER_ERRORS = '@@app/START_SIMULATE_SERVER_ERRORS',
    STOP_SIMULATE_SERVER_ERRORS = '@@app/STOP_SIMULATE_SERVER_ERRORS',

    SET_WS_CONNECTION_REQUIRED = '@@app/SET_WS_CONNECTION_REQUIRED',
    SET_INCREMENT_WS_RECONNECT_COUNT = '@@app/SET_INCREMENT_WS_RECONNECT_COUNT',
    SET_WS_DISCONNECT_ALERT_IMMEDIATELY = '@@app/SET_WS_DISCONNECT_ALERT_IMMEDIATELY',
    SET_WS_RECONNECT_COUNT_TO_ZERO = '@@app/SET_WS_RECONNECT_COUNT_TO_ZERO',

    REPORT_LOCATION_POSITION = '@@app/REPORT_LOCATION_POSITION',

    SET_WEB_RTC_SUPPORTED = '@@app/SET_WEB_RTC_SUPPORTED',

    SET_CAMERA_REQUEST_ACCESS_NOW = '@@app/SET_CAMERA_TEST_ACCESS_NOW',
    SET_CAMERA_ALLOWED_IN_SESSION = '@@app/SET_CAMERA_ALLOWED_IN_SESSION',

    SET_ROUTE_LEAVE_CONFIRM_MESSAGE = '@@app/SET_ROUTE_LEAVE_CONFIRM_MESSAGE',

    SET_NEED_INITIAL_CUSTOMIZER = '@@app/SET_NEED_INITIAL_CUSTOMIZER',

    NEW_WINDOW_OPENED = '@@app/NEW_WINDOW_OPENED',
    LOGOUT_IN_ANOTHER_WINDOW = '@@app/LOGOUT_IN_ANOTHER_WINDOW',

    COLLECT_UTM = '@@app/COLLECT_UTM',

    // Action запускает сагу, обновляющую страницу браузера
    REBOOT_APP = '@app/REBOOT_APP'
}

export interface CameraAccess {
    requestAccessNow: boolean,
    allowedInSession: boolean | null
}

export interface AppState {
    appInitializationState: AppInitializationStateTypes;
    appInitializedAt: Date | null;
    wsConnectionStatus: WsConnectionStatusEnum;
    inFatalErrorState: boolean;
    lastPingDate: Date | null;
    pingTimeUpdateSuspended: boolean;
    wsConnectionRequired: boolean;
    wsReconnectNum: number;
    // true, если нужно показывать сообщение об ошибке подключения к ws немедленно,
    // не дожидаясь первой ошибки переподключения
    wsDisconnectAlertImmediately: boolean;
    webRtcSupported: boolean;
    cameraAccess: CameraAccess;
    routeLeaveConfirmMessage: string | null;
    needInitialCustomizer: boolean;
}
