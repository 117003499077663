import {ArrayMaxSize, ArrayMinSize, IsArray, IsNotEmpty, ValidateNested} from "class-validator";
import {Type} from "class-transformer";
import {QueueItem} from "./QueueItem";

export class DtoSaveSlideWorkDataQueueRequest {
    @IsNotEmpty()
    @IsArray()
    @ArrayMinSize(1)
    @ArrayMaxSize(50)
    @ValidateNested({each: true})
    @Type(() => QueueItem)
    items: QueueItem[];
}
