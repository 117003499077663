import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Sortable, SortableItemComponent} from "../../../../../components/Sortable/Sortable";
import {LevelItem} from "./LevelItem";
import {DtoTmLevel} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmLevels/DtoTmLevel";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {t, Trans} from "@lingui/macro";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {CreateNewLevelItem} from "./CreateNewLevelItem";
import {Notice} from "../../../../../components/Ui/Elements/Notice";
import {LoadMoreLevelItem} from "./LoadMoreLevelItem";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";

const LevelsListComponentWrapper = styled.div`
`;

const NoticeStyled = styled(Notice)`
  margin-top: 18px;
`;

const SortableWrapper = styled.div`
  margin-bottom: 10px;
`;

interface LevelsListProps {
    disciplineId: string;
    levels: DtoTmLevel[] | null;
    setLevels: React.Dispatch<React.SetStateAction<DtoTmLevel[] | null>>;
    selectedLevelId: string | null;
    setSelectedLevelId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const LevelsList: React.FC<LevelsListProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [paginationPagesLoaded, setPaginationPagesLoaded] = useState<number>(0);
    const [totalLevelsItems, setTotalLevelsItems] = useState<number>(0);

    const [savingOrderResultNow, setSavingOrderResultNow] = useState<boolean>(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {disciplineId, levels, setLevels, selectedLevelId, setSelectedLevelId} = {...props};

    const loadContent = useCallback((page?: number) => {
        if (!sessionToken) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        if (page === undefined) {
            page = 1;
        }

        httpApiClient.tmGetLevels(sessionToken, disciplineId, page, 20)
            .then((data) => {
                setLevels((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setPaginationPagesLoaded(data.data.page);
                setTotalLevelsItems(data.data.totalCount);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_DISCIPLINE_LEVELS_API, 'Error on loading discipline level list: ', err);

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [sessionToken, httpApiClient, disciplineId, setLevels, logger]);

    const reorderItems = (from: number, to: number) => {
        if ((levels === null) || (sessionToken === null)) {
            return;
        }

        const firstItemId = levels[from].id;
        const secondItemId = levels[to].id;

        const newLevelsArray = arrayMove(levels, from, to);

        setLevels(newLevelsArray);

        setSavingOrderResultNow(true);

        httpApiClient.tmSwapLevelOrderPosition(sessionToken, firstItemId, secondItemId)
            .catch((err) => {
                logger.error(
                    LoggerSectionsEnum.TM_DISCIPLINE_LEVELS_API,
                    'Error on reorder discipline level list: ',
                    err
                );

                setLevels(arrayMove(newLevelsArray, to, from));

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок дисциплин. Попробуйте повторить попытку.`
                );
            })
            .finally(() => {
                setSavingOrderResultNow(false);
            });
    }

    // Сброс уровней при изменении выбранной дисциплины
    useEffect(() => {
        setLevels(() => null);

    }, [disciplineId, setLevels]);

    // Загрузка дисциплин при сбросе списка дисциплин
    useEffect(() => {
        if (levels === null) {
            loadContent(1);
        }
    }, [levels, loadContent]);

    const levelOnClick = (itemId: string) => {
        setSelectedLevelId(itemId);
    }

    const items = useMemo(() => {
        if (levels === null) {
            return [];
        }

        return levels?.map((item): SortableItemComponent<DtoTmLevel> => {
            return {
                id: item.id,
                selected: (selectedLevelId === item.id),
                disabled: savingOrderResultNow,
                item: item
            }
        });

    }, [levels, selectedLevelId, savingOrderResultNow]);

    const levelsList = () => {
        if (nowLoading) {
            return <DefaultLoader/>;
        }

        return <LevelsListComponentWrapper>
            <SortableWrapper>
                <Sortable<DtoTmLevel>
                    items={items}
                    reorderItems={(array, from, to) => {
                        reorderItems(from, to);
                    }}
                    renderItemContent={(item, index) => {
                        return <LevelItem item={item}
                                          index={index}
                                          onSelect={() => levelOnClick(item.item.id)}/>
                    }}
                />
            </SortableWrapper>

            {((props.levels !== null) && (totalLevelsItems > props.levels.length)) ?
                <LoadMoreLevelItem onClick={() => loadContent(paginationPagesLoaded + 1)}/> : null}

            <CreateNewLevelItem disciplineId={props.disciplineId}
                                onItemCreated={() => props.setLevels(null)}/>

            {(((props.levels?.length ?? 0) > 1)) ? <NoticeStyled>
                <Trans>Порядок уровней имеет значение. Сверху нужно оставить начальный уровень, а внизу -
                    продвинутый.</Trans>
            </NoticeStyled> : null}

        </LevelsListComponentWrapper>
    }

    return (
        <div>
            {
                (loadingError)
                    ? <ErrorLoadingContent
                        title={t`Не удалось загрузить список уровней`}
                        retryBtnClick={() => loadContent()}
                    />
                    : levelsList()
            }

        </div>
    );
}