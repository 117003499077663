import React, {useCallback, useContext, useEffect, useRef} from "react";
import {AudioRoomActionsContextProvider} from "./AudioRoomActionsContext";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../contexts/CommonContext";
import {IJanusAudioRoomPlugin} from "../../../../../components/JanusClient/AudioBridge/IJanusAudioRoomPlugin";
import {JanusAudioRoomPlugin} from "../../../../../components/JanusClient/AudioBridge/JanusAudioRoomPlugin";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IMediaDeviceService} from "../../../../../services/media-device/IMediaDeviceService";
import {IJanusConnection} from "../../../../../components/JanusClient/IJanusConnection";
import {IDeviceMediaStreamFetcher} from "../../../../../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";
import {useDispatch, useSelector} from "react-redux";
import {currentUserIdSelector} from "../../../../../store/user/selector";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import * as appActionCreators from "../../../../../store/app/actions";
import {AudioRoomConnectionState, ConnectionStateEnum, StreamStatusEnum} from "../../Types";
import {cloneDeep} from "lodash";
import {AudioRoomContext, IAudioRoomContext} from "../../contexts/AudioRoomContext";
import {DeviceInfo} from "../../../../../services/media-device/Types";
import {NotificationTypesEnum, openNotification} from "../../../Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {
    AudioRoomStudentListActionsContext,
    IAudioRoomStudentListActionsContext
} from "./AudioRoomStudentListActionsContext";
import {IRoomCommonActionsContext, RoomCommonActionsContext} from "../common/RoomCommonActionsContext";
import {AudioRoomParticipantItem} from "../../../../../components/JanusClient/AudioBridge/Types";

interface AudioRoomActionsProviderProps {
    setAudioRoomConnectionState: React.Dispatch<React.SetStateAction<AudioRoomConnectionState>>;
}

export const AudioRoomActionsProvider: React.FC<AudioRoomActionsProviderProps> = (
    {children, setAudioRoomConnectionState}
) => {
    const dispatch = useDispatch();
    const currentUserId = useSelector(currentUserIdSelector);

    const commonContext = useContext<ICommonContext>(CommonContext);
    const roomCommonActionsContext = useContext<IRoomCommonActionsContext>(RoomCommonActionsContext);
    const audioRoomContext = useContext<IAudioRoomContext>(AudioRoomContext);
    const audioRoomStudentListActions = useContext<IAudioRoomStudentListActionsContext>(AudioRoomStudentListActionsContext);
    const audioRoomPlugin = useRef<IJanusAudioRoomPlugin | null>(null);

    const audioElement = useRef<HTMLAudioElement>(null);

    const updateMuteStateValue = useCallback((newValue: boolean) => {
        setAudioRoomConnectionState((state) => {
            const result = cloneDeep(state);

            result.myMicMuted = newValue;

            return result;
        });
    }, [setAudioRoomConnectionState]);

    const muteMyAudio = useCallback(async () => {
        audioRoomPlugin.current?.setMuteOwnAudio(true);
        updateMuteStateValue(true);

        if (currentUserId) {
            roomCommonActionsContext.setParticipantsListItemUpdate([{
                id: currentUserId,
                audioMuted: true
            }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId, updateMuteStateValue]);

    const unmuteMyAudio = useCallback(async () => {
        audioRoomPlugin.current?.setMuteOwnAudio(false);
        updateMuteStateValue(false);

        if (currentUserId) {
            roomCommonActionsContext.setParticipantsListItemUpdate([{
                id: currentUserId,
                audioMuted: false
            }]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserId, updateMuteStateValue]);

    const audioElementOnCanPlay = useCallback(() => {
        audioElement.current?.play();
    }, []);

    const connectToRoom = useCallback(async () => {
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        const mutedState = audioRoomContext.audioRoomConnectionState.myMicMuted;

        audioRoomPlugin.current = new JanusAudioRoomPlugin(
            logger,
            container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE),
            container.resolve<IDeviceMediaStreamFetcher>(DiTokens.DEVICE_MEDIA_STREAM_FETCHER),
            container.resolve<IJanusConnection>(DiTokens.JANUS_CONNECTION),
        );

        if ((commonContext.conferenceConnectionParams === null) || (currentUserId === null)) {
            logger.error(
                LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                `Empty conferenceConnectionParams`
            );

            dispatch(appActionCreators.setInFatalErrorState(true));

            return;
        }

        const emitConnectionError = () => {
            setAudioRoomConnectionState((state) => {
                const result = cloneDeep(state);

                result.connection.status = ConnectionStateEnum.CONNECTION_ERROR;
                result.streamIsReady = false;

                return result;
            });

            roomCommonActionsContext.setUnavailableAudioForAllParticipants();
        };

        audioRoomPlugin.current.joinToAudioRoom(
            {
                userId: currentUserId,
                roomId: commonContext.conferenceConnectionParams.janusRoomId,
                roomJoinKey: commonContext.conferenceConnectionParams.janusJoinKey,
                startMuted: mutedState,
                callbacks: {
                    roomClosed: () => {
                        setAudioRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            result.connection.status = ConnectionStateEnum.CLOSED;
                            result.streamIsReady = false;

                            return result;
                        });

                        roomCommonActionsContext.setUnavailableAudioForAllParticipants();
                    },
                    joinedSuccessfully: () => {
                        setAudioRoomConnectionState(() => {
                            return {
                                connection: {
                                    status: ConnectionStateEnum.CONNECTED,
                                    badConnectionSignal: null,
                                    totalLossesPacked: 0
                                },
                                myMicMuted: mutedState,
                                streamIsReady: false
                            }
                        });

                        roomCommonActionsContext.setParticipantsListItemUpdate([{
                            id: currentUserId,
                            audioStatus: StreamStatusEnum.CONNECTING,
                            audioMuted: mutedState
                        }]);
                    },
                    iceDisconnected: () => {
                        emitConnectionError();
                    },
                    error: (error: any) => {
                        emitConnectionError();
                    },
                    onMicGoneAway: (oldMic: DeviceInfo | null, fatal: boolean) => {
                        if (!fatal) {
                            openNotification(
                                NotificationTypesEnum.INFO,
                                t`Микрофон отключился`,
                                (oldMic)
                                    ? t`Микрофон ${oldMic.name} отключился, подключаем другой`
                                    : ''
                            );
                        } else {
                            openNotification(
                                NotificationTypesEnum.ERROR,
                                t`Микрофон отключился`,
                                (oldMic)
                                    ? t`Микрофон ${oldMic.name} больше не работает`
                                    : ''
                            );

                            setAudioRoomConnectionState((state) => {
                                const result = cloneDeep(state);

                                result.connection.status = ConnectionStateEnum.CONNECTION_ERROR;
                                result.streamIsReady = false;

                                return result;
                            });

                            roomCommonActionsContext.setParticipantsListItemUpdate([{
                                id: currentUserId,
                                audioStatus: StreamStatusEnum.NOT_AVAILABLE
                            }]);
                        }

                    },
                    trackStateChange: (ready: boolean) => {
                        setAudioRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            result.streamIsReady = ready;

                            return result;
                        });

                        roomCommonActionsContext.setParticipantsListItemUpdate([{
                            id: currentUserId,
                            audioStatus: (ready) ? StreamStatusEnum.CONNECTED : StreamStatusEnum.NOT_AVAILABLE
                        }]);
                    },
                    handleJoinedParticipants: (participants: AudioRoomParticipantItem[]) => {
                        audioRoomStudentListActions.setJoinedAudioStreams(participants);
                    },
                    handleDisconnectedParticipants: (userIds: string[]) => {
                        audioRoomStudentListActions.setLeaveAudioStreams(userIds);
                    },
                    setParticipantTalkingValue: (userId: string, talking: boolean) => {
                        roomCommonActionsContext.setParticipantsListItemUpdate([{
                            id: userId,
                            talkingNow: talking
                        }]);
                    },
                    setParticipantMutedValue: (userId: string, muted: boolean) => {
                        roomCommonActionsContext.setParticipantsListItemUpdate([{
                            id: userId,
                            audioMuted: muted
                        }]);
                    },
                    slowLinkMessage: (lostPacketsCount: number) => {
                        setAudioRoomConnectionState((state) => {
                            const result = cloneDeep(state);

                            if (result.connection.badConnectionSignal === null) {
                                result.connection.badConnectionSignal = new Date();
                            }

                            result.connection.totalLossesPacked = lostPacketsCount;

                            return result;
                        });
                    },
                    onMicChanged: (newMicInfo: DeviceInfo) => {
                        openNotification(
                            NotificationTypesEnum.INFO,
                            t`Микрофон изменён`,
                            `Сейчас используется ${newMicInfo.name}`
                        );
                    },
                    onCleanup: () => {
                        roomCommonActionsContext.setUnavailableAudioForAllParticipants();
                    }
                }
            }
        );
    }, [
        audioRoomContext.audioRoomConnectionState.myMicMuted,
        audioRoomStudentListActions,
        commonContext.conferenceConnectionParams,
        currentUserId,
        dispatch,
        roomCommonActionsContext,
        setAudioRoomConnectionState
    ]);

    useEffect(() => {
        if (commonContext.lessonConferenceState === LessonConferenceStatusEnum.STARTED) {
            connectToRoom();
        }

        if (
            (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CONNECTION_ERROR)
            || (commonContext.lessonConferenceState === LessonConferenceStatusEnum.CONNECTION_ERROR_WAIT_NETWORK)
            || (commonContext.lessonConferenceState === LessonConferenceStatusEnum.ON_DESTROY)
        ) {
            if (audioRoomPlugin.current !== null) {
                audioRoomPlugin.current?.destroy();

                audioRoomPlugin.current = null;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonContext.lessonConferenceState]);

    useEffect(() => {
        if (audioRoomContext.audioRoomConnectionState.streamIsReady) {
            if ((!audioRoomPlugin.current) || (!audioElement.current)) {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.JANUS_AUDIOROOM_PLUGIN,
                    `Stream is ready, but audioRoomPlugin or audioElement is empty.`
                );

                dispatch(appActionCreators.setInFatalErrorState(true));

                return;
            }

            audioRoomPlugin.current.attachAudioToElement(audioElement.current);
        }
    }, [audioRoomContext.audioRoomConnectionState.streamIsReady, dispatch]);

    // Размонтирование
    useEffect(() => {
        return () => {
            if (audioRoomPlugin.current !== null) {
                audioRoomPlugin.current?.destroy();

                audioRoomPlugin.current = null;
            }
        }
    }, []);

    return <AudioRoomActionsContextProvider value={{
        muteMyAudio: muteMyAudio,
        unmuteMyAudio: unmuteMyAudio
    }}>
        <audio autoPlay={true} ref={audioElement} onCanPlay={audioElementOnCanPlay}/>
        {children}
    </AudioRoomActionsContextProvider>
}
