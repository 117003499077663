import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {selectedAgreement, selectedUserInSchool} from "../../../store/user/selector";
import {Modal} from "../Ui/Elements/Modal";
import {sessionTokenSelector} from "../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {ILogger} from "../../../components/Logger/ILogger";
import {PageTitle, RegularTextCss} from "../../styles/global-elements";
import {DefaultLoader} from "../DefaultLoader";
import {t, Trans} from "@lingui/macro";
import {ErrorLoadingContent} from "../Ui/Elements/ErrorLoadingContent";
import {BtnStyleEnum, Button} from "../Ui/Elements/Button";
import {PopupActions} from "reactjs-popup/dist/types";
import {
    DtoOnlinePaymentOrderStateResponse
} from "../../../components/HttpApiClient/ApiDto/Response/OnlinePayments/DtoOnlinePaymentOrderStateResponse";
import {OnlinePaymentOrderStateEnum} from "../../../enums/OnlinePaymentOrderStateEnum";
import styled from "styled-components";

import OrderInProcessIcon from "./img/OrderInProcess.png";
import OrderNotFoundIcon from "./img/OrderNotFound.png";
import OrderRejectedIcon from "./img/OrderRejected.png";
import OrderReturnedIcon from "./img/OrderReturned.png";
import OrderSuccessIcon from "./img/OrderSuccess.png";

import {ReactComponent as PaymentOrderCommentSvg} from "./icons/PaymentOrderComment.svg";
import {ReactComponent as PaymentOrderDateSvg} from "./icons/PaymentOrderDate.svg";
import {ReactComponent as PaymentOrderSumSvg} from "./icons/PaymentOrderSum.svg";
import {ReactComponent as PaymentOrderIdSvg} from "./icons/PaymentOrderId.svg";
import {IDateHelperService} from "../../../services/date-helper/IDateHelperService";
import {i18n} from "@lingui/core";

const ImageWrapper = styled.div`
  margin-bottom: 64px;
  text-align: center;

  @media (${({theme}) => theme.media.small}) {
    text-align: left;
  }
`;

const Image = styled.img`
  width: 280px;
  height: 180px;
`;

const PageTitleStyled = styled(PageTitle)`
  margin-bottom: 4px;

  @media (${({theme}) => theme.media.large}) {
    margin-bottom: 12px;
  }
`;

const PageSubtitleStyled = styled.div`
  ${RegularTextCss};
  color: ${({theme}) => theme.colors.textSecondary};
  margin-bottom: 40px;
`;

const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const ContentItemIconWrapper = styled.div`
  max-width: 18px;
  max-height: 18px;
  padding-top: 4px;

  color: ${({theme}) => theme.colors.textTertiary};
  margin-right: 12px;

  @media (${({theme}) => theme.media.medium}) {
    padding-top: 5px;
  }
`;

const ContentItemTextWrapper = styled.div`
  ${RegularTextCss};
  flex-grow: 1;
`;

interface PayOnlinePopupProps {
    orderId: string;
}

enum LoadingStateEnum {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export const PayOnlineResultPopup = forwardRef<PopupActions, PayOnlinePopupProps>(
    ({orderId}, ref) => {
        const sessionToken = useSelector(sessionTokenSelector);
        const userInSchool = useSelector(selectedUserInSchool);
        const agreement = useSelector(selectedAgreement);

        const [loadingState, setLoadingState] = useState<LoadingStateEnum>(LoadingStateEnum.NOT_INIT);
        const [abortController, setAbortController] = useState<AbortController | null>(null);
        const [orderInfo, setOrderInfo] = useState<DtoOnlinePaymentOrderStateResponse | null>(null);

        const closeAllowed = useMemo<boolean>(() => {
            return (loadingState === LoadingStateEnum.SUCCESS) || (loadingState === LoadingStateEnum.ERROR);
        }, [loadingState]);

        const fetchDocuments = useCallback(() => {
            if ((sessionToken === null) || (userInSchool === null) || (agreement === null)) {
                setLoadingState(LoadingStateEnum.ERROR);

                return;
            }

            if (abortController !== null) {
                abortController.abort();
            }

            setLoadingState(LoadingStateEnum.LOADING);
            const newAbortController = new AbortController();
            setAbortController(newAbortController);

            const httpClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            httpClient.onlinePaymentGetOrderState(
                sessionToken,
                orderId,
                newAbortController
            )
                .then((data) => {
                    setOrderInfo(data.data)
                    setLoadingState(LoadingStateEnum.SUCCESS);
                })
                .catch((error) => {
                    logger.error(LoggerSectionsEnum.PAYMENT_MODAL, 'Error on load payment order info ', error);

                    setLoadingState(LoadingStateEnum.ERROR);
                })

        }, [abortController, agreement, orderId, sessionToken, userInSchool]);

        const onOpen = useCallback(() => {
            fetchDocuments();
        }, [fetchDocuments]);

        const onClose = useCallback(() => {
            if (abortController !== null) {
                abortController.abort();
                setAbortController(null);
            }
        }, [abortController]);

        useEffect(() => {
            return () => {
                if (abortController !== null) {
                    abortController.abort();
                    setAbortController(null);
                }
            }
        }, [abortController]);

        const showTitle = useMemo<boolean>(() => {
            return (loadingState !== LoadingStateEnum.SUCCESS);
        }, [loadingState]);

        const statusImage = useMemo<JSX.Element>(() => {
            if (!orderInfo) {
                return <ImageWrapper>
                    <Image src={OrderNotFoundIcon} alt={t`Не найден`}/>
                </ImageWrapper>
            }

            switch (orderInfo.status) {
                case OnlinePaymentOrderStateEnum.NEW_ORDER: {
                    return <ImageWrapper>
                        <Image src={OrderInProcessIcon} alt={t`Новый платёж, ожидает обработки`}/>
                    </ImageWrapper>
                }
                case OnlinePaymentOrderStateEnum.SUCCESSFUL_PAID: {
                    return <ImageWrapper>
                        <Image src={OrderSuccessIcon} alt={t`Успешно проведён`}/>
                    </ImageWrapper>
                }
                case OnlinePaymentOrderStateEnum.FAILED: {
                    return <ImageWrapper>
                        <Image src={OrderRejectedIcon} alt={t`Не выполнен`}/>
                    </ImageWrapper>
                }
                case OnlinePaymentOrderStateEnum.RETURNED: {
                    return <ImageWrapper>
                        <Image src={OrderReturnedIcon} alt={t`Возвращён`}/>
                    </ImageWrapper>
                }
                case OnlinePaymentOrderStateEnum.NOT_FOUND: {
                    return <ImageWrapper>
                        <Image src={OrderNotFoundIcon} alt={t`Платёж не найден`}/>
                    </ImageWrapper>
                }
                case OnlinePaymentOrderStateEnum.UNKNOWN:
                default: {
                    return <ImageWrapper>
                        <Image src={OrderNotFoundIcon} alt={t`Неизвестное состояние`}/>
                    </ImageWrapper>
                }
            }
        }, [orderInfo]);

        const statusTitle = useMemo<JSX.Element>(() => {
            if (!orderInfo) {
                return <Trans>Платёж не найден</Trans>;
            }

            switch (orderInfo.status) {
                case OnlinePaymentOrderStateEnum.NEW_ORDER: {
                    return <Trans>Платёж в обработке</Trans>;
                }
                case OnlinePaymentOrderStateEnum.SUCCESSFUL_PAID: {
                    return <Trans>Успех</Trans>
                }
                case OnlinePaymentOrderStateEnum.FAILED: {
                    return <Trans>Платёж не выполнен</Trans>
                }
                case OnlinePaymentOrderStateEnum.RETURNED: {
                    return <Trans>Платёж возвращён</Trans>
                }
                case OnlinePaymentOrderStateEnum.NOT_FOUND: {
                    return <Trans>Платёж не найден</Trans>;
                }
                case OnlinePaymentOrderStateEnum.UNKNOWN:
                default: {
                    return <Trans>Тут такое дело...</Trans>;
                }
            }
        }, [orderInfo]);

        const statusSubtitle = useMemo<JSX.Element>(() => {
            if (!orderInfo) {
                return <Trans>Можете обратиться с преподавателю с вопросом об оплате.</Trans>;
            }

            switch (orderInfo.status) {
                case OnlinePaymentOrderStateEnum.NEW_ORDER: {
                    return <Trans>Платёж ещё не поступил в обработку, но находится в очереди.</Trans>;
                }
                case OnlinePaymentOrderStateEnum.SUCCESSFUL_PAID: {
                    return <Trans>Платёж успешно принят.</Trans>;
                }
                case OnlinePaymentOrderStateEnum.FAILED: {
                    return <Trans>Банк отклонил или отменил платёж. Можете обратиться с преподавателю с вопросом об
                        оплате.</Trans>
                }
                case OnlinePaymentOrderStateEnum.RETURNED: {
                    return <Trans>Выполнен возврат средств плательщику</Trans>
                }
                case OnlinePaymentOrderStateEnum.NOT_FOUND: {
                    return <Trans>Можете обратиться с преподавателю с вопросом об оплате.</Trans>;
                }
                case OnlinePaymentOrderStateEnum.UNKNOWN:
                default: {
                    return <Trans>Не удалось определить состояние платежа. Попробуйте обратиться с преподавателю с
                        вопросом об оплате.</Trans>;
                }
            }
        }, [orderInfo]);


        const content = useMemo<JSX.Element>(() => {
            switch (loadingState) {
                case LoadingStateEnum.NOT_INIT:
                case LoadingStateEnum.LOADING: {
                    return <DefaultLoader/>
                }
                case LoadingStateEnum.ERROR: {
                    return <ErrorLoadingContent retryBtnClick={fetchDocuments}/>
                }
                case LoadingStateEnum.SUCCESS: {
                    const dateHelper = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);

                    if (!orderInfo) {
                        return <div></div>;
                    }

                    return <div>
                        {statusImage}
                        <PageTitleStyled>{statusTitle}</PageTitleStyled>
                        <PageSubtitleStyled>{statusSubtitle}</PageSubtitleStyled>
                        <ContentItem>
                            <ContentItemIconWrapper>
                                <PaymentOrderCommentSvg/>
                            </ContentItemIconWrapper>
                            <ContentItemTextWrapper>
                                {orderInfo.description}
                            </ContentItemTextWrapper>
                        </ContentItem>

                        <ContentItem>
                            <ContentItemIconWrapper>
                                <PaymentOrderDateSvg/>
                            </ContentItemIconWrapper>
                            <ContentItemTextWrapper>
                                {dateHelper.formatAsDate(dateHelper.dateFromString(orderInfo.createdAt))}
                            </ContentItemTextWrapper>
                        </ContentItem>

                        <ContentItem>
                            <ContentItemIconWrapper>
                                <PaymentOrderSumSvg/>
                            </ContentItemIconWrapper>
                            <ContentItemTextWrapper>
                                {i18n.number(orderInfo.sum, {style: "currency", currency: "RUB"})}
                            </ContentItemTextWrapper>
                        </ContentItem>

                        <br/>

                        <ContentItem>
                            <ContentItemIconWrapper>
                                <PaymentOrderIdSvg/>
                            </ContentItemIconWrapper>
                            <ContentItemTextWrapper>
                                ID: {orderInfo.id}
                            </ContentItemTextWrapper>
                        </ContentItem>
                    </div>
                }
                default: {
                    throw new Error('Unknown loading state');
                }
            }
        }, [loadingState, fetchDocuments, orderInfo, statusImage, statusTitle, statusSubtitle]);

        return <Modal onOpen={onOpen} onClose={onClose} closeAllowed={closeAllowed} innerRef={ref}
                      footer={
                          (controls) => {
                              return <div>
                                  <Button btnStyle={BtnStyleEnum.Primary}
                                          disabled={!closeAllowed}
                                          onClick={controls.closeModal}
                                  >
                                      <Trans>Закрыть</Trans>
                                  </Button>
                              </div>
                          }
                      }
                      children={(controls) => {
                          return <div>
                              {
                                  (showTitle)
                                  && <PageTitle><Trans>Состояние платежа</Trans></PageTitle>
                              }
                              {content}
                          </div>
                      }}
        />
    })
