import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    DtoSelfStudyTrackForEditResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackForEditResponse";
import styled from "styled-components";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../components/Ui/Elements/NoticeBlock";
import {Form, FormItem, useForm} from "../../../../components/Ui/Elements/Form";
import {EditorData, EditorItemDataParams} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {SlideEditor, SlideEditorRefMethods} from "../../../../components/SlideEditor";
import {t, Trans} from "@lingui/macro";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import {ElementTypeEnum} from "../../../../components/SlidePlayerEditorCommonParts/ElementTypeEnum";
import {v4 as uuidv4} from "uuid";
import {
    ButtonsWrapper,
    FormItemsWrapper,
    FormWrapper,
    PageSubtitle2,
    RegularText
} from "../../../../styles/global-elements";
import {Input} from "../../../../components/Ui/Elements/TextInput";
import {SubjectAreaSelector} from "../../../../components/Ui/Elements/Selectors/SubjectAreaSelector";
import {StTeacherSelector} from "../../../../components/Ui/Elements/Selectors/StTeacherSelector";
import {TmSectionSelector} from "../../../../components/Ui/Elements/Selectors/TmSectionSelector";
import {LandingEditorAreaPlaceholder} from "./LandingEditorAreaPlaceholder";
import {BottomPaletteButton} from "../../../../components/SlideEditor/bottom-palette-button";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {
    StTeacherSelectorItem
} from "../../../../components/Ui/Elements/Selectors/StTeacherSelector/StTeacherSelectModalWindow";
import {EditorDataDtoMapper} from "../../../../components/SlidePlayerEditorCommonParts/EditorDataDtoMapper";
import {DtoTutorProfile} from "../../../../../components/HttpApiClient/ApiDto/Response/User/DtoTutorProfile";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {RoutesList} from "../../../../RoutesList";
import {ConfirmDialog} from "../../../../components/Ui/Elements/ConfirmDialog";
import {LargeItem} from "../../../../components/Ui/Elements/SelfStudyTrackCover/FullCover/LargeItem";
import {allMasks, SelfStudyTrackCoverMaskTypeEnum} from "../../../../../enums/SelfStudyTrackCoverMaskTypeEnum";
import {
    ImageCropperUploader,
    ImageCropperUploaderRefMethods
} from "../../../../components/Ui/Elements/ImageCropperUploader";
import {UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";

const ValidationSummary = styled(NoticeBlock)`
  width: 100%;
`;

const ValidationSummaryList = styled.ul`
  margin-bottom: 0;
`;

const SlideEditorWrapper = styled.div`
  padding-top: 10px;
  margin-bottom: 70px;
`;

const CoverExampleWrapper = styled.div`
  margin: auto;
`;

const CoverButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (${({theme}) => theme.media.medium}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

enum FormFieldNamesEnum {
    SUBJECT_AREA = 'subjectArea',
    NAME = 'name',
    SHORT_DESCRIPTION = 'shortDescription',
    INTERNAL_DESCRIPTION = 'internalDescription',
    TUTOR = 'tutor',
    TM_SECTION = 'tmSection',
    COVER_MASK_ID = 'coverMaskId',
    ACCENT_COLOR_ID = 'accentColorId'
}

interface SelfStudyTrackFormProps {
    initialData: Partial<DtoSelfStudyTrackForEditResponse>;
    saveInProcess: boolean;
    onSave: (newData: DtoSelfStudyTrackForEditResponse) => void;
    onDeleteTrack: () => Promise<void>;
}

export const SelfStudyTrackForm: React.FC<SelfStudyTrackFormProps> = (
    {
        initialData,
        saveInProcess,
        onSave,
        onDeleteTrack
    }
) => {

    const [form] = useForm();

    const [landingContent, setLandingContent] = useState<EditorData | null>(null);

    const slideEditorRef = useRef<SlideEditorRefMethods>(null);
    const cropperRef = useRef<ImageCropperUploaderRefMethods>(null);

    const [validationErrorsList, setValidationErrorsList] = useState<string[]>([]);

    // Для отображения preview
    const [nameForCover, setNameForCover] = useState<string>(() => initialData.name??'');
    const [shortDescriptionForCover, setShortDescriptionForCover] = useState<string>(() => initialData.shortDescription??'');

    const [coverFileId, setCoverFileId] = useState<string | null | undefined>(() => {
        return initialData.coverFileId;
    });

    const [currentMaskType, setCurrentMaskType] = useState<SelfStudyTrackCoverMaskTypeEnum>(() => {
        return initialData.coverMaskId
            ? initialData.coverMaskId as SelfStudyTrackCoverMaskTypeEnum
            : SelfStudyTrackCoverMaskTypeEnum.SQUARE;
    });

    const [currentAccentColor, setCurrentAccentColor] = useState<string>(() => {
        return initialData.accentColor ?? '#1979FF';
    });

    useEffect(() => {
        if (slideEditorRef.current !== null && initialData.landingDescription) {
            const editorData = EditorDataDtoMapper.dtoToEditorData(initialData.landingDescription);

            slideEditorRef.current.reloadInitialData(editorData);
            setLandingContent(editorData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFinish = async (values: any) => {
        if (landingContent === null) {
            setValidationErrorsList([
                t`Добавьте контент для лендинга`
            ]);

            return;
        }

        if (!coverFileId) {
            setValidationErrorsList([
                t`Добавьте изображение - обложку для трека`
            ]);

            return;
        }

        const dto = new DtoSelfStudyTrackForEditResponse();

        dto.id = initialData.id ?? '';
        dto.name = form.getFieldValue(FormFieldNamesEnum.NAME);
        dto.shortDescription = form.getFieldValue(FormFieldNamesEnum.SHORT_DESCRIPTION);
        dto.landingDescription = EditorDataDtoMapper.editorDataToDto(landingContent);
        dto.internalDescription = form.getFieldValue(FormFieldNamesEnum.INTERNAL_DESCRIPTION);
        dto.availableForAll = false;
        dto.subjectAreaLevel = form.getFieldValue(FormFieldNamesEnum.SUBJECT_AREA);
        dto.coverFileId = coverFileId;
        dto.coverMaskId = currentMaskType;
        dto.accentColor = currentAccentColor;

        const tutorDto = new DtoTutorProfile();
        tutorDto.id = '';
        tutorDto.stApiId = form.getFieldValue(FormFieldNamesEnum.TUTOR).id;
        tutorDto.fio = form.getFieldValue(FormFieldNamesEnum.TUTOR).longName;
        tutorDto.position = '';
        tutorDto.avatarFileId = null;

        dto.tutor = tutorDto;

        dto.tmSection = form.getFieldValue(FormFieldNamesEnum.TM_SECTION);
        dto.createdAt = '';

        onSave(dto);
    }

    const onFormValuesChange = useCallback(() => {
        if (validationErrorsList.length > 0) {
            setValidationErrorsList([]);
        }

        setNameForCover(form.getFieldValue(FormFieldNamesEnum.NAME));
        setShortDescriptionForCover(form.getFieldValue(FormFieldNamesEnum.SHORT_DESCRIPTION));
    }, [form, validationErrorsList.length]);

    const landingContentOnChange = useCallback((content: EditorData) => {
        setLandingContent(content);
    }, []);

    const onFormFinishFailed = ({values, errorFields, outOfDate}: ValidateErrorEntity) => {
        const errorsArray: string[] = [];

        errorFields.forEach(item =>
            item.errors.forEach(error => errorsArray.push(error))
        );

        setValidationErrorsList(errorsArray);
    }

    const paletteElementOnSelect = (elementType: ElementTypeEnum) => {
        const itemParams = new EditorItemDataParams();
        itemParams.visible = true;

        if (elementType === ElementTypeEnum.CLIPBOARD_PASTE) {
            openNotification(
                NotificationTypesEnum.ERROR,
                t`Не удалось`,
                t`Вставку нельзя использовать здесь`
            );

            return;
        }

        slideEditorRef.current?.putElement({
            id: uuidv4(),
            alias: null,
            params: itemParams,
            data: null,
            type: elementType,
            interactivityConfig: null
        });
    }

    const tutorInitialValue = useMemo<StTeacherSelectorItem | null>(() => {
        if (!initialData.tutor) {
            return null;
        }

        return {
            id: initialData.tutor.stApiId as number,
            longName: initialData.tutor.fio,
            isActive: true
        }
    }, [initialData]);

    const getNextValueIndex = (currentValue: string, array: string[]): number => {
        const currentIndex = array.indexOf(currentValue);

        if ((currentIndex === -1) || (currentIndex === array.length - 1)) {
            return 0;
        }

        return currentIndex + 1;
    }

    const rotateMask = () => {
        const nextMask = getNextValueIndex(
            currentMaskType,
            allMasks
        );

        setCurrentMaskType(allMasks[nextMask]);
    }

    const rotateColor = () => {
        const colors = [
            '#1979FF',
            '#B14AFF',
            '#00FFA7',
            '#FF6B00',
            '#D9D9D9',
            '#A207BA'
        ];

        const nextColor = getNextValueIndex(
            currentAccentColor,
            colors
        );

        setCurrentAccentColor(colors[nextColor]);
    }

    const changeCoverImageBtnOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
        cropperRef.current?.selectFile();
    };

    const cancelButton = useMemo(() => {
        if (!initialData.id) {
            return <LinkAsButton
                btnStyle={BtnStyleEnum.Secondary}
                to={RoutesList.TEACHER_SELF_STUDY_TRACKS}
                disabled={saveInProcess}
            >
                <Trans>Отмена</Trans>
            </LinkAsButton>
        }

        return <ConfirmDialog
            okText={t`Удалить`}
            cancelText={t`Отмена`}
            title={t`Удалить трек?`}
            text={
                <RegularText>
                    <Trans>Трек будет удалён также у всех учеников, которые его
                        проходят.</Trans>
                </RegularText>
            }
            trigger={
                <Button
                    btnStyle={BtnStyleEnum.Secondary}
                    disabled={saveInProcess}
                ><Trans>Удалить трек</Trans></Button>
            }
            okMethod={onDeleteTrack}/>
    }, [initialData.id, onDeleteTrack, saveInProcess]);

    return <FormWrapper>
        <Form form={form} layout={"inline"}
              onFinish={onFinish}
              onValuesChange={onFormValuesChange}
              onFinishFailed={onFormFinishFailed}
        >
            <FormItemsWrapper>
                <RegularText><Trans>
                    Self-study трек - это мини-курс по определённой теме. <br/>
                    Трек состоит из эпизодов (уроков). Каждый день для прохождения ученику открывается новый эпизод к прохождению.<br/><br/>
                    Ученик может начать участие, перейдя по ссылке.<br/>
                </Trans></RegularText>

                <FormItem
                    name={FormFieldNamesEnum.NAME}
                    initialValue={initialData.name}
                    label={<Trans>Название трека (будет отображаться ученикам и на лендинге)</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Заполните название трека`,
                        }
                    ]}
                >
                    <Input/>
                </FormItem>

                <FormItem
                    name={FormFieldNamesEnum.SHORT_DESCRIPTION}
                    initialValue={initialData.shortDescription}
                    label={<Trans>Короткое описание (будет отображаться на лендинге и в рекламной сети)</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Укажите короткое описание трека`,
                        },
                        {
                            max: 150,
                            message: "Должно быть меньше 150 символов",
                        },
                    ]}
                >
                    <Input/>
                </FormItem>

                <FormItem
                    name={FormFieldNamesEnum.INTERNAL_DESCRIPTION}
                    initialValue={initialData.internalDescription}
                    label={<Trans>Внутреннее описание (будет видно только вам)</Trans>}
                    rules={[
                        {
                            max: 500,
                            message: "Должно быть меньше 500 символов",
                        },
                    ]}
                >
                    <Input/>
                </FormItem>

                <div>
                    <PageSubtitle2><Trans>Дисциплина и уровень</Trans></PageSubtitle2>
                    <RegularText><Trans>Укажите дисциплину и уровень - это позволит, например, рекомендовать
                        материалы по английскому ученикам изучающим именно английский.</Trans></RegularText>
                </div>
                <FormItem
                    name={FormFieldNamesEnum.SUBJECT_AREA}
                    initialValue={initialData.subjectAreaLevel}
                    label={<Trans>Дисциплина и уровень</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Укажите дисциплину и уровень`,
                        }
                    ]}
                >
                    <SubjectAreaSelector/>
                </FormItem>

                <div>
                    <PageSubtitle2><Trans>Куратор</Trans></PageSubtitle2>
                    <RegularText><Trans>Ученики будут знать с кем связаться по вопросам
                        связанными с треком.</Trans></RegularText>
                </div>
                <FormItem
                    name={FormFieldNamesEnum.TUTOR}
                    initialValue={tutorInitialValue}
                    label={<Trans>Курирующий преподаватель</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Укажите курирующего преподавателя`,
                        }
                    ]}
                >
                    <StTeacherSelector/>
                </FormItem>

                <div>
                    <PageSubtitle2><Trans>Материалы трека</Trans></PageSubtitle2>
                    <RegularText><Trans>Задания трека берутся из содержимого раздела «Учебные материалы»:
                        <ul>
                            <li>«Раздел» (Unit) становится «треком»</li>
                            <li>«Урок» раздела становятся «эпизодом трека»</li>
                        </ul>
                        Т.е. каждый день ученику автоматически будет открываться 1 новый урок из раздела.
                    </Trans></RegularText>
                </div>
                <FormItem
                    name={FormFieldNamesEnum.TM_SECTION}
                    initialValue={initialData.tmSection}
                    label={<Trans>Раздел учебных материалов</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Укажите раздел учебных материалов для трека`,
                        }
                    ]}
                >
                    <TmSectionSelector/>
                </FormItem>

                <div>
                    <PageSubtitle2><Trans>Обложка</Trans></PageSubtitle2>
                    <RegularText><Trans>Обложка отображается в ЛК ученика и на лендинге.</Trans></RegularText>
                </div>

                <ImageCropperUploader ref={cropperRef}
                                      fileType={UserFileTypeEnum.SELF_STUDY_TRACK_COVER}
                                      handleFile={(fileId) => {
                                          setCoverFileId(fileId);
                                          return Promise.resolve();
                                      }}
                                      config={{
                                          width: 1000,
                                          height: 1000,
                                          shape: "rect",
                                          aspectRatio: 1
                                      }}
                />

                {
                    (!coverFileId)
                        ? <CoverButtonsWrapper><Button btnStyle={BtnStyleEnum.Primary}
                                                       onClick={changeCoverImageBtnOnClick}>
                            <Trans>Выбрать изображение</Trans>
                        </Button></CoverButtonsWrapper>
                        : <>
                            <CoverExampleWrapper>
                                <LargeItem baseData={{
                                    coverMaskId: currentMaskType,
                                    name: nameForCover??t`Название трека`,
                                    accentColor: currentAccentColor,
                                    shortDescription: shortDescriptionForCover??t`Описание трека`,
                                    coverFileId: coverFileId
                                }}/>
                            </CoverExampleWrapper>

                            <CoverButtonsWrapper>
                                <Button btnStyle={BtnStyleEnum.Secondary} onClick={changeCoverImageBtnOnClick}>
                                    <Trans>Изменить картинку</Trans>
                                </Button>

                                <Button btnStyle={BtnStyleEnum.Secondary} onClick={rotateMask}>
                                    <Trans>Изменить форму</Trans>
                                </Button>

                                <Button btnStyle={BtnStyleEnum.Secondary} onClick={rotateColor}>
                                    <Trans>Изменить цвет</Trans>
                                </Button>
                            </CoverButtonsWrapper>
                        </>
                }

                <div>
                    <PageSubtitle2><Trans>Описание для лендинга</Trans></PageSubtitle2>
                    <RegularText><Trans>У каждого трека есть страничка-лендинг, с которой действующий или потенциальный
                        ученик может записаться на трек.</Trans></RegularText>
                </div>
                <FormItem
                    label={<Trans>Описание для лендинга</Trans>}
                    rules={[
                        {
                            required: true,
                            message: t`Задайте описание для лендинга`,
                        }
                    ]}
                >
                    <SlideEditorWrapper>
                        <SlideEditor ref={slideEditorRef} contentOnChange={landingContentOnChange}/>
                        {
                            (landingContent === null || landingContent.items.length === 0)
                            && <LandingEditorAreaPlaceholder/>
                        }
                        <BottomPaletteButton onSelectItem={paletteElementOnSelect}/>
                    </SlideEditorWrapper>
                </FormItem>

            </FormItemsWrapper>

            {
                (validationErrorsList.length > 0)
                && <ValidationSummary>
                    <NoticeBlockTitle><Trans>Нужно внести правки:</Trans></NoticeBlockTitle>
                    <NoticeBlockText>
                        <ValidationSummaryList>
                            {
                                validationErrorsList.map(item => {
                                    return <li>{item}</li>
                                })
                            }
                        </ValidationSummaryList>
                    </NoticeBlockText>
                </ValidationSummary>
            }

            <ButtonsWrapper>
                <Button
                    btnStyle={BtnStyleEnum.Primary}
                    loading={saveInProcess}
                    onClick={() => form.submit()}
                >
                    <Trans>Сохранить</Trans>
                </Button>
                {cancelButton}
            </ButtonsWrapper>
        </Form>
    </FormWrapper>
}