import {ICustomStorage} from "./ICustomStorage";
import {TargetsEnum} from "./TargetsEnum";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

const SESSION_STORAGE_USE_FLAG_NAME = 'usedSessionStorage';

export class CustomStorage implements ICustomStorage {

    /**
     * Текущее место хранения
     *
     * @protected
     */
    protected currentTarget: TargetsEnum;

    /**
     * Объект хранения на случай, если запрашиваемое хранилище недоступно
     *
     * @protected
     */
    protected objectStorage: { [id: string]: string };

    /**
     * Список подписчиков на изменение target хранилища
     *
     * @protected
     */
    protected subscribers: Function[];

    /**
     *
     * @protected
     */
    protected logger: ILogger;

    constructor(logger: ILogger) {
        this.currentTarget = TargetsEnum.OBJECT;
        this.logger = logger;
        this.objectStorage = {};
        this.subscribers = [];
    }

    /**
     * @inheritDoc
     */
    getItem(key: string): Promise<string | null> {
        return new Promise<string | null>((resolve) => {
            let value = null;

            switch (this.currentTarget) {
                case TargetsEnum.LOCAL_STORAGE: {
                    value = window.localStorage.getItem(key);

                    break;
                }
                case TargetsEnum.SESSION_STORAGE: {
                    value = window.sessionStorage.getItem(key);

                    break;
                }
                default: {
                    value = (this.objectStorage[key] === undefined) ? null : this.objectStorage[key];

                    break;
                }
            }

            resolve(value);
        });
    }

    /**
     * @inheritDoc
     */
    removeItem(key: string): Promise<void> {
        return new Promise<void>(resolve => {
            switch (this.currentTarget) {
                case TargetsEnum.LOCAL_STORAGE: {
                    window.localStorage.removeItem(key);

                    break;
                }
                case TargetsEnum.SESSION_STORAGE: {
                    window.sessionStorage.removeItem(key);

                    break;
                }
                default: {
                    if (this.objectStorage[key] !== undefined) {
                        delete this.objectStorage[key];
                    }

                    break;
                }
            }

            resolve();
        });
    }

    /**
     * @inheritDoc
     */
    setItem(key: string, value: string): Promise<void> {
        return new Promise<void>(resolve => {
            switch (this.currentTarget) {
                case TargetsEnum.LOCAL_STORAGE: {
                    window.localStorage.setItem(key, value);

                    break;
                }
                case TargetsEnum.SESSION_STORAGE: {
                    window.sessionStorage.setItem(key, value);

                    break;
                }
                default: {
                    this.objectStorage[key] = value;

                    break;
                }
            }

            resolve();
        });
    }

    /**
     * @inheritDoc
     */
    setNewTarget(target: TargetsEnum): void {
        const testResult = this.testStorage(target);

        if (testResult) {
            if (
                (this.currentTarget === TargetsEnum.SESSION_STORAGE)
                && (target !== TargetsEnum.SESSION_STORAGE)
            ) {
                // Если был sessionStorage, но теперь будет не он
                window.sessionStorage.removeItem(SESSION_STORAGE_USE_FLAG_NAME);
            }

            this.currentTarget = target;

            this.logger.info(
                LoggerSectionsEnum.CUSTOM_STORAGE,
                `Selected storage ${target}`
            );

            if (target === TargetsEnum.SESSION_STORAGE) {
                this.setItem(SESSION_STORAGE_USE_FLAG_NAME, "true");
            }

            for (let i = 0; i < this.subscribers.length; i++) {
                try {
                    this.subscribers[i]();
                } catch (e) {
                    this.logger.error(
                        LoggerSectionsEnum.CUSTOM_STORAGE,
                        `Error on subscriber call: `,
                        e
                    );
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    selectLastUserStorage(): void {
        if (window.sessionStorage !== undefined) {
            const flag = window.sessionStorage.getItem(SESSION_STORAGE_USE_FLAG_NAME);

            if (flag) {
                this.setNewTarget(TargetsEnum.SESSION_STORAGE);

                return;
            }
        }

        this.setNewTarget(TargetsEnum.LOCAL_STORAGE);
    }

    /**
     * @inheritDoc
     */
    subscribeTargetOnChanged(func: Function): void {
        this.subscribers.push(func);
    }

    /**
     * @inheritDoc
     */
    getCurrentTarget(): TargetsEnum {
        return this.currentTarget;
    }

    /**
     * @inheritDoc
     */
    get length(): number {
        let lengthValue = 0;

        switch (this.currentTarget) {
            case TargetsEnum.LOCAL_STORAGE: {
                lengthValue = window.localStorage.length;

                break;
            }
            case TargetsEnum.SESSION_STORAGE: {
                lengthValue = window.sessionStorage.length;

                break;
            }
            default: {
                lengthValue = Object.keys(this.objectStorage).length;

                break;
            }
        }

        return lengthValue;
    }

    /**
     * @inheritDoc
     */
    clear(): void {
        switch (this.currentTarget) {
            case TargetsEnum.LOCAL_STORAGE: {
                window.localStorage.clear();

                break;
            }
            case TargetsEnum.SESSION_STORAGE: {
                window.sessionStorage.clear();

                break;
            }
            default: {
                this.objectStorage = {};

                break;
            }
        }
    }

    /**
     * @inheritDoc
     */
    key(index: number): string | null {
        this.logger.error(
            LoggerSectionsEnum.CUSTOM_STORAGE,
            'Called method "key"'
        );

        return null;
    }

    /**
     * Протестировать хранилище
     *
     * @protected
     */
    protected testStorage(target: TargetsEnum): boolean {
        const testKey = 'oS*HFI8oha&SF';
        const testValue = '*HASS&I*fhih';

        switch (target) {
            case TargetsEnum.LOCAL_STORAGE: {
                if (!window.localStorage) {
                    this.logger.error(
                        LoggerSectionsEnum.CUSTOM_STORAGE,
                        "Browser without local storage"
                    );

                    return false;
                }

                try {
                    window.localStorage.setItem(testKey, testValue);

                    const receivedValue = window.localStorage.getItem(testKey);

                    if (receivedValue !== testValue) {
                        throw new Error(`Received value ${receivedValue}, but test value was ${testValue}`);
                    }

                    window.localStorage.removeItem(testKey);
                } catch (e) {
                    this.logger.error(
                        LoggerSectionsEnum.CUSTOM_STORAGE,
                        'Error on test local storage:',
                        e
                    );

                    return false;
                }

                return true;
            }

            case TargetsEnum.SESSION_STORAGE: {
                if (!window.sessionStorage) {
                    this.logger.error(
                        LoggerSectionsEnum.CUSTOM_STORAGE,
                        "Browser without session storage"
                    );

                    return false;
                }

                try {
                    window.sessionStorage.setItem(testKey, testValue);

                    const receivedValue = window.sessionStorage.getItem(testKey);

                    if (receivedValue !== testValue) {
                        throw new Error(`Received value ${receivedValue}, but test value was ${testValue}`);
                    }

                    window.sessionStorage.removeItem(testKey);
                } catch (e) {
                    this.logger.error(
                        LoggerSectionsEnum.CUSTOM_STORAGE,
                        'Error on test session storage:',
                        e
                    );

                    return false;
                }

                return true;
            }
        }

        throw new Error(`Unknown test storage target for test: ${target}`);
    }
}
