import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Trans} from "@lingui/macro";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {
    DtoSchoolsSummaryInfo
} from "../../../../components/StonlineApiClient/ApiDto/Response/SchoolsManagement/DtoSchoolsSummaryInfo";
import {useSelector} from "react-redux";
import {stTokenSelector} from "../../../../store/app/selector";
import {PageLoadStatus} from "../../teacher/knowledge-base/common/Enums";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {NoticeBlock, NoticeBlockText} from "../../../components/Ui/Elements/NoticeBlock";
import {SchoolCard} from "./SchoolCard";
import {PageWrapper} from "../../../components/PageWrapper";
import styled from "styled-components";

const SchoolCardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Moderator: React.FC = () => {
    const token = useSelector(stTokenSelector);
    const [pageState, setPageState] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [schoolsDto, setSchoolsDto] = useState<DtoSchoolsSummaryInfo[]>([]);

    const fetchData = useCallback(() => {
        if (token === null) {
            setPageState(PageLoadStatus.ERROR);

            return;
        }

        setPageState(PageLoadStatus.LOADING);

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);

        stApiClient.getSchoolsSummary(token)
            .then((data) => {
                setSchoolsDto(data.result.items);
                setPageState(PageLoadStatus.SUCCESS);
            })
            .catch((err) => {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(
                    LoggerSectionsEnum.MODERATOR_PANEL,
                    'Error on load schools info: ',
                    err
                );

                setPageState(PageLoadStatus.ERROR);
            });
    }, [token]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = useMemo(() => {
        if (pageState === PageLoadStatus.ERROR) {
            return <ErrorLoadingContent retryBtnClick={fetchData}/>
        }

        if (pageState === PageLoadStatus.SUCCESS) {
            if (schoolsDto.length === 0) {
                return <NoticeBlock>
                    <NoticeBlockText>
                        <Trans>Ничего не найдено</Trans>
                    </NoticeBlockText>
                </NoticeBlock>;
            }

            return <SchoolCardsList>
                {schoolsDto.map((item) => {
                    return <SchoolCard key={item.id} dto={item}/>
                })}
            </SchoolCardsList>
        }

        return <DefaultLoader/>;
    }, [fetchData, pageState, schoolsDto]);

    return <div>
        <PageWrapper
            backLink={null}
            pageTitle={<Trans>Управление</Trans>}
            rightBlockContent={null}
            pageContent={content}/>
    </div>;
}