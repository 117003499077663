import {action} from "typesafe-actions";

import {
    AuthUserRequestContext,
    SetSelectedUserIdReducerInput,
    UserActionTypes,
    UserProfileLoadState,
    UserProfileType
} from "./type";
import {
    UserProfileDataDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserProfileDataDto";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {AuthLoginResultDto} from "../../components/WsApiClient/ApiDto/Response/Auth/LoginResultDto";
import {
    ActualParamsDto
} from "../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/ActualParamsDto";

export const startLoadingUserProfile = () => {
    return action(UserActionTypes.LOAD_USER_DATA_BY_SESSION_TOKEN);
};

export const startUserAuthOnWsConnection = () => {
    return action(UserActionTypes.START_USER_AUTH_ON_WS_CONNECTION);
};

export const startUserReconnectionAuthOnWsConnection = () => {
    return action(UserActionTypes.START_USER_RECONNECTION_AUTH_ON_WS_CONNECTION);
};

export const processUserWsAuthResult = (baseDto: ResponseActionCreatorPayload<ResponseBaseDto<AuthLoginResultDto>, AuthUserRequestContext>) => {
    return action(UserActionTypes.PROCESS_USER_WS_AUTH_RESULT, baseDto);
};

export const activateAgreementId = (data: SetSelectedUserIdReducerInput) => {
    return action(UserActionTypes.ACTIVATE_AGREEMENT_ID, data);
};

export const saveUserUtm = () => {
    return action(UserActionTypes.SAVE_USER_UTM);
};

export const setUserProfileLoadState = (newAuthState: UserProfileLoadState) => {
    return action(UserActionTypes.SET_USER_PROFILE_LOAD_STATE, newAuthState)
};

export const setUserProfileType = (profileType: UserProfileType) => {
    return action(UserActionTypes.SET_USER_PROFILE_TYPE, profileType)
};

export const setUserAvatarFileId = (avatarFileId: string) => {
    return action(UserActionTypes.SET_USER_AVATAR_FILE_ID, avatarFileId)
};

export const setUserSessionToken = (newAuthToken: string | null) => {
    return action(UserActionTypes.SET_USER_SESSION_TOKEN, newAuthToken)
};

export const setUserStToken = (newStToken: string) => {
    return action(UserActionTypes.SET_USER_ST_TOKEN, newStToken)
};

export const setUserProfileData = (userProfileData: UserProfileDataDto) => {
    return action(UserActionTypes.SET_USER_PROFILE_DATA, userProfileData);
};

export const setTeacherActualParams = (actualParamsDto: ActualParamsDto) => {
    return action(UserActionTypes.SET_USER_TEACHER_ACTUAL_PARAMS, actualParamsDto);
};

export const setUserIsModerator = (newValue: boolean) => {
    return action(UserActionTypes.SET_USER_IS_MODERATOR, newValue);
};

export const setUserWsReconnectionToken = (wsReconnectionToken: string) => {
    return action(UserActionTypes.SET_WS_RECONNECTION_TOKEN, wsReconnectionToken);
};

export const setSelectedAgreementId = (data: SetSelectedUserIdReducerInput) => {
    return action(UserActionTypes.SET_SELECTED_AGREEMENT_ID, data);
};

export const setUpdatesAfterInitialCustomizerFinished = (userName: string, timezoneName: string, actualParams: ActualParamsDto, schoolName: string) => {
    return action(
        UserActionTypes.SET_UPDATES_AFTER_INITIAL_CUSTOMIZER_FINISHED,
        {
            userName: userName,
            timezoneName: timezoneName,
            actualParams: actualParams,
            schoolName: schoolName
        }
    );
}

export const logoutUser = () => {
    return action(UserActionTypes.LOGOUT_USER);
};
