import React, {useCallback, useContext, useMemo} from "react";
import {AdminViewContext, IAdminViewContext} from "./Context";
import styled from "styled-components";
import {BtnStyleEnum, Button} from "../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {container} from "tsyringe";
import {IClipboardHelper} from "../../../../../components/ClipboardHelper/IClipboardHelper";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";
import {LinkAsButton} from "../../../../components/Ui/Elements/LinkAsButton";
import {ReactComponent as PencilSvg} from "../../../../components/Ui/Svg/PencilNoPadding.svg";
import {ReactComponent as CopySvg} from "../../../../components/Ui/Svg/Copy20.svg";

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 32px;
    justify-content: right;
    width: 100%;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;

    @media (${({theme}) => theme.media.small}) {
        width: unset;
    }
`;

const LinkAsButtonStyled = styled(LinkAsButton)`
    width: 100%;
    
    @media (${({theme}) => theme.media.small}) {
        width: auto;
    }
`;

const ButtonStyled = styled(Button)`
    width: 100%;
    
    @media (${({theme}) => theme.media.small}) {
        width: auto
    }
`;

const BtnContent = styled.span`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
`;

const BtnLabel = styled.span``;
const BtnIcon = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const LandingLinkWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const LandingLink = styled.a`
    font-size: 10px;
    color: ${({theme}) => theme.colors.textSecondary};
`;

export const LinksBlock: React.FC = () => {
    const context = useContext<IAdminViewContext>(AdminViewContext);

    const editLink = useMemo<string>(() => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_SELF_STUDY_TRACK_EDIT,
            [
                {
                    key: 'trackId',
                    value: context.trackId
                }
            ]
        )
    }, [context.trackId]);

    const landingLink = useMemo<string>(() => {
        return `https://about.my-lessons.ru/mini-course/${context.trackSlug}`
    }, [context.trackSlug]);

    const copySubscribeLink = useCallback(() => {
        const clipboardHelper = container.resolve<IClipboardHelper>(DiTokens.CLIPBOARD_HELPER);

        clipboardHelper.copyText(
            window.location.origin + RoutesHelper.replaceParams(
                RoutesList.SELF_STUDY_SUBSCRIBE,
                [
                    {
                        key: 'trackId',
                        value: context.trackId
                    }
                ]
            )
        )
            .then(() => {
                openNotification(
                    NotificationTypesEnum.SUCCESS,
                    t`Ссылка скопирована`,
                    ''
                );

                return;
            })
            .catch(() => {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не получается скопировать. Попробуйте ещё раз позднее.`,
                    ''
                );
            });
    }, [context.trackId]);

    return <ButtonsWrapper>
        <ButtonWrapper>
            <LinkAsButtonStyled
                to={editLink}
                target={'_blank'}
                btnStyle={BtnStyleEnum.Secondary}
            >
                <BtnContent>
                    <BtnLabel>
                        <Trans>Редактировать</Trans>
                    </BtnLabel>
                    <BtnIcon>
                        <PencilSvg/>
                    </BtnIcon>
                </BtnContent>
            </LinkAsButtonStyled>
        </ButtonWrapper>

        <ButtonWrapper>
            <ButtonStyled
                onClick={copySubscribeLink}
                btnStyle={BtnStyleEnum.Secondary}
            >
                <BtnContent>
                    <BtnLabel>
                        <Trans>Ссылка для участия</Trans>
                    </BtnLabel>
                    <BtnIcon>
                        <CopySvg/>
                    </BtnIcon>
                </BtnContent>
            </ButtonStyled>
            <LandingLinkWrapper>
                <LandingLink href={landingLink} target={'_blank'}>
                    <Trans>Лендинг на платформе</Trans>
                </LandingLink>
            </LandingLinkWrapper>
        </ButtonWrapper>
    </ButtonsWrapper>
}