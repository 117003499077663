import {CustomElement} from "./TextEditorElementTypes/CustomElement";
import {ICustomText} from "./TextEditorElementTypes/ICustomText";
import {ElementTypes} from "./TextEditorElementTypeEnum";

export class ElementFetcher {
    static fetchElementsByTypesInEditorData<T>(desiredElementType: ElementTypes, editorDataElements: CustomElement[] | ICustomText[]): Array<T> {
        let result: Array<T> = [];

        const editorDataItemsCount = editorDataElements.length;

        for (let index = 0; index < editorDataItemsCount; index++) {
            const elementAsUnknown = editorDataElements[index] as any;

            if (elementAsUnknown.type === undefined) {
                // Если тип не указан, значит это просто текст
                continue;
            }

            // Если указан тип, это какой-то сложный компонент с children
            const elementAsElement = elementAsUnknown as CustomElement;

            if (elementAsElement.type === desiredElementType) {
                result.push(elementAsElement as unknown as T);
            }

            if (
                (elementAsElement.children !== undefined)
                && (Array.isArray(elementAsElement.children))
                && (elementAsElement.children.length > 0)
            ) {
                result.push(...ElementFetcher.fetchElementsByTypesInEditorData<T>(
                    desiredElementType,
                    elementAsElement.children
                ));
            }
        }

        return result;
    }
}