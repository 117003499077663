import {instanceCachingFactory} from 'tsyringe';
import {ILogger} from "../components/Logger/ILogger";
import {DiTokens} from "./DiTokens";
import {DeviceMediaStreamFetcher} from "../components/DeviceMediaStreamFetcher/DeviceMediaStreamFetcher";
import {IDeviceMediaStreamFetcher} from "../components/DeviceMediaStreamFetcher/IDeviceMediaStreamFetcher";

export default instanceCachingFactory<IDeviceMediaStreamFetcher>((container) => {
    return new DeviceMediaStreamFetcher(
        container.resolve<ILogger>(DiTokens.LOGGER)
    );
});
