import {put, takeEvery} from 'redux-saga/effects';
import {LayoutActionTypes} from "../../store/layout/type";
import {availableThemes, ThemeEnum} from "../../services/theme/ThemeEnum";
import * as layoutActionCreators from "../../store/layout/actions";
import * as commonPersistedActionCreators from "../../store/commonPersisted/actions";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {LightTheme} from "../../services/theme/LightTheme";
import {DarkTheme} from "../../services/theme/DarkTheme";

interface PayloadType {
    name: ThemeEnum;
    dontPersist: boolean
}

/**
 * Сага отвечает за смену темы оформления.
 */
export function* watchChangeTheme() {
    yield takeEvery<WorkerPayloadType<PayloadType>>(
        LayoutActionTypes.CHANGE_THEME,
        setTheme
    );
}

function* setTheme(data: WorkerPayloadType<PayloadType>) {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.THEME_SERVICE, 'Start change theme to: ', data.payload.name);

    if (!availableThemes.includes(data.payload.name)) {
        logger.error(LoggerSectionsEnum.THEME_SERVICE, `Not found theme by name: `, data.payload.name);

        return;
    }

    let theme = LightTheme;
    let themeName = ThemeEnum.light;

    switch (data.payload.name) {
        case ThemeEnum.dark: {
            theme = DarkTheme;
            themeName = ThemeEnum.dark;

            break;
        }
    }

    yield put(layoutActionCreators.setActiveTheme(theme));
    yield put(layoutActionCreators.setActiveThemeName(themeName));

    // Если изменение темы временное и не нужно его сохранять
    if (!data.payload.dontPersist) {
        yield put(commonPersistedActionCreators.setThemeName(data.payload.name));
    }

    // Установим цветовую тему браузера
    const elem = document.getElementById('themeColor');

    if (elem) {
        elem.setAttribute('content', theme.colors.browserTheme);
    }

    logger.info(
        LoggerSectionsEnum.THEME_SERVICE,
        `Theme selected successfully: ${data.payload.name}`,
        (data.payload.dontPersist) ? '(temporary)' : '(permanent)'
    );
}
