import React, {useContext, useMemo} from "react";
import {IButtonBaseProps} from "../IButtonBaseProps";
import {ControlPanelItem, ControlPanelItemIcon} from "../../../../Ui/Elements/ControlPanel/ControlPanel";
import {ReactComponent as Settings} from "../../../../Ui/Elements/ControlPanel/icons/Settings.svg";
import classNames from "classnames";
import {CommonContext, ICommonContext, LessonConferenceStatusEnum} from "../../../contexts/CommonContext";
import {AudioRoomContext, IAudioRoomContext} from "../../../contexts/AudioRoomContext";
import {MediaDeviceSelectModal} from "./MediaDeviceSelectModal";
import {IVideoRoomContext, VideoRoomContext} from "../../../contexts/VideoRoomContext";
import {ConnectionStateEnum} from "../../../Types";

export const SettingsBtn: React.FC<IButtonBaseProps> = () => {
    const commonContext = useContext<ICommonContext>(CommonContext);
    const audioRoomContext = useContext<IAudioRoomContext>(AudioRoomContext);
    const videoRoomContext = useContext<IVideoRoomContext>(VideoRoomContext);

    const btnDisabled = useMemo<boolean>(() => {
        return (
            commonContext.lessonConferenceState !== LessonConferenceStatusEnum.STARTED
            ||
            (
                !audioRoomContext.audioRoomConnectionState.streamIsReady
                && videoRoomContext.videoRoomConnectionState.publisherConnection.status !== ConnectionStateEnum.CONNECTED
            )
        );
    }, [audioRoomContext.audioRoomConnectionState.streamIsReady, commonContext.lessonConferenceState, videoRoomContext.videoRoomConnectionState.publisherConnection.status]);

    return <MediaDeviceSelectModal
        allowMicSection={audioRoomContext.audioRoomConnectionState.streamIsReady}
        allowCameraSection={videoRoomContext.videoRoomConnectionState.publisherConnection.status === ConnectionStateEnum.CONNECTED}
        trigger={
        <ControlPanelItem className={classNames('panel-item', btnDisabled && 'disabled')}>
            <ControlPanelItemIcon className={'no-margin'}><Settings/></ControlPanelItemIcon>
        </ControlPanelItem>
    }/>
}
