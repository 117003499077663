import {put, select, takeEvery} from 'redux-saga/effects';
import {LessonRoomActionTypes, LessonRoomUserTypeEnum} from "../../store/lessonRoom/type";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {DtoRoomParticipant} from "../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoRoomParticipant";
import {setItemsToMembersList} from "../../store/lessonRoom/actions";
import {ApplicationState} from "../../store";
import {NotificationTypesEnum, openNotification} from "../../views/components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {StreamEventStoreItem} from "../../store/streamEvent/type";

/**
 * Обработка сообщения о подключении пользователя к комнате
 */
export function* watchHandleRoomMemberEntered() {
    yield takeEvery<WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoRoomParticipant>, null>>>(
        LessonRoomActionTypes.HANDLE_SE_MEMBER_ENTERED,
        handleRoomMemberEntered
    );
}

const currentRoomId = ({lessonRoom}: ApplicationState) => lessonRoom.roomId;

function* handleRoomMemberEntered(data: WorkerPayloadType<ResponseActionCreatorPayload<StreamEventStoreItem<DtoRoomParticipant>, null>>) {
    const memberRoomId = data.payload.response.dto.payload.lessonId;

    const activeRoomId = (yield select(currentRoomId)) as string | null;

    if (activeRoomId !== memberRoomId) {
        return;
    }

    const dtoItem = data.payload.response.dto.payload;

    yield put(setItemsToMembersList([{
        userId: dtoItem.userId,
        userName: dtoItem.userName,
        userAvatarFileId: dtoItem.userAvatarFileId,
        userType: dtoItem.userType,
        screenOrientation: dtoItem.screenOrientation,
        detailsForTeacher:
            (dtoItem.detailsForTeacher)
                ? {
                    stApiUserId: dtoItem.detailsForTeacher.stApiUserId,
                    openedSlideId: dtoItem.detailsForTeacher.openedSlideId
                }
                : null,
        pinnedUser: (dtoItem.conferenceParams) ? dtoItem.conferenceParams.pinnedUser : null,
        inFullScreen: (dtoItem.conferenceParams) ? dtoItem.conferenceParams.inFullScreen : false
    }]));

    openNotification(
        NotificationTypesEnum.INFO,
        (dtoItem.userType === LessonRoomUserTypeEnum.TEACHER)
            ? t`Вошёл учитель`
            : t`Вошёл ученик`,
        dtoItem.userName
    );
}
