import React, {useEffect, useRef, useState} from 'react';
import {quintInOut} from "eases";

type CallbackType = () => void;
export type CustomFunctionRender = (value: number, decimals: number) => number;

function useInterval(callback: CallbackType, delay: number) {
    const savedCallback = useRef<CallbackType>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (savedCallback.current) {
                savedCallback.current();
            }
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

function defaultRender(value: number, decimals: number) {
    return Number(value).toFixed(decimals);
}

interface NumberEasingProps {
    className?: string;
    value: number;
    speed?: number;
    decimals?: number;
    customFunctionRender?: CustomFunctionRender;
    ease?: (t: number) => number;
}

export const NumberEasing: React.FC<NumberEasingProps> = (props) => {
    const {value, speed, decimals, customFunctionRender, ease, className} = props;

    const [renderValue, renderValueSet] = useState(value);
    const [lastTarget, lastTargetSet] = useState(value);

    const [lastUpdateTime, lastUpdateTimeSet] = useState(new Date().getTime());

    useEffect(() => {
        lastUpdateTimeSet(new Date().getTime() - 16);
        lastTargetSet(renderValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useInterval(() => {
        const currentTime = new Date().getTime();
        const absoluteProgress = (currentTime - lastUpdateTime) / (speed ?? 300);

        let easeFn = (ease ?? quintInOut);

        if (absoluteProgress >= 1) {
            renderValueSet(value);
        } else {
            const easedProgress = easeFn(absoluteProgress);
            renderValueSet(lastTarget + (value - lastTarget) * easedProgress);
        }
    }, 16);

    const functionRender = customFunctionRender || defaultRender;

    return <div className={className}>
        {functionRender(renderValue, (decimals ?? 0))}
    </div>;
}