import styled from 'styled-components';
import {MaskedInput as AntMaskedInput} from "antd-mask-input";
import {RegularFontSize} from "../../../styles/global-elements";

export const MaskedInput = styled(AntMaskedInput)`
  ${RegularFontSize};
  border: none;
  background-color: ${({theme}) => theme.colors.backgroundPrimary};
  border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
  padding: 13px 0;
  color: ${({theme}) => theme.colors.textPrimary};

  &:focus, &:hover {
    border: none;
    border-bottom: 1px solid ${({theme}) => theme.colors.accentDivider};
    border-right-width: inherit;
    box-shadow: none;
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }

  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
  &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
    border-color: #ff4d4f;
    box-shadow: none;
    border-right-width: inherit;
    outline: 0;
    background-color: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};
  }
`;