import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../../../../../components/Ui/Elements/Modal";
import React, {useRef, useState} from "react";
import {Form, FormItem, useForm} from "../../../../../../components/Ui/Elements/Form";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {Input} from "../../../../../../components/Ui/Elements/TextInput";
import {
    DtoTmOrganization
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {t, Trans} from "@lingui/macro";
import {ModalP, ModalSubTitle, ModalTitle} from "../../../../../../styles/global-elements";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../../store/app/selector";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {ConfirmDialog} from "../../../../../../components/Ui/Elements/ConfirmDialog";

export enum ModalResultType {
    CREATED,
    UPDATED,
    DELETED
}

interface TmOrganizationModalProps extends ModalChildProps {
    organizationItem: DtoTmOrganization | null;
    result: (action: ModalResultType, item: DtoTmOrganization | null) => void;
}

export const TmOrganizationModal: React.FC<TmOrganizationModalProps> = (props) => {
    const [form] = useForm();
    const modalRef = useRef<ReactjsPopupActions>(null);
    const [closeAllowed, setCloseAllowed] = useState(true);
    const [saveInProcess, setSaveInProcess] = useState(false);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const onFinish = (values: any) => {
        if ((!sessionToken) || (!modalRef)) {
            return;
        }

        setCloseAllowed(false);
        setSaveInProcess(true);

        const promise = (props.organizationItem) ?
            httpApiClient.tmUpdateOrganization(
                sessionToken,
                props.organizationItem.id,
                values.name
            )
            : httpApiClient.tmCreateOrganization(
                sessionToken,
                values.name
            );

        promise
            .then((data) => {
                props.result(
                    (props.organizationItem) ? ModalResultType.UPDATED : ModalResultType.CREATED,
                    data.data
                );

                modalRef?.current?.close();
            })
            .catch((error) => {
                setCloseAllowed(true);
                setSaveInProcess(false);

                if (props.organizationItem) {
                    logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error on update organization: ", error);
                } else {
                    logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error on create organization: ", error);
                }

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    const onDelete = async () => {
        if ((!sessionToken) || (!modalRef) || (!props.organizationItem)) {
            return;
        }

        try {
            await httpApiClient.tmDeleteOrganization(
                sessionToken,
                props.organizationItem.id
            )
        } catch (error) {
            logger.error(LoggerSectionsEnum.TM_ORGANIZATIONS_API, "Error on delete organization: ", error);

            throw error;
        }

        props.result(
            ModalResultType.DELETED,
            null
        );

        modalRef?.current?.close();
    }

    const footerContent = (controls: ModalControlParams) => {
        if (props.organizationItem === null) {
            return <div>
                <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                        onClick={() => form.submit()}>
                    <Trans>Создать организацию</Trans>
                </Button>
            </div>;
        }

        // Если сейчас мы редактируем организацию
        return <>
            <ConfirmDialog trigger={
                <div>
                    <Button style={{marginRight: "20px"}} btnStyle={BtnStyleEnum.Secondary} disabled={saveInProcess}>
                        <Trans>Удалить</Trans>
                    </Button>
                </div>
            }
                           okText={t`Удалить`}
                           cancelText={t`Отмена`}
                           title={t`Удалить организацию?`}
                           text={t`Вы уверены, что нужно удалить организацию ${props.organizationItem.name}?`}
                           errorText={t`Не удалось удалить информацию`}
                           okMethod={onDelete}/>
            <Button btnStyle={BtnStyleEnum.Primary} loading={saveInProcess}
                    onClick={() => form.submit()}>
                <Trans>Сохранить</Trans>
            </Button>
        </>;
    }

    return <Modal innerRef={modalRef}
                  trigger={props.trigger}
                  closeAllowed={closeAllowed}
                  onClose={() => {
                      form.resetFields();
                  }}
                  footer={footerContent}
                  footerMode={(props.organizationItem) ? FooterMode.SPACE_BETWEEN : FooterMode.DEFAULT}
                  children={
                      (controls) => {
                          return <div>
                              <ModalTitle>
                                  {
                                      (props.organizationItem === null)
                                          ? <Trans>Создать организацию</Trans>
                                          : <Trans>Редактировать организацию</Trans>
                                  }
                              </ModalTitle>
                              {(props.organizationItem === null) && (<ModalP>
                                  <Trans>Все создаваемые учебно-методические материалы закрепляются конкретной
                                      организацией-издателем. При желании материалы организации можно предоставить в
                                      пользование другим школам системы "Мои занятия"</Trans>
                              </ModalP>)}
                              <ModalSubTitle> </ModalSubTitle>
                              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                                  <FormItem
                                      name={`name`}
                                      initialValue={props.organizationItem?.name}
                                      // label={t`Название организации`}
                                      rules={[
                                          {
                                              required: true,
                                              message: t`Необходимо указать название организации`,
                                          },
                                          {
                                              type: "string",
                                              min: 3,
                                              message: t`Имя должно быть не короче трёх символов`
                                          },
                                          {
                                              type: "string",
                                              max: 255,
                                              message: t`Имя должно быть не длиннее 255 символов`
                                          }
                                      ]}
                                  >
                                      <Input
                                          status={""}
                                          type="text"
                                          size="large"
                                          // onChange={}
                                          // onKeyDown={}
                                          placeholder={'Название организации'}/>
                                  </FormItem>
                              </Form>
                              {(props.organizationItem === null) || (<ModalP>
                                  <Trans>Если вы хотите передать учебно-методические материалы закреплённые за
                                      организацией в пользование другой фирме, обратитесь в поддержку "Мои
                                      занятия"</Trans>
                              </ModalP>)}
                          </div>
                      }
                  }
    />
}