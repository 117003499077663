import {UserFileFormatsEnum, UserFileTypeEnum} from "../../enums/UserFileEnums";

export enum FileFormatsRepoActionTypes {
    FETCH_FILE_INFO = '@@uploadQueue/FETCH_FILE_INFO',

    SET_FILE_INFO = '@@uploadQueue/SET_FILE_INFO',
    REMOVE_FILE_INFO = '@@uploadQueue/REMOVE_FILE_INFO'
}

export type IndexByFileIdType = { [fileId: string]: number };

export enum FileFetchInfoProcessStatus {
    WAIT_FOR_START,
    IN_PROCESS,
    SUCCESS,
    FAILED
}

export interface FileFormatInfo {
    id: string;
    formatType: UserFileFormatsEnum;
    url: string;
    mimeType: string;
}

export interface FileInfo {
    id: string;
    type: UserFileTypeEnum | null;
    availableFormats: FileFormatInfo[];
    fetchInfoStatus: FileFetchInfoProcessStatus;
    formatForPrefetch: UserFileFormatsEnum | null;
}

export interface FileFormatsRepoState {
    files: FileInfo[];
    indexByFileId: IndexByFileIdType
}