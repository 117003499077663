import React, {useContext} from "react";
import {IStepWizardContext, StepWizardContext} from "../../../../components/StepWizard/StepWizardContext";
import {PageSectionsEnum} from "../PageSectionsEnum";
import {StepWizardStatusEnum} from "../../../../components/StepWizard/StepWizardStatusEnum";
import {PageSubtitleSmallMargin, RegularText} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {ButtonArea} from "../../student/create-agreement-page/ButtonArea";

const FormWrapper = styled.div`
  margin-bottom: 30px;
`;

const ImageWrapper = styled.div`
  text-align: center;

  @media (${({theme}) => theme.media.small}) {
    text-align: left;
  }
`;

const Image = styled.img`
  box-shadow: 0px 4px 50px 0px rgba(25, 121, 255, 0.50);
  width: 70%;
  height: auto;

  @media (${({theme}) => theme.media.small}) {
    height: 327px;
    width: auto;
  }
`;

export const AboutActParamsSection: React.FC = () => {
    const stepWizardContext = useContext<IStepWizardContext>(StepWizardContext);

    const onFinish = () => {
        stepWizardContext.goNext(
            [
                {
                    id: PageSectionsEnum.ABOUT_ACT_PARAMS,
                    status: StepWizardStatusEnum.COMPLETED
                }
            ]
        )
    }

    return <div>
        <FormWrapper>
            <PageSubtitleSmallMargin>
                <Trans>На платформе учителя могут:</Trans>
            </PageSubtitleSmallMargin>
            <RegularText>
                <Trans><b>Оценивать работу</b> ученика по нескольким параметрам, с использованием 100-балльной
                    системы. Например, для уроков иностранного языка это обычно:<br/>
                    Чтение, Письмо, Речь, Восприятие на слух. </Trans>
            </RegularText>
            <br/>
            <RegularText>
                <Trans><b>На уроке</b> оценку по каждому параметру ставит сам учитель, а <b>домашнюю работу</b> по тем
                    же параметрам автоматически оценивает алгоритм платформы.</Trans>
            </RegularText>
            <br/>
            <RegularText>
                <Trans>Вот как выглядит оценка работы ученика, если в домашней работе были задания на
                    чтение и развитие речи:</Trans>
            </RegularText>
            <br/>
            <br/>
            <ImageWrapper>
                <Image src={'/img/rates-example.png'}/>
            </ImageWrapper>
            <br/>
            <br/>
        </FormWrapper>
        <ButtonArea
            prevSectionAvailable={true}
            nextSectionAvailable={true}
            prevBtnOnClick={() => {
                stepWizardContext.goPrev();

                return Promise.resolve();
            }}
            nextBtnOnClick={async () => {
                onFinish();

                return Promise.resolve();
            }}
        />
    </div>;

}