import {Editor, Element as SlateElement} from "slate";
import {MarkFormats} from "./Enums";
import {ElementTypes} from "../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";
import {AlignTypes} from "../../../SlidePlayerEditorCommonParts/TestEditorAlignTypeEnum";

export const isMarkActive = (editor: Editor, format: MarkFormats) => {
    const marks = Editor.marks(editor);

    if (!marks) {
        return false;
    }

    // return marks.marks.includes(format);
    return !!(marks[format] && marks[format] === true);
}


export const alignTypeIsActive = (editor: Editor, alignType: AlignTypes) => {
    const {selection} = editor;

    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                return (node.align === undefined) ? AlignTypes.LEFT === alignType : (node.align === alignType);
            }
        })
    )

    return !!match
}

export const elementTypeIsActive = (editor: Editor, elementType: ElementTypes) => {
    const {selection} = editor

    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                return node.type === elementType;
            }
        })
    )

    return !!match
}

