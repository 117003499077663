import {MessageTypeIdEnum} from "../MessageTypeIdEnum";
import {IsNotEmpty, IsString} from "class-validator";

export class BaseMessageDto<T> {
    @IsNotEmpty()
    @IsString()
    typeId: MessageTypeIdEnum;

    payload: T
}
